import { useEffect, useRef, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAllAccountingFirms } from '../../../api/service/superAdmin/accounting-firm/service';
import { GetAccountingFirm } from '../../../api/service/superAdmin/accounting-firm/types';
import CardComponent from '../../../components/base/CardComponent';
import BSTable from '../../../components/table/BSTable';
import Pagination from '../../company/Pagination';
import { useColumn } from './useColumn';

type Props = {
  isAccountingFirmCreated: boolean;
};

export default function AccountingFirmGrid({ isAccountingFirmCreated }: Props) {
  const navigate = useNavigate();
  const { accountingFirmColumn } = useColumn();
  const [isLoading, setIsLoading] = useState(true);
  const [companyData, setCompanyData] = useState<GetAccountingFirm | any | any[]>(null);
  const { t } = useTranslation();
  const searchRef = useRef<any>();
  const totalPages = companyData?.metaData
    ? Math.ceil(companyData?.metaData.total / companyData?.metaData?.page.size)
    : 0;

  const onSearch = async () => {
    const searchTerm = searchRef.current['value'];
    fetchCompanyClients({ searchString: searchTerm });
  };

  const onPagination = async ({ page, sizePerPage }) => {};

  const fetchCompanyClients = async ({ searchString = '' }) => {
    const clients = await getAllAccountingFirms({ searchString });
    setCompanyData(clients);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isAccountingFirmCreated) {
      setIsLoading(true);
      fetchCompanyClients({});
    }
  }, [isAccountingFirmCreated]);

  useEffect(() => {
    fetchCompanyClients({});
  }, []);

  return (
    <>
      <CardComponent>
        <Row className="justify-content-between">
          <Col className="mb-4" md={3}>
            <div className="search-field">
              <div className="input-group">
                <input
                  ref={searchRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearch();
                    }
                  }}
                  placeholder={`${t('search')}...`}
                  className="form-control"
                  style={{ borderRight: 'none' }}
                />
                <span className="input-group-append bg-white border-left-0">
                  <span className="input-group-text ">
                    <Search className="cursor-pointer" width={16} height={30} onClick={onSearch} />
                  </span>
                </span>
              </div>
            </div>
          </Col>
          {isLoading ? (
            <Spinner />
          ) : (
            <BSTable
              data={companyData ? companyData : []}
              columns={accountingFirmColumn}
              noDataIndication="No clients found"
              key="_id"
              onRowClick={(row) => {
                navigate(row._id);
              }}
            />
          )}
        </Row>
        {totalPages > 1 && (
          <Pagination
            total={companyData?.metaData.total ?? 0}
            itemsPerPage={companyData?.metaData.page.size ?? 0}
            currentPage={companyData?.metaData.page.current ?? 0}
            onPageChange={(page) => onPagination({ page, sizePerPage: companyData?.metaData.page.size })}
          />
        )}
      </CardComponent>
    </>
  );
}
