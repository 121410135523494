import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PercentageNumber } from './inputs/PercentangeNumber';
import PagesHeader from '../base/PagesHeader';
import { TypeOccupationPension } from '../../pages/superAdmin/PensionPolicy/types';
import { errorHighlighter } from '../../util/form';
import PriceInput from '../base/PriceInput';
import useIncomeCalculation from '../../pages/superAdmin/PensionPolicy/hooks/useIncomeCalculation';
import {
  SALARY_AMOUNT_INDEX_OPTIONS,
  SALARY_INTERVAL_AGE_DEFINITION_OPTIONS,
} from '../../pages/superAdmin/PensionPolicy/constants';
import useContext from '../../pages/superAdmin/PensionPolicy/PensionPolicyContext/useContext';
import { Info } from 'react-feather';
import ErrorFeedback from '../ErrorFeedback';

const CompensationPlan = () => {
  const { t } = useTranslation();
  const { formGroup, occupationalPension } = useContext();
  const [compensationPlan, setCompensationPlan] = useState<TypeOccupationPension>(occupationalPension);
  const isIntervalCompensation = formGroup.values.policySettings?.occupationalPension?.isIntervalCompensation;
  // const ageList = generateNumberList(21, 80);
  const {
    groupCollection,
    ageCollection,
    amountIndexCollection,
    ageOptions,
    setGroupItemIncomeValue,
    validateAmountComparison,
    setAgeCollection,
    setAmountIndexCollection,
  } = useIncomeCalculation();

  /* const handlePlanValuesChange = (ev, planKey) => {
    if (!ev.target.value.includes('-')) {
      const speratedNumber = numberSpaceSeparator(ev.target.value.replaceAll(' ', ''));
      ev.target.value = Math.abs(speratedNumber.replaceAll(' ', ''));
      ev.target.value = speratedNumber;
      const payload = {
        ...compensationPlan,
        [planKey]: { ...compensationPlan[planKey], [ev.target.name]: ev.target.value },
      };
      setCompensationPlan(payload);
      onPlansUpdate(payload);
    }
  }; */
  const handlePercentageValue = (value, planKey) => {
    formGroup.setFieldValue(`policySettings.occupationalPension.${planKey}`, value);
  };

  const handleFieldNumberValue = (value, fieldName) => {
    const payload = {
      ...compensationPlan,
      [fieldName]: value,
    };
    formGroup.setFieldValue(`policySettings.occupationalPension.${fieldName}`, value);

    setCompensationPlan(payload);
    // onPlansUpdate(payload);
  };

  const handleAgeChange = (value, idx) => {
    const _ageCollection = [...ageCollection];

    _ageCollection[idx] = value;

    setAgeCollection(_ageCollection);
  };

  const handleIndexAmountChange = (value, idx) => {
    const _amountIndexCollection = [...amountIndexCollection];

    _amountIndexCollection[idx] = value;

    setAmountIndexCollection(_amountIndexCollection);

    validateAmountComparison(value, idx);
  };

  const handleIncomeValueChange = (groupIdx, colIdx, value) => {
    setGroupItemIncomeValue(groupIdx, colIdx, value);
  };

  const handleIncomeMatrix = (e) => {
    const payload = {
      ...compensationPlan,
      [e.target.name]: e.target.value,
    };
    formGroup.setFieldValue(`policySettings.occupationalPension.${e.target.name}`, e.target.value);
    setCompensationPlan(payload);
    // onPlansUpdate(payload);
  };

  const toggleIntervalCmpensation = (e) => {
    formGroup.setFieldValue('policySettings.occupationalPension.isIntervalCompensation', e.target.checked);
  };

  useEffect(() => {
    setCompensationPlan(occupationalPension);
  }, [occupationalPension]);

  return (
    <>
      <div className="mb-4">
        <div className="mt-4 mb-3">
          <PagesHeader pageTitle={t('PENSION_POLICY.premium_calculation')} biggerHeading={true} translate={false} />
        </div>

        <div className="d-flex dark-content-section mb-4 d-none">
          <Form>
            <Form.Group className=" pe-0">
              <Form.Check inline id="isPremium" className="fs-name mb-1">
                <Form.Check.Input
                  name="isPremium"
                  type="checkbox"
                  checked={formGroup.values.policySettings.occupationalPension.isPremium}
                  onChange={(value) => handleFieldNumberValue(value.target.checked, 'isPremium')}
                />
                <Form.Check.Label htmlFor="isPremium" className="form-check-label text-label-note">
                  {t('PENSION_POLICY.premium_payment')}
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
            <div className="ms-4">
              <p className="text-label-note">{t('PENSION_POLICY.parental_leave_premium_continuation')}.</p>
            </div>
          </Form>
        </div>
        <div className="d-flex dark-content-section mb-4">
          <Row>
            <Col className="d-flex align-items-start">
              <Form>
                <Form.Group className=" pe-0 switch-btn-green">
                  <Form.Label className="text-label-note">{t('PENSION_POLICY.interval_compensation')}</Form.Label>
                  <Form.Check
                    type="switch"
                    bsPrefix="chart-switch"
                    className="fs-name mb-1"
                    inline
                    label=""
                    id="isIntervalCompensation"
                    onChange={toggleIntervalCmpensation}
                    checked={isIntervalCompensation}
                    name="isIntervalCompensation"
                  />
                </Form.Group>
              </Form>
            </Col>
            <Col className="d-flex align-items-start">
              <div className="ms-4">
                <p className="m-0 text-label-note">{t('PENSION_POLICY.interval_compensation_text')}</p>
              </div>
            </Col>
          </Row>
        </div>
        {/* <div className="color-error position-relative mb-2">
          {getFieldError(formGroup!.getFieldMeta('plan.totalSalary'))}
        </div> */}
        {!isIntervalCompensation && (
          <>
            <PagesHeader pageTitle={t('total_salary_compensation')} translate={false} />
            <ErrorFeedback behavior="alert" field={formGroup!.getFieldMeta('policySettings.occupationalPension')} />

            <div className="d-flex align-items-end dark-content-section">
              <div className="cr-form-row">
                <PercentageNumber
                  label={`${t('percentage_compensation')} (%)`}
                  name="percentageCompensation"
                  value={formGroup.values.policySettings.occupationalPension.percentageCompensation}
                  onChangeHandler={(ev) => handlePercentageValue(ev, 'percentageCompensation')}
                  decimalPlace={2}
                  className={classNames(
                    'percentage-compensation-field text-right',
                    errorHighlighter(formGroup!.getFieldMeta(`policySettings.occupationalPension`)),
                  )}
                />
                <Form.Group className="fixed-compensation-field">
                  <Form.Label className="fs-name">{t('fixed_compensation')} (SEK)</Form.Label>
                  <PriceInput
                    name="fixedCompensation"
                    defaultValue={formGroup.values.policySettings.occupationalPension.fixedCompensation}
                    onChange={(value) => {
                      handleFieldNumberValue(value, 'fixedCompensation');
                    }}
                    maxValueDigit={7}
                    className={classNames(
                      'text-right',
                      errorHighlighter(formGroup!.getFieldMeta(`policySettings.occupationalPension`)),
                    )}
                  />
                </Form.Group>
              </div>
            </div>
          </>
        )}

        {/* <ErrorFeedback field={formGroup!.getFieldMeta(`plan.totalSalary.fixedValue`)} /> */}
      </div>
      {isIntervalCompensation && (
        <div>
          <PagesHeader pageTitle="Salary interval compensation" />
          <ErrorFeedback behavior="alert" field={formGroup!.getFieldMeta('policySettings.occupationalPension')} />
          {/*----INCOME METRIX SECTION----*/}

          <PagesHeader pageTitle={t('PENSION_POLICY.income_matrix')} headingClass="fs-text-400" translate={false} />
          <div className="d-flex dark-content-section mb-4 income-metrix-box">
            <Row className="w-100">
              <Col className="col-3">
                <div className="left-box">
                  <Form.Group>
                    <Form.Label className="fs-name">{t('PENSION_POLICY.amount_index')}</Form.Label>
                    <p className="text-label-note">{t('PENSION_POLICY.salary_matrix_index_selection')}</p>
                    <Form.Select
                      style={{ maxWidth: 80 }}
                      value={formGroup.values.policySettings.occupationalPension.amountIndex}
                      name="amountIndex"
                      onChange={handleIncomeMatrix}
                    >
                      {SALARY_AMOUNT_INDEX_OPTIONS.map((item) => {
                        return (
                          <option key={item.key} value={item.key}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {/* <PriceInput
                    name="fixedValue"
                    defaultValue={compensationPlan.fixedCompensation}
                    onChange={(value) => handleFieldNumberValue(value, 'fixedValue')}
                    style={{ width: 100 }}
                    maxValueDigit={7}
                    className={classNames(
                      'text-right',
                      errorHighlighter(formGroup!.getFieldMeta(`plan.totalSalary.fixedValue`)),
                    )}
                  /> */}
                  </Form.Group>
                  {/* <Form.Group>
                    <Form.Label className="fs-name">Offset calculation</Form.Label>
                    <Form.Select
                      value={compensationPlan.offsetCalculation}
                      name="offsetCalculation"
                      onChange={handleIncomeMatrix}
                    >
                      {SALARY_INTERVAL_OFFSET_OPTIONS.map((item) => {
                        return (
                          <option key={item.key} value={item.key}>
                            {item.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group className="mt-2">
                    <Form.Label className="fs-name">{t('PENSION_POLICY.age_definition')}</Form.Label>
                    <p className="text-label-note">{t('PENSION_POLICY.choose_salary_age_definition')}</p>
                    <Form.Select
                      value={compensationPlan.ageDefination}
                      onChange={handleIncomeMatrix}
                      name="ageDefination"
                      style={{ maxWidth: 160 }}
                    >
                      {SALARY_INTERVAL_AGE_DEFINITION_OPTIONS.map((item) => {
                        return (
                          <option key={item.key} value={item.key}>
                            {t(item.translationKey)}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </div>
              </Col>
              <Col className="col-9">
                <div className="text-right">
                  <div className="text-right p-2 mb-2 d-inline-flex align-items-center alert alert-info theme-text">
                    <div>
                      <Info className="icon-gap-right" />
                      {t('PENSION_POLICY.ensure_value_range_integrity')}
                    </div>
                  </div>
                </div>

                <div className="metrix-table">
                  <span className="first-column-line"></span>
                  <table className="w-100">
                    <thead>
                      <th>
                        <span className="theme-text">{t('PENSION_POLICY.age')}</span>
                        <div className="age-input-metrix">
                          <Form.Select
                            name={`age-item-0`}
                            value={ageCollection[0]}
                            onChange={(e) => handleAgeChange(e.target.value, 0)}
                          >
                            <option disabled selected={ageCollection[0] === null}>
                              {t('select')}...
                            </option>
                            {ageOptions[0].map((item) => (
                              <option value={item.value} key={item.value} disabled={item.disabled}>
                                {item.label}
                              </option>
                            ))}
                          </Form.Select>
                          {/* <PriceInput
                          name="fixedValue"
                          defaultValue={compensationPlan.fixedCompensation}
                          onChange={(value) => handleFieldNumberValue(value, 'fixedValue')}
                          maxValueDigit={7}
                          className={classNames(
                            'text-right',
                            errorHighlighter(formGroup!.getFieldMeta(`plan.totalSalary.fixedValue`)),
                          )}
                        /> */}
                        </div>
                      </th>
                      <th>
                        <span className="second-th-heading theme-text">{t('amount')}</span>
                        <PriceInput
                          name={`amountIndex-0`}
                          defaultValue={amountIndexCollection[0]}
                          onChange={(value) => handleIndexAmountChange(value, 0)}
                          maxValueDigit={7}
                        />
                      </th>
                      {amountIndexCollection
                        .filter((_, idx) => idx !== 0)
                        .map((_val, idx) => {
                          return (
                            <th key={`indexAmount-${idx + 1}`}>
                              <span className="theme-text">
                                {t('PENSION_POLICY.income_level')} {idx + 1}
                              </span>
                              <PriceInput
                                name={`amountIndex-${idx + 1}`}
                                defaultValue={amountIndexCollection[idx + 1]}
                                onChange={(value) => handleIndexAmountChange(value, idx + 1)}
                                maxValueDigit={7}
                              />
                            </th>
                          );
                        })}
                    </thead>
                    <tbody>
                      {groupCollection.map((group, groupIdx) => {
                        return (
                          <tr key={`grouprow-${groupIdx}`}>
                            <td>
                              <Form.Select
                                name={`age-item-${groupIdx + 1}`}
                                value={ageCollection[groupIdx + 1]}
                                onChange={(e) => handleAgeChange(e.target.value, groupIdx + 1)}
                              >
                                <option disabled selected={ageCollection[groupIdx + 1] === null}>
                                  {t('select')}...
                                </option>
                                {ageOptions[groupIdx + 1].map((item) => (
                                  <option value={item.value} key={item.value} disabled={item.disabled}>
                                    {item.label}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <th>
                              <span className="theme-text">
                                {t('PENSION_POLICY.age_group')} {groupIdx + 1}
                              </span>
                            </th>
                            {group.incomeLevels.map((incomeLevel, levelIdx) => {
                              return (
                                <React.Fragment key={`group-income-${groupIdx}${levelIdx}`}>
                                  <td>
                                    <PriceInput
                                      name={`income-input-${groupIdx}${levelIdx}`}
                                      defaultValue={incomeLevel.value}
                                      onChange={(value) => handleIncomeValueChange(groupIdx, levelIdx, value)}
                                      maxValueDigit={7}
                                    />
                                  </td>
                                </React.Fragment>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};
export default React.memo(CompensationPlan);
