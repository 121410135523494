import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PensionPoliciesDetailsEditable from './PensionPoliciesDetailsEditable';
import useContext from './PensionPolicyContext/useContext';
import ValidationAlert from '../../../components/ValidationAlert';

type Props = {
  errors?: any;
  isInvalid?: boolean;
};

export default function CreatePolicy({ errors, isInvalid = false }: Props) {
  const { setIsResetPolicy } = useContext();
  // const localUser = localStorage.getItem('user');
  //  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  // const isSuperAdmin = user?.role?.role === UserRole.SUPER_ADMIN;
  // const [isBusy, setIsBusy] = useState(false);
  //const navigate = useNavigate();
  // const { isInvalid, errors } = useValidationError();

  //const { localize } = useLocale();
  // const breadcrumb: IBreadCrumb[] = [
  //   {
  //     name: 'Overview',
  //     href: '/dashboard',
  //   },
  //   {
  //     name: 'Pension Policies',
  //     href: '/pension-policies',
  //   },
  //   {
  //     name: policyForm.policyName ?? '',
  //     translate: false,
  //   },
  // ];

  /* const clearForm = () => {
    replacePolicyInput({
      policyName: '',
      policyDescription: '',
      version: '',
      employeeType: '',
      effectiveFrom: '',
      isCollectiveAgreementLock: false,
      status: '',
      type: '',
    });
  }; */

  // const navigateToPensionPolicies = () => {
  //   navigate('/pension-policies');
  //   clearForm();
  // };

  // const setDefaultPlanValue = (plan) => {
  //   const defaulPlan = deepClone(plan);

  //   Object.entries(defaulPlan).forEach(([planKey, planValue]) => {
  //     if (typeof planValue === 'string') {
  //       if (planValue === '') {
  //         plan[planKey] = 0;
  //       } else {
  //         let formattedNumber = planValue.replaceAll(' ', '');
  //         formattedNumber = formattedNumber.replaceAll(',', '.');
  //         plan[planKey] = Number(formattedNumber);
  //       }
  //     } else if (typeof planValue === 'object') {
  //       modifyPlanValues(planValue);
  //     }
  //   });

  //   return defaulPlan;
  // };

  // const onSavePolicy = async () => {
  //   try {
  //     //  formGroup.handleSubmit();
  //     // formGroup.validateForm().then((errors) => {
  //     //   setErrors(errors);
  //     // });

  //     // if (!formGroup.isValid) {
  //     //   return false;
  //     // }
  //     const isTemplateCalculation = formGroup.values.policySettings.salaryType.isTemplateCalculation;
  //     // const isIntervalCompensation = formGroup.values.policySettings.occupationalPension.isIntervalCompensation;

  //     let modifiedSalaryTypePayload;
  //     // let modifiedOccupationalPensionPayload;

  //     // if (isIntervalCompensation) {
  //     //   modifiedOccupationalPensionPayload = {
  //     //     isIntervalCompensation,
  //     //     isPremium: formGroup.values.policySettings.occupationalPension.isIntervalCompensation,
  //     //     ageDefination: formGroup.values.policySettings.occupationalPension.ageDefination,
  //     //     amountIndex: formGroup.values.policySettings.occupationalPension.amountIndex,
  //     //     calculation: formGroup.values.policySettings.occupationalPension.calculation,
  //     //     offsetCalculation: formGroup.values.policySettings.occupationalPension.offsetCalculation,
  //     //   };
  //     // } else {
  //     //   modifiedOccupationalPensionPayload = {
  //     //     isIntervalCompensation,
  //     //     isPremium: formGroup.values.policySettings.occupationalPension.isIntervalCompensation,
  //     //     fixedCompensation: formGroup.values.policySettings.occupationalPension.fixedCompensation,
  //     //     percentageCompensation: formGroup.values.policySettings.occupationalPension.percentageCompensation,
  //     //   };
  //     // }

  //     if (isTemplateCalculation) {
  //       modifiedSalaryTypePayload = {
  //         isTemplateCalculation: isTemplateCalculation,
  //         salaryTypesWithHolidays: formGroup.values.policySettings.salaryType.salaryTypesWithHolidays.map((value) => {
  //           return {
  //             isEnable: value.isEnable,
  //             setupName: value.setupName,
  //             holidayDaysPerYear: toNumber(value.holidayDaysPerYear),
  //             data: value.data.filter((item) => item.holidayPayMultiple !== 0 || item.salaryType.name !== ''),
  //           };
  //         }),
  //       };
  //     } else {
  //       modifiedSalaryTypePayload = {
  //         isTemplateCalculation: isTemplateCalculation,
  //         salaryTypes: formGroup.values.policySettings.salaryType.salaryTypes
  //           .map((value) => {
  //             return {
  //               isInclude: value.includedInPension,
  //               id: value._id,
  //               name: value.name,
  //               code: value.code,
  //               percentage: value.percentage || 0,
  //               mapsTo: value.mapsTo,
  //             };
  //           })
  //           .filter((item) => item.name !== '' || item.code !== 0),
  //       };
  //     }
  //     const payload = {
  //       policyName: policyForm.policyName,
  //       version: policyForm.version,
  //       employeeType: policyForm.employeeType,
  //       effectiveFrom: policyForm.effectiveFrom,
  //       status: 'InActive',
  //       type: isSuperAdmin ? 'CollectiveAgreement' : 'Company',
  //       isCollectiveAgreementLock: isSuperAdmin ? policyForm.isCollectiveAgreementLock : false,
  //       policyDescription: policyForm.policyDescription,
  //       policySettings: {
  //         ...formGroup.values.policySettings,
  //         salaryType: modifiedSalaryTypePayload,
  //       },
  //     };

  //     setIsBusy(true);
  //     await savePolicy(payload);
  //     setIsResetPolicy(true);
  //     formGroup.resetForm();
  //     navigateToPensionPolicies();
  //     clearForm();
  //   } catch (error) {
  //     if (error instanceof Error) {
  //       setErrors({ error: error.message });
  //     }
  //   } finally {
  //     setIsBusy(false);
  //   }
  // };

  useEffect(() => {
    setIsResetPolicy(true);

    return () => {
      setIsResetPolicy(false);
    };
  }, []);

  return (
    <Container fluid className="p-0">
      {/* <Breadcrumb navItem={breadcrumb} /> */}
      <ValidationAlert show={isInvalid} errors={errors} />
      <div className="d-flex justify-content-between align-items-center">
        {/* <PagesHeader headingClass="fs-h1" pageTitle={policyForm.policyName ?? ''} translate={false} /> */}
        {/* <div className="mb-2">
          <Button onClick={() => navigate(-1)} className="me-1 app-btn-secondary cancel-btn">
            {localize('cancel')}
          </Button>
          <Button onClick={onSavePolicy} className="app-btn-primary">
            {isBusy ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}
            {`${localize('save')} ${localize('policy')}`}
          </Button>
        </div> */}
      </div>

      <PensionPoliciesDetailsEditable openedByModal={true} />
    </Container>
  );
}
