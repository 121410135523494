import { Link } from 'react-router-dom';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Check, Copy, Edit2, Lock, Trash2 } from 'react-feather';
import moment from 'moment';

import { sortCaret } from '../../../../components/table/formatters';
import { tableHeaderText } from '../../../../components/table/TableHeaderFormatter';
import { ReactComponent as CollectiveAgreementIcon } from '../../../../assets/img/collective-agreement.svg';
import { AuthUser, UserRole } from '../../../../types/auth';
import { PensionPolicyVariant } from '../types';
import usePreferredPolicy from '../hooks/usePreferredPolicy';
import useLocalizationResolver from '../../../../hooks/useLocalizationResolver';
import { useEffect, useState } from 'react';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';
import { useTranslation } from 'react-i18next';

const LockIcon = (_row) => {
  const { t } = useTranslation();
  return (
    <span className="lock-agreement-markup lock-agreement-markup--grid">
      <TooltipBox msg={`${t('locked')}`}>
        <Lock />
      </TooltipBox>
    </span>
  );
};

const EditIcon = ({ row }: { row: any }) => {
  const { t } = useTranslation();
  return (
    <TooltipBox msg={`${t('edit')}`}>
      <Link
        className="mb-0"
        style={{ float: 'right' }}
        to={
          row.type === 'Personal' && row.employeeId
            ? `/employees/${row.employeeId}/${row._id}`
            : `/pension-policies/${row._id}/edit`
        }
      >
        <Edit2 className="blue-icon-btn" />
      </Link>
    </TooltipBox>
  );
};

const EditOrLockMarkup = ({ row }: { row: any }) => {
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const isSuperAdmin = user?.role?.role === UserRole.SUPER_ADMIN;

  const preferredPolicy = usePreferredPolicy(row);

  if (isSuperAdmin) {
    if (preferredPolicy.isCollectiveContract()) {
      return <LockIcon row={row} />;
    }

    return <EditIcon row={row} />;
  } else {
    if (preferredPolicy.hasCorrectoAdmin()) {
      return <LockIcon row={row} />;
    }

    return <EditIcon row={row} />;
  }
};

const CollectiveAgreementMarkup = ({ row }: { row: any }) => {
  const preferredPolicy = usePreferredPolicy(row);
  const { t } = useTranslation();
  return (
    <>
      {preferredPolicy.isCollectiveContract() && (
        <TooltipBox msg={`${t('collective_agreement')}`}>
          <span className="partner-icon">
            <CollectiveAgreementIcon />
          </span>
        </TooltipBox>
      )}
    </>
  );
};

const EmployeementTypeColumn = ({ row }: { row: any }) => {
  const { getLang } = useLocalizationResolver();
  return <>{getLang(row.employeeType)}</>;
};

const PolicyTypeColumn = ({ row }: { row: any }) => {
  const { getLang } = useLocalizationResolver();
  return <>{getLang(`POLICY_TYPE.${row.type}`)}</>;
};

const StatusColumn = ({ row }: { row: any }) => {
  const { getLang } = useLocalizationResolver();
  return <>{getLang(row.status)}</>;
};

interface InputProps {
  onDelete: (id) => void;
  onCopy: (id) => void;
  isBusy: boolean;
  workflowSetting: any;
}

export const useColumn = ({ onDelete, onCopy, isBusy, workflowSetting }: InputProps) => {
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const isSuperAdmin = user?.role?.role === UserRole.SUPER_ADMIN;
  const { t } = useTranslation();
  const canDeletePolicy = (row) => {
    if (isSuperAdmin) {
      if (
        [PensionPolicyVariant.CORRECTO_PROVIDED, PensionPolicyVariant.COLLECTIVE_AGREEMENT].indexOf(row.type) !== -1
      ) {
        return true;
      }

      return false;
    } else {
      if ([PensionPolicyVariant.COMPANY, PensionPolicyVariant.PERSONAL].indexOf(row.type) !== -1) {
        return true;
      }

      return false;
    }
  };
  /* const renderTitle = (cell, row) => {
    if (row?.type !== 'Personal') return <span>{cell}</span>;

    const personalNumberRegex = /[0-9]{8}-[0-9]{4}/;
    const split = cell.split(' ');
    let splicedStr = split.slice(0, split.length - 1).join(' ');
    const splicedStrArr = splicedStr.split(' ');
    const personalNumber = splicedStrArr.pop() ?? '';
    splicedStr = [...splicedStrArr, personalNumberRegex.test(personalNumber) ? '' : personalNumber].join(' ');
    if (!personalNumber) return <span>splicedStr</span>;

    return (
      <span>
        {splicedStr} <br />
        {personalNumber}
      </span>
    );
  }; */

  const collectiveAgreementColumn = [
    {
      text: '',
      dataField: 'isCollective',
      align: 'left',
      headerStyle: () => {
        return { width: '70px' };
      },
      formatter: (_, row) => {
        return <CollectiveAgreementMarkup row={row} />;
      },
    },
  ];

  const editOrLockColumn = [
    {
      text: '',
      dataField: 'canEdit',
      align: 'center',
      headerStyle: () => {
        return { width: '55px' };
      },
      formatter: (_, row) => {
        return (
          <>
            <EditOrLockMarkup row={row} />
          </>
        );
      },
    },
  ];

  const copyColumn = [
    {
      text: '',
      dataField: 'canCopy',
      align: 'center',
      headerStyle: () => {
        return { width: '55px' };
      },
      formatter: (_, row) => {
        return (
          <>
            {row['type'] !== PensionPolicyVariant.PERSONAL && (
              <TooltipBox msg={t('duplicate')}>
                <span
                  className="mb-0"
                  onClick={isBusy ? undefined : () => onCopy(row._id)}
                  style={{ cursor: 'pointer' }}
                >
                  <Copy className="blue-icon-btn" />
                </span>
              </TooltipBox>
            )}
          </>
        );
      },
    },
  ];

  const deleteColumn = [
    {
      text: '',
      dataField: 'canDelete',
      align: 'center',
      headerStyle: () => {
        return { width: '40px' };
      },
      formatter: (_, row) => {
        return (
          <>
            {canDeletePolicy(row) && (
              <TooltipBox msg={`${t('delete')}`}>
                <span
                  className="mb-0"
                  onClick={isBusy ? undefined : () => onDelete(row._id)}
                  style={{ cursor: 'pointer' }}
                >
                  <Trash2 className="delete-icon" />
                </span>
              </TooltipBox>
            )}
          </>
        );
      },
    },
  ];

  const autoAssignColumn = [
    {
      text: tableHeaderText('Auto assign'),
      dataField: 'isAutoAssign',
      headerStyle: () => {
        return { width: '85px' };
      },
      sort: false,
      sortCaret,
      formatter: (_, row) => {
        if (!row.isAutoAssign) {
          return <></>;
        }

        return <Check color="#0D830B" className="icon-gap-right" />;
      },
    },
  ];

  const commonColumns: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Name'),
      dataField: 'policyName',
      sort: true,
      sortCaret,
      headerStyle: () => {
        return { width: '210px' };
      },
      // formatter: renderTitle, I have comment this renderTitle because of CPIV-190
    },
    {
      text: tableHeaderText('Version'),
      dataField: 'version',
      sort: true,
      sortCaret,
      headerStyle: () => {
        return { width: '90px' };
      },
    },
    {
      text: tableHeaderText('Type'),
      dataField: 'type',
      sort: true,
      sortCaret,
      headerStyle: () => {
        return { width: '150px' };
      },
      formatter: (_, row) => {
        return (
          <>
            <PolicyTypeColumn row={row} />
          </>
        );
      },
    },
    {
      text: tableHeaderText('Employment Type'),
      dataField: 'employeeType',
      sort: true,
      sortCaret,
      headerStyle: () => {
        return { width: '140px' };
      },
      formatter: (_, row) => {
        return (
          <>
            <EmployeementTypeColumn row={row} />
          </>
        );
      },
    },
  ];

  const commonEndColumns: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Effective From'),
      dataField: 'effectiveFrom',
      sort: true,
      sortCaret,
      formatter: (cell) => moment(cell).format('YYYY-MM-DD'),
      headerStyle: () => {
        return { width: '122px' };
      },
    },
    {
      text: tableHeaderText('Status'),
      dataField: 'status',
      sort: true,
      sortCaret,
      headerStyle: () => {
        return { width: '100px' };
      },
      formatter: (_, row) => <StatusColumn row={row} />,
    },
  ];

  const [baseColumns, setBaseColumns] = useState<any[]>([...commonColumns, ...commonEndColumns]);

  const pensionPolicyListColumns = [
    ...baseColumns,
    ...collectiveAgreementColumn,
    ...editOrLockColumn,
    ...copyColumn,
    ...deleteColumn,
  ];

  useEffect(() => {
    if (
      workflowSetting &&
      (workflowSetting.blueCollarPolicy?.automatic || workflowSetting.whiteCollarPolicy?.automatic)
    ) {
      setBaseColumns([...commonColumns, ...autoAssignColumn, ...commonEndColumns]);
    }
  }, [workflowSetting]);

  return { pensionPolicyListColumns };
};
