import { ISettingInvoicesDoc, ISettingProxyDoc } from '../../../pages/company/Settings/types';
import {
  API_VERSION,
  COMPANY_CLIENTS_ENDPOINT,
  COMPANY_SALARY_TYPES_ENDPOINT,
  COMPANY_WORKFLOW_ENDPOINT,
} from '../../../util/constants';
import { HttpQuery } from '../../../util/http-query';
import { post, get, put } from '../../../util/httpService';

export const uploadSalaryFiles = async (payload, companyId) => {
  const endpoint = `${API_VERSION}/salary-files/${companyId}/upload`;
  const res = await post(endpoint, payload, { 'Content-Type': 'multipart/form-data' });

  return res;
};

export const getSalaryFiles = async () => {
  const endpoint = `${API_VERSION}/salary-files`;
  const res = await get(endpoint);
  return res;
};
const getDataValidationFilterParams = (param) => {
  const filterValue = {
    fullName: { like: "" },
    period: "",
    nonValid: {
      exist: false
    },
  };
  const defaultParam = {
    page: 1,
    sizePerPage: 25,
    sort: {
      fullName: '1',
    },
    filter: filterValue,
  };
  const fullName = param.filter?.fullName;
  const filterParam = {
    ...param.filter,
    fullName: { like: fullName }
  }
  return Object.assign({}, defaultParam, param, { filter: filterParam });
}
export const getDataValidations = async (
  filter: any
) => {
  let endpoint = HttpQuery.serialize(getDataValidationFilterParams(filter), `${API_VERSION}/data-validations`);
  const res = await get(endpoint);
  return res;

};

export const getDataValidationDetailsByID = async (id) => {
  const endpoint = `${API_VERSION}/data-validations/${id}`;
  const res = await get(endpoint);
  return res;
};

export const saveSalaryDataValidation = async (id, data) => {
  const endpoint = `${API_VERSION}/data-validations/${id}/execute`;
  const res = await put(endpoint, data, {});
  return res;
};

export const getServiceAgreementDocuments = async (id): Promise<any> => {
  const endpoint = `${API_VERSION}/verified/envelope/data/${id}`;
  const res = await get(endpoint);
  return res || [];
};

export const getProxyDocuments = (): Promise<ISettingProxyDoc[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          title: 'PROXY Konsultia AB (2021-03-01) signed 2021-02-15',
        },
        {
          title: 'PROXY Konsultia AB (2021-03-01) signed 2021-02-15',
        },
        {
          title: 'PROXY Konsultia AB (2021-03-01) signed 2021-02-15',
        },
      ]);
    }, 2000);
  });
};
export const getInvoicesDocuments = (): Promise<ISettingInvoicesDoc[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          title: '2022-03 (invoice no: 2392)',
        },
        {
          title: '2022-04 (invoice no: 2606) ',
        },
        {
          title: '2022-05 (invoice no: 3214)',
        },
        {
          title: '2022-06 (invoice no: 3214)',
        },
        {
          title: '2022-07 (invoice no: 3214)',
        },
      ]);
    }, 2000);
  });
};

export const verifyFortnoxSession = async () => {
  const endpoint = `${API_VERSION}/fortnox/auth/get-token-by-company`;
  const res = await get(endpoint);
  return res;
};

export const syncFortnoxEmployees = async () => {
  const endpoint = `${API_VERSION}/fortnox/employees/sync`;
  const res = await post(endpoint, {}, {});
  return res;
};

export const getCompanySalaryCode = async () => {
  const endpoint = `${API_VERSION}${COMPANY_SALARY_TYPES_ENDPOINT}`;
  const salaryTypes = await get(endpoint);
  return salaryTypes;
};

export const saveCompanySalaryCode = async (payload) => {
  const endpoint = `${API_VERSION}${COMPANY_SALARY_TYPES_ENDPOINT}`;
  const salaryTypes = await put(endpoint, payload, {});
  return salaryTypes;
};

export const getCompanyWorkflow = async () => {
  const endpoint = `${API_VERSION}${COMPANY_WORKFLOW_ENDPOINT}`;
  const res = await get(endpoint);

  return res;
};

export const saveompanyWorkflow = async (payload) => {
  const endpoint = `${API_VERSION}${COMPANY_WORKFLOW_ENDPOINT}`;
  const salaryTypes = await post(endpoint, payload, {});
  return salaryTypes;
};

export const saveAccessLevel = async (payload, id) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/access/${id}`;
  const accessLevel = await put(endpoint, payload, {});
  return accessLevel;
};
