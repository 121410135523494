import DatePicker from 'react-datepicker';
import React, { ForwardedRef, forwardRef, useState } from 'react';
import { Calendar } from 'react-feather';
import classNames from 'classnames';

type CalenderInputProps = {
  startDate?: Date;
  dateFormat?: string;
  className?: string;
  showMonthYearPicker?: boolean;
  datepickerWidth?: number;
  minDate?: Date;
  maxDate?: Date;
  onChangeHandler: (date) => void;
};

interface InputFieldProps {
  value?: string;
  onClick?: () => void;
  datepickerWidth: any;
}

export const InputField = forwardRef(
  ({ value, onClick, datepickerWidth }: InputFieldProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <div className="crt-datepicker-field">
        <input type="text" className="form-control" ref={ref} value={value} onClick={onClick} readOnly />
        <Calendar />
      </div>
    );
  },
);
InputField.displayName = 'InputField';

export const CalenderInput = ({
  startDate,
  dateFormat,
  className,
  showMonthYearPicker,
  datepickerWidth,
  minDate,
  maxDate,
  onChangeHandler,
}: CalenderInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onChangeInput = (date) => {
    setIsOpen(!isOpen);
    onChangeHandler(date);
  };

  return (
    <>
      <DatePicker
        onChange={(date) => onChangeInput(date)}
        dateFormat={dateFormat}
        selected={startDate}
        showMonthYearPicker={showMonthYearPicker}
        className={classNames(className)}
        calendarClassName={classNames('crt-datepicker', className)}
        customInput={<InputField datepickerWidth={datepickerWidth} />}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  );

  /* return (
    <>
      <div className="calender-input">
        <div
          className="d-flex justify-content-between form-control"
          onClick={() => setIsOpen(!isOpen)}
          style={{ width: datepickerWidth }}
        >
          <div className="align-self-center " onBlur={() => setIsOpen(!isOpen)}>
            {moment(startDate).format('MMM-yyyy')}
          </div>
          <Calendar className="align-self-center" />
        </div>
        {isOpen && (
          <div className="">
            <DatePicker
              selected={startDate}
              inline
              onChange={(date) => onChangeInput(date)}
              dateFormat={dateFormat}
              showMonthYearPicker={showMonthYearPicker}
              className={classNames(className)}
              calendarClassName="crt-datepicker"
            />
          </div>
        )}
      </div>
    </>
  ); */
};
