import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { CornerDownRight, RefreshCw } from 'react-feather';
import { getRoaringCompanyInfo } from '../../../../api/service/superAdmin/company-clients/service';
import PagesHeader from '../../../../components/base/PagesHeader';
import { CompanyClientSaveMode, FormProps } from '../types';
import moment from 'moment';
import classNames from 'classnames';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../util/form';
import { OrganizationNumber } from '../../../../components/form/inputs/OrganizationNumber';
import useLocale from '../../../../hooks/useLocale';
import { isNumeric, splitFullName } from '../../../../util/util';
import { DEFAULT_COMPANY_INFO } from '../../../../types/general';
import { toTitleCase } from '../../../../util';

export default function CompanyDetails({ context, date }: FormProps) {
  const { localize } = useLocale();
  const [isOrgNumberValid, setIsisOrgNumberValid] = useState<boolean>(true);
  const {
    companyDetails,
    isEditMode,
    formGroup,
    replaceAddress,
    replaceCompanyDetails,
    replaceAuthorizedSignatory,
    replaceCombinations,
    updateAddress,
    updateCompanyDetails,
    selectedCompanyClient,
    contractNumber,
  } = context;

  const handleSetCompany = (e) => {
    updateCompanyDetails(e.target.name, e.target.value);
    setIsisOrgNumberValid(true);
  };

  const handleAddressCompany = (e) => {
    updateAddress(e.target.name, e.target.value);
  };

  const fetchCompanyDetails = async () => {
    try {
      updateCompanyDetails('name', DEFAULT_COMPANY_INFO.name);
      replaceAddress(DEFAULT_COMPANY_INFO.address);
      replaceCombinations(DEFAULT_COMPANY_INFO.authorizedSignatory);

      const { name, address, authorizedSignatory } = await getRoaringCompanyInfo(
        companyDetails?.organizationNumber || '559136-6140',
      );

      replaceCompanyDetails({ ...companyDetails, name });
      replaceAddress({ ...(address || null), city: toTitleCase(address.city) });
      replaceCombinations(
        authorizedSignatory.map((item, index) => {
          const updatedSignatory = item.map((value) => {
            const { name, positions, ...signatoryDetails } = value;
            return {
              ...signatoryDetails,
              ...splitFullName(name),
              position: positions[0].roleName,
              email: '',
              fullName: name,
            };
          });
          if (index === 0) {
            return {
              authorizedSignatory: updatedSignatory,
              signee: true,
            };
          } else {
            return {
              authorizedSignatory: updatedSignatory,
              signee: false,
            };
          }
        }),
      );
      replaceAuthorizedSignatory(
        authorizedSignatory.map((i, index) => {
          if (index === 0) {
            return {
              _id: Math.random(),
              incharge: true,
              ...i,
            };
          } else {
            return { _id: Math.random(), incharge: false, ...i };
          }
        }),
      );
      setIsisOrgNumberValid(true);
    } catch (error) {
      setIsisOrgNumberValid(false);
    }
  };

  return (
    <div>
      <PagesHeader pageTitle="Company details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {isEditMode && companyDetails?.status !== CompanyClientSaveMode.DRAFT && (
          <div className="cr-form-row">
            <div className="contract-num-field">
              <Form.Label className="fs-name mb-0">{localize('contract_number')}</Form.Label>
              <Form.Control disabled defaultValue={contractNumber} />
            </div>
            <div className="employees-field">
              <Form.Label className="fs-name mb-0">{localize('employees')}</Form.Label>
              <Form.Control
                disabled
                defaultValue={isNumeric(selectedCompanyClient?.employees) ? selectedCompanyClient?.employees : '0'}
              />
            </div>
            <div className="creation-date-field">
              <Form.Label className="fs-name mb-0">{localize('creation_date')}</Form.Label>
              <Form.Control disabled defaultValue={moment(date?.createdAt).format('YYYY-MM-DD')} />
            </div>
            {isEditMode && companyDetails?.status !== CompanyClientSaveMode.DRAFT && (
              <>
                <div className="created-by-field">
                  <Form.Label className="fs-name mb-0">{localize('created_by')}</Form.Label>
                  <Form.Control disabled defaultValue={selectedCompanyClient?.createdBy?.fullName || ''} />
                </div>
                <div className="last-edit-field">
                  <Form.Label className="fs-name mb-0">{localize('last_edit')}</Form.Label>
                  <Form.Control disabled defaultValue={moment(date?.updatedAt).format('YYYY-MM-DD')} />
                </div>
              </>
            )}
          </div>
        )}

        <div className="cr-form-row">
          <OrganizationNumber
            disabled={isEditMode && companyDetails?.status !== CompanyClientSaveMode.DRAFT}
            onChangeHandler={handleSetCompany}
            field={formGroup.getFieldMeta('companyDetails.organizationNumber')}
            value={companyDetails.organizationNumber}
            isValid={isOrgNumberValid}
            lazy={true}
          />

          <div>
            <Button
              disabled={isEmpty(companyDetails?.organizationNumber)}
              onClick={
                isEmpty(companyDetails?.organizationNumber)
                  ? undefined
                  : () => {
                      fetchCompanyDetails();
                    }
              }
              className="app-btn-primary"
            >
              {isEditMode ? <RefreshCw className="icon-gap-right" /> : <CornerDownRight className="icon-gap-right" />}{' '}
              {isEditMode ? `${localize('refresh')}` : `${localize('fetch_data')}`}
            </Button>
          </div>
          <div className="company-name-field">
            <Form.Label className="fs-name mb-0 required">{localize('company_name')}</Form.Label>
            <Form.Control
              disabled
              onChange={handleSetCompany}
              name="name"
              value={formGroup.values.companyDetails.name}
              className={classNames(errorHighlighter(formGroup.getFieldMeta(`companyDetails.name`)))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('companyDetails.name')} />
          </div>
          {isEditMode && companyDetails?.status !== CompanyClientSaveMode.DRAFT && (
            <div className="status-field">
              <Form.Label className="fs-name mb-0 required">{localize('status')}</Form.Label>
              {companyDetails?.status === CompanyClientSaveMode.SETUP && (
                <Form.Control disabled name="status" defaultValue={localize('setup')} />
              )}
              {companyDetails?.status !== CompanyClientSaveMode.SETUP && (
                <Form.Select
                  name="status"
                  value={formGroup.values.companyDetails?.status}
                  onChange={handleSetCompany}
                  className={classNames(errorHighlighter(formGroup.getFieldMeta(`companyDetails.status`)))}
                >
                  <option value="">{localize('please_select')}</option>
                  <option value="Active">{localize('active')}</option>
                  <option value="InActive">{localize('inactive')}</option>
                </Form.Select>
              )}

              <ErrorFeedback field={formGroup.getFieldMeta('companyDetails.status')} />
            </div>
          )}
        </div>
        <div className="cr-form-row">
          <div className="address-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('address')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                name="street"
                value={formGroup.values.address.street}
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.street`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.street')} />
            </Form.Group>
          </div>
          <div className="post-code-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('postcode')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={formGroup.values.address.postcode}
                name="postcode"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.postcode`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.postcode')} />
            </Form.Group>
          </div>
          <div className="city-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('city')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={formGroup.values.address.city}
                name="city"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.city`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.city')} />
            </Form.Group>
          </div>
          <div className="country-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('country')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={formGroup.values.address.country}
                name="country"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.country`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.country')} />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
