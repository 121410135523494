import { Spinner } from 'react-bootstrap';
import BSTable from '../../../../components/table/BSTable';
import useContext from './context/useContext';
import { useColumns } from './useColumn';
import PagesHeader from '../../../../components/base/PagesHeader';
import CardComponent from '../../../../components/base/CardComponent';
import { Mode } from '../types';
import { useSuperAdminSettingsContext } from '../useContext';
import { getAccountingSalaryCompanyDetail } from '../../../../api/service/superAdmin/settings/accounting-salary-company';

export default function AccountingSalaryCompanyList() {
  const { accountingSalaryCompanies, isBusy, replaceAccountingSalaryCompany, replaceContactInformation } = useContext();
  const { setAccountingMode } = useSuperAdminSettingsContext();
  const columns = useColumns();
  const setAccountingSalaryCompanyDetail = async (id) => {
    const accountingSalaryCompanyDetail = await getAccountingSalaryCompanyDetail(id);

    replaceAccountingSalaryCompany({
      shortName: accountingSalaryCompanyDetail['shortName'],
      companyName: accountingSalaryCompanyDetail['companyName'],
      clientNumber: accountingSalaryCompanyDetail['clientNumber'],
      contractNumber: accountingSalaryCompanyDetail['contractNumber'],
      description: accountingSalaryCompanyDetail['description'],
      organizationNumber: accountingSalaryCompanyDetail['organizationNumber'],
      vatNumber: accountingSalaryCompanyDetail['vatNumber'],
      bankgiroNumber: accountingSalaryCompanyDetail['bankgiroNumber'],
      plusgiroNumber: accountingSalaryCompanyDetail['plusgiroNumber'],
      integrationType: accountingSalaryCompanyDetail['integrationType'],
      apiKey: accountingSalaryCompanyDetail['apiKey'],
      id: accountingSalaryCompanyDetail['id'],
    });
    replaceContactInformation(accountingSalaryCompanyDetail.contactInformation);
    setAccountingMode(Mode.Detail);
  };
  return (
    <>
      <CardComponent>
        <PagesHeader headingClass="fs-h2 font-black my-2" pageTitle={'Accounting and salary company'} />
        {isBusy ? (
          <Spinner />
        ) : (
          <BSTable
            data={accountingSalaryCompanies}
            columns={columns}
            onRowClick={(row) => {
              setAccountingSalaryCompanyDetail(row.id);
            }}
          />
        )}
      </CardComponent>
    </>
  );
}
