import { EmployeeActionLoading } from '../types';
import useEmployeePensionContext from '../useContext';

const useActionLoading = () => {
  const { actionLoading, setActionLoading } = useEmployeePensionContext();

  const toggle = (actionType: keyof EmployeeActionLoading, value) => {
    setActionLoading((prev) => {
      return {
        ...prev,
        [actionType]: value,
      };
    });
  };

  return {
    ...({
      ...actionLoading,
    } as EmployeeActionLoading),
    event: actionLoading,
    toggle,
  };
};

export default useActionLoading;
