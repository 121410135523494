import { Form } from 'react-bootstrap';
import { Info } from 'react-feather';
import { t } from 'i18next';

import MonthAndYearDropdown from '../../../../../section/MonthAndYearDropdown';
import useContext from '../../PensionPolicyContext/useContext';
import usePolicyMode from '../../hooks/usePolicyMode';
import usePensionSchedule from '../../../../company/Employees/hooks/usePensionSchedule';
import useEmployeePensionManagerEffectiveDate from './useEmployeePensionManagerEffectiveDate';
import { Calendar } from '../../../../../util/calendar';

type Props = {};

const PensionManagerEffectiveDate = (props: Props) => {
  const { externalPolicyRevision } = useContext();

  const {
    isModifyEffectiveDate,
    defaultStartEffectiveDate,
    updateEmployeePensionSettings,
    getEmployeePensionSettings,
  } = useEmployeePensionManagerEffectiveDate();

  const { isCompanyEmployeeReadOnlyMode, isCompanyEmployeeEditableMode, isCompanyEmployeeScheduledMode } =
    usePolicyMode();

  const { hasShownScheduledEffectiveDateNotifier } = usePensionSchedule();

  const hasShownScheduledNotifier = () => {
    return (
      isCompanyEmployeeScheduledMode() &&
      hasShownScheduledEffectiveDateNotifier(getEmployeePensionSettings('effectiveFrom'))
    );
  };

  return (
    <div className="employee-section mt-3">
      <Form.Group style={{ width: 220 }}>
        <Form.Label className="fs-name">{t('PENSION_POLICY.pension_manager_settings_effective_from')}</Form.Label>
        {isCompanyEmployeeEditableMode() && (
          <MonthAndYearDropdown
            startFrom={externalPolicyRevision?.effectiveFrom}
            value={getEmployeePensionSettings('effectiveFrom') || ''}
            onOptionSelect={(val) => {
              updateEmployeePensionSettings('effectiveFrom', val);
            }}
            disabled={!isModifyEffectiveDate}
            customOptions={Calendar.generateEffectiveDateOption(defaultStartEffectiveDate())}
          />
        )}
        {isCompanyEmployeeReadOnlyMode() && (
          <>
            {hasShownScheduledNotifier() && (
              <div className="p-2 mb-2 d-inline-flex align-items-center alert alert-info theme-text">
                <Info className="icon-gap-right" />
                <span className="fs-name">{t('scheduled')}</span>
              </div>
            )}
            <MonthAndYearDropdown
              startFrom={externalPolicyRevision?.effectiveFrom}
              value={getEmployeePensionSettings('effectiveFrom') || ''}
              disabled
            />
          </>
        )}
      </Form.Group>
    </div>
  );
};

export default PensionManagerEffectiveDate;
