import React from 'react';
import SignIn from '../../components/auth/SignIn';
import { UserRole } from '../../types/auth';

interface Props {
  pageRoleType?: UserRole;
}

const SignInPage = ({ pageRoleType = UserRole.COMPANY_ADMIN }: Props) => {
  return (
    <React.Fragment>
      <div className="middle-content pt-6">
        {pageRoleType === UserRole.EMPLOYEE && <h1 className="auth-title">Welcome to My Pages</h1>}
        <div className="auth-box">
          <SignIn pageRoleType={pageRoleType} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignInPage;
