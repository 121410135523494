import { Dropdown } from 'react-bootstrap';

import usFlag from '../../assets/img/flags/uk.svg';
import swedenFlag from '../../assets/img/flags/sweden.svg';
import { useTranslation } from 'react-i18next';
import { LocaleCode } from '../../types/locale';
import useLocale from '../../hooks/useLocale';
import { useState } from 'react';

interface languageOptionsType {
  [key: string]: {
    icon: string;
    name: string;
    value: LocaleCode;
  };
}

const languageOptions: languageOptionsType = {
  [LocaleCode.EN]: {
    icon: usFlag,
    name: 'English',
    value: LocaleCode.EN,
  },
  [LocaleCode.SE]: {
    icon: swedenFlag,
    name: 'Svenska',
    value: LocaleCode.SE,
  },
};

const NavbarLanguages = () => {
  const { i18n } = useTranslation();
  const { loadTranslation, localeSession } = useLocale();

  const [currentLang, setCurrentLang] = useState(localeSession);
  const language = localStorage.getItem('i18nextLng');
  const selectedLanguage = languageOptions[language ?? 'en'];

  const handleLanguageChange = (lng) => {
    const choosenLang = languageOptions[lng].value;
    i18n.changeLanguage(choosenLang);

    if (currentLang !== choosenLang) {
      loadTranslation(choosenLang);
    }
    setCurrentLang(choosenLang);
  };

  return (
    <Dropdown className="me-2 nav-item" align="end">
      <Dropdown.Toggle as="a" className="nav-link nav-flag">
        <img src={selectedLanguage.icon} alt={selectedLanguage.name} />
      </Dropdown.Toggle>
      <Dropdown.Menu className='dropdown-flag'>
        {Object.keys(languageOptions).map((language) => (
          <Dropdown.Item onClick={() => handleLanguageChange(language)} key={language}>
            <img src={languageOptions[language].icon} alt="English" width="20" className="align-middle" />
            <span className="align-middle">{languageOptions[language].name}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarLanguages;
