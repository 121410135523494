import React, { useEffect } from 'react';
import useContext from './context/useContext';
import { Mode } from '../types';
import { useSuperAdminSettingsContext } from '../useContext';
import { CreateAccountingSalaryCompanyModal } from './CreateAccountingSalaryCompany/CreateAccountingCompanyModal';
import EditAccountingSalaryCompany from './EditAccountingSalaryCompany';
import AccountingSalaryCompanyDetails from './AccountingSalaryCompanyDetails';
import AccountingSalaryCompanyList from './AccountingSalaryCompanyList';
import { getAccountingSalaryCompanies } from '../../../../api/service/superAdmin/settings/accounting-salary-company';

type Props = {
  showCreateModal: boolean;
  setIsAccountingSalaryCompanyCreated: (args) => void;
  toggleModal: () => void;
  updateAccountingSalaryCompany?: (input, value) => void;
  onDetailsAccountingSalaryCompany?: (value) => void;
};

export default function AccountingSalaryCompany({
  setIsAccountingSalaryCompanyCreated,
  showCreateModal,
  toggleModal,
  updateAccountingSalaryCompany,
  onDetailsAccountingSalaryCompany,
}: Props) {
  const { replaceAccountingSalaryCompanies, formGroup, accountingSalaryCompany, contactInformation } = useContext();
  const { accountingMode, setAccountingMode } = useSuperAdminSettingsContext();

  const fetchtAccountingSalaryCompanies = async () => {
    const _accountingSalaryCompanies = await getAccountingSalaryCompanies();
    replaceAccountingSalaryCompanies(_accountingSalaryCompanies);
  };
  const OnupdateAccountigSalaryCompany = () => {
    if (updateAccountingSalaryCompany)
      updateAccountingSalaryCompany(formGroup, { ...accountingSalaryCompany, contactInformation });
  };
  const detailsAccountigSalaryCompany = () => {
    if (onDetailsAccountingSalaryCompany)
      onDetailsAccountingSalaryCompany({ ...accountingSalaryCompany, contactInformation });
  };
  useEffect(() => {
    if (accountingMode === Mode.List) {
      fetchtAccountingSalaryCompanies();
      formGroup.resetForm();
    }
  }, [accountingMode]);
  useEffect(() => {
    if (showCreateModal) {
      setAccountingMode(Mode.Add);
    } else {
      setAccountingMode(Mode.List);
    }
  }, [showCreateModal]);

  return (
    <>
      {accountingMode === Mode.List && <AccountingSalaryCompanyList />}
      {accountingMode === Mode.Add && showCreateModal && (
        <CreateAccountingSalaryCompanyModal
          setIsAccountingSalaryCompanyCreated={setIsAccountingSalaryCompanyCreated}
          toggleModal={toggleModal}
          showCreateModal={showCreateModal}
        />
      )}

      {accountingMode === Mode.Edit && (
        <EditAccountingSalaryCompany OnupdateAccountingSalaryCompany={OnupdateAccountigSalaryCompany} />
      )}
      {accountingMode === Mode.Detail && (
        <AccountingSalaryCompanyDetails onDetailsAccountingSalaryCompany={detailsAccountigSalaryCompany} />
      )}
    </>
  );
}
