import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { Spinner } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-dropzone-uploader/dist/styles.css';
import 'react-month-picker/scss/month-picker.scss';
import 'react-month-picker/css/month-picker.css';
import './i18n';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const Loader = () => {
  return (
    <div className="crt-app-loader d-flex justify-content-center align-items-center h-100">
      <Spinner />
      <span className="theme-text loader-text">Loading...</span>
    </div>
  );
};

root.render(
  <BrowserRouter>
    <AuthProvider>
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    </AuthProvider>
  </BrowserRouter>,
);
