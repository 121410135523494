import { Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';
import { Consultants } from '../../Consultant/types';

export interface LooseObject {
  [key: string]: any;
}

export type DateType = {
  createdAt: string;
  updatedAt: string;
};

export type CompanyDetails = {
  organizationNumber: string;
  name: string;
  status: string;
  contractNumber: string;
  resellerAgreement: string;
  lastEdit?: string;
  updatedAt?: string;
};

export type AddressDetails = {
  city: string;
  country: string;
  postcode: string;
  street: string;
};

export type Position = {
  roleName: string;
  roleCode: number;
};

export type AuthorizedSignatory = {
  _id?: string;
  id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  incharge?: boolean;
  positions?: Position[];
  position?: string;
};

export interface AuthorizedSignatoryGroup {
  authorizedSignatory: AuthorizedSignatory[];
  signee: boolean;
}

export type Consultant = {
  _id?: string;
  id?: string;
  fullName: string;
  firstName?: string;
  lastName?: string;
  email: string;
  short?: string;
  position: string;
  personalNumber: string;
};

export type Supervisors = {
  id?: number;
  email: string;
  fullName: string;
  firstName: string;
  lastName: string;
  personalNumber: string;
};

export type CompanyClientForm = {
  companyDetails: CompanyDetails;
  address: AddressDetails;
  contractEffectiveFrom: string;
  contractEmail: string;
  invoiceCostCenter: string;
  // authorizedSignatory: AuthorizedSignatory[];
  combinations: AuthorizedSignatoryGroup[];

  // admins: AdminDetails[];
  // consultants: Consultants[];
  // consultant: Consultants;
  // salaryContactPeople: ContactPeople;
  // accountingContactPeople: ContactPeople;
  // collectiveAgreements: CollectiveAgreementType[];
  // invitationEmail: string;
};

export type CompanyClientFormGroup = FormikProps<CompanyClientForm>;

export interface ICompanyClientContext {
  companyDetails: CompanyDetails;
  setCompanyDetails: Dispatch<SetStateAction<CompanyDetails>>;

  addressDetails: AddressDetails;
  setAddressDetails: Dispatch<SetStateAction<AddressDetails>>;

  // adminDetails: Array<AdminDetails>;
  // setAdminDetails: Dispatch<SetStateAction<Array<AdminDetails>>>;

  signatoryDetails: Array<AuthorizedSignatory>;
  setSignatoryDetails: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  accessConsultants: Array<Consultants>;
  setAccessConsultants: Dispatch<SetStateAction<Array<Consultants>>>;

  // salaryContactPeople: ContactPeople;
  // setSalaryContactPeople: Dispatch<SetStateAction<ContactPeople>>;

  // accountingContactPeople: ContactPeople;
  // setAccountingContactPeople: Dispatch<SetStateAction<ContactPeople>>;

  contractEffectiveFrom: string;
  setContractEffectiveFrom: Dispatch<SetStateAction<string>>;

  firmDetails: any;
  setFirmDetails: Dispatch<SetStateAction<any>>;

  invoiceCostCenter: string;
  setInvoiceCostCenter: Dispatch<SetStateAction<string>>;

  contractEmail: string;
  setContractEmail: Dispatch<SetStateAction<string>>;

  combinations: Array<AuthorizedSignatoryGroup>;
  setCombinations: Dispatch<SetStateAction<Array<AuthorizedSignatoryGroup>>>;

  deletedAdmins: Array<string>;
  setDeletedAdmins: Dispatch<SetStateAction<Array<string>>>;

  deletedConsultants: Array<string>;
  setDeletedConsultants: Dispatch<SetStateAction<Array<string>>>;

  signatoryAdmins: Array<AuthorizedSignatory>;
  setSignatoryAdmins: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  allowCorrectoRemoteAccess: boolean;
  setAllowCorrectoRemoteAccess: Dispatch<SetStateAction<boolean>>;

  allowAdvisorRemoteAccess: boolean;
  setAllowAdvisorRemoteAccess: Dispatch<SetStateAction<boolean>>;

  isEditMode: boolean;

  formGroup: CompanyClientFormGroup;

  // replaceAdminDetails: (value: any) => void;
  replaceAddress: (value: any) => void;
  replaceAuthorizedSignatory: (value: any) => void;
  replaceCompanyDetails: (value: any) => void;
  replaceAccessConsultants: (value: any) => void;
  // replaceAccountingContactPeople: (value: any) => void,
  // replaceSalaryContactPeople: (value: any) => void,
  // updateAdminDetails: (inputName: any, inputValue: any) => void,
  updateAddress: (inputName: any, inputValue: any) => void;
  updateAuthorizedSignatory: (inputName: any, inputValue: any) => void;
  updateCompanyDetails: (inputName: any, inputValue: any) => void;
  updateAccessConsultants: (inputName: any, inputValue: any) => void;
  updateAccessConsultant: (inputName: any, inputValue: any) => void;
  // updateAccountingContactPeople: (inputName: any, inputValue: any) => void,
  // updateSalaryContactPeople: (inputName: any, inputValue: any) => void,
  updateContractEffectiveInput: (inputValue: any) => void;
  updateInvoicingCostCenter: (inputValue: any) => void;
  updateInvoiceEmailAddress: (inputValue: any) => void;
  patchFirmDetails: (inputValue: any) => void;
  replaceCombinations: (value: any) => void;
  updateCombinations: (inputName: any, inputValue: any) => void;
  appendDeletedAdmin: (value: any) => void;
  appendDeletedConsultant: (value: any) => void;
  replaceSignatoryAdmins: (value: any) => void;
  replaceAccess: (correctoAccess: boolean, advisorAccess: boolean) => void;
}

export type FormProps = {
  context: ICompanyClientContext;
  date?: DateType;
  formGroup: CompanyClientFormGroup;
};

export enum AccountingFirmSaveMode {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SETUP = 'Setup',
}

export enum ResellerAgreementMode {
  CERTIFIED_PROVIDER = 'Certified Provider',
}
