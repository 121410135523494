import { createContext, useState } from 'react';
import { useFormik } from 'formik';
import {
  IAccountingSalaryCompanyContext,
  TypeContactInformation,
  TypeAccountingSalaryCompany,
  AccountingSalaryCompanyFormGroup,
} from '../../types';
import { FORM_INITIAL_VALUES, INITIAL_CONTACT_INFORMATION, INITIAL_ACCOUNTING_SALARY_COMPANY } from '../constants';
import { formSchema } from './schema';

type Props = {
  children: JSX.Element;
};

export const AccountingSalaryCompanyContext = createContext<IAccountingSalaryCompanyContext>({
  accountingSalaryCompany: INITIAL_ACCOUNTING_SALARY_COMPANY,
  accountingSalaryCompanies: [],
  contactInformation: INITIAL_CONTACT_INFORMATION,
  isBusy: false,
  formGroup: {} as AccountingSalaryCompanyFormGroup,
  setContactInformation: () => {},
  replaceAccountingSalaryCompanies: () => {},
  updateContactInformation: () => {},
  replaceAccountingSalaryCompany: () => {},
  replaceContactInformation: () => {},
  updateAccountingSalaryCompany: () => {},
  setAccountingSalaryCompany: () => {},
});

export const AccountingSalaryCompanyProvider = ({ children }: Props) => {
  const [accountingSalaryCompany, setAccountingSalaryCompany] = useState<TypeAccountingSalaryCompany>(
    INITIAL_ACCOUNTING_SALARY_COMPANY,
  );
  const [accountingSalaryCompanies, setAccountingSalaryCompanies] = useState<Array<TypeAccountingSalaryCompany>>([]);
  const [contactInformation, setContactInformation] = useState<TypeContactInformation>(INITIAL_CONTACT_INFORMATION);

  const [isBusy /*setIsBusy*/] = useState(false);

  const formGroup = useFormik({
    initialValues: FORM_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const replaceAccountingSalaryCompanies = (value) => {
    setAccountingSalaryCompanies(value);
    formGroup.setFieldValue('accountingSalaryCompanies', value);
  };
  const replaceAccountingSalaryCompany = (value) => {
    setAccountingSalaryCompany(value);
    formGroup.setFieldValue('accountingSalaryCompany', value);
    formGroup.setFieldTouched('accountingSalaryCompany');
  };

  const updateContactInformation = (inputName, inputValue) => {
    setContactInformation((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`contactInformation.${inputName}`, inputValue);
  };
  const updateAccountingSalaryCompany = (inputName, inputValue) => {
    setAccountingSalaryCompany((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`accountingSalaryCompany.${inputName}`, inputValue);
  };
  const replaceContactInformation = (value) => {
    setContactInformation(value);
    formGroup.setFieldValue('contactInformation', value);
    formGroup.setFieldTouched('contactInformation');
  };
  const context: IAccountingSalaryCompanyContext = {
    accountingSalaryCompany,
    setAccountingSalaryCompany,
    accountingSalaryCompanies,
    contactInformation,
    setContactInformation,
    isBusy,
    formGroup: formGroup as any,
    replaceAccountingSalaryCompanies,
    updateAccountingSalaryCompany,
    updateContactInformation,
    replaceAccountingSalaryCompany,
    replaceContactInformation,
  };
  return <AccountingSalaryCompanyContext.Provider value={context}>{children}</AccountingSalaryCompanyContext.Provider>;
};
