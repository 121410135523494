import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Save, Send } from 'react-feather';
import { createPersonalClient } from '../../../../api/service/superAdmin/personal-clients/service';
import { useCreatePersonalClientContext } from '../context/useContext';
import { LooseObject, PersonalDetailsFormGroup } from '../types';
import useLocale from '../../../../hooks/useLocale';

type Props = {
  onClientCreated: () => boolean;
  toggleModal: () => void;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
  setIsClientCreated: Dispatch<SetStateAction<boolean>>;
  formGroup: PersonalDetailsFormGroup;
};

const STRING_TYPE = {
  DRAFT: 'DRAFT',
  SUBMIT: 'SUBMIT',
};

export default function CreatePersonalClientFooter({ setIsClientCreated, toggleModal, setErrors, formGroup }: Props) {
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const { personalDetails, getFilteredRequestParams } = useCreatePersonalClientContext();
  const { localize } = useLocale();

  const handleSubmit = async (type) => {
    let params = {};
    if (type === STRING_TYPE.DRAFT) {
      setIsBusyDrafting(true);
      params = {
        draft: true,
        sendEmails: false,
      };
    } else {
      setIsBusy(true);
      params = {
        draft: false,
        sendEmails: true,
      };
    }

    try {
      formGroup.handleSubmit();
      setErrors(formGroup.errors);

      if (!formGroup.isValid) {
        setIsBusy(false);
        setIsBusyDrafting(false);
        return false;
      }

      const payload = getFilteredRequestParams(personalDetails);

      await createPersonalClient(payload, params);
      setIsClientCreated(true);
      toggleModal();
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsBusy(false);
      setIsBusyDrafting(false);
    }
  };

  return (
    <>
      <Button className="app-btn-secondary">{localize('cancel')}</Button>
      <div className='d-flex flex-wrap-row gap-2'>
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary"
          onClick={() => {
            handleSubmit(STRING_TYPE.DRAFT);
          }}
        >
          {isBusyDrafting ? <Spinner className="icon-gap-right" size="sm" /> : <Save className="icon-gap-right" />}
          {`${localize('save')} ${localize('as')} ${localize('draft')}`}
        </Button>
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary"
          onClick={() => {
            handleSubmit(STRING_TYPE.SUBMIT);
          }}
        >
          {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <Send className="icon-gap-right" />}
          {`${localize('save')} ${localize('personal_clients')} ${localize('and_send_invites')}`}
        </Button>
      </div>
    </>
  );
}
