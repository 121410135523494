import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

type Props = {
  children: JSX.Element | null;
};

function ErrorFallback({ error }: { error: any }) {
  return (
    <h1>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </h1>
  );
}

export const ErrorBoundary = ({ children }: Props) => {
  const handleError = (error, info) => {
    console.error('Error caught by error boundary:', error);
  };

  return (
    <ReactErrorBoundary fallbackRender={({ error }) => <ErrorFallback error={error} />} onError={handleError}>
      <>{children}</>
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
