import { memo } from 'react';
import { DownloadCloud, ExternalLink, Icon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import useLocalizationResolver from '../../../hooks/useLocalizationResolver';

type Transaction = {
  title: string;
  date: string;
  percent: number;
  icon: Icon;
};

const transactions: Transaction[] = [
  {
    title: 'Pension data output',
    date: 'Oct 3',
    percent: 100,
    icon: ExternalLink,
  },
  {
    title: 'Salary data import',
    date: 'Oct 28',
    percent: 100,
    icon: DownloadCloud,
  },
];

export const LastTransactionSidebar = memo(() => {
  const { t } = useTranslation();
  const { getLang } = useLocalizationResolver();
  return (
    <>
      {transactions.map((transaction) => (
        <div key={transaction.title} className="mt-2 transaction-box">
          <div className="box-prefix">
            <transaction.icon className="image-filter" stroke="#2F363D" />
          </div>
          <div className="theme-text box-content">
            {`${getLang(transaction.title)} ${t('done')?.toLowerCase?.()} ${transaction.date}`}
          </div>
          <div className="percent-box">{transaction.percent}%</div>
        </div>
      ))}
    </>
  );
});

LastTransactionSidebar.displayName = 'LastTransactionSidebar';
