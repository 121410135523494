import React, { useRef, useState } from 'react';
import { Tooltip, Overlay } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';

type LabelValue = {
  type: 'LABEL_VALUE';
  label: string;
  value: string | number;
  tooltip?: any;
  className?: string;
};

type IconValue = {
  type: 'ICON_VALUE';
  icon?: boolean;
  text: string;
  id?: string;
  children?: React.ReactNode;
};

type SingleValue = {
  type: 'SINGLE_VALUE';
  text: string;
};

type Props = LabelValue | IconValue | SingleValue;

function ReportLine(props: Props) {
  const [show, setShow] = useState(false);
  const pRef = useRef(null);
  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  const content = () => {
    if (props.type === 'LABEL_VALUE') {
      const { label, value, tooltip, className } = props;
      return (
        <>
          <p
            ref={pRef}
            className={`font-weight-bold text-dark mb-0 ${className}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {label}
          </p>
          {tooltip && (
            <Overlay target={pRef.current} show={show} placement="right">
              {(props) => <Tooltip {...props}>{tooltip}</Tooltip>}
            </Overlay>
          )}
          <p className={`text-uppercase text-dark mb-0 ${className} `}>{value}</p>
        </>
      );
    } else if (props.type === 'ICON_VALUE') {
      const { text, icon, id, children } = props;
      return (
        <div className="report-span">
          {icon && <ChevronRight className="fs-5 text-dark icon-gap-right" />}{' '}
          <span
            className="fw-bold"
            onClick={() => {
              if (id) navigate(`/reports/${id}`);
            }}
          >
            {text}
          </span>
          {children && <p className="ms-4 mt-1 mb-0">{children}</p>}
        </div>
      );
    }
  };

  return <div className="d-flex justify-content-between my-1">{content()}</div>;
}

export default ReportLine;
