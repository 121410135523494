import { sortCaret } from '../../../../../components/table/formatters';
import { tableHeaderText } from '../../../../../components/table/TableHeaderFormatter';
import { FakeInputBox } from '../../../../../components/base/FakeInputBox';
import { ColumnDescription } from 'react-bootstrap-table-next';

export const useColumn = () => {
  const accessLevelColumn: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Company'),
      dataField: 'company.name',
      headerStyle: { width: 200 },
      sort: true,
      sortCaret,
      formatter: (cell) => <FakeInputBox align="start" value={cell} />,
    },
    {
      text: tableHeaderText('Org.number'),
      dataField: 'company.organizationNumber',
      headerStyle: { width: 118 },
      sort: true,
      sortCaret,
      formatter: (cell) => <FakeInputBox align="start" value={cell} />,
    },
    {
      text: tableHeaderText('email'),
      dataField: 'email',
      headerStyle: { width: 200 },
      sort: true,
      sortCaret,
      formatter: (cell) => <FakeInputBox align="start" value={cell} />,
    },
    {
      text: tableHeaderText('role(s)'),
      dataField: 'subRole',
      headerStyle: { width: 250 },
      sort: true,
      sortCaret,
      formatter: (_cell, row) => {
        // const role: string[] = [];
        // const subRole: string[] = [];
        return _cell;
        // _cell.forEach((value) => {
        //   if (!role.includes(value.role) && value.role !== null) {
        //     role.push(value.role);
        //   }
        //   if (!subRole.includes(value.subRole) && value.subRole !== null) {
        //     subRole.push(value.subRole);
        //   }
        // });
        // return [...role, ...subRole].join(' , ');
      },
    },
  ];

  return { accessLevelColumn };
};
