import { Finance } from './types';

export const data: Finance[] = [
  {
    _id: '64240ce96e86958a8dee0816',
    salaryPeriod: {
      from: '2021-03-01T00:00:00.000Z',
      to: '2021-03-31T00:00:00.000Z',
      paymentDate: '2021-05-25T00:00:00.000Z',
    },
    totalPayable: 0,
    preliminaryTax: -5180,
    grossSalary: 24657.5,
    adjustment: 0,
    taxableIcome: 24657.5,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Förskott, utbetalning, netto',
        code: 292,
        amount: -19477.5,
        salaryCodeData: {
          id: '63b3ffeef3a824335f941c7f',
          code: 292,
          name: 'Förskott, utbetalning, netto',
        },
      },
      {
        title: 'Månadslön, del av',
        code: 11,
        amount: 24657.5,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b65',
          code: 11,
          name: 'Månadslön, del av',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 24657.5,
    accumulatedPreliminaryTax: -5180,
    errorData: [],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.813Z',
    updatedAt: '2023-03-29T10:03:21.813Z',
  },
  {
    _id: '64240ce96e86958a8dee0817',
    salaryPeriod: {
      from: '2021-04-01T00:00:00.000Z',
      to: '2021-04-30T00:00:00.000Z',
      paymentDate: '2021-04-23T00:00:00.000Z',
    },
    totalPayable: 0,
    preliminaryTax: -27158,
    grossSalary: 75000,
    adjustment: 0,
    taxableIcome: 75000,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Förskott, utbetalning, netto',
        code: 292,
        amount: -47842,
        salaryCodeData: {
          id: '63b3ffeef3a824335f941c7f',
          code: 292,
          name: 'Förskott, utbetalning, netto',
        },
      },
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 75000,
    accumulatedPreliminaryTax: -27158,
    errorData: [],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.813Z',
    updatedAt: '2023-03-29T10:03:21.813Z',
  },
  {
    _id: '64240ce96e86958a8dee0818',
    salaryPeriod: {
      from: '2021-05-01T00:00:00.000Z',
      to: '2021-05-31T00:00:00.000Z',
      paymentDate: '2021-05-25T00:00:00.000Z',
    },
    totalPayable: 40162,
    preliminaryTax: -27158,
    grossSalary: 75000,
    adjustment: 0,
    taxableIcome: 75000,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Förskott, utbetalning, netto',
        code: 292,
        amount: -7680.5,
        salaryCodeData: {
          id: '63b3ffeef3a824335f941c7f',
          code: 292,
          name: 'Förskott, utbetalning, netto',
        },
      },
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 150000,
    accumulatedPreliminaryTax: -54316,
    errorData: [],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.813Z',
    updatedAt: '2023-03-29T10:03:21.813Z',
  },
  {
    _id: '64240ce96e86958a8dee0819',
    salaryPeriod: {
      from: '2021-06-01T00:00:00.000Z',
      to: '2021-06-30T00:00:00.000Z',
      paymentDate: '2021-06-24T00:00:00.000Z',
    },
    totalPayable: 47842,
    preliminaryTax: -27158,
    grossSalary: 75000,
    adjustment: 0,
    taxableIcome: 755000,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 249657.5,
    accumulatedPreliminaryTax: -86654,
    errorData: [],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.813Z',
    updatedAt: '2023-03-29T10:03:21.813Z',
  },
  {
    _id: '64240ce96e86958a8dee081a',
    salaryPeriod: {
      from: '2021-07-01T00:00:00.000Z',
      to: '2021-07-31T00:00:00.000Z',
      paymentDate: '2021-07-23T00:00:00.000Z',
    },
    totalPayable: 47842,
    preliminaryTax: -27158,
    grossSalary: 75000,
    adjustment: 0,
    taxableIcome: 75000,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 324657.5,
    accumulatedPreliminaryTax: -113812,
    errorData: [],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.813Z',
    updatedAt: '2023-03-29T10:03:21.813Z',
  },
  {
    _id: '64240ce96e86958a8dee081b',
    salaryPeriod: {
      from: '2021-08-01T00:00:00.000Z',
      to: '2021-08-31T00:00:00.000Z',
      paymentDate: '2021-08-25T00:00:00.000Z',
    },
    totalPayable: 47949,
    preliminaryTax: -27374,
    grossSalary: 75322.5,
    adjustment: 0,
    taxableIcome: 75322.5,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
      {
        title: 'Semester årets månadsavlönad',
        code: 91,
        amount: 3772.5,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bb5',
          code: 91,
          name: 'Semester årets månadsavlönad',
        },
      },
      {
        title: 'Semester förskott månadsavlönad',
        code: 108,
        amount: 13800,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bc6',
          code: 108,
          name: 'Semester förskott månadsavlönad',
        },
      },
      {
        title: 'Semester förskott månadsavlönad',
        code: 108,
        amount: 177250,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bc6',
          code: 108,
          name: 'Semester förskott månadsavlönad',
        },
      },
      {
        title: 'Semesterlöneavdrag månadslön',
        code: 94,
        amount: -3450,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bb8',
          code: 94,
          name: 'Semesterlöneavdrag månadslön',
        },
      },
      {
        title: 'Semesterlöneavdrag månadslön',
        code: 94,
        amount: -13800,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bb8',
          code: 94,
          name: 'Semesterlöneavdrag månadslön',
        },
      },
      {
        title: 'Semesterlöneavdrag månadslön',
        code: 94,
        amount: -17250,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bb8',
          code: 94,
          name: 'Semesterlöneavdrag månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 399980,
    accumulatedPreliminaryTax: -141186,
    errorData: [
      {
        message: 'Total does not match with transactions',
        code: 101,
      },
    ],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.813Z',
    updatedAt: '2023-03-29T10:03:21.813Z',
  },
  {
    _id: '64240ce96e86958a8dee081c',
    salaryPeriod: {
      from: '2021-09-01T00:00:00.000Z',
      to: '2021-09-30T00:00:00.000Z',
      paymentDate: '2021-09-24T00:00:00.000Z',
    },
    totalPayable: 47842,
    preliminaryTax: 0,
    grossSalary: 0,
    adjustment: 0,
    taxableIcome: 75000,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
      {
        title: 'Semester förskott månadsavlönad',
        code: 108,
        amount: 17250,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bc6',
          code: 108,
          name: 'Semester förskott månadsavlönad',
        },
      },
      {
        title: 'Semester förskott månadsavlönad',
        code: 108,
        amount: 3450,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bc6',
          code: 108,
          name: 'Semester förskott månadsavlönad',
        },
      },
      {
        title: 'Semesterlöneavdrag månadslön',
        code: 94,
        amount: -17250,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bb8',
          code: 94,
          name: 'Semesterlöneavdrag månadslön',
        },
      },
      {
        title: 'Semesterlöneavdrag månadslön',
        code: 94,
        amount: -33,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bb8',
          code: 94,
          name: 'Semesterlöneavdrag månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 474980,
    accumulatedPreliminaryTax: -168344,
    errorData: [
      {
        message: 'Total does not match with transactions',
        code: 101,
      },
      {
        message: 'Gross Salary Not Fetched',
        code: 101,
      },
    ],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.813Z',
    updatedAt: '2023-03-29T10:03:21.813Z',
  },
  {
    _id: '64240ce96e86958a8dee081d',
    salaryPeriod: {
      from: '2021-10-01T00:00:00.000Z',
      to: '2021-10-31T00:00:00.000Z',
      paymentDate: '2021-10-25T00:00:00.000Z',
    },
    totalPayable: 47842,
    preliminaryTax: -27158,
    grossSalary: 75000,
    adjustment: 0,
    taxableIcome: 75000,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
      {
        title: 'Semester förskott månadsavlönad',
        code: 108,
        amount: 17250,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bc6',
          code: 108,
          name: 'Semester förskott månadsavlönad',
        },
      },
      {
        title: 'Semester förskott månadsavlönad',
        code: 108,
        amount: 10350,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bc6',
          code: 108,
          name: 'Semester förskott månadsavlönad',
        },
      },
      {
        title: 'Semesterlöneavdrag månadslön',
        code: 94,
        amount: -17250,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bb8',
          code: 94,
          name: 'Semesterlöneavdrag månadslön',
        },
      },
      {
        title: 'Semesterlöneavdrag månadslön',
        code: 94,
        amount: -10350,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941bb8',
          code: 94,
          name: 'Semesterlöneavdrag månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 549980,
    accumulatedPreliminaryTax: -195502,
    errorData: [],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.814Z',
    updatedAt: '2023-03-29T10:03:21.814Z',
  },
  {
    _id: '64240ce96e86958a8dee081e',
    salaryPeriod: {
      from: '2021-11-01T00:00:00.000Z',
      to: '2021-11-30T00:00:00.000Z',
      paymentDate: '2021-11-25T00:00:00.000Z',
    },
    totalPayable: 47842,
    preliminaryTax: -27158,
    grossSalary: 75000,
    adjustment: 0,
    taxableIcome: 75000,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 624980,
    accumulatedPreliminaryTax: -222660,
    errorData: [],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.814Z',
    updatedAt: '2023-03-29T10:03:21.814Z',
  },
  {
    _id: '64240ce96e86958a8dee081f',
    salaryPeriod: {
      from: '2021-12-01T00:00:00.000Z',
      to: '2021-12-31T00:00:00.000Z',
      paymentDate: '2021-12-23T00:00:00.000Z',
    },
    totalPayable: 47842,
    preliminaryTax: -27158,
    grossSalary: 75000,
    adjustment: 0,
    taxableIcome: 75000,
    employeeId: '6405ff0516fa1be8978bed4a',
    companyId: '63aaeab62789d9cc096a4748',
    transactions: [
      {
        title: 'Månadslön',
        code: 9,
        amount: 75000,
        salaryCodeData: {
          id: '63b3ffedf3a824335f941b63',
          code: 9,
          name: 'Månadslön',
        },
      },
    ],
    importInformation: {
      importedFrom: 'PDF',
      id: '6405ff0516fa1be8978bed4a',
      idKey: 'SalaryRow',
    },
    accumulatedGrossSalary: 699980,
    accumulatedPreliminaryTax: -249818,
    errorData: [],
    __v: 0,
    createdAt: '2023-03-29T10:03:21.814Z',
    updatedAt: '2023-03-29T10:03:21.814Z',
  },
];

export const INITIAL_DATA = {
  effectiveFrom: '',
  fixedOffset: 0,
};

export const pensionCompaniesData = {
  effectiveFrom: '2023-04-01T14:09:04.371Z',
  fixedOffset: 2220,
  history: [
    {
      changeValue: 2220,
      effectiveOn: '2022-10-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2022-11-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2022-12-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2023-01-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2023-02-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2023-03-01T14:09:04.371Z',
    },
  ],
};
export const pensionCostData = {
  effectiveFrom: '2023-04-01T14:09:04.371Z',
  fixedOffset: 2220,
  history: [
    {
      changeValue: 2220,
      effectiveOn: '2022-10-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2022-11-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2022-12-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2023-01-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2023-02-01T14:09:04.371Z',
    },
    {
      changeValue: 2220,
      effectiveOn: '2023-03-01T14:09:04.371Z',
    },
  ],
};

export const PENSIONS = {
  headers: [
    { title: 'Artur', name: 'artur' },
    { title: 'Greg', name: 'greg' },
    { title: 'Marta', name: 'marta' },
    { title: 'Zeb', name: 'zeb' },
  ],
  reports: [
    {
      year: 2021,
      dataset: [
        {
          name: 'artur',
          months: [
            {
              monthIndex: 1,
              value: 400,
            },
            {
              monthIndex: 2,
              value: 600,
            },
            {
              monthIndex: 3,
              value: 300,
            },
            {
              monthIndex: 4,
              value: 320,
            },
            {
              monthIndex: 5,
              value: 360,
            },
            {
              monthIndex: 6,
              value: 350,
            },
            {
              monthIndex: 7,
              value: 400,
            },
            {
              monthIndex: 8,
              value: 220,
            },
            {
              monthIndex: 9,
              value: 230,
            },
            {
              monthIndex: 10,
              value: 240,
            },
            {
              monthIndex: 11,
              value: 250,
            },
            {
              monthIndex: 12,
              value: 260,
            },
          ],
        },
        {
          name: 'greg',
          months: [
            {
              monthIndex: 1,
              value: 800,
            },
            {
              monthIndex: 2,
              value: 600,
            },
            {
              monthIndex: 3,
              value: 500,
            },
            {
              monthIndex: 4,
              value: 120,
            },
            {
              monthIndex: 5,
              value: 140,
            },
            {
              monthIndex: 6,
              value: 150,
            },
            {
              monthIndex: 7,
              value: 180,
            },
            {
              monthIndex: 8,
              value: 210,
            },
            {
              monthIndex: 9,
              value: 245,
            },
            {
              monthIndex: 10,
              value: 235,
            },
            {
              monthIndex: 11,
              value: 270,
            },
            {
              monthIndex: 12,
              value: 280,
            },
          ],
        },
        {
          name: 'marta',
          months: [
            {
              monthIndex: 1,
              value: 500,
            },
            {
              monthIndex: 2,
              value: 600,
            },
            {
              monthIndex: 3,
              value: 700,
            },
            {
              monthIndex: 4,
              value: 200,
            },
            {
              monthIndex: 5,
              value: 900,
            },
            {
              monthIndex: 6,
              value: 500,
            },
            {
              monthIndex: 7,
              value: 250,
            },
            {
              monthIndex: 8,
              value: 110,
            },
            {
              monthIndex: 9,
              value: 115,
            },
            {
              monthIndex: 10,
              value: 140,
            },
            {
              monthIndex: 11,
              value: 180,
            },
            {
              monthIndex: 12,
              value: 160,
            },
          ],
        },
        {
          name: 'zeb',
          months: [
            {
              monthIndex: 1,
              value: 400,
            },
            {
              monthIndex: 2,
              value: 700,
            },
            {
              monthIndex: 3,
              value: 200,
            },
            {
              monthIndex: 4,
              value: 850,
            },
            {
              monthIndex: 5,
              value: 950,
            },
            {
              monthIndex: 6,
              value: 200,
            },
            {
              monthIndex: 7,
              value: 300,
            },
            {
              monthIndex: 8,
              value: 100,
            },
            {
              monthIndex: 9,
              value: 200,
            },
            {
              monthIndex: 10,
              value: 150,
            },
            {
              monthIndex: 11,
              value: 500,
            },
            {
              monthIndex: 12,
              value: 300,
            },
          ],
        },
      ],
    },
    {
      year: 2022,
      dataset: [
        {
          name: 'artur',
          months: [
            {
              monthIndex: 1,
              value: 500,
            },
            {
              monthIndex: 2,
              value: 300,
            },
            {
              monthIndex: 3,
              value: 200,
            },
            {
              monthIndex: 4,
              value: 120,
            },
            {
              monthIndex: 5,
              value: 660,
            },
            {
              monthIndex: 6,
              value: 750,
            },
            {
              monthIndex: 7,
              value: 900,
            },
            {
              monthIndex: 8,
              value: 520,
            },
            {
              monthIndex: 9,
              value: 630,
            },
            {
              monthIndex: 10,
              value: 140,
            },
            {
              monthIndex: 11,
              value: 150,
            },
            {
              monthIndex: 12,
              value: 360,
            },
          ],
        },
        {
          name: 'greg',
          months: [
            {
              monthIndex: 1,
              value: 800,
            },
            {
              monthIndex: 2,
              value: 600,
            },
            {
              monthIndex: 3,
              value: 500,
            },
            {
              monthIndex: 4,
              value: 120,
            },
            {
              monthIndex: 5,
              value: 140,
            },
            {
              monthIndex: 6,
              value: 150,
            },
            {
              monthIndex: 7,
              value: 180,
            },
            {
              monthIndex: 8,
              value: 210,
            },
            {
              monthIndex: 9,
              value: 245,
            },
            {
              monthIndex: 10,
              value: 235,
            },
            {
              monthIndex: 11,
              value: 270,
            },
            {
              monthIndex: 12,
              value: 280,
            },
          ],
        },
        {
          name: 'marta',
          months: [
            {
              monthIndex: 1,
              value: 500,
            },
            {
              monthIndex: 2,
              value: 600,
            },
            {
              monthIndex: 3,
              value: 700,
            },
            {
              monthIndex: 4,
              value: 200,
            },
            {
              monthIndex: 5,
              value: 900,
            },
            {
              monthIndex: 6,
              value: 500,
            },
            {
              monthIndex: 7,
              value: 250,
            },
            {
              monthIndex: 8,
              value: 110,
            },
            {
              monthIndex: 9,
              value: 115,
            },
            {
              monthIndex: 10,
              value: 140,
            },
            {
              monthIndex: 11,
              value: 180,
            },
            {
              monthIndex: 12,
              value: 160,
            },
          ],
        },
        {
          name: 'zeb',
          months: [
            {
              monthIndex: 1,
              value: 400,
            },
            {
              monthIndex: 2,
              value: 700,
            },
            {
              monthIndex: 3,
              value: 200,
            },
            {
              monthIndex: 4,
              value: 850,
            },
            {
              monthIndex: 5,
              value: 950,
            },
            {
              monthIndex: 6,
              value: 200,
            },
            {
              monthIndex: 7,
              value: 300,
            },
            {
              monthIndex: 8,
              value: 100,
            },
            {
              monthIndex: 9,
              value: 200,
            },
            {
              monthIndex: 10,
              value: 150,
            },
            {
              monthIndex: 11,
              value: 500,
            },
            {
              monthIndex: 12,
              value: 300,
            },
          ],
        },
      ],
    },
  ],
};

export const PENSION_COST = {
  headers: [
    { title: 'Artur', name: 'artur' },
    { title: 'Greg', name: 'greg' },
    { title: 'Marta', name: 'marta' },
    { title: 'Zeb', name: 'zeb' },
  ],
  reports: [
    {
      year: 2022,
      dataset: [
        {
          name: 'artur',
          months: [
            {
              monthIndex: 1,
              value: 200,
            },
            {
              monthIndex: 2,
              value: 300,
            },
            {
              monthIndex: 3,
              value: 400,
            },
            {
              monthIndex: 4,
              value: 520,
            },
            {
              monthIndex: 5,
              value: 660,
            },
            {
              monthIndex: 6,
              value: 750,
            },
            {
              monthIndex: 7,
              value: 100,
            },
            {
              monthIndex: 8,
              value: 120,
            },
            {
              monthIndex: 9,
              value: 430,
            },
            {
              monthIndex: 10,
              value: 140,
            },
            {
              monthIndex: 11,
              value: 550,
            },
            {
              monthIndex: 12,
              value: 660,
            },
          ],
        },
        {
          name: 'greg',
          months: [
            {
              monthIndex: 1,
              value: 150,
            },
            {
              monthIndex: 2,
              value: 250,
            },
            {
              monthIndex: 3,
              value: 350,
            },
            {
              monthIndex: 4,
              value: 450,
            },
            {
              monthIndex: 5,
              value: 550,
            },
            {
              monthIndex: 6,
              value: 560,
            },
            {
              monthIndex: 7,
              value: 570,
            },
            {
              monthIndex: 8,
              value: 580,
            },
            {
              monthIndex: 9,
              value: 545,
            },
            {
              monthIndex: 10,
              value: 335,
            },
            {
              monthIndex: 11,
              value: 770,
            },
            {
              monthIndex: 12,
              value: 180,
            },
          ],
        },
        {
          name: 'marta',
          months: [
            {
              monthIndex: 1,
              value: 560,
            },
            {
              monthIndex: 2,
              value: 170,
            },
            {
              monthIndex: 3,
              value: 180,
            },
            {
              monthIndex: 4,
              value: 190,
            },
            {
              monthIndex: 5,
              value: 200,
            },
            {
              monthIndex: 6,
              value: 330,
            },
            {
              monthIndex: 7,
              value: 440,
            },
            {
              monthIndex: 8,
              value: 550,
            },
            {
              monthIndex: 9,
              value: 225,
            },
            {
              monthIndex: 10,
              value: 110,
            },
            {
              monthIndex: 11,
              value: 770,
            },
            {
              monthIndex: 12,
              value: 220,
            },
          ],
        },
        {
          name: 'zeb',
          months: [
            {
              monthIndex: 1,
              value: 1400,
            },
            {
              monthIndex: 2,
              value: 200,
            },
            {
              monthIndex: 3,
              value: 220,
            },
            {
              monthIndex: 4,
              value: 820,
            },
            {
              monthIndex: 5,
              value: 920,
            },
            {
              monthIndex: 6,
              value: 220,
            },
            {
              monthIndex: 7,
              value: 320,
            },
            {
              monthIndex: 8,
              value: 120,
            },
            {
              monthIndex: 9,
              value: 220,
            },
            {
              monthIndex: 10,
              value: 120,
            },
            {
              monthIndex: 11,
              value: 520,
            },
            {
              monthIndex: 12,
              value: 320,
            },
          ],
        },
      ],
    },
    {
      year: 2023,
      dataset: [
        {
          name: 'artur',
          months: [
            {
              monthIndex: 1,
              value: 500,
            },
            {
              monthIndex: 2,
              value: 300,
            },
            {
              monthIndex: 3,
              value: 200,
            },
            {
              monthIndex: 4,
              value: 120,
            },
            {
              monthIndex: 5,
              value: 660,
            },
            {
              monthIndex: 6,
              value: 750,
            },
            {
              monthIndex: 7,
              value: 900,
            },
            {
              monthIndex: 8,
              value: 520,
            },
            {
              monthIndex: 9,
              value: 630,
            },
            {
              monthIndex: 10,
              value: 140,
            },
            {
              monthIndex: 11,
              value: 150,
            },
            {
              monthIndex: 12,
              value: 360,
            },
          ],
        },
        {
          name: 'greg',
          months: [
            {
              monthIndex: 1,
              value: 800,
            },
            {
              monthIndex: 2,
              value: 600,
            },
            {
              monthIndex: 3,
              value: 500,
            },
            {
              monthIndex: 4,
              value: 120,
            },
            {
              monthIndex: 5,
              value: 140,
            },
            {
              monthIndex: 6,
              value: 150,
            },
            {
              monthIndex: 7,
              value: 180,
            },
            {
              monthIndex: 8,
              value: 210,
            },
            {
              monthIndex: 9,
              value: 245,
            },
            {
              monthIndex: 10,
              value: 235,
            },
            {
              monthIndex: 11,
              value: 270,
            },
            {
              monthIndex: 12,
              value: 280,
            },
          ],
        },
        {
          name: 'marta',
          months: [
            {
              monthIndex: 1,
              value: 500,
            },
            {
              monthIndex: 2,
              value: 600,
            },
            {
              monthIndex: 3,
              value: 700,
            },
            {
              monthIndex: 4,
              value: 200,
            },
            {
              monthIndex: 5,
              value: 900,
            },
            {
              monthIndex: 6,
              value: 500,
            },
            {
              monthIndex: 7,
              value: 250,
            },
            {
              monthIndex: 8,
              value: 110,
            },
            {
              monthIndex: 9,
              value: 115,
            },
            {
              monthIndex: 10,
              value: 140,
            },
            {
              monthIndex: 11,
              value: 180,
            },
            {
              monthIndex: 12,
              value: 160,
            },
          ],
        },
        {
          name: 'zeb',
          months: [
            {
              monthIndex: 1,
              value: 400,
            },
            {
              monthIndex: 2,
              value: 700,
            },
            {
              monthIndex: 3,
              value: 200,
            },
            {
              monthIndex: 4,
              value: 850,
            },
            {
              monthIndex: 5,
              value: 950,
            },
            {
              monthIndex: 6,
              value: 200,
            },
            {
              monthIndex: 7,
              value: 300,
            },
            {
              monthIndex: 8,
              value: 100,
            },
            {
              monthIndex: 9,
              value: 200,
            },
            {
              monthIndex: 10,
              value: 150,
            },
            {
              monthIndex: 11,
              value: 500,
            },
            {
              monthIndex: 12,
              value: 300,
            },
          ],
        },
      ],
    },
  ],
};
