import { useEffect, useState } from 'react';
import { ArticleFormField, SalarySlipFormGroupCtx, SalarySlipRecord } from '../types';

export const useSalaryCompensationTotal = (
  formGroup: SalarySlipFormGroupCtx,
  selectedDetails: SalarySlipRecord | null,
) => {
  const [originalInput, setOriginalInput] = useState({
    grossSalary: 0,
    tax: selectedDetails?.preliminaryTax || 0,
    excludedDeductions: selectedDetails?.taxFreeExpensesAndDeductions || 0,
    payout: 0,
  });
  const [modifiedInput, setModifiedInput] = useState({
    grossSalary: 0,
    tax: selectedDetails?.preliminaryTax || 0,
    excludedDeductions: selectedDetails?.taxFreeExpensesAndDeductions || 0,
    payout: 0,
  });

  useEffect(() => {
    if (selectedDetails) {
      setOriginalInput((prev) => {
        return {
          ...prev,
          tax: selectedDetails.preliminaryTax || 0,
          excludedDeductions: selectedDetails?.taxFreeExpensesAndDeductions || 0,
        };
      });
      setModifiedInput((prev) => {
        return {
          ...prev,
          tax: selectedDetails.preliminaryTax || 0,
          excludedDeductions: selectedDetails?.taxFreeExpensesAndDeductions || 0,
        };
      });
    }
  }, [selectedDetails]);

  useEffect(() => {
    const originalValueTotal = formGroup.values.articles.reduce((prev: number, current: ArticleFormField) => {
      const currentValueAsNumber = parseFloat(current.orignalValue) || 0;

      return prev + currentValueAsNumber;
    }, 0);

    const modifiedValueTotal = formGroup.values.articles.reduce((prev: number, current: ArticleFormField) => {
      const currentValueAsNumber = parseFloat(current.updatedValue) || 0;

      return prev + currentValueAsNumber;
    }, 0);

    const originalValuePayout = originalValueTotal + originalInput.tax + originalInput.excludedDeductions;
    const modifiedValuePayout = modifiedValueTotal + modifiedInput.tax + modifiedInput.excludedDeductions;

    setOriginalInput((prev) => {
      return {
        ...prev,
        grossSalary: originalValueTotal,
        payout: originalValuePayout,
      };
    });

    setModifiedInput((prev) => {
      return {
        ...prev,
        grossSalary: modifiedValueTotal,
        payout: modifiedValuePayout,
      };
    });
  }, [formGroup.values.articles]);

  return {
    originalInput,
    modifiedInput,
  };
};
