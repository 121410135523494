import React from 'react';
import CardComponent from '../../../../../components/base/CardComponent';
import PersonalInfo from './PersonalInfo/PersonalInfo';
import AccessLevels from './AccessLevels/AccessLevels';

type Props = {};

const General = (props: Props) => {
  return (
    <CardComponent>
      <PersonalInfo />
      <AccessLevels />
    </CardComponent>
  );
};

export default General;
