import { useState } from 'react';
import { deepClone } from '../../../../util/util';
import { INITIAL_PERSONAL_DETAILS, PersonalDetails } from '../types';
import _ from 'lodash';
import { useEditPersonalClientContext } from '../context/useContext';

export const usePersonalClientFormObserver = () => {
  const { replacePersonalDetailsInput } = useEditPersonalClientContext();
  const [personalDetailsRes, setPersonalDetailsRes] = useState<PersonalDetails | null>(null);

  const getDefaultValue = (data, value) => {
    const initialPersonalDetails = deepClone(INITIAL_PERSONAL_DETAILS);
    const result = _.get(data, value);
    const initialPersonalDetailsValues = _.get(initialPersonalDetails, value);
    return result || initialPersonalDetailsValues;
  };

  const getInitialFormValue = () => {
    const initialPersonalDetails = deepClone(INITIAL_PERSONAL_DETAILS);
    const initialFormGroup: PersonalDetails = personalDetailsRes
      ? {
          personalNumber: getDefaultValue(personalDetailsRes, 'personalNumber'),
          fullName: getDefaultValue(personalDetailsRes, 'fullName'),
          email: getDefaultValue(personalDetailsRes, 'email'),
          alternateEmail: getDefaultValue(personalDetailsRes, 'alternateEmail'),
          status: getDefaultValue(personalDetailsRes, 'status'),
          address: {
            street: getDefaultValue(personalDetailsRes, 'address.street'),
            postcode: getDefaultValue(personalDetailsRes, 'address.postcode'),
            city: getDefaultValue(personalDetailsRes, 'address.city'),
            country: getDefaultValue(personalDetailsRes, 'address.country'),
          },
          phoneNumber: {
            personal: getDefaultValue(personalDetailsRes, 'phoneNumber.personal'),
            private: getDefaultValue(personalDetailsRes, 'phoneNumber.private'),
          },
        }
      : initialPersonalDetails;

    return initialFormGroup;
  };

  const setPersonalClientResponse = (data) => {
    setPersonalDetailsRes(deepClone(data));
    replacePersonalDetailsInput({
      personalNumber: getDefaultValue(data, 'personalNumber'),
      fullName: getDefaultValue(data, 'fullName'),
      email: getDefaultValue(data, 'email'),
      alternateEmail: getDefaultValue(data, 'alternateEmail'),
      status: getDefaultValue(data, 'status'),
      address: {
        street: getDefaultValue(data, 'address.street'),
        postcode: getDefaultValue(data, 'address.postcode'),
        city: getDefaultValue(data, 'address.city'),
        country: getDefaultValue(data, 'address.country'),
      },
      phoneNumber: {
        personal: getDefaultValue(data, 'phoneNumber.personal'),
        private: getDefaultValue(data, 'phoneNumber.private'),
      },
    });
  };
  return {
    personalDetailsRes,
    setPersonalDetailsRes,
    getDefaultValue,
    getInitialFormValue,
    setPersonalClientResponse,
  };
};
