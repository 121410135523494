import { useEffect, useRef, useState } from 'react';
import BSModal from '../../../../../components/Modal';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Search, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import BSTable from '../../../../../components/table/BSTable';
import tableHeaderFormatter, { tableHeaderText } from '../../../../../components/table/TableHeaderFormatter';
import { getCompanyEmployees } from '../../../../../api/service/company/reports/service';
import classNames from 'classnames';
import useLocalizationResolver from '../../../../../hooks/useLocalizationResolver';

type EmployeeModalProps = {
  showEmployeeModal: boolean;
  employeeList?: any;
  toggleModal: () => void;
  addEmployeeToChart: (employee) => void;
};

const EmployeementTypeColumn = ({ row }: { row: any }) => {
  const { getLang } = useLocalizationResolver();
  return <>{getLang(row.personnelType)}</>;
};

export const EmployeeModal = ({
  showEmployeeModal,
  employeeList,
  toggleModal,
  addEmployeeToChart,
}: EmployeeModalProps) => {
  const { t } = useTranslation();
  //  const { employeeColumn } = useColumn();
  const personnelTypeRef = useRef<any>();
  const searchRef = useRef<any>();
  const [employeeData, setEmployeeData] = useState<any>({ data: [] });
  const [employeeFilter, setEmployeeFilter] = useState({ fullName: { like: '' }, personnelType: { like: '' } });
  const [isLoading, setIsLoading] = useState(false);
  const [employeeMetaData, setEmployeeMetaData] = useState({
    page: { size: 25, current: 1 },
    total: 0,
  });

  const setSearchFilter = () => {
    const searchValue = searchRef.current!.value;
    const employeeType = personnelTypeRef.current!.value;

    setEmployeeFilter((prevValue) => ({
      ...prevValue,
      fullName: { like: searchValue },
      personnelType: { like: employeeType },
    }));
  };
  const getEmployeeList = async ({
    filter = employeeFilter,
    page = employeeMetaData.page.current,
    pageSize = employeeMetaData.page.size,
    sortField = 'fullName',
    sortOrder = '1',
    sort = {
      [sortField]: sortOrder,
    },
  }) => {
    setIsLoading(true);
    const employees = await getCompanyEmployees({
      filter,
      page,
      pageSize,
      sort,
    });
    const mappedData = employees['data'].map((i) => ({ ...i, isDisable: false }));

    employeeList.forEach((element: any) => {
      const matchedItem = mappedData?.find((item) => item.id === element.field);
      if (matchedItem) {
        matchedItem.isDisable = true;
      }
    });
    setEmployeeData({ data: mappedData });
    setEmployeeMetaData(employees['metaData']);
    setIsLoading(false);
  };
  const addToChartHandler = (employee) => {
    // selectedEmployee.push({ ...employee, isDisable: true });
    setEmployeeData({
      data: employeeData.data.map((item) => {
        if (employee.id === item.id) {
          item.isDisable = true;
        }
        return item;
      }),
    });
    addEmployeeToChart(employee);
    toggleModal();
  };

  useEffect(() => {
    // if (employeeFilter.fullName !== '' || employeeFilter.personnelType !== '') {
    getEmployeeList({ filter: employeeFilter });
    // }
  }, [employeeFilter]);
  return (
    <>
      <BSModal
        title={`${t('add_employee_to_chart')}`}
        show={showEmployeeModal}
        onHide={() => {
          toggleModal();
        }}
        centered
        hideFooter={true}
        className="chart-search-employee-modal"
        // ModalFooter={<EmployeeModalFooter toggleModal={toggleModal} handleSaveChanges={handleSaveEmployeeChanges} />}
      >
        <div className="cr-form-row justify-content-between">
          <div className="cr-form-row">
            <div className="search-field">
              <div className="input-group">
                <input
                  ref={searchRef}
                  onKeyDown={(e) => e.key === 'Enter' && setSearchFilter()}
                  placeholder={`${t('search')} ${t('employee')}...`}
                  className="form-control"
                  style={{ borderRight: 'none' }}
                />
                <span className="input-group-append bg-white border-left-0">
                  <span className="input-group-text ">
                    <Search className="cursor-pointer" onClick={setSearchFilter} width={16} height={30} />
                  </span>
                </span>
              </div>
            </div>
            <div className="show-type-field">
              <Form.Label className="fs-name mb-0">{`${t('employment_type')}`}</Form.Label>
              <Form.Select ref={personnelTypeRef} onChange={() => setSearchFilter()}>
                <option value="">{t('all')}</option>
                <option value="BlueCollar">{t('blue_collar')}</option>
                <option value="White collar">{t('white_collar')}</option>
              </Form.Select>
            </div>
          </div>

          {isLoading && <Spinner />}
          <div className="mt-3">
            <BSTable
              data={employeeData.data}
              columns={[
                {
                  text: tableHeaderText('Name'),
                  dataField: 'fullName',

                  headerStyle: { width: 200 },
                },
                {
                  text: tableHeaderText('Personal no'),
                  dataField: 'personalNumber',
                  headerFormatter: tableHeaderFormatter,
                  headerStyle: { width: 170 },
                },
                {
                  text: tableHeaderText('Employment type'),
                  dataField: 'personnelType',
                  headerStyle: { width: 170 },
                  formatter: (_, row) => {
                    return (
                      <>
                        <EmployeementTypeColumn row={row} />
                      </>
                    );
                  },
                },
                {
                  dataField: 'isDisable',
                  text: '',
                  formatter: (cell, row) => {
                    return (
                      <div className={classNames('float-end')}>
                        <Button
                          className="app-btn-secondary"
                          onClick={() => addToChartHandler(row)}
                          disabled={row.isDisable}
                        >
                          <Plus className="icon-gap-right" />
                          {t('add_to_chart')}
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
              rowClass={(row) => {
                return row['isDisable'] === true ? 'selected-row' : '';
              }}
            />
          </div>
        </div>
      </BSModal>
    </>
  );
};
