import { useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { useTranslation } from 'react-i18next';
import useContext from './context/useContext';
import CardComponent from '../../../../components/base/CardComponent';
type Props = {
  onDetailsAccountingSalaryCompany?: (args) => void;
};
export default function AccountingSalaryCompanyDetails({ onDetailsAccountingSalaryCompany }: Props) {
  const { accountingSalaryCompany, contactInformation, formGroup } = useContext();

  const { t } = useTranslation();
  useEffect(() => {
    if (onDetailsAccountingSalaryCompany) {
      onDetailsAccountingSalaryCompany(formGroup);
    }
  }, []);
  return (
    <>
      <CardComponent>
        <PagesHeader headingClass="fs-h3" pageTitle="Company Information" />
        <Row className="align-items-end mb-4">
          <div style={{ width: 219 }}>
            <Form.Label className="fs-name">Short name</Form.Label>
            <Form.Control value={accountingSalaryCompany?.shortName} disabled />
          </div>
          <div style={{ width: 360 }}>
            <Form.Label className="fs-name">Company name</Form.Label>
            <Form.Control value={accountingSalaryCompany?.companyName} disabled />
          </div>
          <div style={{ width: 76 }}>
            <Form.Label className="fs-name">Client no.</Form.Label>
            <Form.Control value={accountingSalaryCompany?.clientNumber} disabled />
          </div>
          <div style={{ width: 120 }}>
            <Form.Label className="fs-name">Contract no.</Form.Label>
            <Form.Control value={accountingSalaryCompany?.contractNumber} disabled />
          </div>
        </Row>
        <Row className="align-items-end mb-4">
          <div style={{ width: 438 }}>
            <Form.Label className="fs-name">Text description</Form.Label>
            <Form.Control value={accountingSalaryCompany?.description} disabled />
          </div>
        </Row>
        <Row className="align-items-end mb-4">
          <div style={{ width: 116 }}>
            <Form.Label className="fs-name">Org. no.</Form.Label>
            <Form.Control value={accountingSalaryCompany?.organizationNumber} disabled />
          </div>
          <div style={{ width: 120 }}>
            <Form.Label className="fs-name">VAT no.</Form.Label>
            <Form.Control value={accountingSalaryCompany?.vatNumber} disabled />
          </div>
          <div style={{ width: 116 }}>
            <Form.Label className="fs-name">Bankgiro no.</Form.Label>
            <Form.Control value={accountingSalaryCompany?.bankgiroNumber} disabled />
          </div>
          <div style={{ width: 105 }}>
            <Form.Label className="fs-name">Plusgiro no.</Form.Label>
            <Form.Control value={accountingSalaryCompany?.plusgiroNumber} disabled />
          </div>
        </Row>
        <PagesHeader headingClass="fs-h3" pageTitle="Contact information" />
        <Row className="align-items-end mb-4">
          <div style={{ width: 116 }}>
            <Form.Label className="fs-name">Postal address</Form.Label>
            <Form.Control value={contactInformation?.address} disabled />
          </div>
          <div style={{ width: 120 }}>
            <Form.Label className="fs-name">ZIP code</Form.Label>
            <Form.Control value={contactInformation?.postalCode} disabled />
          </div>
          <div style={{ width: 116 }}>
            <Form.Label className="fs-name">City</Form.Label>
            <Form.Control value={contactInformation?.city} disabled />
          </div>
        </Row>
        <Row className="align-items-end mb-4">
          <div style={{ width: 121 }}>
            <Form.Label className="fs-name">Phone</Form.Label>
            <Form.Control value={contactInformation?.phone} disabled />
          </div>
          <div style={{ width: 121 }}>
            <Form.Label className="fs-name">Fax</Form.Label>
            <Form.Control value={contactInformation?.fax} disabled />
          </div>
          <div style={{ width: 220 }}>
            <Form.Label className="fs-name">Email</Form.Label>
            <Form.Control value={contactInformation?.email} disabled />
          </div>
        </Row>
        {/* <PagesHeader headingClass="fs-h3" pageTitle="Pension settings" /> */}
        <Row className="align-items-end mb-4">
          <div>
            <Form.Label className="fs-name mb-3">{`${t('type')} ${t('of')} ${t('Integration')}`}</Form.Label>
            <div className="d-flex">
              <Form.Check
                readOnly
                inline
                label="Company API"
                name="companyAPI"
                checked={
                  accountingSalaryCompany?.integrationType.length > 0 &&
                  accountingSalaryCompany?.integrationType[0]?.key.includes('companyAPI')
                }
                className="fs-name"
                style={{ width: 120 }}
              />
              <Form.Group style={{ width: 200 }}>
                <Form.Label className="fs-name">
                  {t('API')} {t('key')}
                </Form.Label>
                <Form.Control type="text" name="apiKey" value={accountingSalaryCompany?.apiKey} />
              </Form.Group>
            </div>
          </div>
        </Row>
      </CardComponent>
    </>
  );
}
