import moment from "moment";

export function enumerateMonthsBetweenDates(startDate, endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);

    var now = startDate,
        dates: any = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {
        dates.push(now.format('MMM-YY'));
        now.add(1, 'months');
    }
    return dates;
}
export function padMonthWithZeros(dateRange, data, opt: { colLength: number } | null = null) {
    const startDate = moment(dateRange[0]);
    const endDate = moment(dateRange[1]);
    const filledData: any[] = [];

    let dataIndex = 0;
    for (
        const currentDate = startDate.clone();
        currentDate.isSameOrBefore(endDate, 'month');
        currentDate.add(1, 'month')
    ) {
        const dateString = currentDate.startOf('month').format('YYYY-MM-DD');
        const foundMonthData = data.find(
            (item) => moment(item.date).format('YYYY-MM') === moment(dateString).format('YYYY-MM'),
        );
        if (dataIndex < data.length && foundMonthData) {

            filledData.push(foundMonthData);
            dataIndex++;
        } else {

            const defaultData: any[] = [{
                date: moment(dateString).format('YYYY-MM'),
                total: {
                    salaryChange: 0,
                    calculated: 0,
                    totalPayable: 0
                }
            }];
            // const numCols = data[0] ? data[0].length - 1 : opt?.colLength || 0;

            // for (let counter = 0; counter < numCols; counter++) {
            //     defaultData.push({
            //         calculated: 0,
            //         salary_change: 0,
            //         date: dateString
            //     });
            // }
            filledData.push(...defaultData);

        }
    }

    return filledData;
}