import classNames from 'classnames';
import { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import BootstrapTable, { ColumnDescription, SelectRowProps, TableChangeHandler } from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import LocaleGuard from '../locale/LocaleGuard';
// import InitalSortArrow from '../../assets/img/initial-sort-arrow.png';
// import SortArrowDown from '../../assets/img/sort-arrow-down.png';
// import SortArrowUp from '../../assets/img/sort-arrow-up.png';

type TableDataType = {
  columns: ColumnDescription<any, any>[];
  data: any[];
  sizePerPage: number;
  noDataIndication?: string;
  keyField?: string;
  onSizePerPageChange?: Function;
  onRowClick?: (row: any) => void;
  onTableChange?: TableChangeHandler<any> | undefined;
  selectRow?: SelectRowProps<any>;
  children?: React.ReactNode;
  rowStyle?: React.CSSProperties | ((row: any, rowIndex: number) => React.CSSProperties) | undefined;
  rowClasses?: string | ((row: any, rowIndex: number) => string) | undefined;
  isTableHover?: boolean;
  bodyClasses?: string;
  tableClass?: string;
};

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => {
  return (
    <div className="btn-group w-auto mb-2 mt-2" role="group">
      {options.map((option) => {
        const isSelect = currSizePerPage === `${option.page}`;
        return (
          <Button
            className={classNames('fs-label border-6px theme-text size-per-page-btn mx-1', {
              'sizeperpage-btn-underline': isSelect,
            })}
            key={option.text}
            onClick={() => onSizePerPageChange(option.page)}
          >
            <p className={classNames('mb-0', { 'mt-1': isSelect })}>{option.text}</p>
            {isSelect && <div style={{ borderBottom: '4px solid #EAB600', width: '100%', borderRadius: 10 }}></div>}
          </Button>
        );
      })}
    </div>
  );
};
const BSRemoteTable = forwardRef(
  (
    {
      data,
      columns,
      sizePerPage,
      onSizePerPageChange,
      noDataIndication = 'No Data found',
      onRowClick,
      keyField = '_id',
      onTableChange,
      selectRow,
      children,
      rowStyle,
      rowClasses,
      tableClass,
      isTableHover = true,
      bodyClasses,
    }: TableDataType,
    ref,
  ) => {
    const defaultPaginationOptions = {
      custom: true,
      sizePerPage,
      sizePerPageRenderer,
      sizePerPageList: [
        {
          text: '25',
          value: 25,
        },
        {
          text: '50',
          value: 50,
        },
        {
          text: '100',
          value: 100,
        },
      ],
      onSizePerPageChange,
    };

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target['nodeName'] === 'TD' && e.target.className !== 'disabled-action') {
          onRowClick && onRowClick(row);

          return;
        }

        const hasRowNavigator = e?.target?.closest && e?.target?.closest('.row-navigator');

        if (hasRowNavigator) {
          onRowClick && onRowClick(row);
        }
      },
    };

    const noDataFound = data.length === 0;

    return (
      <LocaleGuard forceRefresh>
        <PaginationProvider pagination={paginationFactory(defaultPaginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <>
                <SizePerPageDropdownStandalone {...paginationProps} />
                {children}
                <BootstrapTable
                  condensed
                  ref={ref}
                  keyField={keyField}
                  remote={{ sort: true }}
                  classes={classNames(tableClass)}
                  headerClasses="correcto-table-header"
                  headerWrapperClasses="header-wrapper"
                  bodyClasses={`remote-table-body ${onRowClick ? 'clickable-row' : ''} ${
                    isTableHover === false ? 'remote-table-body--hover-disabled' : ''
                  } ${bodyClasses ? bodyClasses : ''}`}
                  bordered={false}
                  data={data}
                  columns={columns}
                  noDataIndication={noDataIndication}
                  rowEvents={rowEvents}
                  onTableChange={onTableChange}
                  hover={isTableHover && !noDataFound}
                  selectRow={selectRow}
                  rowStyle={rowStyle}
                  rowClasses={rowClasses}
                  // sort={sortOption}
                  {...paginationTableProps}
                />
                {/* <PaginationListStandalone {...paginationProps} /> */}
              </>
            );
          }}
        </PaginationProvider>
      </LocaleGuard>
    );
  },
);

BSRemoteTable.displayName = 'BSRemoteTable';

export default BSRemoteTable;
