import moment from 'moment';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { sortCaret } from '../../../components/table/formatters';
import { tableHeaderText } from '../../../components/table/TableHeaderFormatter';
import classNames from 'classnames';
import useLocalizationResolver from '../../../hooks/useLocalizationResolver';
import CompanyRoleSwitcher from './CompanyList/CompanyRoleSwitcher';
import { CompanyClientSaveMode } from './types';

export const useColumn = () => {
  const { getLang } = useLocalizationResolver();
  const companyClientColumn: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Company name'),
      dataField: 'name',
      sort: true,
      sortCaret,
      headerStyle: { width: 200 },
    },
    {
      text: tableHeaderText('Org.number'),
      dataField: 'organizationNumber',
      sort: true,
      sortCaret,
      headerStyle: { width: 110 },
    },
    {
      text: tableHeaderText('Employees'),
      dataField: 'employees',
      sort: true,
      sortCaret,
      // formatter: () => 10,
      headerStyle: { width: 93 },
      classes: 'text-right',
    },
    {
      text: tableHeaderText('Contract number'),
      dataField: 'contractNumber',
      sort: true,
      sortCaret,
      headerStyle: { width: 150 },
    },
    {
      text: tableHeaderText('Status'),
      dataField: 'status',
      sort: true,
      sortCaret,
      formatter: (cell) => <span className={classNames('fs-text-400', 'active-green')}>{getLang(cell)}</span>,
      headerStyle: { width: 110 },
    },
    {
      text: tableHeaderText('Creation date'),
      dataField: 'createdAt',
      sort: true,
      sortCaret,
      formatter: (cell) => moment(cell).format('YYYY-MM-DD'),
      headerStyle: { width: 110 },
    },
    {
      text: tableHeaderText('Last edit'),
      dataField: 'updatedAt',
      sort: true,
      sortCaret,
      formatter: (cell) => moment(cell).format('YYYY-MM-DD HH:mm'),
      headerStyle: { width: 130 },
    },
    {
      text: '',
      dataField: '_id',
      align: 'left',
      formatter: (_, row) => {
        const hasActiveMode = row?.status === CompanyClientSaveMode.ACTIVE;
        if (hasActiveMode && row.allowCorrectoAccess) {
          return <CompanyRoleSwitcher row={row} />;
        }

        return <></>;
      },
      headerStyle: { width: 100 },
      classes: 'disabled-action',
    },
  ];

  return { companyClientColumn };
};
