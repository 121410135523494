import useSidebar from '../../hooks/useSidebar';
import SidebarNav from './sidebar_nav/SidebarNav';
import CorrectoLogo from '../../assets/img/c-logo.svg';
import { SidebarItemsType } from '../../types/sidebar';
import useAuth from '../../hooks/useAuth';

interface SidebarProps {
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  logo: string;
  open?: boolean;
  showFooter?: boolean;
}

const Sidebar = ({ items, logo }: SidebarProps) => {
  // feather.icons.toSvg();

  const { isOpen } = useSidebar();
  const { user } = useAuth();
  const companyName = user?.role?.company?.name || '';

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      {/* <PerfectScrollbar> */}
      <a className="sidebar-brand" href="/dashboard">
        {companyName ? companyName : <img src={logo} alt="logo" />}
        {/* <span className="align-middle">
            <Text />
          </span> */}
      </a>
      <div className="sidebar-content-wrapper">
        <div className={`sidebar-content ${!isOpen ? 'main-collapsed' : ''}`}>
          <SidebarNav items={items} />
          {/* </PerfectScrollbar> */}
        </div>
        <div className="footer-logo">
          <img src={CorrectoLogo} alt="logo" height={70} width={70} />
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
