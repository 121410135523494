import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { ExternalLink, Slash, Zap, Check } from 'react-feather';
import { useTranslation } from 'react-i18next';

import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import SalaryDocument from './SalaryDocument';
import { useSalarySlipContext } from '../context/useContext';
import { getSalaryTypes } from '../../../../api/service/superAdmin/pension-policies/service';
import { getDataValidationDetailsByID, saveSalaryDataValidation } from '../../../../api/service/company/settings';
import { DialogWithTextField } from '../../../../util/dialogs';
import { openExternalLink } from '../../../../util';

export default function SalarySlipDetail() {
  const { t } = useTranslation();
  const { selectedDetails, initialize, setSalaryTypes, errorType, formGroup, normalizeRequestPayload } =
    useSalarySlipContext();
  const pageTitle = selectedDetails?._internal?.title || t('salary_slip');
  const { id } = useParams();
  const [isBusy, setIsBusy] = useState(true);
  const [isUpdating, setisUpdating] = useState(false);
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
    },
    {
      name: 'Cost validation',
      href: '/data-management',
    },
    {
      name: pageTitle,
      translate: false,
    },
  ];

  const onCancel = () => {};

  const onUpdate = async () => {
    formGroup.handleSubmit();

    if (!formGroup.isValid) {
      return false;
    }

    const result = await DialogWithTextField({
      title: `<h2 class="fs-page-title">The updated salary slip will get calculated in the system, are you sure the updates are correct?</h2>`,
      confirmButtonText: t('yes'),
      denyButtonText: t('no'),
      showConfirmationInput: false,
    });

    if (result.isConfirmed) {
      saveDetails();
    }
  };

  const onOpenLink = () => {
    if (selectedDetails?.documentLink) {
      openExternalLink(selectedDetails.documentLink);
    }
  };

  const saveDetails = async () => {
    try {
      setisUpdating(true);

      const payload = normalizeRequestPayload();
      await saveSalaryDataValidation(id, payload);
    } catch (e) {
      console.error(e);
    } finally {
      setisUpdating(false);
    }
  };

  const fetchDetails = async () => {
    setIsBusy(true);
    const _types = await getSalaryTypes();

    const response = await getDataValidationDetailsByID(id);

    setSalaryTypes(_types);
    initialize(response);
    setIsBusy(false);
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader headingClass="fs-h1" pageTitle={pageTitle} translate={false} />
        <div>
          {isBusy === false && !errorType && (
            <div className="file-status mb-3">
              <Check className="icon-gap-right active-green" />
              <span className="theme-text fs-14px">{t('successfully_processed')}</span>
            </div>
          )}
          {errorType && (
            <Button onClick={onCancel} className="app-btn-primary me-2 cancel-btn">
              <Slash className="icon-gap-right" /> {`${t('cancel')} ${t('edits')}`}
            </Button>
          )}
          {errorType && (
            <Button onClick={onUpdate} className="app-btn-primary me-2">
              {isUpdating ? <Spinner size="sm" /> : <Zap className="icon-gap-right" />} {t('execute_updated_values')}
            </Button>
          )}
          <Button onClick={() => onOpenLink()} className="app-btn-primary">
            <ExternalLink className="icon-gap-right" />
            {t('open_file_in_new_tab')}
          </Button>
        </div>
      </div>
      <Tabs defaultActiveKey="general">
        <Tab eventKey="general" title={`${t('salary_documents')}`}>
          <SalaryDocument isBusy={isBusy} />
        </Tab>
        <Tab eventKey="billing" title={`${t('supplier_invoices')}`}></Tab>
      </Tabs>
    </>
  );
}
