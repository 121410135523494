import { CompanyWokflowFormGroup } from './types';

export const INITIAL_POLICY_DETAIL = {
  id: '',
  name: '',
};

export const INITIAL_EMPLOYEE_TYPE_WORKFLOW_POLICY = {
  blueCollarEmployee: '',
  whiteCollarEmployee: '',
};

export const INITIAL_WORKFLOW_FORM: CompanyWokflowFormGroup = {
  whiteCollarPolicy: {
    automatic: false,
    policyId: '',
  },
  blueCollarPolicy: {
    automatic: false,
    policyId: '',
  },
};

export const INITIAL_COMPANY_DETAILS = {
  invoiceEmailAddress: '',
  invoicingCostCenter: '',
  organizationNumber: '',
  name: '',
  employees: null,
  contractEffectiveFrom: '',
  admins: [],
  combinations: [],
  address: {
    city: '',
    country: '',
    postcode: '',
    street: '',
  },
  createdAt: '',
  updatedAt: '',
  status: '',
  contractNumber: '',
  deletedAdmins: [],
  deletedConsultants: [],
}