import React, { useState } from 'react';
import PagesHeader from '../../../components/base/PagesHeader';
import Container from 'react-bootstrap/esm/Container';
import Breadcrumb from '../../../components/base/Breadcrumb';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import { Button, Col, Row } from 'react-bootstrap';
import { Plus } from 'react-feather';
import AccountingFirmGrid from './AccountingFirmGrid';
import { CreateAccountingFirmProvider } from './context/CreateAccountingFirmContext';
import CreateAccountingFirmModal from './CreateAccountingFirm/CreateAccountingFirmModal';
import useLocale from '../../../hooks/useLocale';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Accounting firms',
  },
];

const AccountingFirms = () => {
  const { localize } = useLocale();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isAccountingFirmCreated, setIsAccountingFirmCreated] = useState<boolean>(false);

  const toggleModal = () => {
    setShowCreateModal(!showCreateModal);
    setIsAccountingFirmCreated(false);
  };

  return (
    <Container fluid className="p-0 accounting-firm">
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader headingClass="fs-h1" pageTitle="Accounting Firms" />
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <span>
            <Button onClick={toggleModal} className="app-btn-primary">
              <Plus className="icon-gap-right" />
              {`${localize('new')} ${localize('accounting_firm')}`}
            </Button>
          </span>
        </Col>
      </Row>
      <AccountingFirmGrid isAccountingFirmCreated={isAccountingFirmCreated} />
      {showCreateModal && (
        <CreateAccountingFirmProvider>
          <CreateAccountingFirmModal
            setIsAccountingFirmCreated={setIsAccountingFirmCreated}
            toggleModal={toggleModal}
            showCreateModal={showCreateModal}
          />
        </CreateAccountingFirmProvider>
      )}
    </Container>
  );
};

export default AccountingFirms;
