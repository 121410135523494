import moment from 'moment';
import { Col } from 'react-bootstrap';

import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import ReportHeader from '../../../components/report/ReportHeader';
import ReportLine from '../../../components/report/ReportLine';
import { Finance } from './types';
import { useTranslation } from 'react-i18next';
import { numberSpaceSeparator } from '../../../util/util';

export const FinanceSalaryDetails = ({ data }: { data: Finance[] }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex salary-specs-wrapper" style={{ paddingTop: 30 }}>
      {data.map((item, idx) => {
        return (
          <>
            <Col className="mx-2 mt-2 position-relative" key={idx} md="4" xl>
              <ReportHeader
                title={`${moment(item.salaryPeriod.from).year()} ${moment(item.salaryPeriod.from).format('MMMM')}`}
              />

              <PagesHeader pageTitle="Salary" headingClass="font-black fs-h3" />
              <CardComponent>
                <div className="mt-3">
                  {item.transactions.map((i) => {
                    const salaryCodeName = i.salaryCodeData.name;
                    const salaryCode = i.salaryCodeData.code;
                    const label =
                      i.salaryCodeData.code === 0 ? 'Missing salary code' : `${salaryCodeName} (Code ${salaryCode})`;

                    return (
                      <>
                        <ReportLine
                          key={i['salaryCode']}
                          label={label}
                          value={numberSpaceSeparator(i.amount.toFixed(2))}
                          tooltip={label}
                          type="LABEL_VALUE"
                          className="fs-text-400"
                        />
                      </>
                    );
                  })}
                  <div style={{ borderTop: '1px solid #000000' }}>
                    <ReportLine
                      label={t('total_gross_salary')}
                      value={numberSpaceSeparator(item.grossSalary.toFixed(2))}
                      tooltip={t('total_gross_salary') ?? ''}
                      type="LABEL_VALUE"
                      className="fs-h3 font-black"
                    />
                    <ReportLine
                      label={t('preliminary_tax')}
                      value={numberSpaceSeparator(item.preliminaryTax.toFixed(2))}
                      tooltip={t('preliminary_tax')}
                      type="LABEL_VALUE"
                      className="fs-h3 font-black"
                    />
                    <ReportLine
                      label={t('taxable_income')}
                      value={numberSpaceSeparator(item.taxableIcome.toFixed(2))}
                      tooltip={t('taxable_income')}
                      type="LABEL_VALUE"
                      className="fs-h3 font-black"
                    />
                    <ReportLine
                      label={t('paid_out')}
                      value={numberSpaceSeparator(item.totalPayable.toFixed(2))}
                      tooltip={t('paid_out')}
                      type="LABEL_VALUE"
                      className="fs-18px"
                    />
                  </div>
                </div>
              </CardComponent>
            </Col>
            {idx % 2 === 0 && (
              <Col
                className="align-self-center mx-4"
                md="4"
                style={{ background: '#5F5F5F', width: 1, height: '100%' }}
              ></Col>
            )}
          </>
        );
      })}
    </div>
  );
};
