import React, { useEffect } from 'react';
import useContext from './context/useContext';
import { Mode } from '../types';
import { useSuperAdminSettingsContext } from '../useContext';
import { InsuranceList } from './InsuranceList';
import { CreateInsuranceModal } from './CreateInsurance/CreateInsuranceModal';
import EditInsurance from './EditInsurance';
import { getInsurances } from '../../../../api/service/superAdmin/settings/insurances';

type Props = {
  showCreateModal: boolean;
  setIsInsuranceCreated: (args) => void;
  toggleModal: () => void;
  updateInsurance?: (input, value) => void;
  onDetailsInsurance?: (value) => void;
};

export default function Insurance({ setIsInsuranceCreated, showCreateModal, toggleModal }: Props) {
  const { replaceInsurances, formGroup } = useContext();
  const { mode, setMode } = useSuperAdminSettingsContext();

  const fetchInsurances = async () => {
    const _insurances = await getInsurances();
    replaceInsurances(_insurances);
  };

  useEffect(() => {
    if (mode === Mode.List) {
      fetchInsurances();
      formGroup.resetForm();
    }
  }, [mode]);

  useEffect(() => {
    if (showCreateModal) {
      setMode(Mode.Add);
    } else {
      setMode(Mode.List);
    }
  }, [showCreateModal]);

  return (
    <>
      {mode === Mode.List && <InsuranceList />}
      {mode === Mode.Add && showCreateModal && (
        <CreateInsuranceModal
          setIsInsuranceCreated={setIsInsuranceCreated}
          toggleModal={toggleModal}
          showCreateModal={showCreateModal}
        />
      )}

      {mode === Mode.Edit && <EditInsurance />}
      {mode === Mode.Detail && <EditInsurance />}
    </>
  );
}
