import { Dispatch, SetStateAction, useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import BSModal from '../../../../components/Modal';
import CreateCompanyClientModalFooter from './CreateCompanyClientModalFooter';
import { useCreateCompanyClientContext } from '../context/useContext';
import { CompanyDetails, ContractDetails, AuthorizedSignatory, AdminDetails, Invitation } from '../CompanyClientForms';
import { INITIAL_COMPANY_DETAILS, INITIAL_ADDRESS_DETAILS, INITIAL_CONTACT_PEOPLE } from '../contants';
import ExternalConsultant from '../CompanyClientForms/Consultants';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';
import { getCompanyContractNumber } from '../../../../api/service/superAdmin/company-clients/service';
import useLocale from '../../../../hooks/useLocale';
import { AccessLevel } from '../CompanyClientForms/Access';
import { FileUpload } from '../CompanyClientForms/FileUpload';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsCompanyCreated: Dispatch<SetStateAction<boolean>>;
};

const CreateCompanyClientModal = ({ showCreateModal, toggleModal, setIsCompanyCreated }: Props) => {
  const { errors, isInvalid, setErrors } = useValidationError();
  const { localize } = useLocale();
  const {
    setAddressDetails,
    setCompanyDetails,
    setSignatoryDetails,
    setAdminDetails,
    setSalaryContactPeople,
    setAccountingContactPeople,
    setContractEffectiveFrom,
    setConsultants,
    setInvitationEmail,
    updateContractNumber,
    formGroup,
  } = useCreateCompanyClientContext();
  const [isContractLoading, setIsContractLoading] = useState(false);

  const fetchContractNumber = useCallback(async () => {
    try {
      setIsContractLoading(true);

      const res = await getCompanyContractNumber();
      updateContractNumber(res);
    } finally {
      setIsContractLoading(false);
    }
  }, []);

  useEffect(() => {
    if (showCreateModal) {
      fetchContractNumber();
    }
  }, [showCreateModal]);

  return (
    <>
      <BSModal
        title={`${localize('new_company_client')}`}
        show={showCreateModal}
        onHide={() => {
          setCompanyDetails(INITIAL_COMPANY_DETAILS);
          setAddressDetails(INITIAL_ADDRESS_DETAILS);
          setSignatoryDetails([]);
          setAdminDetails([]);
          setSalaryContactPeople(INITIAL_CONTACT_PEOPLE);
          setAccountingContactPeople(INITIAL_CONTACT_PEOPLE);
          setContractEffectiveFrom('');
          setInvitationEmail('');
          setConsultants([]);
          toggleModal();
        }}
        centered
        ModalFooter={
          <CreateCompanyClientModalFooter
            setIsCompanyCreated={setIsCompanyCreated}
            toggleModal={toggleModal}
            setErrors={setErrors}
            formGroup={formGroup!}
          />
        }
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />

          <CompanyDetails context={useCreateCompanyClientContext()} formGroup={formGroup!} />

          <ContractDetails
            isContractLoading={isContractLoading}
            context={useCreateCompanyClientContext()}
            formGroup={formGroup!}
          />

          <AuthorizedSignatory context={useCreateCompanyClientContext()} formGroup={formGroup!} />

          <AdminDetails context={useCreateCompanyClientContext()} formGroup={formGroup!} />

          <ExternalConsultant context={useCreateCompanyClientContext()} formGroup={formGroup!} />

          {/* <ContactPeople context={useCreateCompanyClientContext()} formGroup={formGroup!} /> */}
          <AccessLevel context={useCreateCompanyClientContext()} formGroup={formGroup!} />
          <FileUpload context={useCreateCompanyClientContext()} formGroup={formGroup!} />
          <Invitation context={useCreateCompanyClientContext()} formGroup={formGroup!} />
        </Form>
      </BSModal>
    </>
  );
};

export default CreateCompanyClientModal;
