import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { Plus } from 'react-feather';
import { useSortable } from '@dnd-kit/sortable';
import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/settings/pension-manager.module.scss';
import { IPensionCompaniesContext, Mode, TypePlans } from '../../types';
import { CSS } from '@dnd-kit/utilities';
import { Trash2, MoreVertical } from 'react-feather';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../../util/form';
import useLocale from '../../../../../hooks/useLocale';
import { isObjectEmpty } from '../../../../../util/util';
import React from 'react';
import CostSpecification from './CostSpecification';
import CostSpecificationViewDetails from './CostSpecificationViewDetails';
import TooltipBox from '../../../../../components/base/Overlay/TooltipBox';

interface Props {
  context: IPensionCompaniesContext;
  mode: Mode;
  index: number;
  plan: TypePlans;
  isEditable: boolean;
}

export const PlanItem = ({ context, mode, index, plan, isEditable }: Props) => {
  const { replacePensionManagerPlan, replacePensionManagerDocuments, formGroup } = context;
  const { localize } = useLocale();
  const [documentValue, setDocumentValue] = useState({ text: '', documentLink: '' });
  const managerPlans = formGroup.values?.pensionManagerPlans.plans || [];
  const managerDocuments = formGroup.values?.pensionManagerPlans.plans[index].documents || [];
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: plan.id!,
    attributes: {
      role: 'draggable',
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const deleteProjectManagerPlan = (index) => {
    const _pensionManagers = [...managerPlans];
    const filteredPensionManagers = _pensionManagers
      .filter((i, idx) => idx !== index)
      .map((item, idx) => {
        item.order = idx + 1;

        return item;
      });

    replacePensionManagerPlan(filteredPensionManagers);
  };

  const handlePensionManagerPlan = (e, index) => {
    const plans = [...managerPlans];
    const selectedPensionManager = plans[index];
    selectedPensionManager![e.target.name] = e.target.value;

    replacePensionManagerPlan(plans);
  };

  const addDocument = () => {
    const document = [...managerDocuments, documentValue];
    replacePensionManagerDocuments(document, index);
    setDocumentValue({ text: '', documentLink: '' });
  };

  const deleteDocument = (documnetIndex) => {
    const _documents = [...managerDocuments];
    const filteredDocuments = _documents.filter((doc, docIndex) => {
      return docIndex !== documnetIndex;
    });
    replacePensionManagerDocuments(filteredDocuments, index);
  };

  const handlePensionManagerDocuments = (e, docIndex) => {
    const _documents = [...managerDocuments];
    const foundDocument = _documents.find((i, index) => index === docIndex);
    foundDocument![e.target.name] = e.target.value;
    replacePensionManagerDocuments(_documents, index);
  };
  const handleDocuments = (e, index) => {
    setDocumentValue((preval) => ({ ...preval, [e.target.name]: e.target.value }));
  };

  return (
    <tr className="highlight-inactive" ref={setNodeRef} style={style} {...attributes}>
      {isEditable && (
        <td width={25} className="align-baseline">
          <div {...listeners} className="draggable-item">
            <MoreVertical />
          </div>
        </td>
      )}
      <td className="plan-name-field align-baseline verticle-top">
        <div className="item-control">
          <Form.Label className="fs-name">
            {localize('plan')} {localize('name')}
          </Form.Label>
          <Form.Control
            type="text"
            name="planName"
            onChange={(e) => handlePensionManagerPlan(e, index)}
            value={formGroup.values.pensionManagerPlans?.plans[index]?.planName}
            disabled={mode === Mode.Detail}
          />
        </div>
      </td>
      <td style={{ maxWidth: 600, minWidth: 600 }} className={classNames('align-baseline')}>
        <table className={classNames('table', themeStyle.provider_grid)}>
          <tbody>
            <tr className="highlight-inactive">
              <td className="align-baseline pe-3 provider-desc-field ps-0">
                <div className="item-control pl-0">
                  <Form.Label className="fs-name">
                    {localize('provider')}&nbsp;
                    {localize('description')}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    style={{ height: 154 }}
                    value={formGroup.values.pensionManagerPlans?.plans[index]?.providerDescription}
                    className={classNames('text-left')}
                    onChange={(e) => handlePensionManagerPlan(e, index)}
                    name="providerDescription"
                    disabled={mode === Mode.Detail}
                  />
                </div>
              </td>
              <td className="align-baseline ps-0 document-link-field">
                <div className="item-control pr-0">
                  <Form.Label className="fs-name">
                    {localize('documents')} ({localize('links')})
                  </Form.Label>
                  {formGroup.values?.pensionManagerPlans?.plans[index]?.documents?.map((document, docIndex) => (
                    <React.Fragment key={docIndex}>
                      <div className="cr-form-row flex-nowrap align-items-center">
                        <div className="doc-link-name-field">
                          <Form.Control
                            type="text"
                            value={formGroup.values.pensionManagerPlans?.plans[index]?.documents[docIndex].text}
                            className={classNames(
                              'text-left',
                              errorHighlighter(
                                formGroup.getFieldMeta(
                                  `pensionManagerPlans.plans.${[index]}.documents${[docIndex]}.text`,
                                ),
                              ),
                            )}
                            onChange={(e) => handlePensionManagerDocuments(e, docIndex)}
                            name="text"
                            disabled={mode === Mode.Detail}
                            placeholder={`${localize('link_text')}`}
                          />
                          <ErrorFeedback
                            field={formGroup.getFieldMeta(
                              `pensionManagerPlans.plans.${[index]}.documents${[docIndex]}.text`,
                            )}
                          />
                        </div>
                        <div className="doc-link-field">
                          <Form.Control
                            type="text"
                            value={formGroup.values.pensionManagerPlans?.plans[index]?.documents[docIndex].documentLink}
                            className={classNames(
                              'text-left',
                              errorHighlighter(
                                formGroup.getFieldMeta(
                                  `pensionManagerPlans.plans${[index]}.documents.${[docIndex]}.documentLink`,
                                ),
                              ),
                            )}
                            onChange={(e) => handlePensionManagerDocuments(e, docIndex)}
                            name="documentLink"
                            disabled={mode === Mode.Detail}
                            placeholder={`${localize('document_link')}`}
                          />
                          <ErrorFeedback
                            field={formGroup.getFieldMeta(
                              `pensionManagerPlans.plans${[index]}.documents.${[docIndex]}.documentLink`,
                            )}
                          />
                        </div>

                        {!isObjectEmpty(document) && [Mode.Edit, Mode.Add].indexOf(mode) !== -1 && (
                          <div className="document-link-btn-field">
                            <span style={{ cursor: 'pointer' }} onClick={() => deleteDocument(docIndex)}>
                              <TooltipBox msg={`${localize('delete')}`}>
                                <Trash2 color="#930000" />
                              </TooltipBox>
                            </span>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                  {[Mode.Edit, Mode.Add].indexOf(mode) !== -1 && (
                    <div className="cr-form-row flex-nowrap align-items-center">
                      <div className="doc-link-name-field">
                        <Form.Control
                          type="text"
                          value={documentValue.text}
                          className={classNames('text-left')}
                          onChange={(e) => handleDocuments(e, index)}
                          name="text"
                          placeholder={`${localize('link_text')}`}
                        />
                      </div>
                      <div className="doc-link-field">
                        <Form.Control
                          type="text"
                          value={documentValue.documentLink}
                          className={classNames('text-left')}
                          onChange={(e) => handleDocuments(e, index)}
                          name="documentLink"
                          placeholder={`${localize('document_link')}`}
                        />
                      </div>

                      <div className="document-link-btn-field">
                        <Button className="app-btn-secondary btn btn-primary" onClick={addDocument}>
                          <Plus />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-4">
          {mode !== Mode.Detail && <CostSpecification planIndex={index} mode={mode} />}
          {mode === Mode.Detail && <CostSpecificationViewDetails planIndex={index} mode={mode} />}
        </div>
      </td>

      {mode !== Mode.Detail && (
        <td align="right" className="align-baseline">
          <span style={{ cursor: 'pointer' }} onClick={() => deleteProjectManagerPlan(index)}>
            <TooltipBox msg={`${localize('delete')}`}>
              <Trash2 color="#930000" />
            </TooltipBox>
          </span>
        </td>
      )}
      {mode === Mode.Detail && <td></td>}
    </tr>
  );
};

export default PlanItem;
