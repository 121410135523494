import React, { useMemo } from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';
import HighChartAccessibility from 'highcharts/modules/accessibility';
import HighChartStock from 'highcharts/modules/stock';
import HighChartData from 'highcharts/modules/data';
import HighChartMore from 'highcharts/highcharts-more';
import { Menu, ArrowRight, UserPlus } from 'react-feather';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import moment from 'moment';

import { ThemeContext } from '../../../contexts/ThemeContext';
import { ChartTable } from '../ChartTable';
import { ISeries, ISeriesOptions } from './types';
import { SeriesAdapter } from './series-adapter';
import ChartSwitchFilter from './ChartSwitchFilter';
import { CalenderInput } from '../../base/CalenderInput';
import { Calendar } from '../../../util/calendar';
import { activeStyleByMonthRange, getRecentDateRange } from '../utils';
import { t } from 'i18next';
import { deepClone } from '../../../util/util';

HighchartsExporting(Highcharts);
HighchartsExportingData(Highcharts);
HighChartAccessibility(Highcharts);
HighChartStock(Highcharts);
HighChartData(Highcharts);
HighChartMore(Highcharts);

interface ChartProps {
  data: any[];
  seriesOptions: ISeriesOptions;
  addSeriesHandler?: () => void;
  removeSeriesHandler?: (series: ISeries) => void;
  changeDateHandler?: (dateRange: [string, string]) => void;
  initialDateRange?: string[];
  addSeriesBtnClass?: string;
  isLoading?: boolean;
  showRangeSelector?: boolean;
  showDateSelector?: boolean;
  chartTitle?: string;
  containerStyle?: any;
  onSelectEmployee?: (value) => void;
  preFilterSection?: React.ReactNode;
  postFilterSection?: React.ReactNode;
  captionText?: string;
  addSeriesBtnText?: string;
  translate?: boolean;
  hideIfNoLastMonthData?: boolean;
}

const exportOptions = [
  { name: 'Print Chart', type: 'print' },
  { name: 'Download PNG image', type: 'image/png' },
  { name: 'Download JPEG image', type: 'image/jpeg' },
  { name: 'Download PDF document', type: 'application/pdf' },
  { name: 'Download SVG image', type: 'image/svg+xml' },
  { name: 'Download CSV', type: 'csv' },
  { name: 'Download XLS', type: 'xls' },
  { name: 'View Data Table', type: 'table' },
];
const RANGE_VALUE = [
  { type: 'month', maxRange: 3, text: '3m', range: Calendar.getRecentMonthBoundary(3) },
  { type: 'month', maxRange: 6, text: '6m', range: Calendar.getRecentMonthBoundary(6) },
  { type: 'year', maxRange: 12, text: '12m', range: Calendar.getRecentMonthBoundary(12) },
  { type: 'ytd', maxRange: 0, text: 'YTD', range: Calendar.getRecentMonthBoundary() },
];

export const MultiSeriesChart = React.forwardRef(
  (
    {
      showRangeSelector = true,
      showDateSelector = true,
      translate = false,
      hideIfNoLastMonthData = true,
      ...props
    }: ChartProps,
    _ref: any,
  ) => {
    const { theme } = React.useContext(ThemeContext);
    const ref: any = useRef(null);
    const [startMonth, setStartMonth] = useState<Date>(
      new Date(props?.initialDateRange?.[0] ?? Calendar.yearToDateBoundary[0]),
    );
    const [endMonth, setEndMonth] = useState<Date>(
      new Date(props?.initialDateRange?.[1] ?? Calendar.yearToDateBoundary[1]),
    );
    const [seriesCollection, setSeriesCollection] = useState<ISeries[]>([]);
    const seriesAdapter = React.useMemo(() => {
      return SeriesAdapter.load(props.seriesOptions, props.data, [startMonth, endMonth]);
    }, [props.seriesOptions, props.data, startMonth, endMonth]);

    const [updatedChart, setUpdatedChart] = useState<any>(
      theme === 'dark' ? seriesAdapter.darktModeConfig : seriesAdapter.lightModeConfig,
    );
    const [selectedDateRange, setSelectedDateRange] = useState<string[]>(Calendar.yearToDateBoundary);
    const [isTable, setIsTable] = useState<boolean>(false);
    let [formattedTableData, setFormattedTableData] = useState<any>([]);
    const changeDateHandler = props.changeDateHandler;
    const exportChart = (option) => {
      const chart = (ref!.current! as any).chart;

      if (option.type === 'print') {
        chart.print();
      } else if (option.type === 'csv') {
        chart.downloadCSV();
      } else if (option.type === 'xls') {
        chart.downloadXLS();
      } else if (option.type === 'table') {
        setIsTable(!isTable);
        !isTable ? (option.name = 'Hide Data Table') : (option.name = 'View Data Table');
      } else {
        chart.exportChart({
          type: option.type,
          filename: `${'my' + option.type}`,
        });
      }
    };

    const [hasCurrentMonthDataNotExist, setHasCurrentMonthDataNotExist] = useState(false);

    const calendarRangeOptions = useMemo(() => {
      return deepClone(RANGE_VALUE).map((item) => {
        return {
          ...item,
          range: getRecentDateRange(item.maxRange, hasCurrentMonthDataNotExist),
        };
      });
    }, [hasCurrentMonthDataNotExist]);

    const setRangeFromDuration = useCallback(
      (startMonth, endMonth) => {
        const foundRangeItem = calendarRangeOptions.find((rangeItem) => {
          return (
            rangeItem.range[0] === moment(startMonth).format('YYYY-MM') &&
            rangeItem.range[1] === moment(endMonth).format('YYYY-MM')
          );
        });

        if (foundRangeItem) {
          setSelectedDateRange(foundRangeItem.range);
        } else {
          setSelectedDateRange([]);
        }

        return foundRangeItem;
      },
      [calendarRangeOptions],
    );

    const setChartRange = (rangeItem) => {
      setSelectedDateRange(rangeItem.range);
      setStartMonth(new Date(moment(rangeItem.range[0]).format('YYYY-MM')));
      setEndMonth(new Date(moment(rangeItem.range[1]).format('YYYY-MM')));
    };

    const formatTableData = () => {
      setFormattedTableData(seriesAdapter.data);
    };

    const processRecentDatatsetByCurrentMonth = (dataset) => {
      const chartRef = (ref!.current! as any).chart;
      const latestMonthData = dataset.find((item) => {
        return Calendar.isCurrentMonth(item[0]);
      });

      const hasNoDataExist = (arr) => {
        return arr.slice(1).reduce((prev, curr) => prev + curr, 0) === 0;
      };

      if (latestMonthData && hasNoDataExist(latestMonthData)) {
        const prevMonthInstance = moment().subtract(1, 'month').startOf('month');

        setEndMonth(prevMonthInstance.toDate());
        setHasCurrentMonthDataNotExist(true);
        chartRef.redraw();
        formatTableData();
      } else {
        setHasCurrentMonthDataNotExist(false);
        chartRef.redraw();
        formatTableData();
      }
    };

    useEffect(() => {
      const chartRef = (ref!.current! as any).chart;

      chartRef.redraw();

      if (Calendar.isCurrentMonth(endMonth)) {
        processRecentDatatsetByCurrentMonth(seriesAdapter.data);
      }
    }, [seriesAdapter.data]);

    // Set initial visibility of the chart series
    useEffect(() => {
      const chartRef = (ref!.current! as any).chart;
      seriesAdapter.series.forEach((seriesItem) => {
        if (!seriesItem.checked) {
          chartRef.series[seriesItem!.seriesIndex!].setVisible(false);
        }
      });
    }, []);

    useEffect(() => {
      const chartRef = (ref!.current! as any).chart;

      if (changeDateHandler) {
        changeDateHandler!([
          moment(startMonth).startOf('month').format('YYYY-MM-DD'),
          moment(endMonth).startOf('month').format('YYYY-MM-DD'),
        ]);
      }

      setRangeFromDuration(startMonth, endMonth);
      chartRef.xAxis[0].setExtremes(
        new Date(moment(startMonth).startOf('month').format('YYYY-MM-DD')).getTime(),
        new Date(moment(endMonth).startOf('month').format('YYYY-MM-DD')).getTime(),
        true,
      );
    }, [startMonth, endMonth]);

    useEffect(() => {
      const chartSeries = seriesAdapter.series.map((element, index) => ({
        ...element,
        color:
          theme === 'default'
            ? seriesAdapter.series[index].style!.defaultColor
            : seriesAdapter.series[index].style!.darkColor,
      }));

      theme === 'dark'
        ? setUpdatedChart(seriesAdapter.darktModeConfig)
        : setUpdatedChart(seriesAdapter.lightModeConfig);
      setSeriesCollection(chartSeries);
    }, [theme, seriesAdapter.series]);

    return (
      <>
        {props.preFilterSection && (
          <>
            <div className="">{props.preFilterSection}</div>
          </>
        )}
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="chart-series-filter align-items-center">
            {seriesAdapter.showToggleButtons && (
              <ChartSwitchFilter
                ref={ref}
                seriesCollection={seriesCollection}
                series={seriesAdapter.series}
                totalSeriesItem={seriesAdapter.totalSeriesItem}
                initialChecked={seriesAdapter.initialChecked}
                shouldToggleTotal={seriesAdapter.shouldToggleTotal}
                allowDeleteSeries={seriesAdapter.allowDeleteSeries}
                removeSeriesHandler={props.removeSeriesHandler}
                translate={translate}
              />
            )}

            {seriesAdapter.allowAddSeries && (
              <span
                className={classNames('chart-series-filter__add-btn', seriesAdapter.series.length < 2 && 'p-0')}
                onClick={props!.addSeriesHandler}
              >
                <UserPlus className="theme-text icon-gap-right" />
                <span className="theme-text">{props.addSeriesBtnText || t('add_employee')}</span>
              </span>
            )}
            {props.chartTitle && (
              <div className="d-none d-sm-block col-auto">
                <h3 className="fs-h3 theme-text">{props.chartTitle}</h3>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <Dropdown className="nav-item" align="end">
              <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
                <Button className="app-btn-secondary">
                  <Menu className="theme-text" />
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {exportOptions.map((option, index) => (
                  <Dropdown.Item key={index} onClick={() => exportChart(option)}>
                    {option.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {props.postFilterSection && <div className="mb-2">{props.postFilterSection}</div>}
        <div className="mb-2 d-flex">
          {showDateSelector && (
            <div className="d-flex  me-4">
              <CalenderInput
                startDate={startMonth}
                onChangeHandler={(date) => setStartMonth(date)}
                dateFormat="MMM-yyyy"
                showMonthYearPicker={true}
                datepickerWidth={150}
                maxDate={endMonth}
              />
              <ArrowRight className="mx-2 mt-2" />
              <CalenderInput
                startDate={endMonth}
                onChangeHandler={(date) => setEndMonth(date)}
                dateFormat="MMM-yyyy"
                showMonthYearPicker={true}
                datepickerWidth={150}
                minDate={startMonth}
                className="end-date"
              />
            </div>
          )}
          {showRangeSelector && (
            <div>
              {calendarRangeOptions.map((rangeItem, index) => (
                <Button
                  key={index}
                  onClick={() => setChartRange(rangeItem)}
                  className={classNames(
                    'app-btn-white',
                    'me-2',
                    activeStyleByMonthRange(selectedDateRange, rangeItem.range),
                  )}
                >
                  {rangeItem.text}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className="chart-parent" style={props.containerStyle}>
          {props.isLoading && (
            <div className="spinner-wrapper spinner-wrapper--overlay">
              <div className="spinner-card">
                <Spinner className="icon-gap-right" />
                <span className="loading-text">Loading...</span>
              </div>
            </div>
          )}
          {props.captionText && <h3 className="theme-text font-weight-bold mt-3">{props.captionText}</h3>}
          <HighchartsReact
            highcharts={Highcharts}
            options={{ ...updatedChart, data: { rows: seriesAdapter.data } }}
            updateArgs={[true]}
            oneToOne={true}
            ref={ref}
          />
          {isTable && <ChartTable tableData={formattedTableData} selectedSeries={seriesCollection} />}
        </div>
      </>
    );
  },
);
MultiSeriesChart.displayName = 'MultiSeriesChart';
