import React from 'react';
import PagesHeader from '../../../../components/base/PagesHeader';
import { MultiSeriesChart } from '../../../../components/charts/multi-series/MultiSeriesChart';
import { EmployeeModal } from '../components/EmployeeModal/EmployeeModal';
import { getCompanySpecialPayrollTaxReports } from '../../../../api/service/company/reports/service';
import { useEmployeeModal } from '../components/EmployeeModal/hooks/useEmployeeModal';
import useComparisonSeriesChart from '../hooks/useComparisonSeriesChart';
import { useTranslation } from 'react-i18next';

export const SpecialPayrollTaxChart = () => {
  const { t } = useTranslation();
  const {
    seriesOptions,
    employeeSeries,
    dateRange,
    chartRecords,
    isLoading,
    changeDateHandler,
    addEmployeeToChart,
    deleteHandler,
  } = useComparisonSeriesChart(getCompanySpecialPayrollTaxReports, { fieldKey: 'tax' });

  const { showEmployeeModal, toggleModal, addSeriesHandler } = useEmployeeModal();

  return (
    <>
      <PagesHeader pageTitle="Comparison" headingClass="fs-page-title" />
      <p className="theme-text">{t('selected_employees_for_comparison')}</p>
      <div className="chart mt-4">
        <MultiSeriesChart
          seriesOptions={seriesOptions}
          data={chartRecords}
          addSeriesHandler={addSeriesHandler}
          removeSeriesHandler={deleteHandler}
          initialDateRange={dateRange}
          changeDateHandler={changeDateHandler}
          isLoading={isLoading}
        />
      </div>
      {showEmployeeModal && (
        <EmployeeModal
          showEmployeeModal={showEmployeeModal}
          toggleModal={toggleModal}
          employeeList={employeeSeries}
          addEmployeeToChart={addEmployeeToChart}
        />
      )}
    </>
  );
};
