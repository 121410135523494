import { useEffect } from 'react';
import { MultiSeriesChart } from '../../../../components/charts/multi-series/MultiSeriesChart';
import CustomChartSwitchFilter from './CustomChartSwitchFilter';
import useCustomEmployeeReportCategoryChart from './hooks/useCustomEmployeeReportCategoryChart';
import { ISeries } from '../../../../components/charts/multi-series/types';
import { useTranslation } from 'react-i18next';

type Props = {
  selectedEmployee: ISeries;
  selectedReportTypeSeries: ISeries[];
  employeeOptions: any;
  selectedDateRange: [string, string] | [];
  addReportSeriesHandler: (...args) => void;
  removeReportSeriesHandler: (...args) => void;
  updateCalendarDateRange: (...args) => void;
};

export const EmployeeSelectorChart = ({
  selectedReportTypeSeries,
  selectedEmployee,
  employeeOptions,
  selectedDateRange,
  addReportSeriesHandler,
  removeReportSeriesHandler,
  updateCalendarDateRange,
}: Props) => {
  const { t } = useTranslation();
  const { seriesOptions, dateRange, chartRecords, isLoading, changeDateHandler, deleteHandler } =
    useCustomEmployeeReportCategoryChart(selectedEmployee, selectedReportTypeSeries, selectedDateRange);

  const onRemoveReportTypeSeries = (reportSeries: ISeries) => {
    removeReportSeriesHandler(reportSeries);
    deleteHandler(reportSeries);
  };

  useEffect(() => {
    updateCalendarDateRange(dateRange);
  }, [dateRange]);
  return (
    <>
      <div>
        {/* <p className="theme-text mb-2">
          <span className="fs-name">Employee drilling types</span> (Click employee in chart for drilling down)
        </p> */}
        <MultiSeriesChart
          captionText={selectedEmployee?.label}
          seriesOptions={{ ...seriesOptions }}
          data={chartRecords}
          removeSeriesHandler={onRemoveReportTypeSeries}
          initialDateRange={dateRange}
          changeDateHandler={changeDateHandler}
          isLoading={isLoading}
          addSeriesBtnText={`${t('add_type')}`}
          addSeriesHandler={addReportSeriesHandler}
          translate={true}
          preFilterSection={
            <div className="mb-2">
              <CustomChartSwitchFilter options={employeeOptions} addBtnText={`${t('add_employee')}`} />
              <p className="theme-text mt-3 mb-2">
                <div className="inner-text">
                  <span className="fs-name">{t('employee_drilling_types')}</span> ({t('drill_down_on_employee')})
                </div>
              </p>
            </div>
          }
        ></MultiSeriesChart>
      </div>
    </>
  );
};
