import React from 'react';
import { SetStateAction, Dispatch } from 'react';
import BSModal from '../../../../../components/Modal';
import { useTranslation } from 'react-i18next';
import { useValidationError } from '../../../../../hooks/useValidationError';
import { Form } from 'react-bootstrap';
import ValidationAlert from '../../../../../components/ValidationAlert';
import useContext from '../context/useContext';
import { INITIAL_CONTACT_INFORMATION, INITIAL_ACCOUNTING_SALARY_COMPANY } from '../constants';
import EditAccountingSalaryCompany from '../EditAccountingSalaryCompany';
import { CreateAccountingSalaryCompanyModalFooter } from './CreateAccountingCompanyModalFooter';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsAccountingSalaryCompanyCreated: Dispatch<SetStateAction<boolean>>;
};

export const CreateAccountingSalaryCompanyModal = ({
  showCreateModal,
  toggleModal,
  setIsAccountingSalaryCompanyCreated,
}: Props) => {
  const { isInvalid, errors, setErrors } = useValidationError();
  const { setAccountingSalaryCompany, setContactInformation } = useContext();
  const { t } = useTranslation();

  return (
    <>
      <BSModal
        title={`${t('add_accouting_and_salary_company')}`}
        show={showCreateModal}
        onHide={() => {
          setAccountingSalaryCompany(INITIAL_ACCOUNTING_SALARY_COMPANY);
          setContactInformation(INITIAL_CONTACT_INFORMATION);
          toggleModal();
        }}
        centered
        ModalFooter={
          <CreateAccountingSalaryCompanyModalFooter
            setIsAccountingSalaryCompanyCreated={setIsAccountingSalaryCompanyCreated}
            toggleModal={toggleModal}
            setErrors={setErrors}
          />
        }
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />
          <EditAccountingSalaryCompany />
        </Form>
      </BSModal>
    </>
  );
};
