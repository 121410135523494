import { Button, Form } from 'react-bootstrap';
import { Send } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { FormProps } from '../context/EmployeePensionContext';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import classNames from 'classnames';
import { errorHighlighter } from '../../../../util/form';
import moment from 'moment';
import useLocalizationResolver from '../../../../hooks/useLocalizationResolver';
import { PercentageNumber } from '../../../../components/form/inputs/PercentangeNumber';
import { useState } from 'react';

function PersonalInfo({ context }: FormProps) {
  // const [endDate, setEndDate] = useState('');
  const { formGroup, updateEmployeeDetails, employee } = context;
  const { t } = useTranslation();
  const { getLang } = useLocalizationResolver();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    updateEmployeeDetails(name, value);
  };

  const handleScopeOfWork = (value) => {
    updateEmployeeDetails('scopeOfWork', value);
  };

  const [selectedOption, setSelectedOption] = useState(employee?.employmentFrom || '');

  const EmployementFromHandleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <h3 className="fs-22px theme-text mt-1 mb-2 font-weight-600"> {t('personal_info')}</h3>

      <Form>
        <div className="dark-content-section">
          <div className="cr-form-row">
            <Form.Group className="full-name-field">
              <Form.Label className="fs-name">{t('full_name')}</Form.Label>
              <Form.Control disabled name="Full Name" value={employee?.fullName} />
            </Form.Group>

            <Form.Group className="personal-num-field-two">
              <Form.Label className="fs-name">{t('personal_number')}</Form.Label>
              <Form.Control
                disabled
                //  placeholder="Enter Personal number"
                name="Personal number"
                value={employee?.personalNumber}
              />
            </Form.Group>
          </div>

          <div className="cr-form-row">
            <Form.Group className="address-field">
              <Form.Label className="fs-name">{t('address')}</Form.Label>
              <Form.Control
                disabled
                //  placeholder="Enter Address"
                name="Address"
                value={employee ? employee['addresses'][0]['street'] : ''}
              />
            </Form.Group>

            <Form.Group className="post-code-field">
              <Form.Label className="fs-name">{t('postcode')}</Form.Label>
              <Form.Control
                disabled
                //  placeholder="Enter Postcode"
                name="Postcode"
                value={employee ? employee['addresses'][0]['postcode'] : ''}
              />
            </Form.Group>

            <Form.Group className="city-field">
              <Form.Label className="fs-name">{t('city')}</Form.Label>
              <Form.Control
                disabled
                //  placeholder="Enter City"
                name="City"
                value={employee ? employee['addresses'][0]['city'] : ''}
              />
            </Form.Group>

            <Form.Group className="country-field">
              <Form.Label className="fs-name">{t('country')}</Form.Label>
              <Form.Control
                disabled
                //  placeholder="Enter Country"
                name="Country"
                value={employee ? employee['addresses'][0]['country'] : ''}
              />
            </Form.Group>
          </div>

          <div className="cr-form-row">
            <Form.Group className="phone-num-work-field">
              <Form.Label className="fs-name">{`${t('phone_number')} (${t('work')})`}</Form.Label>
              <Form.Control
                value={employee?.phoneNumberWork}
                name="phoneNumberWork"
                type="number"
                onChange={handleChange}
                className={classNames(errorHighlighter(formGroup.getFieldMeta('phoneNumberWork')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('phoneNumberWork')} />
            </Form.Group>
            <Form.Group className="phone-num-work-field">
              <Form.Label className="fs-name">{`${t('phone_number')} (${t('private')})`}</Form.Label>
              <Form.Control
                value={employee?.phoneNumberPrivate}
                name="phoneNumberPrivate"
                type="number"
                onChange={handleChange}
                className={classNames(errorHighlighter(formGroup.getFieldMeta('phoneNumberPrivate')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('phoneNumberPrivate')} />
            </Form.Group>
            <Form.Group className="email-field">
              <Form.Label className="fs-name">{`${t('email_address_work')}`}</Form.Label>
              <Form.Control
                value={employee?.email}
                type="email"
                name="email"
                onChange={handleChange}
                className={classNames(errorHighlighter(formGroup.getFieldMeta('email')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('email')} />
            </Form.Group>

            <Form.Group className="status-small-field">
              <Form.Label className="fs-name">{t('status')}</Form.Label>
              <Form.Control
                disabled
                //  placeholder="Enter Status"
                name="Status"
                value={employee?.importInformation?.activeOnEMS ? 'Active' : 'Inactive'}
              />
            </Form.Group>
            <Button className="app-btn-primary mt-3">
              <Send className="icon-gap-right" /> {t('send_invitation')}
            </Button>
          </div>
        </div>
        <h3 className="fs-22px theme-text mt-3 mb-2 font-weight-600"> {t('employment')}</h3>
        <div className="dark-content-section">
          <div className="cr-form-row">
            <Form.Group className="start-date-small-field">
              <Form.Label className="fs-name">{t('start_date')}</Form.Label>
              <Form.Control
                disabled
                type="text"
                //  placeholder="Enter Start Date"
                name="Start Date"
                value={moment(employee?.employmentDate).format('YYYY-MM-DD')}
              />
            </Form.Group>

            <Form.Group className="employment-num-field">
              <Form.Label className="fs-name">{t('employment_number')}</Form.Label>
              <Form.Control disabled name="Employment number" value="331" />
            </Form.Group>

            <Form.Group className="employment_status-field">
              <Form.Label className="fs-name">{`${t('EMP_OVERVIEW.employment_status')}`}</Form.Label>
              <Form.Control disabled type="text" value={getLang(employee?.employmentType)} />
            </Form.Group>
            <Form.Group className="cost-center-field">
              <Form.Label className="fs-name">{t('cost_center')}</Form.Label>
              <Form.Control name="costCenter" onChange={handleChange} value={employee?.costCenter || ''} />
            </Form.Group>
            <Form.Group className="employment-type-field">
              <Form.Label className="fs-name">{`${t('employment_type')}`}</Form.Label>
              <Form.Select disabled onChange={handleChange} name="personnelType" value={employee?.personnelType}>
                <option value="None">{t('none')}</option>
                <option value="BlueCollar">{t('blue_collar')}</option>
                <option value="WhiteCollar">{t('white_collar')}</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="cr-form-row">
            <Form.Group className="employment-from-field">
              <Form.Label className="fs-name">{`${t('employment_from')}`}</Form.Label>
              <Form.Select onChange={EmployementFromHandleChange} name="personnelType" value={selectedOption}>
                <option value="None">{t('none')}</option>
                <option value="PostWithConditionalTenure">{t('post_with_conditional_tenure')}</option>
                <option value="ProbationaryAppointment">{t('probationary_appointment')}</option>
                <option value="GeneralTemporaryEmployment">{t('general_temporary_employment')}</option>
                <option value="SpecialFixedTermEmployment">{t('special_fixed_term_employment')}</option>
                <option value="CoverStaff">{t('cover_staff')}</option>
                <option value="ProjectEmployment">{t('project_employment')}</option>
                <option value="WorkExperience">{t('work_experience')}</option>
                <option value="HolidayWork">{t('holiday_work')}</option>
                <option value="SeasonalEmployment">{t('seasonal_employment')}</option>
                <option value="NotEmployee">{t('not_employee')}</option>
              </Form.Select>
            </Form.Group>
            {selectedOption && selectedOption !== 'None' && (
              <Form.Group className="start-date-calender-field">
                <Form.Label className="fs-name">{t('start_date')}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Start Date"
                  name="Start Date"
                  className="calender-field"
                  value={moment(employee?.employmentDate).format('YYYY-MM-DD')}
                />
              </Form.Group>
            )}
            <Form.Group className="job-title-field">
              <Form.Label className="fs-name">{`${t('job_title')}`}</Form.Label>
              <Form.Control disabled type="text" value={getLang(employee?.jobTitle)} />
            </Form.Group>

            <Form.Group>
              <PercentageNumber
                label={`${t('scope_of_work')} (%)`}
                name="scopeOfWork"
                value={employee.scopeOfWork}
                onChangeHandler={(ev) => handleScopeOfWork(ev)}
                decimalPlace={2}
                className={classNames('text-right')}
              />
            </Form.Group>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default PersonalInfo;
