import { useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './Workflow.module.scss';

import PagesHeader from '../../../../components/base/PagesHeader';
import CardComponent from '../../../../components/base/CardComponent';
import { WorkflowRef, WorkflowTypeKey } from '../types';

import useWorkflowEditor from './useWorkflowEditor';

interface InputProps {
  emitValues: (...args) => void;
}

const Workflow = ({ emitValues }: InputProps) => {
  const { t } = useTranslation();
  const workflowEditor = useWorkflowEditor();

  const handleSelectedPensionPolicy = (e: React.ChangeEvent<HTMLSelectElement>, workflowType: WorkflowTypeKey) => {
    const value = e.target.value;

    workflowEditor.handleFormInput(workflowType, 'policyId', value);
  };

  useEffect(() => {
    workflowEditor.fetchPolicyAssignmentSettings();
    workflowEditor.fetchAllPensionPolicy();
  }, []);

  useEffect(() => {
    emitValues({
      isSavingWorkflowChanges: workflowEditor.isLoading,
      saveWorkFlowChanges: workflowEditor.saveChanges,
      cancelWorkFlowChanges: workflowEditor.cancelChanges,
      disableSaveChangesBtn: workflowEditor.disableSaveChangesBtn,
    } as WorkflowRef);
  }, [
    workflowEditor.saveChanges,
    workflowEditor.isLoading,
    workflowEditor.cancelChanges,
    workflowEditor.disableSaveChangesBtn,
  ]);

  return (
    <>
      <CardComponent>
        <div className="d-inline-flex">
          {workflowEditor.isFetching && <Spinner size="sm" className="me-2" />}
          <PagesHeader pageTitle="Work flow" headingClass="fs-page-title" />
        </div>
        <PagesHeader pageTitle={t('WORKFLOW.pension_policy_assignment')} translate={false} />
        <p className="theme-text">{t('WORKFLOW.employee_policy_validate_text')}</p>
        <div>
          <span className="fs-name theme-text fs-14px">{t('automatic')}</span>
          <p className="theme-text">{t('WORKFLOW.one_policy_per_employee_text')}</p>
        </div>
        <div>
          <span className="fs-name theme-text fs-14px">{t('manual')}</span>
          <p className="theme-text">{t('WORKFLOW.notification_sent_to_admin_text')} </p>
        </div>

        <div className={classNames('d-flex justify-content-between mt-4', styles.workflow_content)}>
          <div>
            <h4 className="theme-text fs-h4 text-bold">{t('WORKFLOW.white_collar_auto_assign')}</h4>
            <div className="d-flex mt-2 align-items-start">
              <Form.Check
                inline
                type="radio"
                label={t('manual')}
                id="wcpAuto2"
                name="wcpAuto"
                className="fs-name mb-1"
                onChange={(e) => workflowEditor.handleFormInput('whiteCollarPolicy', 'automatic', false)}
                value={'false'}
                checked={workflowEditor.workflowFormGroup.whiteCollarPolicy.automatic.toString() === 'false'}
                disabled={workflowEditor.isFetching}
              />
              <Form.Check
                inline
                type="radio"
                label={t('automatic')}
                id="wcpAuto1"
                name="wcpAuto"
                className="fs-name mb-1 me-3"
                onChange={(e) => workflowEditor.handleFormInput('whiteCollarPolicy', 'automatic', true)}
                value={'true'}
                checked={workflowEditor.workflowFormGroup.whiteCollarPolicy.automatic.toString() === 'true'}
                disabled={workflowEditor.isFetching}
              />
              {workflowEditor.workflowFormGroup.whiteCollarPolicy.automatic && (
                <Form.Group className="ms-4">
                  <Form.Label className="fs-name mb-0 ">{t('pension_policy')}</Form.Label>

                  <Form.Select
                    style={{ width: 200 }}
                    onChange={(e) => handleSelectedPensionPolicy(e, 'whiteCollarPolicy')}
                    value={workflowEditor.workflowFormGroup.whiteCollarPolicy.policyId}
                  >
                    <option value="" disabled>
                      {t('please_select')}
                    </option>
                    {workflowEditor.inputOptionList.whiteCollarPolicies?.map((policy) => (
                      <option value={policy._id || policy.id} key={policy._id || policy.id}>
                        {policy.policyName}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
            </div>
          </div>
        </div>

        <div className={classNames('mt-4', styles.workflow_content)}>
          <h4 className="theme-text fs-h4 text-bold">{t('WORKFLOW.blue_collar_auto_assign')}</h4>
          <div className="d-flex mt-2 align-items-start">
            <Form.Check
              inline
              type="radio"
              label={t('manual')}
              id="bcpAuto2"
              name="bcpAuto"
              className="fs-name mb-1"
              onChange={(e) => workflowEditor.handleFormInput('blueCollarPolicy', 'automatic', false)}
              value={'false'}
              checked={workflowEditor.workflowFormGroup.blueCollarPolicy.automatic.toString() === 'false'}
              disabled={workflowEditor.isFetching}
            />
            <Form.Check
              inline
              type="radio"
              label={t('automatic')}
              id="bcpAuto1"
              name="bcpAuto"
              className="fs-name mb-1 me-3"
              onChange={(e) => workflowEditor.handleFormInput('blueCollarPolicy', 'automatic', true)}
              value={'true'}
              checked={workflowEditor.workflowFormGroup.blueCollarPolicy.automatic.toString() === 'true'}
              disabled={workflowEditor.isFetching}
            />
            {workflowEditor.workflowFormGroup.blueCollarPolicy.automatic && (
              <Form.Group className="ms-4">
                <Form.Label className="fs-name mb-0 ">{t('pension_policy')}</Form.Label>

                <Form.Select
                  style={{ width: 200 }}
                  onChange={(e) => handleSelectedPensionPolicy(e, 'blueCollarPolicy')}
                  value={workflowEditor.workflowFormGroup.blueCollarPolicy.policyId}
                >
                  <option value="" disabled>
                    {t('please_select')}
                  </option>
                  {workflowEditor.inputOptionList.blueCollarPolicies?.map((policy) => (
                    <option value={policy._id || policy.id} key={policy._id || policy.id}>
                      {policy.policyName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          </div>
        </div>
      </CardComponent>
    </>
  );
};

export default Workflow;
