import React from 'react';

import { SidebarItemsType } from '../../types/sidebar';
import TopMenuItemLink from './TopMenuItemLink';

interface TopMenuItemProps {
  title?: string;
  pages: SidebarItemsType[];
  children?: React.ReactNode;
}

const TopMenuItem = (props: TopMenuItemProps) => {
  const navItemLinks = props.pages.map((page, idx) => {
    return <TopMenuItemLink key={idx} page={page} />;
  });

  return <>{navItemLinks}</>;
};

export default TopMenuItem;
