import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { CornerDownRight, UserX, UserPlus, RefreshCw, Send } from 'react-feather';
import { getRoaringPersonInfo } from '../../../../../api/service/superAdmin/company-clients/service';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import HorizontalRule from '../../../../../components/base/HorizontalRule';
import PagesHeader from '../../../../../components/base/PagesHeader';
import PersonalNumber from '../../../../../components/form/inputs/PersonalNumber';
import { requiredError, errorHighlighter } from '../../../../../util/form';
import { isEmpty } from '../../../../../util/util';
import classNames from 'classnames';
import { CompanyClientSaveMode, FormProps } from './types';
import { DEFAULT_PERSON_INFO } from '../../../../../types/general';
import Avatar from '../../../../../components/avatar/Avatar';
import TooltipBox from '../../../../../components/base/Overlay/TooltipBox';

export const AdminDetails = ({ context }: FormProps) => {
  const {
    adminDetails,
    isEditMode,
    formGroup,
    replaceAdminDetails,
    appendDeletedAdmin,
    signatoryAdmins,
    replaceSignatoryAdmins,
    combinations,
    companyDetails,
  } = context;
  const { t } = useTranslation();

  const [, /*isPersonalNumberValid*/ setIsPersonalNumberValid] = useState<boolean>(true);
  const [selectedSignatoryCombination, setSelectedSignatoryCombination] = useState<number>();
  const isAllowedSignatoryOption =
    [CompanyClientSaveMode.SETUP, CompanyClientSaveMode.ACTIVE].indexOf(
      companyDetails?.status as CompanyClientSaveMode,
    ) !== -1;

  const handleAdminCompany = (e, _id, index) => {
    const _adminDetails = [...adminDetails];
    const foundDetail = _adminDetails.find((i) => i['_id'] === _id);
    foundDetail![e.target.name] = e.target.value;
    replaceAdminDetails(_adminDetails);
    setIsPersonalNumberValid(true);
  };

  const handleRemoveSignatoryAdmin = (removedIndex) => {
    const filterSignatoryAdmins = signatoryAdmins.filter((i, index) => index !== removedIndex);

    replaceSignatoryAdmins(filterSignatoryAdmins);
  };

  const setPersonalInfo = (form, fullName) => {
    const _adminDetails = [...adminDetails];
    const foundDetail = _adminDetails.find((i) => i['_id'] === form['_id']);
    foundDetail!.fullName = fullName;
    replaceAdminDetails(_adminDetails);
    setIsPersonalNumberValid(true);
  };

  const loadDetails = async (form) => {
    try {
      setPersonalInfo(form, DEFAULT_PERSON_INFO.fullName);
      const { fullName } = await getRoaringPersonInfo(form.personalNumber);
      setPersonalInfo(form, fullName);
      form['valid'] = true;
    } catch (e) {
      form['valid'] = false;
      setIsPersonalNumberValid(false);
    }
  };

  const onAddNew = () => {
    replaceAdminDetails([
      ...adminDetails,
      { _id: `${Math.random()}`, personalNumber: '', email: '', fullName: '', hasNotExist: true },
    ]);
  };

  const onRemoveAdmin = (form, idx: number) => {
    const _adminDetails = [...adminDetails];

    const findValidAdmin = _adminDetails.find((i) => i['_id'] === form['_id']);
    const filteredAdmins = _adminDetails.filter((i) => i['_id'] !== form['_id']);

    replaceAdminDetails(filteredAdmins);

    if (findValidAdmin?._id && !findValidAdmin?.hasNotExist) {
      appendDeletedAdmin(_adminDetails[idx]._id || _adminDetails[idx].id);
    }
  };

  useEffect(() => {
    const combination = combinations.find((signatory) => signatory.signee === true);
    const selectedIndex = combinations.findIndex((signatory) => signatory.signee === true);

    if (combination) {
      setSelectedSignatoryCombination(selectedIndex! as number);
      if (
        signatoryAdmins.length === combination.authorizedSignatory.length ||
        selectedSignatoryCombination !== selectedIndex
      ) {
        if (isEditMode && signatoryAdmins.length === 0) {
          replaceSignatoryAdmins(
            combination.authorizedSignatory
              .filter((signatory) => adminDetails.find((item) => item.personalNumber === signatory.personalNumber))
              .map((item) => {
                return {
                  email: item.email,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  fullName: item.fullName,
                  personalNumber: item.personalNumber,
                  position: item.position,
                };
              }),
          );
        } else {
          replaceSignatoryAdmins(
            combination.authorizedSignatory.map((item) => {
              return {
                email: item.email,
                firstName: item.firstName,
                lastName: item.lastName,
                fullName: item.fullName,
                personalNumber: item.personalNumber,
                position: item.position,
              };
            }),
          );
        }
      } else {
        const selectedAuthorizedSignatories = combination.authorizedSignatory.filter((signatory) => {
          return signatoryAdmins.find((admin) => admin.personalNumber === signatory.personalNumber);
        });
        replaceSignatoryAdmins(
          selectedAuthorizedSignatories.map((item) => {
            return {
              email: item.email,
              firstName: item.firstName,
              lastName: item.lastName,
              fullName: item.fullName,
              personalNumber: item.personalNumber,
              position: item.position,
            };
          }),
        );
      }
    }
  }, [combinations]);
  return (
    <div>
      <PagesHeader pageTitle="Admin details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {!isAllowedSignatoryOption && signatoryAdmins.length > 0 && (
          <Form.Label className="fs-name mb-3">{t('authorized_signatory')}</Form.Label>
        )}
        {!isAllowedSignatoryOption &&
          signatoryAdmins.map(
            (signatory, idx) =>
              signatory.fullName !== '' && (
                <div key={`signatory-admin-list-${idx}`}>
                  <Row className="align-items-center mb-3" key={idx}>
                    <Col md="3">
                      <Avatar name={signatory.fullName} displayName />
                    </Col>
                    <Col style={{ maxWidth: 170 }}>
                      <span className="theme-text fs-14px">{signatory.personalNumber}</span>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <span className="me-2">
                        <TooltipBox msg={`${t('delete_user')}`}>
                          <UserX
                            onClick={() => {
                              handleRemoveSignatoryAdmin(idx);

                              if (signatoryAdmins[idx]._id || signatoryAdmins[idx].id) {
                                appendDeletedAdmin(signatoryAdmins[idx]._id || signatoryAdmins[idx].id);
                              }
                            }}
                            className="cursor-pointer delete-icon"
                          />
                        </TooltipBox>
                      </span>
                    </Col>
                  </Row>
                  <HorizontalRule color="#C6C6C6" height="1px" />
                </div>
              ),
          )}
        {adminDetails.map((form, idx) => (
          <div key={form._id}>
            {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

            <div className="cr-form-row">
              <PersonalNumber
                disabled={false}
                maxWidth={137}
                value={form?.personalNumber}
                onChangeHandler={(e) => handleAdminCompany(e, form['_id'], idx)}
                isRequiredError={true}
                field={formGroup.getFieldMeta(`admins[${idx}].personalNumber`)}
                isValid={form['valid']}
                lazy={true}
              />

              <div>
                <Button
                  disabled={isEmpty(formGroup.values.admins[idx]?.personalNumber)}
                  variant="primary"
                  onClick={() => loadDetails(form)}
                  className="app-btn-primary"
                >
                  {isEditMode ? (
                    <RefreshCw className="icon-gap-right" />
                  ) : (
                    <CornerDownRight className="icon-gap-right" />
                  )}{' '}
                  {isEditMode ? t('refresh') : t('fetch_name')}
                </Button>
              </div>
              <div className="full-name-field">
                <Form.Label
                  className={classNames(
                    'fs-name mb-0',
                    requiredError(formGroup.getFieldMeta(`admins[${idx}].fullName`)),
                  )}
                >
                  {t('name')}
                </Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="fullName"
                  value={form?.fullName}
                  disabled
                  className={classNames(
                    'text-left',
                    errorHighlighter(formGroup.getFieldMeta(`admins[${idx}].fullName`)),
                  )}
                />
                <ErrorFeedback field={formGroup.getFieldMeta(`admins[${idx}].fullName`)} />
              </div>
              <div className="email-field">
                <Form.Group>
                  <Form.Label
                    className={classNames(
                      'fs-name mb-0',
                      requiredError(formGroup.getFieldMeta(`admins[${idx}].email`)),
                    )}
                  >{`${t('email_address')}`}</Form.Label>
                  <Form.Control
                    value={form?.email}
                    size="lg"
                    type="text"
                    onChange={(e) => handleAdminCompany(e, form['_id'], idx)}
                    name="email"
                    className={classNames(
                      'text-left',
                      errorHighlighter(formGroup.getFieldMeta(`admins[${idx}].email`)),
                    )}
                  />
                  <ErrorFeedback field={formGroup.getFieldMeta(`admins[${idx}].email`)} />
                </Form.Group>
              </div>
              {isEditMode && (
                <div>
                  <Button className="app-btn-secondary">
                    <Send className="icon-gap-right" /> {t('resend_invite')}
                  </Button>
                </div>
              )}

              <div className="last-button-box my-2">
                <TooltipBox msg={`${t('delete_user')}`}>
                  <UserX onClick={() => onRemoveAdmin(form, idx)} className="cursor-pointer delete-icon" />
                </TooltipBox>
              </div>
            </div>
          </div>
        ))}

        <Button onClick={onAddNew} className="app-btn-secondary">
          <UserPlus className="theme-text" />
        </Button>
      </div>
    </div>
  );
};
