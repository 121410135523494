import { useState } from 'react';
import PagesHeader from '../../../components/base/PagesHeader';
import DataManagementGrid from './DataManagementGrid';
import FileUploadModal from './FileUploadModal';
import { Button, Tabs, Tab } from 'react-bootstrap';
import { UploadCloud, RefreshCw } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import Breadcrumb from '../../../components/base/Breadcrumb';

export default function DataManagement() {
  const { t } = useTranslation();
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
    },
    {
      name: 'Cost validation',
    },
  ];

  const toggleModal = () => setShowFileUploadModal(!showFileUploadModal);

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader pageTitle="Cost validation" headingClass="fs-h1" />
        <div className='cr-form-row'>
          <Button className="app-btn-secondary">
            <RefreshCw className="icon-gap-right" /> {`${t('refresh_data')}`}
          </Button>
          <Button onClick={toggleModal} className="app-btn-primary">
            <UploadCloud className="icon-gap-right" /> {`${t('upload_file(s)')}`}
          </Button>
        </div>
      </div>
      {showFileUploadModal && (
        <FileUploadModal
          isUploaded={isUploaded}
          show={showFileUploadModal}
          onUploaded={setIsUploaded}
          toggleModal={toggleModal}
        />
      )}
      <Tabs defaultActiveKey="general">
        <Tab eventKey="general" title={t('salary_documents')}>
          <DataManagementGrid isUploaded={isUploaded} />
        </Tab>
        {/* <Tab eventKey="billing" title={t('supplier_invoices')}></Tab> */}
      </Tabs>
    </>
  );
}
