import BSModal from '../../../../../components/Modal';
import { useValidationError } from '../../../../../hooks/useValidationError';
import { Form } from 'react-bootstrap';
import ValidationAlert from '../../../../../components/ValidationAlert';
import useContext from '../context/useContext';
import useLocale from '../../../../../hooks/useLocale';
import EditIntegration from '../EditIntegration';
import { CreateIntegrationModalFooter } from './CreateIntegrationModalFooter';
import { INITIAL_INTEGRATION_INFORMATION, INITIAL_TYPE_OF_INTEGRATION } from '../constants';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
};

export const CreateIntegrationModal = ({ showCreateModal, toggleModal }: Props) => {
  const { isInvalid, errors, setErrors } = useValidationError();
  const { replaceIntegrationInformation, replaceTypeOfIntegration } = useContext();
  const { localize } = useLocale();
  return (
    <>
      <BSModal
        title={`${localize('add_integration')}`}
        show={showCreateModal}
        onHide={() => {
          replaceIntegrationInformation(INITIAL_INTEGRATION_INFORMATION);
          replaceTypeOfIntegration(INITIAL_TYPE_OF_INTEGRATION);
          toggleModal();
        }}
        centered
        ModalFooter={<CreateIntegrationModalFooter toggleModal={toggleModal} setErrors={setErrors} />}
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />
          <EditIntegration />
        </Form>
      </BSModal>
    </>
  );
};
