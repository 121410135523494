import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import ErrorFeedback from '../ErrorFeedback';
import { useTranslation } from 'react-i18next';
import { errorHighlighter } from '../../util/form';

const variants = {
  xs: 100,
  sm: 120,
  md: 150,
  xmd: 140,
  lg: 180,
  xlg: 200,
};

type Options = {
  label: string;
  value: string | number | undefined;
  disabled?: boolean | undefined;
};

interface SelectBoxProps {
  value?: string;
  className?: string;
  name?: string;
  disabled?: boolean;
  label?: string | undefined | null;
  variant?: keyof typeof variants;
  maxWidth?: number;
  field?: any;
  options?: Options[] | undefined;
  placeholder?: string | undefined;
  bsPrefix?: string | undefined;
  ref?: null;
  labelSuffix?: string | number | undefined;
  labelSuffixClassname?: string;
  labelDetail?: string | number | undefined;
  labelDetailClassname?: string;
  selectboxWidth?: number;
  onChangeHandler?: (e) => void;
}

export default function BaseSelectBox({
  value,
  name,
  className,
  disabled = false,
  label,
  maxWidth,
  field,
  variant,
  options,
  placeholder,
  bsPrefix,
  ref,
  labelSuffix,
  labelSuffixClassname,
  labelDetail,
  labelDetailClassname,
  onChangeHandler,
}: SelectBoxProps) {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ maxWidth: `${variants[variant!] || maxWidth}px` || '' }}>
        <Form.Label className={classNames('fs-name', 'mb-0 ', `${field ? 'required' : ''}`)}>
          {label} <span className={classNames('font-weight-400', labelSuffixClassname)}>{labelSuffix}</span>
        </Form.Label>
        <p className={classNames('fs-name font-weight-400 theme-text', labelDetailClassname)}>{labelDetail}</p>
        <Form.Select
          name={name}
          value={value}
          onChange={onChangeHandler}
          disabled={disabled}
          className={classNames(className, field ? errorHighlighter(field) : '')}
          ref={ref}
          bsPrefix={bsPrefix}
        >
          {options?.map((option) => (
            <option key={option.value} value={option.value} disabled={option.disabled}>
              {t(option.label)}
            </option>
          ))}
        </Form.Select>
        {field && <ErrorFeedback field={field} />}
      </div>
    </>
  );
}
