import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { SalaryType } from '../../../types';

export interface SalarySlipRecord {
  _id: string;
  documentName: string;
  documentLink: string;
  title?: string;
  employee: Employee;
  user: User;
  paymentDate: string;
  status: string;
  dataValidationErrors: Error[];
  comments: string[];
  salaryPeriod: SalaryPeriod;
  deviationPeriod: SalaryPeriod;
  taxTable: string;
  equalization: string;
  preliminaryEmployerSeverancePercentage: string;
  prelEmployerAvg: string;
  tableTaxBase: string;
  oneTimeTaxBase: string;
  cumulativeGrossSalary: string;
  cumulativePreliminaryTax: string;
  flexBalance: string;
  compBalance: string;
  grossSalary: string;
  preliminaryTax: number;
  taxFreeExpensesAndDeductions: number;
  toPay: string;
  articles: Article[];
  holidays: {
    paid: string;
    saved: string;
    obetald: string;
    forskott: string;
  };
  toPayout: number;
  createdAt: string;
  updatedAt: string;
  _internal?: any;
}

export interface Employee {
  addresses: Address[];
  employmentDate: string;
  employmentType: string;
  foraType: string;
  importInformation: ImportInformation;
  jobTitle: string;
  personnelType: string;
  salary: string;
  salaryType: string;
  scheduleId: string;
  userId: string;
  companyClientId: string;
  phoneNumber: string;
  email: string;
  datedWages: DatedWage[];
  datedSchedules: DatedSchedule[];
}

export interface Address {
  street: string;
  postcode: string;
  city: string;
  country: string;
}

export interface ImportInformation {
  importedFrom: string;
  id: string;
  activeOnEMS: boolean;
  idKey: string;
}

export interface DatedWage {
  EmployeeId: string;
  FirstDay: string;
  MonthlySalary: string;
  HourlyPay: any;
}

export interface DatedSchedule {
  EmployeeId: string;
  FirstDay: string;
  ScheduleId: string;
}

export interface User {
  fullName: string;
  personalNumber: string;
  roles: Role[];
}

export interface Role {
  role: string;
  status: string;
  company: Company;
}

export interface Company {
  _id: string;
  name: string;
}

export interface Error {
  categoryCode: number;
  category: string;
  message: string;
  code: number;
}

export interface SalaryPeriod {
  from: string;
  to: string;
}

export interface Article {
  article: ArticleBasicInfo;
  fromDate: string;
  toDate: string;
  pieces: string;
  unit: string;
  amount: string;
  orignalValue: string;
  updatedValue: string;
}

export interface ArticleBasicInfo {
  salaryCodeData: {
    id: string;
    code: number;
    name: string;
  };
  extractedData: {
    name: string;
  };
  isDefault?: boolean;
}

export interface ArticleFormField {
  _internal?: {
    articleId: string;
    disabledArticle?: boolean;
    isLocked?: boolean;
  };
  article: ArticleBasicInfo;
  fromDate: string;
  toDate: string;
  pieces: string;
  unit: string;
  amount: string;
  orignalValue: string;
  updatedValue: string;
}

export interface SalarySlipFormGroup {
  articles: ArticleFormField[];
}

export type SalarySlipFormGroupCtx = FormikProps<SalarySlipFormGroup>;

export interface ISalarySlipContext {
  selectedDetails: SalarySlipRecord | null;
  setSelectedDetails: Dispatch<SetStateAction<SalarySlipRecord | null>>;
  salaryTypes: SalaryType[];
  setSalaryTypes: Dispatch<SetStateAction<SalaryType[]>>;
  errorType: SalaryException | null;
  formGroup: SalarySlipFormGroupCtx;
  initialize: (details: SalarySlipRecord) => void;
  replaceArticles: (value) => void;
  setFormField: (name, value) => void;
  formatDate: (date?: string) => string;
  normalizeRequestPayload: (formData?: any) => any;
}

export enum SalaryErrorType {
  UNMATCHED_TYPE = 1,
  TOTAL_MISMATCHED_TYPE = 2,
  UNMATCHED_WITH_TOTAL = 3,
}

export interface SalaryException {
  msg: string;
  type: SalaryErrorType;
}
