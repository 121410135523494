import { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Edit2, Info, Plus } from 'react-feather';
import { ReactComponent as CollectiveAgreementIcon } from '../../../../assets/img/collective-agreement.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getEmployeePension } from '../../../../api/service/company/employees/service';
import {
  getPolicies,
  getPolicyDetails,
  getPolicyDetailsByName,
} from '../../../../api/service/superAdmin/pension-policies/service';
import { TabDataType } from '../EmployeeDetails/EmployeeDetails';
import {
  INITIAL_PENSION_COMPANY_VALUE,
  INITIAL_NO_POLICY_VALUE,
  INITIAL_SALARY_CHANGE_VALUE,
} from '../context/EmployeePensionContext';
import useContext from '../useContext';
import PensionPoliciesDetailsNonEditable from '../../../superAdmin/PensionPolicy/PensionPoliciesDetailsNonEditable';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';
import { isEmpty, numberSpaceSeparator } from '../../../../util/util';
import PagesHeader from '../../../../components/base/PagesHeader';
import CreatePersonalPolicyModal from '../PersonalPolicy/CreatePersonalPolicyModal';
import classNames from 'classnames';
import { PensionPolicyProvider } from '../../../superAdmin/PensionPolicy/PensionPolicyContext/PensionPolicyContext';
import {
  PensionPolicyCategory,
  PensionPolicyMode,
  PensionPolicyVariant,
} from '../../../superAdmin/PensionPolicy/types';
import { DEFAULT_POLICY_CATEGORY } from '../../../superAdmin/PensionPolicy/constants';
import useAssignEmployeePension from '../hooks/useAssignEmployeePension';
import { IPersonalPolicyActionModalProps, ISelectablePersonalPolicyOption } from '../types';
import useCalendarEffectiveDate from '../hooks/useCalendarEffectiveDate';
import usePensionTabMode from '../hooks/usePensionTabMode';
import PensionPolicyDisabledView from './PensionPolicyDisabledView';
import usePensionMode from '../hooks/usePensionMode';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';
import useEmployeePolicyLoaded from '../hooks/useEmployeePolicyLoaded';
import { Calendar } from '../../../../util/calendar';

function PensionPolicy({
  employee,
  tabKey,
  reset,
  setReset,
  pensionDataset,
  insuranceDataset,
  policy: _policyMetaDetails,
}: TabDataType) {
  const { t } = useTranslation();
  const {
    setpensionCompanies,
    setpolicies,
    effectiveFrom,
    employeePolicyRevision,
    selectedPolicyType,
    setEffectiveFrom,
    setsalaryChange,
    updatePolicyType,
    formGroup,
    selectedPensionPolicy,
    selectedEmployeePension,
    findPolicyType,
    advisoryInvite,
    setAdvisoryInvite,
    employeePensionSummary,
    isReverting,
    setIsReverting,
  } = useContext();
  const {
    // resetToInitialEmployeePension,
    resetEmployeePolicyRevision,
    setInitialEmployeePolicyRevision,
    findExistingRevisionSettings,
    replacePolicyRevision,
    findExistingPersonalPolicy,
    syncPolicFormDataToEmployeePension,
    selectPensionBySelectedPolicyFromRevision,
    setDetailsWhenNoPolicyRevision,
    resetPersonalPolicyRevisionSettings,
    addDefaultAdminPolicyRevision,
  } = useAssignEmployeePension();
  const { loadEmployeePensionDetails, revertPensionDetails } = useEmployeePolicyLoaded();
  const calendarEffectiveDate = useCalendarEffectiveDate();
  const { canEditCurrentPolicy } = usePensionTabMode();
  const pensionMode = usePensionMode();
  const { id, policyId } = useParams();
  const navigate = useNavigate();
  const [policyType, setPolicyType] = useState<string | null>('');
  const [shouldResetPolicy, setShouldResetPolicy] = useState(false);
  const [companyPolicies, setCompanyPolicies] = useState([]);
  const [collectiveAggrementPolicies, setCollectiveAggrementPolicies] = useState([]);
  const [policyDetails, setPolicyDetails] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const hasPolicy = !isEmpty(policyDetails);
  const [empolyeeAttachedPersonalPolicy, setEmpolyeeAttachedPersonalPolicy] = useState<any>();
  const [editPersonalPolicyOption, setEditPersonalPolicyOption] = useState<ISelectablePersonalPolicyOption | null>(
    null,
  );
  const [defaultPensionRevision, setDefaultPensionRevision] = useState(null);
  const [isFetchedSource, setIsFetchedSource] = useState({
    [PensionPolicyCategory.CORRECTO_PROVIDED_POLICY]: false,
    [PensionPolicyCategory.COMPANY_POLICY]: false,
    chosenPolicy: false,
    loadPersonlPolicy: false,
  });

  const toggleModal = (_opt: Partial<IPersonalPolicyActionModalProps> | null = null) => {
    if (showModal === true) {
      setEditPersonalPolicyOption(null);
    }

    setShowModal(!showModal);
  };

  const onEditPersonalPolicyDetails = async () => {
    try {
      toggleFetchSourceLoader('loadPersonlPolicy', true);

      const _policy = await getPolicyDetails(empolyeeAttachedPersonalPolicy._id);

      setEditPersonalPolicyOption({
        chosenAssignedPersonalPolicy: _policy,
      });

      toggleModal();
    } finally {
      toggleFetchSourceLoader('loadPersonlPolicy', false);
    }
  };

  const onSelectPolicyType = (ev) => {
    const { value } = ev.target;
    setShouldResetPolicy(true);
    setPolicyType(value);
    updatePolicyType(value);

    if (value === 'companyPolicy' && companyPolicies.length) {
      const foundPolicy: any = companyPolicies.find((item: any) => {
        return !isEmpty(policyDetails) && item._id === policyDetails._id;
      });

      if (foundPolicy) {
        setPolicyDetails({ ...foundPolicy });
        setDefaultPensionRevision(selectPensionBySelectedPolicyFromRevision(foundPolicy, value));
      }

      // getAndSetCompanyPolicies();
    } else if (value === 'correctoProvidedPolicy' && collectiveAggrementPolicies.length) {
      //getAndSetCollectiveAggrementPolicies();
      const foundPolicy: any = collectiveAggrementPolicies.find((item: any) => {
        return !isEmpty(policyDetails) && item._id === policyDetails._id;
      });

      if (foundPolicy) {
        setPolicyDetails({ ...foundPolicy });
        setDefaultPensionRevision(selectPensionBySelectedPolicyFromRevision(foundPolicy, value));
      }
    } else if (value === 'noPolicy') {
      setpolicies(INITIAL_NO_POLICY_VALUE);
      setDefaultPensionRevision(selectPensionBySelectedPolicyFromRevision(null, value));
    } else if (value === PensionPolicyCategory.PERSONAL_POLICY) {
      const _policy = findExistingPersonalPolicy();

      if (_policy) {
        setPolicyDetails({ ..._policy.metaDetails });
        setDefaultPensionRevision(selectPensionBySelectedPolicyFromRevision(_policy.metaDetails, value));
        return;
      }

      if (empolyeeAttachedPersonalPolicy) {
        setPolicyDetails({ ...empolyeeAttachedPersonalPolicy });
        setDefaultPensionRevision(selectPensionBySelectedPolicyFromRevision(empolyeeAttachedPersonalPolicy, value));
      }
    }
  };

  const onSelectPensionPolicy = async (ev, type) => {
    setShouldResetPolicy(true);

    const { value } = ev.target;

    toggleFetchSourceLoader('chosenPolicy', true);

    const _policy = await getPolicyDetails(value);

    toggleFetchSourceLoader('chosenPolicy', false);
    // const existingPolicy = findExistingRevisionSettings(value);

    if (_policy) {
      setPolicyDetails(_policy);
      setDefaultPensionRevision(selectPensionBySelectedPolicyFromRevision(_policy, selectedPolicyType!));
      return;
    }

    // setEmployeePensionDetails(_policy);
    setPolicyDetails(_policy);
    setDefaultPensionRevision(selectPensionBySelectedPolicyFromRevision(_policy, selectedPolicyType!));
  };

  const setEmployeePensionDetails = (_policy: any) => {
    setInitialEmployeePolicyRevision(_policy.type, _policy);
    //setPolicyDetails(_policy);
    setpolicies({ id: _policy._id, type: _policy.type, attachedAt: new Date().toISOString() });
  };

  const getAndSetCompanyPolicies = async () => {
    toggleFetchSourceLoader(PensionPolicyCategory.COMPANY_POLICY, true);

    const policies = await getPolicies({ type: 'Company', selectAll: true, employeeId: employee!._id });

    setCompanyPolicies(policies as any);
    toggleFetchSourceLoader(PensionPolicyCategory.COMPANY_POLICY, false);
  };

  const getAndSetCollectiveAggrementPolicies = async () => {
    toggleFetchSourceLoader(PensionPolicyCategory.CORRECTO_PROVIDED_POLICY, true);

    const policies = await getPolicies({
      type: PensionPolicyVariant.CORRECTO_PROVIDED,
      selectAll: true,
      employeeId: employee!._id,
    });

    setCollectiveAggrementPolicies(policies as any);
    toggleFetchSourceLoader(PensionPolicyCategory.CORRECTO_PROVIDED_POLICY, false);
  };

  const toggleFetchSourceLoader = (type, value: boolean) => {
    setIsFetchedSource((prev) => {
      return {
        ...prev,
        [type]: value,
      };
    });
  };

  const hasLoadedSource = useCallback(
    (_type: PensionPolicyCategory, _policyDetails: any = null) => {
      if (PensionPolicyCategory.COMPANY_POLICY === _type) {
        return (
          isFetchedSource[_type] && _policyDetails?.policyName && _policyDetails?.type === PensionPolicyVariant.COMPANY
        );
      } else if (PensionPolicyCategory.CORRECTO_PROVIDED_POLICY === _type) {
        return (
          isFetchedSource[_type] &&
          _policyDetails?.policyName &&
          _policyDetails?.type === PensionPolicyVariant.CORRECTO_PROVIDED
        );
      }
    },
    [isFetchedSource],
  );

  const getAndSetPolicyDetails = async (id) => {
    const _policy = await getPolicyDetails(id);
    setPolicyDetails(_policy);
    return _policy;
  };

  const getEmployeePersonalPolicy = async () => {
    try {
      const _policy = await getPolicyDetailsByName(`${employee?.fullName} ${employee?.personalNumber}`);

      if (_policy) {
        setEmpolyeeAttachedPersonalPolicy(_policy);
      }

      return _policy;
    } catch (e) {
      console.error(e);
    }
  };

  const onEditPolicy = () => {
    if (policyType === 'companyPolicy' || policyType === 'correctoProvidedPolicy') {
      navigate(`/pension-policies/${policyDetails['_id']}/edit`);
    } else {
      onEditPersonalPolicyDetails();
    }
  };

  const onAddPersonalPolicy = (_policyDetails) => {
    setPolicyDetails(_policyDetails);
    setEmpolyeeAttachedPersonalPolicy(_policyDetails);
    setDefaultPensionRevision(
      selectPensionBySelectedPolicyFromRevision(_policyDetails, PensionPolicyCategory.PERSONAL_POLICY),
    );
  };

  const onUpdatePersonalPolicy = (updatedPolicyDetails) => {
    // window.location.reload();
    const initialRevisionSettings = resetPersonalPolicyRevisionSettings(updatedPolicyDetails);
    setDefaultPensionRevision(initialRevisionSettings as any);
    setEmpolyeeAttachedPersonalPolicy(updatedPolicyDetails);
    setPolicyDetails(updatedPolicyDetails);
  };

  const foundMatchedPolicy = (val) => {
    if (val === 'Company') {
      return 'companyPolicy';
    } else if (val === PensionPolicyVariant.CORRECTO_PROVIDED) {
      return PensionPolicyCategory.CORRECTO_PROVIDED_POLICY;
    } else {
      return 'personalPolicy';
    }
  };

  const fetchEmployeePolicy = async () => {
    try {
      const _policy = await getEmployeePension(id);
      setReset && setReset(false);
      if (!isEmpty(_policy)) {
        setpensionCompanies(_policy['pensionCompanies']);
        setpolicies(_policy['currentPolicy']);
        setEffectiveFrom(_policy['effectiveFrom']);
        setsalaryChange({
          effectiveFrom: _policy['salaryChange']['effectiveFrom'],
          fixedOffset: numberSpaceSeparator(_policy['salaryChange']['fixedOffset']),
          history: _policy['salaryChange']['history'],
        });
        let chosenPolicyType = 'noPolicy';

        if (_policy['currentPolicy']) {
          const __policy = await getAndSetPolicyDetails(_policy['currentPolicy']['id']);
          chosenPolicyType = foundMatchedPolicy(__policy['type']);
        }

        setPolicyType(chosenPolicyType);
        updatePolicyType(chosenPolicyType);
      } else {
        setPolicyType('noPolicy');
        updatePolicyType('noPolicy');
        setPolicyDetails({});
        setpensionCompanies(INITIAL_PENSION_COMPANY_VALUE);
        setpolicies(INITIAL_NO_POLICY_VALUE);
        setEffectiveFrom('');
        setsalaryChange({ ...INITIAL_SALARY_CHANGE_VALUE, fixedOffset: 0 });
      }
    } catch (error) {
      setPolicyType('noPolicy');
      updatePolicyType('noPolicy');
      setPolicyDetails({});
      setpensionCompanies(INITIAL_PENSION_COMPANY_VALUE);
      setpolicies(INITIAL_NO_POLICY_VALUE);
      setEffectiveFrom('');
      setsalaryChange({ ...INITIAL_SALARY_CHANGE_VALUE, fixedOffset: 0 });
    }
  };

  const patchDefaultEmployeePensionForm = () => {
    if (!(selectedEmployeePension && selectedPensionPolicy)) {
      return false;
    }

    setpolicies(selectedEmployeePension['currentPolicy']);
    setEffectiveFrom(selectedEmployeePension['effectiveFrom']);

    let chosenPolicyType = DEFAULT_POLICY_CATEGORY as any;

    if (selectedEmployeePension['currentPolicy']?.type) {
      chosenPolicyType = findPolicyType(selectedEmployeePension['currentPolicy'].type);
    }

    setPolicyType(chosenPolicyType);
    setpolicies(selectedEmployeePension['currentPolicy']);
    setEffectiveFrom(selectedEmployeePension['effectiveFrom']);
  };

  const onCreatePersonalPolicy = () => {
    toggleModal();
    setPolicyDetails({});
  };

  const onSelectEffectiveDate = (val) => {
    setEffectiveFrom(val);
  };

  /*  const resetEmployeePension = () => {
    if (employeePolicyRevision && selectedPensionPolicy) {
      resetToInitialEmployeePension(employeePolicyRevision, selectedPensionPolicy);
      const chosenPolicyType = findPolicyType(selectedPensionPolicy.type);

      if (chosenPolicyType) {
        updatePolicyType(chosenPolicyType);
        setPolicyType(chosenPolicyType);
      }

      setPolicyDetails(selectedPensionPolicy);
    } else {
      setDefaultPension();
    }
  }; */

  /* const _setDefaultPension = () => {
    resetToInitialEmployeePension(null);
    updatePolicyType(DEFAULT_POLICY_CATEGORY);
    setPolicyType(DEFAULT_POLICY_CATEGORY);
    setPolicyDetails({});
    setpensionCompanies(INITIAL_PENSION_COMPANY_VALUE);
    setpolicies(INITIAL_NO_POLICY_VALUE);
    setEffectiveFrom('');
  }; */

  const syncPensionPolicyFormData = (policySettings) => {
    if (policyDetails.type === PensionPolicyVariant.PERSONAL) {
      syncPolicFormDataToEmployeePension(policyDetails._id, { policySettings }, 'personal');
    } else {
      syncPolicFormDataToEmployeePension(policyDetails._id, { policySettings }, 'admin');
    }

    const policySettingsPayload = {
      salaryChange: Object.assign(
        {},
        policySettings.salaryChange?.fixedMonthlyOffset?.offset ||
          policySettings.salaryChange?.fixedMonthlyOffset?.effectiveFrom
          ? {
              fixedOffset: {
                offset: policySettings.salaryChange.fixedMonthlyOffset.offset,
                effectiveFrom: policySettings.salaryChange.fixedMonthlyOffset.effectiveFrom,
              },
            }
          : null,
        policySettings.salaryChange?.percentageOfSalaryOffset?.offset ||
          policySettings.salaryChange?.percentageOfSalaryOffset?.effectiveFrom
          ? {
              percentageOfSalaryOffset: {
                offset: policySettings.salaryChange.percentageOfSalaryOffset.offset,
                effectiveFrom: policySettings.salaryChange.percentageOfSalaryOffset.effectiveFrom,
              },
            }
          : null,
        policySettings.salaryChange?.manualOffset?.offset || policySettings.salaryChange?.manualOffset?.effectiveFrom
          ? {
              oneTimeOffset: {
                offset: policySettings.salaryChange.manualOffset.offset,
                effectiveFrom: policySettings.salaryChange.manualOffset.effectiveFrom,
              },
            }
          : null,
      ),
      pensionManagers: policySettings.pensionManagers,
      insurances: policySettings.insurances,
      employeeMetaSettings: policySettings.employeeMetaSettings,
    };

    formGroup.setFieldValue('policySettings', policySettingsPayload);
  };

  const isEditableLink = () => {
    if ([PensionPolicyVariant.COMPANY, PensionPolicyVariant.PERSONAL].includes(policyDetails.type)) {
      return pensionMode.canVisibleEditPolicyLink();
    }

    return false;
  };

  useEffect(() => {
    if (hasPolicy) {
      // patchDefaultEmployeePensionForm();

      if (policyDetails['type'] === 'Company') {
        getAndSetCompanyPolicies();
        setPolicyType('companyPolicy');
        updatePolicyType('companyPolicy');
      } else if (policyDetails['type'] === 'Personal') {
        setPolicyType('personalPolicy');
        updatePolicyType('personalPolicy');
      }
    }
  }, [tabKey]);

  useEffect(() => {
    if (policyId) {
      setShowModal(true);
    }
  }, [policyId]);

  useEffect(() => {
    if (reset) {
      fetchEmployeePolicy();
    }
  }, [reset]);

  useEffect(() => {
    getAndSetCompanyPolicies();
    getAndSetCollectiveAggrementPolicies();
    getEmployeePersonalPolicy();
  }, []);

  useEffect(() => {
    if (shouldResetPolicy) {
      resetEmployeePolicyRevision();
      setShouldResetPolicy(false);

      return;
    }
  }, [shouldResetPolicy]);

  useEffect(() => {
    if (selectedPolicyType !== policyType) {
      setPolicyType(selectedPolicyType);

      return;
    }
  }, [selectedPolicyType]);

  useEffect(() => {
    if (employeePensionSummary) {
      setPolicyDetails(_policyMetaDetails);
      loadEmployeePensionDetails(_policyMetaDetails, employeePensionSummary);

      return;
    }

    if (isEmpty(_policyMetaDetails) && !employeePensionSummary) {
      loadEmployeePensionDetails(_policyMetaDetails, null);
    }
  }, [_policyMetaDetails, employeePensionSummary]);

  useEffect(() => {
    if (!isEmpty(policyDetails)) {
      if (policyDetails.type === PensionPolicyVariant.PERSONAL) {
        const foundPolicy = findExistingPersonalPolicy();

        if (foundPolicy) {
          replacePolicyRevision(foundPolicy);
          setpolicies(foundPolicy.currentPolicy);

          return;
        }
      } else {
        const foundPolicy = findExistingRevisionSettings(policyDetails._id);

        if (foundPolicy) {
          replacePolicyRevision(foundPolicy);
          setpolicies(foundPolicy.currentPolicy);

          return;
        }
      }

      setEmployeePensionDetails(policyDetails);
    }

    if (isEmpty(policyDetails) && employeePolicyRevision) {
      setDetailsWhenNoPolicyRevision();
    }
  }, [policyDetails]);

  useEffect(() => {
    if (isReverting && employeePensionSummary?.current) {
      revertPensionDetails();

      setIsReverting(false);
      return;
    }
  }, [isReverting, employeePensionSummary]);

  useEffect(() => {
    patchDefaultEmployeePensionForm();

    if (selectedEmployeePension && selectedPensionPolicy) {
      //[OR] addAndUpdateRevision(selectedEmployeePension, selectedPensionPolicy);
      addDefaultAdminPolicyRevision(selectedEmployeePension, selectedPensionPolicy);
      setDefaultPensionRevision(selectedEmployeePension);
    }
  }, [selectedEmployeePension, selectedPensionPolicy]);

  if (!canEditCurrentPolicy()) {
    return <PensionPolicyDisabledView empolyeeAttachedPersonalPolicy={empolyeeAttachedPersonalPolicy} />;
  }

  return (
    <div className="employee-policy">
      {pensionMode.hasOnlyScheduled() && (
        <div className="p-2 mb-3 d-flex align-items-center alert alert-info theme-text">
          <Info className="icon-gap-right" />
          <span>{t('scheduled_update_prevents_settings_creation')}.</span>
        </div>
      )}
      <PagesHeader pageTitle="Pension policy" />
      <Form>
        <div className="d-flex mb-5 gap-2 pension-policy-selector flex-wrap-row">
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'companyPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'companyPolicy'}
              onChange={onSelectPolicyType}
              inline
              value="companyPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.company_policy')}`}
              className="fs-name mb-1"
              id="companyPolicy"
              disabled={pensionMode.hasReadOnlyCurrentPolicy()}
            />
            {hasLoadedSource(PensionPolicyCategory.COMPANY_POLICY, policyDetails) && (
              <Form.Control disabled value={policyDetails.policyName} />
            )}
            {!hasLoadedSource(PensionPolicyCategory.COMPANY_POLICY, policyDetails) && (
              <Form.Select
                value={hasPolicy && policyDetails['type'] === PensionPolicyVariant.COMPANY ? policyDetails['_id'] : ''}
                onChange={(ev) => onSelectPensionPolicy(ev, PensionPolicyVariant.COMPANY)}
                disabled={policyType !== 'companyPolicy' || pensionMode.hasReadOnlyCurrentPolicy()}
              >
                <option disabled value="" selected={employeePolicyRevision === null}>
                  {`${t('PENSION_POLICY.select_policy')}...`}
                </option>
                {companyPolicies.map((item) => {
                  return (
                    <option key={item['_id']} value={item['_id']}>
                      {item['policyName']}
                    </option>
                  );
                })}
              </Form.Select>
            )}
          </div>
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'correctoProvidedPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'correctoProvidedPolicy'}
              onChange={onSelectPolicyType}
              inline
              value="correctoProvidedPolicy"
              name="policyType"
              type="radio"
              label={t('correcto_provided')}
              className="fs-name mb-1"
              id="correctoProvidedPolicy"
              disabled={pensionMode.hasReadOnlyCurrentPolicy()}
            />
            {hasLoadedSource(PensionPolicyCategory.CORRECTO_PROVIDED_POLICY, policyDetails) && (
              <Form.Control disabled value={policyDetails.policyName} />
            )}
            {!hasLoadedSource(PensionPolicyCategory.CORRECTO_PROVIDED_POLICY, policyDetails) && (
              <Form.Select
                value={
                  hasPolicy && policyDetails['type'] === PensionPolicyVariant.CORRECTO_PROVIDED
                    ? policyDetails['_id']
                    : ''
                }
                onChange={(ev) => onSelectPensionPolicy(ev, PensionPolicyVariant.CORRECTO_PROVIDED)}
                disabled={policyType !== 'correctoProvidedPolicy' || pensionMode.hasReadOnlyCurrentPolicy()}
              >
                <option disabled value="" selected={employeePolicyRevision === null}>
                  {`${t('PENSION_POLICY.select_policy')}...`}
                </option>
                {collectiveAggrementPolicies.map((item) => {
                  return (
                    <option key={item['_id']} value={item['_id']}>
                      {item['policyName']}
                    </option>
                  );
                })}
              </Form.Select>
            )}
          </div>
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'personalPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'personalPolicy'}
              onChange={onSelectPolicyType}
              inline
              value="personalPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.personal_policy')}`}
              className="fs-name radio-button-label"
              id="personalPolicy"
              disabled={pensionMode.hasReadOnlyCurrentPolicy()}
            />

            <div>
              {empolyeeAttachedPersonalPolicy && <p>{empolyeeAttachedPersonalPolicy?.policyName}</p>}
              {!empolyeeAttachedPersonalPolicy && (
                <Button
                  onClick={onCreatePersonalPolicy}
                  disabled={policyType !== 'personalPolicy'}
                  className="app-btn-primary"
                >
                  <Plus />
                  {`${t('PENSION_POLICY.create_personal_policy')}`}
                </Button>
              )}
            </div>
          </div>
          <Col
            style={{ height: 86, width: 240, maxWidth: 240, minWidth: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'noPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'noPolicy'}
              onChange={onSelectPolicyType}
              inline
              value="noPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.no_policy')} `}
              className="fs-name"
              id="noPolicy"
              disabled={pensionMode.hasReadOnlyCurrentPolicy()}
            />

            <Form.Check
              checked={advisoryInvite}
              onChange={(e) => setAdvisoryInvite(e.target.checked)}
              inline
              //  value="noPolicy"
              name="advisoryInvite"
              type="checkbox"
              label={t('invite_employee_to_correcto_advisory')}
              className="fs-name d-flex align-items-start mt-2"
              id="inviteEmployee"
              disabled={policyType !== 'noPolicy' || pensionMode.hasReadOnlyCurrentPolicy()}
            />
          </Col>
        </div>
        {selectedPolicyType && (
          <Row className="mb-4">
            <Form.Label className="mb-1 fs-name">{`${t('PENSION_POLICY.effective_from')}`}</Form.Label>
            <Col md={2}>
              <MonthAndYearDropdown
                startFrom={calendarEffectiveDate.selectMonthOffsetByPolicy(policyDetails)}
                value={effectiveFrom}
                onOptionSelect={(val) => onSelectEffectiveDate(val)}
                disabled={pensionMode.hasDisabledOnEditMode()}
                customOptions={Calendar.generateEffectiveDateOption(effectiveFrom)}
              />
            </Col>
          </Row>
        )}
      </Form>
      <hr />

      {isFetchedSource.chosenPolicy && <Spinner />}
      {employeePolicyRevision && !isEmpty(policyDetails) && (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <PagesHeader biggerHeading={true} pageTitle={`${policyDetails['policyName']}`} translate={false} />
            {policyDetails['isCollectiveAgreementLock'] && (
              <h6>
                <TooltipBox msg={`${t('collective_agreement')}`}>
                  <span className="partner-icon">
                    <CollectiveAgreementIcon />
                  </span>
                </TooltipBox>
              </h6>
            )}
          </div>
          {isEditableLink() && (
            <Button onClick={onEditPolicy} className="app-btn-primary mb-2">
              {isFetchedSource.loadPersonlPolicy ? <Spinner size="sm" /> : <Edit2 className="icon-gap-right" />}
              {`${t('PENSION_POLICY.edit_policy')}`}
            </Button>
          )}
        </div>
      )}
      {employeePolicyRevision && !isEmpty(policyDetails) && (
        <PensionPolicyProvider
          defaultSelectedPolicy={policyDetails}
          shouldReset={shouldResetPolicy}
          defaultSelectedPolicyMode={PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY}
          syncPensionPolicyFormData={syncPensionPolicyFormData}
          defaultPensionFormPopulated={defaultPensionRevision}
          defaultExternalPolicyRevision={{ effectiveFrom, assignedEmployeePension: selectedEmployeePension }}
          defaultActionMode={pensionMode.actionModeCurrentPolicy()}
        >
          <PensionPoliciesDetailsNonEditable
            policy={policyDetails}
            containerBodyClassName="p-0"
            containerClassName="shadow-none"
            isSelectable
            effectiveFrom={effectiveFrom}
            fetchedFromExternalSource={true}
            pensionDataset={pensionDataset}
            insuranceDataset={insuranceDataset}
          />
        </PensionPolicyProvider>
      )}

      {
        <CreatePersonalPolicyModal
          showModal={showModal}
          toggleModal={toggleModal}
          employee={employee}
          onSavePolicy={onAddPersonalPolicy}
          onUpdatePersonalPolicy={onUpdatePersonalPolicy}
          policy={policyDetails}
          isEditAction={editPersonalPolicyOption ? true : false}
          defaultSelectedPolicyOrigin={editPersonalPolicyOption?.chosenAssignedPersonalPolicy}
        />
      }
    </div>
  );
}

export default PensionPolicy;
