import moment from 'moment';

import useEmployeePensionContext from '../useContext';
import { PensionPolicy } from '../../../superAdmin/PensionPolicy/types';
import { isEmpty } from '../../../../util/util';
import usePensionMode from './usePensionMode';

const useCalendarEffectiveDate = () => {
  const { employeePolicyRevision, employeePensionSummary } = useEmployeePensionContext();
  const { hasCurrentPolicyExist } = usePensionMode();

  const isDisabledEffectiveDateInput = (_policyDetails: PensionPolicy) => {
    if (hasCurrentPolicyExist()) {
      if (employeePensionSummary?.current?.id === _policyDetails._id) {
        return true;
      }
    }

    return false;
  };

  const selectMonthOffsetByPolicy = (_policyDetails: PensionPolicy) => {
    if (!employeePolicyRevision || isEmpty(_policyDetails)) {
      return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    }

    const inputDate = moment(_policyDetails.effectiveFrom);
    const currentDate = moment(`${moment().year()}-${moment().month() + 1}`);

    if (isDisabledEffectiveDateInput(_policyDetails)) {
      return employeePensionSummary?.current?.effectiveFrom;
    }

    if (inputDate.format('YYYY-MM') === currentDate.format('YYYY-MM')) {
      return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    }

    if (inputDate.isBefore(currentDate)) {
      return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    }

    return _policyDetails.effectiveFrom;
  };

  return {
    selectMonthOffsetByPolicy,
    isDisabledEffectiveDateInput,
  };
};

export default useCalendarEffectiveDate;
