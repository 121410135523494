import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

export const interceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    function (config: any): any {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    function (error: AxiosError): Promise<AxiosError> {
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    function (response: AxiosResponse): AxiosResponse {
      return response;
    },
    function (error: AxiosError): Promise<AxiosError> {
      return Promise.reject(error);
    },
  );
};
