import { ChevronRight } from 'react-feather';
import { Item } from '../../../api/service/webflow/types';
import { useTranslation } from 'react-i18next';

export const BlogsSection = ({ blogs }: { blogs: Item[] }) => {
  const { t } = useTranslation();

  const onClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="mt-2 blogs-section">
      {blogs?.map((item, idx) => {
        return (
          <>
            <div className="d-flex flex-column mb-2" key={item._id}>
              <div className="d-flex justify-content-between position-relative">
                <span className="fs-h3 theme-text" style={{ width: '80%' }}>
                  {item.name}
                </span>
                <img
                  className="position-absolute blog-section-logo"
                  src={item['post-illustration']?.url}
                  alt={item['post-illustration']?.alt ?? 'logo'}
                />
              </div>
              <span className="fs-text-400 theme-text" style={{ paddingRight: 10 }}>
                {item['body-text']}
              </span>
              <div
                onClick={(_) => onClick(item['read-more-url'])}
                className="fs-tertiery theme-text d-flex justify-content-end cursor-pointer mt-1 pe-2"
                style={{ float: 'right' }}
              >
                <ChevronRight />
                {t('read_more')}
              </div>
            </div>
            {idx !== blogs.length - 1 && <hr />}
          </>
        );
      })}
    </div>
  );
};
