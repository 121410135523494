import { useCallback, useEffect, useState } from 'react';
import { useValidationError } from '../../../../hooks/useValidationError';
import { ConfirmationDialog } from '../../../../util/dialogs';
import { t } from 'i18next';
import { isEqual } from 'lodash';
import { deepClone, splitFullName } from '../../../../util/util';
import { GeneralSettingsEditor, IAdmin, IAdvisor, Mode } from '../types';
import { useGeneralSettingsContext } from '../AdminSettingsContext/useContext';
import { useSuperAdminSettingsContext } from '../useContext';
import { DEFAULT_PERSON_INFO } from '../../../../types/general';
import { getRoaringPersonInfo } from '../../../../api/service/superAdmin/company-clients/service';
import {
  //   addCompanyAdmin,
  addCompanyAdvisor,
  deleteAdvisor,
  deleteCompanyAdmin,
  fetchCompanyAdmins,
  fetchInsuranceAdviors,
} from '../../../../api/service/general/user.service';

let _adminDetail = [];
let _advisorDetail = [];

const useGeneralSettingsEditor = (): GeneralSettingsEditor => {
  const { /* errors, isInvalid, */ setErrors } = useValidationError();

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [isAdvisorPersonalNumberValid, setIsAdvisorPersonalNumberValid] = useState<boolean>(true);

  const { adminDetails, replaceAdminDetails, formGroup, replaceInsuranceAdvisors, advisors } =
    useGeneralSettingsContext();
  const { adminMode, setAdminMode } = useSuperAdminSettingsContext();

  const handleInsuranceAdvisor = useCallback(
    (e, id) => {
      const _advisors = [...advisors];
      const foundDetail = _advisors.find((i) => i['id'] === id);
      foundDetail![e.target.name] = e.target.value;
      replaceInsuranceAdvisors(_advisors);
      setIsAdvisorPersonalNumberValid(true);
    },
    [advisors, isAdvisorPersonalNumberValid],
  );

  const saveChanges = useCallback(async () => {
    try {
      setIsLoading(true);
      formGroup?.handleSubmit();
      formGroup?.validateForm().then((errors) => {
        setErrors(errors);
      });

      if (!formGroup?.isValid) {
        return false;
      }

      try {
        const updatedAdmins: IAdmin[] = [];
        const newAdminsList: IAdmin[] = [];

        adminDetails.filter((admin) => {
          if (admin.isNew === true) {
            return newAdminsList.push({
              personalNumber: admin.personalNumber,
              fullName: admin.fullName,
              email: admin.email,
            });
          } else {
            return updatedAdmins.push({
              personalNumber: admin.personalNumber,
              fullName: admin.fullName,
              email: admin.email,
              id: admin.id,
            });
          }
        });
        const updatedAdvisors: IAdvisor[] = [];
        const newAdvisors: IAdvisor[] = [];

        advisors?.filter((admin) => {
          if (admin.isNew === true) {
            return newAdvisors.push({
              personalNumber: admin.personalNumber,
              fullName: admin.fullName,
              email: admin.email,
            });
          } else {
            return updatedAdvisors.push({
              personalNumber: admin.personalNumber,
              fullName: admin.fullName,
              email: admin.email,
              id: admin.id,
            });
          }
        });
        // const admins = {
        //   admins: [...newAdminsList, ...updatedAdmins],
        //   advisors: [...newAdvisors, ...updatedAdvisors],
        // };
        // await addCompanyAdmin(admins);

        const _advisors = {
          admins: [...newAdminsList, ...updatedAdmins],
          advisors: [...newAdvisors, ...updatedAdvisors],
        };
        await addCompanyAdvisor(_advisors);
      } catch (error) {
        if (error instanceof Error) {
          setErrors({ error: error.message });
        }
      } finally {
        setAdminMode(Mode.List);

        // setIsSaving(false);
      }
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, adminMode]);

  const cancelChanges = useCallback(() => {
    replaceAdminDetails(deepClone(_adminDetail));
    replaceInsuranceAdvisors(deepClone(_advisorDetail));
  }, [_adminDetail, _advisorDetail]);

  const handleCorrectoAdmin = useCallback(
    (e, id) => {
      const _adminDetails = [...adminDetails];
      const foundDetail = _adminDetails.find((i) => i['id'] === id);
      foundDetail![e.target.name] = e.target.value;
      replaceAdminDetails(_adminDetails);
      // setIsAdminPersonalNumberValid(true);
    },
    [adminDetails],
  );

  const setAdminPersonalInfo = (form, fullName) => {
    const { firstName, lastName } = splitFullName(fullName);

    const _adminDetails = [...adminDetails];
    const foundDetail = _adminDetails.find((i) => i['id'] === form['id']);
    foundDetail!.fullName = fullName;
    foundDetail!.firstName = firstName;
    foundDetail!.lastName = lastName;
    replaceAdminDetails(_adminDetails);
    // setIsAdminPersonalNumberValid(true);
  };

  const setAdvisorPersonalInfo = (form, fullName) => {
    const { firstName, lastName } = splitFullName(fullName);

    const _advisors = [...advisors];
    const foundDetail = _advisors.find((i) => i['id'] === form['id']);

    foundDetail!.fullName = fullName;
    foundDetail!.firstName = firstName;
    foundDetail!.lastName = lastName;
    replaceInsuranceAdvisors(_advisors);
    setIsAdvisorPersonalNumberValid(true);
  };

  const loadAdminDetails = useCallback(
    async (form) => {
      try {
        setAdminPersonalInfo(form, DEFAULT_PERSON_INFO.fullName);
        const { fullName } = await getRoaringPersonInfo(form?.personalNumber);
        setAdminPersonalInfo(form, fullName);
        form['valid'] = true;
      } catch (e) {
        form['valid'] = false;
        setAdminPersonalInfo(form, DEFAULT_PERSON_INFO.fullName);

        console.error(e);
        // setIsAdminPersonalNumberValid(false);
      }
    },
    [adminDetails],
  );

  const loadAdvisorDetails = useCallback(
    async (form) => {
      try {
        setAdvisorPersonalInfo(form, DEFAULT_PERSON_INFO.fullName);
        const { fullName } = await getRoaringPersonInfo(form?.personalNumber);
        setAdvisorPersonalInfo(form, fullName);
        form['valid'] = true;
      } catch (e) {
        form['valid'] = false;
        setIsAdvisorPersonalNumberValid(false);
      }
    },
    [isAdvisorPersonalNumberValid],
  );

  const addCorrectoAdmin = useCallback(() => {
    setAdminMode(Mode.Add);
    replaceAdminDetails([
      ...adminDetails,
      {
        id: `${Math.random()}`,
        personalNumber: '',
        email: '',
        fullName: '',
        firstName: '',
        lastName: '',
        isActive: true,
        isNew: true,
        hasRecordExist: false,
      },
    ]);
  }, [adminMode, adminDetails]);

  const addInsuranceAdvisor = useCallback(() => {
    setAdminMode(Mode.Add);
    replaceInsuranceAdvisors([
      ...advisors,
      {
        id: `${Math.random()}`,
        personalNumber: '',
        email: '',
        fullName: '',
        firstName: '',
        lastName: '',
        isActive: true,
        isNew: true,
        hasRecordExist: false,
      },
    ]);
  }, [adminMode, advisors]);

  const deleteCorrectoAdmin = useCallback(
    async (form) => {
      const title = 'Are you sure you want to delete this admin?';

      const result = await ConfirmationDialog({
        title,
        confirmButtonText: t('yes'),
        denyButtonText: t('no'),
      });

      if (result.isConfirmed) {
        try {
          const _adminDetails = [...adminDetails];
          const selectedIndex = _adminDetails.findIndex((item) => item.id === form['id'])!;
          const selected = _adminDetails[selectedIndex];
          if (selected.hasRecordExist) {
            replaceAdminDetails(_adminDetails.filter((i) => i['id'] !== form['id']));
            setisDeleting(true);
            await deleteCompanyAdmin(form.personalNumber ?? '');
          }

          // navigate('/company-clients');
        } finally {
          setAdminMode(Mode.List);
          setisDeleting(false);
        }
      }
    },
    [isDeleting, adminDetails, adminMode],
  );

  const deleteInsuranceAdvisor = useCallback(
    async (form) => {
      const title = 'Are you sure you want to delete this advisor?';

      const result = await ConfirmationDialog({
        title,
        confirmButtonText: t('yes'),
        denyButtonText: t('no'),
      });

      if (result.isConfirmed) {
        try {
          const _advisors = [...advisors];
          const selectedIndex = _advisors.findIndex((item) => item.id === form['id'])!;
          const selected = _advisors[selectedIndex];
          if (selected.hasRecordExist) {
            replaceInsuranceAdvisors(_advisors.filter((i) => i['id'] !== form['id']));
            setisDeleting(true);
            await deleteAdvisor(form.personalNumber ?? '');
          }

          // navigate('/company-clients');
        } finally {
          setAdminMode(Mode.List);
          setisDeleting(false);
        }
      }
    },
    [isDeleting, advisors, adminMode],
  );

  const fetchAdminsList = useCallback(async () => {
    const admins = await fetchCompanyAdmins();
    const updatedAdmins = admins?.map((admin) => {
      const { firstName, lastName } = splitFullName(admin['fullName']);
      return {
        firstName,
        lastName,
        ...admin,
        isActive: false,
        hasRecordExist: true,
      };
    });
    _adminDetail = structuredClone(deepClone(updatedAdmins));
    replaceAdminDetails(updatedAdmins);
  }, []);

  const fetchAdviors = useCallback(async () => {
    const adviors = await fetchInsuranceAdviors();
    const updatedAdvisors = adviors?.map((admin) => {
      const { firstName, lastName } = splitFullName(admin['fullName']);
      return {
        firstName,
        lastName,
        ...admin,
        isActive: false,
        hasRecordExist: true,
      };
    });
    _advisorDetail = structuredClone(updatedAdvisors);
    replaceInsuranceAdvisors(_advisorDetail);
  }, []);

  useEffect(() => {
    if (adminMode === Mode.List) {
      fetchAdminsList();
      fetchAdviors();
    }
  }, [adminMode]);

  const hasChanges = useCallback(() => {
    return !isEqual(_adminDetail, adminDetails) || !isEqual(_advisorDetail, advisors);
  }, [adminDetails, advisors]);

  return {
    saveChanges,
    isLoading,
    cancelChanges,
    handleCorrectoAdmin,
    handleInsuranceAdvisor,
    deleteCorrectoAdmin,
    deleteInsuranceAdvisor,
    addCorrectoAdmin,
    addInsuranceAdvisor,
    loadAdminDetails,
    loadAdvisorDetails,
    hasChanges,
  } as any;
};

export default useGeneralSettingsEditor;
