import { createContext, useState } from 'react';
import { ContactPeople } from '../../../../api/service/superAdmin/company-clients/types';
import { INITIAL_CONTACT_PEOPLE, INITIAL_COMPANY_DETAILS, INITIAL_ADDRESS_DETAILS } from '../contants';
import {
  AccountingFirm,
  AddressDetails,
  AdminDetails,
  AuthorizedSignatory,
  AuthorizedSignatoryGroup,
  CollectiveAgreementType,
  CompanyClientFormGroup,
  CompanyDetails,
  Consultants,
  ICompanyClientContext,
} from '../types';
import { useFormik } from 'formik';
import { formSchema } from './schema';

export const EditCompanyClientContext = createContext<ICompanyClientContext>({
  companyDetails: INITIAL_COMPANY_DETAILS,
  setCompanyDetails: () => {},

  adminDetails: [],
  setAdminDetails: () => {},

  signatoryAdmins: [],
  setSignatoryAdmins: () => {},

  deletedAdmins: [],
  setDeletedAdmins: () => {},

  deletedConsultants: [],
  setDeletedConsultants: () => {},

  signatoryDetails: [],
  setSignatoryDetails: () => {},

  combinations: [],
  setCombinations: () => {},

  addressDetails: INITIAL_ADDRESS_DETAILS,
  setAddressDetails: () => {},

  salaryContactPeople: INITIAL_CONTACT_PEOPLE,
  setSalaryContactPeople: () => {},

  accountingContactPeople: INITIAL_CONTACT_PEOPLE,
  setAccountingContactPeople: () => {},

  contractEffectiveFrom: '',
  setContractEffectiveFrom: () => {},

  collectiveAgreements: [],
  setCollectiveAgreements: () => {},

  invoiceCostCenter: '',
  setInvoiceCostCenter: () => {},

  contractEmail: '',
  setContractEmail: () => {},

  contractNumber: '',
  setContractNumber: () => {},

  consultants: [],
  setConsultants: () => {},

  accountingFirms: [],
  setAccountingFirms: () => {},

  invitationEmail: '',
  setInvitationEmail: () => {},

  isEditMode: true,
  formGroup: {} as CompanyClientFormGroup,

  selectedCompanyClient: null,
  setCompanyClient: () => {},

  replaceAdminDetails: () => {},
  replaceSignatoryAdmins: () => {},
  replaceAddress: () => {},
  replaceAuthorizedSignatory: () => {},
  replaceCombinations: () => {},
  replaceCompanyDetails: () => {},
  replaceConsultants: () => {},
  replaceConsultant: () => {},
  replaceAccountingContactPeople: () => {},
  replaceSalaryContactPeople: () => {},
  appendDeletedAdmin: () => {},
  appendDeletedConsultant: () => {},
  updateAdminDetails: () => {},
  updateAddress: () => {},
  updateAuthorizedSignatory: () => {},
  updateCombinations: () => {},
  updateCompanyDetails: () => {},
  updateConsultants: () => {},
  updateConsultant: () => {},
  updateAccountingContactPeople: () => {},
  updateSalaryContactPeople: () => {},
  updateContractEffectiveInput: () => {},
  updateInvoicingCostCenter: () => {},
  updateInvoiceEmailAddress: () => {},
  updateContractNumber: () => {},
  replaceCollectiveAgreements: () => {},
  updateInvitationEmail: () => {},
  replaceAccessLevel: () => {},
});

type Props = {
  children: JSX.Element;
};

export const EditCompanyClientProvider = ({ children }: Props) => {
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(INITIAL_COMPANY_DETAILS);
  const [addressDetails, setAddressDetails] = useState<AddressDetails>(INITIAL_ADDRESS_DETAILS);
  const [adminDetails, setAdminDetails] = useState<Array<AdminDetails>>([]);
  const [signatoryDetails, setSignatoryDetails] = useState<Array<AuthorizedSignatory>>([]);
  const [signatoryAdmins, setSignatoryAdmins] = useState<Array<AuthorizedSignatory>>([]);
  const [combinations, setCombinations] = useState<Array<AuthorizedSignatoryGroup>>([]);
  const [salaryContactPeople, setSalaryContactPeople] = useState<ContactPeople>(INITIAL_CONTACT_PEOPLE);
  const [accountingContactPeople, setAccountingContactPeople] = useState<ContactPeople>(INITIAL_CONTACT_PEOPLE);
  const [contractEffectiveFrom, setContractEffectiveFrom] = useState<string>('');
  const [invoiceCostCenter, setInvoiceCostCenter] = useState<string>('');
  const [contractEmail, setContractEmail] = useState<string>('');
  const [contractNumber, setContractNumber] = useState<string>('');
  const [collectiveAgreements, setCollectiveAgreements] = useState<Array<CollectiveAgreementType>>([]);
  const [accountingFirms, setAccountingFirms] = useState<Array<AccountingFirm>>([]);
  const [consultants, setConsultants] = useState<Array<Consultants>>([]);
  const [invitationEmail, setInvitationEmail] = useState<string>('');
  const [selectedCompanyClient, setSelectedCompanyClient] = useState(null);
  const [deletedAdmins, setDeletedAdmins] = useState<Array<string>>([]);
  const [deletedConsultants, setDeletedConsultants] = useState<Array<string>>([]);
  const isEditMode = true;

  const CLIENT_INITIAL_VALUES = {
    companyDetails: { ...INITIAL_COMPANY_DETAILS },
    address: INITIAL_ADDRESS_DETAILS,
    contractEffectiveFrom: '',
    contractEmail: '',
    invoiceCostCenter: '',
    authorizedSignatory: [],
    combinations: [],
    admins: [],
    consultants: [],
    invitationEmail: '',
    salaryContactPeople: { ...INITIAL_CONTACT_PEOPLE },
    accountingContactPeople: { ...INITIAL_CONTACT_PEOPLE },
    allowCorrectoAccess: false,
    allowAdvisorAccess: false,
  };

  const formGroup = useFormik({
    initialValues: CLIENT_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const replaceAdminDetails = (value) => {
    setAdminDetails(value);
    formGroup.setFieldValue('admins', value);
  };

  const replaceSignatoryAdmins = (value) => {
    setSignatoryAdmins(value);
    formGroup.setFieldValue('signatoryAdmins', value);
  };

  const replaceAddress = (value) => {
    setAddressDetails(value);
    formGroup.setFieldValue('address', value);
  };

  const replaceAuthorizedSignatory = (value) => {
    setSignatoryDetails(value);
    formGroup.setFieldValue('authorizedSignatory', value);
    formGroup.setFieldTouched('address');
  };

  const replaceCombinations = (value) => {
    setCombinations(value);
    formGroup.setFieldValue('combinations', value);
  };

  const replaceCompanyDetails = (value) => {
    setCompanyDetails(value);
    formGroup.setFieldValue('companyDetails', value);
    formGroup.setFieldTouched('companyDetails');
  };

  const replaceConsultants = (value) => {
    setConsultants(value);
    formGroup.setFieldValue('consultants', value);
  };
  const replaceConsultant = (value) => {
    formGroup.setFieldValue('consultant', value);
  };
  const replaceAccountingContactPeople = (value) => {
    setAccountingContactPeople(value);
    formGroup.setFieldValue('accountingContactPeople', value);
  };

  const replaceSalaryContactPeople = (value) => {
    setSalaryContactPeople(value);
    formGroup.setFieldValue('salaryContactPeople', value);
  };

  const updateAddress = (inputName, inputValue) => {
    setAddressDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`address.${inputName}`, inputValue);
  };

  const updateAdminDetails = (inputName, inputValue) => {
    setAdminDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`admins.${inputName}`, inputValue);
  };

  const updateAuthorizedSignatory = (inputName, inputValue) => {
    setSignatoryDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`authorizedSignatory.${inputName}`, inputValue);
  };

  const updateCombinations = (inputName, inputValue) => {
    setCombinations((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`combinations.${inputName}`, inputValue);
  };

  const updateCompanyDetails = (inputName, inputValue) => {
    setCompanyDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`companyDetails.${inputName}`, inputValue);
  };

  const updateConsultants = (inputName, inputValue) => {
    setConsultants((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`consultants.${inputName}`, inputValue);
  };

  const updateConsultant = (inputName, inputValue) => {
    formGroup.setFieldValue(`consultant.${inputName}`, inputValue);
  };

  const updateAccountingContactPeople = (inputName, inputValue) => {
    setAccountingContactPeople((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`accountingContactPeople.${inputName}`, inputValue);
  };

  const updateSalaryContactPeople = (inputName, inputValue) => {
    setSalaryContactPeople((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`salaryContactPeople.${inputName}`, inputValue);
  };

  const updateContractEffectiveInput = (inputValue) => {
    setContractEffectiveFrom(inputValue);
    formGroup.setFieldValue(`contractEffectiveFrom`, inputValue);
  };

  const updateInvoicingCostCenter = (inputValue) => {
    setInvoiceCostCenter(inputValue);
    formGroup.setFieldValue(`invoiceCostCenter`, inputValue);
  };

  const updateInvoiceEmailAddress = (inputValue) => {
    setContractEmail(inputValue);
    formGroup.setFieldValue(`contractEmail`, inputValue);
  };

  const updateContractNumber = (inputValue) => {
    setContractNumber(inputValue);
    formGroup.setFieldValue(`contractNumber`, inputValue);
  };

  const replaceCollectiveAgreements = (inputValue) => {
    setCollectiveAgreements(inputValue);
    formGroup.setFieldValue('collectiveAgreements', inputValue);
  };

  const updateInvitationEmail = (inputValue) => {
    setInvitationEmail(inputValue);
    formGroup.setFieldValue(`invitationEmail`, inputValue);
  };

  const setCompanyClient = (companyClient) => {
    setSelectedCompanyClient(companyClient);
  };

  const appendDeletedAdmin = (value) => {
    if (!isEditMode) {
      return;
    }

    const _deletedAdmins = [...deletedAdmins];
    _deletedAdmins.push(value);

    setDeletedAdmins(_deletedAdmins);
  };

  const appendDeletedConsultant = (value) => {
    if (!isEditMode) {
      return;
    }

    const _deletedConsultants = [...deletedConsultants];
    _deletedConsultants.push(value);

    setDeletedConsultants(_deletedConsultants);
  };

  const replaceAccessLevel = (allowCorrectoAccess, allowAdvisorAccess) => {
    formGroup.setFieldValue(`allowCorrectoAccess`, allowCorrectoAccess);
    formGroup.setFieldValue(`allowAdvisorAccess`, allowAdvisorAccess);
  };

  const context: ICompanyClientContext = {
    companyDetails,
    setCompanyDetails,
    adminDetails,
    setAdminDetails,
    signatoryDetails,
    setSignatoryDetails,
    signatoryAdmins,
    setSignatoryAdmins,
    combinations,
    setCombinations,
    addressDetails,
    setAddressDetails,
    salaryContactPeople,
    setSalaryContactPeople,
    accountingContactPeople,
    setAccountingContactPeople,
    contractEffectiveFrom,
    setContractEffectiveFrom,
    isEditMode: true,
    collectiveAgreements,
    setCollectiveAgreements,
    invoiceCostCenter,
    setInvoiceCostCenter,
    contractEmail,
    setContractEmail,
    contractNumber,
    setContractNumber,
    consultants,
    setConsultants,
    accountingFirms,
    setAccountingFirms,
    invitationEmail,
    setInvitationEmail,
    formGroup: formGroup as any,
    deletedAdmins,
    setDeletedAdmins,
    deletedConsultants,
    setDeletedConsultants,
    replaceAdminDetails,
    replaceAddress,
    replaceSignatoryAdmins,
    replaceAuthorizedSignatory,
    replaceCombinations,
    replaceCompanyDetails,
    replaceConsultants,
    replaceConsultant,
    replaceAccountingContactPeople,
    replaceSalaryContactPeople,
    appendDeletedAdmin,
    appendDeletedConsultant,
    updateAdminDetails,
    updateAddress,
    updateAuthorizedSignatory,
    updateCombinations,
    updateCompanyDetails,
    updateConsultants,
    updateConsultant,
    updateAccountingContactPeople,
    updateSalaryContactPeople,
    updateContractEffectiveInput,
    updateInvoiceEmailAddress,
    updateContractNumber,
    updateInvoicingCostCenter,
    replaceCollectiveAgreements,
    updateInvitationEmail,
    selectedCompanyClient,
    setCompanyClient,
    replaceAccessLevel,
  };

  return <EditCompanyClientContext.Provider value={context}>{children}</EditCompanyClientContext.Provider>;
};
