import { Form } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import { Mode } from '../types';
import { useSuperAdminSettingsContext } from '../useContext';
import IntegrationInformation from './IntegrationForm/IntegrationInformation';
import TypeOfIntegration from './IntegrationForm/TypeOfIntegration';
import useContext from './context/useContext';

const EditIntegration = () => {
  const { mode } = useSuperAdminSettingsContext();
  const context = useContext();

  const isEditable = [Mode.Edit, Mode.Detail].indexOf(mode) !== -1;

  const bodyContainer = (
    <Form>
      <IntegrationInformation context={context} mode={mode} />
      <TypeOfIntegration context={context} mode={mode} />
    </Form>
  );

  return <>{isEditable ? <CardComponent>{bodyContainer}</CardComponent> : <>{bodyContainer}</>}</>;
};

export default EditIntegration;
