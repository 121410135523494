import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Plus, Slash, UserPlus, UserX } from 'react-feather';
import useLocale from '../../../../hooks/useLocale';
import { deepClone, hasEmptyValues } from '../../../../util/util';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import useEmployeeContext from '../useContext';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';
import moment from 'moment';

function ParentalInfo() {
  const { t } = useTranslation();
  const { localize } = useLocale();
  const { replaceParentalLeave, parentalInfo } = useEmployeeContext();
  const [chosenChild, setChosenChild] = useState<any>({ firstName: '', lastName: '', birthDate: '' });
  const [showAddBtn, setShowAddBtn] = useState(true);
  const isDisableAddBtn = hasEmptyValues(chosenChild);
  const handleChangeChildDetail = (e, name) => {
    const value = e.target.value;

    setChosenChild((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const addChildHandler = () => {
    const _parentalInfo = [...parentalInfo];

    _parentalInfo.push(deepClone(chosenChild));
    replaceParentalLeave(_parentalInfo);

    setChosenChild({
      firstName: '',
      lastName: '',
      birthDate: '',
    });
    setShowAddBtn(true);
  };

  const onAddHandler = () => {
    setShowAddBtn(false);

    setChosenChild({
      firstName: '',
      lastName: '',
      birthDate: '',
    });
  };

  const onRemoveChildRow = (idx) => {
    const _parentalInfo = [...parentalInfo];

    _parentalInfo.splice(idx, 1);
    replaceParentalLeave(_parentalInfo);
  };

  const onCancel = () => {
    setShowAddBtn(true);

    setChosenChild({
      firstName: '',
      lastName: '',
      birthDate: '',
    });
  };

  return (
    <div>
      <h3 className="fs-22px theme-text mt-3 mb-2 font-weight-600">
        {' '}
        {localize('EMPLOYEE_PENSION.parental_leave_children')}
      </h3>
      <div className="dark-content-section">
        <Form>
          <p className="theme-text">{localize('EMPLOYEE_PENSION.parental_leave_text')}</p>
          {(parentalInfo || []).length > 0 && (
            <div className="form-section mb-3 dark-content-section">
              {(parentalInfo || [])?.map((item, idx) => (
                <div key={idx}>
                  {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

                  <div className="cr-form-row mb-1">
                    <div className="full-name-field">
                      <span className="theme-text fs-14px">
                        {item.firstName}
                        {item.lastName ? ` ${item.lastName}` : ''}
                      </span>
                    </div>
                    <div className="creation-date-field">
                      <span className="theme-text fs-14px">{moment(item.birthDate).format('YYYY-MM-DD')}</span>
                    </div>
                    <div className="last-button-box">
                      <span className="me-2 mb-0">
                        <TooltipBox msg={`${localize('delete_user')}`}>
                          <UserX onClick={() => onRemoveChildRow(idx)} className="cursor-pointer delete-icon" />
                        </TooltipBox>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!showAddBtn && (
            <div className="">
              <div className="mb-3 align-items-end">
                <div className="cr-form-row">
                  <Form.Group className="first-name-field">
                    <Form.Label className="fs-name">{t('first_name')}</Form.Label>
                    <Form.Control
                      name="firstName"
                      value={chosenChild.firstName}
                      onChange={(e) => handleChangeChildDetail(e, 'firstName')}
                    />
                  </Form.Group>

                  <Form.Group className="last-name-field">
                    <Form.Label className="fs-name">{t('last_name')}</Form.Label>
                    <Form.Control name="lastName" onChange={(e) => handleChangeChildDetail(e, 'lastName')} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fs-name">{t('birth_date')}</Form.Label>
                    <Form.Control
                      name="birthDate"
                      type="date"
                      onChange={(e) => handleChangeChildDetail(e, 'birthDate')}
                    />
                  </Form.Group>
                  <Button
                    className="app-btn-secondary btn btn-primary"
                    onClick={addChildHandler}
                    disabled={isDisableAddBtn}
                  >
                    <Plus className="icon-gap-right" />
                    {t('EMPLOYEE_PENSION.add_child')}
                  </Button>
                  <Button className="app-btn-secondary btn btn-primary" onClick={onCancel}>
                    <Slash className="icon-gap-right" />
                    {t('cancel')}
                  </Button>
                </div>
                <HorizontalRule color="#C6C6C6" height="1px" />
              </div>
            </div>
          )}
        </Form>
        {showAddBtn && (
          <Button className="app-btn-secondary" onClick={onAddHandler}>
            <UserPlus className="theme-text" />
          </Button>
        )}
      </div>
    </div>
  );
}

export default ParentalInfo;
