import { useEffect, useState } from 'react';
import useContext from '../PensionPolicyContext/useContext';
import { deepClone, generateNumberList, generateUniqueId, isEqual, toNumber } from '../../../../util/util';

const GROUP_LEVELS = Array(4)
  .fill(0)
  .map((_item, idx) => {
    const incomeLevels = new Array(4).fill(0).map((__item, colIndex) => {
      return deepClone({
        id: generateUniqueId(),
        index: colIndex,
        x: `Age group ${idx + 1}`,
        y: `Income level ${colIndex + 1}`,
        value: null,
      });
    });

    return {
      id: generateUniqueId(),
      index: idx,
      age: {
        min: 0,
        max: 0,
      },
      indexAmount: {
        min: 0,
        max: 0,
      },
      ageBoundary: [idx, idx + 1],
      incomeLevels: incomeLevels,
    };
  });

const DEFAULT_BOUNDARY = new Array(5).fill(null);

const generateAgeOptions = (options: number[] = []) => {
  return options.map((item) => {
    return { label: item, value: item, disabled: false };
  });
};

function findMaxNumber(numbers) {
  if (!Array.isArray(numbers) || numbers.length === 0) {
    return null;
  }

  let largestNumber = numbers[0];

  for (let i = 1; i < numbers.length; i++) {
    if (numbers[i] > largestNumber) {
      largestNumber = numbers[i];
    }
  }

  return largestNumber;
}

function findMinNumber(numbers) {
  if (!Array.isArray(numbers) || numbers.length === 0) {
    return null;
  }

  let minNumber = Infinity;

  for (let i = 0; i < numbers.length; i++) {
    const num = numbers[i];
    if (num != null && num !== '' && num < minNumber) {
      minNumber = num;
    }
  }

  return minNumber === Infinity ? null : minNumber;
}

function compareTwoList(array1, array2) {
  if (!Array.isArray(array1) || !Array.isArray(array2)) {
    return false;
  }

  return isEqual(array1, array2);
}

const useIncomeCalculation = () => {
  const [groupCollection, setGroupCollection] = useState<any[]>(deepClone(GROUP_LEVELS));
  const [ageCollection, setAgeCollection] = useState(DEFAULT_BOUNDARY);
  const [amountIndexCollection, setAmountIndexCollection] = useState(DEFAULT_BOUNDARY);
  const [amountIndexTimerCollection, setAmountIndexTimerCollection] = useState(DEFAULT_BOUNDARY);
  const [ageOptions, setAgeOptions] = useState(new Array(5).fill(generateAgeOptions(generateNumberList(18, 80))));

  const { formGroup, selectedPolicyDetails, isEditorMode, isResetPolicy } = useContext();

  const updateGroupChanges = () => {
    const _groupCollection = deepClone(groupCollection);

    const updatedCollection = _groupCollection.map((group, idx) => {
      group.age['min'] = ageCollection[idx];
      group.age['max'] = ageCollection[idx + 1];

      group.indexAmount['min'] = amountIndexCollection[idx];
      group.indexAmount['max'] = amountIndexCollection[idx + 1];

      return group;
    });

    setGroupCollection(updatedCollection);
  };

  const setGroupItemIncomeValue = (groupIndex, colIndex, value) => {
    const _groupCollection = deepClone(groupCollection);

    _groupCollection[groupIndex].incomeLevels[colIndex].value = value;

    setGroupCollection(_groupCollection);
  };

  const toRequestedModel = () => {
    const collection: any[] = [];

    groupCollection.forEach((item, idx) => {
      const entity = {
        x: `Age group ${idx + 1}`,
        y: '',
        value: null,
        age: {
          min: toNumber(item.age.min),
          max: toNumber(item.age.max),
        },
      };

      item.incomeLevels.forEach((incomeLevelItem, levelIdx) => {
        const clonedEntity = deepClone(entity);

        clonedEntity['y'] = `Income level ${levelIdx + 1}`;
        clonedEntity['value'] = incomeLevelItem.value;

        clonedEntity['indexAmount'] = {
          min: toNumber(amountIndexCollection[levelIdx]),
          max: toNumber(amountIndexCollection[levelIdx + 1]),
        };

        collection.push(clonedEntity);
      });
    });

    formGroup.setFieldValue('policySettings.occupationalPension.calculation', collection);
  };

  const extractNumberFromText = (text) => {
    let parsedText = (text || '').match(/\d+/);
    if (parsedText) {
      return parseInt(parsedText[0], 10);
    }

    return null;
  };

  const patchCalculationDetails = (_groupCollection = []) => {
    const metrixBoundarySet = new Array(4).fill(0);
    const ageGroups: number[] = [];
    const amountIndexGroups: number[] = [];

    metrixBoundarySet.forEach((_, index) => {
      if (index === 0) {
        const initialAgeBoundary: any = _groupCollection.find((item: any) => {
          const ageBoundaryPoint = extractNumberFromText(item.x);
          const incomeBoundaryPoint = extractNumberFromText(item.y);

          return ageBoundaryPoint === 1 && incomeBoundaryPoint === 1;
        });

        ageGroups.push(initialAgeBoundary.age.min);
        amountIndexGroups.push(initialAgeBoundary.indexAmount.min);
      }

      const foundBoundary: any = _groupCollection.find((item: any) => {
        const ageBoundaryPoint = extractNumberFromText(item.x);
        const incomeBoundaryPoint = extractNumberFromText(item.y);

        return ageBoundaryPoint === index + 1 && incomeBoundaryPoint === index + 1;
      });

      if (foundBoundary) {
        ageGroups.push(foundBoundary!.age.max);
        amountIndexGroups.push(foundBoundary!.indexAmount.max);

        return;
      }

      ageGroups.push(0);
      amountIndexGroups.push(0);
    });

    const groupLevels = deepClone(GROUP_LEVELS).map((groupItem, index) => {
      groupItem.incomeLevels = groupItem.incomeLevels.map((incomeItem, colIndex) => {
        const foundBoundary: any = _groupCollection.find((item: any) => {
          const ageBoundaryPoint = extractNumberFromText(item.x);
          const incomeBoundaryPoint = extractNumberFromText(item.y);

          return ageBoundaryPoint === index + 1 && incomeBoundaryPoint === colIndex + 1;
        });

        if (foundBoundary) {
          incomeItem.value = foundBoundary.value;
          groupItem.age = {
            ...groupItem.age,
            ...foundBoundary.age,
          };
          groupItem.indexAmount = {
            ...groupItem.indexAmount,
            ...foundBoundary.indexAmount,
          };
        } else {
          incomeItem.value = 0;
        }

        return incomeItem;
      });

      return groupItem;
    });

    setAgeCollection(ageGroups);
    setAmountIndexCollection(amountIndexGroups);
    setGroupCollection(groupLevels);
  };

  const validateAmountComparison = (value, index) => {
    const callback = () => {
      const prevAmountCollection = amountIndexCollection.filter((_, prevIndex) => prevIndex < index).map((val) => val);

      const nextAmountCollection = amountIndexCollection.filter((_, prevIndex) => prevIndex > index).map((val) => val);

      const fromFirstIndexMaxNumber = index === 0 ? findMinNumber(nextAmountCollection) : null;
      const fromMaxNumber = index !== 0 ? findMaxNumber(prevAmountCollection) : null;
      const toMaxNumber = findMaxNumber(nextAmountCollection);

      if (fromFirstIndexMaxNumber !== null && value > fromFirstIndexMaxNumber) {
        const _amountIndexCollection = [...amountIndexCollection];
        _amountIndexCollection[0] = null;

        setAmountIndexCollection((prev: any[]) => {
          prev[0] = null;
          return [...prev];
        });

        return;
      }

      if (fromMaxNumber !== null && value < fromMaxNumber) {
        const _amountIndexCollection = [...amountIndexCollection];
        _amountIndexCollection[index] = null;

        setAmountIndexCollection((prev: any[]) => {
          prev[index] = null;
          return [...prev];
        });

        return;
      }

      if (toMaxNumber !== null && value > toMaxNumber) {
        const _amountIndexCollection = [...amountIndexCollection];
        _amountIndexCollection[index] = null;

        setAmountIndexCollection((prev: any[]) => {
          prev[index] = null;
          return [...prev];
        });

        return;
      }
    };

    if (amountIndexTimerCollection[index]) {
      clearTimeout(amountIndexTimerCollection[index]);
    }

    const timer = setTimeout(() => {
      callback();
    }, 600);

    setAmountIndexTimerCollection((prev: any[]) => {
      prev[index] = timer;
      return [...prev];
    });
  };

  const clearForm = () => {
    setGroupCollection(deepClone(GROUP_LEVELS));
    setAgeCollection(deepClone(DEFAULT_BOUNDARY));
    setAmountIndexCollection(deepClone(DEFAULT_BOUNDARY));
    setAgeOptions(new Array(5).fill(generateAgeOptions(generateNumberList(18, 80))));
  };

  useEffect(() => {
    if (!isEditorMode) return;
    updateGroupChanges();
  }, [ageCollection, amountIndexCollection]);

  useEffect(() => {
    if (!isEditorMode) return;
    toRequestedModel();
  }, [groupCollection]);

  useEffect(() => {
    if (ageCollection.length) {
      const _ageOptions = ageOptions.map((value, index) => {
        /* if (index === 0) {
          return value;
        } */

        const prevAgeCollection = ageCollection.filter((_, prevIndex) => prevIndex < index).map((val) => val);
        const nextAgeCollection = ageCollection.filter((_, prevIndex) => prevIndex > index).map((val) => val);
        const fromMaxNumber = findMaxNumber(prevAgeCollection);
        const toMaxNumber = findMaxNumber(nextAgeCollection);

        const availableOptions = generateAgeOptions(generateNumberList(18, 80)).map((item, idx) => {
          if (fromMaxNumber !== null && item.value < fromMaxNumber) {
            item.disabled = true;
          }

          if (toMaxNumber !== null && item.value > toMaxNumber) {
            item.disabled = true;
          }

          return item;
        });

        return availableOptions;
      });

      const _ageCollection = ageCollection.map((value, index) => {
        const prevAgeCollection = ageCollection.filter((_, prevIndex) => prevIndex < index).map((val) => val);
        const nextAgeCollection = ageCollection.filter((_, prevIndex) => prevIndex > index).map((val) => val);
        const fromMaxNumber = findMaxNumber(prevAgeCollection);
        const toMaxNumber = findMaxNumber(nextAgeCollection);

        if (fromMaxNumber !== null && value < fromMaxNumber) {
          return null;
        }

        if (toMaxNumber !== null && value > toMaxNumber) {
          return null;
        }

        return value;
      });

      setAgeOptions(_ageOptions);

      if (!compareTwoList(ageCollection, _ageCollection)) {
        setAgeCollection(_ageCollection);
      }
    }
  }, [ageCollection]);

  //reset the form
  useEffect(() => {
    if (isResetPolicy) {
      clearForm();
    }
  }, [isResetPolicy]);

  useEffect(() => {
    if (selectedPolicyDetails) {
      patchCalculationDetails(selectedPolicyDetails.policySettings.occupationalPension.calculation);
    }
  }, [selectedPolicyDetails]);

  return {
    groupCollection,
    setGroupItemIncomeValue,
    ageCollection,
    ageOptions,
    amountIndexCollection,
    setAgeCollection,
    setAmountIndexCollection,
    validateAmountComparison,
  };
};

export default useIncomeCalculation;
