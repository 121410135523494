import { Form } from 'react-bootstrap';
import ErrorFeedback from '../../ErrorFeedback';
import classNames from 'classnames';
import { errorHighlighter } from '../../../util/form';
import { useEffect, useState } from 'react';
import { isEmptyVal, numberFormat, toNumber } from '../../../util/util';

const variants = {
  xs: 100,
  sm: 120,
  md: 150,
  xmd: 140,
  lg: 180,
  xlg: 200,
};

interface PercentageNumberProps {
  disabled?: boolean;
  field?: any;
  value?: string | number;
  type?: string;
  className?: string;
  name?: string;
  defaultValue?: string | number | undefined;
  label?: string | undefined | null;
  variant?: keyof typeof variants;
  maxWidth?: number;
  placeholder?: string | undefined;
  key?: string;
  labelSuffix?: string | number | undefined;
  labelSuffixClassname?: string;
  isRequiredError?: boolean;
  labelWidth?: number;
  labelStyle?: Object;
  inputStyle?: Object;
  labelClass?: string;
  formGroupClass?: string;
  decimalPlace?: number;
  onChangeHandler?: (e) => void;
  onBlurHandler?: (e) => void;
}
export const PercentageNumber = ({
  value,
  type = 'text',
  name,
  disabled = false,
  defaultValue,
  key,
  maxWidth,
  field,
  variant,
  placeholder,
  label,
  labelSuffix,
  className,
  labelClass,
  labelSuffixClassname,
  isRequiredError = false,
  formGroupClass,
  labelStyle,
  inputStyle,
  labelWidth,
  decimalPlace = 0,
  onChangeHandler,
  onBlurHandler,
}: PercentageNumberProps) => {
  const parseValue = value?.toString();
  const [percentage, setPercentage] = useState<any>(parseValue);

  const [isFocused, setIsFocused] = useState(false);
  let inputNumber;
  const formatNumber = (inputValue?: string) => {
    if (!isEmptyVal(inputValue)) {
      const formattedNumber = numberFormat(toNumber(inputValue));
      return formattedNumber;
    }
    return '';
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onBlurHandler) {
      onBlurHandler(percentage);
    }
    // onBlurHandler!(inpuNumericValue);
  };
  const handleChange = (e) => {
    let inputValue = e.target.value;
    const numericValue = toNumber(inputValue, true, decimalPlace);
    const decimalSeparaterValues = inputValue.split('.').length - 1;

    if (decimalSeparaterValues >= 2 || numericValue > 100) {
      return;
    }
    if (decimalPlace === 0 || numericValue === 100) {
      inputNumber = inputValue.replace(/[^0-9]/g, '');
    } else {
      inputNumber = inputValue.replace(/[^0-9.,]/g, '').replace(/(\..*)\./g, '$1');
    }
    const [, decimal] = inputNumber.split('.');
    if (decimal?.length > decimalPlace) {
      return;
    }

    setPercentage(inputNumber || '');
    if (onChangeHandler) {
      onChangeHandler(numericValue);
    }
  };

  useEffect(() => {
    setPercentage(parseValue || '');
  }, [value]);

  return (
    <>
      <div className='scope-of-work-field'>
        {label && (
          <>
            <Form.Label
              className={classNames(
                'fs-name',
                labelClass,
                // `${isRequiredError ? requiredError(field) : field ? 'required' : ''}`,
              )}
              style={{ width: labelWidth }}
            >
              {label}{' '}
              <span className={classNames('fs-name font-weight-400 theme-text', labelSuffixClassname)}>
                {labelSuffix}
              </span>
            </Form.Label>
          </>
        )}
        <Form.Control
          key={key}
          value={!isFocused ? formatNumber(percentage)! : percentage}
          type={type}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          name={name}
          disabled={disabled}
          defaultValue={defaultValue}
          className={classNames('form-control', className, field ? errorHighlighter(field) : '')}
          style={inputStyle}
        ></Form.Control>
        {field && <ErrorFeedback field={field} />}{' '}
      </div>
    </>
  );
};
