import { SetStateAction, Dispatch } from 'react';
import BSModal from '../../../../../components/Modal';
import { useValidationError } from '../../../../../hooks/useValidationError';
import { Form } from 'react-bootstrap';
import ValidationAlert from '../../../../../components/ValidationAlert';
import useContext from '../context/useContext';
import useLocale from '../../../../../hooks/useLocale';
import { INITIAL_CONTACT_INFORMATION, INITIAL_INSURANCE_COMPANY } from '../constants';
import EditInsurance from '../EditInsurance';
import { CreateInsuranceModalFooter } from './CreateInsuranceModalFooter';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsInsuranceCreated: Dispatch<SetStateAction<boolean>>;
};

export const CreateInsuranceModal = ({ showCreateModal, toggleModal, setIsInsuranceCreated }: Props) => {
  const { isInvalid, errors, setErrors } = useValidationError();
  const { setInsuranceCompany, setContactInformation } = useContext();
  const { localize } = useLocale();
  return (
    <>
      <BSModal
        title={`${localize('add_insurance_company')}`}
        show={showCreateModal}
        onHide={() => {
          setInsuranceCompany(INITIAL_INSURANCE_COMPANY);
          setContactInformation(INITIAL_CONTACT_INFORMATION);
          toggleModal();
        }}
        centered
        ModalFooter={
          <CreateInsuranceModalFooter
            setIsInsuranceCreated={setIsInsuranceCreated}
            toggleModal={toggleModal}
            setErrors={setErrors}
          />
        }
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />
          <EditInsurance />
        </Form>
      </BSModal>
    </>
  );
};
