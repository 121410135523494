import { useParams } from 'react-router-dom';

import {
  getArchivevdEmployeePensions,
  getArchivevdPensionByID,
} from '../../../../api/service/company/employees/service';
import { getPolicyDetails } from '../../../../api/service/superAdmin/pension-policies/service';
import { EmployeeArchivedPension } from '../../../superAdmin/PensionPolicy/types';
import useEmployeePensionContext from '../useContext';
import useActionLoading from './useActionLoading';
import usePensionTabMode from './usePensionTabMode';
import { PensionTabKey } from '../types';
import { EmployeePensionRecord } from '../entity/EmployeePensionRecord';

const useArchivedPension = () => {
  const params = useParams();
  const employeeID = params.id;

  const { archivedPensionList, selectedArchivedPension, setSelectedArchivedPension, setArchivedPensionList } =
    useEmployeePensionContext();
  const actionLoading = useActionLoading();
  const pensionTabMode = usePensionTabMode();

  const fetchAllArchivedPensionList = async () => {
    try {
      actionLoading.toggle('loadAchivedPension', true);

      const archivedPensions = await getArchivevdEmployeePensions(employeeID);

      setArchivedPensionList(archivedPensions);
    } catch (e) {
      console.error(e);
    } finally {
      actionLoading.toggle('loadAchivedPension', false);
    }
  };

  const fetchArchivedPensionDetails = async (id) => {
    try {
      actionLoading.toggle('loadAchivedPension', true);

      const pension = (await getArchivevdPensionByID(id)) as EmployeeArchivedPension;
      const policyDetails = await getPolicyDetails(pension.current.id);

      const employeePensionSettings = EmployeePensionRecord.toPolicySettings(pension.current, employeeID!);

      setSelectedArchivedPension({
        ...pension,
        currentInternal: employeePensionSettings!,
        policyMetaDetails: policyDetails,
      });
    } catch (e) {
      console.error(e);
    } finally {
      actionLoading.toggle('loadAchivedPension', false);
    }
  };

  const clearArchiveDetails = () => {
    setSelectedArchivedPension(null);
  };

  const handleSelect = (id) => {
    pensionTabMode.toggleTab(PensionTabKey.ARCHIVE);
    fetchArchivedPensionDetails(id);
  };

  return {
    archivedPensionList,
    selectedArchivedPension,
    loadingArchivedPension: actionLoading.loadAchivedPension,
    fetchAllArchivedPensionList,
    handleSelect,
    clearArchiveDetails,
  };
};

export default useArchivedPension;
