import { useEffect, useState } from 'react';
import { isEqual } from '../../../../../util/util';
import useContext from '../../PensionPolicyContext/useContext';
import { TypePensionManager } from '../../types';

const useEmployeePensionManagerEffectiveDate = () => {
  const {
    preAssignedPolicy,
    formGroup,
    getPolicySettingsInput,
    updatePolicySettingsInput,
    externalPolicyRevision,
    selectedPolicyDetails,
  } = useContext();

  const [isModifyEffectiveDate, setIsModifyEffectiveDate] = useState(true);

  const updateEmployeePensionSettings = (keyName: string, val) => {
    updatePolicySettingsInput(`employeeMetaSettings.pensionManager.${keyName}`, val);
  };

  const getEmployeePensionSettings = (keyName: string) => {
    return getPolicySettingsInput(`employeeMetaSettings.pensionManager.${keyName}`);
  };

  const postToggleEffectiveDate = () => {
    if (
      externalPolicyRevision?.assignedEmployeePension &&
      selectedPolicyDetails &&
      externalPolicyRevision?.assignedEmployeePension?.currentPolicy?.id !== selectedPolicyDetails?._id
    ) {
      setIsModifyEffectiveDate(false);
    }
  };

  const disableEffectiveDateIfInvalid = () => {
    if (!preAssignedPolicy?.id) {
      setIsModifyEffectiveDate(true);
      updateEmployeePensionSettings('effectiveFrom', '');

      return;
    }

    const _currentPolicySettings = formGroup.values?.policySettings;
    const _initialPolicySettings = externalPolicyRevision?.assignedEmployeePension?.policySettings || null;

    const preAssignedPensionManagers: TypePensionManager[] = _initialPolicySettings?.pensionManagers?.map((item) => {
      return {
        pensionManagerId: item.pensionManagerId || '',
        planName: item.planName || '',
        fromOccupationalPension: {
          customerNumber: item?.fromOccupationalPension?.customerNumber || '',
          insuranceNumber: item?.fromOccupationalPension?.insuranceNumber || '',
          isDefault: item?.fromOccupationalPension?.isDefault || false,
          isInclude: item?.fromOccupationalPension?.isInclude || false,
        },
        fromSalaryChange: {
          customerNumber: item?.fromSalaryChange?.customerNumber || '',
          insuranceNumber: item?.fromSalaryChange?.insuranceNumber || '',
          isDefault: item?.fromSalaryChange?.isDefault || false,
          isInclude: item?.fromSalaryChange?.isInclude || false,
        },
      };
    });

    const currentPensionManagers: TypePensionManager[] = (_currentPolicySettings?.pensionManagers || [])?.map(
      (item) => {
        return {
          pensionManagerId: item.pensionManagerId || '',
          planName: item.planName || '',
          fromOccupationalPension: {
            customerNumber: item?.fromOccupationalPension?.customerNumber || '',
            insuranceNumber: item?.fromOccupationalPension?.insuranceNumber || '',
            isDefault: item?.fromOccupationalPension?.isDefault || false,
            isInclude: item?.fromOccupationalPension?.isInclude || false,
          },
          fromSalaryChange: {
            customerNumber: item?.fromSalaryChange?.customerNumber || '',
            insuranceNumber: item?.fromSalaryChange?.insuranceNumber || '',
            isDefault: item?.fromSalaryChange?.isDefault || false,
            isInclude: item?.fromSalaryChange?.isInclude || false,
          },
        };
      },
    );

    if (isEqual(preAssignedPensionManagers, currentPensionManagers)) {
      setIsModifyEffectiveDate(false);
      updateEmployeePensionSettings(
        'effectiveFrom',
        _initialPolicySettings?.employeeMetaSettings?.pensionManager?.effectiveFrom,
      );
    } else {
      setIsModifyEffectiveDate(true);
      updateEmployeePensionSettings('effectiveFrom', '');
    }
  };

  const defaultStartEffectiveDate = () => {
    if (!isModifyEffectiveDate) {
      return getEmployeePensionSettings(`effectiveFrom`);
    }

    return null;
  };

  useEffect(() => {
    if (preAssignedPolicy?.id) {
      disableEffectiveDateIfInvalid();
    }

    postToggleEffectiveDate();
  }, [
    formGroup.values?.policySettings?.pensionManagers,
    preAssignedPolicy,
    externalPolicyRevision,
    selectedPolicyDetails,
  ]);

  return {
    isModifyEffectiveDate,
    defaultStartEffectiveDate,
    updateEmployeePensionSettings,
    getEmployeePensionSettings,
  };
};

export default useEmployeePensionManagerEffectiveDate;
