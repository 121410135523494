import { FormikProps, useFormik } from 'formik';
import { createContext, useEffect, useState } from 'react';
import moment from 'moment';

import {
  Article,
  ArticleFormField,
  ISalarySlipContext,
  SalaryErrorType,
  SalaryException,
  SalarySlipFormGroup,
  SalarySlipFormGroupCtx,
  SalarySlipRecord,
} from '../types';
import { formSchema } from '../SalarySlipDetail/schema';
import { SalaryType } from '../../../../types';
import { excludeKeys } from '../../../../util/util';

export type ConsultantsFormGroup = FormikProps<SalarySlipRecord>;

const INITIAL_FORM: SalarySlipFormGroup = {
  articles: [],
};

export const SalarySlipContext = createContext<ISalarySlipContext>({
  selectedDetails: null,
  setSelectedDetails: () => {},
  salaryTypes: [],
  setSalaryTypes: () => {},
  errorType: null,
  formGroup: {} as SalarySlipFormGroupCtx,
  initialize: () => {},
  replaceArticles: () => {},
  setFormField: (name: string, value: unknown) => {},
  formatDate: (date?: string) => '',
  normalizeRequestPayload: (formData?: any) => {},
});

type Props = {
  children: JSX.Element;
};

export const SalarySlipProvider = ({ children }: Props) => {
  const [selectedDetails, setSelectedDetails] = useState<SalarySlipRecord | null>(null);
  const [errorType, setErrorType] = useState<SalaryException | null>(null);
  const [salaryTypes, setSalaryTypes] = useState<SalaryType[]>([]);

  const formGroup = useFormik({
    initialValues: INITIAL_FORM,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const replaceArticles = (val: Article[]) => {
    const articleFormFields: ArticleFormField[] = val.map((item) => {
      return Object.assign(
        {
          _internal: {
            articleId: item?.article?.salaryCodeData?.id || '',
            disabledArticle: false,
            isLocked: true,
          },
          ...item,
          updatedValue:
            item.updatedValue === null || item.updatedValue === undefined ? item.orignalValue : item.updatedValue,
        },
        item.fromDate && {
          fromDate: moment(item.fromDate).format('YYYY-MM-DD'),
        },
        item.toDate && {
          toDate: moment(item.toDate).format('YYYY-MM-DD'),
        },
      );
    });

    formGroup.setFieldValue('articles', articleFormFields);
  };

  const normalizeRequestPayload = (formData?: any) => {
    const articleItems = formGroup.values.articles;

    const payload: SalarySlipRecord = {
      ...excludeKeys(selectedDetails!, ['_internal']),

      articles: articleItems.map((item, index) => {
        const originalArticleItem = selectedDetails?.articles[index];

        if (item.fromDate) {
          item.fromDate = parseDateTime(item.fromDate, originalArticleItem!.fromDate);
        }
        if (item.toDate) {
          item.toDate = parseDateTime(item.toDate, originalArticleItem!.toDate);
        }

        return excludeKeys(item, ['_internal']);
      }),
    };

    return payload;
  };

  const initialize = (details: SalarySlipRecord) => {
    //${moment(details.salaryPeriod.from).format('MMM')} (${details.documentName})
    const title = `Salary Slip`;

    setSelectedDetails({
      ...details,
      _internal: {
        title,
      },
    });
    replaceArticles(details.articles);
    setException(details);
  };

  const setException = (details: SalarySlipRecord) => {
    const errors = details!.dataValidationErrors || [];

    if (errors.length) {
      const isContainMoreThanOne = errors.length === 1;

      if (!isContainMoreThanOne) {
        const errorMsgs = errors.map((item) => item.message);

        setErrorType({
          type: SalaryErrorType.UNMATCHED_WITH_TOTAL,
          msg: errorMsgs.join(' and '),
        });
      } else {
        const error = errors[0];
        const errorType = error.code === 101 ? SalaryErrorType.UNMATCHED_TYPE : SalaryErrorType.TOTAL_MISMATCHED_TYPE;

        setErrorType({
          type: errorType,
          msg: error.message,
        });
      }
    }
  };

  const setFormField = (name: string, value: unknown) => {
    return formGroup.setFieldValue(name, value);
  };

  const formatDate = (dateStr?: string) => {
    if (!dateStr) return '';

    return moment(dateStr).format('YYYY-MM-DD');
  };

  const parseDateTime = (updatedDate: string, originalDate: string) => {
    const timePortion = moment(originalDate).format('HH:mm:ss.SSS');
    const combinedDatetime = moment(`${updatedDate}T${timePortion}`);

    return combinedDatetime.toISOString();
  };

  useEffect(() => {}, [formGroup]);

  const context: ISalarySlipContext = {
    selectedDetails,
    setSelectedDetails,
    salaryTypes,
    setSalaryTypes,
    errorType,
    formGroup,
    replaceArticles,
    initialize,
    setFormField,
    formatDate,
    normalizeRequestPayload,
  };

  return <SalarySlipContext.Provider value={context}>{children}</SalarySlipContext.Provider>;
};
