import { Dispatch, SetStateAction, forwardRef, useEffect, useState, ForwardedRef, useImperativeHandle } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Save } from 'react-feather';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { savePolicy, updatePolicy } from '../../../../api/service/superAdmin/pension-policies/service';
import { LooseObject } from '../../../superAdmin/PensionPolicy/types';
import useContext from '../../../superAdmin/PensionPolicy/PensionPolicyContext/useContext';
import { PensionPolicyDTO } from '../../../superAdmin/PensionPolicy/entity';

type Props = {
  personalPolicyName: string;
  policy: any | null;
  employee: any | null;
  defaultSelectedPolicyOrigin?: any;
  showModal: boolean;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
  closeModal: () => void;
  onSavePolicy: (args) => void;
  onUpdatePersonalPolicy: (updatedPolicyChanges: any) => void;
};

export const PersonalPolicyModalFooter = forwardRef(
  (
    {
      showModal,
      employee,
      personalPolicyName,
      defaultSelectedPolicyOrigin,
      closeModal,
      onSavePolicy,
      onUpdatePersonalPolicy,
      setErrors,
    }: Props,
    _ref: ForwardedRef<any>,
  ) => {
    const { formGroup, policyForm, reloadToDefault, setIsResetPolicy } = useContext();
    const [isBusy, setIsBusy] = useState(false);
    const { t } = useTranslation();

    const _onSavePolicy = async () => {
      try {
        /* formGroup.handleSubmit();
        await formGroup.validateForm().then((errors) => {
          setErrors(errors);
        });

        if (!formGroup.isValid) {
          return false;
        } */

        const payload = PensionPolicyDTO.toPersonalEmployee({
          formGroup: formGroup.values,
          policyForm: policyForm,
          defaultPolicyInput: {
            policyName: personalPolicyName,
            version: moment().format('YYYY-MM-DD'),
            employeeType: employee.personnelType,
          },
        });

        // setErrors({});
        setIsBusy(true);

        const _policy = defaultSelectedPolicyOrigin
          ? await updatePolicy(defaultSelectedPolicyOrigin._id, payload)
          : await savePolicy(payload);

        onClose();

        if (defaultSelectedPolicyOrigin) {
          onUpdatePersonalPolicy(_policy);
        } else {
          onSavePolicy(_policy);
        }
      } catch (error) {
        if (error instanceof Error) {
          setErrors({ error: error.message });
        }
      } finally {
        setIsBusy(false);
      }
    };

    const onClose = () => {
      reloadToDefault({ isEditAction: true });
      closeModal();
    };

    const restoreToDefault = () => {
      reloadToDefault({ isEditAction: true });
    };

    useEffect(() => {
      if (showModal) {
        setIsResetPolicy(false);
      } else {
        setIsResetPolicy(true);
      }
    }, [showModal]);

    useImperativeHandle(_ref, () => {
      return {
        restoreToDefault: restoreToDefault,
      };
    }, [restoreToDefault]);

    return (
      <>
        <Button onClick={() => onClose()} className="app-btn-secondary cancel-btn">
          {t('cancel')}
        </Button>
        <Button onClick={_onSavePolicy} className="app-btn-primary">
          {isBusy ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}{' '}
          {defaultSelectedPolicyOrigin ? `${t('update')}` : `${t('PENSION_POLICY.save_policy')}`}
        </Button>
      </>
    );
  },
);

PersonalPolicyModalFooter.displayName = 'PersonalPolicyModalFooter';
