import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { ConfirmationDialog } from '../../../../util/dialogs';
import useContext from './context/useContext';
import { deleteAccountingSalaryCompany } from '../../../../api/service/superAdmin/settings/accounting-salary-company';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

type Props = {
  id: string;
};

export const DeleteAction = ({ id }: Props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { accountingSalaryCompanies, replaceAccountingSalaryCompanies } = useContext();
  const { t } = useTranslation();

  const onDelete = async () => {
    const title = 'Are you sure you want to delete this accounting company?';

    const result = await ConfirmationDialog({
      title,
      confirmButtonText: t('yes'),
      denyButtonText: t('no'),
    });
    if (result.isConfirmed) {
      try {
        setIsBusy(true);
        await deleteAccountingSalaryCompany(id);
        const filteredAccountingCompanies = accountingSalaryCompanies.filter((company) => company.id !== id);
        replaceAccountingSalaryCompanies(filteredAccountingCompanies);
      } finally {
        setIsBusy(false);
      }
    }
  };
  return isBusy ? (
    <Spinner />
  ) : (
    <TooltipBox msg={`${t('delete')}`}>
      <Trash2 className="delete-icon" onClick={onDelete} />
    </TooltipBox>
  );
};
