import { useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';
import PagesHeader from '../../../../components/base/PagesHeader';
import { PensionPolicyFormGroup } from '../types';
// import { errorHighlighter } from '../../../../util/form';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import { PercentageNumber } from '../../../../components/form/inputs/PercentangeNumber';
import { DEFAULT_COMPENSATION_INCREASED_OFFSET, INITIAL_SALARY_CHNAGE } from '../constants';
import useContext from '../PensionPolicyContext/useContext';
import { useTranslation } from 'react-i18next';
import { errorHighlighter } from '../../../../util/form';
import { deepClone, numberFormatToSameDecimal } from '../../../../util/util';

type SalaryChangeProps = {
  onInputChange: (args) => void;
  formGroup: PensionPolicyFormGroup;
  setSalaryChangeHandler: (args) => void;
};

export default function SalaryChangeGrid({ formGroup, onInputChange, setSalaryChangeHandler }: SalaryChangeProps) {
  const { t } = useTranslation();

  const { updateSalaryChangeFieldInput } = useContext();
  const showSalaryChange = formGroup?.values.policySettings.salaryChange.isAllowed;

  const toggleSalaryChangeOptions = (e) => {
    formGroup.setFieldValue('policySettings.salaryChange.isAllowed', e.target.checked);
  };

  const handleCheckedValue = (e, inputkey: string) => {
    updateSalaryChangeFieldInput(`${inputkey}`, e.target.checked);
  };

  const handlePensionManagerPlan = (e) => {
    updateSalaryChangeFieldInput('isCheckedSepratePensionManagerPlans', e.target.checked);
  };

  const handlePercentageValue = (value, inputkey: string) => {
    updateSalaryChangeFieldInput(inputkey, value);
  };

  const canAllowEmployeeEdit = (pathKey) => {
    return formGroup?.values.policySettings?.salaryChange?.[pathKey].isChecked ? false : true;
  };

  const clearForm = () => {
    formGroup.setFieldValue('policySettings.salaryChange', deepClone(INITIAL_SALARY_CHNAGE));
  };

  useEffect(() => {
    if (!showSalaryChange) {
      setSalaryChangeHandler(deepClone(INITIAL_SALARY_CHNAGE));
      clearForm();
    }
  }, [showSalaryChange]);

  return (
    <>
      <div className="mt-4 mb-3">
        <PagesHeader pageTitle="Salary change" biggerHeading={true} />
        <ErrorFeedback behavior="alert" field={formGroup!.getFieldMeta('policySettings.salaryChange')} />
      </div>
      <div className="d-flex dark-content-section mt-3 mb-4 align-items-center">
        <Row className="align-items-start row">
          <Col className="d-flex">
            <Form>
              <Form.Group className=" pe-0 switch-btn-green">
                <Form.Label className="form-label text-label-note">{t('policy_allow_salary_change')}</Form.Label>
                <Form.Check
                  type="switch"
                  bsPrefix="chart-switch"
                  className={classNames('', 'fs-name mb-1 ')}
                  inline
                  label=""
                  id="isAllowed"
                  onChange={toggleSalaryChangeOptions}
                  checked={showSalaryChange}
                  name="isAllowed"
                />
              </Form.Group>
            </Form>
          </Col>
          <Col>
            <div className="ms-4">
              <p className="m-0 text-label-note">
                {
                  <>
                    {t('PENSION_POLICY.deduction_from_salary')}
                    <br />
                    {t('PENSION_POLICY.amount_paid_by_company')}
                  </>
                }
              </p>
            </div>
          </Col>
        </Row>
      </div>
      {showSalaryChange && (
        <div className="table-responsive react-bootstrap-table chart-table">
          <table className="table table-condensed salary-change-options-table">
            <thead className="header-wrapper">
              <tr className="correcto-table-header">
                <th className="month-header-col" style={{ width: '350px' }}>
                  {t('options')}
                </th>
                <th className="month-header-col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ verticalAlign: 'top' }}>
                  <Form.Group className="pe-0">
                    <Form.Check
                      name="isChecked"
                      inline
                      type="checkbox"
                      label={`1. ${t('fixed_monthly_offset')}`}
                      id="isChecked"
                      className={classNames(
                        'fs-name mb-1',
                        errorHighlighter(formGroup!.getFieldMeta(`policySettings.salaryChange`), 'form-check-error'),
                      )}
                      checked={formGroup?.values.policySettings.salaryChange.fixedMonthlyOffset.isChecked}
                      onChange={(e) => handleCheckedValue(e, 'fixedMonthlyOffset.isChecked')}
                    />
                  </Form.Group>
                </td>
                <td>
                  <table>
                    <tr>
                      <td>
                        <Form.Group className="pe-0">
                          <Form.Check
                            inline
                            type="checkbox"
                            label={t('employee_can_choose')}
                            id="isEditAllowed"
                            className="fs-name mb-1"
                            name="isEditAllowed"
                            checked={
                              formGroup?.values.policySettings.salaryChange.fixedMonthlyOffset.isEditAllowed
                            }
                            disabled={canAllowEmployeeEdit('fixedMonthlyOffset')}
                            onChange={(e) => handleCheckedValue(e, 'fixedMonthlyOffset.isEditAllowed')}
                          />
                        </Form.Group>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: 'top' }}>
                  <Form.Group className="pe-0">
                    <Form.Check
                      inline
                      type="checkbox"
                      label={`2. ${t('PENSION_POLICY.percentage_of_pensionable_salary')}`}
                      id="percentageOfSalaryOffset"
                      className={classNames(
                        'fs-name mb-1',
                        errorHighlighter(formGroup!.getFieldMeta(`policySettings.salaryChange`), 'form-check-error'),
                      )}
                      onChange={(e) => handleCheckedValue(e, 'percentageOfSalaryOffset.isChecked')}
                      name="isChecked"
                      checked={formGroup?.values.policySettings.salaryChange.percentageOfSalaryOffset.isChecked}
                    />
                  </Form.Group>
                </td>
                <td>
                  <table>
                    <tr>
                      <td>
                        <Form.Group className="pe-0">
                          <Form.Check
                            inline
                            type="checkbox"
                            label={t('employee_can_choose')}
                            id="isEditAllowed"
                            className="fs-name mb-1"
                            onChange={(e) => handleCheckedValue(e, 'percentageOfSalaryOffset.isEditAllowed')}
                            name="isEditAllowed"
                            checked={
                              formGroup?.values.policySettings.salaryChange.percentageOfSalaryOffset.isEditAllowed
                            }
                            disabled={canAllowEmployeeEdit('percentageOfSalaryOffset')}
                          />
                        </Form.Group>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: 'top' }}>
                  <Form.Group className="pe-0">
                    <Form.Check
                      inline
                      type="checkbox"
                      label={`3. ${t('manual_offset_allowed')}`}
                      id="manualoffset"
                      className={classNames(
                        'fs-name mb-1',
                        errorHighlighter(formGroup!.getFieldMeta(`policySettings.salaryChange`), 'form-check-error'),
                      )}
                      onChange={(e) => handleCheckedValue(e, 'manualOffset.isChecked')}
                      name="isChecked"
                      checked={formGroup?.values.policySettings.salaryChange.manualOffset.isChecked}
                    />
                    <p className="ms-4">{t('PENSION_POLICY.manual_offset_text')}</p>
                  </Form.Group>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <Form.Group>
                    <Form.Check
                      inline
                      type="checkbox"
                      label={t('employee_can_choose')}
                      id="isManualOffsetAllowed"
                      className="fs-name mb-1"
                      onChange={(e) => handleCheckedValue(e, 'manualOffset.isManualOffsetAllowed')}
                      name="isManualOffsetAllowed"
                      checked={formGroup?.values.policySettings.salaryChange.manualOffset.isManualOffsetAllowed}
                      disabled={canAllowEmployeeEdit('manualOffset')}
                    />
                  </Form.Group>
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Group className="pe-0">
                    <Form.Check
                      inline
                      type="checkbox"
                      label={`4. ${t('PENSION_POLICY.compensation_with_increased_offset')}`}
                      id="salaryoffset3"
                      className="fs-name mb-1"
                      onChange={(e) => handleCheckedValue(e, 'compensationWithIncreasedOffset.isChecked')}
                      name="isChecked"
                      checked={
                        formGroup?.values.policySettings.salaryChange.compensationWithIncreasedOffset.isChecked
                      }
                    />
                    <p className="ms-4">{t('PENSION_POLICY.employer_compensation_adjustment')} </p>
                  </Form.Group>
                </td>
                <td style={{ verticalAlign: 'top' }}>
                  <table>
                    <tr>
                      <td style={{ width: '200px', verticalAlign: 'top' }}>
                        <Form.Group className="me-3">
                          <Form.Label style={{ width: '350px' }} className="theme-text fs-name">
                            {t('offset_addition')}{' '}
                            <span className="font-weight-400 required required--sub">
                              {t('PENSION_POLICY.offset_addition_default', {
                                percent: numberFormatToSameDecimal(DEFAULT_COMPENSATION_INCREASED_OFFSET),
                              })}
                            </span>
                          </Form.Label>
                          <PercentageNumber
                            maxWidth={100}
                            value={
                              formGroup?.values.policySettings.salaryChange.compensationWithIncreasedOffset
                                .offsetAddition
                            }
                            name="offsetAddition"
                            onChangeHandler={(value) =>
                              handlePercentageValue(value, 'compensationWithIncreasedOffset.offsetAddition')
                            }
                            field={formGroup!.getFieldMeta(
                              'policySettings.salaryChange.compensationWithIncreasedOffset.offsetAddition',
                            )}
                            className="text-right"
                            decimalPlace={2}
                            disabled={
                              !formGroup?.values.policySettings.salaryChange.compensationWithIncreasedOffset.isChecked
                            }
                          />
                          <ErrorFeedback
                            field={formGroup.getFieldMeta(
                              'policySettings.salaryChange.compensationWithIncreasedOffset.offsetAddition',
                            )}
                          />
                        </Form.Group>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: 'top' }}>
                  <Form.Group className="pe-0">
                    <Form.Check
                      inline
                      type="checkbox"
                      label={`5. ${t('pension_manager_salary_change_plans')}`}
                      id="isCheckedSepratePensionManagerPlans"
                      className="fs-name mb-1"
                      onChange={(e) => handlePensionManagerPlan(e)}
                      name="isCheckedSepratePensionManagerPlans"
                      checked={formGroup?.values.policySettings.salaryChange.isCheckedSepratePensionManagerPlans}
                    />
                    <p className="ms-4">{t('PENSION_POLICY.separate_pension_manager_plan_text')}</p>
                  </Form.Group>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
