import { Spinner } from 'react-bootstrap';

type InputProps = {
  busy?: boolean;
};

export default function OverlayLoader({ busy }: InputProps) {
  return (
    <>
      {busy && (
        <div className="spinner-wrapper spinner-wrapper--overlay">
          <div className="spinner-card">
            <Spinner className="icon-gap-right" />
            <span className="loading-text">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
}
