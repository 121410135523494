import { SalaryErrorType } from '../types';

export class SalaryErrorLevel {
  static isLevelTwo(val: SalaryErrorType | null) {
    if (!val) return false;

    return [SalaryErrorType.TOTAL_MISMATCHED_TYPE, SalaryErrorType.UNMATCHED_WITH_TOTAL].indexOf(val) !== -1;
  }

  static isLevelOne(val: SalaryErrorType | null) {
    if (!val) return false;

    return [SalaryErrorType.UNMATCHED_TYPE].indexOf(val) !== -1;
  }
}
