import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { Col, Row } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import { CalculatedSalaryChangeChart } from './CalculatedSalaryChangeChart';
import { CalculatedSalaryChangeReport } from './CalculatedSalaryChangeReport';

export const CalculatedSalaryChange = () => {
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Reports',
      href: '/reports',
    },
    {
      name: 'Calculated & salary change',
    },
  ];

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader headingClass="fs-h1" pageTitle="Calculated & salary change" />
        </Col>
      </Row>
      <CardComponent>
        <CalculatedSalaryChangeChart />
        <CalculatedSalaryChangeReport />
      </CardComponent>
    </>
  );
};
