import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { UserX, UserPlus, RefreshCw, Send, CornerDownRight } from 'react-feather';
import { getRoaringPersonInfo } from '../../../../api/service/superAdmin/company-clients/service';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import PagesHeader from '../../../../components/base/PagesHeader';
import { FormProps, SeniorConsultants } from '../types';
import { splitFullName } from '../../../../util/util';
import classNames from 'classnames';
import { errorHighlighter, isPersonalNumberMatch, requiredError } from '../../../../util/form';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import PersonalNumber from '../../../../components/form/inputs/PersonalNumber';
import useLocale from '../../../../hooks/useLocale';
import { DEFAULT_PERSON_INFO } from '../../../../types/general';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

export default function SeniorConsultant({ context }: FormProps) {
  const { seniorConsultants, replaceSeniorConsultantsInput, formGroup, isEditMode, appendDeletedConsultant } = context;
  const { localize } = useLocale();
  const [, /*isPersonalNumberValid*/ setIsPersonalNumberValid] = useState<boolean>(true);

  const handleSeniorConsultants = (e, _id) => {
    const _seniorConsultants = [...seniorConsultants];
    const foundDetail = _seniorConsultants.find((i) => i['_id'] === _id);
    foundDetail![e.target.name] = e.target.value;
    replaceSeniorConsultantsInput(_seniorConsultants);
    setIsPersonalNumberValid(true);
  };

  const setPersonalInfo = (form, fullName) => {
    const { firstName, lastName } = splitFullName(fullName);

    const _seniorConsultants = [...seniorConsultants];
    const foundDetail = _seniorConsultants.find((i) => i['_id'] === form['_id']);

    foundDetail!.fullName = fullName;
    foundDetail!.firstName = firstName;
    foundDetail!.lastName = lastName;

    replaceSeniorConsultantsInput(_seniorConsultants);
    setIsPersonalNumberValid(true);
  };

  const loadDetails = async (form: SeniorConsultants) => {
    try {
      setPersonalInfo(form, DEFAULT_PERSON_INFO.fullName);
      const { fullName } = await getRoaringPersonInfo(form?.personalNumber);
      setPersonalInfo(form, fullName);
      form['valid'] = true;
    } catch (e) {
      form['valid'] = false;
      setIsPersonalNumberValid(false);
    }
  };

  return (
    <div>
      <PagesHeader pageTitle="Consultant" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {seniorConsultants.map((form, idx) => (
          <div key={form._id}>
            {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

            <div className="cr-form-row">
              {/* <Col style={{ maxWidth: 170 }}>
                <Form.Label
                  className={classNames(
                    'fs-name mb-0',
                    requiredError(formGroup.getFieldMeta(`seniorConsultants[${idx}].personalNumber`)),
                  )}
                >
                  {t('personal_number')}{' '}
                  <span className="font-weight-400">
                    ({PERSONAL_NUMBER_DIGITS} {t('digits')})
                  </span>
                </Form.Label>
                <Form.Control
                  value={form?.personalNumber}
                  size="lg"
                  type="number"
                  onChange={(e) => handleSeniorConsultants(e, form._id)}
                  name="personalNumber"
                  className={classNames(
                    'text-left',
                    errorHighlighter(formGroup.getFieldMeta(`seniorConsultants[${idx}].personalNumber`)),
                  )}
                />
                <ErrorFeedback field={formGroup.getFieldMeta(`seniorConsultants[${idx}].personalNumber`)} />
              </Col> */}
              <PersonalNumber
                onChangeHandler={(e) => handleSeniorConsultants(e, form._id)}
                value={form?.personalNumber}
                field={formGroup.getFieldMeta(`seniorConsultants[${idx}].personalNumber`)}
                isRequiredError={true}
                isValid={form['valid']}
                lazy={true}
              />
              <div>
                <Button
                  disabled={isPersonalNumberMatch(form?.personalNumber)}
                  variant="primary"
                  onClick={() => loadDetails(form)}
                  className="app-btn-secondary"
                >
                  {isEditMode ? (
                    <RefreshCw className="icon-gap-right" />
                  ) : (
                    <CornerDownRight className="icon-gap-right" />
                  )}{' '}
                  {isEditMode ? localize('refresh') : localize('fetch_name')}
                </Button>
              </div>
              <div className="first-name-field">
                <Form.Label
                  className={classNames(
                    'fs-name mb-0',
                    requiredError(formGroup.getFieldMeta(`seniorConsultants[${idx}].firstName`)),
                  )}
                >
                  {localize('first_name')}
                </Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="firstName"
                  value={form?.firstName}
                  disabled
                  className={classNames(
                    'text-left',
                    errorHighlighter(formGroup.getFieldMeta(`seniorConsultants[${idx}].firstName`)),
                  )}
                />
                <ErrorFeedback field={formGroup.getFieldMeta(`seniorConsultants[${idx}].firstName`)} />
              </div>
              <div className="last-name-field">
                <Form.Label
                  className={classNames(
                    'fs-name mb-0',
                    requiredError(formGroup.getFieldMeta(`seniorConsultants[${idx}].lastName`)),
                  )}
                >
                  {localize('last_name')}
                </Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="lastName"
                  value={form?.lastName}
                  disabled
                  className={classNames(
                    'text-left',
                    errorHighlighter(formGroup.getFieldMeta(`seniorConsultants[${idx}].lastName`)),
                  )}
                />
                <ErrorFeedback field={formGroup.getFieldMeta(`seniorConsultants[${idx}].lastName`)} />
              </div>
              <div className="email-field">
                <Form.Group>
                  <Form.Label
                    className={classNames(
                      'fs-name mb-0',
                      requiredError(formGroup.getFieldMeta(`seniorConsultants[${idx}].email`)),
                    )}
                  >{`${localize('email_address')}`}</Form.Label>
                  <Form.Control
                    value={form?.email}
                    size="lg"
                    type="text"
                    onChange={(e) => handleSeniorConsultants(e, form['_id'])}
                    name="email"
                    className={classNames(
                      'text-left',
                      errorHighlighter(formGroup.getFieldMeta(`seniorConsultants[${idx}].email`)),
                    )}
                  />
                </Form.Group>
                <ErrorFeedback field={formGroup.getFieldMeta(`seniorConsultants[${idx}].email`)} />
              </div>
              {isEditMode && (
                <div>
                  <Button className="app-btn-secondary">
                    <Send className="icon-gap-right" /> {localize('resend')} {localize('invite')}
                  </Button>
                </div>
              )}

              {idx >= 0 && (
                <div className="last-button-box">
                  <div className="button-align mx-3  mt-2">
                    <TooltipBox msg={`${localize('delete_user')}`}>
                      <UserX
                        onClick={() => {
                          const _adminDetails = [...seniorConsultants];
                          replaceSeniorConsultantsInput(_adminDetails.filter((i) => i['_id'] !== form['_id']));

                          appendDeletedConsultant(form.id || form._id);
                        }}
                        className="cursor-pointer delete-icon"
                      />
                    </TooltipBox>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

        <Button
          onClick={() =>
            replaceSeniorConsultantsInput([
              ...seniorConsultants,
              { _id: `${Math.random()}`, personalNumber: '', email: '', fullName: '', firstName: '', lastName: '' },
            ])
          }
          className="app-btn-secondary"
        >
          <UserPlus className="theme-text" />
        </Button>
      </div>
    </div>
  );
}
