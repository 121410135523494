import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Home, Moon, Sun, User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { getInitializedUserSession, switchUserSessionToInitial } from '../../api/service/auth/service';

import { ThemeContext } from '../../contexts/ThemeContext';
import useAuth from '../../hooks/useAuth';
import { getNameInitials } from '../../util/util';
import { useNavigate } from 'react-router-dom';

const NavbarUser = () => {
  const { t } = useTranslation();
  const { signOut, user } = useAuth();
  const userName = user ? user.fullName : 'Correcto User';
  const { setTheme, theme } = React.useContext(ThemeContext);
  const isDark = theme === 'dark';
  const initializedUserSession = getInitializedUserSession();
  const navigate = useNavigate();

  const handleChange = () => {
    setTheme(theme === 'dark' ? 'default' : 'dark');
  };

  const switchToInitializeSession = () => {
    const session = switchUserSessionToInitial();

    window.location.href = session.redirectTo;
  };

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <div
            style={{ backgroundColor: 'rgba(211, 129, 82, 1)', color: '#ffffff' }}
            className="avatar img-fluid rounded-circle me-2"
          >
            {getNameInitials(userName)}
          </div>
          <span className="theme-text">{userName}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        {user && user?.role?.company?.name && (
          <Dropdown.Item disabled className="text-white bg-dark">
            {user.role.company.name}
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={handleChange}>
          {isDark ? (
            <Sun size={18} className="align-middle me-2 icon-gap-right" />
          ) : (
            <Moon size={18} className="align-middle me-2 icon-gap-right" />
          )}
          {isDark ? t('switch_to_light') : t('switch_to_dark')}
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate('/profile')}>
          <User size={18} className="align-middle me-2 icon-gap-right" />
          {t('profile')}
        </Dropdown.Item>

        {initializedUserSession && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => switchToInitializeSession()}>
              <Home size={18} className="align-middle me-2 icon-gap-right" />
              {initializedUserSession?.role?.company?.name}
            </Dropdown.Item>
          </>
        )}

        <Dropdown.Divider />
        <Dropdown.Item onClick={() => signOut()}>{t('sign_out')}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
