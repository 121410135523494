import { lazyLoad } from './route-loader';
import Page404 from '../pages/base/Page404';

const LazyEmployeeComponent = lazyLoad(() => import('../pages/employee/Employee'));
const LazyEmployeeLayout = lazyLoad(() => import('../layouts/employee/EmployeeLayout'));

const EMPLOYEE_ROUTES = [
  {
    path: '',
    element: <LazyEmployeeComponent />,
  },
  {
    path: '*',
    element: <Page404 />,
  },
];

const EMPLOYEE_ROUTE_REGISTRY = [
  {
    path: '/employee',
    element: <LazyEmployeeLayout />,
    children: EMPLOYEE_ROUTES,
  },
];

export { EMPLOYEE_ROUTES, EMPLOYEE_ROUTE_REGISTRY };
