import useContext from '../../PensionPolicyContext/useContext';
import { PolicyLeaveCategory } from '../../types';
import { INITIAL_POLICY_SPECIAL_CONDITION } from '../../constants';

const useSpecialConditionsViewer = () => {
  const { selectedPolicyDetails } = useContext();
  const specialConditionSettings = selectedPolicyDetails.policySettings.specialConditions;

  const probationaryEmployement =
    specialConditionSettings?.probationaryEmployement || INITIAL_POLICY_SPECIAL_CONDITION.probationaryEmployement;
  const parentalLeave = specialConditionSettings?.parentalLeave || INITIAL_POLICY_SPECIAL_CONDITION.parentalLeave;
  const otherLeave = specialConditionSettings?.otherLeave || INITIAL_POLICY_SPECIAL_CONDITION.otherLeave;

  const isPolicyPartIncluded = (category: PolicyLeaveCategory, isDaysIncluded = false) => {
    if (!specialConditionSettings) {
      return false;
    }

    const policyLeaveCategory = specialConditionSettings[category];

    if (!policyLeaveCategory) {
      return false;
    }

    if (
      policyLeaveCategory.isInsurances ||
      policyLeaveCategory.isPremiumCalculation ||
      policyLeaveCategory.isSalaryChange ||
      (isDaysIncluded && policyLeaveCategory.maximumLength)
    ) {
      return true;
    }

    return false;
  };

  return {
    probationaryEmployement,
    parentalLeave,
    otherLeave,

    isPolicyPartIncluded,
  };
};

export default useSpecialConditionsViewer;
