import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { Col, Row } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import { BaseSalaryChart } from './BaseSalaryChart';
import { BaseSalaryReport } from './BaseSalaryReport';

export const BaseSalary = () => {
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Reports',
      href: '/reports',
    },
    {
      name: 'Base Salary',
    },
  ];

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader headingClass="fs-h1" pageTitle="Base Salary" />
        </Col>
      </Row>
      <CardComponent>
        <BaseSalaryChart />
        <BaseSalaryReport />
      </CardComponent>
    </>
  );
};
