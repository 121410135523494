import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import classNames from 'classnames';

import PagesHeader from '../../../../components/base/PagesHeader';

import { ReactComponent as CheckBox } from '../../../../assets/img/check-box.svg';
import { ReactComponent as OpenLink } from '../../../../assets/img/open-link.svg';
import { ReactComponent as CurrencyIcon } from '../../../../assets/img/currency-union.svg';

import usePolicyMode from '../hooks/usePolicyMode';
import useInsuranceForm from '../hooks/useInsuranceForm';

import { INSURANCE_CONDITION } from '../constants';
import useLocale from '../../../../hooks/useLocale';
import { IInsurance } from '../../AdminSettings/types';
import useContext from '../PensionPolicyContext/useContext';
import useCostSpecificationModal from '../components/CostSpecificationModal/useCostSpecificationModal';
import CostSpecificationModal from '../components/CostSpecificationModal/CostSpecificationModal';
import InsuranceEffectiveDate from '../components/InsuranceEffectiveDate/InsuranceEffectiveDate';
import useLocalizationResolver from '../../../../hooks/useLocalizationResolver';

interface InputProps {
  insuranceDataset?: IInsurance[];
  fetchedFromExternalSource?: boolean;
  selectedPolicy: any;
}

const DEFAULT_PROPS = { fetchedFromExternalSource: false, insuranceDataset: [] };

const InsuranceDetailsViewer = ({
  fetchedFromExternalSource,
  insuranceDataset,
}: Partial<InputProps> = DEFAULT_PROPS) => {
  const {
    assignedInsurances,
    addOrRemoveInsuranceCollection,
    findInsurancePlan,
    updateEmployeePensionSettings,
    hasEmployeeChosenPolicyCollectiveAgreement,
  } = useInsuranceForm({
    hasExternalSource: fetchedFromExternalSource,
    remoteDataSource: insuranceDataset,
  });
  const { externalPolicyRevision, preAssignedPolicy } = useContext();
  const { isCompanyEmployeePolicyMode, isCompanyEmployeeReadOnlyMode, isCompanyEmployeeEditableMode } = usePolicyMode();
  const { localize } = useLocale();
  const { getLang } = useLocalizationResolver();

  const {
    toggleModal: toggleCostSpecModal,
    showCostSpecModal,
    setCostPlanManager,
    selectedCostPlanManager,
  } = useCostSpecificationModal();

  const hasIncludedInsurancePlan = (insuranceId, planID) => {
    return findInsurancePlan(insuranceId, planID) ? true : false;
  };

  const handleCheck = (insuranceID, insurancePlanID, categoryName) => {
    addOrRemoveInsuranceCollection({
      insuranceID: insuranceID,
      planID: insurancePlanID,
      categoryName,
    });
  };

  const getInsuranceLabel = (key: string) => {
    const selected = INSURANCE_CONDITION.find((item) => item.key === key)?.lang;

    return selected ? localize(selected) : '';
  };

  const onShowPlanCostSpecification = (planType, insurancePlan) => {
    setCostPlanManager({ manager: planType, planItem: insurancePlan });
    toggleCostSpecModal();
  };

  useEffect(() => {
    if (!isCompanyEmployeeEditableMode()) {
      return;
    }

    if (externalPolicyRevision?.effectiveFrom) {
      updateEmployeePensionSettings('effectiveFrom', externalPolicyRevision?.effectiveFrom);
    }
  }, [externalPolicyRevision?.effectiveFrom]);

  useEffect(() => {
    if (!isCompanyEmployeeEditableMode()) {
      return;
    }

    if (preAssignedPolicy?.policySettings?.employeeMetaSettings?.insurance?.effectiveFrom) {
      updateEmployeePensionSettings(
        'effectiveFrom',
        preAssignedPolicy.policySettings?.employeeMetaSettings?.insurance?.effectiveFrom,
      );
    }
  }, [preAssignedPolicy]);

  if (assignedInsurances.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className="mt-4 mb-3 insurance-viewer-header">
        <PagesHeader headingClass="pp-sec-heading" pageTitle="Insurances" biggerHeading />
      </div>
      <div className="insurance-table-accordion">
        <Accordion defaultActiveKey="0">
          {assignedInsurances.map((insuranceItem, idx) => {
            return (
              <Accordion.Item key={`insurance-row-${idx}`} eventKey={`${idx}`} className="insurance-accordion-item">
                <Accordion.Header>
                  <Row className="accordion-table">
                    <Col md={10} className="px-0">
                      <span className="fs-h3 theme-text">{insuranceItem.metaDetails.companyName}</span>
                      <p className="m-0">{insuranceItem.metaDetails.providerDescription}</p>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body>
                  {insuranceItem.assignedPlanTypes.map((planType, planTypeIndex) => {
                    return (
                      <React.Fragment key={`insuranceSection-${planType.categoryName}-${idx}`}>
                        <div className="pt-3">
                          <span className="fs-text-400 text-bold theme-text">{getLang(planType.label)}</span>
                        </div>
                        <div className="table-responsive react-bootstrap-table insurance-plan-detail">
                          <table className="table table-hover table-condensed policy-insurance-plan-table">
                            <thead className="header-wrapper">
                              <tr>
                                <th style={{ width: '280px' }}>{localize('plan')}</th>
                                <th style={{ width: '120px' }}>{localize('age_span')}</th>
                                <th style={{ width: '200px' }}>{localize('special_condition')}</th>
                                <th style={{ width: '140px' }}></th>
                                <th style={{ width: '120px' }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {planType.plans.map((insurancePlan, planIndex) => {
                                return (
                                  <tr
                                    key={`insurancePlan-${planType.categoryName}-${idx}${planIndex}`}
                                    className={classNames(
                                      { 'highlight-editable-option': isCompanyEmployeePolicyMode() },
                                      {
                                        'highlight-editable-option--selected':
                                          isCompanyEmployeePolicyMode() &&
                                          hasIncludedInsurancePlan(
                                            insuranceItem.insuranceId,
                                            insurancePlan.planMetaDetails?.insuranceName,
                                          ),
                                      },
                                    )}
                                  >
                                    <td style={{ verticalAlign: 'top' }}>
                                      {!isCompanyEmployeePolicyMode() && (
                                        <>
                                          <CheckBox /> <span>{insurancePlan.planMetaDetails?.insuranceName}</span>
                                        </>
                                      )}
                                      {isCompanyEmployeeEditableMode() && (
                                        <Form.Group>
                                          <Form.Check
                                            inline
                                            type="checkbox"
                                            label={insurancePlan.planMetaDetails?.insuranceName}
                                            id={`activeinsurance-${idx}${planIndex}`}
                                            className="fs-name mb-1 theme-text"
                                            onChange={(e) =>
                                              handleCheck(
                                                insuranceItem.insuranceId,
                                                insurancePlan.planMetaDetails?.insuranceName,
                                                planType.categoryName,
                                              )
                                            }
                                            checked={hasIncludedInsurancePlan(
                                              insuranceItem.insuranceId,
                                              insurancePlan.planMetaDetails?.insuranceName,
                                            )}
                                            disabled={hasEmployeeChosenPolicyCollectiveAgreement()}
                                          />
                                        </Form.Group>
                                      )}
                                      {isCompanyEmployeeReadOnlyMode() && (
                                        <Form.Group>
                                          <Form.Check
                                            inline
                                            disabled
                                            type="checkbox"
                                            label={insurancePlan.planMetaDetails?.insuranceName}
                                            id={`activeinsurance-${idx}${planIndex}`}
                                            className="fs-name mb-1 theme-text"
                                            checked={hasIncludedInsurancePlan(
                                              insuranceItem.insuranceId,
                                              insurancePlan.planMetaDetails?.insuranceName,
                                            )}
                                          />
                                        </Form.Group>
                                      )}
                                      <p className="ml-20 ps-2 theme-text">
                                        {insurancePlan.planMetaDetails?.insuranceDescription}
                                      </p>
                                    </td>
                                    <td className="theme-text" style={{ verticalAlign: 'top' }}>
                                      {insurancePlan.planMetaDetails?.ageSpan.from}
                                      {insurancePlan.planMetaDetails?.ageSpan.from &&
                                      insurancePlan.planMetaDetails?.ageSpan.to
                                        ? '-'
                                        : ''}{' '}
                                      {insurancePlan.planMetaDetails?.ageSpan.to}
                                    </td>
                                    <td style={{ verticalAlign: 'top' }}>
                                      <Row>
                                        <Col className="theme-text">
                                          {getInsuranceLabel(insurancePlan.planMetaDetails?.specialCondition)}
                                        </Col>
                                      </Row>
                                    </td>
                                    <td style={{ verticalAlign: 'top' }}>
                                      {insurancePlan?.planMetaDetails?.costSpecification && (
                                        <span
                                          className="link-text cursor-pointer"
                                          onClick={() => onShowPlanCostSpecification(planType, insurancePlan)}
                                        >
                                          <CurrencyIcon className="me-1" />
                                          Cost specification
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <ul className="table-links">
                                        <li>
                                          {insurancePlan.planMetaDetails?.documents.map((doc, docIdx) => {
                                            return (
                                              <Link
                                                className="link-text"
                                                target="_blank"
                                                key={`doc-${idx}${docIdx}`}
                                                to={doc.documentLink}
                                              >
                                                <OpenLink /> {doc.text}
                                              </Link>
                                            );
                                          })}
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
      {isCompanyEmployeePolicyMode() && <InsuranceEffectiveDate />}
      <CostSpecificationModal
        show={showCostSpecModal}
        toggleModal={toggleCostSpecModal}
        costSpecDetails={selectedCostPlanManager?.planItem?.planMetaDetails?.costSpecification}
        planName={selectedCostPlanManager?.planItem?.planMetaDetails?.insuranceName}
        managerName={selectedCostPlanManager?.manager?.label}
      />
    </>
  );
};

export default React.memo(InsuranceDetailsViewer);
