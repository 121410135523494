import EditPensionCompany from './EditPensionCompany';
import PensionCompaniesList from './PensionCompaniesList';
import React, { useEffect } from 'react';
import { CreatePensionCompanyModal } from './CreatePensionCompany/CreatePensionCompanyModal';
import { getPensionCompanies } from '../../../../api/service/superAdmin/pension-policies/service';
import useContext from './context/useContext';
import { Mode } from '../types';
import { useSuperAdminSettingsContext } from '../useContext';

type Props = {
  showCreateModal: boolean;
  setIsPensionCompanyCreated: (args) => void;
  toggleModal: () => void;
  updatePensionCompany?: (input, value) => void;
  onDetailsPensionCompany?: (value) => void;
};

export default function AdminPensionComapnies({ setIsPensionCompanyCreated, showCreateModal, toggleModal }: Props) {
  const { replacePensionCompanies, formGroup } = useContext();
  const { mode, setMode } = useSuperAdminSettingsContext();

  const fetchPensionCompanies = async () => {
    const _pensionCompanies = await getPensionCompanies();
    replacePensionCompanies(_pensionCompanies);
  };

  useEffect(() => {
    if (mode === Mode.List) {
      fetchPensionCompanies();
      formGroup.resetForm();
    }
  }, [mode]);

  useEffect(() => {
    if (showCreateModal) {
      setMode(Mode.Add);
    } else {
      setMode(Mode.List);
    }
  }, [showCreateModal]);

  return (
    <>
      {mode === Mode.List && <PensionCompaniesList />}
      {mode === Mode.Add && showCreateModal && (
        <CreatePensionCompanyModal
          setIsPensionCompanyCreated={setIsPensionCompanyCreated}
          toggleModal={toggleModal}
          showCreateModal={showCreateModal}
        />
      )}

      {mode === Mode.Edit && <EditPensionCompany />}
      {mode === Mode.Detail && <EditPensionCompany />}
    </>
  );
}
