import { useEffect, useState } from 'react';
import { EditableProfileForm } from '../types';
import { INITIAL_PROFILE } from '../constants';
import { deepClone, isEqual } from '../../../../util/util';
import _ from 'lodash';
import useProfileForm from './useProfileForm';
import { useProfileContext } from '../context/useProfileContext';

export const useProfileObserver = () => {
  const [isFormModified, setIsFormModified] = useState(false);
  const { profileFormVal } = useProfileForm();
  const { profileDetails } = useProfileContext();

  const getDefaultValue = (data, value) => {
    const initialPersonalDetails = deepClone(INITIAL_PROFILE);
    const result = _.get(data, value);

    const initialPersonalDetailsValues = _.get(initialPersonalDetails, value);
    return result || initialPersonalDetailsValues;
  };

  const getInitialFormValue = () => {
    const initialFormValue: EditableProfileForm = {
      phoneNumber: getDefaultValue(profileDetails, 'phoneNumber'),
      email: getDefaultValue(profileDetails, 'email'),
    };

    return initialFormValue;
  };

  const getUpdatedFormValue = (): EditableProfileForm => {
    return {
      phoneNumber: profileFormVal.phoneNumber || '',
      email: profileFormVal.email || '',
    };
  };

  useEffect(() => {
    const initialFormValue = getInitialFormValue();
    const updatedFormValue = getUpdatedFormValue();

    if (isEqual(initialFormValue, updatedFormValue)) {
      setIsFormModified(false);
    } else {
      setIsFormModified(true);
    }
  }, [profileFormVal, profileDetails]);

  return {
    isFormModified,
  };
};
