export const VACATION_DAYS_SETUP_CONFIG = [
  { holidayPerYear: 0.0, holidayPayMultiple: 0.0 },
  { holidayPerYear: 1.0, holidayPayMultiple: 0.48 },
  { holidayPerYear: 2.0, holidayPayMultiple: 0.96 },
  { holidayPerYear: 3.0, holidayPayMultiple: 1.44 },
  { holidayPerYear: 4.0, holidayPayMultiple: 1.92 },
  { holidayPerYear: 5.0, holidayPayMultiple: 2.4 },
  { holidayPerYear: 6.0, holidayPayMultiple: 2.88 },
  { holidayPerYear: 7.0, holidayPayMultiple: 3.36 },
  { holidayPerYear: 8.0, holidayPayMultiple: 3.84 },
  { holidayPerYear: 9.0, holidayPayMultiple: 4.32 },
  { holidayPerYear: 10.0, holidayPayMultiple: 4.8 },
  { holidayPerYear: 11.0, holidayPayMultiple: 5.28 },
  { holidayPerYear: 12.0, holidayPayMultiple: 5.76 },
  { holidayPerYear: 13.0, holidayPayMultiple: 6.24 },
  { holidayPerYear: 14.0, holidayPayMultiple: 6.72 },
  { holidayPerYear: 15.0, holidayPayMultiple: 7.2 },
  { holidayPerYear: 16.0, holidayPayMultiple: 7.68 },
  { holidayPerYear: 17.0, holidayPayMultiple: 8.16 },
  { holidayPerYear: 18.0, holidayPayMultiple: 8.64 },
  { holidayPerYear: 19.0, holidayPayMultiple: 9.12 },
  { holidayPerYear: 20.0, holidayPayMultiple: 9.6 },
  { holidayPerYear: 21.0, holidayPayMultiple: 10.08 },
  { holidayPerYear: 22.0, holidayPayMultiple: 10.56 },
  { holidayPerYear: 23.0, holidayPayMultiple: 11.04 },
  { holidayPerYear: 24.0, holidayPayMultiple: 11.52 },
  { holidayPerYear: 25.0, holidayPayMultiple: 12.0 },
  { holidayPerYear: 26.0, holidayPayMultiple: 12.48 },
  { holidayPerYear: 27.0, holidayPayMultiple: 12.96 },
  { holidayPerYear: 28.0, holidayPayMultiple: 13.44 },
  { holidayPerYear: 29.0, holidayPayMultiple: 13.92 },
  { holidayPerYear: 30.0, holidayPayMultiple: 14.4 },
  { holidayPerYear: 31.0, holidayPayMultiple: 14.88 },
  { holidayPerYear: 32.0, holidayPayMultiple: 15.36 },
  { holidayPerYear: 33.0, holidayPayMultiple: 15.84 },
  { holidayPerYear: 34.0, holidayPayMultiple: 16.32 },
  { holidayPerYear: 35.0, holidayPayMultiple: 16.8 },
  { holidayPerYear: 36.0, holidayPayMultiple: 17.28 },
  { holidayPerYear: 37.0, holidayPayMultiple: 17.76 },
  { holidayPerYear: 38.0, holidayPayMultiple: 18.24 },
  { holidayPerYear: 39.0, holidayPayMultiple: 18.72 },
  { holidayPerYear: 40.0, holidayPayMultiple: 19.2 },
];
