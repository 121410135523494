import { useState, useEffect, useContext } from 'react';
import { omit, isEqual } from 'lodash';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Slash, Save, Trash2, Grid, Send } from 'react-feather';
import { useParams, useNavigate } from 'react-router-dom';
import {
  deleteClient,
  getClientDetailsByID,
  updateClient,
} from '../../../../api/service/accountingFirm/clients/service';
import { AccountingFirmClient } from '../../../../api/service/accountingFirm/clients/types';
import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { useEditClientContext } from '../context/useContext';
import General from './General';
import { DialogWithTextField } from '../../../../util/dialogs';
import { useTranslation } from 'react-i18next';
import { OverlayLoaderContext } from '../../../../contexts/OverlayLoaderContext';
import RoleAccess from '../../../../components/base/RoleAccess';
import { UserSubRole } from '../../../../types/auth';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';
import { ClientSaveMode } from '../types';
import { useAccountingFirmRoleSwitcher } from '../hooks/useAccountingFirmRoleSwitcher';
import AccessButton from './ActionButton';

let _companyDetail = {};
export default function ClientDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { errors, isInvalid, setErrors } = useValidationError();
  const [date, setDate] = useState({ createdAt: '', updatedAt: '' });
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const { id } = useParams();
  const context = useEditClientContext();
  const { setBusy } = useContext(OverlayLoaderContext);
  const [accountingFirmClientDetails, setClientDetails] = useState<any>(null);
  const { switchToCompanyAdmin } = useAccountingFirmRoleSwitcher();
  const {
    invoiceEmailAddress,
    invoiceCostCenter,
    companyDetails,
    adminDetails,
    addressDetails,
    salaryContactPeople,
    accountingContactPeople,
    contractEffectiveFrom,
    clientConsultants,
    vismaAPIKey,
    invitationEmail,
    contractNumber,
    formGroup,
    replaceCompanyDetails,
    replaceAddress,
    replaceAdminDetails,
    replaceConsultants,
    replaceAccountingContactPeople,
    replaceSalaryContactPeople,
    updateContractEffectiveInput,
    updateInvoiceEmailAddress,
    updateInvitationEmail,
    updateInvoicingCostCenter,
    replaceCollectiveAgreements,
    updateVismaAPIKey,
    updateContractNumber,
    deletedAdmins,
    deletedConsultants,
    combinations,
    replaceCombinations,
    replaceAccessLevel,
  } = context;

  const hasChanges =
    !isEqual(_companyDetail['name'], companyDetails.name) ||
    !isEqual(_companyDetail['organizationNumber'], companyDetails.organizationNumber) ||
    !isEqual(_companyDetail['status'], companyDetails.status) ||
    !isEqual(_companyDetail['address'], addressDetails) ||
    !isEqual(_companyDetail['admins'], adminDetails) ||
    !isEqual(_companyDetail['contactPeopleForSalary'], salaryContactPeople) ||
    !isEqual(_companyDetail['contactPeopleForAccounting'], accountingContactPeople) ||
    !isEqual(_companyDetail['contractEffectiveFrom'], contractEffectiveFrom) ||
    !isEqual(_companyDetail['invoiceEmailAddress'], invoiceEmailAddress) ||
    !isEqual(_companyDetail['invoicingCostCenter'], invoiceCostCenter) ||
    !isEqual(_companyDetail['vismaAPIKey'], vismaAPIKey) ||
    !isEqual(_companyDetail['invitationEmail'], invitationEmail) ||
    !isEqual(_companyDetail['consultants'], clientConsultants) ||
    !isEqual(_companyDetail['combinations'], combinations) ||
    !isEqual(_companyDetail['allowCorrectoAccess'], formGroup.values.allowCorrectoAccess) ||
    !isEqual(_companyDetail['allowAdvisorAccess'], formGroup.values.allowAdvisorAccess);
  const companyName = companyDetails ? companyDetails['name'] : '';
  const hasDraftMode = companyDetails?.status === ClientSaveMode.DRAFT;
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Clients',
      href: '/accounting-firms',
    },
    {
      name: companyName,
      translate: false,
    },
  ];

  const myClientsBreadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'My Clients',
      href: '/accounting-firms',
    },
    {
      name: companyName,
      translate: false,
    },
  ];

  const onCancel = () => {
    replaceCompanyDetails({
      name: _companyDetail['name'],
      organizationNumber: _companyDetail['organizationNumber'],
      status: _companyDetail['status'],
    });
    replaceAddress(_companyDetail['address']);
    updateContractEffectiveInput(_companyDetail['contractEffectiveFrom']);
    updateInvoiceEmailAddress(_companyDetail['invoiceEmailAddress']);
    updateInvoicingCostCenter(_companyDetail['invoicingCostCenter']);
    updateInvitationEmail(_companyDetail['invitationEmail']);
    replaceAdminDetails(_companyDetail['admins']);
    replaceCombinations(_companyDetail['combinations']);
    replaceAccountingContactPeople(_companyDetail['contactPeopleForAccounting']);
    replaceSalaryContactPeople(_companyDetail['contactPeopleForSalary']);
    setDate({ createdAt: _companyDetail['createdAt'], updatedAt: _companyDetail['updatedAt'] });
    replaceConsultants([..._companyDetail['consultants']]);
    updateVismaAPIKey(_companyDetail['vismaAPIKey']);
    replaceAccessLevel(_companyDetail['allowCorrectoAccess'], _companyDetail['allowAdvisorAccess']);
  };

  const onUpdateClient = async (saveMode: ClientSaveMode | null = null) => {
    try {
      const modifiedAdminDetails = adminDetails.map((i) => omit(i, ['id', 'valid']));
      const data = {
        ...companyDetails,
        address: addressDetails,
        admins: modifiedAdminDetails,
        contactPeopleForSalary: salaryContactPeople,
        contactPeopleForAccounting: accountingContactPeople,
        contractEffectiveFrom,
        invoiceEmailAddress: invoiceEmailAddress,
        invoicingCostCenter: invoiceCostCenter,
        invitationEmail: invitationEmail,
        consultants: clientConsultants,
        contractNumber: contractNumber,
        combinations: combinations,
        deletedAdmins,
        deletedConsultants,
        allowAdvisorAccess: formGroup.values.allowAdvisorAccess,
        allowCorrectoAccess: formGroup.values.allowCorrectoAccess,
      } as unknown as AccountingFirmClient;

      const payload = {
        ...data,
        vismaAPIKey: vismaAPIKey,
      };
      if (hasDraftMode && saveMode === ClientSaveMode.DRAFT) {
        payload.status = ClientSaveMode.DRAFT;
      } else if (
        hasDraftMode &&
        [ClientSaveMode.ACTIVE, ClientSaveMode.INACTIVE].indexOf(payload.status as any) === -1
      ) {
        payload.status = ClientSaveMode.SETUP;
      }
      formGroup.handleSubmit();
      formGroup.validateForm().then((errors) => {
        setErrors(errors);
      });

      if (!formGroup.isValid) {
        return false;
      }
      if (saveMode === ClientSaveMode.SETUP) {
        setisUpdating(true);
      }
      if (saveMode === ClientSaveMode.DRAFT) {
        setIsBusyDrafting(true);
      }

      await updateClient(id, payload);
      await fetchClientDetails();
    } finally {
      if (saveMode === ClientSaveMode.SETUP) {
        setisUpdating(false);
      }
      if (saveMode === ClientSaveMode.DRAFT) {
        setIsBusyDrafting(false);
      }
    }
  };

  const fetchClientDetails = async () => {
    try {
      setIsBusy(true);
      const clientDetails = await getClientDetailsByID(id);
      const {
        name,
        organizationNumber,
        contractEffectiveFrom,
        address,
        admins,
        combinations,
        contactPeopleForSalary,
        contactPeopleForAccounting,
        createdAt,
        updatedAt,
        collectiveAgreements,
        status,
        invoiceEmailAddress,
        invoicingCostCenter,
        consultants,
        invitationEmail,
        contractNumber,
        allowAdvisorAccess,
        allowCorrectoAccess,
      } = clientDetails;
      const clientResponse = {
        ...clientDetails,
        invitationEmail: invitationEmail || '',
        vismaAPIKey: vismaAPIKey || '',
      };
      setClientDetails(clientDetails);
      _companyDetail = structuredClone(clientResponse);
      replaceCompanyDetails({ name, organizationNumber, status });
      replaceAddress(address);
      updateContractEffectiveInput(contractEffectiveFrom);
      updateInvoiceEmailAddress(invoiceEmailAddress);
      updateInvoicingCostCenter(invoicingCostCenter);
      replaceAdminDetails(admins);
      updateContractNumber(contractNumber);
      replaceCombinations(combinations);
      replaceAccountingContactPeople(contactPeopleForAccounting);
      replaceSalaryContactPeople(contactPeopleForSalary);
      replaceConsultants(consultants);
      replaceCollectiveAgreements(collectiveAgreements);
      updateVismaAPIKey(clientResponse.vismaAPIKey);
      updateInvitationEmail(clientResponse.invitationEmail);
      setDate({ createdAt, updatedAt });
      replaceAccessLevel(allowCorrectoAccess, allowAdvisorAccess);
    } finally {
      setIsBusy(false);
    }
  };

  const onDelete = async () => {
    const title = `${t('i_want_to_detete')} ${companyName} ${t('forever')}`;
    const textPara = `${t('to_delete_the')} ${t('client')}, ${t('type_in')}`;
    const result = await DialogWithTextField({
      title: t('are_you_sure'),
      confirmButtonText: t('delete_client'),
      denyButtonText: t('cancel'),
      validationTitle: title,
      validationMessage: t('validation_message'),
      content: {
        caption: textPara,
        body: title,
      },
      confirmBtnClass: 'confirm-delete-btn',
    });

    if (result.isConfirmed) {
      try {
        setisDeleting(true);
        await deleteClient(id ?? '');
        navigate('/accounting-firms');
      } finally {
        setisDeleting(false);
      }
    }
  };

  const switchCompanyLogin = async () => {
    switchToCompanyAdmin({ setBusy, details: accountingFirmClientDetails });
  };

  useEffect(() => {
    fetchClientDetails();
  }, []);

  return (
    <div className="accounting-firm">
      <RoleAccess allowedRoles={[UserSubRole.SUPERVISOR]} roleType="secondary">
        <Breadcrumb navItem={breadcrumb} />
      </RoleAccess>
      <RoleAccess allowedRoles={[UserSubRole.SENIOR_CONSULTANT]} roleType="secondary">
        <Breadcrumb navItem={myClientsBreadcrumb} />
      </RoleAccess>
      <div className="d-flex justify-content-between">
        <PagesHeader
          headingClass="fs-h1"
          pageTitle={
            companyDetails ? (hasDraftMode ? `${companyDetails['name']} (draft)` : companyDetails['name']) : ''
          }
          translate={false}
        />
        <div>
          <RoleAccess allowedRoles={[UserSubRole.SENIOR_CONSULTANT]} roleType="secondary">
            <Button className="app-btn-primary me-2" onClick={() => switchCompanyLogin()}>
              <Grid className="icon-gap-right" />
              {`${t('overview')}`}
            </Button>
          </RoleAccess>

          {hasChanges && (
            <Button onClick={onCancel} className="app-btn-primary me-2 cancel-btn">
              <Slash className="icon-gap-right" /> {`${t('cancel_changes')}`}
            </Button>
          )}
          {hasDraftMode && (
            <Button
              disabled={isBusyDrafting || isDeleting || isUpdating || !hasChanges}
              onClick={() => onUpdateClient(ClientSaveMode.DRAFT)}
              className="app-btn-primary me-2"
            >
              {isBusyDrafting ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}{' '}
              {`${t('save')} ${t('as')} ${t('draft')}`}
            </Button>
          )}
          <Button
            disabled={isBusyDrafting || isDeleting || isUpdating || !hasChanges}
            onClick={() => onUpdateClient(ClientSaveMode.SETUP)}
            className="app-btn-primary me-2"
          >
            {isUpdating ? (
              <Spinner size="sm" />
            ) : hasDraftMode ? (
              <Send className="icon-gap-right" />
            ) : (
              <Save className="icon-gap-right" />
            )}{' '}
            {hasDraftMode
              ? `${t('save')} ${t('clients')} ${t('and_send_all_invites')}`
              : `${t('save')} ${t('changes')}`}
          </Button>

          <AccessButton firmDetails={accountingFirmClientDetails} />
          {false && (
            <RoleAccess allowedRoles={[UserSubRole.SUPERVISOR]} roleType="secondary">
              <Button
                disabled={isBusyDrafting || isDeleting || isUpdating}
                onClick={onDelete}
                className="app-btn-danger"
              >
                {isDeleting ? <Spinner size="sm" /> : <Trash2 className="icon-gap-right" />}
                {`${t('delete')} ${t('client')}`}
              </Button>
            </RoleAccess>
          )}
        </div>
      </div>
      <ValidationAlert show={isInvalid} errors={errors} />
      <Tabs defaultActiveKey="general">
        <Tab eventKey="general" title={`${t('general')}`}>
          <General isBusy={isBusy} date={date} switchCompanyLogin={switchCompanyLogin} />
        </Tab>
        {/* <Tab eventKey="billing" title="Billing"></Tab>
        <Tab eventKey="pension" title="Pension data"></Tab>
        <Tab eventKey="mislife" title="MisLife data"></Tab>
        <Tab eventKey="contract" title="Contract"></Tab> */}
      </Tabs>
    </div>
  );
}
