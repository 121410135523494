import * as yup from 'yup';

export const formSchema = yup.object().shape({
    integrationInformation: yup.object().shape({
        shortName: yup.string().required('Required'),
        companyName: yup.string(),
        status: yup.string(),
        instruction: yup.string(),

    }),
    typeOfIntegration: yup.object().shape({
        companyApi: yup.boolean().default(false),
        channel: yup.string(),
        apiKey: yup.string(),
        apiKey2: yup.string(),
    })
});
