import { Spinner } from 'react-bootstrap';
import { AlertTriangle, Check } from 'react-feather';

export const STATUS = {
  PROCESSED: {
    key: 'successfully_processed',
    title: 'Successfully processed',
    icon: <Check color="#0D830B" className="icon-gap-right" />,
  },
  PROCESSING: {
    key: 'processing',
    title: 'Processing',
    icon: (
      <Spinner
        className="icon-gap-right"
        style={{ height: 24, width: 24, verticalAlign: 'middle', color: '#4E89C5' }}
      />
    ),
  },
  PROCESSING_ERROR: {
    key: 'processing_error',
    title: 'Processing error',
    icon: <AlertTriangle color="#930000" className="icon-gap-right" />,
  },
  UPLOADED: {
    key: 'processing_success',
    title: 'Processing successful',
    icon: <Check color="#0D830B" className="icon-gap-right" />,
  },
};
