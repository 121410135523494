import { useEffect, useState } from 'react';

import {
  ComparisonSeriesReportType,
  FileExportType,
  EmployeeBenefitReportType,
  EmployeeBenefitSummary,
  ReportResponseField,
  ReportNameMap,
} from '../../../../types/report';
import useAuth from '../../../../hooks/useAuth';
import { Calendar } from '../../../../util/calendar';
import { exportEmployeeBenfitReport, getEmployeeBenefitReport } from '../../../../api/service/company/reports/service';
import { ReportFilterParams } from './types';
import useColumn from './useColumn';

const useEmployeeBenefitReport = (
  reportType: EmployeeBenefitReportType,
  filter: ReportFilterParams,
  reportFieldKey: ReportResponseField,
) => {
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  // report table layout
  const [reportRecords, setReportRecords] = useState<any[]>([]);
  const selectedMonth = filter.months;
  const reportHeaders = useColumn(reportType);

  const fetchAllEmployeeYearlyReport = async () => {
    try {
      setIsLoading(true);

      const res = await getEmployeeBenefitReport(reportType, {
        employee_benefit_report_name: reportType,
        duration: {
          from: selectedMonth[0],
          to: selectedMonth[1],
        },
        companyClientId: user?.role.company.id,
        type: ComparisonSeriesReportType.TABLE,
        year: Calendar.parseDate(selectedMonth[0], 'yyyy'),
      });

      const reportDetails = (res[reportFieldKey] || []) as EmployeeBenefitSummary[];

      //   const reportDetails = EMP_PENSION_OFFSET[reportFieldKey] as EmployeeBenefitSummary[];

      setReportRecords(reportDetails);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const exportCSV = async (fileType: FileExportType = FileExportType.XLSX) => {
    try {
      setIsExporting(true);

      // if (fileType === FileExportType.XLSX || fileType === FileExportType.CSV) {
      //   return;
      // }

      const reportDetails = await exportEmployeeBenfitReport(
        {
          duration: {
            from: Calendar.getSelectedYearBoundary(Number(selectedMonth))[0],
            to: Calendar.getSelectedYearBoundary(Number(selectedMonth))[1],
          },
          companyClientId: user?.role.company.id,
          type: ComparisonSeriesReportType.TABLE,
          year: Calendar.parseDate(selectedMonth[0], 'yyyy'),
        },

        reportType,
      );
      const url = window.URL.createObjectURL(new Blob([reportDetails]));
      const filePrefix = ReportNameMap['employee-benefit'];
      const fileSuffix = Calendar.instance().format('DDMMYYYYHHmmss');
      const fileName = `${filePrefix}-${fileSuffix}.${fileType}`;

      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    fetchAllEmployeeYearlyReport();
  }, [selectedMonth]);

  return {
    reportHeaders,
    reportRecords,
    isLoading,
    exportCSV,
    isExporting,
  };
};

export default useEmployeeBenefitReport;
