import { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { UserX, UserPlus, RefreshCw, Send, CornerDownRight } from 'react-feather';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import PagesHeader from '../../../../components/base/PagesHeader';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { errorHighlighter, isPersonalNumberMatch, requiredError } from '../../../../util/form';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import PersonalNumber from '../../../../components/form/inputs/PersonalNumber';
import { GeneralSettingsRef } from '../types';
import { useGeneralSettingsContext } from '../AdminSettingsContext/useContext';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

interface InputProps {
  emitValues: (...args) => void;
  generalSettingsEditor: GeneralSettingsRef;
}

export default function CorrectoAdministrator({ emitValues, generalSettingsEditor }: InputProps) {
  const { adminDetails, formGroup } = useGeneralSettingsContext();
  const { t } = useTranslation();

  useEffect(() => {
    emitValues({
      isLoading: generalSettingsEditor.isLoading,
      saveChanges: generalSettingsEditor.saveChanges,
      cancelChanges: generalSettingsEditor.cancelChanges,
      hasChanges: generalSettingsEditor.hasChanges,
      handleCorrectoAdmin: generalSettingsEditor.handleCorrectoAdmin,
      handleInsuranceAdvisor: generalSettingsEditor.handleInsuranceAdvisor,
      deleteCorrectoAdmin: generalSettingsEditor.deleteCorrectoAdmin,
      deleteInsuranceAdvisor: generalSettingsEditor.deleteInsuranceAdvisor,
      addCorrectoAdmin: generalSettingsEditor.addCorrectoAdmin,
      addInsuranceAdvisor: generalSettingsEditor.addInsuranceAdvisor,
      loadAdminDetails: generalSettingsEditor.loadAdminDetails,
      loadAdvisorDetails: generalSettingsEditor.loadAdvisorDetails,
    } as GeneralSettingsRef);
  }, [
    generalSettingsEditor.saveChanges,
    generalSettingsEditor.isLoading,
    generalSettingsEditor.cancelChanges,
    generalSettingsEditor.hasChanges,
    generalSettingsEditor.handleCorrectoAdmin,
    generalSettingsEditor.handleInsuranceAdvisor,
    generalSettingsEditor.deleteCorrectoAdmin,
    generalSettingsEditor.deleteInsuranceAdvisor,
    generalSettingsEditor.addCorrectoAdmin,
    generalSettingsEditor.addInsuranceAdvisor,
    generalSettingsEditor.loadAdminDetails,
    generalSettingsEditor.loadAdvisorDetails,
  ]);

  return (
    <>
      <PagesHeader pageTitle="Correcto administrators" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {adminDetails?.map((form, idx) => (
          <div key={form.id}>
            {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

            <div className="cr-form-row">
              <PersonalNumber
                onChangeHandler={(e) => generalSettingsEditor?.handleCorrectoAdmin(e, form.id)}
                value={form?.personalNumber}
                disabled={!form.isActive}
                field={formGroup.getFieldMeta(`admins[${idx}].personalNumber`)}
                isValid={form['valid']}
                lazy={true}
              />
              <div>
                <Button
                  variant="primary"
                  onClick={() => generalSettingsEditor?.loadAdminDetails(form)}
                  className="app-btn-secondary"
                  disabled={isPersonalNumberMatch(form?.personalNumber)}
                >
                  {!form.isActive ? (
                    <RefreshCw className="icon-gap-right" />
                  ) : (
                    <CornerDownRight className="icon-gap-right" />
                  )}
                  {!form.isActive ? t('refresh') : t('fetch_name')}
                </Button>
              </div>
              <div className="first-name-field">
                <Form.Label className={classNames('fs-name mb-0')}>{t('first_name')}</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="firstName"
                  value={form?.firstName}
                  disabled
                  className={classNames('text-left')}
                />
              </div>
              <div className="last-name-field">
                <Form.Label className={classNames('fs-name mb-0')}>{t('last_name')}</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="lastName"
                  value={form?.lastName}
                  disabled
                  className={classNames('text-left')}
                />
              </div>
              <div className="email-field">
                <Form.Group>
                  <Form.Label
                    className={classNames(
                      'fs-name mb-0',
                      requiredError(formGroup.getFieldMeta(`admins[${idx}].email`)),
                    )}
                  >{`${t('email')} ${t('address')}`}</Form.Label>
                  <Form.Control
                    value={form?.email}
                    size="lg"
                    type="text"
                    onChange={(e) => generalSettingsEditor?.handleCorrectoAdmin(e, form['id'])}
                    name="email"
                    className={classNames(
                      'text-left',
                      errorHighlighter(formGroup.getFieldMeta(`admins[${idx}].email`)),
                    )}
                  />
                </Form.Group>
                <ErrorFeedback field={formGroup.getFieldMeta(`admins[${idx}].email`)} />
              </div>
              <div>
                <Button className="app-btn-secondary">
                  <Send className="icon-gap-right" />{' '}
                  {!form.isActive ? `${t('resend')} ${t('invite')}` : `${t('send')} ${t('invite')}`}
                </Button>
              </div>

              {idx >= 0 && (
                <div className="last-button-box">
                  <div className="button-align mx-3 mt-2">
                    <TooltipBox msg={`${t('delete_user')}`}>
                      <UserX
                        onClick={() => generalSettingsEditor?.deleteCorrectoAdmin(form)}
                        className="cursor-pointer delete-icon"
                      />
                    </TooltipBox>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

        <Button onClick={generalSettingsEditor?.addCorrectoAdmin} className="app-btn-secondary mt-2">
          <UserPlus className="theme-text" />
        </Button>
      </div>
    </>
  );
}
