import React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';
import HighChartAccessibility from 'highcharts/modules/accessibility';
import HighChartStock from 'highcharts/modules/stock';
import HighChartData from 'highcharts/modules/data';
import HighChartMore from 'highcharts/highcharts-more';
import { Menu } from 'react-feather';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import { ThemeContext } from '../../../contexts/ThemeContext';
import { ISeries, ISeriesOptions } from '../multi-series/types';
import { Calendar } from '../../../util/calendar';
import { PieChartAdapter } from './piechart-adapter';
import { PieChartTable } from './PiechartTable';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

HighchartsExporting(Highcharts);
HighchartsExportingData(Highcharts);
HighChartAccessibility(Highcharts);
HighChartStock(Highcharts);
HighChartData(Highcharts);
HighChartMore(Highcharts);

interface ChartProps {
  data: any;
  seriesOptions: ISeriesOptions;
  initialDateRange?: string[];
  isLoading?: boolean;
  chartTitle?: string;
  containerStyle?: any;
}
const exportOptions = [
  { name: 'Print Chart', type: 'print' },
  { name: 'Download PNG image', type: 'image/png' },
  { name: 'Download JPEG image', type: 'image/jpeg' },
  { name: 'Download PDF document', type: 'application/pdf' },
  { name: 'Download SVG image', type: 'image/svg+xml' },
  { name: 'Download CSV', type: 'csv' },
  { name: 'Download XLS', type: 'xls' },
  { name: 'View Data Table', type: 'table' },
];
export const PieChart = React.forwardRef((props: ChartProps, _ref: any) => {
  const ref: any = useRef(null);
  const { t } = useTranslation();
  const { theme } = React.useContext(ThemeContext);
  const [seriesCollection, setSeriesCollection] = useState<ISeries[]>([]);
  const [isTable, setIsTable] = useState<boolean>(false);
  const [formattedTableData, setFormattedTableData] = useState<any[]>([]);
  const pieChartAdapter = React.useMemo(() => {
    return PieChartAdapter.load(props.seriesOptions, props.data);
  }, [props.seriesOptions, props.data]);

  const [updatedChart, setUpdatedChart] = useState<any>(
    theme === 'dark' ? pieChartAdapter.darktModeConfig : pieChartAdapter.lightModeConfig,
  );

  const exportChart = (option) => {
    const chart = (ref!.current! as any).chart;

    if (option.type === 'print') {
      chart.print();
    } else if (option.type === 'csv') {
      chart.downloadCSV();
    } else if (option.type === 'xls') {
      chart.downloadXLS();
    } else if (option.type === 'table') {
      setIsTable(!isTable);
      !isTable ? (option.name = 'Hide Data Table') : (option.name = 'View Data Table');
    } else {
      chart.exportChart({
        type: option.type,
        filename: `${'my' + option.type}`,
      });
    }
  };

  const formatTableData = () => {
    setFormattedTableData(pieChartAdapter.formattedTableData);
  };

  useEffect(() => {
    const chartRef = (ref!.current! as any).chart;
    props.isLoading
      ? chartRef.setTitle({ text: null }, { text: null })
      : chartRef.setTitle(
          { text: t(`MONTH_NAME.${Calendar.getCurrentMonth}`) || '' },
          { text: props?.data?.totalEmployees ? `Company size<br>(${props?.data?.totalEmployees})` : '' },
        );

    chartRef.redraw();
    formatTableData();
  }, [pieChartAdapter.data]);

  useEffect(() => {
    const chartRef = (ref!.current! as any).chart;
    pieChartAdapter.series.forEach((seriesItem) => {
      if (!seriesItem.checked) {
        chartRef.series[seriesItem!.seriesIndex!].setVisible(false);
      }
    });
  }, []);

  useEffect(() => {
    const chartSeries = pieChartAdapter.series.map((element, index) => ({
      ...element,
      color:
        theme === 'default'
          ? pieChartAdapter.series[index].style!.defaultColor
          : pieChartAdapter.series[index].style!.darkColor,
    }));

    theme === 'dark'
      ? setUpdatedChart(pieChartAdapter.darktModeConfig)
      : setUpdatedChart(pieChartAdapter.lightModeConfig);
    setSeriesCollection(chartSeries);
  }, [theme, pieChartAdapter.series]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-none d-sm-block col-auto">
          <h3 className="fs-h3 theme-text">{props.chartTitle}</h3>
        </div>
        <Dropdown className="nav-item" align="end">
          <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
            <Button className="app-btn-secondary">
              <Menu className="theme-text" />
            </Button>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {exportOptions.map((option, index) => (
              <Dropdown.Item key={index} onClick={() => exportChart(option)}>
                {option.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className={classNames('chart-parent mt-3', 'crt_pie_chart')} style={props.containerStyle}>
        {props.isLoading && (
          <div className="spinner-wrapper spinner-wrapper--overlay">
            <div className="spinner-card">
              <Spinner className="icon-gap-right" />
              <span className="loading-text">Loading...</span>
            </div>
          </div>
        )}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            ...updatedChart,
            series: pieChartAdapter.data,
          }}
          updateArgs={[true]}
          oneToOne={true}
          ref={ref}
        />
        {isTable && <PieChartTable tableData={formattedTableData} selectedSeries={seriesCollection} />}
      </div>
    </>
  );
});
PieChart.displayName = 'PieChart';
