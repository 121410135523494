import { useEffect, useRef, useState } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { Search } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { getPersonalClients } from '../../../api/service/superAdmin/personal-clients/service';
import { GetPersonalClientType } from '../../../api/service/superAdmin/personal-clients/types';
import CardComponent from '../../../components/base/CardComponent';
import BSRemoteTable from '../../../components/table/BSRemoteTable';
import Pagination from '../../company/Pagination';
import { useColumn } from './useColumn';
import useLocale from '../../../hooks/useLocale';

type Props = {
  isClientCreated: boolean;
};

export default function PersonalClientGrid({ isClientCreated }: Props) {
  const navigate = useNavigate();
  const { localize } = useLocale();
  const [isLoading, setIsLoading] = useState(true);
  const [companyData, setCompanyData] = useState<GetPersonalClientType | null>(null);
  const { personalClientColumn } = useColumn();
  const searchRef = useRef<any>();
  const totalPages = companyData?.metaData
    ? Math.ceil(companyData?.metaData.total / companyData?.metaData?.page.size)
    : 0;

  const onPagination = async ({ page, sizePerPage }) => {
    await fetchPersonalClients({ page, sizePerPage });
  };

  const onSearch = async () => {
    const searchTerm = searchRef.current['value'];
    fetchPersonalClients({ searchString: searchTerm });
  };

  const fetchPersonalClients = async ({
    searchString = '',
    page = companyData?.metaData.page.current,
    sizePerPage = companyData?.metaData.page.size,
    sortField = 'name',
    sortOrder = '1',
  }) => {
    const clients = await getPersonalClients({ searchString, page, sizePerPage, sortField, sortOrder });
    setCompanyData(clients);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isClientCreated) {
      fetchPersonalClients({});
    }
  }, [isClientCreated]);

  useEffect(() => {
    fetchPersonalClients({});
  }, []);

  return (
    <>
      <CardComponent>
        <Row className="justify-content-between">
          <div className="cr-form-row w-auto">
            <div className="search-field">
              <div className="input-group">
                <input
                  placeholder={`${localize('search')}...`}
                  className="form-control"
                  style={{ borderRight: 'none' }}
                  ref={searchRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearch();
                    }
                  }}
                />
                <span className="input-group-append bg-white border-left-0">
                  <span className="input-group-text">
                    <Search className="cursor-pointer" onClick={onSearch} width={16} height={30} />
                  </span>
                </span>
              </div>
            </div>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <BSRemoteTable
              data={companyData?.data ?? []}
              columns={personalClientColumn}
              noDataIndication={`${localize('no_clients_found')}`}
              sizePerPage={companyData?.metaData['size']}
              onSizePerPageChange={(sizePerPage, page) => onPagination({ sizePerPage, page })}
              keyField="id"
              onRowClick={(row) => {
                navigate(row.id);
              }}
              onTableChange={(type, { sortOrder, sortField }) => {
                if (type === 'sort') {
                  fetchPersonalClients({ sortField, sortOrder: sortOrder === 'asc' ? '1' : '-1' });
                }
              }}
            />
          )}
        </Row>
        {totalPages > 1 && (
          <Pagination
            total={companyData?.metaData.total ?? 0}
            itemsPerPage={companyData?.metaData.page.size ?? 0}
            currentPage={companyData?.metaData.page.current ?? 0}
            onPageChange={(page) => onPagination({ page, sizePerPage: companyData?.metaData.page.size })}
          />
        )}
      </CardComponent>
    </>
  );
}
