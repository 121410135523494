/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Form, Accordion } from 'react-bootstrap';
import CardComponent from '../../../components/base/CardComponent';
import moment from 'moment';
import OccupationalPensionDetail from './PensionPolicyDetails/OccupationalPensionDetail';
import useLocale from '../../../hooks/useLocale';
import { SalaryDetailsViewer } from './PensionPolicyDetails/SalaryDetailsViewer';
import PlansDetailsViewer from './PensionPolicyDetails/PlansDetailsViewer';
import InsuranceDetailsViewer from './PensionPolicyDetails/InsuranceDetailsViewer';
import { EmployeePensionPolicy } from './types';
import useLocalizationResolver from '../../../hooks/useLocalizationResolver';
import usePolicyMode from './hooks/usePolicyMode';
import AutoAssignEmployeeTypeSetting from './PensionPolicyForm/AutoAssignEmployeeTypeSetting';
import SpecialConditionsViewer from './PensionPolicyDetails/SpecialConditions/SpecialConditionsViewer';
import { SalaryTypeDetailViewer } from './PensionPolicyDetails/SalaryTypeDetailViewer';

interface Props {
  policy: any;
  isSelectable: any;
  containerClassName?: string;
  containerBodyClassName?: string;
  effectiveFrom?: string;
  fetchedFromExternalSource?: boolean;
  pensionDataset?: any;
  insuranceDataset?: any;
  employeePolicyRevision?: EmployeePensionPolicy | null;
}

function PensionPoliciesDetailsNonEditable({
  policy,
  isSelectable = false,
  containerClassName = '',
  containerBodyClassName = '',
  effectiveFrom,
  fetchedFromExternalSource,
  pensionDataset,
  insuranceDataset,
  employeePolicyRevision,
}: Partial<Props>) {
  // const { pensionCompanies } = useContext();
  const { localize } = useLocale();
  const { getLang } = useLocalizationResolver();
  const { isCompanyPolicyMode } = usePolicyMode();
  const [activeIndexes, setActiveIndexes] = useState<string[]>(['policyDescription']);

  const onSelectTab = (currentActiveIndexes) => {
    setActiveIndexes(currentActiveIndexes);
  };

  const isPensionManagerActiveIndex = activeIndexes.find((item) => item === 'plan_manager') ? true : false;

  return (
    <CardComponent className={containerClassName} containerBodyClassName={containerBodyClassName}>
      <Form>
        <div className="cr-form-row">
          <div className="policy-name-field">
            <Form.Group>
              <Form.Label className="fs-name">{localize('policy_name')}</Form.Label>
              <Form.Control
                value={policy['policyName']}
                disabled
                size="sm"
                type="text"
                name="policy-name"
                placeholder={`${localize('enter_policy_name')}`}
              />
            </Form.Group>
          </div>
          <div className="version-field">
            <Form.Group>
              <Form.Label className="fs-name">{localize('version')}</Form.Label>
              <Form.Control
                value={policy['version']}
                disabled
                size="sm"
                type="text"
                name="version"
                placeholder={`${localize('enter_version')}`}
              />
            </Form.Group>
          </div>
          <div className="employment-type-field">
            <Form.Group>
              <Form.Label className="fs-name">{localize('employment_type')}</Form.Label>
              <Form.Control
                value={getLang(policy['employeeType'])}
                disabled
                size="sm"
                type="text"
                name="employee-type"
                placeholder={`${localize('enter_emp_type')}`}
              />
            </Form.Group>
          </div>
          <div className="effect-from-field">
            <Form.Group>
              <Form.Label className="fs-name">{localize('effective_from')}</Form.Label>
              <Form.Control
                value={moment(policy['effectiveFrom']).format('MMM YYYY')}
                disabled
                size="sm"
                type="text"
                name="effective-from"
                placeholder={`${localize('enter_effective_from')}`}
              />
            </Form.Group>
          </div>
          {isCompanyPolicyMode() && (
            <div>
              <AutoAssignEmployeeTypeSetting />
            </div>
          )}
          {policy['isCollectiveAgreementLock'] && (
            <div>
              <Form.Group className="mb-3">
                <Form.Check
                  inline
                  type="checkbox"
                  id="agreement"
                  className="fs-name "
                  label={`${localize('collective_agreement')}`}
                  checked={policy['isCollectiveAgreementLock']}
                  disabled
                />
              </Form.Group>
            </div>
          )}
        </div>

        <div className="insurance-table-accordion editor-accordion pp-accordion mb-3">
          <Accordion
            defaultActiveKey={['policyDescription']}
            className="policy-desc-accordion"
            alwaysOpen
            onSelect={onSelectTab}
          >
            <Accordion.Item eventKey="policyDescription">
              <Accordion.Header>
                <h3 className="fs-h3 theme-text">{localize('PENSION_POLICY.policy_description')}</h3>
              </Accordion.Header>
              <Accordion.Body className="mt-2">
                <div className="row">
                  <div className="col-12">
                    <div className="p-3" dangerouslySetInnerHTML={{ __html: policy['policyDescription'] }} />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="occupation" className="policy-accordion-item">
              <Accordion.Header>
                <h2 className="fs-h2 theme-text">1. {localize('PENSION_POLICY.premium_calculation')}</h2>
              </Accordion.Header>
              <Accordion.Body className="mt-2 policy-accordion-body">
                <div className="row">
                  <div className="col-12">
                    <OccupationalPensionDetail selectedPolicy={policy} />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="salary_change" className="policy-accordion-item">
              <Accordion.Header>
                <h2 className="fs-h2 theme-text">2. {localize('salary_change')}</h2>
              </Accordion.Header>
              <Accordion.Body className="mt-2 policy-accordion-body">
                <div className="row">
                  <div className="col-12">
                    <SalaryDetailsViewer selectedPolicy={policy} effectiveFrom={effectiveFrom} />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="pensionable_salary" className="policy-accordion-item">
              <Accordion.Header>
                <h2 className="fs-h2 theme-text">3. {localize('pensionable_salary')}</h2>
              </Accordion.Header>
              <Accordion.Body className="mt-2 policy-accordion-body">
                <div className="row">
                  <div className="col-12">
                    <SalaryTypeDetailViewer selectedPolicy={policy} />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="plan_manager" className="policy-accordion-item">
              <Accordion.Header>
                <h2 className="fs-h2 theme-text">4. {localize('pension_managers')}</h2>
              </Accordion.Header>
              <Accordion.Body className="mt-2 policy-accordion-body">
                <div className="row">
                  <div className="col-12">
                    <PlansDetailsViewer
                      isExpanded={isPensionManagerActiveIndex}
                      fetchedFromExternalSource={fetchedFromExternalSource}
                      pensionDataset={pensionDataset}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="insurance" className="policy-accordion-item">
              <Accordion.Header>
                <h2 className="fs-h2 theme-text">5. {localize('insurances')}</h2>
              </Accordion.Header>
              <Accordion.Body className="mt-2 policy-accordion-body">
                <div className="row">
                  <div className="col-12">
                    <InsuranceDetailsViewer
                      fetchedFromExternalSource={fetchedFromExternalSource}
                      insuranceDataset={insuranceDataset}
                      selectedPolicy={policy}
                    />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="special_condition" className="policy-accordion-item">
              <Accordion.Header>
                <h2 className="fs-h2 theme-text">6. {localize('PENSION_POLICY.special_conditions')}</h2>
              </Accordion.Header>
              <Accordion.Body className="mt-2 policy-accordion-body">
                <div className="row">
                  <div className="col-12">
                    <SpecialConditionsViewer />
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Form>
    </CardComponent>
  );
}

export default PensionPoliciesDetailsNonEditable;
