import useAuth from '../../hooks/useAuth';
import { UserRole, UserSubRole } from '../../types/auth';

type AccessRole = UserRole | UserRole[] | UserSubRole | UserSubRole[];

interface InputProps {
  allowedRoles: AccessRole;
  roleType?: 'primary' | 'secondary';
  children: React.ReactNode;
  hasWithoutSubRole?: boolean;
}

const getAllowedPermissions = (allowedRoles: AccessRole): UserRole[] | UserSubRole[] => {
  if (!Array.isArray(allowedRoles) && typeof allowedRoles !== 'string') {
    return [];
  }

  if (typeof allowedRoles === 'string') {
    return [allowedRoles] as UserRole[] | UserSubRole[];
  }

  return allowedRoles;
};

export default function RoleAccess({
  allowedRoles,
  roleType = 'primary',
  children,
  hasWithoutSubRole = false,
}: InputProps) {
  const { user } = useAuth();
  const currentCompanyRole = user?.role;
  const rolePermissions = getAllowedPermissions(allowedRoles);

  if (
    roleType === 'primary' &&
    currentCompanyRole &&
    (rolePermissions as UserRole[]).includes(currentCompanyRole.role)
  ) {
    return <>{children}</>;
  }

  if (hasWithoutSubRole && !currentCompanyRole?.subRole && currentCompanyRole?.role === UserRole.ACCOUNTING_FIRM) {
    return <>{children}</>;
  }

  if (roleType === 'secondary' && currentCompanyRole && 'subRole' in currentCompanyRole) {
    const currentSubRole = currentCompanyRole.subRole;

    if (currentSubRole && (rolePermissions as UserSubRole[]).includes(currentSubRole)) {
      return <>{children}</>;
    }
  }

  return <></>;
}
