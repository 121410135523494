import * as yup from 'yup';
import { organizationNumberValidations } from '../../../../../util/validation';

export const formSchema: any = yup.object().shape({
  insuranceCompany: yup.object().shape({
    shortName: yup.string().required('Required'),
    companyName: yup.string().required('Required'),
    clientNumber: yup.string().required('Required'),
    contractNumber: yup.string().required('Required'),
    description: yup.string() /* .required('Required') */,
    organizationNumber: organizationNumberValidations(false),
    vatNumber: yup.string() /* .required('Required') */,
    bankgiroNumber: yup.string() /* .required('Required') */,
    plusgiroNumber: yup.string() /* .required('Required') */,
    status: yup.string(),
  }),
  contactInformation: yup.object().shape({
    address: yup.string(),
    city: yup.string(),
    phone: yup.string(),
    fax: yup.string(),
    postalCode: yup.string(),
    email: yup.string().email('Invalid Email'),
  }),
  connection: yup.object().shape({
    connectionTypeAPI: yup.object().shape({
      isChecked: yup.boolean().default(true),
      apiKey: yup.string(),
      serviceKey: yup.string(),
    }),
    connectionTypeFile: yup.object().shape({
      isChecked: yup.boolean().default(false),
      email: yup.string().email('Invalid Email'),
      type: yup.string(),
    }),
  }),
  insurancePlans: yup.object().shape({
    providerDescription: yup.string(),
    healthInsurancePlan: yup.array().of(
      yup.object().shape({
        insuranceName: yup.string().required('Required'),
        insuranceDescription: yup.string(),
        specialCondition: yup.string(),
        hjertaInfinity: yup.boolean(),
        ageSpan: yup.object().shape({
          to: yup.string().required('Required'),
          from: yup.string().required('Required'),
        }),
        documents: yup.array().of(
          yup.object().shape({
            text: yup.string(),
            documentLink: yup.string(),
          }),
        ),
        order: yup.number(),
        costSpecification: yup.object().shape({
          specItems: yup.array().of(
            yup.object().shape({
              title: yup.string(),
              fee: yup.mixed(),
              exemptionLetter: yup.mixed(),
            }),
          ),
          description: yup.string(),
        }),
      }),
    ),
    otherInsurancesPlan: yup.array().of(
      yup.object().shape({
        insuranceName: yup.string().required('Required'),
        insuranceDescription: yup.string(),
        specialCondition: yup.string(),
        hjertaInfinity: yup.boolean(),
        ageSpan: yup.object().shape({
          to: yup.string(),
          from: yup.string(),
        }),
        documents: yup.array().of(
          yup.object().shape({
            text: yup.string(),
            documentLink: yup.string(),
          }),
        ),
        order: yup.number(),
        costSpecification: yup.object().shape({
          specItems: yup.array().of(
            yup.object().shape({
              title: yup.string(),
              fee: yup.mixed(),
              exemptionLetter: yup.mixed(),
            }),
          ),
          description: yup.string(),
        }),
      }),
    ),
    occupationalPensionInsurancePlan: yup.array().of(
      yup.object().shape({
        insuranceName: yup.string().required('Required'),
        insuranceDescription: yup.string(),
        specialCondition: yup.string(),
        hjertaInfinity: yup.boolean(),
        ageSpan: yup.object().shape({
          to: yup.string(),
          from: yup.string(),
        }),
        documents: yup.array().of(
          yup.object().shape({
            text: yup.string(),
            documentLink: yup.string(),
          }),
        ),
        order: yup.number(),
        costSpecification: yup.object().shape({
          specItems: yup.array().of(
            yup.object().shape({
              title: yup.string(),
              fee: yup.mixed(),
              exemptionLetter: yup.mixed(),
            }),
          ),
          description: yup.string(),
        }),
      }),
    ),
    individualInsurancesPlan: yup.array().of(
      yup.object().shape({
        insuranceName: yup.string().required('Required'),
        insuranceDescription: yup.string(),
        specialCondition: yup.string(),
        hjertaInfinity: yup.boolean(),
        ageSpan: yup.object().shape({
          to: yup.string(),
          from: yup.string(),
        }),
        documents: yup.array().of(
          yup.object().shape({
            text: yup.string(),
            documentLink: yup.string(),
          }),
        ),
        order: yup.number(),
        costSpecification: yup.object().shape({
          specItems: yup.array().of(
            yup.object().shape({
              title: yup.string(),
              fee: yup.mixed(),
              exemptionLetter: yup.mixed(),
            }),
          ),
          description: yup.string(),
        }),
      }),
    ),
    groupInsurancePlan: yup.array().of(
      yup.object().shape({
        insuranceName: yup.string().required('Required'),
        insuranceDescription: yup.string(),
        specialCondition: yup.string(),
        hjertaInfinity: yup.boolean(),
        ageSpan: yup.object().shape({
          to: yup.string(),
          from: yup.string(),
        }),
        documents: yup.array().of(
          yup.object().shape({
            text: yup.string(),
            documentLink: yup.string(),
          }),
        ),
        pay: yup.string(),
        order: yup.number(),
        costSpecification: yup.object().shape({
          specItems: yup.array().of(
            yup.object().shape({
              title: yup.string(),
              fee: yup.mixed(),
              exemptionLetter: yup.mixed(),
            }),
          ),
          description: yup.string(),
        }),
      }),
    ),
  }),
});
