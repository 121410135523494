import { BaseStatus } from '../../../types';
import { AccountAccessLevel, ProfileFormAttributes } from './types';

export const INITIAL_ACCESS_CATEGORY = {
  accountingLevel: [],
  companyLevel: [],
  correcto_level: [],
};

export const INITIAL_PROFILE: ProfileFormAttributes = {
  firstName: '',
  lastName: '',
  email: '',
  personalNumber: '',
  phoneNumber: '',
  status: BaseStatus.IN_ACTIVE,
  accessLevels: INITIAL_ACCESS_CATEGORY,
};

export const INITIAL_ACCESS_LEVEL: AccountAccessLevel = {
  company: {
    id: '',
    name: '',
    organizationNumber: '',
  },
  role: [],
  organizationNumber: '',
};
