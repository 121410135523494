import { Dispatch, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Save, Send } from 'react-feather';
import omit from 'lodash/omit';
import { CompanyClient } from '../../../../api/service/superAdmin/company-clients/types';
import { useCreateCompanyClientContext } from '../context/useContext';
import { postNewCompanyClient } from '../../../../api/service/superAdmin/company-clients/service';
import { SetStateAction } from 'react';
import { CompanyClientFormGroup, CompanyClientSaveMode, LooseObject } from '../types';
import { isObjectEmpty } from '../../../../util/util';
import useLocale from '../../../../hooks/useLocale';
import { useTranslation } from 'react-i18next';

type Props = {
  toggleModal: () => void;
  setIsCompanyCreated: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
  formGroup: CompanyClientFormGroup;
};

const STRING_TYPE = {
  DRAFT: 'DRAFT',
  SUBMIT: 'SUBMIT',
};

export default function CreateCompanyClientModalFooter({ toggleModal, setIsCompanyCreated, setErrors }: Props) {
  const { t } = useTranslation();
  const { localize } = useLocale();
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const {
    adminDetails,
    companyDetails,
    addressDetails,
    signatoryDetails,
    signatoryAdmins,
    salaryContactPeople,
    accountingContactPeople,
    contractEffectiveFrom,
    contractEmail,
    invoiceCostCenter,
    consultants,
    invitationEmail,
    contractNumber,
    combinations,
    formGroup,
  } = useCreateCompanyClientContext();

  const modifiedAdminDetails = adminDetails
    .filter((item) => {
      return isObjectEmpty(item);
    })
    .map((i: any) => {
      return omit(i, ['_id', 'valid']);
    });

  const modifiedSignatoryDetails = signatoryDetails.map((i) => omit(i, 'id'));
  const modifiedCombinations = combinations.map((i) => {
    return {
      signee: i.signee,
      authorizedSignatory: i.authorizedSignatory.map((signatory) => {
        const { email, ...details } = signatory;

        return email ? signatory : details;
      }),
    };
  });

  const handleSaveCompany = async (type: string, saveMode: CompanyClientSaveMode) => {
    const data = {
      ...companyDetails,
      address: addressDetails,
      admins: [...signatoryAdmins, ...modifiedAdminDetails],
      authorizedSignatory: modifiedSignatoryDetails,
      combinations: modifiedCombinations,
      contactPeopleForSalary: salaryContactPeople,
      contactPeopleForAccounting: accountingContactPeople,
      contractEffectiveFrom,
      invoiceEmailAddress: contractEmail,
      contractNumber: contractNumber,
      invoicingCostCenter: invoiceCostCenter,
      // collectiveAgreements,
      consultants: consultants,
      invitationEmail: invitationEmail,
      status: saveMode,
      allowAdvisorAccess: formGroup.values.allowAdvisorAccess,
      allowCorrectoAccess: formGroup.values.allowCorrectoAccess,
    } as CompanyClient;

    let params = {};
    if (type === STRING_TYPE.DRAFT) {
      setIsBusyDrafting(true);
      params = {
        draft: true,
        sendEmails: false,
      };
    } else {
      setIsBusy(true);
      params = {
        draft: false,
        sendEmails: true,
      };
    }
    setIsCompanyCreated(false);

    try {
      formGroup.handleSubmit();

      formGroup.validateForm().then((errors) => {
        setErrors(errors);
      });
      if (!formGroup.isValid) {
        return false;
      }
      await postNewCompanyClient(data, params);
      toggleModal();
      setIsCompanyCreated(true);
    } catch (error) {
      const err = error as any;

      if (error instanceof Error) {
        if (err?.metaDetails?.error === 'Conflict') {
          setErrors({ error: t('COMPANY.existing_client') });
        } else {
          setErrors({ error: error.message });
        }
      }
      setIsCompanyCreated(false);
    } finally {
      setIsBusy(false);
      setIsBusyDrafting(false);
    }
  };

  return (
    <div className="d-flex justify-content-between w-100 flex-wrap-row gap-3 ">
      <Button className="app-btn-secondary cancel-btn cr-modal-cancel-btn" onClick={toggleModal}>
        {localize('cancel')}
      </Button>

      <div className="d-flex gap-3 flex-wrap-row">
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary cr-modal-draft-btn"
          onClick={() => {
            handleSaveCompany(STRING_TYPE.DRAFT, CompanyClientSaveMode.DRAFT);
          }}
        >
          {isBusyDrafting ? <Spinner className="icon-gap-right" size="sm" /> : <Save className="icon-gap-right" />}
          {`${localize('save')} ${localize('as')} ${localize('draft')}`}
        </Button>
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary cr-modal-company-client-btn"
          onClick={() => {
            handleSaveCompany(STRING_TYPE.SUBMIT, CompanyClientSaveMode.SETUP);
          }}
        >
          {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <Send className="icon-gap-right" />}
          {`${localize('save')} ${localize('company_clients')} ${localize('and_send_all_invites')}`}
        </Button>
      </div>
    </div>
  );
}
