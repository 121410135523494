export const INITIAL_INSURANCE_COMPANY = {
  shortName: '',
  companyName: '',
  clientNumber: '',
  contractNumber: '',
  description: '',
  organizationNumber: '',
  vatNumber: '',
  bankgiroNumber: '',
  plusgiroNumber: '',
  status: 'Active',
  collectiveAgreement: false,
};
export const INITIAL_CONTACT_INFORMATION = {
  address: '',
  city: '',
  phone: '',
  fax: '',
  postalCode: '',
  email: '',
};

export const INITIAL_INSURANCE_PLANS = {
  providerDescription: '',
  healthInsurancePlan: [],
  otherInsurancesPlan: [],
  occupationalPensionInsurancePlan: [],
  individualInsurancesPlan: [],
  groupInsurancePlan: [],
};

export const INITIAL_CONNECTION = {
  connectionTypeAPI: {
    isChecked: true,
    apiKey: '',
    serviceKey: '',
  },
  connectionTypeFile: {
    isChecked: false,
    email: '',
    type: 'CSV',
  },
};
export const FORM_INITIAL_VALUES = {
  contactInformation: INITIAL_CONTACT_INFORMATION,
  insuranceCompany: INITIAL_INSURANCE_COMPANY,
  connection: INITIAL_CONNECTION,
  insurancePlans: INITIAL_INSURANCE_PLANS,
};
