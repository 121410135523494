import { t } from 'i18next';
import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { File, FilePlus } from 'react-feather';
import BSTable from '../../../../../components/table/BSTable';
import { useColumn } from './useColumn';
import { GetCompanyClientLogsType } from '../../../../../api/service/superAdmin/company-clients/types';
import { UsersListResponse } from '../../../../../api/service/general/type';
import moment from 'moment';
import CardComponent from '../../../../../components/base/CardComponent';

type Props = {};

const Log = (props: Props) => {
  const [isLoading /* setIsLoading */] = useState(false);
  const { companyClientLogColumn } = useColumn();
  const [companyClientLogData /* setCompanyClientLogData */] = useState<GetCompanyClientLogsType | null>(null);
  const [users /* setUsers */] = useState<UsersListResponse[]>([]);
  // const [categories, setCategories] = useState<any[]>([]);
  const [logsFilter, setLogFilter] = useState({
    userId: '',
    action: '',
    level: '',
    from: '',
    to: '',
  });

  const exportCSV = (props) => {};
  const setFilter = (key, value) => {
    setLogFilter((prevValue) => ({
      ...prevValue,
      [key]: value,
    }));
  };

  return (
    <CardComponent>
      <Row className="justify-content-between">
        <Col className="mb-5">
          <Form>
            <ToolkitProvider
              search
              keyField="id"
              data={companyClientLogData?.data ?? []}
              columns={companyClientLogColumn}
              csv
              // exportCSV={{
              //   fileName: 'custom.xls',
              // }}
            >
              {(props: any) => (
                <div>
                  <div className="d-flex align-items-end justify-content-between">
                    <div className="filter-col d-flex">
                      <Form.Group className="from-to-field">
                        <Form.Label className="fs-name">{t('from')}</Form.Label>
                        <Form.Control
                          type="date"
                          name="startDate"
                          value={logsFilter.from}
                          onChange={(e) => {
                            setFilter('from', moment(e.target.value).format('YYYY-MM-DD'));
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="from-to-field">
                        <Form.Label className="fs-name">{t('to')}</Form.Label>
                        <Form.Control
                          type="date"
                          name="endDate"
                          value={logsFilter.to}
                          onChange={(e) => {
                            setFilter('to', moment(e.target.value).format('YYYY-MM-DD'));
                          }}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="fs-name">{t('company')}</Form.Label>
                        <Form.Select
                          name="user"
                          className="text-left"
                          onChange={(e) => {
                            setFilter('userId', e.target.value);
                          }}
                        >
                          <option value="">{t('all')}</option>
                          {users &&
                            users?.map((user) => (
                              <option value={user.id} key={user.id}>
                                {user.fullName}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>
                    </div>

                    <div>
                      <Button className="app-btn-secondary me-2" onClick={() => exportCSV({ ...props.csvProps })}>
                        <File className="icon-gap-right" /> {`${t('CSV')} ${t('export')}`}
                      </Button>
                      <Button className="app-btn-secondary" onClick={() => exportCSV({ ...props.csvProps })}>
                        <FilePlus className="icon-gap-right" />
                        {`${t('excel')} ${t('export')}`}
                      </Button>
                    </div>

                    {/* <div className="d-flex">
                  <Button style={{ maxWidth: 160, minWidth: 160 }} className="app-btn-secondary me-2" onClick={() => exportCSV({ ...props.csvProps })}>
                    <File className="icon-gap-right" /> {`${t('CSV')} ${t('export')}`}
                  </Button>
                  <Button style={{ maxWidth: 160, minWidth: 160 }} className="app-btn-secondary" onClick={() => exportCSV({ ...props.csvProps })}>
                    <FilePlus className="icon-gap-right" />
                    {`${t('excel')} ${t('export')}`}
                  </Button>
                </div> */}
                  </div>
                  <div className="crt-table-report">
                    {isLoading ? <Spinner /> : <BSTable baseProps={props.baseProps} {...props.baseProps} />}
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </Form>
        </Col>
      </Row>
    </CardComponent>
  );
};

export default Log;
