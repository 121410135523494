import { Button, Form } from 'react-bootstrap';
import { CornerDownRight, RefreshCw } from 'react-feather';
import PagesHeader from '../../../../components/base/PagesHeader';
import isEmpty from 'lodash/isEmpty';
import { FormProps } from '../types';
import { useTranslation } from 'react-i18next';
import { getRoaringPersonInfo } from '../../../../api/service/superAdmin/company-clients/service';
import { splitFullName } from '../../../../util/util';
import classNames from 'classnames';
import { errorHighlighter } from '../../../../util/form';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import PersonalNumber from '../../../../components/form/inputs/PersonalNumber';
import { useState } from 'react';
import { DEFAULT_PERSON_INFO } from '../../../../types/general';
// import BaseButton from '../../../../components/base/BaseButton';

export default function PersonalDetails({ context }: FormProps) {
  const { personalDetails, isEditMode, formGroup, patchConsultantForm, updateConsultantInput } = context;
  const { t } = useTranslation();
  const [isPersonalNumberValid, setIsPersonalNumberValid] = useState<boolean>(true);

  const handlePersonalDetails = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    updateConsultantInput(inputName, inputValue);
    setIsPersonalNumberValid(true);
  };
  const loadDetails = async (personalNumber) => {
    try {
      patchConsultantForm(DEFAULT_PERSON_INFO);

      const { fullName, email } = await getRoaringPersonInfo(personalNumber);
      const { firstName, lastName } = splitFullName(fullName);
      personalDetails!.firstName = firstName;
      personalDetails!.lastName = lastName;
      formGroup.values!.firstName = firstName;
      formGroup.values!.lastName = lastName;

      patchConsultantForm({
        fullName,
        firstName: personalDetails!.firstName,
        lastName: personalDetails!.lastName,
        email,
      });
      setIsPersonalNumberValid(true);
    } catch (e) {
      setIsPersonalNumberValid(false);
    }
  };

  return (
    <div>
      <PagesHeader pageTitle="Personal details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        <div>
          <div className="cr-form-row">
            <PersonalNumber
              disabled={isEditMode}
              value={formGroup.values.personalNumber}
              onChangeHandler={(e) => handlePersonalDetails(e)}
              field={formGroup.getFieldMeta('personalNumber')}
              isValid={isPersonalNumberValid}
              lazy={true}
            />

            <Button
              disabled={isEmpty(personalDetails?.personalNumber)}
              variant="primary"
              onClick={() => loadDetails(personalDetails?.personalNumber)}
              className="app-btn-primary"
            >
              {isEditMode ? <RefreshCw className="icon-gap-right" /> : <CornerDownRight className="icon-gap-right" />}{' '}
              {isEditMode ? t('refresh') : t('fetch_name')}
            </Button>

            <div className="first-name-field">
              <Form.Label className="fs-name mb-0 required">{t('first_name')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="firstName"
                className={classNames(errorHighlighter(formGroup.getFieldMeta('firstName')))}
                value={formGroup.values.firstName}
                disabled
                onChange={(e) => handlePersonalDetails(e)}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('firstName')} />
            </div>
            <div className="last-name-field">
              <Form.Label className="fs-name mb-0 required">{t('last_name')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="lastName"
                value={formGroup.values.lastName}
                disabled
                onChange={(e) => handlePersonalDetails(e)}
                className={classNames(errorHighlighter(formGroup.getFieldMeta('lastName')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('lastName')} />
            </div>
            <div className="email-field">
              <Form.Group>
                <Form.Label className="fs-name mb-0 required">{`${t('email')} ${t('address')}`}</Form.Label>
                <Form.Control
                  value={formGroup.values.email}
                  size="lg"
                  type="text"
                  onChange={(e) => handlePersonalDetails(e)}
                  name="email"
                  className={classNames(errorHighlighter(formGroup.getFieldMeta('email')))}
                />
                <ErrorFeedback field={formGroup.getFieldMeta('email')} />
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
