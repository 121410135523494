import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import classNames from 'classnames';
import useContext from './context/useContext';
import useLocale from '../../../../hooks/useLocale';
import PagesHeader from '../../../../components/base/PagesHeader';
import CardComponent from '../../../../components/base/CardComponent';
import { IInsurances } from '../types';
import InsurancesRowItem from './InsurancesRowItem';
import { sortInsurancePlans } from '../../../../api/service/superAdmin/settings/insurances';

export const InsuranceList = () => {
  const { localize } = useLocale();
  const { insurances, isBusy, replaceInsurances } = useContext();
  const [loadingContainer, setLoadingContainer] = useState({ primary: false, secondary: false });
  const insuranceList: IInsurances[] = insurances;
  const collectiveInsuranceList: IInsurances[] = insurances.filter((item) => (item as any).collectiveAgreement) || [];
  const nonCollectiveInsuranceList: IInsurances[] =
    insurances.filter((item) => !(item as any).collectiveAgreement) || [];

  const toggleLoadingContainer = (containerKey) => {
    setLoadingContainer((prev) => {
      return {
        ...prev,
        [containerKey]: !prev[containerKey],
      };
    });
  };

  const listDiff = (prevList, newList) => {
    prevList = JSON.parse(JSON.stringify(prevList));
    newList = JSON.parse(JSON.stringify(newList));

    let changed = newList.filter((p, idx) => {
      if (p['id'] !== prevList[idx]['id']) {
        p['newSortOrder'] = idx + 1;
        return p;
      }

      return false;
    });

    return changed;
  };

  const onDragEnd = async (event, type) => {
    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }

    const selectedLoadingType = 'primary';
    const oldIndex = insuranceList.findIndex((item) => item.id === active.id);
    const newIndex = insuranceList.findIndex((item) => item.id === over.id);
    const sortedList = arrayMove(insuranceList, oldIndex, newIndex).map((item, index) => {
      item.order = index + 1;

      return item;
    });

    let _insuranceList = { ...insurances };
    _insuranceList = sortedList;
    replaceInsurances(_insuranceList);

    const selectedReOrderedItems = listDiff(insuranceList, sortedList);

    try {
      toggleLoadingContainer(selectedLoadingType);
      await sortInsurancePlans({
        orders: selectedReOrderedItems.map((item) => {
          return {
            id: item.id,
            order: item.order,
          };
        }),
      });
    } catch (e) {
      console.error(e);
    } finally {
      toggleLoadingContainer(selectedLoadingType);
    }
  };
  return (
    <>
      <CardComponent>
        <PagesHeader headingClass="fs-h2 font-black" pageTitle={'Insurance companies'} />
        {
          <div className="d-flex align-items-start my-2 mb-4">
            <h3 className="fs-h3 font-black">{localize('for_collective_agreements')}</h3>
            {loadingContainer.primary && <Spinner size="sm" className="ms-1" />}
          </div>
        }
        {isBusy ? (
          <Spinner />
        ) : (
          <>
            <div
              className={classNames('table-responsive react-bootstrap-table chart-table', {
                'ui-blocker': loadingContainer.primary,
              })}
            >
              <DndContext collisionDetection={closestCenter} onDragEnd={(event) => onDragEnd(event, 'hjertaPartner')}>
                <SortableContext
                  items={collectiveInsuranceList.map((item) => item.id!)}
                  strategy={verticalListSortingStrategy}
                >
                  <table className="table table-hover table-condensed pension-plans-table">
                    <thead className="header-wrapper">
                      <tr className="correcto-table-header">
                        <th style={{ width: 25 }}></th>
                        <th className="month-header-col" style={{ width: 150 }}>
                          {localize('company_short_name')}
                        </th>
                        <th className="month-header-col" style={{ width: 300 }}>
                          {localize('description')}
                        </th>
                        <th className="month-header-col" style={{ width: 80 }}>
                          {localize('status')}
                        </th>
                        <th style={{ width: 70 }}></th>
                      </tr>
                    </thead>
                    <tbody className="remote-table-body">
                      {collectiveInsuranceList?.map((item) => {
                        return <InsurancesRowItem key={item.id} item={item} />;
                      })}
                    </tbody>
                  </table>
                </SortableContext>
              </DndContext>
            </div>
          </>
        )}
        <div className="d-flex align-items-start my-2 mb-4">
          <h3 className="fs-h3 font-black theme-text">{localize('other')}</h3>
          {loadingContainer.secondary && <Spinner size="sm" className="ms-2" />}
        </div>
        {isBusy ? (
          <Spinner />
        ) : (
          <>
            <div
              className={classNames('table-responsive react-bootstrap-table chart-table', {
                'ui-blocker': loadingContainer.primary,
              })}
            >
              <DndContext collisionDetection={closestCenter} onDragEnd={(event) => onDragEnd(event, 'hjertaPartner')}>
                <SortableContext
                  items={nonCollectiveInsuranceList.map((item) => item.id!)}
                  strategy={verticalListSortingStrategy}
                >
                  <table className="table table-hover table-condensed pension-plans-table">
                    <thead className="header-wrapper">
                      <tr className="correcto-table-header">
                        <th style={{ width: 25 }}></th>
                        <th className="month-header-col" style={{ width: 150 }}>
                          {localize('company_short_name')}
                        </th>
                        <th className="month-header-col" style={{ width: 300 }}>
                          {localize('description')}
                        </th>
                        <th className="month-header-col" style={{ width: 80 }}>
                          {localize('status')}
                        </th>
                        <th style={{ width: 70 }}></th>
                      </tr>
                    </thead>
                    <tbody className="remote-table-body">
                      {nonCollectiveInsuranceList?.map((item) => {
                        return <InsurancesRowItem key={item.id} item={item} />;
                      })}
                    </tbody>
                  </table>
                </SortableContext>
              </DndContext>
            </div>
          </>
        )}
      </CardComponent>
    </>
  );
};
