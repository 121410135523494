import React from 'react';
import { Form } from 'react-bootstrap';
import { PercentageNumber } from '../../../../components/form/inputs/PercentangeNumber';
import classNames from 'classnames';

type Props = {
  index: number;
  salary: any;
  type: string;
  handleCheckedRow: (value, salary, type) => void;
  handleSalaryChange: (value, salary, type) => void;
};

const SalaryTypeItem = ({ index, salary, type, handleCheckedRow, handleSalaryChange }: Props) => {
  return (
    <>
      <tr className={classNames('highlight-inactive', { 'active-highlighted-row': salary.selected })} key={index}>
        <td style={{ maxWidth: 80, width: 80 }}>
          <Form.Group className="pe-0">
            <Form.Check
              onChange={(e) => handleCheckedRow(e, salary, type)}
              inline
              type="checkbox"
              label=""
              id={salary._id}
              className="fs-name mb-1 "
              checked={salary.selected === true ? true : false}
              value={salary.selected}
            />
          </Form.Group>
        </td>
        {type === 'company' ? (
          <td style={{ maxWidth: 100, width: 100 }}>
            <div className="salary-code-control salary-code-control--disabled">
              <span>C</span>
              <Form.Control value={salary.code} disabled />
            </div>
          </td>
        ) : (
          <td style={{ maxWidth: 100, width: 100 }}>
            <Form.Control value={salary.code} disabled />
          </td>
        )}
        <td style={{ maxWidth: 300, width: 300 }}>
          <Form.Control value={salary.name} disabled />
        </td>
        <td>
          <PercentageNumber
            value={salary.percentage}
            onChangeHandler={(val) => handleSalaryChange(val, salary, type)}
            decimalPlace={2}
            inputStyle={{ textAlign: 'right', width: '80px' }}
            name="percentage"
          />
        </td>
      </tr>
    </>
  );
};

export default React.memo(SalaryTypeItem);
