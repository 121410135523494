import React, { useEffect, useState } from 'react';
import { isEmptyVal, numberFormat, toNumber } from '../../util/util';
import classNames from 'classnames';

interface PriceInputProps {
  defaultValue?: number | string | null;
  onChange?: (value?: number | null) => void;
  disabled?: boolean;
  width?: string | number | undefined;
  className?: string;
  maxValueDigit?: number;
  style?: React.CSSProperties | undefined;
  name?: string;
}

export default function PriceInput({
  defaultValue = '',
  onChange,
  width,
  disabled,
  className,
  maxValueDigit,
  style,
  name,
}: PriceInputProps) {
  const [inputValue, setInputValue] = useState(defaultValue?.toString());
  const [isFocused, setIsFocused] = useState(false);

  const formatNumber = (inputValue?: string) => {
    if (!isEmptyVal(inputValue)) {
      return numberFormat(toNumber(inputValue));
    }

    return '';
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    // onChange(toNumber(inputValue)!);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    const regex = /^-?\d*\.?,?\d*$/;

    if (regex.test(inputValue)) {
      const numericValue = toNumber(inputValue);

      if (maxValueDigit) {
        const maxValueRange = calculateMaxValue(maxValueDigit);

        if (numericValue > maxValueRange) {
          setInputValue(maxValueRange.toString());
          onChange!(maxValueRange);

          return;
        }
      }

      setInputValue(inputValue);
      onChange!(numericValue);
    }
  };

  const calculateMaxValue = (digits: number) => {
    const maxValue = Math.pow(10, digits) - 1;

    return maxValue;
  };

  useEffect(() => {
    if (defaultValue !== inputValue) {
      const numericValue = toNumber(defaultValue);
      const parsedValue = numericValue !== null ? numericValue : '';

      setInputValue(parsedValue?.toString());
    }
  }, [defaultValue]);

  return (
    <>
      <input
        className={classNames('form-control', className)}
        type="text"
        name={name}
        value={!isFocused ? formatNumber(inputValue)! : inputValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        width={width}
        disabled={disabled}
        style={style}
      />
    </>
  );
}
