import moment from 'moment';

export const padMonthWithZeros = (dateRange, data, opt: { colLength: number } | null = null) => {
  const startDate = moment(dateRange[0]);
  const endDate = moment(dateRange[1]);
  const filledData: any[] = [];

  let dataIndex = 0;

  for (
    const currentDate = startDate.clone();
    currentDate.isSameOrBefore(endDate, 'month');
    currentDate.add(1, 'month')
  ) {
    const dateString = currentDate.startOf('month').format('YYYY-MM-DD');

    const foundMonthData = data.find(
      (item) => moment(item[0]).format('YYYY-MM') === moment(dateString).format('YYYY-MM'),
    );

    if (dataIndex < data.length && foundMonthData) {
      filledData.push(foundMonthData);
      dataIndex++;
    } else {
      const defaultData: any[] = [dateString];
      const numCols = data[0] ? data[0].length - 1 : opt?.colLength || 0;

      for (let counter = 0; counter < numCols; counter++) {
        defaultData.push(0);
      }

      filledData.push(defaultData);
    }
  }

  return filledData;
};

export const activeStyleByMonthRange = (target: string[], source: string[]) => {
  if (!(Array.isArray(target) && Array.isArray(source))) {
    return '';
  }

  return target[0] === source[0] && target[1] === source[1] ? 'selected-button' : '';
};

export const getRecentDateRange = (maxRange, hasCurrentMonthDataNotExist) => {
  const currentDate = moment();
  const subtractEndMonthRange = hasCurrentMonthDataNotExist ? 1 : 0;

  if (!maxRange) {
    return [
      moment().startOf('year').startOf('months').format('YYYY-MM'),
      moment().startOf('months').subtract(subtractEndMonthRange, 'month').format('YYYY-MM'),
    ];
  }

  const endMonth = currentDate.subtract(subtractEndMonthRange, 'month').endOf('month').format('YYYY-MM');
  currentDate.subtract(maxRange - 1, 'months');
  const startMonth = currentDate.format('YYYY-MM');

  return [startMonth, endMonth];
};
