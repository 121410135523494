import { useCallback, useState } from 'react';
import { CompanySalaryEditor, CompanySalaryTypeSettings } from '../types';
import { getCompanySalaryCode, saveCompanySalaryCode } from '../../../../api/service/company/settings';
import { useValidationError } from '../../../../hooks/useValidationError';
import { ConfirmationDialog } from '../../../../util/dialogs';
import { t } from 'i18next';
import { deepClone, generateUniqueId } from '../../../../util/util';
// import _ from 'lodash';

const useCompanySalaryEditor = (): CompanySalaryEditor => {
  const { /* errors, isInvalid, */ setErrors } = useValidationError();
  const [initialCompanySalaryTypes, setInitialCompanySalaryTypes] = useState<CompanySalaryTypeSettings[]>([]);
  const [companySalaryTypes, setCompanySalaryTypes] = useState<CompanySalaryTypeSettings[]>([]);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const addSalaryType = useCallback(() => {
    const _companySalaryTypes = [...companySalaryTypes];

    _companySalaryTypes.push({
      _id: generateUniqueId(),
      name: '',
      code: '',
      type: 'Company',
      hasRecordExist: false,
    });

    setCompanySalaryTypes(_companySalaryTypes);
    scroll();
  }, [companySalaryTypes]);

  const save = useCallback(async () => {
    try {
      setIsLoading(true);

      const selectedSalaryTypes = companySalaryTypes
        // .filter((item) => item.hasRecordExist === false)
        .map((item) => {
          const { _id, id, hasRecordExist, code, ..._chosenSalaryType } = item;

          return Object.assign(
            {
              code: parseFloat(code),
              ..._chosenSalaryType,
            },
            hasRecordExist
              ? {
                  _id: id,
                }
              : null,
          );
        });

      await saveCompanySalaryCode({
        salaryTypes: selectedSalaryTypes,
        deletedIds: deletedIds,
      });

      fetchAll();
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  }, [companySalaryTypes, isLoading]);

  /*const findObjectDifferences = (obj1, obj2, path: any[] = []) => {
    // Recursively compare objects
    function difference(object, base, path: any = []) {
      const result: any = [];
      _.transform(object, function (__, value, key) {
        if (!_.isEqual(value, base[key])) {
          result.push({
            path: [...path, key],
            oldValue: base[key],
            newValue: value,
            object: object, // Include the entire object where the change occurred
          });
        } else if (_.isObject(value) && _.isObject(base[key])) {
          result.push(...difference(value, base[key], [...path, key]));
        } else if (_.isArray(value) && _.isArray(base[key])) {
          if (!_.isEqual(value, base[key])) {
            result.push({
              path: [...path, key],
              oldValue: base[key],
              newValue: value,
              object: object,
            });
          }
        }
      });
      return result;
    }

    return difference(obj1, obj2, path);
  };*/

  const fetchAll = useCallback(async () => {
    try {
      setIsLoading(true);

      const _salaryTypes = await getCompanySalaryCode();
      const result: any[] =
        _salaryTypes.map((item) => {
          return {
            ...item,
            hasRecordExist: true,
          };
        }) || [];
      setCompanySalaryTypes(result);
      setInitialCompanySalaryTypes(deepClone(result));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, companySalaryTypes]);

  const removeSalaryType = async (id, index) => {
    const title = 'Are you sure you want to remove that salary type?';
    const result = await ConfirmationDialog({
      title: title,
      confirmButtonText: t('yes'),
      denyButtonText: t('no'),
    });

    if (result.isConfirmed) {
      const _companySalaryTypes = [...companySalaryTypes];
      const _initialCompanySalaryTypes = [...initialCompanySalaryTypes];
      const selectedIndex = _companySalaryTypes.findIndex((item) => item.id === id)!;
      const selected = _companySalaryTypes[selectedIndex];

      if (selected.hasRecordExist) {
        const _deletedIds = [...deletedIds];
        _deletedIds.push(selected.id!);
        setDeletedIds(_deletedIds);
      }

      _companySalaryTypes.splice(selectedIndex, 1);
      _initialCompanySalaryTypes.splice(selectedIndex, 1);

      setCompanySalaryTypes(_companySalaryTypes);
      setInitialCompanySalaryTypes(deepClone(_initialCompanySalaryTypes));
    }
  };

  const handleChange = (index, key, value) => {
    const _companySalaryTypes = [...companySalaryTypes];
    const selected = _companySalaryTypes[index];

    if (key === 'code') {
      const regex = /^[0-9\b]*$/;

      if (!regex.test(value)) {
        return false;
      }
    }
    selected[key] = value;

    setCompanySalaryTypes(_companySalaryTypes);
  };

  const scroll = useCallback(() => {
    setTimeout(() => {
      const element = document.getElementById('salaryRef');
      let scrollHeight = element?.scrollHeight;
      element?.scroll(0, scrollHeight ? scrollHeight + 400 : 0);
    }, 100);
  }, []);

  return {
    companySalaryTypes,
    isLoading,
    addSalaryType,
    removeSalaryType,
    fetchAll,
    save,
    handleChange,
  };
};

export default useCompanySalaryEditor;
