import React from 'react';
import { Form } from 'react-bootstrap';

import PagesHeader from '../../../../../../components/base/PagesHeader';
import { t } from 'i18next';
import useProfileForm from '../../../hooks/useProfileForm';
import PersonalNumber from '../../../../../../components/form/inputs/PersonalNumber';

type Props = {};

const PersonalInfo = (props: Props) => {
  const { profileFormVal, handleProfileChange } = useProfileForm();

  return (
    <div>
      <PagesHeader pageTitle="Personal info" headingClass="fs-h2 font-black" />
      <div className="cr-form-row">
        <div className="first-name-field">
          <Form.Label className="fs-name mb-0">{t('first_name')}</Form.Label>
          <Form.Control disabled defaultValue={profileFormVal.firstName} />
        </div>
        <div className="last-name-field">
          <Form.Label className="fs-name mb-0">{t('last_name')}</Form.Label>
          <Form.Control disabled defaultValue={profileFormVal.lastName} />
        </div>
        <PersonalNumber value={profileFormVal.personalNumber} disabled />
      </div>
      <div className="cr-form-row">
        <div className="phone-num-work-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0">{`${t('phone_number')} (${t('private')})`}</Form.Label>
            <Form.Control
              type="number"
              onChange={(ev) => {
                handleProfileChange('phoneNumber', ev.target.value);
              }}
              name="phoneNumber.private"
              value={profileFormVal.phoneNumber}
            />
          </Form.Group>
        </div>
        <div className="email-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0">{`${t('alternate_email_address')} (${t('private')})`}</Form.Label>
            <Form.Control
              type="email"
              onChange={(ev) => {
                handleProfileChange('email', ev.target.value);
              }}
              name="alternateEmail"
              value={profileFormVal.email}
            />
          </Form.Group>
        </div>
        <div className="status-small-field">
          <Form.Label className="fs-name mb-0">{t('status')}</Form.Label>
          <Form.Control disabled value={profileFormVal.status} />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
