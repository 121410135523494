import classNames from 'classnames';
import { Info } from 'react-feather';

type Props = {
  show: boolean;
  msg: string;
  inline?: boolean;
};

export default function AlertInfo({ show, msg, inline = false }: Props) {
  if (!show) {
    return <></>;
  }

  return (
    <div
      className={classNames(
        'mb-3 alert alert-info theme-text',
        inline ? 'd-inline-flex' : 'd-flex',
        'align-items-center',
      )}
      style={{ padding: '8px 15px 8px 15px' }}
    >
      <Info className="icon-gap-right" />
      <span className="fs-label theme-text">{msg}</span>
    </div>
  );
}
