import { API_VERSION, PENSION_COMPANIES_ENDPOINT } from '../../../../util/constants';
import { HttpQuery } from '../../../../util/http-query';
import { del, get, post, put } from '../../../../util/httpService';

export const createPensionCompany = async (payload) => {
  const pensionCompany = await post(`${API_VERSION}${PENSION_COMPANIES_ENDPOINT}`, payload, {});
  return pensionCompany;
};

export const getPensionManagers = async (params = {}) => {
  let endpoint = HttpQuery.serialize(params, `${API_VERSION}${PENSION_COMPANIES_ENDPOINT}`);

  const pensionCompany = await get(endpoint);
  return pensionCompany;
};

export const getPensionManagerByAgreement = async (params = {}) => {
  let endpoint = HttpQuery.serialize(params, `${API_VERSION}${PENSION_COMPANIES_ENDPOINT}/by/collectiveAgreement`);

  const pensionCompany = await get(endpoint);
  return pensionCompany;
};

export const updatePensionCompany = async (id, payload) => {
  const pensionCompany = await put(`${API_VERSION}${PENSION_COMPANIES_ENDPOINT}/${id}`, payload, {});
  return pensionCompany;
};

export const deletePensionCompany = async (id) => {
  const pensionCompany = await del(`${API_VERSION}${PENSION_COMPANIES_ENDPOINT}/${id}`, {});
  return pensionCompany;
};
export const getPensionCompanyDetail = async (id) => {
  const pensionCompany = await get(`${API_VERSION}${PENSION_COMPANIES_ENDPOINT}/${id}`);
  if (pensionCompany.contactInformation) {
    return pensionCompany;
  } else {
    return { ...pensionCompany, contactInformation: {} };
  }
};

export const sortPensionManagers = async (payload) => {
  const pensionCompany = await post(`${API_VERSION}${PENSION_COMPANIES_ENDPOINT}/orders`, payload, {});
  return pensionCompany;
};
