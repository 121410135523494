import useContext from '../PensionPolicyContext/useContext';
import { TypeOffset } from '../types';
import { isEmptyVal } from '../../../../util/util';

const useEmployeeSalaryChange = () => {
  const { preAssignedPolicy, getPolicySettingsInput, formGroup, externalPolicyRevision } = useContext();

  const preAssignedSalaryChange = externalPolicyRevision?.assignedEmployeePension?.policySettings?.salaryChange;

  const disableEffectiveDateIfInvalid = (key, assignedEmployeePensionID, revisionPensionID) => {
    if (assignedEmployeePensionID && revisionPensionID && assignedEmployeePensionID !== revisionPensionID) {
      return true;
    }

    if (!preAssignedPolicy?.id) {
      return;
    }

    const preAssignedInput: TypeOffset = preAssignedSalaryChange?.[key];
    const currentInput: TypeOffset = getPolicySettingsInput(`salaryChange.${key}`);

    if (!preAssignedInput) {
      return !isEmptyVal(currentInput?.offset) ? false : true;
    }

    const currentVal: Omit<TypeOffset, 'isEditAllowed' | 'isChecked'> = {
      effectiveFrom: currentInput?.effectiveFrom || '',
      offset: currentInput?.offset ?? (null as any),
    };
    const lastVal: Omit<TypeOffset, 'isEditAllowed' | 'isChecked'> = {
      effectiveFrom: preAssignedInput?.effectiveFrom || '',
      offset: preAssignedInput?.offset ?? (null as any),
    };

    return currentVal?.offset === lastVal.offset ? true : false;
  };

  const applyEmployeeOffset = (currentOffsetValue, key) => {
    if (!preAssignedPolicy?.id) {
      return;
    }

    const preAssignedSalaryChange: TypeOffset = preAssignedPolicy?.policySettings?.salaryChange?.[key];

    if (!preAssignedSalaryChange) {
      return null;
    }

    if (preAssignedSalaryChange?.offset === currentOffsetValue) {
      formGroup.setFieldValue(
        `policySettings.salaryChange.${key}.effectiveFrom`,
        preAssignedSalaryChange?.effectiveFrom || '',
      );
    } else {
      formGroup.setFieldValue(`policySettings.salaryChange.${key}.effectiveFrom`, '');
    }
  };

  return {
    disableEffectiveDateIfInvalid,
    applyEmployeeOffset,
  };
};

export default useEmployeeSalaryChange;
