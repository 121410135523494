import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  forceRefresh?: boolean;
};

const LocaleGuard = ({ children, forceRefresh }: React.PropsWithChildren<Props>) => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = () => {
      setCurrentLang(i18n.language);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  if (forceRefresh) {
    return <React.Fragment key={`crtlang-${currentLang}`}>{children}</React.Fragment>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default LocaleGuard;
