import { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { t } from 'i18next';

import General from './ProfilePanes/General/General';
import Log from './ProfilePanes/Log/Log';

import Breadcrumb from '../../../components/base/Breadcrumb';
// import ProfilePaneAction from './components/ProfilePaneAction/ProfilePaneAction';

import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import useProfileContainer from './hooks/useProfileContainer';
import useProfileForm from './hooks/useProfileForm';
import { getFullName } from '../../../util';
import { getNameInitials } from '../../../util/util';

import themeStyle from '../../../assets/scss/6-pages/superAdmin/settings/profile.module.scss';
import ProfilePaneAction from './components/ProfilePaneAction/ProfilePaneAction';

type Props = {};

const ProfileContainer = (props: Props) => {
  useProfileContainer();

  const { profileFormVal } = useProfileForm();
  const fullName = getFullName(profileFormVal.firstName, profileFormVal.lastName);

  const [activeTabKey, setActiveTabKey] = useState('general');

  const handleTabChange = (key) => {
    setActiveTabKey(key);
  };

  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Profile',
    },
  ];

  return (
    <div className={themeStyle.profile_view}>
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-baseline mb-3">
          <div
            style={{ backgroundColor: '#01B8AA', color: '#ffffff', width: '44px', height: '44px', fontSize: '19px' }}
            className="avatar img-fluid rounded-circle me-2"
          >
            {getNameInitials(fullName)}
          </div>
          <h1 className="fs-h1 m-0">{fullName}</h1>
        </div>
        <ProfilePaneAction profileFormVal={profileFormVal} />
      </div>
      <>
        <Tabs defaultActiveKey="general" onSelect={handleTabChange} activeKey={activeTabKey}>
          <Tab eventKey="general" title={t('general')}>
            <General />
          </Tab>
          <Tab eventKey="logs" title={t('logs')}>
            <Log />
          </Tab>
        </Tabs>
      </>
    </div>
  );
};

export default ProfileContainer;
