import { Row, Col, Form, Button } from 'react-bootstrap';
import { Plus, Save, Slash, Trash2 } from 'react-feather';
import { Dispatch, SetStateAction, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TooltipBox from '../../../components/base/Overlay/TooltipBox';

export const BillingDetailGrid = ({
  editMode,
  setEditMode,
}: {
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const [billingColumns, setBillingColumns] = useState([
    { title: 'Employees' },
    { title: 'Admins' },
    { title: 'Kivra integration' },
    { title: 'Scanned PDFs' },
    { title: 'Product 01' },
    { title: 'Manual' },
    { title: 'Employees <3' },
  ]);

  return (
    <div className="mb-5 p-2 dark-content-section">
      <div className="cr-table">
        <table cellSpacing={10} cellPadding={7}>
          <tr className="t-headings">
            <td width={150} className="fs-name text-theme">
              {t('product_name')}
            </td>
            <td width={80} className="fs-name text-theme">
              {t('article_no')}
            </td>
            <td width={150} className="fs-name text-theme">
              {t('data_connector')}
            </td>
            <td width={50} className="fs-name text-theme text-center">
              {t('pcs')}
            </td>
            <td width={110} className="fs-name text-theme ">
              {t('price')} (SEK)
            </td>
            <td width={110} className="fs-name text-theme text-left">
              {t('period')}
            </td>
            <td width={100} className="fs-name text-theme px-3">
              % {t('discount')}
            </td>
            <td width={120} className="fs-name text-theme text-left">
              {t('from_date')}
            </td>
            <td width={120} className="fs-name text-theme text-left">
              {t('to_date')}
            </td>
          </tr>
          {React.Children.toArray(
            billingColumns.map((item, index) => {
              return (
                <tr>
                  <td>
                    <span className="fs-text-700 theme-text ">{item.title}</span>
                  </td>
                  <td align="right">
                    <span className="fs-text-700 text-theme">1000</span>
                  </td>
                  <td>
                    <Form.Control type="text" disabled defaultValue={item.title} />
                  </td>
                  <td>
                    <p className="fs-text-400 text-theme text-right">20</p>
                  </td>
                  <td>
                    <Form.Control type="number" defaultValue={200} />
                  </td>
                  <td>
                    <Form.Select>
                      <option>{t('monthly')}</option>
                      <option>{t('quaterly')}</option>
                      <option>{t('yearly')}</option>
                    </Form.Select>
                  </td>
                  <td className="discount-col">
                    <Form.Control disabled={editMode} type="number" defaultValue={200} />
                  </td>
                  <td>
                    <Form.Control disabled={editMode} type="date" />
                  </td>
                  <td>
                    <Form.Control disabled={editMode} type="date" />
                  </td>
                  {editMode && (
                    <td>
                      <TooltipBox msg={`${t('delete')}`}>
                        <Trash2
                          onClick={() => {
                            const data = [...billingColumns];
                            data.splice(index, 1);
                            setBillingColumns(data);
                          }}
                          className="cursor-pointer color-danger"
                        />
                      </TooltipBox>
                    </td>
                  )}
                </tr>
              );
            }),
          )}
        </table>
        {/* <HorizontalRule /> */}
        {/* <Row>
          <Col style={{ maxWidth: 125 }}>
            <p className="fs-name text-theme text-right">Product name</p>
          </Col>
          <Col style={{ maxWidth: 80 }}>
            <p className="fs-name text-theme text-right">Article no</p>
          </Col>
          <Col style={{ maxWidth: 151 }}>
            <p className="fs-name text-theme">Data connector</p>
          </Col>
          <Col style={{ maxWidth: 54 }}>
            <p className="fs-name text-theme">Pcs</p>
          </Col>
          <Col style={{ maxWidth: 90 }}>
            <p className="fs-name text-theme">Price (SEK)</p>
          </Col>
          <Col style={{ maxWidth: 90 }}>
            <p className="fs-name text-theme text-right">Period</p>
          </Col>
          <Col style={{ maxWidth: 140 }}>
            <p className="fs-name text-theme text-right">% discount</p>
          </Col>
          <Col style={{ maxWidth: 100 }}>
            <p className="fs-name text-theme text-right">From date</p>
          </Col>
          <Col style={{ maxWidth: 130 }}>
            <p className="fs-name text-theme text-right">To date</p>
          </Col>
        </Row> */}
        {React.Children.toArray(
          billingColumns.map((item, index) => {
            return (
              <Row className="align-items-baseline my-2" style={{ display: 'none' }}>
                <Col style={{ maxWidth: 151 }}>
                  <h2 className="fs-text-700 theme-text text-right">{item.title}</h2>
                </Col>
                <Col style={{ maxWidth: 80 }}>
                  <p className="fs-text-700 text-theme text-right">1000</p>
                  {/* <Form.Control type="number" disabled defaultValue={1002} /> */}
                </Col>
                <Col style={{ maxWidth: 151 }}>
                  <Form.Control type="text" disabled defaultValue={item.title} />
                </Col>
                <Col style={{ maxWidth: 54 }}>
                  <p className="fs-text-400 text-theme text-right">20</p>
                  {/* <Form.Control type="number" disabled defaultValue={1002} /> */}
                </Col>
                <Col style={{ maxWidth: 120 }}>
                  <Form.Control type="number" defaultValue={200} />
                </Col>
                <Col style={{ maxWidth: 120 }}>
                  <Form.Select>
                    <option>{t('monthly')}</option>
                    <option>{t('quaterly')}</option>
                    <option>{t('yearly')}</option>
                  </Form.Select>
                </Col>
                <Col style={{ borderLeft: '1px solid #5F5F5F', maxWidth: 100 }}>
                  <Form.Control disabled={editMode} type="number" defaultValue={200} />
                </Col>
                <Col style={{ maxWidth: 150 }}>
                  <Form.Control disabled={editMode} type="date" />
                </Col>
                <Col style={{ maxWidth: 150 }}>
                  <Form.Control disabled={editMode} type="date" />
                </Col>
                {editMode && (
                  <Col style={{ float: 'right', maxWidth: 20 }}>
                    <TooltipBox msg={`${t('delete')}`}>
                      <Trash2
                        onClick={() => {
                          const data = [...billingColumns];
                          data.splice(index, 1);
                          setBillingColumns(data);
                        }}
                        className="cursor-pointer color-danger"
                      />
                    </TooltipBox>
                  </Col>
                )}
              </Row>
            );
          }),
        )}
      </div>
      {editMode && (
        <div className="d-flex justify-content-between mt-2">
          <Button
            onClick={() => {
              const data = [...billingColumns];
              data.push({ title: '' });
              setBillingColumns(data);
            }}
            className="app-btn-secondary"
          >
            <Plus className="icon-gap-right" /> {t('add_new_product')}
          </Button>
          <div>
            <Button onClick={() => setEditMode(false)} className="mx-2 app-btn-secondary cancel-btn">
              <Slash className="icon-gap-right" /> {t('cancel_changes')}
            </Button>
            <Button className="app-btn-primary">
              <Save className="icon-gap-right" /> {t('save_product_template')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
