import { PensionPolicy } from '../../superAdmin/PensionPolicy/types';

export enum PensionPolicyType {
  abc,
}

export interface IPersonalPolicyActionModalProps {
  isEditAction: boolean;
}

export interface ISelectablePersonalPolicyOption {
  chosenAssignedPersonalPolicy: PensionPolicy;
}

export interface EmployeePensionOffset {
  year: number;
  offsets: { month: number; pensionOffset: number | null; salaryChange: number | null }[];
}

export enum PensionTabKey {
  CURRENT = 'currentPolicy',
  SCHEDULE = 'schedulePolicy',
  ARCHIVE = 'archivedPolicy',
}

export interface BaseAttachedEmployeePolicy {
  id: string;
  type: string;
  attachedAt: string;
}

export type EmployeeActionLoading = {
  cancelSchedule: boolean;
  loadEmployeePension: boolean;
  loadAchivedPension: boolean;
};
