import useContext from '../PensionPolicyContext/useContext';
import { ActionMode, PensionPolicyMode } from '../types';

const usePolicyMode = () => {
  const { pensionPolicyMode, actionMode } = useContext();

  const isAdminPolicyMode = () => {
    return [PensionPolicyMode.ADMIN_POLICY, PensionPolicyMode.COMPANY_POLICY].indexOf(pensionPolicyMode) !== -1;
  };

  const isSuperAdminPolicyMode = () => {
    return PensionPolicyMode.ADMIN_POLICY === pensionPolicyMode;
  };

  const isCompanyPolicyMode = () => {
    return PensionPolicyMode.COMPANY_POLICY === pensionPolicyMode;
  };

  const isCompanyEmployeePolicyMode = () => {
    return PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY === pensionPolicyMode;
  };

  const isCompanyEmployeeReadOnlyMode = () => {
    return (
      PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY === pensionPolicyMode &&
      (actionMode === ActionMode.VIEW || actionMode === ActionMode.SCHEDULED || actionMode === ActionMode.ARCHIVED)
    );
  };

  const isAdminReadOnlyMode = () => {
    return isAdminPolicyMode() && actionMode === ActionMode.VIEW;
  };

  const isCompanyReadOnlyMode = () => {
    return PensionPolicyMode.COMPANY_POLICY === pensionPolicyMode && actionMode === ActionMode.VIEW;
  };

  const isCompanyEmployeeEditableMode = () => {
    return (
      PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY === pensionPolicyMode &&
      actionMode !== ActionMode.VIEW &&
      actionMode !== ActionMode.SCHEDULED &&
      actionMode !== ActionMode.ARCHIVED
    );
  };

  const isCompanyEmployeeArchiveMode = () => {
    return PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY === pensionPolicyMode && actionMode === ActionMode.ARCHIVED;
  };

  const isCompanyEmployeeScheduledMode = () => {
    return PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY === pensionPolicyMode && actionMode === ActionMode.SCHEDULED;
  };

  const isPersonalPolicyMode = () => {
    return PensionPolicyMode.PERSONAL_POLICY === pensionPolicyMode;
  };

  const hasCompanyOnlyMode = () => {
    return isCompanyPolicyMode() || isCompanyEmployeePolicyMode();
  };

  const hasCompanyWithPersonalPolicyMode = () => {
    return isCompanyPolicyMode() || isPersonalPolicyMode();
  };

  const hasAdminPolicyMode = () => {
    return isCompanyPolicyMode() || isSuperAdminPolicyMode() || isCompanyEmployeePolicyMode();
  };

  return {
    isAdminPolicyMode,
    isSuperAdminPolicyMode,
    isCompanyPolicyMode,
    isCompanyEmployeePolicyMode,
    isPersonalPolicyMode,
    hasCompanyOnlyMode,
    hasCompanyWithPersonalPolicyMode,
    hasAdminPolicyMode,
    isCompanyEmployeeReadOnlyMode,
    isCompanyEmployeeEditableMode,
    isCompanyEmployeeArchiveMode,
    isCompanyEmployeeScheduledMode,
    isCompanyReadOnlyMode,
    isAdminReadOnlyMode,
  };
};

export default usePolicyMode;
