import { useEffect, useRef, useState } from 'react';
import { Row, Spinner } from 'react-bootstrap';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getCompanyClients } from '../../../api/service/superAdmin/company-clients/service';
import { GetCompanyClientType } from '../../../api/service/superAdmin/company-clients/types';
import CardComponent from '../../../components/base/CardComponent';
import BSRemoteTable from '../../../components/table/BSRemoteTable';
import Pagination from '../../company/Pagination';
import { useColumn } from './useColumn';
import { OverlayLoaderProvider } from '../../../contexts/OverlayLoaderContext';

type Props = {
  isCompanyCreated: boolean;
};

export default function CompanyClientsGrid({ isCompanyCreated }: Props) {
  const navigate = useNavigate();
  const { companyClientColumn } = useColumn();
  const [isLoading, setIsLoading] = useState(true);
  const [companyData, setCompanyData] = useState<GetCompanyClientType | null>(null);
  const { t } = useTranslation();
  const searchRef = useRef<any>();
  const totalPages = companyData?.metaData
    ? Math.ceil(companyData?.metaData.total / companyData?.metaData?.page.size)
    : 0;

  const onPagination = async ({ page, sizePerPage }) => {
    await fetchCompanyClients({ page, sizePerPage });
  };

  const onSearch = async () => {
    const searchTerm = searchRef.current['value'];
    fetchCompanyClients({ searchString: searchTerm });
  };

  const fetchCompanyClients = async ({
    searchString = '',
    page = companyData?.metaData.page.current,
    sizePerPage = companyData?.metaData.page.size,
    sortField = 'name',
    sortOrder = '1',
  }) => {
    const clients = await getCompanyClients({ searchString, page, sizePerPage, sortField, sortOrder });
    setCompanyData(clients);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isCompanyCreated) {
      fetchCompanyClients({});
    }
  }, [isCompanyCreated]);

  useEffect(() => {
    fetchCompanyClients({});
  }, []);

  return (
    <>
      <CardComponent>
        <OverlayLoaderProvider>
          <Row className="justify-content-between">
            <div className="cr-form-row w-auto">
              <div className="search-field">
                <div className="input-group">
                  <input
                    ref={searchRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSearch();
                      }
                    }}
                    placeholder={`${t('search')}...`}
                    className="form-control"
                    style={{ borderRight: 'none' }}
                  />
                  <span className="input-group-append bg-white border-left-0">
                    <span className="input-group-text ">
                      <Search className="cursor-pointer" onClick={onSearch} width={16} height={30} />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <BSRemoteTable
                data={companyData?.data ?? []}
                columns={companyClientColumn}
                noDataIndication={`${t('no_clients_found')}`}
                sizePerPage={companyData?.metaData['size']}
                onSizePerPageChange={(sizePerPage, page) => onPagination({ sizePerPage, page })}
                keyField="id"
                onRowClick={(row) => {
                  navigate(row.id);
                }}
                onTableChange={(type, { sortOrder, sortField }) => {
                  if (type === 'sort') {
                    fetchCompanyClients({ sortField, sortOrder: sortOrder === 'asc' ? '1' : '-1' });
                  }
                }}
              />
            )}
          </Row>
          {totalPages > 1 && (
            <Pagination
              total={companyData?.metaData.total ?? 0}
              itemsPerPage={companyData?.metaData.page.size ?? 0}
              currentPage={companyData?.metaData.page.current ?? 0}
              onPageChange={(page) => onPagination({ page, sizePerPage: companyData?.metaData.page.size })}
            />
          )}
        </OverlayLoaderProvider>
      </CardComponent>
    </>
  );
}
