import { Form } from 'react-bootstrap';
import useContext from './context/useContext';
import CardComponent from '../../../../components/base/CardComponent';
import { useSuperAdminSettingsContext } from '../useContext';
import CompanyInformation from './AdminPensionCompaniesForm/CompanyInformation';
import Connection from './AdminPensionCompaniesForm/Connection';
import { PensionManagerPlan } from './AdminPensionCompaniesForm/PensionManagerPlan';
import { Mode } from '../types';

export default function EditPensionCompany() {
  const context = useContext();
  const { mode } = useSuperAdminSettingsContext();
  const { updatePensionCompany, formGroup, pensionCompany, contactInformation } = context;
  const onUpdate = () => {
    if (updatePensionCompany) updatePensionCompany(formGroup, { ...pensionCompany, contactInformation });
  };
  const isEditable = [Mode.Edit, Mode.Detail].indexOf(mode) !== -1;
  const bodyContainer = (
    <Form>
      <CompanyInformation context={context} mode={mode} OnupdatePensionCompany={onUpdate} />
      <Connection context={context} mode={mode} OnupdatePensionCompany={onUpdate} />
      <PensionManagerPlan context={context} mode={mode} OnupdatePensionCompany={onUpdate} />
    </Form>
  );

  return <>{isEditable ? <CardComponent>{bodyContainer}</CardComponent> : <>{bodyContainer}</>}</>;
}
