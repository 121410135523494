import { MouseEvent, useEffect, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { reInitializeSessionWithCompany, getConsultantDetailsByPersonalNumber } from '../../api/service/auth/service';
import { Company, UserCompanyRole } from '../../api/service/auth/types';
import { AuthUser } from '../../types/auth';
import { ReactComponent as CompanyIcon } from '../../assets/img/icons/company-2.svg';

interface InputProps {
  user: AuthUser;
}

export default function CompanySwitcher({ user }: InputProps) {
  const [companyRoles, setCompanyRoles] = useState<UserCompanyRole[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const fetchUserDetails = async () => {
    try {
      // const userDetails = await getUserDetailsByPersonalNumber(user!.personalNumber);
      const userDetails = await getConsultantDetailsByPersonalNumber(user!.personalNumber);
      setCompanyRoles(userDetails.roles);
      // setCompanyRoles(
      //   userDetails.roles.filter((item) => {
      //     if (item.company.id === user!.role.company.id) {
      //       return false;
      //     }

      //     const hasCompanyConsultant =
      //       item!.role === UserRole.COMPANY_ADMIN && item!.subRole === UserSubRole.SENIOR_CONSULTANT;

      //     /* const hasAccountingFirmConsultant =
      //       item!.role === UserRole.ACCOUNTING_FIRM && item!.subRole === UserSubRole.SUPERVISOR; */

      //     return hasCompanyConsultant;
      //   }),
      // );
    } catch (e) {
      console.error(e);
    }
  };

  const selectCompany = async (event: MouseEvent<HTMLElement>, company: Company, companyRole: UserCompanyRole) => {
    try {
      event.preventDefault();

      const personalNumber = user!.personalNumber;
      setLoading(true);

      const session = await reInitializeSessionWithCompany({
        personalNumber: personalNumber,
        companyId: company.id,
        subRole: companyRole?.subRole,
      });

      window.location.href = session.redirectTo;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  if (companyRoles.length < 1) {
    return <></>;
  }

  return (
    <Dropdown className="nav-item dropdown--company-switcher">
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <span className="theme-text">
            {isLoading ? (
              <Spinner size="sm" />
            ) : (
              <CompanyIcon className="icon-gap-right feather align-middle image-filter" />
            )}
            <span className="title-text">{user!.role.company.name}</span>
          </span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        {companyRoles.map((companyRole, idx) => {
          return (
            <Dropdown.Item
              key={`${companyRole.company.id}${idx}`}
              onClick={(e) => selectCompany(e, companyRole.company, companyRole)}
            >
              {companyRole.company.name}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
