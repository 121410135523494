import {
  API_VERSION,
  COMPANY_CLIENTS_ENDPOINT,
  LOGS_ENDPOINT,
  MOCK_COMPANY_NAME_ENDPOINT,
  MOCK_USER_ENDPOINT,
} from '../../../../util/constants';

import { del, get, post, put } from '../../../../util/httpService';
import { CompanyClient, GetCompanyClientType, ICompanyClientLogsParams } from './types';
import { HttpQuery } from '../../../../util/http-query';

const getLogsFilterParams = (param: ICompanyClientLogsParams): ICompanyClientLogsParams => {
  const defaultParam = {
    page: 1,
    pageSize: 10,
    filter: {
      userId: '',
      action: '',
      level: '',
      from: '',
      to: '',
    },
    sort: { action: 1, fullName: 1, role: 1, dateTime: 1 },
  };
  return Object.assign({}, defaultParam, param);
};
const getCompanyClients = async ({
  page = 1,
  sizePerPage = 25,
  searchString = '',
  sortField = 'name',
  sortOrder = '1',
}) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}?page=${page}&pageSize=${sizePerPage}&filter[name][like]=${searchString}&sort[${sortField}]=${sortOrder}`;
  const company: GetCompanyClientType = await get(endpoint);
  return company;
};

const postNewCompanyClient = async (body: CompanyClient, params: Record<string, boolean>) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}?draft=${params['draft']}&sendEmails=${params['sendEmails']}`;
  const company = await post(endpoint, { ...body }, {});
  return company;
};

const updateCompanyClient = async (id: string | undefined, body: CompanyClient) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/${id}`;
  const company = await put(endpoint, { ...body }, {});
  return company;
};

const deleteCompanyClient = async (id: string) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/${id}`;
  const company = await del(endpoint, {});
  return company;
};

const getMockDetail = async ({ id, type }) => {
  const endpoint = `${API_VERSION}${MOCK_COMPANY_NAME_ENDPOINT}/${id}?type=${type}`;

  const data = await get(endpoint);

  return data;
};

const getMockUserName = async () => {
  const endpoint = `${API_VERSION}${MOCK_USER_ENDPOINT}`;

  const { fullName } = await get(endpoint);

  return fullName;
};

const getCompanyClientDetails = async (id) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/${id}`;
  const company: CompanyClient = await get(endpoint);
  return company;
};

const getCompanyClientLogDetails = async (companyClientId: any, filter: ICompanyClientLogsParams) => {
  let endpoint = HttpQuery.serialize(getLogsFilterParams(filter), `${API_VERSION}${LOGS_ENDPOINT}/${companyClientId}`);
  const logs = await get(endpoint);
  return logs;
};

const getCompanyClientLogCategory = async (companyClientId) => {
  let endpoint = `${API_VERSION}${LOGS_ENDPOINT}/actions/${companyClientId}`;
  const categories = await get(endpoint);
  return categories;
};

const getCompanyContractNumber = async (): Promise<string> => {
  let endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/contract/number`;
  const categories = await get(endpoint);
  return categories;
};

const getRoaringCompanyInfo = async (companyId) => {
  let endpoint = `${API_VERSION}/roaring/companyInfo/${companyId}`;
  const companyInfo = await get(endpoint);
  return {
    ...companyInfo, address: companyInfo.address ?? {},
    authorizedSignatory: companyInfo.authorizedSignatory ?? []
  };
}
const getRoaringPersonInfo = async (personalNumber) => {
  let endpoint = `${API_VERSION}/roaring/personInfo/${personalNumber}`;
  const personInfo = await get(endpoint);
  return {
    ...personInfo, address: personInfo.address ?? {},
  };
}


const getCompanyClientLegalDocuments = async (id) => {
  let endpoint = `${API_VERSION}/verified/envelope/data/${id}`;
  const legalDocs = await get(endpoint);
  return legalDocs
};

export {
  getCompanyClients,
  postNewCompanyClient,
  getMockDetail,
  getMockUserName,
  getCompanyClientDetails,
  updateCompanyClient,
  deleteCompanyClient,
  getCompanyClientLogDetails,
  getCompanyClientLogCategory,
  getCompanyContractNumber,
  getRoaringCompanyInfo,
  getRoaringPersonInfo,
  getCompanyClientLegalDocuments,
};
