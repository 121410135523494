import React, { useEffect } from 'react';
import classNames from 'classnames';
import ChartSwitch from '../ChartSwitch';
import { useState } from 'react';
import { ISeries } from './types';
import { useTranslation } from 'react-i18next';

interface ChartSwitchProps {
  seriesCollection: ISeries[];
  series: ISeries[];
  initialChecked: string[];
  totalSeriesItem: ISeries;
  shouldToggleTotal: boolean;
  allowDeleteSeries: boolean;
  translate?: boolean;
  removeSeriesHandler?: (series: ISeries) => void;
}

export const ChartSwitchFilter = React.forwardRef(
  (
    {
      seriesCollection,
      series,
      initialChecked,
      totalSeriesItem,
      shouldToggleTotal,
      allowDeleteSeries,
      removeSeriesHandler,
      translate = false,
    }: ChartSwitchProps,
    ref: any,
  ) => {
    const [choosenSeries, setChoosenSeries] = useState<string[]>([]);
    const { t } = useTranslation();
    const handleSeriesToggle = (e, selectedItem: ISeries) => {
      const selectedChart = (ref!.current! as any).chart;
      const value = selectedItem.seriesIndex!;
      const field = selectedItem.field;
      const selectedSeriesCollectionItem = seriesCollection.find((item) => item.field === field)!;

      const checked = e.target.checked;
      const isIncluded = !choosenSeries.includes(field);

      if (shouldToggleTotal) {
        if (isIncluded && field === totalSeriesItem.field) {
          setChoosenSeries([totalSeriesItem.field]);

          series
            .filter((seriesItem) => seriesItem.value !== totalSeriesItem.value)
            .forEach((seriesItem) => {
              selectedChart.series[seriesItem.value!].setVisible(false);
              seriesCollection[seriesItem.value!].checked = false;
            });

          selectedChart.series[value].setVisible(true);
          selectedSeriesCollectionItem.checked = true;

          return;
        } else if (!isIncluded && field === totalSeriesItem.field) {
          setChoosenSeries(initialChecked);

          series
            .filter((seriesItem) => seriesItem.value !== totalSeriesItem.value)
            .forEach((seriesItem) => {
              selectedChart.series[seriesItem.value!].setVisible(true);
              seriesCollection[seriesItem.value!].checked = true;
            });

          selectedChart.series[value].setVisible(false);
          selectedSeriesCollectionItem.checked = false;
          return;
        }
      }

      setChoosenSeries((prevChoosenPerson) =>
        prevChoosenPerson.includes(field)
          ? [...prevChoosenPerson.filter((p) => p !== field)]
          : [...prevChoosenPerson, field],
      );
      selectedSeriesCollectionItem.checked = checked;
      selectedChart.series[value].setVisible(isIncluded);
    };

    const onRemove = (val: ISeries, index) => {
      /* const selectedChart = (ref!.current! as any).chart as any;

      selectedChart.series[index].remove(); */

      if (removeSeriesHandler) {
        removeSeriesHandler(val);
      }
    };

    const getSwitchLabel = (label, key) => {
      if (translate) {
        return key ? t(key) : label;
      } else {
        return label;
      }
    };

    useEffect(() => {
      setChoosenSeries(initialChecked);
    }, [initialChecked]);
    return (
      <div className="d-flex justify-content-between align-items-end">
        <div>
          {seriesCollection?.map((series, index) => (
            <ChartSwitch
              label={getSwitchLabel(series?.label, series?.labelKey)}
              switchColor={!series.checked ? '#d3d3d3' : series!.color!}
              onSwitch={(e) => handleSeriesToggle(e, series)}
              key={'series' + index}
              className={classNames('me-2', !series.checked ? 'form-switch-off' : '')}
              checked={series.checked!}
              showDeleteBtn={allowDeleteSeries && series.field !== 'total'}
              onDeleteHandler={() => onRemove(series, index)}
            />
          ))}
        </div>
      </div>
    );
  },
);
ChartSwitchFilter.displayName = 'ChartSwitchFilter';

export default React.memo(ChartSwitchFilter);
