import React, { createContext, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useFormik } from 'formik';
import {
  IPensionCompaniesContext,
  IPensionManagerFormGroup,
  PensionCompaniesFormGroup,
  TypeConnection,
  TypeContactInformation,
  TypePensionCompany,
  TypePensionManagerPlan,
  TypePlans,
} from '../../types';
import {
  FORM_INITIAL_VALUES,
  INITIAL_CONTACT_INFORMATION,
  INITIAL_PENSION_COMPANY,
  INITIAL_CONNECTION,
  INITIAL_PENSION_MANAGER_PLANS,
  DEFAULT_PLANS,
} from '../constants';
import { formSchema } from './schema';
import { excludeKeys, generateUniqueId, includeKeys, isObjectEmpty } from '../../../../../util/util';

type Props = {
  children: JSX.Element;
  patchPensionManagerForm?: Function;
  emitValues: (...args) => void;
};

export const PensionCompaniesContext = createContext<IPensionCompaniesContext>({
  pensionCompany: INITIAL_PENSION_COMPANY,
  pensionCompanies: [],
  contactInformation: INITIAL_CONTACT_INFORMATION,
  connection: INITIAL_CONNECTION,
  pensionManagerPlans: INITIAL_PENSION_MANAGER_PLANS,
  isBusy: false,
  formGroup: {} as PensionCompaniesFormGroup,
  selectedPensionID: null,
  selectedPensionManagerDetails: null,
  setSelectedPensionID: () => {},
  setPensionCompany: () => {},
  setContactInformation: () => {},
  setConnection: () => {},
  setPensionManagerPlans: () => {},
  setSelectedPensionManagerDetails: () => {},
  replacePensionCompanies: () => {},
  updateContactInformation: () => {},
  replacePensionCompany: () => {},
  replaceContactInformation: () => {},
  updatePensionCompany: () => {},
  replaceConnection: () => {},
  updateConnection: () => {},
  replacePensionManagerPlan: () => {},
  updatePensionManagerPlan: () => {},
  replacePensionManager: () => {},
  replacePensionManagerDocuments: () => {},
  validateDraftForm: () => new Promise(() => ({ valid: false, errors: null })),
  validateForm: () => new Promise(() => {}),
  getRequestPayload: (_additionalProps: any) => {},
  addPlan: (defaultVal?: Partial<TypePlans> | null) => {},
  initializeDefaultPlans: () => {},
});

export const PensionCompaniesProvider = React.forwardRef(
  ({ children, patchPensionManagerForm, emitValues }: Props, _ref: any) => {
    const [selectedPensionID, setSelectedPensionID] = useState<string | null>(null);
    const [selectedPensionManagerDetails, setSelectedPensionManagerDetails] = useState<any>(null);
    const [pensionCompany, setPensionCompany] = useState<TypePensionCompany>(INITIAL_PENSION_COMPANY);
    const [pensionCompanies, setPensionCompanies] = useState<Array<TypePensionCompany>>([]);
    const [contactInformation, setContactInformation] = useState<TypeContactInformation>(INITIAL_CONTACT_INFORMATION);
    const [connection, setConnection] = useState<TypeConnection>(INITIAL_CONNECTION);
    const [pensionManagerPlans, setPensionManagerPlans] =
      useState<TypePensionManagerPlan>(INITIAL_PENSION_MANAGER_PLANS);
    const [isBusy /*setIsBusy*/] = useState(false);

    const formGroup = useFormik({
      initialValues: FORM_INITIAL_VALUES,
      validateOnMount: true,
      validationSchema: () => formSchema,
      onSubmit: (val) => {},
    });

    const replacePensionCompanies = (value) => {
      setPensionCompanies(value);
      formGroup.setFieldValue('pensionCompanies', value);
    };

    const replacePensionCompany = (value) => {
      setPensionCompany(value);
      formGroup.setFieldValue('pensionCompany', value);
    };

    const updateContactInformation = (inputName, inputValue) => {
      setContactInformation((value) => ({
        ...value,
        [inputName]: inputValue,
      }));
      formGroup.setFieldValue(`contactInformation.${inputName}`, inputValue);
    };

    const updatePensionCompany = (inputName, inputValue) => {
      setPensionCompany((value) => ({
        ...value,
        [inputName]: inputValue,
      }));

      formGroup.setFieldValue(`pensionCompany.${inputName}`, inputValue);
    };

    const replaceContactInformation = (value) => {
      setContactInformation(value);
      formGroup.setFieldValue('contactInformation', value);
    };

    const replaceConnection = (value) => {
      setConnection(value);
      formGroup.setFieldValue('connection', value);
    };

    const updateConnection = (inputName, inputValue) => {
      setConnection((value) => ({
        ...value,
        [inputName]: inputValue,
      }));
      formGroup.setFieldValue(`connection.${inputName}`, inputValue);
    };

    const replacePensionManagerPlan = (value) => {
      const managerPlan = formGroup.getFieldMeta('pensionManagerPlans').value;
      const _pensionManager = { ...managerPlan };
      _pensionManager.plans = value;
      setPensionManagerPlans(_pensionManager);
      formGroup.setFieldValue('pensionManagerPlans', _pensionManager);
    };

    const updatePensionManagerPlan = (inputName, inputValue) => {
      setPensionManagerPlans((value) => ({
        ...value,
        [inputName]: inputValue,
      }));
      formGroup.setFieldValue(`pensionManagerPlans.${inputName}`, inputValue);
    };

    const replacePensionManager = (value) => {
      setPensionManagerPlans(value);
      formGroup.setFieldValue('pensionManagerPlans', value);
    };

    const replacePensionManagerDocuments = (value, index) => {
      const managerDocument = formGroup.getFieldMeta('pensionManagerPlans').value;
      const _document = { ...managerDocument };
      _document.plans[index].documents = value;
      formGroup.setFieldValue(`pensionManagerPlans`, _document);
    };

    const validateDraftForm = async (formCtx: IPensionManagerFormGroup | null = null) => {
      const formInstance = formCtx || formGroup;

      formInstance.setSubmitting(false);
      formInstance.setErrors({});
      formInstance.handleReset(null);

      await formInstance.setFieldTouched('pensionCompany.shortName', true, true);
      await formInstance.setFieldTouched('pensionCompany.companyName', true, true);

      const draftErrors = includeKeys(formInstance.errors, ['pensionCompany.shortName', 'pensionCompany.companyName']);

      return isObjectEmpty(draftErrors) ? { valid: true, errors: null } : { valid: false, errors: draftErrors };
    };

    const validateForm = () => {
      formGroup.handleSubmit();

      return formGroup.validateForm();
    };

    const getRequestPayload = useCallback(
      (additionalProps = {}, formCtx: IPensionManagerFormGroup | null = null) => {
        const formInstance = formCtx || formGroup;

        const { pensionCompany, pensionManagerPlans, connection, ...formData } = formInstance.values;
        const data = {
          ...pensionCompany,
          pensionManagerPlans: {
            ...pensionManagerPlans,
            plans: (pensionManagerPlans.plans || []).map((item) => excludeKeys(item, ['id'])),
          },
          connection: {
            ...connection,
            connectionTypeFile: Object.assign(
              {},
              {
                ...(connection?.connectionTypeFile || {}),
              },
              !connection?.connectionTypeFile?.email ? { email: undefined } : connection?.connectionTypeFile,
            ),
          },
          ...formData,
          ...additionalProps,
        };

        return data;
      },
      [formGroup.values],
    );

    const addPlan = (defaultValue: Partial<TypePlans> | Partial<TypePlans>[] | null = null) => {
      const plans: TypePlans[] = formGroup.getFieldMeta('pensionManagerPlans.plans').value;
      const lastOrder = plans?.[plans.length - 1]?.order || plans.length;

      const defaultPlan: Omit<TypePlans, 'order'> = {
        id: generateUniqueId(),
        planName: '',
        providerDescription: '',
        documents: [],
        costSpecification: {
          specItems: [],
          description: '',
        },
      };

      if (Array.isArray(defaultValue)) {
        const defaultPlans: TypePlans[] = defaultValue.map((item, index) => {
          return {
            ...defaultPlan,
            ...item,
            order: lastOrder + index + 1,
            id: generateUniqueId(),
          };
        });

        replacePensionManagerPlan([...plans, ...defaultPlans]);
      } else {
        const plan: TypePlans = {
          ...defaultPlan,
          order: lastOrder + 1,
          ...defaultValue,
        };

        replacePensionManagerPlan([...plans, plan]);
      }
    };

    const initializeDefaultPlans = () => {
      addPlan(DEFAULT_PLANS);
    };

    const context: IPensionCompaniesContext = {
      pensionCompany,
      setPensionCompany,
      pensionCompanies,
      contactInformation,
      setContactInformation,
      connection,
      setConnection,
      pensionManagerPlans,
      setPensionManagerPlans,
      isBusy,
      formGroup: formGroup as any,
      selectedPensionID,
      setSelectedPensionID,
      replacePensionCompanies,
      updatePensionCompany,
      updateContactInformation,
      updateConnection,
      updatePensionManagerPlan,
      replacePensionCompany,
      replaceContactInformation,
      replaceConnection,
      replacePensionManagerPlan,
      replacePensionManager,
      replacePensionManagerDocuments,
      validateDraftForm,
      validateForm,
      getRequestPayload,
      addPlan,
      initializeDefaultPlans,
      selectedPensionManagerDetails,
      setSelectedPensionManagerDetails,
    };

    useImperativeHandle(
      _ref,
      () => {
        return {
          formGroup: formGroup,
          selectedPensionID,
          validateDraftForm,
          getRequestPayload,
        };
      },
      [formGroup.values],
    );

    useEffect(() => {
      patchPensionManagerForm!(formGroup);
    }, [formGroup.values, formGroup.errors]);

    useEffect(() => {
      emitValues({
        formGroup,
        selectedPensionID,
        getRequestPayload,
      });
    }, [formGroup.values, selectedPensionID, getRequestPayload]);

    return <PensionCompaniesContext.Provider value={context}>{children}</PensionCompaniesContext.Provider>;
  },
);

PensionCompaniesProvider.displayName = 'PensionCompaniesProvider';
