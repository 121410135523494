import ErrorFeedback from '../../../../../components/ErrorFeedback';
import PagesHeader from '../../../../../components/base/PagesHeader';
import { TextEditor } from '../../../../../components/form/inputs/TextEditor';
import useLocale from '../../../../../hooks/useLocale';
import { errorHighlighter } from '../../../../../util/form';
import { IntegrationFormProps, Mode } from '../../types';
import { Form, Row } from 'react-bootstrap';
import classNames from 'classnames';

const IntegrationInformation = ({ context, mode }: IntegrationFormProps) => {
  const { localize } = useLocale();
  const { formGroup, updateIntegrationInformation } = context;
  const isEditable = [Mode.Edit, Mode.Detail].indexOf(mode) !== -1;

  const handleIntegrationInfo = (e) => {
    updateIntegrationInformation(e.target.name, e.target.value);
  };

  const handleTextEditor = (value) => {
    updateIntegrationInformation('instruction', value);
  };
  return (
    <>
      <PagesHeader headingClass="fs-h3" pageTitle="Integration Information" />
      <Row className="align-items-end mb-4">
        <div style={{ width: 219 }}>
          <Form.Label className="fs-name  required">{localize('short_name')}</Form.Label>
          <Form.Control
            name="shortName"
            onChange={(e) => handleIntegrationInfo(e)}
            value={formGroup.values.integrationInformation?.shortName}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('integrationInformation.shortName')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('integrationInformation.shortName')} />
        </div>
        <div style={{ width: 360 }}>
          <Form.Label className="fs-name">{localize('company_name')}</Form.Label>
          <Form.Control
            name="companyName"
            onChange={(e) => handleIntegrationInfo(e)}
            value={formGroup.values.integrationInformation?.companyName}
            disabled={mode === Mode.Detail}
          />
        </div>
        {isEditable && (
          <div style={{ width: 135 }}>
            <Form.Label className="fs-name">{localize('status')}</Form.Label>
            <Form.Select
              onChange={(e) => handleIntegrationInfo(e)}
              value={formGroup.values.integrationInformation?.status}
              disabled={mode === Mode.Detail}
              name="status"
            >
              <option disabled value="">
                {localize('please_select')}
              </option>
              <option value="Active">{localize('active')}</option>
              <option value="InActive">{localize('inactive')}</option>
              <option value="Draft">{localize('draft')}</option>
            </Form.Select>
          </div>
        )}
      </Row>
      <Row className="align-items-end mb-4">
        <div className="integration-instruction-textbox">
          <Form.Label className="fs-name">{localize('instruction')}</Form.Label>
          {mode !== Mode.Detail ? (
            <TextEditor
              onChangeHandler={(value) => handleTextEditor(value)}
              defaultValue={formGroup.values.integrationInformation?.instruction || ''}
            />
          ) : (
            <div className="row">
              <div className="col-12">
                <div
                  className="p-3 instruction-text--disabled"
                  dangerouslySetInnerHTML={{ __html: formGroup.values.integrationInformation['instruction'] }}
                />
              </div>
            </div>
          )}
        </div>
      </Row>
    </>
  );
};

export default IntegrationInformation;
