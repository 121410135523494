import { API_VERSION, ACCOUNTING_SALARY_COMPANY_ENDPOINT } from '../../../../util/constants';
import { del, get, post, put } from '../../../../util/httpService';

export const getAccountingSalaryCompanies = async () => {
    const endpoint = `${API_VERSION}${ACCOUNTING_SALARY_COMPANY_ENDPOINT}`;
    const accountingSalaryCompanies = await get(endpoint);
    return accountingSalaryCompanies
};

export const createAccountingSalaryCompany = async (payload) => {
    const accountingCompany = await post(`${API_VERSION}${ACCOUNTING_SALARY_COMPANY_ENDPOINT}`, payload, {});
    return accountingCompany;
};

export const updatePAccountingSalaryCompany = async (id, payload) => {
    const accountingCompany = await put(`${API_VERSION}${ACCOUNTING_SALARY_COMPANY_ENDPOINT}/${id}`, payload, {});
    return accountingCompany;
};

export const deleteAccountingSalaryCompany = async (id) => {
    const accountingCompany = await del(`${API_VERSION}${ACCOUNTING_SALARY_COMPANY_ENDPOINT}/${id}`, {});
    return accountingCompany;
};
export const getAccountingSalaryCompanyDetail = async (id) => {
    const accountingCompany = await get(`${API_VERSION}${ACCOUNTING_SALARY_COMPANY_ENDPOINT}/${id}`);
    if (accountingCompany.contactInformation) {
        return accountingCompany
    }
    else {
        return { ...accountingCompany, contactInformation: {} }
    }
};
