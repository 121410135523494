import { deepClone, deepMerge } from '../../../util/util';
import { defaultChartOption, pieChartDarkModeOption, pieChartLightModeOption } from './piechart-config';
import { ISeries, ISeriesMetaOptions, ISeriesOptions } from './types';


export class PieChartAdapter {

    static colorStyle = {
        seriesColors: [
            { defaultColor: '#01b8aa', darkColor: '#01b8aa' },
            { defaultColor: '#fd625e', darkColor: '#fd625e' },
            { defaultColor: '#26a4eb', darkColor: '#26a4eb' },
            { defaultColor: '#ddb60a', darkColor: '#ddb60a' },
            { defaultColor: '#f160e3', darkColor: '#f160e3' },
            { defaultColor: '#ff7f0e', darkColor: '#ff7f0e' },
            { defaultColor: '#2ca02c', darkColor: '#2ca02c' },
            { defaultColor: '#d62728', darkColor: '#d62728' },
            { defaultColor: '#9467bd', darkColor: '#9467bd' },
            { defaultColor: '#8c564b', darkColor: '#8c564b' },
            { defaultColor: '#800000', darkColor: '#FF8C00' },
        ],
        preset: {
            defaultColor: '#800000',
            darkColor: '#FF8C00',
        },
    };

    static load(opt: ISeriesOptions, data: any): SeriesOptionBuilder {
        const { series: selectedSeries, sourceSeries } = this.getSeries(opt.series, opt);
        opt.series = selectedSeries;
        const formattedData = [{
            size: '79%',
            innerSize: '70%',
            name: 'Company Client size',
            data: data?.dataset.map((value, index) => {
                return {
                    y: Number(value.count),
                    name: value.label,
                    color: selectedSeries[index]?.color,
                    dataLabels: {
                        distance: 6
                    }
                }
            })
        }]
        return new SeriesOptionBuilder({ ...opt, data: formattedData ? deepClone(formattedData) : [], sourceSeries });
    }

    private static getSeries(series: ISeries[], opt: ISeriesOptions) {
        const styledSeries = this.applyStyles(series, opt);
        const transformedSeries = styledSeries.map((item, index) => {
            if (item.value) {
                item.seriesIndex = index;

                return item;
            }

            if (typeof item.checked !== 'boolean') {
                item.checked = true;
            }
            item.value = (index).toString();
            item.seriesIndex = index;

            return item;
        });
        const sourceSeries = deepClone(transformedSeries);

        return {
            sourceSeries: sourceSeries,
            series: transformedSeries,
        };
    }

    private static applyStyles(series: ISeries[], opt: ISeriesOptions) {
        const usedColors = series.map((item) => item.style);
        const availableColors = this.colorStyle.seriesColors.filter((item) => {
            return !usedColors.find((usedItem) => usedItem?.defaultColor === item.defaultColor);
        });

        return series.map((item, index) => {
            if (item.value) {
                return item;
            }

            const colorStyle = availableColors[0] ?? this.colorStyle.preset;

            item.style = { ...(item.style || {}), ...colorStyle };
            item.color = item.style.defaultColor;

            availableColors.shift();

            return item;
        });
    }
}

export class SeriesOptionBuilder {
    header: string[] = [];
    series: ISeries[] = [];
    sourceSeries: ISeries[] = [];
    data: any[] = [];
    initialChecked: string[] = [];
    lightModeConfig;
    darktModeConfig;
    formattedTableData: any[] = []
    tableData: any[] = []


    constructor(opt: ISeriesMetaOptions) {
        Object.assign(this, opt);
        this.setInitial();
        this.setHeader();
        this.setConfig();

    }

    setHeader() {
        this.data[0]?.data?.map((values, index) =>
            this.tableData.push([values?.name, values?.y])
        );
        this.header = ['Category', 'Company client size'];
        this.formattedTableData = [this.header, ...this.tableData]
    }

    setInitial() {
        this.initialChecked = this.series.filter((item) => item.checked).map((item) => item.field);
    }

    config(chartOptionSource) {
        return {
            ...chartOptionSource,
            series: this.series.map((item) => {
                return {
                    type: 'pie',
                    visilbe: true,
                    color: item.color,
                    size: '70%',
                    innerSize: '70.5%',
                    name: item.label,


                };
            }),
        };
    }

    setConfig() {
        this.lightModeConfig = deepMerge(
            {
                ...pieChartLightModeOption,
                series: this.series.map((item) => {
                    return {
                        type: 'pie',
                        visilbe: true,
                        color: item.color,
                        name: item.label,
                        size: '70%',
                        innerSize: '70.5%',
                        states: {
                            hover: {
                                borderWidth: 4,
                                halo: {
                                    enabled: true,
                                    size: 20,
                                    opacity: 1,
                                    attributes: {
                                        fill: item.color,
                                    }
                                }
                            }
                        },

                    };
                }),
            },
            defaultChartOption,
        );

        this.darktModeConfig = deepMerge(
            {
                ...pieChartDarkModeOption,
                series: this.series.map((item) => {
                    return {
                        type: 'pie',
                        visilbe: true,
                        color: item.color,
                        size: '70%',
                        name: item.label,
                        innerSize: '70.5%',

                    };
                }),
            },
            defaultChartOption,
        );
    }
}
