import { useEffect } from 'react';
import PensionPoliciesDetailsEditable from '../../../superAdmin/PensionPolicy/PensionPoliciesDetailsEditable';
import useContext from '../../../superAdmin/PensionPolicy/PensionPolicyContext/useContext';
import { ActionMode } from '../../../superAdmin/PensionPolicy/types';

function PersonalPolicy({ employee, policy, defaultSelectedPolicyOrigin }: any) {
  const { setPreAssignedPolicy, setSelectedPolicyDetails, setActionMode, patchSelectedPensionPolicy } = useContext();

  useEffect(() => {
    if (defaultSelectedPolicyOrigin) {
      setActionMode(ActionMode.EDIT);
      patchSelectedPensionPolicy(defaultSelectedPolicyOrigin);
      setPreAssignedPolicy(defaultSelectedPolicyOrigin);
      setSelectedPolicyDetails(defaultSelectedPolicyOrigin);
    }
  }, [defaultSelectedPolicyOrigin]);

  return (
    <>
      <PensionPoliciesDetailsEditable openedByModal={true} policy={defaultSelectedPolicyOrigin} />
    </>
  );
}

export default PersonalPolicy;
