import { Dispatch, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Save, Send } from 'react-feather';
import omit from 'lodash/omit';
import { useCreateClientContext } from '../context/useContext';
import { postNewClient } from '../../../../api/service/accountingFirm/clients/service';
import { SetStateAction } from 'react';
import { ClientFormGroup, ClientSaveMode, LooseObject } from '../types';
import { useTranslation } from 'react-i18next';
import { AccountingFirmClient } from '../../../../api/service/accountingFirm/clients/types';
import { isObjectEmpty, isValueEmpty } from '../../../../util/util';

type Props = {
  toggleModal: () => void;
  setIsClientCreated: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
  formGroup: ClientFormGroup;
};

const STRING_TYPE = {
  DRAFT: 'DRAFT',
  SUBMIT: 'SUBMIT',
};

export default function CreateClientModalFooter({ toggleModal, setIsClientCreated, setErrors, formGroup }: Props) {
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const {
    adminDetails,
    companyDetails,
    addressDetails,
    salaryContactPeople,
    accountingContactPeople,
    contractEffectiveFrom,
    invoiceEmailAddress,
    invoiceCostCenter,
    clientConsultants,
    newClientConsultants,
    invitationEmail,
    isEditMode,
    contractNumber,
    vismaAPIKey,
    combinations,
  } = useCreateClientContext();
  const modifiedAdminDetails = adminDetails
    .filter((item) => {
      return isObjectEmpty(item);
    })
    .map((i: any) => {
      return omit(i, ['_id', 'valid']);
    });
  const modifiedCombinations = combinations.map((i) => {
    return {
      signee: i.signee,
      authorizedSignatory: i.authorizedSignatory.map((signatory) => {
        const { email, ...details } = signatory;

        return email ? signatory : details;
      }),
    };
  });
  const modifiedClientConsultants = clientConsultants
    .filter((item, idx) => {
      return !isValueEmpty(item.personalNumber);
    })
    .map((i) => omit(i, ['_id', 'valid']));
  const modifiedNewClientConsulatants = newClientConsultants.map((i) => omit(i, ['_id', 'valid']));

  const handleSaveCompany = async (type: string, saveMode: ClientSaveMode) => {
    const data = Object.assign(
      {},
      {
        ...companyDetails,
        address: addressDetails,
        contractNumber: contractNumber,
        admins: modifiedAdminDetails,
        combinations: modifiedCombinations,
        contactPeopleForSalary: salaryContactPeople,
        contactPeopleForAccounting: accountingContactPeople,
        contractEffectiveFrom,
        invoiceEmailAddress: invoiceEmailAddress,
        invoicingCostCenter: invoiceCostCenter,
        consultants: [...modifiedClientConsultants, ...modifiedNewClientConsulatants],
        invitationEmail: invitationEmail,
        status: saveMode,
        allowAdvisorAccess: formGroup.values.allowAdvisorAccess,
        allowCorrectoAccess: formGroup.values.allowCorrectoAccess,
      } as AccountingFirmClient,
      isEditMode ? { vismaAPIKey } : null,
    );

    let params = {};

    if (type === STRING_TYPE.DRAFT) {
      setIsBusyDrafting(true);
      params = {
        draft: true,
        sendEmails: false,
      };
    } else {
      setIsBusy(true);
      params = {
        draft: false,
        sendEmails: true,
      };
    }
    try {
      formGroup.handleSubmit();
      formGroup.validateForm().then((errors) => {
        setErrors(errors);
      });
      if (!formGroup.isValid) {
        setIsBusyDrafting(false);
        setIsBusy(false);
        return false;
      }

      await postNewClient(data, params);

      toggleModal();
      setIsClientCreated(true);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
      setIsClientCreated(false);
    } finally {
      setIsBusy(false);
      setIsBusyDrafting(false);
    }
  };

  return (
    <div className="cr-form-row d-flex justify-content-between w-100">
      <Button className="app-btn-secondary cancel-btn" onClick={toggleModal}>
        {t('cancel')}
      </Button>

      <div className="cr-form-row mb-0 d-flex gap-3">
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary"
          onClick={() => {
            handleSaveCompany(STRING_TYPE.DRAFT, ClientSaveMode.DRAFT);
          }}
        >
          {isBusyDrafting ? <Spinner className="icon-gap-right" size="sm" /> : <Save className="icon-gap-right" />}
          {`${t('save_as_draft')}`}
        </Button>
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary"
          onClick={() => {
            handleSaveCompany(STRING_TYPE.SUBMIT, ClientSaveMode.SETUP);
          }}
        >
          {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <Send className="icon-gap-right" />}
          {`${t('save_clients_and_send_all_invites')}`}
        </Button>
      </div>
    </div>
  );
}
