import React, { useState } from 'react';

import useAuth from '../../hooks/useAuth';

import { getDetailsByPersonalNumber, initializeSession, invokeToken } from '../../api/service/auth/service';
import { SignInAction, SignInMethod, SignInType, UserRole } from '../../types/auth';
import { UserCompanyRole } from '../../api/service/auth/types';

interface Props {
  pageRoleType: UserRole;
}

const useAuthenticator = ({ pageRoleType }: Props) => {
  const { signIn } = useAuth();

  const [isLoading, SetIsLoading] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  const [companyOptions, setCompanyOptions] = useState<any>([]);
  const [accountingFirmOptions, setAccountingFirmOptions] = useState<any>([]);

  const [selectedRole, setSelectedRole] = useState({});
  const [selectedAccountingFirmRole, setSelectedAccountingFirmRole] = useState<unknown>(null);
  const [user, setUser] = useState({});

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const [sessionID, setSessionID] = useState<any>();
  const [signInAction, setSignInAction] = useState<SignInType | null>(null);

  const onInitSession = async (method: SignInMethod) => {
    setSignInAction(SignInAction[method]);
    SetIsLoading(true);
    setErrorMsg(null);
    try {
      const { sessionId, autoStartToken } = await initializeSession(SignInAction[method].device);

      if (SignInAction[method].method === 'desktop') {
        window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
      }

      setSessionID(sessionId);
    } catch (error) {
      if (error instanceof Error) {
        console.error('handleSigninMethodChange Error', error.message);
        setErrorMsg(error.message);
      }
    } finally {
      SetIsLoading(false);
    }
  };

  const fetchVerifiedUserDetails = async (personalNumber) => {
    try {
      const user = await getDetailsByPersonalNumber(
        personalNumber,
        pageRoleType === UserRole.SUPER_ADMIN ? 'ADMIN' : 'USER',
      );
      const _accountingFirmOptions: any[] = [];
      const _companyOptions: any[] = [];

      if (pageRoleType === UserRole.SUPER_ADMIN || pageRoleType === UserRole.EMPLOYEE || user['roles'].length === 1) {
        const rolesData = user['roles'];
        const signInRole = selectSignInRoleByUserType(rolesData);

        if (!signInRole) {
          throw new Error('Sorry no user role found');
        }

        const company = signInRole.role !== UserRole.ACCOUNTING_FIRM ? signInRole.company : null;
        const companyId = company ? company['id'] : null;
        signInByDefaultRole({
          personalNumber: user['personalNumber'],
          companyId,
          subRole: signInRole?.subRole,
          userLoginRole: signInRole.role,
        });
      } else {
        user.roles.forEach((item) => {
          if (item!.role === UserRole.ACCOUNTING_FIRM) {
            _accountingFirmOptions.push(item);
          }
          if (item!.role === UserRole.COMPANY_ADMIN) {
            _companyOptions.push(item);
          }
        });
        setUser(user);
        setAccountingFirmOptions(_accountingFirmOptions);
        setCompanyOptions(_companyOptions);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMsg(error.message);
      }
    } finally {
      SetIsLoading(false);
    }
  };

  const selectSignInRoleByUserType = (roles: UserCompanyRole[]) => {
    if (roles.length === 0) {
      return null;
    }

    if (roles.length === 1) {
      return roles[0];
    }

    if (pageRoleType === UserRole.EMPLOYEE) {
      const role = roles.find((item) => item.role === UserRole.EMPLOYEE);

      return role;
    } else {
      return roles[0];
    }
  };

  const handleSelectCompanyOrAccountingFirm = (e: React.ChangeEvent<HTMLSelectElement>, role: UserRole) => {
    const options = e.target.options;
    const companyName = e.target.value;
    const id = options[options.selectedIndex].getAttribute('id');

    if (role === UserRole.COMPANY_ADMIN) {
      const selectedCompany = companyOptions.find((item) => item?.company?.id === id);

      setSelectedRole({ id, name: companyName, subRole: selectedCompany?.subRole });
    } else if (role === UserRole.ACCOUNTING_FIRM) {
      const selectedCompany = accountingFirmOptions.find((item) => item?.company?.id === id);

      setSelectedAccountingFirmRole({ id, name: companyName, subRole: selectedCompany?.subRole });
    }
  };

  const signInByDefaultRole = async ({ personalNumber = null, companyId = null, subRole, userLoginRole }: any) => {
    try {
      setIsSigningIn(true);

      let _userLoginRole;

      if (pageRoleType === UserRole.SUPER_ADMIN) {
        _userLoginRole = 'admin';
      } else if (subRole) {
        _userLoginRole = subRole;
      } else {
        _userLoginRole = userLoginRole;
      }

      let userSession = await invokeToken({
        personalNumber: personalNumber,
        companyId: companyId,
        role: _userLoginRole,
      });

      localStorage.setItem('user', JSON.stringify(userSession.user));
      localStorage.setItem('access_token', userSession.accessToken);
      signIn({ ...userSession.user, accessToken: userSession.accessToken });
    } catch (error) {
      if (error instanceof Error) {
        console.error('handleSigninMethodChange Error', error.message);
        setErrorMsg(error.message);
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  const signInBySelectedRole = async ({ personalNumber = null, companyId = null, role, type }: any) => {
    try {
      setIsSigningIn(true);
      let userSession;
      if (type === UserRole.ACCOUNTING_FIRM) {
        userSession = await invokeToken({
          personalNumber: personalNumber ?? user?.['personalNumber'],
          companyId: companyId ?? selectedAccountingFirmRole?.['id'],
          role: selectedAccountingFirmRole?.['subRole'] || 'USER',
        });
      } else if (type === UserRole.COMPANY_ADMIN) {
        userSession = await invokeToken({
          personalNumber: personalNumber ?? user['personalNumber'],
          companyId: companyId ?? selectedRole['id'],
          role: selectedRole?.['subRole'] || 'USER',
        });
      }
      localStorage.setItem('user', JSON.stringify(userSession.user));
      localStorage.setItem('access_token', userSession.accessToken);
      signIn({ ...userSession.user, accessToken: userSession.accessToken });
    } catch (error) {
      if (error instanceof Error) {
        console.error('handleSigninMethodChange Error', error.message);
        setErrorMsg(error.message);
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  const onRetrySignIn = () => {
    if (signInAction?.method === 'mobile') {
      setSessionID(null);
      onInitSession('mobile');
    } else {
      setSessionID(null);
      onInitSession('desktop');
    }
  };

  const clear = () => {
    setErrorMsg('');
    setSignInAction(null);
  };

  const isSingingByMobile = () => {
    return signInAction?.method === 'mobile' && sessionID;
  };

  const isSingingByDesktop = () => {
    return signInAction?.method === 'desktop' && sessionID;
  };

  return {
    isLoading,
    isSigningIn,
    companyOptions,
    accountingFirmOptions,
    selectedRole,
    selectedAccountingFirmRole,
    user,
    errorMsg,
    sessionID,
    signInAction,

    setUser,
    SetIsLoading,
    setIsSigningIn,
    setCompanyOptions,
    setAccountingFirmOptions,
    setSelectedRole,
    setSelectedAccountingFirmRole,
    setSessionID,
    setSignInAction,
    setErrorMsg,
    signIn,

    fetchVerifiedUserDetails,
    signInByDefaultRole,
    signInBySelectedRole,
    onInitSession,
    onRetrySignIn,
    handleSelectCompanyOrAccountingFirm,
    isSingingByMobile,
    isSingingByDesktop,
    clear,
  };
};

export default useAuthenticator;
