import { Button, Form } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import PagesHeader from '../../../../components/base/PagesHeader';
import { FormProps } from '../types';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
export default function Statistics({ context, date }: FormProps) {
  const { statistics, setStatistics, consultantDetailRes } = context;
  const statsDate = (statistics?.lastInteraction && moment(statistics?.lastInteraction).format('YYYY-MM-DD')) || 'N/A';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const handleStatistics = (e) => {
    setStatistics(e.target.value);
  };
  const changeRoute = () => {
    navigate({
      pathname: '/accounting-firms',
      search: `?consultant=${id}`,
    });
  };

  console.log('consultantDetailRes', consultantDetailRes);
  return (
    <div>
      <PagesHeader pageTitle="Statistics" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {/* {isEditMode && ( */}
        <div className="cr-form-row">
          <div className="last-interaction-field">
            <Form.Label className="fs-name mb-0">{t('last_interaction')}</Form.Label>
            <Form.Control disabled value={statsDate} onChange={(e) => handleStatistics(e)} />
          </div>
          <div className="client-no-field">
            <Form.Label className="fs-name mb-0">{t('no_of_clients')}</Form.Label>
            <Form.Control disabled value={consultantDetailRes?.clients || 0} onChange={(e) => handleStatistics(e)} />
          </div>
          <Button variant="primary" className="app-btn-primary" onClick={changeRoute}>
            <ChevronRight className="icon-gap-right" /> {t('client_list')}
          </Button>
        </div>
      </div>
    </div>
  );
}
