import { UserCompanyRole } from '../api/service/auth/types';
import { APP_CONFIG } from '../config/config';
import { UserRole } from '../types/auth';

export const hasAccountingFirmRole = (companyRole: UserCompanyRole) => {
  return companyRole?.role === UserRole.ACCOUNTING_FIRM;
};

export const hasValueChanges = (params) => {
  const res = {};

  Object.keys(params).forEach((key) => {
    const val = params[key];

    if (val === null || val === undefined) {
      return false;
    }

    if (typeof val === 'string' && !val.trim()) {
      return false;
    }

    if (typeof val === 'boolean' && val === false) {
      return false;
    }

    if (val.constructor.name === 'Object') {
      if (Object.keys(val).length === 0) {
        return false;
      } else {
        res[key] = hasValueChanges(val);

        return;
      }
    }

    res[key] = params[key];
  });

  return Object.keys(res).length > 0;
};

export const openExternalLink = (url: string, opt: { event: any } | null = null) => {
  if (opt?.event && opt.event.preventDefault) {
    opt.event.preventDefault();
  }

  if (!url) {
    return;
  }

  const urlPath = url.replace(/\/?api\/?/, '').replace(/^\/+|\/+$/g, '');
  const baseUrl = `${APP_CONFIG.apiBaseURL}`.replace(/\/+$/, '');
  const link = `${baseUrl}/api/${urlPath}`;

  window.open(link, '_blank', 'noreferrer');
};

export const copyToClipboard = async (text, includeDomain = true) => {
  try {
    // const formattedText = text.trim().replace(/-/g, '')
    await navigator.clipboard.writeText(text);
  } catch (err) {
    console.error(err);
  }
};

export const generateEmailDomain = (text) => {
  if (!text) {
    return '';
  }

  return `${text}@${APP_CONFIG.mailDomain}`;
};

export const toLowerCase = (str: string): string => {
  if (typeof str !== 'string') {
    return '';
  }
  return str.toLowerCase();
};

export const toCapitalizeInitial = (str: string): string => {
  if (typeof str !== 'string') {
    return '';
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const toTitleCase = (str: string): string => {
  if (typeof str !== 'string') {
    return '';
  }

  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const getFullName = (firstName: string, lastName: string, middleName?: string): string => {
  const nameParts = [firstName, middleName, lastName];

  const trimmedNameParts = nameParts.filter(Boolean).map((part) => part?.trim());

  return trimmedNameParts.join(' ');
};
