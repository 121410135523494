import { Form, Row } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { useTranslation } from 'react-i18next';
import useContext from './context/useContext';
import classNames from 'classnames';
import { errorHighlighter } from '../../../../util/form';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import CardComponent from '../../../../components/base/CardComponent';
import { OrganizationNumber } from '../../../../components/form/inputs/OrganizationNumber';
import { useEffect } from 'react';
import { useSuperAdminSettingsContext } from '../useContext';
import { Mode } from '../types';
let integrationType: Array<any> = [];

type Props = {
  OnupdateAccountingSalaryCompany?: (args) => void;
};
export default function EditAccountingSalaryCompany({ OnupdateAccountingSalaryCompany }: Props) {
  const {
    accountingSalaryCompany,
    updateContactInformation,
    contactInformation,
    formGroup,
    updateAccountingSalaryCompany,
    replaceAccountingSalaryCompany,
    replaceContactInformation,
  } = useContext();
  const { t, i18n } = useTranslation();
  const { accountingMode } = useSuperAdminSettingsContext();
  const handleChange = (ev) => {
    updateAccountingSalaryCompany(ev.target.name, ev.target.value);
    if (OnupdateAccountingSalaryCompany) {
      OnupdateAccountingSalaryCompany(formGroup);
    }
  };

  const handleIntegrationTypeChange = (e) => {
    if (e.target.checked) {
      integrationType.push({ name: e.target.name, key: e.target.name });
    } else {
      integrationType = integrationType.filter((i) => i.key !== e.target.name);
    }
    updateAccountingSalaryCompany('integrationType', integrationType);
    if (OnupdateAccountingSalaryCompany) {
      OnupdateAccountingSalaryCompany(formGroup);
    }
  };
  const handleContactInfo = (e) => {
    updateContactInformation(e.target.name, e.target.value);
    if (OnupdateAccountingSalaryCompany) {
      OnupdateAccountingSalaryCompany(formGroup);
    }
  };
  useEffect(() => {
    if (OnupdateAccountingSalaryCompany) {
      OnupdateAccountingSalaryCompany(formGroup);
    }
    if (accountingMode === Mode.Add) {
      replaceAccountingSalaryCompany({
        shortName: '',
        companyName: '',
        clientNumber: '',
        contractNumber: '',
        description: '',
        organizationNumber: '',
        vatNumber: '',
        bankgiroNumber: '',
        plusgiroNumber: '',
        integrationType: [],
        apiKey: '',
        id: '',
      });
      replaceContactInformation({
        address: '',
        city: '',
        phone: '',
        fax: '',
        postalCode: '',
        email: '',
      });
    }
  }, []);
  return (
    <>
      <CardComponent>
        <PagesHeader headingClass="fs-h3" pageTitle="Company Information" />
        <Row className="align-items-end mb-4">
          <div style={{ width: 219 }}>
            <Form.Label className="fs-name  required ">{t('short_name')}</Form.Label>
            <Form.Control
              name="shortName"
              onChange={(e) => handleChange(e)}
              value={accountingSalaryCompany?.shortName}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.shortName')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.shortName')} />
          </div>
          <div style={{ width: 360 }}>
            <Form.Label className="fs-name  required">{t('company_name')}</Form.Label>
            <Form.Control
              name="companyName"
              onChange={handleChange}
              value={accountingSalaryCompany?.companyName}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.companyName')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.shortName')} />
          </div>
          <div style={{ width: 76 }}>
            <Form.Label className="fs-name  required">{`${t('client_no')}.`}</Form.Label>
            <Form.Control
              type="number"
              name="clientNumber"
              onChange={handleChange}
              value={accountingSalaryCompany?.clientNumber}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.clientNumber')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.clientNumber')} />
          </div>
          <div style={{ width: 120 }}>
            <Form.Label className="fs-name  required">{`${t('contract_no')}`}</Form.Label>
            <Form.Control
              type="number"
              name="contractNumber"
              onChange={handleChange}
              value={accountingSalaryCompany?.contractNumber}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.contractNumber')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.contractNumber')} />
          </div>
        </Row>
        <Row className="align-items-end mb-4">
          <div style={{ width: 438 }}>
            <Form.Label className="fs-name required">{t('text_description')}</Form.Label>
            <Form.Control
              name="description"
              onChange={handleChange}
              value={accountingSalaryCompany?.description}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.description')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.description')} />
          </div>
        </Row>
        <Row className="align-items-end mb-4">
          <OrganizationNumber
            onChangeHandler={handleChange}
            field={formGroup.getFieldMeta('accountingSalaryCompany.organizationNumber')}
            value={accountingSalaryCompany?.organizationNumber}
            customLabel="Org.no."
          />

          <div style={{ width: 120 }}>
            <Form.Label style={{ width: i18n.language === 'sv' ? 100 : 120 }} className="fs-name required">
              {t('vat_no')}
            </Form.Label>
            <Form.Control
              type="number"
              name="vatNumber"
              onChange={handleChange}
              value={accountingSalaryCompany?.vatNumber}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.vatNumber')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.vatNumber')} />
          </div>
          <div style={{ width: 116 }}>
            <Form.Label className="fs-name  required">{t('bankgiro_no')}</Form.Label>
            <Form.Control
              type="number"
              name="bankgiroNumber"
              onChange={handleChange}
              value={accountingSalaryCompany?.bankgiroNumber}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.bankgiroNumber')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.bankgiroNumber')} />
          </div>
          <div style={{ width: 105 }}>
            <Form.Label className="fs-name required">{t('plusgiro_no')}</Form.Label>
            <Form.Control
              type="number"
              name="plusgiroNumber"
              onChange={handleChange}
              value={accountingSalaryCompany?.plusgiroNumber}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.plusgiroNumber')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.plusgiroNumber')} />
          </div>
        </Row>
        <PagesHeader headingClass="fs-h3" pageTitle="Contact information" />
        <Row className="align-items-end mb-4">
          <div style={{ width: 116 }}>
            <Form.Label className="fs-name  required">{t('postal_address')}</Form.Label>
            <Form.Control
              name="address"
              onChange={handleContactInfo}
              value={contactInformation?.address}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contactInformation.address')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contactInformation.address')} />
          </div>
          <div style={{ width: 120 }}>
            <Form.Label className="fs-name  required">{t('zip_code')}</Form.Label>
            <Form.Control
              type="number"
              name="postalCode"
              onChange={handleContactInfo}
              value={contactInformation?.postalCode}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contactInformation.postalCode')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contactInformation.postalCode')} />
          </div>
          <div style={{ width: 116 }}>
            <Form.Label className="fs-name  required">{t('city')}</Form.Label>
            <Form.Control
              name="city"
              onChange={handleContactInfo}
              value={contactInformation?.city}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contactInformation.city')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contactInformation.city')} />
          </div>
        </Row>
        <Row className="align-items-end mb-4">
          <div style={{ width: 121 }}>
            <Form.Label className="fs-name  required">{t('phone_number')}</Form.Label>
            <Form.Control
              type="number"
              name="phone"
              onChange={handleContactInfo}
              value={contactInformation?.phone}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contactInformation.phone')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contactInformation.phone')} />
          </div>
          <div style={{ width: 121 }}>
            <Form.Label className="fs-name  required">{t('fax')}</Form.Label>
            <Form.Control
              name="fax"
              onChange={handleContactInfo}
              value={contactInformation?.fax}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contactInformation.fax')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contactInformation.fax')} />
          </div>
          <div style={{ width: 220 }}>
            <Form.Label className="fs-name  required">{t('email')}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              onChange={handleContactInfo}
              value={contactInformation ? contactInformation!['email'] : ''}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contactInformation.email')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contactInformation.email')} />
          </div>
        </Row>
        <PagesHeader headingClass="fs-h3" pageTitle="Integration settings" />
        <Row className="align-items-end mb-4">
          <div>
            <Form.Label className="fs-name mb-3">{`${t('type')} ${t('of')} ${t('Integration')}`}</Form.Label>
            <div className="d-flex">
              <Form.Check
                onChange={handleIntegrationTypeChange}
                inline
                label="Company API"
                name="companyAPI"
                defaultChecked={
                  accountingSalaryCompany?.integrationType.length > 0 &&
                  accountingSalaryCompany?.integrationType[0]?.key.includes('companyAPI')
                }
                style={{ width: 120 }}
              />
              <Form.Group style={{ width: 200 }}>
                <Form.Label className="fs-name  required">
                  {t('API')} {t('key')}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="apiKey"
                  onChange={handleChange}
                  value={accountingSalaryCompany?.apiKey}
                  className={classNames(errorHighlighter(formGroup.getFieldMeta('accountingSalaryCompany.apiKey')))}
                />
                <ErrorFeedback field={formGroup.getFieldMeta('accountingSalaryCompany.apiKey')} />
              </Form.Group>
            </div>
          </div>
        </Row>
      </CardComponent>
    </>
  );
}
