import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import { getUserDetailsByPersonalNumber, invokeToken } from '../../api/service/auth/service';
import useAuth from '../../hooks/useAuth';
import { UserRole } from '../../types/auth';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

let mask = 'YdDDmMxy-DDDD';
let formatChars = {
  Y: '[1|2]',
  d: '[0-9]',
  D: '[0-9]',
  m: '[0-1]',
  M: '[0-9]',
  x: '[0|1|2|3]',
  y: '[0-9]',
};

let beforeMaskedValueChange = (newState, oldState, userInput) => {
  let { value } = newState;
  const monthValue = value.charAt(4);
  const dateValue = value.charAt(6);

  if (value.startsWith('1')) formatChars['d'] = '[9]';
  if (value.startsWith('2')) formatChars['d'] = '[0]';

  if (monthValue === '0') {
    formatChars['M'] = '[1-9]';
  }
  if (monthValue === '1') {
    formatChars['M'] = '[0-2]';
  }
  if (dateValue === '0' || dateValue === '1') {
    formatChars['y'] = '[1-9]';
  }
  if (dateValue === '2') {
    formatChars['y'] = '[0-9]';
  }
  if (dateValue === '3') {
    formatChars['y'] = '[0-1]';
  }
  return { value, selection: newState.selection };
};

function PublicLogin() {
  const { signIn } = useAuth();

  const [isLoading, SetIsLoading] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [selectedRole, setSelectedRole] = useState({});
  const [selectedAccountingFirmRole, setSelectedAccountingFirmRole] = useState<unknown>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [user, setUser] = useState({});
  const [personalNumberInput, setPersonalNumber] = useState<string>('');
  const [company /*setCompany*/] = useState<any>([]);
  const [accountingFirm /* setAccountingFirm*/] = useState<any>([]);

  const handleSelectCompanyAndLogin = (e: React.ChangeEvent<HTMLSelectElement>, role: UserRole) => {
    const options = e.target.options;
    const companyName = e.target.value;
    const id = options[options.selectedIndex].getAttribute('id');

    if (role === UserRole.COMPANY_ADMIN) {
      const selectedCompany = company.find((item) => item?.company?.id === id);
      setSelectedRole({ id, name: companyName, subRole: selectedCompany?.subRole });
    } else if (role === UserRole.ACCOUNTING_FIRM) {
      const selectedCompany = accountingFirm.find((item) => item?.company?.id === id);
      setSelectedAccountingFirmRole({ id, name: companyName, subRole: selectedCompany?.subRole });
    }
  };

  const onCompanySignIn = async ({ personalNumber = null, companyId = null, role, type }: any) => {
    try {
      setIsSigningIn(true);
      let userSession;
      if (type === UserRole.ACCOUNTING_FIRM) {
        userSession = await invokeToken({
          personalNumber: personalNumber ?? user?.['personalNumber'],
          companyId: companyId ?? selectedAccountingFirmRole?.['id'],
          role: selectedAccountingFirmRole?.['subRole'] || 'USER',
        });
      } else if (type === UserRole.COMPANY_ADMIN) {
        userSession = await invokeToken({
          personalNumber: personalNumber ?? user['personalNumber'],
          companyId: companyId ?? selectedRole['id'],
          role: selectedRole?.['subRole'] || 'USER',
        });
      }

      localStorage.setItem('user', JSON.stringify(userSession.user));
      localStorage.setItem('access_token', userSession.accessToken);
      signIn({ ...userSession.user, accessToken: userSession.accessToken });
    } catch (error) {
      if (error instanceof Error) {
        console.error('handleSigninMethodChange Error', error.message);
        setErrorMsg(error.message);
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  const signInByDefaultRole = async ({ personalNumber = null, companyId = null, subRole, _type }: any) => {
    try {
      setIsSigningIn(true);

      let userSession = await invokeToken({
        personalNumber: personalNumber,
        companyId: companyId,
        role: subRole,
      });

      localStorage.setItem('user', JSON.stringify(userSession.user));
      localStorage.setItem('access_token', userSession.accessToken);
      signIn({ ...userSession.user, accessToken: userSession.accessToken });
    } catch (error) {
      if (error instanceof Error) {
        console.error('handleSigninMethodChange Error', error.message);
        setErrorMsg(error.message);
      }
    } finally {
      setIsSigningIn(false);
    }
  };

  const handlePersonalNumberChange = (e) => {
    const value = e.target.value;

    setPersonalNumber(value);
  };

  const loginByPersonalNumber = async () => {
    SetIsLoading(true);
    setErrorMsg(null);

    try {
      const user = await getUserDetailsByPersonalNumber(personalNumberInput);

      if (user['roles'].length === 1) {
        const rolesData = user['roles'];
        const companyRole = rolesData[0];
        const company = companyRole.company;
        const companyId = company ? company['id'] : null;

        signInByDefaultRole({
          personalNumber: user['personalNumber'],
          companyId,
          subRole: companyRole?.subRole,
          type: companyRole.role,
        });
      } else {
        user.roles.forEach((item) => {
          if (item!.role === UserRole.ACCOUNTING_FIRM) {
            accountingFirm.push(item);
          }
          if (item!.role === UserRole.COMPANY_ADMIN) {
            company.push(item);
          }
        });
      }
      setUser(user);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMsg(error.message);
      }
    } finally {
      SetIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="mt-4 text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>
          <div className="text-center mt-3">
            {errorMsg && (
              <Alert variant="danger" className="mt-3 p-2">
                {errorMsg}
              </Alert>
            )}
            {!isLoading && user['roles']?.length > 1 ? (
              <Form>
                <Form.Group className="mt-4 m-auto" style={{ width: '70%', textAlign: 'left' }}>
                  <h1 className="fs-h1 font-black theme-text">Welcome</h1>
                  <h2 className="fs-page-title font-black theme-text">{user['fullName']}</h2>
                  {accountingFirm.length > 0 && company.length > 0 && (
                    <p className="fs-text-400 font-black theme-text">
                      You have access to two types of accounts, please select the appropriate account.
                    </p>
                  )}
                  {company.length > 0 && (
                    <Form.Group>
                      <Form.Label className="fs-text-400 font-black theme-text">Company sign in</Form.Label>
                      <Form.Select
                        defaultValue=""
                        bsPrefix="company-select"
                        className="w-100 app-btn-primary text-white px-2"
                        style={{
                          borderRight: '16px solid transparent',
                          marginBottom: isEmpty(selectedRole) ? '24px' : '0',
                        }}
                        name="signin-method"
                        value={selectedRole['name'] ?? ''}
                        onChange={(e) => handleSelectCompanyAndLogin(e, UserRole.COMPANY_ADMIN)}
                      >
                        <option value="" disabled>
                          Choose Your company
                        </option>

                        {company.map((item) => {
                          const company = item['company'];
                          return (
                            <option id={company['id']} key={company['id']} value={company['name']}>
                              {company['name'] + (item['subRole'] ? '   ( ' + item['subRole'] + ' )' : '')}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {!isEmpty(selectedRole) && (
                        <div className="text-center my-3">
                          {isSigningIn ? (
                            <Spinner />
                          ) : (
                            <Button
                              variant="primary"
                              size="lg"
                              onClick={() => onCompanySignIn({ type: UserRole.COMPANY_ADMIN })}
                            >
                              Sign in as {selectedRole['name']}
                            </Button>
                          )}
                        </div>
                      )}
                    </Form.Group>
                  )}
                  {accountingFirm.length > 0 && (
                    <Form.Group>
                      <Form.Label className="fs-text-400 font-black theme-text">Accounting firm sign in</Form.Label>
                      <Form.Select
                        defaultValue=""
                        bsPrefix="company-select"
                        className="w-100 app-btn-primary text-white px-2"
                        style={{
                          borderRight: '16px solid transparent',
                        }}
                        name="signin-method"
                        value={selectedAccountingFirmRole?.['name'] ?? ''}
                        onChange={(e) => handleSelectCompanyAndLogin(e, UserRole.ACCOUNTING_FIRM)}
                      >
                        <option value="" disabled>
                          Choose Your company
                        </option>

                        {accountingFirm.map((item) => {
                          const company = item['company'];
                          return (
                            <option id={company['id']} key={company['id']} value={company['name']}>
                              {company['name'] + (item['subRole'] ? '   ( ' + item['subRole'] + ' )' : '')}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {!isEmpty(selectedAccountingFirmRole) && (
                        <div className="text-center my-3">
                          {isSigningIn ? (
                            <Spinner />
                          ) : (
                            <Button
                              variant="primary"
                              size="lg"
                              onClick={() => onCompanySignIn({ type: UserRole.ACCOUNTING_FIRM })}
                            >
                              Sign in as {selectedAccountingFirmRole?.['name']}
                            </Button>
                          )}
                        </div>
                      )}
                    </Form.Group>
                  )}
                </Form.Group>
              </Form>
            ) : (
              <Form>
                <Form.Group className="mt-4 m-auto" style={{ width: '60%', textAlign: 'left' }}>
                  <Form.Label className="fs-h1 font-black">Sign in</Form.Label>
                  <InputMask
                    value={personalNumberInput}
                    onChange={(e) => handlePersonalNumberChange(e)}
                    name="personalNumber"
                    mask={mask}
                    maskChar=""
                    formatChars={formatChars}
                    beforeMaskedValueChange={beforeMaskedValueChange}
                    className={classNames('form-control text-left')}
                  ></InputMask>
                  {/* <Form.Control
                    value={personalNumberInput}
                    className="text-left"
                    size="lg"
                    type="text"
                    onChange={(e) => handlePersonalNumberChange(e)}
                    name="personalNumber"
                  /> */}
                  <div className="mt-2 mb-2 text-center">
                    <Button
                      onClick={() => loginByPersonalNumber()}
                      style={{ textAlign: 'left', width: '260px' }}
                      className="app-btn-primary"
                      disabled={isLoading}
                    >
                      <ChevronRight /> SignIn By Personal Number
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PublicLogin;
