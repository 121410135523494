import { useEffect, useState } from 'react';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Save } from 'react-feather';
import { useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import {
  getPersonalClientDetail,
  updatePersonalClient,
} from '../../../../api/service/superAdmin/personal-clients/service';
import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { useEditPersonalClientContext } from '../context/useContext';
import { PersonalDetails } from '../types';
import General from './General';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';
import useLocale from '../../../../hooks/useLocale';
import { Legal } from './Legal';
import { AccessLevels } from './AccessLevel/AccessLevels';
import { Log } from './Log/Log';
import useTabChangeListener from '../../../../hooks/useTabChangeListener';
import { deepClone } from '../../../../util/util';
import { usePersonalClientFormObserver } from '../hook/usePersonalClientFormObserver';
import CancelButton from '../../../../components/base/CancelButton';

export default function PersonalClientDetails() {
  const { id } = useParams();
  const { localize } = useLocale();
  const [tabKey, setTabKey] = useState<string | undefined>('general');
  const [isBusy, setIsBusy] = useState(true);
  const [isDeleting /*setisDeleting*/] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const { personalDetails, formGroup, replacePersonalDetailsInput, getFilteredRequestParams } =
    useEditPersonalClientContext();
  const { isInvalid, errors, setErrors } = useValidationError();
  const [disableSaveChangesBtn, setDisableSaveChangesBtn] = useState(false);
  const personalClientFormObserver = usePersonalClientFormObserver();
  // const navigate = useNavigate();

  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Personal clients',
      href: '/personal-clients',
    },
    {
      name: personalDetails.fullName,
      translate: false,
    },
  ];

  const fetchPersonalClientDetails = async () => {
    try {
      const client = await getPersonalClientDetail(id);
      if (client) {
        personalClientFormObserver.setPersonalClientResponse(client);
      }
    } finally {
      setIsBusy(false);
    }
  };

  // const onDeleteClient = async () => {
  //   const title = `${t('i_want_to_detete')} ${personalDetails.fullName} ${t('forever')}`;
  //   const result = await DialogWithTextField({
  //     title: `<b>${t('type_in')}:</b> <br/> <h2 class="fs-page-title user-select-none">${title}</h2>`,
  //     confirmButtonText: t('yes'),
  //     denyButtonText: t('no'),
  //     validationTitle: title,
  //     validationMessage: t('validation_message'),
  //   });

  //   if (result.isConfirmed) {
  //     try {
  //       setisDeleting(true);
  //       await deletePersonalClient(id ?? '');
  //       navigate('/personal-clients');
  //     } finally {
  //       setisDeleting(false);
  //     }
  //   }
  // };

  const onSaveChanges = async () => {
    try {
      formGroup.handleSubmit();
      setErrors(formGroup.errors);

      if (!formGroup.isValid) {
        return false;
      }

      const payload = getFilteredRequestParams(personalDetails);

      setisUpdating(true);
      const response = await updatePersonalClient(id, payload);
      await fetchPersonalClientDetails();
      personalClientFormObserver.setPersonalDetailsRes({ ...response, ...deepClone(payload) });
    } finally {
      setisUpdating(false);
    }
  };

  const onCancel = () => {
    const initialFormGroup: PersonalDetails = deepClone(personalClientFormObserver.getInitialFormValue());
    replacePersonalDetailsInput(initialFormGroup);
  };

  useEffect(() => {
    fetchPersonalClientDetails();
  }, []);

  useTabChangeListener(tabKey);
  useEffect(() => {
    const initialFormGroup: PersonalDetails = personalClientFormObserver.getInitialFormValue();
    setDisableSaveChangesBtn(isEqual(initialFormGroup, personalDetails));
  }, [personalDetails, personalClientFormObserver.personalDetailsRes]);

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader pageTitle={personalDetails.fullName} headingClass="fs-h1" translate={false} />
        {tabKey === 'general' && (
          <div>
            {!disableSaveChangesBtn && <CancelButton onCancel={onCancel} />}
            <Button
              onClick={onSaveChanges}
              disabled={isDeleting || isUpdating || disableSaveChangesBtn}
              className="app-btn-primary ms-2"
            >
              {isUpdating ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}{' '}
              {`${localize('save')} ${localize('changes')}`}
            </Button>
            {/* <Button onClick={onDeleteClient} disabled={isDeleting || isUpdating} className="app-btn-danger ms-2">
              {isDeleting ? <Spinner size="sm" /> : <Trash2 className="icon-gap-right" />}{' '}
              {`${t('delete')} ${t('client')}`}
            </Button> */}
          </div>
        )}
      </div>
      <ValidationAlert show={isInvalid} errors={errors} />
      <Tabs activeKey={tabKey} onSelect={(val) => setTabKey(val ?? 'general')} defaultActiveKey="general">
        <Tab eventKey="general" title={localize('general')}>
          <General isBusy={isBusy} />
        </Tab>
        <Tab eventKey="legal" title={localize('legal')}>
          <Legal />
        </Tab>
        <Tab eventKey="logs" title={localize('logs')}>
          <Log isBusy={isBusy} />
        </Tab>
        <Tab eventKey="access_levels" title={localize('access_levels')}>
          <AccessLevels />
        </Tab>
      </Tabs>
    </>
  );
}
