import React from 'react';
import { tableHeaderText } from '../../../../components/table/TableHeaderFormatter';
import { numberFormat } from '../../../../util/util';
import { EmployeeBenefitReportType } from '../../../../types/report';

const useColumn = (reportType) => {
  const columns: any[] = [
    {
      text: tableHeaderText('Employee'),
      dataField: 'employee.fullName',
      sort: false,
      headerStyle: () => {
        return { width: '210px' };
      },
      footerClasses: 'border-bottom-0',
      footer: (columnData) => <></>,
    },
    {
      text: tableHeaderText('Personal no'),
      dataField: 'employee.personalNumber',
      headerStyle: () => {
        return { width: '115px' };
      },
      footerClasses: 'border-bottom-0',
      footer: (columnData) => <></>,
    },
    {
      text:
        reportType === EmployeeBenefitReportType.INSURANCE
          ? tableHeaderText('Insurance company')
          : tableHeaderText('Pension Manager'),
      dataField:
        reportType === EmployeeBenefitReportType.INSURANCE
          ? 'insuranceCompany.companyName'
          : 'pensionManagerName.shortName',
      headerStyle: () => {
        return { width: '140px' };
      },
      footerClasses: 'border-bottom-0',
      footer: (columnData) => <></>,
    },
    {
      text: tableHeaderText('Plan'),
      dataField: 'plan',
      headerStyle: () => {
        return { width: '140px' };
      },
      footerClasses: 'border-bottom-0',
      footer: (columnData) => <></>,
    },
    {
      text: tableHeaderText('Insurance no'),
      dataField: 'insuranceNumber',
      headerStyle: () => {
        return { width: '120px' };
      },
      footerClasses: 'border-bottom-0',
      footer: (columnData) => <></>,
    },
    {
      text: tableHeaderText('Period'),
      dataField: 'period',
      headerStyle: () => {
        return { width: '130px' };
      },
      footerClasses: 'border-bottom-0',
      footer: (columnData) => <></>,
    },
    {
      text: 'AO',
      dataField: 'ao',
      headerStyle: () => {
        return { width: '90px' };
      },
      footerClasses: 'border-bottom-0',
      footer: (columnData) => <></>,
    },
    {
      text: tableHeaderText('Amount'),
      dataField: 'amount',
      formatter: (cell) => {
        return <span className="cell-content">{numberFormat(cell) || ''}</span>;
      },
      footerClasses: 'border-bottom-0',
      footer: (columnData) => (
        <>
          {columnData.length > 0 ? (
            <span className="theme-text-dark">
              {numberFormat(columnData.reduce((acc, item) => (acc || 0) + (item || 0), 0))}
            </span>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return columns;
};

export default useColumn;
