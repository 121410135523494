import { createContext, useState } from 'react';
import { INITIAL_PERSONAL_DETAILS, IPersonalClientContext, PersonalDetails, PersonalDetailsFormGroup } from '../types';
import { useFormik } from 'formik';
import { formSchema } from './schema';
import set from 'lodash/set';

export const EditPersonalClientContext = createContext<IPersonalClientContext>({
  personalDetails: INITIAL_PERSONAL_DETAILS,
  setPersonalDetails: () => {},

  isEditMode: true,
  formGroup: {} as PersonalDetailsFormGroup,

  updatePersonalDetailsInput: () => {},
  replacePersonalDetailsInput: () => {},
  patchPersonalDetailsForm: () => {},
  getFilteredRequestParams: (value) => ({}),
});

type Props = {
  children: JSX.Element;
};

export const EditPersonalClientProvider = ({ children }: Props) => {
  const [personalDetails, setPersonalDetails] = useState<PersonalDetails>(INITIAL_PERSONAL_DETAILS);

  const formGroup = useFormik({
    initialValues: INITIAL_PERSONAL_DETAILS,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const updatePersonalDetailsInput = (inputName, inputValue) => {
    const _personalDetails = { ...personalDetails };

    set(_personalDetails, inputName, inputValue);

    formGroup.setFieldValue(inputName, inputValue);
    setPersonalDetails((value) => ({
      ...value,
      ..._personalDetails,
    }));
  };

  const replacePersonalDetailsInput = (value) => {
    setPersonalDetails(value);
    formGroup.setValues(value);
  };

  const patchPersonalDetailsForm = (patchValue) => {
    setPersonalDetails((value) => ({
      ...value,
      address: patchValue.address,
      fullName: patchValue.name,
    }));
    formGroup.setValues((value) => ({
      ...value,
      address: patchValue.address,
      fullName: patchValue.name,
    }));
  };

  const getFilteredRequestParams = (data: PersonalDetails) => {
    const { email, alternateEmail, ...partialDetails } = data;

    const payload: Partial<PersonalDetails> = Object.assign(
      {},
      partialDetails,
      email && { email },
      alternateEmail && { alternateEmail },
    );

    return payload;
  };

  const context: IPersonalClientContext = {
    personalDetails,
    setPersonalDetails,
    isEditMode: true,
    formGroup,
    updatePersonalDetailsInput,
    replacePersonalDetailsInput,
    patchPersonalDetailsForm,
    getFilteredRequestParams,
  };

  return <EditPersonalClientContext.Provider value={context}>{children}</EditPersonalClientContext.Provider>;
};
