import { useState } from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import Breadcrumb from '../../../components/base/Breadcrumb';
import PagesHeader from '../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import CreateConsultantModal from './CreateConsultant/CreateConsultantModal';
import ConsultantsGrid from './ConsultantGrid';
import { useTranslation } from 'react-i18next';
import { CreateConsultantProvider } from './context/CreateConsultantContext';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Consultants',
  },
];

function Consultants() {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isConsultantCreated, setIsConsultantCreated] = useState<boolean>(false);
  const toggleModal = () => {
    setShowCreateModal(!showCreateModal);
    setIsConsultantCreated(false);
  };

  return (
    <Container fluid className="p-0 client accounting-firm">
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader headingClass="fs-h1" pageTitle="Consultants" />
        </Col>
        <Col md="6" className="d-flex justify-content-end mb-2">
          <span>
            <Button onClick={toggleModal} className="app-btn-primary me-1">
              <Plus className="icon-gap-right" />
              {`${t('new_consultant')}`}
            </Button>
          </span>
        </Col>
      </Row>
      <ConsultantsGrid isConsultantCreated={isConsultantCreated} />

      {showCreateModal && (
        <CreateConsultantProvider>
          <CreateConsultantModal
            setIsConsultantCreated={setIsConsultantCreated}
            toggleModal={toggleModal}
            showCreateModal={showCreateModal}
          />
        </CreateConsultantProvider>
      )}
    </Container>
  );
}

export default Consultants;
