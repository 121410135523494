import React from 'react';
import { Row } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { FormProps } from '../types';
import BaseSelectBox from '../../../../components/base/BaseSelectBox';
import { INIVITATION_EMAIL_SETTING_OPTIONS } from '../../../../util/constants';
import useLocale from '../../../../hooks/useLocale';

export default function InvitationEmailSettings({ context }: FormProps) {
  const { invitationEmail, updateInvitationEmailInput } = context;
  const { localize } = useLocale();

  return (
    <div>
      <PagesHeader pageTitle="Invitation email settings" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        <div>
          <Row className="align-items-end mb-3  cr-days-field">
            {/* <Col style={{ maxWidth: 250 }}>
              <Form.Label className="fs-name mb-0 required">
                Automatic reminder email delay <span className="font-weight-400">(10 {t('days')})</span>
              </Form.Label>
              <p className="fs-name font-weight-400 fst-italic">Used when invited employees don`t sign in</p>

               <Form.Control
                width={71}
                value={invitationEmail}
                size="lg"
                type="number"
                onChange={(e) => updateInvitationEmailInput(e.target.value)}
                name="invitationEmail"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`invitationEmail`)))}
              /> 
          
              <ErrorFeedback field={formGroup.getFieldMeta(`invitationEmail`)} />
            </Col> */}
            <BaseSelectBox
              options={INIVITATION_EMAIL_SETTING_OPTIONS}
              maxWidth={250}
              onChangeHandler={(e) => updateInvitationEmailInput(e.target.value)}
              value={invitationEmail}
              name="invitationEmail"
              label={localize('invitation_email_label')}
              labelSuffix={`(${localize('days')})`}
              labelDetail={localize('invitation_email_label_detail')}
              labelDetailClassname="fst-italic"
            />
          </Row>
        </div>
      </div>
    </div>
  );
}
