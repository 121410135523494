import React, { useEffect, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { ReactComponent as CheckBox } from '../../../../assets/img/check-box.svg';
import { ReactComponent as OpenLink } from '../../../../assets/img/open-link.svg';
import { ReactComponent as CurrencyIcon } from '../../../../assets/img/currency-union.svg';
import PagesHeader from '../../../../components/base/PagesHeader';
import usePensionManagerForm, { ListMode, SelectionModeType } from '../hooks/usePensionManagerForm';
import usePolicyMode from '../hooks/usePolicyMode';
import { IPensionManagerPlan, IncludedPlanInputType } from '../types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useContext from '../PensionPolicyContext/useContext';
import useCostSpecificationModal from '../components/CostSpecificationModal/useCostSpecificationModal';
import CostSpecificationModal from '../components/CostSpecificationModal/CostSpecificationModal';
import PensionManagerEffectiveDate from '../components/PensionManagerEffectiveDate/PensionManagerEffectiveDate';
import { toNumber } from '../../../../util/util';

type Props = {
  selectedPolicy: any;
  pensionDataset?: IPensionManagerPlan;
  fetchedFromExternalSource?: boolean;
  isExpanded?: boolean;
};

const DEFAULT_PROPS = {
  fetchedFromExternalSource: false,
  /* pensionDataset: {
    hjertaPartner: [],
    notHjertaPartner: [],
  }, */
  pensionDataset: [],
} as any;

const PlansDetailsViewer = ({
  selectedPolicy,
  pensionDataset = DEFAULT_PROPS.pensionDataset,
  fetchedFromExternalSource = DEFAULT_PROPS.fetchedFromExternalSource,
  isExpanded,
}: Partial<Props>) => {
  const { t } = useTranslation();
  const { externalPolicyRevision, preAssignedPolicy, selectedPolicyDetails } = useContext();
  const {
    assignedManagers,
    isCheckedSepratePensionManagerPlansOption,
    hasIncluded,
    markAsIncludeWithDefault,
    changeNumberValue,
    setSelectedMode,
    findManagerPlan,
    isCheckedEmployeeEditOption,
    updateEmployeePensionSettings,
    hasEmployeeChosenPolicyCollectiveAgreement,
  } = usePensionManagerForm({
    hasExternalSource: fetchedFromExternalSource,
    remoteDataSource: pensionDataset,
  });
  const {
    /* hasCompanyOnlyMode, */ isCompanyPolicyMode,
    isCompanyEmployeePolicyMode,
    isCompanyEmployeeReadOnlyMode,
    isCompanyEmployeeEditableMode,
    isAdminReadOnlyMode,
  } = usePolicyMode();

  const {
    toggleModal: toggleCostSpecModal,
    showCostSpecModal,
    setCostPlanManager,
    selectedCostPlanManager,
  } = useCostSpecificationModal();

  const setDefaultPlan = (_managerID, _planID, _key: SelectionModeType) => {
    markAsIncludeWithDefault(_key, _managerID, _planID);
  };

  const handleChangeNumberValue = (
    value: string,
    params: {
      key: SelectionModeType;
      propName: string;
      pensionManagerId: string;
      planID: string;
    },
  ) => {
    changeNumberValue({ ...params, propValue: value });
  };

  const hasShownOccupationalPlanCheckedInput = (planItem, managerID, planInputType: IncludedPlanInputType) => {
    if (!planItem) {
      return false;
    }

    if (isCompanyPolicyMode() && hasShownOccupationalNonEditableInput(planItem, planInputType)) {
      return true;
    }

    if (isCompanyEmployeePolicyMode() && hasShownOccupationalEditableInput(planItem, managerID)) {
      return true;
    }

    return false;
  };

  const hasShownSalaryPlanCheckedInput = (planItem, managerID, planInputType: IncludedPlanInputType) => {
    if (!planItem) {
      return false;
    }

    if (isCompanyPolicyMode() && hasShownSalaryNonEditableInput(planItem, planInputType)) {
      return true;
    }

    if (isCompanyEmployeePolicyMode() && hasShownSalaryEditableInput(planItem, managerID)) {
      return true;
    }

    return false;
  };

  const hasShownOccupationalEditableInput = (planItem, managerID) => {
    if (!planItem) {
      return false;
    }

    if (
      !(
        isCompanyEmployeePolicyMode() &&
        hasIncluded(SelectionModeType.OCCUPATIONAL, managerID, planItem.planMetaDetails.planName)
      )
    ) {
      return false;
    }

    return true;
  };

  const hasShownOccupationalNonEditableInput = (planItem, planInputType: IncludedPlanInputType) => {
    if (!planItem) {
      return false;
    }

    if (isCompanyPolicyMode() && planItem.isOccupationalChecked && planItem.fromOccupationalPension[planInputType]) {
      return true;
    }

    return false;
  };

  const hasShownSalaryEditableInput = (planItem, managerID) => {
    if (!planItem) {
      return false;
    }

    if (
      !(
        isCompanyEmployeePolicyMode() &&
        hasIncluded(SelectionModeType.SALARY, managerID, planItem.planMetaDetails.planName)
      )
    ) {
      return false;
    }

    return true;
  };

  const hasShownSalaryNonEditableInput = (planItem, planInputType: IncludedPlanInputType) => {
    if (!planItem) {
      return false;
    }

    if (isCompanyPolicyMode() && planItem.isSalaryChecked && planItem.fromSalaryChange[planInputType]) {
      return true;
    }

    return false;
  };

  const defaultInputValue = (inputType: IncludedPlanInputType, key: SelectionModeType, pensionManagerId, planID) => {
    const plan = findManagerPlan(key, pensionManagerId, planID);

    if (plan && plan.meta) {
      return plan.meta[inputType];
    }

    return '';
  };

  /* const canEditOccupationalIncludedPlanInput = (planItem, planInputType: IncludedPlanInputType) => {
    if (!planItem) {
      return false;
    }

    if (!isCompanyPolicyMode()) {
      return false;
    }

    if (planItem.isOccupationalChecked && planItem?.fromOccupationalPension[planInputType]) {
      return true;
    }

    return false;
  };

  const canEditSalaryIncludedPlanInput = (planItem, planInputType: IncludedPlanInputType) => {
    if (!planItem) {
      return false;
    }

    if (!isCompanyPolicyMode()) {
      return false;
    }

    if (planItem.isSalaryChecked && planItem?.fromSalaryChange[planInputType]) {
      return true;
    }

    return false;
  }; */

  const onShowPlanCostSpecification = (manager, planItemDetails) => {
    setCostPlanManager({ manager, planItem: planItemDetails });
    toggleCostSpecModal();
  };

  const isSeparatedOption = () => {
    return isCheckedSepratePensionManagerPlansOption;
  };

  useEffect(() => {
    setSelectedMode(ListMode.SECONDARY);
  }, [isCompanyEmployeePolicyMode()]);

  useEffect(() => {
    if (!isCompanyEmployeeEditableMode()) {
      return;
    }

    if (externalPolicyRevision?.effectiveFrom) {
      updateEmployeePensionSettings('effectiveFrom', externalPolicyRevision?.effectiveFrom);
    }
  }, [externalPolicyRevision?.effectiveFrom]);

  useEffect(() => {
    if (!isCompanyEmployeeEditableMode()) {
      return;
    }

    if (preAssignedPolicy?.policySettings?.employeeMetaSettings?.pensionManager?.effectiveFrom) {
      updateEmployeePensionSettings(
        'effectiveFrom',
        preAssignedPolicy.policySettings?.employeeMetaSettings?.pensionManager?.effectiveFrom,
      );
    }
  }, [preAssignedPolicy]);

  useLayoutEffect(() => {
    if (!isAdminReadOnlyMode()) {
      return;
    }

    if (!isExpanded) {
      return;
    }

    if (!isSeparatedOption()) {
      return;
    }

    const renderPlanLayout = setTimeout(() => {
      if (assignedManagers?.length > 0) {
        document.querySelectorAll('.pension-manager-table>tr:not(:nth-of-type(1))').forEach((rowElement) => {
          if (!rowElement) {
            return;
          }

          const providerElement = rowElement.querySelector('td.section-one');
          const providerElementHeight = toNumber(providerElement?.getBoundingClientRect().height || 0);
          const providerInnerElement = rowElement.querySelector('td.section-one table');
          const providerInnerElementHeight = toNumber(providerInnerElement?.getBoundingClientRect().height || 0);

          const planRowElements = rowElement.querySelectorAll(
            'td.section-two .pension-manager-table>tbody>tr:not(:nth-last-of-type(1))',
          );
          const planInnerRowContainerElement = rowElement.querySelector('td.section-two table');
          const planInnerRowContainerElementHeight = toNumber(
            planInnerRowContainerElement?.getBoundingClientRect().height || 0,
          );

          if (planInnerRowContainerElementHeight > providerInnerElementHeight) {
            return;
          }

          let planBeforeLastRowElementHeight: number = 0;

          planRowElements.forEach((planRowElement) => {
            const cellHeight = planRowElement.querySelector('td:nth-of-type(1)')?.getBoundingClientRect().height || 0;

            planBeforeLastRowElementHeight = planBeforeLastRowElementHeight + cellHeight;
          });

          const remainingHeight = providerElementHeight - planBeforeLastRowElementHeight;

          if (remainingHeight) {
            const targetElement: HTMLElement | null = rowElement.querySelector(
              'td.section-two .pension-manager-table>tbody>tr:nth-last-of-type(1) td:nth-last-of-type(1)',
            );

            if (targetElement) {
              targetElement.style.height = remainingHeight + 'px';
            }
          }
        });
      }
    });

    return () => {
      clearTimeout(renderPlanLayout);
    };
  }, [assignedManagers, preAssignedPolicy, selectedPolicyDetails, isExpanded]);

  return (
    <>
      {assignedManagers?.length > 0 && (
        <>
          <PagesHeader headingClass="pp-sec-heading" pageTitle="Pension managers" biggerHeading />
          <Form.Group className="pe-0 chkbox-form-group">
            <Form.Check inline id="employeeRightToEditChecked" className="fs-name mb-1">
              <Form.Check.Input
                name="employeeRightToEditChecked"
                type="checkbox"
                checked={isCheckedEmployeeEditOption}
                disabled
              />
              <Form.Check.Label htmlFor="isPremium" className="form-check-label text-label-note">
                {t('employee_right_to_edit')}
              </Form.Check.Label>
            </Form.Check>
            <div className="subtext-block">
              <p className="text-label-note">{t('select_employee_pension_manager')}.</p>
            </div>
          </Form.Group>
          <div className="table-responsive react-bootstrap-table pension-manager-view">
            <table className="table table-hover table-condensed pension-manager-table pension-policy-single-row pp-manager-view">
              <tr>
                <td className="section-one" style={{ verticalAlign: 'bottom' }}>
                  <table className="table table-hover table-condensed pension-manager-table">
                    <thead className="header-wrapper">
                      <tr className="pm-header-second">
                        <th className="fs-name" style={{ width: '250px' }}>
                          {t('provider')}
                        </th>
                      </tr>
                    </thead>
                  </table>
                </td>
                <td className="section-two">
                  <table className="table table-hover table-condensed pension-manager-table">
                    <thead className="header-wrapper">
                      <tr className="pm-header-second">
                        <th className="fs-name" style={{ width: '515px' }}>
                          {t('plan')}
                        </th>
                        {!isSeparatedOption() && (
                          <th style={{ width: '120px' }} className={classNames('border-left-0')}></th>
                        )}
                        {isSeparatedOption() && (
                          <>
                            <th style={{ width: '120px' }}>{t('from_occupational_pension')}</th>
                            <th style={{ width: '132px', maxWidth: '132px', minWidth: '132px' }}>
                              {t('from_salary_change')}
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                  </table>
                </td>
              </tr>

              {assignedManagers.map((manager, idx) => {
                return (
                  <tr key={`hjerta-manager-item-${idx}`}>
                    <td className="section-one">
                      <table className="table table-hover table-condensed pension-manager-table">
                        <tbody>
                          <tr className="row-section">
                            <td>
                              <span className="fs-name">{manager.metaDetails.companyName}</span>
                              <p>{manager.metaDetails.pensionManagerPlans.providerDescription}</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td className="section-two">
                      <table className="table table-hover table-condensed pension-manager-table">
                        <tbody>
                          {(manager?.assignedPlans || []).map((planItem, planIdx) => {
                            return (
                              <React.Fragment key={`hjerta-plan-item-${idx}${planIdx}`}>
                                <tr className="row-section">
                                  <td
                                    className={classNames({
                                      'active-green':
                                        isCompanyEmployeePolicyMode() &&
                                        !isCheckedSepratePensionManagerPlansOption &&
                                        hasIncluded(
                                          SelectionModeType.OCCUPATIONAL,
                                          manager.metaDetails.id,
                                          planItem.planMetaDetails.planName,
                                        ),
                                    })}
                                    style={{ width: '515px' }}
                                  >
                                    <span className="fs-name">{planItem.planMetaDetails.planName}</span>
                                    <p>{planItem.planMetaDetails.providerDescription}</p>
                                    {planItem?.planMetaDetails?.costSpecification && (
                                      <div className="mb-3">
                                        <span
                                          className="link-text cursor-pointer"
                                          onClick={() => onShowPlanCostSpecification(manager, planItem)}
                                        >
                                          <CurrencyIcon className="me-1" />
                                          Cost specification
                                        </span>
                                      </div>
                                    )}
                                    <ul className="table-links">
                                      {(planItem.planMetaDetails.documents || []).map((docItem, docIdx) => {
                                        return (
                                          <li key={`hjerta-plan-item-link-${idx}${planIdx}-${docIdx}`}>
                                            <Link to={docItem.documentLink} target="_blank" className="link-text">
                                              <OpenLink /> {docItem.text}
                                            </Link>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </td>
                                  <td
                                    style={{ width: '120px' }}
                                    className={classNames(
                                      { 'highlight-editable-option': isCompanyEmployeePolicyMode() },
                                      {
                                        'highlight-editable-option--selected':
                                          isCompanyEmployeePolicyMode() &&
                                          hasIncluded(
                                            SelectionModeType.OCCUPATIONAL,
                                            manager.metaDetails.id,
                                            planItem.planMetaDetails.planName,
                                          ),
                                      },
                                      {
                                        'border-left-0': !isSeparatedOption(),
                                      },
                                      'full-height-border',
                                    )}
                                  >
                                    {!isCompanyEmployeePolicyMode() && planItem.isOccupationalChecked && <CheckBox />}
                                    {isCompanyEmployeeEditableMode() && (
                                      <Form.Check
                                        type="radio"
                                        label=" "
                                        id={`default-occupational-hjerta-plan-item-${idx}${planIdx}`}
                                        className="fs-name"
                                        name="occupational"
                                        onChange={(e) =>
                                          setDefaultPlan(
                                            manager.metaDetails.id,
                                            planItem.planMetaDetails.planName,
                                            SelectionModeType.OCCUPATIONAL,
                                          )
                                        }
                                        checked={hasIncluded(
                                          SelectionModeType.OCCUPATIONAL,
                                          manager.metaDetails.id,
                                          planItem.planMetaDetails.planName,
                                        )}
                                        disabled={hasEmployeeChosenPolicyCollectiveAgreement()}
                                      />
                                    )}
                                    {isCompanyEmployeeReadOnlyMode() && (
                                      <Form.Check
                                        type="radio"
                                        disabled
                                        label=" "
                                        id={`default-occupational-hjerta-plan-item-${idx}${planIdx}`}
                                        className="fs-name"
                                        name="occupational"
                                        checked={hasIncluded(
                                          SelectionModeType.OCCUPATIONAL,
                                          manager.metaDetails.id,
                                          planItem.planMetaDetails.planName,
                                        )}
                                      />
                                    )}
                                    <div className="input-info">
                                      {hasShownOccupationalPlanCheckedInput(
                                        planItem,
                                        manager.metaDetails.id,
                                        IncludedPlanInputType.CUSTOMER_NUMBER,
                                      ) && (
                                        <Form.Group className="mb-2">
                                          <Form.Label className="theme-text fs-name">{t('customer_number')}</Form.Label>
                                          {hasShownOccupationalNonEditableInput(
                                            planItem,
                                            IncludedPlanInputType.CUSTOMER_NUMBER,
                                          ) && (
                                            <Form.Control
                                              type="text"
                                              value={planItem.fromOccupationalPension?.customerNumber}
                                              disabled={true}
                                            />
                                          )}
                                          {hasShownOccupationalEditableInput(planItem, manager.metaDetails.id) && (
                                            <>
                                              {isCompanyEmployeeEditableMode() && (
                                                <Form.Control
                                                  type="text"
                                                  name={`include-hjerta-customer-num-${idx}${planIdx}`}
                                                  onChange={(e) =>
                                                    handleChangeNumberValue(e.target.value, {
                                                      key: SelectionModeType.OCCUPATIONAL,
                                                      propName: 'customerNumber',
                                                      pensionManagerId: manager.metaDetails.id,
                                                      planID: planItem.planMetaDetails.planName,
                                                    })
                                                  }
                                                  value={defaultInputValue(
                                                    IncludedPlanInputType.CUSTOMER_NUMBER,
                                                    SelectionModeType.OCCUPATIONAL,
                                                    manager.metaDetails.id,
                                                    planItem.planMetaDetails.planName,
                                                  )}
                                                />
                                              )}
                                              {isCompanyEmployeeReadOnlyMode() && (
                                                <Form.Control
                                                  type="text"
                                                  disabled
                                                  value={defaultInputValue(
                                                    IncludedPlanInputType.CUSTOMER_NUMBER,
                                                    SelectionModeType.OCCUPATIONAL,
                                                    manager.metaDetails.id,
                                                    planItem.planMetaDetails.planName,
                                                  )}
                                                />
                                              )}
                                            </>
                                          )}
                                        </Form.Group>
                                      )}

                                      {hasShownOccupationalPlanCheckedInput(
                                        planItem,
                                        manager.metaDetails.id,
                                        IncludedPlanInputType.INSURANCE_NUMBER,
                                      ) && (
                                        <Form.Group>
                                          <Form.Label className="theme-text fs-name">
                                            {t('insurance_number')}
                                          </Form.Label>
                                          {hasShownOccupationalNonEditableInput(
                                            planItem,
                                            IncludedPlanInputType.INSURANCE_NUMBER,
                                          ) && (
                                            <Form.Control
                                              type="text"
                                              value={planItem.fromOccupationalPension?.insuranceNumber}
                                              disabled={true}
                                            />
                                          )}
                                          {hasShownOccupationalEditableInput(planItem, manager.metaDetails.id) && (
                                            <>
                                              {isCompanyEmployeeEditableMode() && (
                                                <Form.Control
                                                  type="text"
                                                  name={`include-hjerta-insurance-num-${idx}${planIdx}`}
                                                  onChange={(e) =>
                                                    handleChangeNumberValue(e.target.value, {
                                                      key: SelectionModeType.OCCUPATIONAL,
                                                      propName: 'insuranceNumber',
                                                      pensionManagerId: manager.metaDetails.id,
                                                      planID: planItem.planMetaDetails.planName,
                                                    })
                                                  }
                                                  value={defaultInputValue(
                                                    IncludedPlanInputType.INSURANCE_NUMBER,
                                                    SelectionModeType.OCCUPATIONAL,
                                                    manager.metaDetails.id,
                                                    planItem.planMetaDetails.planName,
                                                  )}
                                                />
                                              )}
                                              {isCompanyEmployeeReadOnlyMode() && (
                                                <Form.Control
                                                  type="text"
                                                  disabled
                                                  value={defaultInputValue(
                                                    IncludedPlanInputType.INSURANCE_NUMBER,
                                                    SelectionModeType.OCCUPATIONAL,
                                                    manager.metaDetails.id,
                                                    planItem.planMetaDetails.planName,
                                                  )}
                                                />
                                              )}
                                            </>
                                          )}
                                        </Form.Group>
                                      )}
                                    </div>
                                  </td>
                                  {isCheckedSepratePensionManagerPlansOption && (
                                    <td
                                      style={{ width: '132px', maxWidth: '132px', minWidth: '132px' }}
                                      className={classNames(
                                        { 'highlight-editable-option': isCompanyEmployeePolicyMode() },
                                        {
                                          'highlight-editable-option--selected':
                                            isCompanyEmployeePolicyMode() &&
                                            hasIncluded(
                                              SelectionModeType.SALARY,
                                              manager.metaDetails.id,
                                              planItem.planMetaDetails.planName,
                                            ),
                                        },
                                      )}
                                    >
                                      {!isCompanyEmployeePolicyMode() && planItem.isSalaryChecked && <CheckBox />}{' '}
                                      {isCompanyEmployeeEditableMode() && (
                                        <Form.Check
                                          type="radio"
                                          label=" "
                                          id={`default-salary-hjerta-plan-item-${idx}${planIdx}`}
                                          className="fs-name"
                                          name="salary"
                                          checked={hasIncluded(
                                            SelectionModeType.SALARY,
                                            manager.metaDetails.id,
                                            planItem.planMetaDetails.planName,
                                          )}
                                          onChange={(e) =>
                                            setDefaultPlan(
                                              manager.metaDetails.id,
                                              planItem?.planMetaDetails?.planName,
                                              SelectionModeType.SALARY,
                                            )
                                          }
                                          disabled={hasEmployeeChosenPolicyCollectiveAgreement()}
                                        />
                                      )}
                                      {isCompanyEmployeeReadOnlyMode() && (
                                        <Form.Check
                                          type="radio"
                                          disabled
                                          label=" "
                                          id={`default-salary-hjerta-plan-item-${idx}${planIdx}`}
                                          className="fs-name"
                                          name="salary"
                                          checked={hasIncluded(
                                            SelectionModeType.SALARY,
                                            manager.metaDetails.id,
                                            planItem.planMetaDetails.planName,
                                          )}
                                        />
                                      )}
                                      <div className="input-info">
                                        {hasShownSalaryPlanCheckedInput(
                                          planItem,
                                          manager.metaDetails.id,
                                          IncludedPlanInputType.CUSTOMER_NUMBER,
                                        ) && (
                                          <Form.Group className="mb-2">
                                            <Form.Label className="theme-text fs-name">
                                              {t('customer_number')}
                                            </Form.Label>
                                            {hasShownSalaryNonEditableInput(
                                              planItem,
                                              IncludedPlanInputType.CUSTOMER_NUMBER,
                                            ) && (
                                              <Form.Control
                                                type="text"
                                                value={planItem.fromSalaryChange?.customerNumber}
                                                disabled={true}
                                              />
                                            )}
                                            {hasShownSalaryEditableInput(planItem, manager.metaDetails.id) && (
                                              <>
                                                {isCompanyEmployeeEditableMode() && (
                                                  <Form.Control
                                                    type="text"
                                                    style={{ width: '120px' }}
                                                    name={`include-hjerta-salary-customer-num-${idx}${planIdx}`}
                                                    onChange={(e) =>
                                                      handleChangeNumberValue(e.target.value, {
                                                        key: SelectionModeType.SALARY,
                                                        propName: 'customerNumber',
                                                        pensionManagerId: manager.metaDetails.id,
                                                        planID: planItem.planMetaDetails.planName,
                                                      })
                                                    }
                                                    value={defaultInputValue(
                                                      IncludedPlanInputType.CUSTOMER_NUMBER,
                                                      SelectionModeType.SALARY,
                                                      manager.metaDetails.id,
                                                      planItem.planMetaDetails.planName,
                                                    )}
                                                  />
                                                )}
                                                {isCompanyEmployeeReadOnlyMode() && (
                                                  <Form.Control
                                                    type="text"
                                                    disabled
                                                    style={{ width: '120px' }}
                                                    value={defaultInputValue(
                                                      IncludedPlanInputType.CUSTOMER_NUMBER,
                                                      SelectionModeType.SALARY,
                                                      manager.metaDetails.id,
                                                      planItem.planMetaDetails.planName,
                                                    )}
                                                  />
                                                )}
                                              </>
                                            )}
                                          </Form.Group>
                                        )}

                                        {hasShownSalaryPlanCheckedInput(
                                          planItem,
                                          manager.metaDetails.id,
                                          IncludedPlanInputType.INSURANCE_NUMBER,
                                        ) && (
                                          <Form.Group>
                                            <Form.Label className="theme-text fs-name">
                                              {t('insurance_number')}
                                            </Form.Label>
                                            {hasShownSalaryNonEditableInput(
                                              planItem,
                                              IncludedPlanInputType.INSURANCE_NUMBER,
                                            ) && (
                                              <Form.Control
                                                type="text"
                                                value={planItem.fromSalaryChange?.insuranceNumber}
                                                disabled={true}
                                              />
                                            )}
                                            {hasShownSalaryEditableInput(planItem, manager.metaDetails.id) && (
                                              <>
                                                {isCompanyEmployeeEditableMode() && (
                                                  <Form.Control
                                                    type="text"
                                                    style={{ width: '120px' }}
                                                    name={`include-hjerta-salary-insurance-num-${idx}${planIdx}`}
                                                    onChange={(e) =>
                                                      handleChangeNumberValue(e.target.value, {
                                                        key: SelectionModeType.SALARY,
                                                        propName: 'insuranceNumber',
                                                        pensionManagerId: manager.metaDetails.id,
                                                        planID: planItem.planMetaDetails.planName,
                                                      })
                                                    }
                                                    value={defaultInputValue(
                                                      IncludedPlanInputType.INSURANCE_NUMBER,
                                                      SelectionModeType.SALARY,
                                                      manager.metaDetails.id,
                                                      planItem.planMetaDetails.planName,
                                                    )}
                                                  />
                                                )}
                                                {isCompanyEmployeeReadOnlyMode() && (
                                                  <Form.Control
                                                    type="text"
                                                    disabled
                                                    style={{ width: '120px' }}
                                                    value={defaultInputValue(
                                                      IncludedPlanInputType.INSURANCE_NUMBER,
                                                      SelectionModeType.SALARY,
                                                      manager.metaDetails.id,
                                                      planItem.planMetaDetails.planName,
                                                    )}
                                                  />
                                                )}
                                              </>
                                            )}
                                          </Form.Group>
                                        )}
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          {isCompanyEmployeePolicyMode() && <PensionManagerEffectiveDate />}
        </>
      )}
      <CostSpecificationModal
        show={showCostSpecModal}
        toggleModal={toggleCostSpecModal}
        costSpecDetails={selectedCostPlanManager?.planItem?.planMetaDetails?.costSpecification}
        planName={selectedCostPlanManager?.planItem?.planMetaDetails?.planName}
        managerName={selectedCostPlanManager?.manager?.metaDetails?.companyName}
      />
    </>
  );
};

export default React.memo(PlansDetailsViewer);
