import { Form, Spinner } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import PagesHeader from '../../../../components/base/PagesHeader';
import { useEditPersonalClientContext } from '../context/useContext';
import { PersonalDetailsForm } from '../PersonalClientForm';
import useLocale from '../../../../hooks/useLocale';

type Props = {
  isBusy: boolean;
};

const General = ({ isBusy }: Props) => {
  const context = useEditPersonalClientContext();
  const { localize } = useLocale();

  return (
    <CardComponent>
      {isBusy ? (
        <Spinner />
      ) : (
        <>
          <PersonalDetailsForm context={context} formGroup={context.formGroup} />
          <PagesHeader headingClass="fs-text-400" pageTitle="Employment" />
          <div className="cr-form-row">
            <div className="employer-field">
              <Form.Label className="fs-name">{localize('employer')}</Form.Label>
              <Form.Control disabled defaultValue="Sizzle AB" />
            </div>
            <div className="date-large-field">
              <Form.Label className="fs-name">{localize('start_date')}</Form.Label>
              <Form.Control disabled type="date" />
            </div>
            <div className="date-large-field">
              <Form.Label className="fs-name">{localize('end_date')}</Form.Label>
              <Form.Control disabled type="date" />
            </div>
            <div className="employment-num-field">
              <Form.Label className="fs-name">{localize('employment_number')}</Form.Label>
              <Form.Control disabled defaultValue="331" />
            </div>
            <div className="status-field">
              <Form.Label className="fs-name">{`${localize('employment')} ${localize('status')}`}</Form.Label>
              <Form.Control disabled />
            </div>
            <div className="employment-type-field">
              <Form.Label className="fs-name">{`${localize('employment_type')}`}</Form.Label>
              <Form.Select disabled defaultValue="whiteCollar">
                <option value="whiteCollar">{localize('white_collar')}</option>
              </Form.Select>
            </div>
          </div>
        </>
      )}
    </CardComponent>
  );
};

export default General;
