import React from 'react';
import { Row } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { FormProps } from '../types';
import { INIVITATION_EMAIL_SETTING_OPTIONS } from '../../../../util/constants';
import BaseSelectBox from '../../../../components/base/BaseSelectBox';
import { useTranslation } from 'react-i18next';

export default function Invitation({ context }: FormProps) {
  const { t } = useTranslation();
  const { invitationEmail, formGroup, updateInvitationEmail } = context;
  return (
    <div>
      <PagesHeader pageTitle="Invitation email settings" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {/* <Row className="align-items-end mb-2">
          <Col md="12">
            <strong className="theme-text mr-1 mb-1 ">Automatic reminder email delay</strong>
            <span className="theme-text required">(days)</span>
          </Col>
          <Col md="12">
            <i className="theme-text">Used when invited employees don&apos;t sign in</i>
          </Col>
        </Row> */}

        <Row className="align-items-end mb-3  cr-days-field">
          {/* <Col style={{ maxWidth: 170 }}>
            <Form.Control
              className={classNames(errorHighlighter(formGroup.getFieldMeta('invitationEmail')))}
              size="lg"
              type="number"
              value={formGroup.values.invitationEmail}
              onChange={(e) => updateInvitationEmail(e.target.value)}
              name="invitationEmail"
            />
            <ErrorFeedback field={formGroup.getFieldMeta('invitationEmail')} />
          </Col> */}
          <BaseSelectBox
            options={INIVITATION_EMAIL_SETTING_OPTIONS}
            onChangeHandler={(e) => updateInvitationEmail(e.target.value)}
            field={formGroup.getFieldMeta(`invitationEmail`)}
            value={invitationEmail}
            name="invitationEmail"
            label="Automatic reminder email delay "
            labelSuffix={`(${t('days')})`}
            labelDetail="Used when invited employees don't sign in"
            labelDetailClassname="fst-italic"
          />{' '}
        </Row>
      </div>
    </div>
  );
}
