import { Form } from 'react-bootstrap';
import BSModal from '../../../../components/Modal';
import { SalaryFile } from '../../Settings/FilesGrid/types';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { InputEditor } from './InputEditor';
import { getSalaryTypes } from '../../../../api/service/superAdmin/pension-policies/service';
import { dataManagementDetails } from '../data';
import { SuperAdminPensionPolicySalaryData } from '../../../../dummy/data';
import HorizontalRule from '../../../../components/base/HorizontalRule';

type Props = {
  showDataModal: boolean;
  toggleModal: () => void;
  selectedFile: SalaryFile;
};

export default function DataManagementDetails({ showDataModal, toggleModal, selectedFile }: Props) {
  const { t } = useTranslation();
  const [salaryTypes, setSalaryTypes] = useState<typeof SuperAdminPensionPolicySalaryData>({ data: [] });

  const handleUpdateAmount = (value) => {
    console.log(value);
  };
  const fetchSalaryTypes = async () => {
    const types = await getSalaryTypes();
    setSalaryTypes({ data: types });
  };
  useEffect(() => {
    fetchSalaryTypes();
  }, []);

  return (
    <>
      <BSModal
        show={showDataModal}
        onHide={() => {
          toggleModal();
        }}
        centered
        title={selectedFile.name}
      >
        <div
          style={{ display: 'grid', gridTemplateColumns: '204px 300px 222px 276px 110px' }}
          className="align-items-center "
        >
          {dataManagementDetails.headers.map((item, index) => (
            <div key={index} className="fs-text-700 theme-text">
              {item}
            </div>
          ))}
        </div>
        <HorizontalRule height="2px" />
        {dataManagementDetails.data.map((item, index) => (
          <>
            <div
              style={{ display: 'grid', gridTemplateColumns: '204px 300px 203px 295px 125px' }}
              className="align-items-center mb-4"
              key={index}
            >
              <div className="align-self-lg-end">
                <p className="fs-text-700 theme-text">{item.title}</p>
              </div>
              <p className="color-red align-self-end">{item.description}</p>
              <div>
                {item?.description?.startsWith('Unmatched type detected:') && (
                  <Form.Select>
                    {salaryTypes.data.map((item) => (
                      <option key={item._id} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                )}
              </div>
              <InputEditor name="amount" value={item.amount} handleSaveChanges={handleUpdateAmount} />
              <Form.Check style={{ float: 'right', width: 130 }} label={`${t('default')} ${t('settings')}`} />
            </div>
            {index + 1 < dataManagementDetails.data.length && <HorizontalRule height="1px" />}
          </>
        ))}

        {/* <HorizontalRule height="1px" /> */}
        {/* <table className="float-end">
          {dataManagementDetails.amount.map((item, index) => (
            <tr key={index} style={{ fontSize: 15 }}>
              <td className="table-right table-body theme-text" style={{ width: 334 }}>
                {item.title}
              </td>
              <td className="text-right theme-text">{numberFormat(item.amount)}</td>
            </tr>
          ))}

          <tr style={{ fontSize: 15 }} className="mt-4">
            <td className="table-right table-body fs-text-700 pt-4 theme-text">ATT UTBETALA</td>
            <td className="text-right theme-text fs-text-700 pt-4">
              {numberFormat(dataManagementDetails.totalAmount)}
            </td>
          </tr>
        </table> */}
      </BSModal>
    </>
  );
}
