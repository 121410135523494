import { Dispatch, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Send } from 'react-feather';
import { SetStateAction } from 'react';
import { Consultants, ConsultantsFormGroup, LooseObject } from '../types';
import { useTranslation } from 'react-i18next';
import { useCreateConsultantContext } from '../context/useContext';
import useAuth from '../../../../hooks/useAuth';
import { postNewConsultant } from '../../../../api/service/superAdmin/consultants/service';

type Props = {
  toggleModal: () => void;
  setIsConsultantCreated: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
  formGroup: ConsultantsFormGroup;
  onClientCreated: () => boolean;
};

const STRING_TYPE = {
  DRAFT: 'DRAFT',
  SUBMIT: 'SUBMIT',
};

export default function CreateConsultantModalFooter({
  toggleModal,
  setIsConsultantCreated,
  setErrors,
  formGroup,
}: Props) {
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const { personalDetails } = useCreateConsultantContext();
  const { user } = useAuth();

  const handleSaveConsultant = async (type: string) => {
    const data = {
      fullName: personalDetails.fullName,
      personalNumber: personalDetails.personalNumber,
      email: personalDetails.email,
    } as Consultants | any;

    let params = {};
    if (type === STRING_TYPE.DRAFT) {
      setIsBusyDrafting(true);
      params = {
        draft: true,
        sendEmail: false,
      };
    } else {
      setIsBusy(true);
      params = {
        draft: false,
        sendEmails: true,
      };
    }

    try {
      formGroup.handleSubmit();
      setErrors(formGroup.errors);
      if (!formGroup.isValid) {
        return false;
      }

      await postNewConsultant(user?.role.company.id, data, params);

      toggleModal();
      setIsConsultantCreated(true);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
      setIsConsultantCreated(false);
    } finally {
      setIsBusy(false);
      setIsBusyDrafting(false);
    }
  };

  return (
    <div className="d-flex justify-content-between w-100">
      <Button className="app-btn-secondary cancel-btn" onClick={toggleModal}>
        {t('cancel')}
      </Button>

      <div className="d-flex gap-3">
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary"
          onClick={() => {
            handleSaveConsultant(STRING_TYPE.SUBMIT);
          }}
        >
          {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <Send className="icon-gap-right" />}
          {`${t('save')} ${t('consultant')} `}
        </Button>
      </div>
    </div>
  );
}
