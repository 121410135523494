import React from 'react';

import { LAYOUT } from '../constants';
import useSettingsState from '../hooks/useSettingsState';
import { UserRole } from '../types/auth';

const initialState = {
  layout: LAYOUT.FLUID,
  setLayout: (layout: string) => {},
};

const LayoutContext = React.createContext(initialState);

interface LayoutProviderType {
  children: React.ReactNode;
  roleType?: UserRole;
}

function LayoutProvider({ children, roleType }: LayoutProviderType) {
  const [layout, setLayout] = useSettingsState(
    'layout',
    roleType === UserRole.ACCOUNTING_FIRM ? LAYOUT.HORIZONTAL : LAYOUT.FLUID,
  );

  return (
    <LayoutContext.Provider
      value={{
        layout,
        setLayout,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };
