import * as yup from 'yup';
import {
    organizationNumberValidations,
    personalNumberValidations,
} from '../../../../../util/validation';

export const formSchema = yup.object().shape({
    companyDetails: yup.object().shape({
        organizationNumber: organizationNumberValidations(),
        name: yup.string().required('Required'),
        status: yup.string().required('Required'),
        contractNumber: yup.string().nullable(),
        resellerAgreement: yup.string().required('Required'),
    }),
    address: yup.object().shape({
        street: yup.string().required('Required'),
        city: yup.string().required('Required'),
        postcode: yup.string().required('Required'),
        country: yup.string().required('Required'),
    }),
    contractEffectiveFrom: yup.string().required('Required'),
    invoicingCostCenter: yup.string(),
    invoiceEmailAddress: yup.string().email('Invalid email'),
    combinations: yup
        .array()
        .of(
            yup.object().shape({
                authorizedSignatory: yup.lazy((authorizedSignatory, context) => {
                    const signee = context.parent.signee;

                    if (signee) {
                        return yup.array().of(
                            yup.object().shape({
                                personalNumber: personalNumberValidations(),
                                fullName: yup.string(),
                                email: yup.string().email('Invalid email').required('Required'),
                            }),
                        );
                    } else {
                        return yup.array().of(
                            yup.object().shape({
                                personalNumber: personalNumberValidations(),
                                fullName: yup.string(),
                                email: yup.string().email('Invalid email'),
                            }),
                        );
                    }
                }),
                signee: yup.boolean(),
            }),
        )
        .min(1, 'At least one signatory is required'),
});

