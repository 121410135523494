import { createContext, useState } from 'react';
import { useFormik } from 'formik';
import { AdminSettingsFormGroup, IAdminDetails, IAdminSettingsContext, IAdvisors } from '../types';
import { FORM_INITIAL_VALUES } from '../constants';
import { formSchema } from './schema';
import React, { useEffect, useImperativeHandle } from 'react';

type Props = {
  children: JSX.Element;
  patchAdminForm?: Function;
  emitValues: (...args) => void;
};

export const AdminSettingsContext = createContext<IAdminSettingsContext>({
  isBusy: false,
  adminDetails: [],
  setAdminDetails: () => {},
  advisors: [],
  setAdvisors: () => {},
  formGroup: {} as AdminSettingsFormGroup,
  replaceAdminDetails: () => {},
  replaceInsuranceAdvisors: () => {},
});

export const AdminSettingsProvider = React.forwardRef(({ children, patchAdminForm, emitValues }: Props, _ref: any) => {
  const [adminDetails, setAdminDetails] = useState<Array<IAdminDetails>>([]);
  const [advisors, setAdvisors] = useState<Array<IAdvisors>>([]);

  const [isBusy /*setIsBusy*/] = useState(false);

  const formGroup = useFormik({
    initialValues: FORM_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const replaceAdminDetails = (value) => {
    setAdminDetails(value);
    formGroup.setFieldValue('admins', value);
  };

  const replaceInsuranceAdvisors = (value) => {
    setAdvisors(value);
    formGroup.setFieldValue('advisors', value);
  };

  useImperativeHandle(
    _ref,
    () => {
      return {
        formGroup: formGroup,
      };
    },
    [formGroup.values],
  );

  // useEffect(() => {
  //   patchAdminForm!(formGroup);
  // }, [formGroup.values, formGroup.errors]);

  useEffect(() => {
    emitValues({
      formGroup,
    });
  }, [formGroup.values]);

  const context: IAdminSettingsContext = {
    isBusy,
    adminDetails,
    setAdminDetails,
    advisors,
    setAdvisors,
    formGroup: formGroup as any,
    replaceAdminDetails,
    replaceInsuranceAdvisors,
  };
  return <AdminSettingsContext.Provider value={context}>{children}</AdminSettingsContext.Provider>;
});

AdminSettingsProvider.displayName = 'AdminSettingsProvider';
