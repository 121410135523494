import HeroImg from '../../assets/img/auth/hero-company.svg';

const CompanyBanner = () => {
  return (
    <div className="hero-image" style={{ backgroundImage: `url(${HeroImg})` }}>
      <h2 className="hero-title">
        Korrekt, effektivt, <br /> spårbart och enkelt
      </h2>
    </div>
  );
};

export default CompanyBanner;
