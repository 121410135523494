import { useEffect } from 'react';
import { getProfileDetails, updateProfileDetails } from '../services/profile.service';
import { useProfileContext } from '../context/useProfileContext';
import { INITIAL_PROFILE } from '../constants';
import { deepClone } from '../../../../util/util';

const useProfileContainer = () => {
  const { setProfileDetails, setLoading, profileFormGroup, loading, profileDetails } = useProfileContext();

  const fetchProfileDetails = async () => {
    try {
      const profileRes = await getProfileDetails();

      setProfileDetails(deepClone(profileRes));
      profileFormGroup.setValues(profileRes);

      setLoading((prev) => {
        return {
          ...prev,
          fetch: true,
        };
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((prev) => {
        return {
          ...prev,
          fetch: true,
        };
      });
    }
  };

  const updateProfile = async (profileDetails) => {
    try {
      setLoading((prev) => {
        return {
          ...prev,
          save: true,
        };
      });

      const payload = {
        phoneNumber: profileDetails?.phoneNumber,
        email: profileDetails.email,
      };

      await updateProfileDetails(payload);
      await fetchProfileDetails();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading((prev) => {
        return {
          ...prev,
          save: false,
        };
      });
    }
  };

  const cancelChanges = () => {
    profileFormGroup.setFieldValue('phoneNumber', profileDetails.phoneNumber || INITIAL_PROFILE.phoneNumber);
    profileFormGroup.setFieldValue('email', profileDetails.email || INITIAL_PROFILE.email);
  };

  useEffect(() => {
    fetchProfileDetails();
  }, []);

  return {
    updateProfile,
    cancelChanges,
    profileDetails,
    loading,
  };
};

export default useProfileContainer;
