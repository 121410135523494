import { Link } from 'react-router-dom';
import { ReactComponent as InitalSortArrow } from '../../assets/img/sorting.svg';
import { ReactComponent as SortArrowDown } from '../../assets/img/sorting-arrow-down.svg';
import { ReactComponent as SortArrowUp } from '../../assets/img/sorting-arrow-up.svg';

export const fullNameFormatter = (cell, row) => {
  return (
    <Link style={{ color: '#2B2B2B' }} to={`${row.id}`}>
      {cell}
    </Link>
  );
};

export const sortCaret = (order, column) => {
  if (!order) return <InitalSortArrow />;
  else if (order === 'asc') return <SortArrowDown />;
  else if (order === 'desc') return <SortArrowUp />;
  return null;
};
