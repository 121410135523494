import { Dispatch, SetStateAction, useRef, useState } from 'react';
import {
  deletePolicy,
  duplicatePolicy,
  getPolicies,
} from '../../../../api/service/superAdmin/pension-policies/service';
import { ConfirmationDialog } from '../../../../util/dialogs';
import { PensionPolicy } from '../types';
import useLocale from '../../../../hooks/useLocale';

interface InputProps {
  setIsBusy: Dispatch<SetStateAction<boolean>>;
  isBusy: boolean;
}

export const usePolicyList = ({ setIsBusy, isBusy }: InputProps) => {
  const searchRef = useRef<any>();
  const { localize } = useLocale();

  const [pensionPolicyList, setPensionPolicyList] = useState<PensionPolicy[]>([]);
  const [result, setResult] = useState<any>();

  const fetchAllPensionPolicy = async ({
    type = 'All',
    employeeType = '',
    page = 1,
    sizePerPage = 25,
    searchString = '',
    sortField = 'name',
    sortOrder = '1',
  } = {}) => {
    try {
      setIsBusy(true);

      const res = await getPolicies({
        type,
        employeeType,
        page,
        sizePerPage,
        searchString,
        sortField,
        sortOrder,
        paginated: true,
      });

      setPensionPolicyList(res.data);
      setResult(res);
    } catch (e) {
      console.error(e);
    } finally {
      setIsBusy(false);
    }
  };

  const onDelete = async (id) => {
    const result = await ConfirmationDialog({
      title: 'Are you sure you want to delete this policy?',
      confirmButtonText: localize('yes'),
      denyButtonText: localize('no'),
    });
    if (result.isConfirmed) {
      setIsBusy(true);
      await deletePolicy(id);
      await fetchAllPensionPolicy();
      setIsBusy(false);
    }
  };

  const onCopy = async (id) => {
    setIsBusy(true);
    await duplicatePolicy(id);
    await fetchAllPensionPolicy();
    setIsBusy(false);
  };

  const onSearch = async () => {
    const searchTerm = searchRef.current['value'];
    fetchAllPensionPolicy({ searchString: searchTerm });
  };

  return {
    pensionPolicyList,
    result,
    searchRef,
    fetchAllPensionPolicy,
    onDelete,
    onCopy,
    onSearch,
  };
};
