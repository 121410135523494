import { FieldMetaProps } from 'formik';
import ErrorAlert from './ErrorAlert';
import { flattenObj } from '../util/util';

type Props = {
  msg?: string | undefined;
  field?: FieldMetaProps<any>;
  behavior?: 'alert';
};

export const ErrorMsg = ({ msg }: { msg: string }) => {
  return <div className="color-error">{msg}</div>;
};

export default function ErrorFeedback({ msg, field, behavior }: Props) {
  if (field) {
    if (field.touched && field.error) {
      if (behavior === 'alert') {
        let errorMsg;

        if (typeof field.error === 'string') {
          errorMsg = field.error;
        } else if (typeof field.error === 'object') {
          errorMsg = flattenObj(field.error);
        }

        return <ErrorAlert inline show msg={errorMsg} />;
      } else {
        return <ErrorMsg msg={field.error} />;
      }
    } else {
      return <></>;
    }
  }

  if (!msg) {
    return <></>;
  }

  return <ErrorMsg msg={msg} />;
}
