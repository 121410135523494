type Props = {
  color?: string;
  height?: string;
  marginBottom?: string;
};

function HorizontalRule({ color = '#000', height = '3px', marginBottom }: Props) {
  return (
    <hr
      style={{
        height,
        border: 'none',
        color,
        backgroundColor: color,
        margin: "12px 0",
        marginBottom: marginBottom ?? '12px',
      }}
    />
  );
}

export default HorizontalRule;
