import BSTable from '../../../../components/table/BSTable';
import { Button, Dropdown, Row, Spinner, Form } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { Menu } from 'react-feather';
import useAllEmployeeYearlySalaryReport from '../hooks/useAllEmployeeYearlySalaryReport';
import { getCompanyHolidayPayLiabilityReports } from '../../../../api/service/company/reports/service';
import { Calendar } from '../../../../util/calendar';
import { useTranslation } from 'react-i18next';

export const HolidayPayLiabilityReport = () => {
  const { t } = useTranslation();
  const { reportHeaders, reportRecords, isLoading, years, setYearFilter } = useAllEmployeeYearlySalaryReport(
    getCompanyHolidayPayLiabilityReports,
  );

  return (
    <>
      <Row className="justify-content-between align-items-end">
        <div className="mt-5">
          <PagesHeader pageTitle="All Employees" headingClass="fs-page-title" />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Form.Group className="me-3">
            <Form.Label className="fs-name">{t('year_period')}</Form.Label>
            <Form.Select
              name="year"
              className="text-left"
              onChange={(e) => {
                setYearFilter(e.target.value);
              }}
            >
              {years.map((year) => (
                <option value={year} key={year}>
                  {year} {Calendar.isCurrentYear(year) ? 'YTD' : ''}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Dropdown className="nav-item" align="end">
            <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
              <Button className="app-btn-secondary">
                <Menu className="theme-text" />
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item onClick={() => printReport()}>Print Chart</Dropdown.Item> */}
              <Dropdown.Item>{t('download_csv')}</Dropdown.Item>
              <Dropdown.Item>{t('download_xls')}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="crt-table-report">
            <BSTable data={reportRecords} columns={reportHeaders} noDataIndication={`${t('no_reports_found')}`} />
          </div>
        )}
      </Row>
    </>
  );
};
