export const INITIAL_CONSULTANTS_DETAILS = {
    name: '',
    personalNumber: '',
    role: '',
    canAddClients: false,
    clients: '',
    status: '',
    lastInteraction: ''
}

export const INITIAL_STATISTICS = {
    lastInteraction: '',
    noOfClients: '',
};

export const INITIAL_PERSONAL_DETAILS = {

    personalNumber: '',
    fullName: '',
    firstName: '',
    lastName: '',
    status: 'Active',
    email: '',

};
export const INITIAL_USER_SETTINGS = {
    role: ''
};
