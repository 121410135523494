import classNames from 'classnames';
import React from 'react';
import { getInitialName } from '../../util/util';
import styles from './Avatar.module.scss';

interface AvatarProps {
  name: string;
  className?: string;
  displayName?: boolean;
  underlined?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({ name, className, displayName, underlined }) => {
  const initialName = React.useMemo(() => (name ? getInitialName(name) : ''), [name]);
  const randomColor = React.useMemo(() => '#' + Math.floor(Math.random() * 16777215).toString(16), []);

  return (
    <div className={styles.avatar_col}>
      <div className={classNames(styles.avatar, className)} style={{ backgroundColor: randomColor }}>
        <span className={classNames({ [styles.avatar__placeholder]: underlined })}>{initialName}</span>
      </div>
      {displayName && <span className="text-black">{name}</span>}
    </div>
  );
};

export default React.memo(Avatar);
