import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { ReactComponent as PDFIcon } from '../../../assets/img/file-PDF.svg';
import { Link } from 'react-router-dom';
import CardComponent from '../../../components/base/CardComponent';
// import PagesHeader from '../../../components/base/PagesHeader';
import { ISettingProxyDoc } from './types';
import { getProxyDocuments, getServiceAgreementDocuments } from '../../../api/service/company/settings';
import useAuth from '../../../hooks/useAuth';
import { openExternalLink } from '../../../util';
import { ICompanyClientLegalDocuments } from '../../../api/service/superAdmin/company-clients/types';
import PagesHeader from '../../../components/base/PagesHeader';

export const Legal = () => {
  const [legalDocs, setLegalDocs] = useState<ICompanyClientLegalDocuments>();
  const [, /*proxyDocs*/ setProxyDocs] = useState<ISettingProxyDoc[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const fetchAllDocuments = async () => {
    try {
      setIsLoading(true);
      const serviceDocs = await getServiceAgreementDocuments(user?.role.company.id);
      const proxyDocs = await getProxyDocuments();
      setLegalDocs(serviceDocs);
      setProxyDocs(proxyDocs);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllDocuments();
  }, []);

  return (
    <>
      <CardComponent>
        <PagesHeader pageTitle="Service agreement" headingClass="fs-h2 font-black" />

        {isLoading ? (
          <Spinner />
        ) : (
          <div className="table-responsive react-bootstrap-table mb-5">
            <table className="table table-hover table-condensed">
              <tbody className="remote-table-body">
                {legalDocs?.document?.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="pdf-icon">
                            <PDFIcon />
                          </span>
                          <Link
                            to={item.path}
                            onClick={(e) => openExternalLink(item.path, { event: e })}
                            target="_blank"
                            rel="noreferrer"
                            className="fs-h3 ms-2 font-link"
                          >
                            {item.name}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {/* <PagesHeader pageTitle="Proxy" headingClass="fs-page-title" />

        {isLoading ? (
          <Spinner />
        ) : (
          <div className="table-responsive react-bootstrap-table">
            <table className="table table-hover table-condensed">
              <tbody className="remote-table-body">
                {proxyDocs.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="pdf-icon">
                            <PDFIcon />
                          </span>
                          <Link to="#" target="_blank" rel="noreferrer" className="fs-h3 ms-2 font-link">
                            {item.title}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )} */}
      </CardComponent>
    </>
  );
};
