import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IBreadCrumb } from '../../types/base/BreadCrumb';

type Props = {
  navItem: IBreadCrumb[];
};

function Breadcrumb({ navItem }: Props) {
  const { t } = useTranslation();
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {React.Children.toArray(
          navItem.map((item) => {
            const breadcrumbTitle =
              item.translate === false ? item.name : t(item.name.toLowerCase().split(' ').join('_'));
            return (
              breadcrumbTitle && (
                <li className="breadcrumb-item fs-text-400 theme-text" aria-current="page">
                  {item.href ? <Link to={item.href}>{breadcrumbTitle}</Link> : breadcrumbTitle}
                </li>
              )
            );
          }),
        )}
      </ol>
    </nav>
  );
}

export default Breadcrumb;
