import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import omit from 'lodash/omit';

import BSModal from '../../../components/Modal';
import FileUpload from '../Settings/FileUpload/FileUpload';
import { uploadSalaryFiles } from '../../../api/service/company/settings';
import { Check, UploadCloud } from 'react-feather';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';

type Props = {
  show: boolean;
  isUploaded: boolean;
  toggleModal: () => void;
  onUploaded: (args) => void;
};

export default function FileUploadModal({ show, isUploaded, toggleModal, onUploaded }: Props) {
  const [isBusy, setIsBusy] = useState(false);
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const { user } = useAuth();

  const handleSubmit = async () => {
    try {
      setIsBusy(true);
      const _files = files.map((i) => omit(i, ['id']));
      const filesFormData = new FormData();
      _files.forEach((element) => {
        filesFormData.append('files', element.file);
      });
      await uploadSalaryFiles(filesFormData, user?.role.company.id);
      onUploaded(true);
      _files.forEach((element) => element.remove());
    } finally {
      setIsBusy(false);
    }
  };

  const closeModal = () => {
    toggleModal();
  };

  return (
    <BSModal
      modalWidthClass="modal-width-70"
      show={show}
      onHide={toggleModal}
      title={t('file_upload')}
      ModalFooter={
        <>
          <Button onClick={toggleModal} className="app-btn-secondary cancel-btn">
            {t('cancel')}
          </Button>
          {!isUploaded && (
            <Button disabled={files.length === 0 || isBusy} onClick={handleSubmit} className="app-btn-primary mt-2">
              {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <UploadCloud className="icon-gap-right" />}{' '}
              {`${t('upload_file')} `}
            </Button>
          )}
          {isUploaded && (
            <Button onClick={closeModal} className="app-btn-primary mt-2">
              <Check className="icon-gap-right" />
              {`${t('done')} `}
            </Button>
          )}
        </>
      }
    >
      <FileUpload onFilesUpload={setFiles} />
    </BSModal>
  );
}
