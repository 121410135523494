import { Dispatch, useState, SetStateAction } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Plus } from 'react-feather';
import { LooseObject } from '../../types';
import { useTranslation } from 'react-i18next';
import useContext from '../context/useContext';
import { Mode } from '../../types';
import { useSuperAdminSettingsContext } from '../../useContext';
import { createAccountingSalaryCompany } from '../../../../../api/service/superAdmin/settings/accounting-salary-company';

type Props = {
  toggleModal: () => void;
  setIsAccountingSalaryCompanyCreated: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
};

export const CreateAccountingSalaryCompanyModalFooter = ({
  toggleModal,
  setErrors,
  setIsAccountingSalaryCompanyCreated,
}: Props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const [, /*isSaving*/ setIsSaving] = useState(false);
  const { t } = useTranslation();
  const { accountingSalaryCompany, formGroup, contactInformation } = useContext();
  const { setAccountingMode } = useSuperAdminSettingsContext();
  const handleSaveCompany = async () => {
    const data = {
      ...accountingSalaryCompany,
      contactInformation,
    };
    formGroup.handleSubmit();
    formGroup.validateForm().then((errors) => {
      setErrors(errors);
    });
    if (!formGroup.isValid) {
      setIsBusy(false);
      setIsBusyDrafting(false);
      return false;
    }

    try {
      setIsBusy(true);
      await createAccountingSalaryCompany(data);
      setAccountingMode(Mode.List);
      toggleModal();
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsSaving(false);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <Button className="app-btn-secondary cancel-btn" onClick={toggleModal}>
          {t('cancel')}
        </Button>

        <div className="d-flex gap-3">
          <Button
            disabled={isBusy || isBusyDrafting}
            type="button"
            className="app-btn-primary"
            onClick={handleSaveCompany}
          >
            {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <Plus className="icon-gap-right" />}
            {`${t('add_accouting_and_salary_company')}`}
          </Button>
        </div>
      </div>
    </>
  );
};
