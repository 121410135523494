import { Container, Button, Col, Row } from 'react-bootstrap';
import { Users } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import filter from 'lodash/filter';
import RoleAccess from '../../components/base/RoleAccess';
import { UserSubRole } from '../../types/auth';
import {
  getCollectionItem,
  getCollections,
  gettingStartedCategoryId,
  postsCategoryId,
} from '../../api/service/webflow/service';
import { ChangelogPostItem, Item, PostType } from '../../api/service/webflow/types';
import { NumberCards } from '../company/Overview/NumberCards';
import { ChartsSection } from '../company/Overview/ChartsSection';
import PagesHeader from '../../components/base/PagesHeader';
import moment from 'moment';
import { ReactComponent as CompanyIcon } from '../../assets/img/icons/company-2.svg';
import BSModal from '../../components/Modal';
import { getAccountingfirmDashboardData } from '../../api/service/accountingFirm/clients/service';
import { useTranslation } from 'react-i18next';

const currentDate = `${moment().format('MMMM')} ${moment().date()}`;
type OverviewCollectionItemState = {
  getStartedItems?: Item[];
  blogs?: Item[];
  instructions?: Item[];
};

export const AccountingFirmOverview = () => {
  const navigate = useNavigate();
  const [, /*isBusy*/ setIsBusy] = useState(true);
  const [, /*collectionItems*/ setCollectionItems] = useState<OverviewCollectionItemState>({
    getStartedItems: [],
    blogs: [],
    instructions: [],
  });
  const { t } = useTranslation();
  const [changelog, setChangelog] = useState<ChangelogPostItem[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [overviewCardsData, setOverviewCardsData] = useState<any>();
  const [overviewChartData, setOverviewChartData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const fetchOverviewData = async () => {
    const overviewData = await getAccountingfirmDashboardData();
    setOverviewChartData({ pieChart: overviewData?.pie, lineChart: overviewData?.lineChart });
    setOverviewCardsData({
      totalCompanyClients: overviewData.totalCompanyClients,
      totalEmployees: overviewData.totalEmployees,
      totalSalaries: overviewData.totalSalaries,
      socialFees: overviewData.socialFees,
      totalPension: overviewData.totalPension,
    });
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchOverviewData();
  }, []);

  const changelogItem = changelog[0];
  const containerRef = useRef<any>();

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.firstElementChild.classList.add('fs-text-400');
      containerRef.current.firstElementChild.classList.add('theme-text');
    }
  });

  const fetchCollectionItems = async () => {
    try {
      const collections = await getCollections();
      collections.forEach(async (collection) => {
        const collectionItem = await getCollectionItem(collection._id!);
        const isChangelogPosts = collection.type === PostType.ChangelogPosts;
        const isOverviewPosts = collection.type === PostType.OverviewPosts;

        setIsBusy(false);
        if (collectionItem.items.length > 0) {
          if (isOverviewPosts) {
            setCollectionItems({
              blogs: filter(collectionItem.items, (i) => i['post-type'] !== gettingStartedCategoryId),
              getStartedItems: filter(collectionItem.items, { 'post-type': gettingStartedCategoryId }),
              instructions: filter(collectionItem.items, { 'post-type': postsCategoryId }),
            });
          } else if (isChangelogPosts) {
            setChangelog(collectionItem.items as ChangelogPostItem[]);
            setShowModal(true);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    fetchCollectionItems();
    fetchOverviewData();
  }, []);
  return (
    <Container fluid className="p-0">
      <PagesHeader headingClass="fs-h1" pageTitle={currentDate} translate={false} />
      <div className="d-flex gap-2">
        <div style={{ width: '100%' }} className="p-0">
          <Row>
            <Col>
              <NumberCards cardsData={overviewCardsData ?? {}} isLoading={isLoading} />
              {/* <DashboardErrorAlert /> */}
            </Col>
          </Row>

          <ChartsSection chartData={overviewChartData ?? {}} isLoading={isLoading} />
        </div>
        <div>
          <div className="mt-3">
            <div>
              <RoleAccess hasWithoutSubRole={true} allowedRoles={[UserSubRole.SUPERVISOR]} roleType="secondary">
                <Button
                  className="app-btn-primary me-2 mb-2"
                  onClick={() => navigate('/accounting-firms')}
                  style={{ width: 186, textAlign: 'start' }}
                >
                  <span className="building-icon">
                    <CompanyIcon className="icon-gap-right" />
                  </span>
                  {t('clients')}
                </Button>
              </RoleAccess>
            </div>
            <div>
              <RoleAccess hasWithoutSubRole={true} allowedRoles={[UserSubRole.SUPERVISOR]} roleType="secondary">
                <Button
                  className="app-btn-primary me-2"
                  onClick={() => navigate('/consultant')}
                  style={{ width: 186, textAlign: 'start' }}
                >
                  <Users className="icon-gap-right" />
                  {t('consultants')}
                </Button>
              </RoleAccess>
            </div>
            <div>
              <RoleAccess allowedRoles={[UserSubRole.SENIOR_CONSULTANT]} roleType="secondary">
                <Button
                  className="app-btn-primary me-2"
                  onClick={() => navigate('/accounting-firms')}
                  style={{ width: 186, textAlign: 'start' }}
                >
                  <span className="building-icon">
                    <CompanyIcon className="icon-gap-right" />
                  </span>
                  {t('my_clients')}
                </Button>
              </RoleAccess>
            </div>
          </div>
          {/* {isBusy ? <Spinner size="sm" /> : <BlogsSection blogs={collectionItems.blogs!} />} */}
        </div>
      </div>
      {
        //TODO: Use showModal state instead of false to enable the model
        false && (
          <BSModal
            className="overview-feature-modals"
            onHide={() => setShowModal(false)}
            title=""
            show={showModal}
            ModalFooter={
              <Button className="app-btn-primary ms-auto" onClick={toggleModal}>
                {t('continue')}
              </Button>
            }
          >
            <img
              style={{ width: '100%', objectFit: 'cover' }}
              height={354}
              src={changelogItem['top-image'].url}
              alt="logo"
            />
            <div style={{ padding: 'var(--bs-modal-padding)' }}>
              <h1 className="fs-h1 mt-3 theme-text">{changelogItem.heading}</h1>
              <div ref={containerRef} dangerouslySetInnerHTML={{ __html: changelogItem['body-content'] }} />
            </div>
          </BSModal>
        )
      }
    </Container>
  );
};
