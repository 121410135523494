import { Navbar, Nav } from 'react-bootstrap';

import useSidebar from '../../hooks/useSidebar';
import { AuthUser, UserRole } from '../../types/auth';
import { NavMenuItem } from '../../types/sidebar';
import { hasAccountingFirmRole } from '../../util/general';
import CompanySwitcher from './CompanySwitcher';
import NavbarLanguages from './NavbarLanguages';

import NavbarUser from './NavbarUser';
import TopMenu from './TopMenu';
import { getInitializedUserSession, switchUserSessionToInitial } from '../../api/service/auth/service';
import { Home } from 'react-feather';

interface NavbarProps {
  roleType?: UserRole;
  items: NavMenuItem[];
  logo?: string;
  user: AuthUser;
}

const NavbarComponent = ({ roleType, items, logo, user }: NavbarProps) => {
  const { isOpen, setIsOpen } = useSidebar();
  const initializedUserSession = getInitializedUserSession();

  const switchToInitializeSession = () => {
    const session = switchUserSessionToInitial();

    window.location.href = session.redirectTo;
  };

  if (roleType === UserRole.ACCOUNTING_FIRM) {
    return (
      <Navbar variant="light" expand className="navbar-bg">
        <TopMenu logo={logo as string} items={items} />
      </Navbar>
    );
  }

  return (
    <Navbar variant="light" expand className="navbar-bg">
      {
        <span
          className="sidebar-toggle d-flex"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <i className="hamburger align-self-center image-filter" />
        </span>
      }
      {initializedUserSession && (
        <Home
          className="icon-gap-right cursor-pointer image-filter"
          stroke="#2F363D"
          onClick={() => switchToInitializeSession()}
        />
      )}
      {initializedUserSession && hasAccountingFirmRole(initializedUserSession!.role) && (
        <>
          <CompanySwitcher user={user} />
        </>
      )}
      <Navbar.Collapse>
        <Nav className="navbar-align align-items-center main-dashboard--horizontal">
          {initializedUserSession && (
            <span className="nav-item nav-item--company-title">{initializedUserSession?.role?.company?.name}</span>
          )}
          <NavbarLanguages />
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
