import { Row } from 'react-bootstrap';
import PagesHeader from '../../../../../components/base/PagesHeader';
import { FormProps } from './types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Copy } from 'react-feather';
import { copyToClipboard, generateEmailDomain } from '../../../../../util';
import TooltipBox from '../../../../../components/base/Overlay/TooltipBox';

export const FileUpload = ({ context }: FormProps) => {
  const { companyDetails } = context;
  const { t } = useTranslation();
  const emailTo = generateEmailDomain(companyDetails?.organizationNumber);

  return (
    <div>
      <PagesHeader pageTitle={t('SETTINGS.file_upload')} headingClass="fs-h3 font-black" translate={false} />
      <div className="form-section mb-3 dark-content-section">
        <Row className="align-items-end">
          <div className="d-flex align-items-center">
            <p className="theme-text mb-0 me-4">
              {t('SETTINGS.file_upload_text')}{' '}
              <Link onClickCapture={() => copyToClipboard(emailTo)} to={`mailto:${emailTo}`}>
                {emailTo}
              </Link>
            </p>
            <span className="mb-0" onClick={() => copyToClipboard(emailTo)} style={{ cursor: 'pointer' }}>
              <TooltipBox msg={`${t('copy')}`}>
                <Copy className="blue-icon-btn" />
              </TooltipBox>
            </span>
          </div>
        </Row>
      </div>
    </div>
  );
};
