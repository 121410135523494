import { useTranslation } from 'react-i18next';
import { getPropByString, parseString } from '../util/util';
import { LOCALE_KEYS } from '../config/localization-keys.config';

const useLocalizationResolver = () => {
  const { t } = useTranslation();

  const getLang = (key: string) => {
    if (key && typeof key !== 'string') {
      return parseString(key);
    }

    const resolvedValue = getPropByString(LOCALE_KEYS, key);

    if (typeof resolvedValue !== 'string') {
      return key;
    }

    const mappedKey = t(resolvedValue);

    return mappedKey || key;
  };

  return { getLang };
};

export default useLocalizationResolver;
