import PagesHeader from '../../../../components/base/PagesHeader';
import { MultiSeriesChart } from '../../../../components/charts/multi-series/MultiSeriesChart';
import { useEmployeeModal } from '../components/EmployeeModal/hooks/useEmployeeModal';
import { useEffect, useState } from 'react';
import CustomChartSwitchFilter from './CustomChartSwitchFilter';
import { EmployeeModal } from '../components/EmployeeModal/EmployeeModal';
import { CUSTOM_REPORT_TYPES } from '../constants';
import { useChartTypeModal } from '../components/ChartTypeModal/hooks/useChartTypeModal';
import { ChartTypeModal } from '../components/ChartTypeModal/ChartTypeModal';
import { EmployeeSelectorChart } from './EmployeeSelectorChart';
import { ISeries } from '../../../../components/charts/multi-series/types';
import { ChevronLeft } from 'react-feather';
import useCustomEmployeeChart from './hooks/useCustomEmployeeChart';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const CustomizableChart = () => {
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const [reportTypeSeries, setReportTypeSeries] = useState<ISeries[]>([
    ...CUSTOM_REPORT_TYPES.map((item) => {
      return {
        label: t(item.labelKey),
        field: item.field,
        metaData: { ...item, label: t(item.labelKey) },
        labelKey: item.labelKey,
      };
    }),
  ]);
  const [selectedEmployee, setSelectedEmployee] = useState<ISeries | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<[string, string] | []>([]);

  const {
    seriesOptions,
    employeeSeries,
    dateRange,
    setDateRange,
    chartRecords,
    isLoading,
    changeDateHandler,
    addEmployeeToChart,
    deleteHandler,
  } = useCustomEmployeeChart(reportTypeSeries);
  const { showEmployeeModal, toggleModal, addSeriesHandler } = useEmployeeModal();
  const { showReportTypeModal, toggleReportModal, addReportSeriesHandler } = useChartTypeModal();

  const employeeOptions = employeeSeries.map((item) => {
    return { label: item.label, value: item.value };
  });
  // const reportTypeOptions = CUSTOM_REPORT_TYPES.map((item) => item.label);
  const reportTypeOptions = reportTypeSeries.map((item) => {
    return { label: item.label, value: item.value, labelKey: item.labelKey };
  });

  const onSelectEmployee = (value: any) => {
    const foundEmployee = employeeSeries.find((employee) => employee.label === value);

    if (foundEmployee && foundEmployee.field !== 'total') {
      setSelectedEmployee(foundEmployee);
    }
  };

  const selectReportType = (report) => {
    setReportTypeSeries([
      ...reportTypeSeries,
      {
        label: t(report.labelKey),
        labelKey: report.labelKey,
        field: report.field,
        metaData: { ...report, label: t(report.labelKey) },
      },
    ]);
  };

  const removeReportType = (report) => {
    const _reportTypeSeries = reportTypeSeries.filter((e) => e.field !== report.field);
    setReportTypeSeries([..._reportTypeSeries]);
  };

  /* const addAllReportTypes = () => {
    setReportTypeSeries([
      ...reportTypeSeries,
      ,
    ]);
  }; */

  const updateCalendarDateRange = (value) => {
    setDateRange(value);
  };

  const updateSelectedDateRange = (value) => {
    setSelectedDateRange(value);
  };

  const navigateToBack = () => {
    setSelectedEmployee(null);
  };

  useEffect(() => {
    updateSelectedDateRange(dateRange);
  }, [dateRange]);

  useEffect(() => {
    if (!lang) {
      return;
    }

    setReportTypeSeries((prev) => {
      return prev.map((item) => {
        return {
          label: t(item.labelKey!),
          field: item.field,
          metaData: { ...item, label: t(item.labelKey!) },
          labelKey: item.labelKey,
        };
      });
    });
  }, [lang]);

  return (
    <>
      <PagesHeader pageTitle="Comparison" headingClass="fs-page-title" />
      {selectedEmployee && (
        <div className="mb-2">
          <button className="app-btn-secondary btn btn-primary" onClick={() => navigateToBack()}>
            <ChevronLeft /> {t('back')}
          </button>
        </div>
      )}
      <p className="theme-text">{t('selected_employees_for_comparison')}</p>
      <div className={classNames('chart mt-3', !selectedEmployee ? 'customzible-chart' : '')}>
        {!selectedEmployee && (
          <MultiSeriesChart
            seriesOptions={{ ...seriesOptions, onSelectEmployeeSeries: onSelectEmployee }}
            data={chartRecords}
            addSeriesHandler={addSeriesHandler}
            removeSeriesHandler={deleteHandler}
            initialDateRange={dateRange}
            changeDateHandler={changeDateHandler}
            isLoading={isLoading}
            onSelectEmployee={onSelectEmployee}
            postFilterSection={
              <CustomChartSwitchFilter
                addBtnText={`${t('add_type')}`}
                showTotalOption
                showAddBtn={reportTypeOptions.length < 5}
                options={reportTypeOptions}
                note={
                  <p className="theme-text my-2">
                    <span className="fs-name">{t('employee_drilling_types')}</span> ({t('drill_down_on_employee')})
                  </p>
                }
                openAddFilterModal={addReportSeriesHandler}
                translate={true}
              />
            }
          ></MultiSeriesChart>
        )}
        {selectedEmployee && (
          <EmployeeSelectorChart
            selectedEmployee={selectedEmployee!}
            selectedReportTypeSeries={reportTypeSeries}
            selectedDateRange={selectedDateRange}
            employeeOptions={employeeOptions}
            addReportSeriesHandler={addReportSeriesHandler}
            removeReportSeriesHandler={removeReportType}
            updateCalendarDateRange={updateCalendarDateRange}
          />
        )}
        {showEmployeeModal && (
          <EmployeeModal
            showEmployeeModal={showEmployeeModal}
            toggleModal={toggleModal}
            employeeList={employeeSeries}
            addEmployeeToChart={addEmployeeToChart}
          />
        )}
        {showReportTypeModal && (
          <ChartTypeModal
            showReportTypeModal={showReportTypeModal}
            toggleModal={toggleReportModal}
            preSelectedReportTypes={reportTypeSeries}
            addReportToChart={selectReportType}
          />
        )}
      </div>
    </>
  );
};
