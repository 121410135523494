import classNames from 'classnames';
import { useEffect, useState, useMemo, FC, useCallback } from 'react';
import ReactBootstrapPagination from 'react-bootstrap/Pagination';
import { ChevronLeft, ChevronRight } from 'react-feather';

const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

type Props = {
  total: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange?: (page: number) => void;
};

const Pagination: FC<Props> = ({ total, itemsPerPage, currentPage, onPageChange }) => {
  const [totalPages, setTotalPages] = useState<number>(0);

  const onPageChangeHandler = useCallback(
    (page: number) => {
      scrollToTop();
      onPageChange && onPageChange(page);
    },
    [onPageChange],
  );

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages: JSX.Element[] = [];

    for (let i = 1; i <= totalPages; i++) {
      const isActive = i === currentPage;
      pages.push(
        <ReactBootstrapPagination.Item
          className="mx-1"
          key={i}
          active={isActive}
          onClick={() => onPageChangeHandler(i)}
        >
          <span className={classNames('fs-label', { 'mt-1': isActive })}>{i}</span>
          {isActive && <div style={{ borderBottom: '4px solid #EAB600', width: '120%', borderRadius: 10 }}></div>}
        </ReactBootstrapPagination.Item>,
      );
    }

    return pages;
  }, [totalPages, currentPage, onPageChangeHandler]);

  if (totalPages === 0) return null;

  return (
    <ReactBootstrapPagination>
      <ReactBootstrapPagination.Item onClick={() => onPageChangeHandler(currentPage - 1)} disabled={currentPage === 1}>
        <ChevronLeft stroke="#FFFFFF" />
      </ReactBootstrapPagination.Item>
      {paginationItems}
      <ReactBootstrapPagination.Item
        onClick={() => onPageChangeHandler(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <ChevronRight stroke="#FFFFFF" />
      </ReactBootstrapPagination.Item>
    </ReactBootstrapPagination>
  );
};

export default Pagination;
