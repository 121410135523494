import React from 'react';
import { Accordion } from 'react-bootstrap';
import classNames from 'classnames';
import { InsurancePlanKey, Mode } from '../../types';
import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/settings/insurance.module.scss';
import useInsuranceCostSpecification from '../hooks/useInsuranceCostSpecification';
import InsuranceCostSpecification from './InsuranceCostSpecification';

type Props = {
  planIndex: number;
  mode: Mode;
  planType: InsurancePlanKey;
};

const InsuranceCostSpecificationViewDetails = ({ planType, planIndex, mode }: Props) => {
  const { getCostSpecification } = useInsuranceCostSpecification(planType, planIndex);
  const costSpecControl = getCostSpecification(planIndex);
  const isViewMode = mode === Mode.Detail;

  if (isViewMode && !costSpecControl?.description && !costSpecControl?.specItems.length) {
    return <></>;
  }

  return (
    <div className={classNames('insurance-table-accordion editor-accordion pp-accordion mb-2', themeStyle.accordion)}>
      <Accordion defaultActiveKey="costSpec" className="policy-desc-accordion">
        <Accordion.Item eventKey="costSpec">
          <Accordion.Header>
            <h4 className="fs-h4 theme-text-dark text-bold">Cost specification</h4>
          </Accordion.Header>
          <Accordion.Body className={classNames('policy-accordion-body', themeStyle.accordion_body)}>
            <InsuranceCostSpecification planType={planType} planIndex={planIndex} mode={mode} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default InsuranceCostSpecificationViewDetails;
