import { PensionPolicyStatus, PensionPolicyVariant } from '../types';

interface Props {
  policyForm: any;
  formGroup: any;
  isSuperAdmin: boolean;
}

interface EmployeePersonalProps {
  policyForm: any;
  formGroup: any;
  defaultPolicyInput: any;
}
export class PensionPolicyDTO {
  static create({ policyForm, formGroup, isSuperAdmin }: Props) {
    let modifiedSalaryTypePayload;
    const isTemplateCalculation = formGroup.policySettings.salaryType.isTemplateCalculation;

    if (isTemplateCalculation) {
      modifiedSalaryTypePayload = {
        isTemplateCalculation: isTemplateCalculation,
        salaryTypesWithHolidays: formGroup.policySettings.salaryType.salaryTypesWithHolidays
          .filter((item) => {
            return item.isEnable;
          })
          .map((value) => {
            value.data = value.data.filter((item) => item.salaryType);

            return value;
          }),
      };
    } else {
      modifiedSalaryTypePayload = {
        isTemplateCalculation: isTemplateCalculation,
        salaryTypes: formGroup.policySettings.salaryType.salaryTypes.filter(
          (item) => item.name !== '' || item.code !== 0,
        ),
      };
    }

    const payload = {
      policyName: policyForm.policyName,
      version: policyForm.version,
      employeeType: policyForm.employeeType,
      effectiveFrom: policyForm.effectiveFrom,
      status: PensionPolicyStatus.IN_ACTIVE,
      type: isSuperAdmin ? PensionPolicyVariant.COLLECTIVE_AGREEMENT : PensionPolicyVariant.COMPANY,
      isCollectiveAgreementLock: isSuperAdmin ? policyForm.isCollectiveAgreementLock : false,
      policyDescription: policyForm.policyDescription,
      isAutoAssign: policyForm.isAutoAssign,
      policySettings: {
        ...formGroup.policySettings,
        /* salaryChange: {
          ...formGroup.policySettings.salaryChange,
          payFixedParental: false,
          payFixedOtherLeave: false,
        },
        insuranceConfig: {
          payInsuranceParental: false,
          payInsuranceOtherLeave: false,
        }, */
        salaryType: modifiedSalaryTypePayload,
      },
    };

    return payload;
  }

  static toPersonalEmployee({ policyForm, formGroup, defaultPolicyInput }: EmployeePersonalProps) {
    let modifiedSalaryTypePayload;
    const isTemplateCalculation = formGroup.policySettings.salaryType.isTemplateCalculation;

    if (isTemplateCalculation) {
      modifiedSalaryTypePayload = {
        isTemplateCalculation: isTemplateCalculation,
        salaryTypesWithHolidays: formGroup.policySettings.salaryType.salaryTypesWithHolidays
          .filter((item) => {
            return item.isEnable;
          })
          // .filter((_, index) => index === 0)
          .map((value) => {
            value.data = value.data.filter((item) => item.salaryType);

            return value;
          }),
      };
    } else {
      modifiedSalaryTypePayload = {
        isTemplateCalculation: isTemplateCalculation,
        salaryTypes: formGroup.policySettings.salaryType.salaryTypes.filter(
          (item) => item.name !== '' || item.code !== 0,
        ),
      };
    }

    const payload = {
      ...defaultPolicyInput,
      effectiveFrom: policyForm.effectiveFrom,
      status: PensionPolicyStatus.IN_ACTIVE,
      isCollectiveAgreementLock: false,
      type: PensionPolicyVariant.PERSONAL,
      policyDescription: policyForm.policyDescription,
      policySettings: {
        ...formGroup.policySettings,
        salaryType: modifiedSalaryTypePayload,
      },
    };

    return payload;
  }
}
