import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as OpenLink } from '../../../../assets/img/open-link.svg';
import { ReactComponent as CurrencyIcon } from '../../../../assets/img/currency-union.svg';
import PagesHeader from '../../../../components/base/PagesHeader';
import { Link } from 'react-router-dom';
import usePensionManagerForm, { ListMode, ManagerMode, SelectionModeType } from '../hooks/usePensionManagerForm';
import usePolicyMode from '../hooks/usePolicyMode';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import useCostSpecificationModal from '../components/CostSpecificationModal/useCostSpecificationModal';
import CostSpecificationModal from '../components/CostSpecificationModal/CostSpecificationModal';

export default function PernsionManagerGrid() {
  const { t } = useTranslation();
  const {
    formGroup,
    hjertaManagerList,
    otherManagerList,
    isCheckedEmployeeEditOption,
    selectedMode,
    isFetching,
    isCheckedCollectiveAgreement,
    markIncludePlanToPension,
    unCheckPlanFromPension,
    markAsDefault,
    markAllInclude,
    markAllExclude,
    hasIncluded,
    hasDefaultChecked,
    findManagerPlan,
    changeNumberValue,
    updateCustomDetails,
  } = usePensionManagerForm();

  const { isCompanyPolicyMode } = usePolicyMode();

  const {
    toggleModal: toggleCostSpecModal,
    showCostSpecModal,
    setCostPlanManager,
    selectedCostPlanManager,
    showParentModal,
    hideParentModal,
  } = useCostSpecificationModal();

  const toggleIncludeAllPlan = (mode: ManagerMode, key: SelectionModeType, checked) => {
    if (checked) {
      markAllInclude(key, mode);
    } else {
      markAllExclude(key, mode);
    }
  };

  const toggleIncludePlan = (managerID, planID, key: SelectionModeType) => {
    if (findManagerPlan(key, managerID, planID)) {
      unCheckPlanFromPension(key, managerID, planID);

      return;
    }

    markIncludePlanToPension(key, managerID, planID);
  };

  const setDefaultPlan = (managerID, planID, key: SelectionModeType) => {
    markAsDefault(key, managerID, planID);
  };

  const isActivePlan = (managerID, planID, key: SelectionModeType) => {
    return findManagerPlan(key, managerID, planID) ? 'active-green' : '';
  };

  const defaultPlanNumber = (params: {
    key: SelectionModeType;
    propName: string;
    pensionManagerId: string;
    planID: string;
  }) => {
    const foundManagerPlan = findManagerPlan(params.key, params.pensionManagerId, params.planID);

    if (foundManagerPlan && foundManagerPlan.meta?.[params.propName]?.trim()) {
      return foundManagerPlan.meta[params.propName];
    }

    return '';
  };

  const handleChangeNumberValue = (
    value: string,
    params: {
      key: SelectionModeType;
      propName: string;
      pensionManagerId: string;
      planID: string;
    },
  ) => {
    changeNumberValue({ ...params, propValue: value });
  };

  const onShowPlanCostSpecification = (manager, planItemDetails) => {
    setCostPlanManager({ manager, planItem: planItemDetails });
    toggleCostSpecModal();
    hideParentModal();
  };

  if (isFetching) {
    return (
      <>
        <div className="mt-4 mb-3">
          <PagesHeader pageTitle="Pension managers" biggerHeading={true} />
        </div>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <div className="mt-4 mb-3">
        <PagesHeader pageTitle="Pension managers" biggerHeading={true} />
        <ErrorFeedback behavior="alert" field={formGroup!.getFieldMeta('policySettings.pensionManagers')} />
      </div>

      <Form.Group className="pe-0 chkbox-form-group">
        <Form.Check inline id="employeeRightToEditChecked" className="fs-name mb-1">
          <Form.Check.Input
            name="employeeRightToEditChecked"
            type="checkbox"
            checked={isCheckedEmployeeEditOption}
            disabled={isCheckedCollectiveAgreement}
            onChange={(value) => updateCustomDetails('employeeRightToEditChecked', value.target.checked)}
          />
          <Form.Check.Label htmlFor="isPremium" className="form-check-label text-label-note">
            {t('employee_can_choose')}
          </Form.Check.Label>
        </Form.Check>
        <div className="subtext-block">
          <p className="text-label-note">{t('select_employee_pension_manager')}.</p>
        </div>
      </Form.Group>

      <div className=" mt-3 mb-4">
        <p className="fs-text-400">{t('policy_provider_plan_selection')}</p>
      </div>

      {selectedMode === ListMode.PRIMARY && (
        <>
          {hjertaManagerList.length > 0 && (
            <div className=" mt-3">
              <p className="fs-h3  mb-0">{t('correcto_partner_providers')}</p>
            </div>
          )}
          {hjertaManagerList.length > 0 && (
            <div className="table-responsive react-bootstrap-table">
              <table className="table table-hover table-condensed pension-manager-table table-single-row">
                <tr>
                  <td className="section-one" style={{ verticalAlign: 'bottom' }}>
                    <table className="table table-hover table-condensed pension-manager-table">
                      <thead className="header-wrapper">
                        <tr className="pm-header-second">
                          <th className="fs-name" style={{ width: '250px' }}>
                            {t('provider')}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </td>
                  <td className="section-two">
                    <table className="table table-hover table-condensed pension-manager-table">
                      <thead className="header-wrapper">
                        <tr className="pm-header-second">
                          <th className="fs-name" style={{ width: '515px' }}>
                            {t('plan')}
                          </th>
                          <th className="col-header-chbox" style={{ width: '150px' }}>
                            <Form.Group>
                              <Form.Check
                                type="checkbox"
                                label={t('include')}
                                id="hjerta-include-all"
                                className="fs-name include-all-chkbox"
                                onChange={(e) =>
                                  toggleIncludeAllPlan(ManagerMode.HJERTA, SelectionModeType.COMBINED, e.target.checked)
                                }
                              />
                            </Form.Group>
                          </th>
                          <th>
                            <span className="fs-name">
                              {t('PENSION_POLICY.mark_as')} <br></br>
                              {t('PENSION_POLICY.default')}
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </td>
                </tr>

                {hjertaManagerList.map((manager, idx) => {
                  return (
                    <tr key={`hjerta-manager-item-${idx}`}>
                      <td className="section-one">
                        <table className="table table-hover table-condensed pension-manager-table">
                          <tbody>
                            <tr className="row-section">
                              <td>
                                <span className="fs-name">{manager.companyName}</span>
                                <p>{manager.pensionManagerPlans.providerDescription}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="section-two">
                        <table className="table table-hover table-condensed pension-manager-table">
                          <tbody>
                            {(manager?.pensionManagerPlans?.plans || []).map((planItem, planIdx) => {
                              return (
                                <React.Fragment key={`hjerta-plan-item-${idx}${planIdx}`}>
                                  <tr className="row-section">
                                    <td
                                      className={isActivePlan(
                                        manager.id,
                                        planItem.planName,
                                        SelectionModeType.COMBINED,
                                      )}
                                      style={{ width: '515px' }}
                                    >
                                      <span className="fs-name">{planItem.planName}</span>
                                      <p>{planItem.providerDescription}</p>
                                      {planItem?.costSpecification && (
                                        <div className="mb-3">
                                          <span
                                            className="link-text cursor-pointer"
                                            onClick={() => onShowPlanCostSpecification(manager, planItem)}
                                          >
                                            <CurrencyIcon className="me-1" />
                                            Cost specification
                                          </span>
                                        </div>
                                      )}
                                      <ul className="table-links">
                                        {(planItem.documents || []).map((docItem, docIdx) => {
                                          return (
                                            <li key={`hjerta-plan-item-link-${idx}${planIdx}-${docIdx}`}>
                                              <Link className="link-text" to={docItem.documentLink} target="_blank">
                                                <OpenLink /> {docItem.text}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </td>
                                    <td
                                      className={isActivePlan(
                                        manager.id,
                                        planItem.planName,
                                        SelectionModeType.COMBINED,
                                      )}
                                      style={{ width: '150px' }}
                                    >
                                      <Form.Group>
                                        <Form.Check
                                          type="checkbox"
                                          label=" "
                                          id={`include-hjerta-plan-item-${idx}${planIdx}`}
                                          className="fs-name"
                                          onChange={(e) =>
                                            toggleIncludePlan(manager.id, planItem.planName, SelectionModeType.COMBINED)
                                          }
                                          checked={hasIncluded(
                                            SelectionModeType.COMBINED,
                                            manager.id,
                                            planItem.planName,
                                          )}
                                        />
                                      </Form.Group>

                                      {isCompanyPolicyMode() &&
                                        hasIncluded(SelectionModeType.COMBINED, manager.id, planItem.planName) && (
                                          <div className="mt-2 input-info">
                                            <Form.Group className="mb-2">
                                              <Form.Label className="theme-text fs-name">
                                                {t('customer_number')}
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name={`include-hjerta-customer-num-${idx}${planIdx}`}
                                                value={defaultPlanNumber({
                                                  key: SelectionModeType.COMBINED,
                                                  propName: 'customerNumber',
                                                  pensionManagerId: manager.id,
                                                  planID: planItem.planName,
                                                })}
                                                onChange={(e) =>
                                                  handleChangeNumberValue(e.target.value, {
                                                    key: SelectionModeType.COMBINED,
                                                    propName: 'customerNumber',
                                                    pensionManagerId: manager.id,
                                                    planID: planItem.planName,
                                                  })
                                                }
                                              />
                                            </Form.Group>

                                            <Form.Group>
                                              <Form.Label className="theme-text fs-name">
                                                {t('insurance_number')}
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name={`include-hjerta-insurance-num-${idx}${planIdx}`}
                                                value={defaultPlanNumber({
                                                  key: SelectionModeType.COMBINED,
                                                  propName: 'insuranceNumber',
                                                  pensionManagerId: manager.id,
                                                  planID: planItem.planName,
                                                })}
                                                onChange={(e) =>
                                                  handleChangeNumberValue(e.target.value, {
                                                    key: SelectionModeType.COMBINED,
                                                    propName: 'insuranceNumber',
                                                    pensionManagerId: manager.id,
                                                    planID: planItem.planName,
                                                  })
                                                }
                                              />
                                            </Form.Group>
                                          </div>
                                        )}
                                    </td>
                                    <td
                                      className={isActivePlan(
                                        manager.id,
                                        planItem.planName,
                                        SelectionModeType.COMBINED,
                                      )}
                                    >
                                      <Form.Group>
                                        {hasDefaultChecked(
                                          SelectionModeType.COMBINED,
                                          manager.id,
                                          planItem.planName,
                                        ) ? (
                                          <Form.Check
                                            type="radio"
                                            label=" "
                                            id={`default-hjerta-plan-item-${idx}${planIdx}`}
                                            className="fs-name"
                                            name="combined"
                                            onChange={(e) =>
                                              setDefaultPlan(manager.id, planItem.planName, SelectionModeType.COMBINED)
                                            }
                                            disabled={
                                              !hasIncluded(SelectionModeType.COMBINED, manager.id, planItem.planName)
                                            }
                                            checked={true}
                                          />
                                        ) : (
                                          <Form.Check
                                            type="radio"
                                            label=" "
                                            id={`default-hjerta-plan-item-${idx}${planIdx}`}
                                            className="fs-name"
                                            name="combined"
                                            onChange={(e) =>
                                              setDefaultPlan(manager.id, planItem.planName, SelectionModeType.COMBINED)
                                            }
                                            disabled={
                                              !hasIncluded(SelectionModeType.COMBINED, manager.id, planItem.planName)
                                            }
                                            checked={false}
                                          />
                                        )}
                                      </Form.Group>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}

          {otherManagerList.length > 0 && (
            <>
              <div className=" mt-3">
                <p className="fs-h3  mb-0">{t('providers')}</p>
              </div>
              <div className="table-responsive react-bootstrap-table">
                <table className="table table-hover table-condensed pension-manager-table  pension-policy-single-row">
                  <tr>
                    <td className="section-one">
                      <table className="table table-hover table-condensed pension-manager-table dd">
                        <thead className="header-wrapper">
                          <tr className="pm-header-second">
                            <th className="fs-name" style={{ width: '250px' }}>
                              {t('provider')}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                    <td className="section-two">
                      <table className="table table-hover table-condensed pension-manager-table">
                        <thead className="header-wrapper">
                          <tr className="pm-header-second">
                            <th className="fs-name" style={{ width: '515px' }}>
                              {t('plan')}
                            </th>
                            <th className="col-header-chbox" style={{ width: '150px' }}>
                              <Form.Group>
                                <Form.Check
                                  type="checkbox"
                                  label={t('include')}
                                  id="nonhjerta-include-all"
                                  className="fs-name include-all-chkbox"
                                  onChange={(e) =>
                                    toggleIncludeAllPlan(
                                      ManagerMode.NON_HJERTA,
                                      SelectionModeType.COMBINED,
                                      e.target.checked,
                                    )
                                  }
                                />
                              </Form.Group>
                            </th>
                            <th>
                              <span className="fs-name">
                                {t('PENSION_POLICY.mark_as')}
                                <br></br>
                                {t('PENSION_POLICY.default')}
                              </span>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                  </tr>

                  {otherManagerList.map((manager, idx) => {
                    return (
                      <tr key={`nonhjerta-manager-item-${idx}`}>
                        <td className="section-one">
                          <table className="table table-hover table-condensed pension-manager-table">
                            <tbody>
                              <tr className="row-section">
                                <td>
                                  <span className="fs-name">{manager.companyName}</span>
                                  <p>{manager.pensionManagerPlans.providerDescription}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td className="section-two">
                          <table className="table table-hover table-condensed pension-manager-table">
                            <tbody>
                              {(manager?.pensionManagerPlans?.plans || []).map((planItem, planIdx) => {
                                return (
                                  <React.Fragment key={`nonhjerta-plan-item-${idx}${planIdx}`}>
                                    <tr className="row-section">
                                      <td
                                        className={isActivePlan(
                                          manager.id,
                                          planItem.planName,
                                          SelectionModeType.COMBINED,
                                        )}
                                        style={{ width: '515px' }}
                                      >
                                        <span className="fs-name">{planItem.planName}</span>
                                        <p>{planItem.providerDescription}</p>
                                        {planItem?.costSpecification && (
                                          <div className="mb-3">
                                            <span
                                              className="link-text cursor-pointer"
                                              onClick={() => onShowPlanCostSpecification(manager, planItem)}
                                            >
                                              <CurrencyIcon className="me-1" />
                                              Cost specification
                                            </span>
                                          </div>
                                        )}
                                        <ul className="table-links">
                                          {(planItem.documents || []).map((docItem, docIdx) => {
                                            return (
                                              <li key={`nonhjerta-plan-item-link-${idx}${planIdx}-${docIdx}`}>
                                                <Link className="link-text" to={docItem.documentLink} target="_blank">
                                                  <OpenLink /> {docItem.text}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </td>
                                      <td
                                        className={isActivePlan(
                                          manager.id,
                                          planItem.planName,
                                          SelectionModeType.COMBINED,
                                        )}
                                        style={{ width: '150px' }}
                                      >
                                        <Form.Group>
                                          <Form.Check
                                            type="checkbox"
                                            label=" "
                                            id={`include-nonhjerta-plan-item-${idx}${planIdx}`}
                                            className="fs-name"
                                            onChange={(e) =>
                                              toggleIncludePlan(
                                                manager.id,
                                                planItem.planName,
                                                SelectionModeType.COMBINED,
                                              )
                                            }
                                            checked={hasIncluded(
                                              SelectionModeType.COMBINED,
                                              manager.id,
                                              planItem.planName,
                                            )}
                                          />
                                        </Form.Group>

                                        {isCompanyPolicyMode() &&
                                          hasIncluded(SelectionModeType.COMBINED, manager.id, planItem.planName) && (
                                            <div className="mt-2 input-info">
                                              <Form.Group className="mb-2">
                                                <Form.Label className="theme-text fs-name">
                                                  {t('customer_number')}
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  name={`include-nonhjerta--customer-num-${idx}${planIdx}`}
                                                  value={defaultPlanNumber({
                                                    key: SelectionModeType.COMBINED,
                                                    propName: 'customerNumber',
                                                    pensionManagerId: manager.id,
                                                    planID: planItem.planName,
                                                  })}
                                                  onChange={(e) =>
                                                    handleChangeNumberValue(e.target.value, {
                                                      key: SelectionModeType.COMBINED,
                                                      propName: 'customerNumber',
                                                      pensionManagerId: manager.id,
                                                      planID: planItem.planName,
                                                    })
                                                  }
                                                />
                                              </Form.Group>

                                              <Form.Group>
                                                <Form.Label className="theme-text fs-name">
                                                  {t('insurance_number')}
                                                </Form.Label>
                                                <Form.Control
                                                  type="text"
                                                  name={`include-nonhjerta--insurance-num-${idx}${planIdx}`}
                                                  value={defaultPlanNumber({
                                                    key: SelectionModeType.COMBINED,
                                                    propName: 'insuranceNumber',
                                                    pensionManagerId: manager.id,
                                                    planID: planItem.planName,
                                                  })}
                                                  onChange={(e) =>
                                                    handleChangeNumberValue(e.target.value, {
                                                      key: SelectionModeType.COMBINED,
                                                      propName: 'insuranceNumber',
                                                      pensionManagerId: manager.id,
                                                      planID: planItem.planName,
                                                    })
                                                  }
                                                />
                                              </Form.Group>
                                            </div>
                                          )}
                                      </td>
                                      <td
                                        className={isActivePlan(
                                          manager.id,
                                          planItem.planName,
                                          SelectionModeType.COMBINED,
                                        )}
                                      >
                                        <Form.Group>
                                          {hasDefaultChecked(
                                            SelectionModeType.COMBINED,
                                            manager.id,
                                            planItem.planName,
                                          ) ? (
                                            <Form.Check
                                              type="radio"
                                              label=" "
                                              id={`default-nonhjerta-plan-item-${idx}${planIdx}`}
                                              className="fs-name"
                                              name="combined"
                                              onChange={(e) =>
                                                setDefaultPlan(
                                                  manager.id,
                                                  planItem.planName,
                                                  SelectionModeType.COMBINED,
                                                )
                                              }
                                              disabled={
                                                !hasIncluded(SelectionModeType.COMBINED, manager.id, planItem.planName)
                                              }
                                              checked={true}
                                            />
                                          ) : (
                                            <Form.Check
                                              type="radio"
                                              label=" "
                                              id={`default-nonhjerta-plan-item-${idx}${planIdx}`}
                                              className="fs-name"
                                              name="combined"
                                              onChange={(e) =>
                                                setDefaultPlan(
                                                  manager.id,
                                                  planItem.planName,
                                                  SelectionModeType.COMBINED,
                                                )
                                              }
                                              disabled={
                                                !hasIncluded(SelectionModeType.COMBINED, manager.id, planItem.planName)
                                              }
                                              checked={false}
                                            />
                                          )}
                                        </Form.Group>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </>
          )}
        </>
      )}

      {selectedMode === ListMode.SECONDARY && (
        <>
          {hjertaManagerList.length > 0 && (
            <div className=" mt-3">
              <p className="fs-h3  mb-2">{t('correcto_partner_providers')}</p>
            </div>
          )}

          {hjertaManagerList.length > 0 && (
            <div className="table-responsive react-bootstrap-table">
              <table className="table table-hover table-condensed pension-manager-table">
                <tr>
                  <td className="section-one">
                    <table className="table table-hover table-condensed pension-manager-table">
                      <thead className="header-wrapper">
                        <tr className="pm-header-first">
                          <td></td>
                        </tr>
                        <tr className="pm-header-second">
                          <th className="fs-name" style={{ width: '250px' }}>
                            {t('provider')}
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </td>
                  <td className="section-two">
                    <table className="table table-hover table-condensed pension-manager-table">
                      <thead className="header-wrapper">
                        <tr className="pm-header-first">
                          <td colSpan={1}></td>
                          <td colSpan={2}>
                            <span className="fs-name">{t('from_occupational_pension')}</span>
                          </td>
                          <td colSpan={2}>
                            <span className="fs-name">{t('from_salary_change')}</span>
                          </td>
                        </tr>
                        <tr className="pm-header-second">
                          <th className="fs-name" style={{ width: '515px' }}>
                            {t('plan')}
                          </th>
                          <th className="col-header-chbox">
                            <Form.Group>
                              <Form.Check
                                type="checkbox"
                                label={t('include')}
                                id="occupational-hjerta-include-all"
                                className="fs-name include-all-chkbox"
                                onChange={(e) =>
                                  toggleIncludeAllPlan(
                                    ManagerMode.HJERTA,
                                    SelectionModeType.OCCUPATIONAL,
                                    e.target.checked,
                                  )
                                }
                              />
                            </Form.Group>
                          </th>
                          <th>
                            <span className="fs-name">
                              {t('PENSION_POLICY.mark_as')} <br></br>
                              {t('PENSION_POLICY.default')}
                            </span>
                          </th>
                          <th className="col-header-chbox">
                            <Form.Group>
                              <Form.Check
                                type="checkbox"
                                label={t('include')}
                                id="salary-hjerta-include-all"
                                className="fs-name include-all-chkbox"
                                onChange={(e) =>
                                  toggleIncludeAllPlan(ManagerMode.HJERTA, SelectionModeType.SALARY, e.target.checked)
                                }
                              />
                            </Form.Group>
                          </th>
                          <th>
                            <span className="fs-name">
                              {t('PENSION_POLICY.mark_as')} <br></br>
                              {t('PENSION_POLICY.default')}
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </td>
                </tr>

                {hjertaManagerList.map((manager, idx) => {
                  return (
                    <tr key={`hjerta-manager-item-${idx}`}>
                      <td className="section-one">
                        <table className="table table-hover table-condensed pension-manager-table">
                          <tbody>
                            <tr className="row-section">
                              <td>
                                <span className="fs-name">{manager.companyName}</span>
                                <p>{manager.pensionManagerPlans.providerDescription}</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="section-two">
                        <table className="table table-hover table-condensed pension-manager-table">
                          <tbody>
                            {(manager?.pensionManagerPlans?.plans || []).map((planItem, planIdx) => {
                              return (
                                <React.Fragment key={`hjerta-plan-item-${idx}${planIdx}`}>
                                  <tr className="row-section">
                                    <td style={{ width: '515px' }}>
                                      <span className="fs-name">{planItem.planName}</span>
                                      <p>{planItem.providerDescription}</p>
                                      {planItem?.costSpecification && (
                                        <div className="mb-3">
                                          <span
                                            className="link-text cursor-pointer"
                                            onClick={() => onShowPlanCostSpecification(manager, planItem)}
                                          >
                                            <CurrencyIcon className="me-1" />
                                            Cost specification
                                          </span>
                                        </div>
                                      )}
                                      <ul className="table-links">
                                        {(planItem.documents || []).map((docItem, docIdx) => {
                                          return (
                                            <li key={`hjerta-plan-item-link-${idx}${planIdx}-${docIdx}`}>
                                              <Link className="link-text" to={docItem.documentLink} target="_blank">
                                                <OpenLink /> {docItem.text}
                                              </Link>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </td>
                                    <td
                                      colSpan={2}
                                      className={`nested-table-checkbox ${isActivePlan(
                                        manager.id,
                                        planItem.planName,
                                        SelectionModeType.OCCUPATIONAL,
                                      )}`}
                                    >
                                      <table>
                                        <tr>
                                          <td>
                                            <Form.Group>
                                              <Form.Check
                                                type="checkbox"
                                                label=" "
                                                id={`include-occupational-hjerta-plan-item-${idx}${planIdx}`}
                                                className="fs-name"
                                                onChange={(e) =>
                                                  toggleIncludePlan(
                                                    manager.id,
                                                    planItem.planName,
                                                    SelectionModeType.OCCUPATIONAL,
                                                  )
                                                }
                                                checked={hasIncluded(
                                                  SelectionModeType.OCCUPATIONAL,
                                                  manager.id,
                                                  planItem.planName,
                                                )}
                                              />
                                            </Form.Group>
                                          </td>
                                          <td
                                            className={isActivePlan(
                                              manager.id,
                                              planItem.planName,
                                              SelectionModeType.OCCUPATIONAL,
                                            )}
                                          >
                                            <Form.Group>
                                              {hasDefaultChecked(
                                                SelectionModeType.OCCUPATIONAL,
                                                manager.id,
                                                planItem.planName,
                                              ) ? (
                                                <Form.Check
                                                  type="radio"
                                                  label=" "
                                                  id={`default-occupational-hjerta-plan-item-${idx}${planIdx}`}
                                                  className="fs-name"
                                                  name="occupational"
                                                  onChange={(e) =>
                                                    setDefaultPlan(
                                                      manager.id,
                                                      planItem.planName,
                                                      SelectionModeType.OCCUPATIONAL,
                                                    )
                                                  }
                                                  disabled={
                                                    !hasIncluded(
                                                      SelectionModeType.OCCUPATIONAL,
                                                      manager.id,
                                                      planItem.planName,
                                                    )
                                                  }
                                                  checked={true}
                                                />
                                              ) : (
                                                <Form.Check
                                                  type="radio"
                                                  label=" "
                                                  id={`default-occupational-hjerta-plan-item-${idx}${planIdx}`}
                                                  className="fs-name"
                                                  name="occupational"
                                                  onChange={(e) =>
                                                    setDefaultPlan(
                                                      manager.id,
                                                      planItem.planName,
                                                      SelectionModeType.OCCUPATIONAL,
                                                    )
                                                  }
                                                  disabled={
                                                    !hasIncluded(
                                                      SelectionModeType.OCCUPATIONAL,
                                                      manager.id,
                                                      planItem.planName,
                                                    )
                                                  }
                                                  checked={false}
                                                />
                                              )}
                                            </Form.Group>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2}>
                                            {isCompanyPolicyMode() &&
                                              hasIncluded(
                                                SelectionModeType.OCCUPATIONAL,
                                                manager.id,
                                                planItem.planName,
                                              ) && (
                                                <div className="mt-2 input-info">
                                                  <Form.Group className="mb-2">
                                                    <Form.Label className="theme-text fs-name">
                                                      {t('customer_number')}
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      name={`include-hjerta-occupational-customer-num-${idx}${planIdx}`}
                                                      value={defaultPlanNumber({
                                                        key: SelectionModeType.OCCUPATIONAL,
                                                        propName: 'customerNumber',
                                                        pensionManagerId: manager.id,
                                                        planID: planItem.planName,
                                                      })}
                                                      onChange={(e) =>
                                                        handleChangeNumberValue(e.target.value, {
                                                          key: SelectionModeType.OCCUPATIONAL,
                                                          propName: 'customerNumber',
                                                          pensionManagerId: manager.id,
                                                          planID: planItem.planName,
                                                        })
                                                      }
                                                    />
                                                  </Form.Group>

                                                  <Form.Group>
                                                    <Form.Label className="theme-text fs-name">
                                                      {t('insurance_number')}
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      name={`include-hjerta-occupational-insurance-num-${idx}${planIdx}`}
                                                      value={defaultPlanNumber({
                                                        key: SelectionModeType.OCCUPATIONAL,
                                                        propName: 'insuranceNumber',
                                                        pensionManagerId: manager.id,
                                                        planID: planItem.planName,
                                                      })}
                                                      onChange={(e) =>
                                                        handleChangeNumberValue(e.target.value, {
                                                          key: SelectionModeType.OCCUPATIONAL,
                                                          propName: 'insuranceNumber',
                                                          pensionManagerId: manager.id,
                                                          planID: planItem.planName,
                                                        })
                                                      }
                                                    />
                                                  </Form.Group>
                                                </div>
                                              )}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>

                                    <td
                                      colSpan={2}
                                      className={`nested-table-checkbox ${isActivePlan(
                                        manager.id,
                                        planItem.planName,
                                        SelectionModeType.SALARY,
                                      )}`}
                                    >
                                      <table>
                                        <tr>
                                          <td>
                                            <Form.Group>
                                              <Form.Check
                                                type="checkbox"
                                                label=" "
                                                id={`include-salary-hjerta-plan-item-${idx}${planIdx}`}
                                                className="fs-name"
                                                onChange={(e) =>
                                                  toggleIncludePlan(
                                                    manager.id,
                                                    planItem.planName,
                                                    SelectionModeType.SALARY,
                                                  )
                                                }
                                                checked={hasIncluded(
                                                  SelectionModeType.SALARY,
                                                  manager.id,
                                                  planItem.planName,
                                                )}
                                              />
                                            </Form.Group>
                                          </td>
                                          <td
                                            className={isActivePlan(
                                              manager.id,
                                              planItem.planName,
                                              SelectionModeType.SALARY,
                                            )}
                                          >
                                            <Form.Group>
                                              {hasDefaultChecked(
                                                SelectionModeType.SALARY,
                                                manager.id,
                                                planItem.planName,
                                              ) ? (
                                                <Form.Check
                                                  type="radio"
                                                  label=" "
                                                  id={`default-salary-hjerta-plan-item-${idx}${planIdx}`}
                                                  className="fs-name"
                                                  name="salary"
                                                  onChange={(e) =>
                                                    setDefaultPlan(
                                                      manager.id,
                                                      planItem.planName,
                                                      SelectionModeType.SALARY,
                                                    )
                                                  }
                                                  disabled={
                                                    !hasIncluded(
                                                      SelectionModeType.SALARY,
                                                      manager.id,
                                                      planItem.planName,
                                                    )
                                                  }
                                                  checked={true}
                                                />
                                              ) : (
                                                <Form.Check
                                                  type="radio"
                                                  label=" "
                                                  id={`default-salary-hjerta-plan-item-${idx}${planIdx}`}
                                                  className="fs-name"
                                                  name="salary"
                                                  onChange={(e) =>
                                                    setDefaultPlan(
                                                      manager.id,
                                                      planItem.planName,
                                                      SelectionModeType.SALARY,
                                                    )
                                                  }
                                                  disabled={
                                                    !hasIncluded(
                                                      SelectionModeType.SALARY,
                                                      manager.id,
                                                      planItem.planName,
                                                    )
                                                  }
                                                  checked={false}
                                                />
                                              )}
                                            </Form.Group>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2}>
                                            {isCompanyPolicyMode() &&
                                              hasIncluded(SelectionModeType.SALARY, manager.id, planItem.planName) && (
                                                <div className="mt-2 input-info">
                                                  <Form.Group className="mb-2">
                                                    <Form.Label className="theme-text fs-name">
                                                      {t('customer_number')}
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      name={`include-hjerta-salary-customer-num-${idx}${planIdx}`}
                                                      value={defaultPlanNumber({
                                                        key: SelectionModeType.SALARY,
                                                        propName: 'customerNumber',
                                                        pensionManagerId: manager.id,
                                                        planID: planItem.planName,
                                                      })}
                                                      onChange={(e) =>
                                                        handleChangeNumberValue(e.target.value, {
                                                          key: SelectionModeType.SALARY,
                                                          propName: 'customerNumber',
                                                          pensionManagerId: manager.id,
                                                          planID: planItem.planName,
                                                        })
                                                      }
                                                    />
                                                  </Form.Group>

                                                  <Form.Group>
                                                    <Form.Label className="theme-text fs-name">
                                                      {t('insurance_number')}
                                                    </Form.Label>
                                                    <Form.Control
                                                      type="text"
                                                      name={`include-hjerta-salary-insurance-num-${idx}${planIdx}`}
                                                      value={defaultPlanNumber({
                                                        key: SelectionModeType.SALARY,
                                                        propName: 'insuranceNumber',
                                                        pensionManagerId: manager.id,
                                                        planID: planItem.planName,
                                                      })}
                                                      onChange={(e) =>
                                                        handleChangeNumberValue(e.target.value, {
                                                          key: SelectionModeType.SALARY,
                                                          propName: 'insuranceNumber',
                                                          pensionManagerId: manager.id,
                                                          planID: planItem.planName,
                                                        })
                                                      }
                                                    />
                                                  </Form.Group>
                                                </div>
                                              )}
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          )}

          {otherManagerList.length > 0 && (
            <>
              <div className=" mt-3">
                <p className="fs-h3  mb-0">{t('providers')}</p>
              </div>
              <div className="table-responsive react-bootstrap-table">
                <table className="table table-hover table-condensed pension-manager-table">
                  <tr>
                    <td className="section-one">
                      <table className="table table-hover table-condensed pension-manager-table">
                        <thead className="header-wrapper">
                          <tr className="pm-header-first">
                            <td></td>
                          </tr>
                          <tr className="pm-header-second">
                            <th className="fs-name" style={{ width: '250px' }}>
                              {t('provider')}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                    <td className="section-two">
                      <table className="table table-hover table-condensed pension-manager-table">
                        <thead className="header-wrapper">
                          <tr className="pm-header-first">
                            <td colSpan={1}></td>
                            <td colSpan={2}>
                              <span className="fs-name">{t('from_occupational_pension')}</span>
                            </td>
                            <td colSpan={2}>
                              <span className="fs-name">{t('from_salary_change')}</span>
                            </td>
                          </tr>
                          <tr className="pm-header-second">
                            <th className="fs-name" style={{ width: '515px' }}>
                              {t('plan')}
                            </th>
                            <th className="col-header-chbox">
                              <Form.Group>
                                <Form.Check
                                  type="checkbox"
                                  label={t('include')}
                                  id="nonhjerta-include-occupational-all"
                                  className="fs-name include-all-chkbox"
                                  onChange={(e) =>
                                    toggleIncludeAllPlan(
                                      ManagerMode.NON_HJERTA,
                                      SelectionModeType.OCCUPATIONAL,
                                      e.target.checked,
                                    )
                                  }
                                />
                              </Form.Group>
                            </th>
                            <th>
                              <span className="fs-name">
                                {t('PENSION_POLICY.mark_as')}
                                <br></br>
                                {t('PENSION_POLICY.default')}
                              </span>
                            </th>
                            <th className="col-header-chbox">
                              <Form.Group>
                                <Form.Check
                                  type="checkbox"
                                  label={t('include')}
                                  id="nonhjerta-include-salary-all"
                                  className="fs-name include-all-chkbox"
                                  onChange={(e) =>
                                    toggleIncludeAllPlan(
                                      ManagerMode.NON_HJERTA,
                                      SelectionModeType.SALARY,
                                      e.target.checked,
                                    )
                                  }
                                />
                              </Form.Group>
                            </th>
                            <th>
                              <span className="fs-name">
                                {t('PENSION_POLICY.mark_as')}
                                <br></br>
                                {t('PENSION_POLICY.default')}
                              </span>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </td>
                  </tr>

                  {otherManagerList.map((manager, idx) => {
                    return (
                      <tr key={`nonhjerta-manager-item-${idx}`}>
                        <td className="section-one">
                          <table className="table table-hover table-condensed pension-manager-table">
                            <tbody>
                              <tr className="row-section">
                                <td>
                                  <span className="fs-name">{manager.companyName}</span>
                                  <p>{manager.pensionManagerPlans.providerDescription}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td className="section-two">
                          <table className="table table-hover table-condensed pension-manager-table">
                            <tbody>
                              {(manager?.pensionManagerPlans?.plans || []).map((planItem, planIdx) => {
                                return (
                                  <React.Fragment key={`nonhjerta-plan-item-${idx}${planIdx}`}>
                                    <tr className="row-section">
                                      <td style={{ width: '515px' }}>
                                        <span className="fs-name">{planItem.planName}</span>
                                        <p>{planItem.providerDescription}</p>
                                        {planItem?.costSpecification && (
                                          <div className="mb-3">
                                            <span
                                              className="link-text cursor-pointer"
                                              onClick={() => onShowPlanCostSpecification(manager, planItem)}
                                            >
                                              <CurrencyIcon className="me-1" />
                                              Cost specification
                                            </span>
                                          </div>
                                        )}
                                        <ul className="table-links">
                                          {(planItem.documents || []).map((docItem, docIdx) => {
                                            return (
                                              <li key={`nonhjerta-plan-item-link-${idx}${planIdx}-${docIdx}`}>
                                                <Link className="link-text" to={docItem.documentLink} target="_blank">
                                                  <OpenLink /> {docItem.text}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </td>
                                      <td
                                        colSpan={2}
                                        className={`nested-table-checkbox ${isActivePlan(
                                          manager.id,
                                          planItem.planName,
                                          SelectionModeType.OCCUPATIONAL,
                                        )}`}
                                      >
                                        <table>
                                          <tr>
                                            <td>
                                              <Form.Group>
                                                <Form.Check
                                                  type="checkbox"
                                                  label=" "
                                                  id={`include-occupational-nonhjerta-plan-item-${idx}${planIdx}`}
                                                  className="fs-name"
                                                  onChange={(e) =>
                                                    toggleIncludePlan(
                                                      manager.id,
                                                      planItem.planName,
                                                      SelectionModeType.OCCUPATIONAL,
                                                    )
                                                  }
                                                  checked={hasIncluded(
                                                    SelectionModeType.OCCUPATIONAL,
                                                    manager.id,
                                                    planItem.planName,
                                                  )}
                                                />
                                              </Form.Group>
                                            </td>
                                            <td
                                              className={isActivePlan(
                                                manager.id,
                                                planItem.planName,
                                                SelectionModeType.OCCUPATIONAL,
                                              )}
                                            >
                                              <Form.Group>
                                                {hasDefaultChecked(
                                                  SelectionModeType.OCCUPATIONAL,
                                                  manager.id,
                                                  planItem.planName,
                                                ) ? (
                                                  <Form.Check
                                                    type="radio"
                                                    label=" "
                                                    id={`default-occupational-nonhjerta-plan-item-${idx}${planIdx}`}
                                                    className="fs-name"
                                                    name="occupational"
                                                    onChange={(e) =>
                                                      setDefaultPlan(
                                                        manager.id,
                                                        planItem.planName,
                                                        SelectionModeType.OCCUPATIONAL,
                                                      )
                                                    }
                                                    disabled={
                                                      !hasIncluded(
                                                        SelectionModeType.OCCUPATIONAL,
                                                        manager.id,
                                                        planItem.planName,
                                                      )
                                                    }
                                                    checked={true}
                                                  />
                                                ) : (
                                                  <Form.Check
                                                    type="radio"
                                                    label=" "
                                                    id={`default-occupational-nonhjerta-plan-item-${idx}${planIdx}`}
                                                    className="fs-name"
                                                    name="occupational"
                                                    onChange={(e) =>
                                                      setDefaultPlan(
                                                        manager.id,
                                                        planItem.planName,
                                                        SelectionModeType.OCCUPATIONAL,
                                                      )
                                                    }
                                                    disabled={
                                                      !hasIncluded(
                                                        SelectionModeType.OCCUPATIONAL,
                                                        manager.id,
                                                        planItem.planName,
                                                      )
                                                    }
                                                    checked={false}
                                                  />
                                                )}
                                              </Form.Group>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>
                                              {isCompanyPolicyMode() &&
                                                hasIncluded(
                                                  SelectionModeType.OCCUPATIONAL,
                                                  manager.id,
                                                  planItem.planName,
                                                ) && (
                                                  <div className="mt-2 input-info">
                                                    <Form.Group className="mb-2">
                                                      <Form.Label className="theme-text fs-name">
                                                        {t('customer_number')}
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        name={`include-other-occupational-customer-num-${idx}${planIdx}`}
                                                        value={defaultPlanNumber({
                                                          key: SelectionModeType.OCCUPATIONAL,
                                                          propName: 'customerNumber',
                                                          pensionManagerId: manager.id,
                                                          planID: planItem.planName,
                                                        })}
                                                        onChange={(e) =>
                                                          handleChangeNumberValue(e.target.value, {
                                                            key: SelectionModeType.OCCUPATIONAL,
                                                            propName: 'customerNumber',
                                                            pensionManagerId: manager.id,
                                                            planID: planItem.planName,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>

                                                    <Form.Group>
                                                      <Form.Label className="theme-text fs-name">
                                                        {t('insurance_number')}
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        name={`include-other-salary-insurance-num-${idx}${planIdx}`}
                                                        value={defaultPlanNumber({
                                                          key: SelectionModeType.OCCUPATIONAL,
                                                          propName: 'insuranceNumber',
                                                          pensionManagerId: manager.id,
                                                          planID: planItem.planName,
                                                        })}
                                                        onChange={(e) =>
                                                          handleChangeNumberValue(e.target.value, {
                                                            key: SelectionModeType.OCCUPATIONAL,
                                                            propName: 'insuranceNumber',
                                                            pensionManagerId: manager.id,
                                                            planID: planItem.planName,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                  </div>
                                                )}
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                      <td
                                        colSpan={2}
                                        className={`nested-table-checkbox ${isActivePlan(
                                          manager.id,
                                          planItem.planName,
                                          SelectionModeType.SALARY,
                                        )}`}
                                      >
                                        <table>
                                          <tr>
                                            <td>
                                              <Form.Group>
                                                <Form.Check
                                                  type="checkbox"
                                                  label=" "
                                                  id={`include-salary-nonhjerta-plan-item-${idx}${planIdx}`}
                                                  className="fs-name"
                                                  onChange={(e) =>
                                                    toggleIncludePlan(
                                                      manager.id,
                                                      planItem.planName,
                                                      SelectionModeType.SALARY,
                                                    )
                                                  }
                                                  checked={hasIncluded(
                                                    SelectionModeType.SALARY,
                                                    manager.id,
                                                    planItem.planName,
                                                  )}
                                                />
                                              </Form.Group>
                                            </td>
                                            <td
                                              className={isActivePlan(
                                                manager.id,
                                                planItem.planName,
                                                SelectionModeType.SALARY,
                                              )}
                                            >
                                              <Form.Group>
                                                {hasDefaultChecked(
                                                  SelectionModeType.SALARY,
                                                  manager.id,
                                                  planItem.planName,
                                                ) ? (
                                                  <Form.Check
                                                    type="radio"
                                                    label=" "
                                                    id={`default-salary-nonhjerta-plan-item-${idx}${planIdx}`}
                                                    className="fs-name"
                                                    name="salary"
                                                    onChange={(e) =>
                                                      setDefaultPlan(
                                                        manager.id,
                                                        planItem.planName,
                                                        SelectionModeType.SALARY,
                                                      )
                                                    }
                                                    disabled={
                                                      !hasIncluded(
                                                        SelectionModeType.SALARY,
                                                        manager.id,
                                                        planItem.planName,
                                                      )
                                                    }
                                                    checked={true}
                                                  />
                                                ) : (
                                                  <Form.Check
                                                    type="radio"
                                                    label=" "
                                                    id={`default-salary-nonhjerta-plan-item-${idx}${planIdx}`}
                                                    className="fs-name"
                                                    name="salary"
                                                    onChange={(e) =>
                                                      setDefaultPlan(
                                                        manager.id,
                                                        planItem.planName,
                                                        SelectionModeType.SALARY,
                                                      )
                                                    }
                                                    disabled={
                                                      !hasIncluded(
                                                        SelectionModeType.SALARY,
                                                        manager.id,
                                                        planItem.planName,
                                                      )
                                                    }
                                                    checked={false}
                                                  />
                                                )}
                                              </Form.Group>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>
                                              {isCompanyPolicyMode() &&
                                                hasIncluded(
                                                  SelectionModeType.SALARY,
                                                  manager.id,
                                                  planItem.planName,
                                                ) && (
                                                  <div className="mt-2 input-info">
                                                    <Form.Group className="mb-2">
                                                      <Form.Label className="theme-text fs-name">
                                                        {t('customer_number')}
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        name={`include-other-salary-customer-num-${idx}${planIdx}`}
                                                        value={defaultPlanNumber({
                                                          key: SelectionModeType.SALARY,
                                                          propName: 'customerNumber',
                                                          pensionManagerId: manager.id,
                                                          planID: planItem.planName,
                                                        })}
                                                        onChange={(e) =>
                                                          handleChangeNumberValue(e.target.value, {
                                                            key: SelectionModeType.SALARY,
                                                            propName: 'customerNumber',
                                                            pensionManagerId: manager.id,
                                                            planID: planItem.planName,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>

                                                    <Form.Group>
                                                      <Form.Label className="theme-text fs-name">
                                                        {t('insurance_number')}
                                                      </Form.Label>
                                                      <Form.Control
                                                        type="text"
                                                        name={`include-hjerta-salary-insurance-num-${idx}${planIdx}`}
                                                        value={defaultPlanNumber({
                                                          key: SelectionModeType.SALARY,
                                                          propName: 'insuranceNumber',
                                                          pensionManagerId: manager.id,
                                                          planID: planItem.planName,
                                                        })}
                                                        onChange={(e) =>
                                                          handleChangeNumberValue(e.target.value, {
                                                            key: SelectionModeType.SALARY,
                                                            propName: 'insuranceNumber',
                                                            pensionManagerId: manager.id,
                                                            planID: planItem.planName,
                                                          })
                                                        }
                                                      />
                                                    </Form.Group>
                                                  </div>
                                                )}
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </>
          )}
        </>
      )}
      <CostSpecificationModal
        show={showCostSpecModal}
        costSpecDetails={selectedCostPlanManager?.planItem?.costSpecification}
        planName={selectedCostPlanManager?.planItem?.planName}
        managerName={selectedCostPlanManager?.manager?.companyName}
        onHide={showParentModal}
        toggleModal={toggleCostSpecModal}
      />
    </>
  );
}
