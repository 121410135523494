import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';
import { errorHighlighter } from '../../../../util/form';
import { AccountingFirmSaveMode, FormProps } from '../types';
import useLocale from '../../../../hooks/useLocale';

export default function ContractDetails({ context }: FormProps) {
  const { localize } = useLocale();
  const { contractEffectiveFrom, firmDetails, formGroup, isEditMode, updateContractEffectiveInput } = context;

  return (
    <div>
      {!isEditMode ||
        (firmDetails?.status === AccountingFirmSaveMode.DRAFT && (
          <PagesHeader pageTitle="Contract details" headingClass="fs-h3 font-black" />
        ))}
      <div className="form-section mb-3 dark-content-section">
        <div className="cr-form-row">
          <div className="contract-effective-field">
            <Form.Label className="fs-name mb-0 ">
              {`${localize('contracts')} ${localize('effective_from')}`}
              <span className="font-weight-400 d-block theme-text required">{localize('contract_details_label')}</span>
            </Form.Label>{' '}
            <MonthAndYearDropdown
              startFrom={contractEffectiveFrom}
              value={contractEffectiveFrom}
              onOptionSelect={(val) => updateContractEffectiveInput(val)}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contractEffectiveFrom')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contractEffectiveFrom')} />
          </div>
          {/* <Col style={{ maxWidth: 250 }}>
            <Form.Label className="fs-name mb-0 required">{localize('invoice_email_address')}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              onChange={(e) => updateInvoiceEmailAddress(e.target.value)}
              name="contractEmail"
              required
              value={invoiceEmailAddress}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('invoiceEmailAddress')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('invoiceEmailAddress')} />
          </Col>
          <Col style={{ maxWidth: 220 }}>
            <Form.Label className="fs-name mb-0">
              {isEditMode && firmDetails?.status === AccountingFirmSaveMode.DRAFT
                ? localize('invoicing_cost_center')
                : localize('po_number')}
            </Form.Label>
            <Form.Control
              size="lg"
              type="number"
              onChange={(e) => updateInvoicingCostCenter(e.target.value)}
              name="poNumber"
              required
              className={classNames('text-left', errorHighlighter(formGroup.getFieldMeta('invoicingCostCenter')))}
              value={invoicingCostCenter}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('invoicingCostCenter')} />
          </Col> */}
        </div>
      </div>
    </div>
  );
}
