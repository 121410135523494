import React, { useRef, useState } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import Breadcrumb from '../../../components/base/Breadcrumb';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import AdminPensionCompanies from './AdminPensionComapnies';
import InternalCalculations from './InternalCalculations/InternalCalculations';
import SalaryCodeConversion from './SalaryCodeConversion';
import { AdminSettingsHeader } from './AdminSettingsHeader';
import General from './General/General';
import { useValidationError } from '../../../hooks/useValidationError';
import ValidationAlert from '../../../components/ValidationAlert';
import { PensionCompaniesProvider } from './AdminPensionComapnies/context/PensionCompaniesContext';
import { AdminSettingsProvider } from './AdminSettingsContext/AdminSettingsContext';
import { deletePensionCompany, updatePensionCompany } from '../../../api/service/superAdmin/settings/pension-companies';
import { useSuperAdminSettingsContext } from './useContext';
import {
  AdminSettingsFormGroup,
  GeneralSettingsRef,
  IInsuranceFormGroup,
  IInsurances,
  IPensionManagerFormGroup,
  InsuranceSaveMode,
  Mode,
  PensionManagerSaveMode,
  TypeAccountingSalaryCompany,
} from './types';
import { ConfirmationDialog } from '../../../util/dialogs';
import { TypePensionCompany } from './types';
import { AccountingSalaryCompanyProvider } from './AccountingSalaryCompany/context/AccountingSalaryCompanyContext';
import AccountingSalaryCompany from './AccountingSalaryCompany';
import { updatePAccountingSalaryCompany } from '../../../api/service/superAdmin/settings/accounting-salary-company';
import { InternalCalculationProvider } from './InternalCalculations/InternalCalculationContext';
import { includeKeys, isObjectEmpty } from '../../../util/util';
import useLocale from '../../../hooks/useLocale';
import Insurance from './AdminInsurance/Insurance';
import { InsurancesProvider } from './AdminInsurance/context/InsuranceContext';
import { deleteInsuranceCompany, updateInsuranceCompany } from '../../../api/service/superAdmin/settings/insurances';
import useTabChangeListener from '../../../hooks/useTabChangeListener';
import { IntegrationProvider } from './Integrations/context/IntegrationsContext';
import AdminIntegrations from './Integrations';

function AdminSettings() {
  const { localize } = useLocale();
  const [tabKey, setTabKey] = useState<string | any>('general');
  const { setMode, setAccountingMode } = useSuperAdminSettingsContext();
  const { errors, isInvalid, setErrors } = useValidationError();
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showAccountingCreateModal, setShowAccountingCreateModal] = useState<boolean>(false);
  const [showInsuranceCreateModal, setShowInsuranceCreateModal] = useState<boolean>(false);
  const [, /*isPensionCompanyCreated*/ setIsPensionCompanyCreated] = useState<boolean>(false);
  const [, /*isPensionCompanyCreated*/ setIsAccountingSalaryCompanyCreated] = useState<boolean>(false);
  const [, /*isPensionCompanyCreated*/ setIsInsuranceCreated] = useState<boolean>(false);
  const [, setPensionCompanyData] = useState<any>({ data: {}, form: {} });
  const [accountingSalaryCompanyData, setAccountingSalaryCompanyData] = useState<any>({ data: {}, form: {} });
  const [, setInsuranceData] = useState<any>({ data: {}, form: {} });
  const [pensionCompanyDetails, setPensionCompanyDetail] = useState<TypePensionCompany>();
  const [accountingSalaryCompanyDetails, setAccountingSalaryCompanyDetail] = useState<TypeAccountingSalaryCompany>();
  const [, /*insuranceDetails*/ setInsuraceDetail] = useState<IInsurances>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [pensionManagerRef, setPensionManagerRef] = useState<any>(null);
  const [insuranceRef, setInsuranceRef] = useState<any>(null);
  const [pensionManagerFormGroup, setPensionManagerFormGroup] = useState<IPensionManagerFormGroup | null>(null);
  const [insuranceFormGroup, setInsuranceFormGroup] = useState<IInsuranceFormGroup | null>(null);
  const [, /*adminFormGroup*/ setAdminFormGroup] = useState<AdminSettingsFormGroup | null>(null);
  const [salaryCodeCmp, setSalaryCodeCmp] = useState();
  const [integrationCmp, setIntegrationCmp] = useState();
  const [, /* adminRef */ setAdminRef] = useState<any>(null);
  const [generalSettingsRef, setGeneralSettingsRef] = useState<GeneralSettingsRef | null>();

  const salaryCodeRef: any = useRef();
  const integrationRef: any = useRef();

  const broadcastAdminValues = (ref) => {
    setAdminRef(ref);
  };

  const broadcasrSalaryCodeConversionValues = (ref) => {
    setSalaryCodeCmp(ref);
  };

  const broadcastPensionManagerRef = (ref) => {
    setPensionManagerRef(ref);
  };

  const broadcastInsuranceRef = (ref) => {
    setInsuranceRef(ref);
  };

  const broadcastIntegrationValues = (ref) => {
    setIntegrationCmp(ref);
    if (!isObjectEmpty(ref.errors)) {
      setErrors(ref.errors);
    } else {
      setErrors({});
    }
  };

  const patchPensionManagerForm = (value: IPensionManagerFormGroup) => {
    setPensionManagerFormGroup(value);
  };

  const patchInsuranceForm = (value: IInsuranceFormGroup) => {
    setInsuranceFormGroup(value);
  };

  const patchAdminForm = (value: AdminSettingsFormGroup) => {
    setAdminFormGroup(value);
  };

  const toggleModal = () => {
    setShowCreateModal(!showCreateModal);
    setIsPensionCompanyCreated(false);
  };

  const toggleInsuranceModal = () => {
    setShowInsuranceCreateModal(!showInsuranceCreateModal);
    setIsInsuranceCreated(false);
  };

  const accountingCompanyToggleModal = () => {
    setShowAccountingCreateModal(!showAccountingCreateModal);
    setIsAccountingSalaryCompanyCreated(false);
  };

  const onUpdatePensionCompany = (form, data) => {
    setPensionCompanyData({ data, form });
  };

  const handleSaveCompany = async (saveMode) => {
    const formGroup = pensionManagerFormGroup!;
    const isDraftMode = saveMode === PensionManagerSaveMode.DRAFT;

    if (isDraftMode) {
      formGroup.setSubmitting(false);
      formGroup.setTouched({}, false);
      formGroup.setErrors({});

      formGroup.validateForm().then(async (errors) => {
        await formGroup.setFieldTouched('pensionCompany.shortName', true, true);
        await formGroup.setFieldTouched('pensionCompany.companyName', true, true);

        const draftErrors = includeKeys(errors, ['pensionCompany.shortName', 'pensionCompany.companyName']);

        setErrors(draftErrors);

        if (isObjectEmpty(draftErrors)) {
          savePensionManager(saveMode);
        }
      });

      return;
    }

    formGroup.handleSubmit();
    formGroup.validateForm().then(async (errors) => {
      setErrors(errors);

      if (isObjectEmpty(errors)) {
        savePensionManager(saveMode);
      }
    });
  };

  const savePensionManager = async (saveMode) => {
    try {
      const data = pensionManagerRef.getRequestPayload({ status: saveMode }, pensionManagerFormGroup)!;
      await updatePensionCompany(pensionManagerRef.selectedPensionID, data);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setMode(Mode.List);
      // setIsSaving(false);
    }
  };

  const onDetailsPensionCompany = (data) => {
    setPensionCompanyDetail(data);
  };

  const onDelete = async () => {
    const title = localize('confirm_delete_pension_company');
    const result = await ConfirmationDialog({
      title,
      confirmButtonText: localize('yes'),
      denyButtonText: localize('no'),
    });
    if (result.isConfirmed) {
      try {
        setIsDeleting(true);
        const id = pensionManagerRef.selectedPensionID;
        await deletePensionCompany(id);
        setMode(Mode.List);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const onUpdateAccountingSalaryCompany = (form, data) => {
    setAccountingSalaryCompanyData({ data, form });
  };

  const handleSaveAccountingSalaryCompany = async () => {
    accountingSalaryCompanyData.form.handleSubmit();
    accountingSalaryCompanyData.form.validateForm().then((errors) => {
      setErrors(errors);
    });
    if (!accountingSalaryCompanyData?.form.isValid) {
      return false;
    }

    try {
      await updatePAccountingSalaryCompany(accountingSalaryCompanyData.data?.id, accountingSalaryCompanyData.data);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setAccountingMode(Mode.List);
      // setIsSaving(false);
    }
  };
  const onDetailsAccountingSalaryCompany = (data) => {
    setAccountingSalaryCompanyDetail(data);
  };

  const onDeleteAccountingSalaryCompany = async () => {
    const title = localize('confirm_delete_accounting_and_company');
    const result = await ConfirmationDialog({
      title,
      confirmButtonText: localize('yes'),
      denyButtonText: localize('no'),
    });
    if (result.isConfirmed) {
      try {
        setIsDeleting(true);
        await deletePensionCompany(accountingSalaryCompanyDetails?.id);
        setAccountingMode(Mode.List);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  // Insurance

  const onUpdateInsurance = (form, data) => {
    setInsuranceData({ data, form });
  };
  const handleSaveInsurance = async (saveMode) => {
    const formGroup = insuranceFormGroup!;
    const isDraftMode = saveMode === InsuranceSaveMode.DRAFT;

    if (isDraftMode) {
      formGroup.setSubmitting(false);
      formGroup.setTouched({}, false);
      formGroup.setErrors({});

      formGroup.validateForm().then(async (errors) => {
        await formGroup.setFieldTouched('insuranceCompany.shortName', true, true);
        await formGroup.setFieldTouched('insuranceCompany.companyName', true, true);

        const draftErrors = includeKeys(errors, ['insuranceCompany.shortName', 'insuranceCompany.companyName']);

        setErrors(draftErrors);

        if (isObjectEmpty(draftErrors)) {
          saveInsurance(saveMode);
        }
      });

      return;
    }

    formGroup.handleSubmit();
    formGroup.validateForm().then(async (errors) => {
      setErrors(errors);

      if (isObjectEmpty(errors)) {
        saveInsurance(saveMode);
      }
    });
  };
  const saveInsurance = async (saveMode) => {
    try {
      const { insuranceCompany, insurances, insurancePlans, connection, ...formData } = insuranceFormGroup!.values;
      const data = {
        ...insuranceCompany,
        // pensionManagerPlans: {
        //   ...pensionManagerPlans,
        //   plans: (pensionManagerPlans.plans || []).map((item) => excludeKeys(item, ['id'])),
        // },
        connection: {
          ...connection,
          connectionTypeFile: Object.assign(
            {},
            {
              ...(connection?.connectionTypeFile || {}),
            },
            !connection?.connectionTypeFile?.email ? { email: undefined } : connection?.connectionTypeFile,
          ),
        },
        ...formData,
        status: saveMode,
        insurances: insurancePlans,
      };
      await updateInsuranceCompany(insuranceRef.selectedInsuranceID, data);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setMode(Mode.List);
      // setIsSaving(false);
    }
  };
  const onDetailsInsurance = (data) => {
    setInsuraceDetail(data);
  };
  const onDeleteInsurance = async () => {
    const title = localize('confirm_delete_insurance_company');
    const result = await ConfirmationDialog({
      title,
      confirmButtonText: localize('yes'),
      denyButtonText: localize('no'),
    });
    if (result.isConfirmed) {
      try {
        setIsDeleting(true);
        const id = insuranceRef.selectedInsuranceID;
        await deleteInsuranceCompany(id);
        setMode(Mode.List);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const onSelectTab = (event) => {
    setTabKey(event);
    setMode(Mode.List);
  };

  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Settings',
    },
    {
      name: pensionCompanyDetails?.shortName ?? '',
      translate: false,
    },
  ];
  const broadcastGeneralSettingsChanges = (ref) => {
    setGeneralSettingsRef(ref);
  };

  useTabChangeListener(tabKey);

  return (
    <Container className="p-0" fluid>
      <Breadcrumb navItem={breadcrumb} />
      <InternalCalculationProvider>
        <>
          <AdminSettingsHeader
            tabKey={tabKey}
            toggleModal={toggleModal}
            toggleInsuranceModal={toggleInsuranceModal}
            handleSaveCompany={handleSaveCompany}
            handleSaveAccountingSalaryCompany={handleSaveAccountingSalaryCompany}
            handleSaveInsurance={handleSaveInsurance}
            onDelete={onDelete}
            onDeleteAccountingSalaryCompany={onDeleteAccountingSalaryCompany}
            onDeleteInsurance={onDeleteInsurance}
            isDeleting={isDeleting}
            accountingCompanyToggleModal={accountingCompanyToggleModal}
            formData={pensionManagerFormGroup?.values}
            insuranceFormData={insuranceFormGroup?.values}
            salaryCodeCmp={salaryCodeCmp}
            integrationCmp={integrationCmp}
            generalSettingsRef={generalSettingsRef}
          />
          <ValidationAlert show={isInvalid} errors={errors} />
          <Tabs
            activeKey={tabKey}
            onSelect={(k) => onSelectTab(k)}
            style={{ borderBottom: 'none' }}
            defaultActiveKey="general"
          >
            <Tab tabClassName="border-none" eventKey="general" title={localize('general')}>
              <AdminSettingsProvider patchAdminForm={patchAdminForm} emitValues={broadcastAdminValues}>
                <General emitValues={broadcastGeneralSettingsChanges} />
              </AdminSettingsProvider>
            </Tab>
            <Tab tabClassName="border-none" eventKey="internal_calculations" title={localize('internal_calculations')}>
              <InternalCalculations />
            </Tab>
            <Tab
              tabClassName="border-none"
              eventKey="salary_code_conversion"
              title={`${localize('salary_code')} ${localize('conversion')}`}
            >
              <SalaryCodeConversion ref={salaryCodeRef} emitValues={broadcasrSalaryCodeConversionValues} />
            </Tab>
            <Tab
              tabClassName="border-none"
              eventKey="pension_managers"
              title={localize('pension_managers')}
              id="pension_managers"
            >
              <PensionCompaniesProvider
                emitValues={broadcastPensionManagerRef}
                patchPensionManagerForm={patchPensionManagerForm}
              >
                <AdminPensionCompanies
                  setIsPensionCompanyCreated={setIsPensionCompanyCreated}
                  showCreateModal={showCreateModal}
                  toggleModal={toggleModal}
                  updatePensionCompany={onUpdatePensionCompany}
                  onDetailsPensionCompany={onDetailsPensionCompany}
                />
              </PensionCompaniesProvider>
            </Tab>
            <Tab tabClassName="border-none" eventKey="insurances" title={localize('insurances')} id="insurances">
              <InsurancesProvider emitValues={broadcastInsuranceRef} patchInsuranceForm={patchInsuranceForm}>
                <Insurance
                  setIsInsuranceCreated={setIsInsuranceCreated}
                  showCreateModal={showInsuranceCreateModal}
                  toggleModal={toggleInsuranceModal}
                  updateInsurance={onUpdateInsurance}
                  onDetailsInsurance={onDetailsInsurance}
                />
              </InsurancesProvider>
            </Tab>
            <Tab
              tabClassName="border-none"
              eventKey="acounting_salary_company"
              title={`${localize('accounting_and_salary_company')}`}
            >
              <AccountingSalaryCompanyProvider>
                <AccountingSalaryCompany
                  setIsAccountingSalaryCompanyCreated={setIsAccountingSalaryCompanyCreated}
                  showCreateModal={showAccountingCreateModal}
                  toggleModal={accountingCompanyToggleModal}
                  updateAccountingSalaryCompany={onUpdateAccountingSalaryCompany}
                  onDetailsAccountingSalaryCompany={onDetailsAccountingSalaryCompany}
                />
              </AccountingSalaryCompanyProvider>
            </Tab>
            <Tab tabClassName="border-none" eventKey="integrations" title={`${localize('integrations')}`}>
              <IntegrationProvider>
                <AdminIntegrations ref={integrationRef} emitValues={broadcastIntegrationValues} />
              </IntegrationProvider>
            </Tab>
          </Tabs>
        </>
      </InternalCalculationProvider>
    </Container>
  );
}

export default AdminSettings;
