import useLocale from '../../hooks/useLocale';

type Props = {
  text: string;
};

export const Locale = ({ text }: Props) => {
  const { localize } = useLocale();

  return <>{localize(text)}</>;
};
