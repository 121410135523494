import * as yup from 'yup';
import {
    organizationNumberValidations,
} from '../../../../../util/validation';

export const formSchema = yup.object().shape({
    accountingSalaryCompany: yup.object().shape({
        shortName: yup.string().required('Required'),
        companyName: yup.string().required('Required'),
        clientNumber: yup.string().required('Required'),
        contractNumber: yup.string().required('Required'),
        description: yup.string().required('Required'),
        organizationNumber: organizationNumberValidations(),
        vatNumber: yup.string().required('Required'),
        bankgiroNumber: yup.string().required('Required'),
        plusgiroNumber: yup.string().required('Required'),
        integrationType: yup.array(),
        apiKey: yup.string().required('Required'),
    }),
    contactInformation: yup.object().shape({
        address: yup.string().required('Required'),
        city: yup.string().required('Required'),
        phone: yup.string().required('Required'),
        fax: yup.string().required('Required'),
        postalCode: yup.string().required('Required'),
        email: yup.string().email('Invalid Email').required('Required'),
    }),

})