import { AlertTriangle, ChevronRight } from 'react-feather';
import { ReactComponent as AlertVector } from '../assets/img/alert-triangle.svg';
import { Link } from 'react-router-dom';
import { OverviewErrorAlert } from '../types';

type Props = {
  dashboardErrorAlerts?: OverviewErrorAlert[];
};

export const DashboardErrorAlert = ({ dashboardErrorAlerts }: Props) => {
  return (
    <div className="new-alert mt-2">
      <span className="bg-alert-icon">
        <AlertVector />
      </span>
      <div className="d-flex">
        <div className="d-flex pt-3 ps-3">
          <AlertTriangle className="color-danger" />
        </div>
        <ul className="error-items w-100 ps-1 pt-3">
          {dashboardErrorAlerts?.map((error, index) => (
            <li className="fs-label color-danger d-flex justify-content-between" key={index}>
              {error.msg}
              <Link to={error.link!}>
                <div className="fs-tertiery alert-action font-color-primary cursor-pointer">
                  <ChevronRight /> {error.linkText}
                </div>
              </Link>
            </li>
          ))}
          {/* <li className="fs-label color-danger d-flex justify-content-between">
            {'10 Processing errors'}
            <div className="fs-tertiery alert-action font-color-primary cursor-pointer">
              <ChevronRight /> Data Management
            </div>
          </li> */}
          {/* <li className="fs-label color-danger d-flex justify-content-between">{'10 Processing errors '}</li> */}
        </ul>
      </div>
    </div>
  );
};
