import { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import Breadcrumb from '../../../components/base/Breadcrumb';
import PagesHeader from '../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import PersonalClientGrid from './PersonalClientGrid';
import CreatePersonalClientModal from './CreatePersonalClient/CreatePersonalClientModal';
import { CreatePersonalClientProvider } from './context/CreatePersonalClientContext';
import useLocale from '../../../hooks/useLocale';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Personal Clients',
  },
];

function PersonalClientsPage() {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isClientCreated, setIsClientCreated] = useState<boolean>(false);
  const { localize } = useLocale();

  const toggleModal = () => setShowCreateModal(!showCreateModal);

  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader pageTitle="Personal Clients" headingClass="fs-h1" />
        <div className="cr-form-row">
          <Button onClick={toggleModal} className="app-btn-primary">
            <Plus className="icon-gap-right" />
            {`${localize('new_personal_client')}`}
          </Button>
        </div>{' '}
      </div>
      <PersonalClientGrid isClientCreated={isClientCreated} />

      {showCreateModal && (
        <CreatePersonalClientProvider>
          <CreatePersonalClientModal
            setIsClientCreated={setIsClientCreated}
            toggleModal={toggleModal}
            showCreateModal={showCreateModal}
          />
        </CreatePersonalClientProvider>
      )}
    </Container>
  );
}

export default PersonalClientsPage;
