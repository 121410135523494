import * as yup from 'yup';
import { isEmpty } from './util';

export function validatePersonalNumber(this: any, value): any {
  if (value == null || (typeof value === 'string' && value.trim().length === 0) || isEmpty(value)) {
    return true;
  }
  const reg = new RegExp(
    /^\(?((19|20)[0-9]{2})\)?[-. ]?(0[1-9]|1[0-2])[-. ]?(0[1-9]|1[0-9]|2[0-9]|3[01])[-. ]?([0-9]{4})$/,
  );
  if (!reg.test(value)) {
    return this.createError({ message: `Invalid Personal Number` });
  }
  return true;
}

export function personalNumberValidations(isRequired: boolean = true) {
  const validator = yup
    .string()
    .trim('Personal Number cannot contain spaces')
    .test('personal_number_validator', '', validatePersonalNumber);

  if (isRequired) {
    return validator.required('Required');
  }

  return validator;
}

export function validateOrganizationNumber(this: any, value) {
  if (value == null || (typeof value === 'string' && value.trim().length === 0) || isEmpty(value)) {
    return true;
  }
  const reg = new RegExp(/^\(?(((2|5|7|8|9))[0-9]{5})\)?[-. ]?([0-9]{4})$/);
  if (!reg.test(value)) {
    return this.createError({ message: `Invalid Organization Number` });
  }
  return true;
}

export function organizationNumberValidations(isRequired: boolean = true) {
  const validator = yup
    .string()
    .trim('Organization Number cannot contain spaces')
    .test('organization_number_validator', '', validateOrganizationNumber);
  if (isRequired) {
    return validator.required('Required');
  }
  return validator;

}

yup.addMethod(yup.string, 'organizationNumberValidations', organizationNumberValidations);
yup.addMethod(yup.string, 'personalNumberValidations', personalNumberValidations);
