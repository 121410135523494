import { FileExportType, ReportCategory } from '../../../types/report';

export const CUSTOM_REPORT_TYPES = [
  {
    field: ReportCategory.GROSS_SALARY,
    label: 'Gross salary',
    checked: false,
    isDisable: false,
    labelKey: 'REPORTS.gross_salary'
  },
  {
    field: ReportCategory.SOCIAL_TAX,
    label: 'Social tax',
    checked: false,
    isDisable: false,
    labelKey: 'REPORTS.social_tax'
  },
  {
    field: ReportCategory.CALCULATED_PENSION,
    label: 'Calculated pension',
    checked: false,
    isDisable: false,
    labelKey: 'REPORTS.calculated_pension'
  },
  {
    field: ReportCategory.SALARY_CHANGE,
    label: 'Salary change',
    checked: false,
    isDisable: false,
    labelKey: 'REPORTS.salary_change'
  },
  {
    field: ReportCategory.SPECIAL_PAYROLL_TAX,
    label: 'Special payroll tax',
    checked: false,
    isDisable: false,
    labelKey: 'REPORTS.special_payroll_tax'
  },
];

export const REPORT_EXPORT_OPTIONS = [
  {
    label: 'Download CSV',
    value: FileExportType.CSV,
  },
  {
    label: 'Download XLS',
    value: FileExportType.XLSX,
  },
];

