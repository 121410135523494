import { useEffect } from 'react';
import { Row, Form, Col, Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/settings/pension-manager.module.scss';

import PlanList from './PlanList';
import PagesHeader from '../../../../../components/base/PagesHeader';
import HorizontalRule from '../../../../../components/base/HorizontalRule';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import { Mode, PensionCompanyFormProps } from '../../types';
import { generateUniqueId } from '../../../../../util/util';
import useLocale from '../../../../../hooks/useLocale';
import classNames from 'classnames';

export const PensionManagerPlan = ({ context, mode }: PensionCompanyFormProps) => {
  const { replacePensionManagerPlan, updatePensionManagerPlan, formGroup, initializeDefaultPlans } = context;
  const { localize } = useLocale();
  const managerPlans = formGroup.values?.pensionManagerPlans.plans || [];
  const isEditable = [Mode.Edit, Mode.Add].indexOf(mode) !== -1;

  const handlePensionManager = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === 'correctoPartner') {
      value = e.target.checked;
    }

    updatePensionManagerPlan(name, value);
  };

  const addProjectManagerPlan = () => {
    replacePensionManagerPlan([
      ...managerPlans,
      { id: generateUniqueId(), planName: '', providerDescription: '', order: managerPlans.length, documents: [] },
    ]);
  };

  useEffect(() => {
    if (mode === Mode.Add) {
      initializeDefaultPlans();
    }
  }, []);

  return (
    <>
      <PagesHeader headingClass="fs-h3" pageTitle="Pension manager plans" />
      <Row className="mb-4">
        <Col md="10">
          <Form.Label className="fs-name">
            {localize('provider')} {localize('description')}
          </Form.Label>
          <Form.Control
            as="textarea"
            style={{ height: 80 }}
            value={formGroup.values?.pensionManagerPlans?.providerDescription}
            onChange={handlePensionManager}
            name="providerDescription"
            disabled={mode === Mode.Detail}
          />
        </Col>
        <Col md="2">
          <Form.Check
            checked={formGroup.values.pensionManagerPlans?.correctoPartner}
            onChange={handlePensionManager}
            inline
            name="correctoPartner"
            type="checkbox"
            label={`${localize('correcto_partner')}`}
            className="fs-name mt-4"
            id="correctoPartner"
            disabled={mode === Mode.Detail}
          />

          <ErrorFeedback field={formGroup.getFieldMeta('pensionManagerPlans.correctoPartner')} />
        </Col>
      </Row>
      <PagesHeader headingClass="fs-h4" pageTitle="Plans" />
      <HorizontalRule height="2px" />
      <div
        className={classNames(
          'table-responsive react-bootstrap-table pension-plans-table mb-2',
          themeStyle.pm_setting_grid,
        )}
      >
        <table className="table table-hover table-condensed mb-1">
          <tbody>
            <PlanList context={context} mode={mode} managerPlans={managerPlans} isEditable={isEditable} />
          </tbody>
        </table>
      </div>
      {mode !== Mode.Detail && (
        <div>
          <Button className="app-btn-secondary btn btn-primary" onClick={addProjectManagerPlan}>
            <Plus />
          </Button>
        </div>
      )}
    </>
  );
};
