import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import usePolicyMode from '../hooks/usePolicyMode';
import useContext from '../PensionPolicyContext/useContext';
import useAuth from '../../../../hooks/useAuth';
import { PensionPolicyVariant } from '../types';

const AutoAssignEmployeeTypeSetting = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { selectedPolicyDetails } = useContext();
  // const autoAssingInputValue = formGroup.values.isAutoAssign;
  const { isCompanyPolicyMode, isCompanyReadOnlyMode } = usePolicyMode();

  /*  const [isCheckedAutoAssignmentSettings, setIsCheckedAutoAssignmentSettings] = useState(false);
  const [isAutoAssignedEmployeeType, setIsAutoAssignedEmployeeType] = useState(false);
  const [hasToggledAutoAssignEmployeeTypeNotification ] =
    useState(false); */

  const isPolicyAutoAssigned = (selectedPolicyDetails?.autoAssignCompanies || []).find(
    (item) => item === user?.role?.company?.id,
  );

  /* const fetchCompanyWorkflowSettings = async () => {
    if (!isCompanyPolicyMode()) {
      return;
    }

    const policyAssignmentRes: CompanyPolicyWorkflow = await getCompanyWorkflow();

    if (policyAssignmentRes) {
      const isBlueCollareAutoAssigned =
        policyAssignmentRes?.blueCollarPolicy?.automatic && policyAssignmentRes.blueCollarPolicy.policyId;
      const isWhiteCollareAutoAssigned =
        policyAssignmentRes.whiteCollarPolicy?.automatic && policyAssignmentRes.whiteCollarPolicy.policyId;

      if (isBlueCollareAutoAssigned || isWhiteCollareAutoAssigned) {
        setIsCheckedAutoAssignmentSettings(true);
      } else {
        setIsCheckedAutoAssignmentSettings(false);
      }

      return;
    }

    setIsCheckedAutoAssignmentSettings(false);
  };

  const fetchCompanyAutoAssingEmployeeType = async () => {
    const isAutoAssign = await isAutoAssignEmployeePolicyType(selectedPolicyDetails.employeeType);

    setIsAutoAssignedEmployeeType(isAutoAssign || false);
  }; */

  /*  const handleAutoAssignEmployeeType = async (isChecked) => {
    if (isAutoAssignedEmployeeType && isChecked) {
      if (!hasToggledAutoAssignEmployeeTypeNotification) {
        // setHasToggledAutoAssignEmployeeTypeNotification(true);

        const result = await ConfirmationDialog({
          title: t('PENSION_POLICY.confirm_auto_assign_policy')!,
          confirmButtonText: t('yes'),
          denyButtonText: t('no'),
        });

        if (result.isConfirmed) {
          updatePolicyFieldInput('isAutoAssign', true);
        } else {
          updatePolicyFieldInput('isAutoAssign', false);
        }
      } else {
        updatePolicyFieldInput('isAutoAssign', isChecked);
      }
    } else {
      updatePolicyFieldInput('isAutoAssign', isChecked);
    }
  }; */

  /* useEffect(() => {
    if (isCompanyPolicyMode()) {
      fetchCompanyWorkflowSettings();
    }
  }, []);

  useEffect(() => {
    if (isCompanyPolicyMode()) {
      fetchCompanyAutoAssingEmployeeType();
    }
  }, [selectedPolicyDetails]); */

  if (!isCompanyPolicyMode()) {
    return <></>;
  }

  return (
    <Form.Group className="pe-0" style={{ width: 200 }}>
      {/* {hasEditAction() && (
        <Form.Check
          className="fs-name mb-1 align-items-start"
          inline
          type="checkbox"
          id="isAutoAssign"
          label={t('PENSION_POLICY.auto_assign_new_employee_text')}
          name="isAutoAssign"
          checked={autoAssingInputValue}
          onChange={(e) => handleAutoAssignEmployeeType(e.target.checked)}
        />
      )} */}
      {isCompanyReadOnlyMode() && selectedPolicyDetails?.type !== PensionPolicyVariant.PERSONAL && (
        <Form.Check
          className="fs-name mb-1 align-items-start me-0"
          inline
          type="checkbox"
          id="isAutoAssign"
          label={t('PENSION_POLICY.auto_assign_new_employee_text')}
          name="isAutoAssign"
          checked={isPolicyAutoAssigned}
          disabled
        />
      )}
    </Form.Group>
  );
};

export default AutoAssignEmployeeTypeSetting;
