import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { deletePensionCompany } from '../../../../api/service/superAdmin/settings/pension-companies';
import { ConfirmationDialog } from '../../../../util/dialogs';
import useContext from './context/useContext';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

type Props = {
  id: string;
  type: string;
  data: any;
};

export const DeleteAction = ({ id, type, data }: Props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { formGroup, replacePensionCompanies } = useContext();
  const { t } = useTranslation();
  const onDelete = async () => {
    const title = t('confirm_delete_pension_company');

    const result = await ConfirmationDialog({
      title,
      confirmButtonText: t('yes'),
      denyButtonText: t('no'),
    });
    if (result.isConfirmed) {
      try {
        setIsBusy(true);
        await deletePensionCompany(id);
        if (type === 'hjertaPartner') {
          const filteredPensionCompanies = formGroup.values.pensionCompanies['hjertaPartner'].filter(
            (company) => company.id !== id,
          );

          replacePensionCompanies({ hjertaPartner: filteredPensionCompanies, notHjertaPartner: data.notHjertaPartner });
        }
        if (type === 'notHjertaPartner') {
          const filteredPensionCompanies = formGroup.values.pensionCompanies['notHjertaPartner'].filter(
            (company) => company.id !== id,
          );

          replacePensionCompanies({ hjertaPartner: data.hjertaPartner, notHjertaPartner: filteredPensionCompanies });
        }
      } finally {
        setIsBusy(false);
      }
    }
  };
  return isBusy ? (
    <Spinner />
  ) : (
    <TooltipBox msg={`${t('delete')}`}>
      <Trash2 className="delete-icon cursor-pointer" onClick={onDelete} />
    </TooltipBox>
  );
};
