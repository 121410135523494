import { API_VERSION, INTEGRATIONS_ENDPOINT } from '../../../../util/constants';
import { del, get, post, put } from '../../../../util/httpService';

export const getIntegrations = async () => {
    let integrations = await get(`${API_VERSION}${INTEGRATIONS_ENDPOINT}`);

    return integrations;
};

export const createIntegration = async (payload) => {
    const integration = await post(`${API_VERSION}${INTEGRATIONS_ENDPOINT}`, payload, {});
    return integration;
};

export const updateIntegration = async (id, payload) => {
    const integration = await put(`${API_VERSION}${INTEGRATIONS_ENDPOINT}/${id}`, payload, {});
    return integration;
};

export const deleteIntegration = async (id) => {
    const integration = await del(`${API_VERSION}${INTEGRATIONS_ENDPOINT}/${id}`, {});
    return integration;
};

export const getIntegrationDetail = async (id) => {
    const integration = await get(`${API_VERSION}${INTEGRATIONS_ENDPOINT}/${id}`);
    return integration;
};

export const sortIntegrations = async (payload) => {
    const integration = await post(`${API_VERSION}${INTEGRATIONS_ENDPOINT}/orders`, payload, {});
    return integration;
};
