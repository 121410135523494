import { Button } from 'react-bootstrap';
import { Send } from 'react-feather';
import PagesHeader from '../../../components/base/PagesHeader';
import { useTranslation } from 'react-i18next';

export default function BillingPageHeader({ hideInvoiceBtn }: { hideInvoiceBtn: boolean }) {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-between">
      <PagesHeader pageTitle="Billing" headingClass="fs-h1" />
      {!hideInvoiceBtn && (
        <span>
          <Button className="app-btn-primary me-1">
            <Send className="icon-gap-right" /> {t('send_for_invoicing')}
          </Button>
        </span>
      )}
    </div>
  );
}
