import { Button, Form } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';

import QrCode from './QRCode';
import { GrandIDAuthStatus } from '../../types/auth';

interface Props {
  signInScanCode?: string | null;
  verificationStatus: GrandIDAuthStatus;
  onRetrySignIn: () => void;
}

function MobileVerification({ signInScanCode, verificationStatus, onRetrySignIn }: Props) {
  return (
    <>
      <div className="mt-4">
        <Form.Label className="fs-h3 font-black d-flex">Sign in with Mobile BankID</Form.Label>
        {verificationStatus === GrandIDAuthStatus.PENDING && (
          <>
            <div className="">
              <div className="theme-text">1. Open the BankID app in your smartphone.</div>
              <div className="theme-text">2. Press “Scan QR code”</div>
              <div className="theme-text">3. Scan the QR code</div>
            </div>
            <div className="qrcode-wrapper">
              <QrCode qrCode={signInScanCode} />
            </div>
          </>
        )}

        {verificationStatus === GrandIDAuthStatus.FAILED && (
          <>
            <div className="theme-text mb-3 mt-3">
              The QR code could not be read as it took too long, please try again. If you do not have the BankID app,
              you must install it and download a BankID from your internet bank.
            </div>
            <div className="d-flex justify-content-center">
              <Button onClick={() => onRetrySignIn()} style={{ textAlign: 'left' }} className="app-btn-primary">
                <ChevronRight /> Try again
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default MobileVerification;
