import { SetStateAction, Dispatch } from 'react';
import BSModal from '../../../../../components/Modal';
import { CreatePensionCompanyModalFooter } from './CreatePensionCompanyModalFooter';
import { useValidationError } from '../../../../../hooks/useValidationError';
import { Form } from 'react-bootstrap';
import ValidationAlert from '../../../../../components/ValidationAlert';
import EditPensionCompany from '../EditPensionCompany';
import useContext from '../context/useContext';
import { INITIAL_CONTACT_INFORMATION, INITIAL_PENSION_COMPANY } from '../constants';
import useLocale from '../../../../../hooks/useLocale';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsPensionCompanyCreated: Dispatch<SetStateAction<boolean>>;
};

export const CreatePensionCompanyModal = ({ showCreateModal, toggleModal, setIsPensionCompanyCreated }: Props) => {
  const { isInvalid, errors, setErrors } = useValidationError();
  const { setPensionCompany, setContactInformation } = useContext();
  const { localize } = useLocale();
  return (
    <>
      <BSModal
        title={`${localize('add_pension_manager')}`}
        show={showCreateModal}
        onHide={() => {
          setPensionCompany(INITIAL_PENSION_COMPANY);
          setContactInformation(INITIAL_CONTACT_INFORMATION);
          toggleModal();
        }}
        centered
        ModalFooter={
          <CreatePensionCompanyModalFooter
            setIsPensionCompanyCreated={setIsPensionCompanyCreated}
            toggleModal={toggleModal}
            setErrors={setErrors}
          />
        }
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />
          <EditPensionCompany />
        </Form>
      </BSModal>
    </>
  );
};
