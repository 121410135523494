import { Form } from 'react-bootstrap';
import useContext from './context/useContext';
import CardComponent from '../../../../components/base/CardComponent';
import { useSuperAdminSettingsContext } from '../useContext';
import { Mode } from '../types';
import CompanyInformation from './AdminInsuranceForm/CompanyInformation';
import Connection from './AdminInsuranceForm/Connection';
import { InsurancePlan } from './AdminInsuranceForm/InsurancePlan';

export default function EditInsurance() {
  const context = useContext();
  const { mode } = useSuperAdminSettingsContext();

  const isEditable = [Mode.Edit, Mode.Detail].indexOf(mode) !== -1;
  const bodyContainer = (
    <Form>
      <CompanyInformation context={context} mode={mode} />
      <Connection context={context} mode={mode} />
      <InsurancePlan context={context} mode={mode} />
    </Form>
  );

  return <>{isEditable ? <CardComponent>{bodyContainer}</CardComponent> : <>{bodyContainer}</>}</>;
}
