import classNames from 'classnames';
import React from 'react';
import { Form } from 'react-bootstrap';
import { UserPlus } from 'react-feather';
import { useTranslation } from 'react-i18next';

type Props = {
  options: any;
  note?: React.ReactNode;
  addBtnText?: string;
  limitOptions?: number;
  showTotalOption?: boolean;
  showAddBtn?: boolean;
  translate?: boolean;
  openAddFilterModal?: (...args) => void;
};

export default function CustomChartSwitchFilter({
  options,
  note,
  addBtnText,
  limitOptions = 6,
  showTotalOption = false,
  showAddBtn = false,
  translate = false,
  openAddFilterModal,
}: Props) {
  const { t } = useTranslation();
  const getCustomChartLabel = (label, labelKey) => {
    if (translate) {
      return labelKey ? t(labelKey) : label;
    } else {
      return label;
    }
  };

  return (
    <>
      {note && <div className="mb-2">{note}</div>}
      <div className="chart-series-filter align-items-start mb-3">
        <div className="switch-filter-section">
          {showTotalOption && options.length >= 1 && (
            <div
              className={classNames('form-switch-wrapper form-switch-wrapper--cancelled form-switch-off', 'me-2')}
              style={{
                background: '#d3d3d3',
              }}
            >
              <Form.Check
                type="switch"
                bsPrefix="chart-switch"
                id={'custom-switch'}
                className={classNames('custom-switch')}
                label={t('total')}
                value={'Total'}
                disabled={true}
                style={{ paddingRight: '0.5em' }}
              />
            </div>
          )}
          {options?.map((item, index) => (
            <div
              className={classNames('form-switch-wrapper form-switch-wrapper--cancelled form-switch-off', 'me-2')}
              style={{
                background: '#d3d3d3',
              }}
              key={index}
            >
              <Form.Check
                type="switch"
                bsPrefix="chart-switch"
                id={'custom-switch'}
                className={classNames('custom-switch')}
                label={getCustomChartLabel(item.label, item.labelKey)}
                value={item.value}
                disabled={true}
                style={{ paddingRight: '0.5em' }}
              />
            </div>
          ))}
        </div>

        {showAddBtn && options.length <= limitOptions && (
          <span
            className={classNames('chart-series-filter__add-btn', options.length < 1 && 'p-0')}
            onClick={openAddFilterModal}
          >
            <UserPlus className="theme-text icon-gap-right" />
            <span className="theme-text">{addBtnText || t('add_employee')}</span>
          </span>
        )}
      </div>
    </>
  );
}
