import { useEffect, useState } from 'react';
import useAuth from '../../../../../hooks/useAuth';
import { ISeries, ISeriesOptions } from '../../../../../components/charts/multi-series/types';
import { IReportFilterParams, IEmployeeSalaryReportResponse } from '../../../../../types/report';
import { Calendar } from '../../../../../util/calendar';

const useCalculatedSalaryChart = (
  requestFn: (param: IReportFilterParams) => Promise<IEmployeeSalaryReportResponse>,
) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState<[string, string]>(Calendar.yearToDateBoundary);
  const [chartRecords, setChartRecords] = useState<any[]>([]);
  const [employeeSeries /*setEmployeeSeries*/] = useState<ISeries[]>([
    { label: 'Calculated', field: 'calculated', checked: true, labelKey: 'REPORTS.calculated' },
    { label: 'Salary Change', field: 'salary_change', checked: true, labelKey: 'REPORTS.salary_change' },
  ]);
  const seriesOptions: ISeriesOptions = {
    series: employeeSeries,
    allowDeleteSeries: false,
    allowAddSeries: false,
  };

  const changeDateHandler = (dateRange: [string, string]) => {
    setDateRange(dateRange);
  };

  const fetchChartData = async () => {
    const startDateOfTheYear = dateRange[0];
    const endDateOfTheYear = dateRange[1];

    try {
      setIsLoading(true);

      let chartData = await requestFn({
        duration: { from: startDateOfTheYear, to: endDateOfTheYear },
        companyClientId: user?.role.company.id,
      });
      setChartRecords(chartData?.salariesByDate);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [dateRange]);

  return {
    seriesOptions,
    dateRange,
    chartRecords,
    isLoading,
    setChartRecords,
    fetchChartData,
    changeDateHandler,
  };
};

export default useCalculatedSalaryChart;
