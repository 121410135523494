import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { Col, Row } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import { CustomizableChart } from './CustomizableChart';
import { CustomizableReport } from './CustomizableReport';

export const Customizable = () => {
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Reports',
      href: '/reports',
    },
    {
      name: 'Customizable',
    },
  ];

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader headingClass="fs-h1" pageTitle="Customizable" />
        </Col>
      </Row>
      <CardComponent>
        <CustomizableChart />
        <CustomizableReport />
      </CardComponent>
    </>
  );
};
