import { Calendar } from '../../../../util/calendar';
import { deepClone, isEmpty } from '../../../../util/util';
import { INITIAL_EMPLOYEE_PENSION } from '../../../superAdmin/PensionPolicy/constants';
import {
  PensionPolicy,
  PensionPolicyCategory,
  PensionPolicyVariant,
  PreExistEmployeePolicy,
} from '../../../superAdmin/PensionPolicy/types';
import useEmployeePensionContext from '../useContext';
import usePolicyRevision from './usePolicyRevision';

const useAssignEmployeePension = () => {
  const {
    setEmployeePolicyRevision,
    updateEmployeeDetails,
    effectiveFrom,
    setEffectiveFrom,
    setAdvisoryInvite,
    selectedEmployeePension,
    employeePolicyRevision,
    selectedPolicyType,
  } = useEmployeePensionContext();
  const {
    addToAdminPolicyRevisionList,
    updateAdminPolicyRevisionSettings,
    findAdminPolicyRevisionList,
    addPersonalPolicyToRevisionList,
    findPersonalPolicyFromRevisionList,
    addDefaultAdminPolicyRevision,
    replaceRevisionSettings,
  } = usePolicyRevision();

  const getFormValues = () => {
    return {
      effectiveFrom,
    };
  };

  const getDefaultFormValuesByPolicy = (policyDetails) => {
    return {
      effectiveFrom: policyDetails.effectiveFrom,
    };
  };

  const hasDefaultEmployeePension = (_policyDetails) => {
    if (
      !isEmpty(_policyDetails) &&
      selectedEmployeePension &&
      selectedEmployeePension.currentPolicy?.id === _policyDetails._id
    ) {
      return selectedEmployeePension;
    }

    return null;
  };

  const selectPensionBySelectedPolicyFromRevision = (_policyDetails, chosenType: PensionPolicyCategory) => {
    if (!_policyDetails || chosenType === PensionPolicyCategory.NO_POLICY) {
      return null;
    }

    if (chosenType === PensionPolicyCategory.PERSONAL_POLICY) {
      const found = findPersonalPolicyFromRevisionList();

      if (found) {
        return found;
      }

      return initializeDefaultEmployeePension(_policyDetails);
    }

    let isDefaultFound = hasDefaultEmployeePension(_policyDetails);

    const found = findAdminPolicyRevisionList(_policyDetails._id);

    if (found) {
      if (isDefaultFound) {
        return {
          ...isDefaultFound,
          ...found,
        };
      }

      return found;
    }

    return initializeDefaultEmployeePension(_policyDetails);
  };

  const resetEmployeePolicyRevision = () => {
    setEmployeePolicyRevision(null);
    setEffectiveFrom('');
    updateEmployeeDetails('policySettings', null);
  };

  const setInitialEmployeePolicyRevision = (type: PensionPolicyVariant, _policyDetails: PensionPolicy) => {
    const pensionType: PreExistEmployeePolicy = {
      id: _policyDetails._id!,
      type,
      attachedAt: new Date().toISOString(),
    };

    const initialAssignedEmployeePension = deepClone(INITIAL_EMPLOYEE_PENSION);

    assignDefaultEffectiveDate(_policyDetails, initialAssignedEmployeePension);

    setEmployeePolicyRevision({
      ...getDefaultFormValuesByPolicy(_policyDetails),
      currentPolicy: pensionType,
      metaDetails: _policyDetails,
      policySettings: initialAssignedEmployeePension['policySettings'],
    });
    setEffectiveFrom(Calendar.selectFutureOrNextMonthDate(_policyDetails['effectiveFrom']));

    if (_policyDetails) {
      if (type === PensionPolicyVariant.PERSONAL) {
        addPersonalPolicyToRevisionList(_policyDetails, { ...getDefaultFormValuesByPolicy(_policyDetails) });
      } else {
        addToAdminPolicyRevisionList(pensionType, _policyDetails, { ...getDefaultFormValuesByPolicy(_policyDetails) });
      }
    }
  };

  const resetToInitialEmployeePension = (employeePension, selectedPolicyDetails: any = null) => {
    setEmployeePolicyRevision(employeePension);

    if (selectedPolicyDetails) {
      updateAdminPolicyRevisionSettings(selectedPolicyDetails._id!, employeePension);
    }
  };

  const resetPersonalPolicyRevisionSettings = (_empolyeeAttachedPersonalPolicy) => {
    const defaultPensionRevisionSettings = deepClone(INITIAL_EMPLOYEE_PENSION);
    const pensionType: PreExistEmployeePolicy = {
      id: _empolyeeAttachedPersonalPolicy._id!,
      type: _empolyeeAttachedPersonalPolicy.type,
      attachedAt: new Date().toISOString(),
    };

    assignDefaultEffectiveDate(_empolyeeAttachedPersonalPolicy, defaultPensionRevisionSettings);

    const pensionSettings = {
      ...getDefaultFormValuesByPolicy(_empolyeeAttachedPersonalPolicy),
      currentPolicy: pensionType,
      metaDetails: _empolyeeAttachedPersonalPolicy,
      policySettings: defaultPensionRevisionSettings['policySettings'],
    };

    replaceRevisionSettings(pensionSettings, 'personal');
    setEffectiveFrom(_empolyeeAttachedPersonalPolicy['effectiveFrom']);
    setEmployeePolicyRevision(pensionSettings);

    return pensionSettings;
  };

  const findAndApplyExistingRevisionSettings = (id: string) => {
    const foundRevision = findAdminPolicyRevisionList(id);

    if (foundRevision) {
      setEmployeePolicyRevision({
        ...getFormValues(),
        currentPolicy: foundRevision.currentPolicy,
        policySettings: foundRevision.policySettings,
        metaDetails: foundRevision.metaDetails,
      });
      setEffectiveFrom(foundRevision['effectiveFrom']);

      return foundRevision;
    }
  };

  const findExistingRevisionSettings = (id: string) => {
    const foundRevision = findAdminPolicyRevisionList(id);

    if (foundRevision) {
      return foundRevision;
    }
  };

  const replacePolicyRevision = (employeePension) => {
    setEmployeePolicyRevision({
      ...getFormValues(),
      currentPolicy: employeePension.currentPolicy,
      policySettings: employeePension.policySettings,
      metaDetails: employeePension.metaDetails,
    });
    setEffectiveFrom(employeePension['effectiveFrom']);
    setAdvisoryInvite(employeePension['advisoryInvite']);
  };

  const updatePersonalPolicyRevision = (employeePension) => {
    replacePolicyRevision(employeePension);

    updateAdminPolicyRevisionSettings(employeePension.currentPolicy.id, employeePension, 'personal');
  };

  const findExistingPersonalPolicy = () => {
    const foundRevision = findPersonalPolicyFromRevisionList();

    if (foundRevision) {
      return foundRevision;
    }
  };

  const syncPolicFormDataToEmployeePension = (id: string, policyFormData, type: 'personal' | 'admin') => {
    updateAdminPolicyRevisionSettings(id, { ...getFormValues(), ...policyFormData }, type);
  };

  const initializeDefaultEmployeePension = (_policyDetails) => {
    const initialPolicy = deepClone(INITIAL_EMPLOYEE_PENSION);

    assignDefaultEffectiveDate(_policyDetails, initialPolicy);

    return initialPolicy;
  };

  const assignDefaultEffectiveDate = (_policyDetails, initialAssignedPolicy) => {
    const defaultEffectiveDate = Calendar.selectFutureOrNextMonthDate(_policyDetails.effectiveFrom);

    initialAssignedPolicy.effectiveFrom = defaultEffectiveDate;
    initialAssignedPolicy.policySettings.salaryChange.fixedMonthlyOffset.effectiveFrom = defaultEffectiveDate;
    initialAssignedPolicy.policySettings.salaryChange.percentageOfSalaryOffset.effectiveFrom = defaultEffectiveDate;
    initialAssignedPolicy.policySettings.salaryChange.manualOffset.effectiveFrom = defaultEffectiveDate;
    initialAssignedPolicy.policySettings.employeeMetaSettings.pensionManager.effectiveFrom = defaultEffectiveDate;
    initialAssignedPolicy.policySettings.employeeMetaSettings.insurance.effectiveFrom = defaultEffectiveDate;
  };

  const setDetailsWhenNoPolicyRevision = () => {
    setAdvisoryInvite(employeePolicyRevision.advisoryInvite);
    setEffectiveFrom(employeePolicyRevision.effectiveFrom);
  };

  const hasDisabledPolicy = () => {
    if (selectedPolicyType) {
      if (selectedPolicyType === PensionPolicyCategory.NO_POLICY) {
        return false;
      }

      if (employeePolicyRevision) {
        return false;
      }
    }
    return true;
  };

  const addAndUpdateRevision = (employeePension, policyDetails) => {
    const id = employeePension.currentPolicy.id;
    const category = employeePension.currentPolicy.type === PensionPolicyVariant.PERSONAL ? 'personal' : 'admin';

    const found = findAdminPolicyRevisionList(id, category);

    if (found) {
      setEffectiveFrom(employeePension['effectiveFrom']);
      setAdvisoryInvite(employeePension['advisoryInvite']);

      updateAdminPolicyRevisionSettings(id, employeePension, category, policyDetails);

      setEmployeePolicyRevision({
        ...getFormValues(),
        ...employeePension,
        currentPolicy: employeePension.currentPolicy,
        policySettings: employeePension.policySettings,
        metaDetails: policyDetails,
      });

      return;
    }

    setEffectiveFrom(employeePension['effectiveFrom']);
    setAdvisoryInvite(employeePension['advisoryInvite']);

    addDefaultAdminPolicyRevision(employeePension, policyDetails);

    setEmployeePolicyRevision({
      ...getFormValues(),
      ...employeePension,
      currentPolicy: employeePension.currentPolicy,
      policySettings: employeePension.policySettings,
      metaDetails: policyDetails,
    });
  };

  return {
    resetEmployeePolicyRevision,
    setInitialEmployeePolicyRevision,
    resetToInitialEmployeePension,
    findAndApplyExistingRevisionSettings,
    replacePolicyRevision,
    updatePersonalPolicyRevision,
    findExistingRevisionSettings,
    findExistingPersonalPolicy,
    syncPolicFormDataToEmployeePension,
    selectPensionBySelectedPolicyFromRevision,
    hasDefaultEmployeePension,
    addDefaultAdminPolicyRevision,
    setDetailsWhenNoPolicyRevision,
    hasDisabledPolicy,
    resetPersonalPolicyRevisionSettings,
    updateAdminPolicyRevisionSettings,
    addAndUpdateRevision,
  };
};

export default useAssignEmployeePension;
