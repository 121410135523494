import classNames from 'classnames';

import styles from './MonthlyStepper.module.scss';

import { MonthlyStepperFooterFooterProps } from './types';
import { numberFormat } from './utils';

const MonthlyStepperFooter = ({
  selectedSummaryTotals,
  shouldRender,
}: /* selectedHeaderMonths, */
MonthlyStepperFooterFooterProps) => {
  return (
    <table>
      <tr>
        {selectedSummaryTotals.map((value) => {
          return (
            <td className={classNames('text-right')} key={value.monthIndex}>
              <span className={classNames(styles.fade_in, shouldRender ? styles.active : '')}>
                {numberFormat(value.value)}
              </span>
            </td>
          );
        })}
      </tr>
    </table>
  );
};

export default MonthlyStepperFooter;
