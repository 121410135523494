export const INITIAL_SIGNATORY_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
  incharge: false,
};

export const INITIAL_ADMIN_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
};

export const INITIAL_CONTACT_PEOPLE = {
  system: '',
  personalNumber: '',
  name: '',
  email: '',
};
export const INITIAL_ADDRESS_DETAILS = {
  city: '',
  country: '',
  postcode: '',
  street: '',
};

export const INITIAL_COMPANY_DETAILS = {
  organizationNumber: '',
  name: '',
  status: 'Active',
};

export const INITIAL_CLIENT_CONSULTANT = {
  fullName: '',
  personalNumber: '',
  email: '',
};

export const INITIAL_CONSULTANT_DETAILS = {
  // name: '',
  personalNumber: '',
  fullName: '',
};

export const INITIAL_INIVITATION_EMAIL_SETTING = '3'

export const FORM_INITIAL_VALUES = {
  companyDetails: INITIAL_COMPANY_DETAILS,
  address: INITIAL_ADDRESS_DETAILS,
  contractEffectiveFrom: '',
  invoiceEmailAddress: '',
  invoiceContractCenter: '',
  authorizedSignatory: [{ _id: `${Math.random()}`, ...INITIAL_SIGNATORY_DETAILS, incharge: true },
  { _id: `${Math.random()}`, ...INITIAL_SIGNATORY_DETAILS }],
  admins: [],
  consultants: [],
  consultant: {},
  salaryContactPeople: { ...INITIAL_CONTACT_PEOPLE },
  accountingContactPeople: { ...INITIAL_CONTACT_PEOPLE },
  invitationEmail: INITIAL_INIVITATION_EMAIL_SETTING,
  vismaAPIKey: '',
};
export const ConsultantPositions = [
  {
    label: 'Authorized Signatory',
    value: 'AuthorizedSignatory',
  },
  {
    label: 'Supervisor',
    value: 'Supervisor',
  },
  {
    label: 'Senior Consultant',
    value: 'SeniorConsultant',
  },
  {
    label: 'Consultant',
    value: 'Consultant',
  },
];
