import { CostSpecification, TypePlans } from '../types';

export const INITIAL_PENSION_COMPANY = {
  shortName: '',
  companyName: '',
  clientNumber: '',
  contractNumber: '',
  description: '',
  organizationNumber: '',
  vatNumber: '',
  bankgiroNumber: '',
  plusgiroNumber: '',
  status: 'Active',
  collectiveAgreement: false,
  // pensionType: [],
  // integrationType: [],
  // apiKey: '',
  // id: '',
};
export const INITIAL_CONTACT_INFORMATION = {
  address: '',
  city: '',
  phone: '',
  fax: '',
  postalCode: '',
  email: '',
};

export const INITIAL_PLANS = {
  planName: '',
  providerDescription: '',
  order: 0,
  documents: [],
};

export const INITIAL_PENSION_MANAGER_PLANS = {
  providerDescription: '',
  correctoPartner: false,
  plans: [],
};

export const INITIAL_CONNECTION = {
  connectionTypeAPI: {
    isChecked: true,
    apiKey: '',
    serviceKey: '',
  },
  connectionTypeFile: {
    isChecked: false,
    email: '',
    type: 'CSV',
  },
};
export const FORM_INITIAL_VALUES = {
  contactInformation: INITIAL_CONTACT_INFORMATION,
  pensionCompany: INITIAL_PENSION_COMPANY,
  connection: INITIAL_CONNECTION,
  pensionManagerPlans: INITIAL_PENSION_MANAGER_PLANS,
};

export const DEFAULT_PLANS: Pick<TypePlans, 'planName'>[] = [
  {
    planName: 'Traditional',
  },
  {
    planName: 'Fund',
  },
  {
    planName: 'Depot',
  },
];

export const DEFAULT_COST_SPEC: CostSpecification = {
  specItems: [],
  description: '',
};
