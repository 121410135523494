import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { LocaleCode } from './types/locale';

//const apiKey = process.env['REACT_APP_I18NEXUS_API_KEY'];
//const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: LocaleCode.EN,
    ns: ['default'],
    defaultNS: 'default',
    supportedLngs: [LocaleCode.EN, LocaleCode.SE],
    maxRetries: 0,
    backend: {
      loadPath: (_languages, _namespaces) => {
        // Provide the dynamic path to your JSON files based on the selected language
        return `/i18n/{{lng}}.json`;
      },
    },
  });
