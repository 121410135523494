import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';

export interface LooseObject {
  [key: string]: any;
}

export const INITIAL_PERSONAL_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
  alternateEmail: '',
  status: '',
  address: {
    street: '',
    postcode: '',
    city: '',
    country: '',
  },
  phoneNumber: {
    personal: '',
    private: '',
  },
};

export type PersonalDetails = {
  personalNumber: string;
  fullName: string;
  email: string;
  alternateEmail: string;
  status: string;
  address: {
    city: string;
    country: string;
    postcode: string;
    street: string;
  };
  phoneNumber: {
    personal: string;
    private: string;
  };
};

export type PersonalDetailsFormGroup = FormikProps<PersonalDetails>;

export interface IPersonalClientContext {
  personalDetails: PersonalDetails;
  setPersonalDetails: Dispatch<SetStateAction<PersonalDetails>>;
  isEditMode: boolean;
  formGroup: PersonalDetailsFormGroup;
  // updatePersonalDetailsInput: (...args: unknown[]) => void;
  updatePersonalDetailsInput: (inputName: string, inputValue: any) => void;
  replacePersonalDetailsInput: (value: any) => void;
  patchPersonalDetailsForm: (patchValue: any) => void;
  getFilteredRequestParams: (value: any) => Partial<PersonalDetails>;
}

export type FormProps = {
  formGroup: PersonalDetailsFormGroup;
  context: IPersonalClientContext;
};

export interface IPersonalClientLegalDoc {
  title: string;
  link?: string;
}
