import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PagesHeader from '../../../components/base/PagesHeader';
import { data } from './data';
import { FinanceSalaryDetails } from './FinanceSalaryDetails';
import { PensionableSalaryDetails } from './PensionableSalaryDetails';

import { Finance } from './types';
import CardComponent from '../../../components/base/CardComponent';

export const FinanceSpecification = () => {
  const financialData: Finance[] = data;

  const { t } = useTranslation();
  const [activeIndex, setactiveIndex] = useState(0);
  const [showTotal, setshowTotal] = useState(2);
  const slicedData = financialData.slice(activeIndex, showTotal);
  const totalDataLength = financialData.length;

  const handleNext = () => {
    setactiveIndex(showTotal);
    setshowTotal(showTotal + 2);
  };

  const handlePrev = () => {
    setactiveIndex(activeIndex - 2);
    setshowTotal((total) => total - 2);
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <PagesHeader headingClass="fs-26px mb-0 font-black" pageTitle="Finance" />
        <div className="d-flex justify-content-between p-0 mb-2">
          <div>
            <Button
              style={{ borderRadius: 6 }}
              className="app-btn-primary me-2 fs-label unique-disable"
              onClick={handlePrev}
              disabled={activeIndex === 0}
            >
              <ChevronLeft className="icon-gap-right" />
              {t('prior_month')}
            </Button>
            <Button
              style={{ borderRadius: 6 }}
              className="app-btn-primary fs-label unique-disable"
              onClick={handleNext}
              disabled={showTotal === totalDataLength}
            >
              {t('next_month')}
              <ChevronRight className="icon-gap-left" />
            </Button>
          </div>
        </div>
      </div>
      {!!slicedData.length && (
        <CardComponent>
          <FinanceSalaryDetails data={slicedData} />
          <PensionableSalaryDetails data={slicedData} />
        </CardComponent>
      )}
    </>
  );
};
