import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { Col, Row } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import { HolidayPayLiabilityChart } from './HolidayPayLiabilityChart';
import { HolidayPayLiabilityReport } from './HolidayPayLiabilityReport';

export const HolidayPayLiability = () => {
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Reports',
      href: '/reports',
    },
    {
      name: 'Holiday pay liability',
    },
  ];

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader headingClass="fs-h1" pageTitle="Holiday pay liability" />
        </Col>
      </Row>
      <CardComponent>
        <HolidayPayLiabilityChart />
        <HolidayPayLiabilityReport />
      </CardComponent>
    </>
  );
};
