import BSTable from '../../../../components/table/BSTable';
import { Button, Dropdown, Row, Spinner, Form } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { Menu } from 'react-feather';
import useAllEmployeeYearlySalaryReport from '../hooks/useAllEmployeeYearlySalaryReport';
import { getCompanyRealSalaryReports } from '../../../../api/service/company/reports/service';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Calendar } from '../../../../util/calendar';
import { REPORT_EXPORT_OPTIONS } from '../constants';
import { useTranslation } from 'react-i18next';

const { SearchBar } = Search;

// const { ExportCSVButton } = CSVExport;

export const RealSalaryReport = () => {
  const { reportHeaders, reportRecords, isLoading, exportCSV, years, setYearFilter, isExporting } =
    useAllEmployeeYearlySalaryReport(getCompanyRealSalaryReports, {
      fieldKey: 'salary',
      export: { requestName: 'real-salaries' },
    });

  const { t } = useTranslation();

  return (
    <>
      <Row className="justify-content-between align-items-end">
        <div className="mt-5">
          <PagesHeader pageTitle="All Employees" headingClass="fs-page-title" />
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="crt-table-report">
              <ToolkitProvider search keyField="id" data={reportRecords} columns={reportHeaders} csv>
                {(props: any) => (
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <Form.Group className="me-3">
                        <Form.Label className="fs-name">{t('year_period')}</Form.Label>
                        <Form.Select
                          name="year"
                          className="text-left"
                          onChange={(e) => {
                            setYearFilter(e.target.value);
                          }}
                        >
                          {years.map((year) => (
                            <option value={year} key={year}>
                              {year} {Calendar.isCurrentYear(year) ? 'YTD' : ''}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                      {isExporting ? (
                        <Spinner />
                      ) : (
                        <Dropdown className="nav-item" align="end">
                          <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
                            <Button className="app-btn-secondary">
                              <Menu className="theme-text" />
                            </Button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {REPORT_EXPORT_OPTIONS.map((report) => (
                              <Dropdown.Item onClick={() => exportCSV(report.value)} key={report.value}>
                                {report.label}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                    <SearchBar className="d-none" {...props.searchProps} />
                    <div className="crt-table-report">
                      <BSTable baseProps={props.baseProps} {...props.baseProps} />
                    </div>{' '}
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </>
        )}
      </Row>
    </>
  );
};
