import { Form } from 'react-bootstrap';
import { Plus, Trash2 } from 'react-feather';
import classNames from 'classnames';

import { TextEditor } from '../../../../../components/form/inputs/TextEditor';
import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/settings/pension-manager.module.scss';
import useCostSpecification from '../hooks/useCostSpecification';
import { Mode } from '../../types';
import TooltipBox from '../../../../../components/base/Overlay/TooltipBox';
import { useTranslation } from 'react-i18next';

interface Props {
  planIndex: number;
  mode: Mode;
}

const CostSpecification = ({ planIndex, mode }: Props) => {
  const { costSpecItems, addSpecItem, deleteSpecItem, updateSpecItem, getCostSpecification } =
    useCostSpecification(planIndex);
  const costSpecControl = getCostSpecification(planIndex);
  const { t } = useTranslation();
  const isViewMode = mode === Mode.Detail;

  const onAdd = () => {
    addSpecItem(planIndex);
  };

  const onRemove = (rowIndex) => {
    deleteSpecItem(planIndex, rowIndex);
  };

  const handleChange = (name, value, rowIndex: number | null = null) => {
    updateSpecItem({
      name,
      value,
      planIndex,
      specIndex: rowIndex,
    });
  };

  if (isViewMode && !costSpecControl?.description && !costSpecControl?.specItems.length) {
    return <></>;
  }

  return (
    <>
      {!isViewMode && <h4 className="fs-h4 theme-text-dark text-bold">Cost Specification</h4>}
      <div className="form-section mb-3 dark-content-section">
        <table className={classNames('table pension-manager-table', themeStyle.cost_spec_grid)}>
          <thead>
            <tr>
              <th>Specification</th>
              <th>Fee</th>
              <th>Exemption letter</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isViewMode && costSpecItems.length === 0 && (
              <tr>
                <td colSpan={4}>No specification Found</td>
              </tr>
            )}
            {costSpecItems.map((item, rowIndex) => {
              if (isViewMode) {
                return (
                  <tr key={`spec.${rowIndex}`} className="highlight-inactive highlight-inactive--hover">
                    <td className={classNames(themeStyle.spec_name, 'theme-text-dark')}>{item.title}</td>
                    <td className={classNames(themeStyle.spec_fee)}>
                      <div>{item.fee}</div>
                    </td>
                    <td colSpan={2} className={classNames(themeStyle.spec_letter)}>
                      <div>{item.exemptionLetter}</div>
                    </td>
                  </tr>
                );
              }

              return (
                <tr key={item.key} className="highlight-inactive highlight-inactive--hover">
                  <td className={classNames(themeStyle.spec_name)}>
                    <Form.Control
                      value={item.title}
                      disabled={isViewMode}
                      onChange={(ev) => {
                        handleChange('title', ev.target.value, rowIndex);
                      }}
                    />
                  </td>
                  <td className={classNames(themeStyle.spec_fee)}>
                    {/*                     <PercentageNumber
                      value={isEmptyVal(item.fee) ? '' : item.fee!}
                      disabled={isViewMode}
                      onChangeHandler={(val) => handleChange('fee', val, rowIndex)}
                      decimalPlace={2}
                    /> */}

                    <input
                      type="text"
                      className="form-control"
                      value={item.fee || ''}
                      onChange={(ev) => handleChange('fee', ev.target.value, rowIndex)}
                    />
                  </td>
                  <td className={classNames(themeStyle.spec_letter)}>
                    {/* <PriceInput
                      defaultValue={item.exemptionLetter}
                      disabled={isViewMode}
                      onChange={(value) => handleChange('exemptionLetter', value, rowIndex)}
                    /> */}
                    <input
                      type="text"
                      className="form-control"
                      value={item.exemptionLetter || ''}
                      onChange={(ev) => handleChange('exemptionLetter', ev.target.value, rowIndex)}
                    />
                  </td>
                  <td align="right" className="align-baseline">
                    {!isViewMode && (
                      <span style={{ cursor: 'pointer' }} onClick={() => onRemove(rowIndex)}>
                        <TooltipBox msg={`${t('delete')}`}>
                          <Trash2 color="#930000" />
                        </TooltipBox>
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {!isViewMode && (
              <tr>
                <td colSpan={4}>
                  <button type="button" className="btn app-btn-secondary" onClick={() => onAdd()}>
                    <Plus className="icon-gap-right" />
                    Add specification
                  </button>
                </td>
              </tr>
            )}
            {!isViewMode && (
              <tr>
                <td className={classNames(themeStyle.desc)} colSpan={4}>
                  <TextEditor
                    onChangeHandler={(value) => {
                      handleChange('description', value);
                    }}
                    defaultValue={costSpecControl?.description || ''}
                  />
                </td>
              </tr>
            )}
            {isViewMode && costSpecControl?.description && (
              <tr>
                <td className={classNames(themeStyle.desc)} colSpan={4}>
                  <div className="pt-2" dangerouslySetInnerHTML={{ __html: costSpecControl?.description || '' }} />
                </td>
              </tr>
            )}
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default CostSpecification;
