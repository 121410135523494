import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Check, Edit3, X } from 'react-feather';
import { numberFormat, toNumber } from '../../../../util/util';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';
import { useTranslation } from 'react-i18next';

type Props = {
  value?: string | number;
  name?: string;
  handleSaveChanges?: (args) => void;
};

export const InputEditor = ({ value, name, handleSaveChanges }: Props) => {
  const [enabledEdit, setEnableEdit] = useState(false);
  const [updatedValue, setUpdateValue] = useState<any>();
  const [initialValue, setInitialValue] = useState(value);
  const { t } = useTranslation();
  const onSaveChanges = () => {
    setUpdateValue(numberFormat(updatedValue));
    setInitialValue(updatedValue);
    handleSaveChanges?.(updatedValue);
    setEnableEdit(false);
  };

  const onDiscardChanges = () => {
    setUpdateValue(numberFormat(initialValue));
    setEnableEdit(false);
  };

  const handleUpdate = (e) => {
    setUpdateValue(e.target.value);
  };

  const setInputValue = () => {
    setEnableEdit(!enabledEdit);
    setUpdateValue(toNumber(updatedValue));
    setInitialValue(toNumber(updatedValue));
  };

  useEffect(() => {
    const formattedValue: any = numberFormat(value);
    setUpdateValue(formattedValue);
  }, []);

  return (
    <>
      <div className="mx-3 d-flex">
        <Form.Control
          size="lg"
          type="text"
          name={name}
          value={updatedValue}
          disabled={!enabledEdit}
          style={{ maxWidth: 190 }}
          onChange={handleUpdate}
        />
        {!enabledEdit && (
          <TooltipBox msg={`${t('edit')}`}>
            <Edit3 onClick={setInputValue} className="cursor-pointer icon-gap-right my-1 mx-2" style={{ width: 15 }} />
          </TooltipBox>
        )}
        {enabledEdit && (
          <div className="d-flex mx-1">
            <Button
              className="app-btn-secondary"
              onClick={onSaveChanges}
              style={{ padding: '1px 6px', margin: '9px 1px' }}
            >
              <Check className="" style={{ width: 13 }} />
            </Button>
            <Button
              className="app-btn-secondary"
              onClick={onDiscardChanges}
              style={{ padding: '1px 6px', margin: '9px 1px' }}
            >
              <X className="" style={{ width: 13 }} />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
