import { Dispatch, SetStateAction } from 'react';
import { ContactPeople } from '../../../api/service/accountingFirm/clients/types';
import { FormikProps } from 'formik';
import { AuthorizedSignatoryGroup } from '../../superAdmin/CompanyClients/types';

export interface LooseObject {
  [key: string]: any;
}

export type DateType = {
  createdAt: string;
  updatedAt: string;
};

export type CollectiveAgreementType = {
  id: string;
  name: string;
};

export type CompanyDetails = {
  organizationNumber: string;
  name: string;
  status: string;
};

export type AddressDetails = {
  city: string;
  country: string;
  postcode: string;
  street: string;
};

export type AuthorizedSignatory = {
  _id?: string;
  id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
  incharge: boolean;
};

export type AdminDetails = {
  _id?: string;
  id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
};

export type AccountingFirm = {
  id?: string;
  name?: string;
};

export type ClientForm = {
  companyDetails: CompanyDetails;
  address: AddressDetails;
  contractEffectiveFrom: string;
  invoiceEmailAddress: string;
  invoiceContractCenter: string;
  contractNumber: string;
  authorizedSignatory: AuthorizedSignatory[];
  combinations: AuthorizedSignatoryGroup[];
  admins: AdminDetails[];
  consultants: ClientConsultant[];
  consultant: ClientConsultant;
  salaryContactPeople: ContactPeople;
  accountingContactPeople: ContactPeople;
  invitationEmail: string;
  vismaAPIKey: string;
  collectiveAgreements: CollectiveAgreementType[];
  allowCorrectoAccess: boolean,
  allowAdvisorAccess: boolean
};

export type ClientFormGroup = FormikProps<ClientForm>;

export type ClientConsultant = {
  _id?: string;
  id?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  short?: string;
  position?: string;
  personalNumber?: string;
  email?: string;
  accountingFirm?: AccountingFirm;
  role?: string;
};

export interface ICompanyClientContext {
  companyDetails: CompanyDetails;
  setCompanyDetails: Dispatch<SetStateAction<CompanyDetails>>;

  addressDetails: AddressDetails;
  setAddressDetails: Dispatch<SetStateAction<AddressDetails>>;

  clientConsultants: Array<ClientConsultant>;
  setClientConsultants: Dispatch<SetStateAction<Array<ClientConsultant>>>;

  newClientConsultants: Array<ClientConsultant>;
  setNewClientConsultants: Dispatch<SetStateAction<Array<ClientConsultant>>>;

  adminDetails: Array<AdminDetails>;
  setAdminDetails: Dispatch<SetStateAction<Array<AdminDetails>>>;

  contractNumber: string;
  setContractNumber: Dispatch<SetStateAction<string>>;

  signatoryDetails: Array<AuthorizedSignatory>;
  setSignatoryDetails: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  salaryContactPeople: ContactPeople;
  setSalaryContactPeople: Dispatch<SetStateAction<ContactPeople>>;

  accountingContactPeople: ContactPeople;
  setAccountingContactPeople: Dispatch<SetStateAction<ContactPeople>>;

  contractEffectiveFrom: string;
  setContractEffectiveFrom: Dispatch<SetStateAction<string>>;

  invoiceCostCenter: string;
  setInvoiceCostCenter: Dispatch<SetStateAction<string>>;

  invoiceEmailAddress: string;
  setInvoiceEmailAddress: Dispatch<SetStateAction<string>>;

  collectiveAgreements: Array<CollectiveAgreementType>;
  setCollectiveAgreements: Dispatch<SetStateAction<Array<CollectiveAgreementType>>>;

  invitationEmail: string;
  setInvitationEmail: Dispatch<SetStateAction<string>>;

  vismaAPIKey: string;
  setVismaAPIKey: Dispatch<SetStateAction<string>>;

  signatoryAdmins: Array<AuthorizedSignatory>;
  setSignatoryAdmins: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  deletedAdmins: Array<string>;
  setDeletedAdmins: Dispatch<SetStateAction<Array<string>>>;

  deletedConsultants: Array<string>;
  setDeletedConsultants: Dispatch<SetStateAction<Array<string>>>;

  combinations: Array<AuthorizedSignatoryGroup>;
  setCombinations: Dispatch<SetStateAction<Array<AuthorizedSignatoryGroup>>>;

  isEditMode: boolean;

  formGroup: ClientFormGroup;

  replaceAdminDetails: (value: any) => void;
  replaceAddress: (value: any) => void;
  replaceAuthorizedSignatory: (value: any) => void;
  replaceCompanyDetails: (value: any) => void;
  replaceConsultants: (value: any) => void;
  replaceConsultant: (value: any) => void;
  replaceAccountingContactPeople: (value: any) => void;
  replaceSalaryContactPeople: (value: any) => void;
  updateAdminDetails: (inputName: any, inputValue: any) => void;
  updateAddress: (inputName: any, inputValue: any) => void;
  updateAuthorizedSignatory: (inputName: any, inputValue: any) => void;
  updateCompanyDetails: (inputName: any, inputValue: any) => void;
  updateConsultants: (inputName: any, inputValue: any) => void;
  updateAccountingContactPeople: (inputName: any, inputValue: any) => void;
  updateSalaryContactPeople: (inputName: any, inputValue: any) => void;
  updateContractEffectiveInput: (inputValue: any) => void;
  updateInvoicingCostCenter: (inputValue: any) => void;
  updateInvoiceEmailAddress: (inputValue: any) => void;
  updateInvitationEmail: (inputValue: any) => void;
  replaceCollectiveAgreements: (inputValue: any) => void;
  updateVismaAPIKey: (inputValue: any) => void;
  updateContractNumber: (inputValue: any) => void;
  appendDeletedAdmin: (value: any) => void;
  appendDeletedConsultant: (value: any) => void;
  replaceCombinations: (value: any) => void;
  updateCombinations: (inputName: any, inputValue: any) => void;
  replaceSignatoryAdmins: (value: any) => void;
  replaceAccessLevel: (allowCorrectoAccess, allowAdvisorAccess) => void

}

export type FormProps = {
  context: ICompanyClientContext;
  date?: DateType;
  formGroup: ClientFormGroup;
};

export enum ClientSaveMode {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SETUP = 'Setup',
}
