import * as Yup from 'yup';
import { PensionPolicyMode } from '../types';
import { isEmptyVal, isNumeric, toNumber } from '../../../../util/util';

const errorMsg = {
  invalidOccupation: 'At least one of percentage compensation or fixed compensation must be provided',
  invalidOccupationInterval: 'Please enter at least one valid criteria for the calculation',
  invalidSalaryChange:
    'Please check at least one option: Fixed monthly offset, Percentage of salary offset, or Manual offset',
  invalidSalaryType: 'At least one salary type checked, with percentage value',
  invalidSalaryTypeHoliday: 'At least one setup needs to have a holiday pay multiple value',
  requiredWithoutSeparateManager: 'At least one plan included',
  requiredWithSeparateManager: 'At least one plan per occupational and salary change pension',
};

const occupationalCalculationValidator = () => {
  const validAgeCriteria = (minAge, maxAge) => {
    const nonEmptyAge = isNumeric(minAge) && minAge > 0 && isNumeric(maxAge) && maxAge > 0;

    return nonEmptyAge;
  };

  const validAmountCriteria = (minAmount, maxAmount) => {
    const nonEmptyAge = isNumeric(minAmount) && minAmount > 0 && isNumeric(maxAmount) && maxAmount > 0;

    return nonEmptyAge;
  };

  const validCalculationCriteria = (item) => {
    const nonEmptyAge = validAgeCriteria(item.age.min, item.age.max);
    const nonEmptyIndexAmount = validAmountCriteria(item.indexAmount.min, item.indexAmount.max);
    const nonEmptyInputVal = isNumeric(item.value) && item.value > 0;

    return nonEmptyAge && nonEmptyIndexAmount && nonEmptyInputVal;
  };

  const isEmptyCalculationCriteria = (item) => {
    const emptyAge = isEmptyVal(item.age.min, { allowNum: true }) && isEmptyVal(item.age.max, { allowNum: true });
    const emptyIndexAmount =
      isEmptyVal(item.indexAmount.min, { allowNum: true }) && isEmptyVal(item.indexAmount.max, { allowNum: true });
    const emptyInputVal = isEmptyVal(item.value, { allowNum: true });

    return emptyAge && emptyIndexAmount && emptyInputVal;
  };

  const parseIndex = (str: string) => {
    return parseInt(str.match(/\d+/)![0]);
  };

  const validateBoundaryValues = (size, arr) => {
    const result: any = [];

    if (arr.length !== size) {
      return false;
    }

    for (let i = 0; i <= arr.length - 1; i++) {
      if (i <= 1 && arr[i] === null) {
        return false;
      }

      if (i > 1) {
        if (i - 1 > 1) {
          if (arr[i] === null && arr[i - 1] === null) {
            result.push(null);

            continue;
          }

          if (arr[i - 1] === null && arr[i]) {
            return false;
          }
        }

        if (arr[i] === null) {
          result.push(null);

          continue;
        }
      }

      result.push(arr[i]);
    }

    return true;
  };

  return {
    validAgeCriteria,
    validAmountCriteria,
    validCalculationCriteria,
    isEmptyCalculationCriteria,
    parseIndex,
    validateBoundaryValues,
  };
};

export const formSchema: Yup.ObjectSchema<any> = Yup.object().shape({
  policyName: Yup.string().required('Required'),
  policyDescription: Yup.string(),
  version: Yup.string().required('Required'),
  type: Yup.string().optional(),
  effectiveFrom: Yup.string().required('Required'),
  employeeType: Yup.string().required('Required').oneOf(['BlueCollar', 'WhiteCollar'], 'Invalid type'),
  isCollectiveAgreementLock: Yup.boolean(),
  status: Yup.string(),
  isAutoAssign: Yup.boolean(),
  policySettings: Yup.object().shape({
    occupationalPension: Yup.object()
      .shape({
        isIntervalCompensation: Yup.boolean(),
        percentageCompensation: Yup.number().nullable(),
        fixedCompensation: Yup.number().nullable(),
        isPremium: Yup.bool(),
        amountIndex: Yup.string(),
        offsetCalculation: Yup.string(),
        ageDefination: Yup.string(),
        calculation: Yup.array().of(Yup.mixed()),
      })
      .test('requiredWhen', function (_, { createError, options }) {
        const _occupationalPension = options.context?.policySettings?.occupationalPension;
        const isIntervalCompensation = _occupationalPension?.isIntervalCompensation;

        const _fixedCompensation = _occupationalPension?.fixedCompensation;
        const _percentCompensation = _occupationalPension?.percentageCompensation;
        const _intervalCalculation = _occupationalPension?.calculation || [];
        const parsedFixedCompensation = toNumber(_fixedCompensation) || 0;
        const parsedPercentCompensation = toNumber(_percentCompensation) || 0;

        if (!isIntervalCompensation) {
          if (parsedFixedCompensation > 0 || parsedPercentCompensation > 0) {
            return true;
          }

          return createError({ message: errorMsg.invalidOccupation });
        } else {
          const { validateBoundaryValues, parseIndex } = occupationalCalculationValidator();

          const getMetricsPoint = () => {
            const ageGroups: number[] = [];
            const amountIndexGroups: number[] = [];
            const metrixBoundarySet = new Array(4).fill(0);
            let initialIncomeValue: number | null = null;

            metrixBoundarySet.forEach((_, index) => {
              if (index === 0) {
                const initialAgeBoundary: any = _intervalCalculation.find((item: any) => {
                  const ageBoundaryPoint = parseIndex(item.x);
                  const incomeBoundaryPoint = parseIndex(item.y);

                  return ageBoundaryPoint === 1 && incomeBoundaryPoint === 1;
                });

                ageGroups.push(toNumber(initialAgeBoundary?.age?.min));
                amountIndexGroups.push(toNumber(initialAgeBoundary?.indexAmount?.min));

                initialIncomeValue = toNumber(initialAgeBoundary?.value);
              }

              const foundBoundary: any = _intervalCalculation.find((item: any) => {
                const ageBoundaryPoint = parseIndex(item.x);
                const incomeBoundaryPoint = parseIndex(item.y);

                return ageBoundaryPoint === index + 1 && incomeBoundaryPoint === index + 1;
              });

              if (foundBoundary) {
                ageGroups.push(toNumber(foundBoundary!.age.max));
                amountIndexGroups.push(toNumber(foundBoundary!.indexAmount.max));

                return;
              }

              ageGroups.push(0);
              amountIndexGroups.push(0);
            });

            return {
              age: ageGroups,
              amountIndex: amountIndexGroups,
              initialIncomeValue,
            };
          };

          const metricsCollection = getMetricsPoint();

          if (
            validateBoundaryValues(5, metricsCollection.age) &&
            validateBoundaryValues(5, metricsCollection.amountIndex) &&
            metricsCollection.initialIncomeValue
          ) {
            return true;
          }

          return createError({ message: errorMsg.invalidOccupationInterval });
        }
      }),
    salaryChange: Yup.object()
      .shape({
        isAllowed: Yup.boolean(),
        fixedMonthlyOffset: Yup.object().shape({
          offset: Yup.number(),
          effectiveFrom: Yup.string(),
          isEditAllowed: Yup.boolean(),
          isChecked: Yup.boolean(),
        }),
        percentageOfSalaryOffset: Yup.object().shape({
          offset: Yup.number(),
          effectiveFrom: Yup.string(),
          isEditAllowed: Yup.boolean(),
          isChecked: Yup.boolean(),
        }),
        manualOffset: Yup.object().shape({
          isManualOffsetAllowed: Yup.boolean(),
          isChecked: Yup.boolean(),
          offset: Yup.number(),
          effectiveFrom: Yup.string(),
        }),
        compensationWithIncreasedOffset: Yup.object().shape({
          offsetAddition: Yup.number(),
          isChecked: Yup.boolean(),
        }),
        isCheckedSepratePensionManagerPlans: Yup.boolean(),
      })
      .test('requiredWhen', function (_, { createError, options }) {
        const _salaryChange = options.context?.policySettings?.salaryChange;
        const isAllowedSalaryChange = _salaryChange?.isAllowed;

        const _fixedMonthlyOffset = _salaryChange?.fixedMonthlyOffset?.isChecked;
        const _percentageOfSalaryOffset = _salaryChange?.percentageOfSalaryOffset?.isChecked;
        const _manualOffset = _salaryChange?.manualOffset?.isChecked;

        if (isAllowedSalaryChange) {
          if (_fixedMonthlyOffset || _percentageOfSalaryOffset || _manualOffset) {
            return true;
          }

          return createError({ message: errorMsg.invalidSalaryChange });
        }

        return true;
      }),
    salaryType: Yup.object()
      .shape({
        isTemplateCalculation: Yup.boolean(),
        salaryTypes: Yup.array().of(
          Yup.object().shape({
            isInclude: Yup.boolean(),
            name: Yup.string().nullable(),
            code: Yup.number().nullable(),
            percentage: Yup.number().nullable(),
            mapsTo: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().nullable(),
                code: Yup.number().nullable(),
                platformName: Yup.string().nullable(),
              }),
            ),
          }),
        ),
        salaryTypesWithHolidays: Yup.array().of(
          Yup.object().shape({
            isEnable: Yup.boolean(),
            setupName: Yup.string().nullable(),
            holidayDaysPerYear: Yup.number().nullable(),
            data: Yup.array().of(
              Yup.object().shape({
                salaryType: Yup.object().nullable().shape({
                  name: Yup.string().nullable(),
                }),
                holidayPayMultiple: Yup.number().nullable(),
              }),
            ),
          }),
        ),
      })
      .test('requiredWhen', function (_, { createError, options }) {
        const _salaryType = options.context?.policySettings?.salaryType;
        const _isTemplateCalculation = _salaryType?.isTemplateCalculation;

        const _salaryTypes = _salaryType?.salaryTypes || [];
        const _salaryTypesWithHolidays = _salaryType?.salaryTypesWithHolidays || [];

        if (_isTemplateCalculation) {
          const validSalaryType = _salaryTypesWithHolidays.find((item) => {
            const validRowItem = item.data.find((rowItem) => {
              return toNumber(rowItem?.holidayPayMultiple) > 0;
            });

            return validRowItem;
          });

          if (validSalaryType) {
            return true;
          }

          return createError({ message: errorMsg.invalidSalaryTypeHoliday });
        } else {
          const validSalaryType = _salaryTypes.find((item) => {
            const value = toNumber(item.percentage);

            return value > 0;
          });

          if (validSalaryType) {
            return true;
          }

          return createError({ message: errorMsg.invalidSalaryType });
        }
      }),
    pensionManagers: Yup.mixed().test('requiredWhen', function (_, { createError, options }) {
      const _pensionManagers = options.context?.policySettings?.pensionManagers || [];
      const _salaryChange = options.context?.policySettings?.salaryChange;
      const isAllowedSalaryChange = _salaryChange?.isAllowed;
      const isSeparatedPensionManager = isAllowedSalaryChange && _salaryChange?.isCheckedSepratePensionManagerPlans;

      if (isSeparatedPensionManager) {
        const validOccupationPlan = _pensionManagers.find((item) => {
          return item?.fromOccupationalPension?.isInclude;
        });
        const validSalaryChangePlan = _pensionManagers.find((item) => {
          return item?.fromSalaryChange?.isInclude;
        });

        if (validOccupationPlan && validSalaryChangePlan) {
          return true;
        }
        return createError({ message: errorMsg.requiredWithSeparateManager });
      } else {
        if (_pensionManagers.length > 0) {
          return true;
        }

        return createError({ message: errorMsg.requiredWithoutSeparateManager });
      }
    }),
    pensionManagerConfig: Yup.object().shape({
      employeeRightToEditChecked: Yup.boolean().default(false),
    }),
    insurances: Yup.mixed(),
    insuranceConfig: Yup.object()
      .shape({
        payInsuranceParental: Yup.boolean().default(false),
        payInsuranceOtherLeave: Yup.boolean().default(false),
      })
      .optional(),
    specialConditions: Yup.object().shape({
      probationaryEmployement: Yup.object().shape({
        isChecked: Yup.boolean(),
        isPremiumCalculation: Yup.boolean(),
        isSalaryChange: Yup.boolean(),
        isInsurances: Yup.boolean(),
      }),
      parentalLeave: Yup.object().shape({
        isChecked: Yup.boolean(),
        isPremiumCalculation: Yup.boolean(),
        isSalaryChange: Yup.boolean(),
        isInsurances: Yup.boolean(),
        maximumLength: Yup.number().nullable(),
      }),
      otherLeave: Yup.object().shape({
        isChecked: Yup.boolean(),
        isPremiumCalculation: Yup.boolean(),
        isSalaryChange: Yup.boolean(),
        isInsurances: Yup.boolean(),
        maximumLength: Yup.number().nullable(),
      }),
    }),
    employeeMetaSettings: Yup.object()
      .shape({
        pensionManager: Yup.object().shape({
          effectiveFrom: Yup.string(),
        }),
        insurance: Yup.object().shape({
          effectiveFrom: Yup.string(),
        }),
      })
      .nullable(),
  }),
});

export const buildSchema = (_mode: PensionPolicyMode | undefined) => {
  return formSchema;
};
