import { Form, Row } from 'react-bootstrap';
import PagesHeader from '../../../../../components/base/PagesHeader';
import classNames from 'classnames';
import { errorHighlighter } from '../../../../../util/form';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import { Mode, PensionCompanyFormProps } from '../../types';
import useLocale from '../../../../../hooks/useLocale';

export default function Connection({ context, mode }: PensionCompanyFormProps) {
  const { localize } = useLocale();
  const { formGroup, updateConnection } = context;

  const handleConnectionTypeAPI = (e) => {
    updateConnection(`connectionTypeAPI.${e.target.name}`, e.target.value);
  };
  const handleConnectionTypeFile = (e) => {
    updateConnection(`connectionTypeFile.${e.target.name}`, e.target.value);
  };
  const handleConnectionChoice = (_e, connectionType) => {
    const altType = connectionType === 'connectionTypeAPI' ? 'connectionTypeFile' : 'connectionTypeAPI';

    updateConnection(`${connectionType}.isChecked`, true);
    updateConnection(`${altType}.isChecked`, false);
  };

  return (
    <>
      <PagesHeader headingClass="fs-h3" pageTitle="Connection" />
      {[Mode.Edit, Mode.Add].indexOf(mode) !== -1 && (
        <Row className="align-items-end mb-4">
          <div className="table-responsive react-bootstrap-table ">
            <table className="table table-hover table-condensed">
              <thead>
                <th className='theme-text'>{localize('connection_type')}</th>
                <th className='theme-text'>{localize('settings')}</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Form.Check
                      inline
                      label="API"
                      name="type"
                      type="radio"
                      defaultChecked={formGroup.values.connection.connectionTypeAPI.isChecked}
                      onChange={(e) => handleConnectionChoice(e, 'connectionTypeAPI')}
                    />
                  </td>
                  <td>
                    <div className="cr-form-row flex-nowrap">
                      <Form.Group className='api-key-field'>
                        <Form.Label className="fs-name">
                          {localize('API')} {localize('key')}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="apiKey"
                          onChange={handleConnectionTypeAPI}
                          value={formGroup.values?.connection?.connectionTypeAPI?.apiKey}
                          className={classNames(
                            errorHighlighter(formGroup.getFieldMeta('connection.connectionTypeAPI.apiKey')),
                          )}
                        />
                        <ErrorFeedback field={formGroup.getFieldMeta('connection.connectionTypeAPI.apiKey')} />
                      </Form.Group>
                      <Form.Group className='service-key-field'>
                        <Form.Label className="fs-name">{localize('service_key')}</Form.Label>
                        <Form.Control
                          type="text"
                          name="serviceKey"
                          onChange={handleConnectionTypeAPI}
                          value={formGroup.values?.connection?.connectionTypeAPI?.serviceKey}
                          className={classNames(
                            errorHighlighter(formGroup.getFieldMeta('connection.connectionTypeAPI.serviceKey')),
                          )}
                        />
                        <ErrorFeedback field={formGroup.getFieldMeta('connection.connectionTypeAPI.serviceKey')} />
                      </Form.Group>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <Form.Check
                      inline
                      label={localize('file')}
                      name="type"
                      type="radio"
                      defaultChecked={formGroup.values.connection.connectionTypeFile.isChecked}
                      onChange={(e) => handleConnectionChoice(e, 'connectionTypeFile')}
                    />
                  </td>
                  <td>
                    <div className="cr-form-row flex-nowrap">
                      <Form.Group className='email-field'>
                        <Form.Label className="fs-name">{localize('email')}</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          onChange={handleConnectionTypeFile}
                          value={formGroup.values?.connection?.connectionTypeFile?.email}
                          className={classNames(
                            errorHighlighter(formGroup.getFieldMeta('connection.connectionTypeFile.email')),
                          )}
                        />
                        <ErrorFeedback field={formGroup.getFieldMeta('connection.connectionTypeFile.email')} />
                      </Form.Group>
                      <Form.Group className='file-type-field'>
                        <Form.Label className="fs-name">{localize('type')}</Form.Label>
                        <Form.Select
                          value={formGroup.values?.connection?.connectionTypeFile?.type}
                          name="type"
                          onChange={handleConnectionTypeFile}
                        >
                          <option value={'CSV'}>{localize('CSV')}</option>
                          <option value={'PDF'}>{localize('PDF')}</option>
                          <option value={'EXCEL'}>{localize('Excel')}</option>
                        </Form.Select>
                        <ErrorFeedback field={formGroup.getFieldMeta('connection.connectionTypeFile.type')} />
                      </Form.Group>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      )}
      {mode === Mode.Detail && (
        <>
          <div className="d-flex gap-2">
            <Form.Group style={{ width: 250 }}>
              <Form.Label className="fs-name">{localize('api_key')}</Form.Label>
              <Form.Control
                type="text"
                name="apiKey"
                onChange={handleConnectionTypeAPI}
                value={formGroup.values?.connection?.connectionTypeAPI?.apiKey}
                disabled={mode === Mode.Detail}
              />
            </Form.Group>
            <Form.Group style={{ width: 250 }}>
              <Form.Label className="fs-name">{localize('service_key')}</Form.Label>
              <Form.Control
                type="text"
                name="serviceKey"
                onChange={handleConnectionTypeAPI}
                value={formGroup.values?.connection?.connectionTypeAPI?.serviceKey}
                disabled={mode === Mode.Detail}
              />
            </Form.Group>
          </div>
        </>
      )}
    </>
  );
}
