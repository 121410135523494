import { useEffect, useState } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import { Copy, Edit2 } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import { duplicatePolicy, getPolicyDetails } from '../../../api/service/superAdmin/pension-policies/service';
import Breadcrumb from '../../../components/base/Breadcrumb';
import PagesHeader from '../../../components/base/PagesHeader';
import PensionPoliciesDetailsNonEditable from './PensionPoliciesDetailsNonEditable';
import useContext from './PensionPolicyContext/useContext';
import { AuthUser, UserRole } from '../../../types/auth';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import useLocale from '../../../hooks/useLocale';
import { ActionMode } from './types';
import { getPensionManagerByAgreement } from '../../../api/service/superAdmin/settings/pension-companies';
import { getInsurancesByAgreement } from '../../../api/service/superAdmin/settings/insurances';
import { ReactComponent as CollectiveAgreementIcon } from '../../../assets/img/collective-agreement.svg';
import usePreferredPolicy from './hooks/usePreferredPolicy';
import TooltipBox from '../../../components/base/Overlay/TooltipBox';

function PensionPolicyDetails() {
  const { id } = useParams();
  const [policy, setPolicy] = useState({});
  const [isBusy, setIsBusy] = useState(true);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [pensionDataset, setPensionDataset] = useState(null);
  const [insuranceDataset, setInsuranceDataset] = useState(null);
  const navigate = useNavigate();
  const { localize } = useLocale();
  const { setSelectedPolicyDetails, setActionMode, selectedPolicyDetails } = useContext();
  const preferredPolicy = usePreferredPolicy(selectedPolicyDetails);
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const isSuperAdmin = user?.role?.role === UserRole.SUPER_ADMIN;

  const isEditablePolicy = () => {
    if (!selectedPolicyDetails) {
      return false;
    }

    if (isSuperAdmin && !preferredPolicy.isCollectiveContract()) {
      return true;
    }

    if (!isSuperAdmin) {
      if (preferredPolicy.hasCorrectoAdmin()) {
        return false;
      }

      return true;
    }
  };

  const canDuplicatePolicy = () => {
    if (!selectedPolicyDetails) {
      return false;
    }

    if (preferredPolicy.hasOnlyPersonal()) {
      return false;
    }

    return true;
  };

  const fetchAndSetPolicyDetails = async () => {
    try {
      const _policy = await getPolicyDetails(id);
      await fetchPlans(_policy);
      setPolicy(_policy);
      setSelectedPolicyDetails(_policy);
    } finally {
      setIsBusy(false);
    }
  };

  const fetchPlans = async (_policy) => {
    const _managerResponse = await getPensionManagerByAgreement({
      isDraft: false,
      isCollectiveAgreement: _policy['isCollectiveAgreementLock'],
    });
    const _insuranceResponse = await getInsurancesByAgreement({
      isDraft: false,
      isCollectiveAgreement: _policy['isCollectiveAgreementLock'],
    });

    setInsuranceDataset(_insuranceResponse);
    setPensionDataset(_managerResponse);
  };

  useEffect(() => {
    setActionMode(ActionMode.VIEW);
    fetchAndSetPolicyDetails();
  }, [id]);

  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Pension Policies',
      href: '/pension-policies',
    },
    { name: `${policy['policyName']}`, translate: false },
  ];

  const navigateToEditPolicy = () => {
    if (policy['type'] === 'Personal' && policy['employeeId']) {
      navigate(`/employees/${policy['employeeId']}/${policy['_id']}`);
    } else {
      navigate('edit');
    }
  };

  const onDuplicatePolicy = async () => {
    try {
      setIsDuplicating(true);
      const { _id } = await duplicatePolicy(id);
      navigate(`/pension-policies/${_id}`);
    } finally {
      setIsDuplicating(false);
    }
  };

  return (
    <Container fluid className="p-0">
      {isBusy ? (
        <Spinner />
      ) : (
        <>
          <Breadcrumb navItem={breadcrumb} />
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <PagesHeader headingClass="fs-h1" pageTitle={`${policy['policyName']}`} translate={false} />{' '}
              {policy['isCollectiveAgreementLock'] && (
                <h6>
                  <TooltipBox msg={`${localize('collective_agreement')}`}>
                    <span className="partner-icon">
                      <CollectiveAgreementIcon />
                    </span>
                  </TooltipBox>
                </h6>
              )}
            </div>
            <div className="d-flex gap-2">
              {isEditablePolicy() && (
                <Button onClick={navigateToEditPolicy} className="app-btn-primary">
                  <Edit2 className="icon-gap-right" />
                  {`${localize('PENSION_POLICY.edit_policy')}`}
                </Button>
              )}
              {canDuplicatePolicy() && (
                <Button onClick={onDuplicatePolicy} className="app-btn-primary">
                  {isDuplicating ? (
                    <Spinner size="sm" className="icon-gap-right" />
                  ) : (
                    <Copy className="icon-gap-right" />
                  )}
                  {localize('PENSION_POLICY.duplicate_policy')}
                </Button>
              )}
            </div>
          </div>

          <PensionPoliciesDetailsNonEditable
            policy={policy}
            effectiveFrom={''}
            fetchedFromExternalSource={true}
            pensionDataset={pensionDataset}
            insuranceDataset={insuranceDataset}
          />
        </>
      )}
    </Container>
  );
}

export default PensionPolicyDetails;
