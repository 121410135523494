import { createContext, useState } from 'react';
import { Mode } from './types';
import { ISuperAdminSettingsContext } from './types';
type Props = {
  children: JSX.Element;
};

export const SuperAdminSettingContext = createContext<ISuperAdminSettingsContext>({
  mode: Mode.List,
  setMode: () => {},
  accountingMode: Mode.List,
  setAccountingMode: () => {},
  adminMode: Mode.List,
  setAdminMode: () => {},
});

export const SuperAdminSettingProvider = ({ children }: Props) => {
  const [mode, setMode] = useState(Mode.List);
  const [accountingMode, setAccountingMode] = useState(Mode.List);
  const [adminMode, setAdminMode] = useState(Mode.List);

  const context = {
    mode,
    setMode,
    accountingMode,
    setAccountingMode,
    adminMode,
    setAdminMode,
  };

  return <SuperAdminSettingContext.Provider value={context}>{children}</SuperAdminSettingContext.Provider>;
};
