import HeroImg from '../../assets/img/auth/MyPagesSignIn.jpg';

const EmployeeBanner = () => {
  return (
    <div className="hero-image" style={{ backgroundImage: `url(${HeroImg})` }}>
      <h2 className="hero-title">
        Take control <br /> over your pension
      </h2>
    </div>
  );
};

export default EmployeeBanner;
