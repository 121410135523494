import React, { createContext, useState } from 'react';
import OverlayLoader from '../components/base/OverlayLoader';

interface OverlayLoaderContextProps {
  busy: boolean;
  setBusy: (busy: boolean) => void;
}

export const OverlayLoaderContext = createContext<OverlayLoaderContextProps>({
  busy: false,
  setBusy: () => {},
});

interface OverlayLoaderProps {
  initialBusy?: boolean;
  children: React.ReactNode;
}

export const OverlayLoaderProvider: React.FC<OverlayLoaderProps> = ({ children, initialBusy = false }) => {
  const [busy, setBusy] = useState(initialBusy);

  const setLoadingState = (loading: boolean) => {
    setBusy(loading);
  };

  const contextValue: OverlayLoaderContextProps = {
    busy,
    setBusy: setLoadingState,
  };

  return (
    <OverlayLoaderContext.Provider value={contextValue}>
      <OverlayLoader busy={busy} />
      {children}
    </OverlayLoaderContext.Provider>
  );
};
