import { useState, useEffect } from 'react';

import { MultiSeriesChart } from './multi-series/MultiSeriesChart';
import { getBusinessAnalysisChart } from '../../api/service/company/business-analysis/service';
import useAuth from '../../hooks/useAuth';
import { ISeries } from './multi-series/types';
import { Calendar } from '../../util/calendar';

type Props = {
  chartId?: string;
  type?: 'base-salaries' | 'real-salaries' | 'salary-changes';
};

const BusinessAnalyticsChart = ({ type }: Props) => {
  const [baseSalaryChartData, setBaseSalaryChartData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState<[string, string]>(Calendar.yearToDateBoundary);

  const { user } = useAuth();
  const series: ISeries[] = [
    // { label: 'Person 1', field: 'Person1' },
    // { label: 'Person 2', field: 'Person2' },
    // { label: 'Person 3', field: 'Person3' },
    // { label: 'Person 4', field: 'Person4' },
    // { label: 'Person 5', field: 'Person5' },
    // { label: 'Person 6', field: 'Person6' },
    // { label: 'Person 7', field: 'Person7' },
    // { label: 'Person 8', field: 'Person8' },
    // { label: 'Person 9', field: 'Person9' },
    // { label: 'Person 10', field: 'Person10' },
    { label: 'Total', field: 'total', checked: true },
  ];

  const seriesOptions = {
    series,
    showTotalAfterSeries: true,
    shouldToggleTotal: true,
    showToggleButtons: false,
  };
  const fetchBusinessAnalysisBaseSalaryChart = async () => {
    const startDateOfTheYear = dateRange[0];
    const endDateOfTheYear = dateRange[1];
    let chartData = await getBusinessAnalysisChart({
      duration: { from: startDateOfTheYear, to: endDateOfTheYear },
      companyClientId: user?.role.company.id,
      report_name: type,
    });
    const formattedData = chartData.map((value) => {
      return [value.date, value.salary];
    });
    setBaseSalaryChartData(formattedData);
    setIsLoading(false);
  };
  const changeDateHandler = (dateRange: [string, string]) => {
    setDateRange(dateRange);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchBusinessAnalysisBaseSalaryChart();
  }, [dateRange]);

  return (
    <div className="chart mt-4">
      <MultiSeriesChart
        data={baseSalaryChartData}
        seriesOptions={seriesOptions}
        initialDateRange={dateRange}
        changeDateHandler={changeDateHandler}
        isLoading={isLoading}
      />
    </div>
  );
};

export default BusinessAnalyticsChart;
