import { Form } from 'react-bootstrap';
import PagesHeader from '../../../../../components/base/PagesHeader';
import classNames from 'classnames';
import { errorHighlighter } from '../../../../../util/form';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import { OrganizationNumber } from '../../../../../components/form/inputs/OrganizationNumber';
import { useEffect } from 'react';
import { Mode, PensionCompanyFormProps } from '../../types';
import {
  INITIAL_CONNECTION,
  INITIAL_CONTACT_INFORMATION,
  INITIAL_PENSION_COMPANY,
  INITIAL_PENSION_MANAGER_PLANS,
} from '../constants';
import { deepClone } from '../../../../../util/util';
import useLocale from '../../../../../hooks/useLocale';

export default function CompanyInformation({ context, mode, OnupdatePensionCompany }: PensionCompanyFormProps) {
  const {
    formGroup,
    pensionCompany,
    contactInformation,
    updatePensionCompany,
    updateContactInformation,
    replacePensionCompany,
    replaceContactInformation,
  } = context;

  const { localize, lang } = useLocale();

  const handleChange = (ev) => {
    updatePensionCompany(ev.target.name, ev.target.value);
  };

  const handleContactInfo = (e) => {
    updateContactInformation(e.target.name, e.target.value);
  };

  const handleCheck = (name, isChecked) => {
    updatePensionCompany(name, isChecked);
  };

  useEffect(() => {
    if (mode === Mode.Add) {
      replacePensionCompany(deepClone(INITIAL_PENSION_COMPANY));
      replaceContactInformation(deepClone(INITIAL_CONTACT_INFORMATION));
      formGroup.setFieldValue('pensionManagerPlans', deepClone(INITIAL_PENSION_MANAGER_PLANS));
      formGroup.setFieldValue('connection', INITIAL_CONNECTION);
    }
  }, []);

  useEffect(() => {
    if (formGroup.values.pensionCompany.collectiveAgreement) {
      formGroup.setFieldValue('pensionManagerPlans.correctoPartner', false);
    }
  }, [formGroup.values.pensionCompany.collectiveAgreement]);

  useEffect(() => {
    if (formGroup.values.pensionManagerPlans.correctoPartner) {
      formGroup.setFieldValue('pensionCompany.collectiveAgreement', false);
    }
  }, [formGroup.values.pensionManagerPlans.correctoPartner]);

  return (
    <>
      <PagesHeader headingClass="fs-h3" pageTitle="Company Information" />
      <div className="cr-form-row">
        <div className="short-name">
          <Form.Label className="fs-name  required">{localize('short_name')}</Form.Label>
          <Form.Control
            name="shortName"
            onChange={(e) => handleChange(e)}
            value={pensionCompany?.shortName}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('pensionCompany.shortName')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('pensionCompany.shortName')} />
        </div>
        <div className="company-name-field">
          <Form.Label className="fs-name  required">{localize('company_name')}</Form.Label>
          <Form.Control
            name="companyName"
            onChange={handleChange}
            value={pensionCompany?.companyName}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('pensionCompany.companyName')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('pensionCompany.companyName')} />
        </div>
        <div className="client-no-field">
          <Form.Label className="fs-name  required">{`${localize('client_no')}.`}</Form.Label>
          <Form.Control
            type="number"
            name="clientNumber"
            onChange={handleChange}
            value={pensionCompany?.clientNumber}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('pensionCompany.clientNumber')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('pensionCompany.clientNumber')} />
        </div>
        <div className="contract-no-field">
          <Form.Label className="fs-name  required">{`${localize('contract_no')}`}</Form.Label>
          <Form.Control
            type="number"
            name="contractNumber"
            onChange={handleChange}
            value={pensionCompany?.contractNumber}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('pensionCompany.contractNumber')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('pensionCompany.contractNumber')} />
        </div>
        <div className="align-self-start">
          <Form.Label className="fs-name"> </Form.Label>
          <Form.Check
            checked={formGroup.values.pensionCompany.collectiveAgreement}
            onChange={(e) => handleCheck('collectiveAgreement', e.target.checked)}
            inline
            name="collectiveAgreement"
            type="checkbox"
            label={`${localize('for_collective_agreement')}`}
            className="fs-name  mt-2 w-100"
            id="collectiveAgreement"
            disabled={mode === Mode.Detail}
          />
        </div>
      </div>
      <div className="cr-form-row mb-4">
        <div className="text_description-field">
          <Form.Label className="fs-name">{localize('text_description')}</Form.Label>
          <Form.Control
            name="description"
            onChange={handleChange}
            value={pensionCompany?.description}
            disabled={mode === Mode.Detail}
          />
        </div>
      </div>
      <div className="cr-form-row mb-4">
        <OrganizationNumber
          onChangeHandler={handleChange}
          field={formGroup.getFieldMeta('pensionCompany.organizationNumber')}
          value={pensionCompany?.organizationNumber}
          customLabel="Org.no."
          disabled={mode === Mode.Detail}
          isRequiredError={true}
        />
        {/* <div style={{ width: 116 }}>
          
            <Form.Label className="fs-name required">Org.no.</Form.Label>
            <Form.Control
              type="number"
              name="organizationNumber"
              onChange={handleChange}
              value={pensionCompany?.organizationNumber}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('organizationNumber')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('organizationNumber')} />
  </div> */}
        <div className="vat-num-field">
          <Form.Label style={{ width: lang === 'se' ? 100 : 120 }} className="fs-name">
            {localize('vat_no')}
          </Form.Label>
          <Form.Control
            type="text"
            name="vatNumber"
            onChange={handleChange}
            value={pensionCompany?.vatNumber}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div className="bankgiro-num-field">
          <Form.Label className="fs-name">{localize('bankgiro_no')}</Form.Label>
          <Form.Control
            type="text"
            name="bankgiroNumber"
            onChange={handleChange}
            value={pensionCompany?.bankgiroNumber}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div style={{ width: 105 }}>
          <Form.Label className="fs-name">{localize('plusgiro_no')}</Form.Label>
          <Form.Control
            type="text"
            name="plusgiroNumber"
            onChange={handleChange}
            value={pensionCompany?.plusgiroNumber}
            disabled={mode === Mode.Detail}
          />
        </div>
      </div>
      <PagesHeader headingClass="fs-h3" pageTitle="Contact information" />
      <div className="cr-form-row mb-4">
        <div style={{ width: 116 }}>
          <Form.Label className="fs-name">{localize('postal_address')}</Form.Label>
          <Form.Control
            name="address"
            onChange={handleContactInfo}
            value={contactInformation?.address}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div className="zip-code-field">
          <Form.Label className="fs-name">{localize('zip_code')}</Form.Label>
          <Form.Control
            type="text"
            name="postalCode"
            onChange={handleContactInfo}
            value={contactInformation?.postalCode}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div className="city-field">
          <Form.Label className="fs-name">{localize('city')}</Form.Label>
          <Form.Control
            name="city"
            onChange={handleContactInfo}
            value={contactInformation?.city}
            disabled={mode === Mode.Detail}
          />
        </div>
      </div>
      <div className="cr-form-row mb-4">
        <div className="phone-num-field">
          <Form.Label className="fs-name">{localize('phone_number')}</Form.Label>
          <Form.Control
            type="text"
            name="phone"
            onChange={handleContactInfo}
            value={contactInformation?.phone}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div className="fax-num-field">
          <Form.Label className="fs-name">{localize('fax')}</Form.Label>
          <Form.Control
            name="fax"
            onChange={handleContactInfo}
            value={contactInformation?.fax}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div className="email-field">
          <Form.Label className="fs-name">{localize('email')}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            onChange={handleContactInfo}
            value={contactInformation ? contactInformation!['email'] : ''}
            disabled={mode === Mode.Detail}
          />
        </div>
      </div>
    </>
  );
}
