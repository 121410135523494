import moment from 'moment';
import { IYearMonthlyAggregate } from './types';

interface IMonthyNavigationInput {
  year: number;
  monthNumber?: number;
  monthName?: string;
  type?: 'next' | 'prev';
}

interface IYearlyMonthInput {
  year: number;
  monthIndex: number;
  monthName: string;
}

export const hasYearWithMonth = (dateString) => {
  const regex = /^(?:\d{4}-\d{2}|\d{4}-\d{2}-\d{2})$/;

  return regex.test(dateString);
};

export const parseYearMonth = (dateString, defaultMonth: 'start' | 'end' | null = null) => {
  const dateMoment = moment(String(dateString), ['YYYY', 'YYYY-MM'], true);
  const year = dateMoment.year();
  const month = dateMoment.month();

  const data = {
    instance: dateMoment,
    year: +year,
    month: hasYearWithMonth(dateString) ? +month + 1 : null,
  };

  if (defaultMonth) {
    if (defaultMonth === 'start') {
      data.month = +dateMoment.startOf('year').startOf('month').format('MM');
    } else if (defaultMonth === 'end') {
      data.month = +dateMoment.endOf('year').endOf('month').format('MM');
    }
  }

  return data;
};

export const calculateMonthDifference = (startYear, endYear) => {
  const startDate = moment(`${startYear}`);
  const endDate = moment(`${endYear}`);

  const monthDifference = endDate.diff(startDate, 'months');

  return monthDifference + 1;
};

export const getYearlyMonthListByCount = (count: number, fromYear: string | null, toYear: string | null = null) => {
  let fromDate: { year: any; month: any; instance: moment.Moment };

  if (fromYear) {
    fromDate = parseYearMonth(fromYear);
  }

  if (fromYear && toYear) {
    const monthDifference = calculateMonthDifference(fromYear, toYear);

    if (monthDifference > count) {
      return Array.apply(0, Array(count)).map(function (_, i) {
        const iteratedDate = fromDate.instance.clone().add(i, 'months');
        return {
          year: iteratedDate.year(),
          monthIndex: iteratedDate.month() + 1,
          monthName: iteratedDate.format('MMM'),
        };
      });
    } else {
      return Array.apply(0, Array(monthDifference)).map(function (_, i) {
        const iteratedDate = fromDate.instance.clone().add(i, 'months');

        return {
          year: iteratedDate.year(),
          monthIndex: iteratedDate.month() + 1,
          monthName: iteratedDate.format('MMM'),
        };
      });
    }
  }

  return Array.apply(0, Array(count)).map(function (_, i) {
    const iteratedDate = fromDate.instance.clone().add(i, 'months');

    return {
      year: iteratedDate.year(),
      monthIndex: iteratedDate.month() + 1,
      monthName: iteratedDate.format('MMM'),
    };
  });
};

export const parseYearRange = (yearRange: string[]) => {
  let startDate = yearRange[0];
  let endDate = yearRange[1];

  if (!startDate) {
    startDate = moment(yearRange[0] ? String(yearRange[0]) : undefined)
      .startOf('year')
      .startOf('month')
      .format('YYYY-MM');
  }

  if (!endDate) {
    endDate = moment(yearRange[1] ? String(yearRange[1]) : undefined)
      .endOf('year')
      .endOf('month')
      .format('YYYY-MM');
  }

  return [startDate, endDate];
};

export const getMonthNavigation = ({ year, monthNumber, type = 'next' }: IMonthyNavigationInput) => {
  const parsedDate = moment({ year, month: monthNumber! - 1 });
  const month = type === 'next' ? parsedDate.add(1, 'month') : parsedDate.subtract(1, 'month');
  const monthlyYear = month.format('YYYY');
  const monthlyIndex = month.format('M');
  const monthlyName = month.format('MMM');

  return {
    year: parseInt(monthlyYear),
    monthIndex: parseInt(monthlyIndex),
    monthName: monthlyName,
  };
};

export const getYearsFromMonth = (yearlyMonths: IYearlyMonthInput[]) => {
  const yearList: IYearMonthlyAggregate[] = [];

  yearlyMonths.forEach((monthlyInput) => {
    const { year } = monthlyInput;

    const existingYear = yearList.find((item) => item.year === year);

    if (existingYear) {
      existingYear.numberOfMonths++;
    } else {
      yearList.push({ year, numberOfMonths: 1 });
    }
  });

  return yearList;
};

export const getSumByKey = (array: any[], key) => {
  return array.reduce((acc, obj) => {
    const value = obj?.[key] || 0;
    if (value !== undefined && !isNaN(value)) {
      return acc + value;
    }
    return acc;
  }, 0);
};

export const isEmptyVal = (value) => {
  if (value === '' || value === null || value === undefined || value === false) {
    return true;
  } else {
    return false;
  }
};

export const numberFormat = (val, opt: Partial<{ decimal: number; locale: string }> | null = null): string | null => {
  const defaultOpt = { decimal: 2, locale: 'se' };
  const locale = opt?.locale || defaultOpt.locale;
  const separator = locale === 'se' ? ',' : '.';
  const decimal = isEmptyVal(opt?.decimal) ? defaultOpt.decimal : opt?.decimal;
  let result: string;

  if (isEmptyVal(val)) {
    return null;
  }

  if (locale === 'se') {
    const parts = Number(val).toLocaleString('sv-SE', { minimumFractionDigits: decimal }).split(',');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const decimalPart = parts[1] ? ',' + parts[1] : '';
    result = integerPart + decimalPart;
  } else {
    result = Number(val)
      .toFixed(decimal)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/\./g, separator);
  }

  return result;
};
