export const INITIAL_SIGNATORY_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
  incharge: false,
};

export const INITIAL_ADMIN_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
};

export const INITIAL_CONTACT_PEOPLE = {
  system: '',
  personalNumber: '',
  name: '',
  email: '',
};

export const INITIAL_ADDRESS_DETAILS = {
  city: '',
  country: '',
  postcode: '',
  street: '',
};

export const INITIAL_COMPANY_DETAILS = {
  organizationNumber: '',
  name: '',
  status: 'Active',
  employees: null,
};

export const INITIAL_CONSULANTS_DETAILS = {
  fullName: '',
  personalNumber: '',
  email: '',
};
export const INITIAL_INIVITATION_EMAIL_SETTING = '3';

export const FORM_INITIAL_VALUES = {
  companyDetails: INITIAL_COMPANY_DETAILS,
  address: INITIAL_ADDRESS_DETAILS,
  contractEffectiveFrom: '',
  contractEmail: '',
  invoiceCostCenter: '',
  authorizedSignatory: [],
  admins: [],
  consultants: [],
  consultant: {},
  salaryContactPeople: { ...INITIAL_CONTACT_PEOPLE },
  accountingContactPeople: { ...INITIAL_CONTACT_PEOPLE },
  collectiveAgreements: [],
  invitationEmail: INITIAL_INIVITATION_EMAIL_SETTING,
  contractNumber: '',
  combinations: [],
  allowCorrectoAccess: false,
  allowAdvisorAccess: false,
};
