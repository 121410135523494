import { Form, Row, Col } from 'react-bootstrap';
import { FakeInputBox } from '../../../../components/base/FakeInputBox';
import { isNumeric, numberFormatToSameDecimal, toNumber } from '../../../../util/util';
import PagesHeader from '../../../../components/base/PagesHeader';
import { useTranslation } from 'react-i18next';
import PriceInput from '../../../../components/base/PriceInput';
import useIncomeCalculation from '../hooks/useIncomeCalculation';
import { SALARY_AMOUNT_INDEX_OPTIONS, SALARY_INTERVAL_AGE_DEFINITION_OPTIONS } from '../constants';

type Props = {
  selectedPolicy: any;
};

export default function OccupationalPensionDetail({ selectedPolicy }: Props) {
  const { t } = useTranslation();
  const { groupCollection } = useIncomeCalculation();
  const occupationalPension = selectedPolicy.policySettings.occupationalPension;
  const isIntervalCompensation = occupationalPension?.isIntervalCompensation;

  return (
    <>
      <PagesHeader
        headingClass="pp-sec-heading"
        pageTitle={`${t('PENSION_POLICY.premium_calculation')}`}
        biggerHeading
        translate={false}
      />
      {!isIntervalCompensation && (
        <>
          <div className="mb-4">
            <PagesHeader pageTitle="Total salary compensation" />
            <div className="dark-content-section p-3 d-flex align-items-end">
              <Form.Group className="percentage-compensation-field">
                <Form.Label className="fs-name">{t('percentage_compensation')} (%)</Form.Label>
                {
                  <FakeInputBox
                    width={60}
                    align="end"
                    value={toNumber(selectedPolicy.policySettings.occupationalPension.percentageCompensation)}
                  />
                }
              </Form.Group>
              <Form.Group className="fixed-compensation-field">
                <Form.Label className="fs-name">{t('fixed_compensation')} (SEK)</Form.Label>
                <FakeInputBox
                  width={100}
                  align="end"
                  value={toNumber(selectedPolicy.policySettings.occupationalPension.fixedCompensation)}
                />
              </Form.Group>
            </div>
          </div>
        </>
      )}
      {isIntervalCompensation && (
        <div className="mb-4">
          <PagesHeader pageTitle="Salary interval compensation" />
          <div className="d-flex">
            <div className="me-4">
              {/* <PagesHeader pageTitle="Salary parts under" headingClass="fs-text-400" /> */}
              <div className="dark-content-section d-flex p-2 align-items-end">
                <div className=" d-flex dark-content-section mb-4 income-metrix-box">
                  <Row className="w-100">
                    <Col className="col-3">
                      <div className="left-box">
                        <Form.Group>
                          <Form.Label className="fs-name">{t('PENSION_POLICY.amount_index')}</Form.Label>
                          <p>{t('PENSION_POLICY.salary_matrix_index_selection')}</p>
                          <Form.Select disabled value={selectedPolicy.policySettings.occupationalPension.amountIndex}>
                            {SALARY_AMOUNT_INDEX_OPTIONS.map((item) => {
                              return (
                                <option key={item.key} value={item.key}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                        {/* <Form.Group>
                          <Form.Label className="fs-name">Offset calculation</Form.Label>
                          <Form.Select
                            disabled
                            value={selectedPolicy.policySettings.occupationalPension.offsetCalculation}
                          >
                            <option>%</option>
                          </Form.Select>
                        </Form.Group> */}
                        <Form.Group>
                          <Form.Label className="fs-name">{t('PENSION_POLICY.age_definition')}</Form.Label>
                          <Form.Select disabled value={selectedPolicy.policySettings.occupationalPension.ageDefination}>
                            {SALARY_INTERVAL_AGE_DEFINITION_OPTIONS.map((item) => {
                              return (
                                <option key={item.key} value={item.key}>
                                  {t(item.translationKey)}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </Form.Group>
                      </div>
                    </Col>
                    <Col className="col-9">
                      <div className="metrix-table metrix-view">
                        <span className="first-column-line"></span>
                        <table className="w-100">
                          <thead>
                            <th>
                              <span className="theme-text">{t('PENSION_POLICY.age')}</span>
                              {/* <div className="age-input-metrix">
                        <Form.Select
                          name="from"
                          value={ageCollection[0]}
                          onChange={(e) => handleAgeChange(e.target.value, 0)}
                        >
                          {ageList.map((item) => (
                            <option value={item} key={item}>
                              {item}
                            </option>
                          ))}
                        </Form.Select> 
                      </div> */}
                            </th>
                            <th>
                              <span className="second-th-heading theme-text">{t('PENSION_POLICY.index_amount')}</span>
                            </th>
                            {groupCollection.map((groupItem, groupIdx) => {
                              return (
                                <th key={groupIdx}>
                                  <span className="second-th-heading theme-text">
                                    {numberFormatToSameDecimal(groupItem.indexAmount.min)}{' '}
                                    {isNumeric(groupItem.indexAmount.min) && isNumeric(groupItem.indexAmount.max)
                                      ? '-'
                                      : ''}{' '}
                                    {numberFormatToSameDecimal(groupItem.indexAmount.max)}{' '}
                                    {selectedPolicy.policySettings.occupationalPension?.amountIndex || ''}
                                  </span>
                                </th>
                              );
                            })}
                          </thead>
                          <tbody>
                            {groupCollection.map((groupItem, groupIdx) => {
                              return (
                                <tr key={groupIdx}>
                                  <td></td>
                                  <td className="theme-text">
                                    {groupItem.age.min} {groupItem.age.min && groupItem.age.max ? '-' : ''}{' '}
                                    {groupItem.age.max}{' '}
                                  </td>
                                  {groupItem.incomeLevels.map((incomeLevel, incomeIdx) => {
                                    return (
                                      <td key={`incomeIdx-${groupIdx}${incomeIdx}`}>
                                        <PriceInput disabled defaultValue={incomeLevel.value || 0} maxValueDigit={7} />
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
