import * as yup from 'yup';
import { personalNumberValidations } from '../../../../util/validation';


export const formSchema = yup.object().shape({
    personalNumber: personalNumberValidations(),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup.string().email().required('Required'),
});
