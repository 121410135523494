import classNames from 'classnames';
import { ChangeEventHandler } from 'react';
import { Form } from 'react-bootstrap';
import { XCircle } from 'react-feather';

type Props = {
  label: any;
  switchColor: string;
  className: string;
  checked: boolean;
  showDeleteBtn?: boolean;
  onDeleteHandler?: (args) => void;
  onSwitch: ChangeEventHandler<HTMLInputElement>;
  value?: any;
};

export const SWITCH_COLORS = {
  Green: '#3CAE35',
  Blue: '#2D99D2',
  Orange: '#D39928',
  Pink: '#D156C5',
  Purple: '#7E28D3',
};

export default function ChartSwitch({
  label,
  switchColor,
  className,
  checked,
  showDeleteBtn = false,
  onSwitch,
  onDeleteHandler,
  value,
}: Props) {
  const handleDelete = (e) => {
    if (onDeleteHandler) {
      onDeleteHandler(e);
    }
  };
  return (
    <div
      className={classNames('form-switch-wrapper', className, { 'form-switch-wrapper--cancelled': showDeleteBtn })}
      style={{
        background: switchColor,
      }}
    >
      <Form.Check
        checked={checked}
        onChange={onSwitch}
        type="switch"
        bsPrefix="chart-switch"
        id={label ? label : 'custom-switch'}
        className={classNames('custom-switch')}
        label={label}
        value={value || label}
      />
      {showDeleteBtn && (
        <span className="cancel-switch">
          <XCircle onClick={handleDelete} />
        </span>
      )}
    </div>
  );
}
