import { createContext, useState } from 'react';
import { INITIAL_PERSONAL_DETAILS, INITIAL_STATISTICS, INITIAL_USER_SETTINGS } from '../contants';
import {
  PersonalDetails,
  UserSettings,
  Statistics,
  ICompanyConsultantContext,
  LooseObject,
  Consultants,
  ConsultantsFormGroup,
} from '../types';
import { useFormik } from 'formik';
import { formSchema } from './schema';
import set from 'lodash/set';

export const EditConsultantContext = createContext<ICompanyConsultantContext>({
  consultantDetailRes: [],
  setConsultantDetailRes: () => {},

  consultants: [],
  setConsultants: () => {},

  personalDetails: INITIAL_PERSONAL_DETAILS,
  setPersonalDetails: () => {},

  userSettings: INITIAL_USER_SETTINGS,
  setUserSettings: () => {},

  statistics: INITIAL_STATISTICS,
  setStatistics: () => {},

  position: '',
  setPosition: () => {},

  isEditMode: true,
  formGroup: {} as ConsultantsFormGroup,

  updateConsultantInput: () => {},
  replaceConsultantInput: () => {},
  patchConsultantForm: () => {},
});

type Props = {
  children: JSX.Element;
};

export const EditConsultantProvider = ({ children }: Props) => {
  const [consultantDetailRes, setConsultantDetailRes] = useState<any>(null);
  const [consultants, setConsultants] = useState<Array<Consultants>>([]);
  const [personalDetails, setPersonalDetails] = useState<PersonalDetails | LooseObject>(INITIAL_PERSONAL_DETAILS);
  const [statistics, setStatistics] = useState<Statistics | LooseObject>(INITIAL_STATISTICS);
  const [userSettings, setUserSettings] = useState<UserSettings | LooseObject>(INITIAL_USER_SETTINGS);
  const [position, setPosition] = useState<string>('');

  const formGroup = useFormik({
    initialValues: INITIAL_PERSONAL_DETAILS,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const updateConsultantInput = (inputName, inputValue) => {
    const _personalDetails = { ...personalDetails };

    set(_personalDetails, inputName, inputValue);

    formGroup.setFieldValue(inputName, inputValue);
    setPersonalDetails((value) => ({
      ...value,
      ..._personalDetails,
    }));
  };

  const replaceConsultantInput = (value) => {
    setPersonalDetails(value);
    formGroup.setValues(value);
  };

  const patchConsultantForm = (patchValue) => {
    setPersonalDetails((value) => ({
      ...value,
      address: patchValue.address,
      fullName: patchValue.name,
    }));
    formGroup.setValues((value) => ({
      ...value,
      address: patchValue.address,
      fullName: patchValue.name,
    }));
  };
  const context: ICompanyConsultantContext = {
    consultants,
    setConsultants,
    personalDetails,
    setPersonalDetails,
    userSettings,
    setUserSettings,
    statistics,
    setStatistics,
    isEditMode: true,
    position,
    setPosition,
    formGroup,
    updateConsultantInput,
    replaceConsultantInput,
    patchConsultantForm,
    consultantDetailRes,
    setConsultantDetailRes,
  };

  return <EditConsultantContext.Provider value={context}>{children}</EditConsultantContext.Provider>;
};
