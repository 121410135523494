import { ColumnDescription } from 'react-bootstrap-table-next';
import { UserRole, UserSubRole } from '../types/auth';

type AccessRole = UserRole | UserRole[] | UserSubRole | UserSubRole[];

interface ColumnProps {
  allowedRoles: AccessRole;
  roleType?: 'primary' | 'secondary';
  columns: ColumnDescription<any, any>[];
}

const getAllowedPermissions = (allowedRoles: AccessRole): UserRole[] | UserSubRole[] => {
  if (!Array.isArray(allowedRoles) && typeof allowedRoles !== 'string') {
    return [];
  }

  if (typeof allowedRoles === 'string') {
    return [allowedRoles] as UserRole[] | UserSubRole[];
  }

  return allowedRoles;
};

export const getColumnsAccessByRole = ({ columns, allowedRoles }: ColumnProps) => {
  const rolePermissions = getAllowedPermissions(allowedRoles);

  return columns.filter((item: any) => {
    const selectedRoles: AccessRole = item.allowedRoles;

    if (!selectedRoles) {
      return true;
    }

    if (typeof selectedRoles === 'string') {
      return (rolePermissions as UserRole[]).includes(selectedRoles as UserRole);
    }

    if (Array.isArray(selectedRoles)) {
      return (rolePermissions as UserRole[]).find((rolePermission: UserRole) => {
        return (selectedRoles as UserRole[]).find((allowRoleItem) => allowRoleItem === rolePermission);
      });
    }

    return true;
  });
};
