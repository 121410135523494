/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { Col, Form, Tab, Tabs, Accordion } from 'react-bootstrap';
import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import useContext from './PensionPolicyContext/useContext';
import MonthAndYearDropdown from '../../../section/MonthAndYearDropdown';
import { AuthUser, UserRole } from '../../../types/auth';
import CompensationPlan from '../../../components/form/CompensationPlan';
import classNames from 'classnames';
import { errorHighlighter } from '../../../util/form';
import ErrorFeedback from '../../../components/ErrorFeedback';
import useLocale from '../../../hooks/useLocale';
import { TextEditor } from '../../../components/form/inputs/TextEditor';
import SalaryChangeGrid from './PensionPolicyForm/SalaryChangeGrid';
import PernsionManagerGrid from './PensionPolicyForm/PensionManagerGrid';
import InsuranceGrid from './PensionPolicyForm/InsuranceGrid';
import { INITIAL_SALARY_CHNAGE, PENSION_POLICY_TABS } from './constants';
import usePolicyMode from './hooks/usePolicyMode';
import SalaryTypesTable from './PensionPolicyForm/SalaryTypesTable';
import { deepClone, isEmptyVal } from '../../../util/util';
import { PensionPolicyTabKey } from './types';
import SpecialConditions from './PensionPolicyForm/SpecialConditions/SpecialConditions';
import { Calendar } from '../../../util/calendar';

type Props = {
  policy?: any;
  openedByModal?: boolean;
};
function PensionPoliciesDetailsEditable({ policy, openedByModal = false }: Props) {
  const {
    policyForm,
    formGroup,
    tabKey,
    setTabKey,
    setSalaryChange,
    replaceSalaryChangeInput,
    updatePolicyFieldInput,
    preAssignedPolicy,
    selectedPolicyDetails,
  } = useContext();
  const { localize } = useLocale();
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const [isPageMounted, setIsPageMounted] = useState(false);
  const handleTextEditor = (field, value) => {
    updatePolicyFieldInput(field, value);
  };
  const { hasAdminPolicyMode } = usePolicyMode();

  const validateTabStyle = (fieldPath) => {
    const field = formGroup.getFieldMeta(`policySettings.${fieldPath}`);

    if (field.touched && field.error) {
      return 'invalid-tab';
    }

    return '';
  };

  const hasValidStep = (fieldPath: PensionPolicyTabKey) => {
    const stepperIndex = PENSION_POLICY_TABS.indexOf(fieldPath);
    const tabsBefore = PENSION_POLICY_TABS.slice(0, stepperIndex);

    if (stepperIndex === 0 || stepperIndex === -1) {
      return true;
    }

    const isValidAll = tabsBefore.every((itemKey) => {
      const selectedTab = formGroup.getFieldMeta(`policySettings.${itemKey}`);

      return selectedTab.error ? false : true;
    });

    return isValidAll;
  };

  const hasInvalidStep = (fieldPath: PensionPolicyTabKey) => {
    return !hasValidStep(fieldPath);
  };

  useEffect(() => {
    setSalaryChange(deepClone(INITIAL_SALARY_CHNAGE));
  }, []);

  useEffect(() => {
    const ele = document.getElementById(`crt-pension-policy-tab-container-tab-${tabKey}`);

    if (!isPageMounted) {
      setIsPageMounted(true);

      return;
    }

    if (!openedByModal) {
      if (ele) {
        /* const rect = ele!.getBoundingClientRect();

        window.scrollTo({ top: window.scrollY + rect.top, behavior: 'instant' as any });*/
        window.scrollTo({ top: 0, behavior: 'instant' as any });
      }
    }

    if (openedByModal) {
      const pensionPolicyModal = document.querySelector('.crt-add-pension-policy-modal .modal-body');

      if (ele && pensionPolicyModal) {
        // const rect = ele!.getBoundingClientRect();

        pensionPolicyModal!.scrollTo({
          // top: pensionPolicyModal!.getBoundingClientRect().top + rect.top,
          top: 0,
          behavior: 'instant' as any,
        });
      }
    }
  }, [tabKey]);

  useEffect(() => {
    if (!isEmptyVal(policy)) {
      //patchSelectedPensionPolicy(policy);
    }
  }, []);

  return (
    <CardComponent containerBodyClassName="crt-pension-policy-container">
      <div className="cr-form-row">
        {hasAdminPolicyMode() && (
          <Form.Group className="policy-name-field">
            <Form.Label className="fs-name required">{localize('policy_name')}</Form.Label>
            <Form.Control
              onChange={(e) => updatePolicyFieldInput('policyName', e.target.value)}
              type="text"
              name="policyName"
              value={policyForm.policyName || ''}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('policyName')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('policyName')} />
          </Form.Group>
        )}

        {hasAdminPolicyMode() && (
          <Form.Group className="version-field">
            <Form.Label className="fs-name required">{localize('version')}</Form.Label>
            <Form.Control
              onChange={(e) => updatePolicyFieldInput('version', e.target.value)}
              type="text"
              name="version"
              value={policyForm.version || ''}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('version')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('version')} />
          </Form.Group>
        )}

        {hasAdminPolicyMode() && (
          <Form.Group className="employment-type-field">
            <Form.Label className="fs-name required">{localize('employment_type')}</Form.Label>
            <Form.Select
              onChange={(e) => updatePolicyFieldInput('employeeType', e.target.value)}
              value={policyForm.employeeType ?? ''}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('employeeType')))}
            >
              <option value="" disabled>
                {localize('select_option')}
              </option>
              <option value="WhiteCollar">{localize('white_collar')}</option>
              <option value="BlueCollar">{localize('blue_collar')}</option>
            </Form.Select>
            <ErrorFeedback field={formGroup.getFieldMeta('employeeType')} />
          </Form.Group>
        )}

        <Form.Group className="effect-from-field">
          <Form.Label className="fs-name required">{localize('effective_from')}</Form.Label>
          <MonthAndYearDropdown
            value={policyForm.effectiveFrom}
            onOptionSelect={(val) => {
              updatePolicyFieldInput('effectiveFrom', val);
            }}
            customOptions={Calendar.generateEffectiveDateOption(selectedPolicyDetails?.effectiveFrom, {
              readonlyPrevMonth: true,
              includeCurrentMonth: true,
            })}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('effectiveFrom')))}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('effectiveFrom')} />
        </Form.Group>

        {/* {isCompanyPolicyMode() && hasEditAction() && <AutoAssignEmployeeTypeSetting />} */}

        {/* {hasPolicy && (
          <Col md="4" xl="1">
            <Form.Group className="mb-3">
              <Form.Label className="fs-name">Status</Form.Label>
              <Form.Select
                onChange={(e) => setPolicyForm({ ...policyForm, status: e.target.value })}
                value={policyForm.status}
              >
                <option value="Draft">Draft</option>
                <option value="Active">Active</option>
                <option value="Inactive">In Active</option>
              </Form.Select>
            </Form.Group>
          </Col>
        )} */}
        {user?.role.role !== UserRole.COMPANY_ADMIN && (
          <Col className="mt-4">
            <Form.Group className="mb-3 pe-0">
              {/* <Form.Check
                inline
                type="checkbox"
                id="isCollectiveAgreementLock"
                label="Collective agreement"
                name="isCollectiveAgreementLock"
                onChange={(e) => updatePolicyFieldInput('isCollectiveAgreementLock', e.target.checked)}
                defaultChecked={policyForm.isCollectiveAgreementLock}
              /> */}
              <Form.Check inline id="isCollectiveAgreementLock">
                <Form.Check.Input
                  name="isCollectiveAgreementLock"
                  type="checkbox"
                  checked={formGroup?.values.isCollectiveAgreementLock}
                  // defaultChecked={policyForm.isCollectiveAgreementLock}
                  onChange={(e) => updatePolicyFieldInput('isCollectiveAgreementLock', e.target.checked)}
                />
                <Form.Check.Label htmlFor="isCollectiveAgreementLock" className="form-check-label text-label-note">
                  {localize('collective_agreement')}
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </Col>
        )}
      </div>

      <div className="insurance-table-accordion editor-accordion mb-3">
        <Accordion defaultActiveKey="policyDescription" className="policy-desc-accordion">
          <Accordion.Item eventKey="policyDescription">
            <Accordion.Header>
              <h3 className="fs-h3 theme-text">{localize('PENSION_POLICY.policy_description')}</h3>
            </Accordion.Header>
            <Accordion.Body>
              <TextEditor
                onChangeHandler={(value) => handleTextEditor('policyDescription', value)}
                defaultValue={
                  preAssignedPolicy ? formGroup?.getFieldMeta<string>('policyDescription')?.value || '' : ''
                }
                field={formGroup.getFieldMeta('policyDescription')}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <PagesHeader pageTitle="Policy Settings" />
      <Tabs
        className="nav-border-bottom"
        activeKey={tabKey}
        onSelect={(k) => setTabKey(k)}
        defaultActiveKey="deal"
        id="crt-pension-policy-tab-container"
      >
        <Tab
          eventKey="deal"
          title={`1. ${localize('PENSION_POLICY.premium_calculation')}`}
          tabClassName={classNames(
            'mb-2 active-invert me-2 fs-label font-color-primary border-radius-all',
            validateTabStyle('occupationalPension'),
          )}
          disabled={hasInvalidStep(PensionPolicyTabKey.OCCUPATIONAL)}
        >
          <CompensationPlan />
        </Tab>
        <Tab
          eventKey="salary_change"
          title={`2. ${localize('salary_change')}`}
          tabClassName={classNames(
            'mb-2 active-invert me-2 fs-label font-color-primary border-radius-all',
            validateTabStyle('salaryChange'),
          )}
          disabled={hasInvalidStep(PensionPolicyTabKey.SALARY_CHANGE)}
        >
          <SalaryChangeGrid
            formGroup={formGroup}
            onInputChange={(data) => replaceSalaryChangeInput(data)}
            setSalaryChangeHandler={setSalaryChange}
          />
        </Tab>

        <Tab
          // disabled={!validateSalaryType()}
          eventKey="types"
          title={`3. ${localize('pensionable_salary')}`}
          tabClassName={classNames(
            'mb-2 active-invert me-2 fs-label font-color-primary border-radius-all',
            validateTabStyle('salaryType'),
          )}
          disabled={hasInvalidStep(PensionPolicyTabKey.SALARY_TYPE)}
        >
          <SalaryTypesTable />
        </Tab>

        <Tab
          // disabled={!isSalaryTypesSelected}
          eventKey="companies"
          title={`4. ${localize('pension_managers')}`}
          tabClassName={classNames(
            'mb-2 active-invert me-2 fs-label font-color-primary border-radius-all',
            validateTabStyle('pensionManagers'),
          )}
          disabled={hasInvalidStep(PensionPolicyTabKey.PENSION_MANAGER)}
        >
          <PernsionManagerGrid />
        </Tab>
        <Tab
          // disabled={!isSalaryTypesSelected}
          eventKey="insurances"
          title={`5. ${localize('insurances')}`}
          tabClassName={classNames('mb-2 active-invert me-2 fs-label font-color-primary border-radius-all')}
          disabled={hasInvalidStep(PensionPolicyTabKey.INSURANCE)}
        >
          <InsuranceGrid />
        </Tab>
        <Tab
          // disabled={!isSalaryTypesSelected}
          eventKey="specialConditions"
          title={`6. ${localize('PENSION_POLICY.special_conditions')}`}
          tabClassName={classNames('mb-2 active-invert me-2 fs-label font-color-primary border-radius-all')}
          disabled={hasInvalidStep(PensionPolicyTabKey.SPECIAL_CONDITIONS)}
        >
          <SpecialConditions />
        </Tab>
      </Tabs>
    </CardComponent>
  );
}

export default PensionPoliciesDetailsEditable;
