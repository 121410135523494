import { useEffect, useState } from 'react';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { TabDataType } from './EmployeeDetails';

import useContext from '../useContext';
import PensionPoliciesDetailsNonEditable from '../../../superAdmin/PensionPolicy/PensionPoliciesDetailsNonEditable';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';
import PagesHeader from '../../../../components/base/PagesHeader';
import classNames from 'classnames';
import { PensionPolicyProvider } from '../../../superAdmin/PensionPolicy/PensionPolicyContext/PensionPolicyContext';
import {
  ActionMode,
  PensionPolicy,
  PensionPolicyCategory,
  PensionPolicyMode,
} from '../../../superAdmin/PensionPolicy/types';
import useArchivedPension from '../hooks/useArchivedPension';
import { ReactComponent as CollectiveAgreementIcon } from '../../../../assets/img/collective-agreement.svg';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

function ArchivedPension({ employee, pensionDataset, insuranceDataset }: TabDataType) {
  const { t } = useTranslation();
  const { findPolicyType } = useContext();
  const { selectedArchivedPension, loadingArchivedPension } = useArchivedPension();
  const [policyType, setPolicyType] = useState<PensionPolicyCategory | null>(null);

  const selectPolicyType = (_selectedScheduledEmployeePension) => {
    if (_selectedScheduledEmployeePension.type) {
      setPolicyType(findPolicyType(_selectedScheduledEmployeePension.type));
    } else {
      setPolicyType(PensionPolicyCategory.NO_POLICY);
    }
  };

  const clearDetails = () => {
    setPolicyType(null);
  };

  useEffect(() => {
    if (selectedArchivedPension) {
      selectPolicyType(selectedArchivedPension.currentInternal);
    } else {
      clearDetails();
    }
  }, [selectedArchivedPension]);

  if (loadingArchivedPension) {
    return (
      <div className="employee-policy">
        <Spinner />
      </div>
    );
  }

  if (!selectedArchivedPension) {
    return (
      <div className="employee-policy">
        <PagesHeader pageTitle="Pension policy" />
      </div>
    );
  }

  return (
    <div className="employee-policy">
      <PagesHeader pageTitle="Pension policy" />
      <Form>
        <div className="d-flex mb-5 gap-2 pension-policy-selector flex-wrap-row">
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'companyPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'companyPolicy'}
              disabled
              inline
              value="companyPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.company_policy')}`}
              className="fs-name mb-1"
              id="companyPolicy"
            />
            <Form.Control
              disabled
              value={(policyType === 'companyPolicy' && selectedArchivedPension?.policyMetaDetails?.policyName) || ''}
            />
          </div>
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'correctoProvidedPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'correctoProvidedPolicy'}
              disabled
              inline
              value="correctoProvidedPolicy"
              name="policyType"
              type="radio"
              label={`${t('correcto_provided')}`}
              className="fs-name mb-1"
              id="correctoProvidedPolicy"
            />
            <Form.Control
              disabled
              value={
                (policyType === 'correctoProvidedPolicy' && selectedArchivedPension?.policyMetaDetails?.policyName) ||
                ''
              }
            />
          </div>
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'personalPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'personalPolicy'}
              disabled
              inline
              value="personalPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.personal_policy')}`}
              className="fs-name radio-button-label"
              id="personalPolicy"
            />

            <div>
              {policyType === 'personalPolicy' && <p>{selectedArchivedPension?.policyMetaDetails?.policyName}</p>}
            </div>
          </div>
          <Col
            style={{ height: 86, width: 240, maxWidth: 240, minWidth: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'noPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'noPolicy'}
              disabled
              inline
              value="noPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.no_policy')}`}
              className="fs-name"
              id="noPolicy"
            />

            <Form.Check
              checked={selectedArchivedPension?.current?.advisoryInvite ? true : false}
              disabled
              inline
              name="advisoryInvite"
              type="checkbox"
              label={`${t('invite_employee_to_correcto_advisory')}`}
              className="fs-name d-flex align-items-start mt-2"
              id="inviteEmployeeDisabled"
            />
          </Col>
        </div>
        <Row className="mb-4">
          <Form.Label className="mb-0 fs-name">{`${t('PENSION_POLICY.effective_from')}`}</Form.Label>
          <p className="fs-name theme-text font-weight-400 mb-0">{`(${t('PENSION_POLICY.employee_policy_effective')})`}</p>
          <Col md={2}>
            <MonthAndYearDropdown
              disabled
              startFrom={selectedArchivedPension?.current?.effectiveFrom}
              value={selectedArchivedPension?.current?.effectiveFrom!}
            />
          </Col>
        </Row>
      </Form>
      <hr />

      {selectedArchivedPension && (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <PagesHeader
              biggerHeading={true}
              pageTitle={`${selectedArchivedPension?.policyMetaDetails?.policyName}`}
              translate={false}
            />
            {!selectedArchivedPension?.policyMetaDetails?.isCollectiveAgreementLock && (
              <h6>
                <TooltipBox msg={`${t('collective_agreement')}`}>
                  <span className="partner-icon">
                    <CollectiveAgreementIcon />
                  </span>
                </TooltipBox>
              </h6>
            )}
          </div>
        </div>
      )}
      {selectedArchivedPension && (
        <PensionPolicyProvider
          defaultSelectedPolicy={selectedArchivedPension.policyMetaDetails}
          defaultSelectedPolicyMode={PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY}
          defaultPensionFormPopulated={selectedArchivedPension.currentInternal as unknown as PensionPolicy}
          defaultActionMode={ActionMode.VIEW}
          defaultExternalPolicyRevision={{ effectiveFrom: selectedArchivedPension?.current.effectiveFrom }}
        >
          <PensionPoliciesDetailsNonEditable
            policy={selectedArchivedPension.policyMetaDetails}
            containerBodyClassName="p-0"
            containerClassName="shadow-none"
            isSelectable
            effectiveFrom={selectedArchivedPension?.current.effectiveFrom || ''}
            fetchedFromExternalSource={true}
            pensionDataset={pensionDataset}
            insuranceDataset={insuranceDataset}
          />
        </PensionPolicyProvider>
      )}
    </div>
  );
}

export default ArchivedPension;
