import React from 'react';
import { Card } from 'react-bootstrap';
import PublicLogin from '../../components/auth/PublicLogin';

import { ReactComponent as Logo } from '../../assets/img/c-logo-black.svg';
import { ReactComponent as Text } from '../../assets/img/c-text-black.svg';

const PublicLoginPage = () => {
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <span className="me-2">
                <Logo className="correcto-logo" />
              </span>
              <span className="align-middle">
                <Text className="correcto-logo-text" />
              </span>
            </div>
            <PublicLogin />
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default PublicLoginPage;
