import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MoreVertical } from 'react-feather';

const IntegrationRowItem = ({ item, onRowClick }: any) => {
  const { attributes, setNodeRef, transform, transition, listeners } = useSortable({
    id: item.id!,
    attributes: {
      role: 'draggable',
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  return (
    <>
      <tr ref={setNodeRef} style={style} {...attributes} className="cursor-pointer">
        <td className="align-baseline">
          <div {...listeners} className="draggable-item">
            <MoreVertical />
          </div>
        </td>
        <td onClick={() => onRowClick(item.id)}>{item.shortName}</td>
        {/* <td onClick={() => onRowClick(item.id)}>{item.description}</td> */}
        <td onClick={() => onRowClick(item.id)}>
          <span
            className={classNames('fs-text-400', {
              'theme-text': item.status === 'Draft',
              'active-green': item.status !== 'Draft',
            })}
          >
            {item.status ?? 'Active'}
          </span>
        </td>
      </tr>
    </>
  );
};

export default IntegrationRowItem;
