import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { numberSpaceSeparator } from '../../../../util/util';
import { FormProps } from '../context/EmployeePensionContext';

function SalaryInfo({ context }: FormProps) {
  const { employee } = context;

  const { t } = useTranslation();

  return (
    <div>
      <h3 className="fs-22px theme-text mt-3 mb-2 font-weight-600"> {t('salary_info')}</h3>
      <Form>
        <div className="dark-content-section">
          <div className="cr-form-row">
            <div className="salary-field">
              <Form.Label className="fs-name">{t('salary')}</Form.Label>{' '}
              <span className="font-weight-normal">(SEK)</span>
              <Form.Control
                className="text-right"
                disabled
                // placeholder="Enter Salary"
                name="Salary"
                value={numberSpaceSeparator(employee?.salary)}
              />
            </div>

            <div className="schedule-field">
              <Form.Label className="fs-name">{t('schedule')}</Form.Label>
              <Form.Select name="Schedule" disabled value={employee?.scheduleId}>
                <option value="fullTime">{t('full_time')}</option>
                <option value="HEL">{t('hel')}</option>
              </Form.Select>
            </div>

            {/* <div style={{ width: 70 }} className="me-3">
              <Form.Label style={{ width: 100 }} className="fs-name">
                Paid vacation days
              </Form.Label>
              <Form.Control type="number" name="paidHolidays" value={employee.paidHolidays} onChange={handleChange} />
            </div> */}
          </div>
        </div>
      </Form>
    </div>
  );
}

export default SalaryInfo;
