import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { CornerDownRight, UserX, UserPlus, RefreshCw } from 'react-feather';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import PagesHeader from '../../../../components/base/PagesHeader';
import isEmpty from 'lodash/isEmpty';
import { FormProps } from '../types';
import classNames from 'classnames';
import { errorHighlighter, requiredError } from '../../../../util/form';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import Avatar from '../../../../components/avatar/Avatar';
import PersonalNumber from '../../../../components/form/inputs/PersonalNumber';
import { CompanyClientSaveMode } from '../../../superAdmin/CompanyClients/types';
import useLocale from '../../../../hooks/useLocale';
import { getRoaringPersonInfo } from '../../../../api/service/superAdmin/company-clients/service';
import { DEFAULT_PERSON_INFO } from '../../../../types/general';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

export default function AdminDetails({ context }: FormProps) {
  const {
    adminDetails,
    isEditMode,
    formGroup,
    replaceAdminDetails,
    combinations,
    signatoryAdmins,
    replaceSignatoryAdmins,
    appendDeletedAdmin,
    companyDetails,
  } = context;
  const { localize } = useLocale();
  const [selectedSignatoryCombination, setSelectedSignatoryCombination] = useState<number>();
  const isAllowedSignatoryOption =
    [CompanyClientSaveMode.SETUP, CompanyClientSaveMode.ACTIVE].indexOf(
      companyDetails?.status as CompanyClientSaveMode,
    ) !== -1;
  const [, /*isPersonalNumberValid*/ setIsPersonalNumberValid] = useState<boolean>(true);

  const handleAdminCompany = (e, _id, index) => {
    const _adminDetails = [...adminDetails];
    const foundDetail = _adminDetails.find((i) => i['_id'] === _id);
    foundDetail![e.target.name] = e.target.value;
    replaceAdminDetails(_adminDetails);
  };

  const handleRemoveSignatoryAdmin = (removedIndex) => {
    const filterSignatoryAdmins = signatoryAdmins.filter((i, index) => index !== removedIndex);

    replaceSignatoryAdmins(filterSignatoryAdmins);
  };

  const setPersonalInfo = (form, fullName) => {
    const _adminDetails = [...adminDetails];
    const foundDetail = _adminDetails.find((i) => i['_id'] === form['_id']);
    foundDetail!.fullName = fullName;
    replaceAdminDetails(_adminDetails);
    setIsPersonalNumberValid(true);
  };

  const loadDetails = async (form) => {
    try {
      setPersonalInfo(form, DEFAULT_PERSON_INFO.fullName);
      const { fullName } = await getRoaringPersonInfo(form?.personalNumber);
      setPersonalInfo(form, fullName);
      form['valid'] = true;
    } catch (e) {
      setIsPersonalNumberValid(false);
      form['valid'] = false;
    }
  };

  useEffect(() => {
    const combination = combinations.find((signatory) => signatory.signee === true);
    const selectedIndex = combinations.findIndex((signatory) => signatory.signee === true);

    if (combination) {
      setSelectedSignatoryCombination(selectedIndex! as number);
      if (
        signatoryAdmins.length === combination.authorizedSignatory.length ||
        selectedSignatoryCombination !== selectedIndex
      ) {
        if (isEditMode && signatoryAdmins.length === 0) {
          replaceSignatoryAdmins(
            combination.authorizedSignatory
              .filter((signatory) => adminDetails.find((item) => item.personalNumber === signatory.personalNumber))
              .map((item) => {
                return {
                  email: item.email,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  fullName: item.fullName,
                  personalNumber: item.personalNumber,
                  position: item.position,
                };
              }),
          );
        } else {
          replaceSignatoryAdmins(
            combination.authorizedSignatory.map((item) => {
              return {
                email: item.email,
                firstName: item.firstName,
                lastName: item.lastName,
                fullName: item.fullName,
                personalNumber: item.personalNumber,
                position: item.position,
              };
            }),
          );
        }
      } else {
        const selectedAuthorizedSignatories = combination.authorizedSignatory.filter((signatory) => {
          return signatoryAdmins.find((admin) => admin.personalNumber === signatory.personalNumber);
        });
        replaceSignatoryAdmins(
          selectedAuthorizedSignatories.map((item) => {
            return {
              email: item.email,
              firstName: item.firstName,
              lastName: item.lastName,
              fullName: item.fullName,
              personalNumber: item.personalNumber,
              position: item.position,
            };
          }),
        );
      }
    }
  }, [combinations]);

  return (
    <div>
      <PagesHeader pageTitle="Admin details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {!isAllowedSignatoryOption && signatoryAdmins.length > 0 && (
          <Form.Label className="fs-name mb-3">{localize('authorized_signatory')}</Form.Label>
        )}
        {!isAllowedSignatoryOption &&
          signatoryAdmins.map(
            (signatory, idx) =>
              signatory.fullName !== '' && (
                <div key={`signatory-admin-list-${idx}`}>
                  <Row className="align-items-center mb-3" key={idx}>
                    <Col md="3">
                      <Avatar name={signatory.fullName} displayName />
                    </Col>
                    <Col style={{ maxWidth: 170, minWidth: 170 }}>
                      <span className="theme-text fs-14px">{signatory.personalNumber}</span>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      <span className="me-2">
                        <TooltipBox msg={`${localize('delete_user')}`}>
                          <UserX
                            onClick={() => {
                              handleRemoveSignatoryAdmin(idx);

                              if (signatoryAdmins[idx]._id || signatoryAdmins[idx].id) {
                                appendDeletedAdmin(signatoryAdmins[idx]._id || signatoryAdmins[idx].id);
                              }
                            }}
                            className="cursor-pointer delete-icon"
                          />
                        </TooltipBox>
                      </span>
                    </Col>
                  </Row>
                  <HorizontalRule color="#C6C6C6" height="1px" />
                </div>
              ),
          )}
        {adminDetails.map((form, idx) => (
          <div key={form._id}>
            {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}
            <div className="cr-form-row mb-3">
              {/* <Col style={{ maxWidth: 170 }}>
                <Form.Label
                  className={classNames(
                    'fs-name mb-0',
                    requiredError(formGroup.getFieldMeta(`admins[${idx}].personalNumber`)),
                  )}
                >
                  {localize('personal_number')}{' '}
                  <span className="font-weight-400">
                    ({PERSONAL_NUMBER_DIGITS} {localize('digits')})
                  </span>
                </Form.Label>
                <Form.Control
                  value={formGroup.values.admins[idx]?.personalNumber}
                  className={classNames(
                    'text-left',
                    errorHighlighter(formGroup.getFieldMeta(`admins[${idx}].personalNumber`)),
                  )}
                  size="lg"
                  type="text"
                  onChange={(e) => handleAdminCompany(e, form._id, idx)}
                  name={`personalNumber`}
                />
                <ErrorFeedback field={formGroup.getFieldMeta(`admins[${idx}].personalNumber`)} />
              </Col> */}
              <PersonalNumber
                value={formGroup.values.admins[idx]?.personalNumber}
                isRequiredError={true}
                maxWidth={137}
                onChangeHandler={(e) => handleAdminCompany(e, form._id, idx)}
                field={formGroup.getFieldMeta(`admins[${idx}].personalNumber`)}
                isValid={form['valid']}
                lazy={true}
              />
              <Button
                disabled={isEmpty(formGroup.values.admins[idx]?.personalNumber)}
                variant="primary"
                onClick={() => loadDetails(form)}
                className="app-btn-primary"
              >
                {isEditMode ? <RefreshCw className="icon-gap-right" /> : <CornerDownRight className="icon-gap-right" />}{' '}
                {isEditMode ? localize('refresh') : localize('fetch_name')}
              </Button>
              <div className="full-name-field">
                <Form.Label
                  className={classNames(
                    'fs-name mb-0',
                    requiredError(formGroup.getFieldMeta(`admins[${idx}].fullName`)),
                  )}
                >
                  {localize('name')}
                </Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name={`fullName`}
                  value={form?.fullName}
                  disabled
                  className={classNames(
                    'text-left',
                    errorHighlighter(formGroup.getFieldMeta(`admins[${idx}].fullName`)),
                  )}
                />
                <ErrorFeedback field={formGroup.getFieldMeta(`admins[${idx}].fullName`)} />
              </div>
              <div className="email-field">
                <Form.Group>
                  <Form.Label
                    className={classNames(
                      'fs-name mb-0',
                      requiredError(formGroup.getFieldMeta(`admins[${idx}].email`)),
                    )}
                  >{`${localize('email')} ${localize('address')}`}</Form.Label>
                  <Form.Control
                    value={form?.email}
                    size="lg"
                    type="text"
                    onChange={(e) => handleAdminCompany(e, form['_id'], idx)}
                    name={`email`}
                    className={classNames(
                      'text-left',
                      errorHighlighter(formGroup.getFieldMeta(`admins[${idx}].email`)),
                    )}
                  />
                  <ErrorFeedback field={formGroup.getFieldMeta(`admins[${idx}].email`)} />
                </Form.Group>
              </div>

              <div className="last-button-box mb-2">
                <span className="me-2 mb-0">
                  <TooltipBox msg={`${localize('delete_user')}`}>
                    <UserX
                      onClick={() => {
                        const _adminDetails = [...adminDetails];
                        replaceAdminDetails(_adminDetails.filter((i) => i['_id'] !== form['_id']));
                      }}
                      className="cursor-pointer delete-icon"
                    />
                  </TooltipBox>
                </span>
              </div>
            </div>
          </div>
        ))}
        <Button
          onClick={() => {
            replaceAdminDetails([
              ...adminDetails,
              { _id: `${Math.random()}`, personalNumber: '', email: '', fullName: '' },
            ]);
          }}
          className="app-btn-secondary"
        >
          <UserPlus className="theme-text" />
        </Button>
      </div>
    </div>
  );
}
