import React, { createContext, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useFormik } from 'formik';
import {
  IInsuranceFormGroup,
  IInsurances,
  IInsurancesContext,
  TypeConnection,
  TypeContactInformation,
  TypeInsurance,
  TypeInsurances,
} from '../../types';
import {
  FORM_INITIAL_VALUES,
  INITIAL_CONTACT_INFORMATION,
  INITIAL_CONNECTION,
  INITIAL_INSURANCE_PLANS,
  INITIAL_INSURANCE_COMPANY,
} from '../constants';
import { formSchema } from './schema';
import { includeKeys, isObjectEmpty } from '../../../../../util/util';

type Props = {
  children: JSX.Element;
  patchInsuranceForm?: Function;
  emitValues: (...args) => void;
};

export const InsurancesContext = createContext<IInsurancesContext>({
  insuranceCompany: INITIAL_INSURANCE_COMPANY,
  insurances: [],
  contactInformation: INITIAL_CONTACT_INFORMATION,
  connection: INITIAL_CONNECTION,
  insurancePlans: INITIAL_INSURANCE_PLANS,
  isBusy: false,
  formGroup: {} as IInsuranceFormGroup,
  selectedInsuranceID: null,
  setSelectedInsuranceID: () => {},
  selectedInsuranceDetails: null,
  setSelectedInsuranceDetails: () => {},
  setInsuranceCompany: () => {},
  setContactInformation: () => {},
  setConnection: () => {},
  setInsurancePlans: () => {},
  replaceInsurances: () => {},
  updateContactInformation: () => {},
  replaceInsuranceCompany: () => {},
  replaceContactInformation: () => {},
  updateInsuranceCompany: () => {},
  replaceConnection: () => {},
  updateConnection: () => {},
  replaceInsurancePlan: () => {},
  updateInsurancePlan: () => {},
  updateInsuranceAgeSpan: () => {},
  replaceInsuranceDocuments: () => {},
  validateDraftForm: () => new Promise(() => ({ valid: false, errors: null })),
  validateForm: () => new Promise(() => {}),
  getRequestPayload: (_additionalProps: any) => {},
});

export const InsurancesProvider = React.forwardRef(({ children, patchInsuranceForm, emitValues }: Props, _ref: any) => {
  const [selectedInsuranceID, setSelectedInsuranceID] = useState<string | null>(null);
  const [selectedInsuranceDetails, setSelectedInsuranceDetails] = useState<any | null>(null);
  const [insuranceCompany, setInsuranceCompany] = useState<TypeInsurance>(INITIAL_INSURANCE_COMPANY);
  const [insurances, setInsurances] = useState<Array<IInsurances>>([]);
  const [contactInformation, setContactInformation] = useState<TypeContactInformation>(INITIAL_CONTACT_INFORMATION);
  const [connection, setConnection] = useState<TypeConnection>(INITIAL_CONNECTION);
  const [insurancePlans, setInsurancePlans] = useState<TypeInsurances>(INITIAL_INSURANCE_PLANS);
  const [isBusy /*setIsBusy*/] = useState(false);

  const formGroup = useFormik({
    initialValues: FORM_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema: () => formSchema,
    onSubmit: (val) => {},
  });

  const replaceInsurances = (value) => {
    setInsurances(value);
    formGroup.setFieldValue('insurances', value);
  };

  const replaceInsuranceCompany = (value) => {
    setInsuranceCompany(value);
    formGroup.setFieldValue('insuranceCompany', value);
  };

  const updateContactInformation = (inputName, inputValue) => {
    setContactInformation((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`contactInformation.${inputName}`, inputValue);
  };

  const updateInsuranceCompany = (inputName, inputValue) => {
    setInsuranceCompany((value) => ({
      ...value,
      [inputName]: inputValue,
    }));

    formGroup.setFieldValue(`insuranceCompany.${inputName}`, inputValue);
  };

  const replaceContactInformation = (value) => {
    setContactInformation(value);
    formGroup.setFieldValue('contactInformation', value);
  };

  const replaceConnection = (value) => {
    setConnection(value);
    formGroup.setFieldValue('connection', value);
  };

  const updateConnection = (inputName, inputValue) => {
    setConnection((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`connection.${inputName}`, inputValue);
  };

  const replaceInsurancePlan = (value, planName) => {
    const insurancePlan = formGroup.getFieldMeta(`insurancePlans`).value?.planName;
    let _insurancePlan = { ...insurancePlan };
    _insurancePlan = value;
    setInsurancePlans(_insurancePlan);
    formGroup.setFieldValue(`insurancePlans.${planName}`, _insurancePlan);
  };

  const updateInsurancePlan = (inputName, inputValue) => {
    formGroup.setFieldValue(`insurancePlans.${inputName}`, inputValue);
    setInsurancePlans((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
  };

  const replaceInsuranceDocuments = (value, planName, index) => {
    const insuranceDocument = formGroup.getFieldMeta(`insurancePlans.${planName}`).value;
    let _document = { ...insuranceDocument };
    _document[index].documents = value;
    formGroup.setFieldValue(`insurancePlans.${planName}.documents`, _document);
  };

  const updateInsuranceAgeSpan = (inputName, inputValue, planName, index) => {
    formGroup.setFieldValue(`insurancePlans.${planName}.${[index]}.ageSpan.${inputName}`, inputValue);
  };

  const validateDraftForm = async () => {
    formGroup.setSubmitting(false);
    formGroup.setErrors({});
    formGroup.handleReset(null);

    await formGroup.setFieldTouched('insuranceCompany.shortName', true, true);
    await formGroup.setFieldTouched('insuranceCompany.companyName', true, true);

    const draftErrors = includeKeys(formGroup.errors, ['insuranceCompany.shortName', 'insuranceCompany.companyName']);

    return isObjectEmpty(draftErrors) ? { valid: true, errors: null } : { valid: false, errors: draftErrors };
  };

  const validateForm = () => {
    formGroup.handleSubmit();

    return formGroup.validateForm();
  };

  const getRequestPayload = useCallback(
    (additionalProps = {}) => {
      const { insuranceCompany, insurancePlans, connection, ...formData } = formGroup.values;
      const data = {
        ...insuranceCompany,
        insurances: insurancePlans,
        connection: {
          ...connection,
          connectionTypeFile: Object.assign(
            {},
            {
              ...(connection?.connectionTypeFile || {}),
            },
            !connection?.connectionTypeFile?.email ? { email: undefined } : connection?.connectionTypeFile,
          ),
        },
        contactInformation: formData.contactInformation,
        ...additionalProps,
      };

      return data;
    },
    [formGroup.values],
  );

  const context: IInsurancesContext = {
    insuranceCompany,
    setInsuranceCompany,
    insurances,
    contactInformation,
    setContactInformation,
    connection,
    setConnection,
    insurancePlans,
    setInsurancePlans,
    isBusy,
    formGroup: formGroup as any,
    selectedInsuranceDetails,
    setSelectedInsuranceDetails,
    selectedInsuranceID,
    setSelectedInsuranceID,
    replaceInsurances,
    updateInsuranceCompany,
    updateContactInformation,
    updateConnection,
    updateInsurancePlan,
    replaceInsuranceCompany,
    replaceContactInformation,
    replaceConnection,
    replaceInsurancePlan,
    replaceInsuranceDocuments,
    updateInsuranceAgeSpan,
    validateDraftForm,
    validateForm,
    getRequestPayload,
  };

  useImperativeHandle(_ref, () => {
    return {
      formGroup: formGroup,
      selectedInsuranceID,
      validateDraftForm,
    };
  }, [formGroup.values, validateDraftForm]);

  useEffect(() => {
    patchInsuranceForm!(formGroup);
  }, [formGroup.values, formGroup.errors]);

  useEffect(() => {
    emitValues({
      formGroup,
      selectedInsuranceID,
      getRequestPayload,
    });
  }, [formGroup.values, selectedInsuranceID, getRequestPayload]);

  return <InsurancesContext.Provider value={context}>{children}</InsurancesContext.Provider>;
});

InsurancesProvider.displayName = 'InsurancesProvider';
