import { Outlet } from 'react-router-dom';

import { LayoutProvider } from '../contexts/LayoutContext';
import { SidebarProvider } from '../contexts/SidebarContext';

const Adminlayout = ({ children }: React.PropsWithChildren) => {
  return (
    <>
      <SidebarProvider>
        <LayoutProvider>
          {children}
          <Outlet />
        </LayoutProvider>
      </SidebarProvider>
    </>
  );
};

export default Adminlayout;
