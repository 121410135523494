import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccessKey } from '../../../../assets/img/access-key.svg';
import { useAccountingFirmRoleSwitcher } from '../hooks/useAccountingFirmRoleSwitcher';
import { useContext } from 'react';
import { OverlayLoaderContext } from '../../../../contexts/OverlayLoaderContext';
import { AccountingFirmClient } from '../../../../api/service/accountingFirm/clients/types';
import { ClientSaveMode } from '../types';
import useAccountingFirmAccess from '../../common/hooks/useAccountingFirmAccess';

export type Props = {
  firmDetails: AccountingFirmClient;
};

const AccessButton = ({ firmDetails }: Props) => {
  const { t } = useTranslation();
  const { canAccessByConsultant } = useAccountingFirmAccess();
  const { switchToCompanyAdmin } = useAccountingFirmRoleSwitcher();
  const { setBusy } = useContext(OverlayLoaderContext);
  const switchCompanyLogin = async () => {
    setBusy(true);
    switchToCompanyAdmin({ setBusy, details: firmDetails });
  };

  if (firmDetails?.status !== ClientSaveMode.ACTIVE) {
    return <></>;
  }

  if (!canAccessByConsultant(firmDetails?.consultants)) {
    return <></>;
  }

  return (
    <Button className="app-btn-primary me-2" onClick={() => switchCompanyLogin()}>
      <AccessKey className="icon-gap-right access-icon access-icon--white" />
      {t('access')}
    </Button>
  );
};

export default AccessButton;
