import { useEffect, useRef, useState } from 'react';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { Search } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { applySettings, getCompanyEmployees } from '../../../api/service/company/employees/service';

import Breadcrumb from '../../../components/base/Breadcrumb';
import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import BSRemoteTable from '../../../components/table/BSRemoteTable';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import Pagination from '../Pagination';
import { useColumn } from '../useColumn';
import { getPolicies } from '../../../api/service/superAdmin/pension-policies/service';
import moment from 'moment';
import { ConfirmationDialog } from '../../../util/dialogs';
import classNames from 'classnames';
import MonthAndYearDropdown from '../../../section/MonthAndYearDropdown';
import { isEmptyVal, toNumber } from '../../../util/util';
import { ReactComponent as ClearFilter } from '../../../assets/img/Clear_filter.svg';
import { Calendar } from '../../../util/calendar';
import ErrorAlert from '../../../components/ErrorAlert';

function EmployeesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [settingEmployeeType, setSettingEmployeeType] = useState<string>('');
  const [settingPensionPolicy, setSettingPensionPolicy] = useState<any>({ id: '', type: '' });
  const [settingPaidVacation, setSettingPaidVacation] = useState<number | string>('');
  const [settingEffectiveFrom, setSettingEffectiveFrom] = useState<string>('');
  const [effectiveDateOptionStartFrom, setEffectiveDateOptionStartFrom] = useState<string | null>(
    Calendar.nextMonthStartDate,
  );
  const [employeeData, setEmployeeData] = useState({ data: [] });
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const [errorCount, setErrorCount] = useState(0);
  const [policies, setPolicies] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [filterOption, setFilterOption] = useState('');
  const [showClearFilter, setShowClearFilter] = useState(false);
  const [isInactive, setIsInactive] = useState<boolean>(false);
  const tableCtxRef = useRef<any>(null);

  const pageTitle = `${t('employees')} (${Calendar.parseCurrentDate('MMM YYYY')})`;
  const [employeeMetaData, setEmployeeMetaData] = useState({
    page: { size: 25, current: 1 },
    total: 0,
  });
  const totalPages = Math.ceil(employeeMetaData.total / employeeMetaData.page.size);
  const searchRef = useRef<any>('');
  const personnelTypeRef = useRef<any>('');
  const [selectedFilter, setSelectedFilter] = useState<any>({
    fullName: { like: '' },
    personnelType: { like: '' },
    nonValid: { exist: null },
    isActive: false,
  });
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Employees',
    },
  ];
  const noEmployeeSelected = selectedEmployees.length === 0;

  const { employeeColumn } = useColumn();
  const getEmployees = async ({
    filter = selectedFilter,
    page = employeeMetaData.page.current,
    pageSize = employeeMetaData.page.size,
    sortField = 'fullName',
    sortOrder = '1',
    sort = {
      [sortField]: sortOrder,
    },
  }) => {
    try {
      const employees = await getCompanyEmployees({
        filter,
        page,
        pageSize,
        sort,
      });

      const mappedData = filter.nonValid.exist
        ? employees['data'].map((i) => ({
            ...i,
            invalid: true,
          }))
        : employees['data'];
      setEmployeeData({ data: mappedData });
      setEmployeeMetaData(employees['metaData']);
      setErrorCount(employees?.nonValidCount || 0);
    } catch (e) {
      console.error(e);
    }
  };

  const getAndSetPensionPolicies = async ({ employeeType = '' }) => {
    if (employeeType === 'all') {
      setPolicies([{ _id: 'noPolicy', policyName: 'No policy', type: 'noPolicy' }]);
    } else {
      const policies: any = await getPolicies({ type: 'CompanyWithCorrectoProvided', employeeType });
      setPolicies(policies);
    }
  };

  const filterEmployeeMap = {
    fullName: () => {
      const searchTerm = searchRef.current?.value;
      setSelectedFilter((prev) => ({ ...prev, fullName: { like: searchTerm } }));
    },
    personnelType: () => {
      const personnelTypeValue = personnelTypeRef.current?.value;

      setSelectedFilter((prev) => ({ ...prev, personnelType: { like: personnelTypeValue } }));
      personnelTypeValue !== '' ? setShowClearFilter(true) : setShowClearFilter(false);
    },
    nonValid: () => {
      setShowClearFilter(true);
      setSelectedFilter((prev) => ({ ...prev, nonValid: { exist: true } }));
    },
  };

  const onFilterChange = (key) => {
    setFilterOption(key);
    filterEmployeeMap[key]();
  };

  const onPagination = async ({ page, sizePerPage }) => {
    await getEmployees({ page, pageSize: sizePerPage });
  };

  const onEmployeeTypeChange = (e) => {
    const employeeType = e.target.value;
    setSettingEmployeeType(employeeType);
    getAndSetPensionPolicies({ employeeType });
  };

  const getEmployeeTypeFromSelectedEmployees = (employees) => {
    const types: Array<string> = employees.map((i) => i['personnelType']);
    const isBlueCollarEmployees = types.every((i) => i === 'Blue Collar' || i === 'BlueCollar');
    const isWhiteCollarEmployees = types.every((i) => i === 'White Collar' || i === 'WhiteCollar');
    if (isBlueCollarEmployees) {
      setSettingEmployeeType('BlueCollar');
      getAndSetPensionPolicies({ employeeType: 'BlueCollar' });
    } else if (isWhiteCollarEmployees) {
      setSettingEmployeeType('WhiteCollar');
      getAndSetPensionPolicies({ employeeType: 'WhiteCollar' });
    } else {
      setSettingEmployeeType('');
      getAndSetPensionPolicies({ employeeType: 'all' });
    }
  };

  const isDisabledEffectiveFrom = () => {
    if (noEmployeeSelected) {
      return true;
    }

    if (settingPensionPolicy['id']) {
      return false;
    }

    if (!isEmptyVal(settingPaidVacation)) {
      return false;
    }

    if (settingEmployeeType) {
      return false;
    }

    return true;
  };

  const isDisabledSaveSettings = () => {
    if (noEmployeeSelected || !settingEffectiveFrom) {
      return true;
    }

    return false;
  };

  const onEmployeeSelect = (row, isSelected) => {
    if (isSelected) {
      const _selectedEmployees = [...selectedEmployees, row];
      getEmployeeTypeFromSelectedEmployees(_selectedEmployees);
      setSelectedEmployees(_selectedEmployees);
      setSettingPensionPolicy({ id: '', type: '' });
    } else {
      const _selectedEmployees = selectedEmployees.filter((i) => i['id'] !== row['id']);
      setSelectedEmployees(_selectedEmployees);
      getEmployeeTypeFromSelectedEmployees(_selectedEmployees);
      setSettingPensionPolicy({ id: '', type: '' });
    }
  };

  const onEmployeeSelectAll = (isSelect, rows) => {
    if (isSelect) {
      setSelectedEmployees(rows);
    } else {
      setSelectedEmployees([]);
    }
  };

  const onPensionPolicySelect = (e) => {
    setSettingEffectiveFrom('');

    const policyId = e.target.value;
    const policy = policies.find((i) => i['_id'] === policyId);
    if (policy) {
      setSettingPensionPolicy({ id: policyId, type: policy['type'] });
    }

    setEffectiveDateOptionStartFrom(Calendar.selectEffectiveDate(policy?.effectiveFrom || null));
  };

  const clearFilter = async () => {
    setShowClearFilter(false);
    setSettingEmployeeType('');
    setSettingPaidVacation('');
    setSettingEffectiveFrom('');
    setSettingPensionPolicy({ id: '', type: '' });
    setSelectedEmployees([]);
    setFilterOption('');
    setSelectedFilter({
      personnelType: { like: '' },
      fullName: { like: '' },
      nonValid: { exist: null },
      isActive: false,
    });

    personnelTypeRef.current.value = '';
    searchRef.current.value = '';

    try {
      setIsLoading(true);
      await getEmployees({});
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }

    // setEmployeeData({ data: [...employeeData.data] });
  };
  const handleSearch = (e) => {
    e.target.value !== '' ? setShowClearFilter(true) : setShowClearFilter(false);

    if (e.key === 'Enter') {
      onFilterChange('fullName');
    }
  };
  const onApplySettings = async () => {
    const title = 'Are you sure you want to change settings to several employees?';
    const result = await ConfirmationDialog({
      title: title,
      confirmButtonText: t('yes'),
      denyButtonText: t('no'),
    });

    if (result.isConfirmed) {
      try {
        setIsBusy(true);
        const payload = Object.assign(
          {
            employeeIds: selectedEmployees.map((i) => i['id']),
            effectiveFrom: settingEffectiveFrom,
          },
          settingEmployeeType
            ? {
                personnelType: settingEmployeeType,
              }
            : null,
          settingPensionPolicy?.id
            ? {
                policy: {
                  id: settingPensionPolicy?.id,
                  type: settingPensionPolicy?.type,
                  attachedAt: moment().toISOString(),
                  effectiveFrom: settingEffectiveFrom,
                },
              }
            : null,
          settingPaidVacation
            ? {
                vacation: { paidHolidays: settingPaidVacation, effectiveFrom: settingEffectiveFrom },
              }
            : null,
        );
        await applySettings(payload);
        getEmployees({});
        setSettingEmployeeType('');
        setSettingPaidVacation(0);
        setSettingEffectiveFrom('');
        setSettingPensionPolicy({ id: '', type: '' });
      } finally {
        setIsBusy(false);
      }
    }
  };
  const handleInactiveEmployees = (e) => {
    setIsInactive(e.target.checked);
    setSelectedFilter((prev) => ({ ...prev, isActive: e.target.checked }));
  };

  useEffect(() => {
    setIsLoading(true);
    // getEmployees({});
    getAndSetPensionPolicies({});
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (settingPensionPolicy['id'] !== '' || !isEmptyVal(settingPaidVacation) || settingEmployeeType !== '') {
      setShowClearFilter(true);
    }
    if (noEmployeeSelected) {
      setShowClearFilter(false);
    }
  }, [settingPensionPolicy, settingPaidVacation, settingEmployeeType, noEmployeeSelected]);

  useEffect(() => {
    getEmployees({ filter: selectedFilter });
  }, [selectedFilter]);

  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader headingClass="fs-h1" pageTitle={pageTitle} translate={false} />
      <ErrorAlert show={errorCount > 0} msg={t('employee_list_error', { count: errorCount })!} />

      <CardComponent>
        <Row className="justify-content-between align-items-end">
          <div className="cr-form-row w-auto mb-0">
            <div className="search-field">
              <div className="input-group">
                <input
                  ref={searchRef}
                  onKeyUp={(e) => handleSearch(e)}
                  placeholder={`${t('search')} ${t('employee')}...`}
                  className="form-control"
                  style={{ borderRight: 'none' }}
                />
                <span className="input-group-append bg-white border-left-0">
                  <span className="input-group-text ">
                    <Search
                      className="cursor-pointer"
                      onClick={() => onFilterChange('fullName')}
                      width={16}
                      height={30}
                    />
                  </span>
                </span>
              </div>
            </div>
            <div className="employment-type-field">
              <Form.Label className="fs-name mb-0">{`${t('employment_type')}`}</Form.Label>
              <Form.Select ref={personnelTypeRef} onChange={() => onFilterChange('personnelType')}>
                <option value="">{t('all')}</option>
                <option value="BlueCollar">{t('blue_collar')}</option>
                <option value="WhiteCollar">{t('white_collar')}</option>
              </Form.Select>
            </div>
            <div>
              <Button
                onClick={() => onFilterChange('nonValid')}
                className={classNames('app-btn-white', filterOption === 'nonValid' ? 'selected-button' : '')}
              >
                {t('non_validating')}
              </Button>
            </div>
            <div>
              {showClearFilter && (
                <Button onClick={clearFilter} className={classNames('app-btn-white')}>
                  <ClearFilter className="image-filter" />
                </Button>
              )}
            </div>
            <Form.Check
              label={t('show_inactive_employees')}
              inline
              type="checkbox"
              id={`inactiveEmployees`}
              className="fs-name mb-3"
              onChange={(e) => handleInactiveEmployees(e)}
              checked={isInactive}
            />
          </div>

          {isLoading ? (
            <Spinner />
          ) : (
            <BSRemoteTable
              ref={tableCtxRef}
              data={employeeData.data}
              columns={employeeColumn}
              noDataIndication="No Employees found"
              tableClass="company-employee-table"
              sizePerPage={employeeMetaData.page.size}
              onSizePerPageChange={(sizePerPage, page) => onPagination({ sizePerPage, page })}
              keyField="id"
              onRowClick={(row) => {
                navigate(row.id);
              }}
              onTableChange={(type, { sortOrder, sortField }) => {
                if (type === 'sort') {
                  getEmployees({ sortField, sortOrder: sortOrder === 'asc' ? '1' : '-1' });
                }
              }}
              rowClasses={(row) => {
                if (row['invalid']) return 'invalid-row';
                return '';
              }}
              selectRow={{
                mode: 'checkbox',
                // selected,
                // bgColor: '#FFE9E9',
                onSelect: onEmployeeSelect,
                onSelectAll: onEmployeeSelectAll,
                selectionRenderer: ({ mode, ...rest }) => {
                  let { checked, disabled } = rest;
                  //  const selectedRow = employeeData.data[rowIndex] as any;
                  // const isChecked = selectedEmployees.find((item) => item.id === (selectedRow.id as any))
                  //   ? true
                  //   : false;
                  return (
                    <div className="form-check">
                      <Form.Check checked={checked} disabled={disabled} onChange={() => {}} />
                    </div>
                  );
                },
                selectionHeaderRenderer: ({ mode, ...rest }) => (
                  <div className="form-check">
                    <Form.Check {...rest} onChange={() => {}} />
                  </div>
                ),
              }}
            >
              <div
                style={{
                  paddingRight: 'calc(var(--bs-gutter-x) * 0.5)',
                  paddingLeft: 'calc(var(--bs-gutter-x) * 0.5)',
                }}
              >
                <p className="mt-4 mb-2 fs-text-400 p-0 text-apply">{t('apply_settings_to_selected_employee')}</p>
                <div className="cr-form-row mb-2 dark-content-section">
                  <div className="employment-type-field">
                    <Form.Label className="fs-name mb-0">{`${t('employment_type')}`}</Form.Label>
                    <Form.Select
                      value={settingEmployeeType}
                      disabled={noEmployeeSelected}
                      onChange={onEmployeeTypeChange}
                    >
                      <option value="all">{t('all')}</option>
                      <option value="BlueCollar">{t('blue_collar')}</option>
                      <option value="WhiteCollar">{t('white_collar')}</option>
                    </Form.Select>
                  </div>
                  <div className="pension-policy-select">
                    <Form.Label className="fs-name mb-0">{`${t('PENSION_POLICY.pension_policy')}`}</Form.Label>
                    <Form.Select
                      disabled={noEmployeeSelected}
                      onChange={onPensionPolicySelect}
                      value={settingPensionPolicy['id']}
                    >
                      <option value="" disabled>
                        {t('please_select_policy')}
                      </option>
                      {policies.map((policy) => {
                        return (
                          <option value={policy['_id']} key={policy['_id']}>
                            {policy['policyName']}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  <div className="paid-vacation-field">
                    <Form.Label className="fs-name mb-0">{t('paid_vac_days')}</Form.Label>
                    <Form.Control
                      value="{settingPaidVacation}"
                      onChange={(e) => setSettingPaidVacation(toNumber(e.target.value))}
                      disabled={noEmployeeSelected}
                      type="number"
                    />
                  </div>

                  <div className="effect-from-field">
                    <Form.Label className="fs-name mb-0">{`${t('effective_from')}`}</Form.Label>
                    <MonthAndYearDropdown
                      startFrom={effectiveDateOptionStartFrom}
                      value={settingEffectiveFrom}
                      onOptionSelect={(val) => {
                        setSettingEffectiveFrom(val);
                      }}
                      disabled={isDisabledEffectiveFrom()}
                    />
                  </div>
                  <div>
                    <Button onClick={onApplySettings} disabled={isDisabledSaveSettings()} className="app-btn-primary">
                      {isBusy && <Spinner className="icon-gap-right" size="sm" />} {t('apply_settings')}
                    </Button>
                  </div>
                </div>
              </div>
            </BSRemoteTable>
          )}
        </Row>
        {totalPages > 1 && (
          <Pagination
            total={employeeMetaData.total}
            itemsPerPage={employeeMetaData.page.size}
            currentPage={employeeMetaData.page.current}
            onPageChange={(page) => onPagination({ page, sizePerPage: employeeMetaData.page.size })}
          />
        )}
      </CardComponent>
    </div>
  );
}

export default EmployeesPage;
