import { EMPLOYEE_SALARY_CHANGE_MAPPED_INPUT } from '../../../../pages/superAdmin/PensionPolicy/constants';
import {
  EmployeePensionSummary,
  EmployeeSalaryChangeInputType,
} from '../../../../pages/superAdmin/PensionPolicy/types';
import {
  API_VERSION,
  CANCEL_EMPLOYEE_VACCATION_SCHEDULE_ENDPOINT,
  COMPANY_EMPLOYEE_ENDPOINT,
  COMPANY_EMPLOYEE_PENSION_ENDPOINT,
} from '../../../../util/constants';
import { HttpQuery } from '../../../../util/http-query';

import { get, post, put } from '../../../../util/httpService';
import { CompanyEmployeeType } from './types';

const getEmployeesFilterParams = (param) => {
  const filterValue = {
    fullName: {
      like: '',
    },
    personnelType: {
      like: '',
    },
    nonValid: {
      exist: null,
    },
    isActive: false
  };
  const defaultParam = {
    page: 1,
    pageSize: '2',
    sort: {
      fullName: '1',
    },
    filter: filterValue,
  };
  return Object.assign({}, defaultParam, param);
};
const getCompanyEmployees = async (filter) => {
  let endpoint = HttpQuery.serialize(getEmployeesFilterParams(filter), `${API_VERSION}${COMPANY_EMPLOYEE_ENDPOINT}`);
  const employeeData = await get(endpoint);
  return employeeData;
};

const getEmloyeeDetails = async (id) => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_ENDPOINT}/${id}`;
  const employee: CompanyEmployeeType = await get(endpoint);

  return employee;
};

const cancelEmployeeVaccationSchedule = async (id) => {
  const endpoint = `${API_VERSION}${CANCEL_EMPLOYEE_VACCATION_SCHEDULE_ENDPOINT}/${id}`;
  const employee: CompanyEmployeeType = await put(endpoint, {}, {});

  return employee;
};

const getEmployeePension = async (employeeId) => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_PENSION_ENDPOINT}?employeeId=${employeeId}`;
  const pension = (await get(endpoint)) as EmployeePensionSummary;

  return pension;
};

const cancelEmployeeScheduledPension = async (employeeId) => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_PENSION_ENDPOINT}/${employeeId}/cancel-schedule`;
  const pension = (await put(endpoint, {}, {})) as EmployeePensionSummary;

  return pension;
};

const getLocalEmployeePension = async (employeeId) => {
  const pension = {
    _id: '6584e69d9df3b958525d1c5a',
    employeeId: '642171eceab6b0fc47513c87',
    effectiveFrom: '2023-12-01',
    currentPolicy: {
      id: '657f5298b05b85e33fc06b70',
      type: 'Company',
      attachedAt: '2023-12-22T01:25:17.227Z',
    },
    pensionManagers: [
      {
        pensionManagerId: '655bb6e133356961728384e8',
        planName: 'Zenaida Wheeler',
        fromOccupationalPension: {
          isDefault: true,
          customerNumber: '11',
          insuranceNumber: '22',
        },
      },
      {
        pensionManagerId: '655bb6e133356961728384e8',
        planName: 'Flavia Warren',
        fromSalaryChange: {
          isDefault: true,
          customerNumber: '33',
          insuranceNumber: '44',
        },
      },
    ],
    insurances: [
      {
        insuranceId: '656d16fc33b069621ca39195',
        plans: [
          {
            planName: 'AA',
            categoryName: 'healthInsurancePlan',
            isChecked: true,
          },
          {
            planName: 'Aliquam dapibu',
            categoryName: 'otherInsurancesPlan',
            isChecked: true,
          },
        ],
      },
      {
        insuranceId: '656d1efe33b069621ca39267',
        plans: [
          {
            planName: 'Kylee Bush',
            categoryName: 'occupationalPensionInsurancePlan',
            isChecked: true,
          },
          {
            planName: 'Jolie Carter',
            categoryName: 'individualInsurancesPlan',
            isChecked: true,
          },
        ],
      },
    ],
    salaryChange: {
      fixedOffset: {
        current: {
          offset: 0,
          effectiveFrom: '2023-12-01',
          updatedAt: '2023-12-22T01:30:05.223Z',
        },
        next: {
          offset: 2.25,
          effectiveFrom: '2024-01-01',
          updatedAt: '2023-12-22T01:30:05.223Z',
        },
        history: [],
      },
      percentageOfSalaryOffset: {
        current: {
          offset: 0,
          effectiveFrom: '2023-12-01',
          updatedAt: '2023-12-22T01:30:05.223Z',
        },
        next: {
          offset: 2.3,
          effectiveFrom: '2024-02-01',
          updatedAt: '2023-12-22T01:30:05.223Z',
        },
        history: [],
      },
      oneTimeOffset: [
        {
          offset: 2.4,
          effectiveFrom: '2024-03-01',
          updatedAt: '2023-12-22T01:30:05.223Z',
        },
      ],
    },
    createdAt: {
      $date: '2023-12-22T01:30:05.263Z',
    },
    updatedAt: {
      $date: '2023-12-22T01:30:05.263Z',
    },
    __v: 0,
  };

  let policySettings: any = null;

  if (pension && pension['currentPolicy']) {
    policySettings = {
      pensionManagers: pension.pensionManagers,
      insurances: pension.insurances,
      salaryChange: EMPLOYEE_SALARY_CHANGE_MAPPED_INPUT.reduce((prev, cur) => {
        if (pension.salaryChange[cur.key]) {
          if (cur.key === EmployeeSalaryChangeInputType.MANUAL_OFFSET) {
            prev[cur.mapTo] = pension.salaryChange[cur.key];
          } else {
            prev[cur.mapTo] = pension.salaryChange[cur.key].next;
          }
        }

        return prev;
      }, {}),
    };
  }

  if (pension) {
    const model = {
      ...pension,
      policySettings,
    };

    return model;
  }

  return pension;
};

const updateEmployeePension = async (employeeId, payload) => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_PENSION_ENDPOINT}/${employeeId}`;
  const pension = await put(endpoint, payload, {});
  return pension;
};

const saveEmployeePension = async (payload) => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_PENSION_ENDPOINT}`;
  const pension = await post(endpoint, payload, {});
  return pension;
};

const updateSalaryCorrections = async (id, payload) => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_PENSION_ENDPOINT}/${id}/make-corrections`;
  const corrections = await put(endpoint, payload, {});
  return corrections;
};

const updateEmployee = async (id, payload) => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_ENDPOINT}/${id}`;
  const employee = await put(endpoint, payload, {});

  return employee;
};

const applySettings = async (payload) => {
  const endpoint = `${API_VERSION}${COMPANY_EMPLOYEE_ENDPOINT}/settings`;
  const employee = await put(endpoint, payload, {});

  return employee;
};

const getPensionOffset = async (employeeID, params) => {
  const endpoint = HttpQuery.serialize(
    params,
    `${API_VERSION}/pension-offset/pension-offset-and-salary-change/${employeeID}`,
  );
  const res = await get(endpoint);
  return res || [];
};

const getArchivevdEmployeePensions = async (employeeID) => {
  const endpoint = HttpQuery.serialize({}, `${API_VERSION}/employee-pension/${employeeID}/archived/`);
  const res = await get(endpoint);
  return res || [];
};

const getArchivevdPensionByID = async (employeePensionID) => {
  const endpoint = HttpQuery.serialize({}, `${API_VERSION}/employee-pension/archived/${employeePensionID}`);
  const res = await get(endpoint);
  return res || [];
};

export {
  getCompanyEmployees,
  getEmloyeeDetails,
  getEmployeePension,
  saveEmployeePension,
  updateEmployeePension,
  updateSalaryCorrections,
  updateEmployee,
  applySettings,
  getLocalEmployeePension,
  getArchivevdPensionByID,
  getPensionOffset,
  getArchivevdEmployeePensions,
  cancelEmployeeScheduledPension,
  cancelEmployeeVaccationSchedule,
};
