// import { data, employeeReportData } from '../../../../pages/company/Reports/Customizable/data';
import {
  ComparisonSeriesReportType,
  EmployeeBenefitReportType,
  ICompanyEmployeesFilterParams,
  IEmployeeByReportTypeResponse,
  IEmployeeSalaryReportResponse,
  IEmployeeeBenefitFilterParams,
  IReportFilterParams,
  ReportSlugName,
} from '../../../../types/report';
import {
  API_VERSION,
  COMPANY_EMPLOYEE_ENDPOINT,
  EMPLOYEE_BENEFIT_REPORT_ENDPOINT,
  REPORTS_CALCULATED_SALARY_CHANGE,
  REPORTS_CALCULATED_SALARY_CHANGE_EXPORT,
  REPORTS_CUSTOMIZABLE_EXPORT,
  REPORTS_HOLIDAYS_CHANGE_ENDPOINT,
  REPORTS_TAXES,
  SALARY_EXPORT_REPORTS_ENDPOINT,
  SALARY_REPORTS_ENDPOINT,
  SALARY_TYPE_EXPORT_ENDPOINT,
} from '../../../../util/constants';
import { HttpQuery } from '../../../../util/http-query';
import { get, getRawHttp } from '../../../../util/httpService';

const getFilterParams = (param: IReportFilterParams): IReportFilterParams => {
  const defaultParam: IReportFilterParams = {
    type: ComparisonSeriesReportType.GRAPH,
  };

  return Object.assign({}, defaultParam, param);
};
const getEmployeesFilterParams = (param: ICompanyEmployeesFilterParams): ICompanyEmployeesFilterParams => {
  const filterValue = {
    fullName: {
      like: '',
    },
    personnelType: {
      like: '',
    },
  };
  const defaultParam = {
    page: 1,
    pageSize: '2',
    sort: {
      fullName: '1',
    },
    filter: filterValue,
  };
  return Object.assign({}, defaultParam, param);
};
const getCompanyEmployees = async (filter: ICompanyEmployeesFilterParams) => {
  let endpoint = HttpQuery.serialize(getEmployeesFilterParams(filter), `${API_VERSION}${COMPANY_EMPLOYEE_ENDPOINT}`);
  const employeeData = await get(endpoint);
  return employeeData;
};
const getCompanyBaseSalaryReport = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${SALARY_REPORTS_ENDPOINT}/base-salaries`);

  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;
  return chartData;
};
const getCompanyRealSalaryReports = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${SALARY_REPORTS_ENDPOINT}/real-salaries`);

  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;

  return chartData;
};
const getCompanyNetSalaryReports = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${SALARY_REPORTS_ENDPOINT}/net-salaries`);

  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;

  return chartData;
};
const getCompanyHolidayPayLiabilityReports = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${REPORTS_HOLIDAYS_CHANGE_ENDPOINT}`);

  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;

  return chartData;
};
const getCompanySpecialPayrollTaxReports = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${REPORTS_TAXES}/special-payroll-tax`);

  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;

  return chartData;
};
const getCompanySocialTaxReports = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${REPORTS_TAXES}/social-tax`);

  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;

  return chartData;
};
const getCompanyCalculatedAndSalaryChangeReports = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${REPORTS_CALCULATED_SALARY_CHANGE}`);

  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;

  return chartData;
};
const getCompanySalaryTypeReport = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}/reports/salary-types`);

  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;

  return chartData;
};

const getSalaryTypeEmployeeDetail = async (filter) => {
  let endpoint = HttpQuery.serialize(
    getFilterParams(filter),
    `${API_VERSION}/reports/salary-types/${filter.companyId}/salary-transactions/${filter.date}`,
  );

  const chartData = await get(endpoint);

  return chartData;
};

const getCustomizableReport = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}/reports/customizable`);
  const chartData = (await get(endpoint)) as IEmployeeSalaryReportResponse;
  return chartData;
  // return data;
};

const getEmployeeStatsByReportType = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}/reports/customizable/employee`);
  const chartData = (await get(endpoint)) as IEmployeeByReportTypeResponse;
  return chartData;
  // return employeeReportData;
};

const getYearlyEmployeeStatsByReportType = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}/reports/customizable`);

  const chartData = (await get(endpoint)) as IEmployeeByReportTypeResponse;

  return chartData;
};

const exportChartReport = async (filter: IReportFilterParams, reportName: ReportSlugName, url?: string) => {
  let endpoint = HttpQuery.serialize(
    getFilterParams(filter),
    `${API_VERSION}${url ? url : `${SALARY_EXPORT_REPORTS_ENDPOINT}/${reportName}`}`,
  );

  const reportData = await getRawHttp(endpoint, {}, { responseType: 'blob' });
  return reportData.data;
};

const exportSalaryTypeReport = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${SALARY_TYPE_EXPORT_ENDPOINT}`);

  const reportData = await getRawHttp(endpoint, {}, { responseType: 'blob' });
  return reportData.data;
};

const exportCalculatedAndSalaryChangeReport = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(
    getFilterParams(filter),
    `${API_VERSION}${REPORTS_CALCULATED_SALARY_CHANGE_EXPORT}`,
  );

  const reportData = await getRawHttp(endpoint, {}, { responseType: 'blob' });
  return reportData.data;
};

const exportCustomizableReport = async (filter: IReportFilterParams) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${REPORTS_CUSTOMIZABLE_EXPORT}`);

  const reportData = await getRawHttp(endpoint, {}, { responseType: 'blob' });
  return reportData.data;
};

const getEmployeeBenefitReport = async (
  reportType: EmployeeBenefitReportType,
  filter: IEmployeeeBenefitFilterParams,
) => {
  let endpoint = HttpQuery.serialize(
    getFilterParams(filter),
    `${API_VERSION}${EMPLOYEE_BENEFIT_REPORT_ENDPOINT}/${reportType}`,
  );

  const reportData = await get(endpoint);
  return reportData;
};

const exportEmployeeBenfitReport = async (filter: IReportFilterParams, reportType: EmployeeBenefitReportType) => {
  let endpoint = HttpQuery.serialize(getFilterParams(filter), `${API_VERSION}${EMPLOYEE_BENEFIT_REPORT_ENDPOINT}/export/${reportType}`);
  const reportData = await getRawHttp(endpoint, {}, { responseType: 'blob' });
  return reportData.data;
};

export {
  getCompanyEmployees,
  getEmployeeBenefitReport,
  getCompanyBaseSalaryReport,
  getCompanyRealSalaryReports,
  getCompanyNetSalaryReports,
  getCompanyHolidayPayLiabilityReports,
  getCompanySpecialPayrollTaxReports,
  getCompanySocialTaxReports,
  getCompanyCalculatedAndSalaryChangeReports,
  getCompanySalaryTypeReport,
  getSalaryTypeEmployeeDetail,
  getCustomizableReport,
  getEmployeeStatsByReportType,
  getYearlyEmployeeStatsByReportType,
  exportChartReport,
  exportCalculatedAndSalaryChangeReport,
  exportCustomizableReport,
  exportSalaryTypeReport,
  exportEmployeeBenfitReport
};
