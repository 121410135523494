import { createContext, useState } from 'react';

import { useFormik } from 'formik';
import { formSchema } from './schema';
import { INITIAL_ADDRESS_DETAILS, INITIAL_COMPANY_DETAILS } from '../constants';
import {
  AddressDetails,
  AuthorizedSignatory,
  AuthorizedSignatoryGroup,
  CompanyClientFormGroup,
  CompanyDetails,
  ICompanyClientContext,
} from '../types';
import { Consultants } from '../../../Consultant/types';

export const EditAccountingFirmGeneralSettingsContext = createContext<ICompanyClientContext>({
  companyDetails: INITIAL_COMPANY_DETAILS,
  setCompanyDetails: () => {},

  signatoryDetails: [],
  setSignatoryDetails: () => {},

  addressDetails: INITIAL_ADDRESS_DETAILS,
  setAddressDetails: () => {},

  contractEffectiveFrom: '',
  setContractEffectiveFrom: () => {},

  accessConsultants: [],
  setAccessConsultants: () => {},

  invoiceCostCenter: '',
  setInvoiceCostCenter: () => {},

  contractEmail: '',
  setContractEmail: () => {},

  combinations: [],
  setCombinations: () => {},

  deletedAdmins: [],
  setDeletedAdmins: () => {},

  deletedConsultants: [],
  setDeletedConsultants: () => {},

  signatoryAdmins: [],
  setSignatoryAdmins: () => {},

  allowCorrectoRemoteAccess: false,
  setAllowCorrectoRemoteAccess: () => {},

  allowAdvisorRemoteAccess: false,
  setAllowAdvisorRemoteAccess: () => {},

  isEditMode: true,
  formGroup: {} as CompanyClientFormGroup,

  firmDetails: null,
  setFirmDetails: () => {},

  replaceAddress: () => {},
  replaceAuthorizedSignatory: () => {},
  replaceCompanyDetails: () => {},
  updateAccessConsultants: () => {},
  updateAccessConsultant: () => {},

  updateAddress: () => {},
  updateAuthorizedSignatory: () => {},
  updateCompanyDetails: () => {},
  updateContractEffectiveInput: () => {},
  updateInvoicingCostCenter: () => {},
  updateInvoiceEmailAddress: () => {},
  patchFirmDetails: () => {},
  replaceCombinations: () => {},
  replaceAccessConsultants: () => {},
  updateCombinations: () => {},
  appendDeletedAdmin: () => {},
  appendDeletedConsultant: () => {},
  replaceSignatoryAdmins: () => {},
  replaceAccess: () => {},
});

type Props = {
  children: JSX.Element;
};

export const EditAccountingFirmGeneralSettingsProvider = ({ children }: Props) => {
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(INITIAL_COMPANY_DETAILS);
  const [addressDetails, setAddressDetails] = useState<AddressDetails>(INITIAL_ADDRESS_DETAILS);
  const [signatoryDetails, setSignatoryDetails] = useState<Array<AuthorizedSignatory>>([]);

  const [contractEffectiveFrom, setContractEffectiveFrom] = useState<string>('');
  const [invoiceCostCenter, setInvoiceCostCenter] = useState<string>('');
  const [contractEmail, setContractEmail] = useState<string>('');
  const [firmDetails, setFirmDetails] = useState<any>(null);
  const [combinations, setCombinations] = useState<Array<AuthorizedSignatoryGroup>>([]);
  const [deletedAdmins, setDeletedAdmins] = useState<Array<string>>([]);
  const [deletedConsultants, setDeletedConsultants] = useState<Array<string>>([]);
  const [signatoryAdmins, setSignatoryAdmins] = useState<Array<AuthorizedSignatory>>([]);
  const [accessConsultants, setAccessConsultants] = useState<Array<Consultants>>([]);
  const [allowCorrectoRemoteAccess, setAllowCorrectoRemoteAccess] = useState(false);
  const [allowAdvisorRemoteAccess, setAllowAdvisorRemoteAccess] = useState(false);

  const CLIENT_INITIAL_VALUES = {
    companyDetails: { ...INITIAL_COMPANY_DETAILS },
    address: INITIAL_ADDRESS_DETAILS,
    contractEffectiveFrom: '',
    contractEmail: '',
    invoiceCostCenter: '',
    combinations: [],
  };

  const formGroup = useFormik({
    initialValues: CLIENT_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const replaceAddress = (value) => {
    setAddressDetails(value);
    formGroup.setFieldValue('address', value);
  };

  const replaceAuthorizedSignatory = (value) => {
    const authorizedSignatoryValue = value || [];

    setSignatoryDetails(authorizedSignatoryValue);
    formGroup.setFieldValue('authorizedSignatory', authorizedSignatoryValue);
  };

  const replaceCompanyDetails = (value) => {
    setCompanyDetails(value);
    formGroup.setFieldValue('companyDetails', value);
    formGroup.setFieldTouched('companyDetails');
  };

  const updateAddress = (inputName, inputValue) => {
    setAddressDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`address.${inputName}`, inputValue);
  };

  const updateAuthorizedSignatory = (inputName, inputValue) => {
    setSignatoryDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`authorizedSignatory.${inputName}`, inputValue);
  };

  const updateCompanyDetails = (inputName, inputValue) => {
    setCompanyDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`companyDetails.${inputName}`, inputValue);
  };

  const updateContractEffectiveInput = (inputValue) => {
    setContractEffectiveFrom(inputValue);
    formGroup.setFieldValue(`contractEffectiveFrom`, inputValue);
  };

  const updateInvoicingCostCenter = (inputValue) => {
    setInvoiceCostCenter(inputValue);
    formGroup.setFieldValue(`invoiceCostCenter`, inputValue);
  };

  const updateInvoiceEmailAddress = (inputValue) => {
    setContractEmail(inputValue);
    formGroup.setFieldValue(`invoiceEmailAddress`, inputValue);
  };

  const patchFirmDetails = (_details: any) => {
    setFirmDetails(_details);
  };

  const replaceCombinations = (value) => {
    setCombinations(value);
    formGroup.setFieldValue('combinations', value);
  };

  const updateCombinations = (inputName, inputValue) => {
    setCombinations((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`combinations.${inputName}`, inputValue);
  };

  const updateAccessConsultants = (inputName, inputValue) => {
    setAccessConsultants((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`consultants.${inputName}`, inputValue);
  };

  const updateAccessConsultant = (inputName, inputValue) => {
    formGroup.setFieldValue(`consultant.${inputName}`, inputValue);
  };

  const replaceAccessConsultants = (value) => {
    setAccessConsultants(value);
    formGroup.setFieldValue('consultants', value);
  };

  const appendDeletedAdmin = (value) => {
    const _deletedAdmins = [...deletedAdmins];
    _deletedAdmins.push(value);

    setDeletedAdmins(_deletedAdmins);
  };

  const appendDeletedConsultant = (value) => {
    // if (!isEditMode) {
    //   return;
    // }

    const _deletedConsultants = [...deletedConsultants];
    _deletedConsultants.push(value);

    setDeletedConsultants(_deletedConsultants);
  };

  const replaceSignatoryAdmins = (value) => {
    setSignatoryAdmins(value);
    formGroup.setFieldValue('signatoryAdmins', value);
  };

  const replaceAccess = (correctoAccess, advisorAccess) => {
    setAllowAdvisorRemoteAccess(advisorAccess);
    setAllowCorrectoRemoteAccess(correctoAccess);
    formGroup.setFieldValue('allowCorrectoAccess', correctoAccess);
    formGroup.setFieldValue('allowAdvisorAccess', advisorAccess);
  };

  const context: ICompanyClientContext = {
    companyDetails,
    setCompanyDetails,
    signatoryDetails,
    setSignatoryDetails,
    addressDetails,
    setAddressDetails,
    contractEffectiveFrom,
    setContractEffectiveFrom,
    isEditMode: true,
    invoiceCostCenter,
    setInvoiceCostCenter,
    contractEmail,
    setContractEmail,
    firmDetails,
    setFirmDetails,
    deletedAdmins,
    setDeletedAdmins,
    deletedConsultants,
    setDeletedConsultants,
    signatoryAdmins,
    setSignatoryAdmins,
    combinations,
    setCombinations,
    accessConsultants,
    setAccessConsultants,
    allowAdvisorRemoteAccess,
    setAllowAdvisorRemoteAccess,
    allowCorrectoRemoteAccess,
    setAllowCorrectoRemoteAccess,
    formGroup: formGroup as any,
    replaceAddress,
    replaceAuthorizedSignatory,
    replaceCompanyDetails,
    replaceCombinations,
    replaceAccessConsultants,
    updateAddress,
    updateAuthorizedSignatory,
    updateCompanyDetails,
    updateAccessConsultants,
    updateAccessConsultant,
    updateContractEffectiveInput,
    updateInvoiceEmailAddress,
    updateInvoicingCostCenter,
    patchFirmDetails,
    updateCombinations,
    appendDeletedAdmin,
    appendDeletedConsultant,
    replaceSignatoryAdmins,
    replaceAccess,
  };

  return (
    <EditAccountingFirmGeneralSettingsContext.Provider value={context}>
      {children}
    </EditAccountingFirmGeneralSettingsContext.Provider>
  );
};
