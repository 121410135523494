import { IBusinessAnalysisFilterParams } from '../../../../types/report';
import { API_VERSION, BUSINESS_ANALYSIS_CHART_ENDPOINT } from '../../../../util/constants';
import { HttpQuery } from '../../../../util/http-query';
import { get } from '../../../../util/httpService';

const getBusinessAnalysisChart = async ({ duration, companyClientId, report_name }: IBusinessAnalysisFilterParams) => {
    let endpoint = HttpQuery.serialize({ duration, companyClientId }, `${API_VERSION}${BUSINESS_ANALYSIS_CHART_ENDPOINT}${report_name}`);
    const chartData = await get(endpoint);
    return chartData;
};
export { getBusinessAnalysisChart }