import { Form, Spinner } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';
import { FormProps } from '../types';
import classNames from 'classnames';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../util/form';
import useLocale from '../../../../hooks/useLocale';

export default function ContractDetails({ context, isContractLoading }: FormProps & { isContractLoading?: boolean }) {
  const { localize } = useLocale();
  const { formGroup, updateContractEffectiveInput, updateInvoiceEmailAddress, updateInvoicingCostCenter } = context;

  return (
    <div>
      <PagesHeader pageTitle="Contract details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        <div className="cr-form-row">
          <div className="contract-num-field">
            <Form.Label className="fs-name mb-0">
              {`${localize('contracts')} ${localize('number')}`} {isContractLoading && <Spinner size="sm" />}
            </Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="contractNumber"
              value={formGroup.values?.contractNumber || ''}
              disabled
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contractEffectiveFrom')} />
          </div>
          <div className="effect-from-field">
            <Form.Label className="fs-name mb-0 required">{`${localize('contracts')} ${localize(
              'effective_from',
            )}`}</Form.Label>
            <MonthAndYearDropdown
              startFrom={formGroup.values.contractEffectiveFrom}
              value={formGroup.values.contractEffectiveFrom}
              onOptionSelect={(val) => updateContractEffectiveInput(val)}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contractEffectiveFrom')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contractEffectiveFrom')} />
          </div>
          <div className="invoice-email-field">
            <Form.Label className="fs-name mb-0 required">{localize('invoice_email_address')}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              onChange={(e) => updateInvoiceEmailAddress(e.target.value)}
              name="contractEmail"
              className={classNames(errorHighlighter(formGroup.getFieldMeta(`contractEmail`)))}
              value={formGroup.values.contractEmail}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contractEmail')} />
          </div>
          <div className="cost-center-field">
            <Form.Label className="fs-name mb-0 ">{localize('invoicing_cost_center')}</Form.Label>
            <Form.Control
              size="lg"
              type="number"
              onChange={(e) => updateInvoicingCostCenter(e.target.value)}
              name="invoiceCostCenter"
              value={formGroup.values.invoiceCostCenter}
              className={classNames('text-left', errorHighlighter(formGroup.getFieldMeta(`invoiceCostCenter`)))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('invoiceCostCenter')} />
          </div>
        </div>
      </div>
    </div>
  );
}
