import classNames from 'classnames';
import { memo } from 'react';
import { Calendar } from 'react-feather';
import SandGlassIcon from '../../../assets/img/icons/sandglass-icon.svg';
import { useTranslation } from 'react-i18next';
import useLocalizationResolver from '../../../hooks/useLocalizationResolver';

type Deadline = {
  title: string;
  date: string;
  daysLeft: number;
};

const deadlines: Deadline[] = [
  {
    title: 'Salary data import',
    date: 'Oct 28',
    daysLeft: 2,
  },
  {
    title: 'Data to pension companies',
    date: 'Nov 3',
    daysLeft: 8,
  },
  {
    title: 'Admin deadline',
    date: 'Nov 22',
    daysLeft: 27,
  },
];

export const DeadlineSidebar = memo(() => {
  const { t } = useTranslation();
  const { getLang } = useLocalizationResolver();
  return (
    <>
      {deadlines.map((i, idx) => {
        return (
          <div key={i.title} className={classNames('deadline-box--extended', { 'mt-2': idx !== 0 })}>
            <span className="misc-box theme-text">
              <Calendar style={{ marginRight: 12, verticalAlign: 'text-top' }} />
              <div className="desc-box">
                <div className="title-text">{getLang(i.title)}</div>
                <div className="date-box theme-text">
                  <div className="date-group">
                    <span>{i.date}</span>
                    <span className="mx-2">|</span>
                    <span className="days-remained">
                      <img className="image-filter" src={SandGlassIcon} alt="icon" style={{ verticalAlign: 'unset' }} />
                      <span>
                        -{i.daysLeft} {t('days')}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </span>
          </div>
        );
      })}
    </>
  );
});

DeadlineSidebar.displayName = 'DeadlineSidebar';
