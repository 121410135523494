export const dataManagementDetails = {
  headers: ['Salary type extracted', 'Error', 'Correcto Salary Type', 'Amount', 'Actions'],
  data: [
    { id: 1, title: 'Mandalson', description: 'Unmatched type detected:string on page number: 3', amount: '75000.00' },
    {
      id: 2,
      title: 'Foraldradig dagsavdrag, manadslon',
      description: 'Total value doesnot match the amount',
      amount: '14285.72',
    },
    {
      id: 3,
      title: 'Urlagg telefon',
      description: 'Unmatched type detected:string on page number: 3',
      amount: '320.00',
    },
    { id: 4, title: 'Utlagg tag', description: 'Total value doesnot match the amount', amount: '1019.00' },
    { id: 5, title: 'Bruttolon', description: 'Total value doesnot match the amount', amount: '60714.28' },
    { id: 6, title: 'Prelimiarskatt', amount: '17616.00' },
    { id: 7, title: 'Skatteria uttagg/avdrag', amount: '1339.00' },
    { id: 8, title: 'ATT UTBETALA', amount: '44437.00' },
  ],
  // amount: [
  //     { title: 'Bruttolon', amount: '60714.28' },
  //     { title: 'Prelimiarskatt', amount: '17616.00' },
  //     { title: 'Skatteria uttagg/avdrag', amount: '1339.00' }
  // ],
  // totalAmount: '44437.00'
};

export const DATA_VALIDATIONS = [
  {
    _id: '64cae12783b9107ae4c90037',
    documentName: 'salary_slip1.pdf',
    employee: {
      addresses: [
        {
          street: 'Liljeholmsgränd 4 ',
          postcode: '11761',
          city: 'Stockholm',
          country: 'Sverige',
        },
      ],
      employmentDate: '2021-03-22T00:00:00.000Z',
      employmentType: 'Permanent employment',
      foraType: '-',
      importInformation: {
        importedFrom: 'Fortnox',
        id: '1',
        activeOnEMS: true,
        idKey: 'EmployeeId',
      },
      jobTitle: '',
      personnelType: 'White collar',
      salary: 75000,
      salaryType: 'Monthly',
      scheduleId: 'HEL',
      userId: '6401e1c8349603814d22357e',
      companyClientId: '6420473d77697d27cc354e2c',
      phoneNumber: '0706749748',
      email: 'oscar_hagman@hotmail.com',
      datedWages: [
        {
          EmployeeId: '1',
          FirstDay: '1970-01-01',
          MonthlySalary: 75000,
          HourlyPay: null,
        },
      ],
      datedSchedules: [
        {
          EmployeeId: '1',
          FirstDay: '1970-01-01',
          ScheduleId: 'HEL',
        },
      ],
    },
    user: {
      fullName: 'Taha khan',
      personalNumber: '19931013-1234',
      roles: [
        {
          role: 'CompanyAdmin',
          status: 'Active',
          company: {
            _id: {
              $oid: '63aaeab62789d9cc096a4748',
            },
            name: 'Sizzle AB',
          },
        },
      ],
    },
    paymentDate: '2022-06-23T00:00:00.000Z',
    status: 'PROCESSING_ERROR',
    errors: [
      {
        categoryCode: 100,
        category: 'Unmateched Type',
        message: 'Unmateched salary Type',
        code: 101,
      },
      {
        categoryCode: 200,
        category: 'Total Mismatched',
        message: 'Total Values does not match',
        code: 201,
      },
    ],
    salaryPeriod: {
      from: '2022-06-01',
      to: '2022-06-30',
    },
    deviationPeriod: {
      from: '2022-06-01',
      to: '2022-06-30',
    },
    taxTable: '31:2',
    equalization: 0,
    'prelEmployerSeverance%': 1332.344,
    prelEmployerAvg: 1332.344,
    tableTaxBase: 1332.344,
    oneTimeTaxBase: '0',
    cumulativeGrossSalary: 6039390,
    cumulativePreliminaryTax: 6039390,
    flexBalance: 6039390,
    compBalance: 6039390,
    grossSalary: '1',
    preliminaryTax: '1',
    taxFreeExpensesAndDeductions: '1',
    toPay: '1',
    articles: [
      {
        article: {
          salaryCodeData: {
            id: '63b3ffedf3a824335f941b63',
            code: 9,
            name: 'Månadslön',
          },
          extractedData: {
            name: 'Månad',
          },
        },
        amount: 1000,
        orignalValue: 2500,
      },
      {
        article: {
          salaryCodeData: {},
          extractedData: {
            name: 'Månad',
          },
        },
        fromDate: '2022-06-01T00:00:00.000Z',
        toDate: '2022-06-04T00:00:00.000Z',
        pieces: 4,
        unit: 'Days',
        amount: 4556,
        orignalValue: 45000,
        updatedValue: 13668,
      },
      {
        article: {
          salaryCodeData: {
            id: '63b3ffedf3a824335f941b63',
            code: 9,
            name: 'Månadslön',
          },
          extractedData: {
            name: 'Månadslön',
          },
          isDefault: true,
        },
        fromDate: '2022-06-01T00:00:00.000Z',
        toDate: '2022-06-04T00:00:00.000Z',
        pieces: 4,
        unit: 'Days',
        amount: 4556,
        orignalValue: 45000,
        updatedValue: 13668,
      },
    ],
    holidays: {
      paid: 2,
      saved: 1,
      obetald: 1,
      forskott: 1,
    },
    toPayout: 4443700,
    createdAt: '2022-06-01T00:00:00.000Z',
    updatedAt: '2022-06-01T00:00:00.000Z',
  },
  {
    _id: '64cae18783b9107ae4c9003a',
    documentName: 'salary_slip2.pdf',
    employee: {
      addresses: [
        {
          street: 'Liljeholmsgränd 4 ',
          postcode: '11761',
          city: 'Stockholm',
          country: 'Sverige',
        },
      ],
      employmentDate: '2021-03-22T00:00:00.000Z',
      employmentType: 'Permanent employment',
      foraType: '-',
      importInformation: {
        importedFrom: 'Fortnox',
        id: '1',
        activeOnEMS: true,
        idKey: 'EmployeeId',
      },
      jobTitle: '',
      personnelType: 'White collar',
      salary: 75000,
      salaryType: 'Monthly',
      scheduleId: 'HEL',
      userId: '6401e1c8349603814d22357e',
      companyClientId: '6420473d77697d27cc354e2c',
      phoneNumber: '0706749748',
      email: 'oscar_hagman@hotmail.com',
      datedWages: [
        {
          EmployeeId: '1',
          FirstDay: '1970-01-01',
          MonthlySalary: 75000,
          HourlyPay: null,
        },
      ],
      datedSchedules: [
        {
          EmployeeId: '1',
          FirstDay: '1970-01-01',
          ScheduleId: 'HEL',
        },
      ],
    },
    user: {
      fullName: 'Ali Haider',
      personalNumber: '19931013-1234',
      roles: [
        {
          role: 'CompanyAdmin',
          status: 'Active',
          company: {
            _id: {
              $oid: '63aaeab62789d9cc096a4748',
            },
            name: 'Dani AB',
          },
        },
      ],
    },
    paymentDate: '2022-06-23T00:00:00.000Z',
    status: 'PROCESSING_ERROR',
    errors: [
      {
        categoryCode: 100,
        category: 'Unmateched Type',
        message: 'Unmateched salary Type',
        code: 101,
      },
    ],
    salaryPeriod: {
      from: '2022-06-01',
      to: '2022-06-30',
    },
    deviationPeriod: {
      from: '2022-06-01',
      to: '2022-06-30',
    },
    taxTable: '31:2',
    equalization: 0,
    'prelEmployerSeverance%': 1332.344,
    prelEmployerAvg: 1332.344,
    tableTaxBase: 1332.344,
    oneTimeTaxBase: '0',
    cumulativeGrossSalary: '6039390',
    cumulativePreliminaryTax: '6039390',
    flexBalance: '6039390',
    compBalance: '6039390',
    grossSalary: '1',
    preliminaryTax: '1',
    taxFreeExpensesAndDeductions: '1',
    toPay: '1',
    articles: [
      {
        article: {
          salaryCodeData: {
            id: '63b3ffedf3a824335f941b63',
            code: 9,
            name: 'Månadslön',
          },
          extractedData: {
            name: 'Månad',
          },
        },
        amount: 0,
        orignalValue: 1500,
        updatedValue: 0,
      },
      {
        article: {
          salaryCodeData: {},
          extractedData: {
            name: 'Månad',
          },
        },
        fromDate: '2022-06-01T00:00:00.000Z',
        toDate: '2022-06-04T00:00:00.000Z',
        pieces: 4,
        unit: 'Days',
        amount: 4556,
        orignalValue: 20000,
        updatedValue: 13668,
      },
      {
        article: {
          salaryCodeData: {
            id: '63b3ffedf3a824335f941b63',
            code: 9,
            name: 'Månadslön',
          },
          extractedData: {
            name: 'Månadslön',
          },
          isDefault: true,
        },
        fromDate: '2022-06-01T00:00:00.000Z',
        toDate: '2022-06-04T00:00:00.000Z',
        pieces: 4,
        unit: 'Days',
        amount: 4556,
        orignalValue: 25000,
        updatedValue: 13668,
      },
    ],
    holidays: {
      paid: 2,
      saved: 1,
      obetald: 1,
      forskott: 1,
    },
    toPayout: 4443700,
    createdAt: '2022-06-01T00:00:00.000Z',
    updatedAt: '2022-06-01T00:00:00.000Z',
  },
  {
    _id: '64cc2eed83b9107ae4c9003b',
    documentName: 'salary_slip3.pdf',
    employee: {
      addresses: [
        {
          street: 'Liljeholmsgränd 4 ',
          postcode: '11761',
          city: 'Stockholm',
          country: 'Sverige',
        },
      ],
      employmentDate: '2021-03-22T00:00:00.000Z',
      employmentType: 'Permanent employment',
      foraType: '-',
      importInformation: {
        importedFrom: 'Fortnox',
        id: '1',
        activeOnEMS: true,
        idKey: 'EmployeeId',
      },
      jobTitle: '',
      personnelType: 'White collar',
      salary: 75000,
      salaryType: 'Monthly',
      scheduleId: 'HEL',
      userId: '6401e1c8349603814d22357e',
      companyClientId: '6420473d77697d27cc354e2c',
      phoneNumber: '0706749748',
      email: 'oscar_hagman@hotmail.com',
      datedWages: [
        {
          EmployeeId: '1',
          FirstDay: '1970-01-01',
          MonthlySalary: 75000,
          HourlyPay: null,
        },
      ],
      datedSchedules: [
        {
          EmployeeId: '1',
          FirstDay: '1970-01-01',
          ScheduleId: 'HEL',
        },
      ],
    },
    user: {
      fullName: 'Daniyal Kukda',
      personalNumber: '19931013-1234',
      roles: [
        {
          role: 'CompanyAdmin',
          status: 'Active',
          company: {
            _id: {
              $oid: '63aaeab62789d9cc096a4748',
            },
            name: 'Sizzle AB',
          },
        },
      ],
    },
    paymentDate: '2022-06-23T00:00:00.000Z',
    status: 'PROCESSING_ERROR',
    errors: [
      {
        categoryCode: 200,
        category: 'Total Mismatched',
        message: 'Total Values does not match',
        code: 201,
      },
    ],
    salaryPeriod: {
      from: '2022-06-01',
      to: '2022-06-30',
    },
    deviationPeriod: {
      from: '2022-06-01',
      to: '2022-06-30',
    },
    taxTable: '31:2',
    equalization: 0,
    'prelEmployerSeverance%': 1332.344,
    prelEmployerAvg: 1332.344,
    tableTaxBase: 1332.344,
    oneTimeTaxBase: '0',
    cumulativeGrossSalary: '6039390',
    cumulativePreliminaryTax: '6039390',
    flexBalance: '6039390',
    compBalance: '6039390',
    grossSalary: '1',
    preliminaryTax: '1',
    taxFreeExpensesAndDeductions: '1',
    toPay: '1',
    articles: [
      {
        article: {
          salaryCodeData: {},
          extractedData: {
            name: 'Månad',
          },
        },
        fromDate: '2022-06-01T00:00:00.000Z',
        toDate: '2022-06-04T00:00:00.000Z',
        pieces: 4,
        unit: 'Days',
        amount: 1400,
        orignalValue: 15000,
        updatedValue: 13668,
      },
      {
        article: {
          salaryCodeData: {
            id: '63b3ffedf3a824335f941b63',
            code: 9,
            name: 'Månadslön',
          },
          extractedData: {
            name: 'Månadslön',
          },
          isDefault: true,
        },
        fromDate: '2022-06-01T00:00:00.000Z',
        toDate: '2022-06-04T00:00:00.000Z',
        pieces: 4,
        unit: 'Days',
        amount: 1250,
        orignalValue: 18000,
        updatedValue: 13668,
      },
    ],
    holidays: {
      paid: 2,
      saved: 1,
      obetald: 1,
      forskott: 1,
    },
    toPayout: 4443700,
    createdAt: '2022-06-01T00:00:00.000Z',
    updatedAt: '2022-06-01T00:00:00.000Z',
  },
  {
    _id: '64cae12783b9107ae4c900388',
    documentName: 'salary_slip1.pdf',
    employee: {
      addresses: [
        {
          street: 'Liljeholmsgränd 4 ',
          postcode: '11761',
          city: 'Stockholm',
          country: 'Sverige',
        },
      ],
      employmentDate: '2021-03-22T00:00:00.000Z',
      employmentType: 'Permanent employment',
      foraType: '-',
      importInformation: {
        importedFrom: 'Fortnox',
        id: '1',
        activeOnEMS: true,
        idKey: 'EmployeeId',
      },
      jobTitle: '',
      personnelType: 'White collar',
      salary: 75000,
      salaryType: 'Monthly',
      scheduleId: 'HEL',
      userId: '6401e1c8349603814d22357e',
      companyClientId: '6420473d77697d27cc354e2c',
      phoneNumber: '0706749748',
      email: 'oscar_hagman@hotmail.com',
      datedWages: [
        {
          EmployeeId: '1',
          FirstDay: '1970-01-01',
          MonthlySalary: 75000,
          HourlyPay: null,
        },
      ],
      datedSchedules: [
        {
          EmployeeId: '1',
          FirstDay: '1970-01-01',
          ScheduleId: 'HEL',
        },
      ],
    },
    user: {
      fullName: 'Taha khan',
      personalNumber: '19931013-1234',
      roles: [
        {
          role: 'CompanyAdmin',
          status: 'Active',
          company: {
            _id: {
              $oid: '63aaeab62789d9cc096a4748',
            },
            name: 'Sizzle AB',
          },
        },
      ],
    },
    paymentDate: '2022-06-23T00:00:00.000Z',
    status: 'SUCCESSFULLY_PROCESSED',
    errors: [],
    salaryPeriod: {
      from: '2022-06-01',
      to: '2022-06-30',
    },
    deviationPeriod: {
      from: '2022-06-01',
      to: '2022-06-30',
    },
    taxTable: '31:2',
    equalization: 0,
    'prelEmployerSeverance%': 1332.344,
    prelEmployerAvg: 1332.344,
    tableTaxBase: 1332.344,
    oneTimeTaxBase: '0',
    cumulativeGrossSalary: 6039390,
    cumulativePreliminaryTax: 6039390,
    flexBalance: 6039390,
    compBalance: 6039390,
    grossSalary: '1',
    preliminaryTax: '1',
    taxFreeExpensesAndDeductions: '1',
    toPay: '1',
    articles: [
      {
        article: {
          salaryCodeData: {
            id: '63b3ffedf3a824335f941b63',
            code: 9,
            name: 'Månadslön',
          },
          extractedData: {
            name: 'Månad',
          },
        },
        amount: 1000,
        orignalValue: 2500,
        updatedValue: 4000,
      },
      {
        article: {
          salaryCodeData: {
            id: '63b3ffedf3a824335f941b63',
            code: 9,
            name: 'Månadslön',
          },
          extractedData: {
            name: 'Månad',
          },
        },
        fromDate: '2022-06-01T00:00:00.000Z',
        toDate: '2022-06-04T00:00:00.000Z',
        pieces: 4,
        unit: 'Days',
        amount: 4556,
        orignalValue: 45000,
        updatedValue: 13668,
      },
    ],
    holidays: {
      paid: 2,
      saved: 1,
      obetald: 1,
      forskott: 1,
    },
    toPayout: 4443700,
    createdAt: '2022-06-01T00:00:00.000Z',
    updatedAt: '2022-06-01T00:00:00.000Z',
  },
];
