import {
  ACCOUNTING_FIRM_ENDPOINT,
  API_VERSION,
  COMPANY_CLIENTS_ENDPOINT,
  MOCK_COMPANY_NAME_ENDPOINT,
  MOCK_USER_ENDPOINT,
} from '../../../../util/constants';

import { del, get, post, put } from '../../../../util/httpService';
import { AccountingFirmClient, Consultant, GetCompanyClientType } from './types';
import { IAccountingFirmProvision } from '../../../../pages/superAdmin/AccountingFirm/types';

const getClients = async ({
  page = 1,
  sizePerPage = 25,
  searchString = '',
  sortField = 'name',
  sortOrder = '1',
  consultant = '',
  isError = false,
  isSetup = false
}) => {
  let endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}?page=${page}&pageSize=${sizePerPage}&filter[name][like]=${searchString}&filter[isError]=${isError}&filter[isSetup]=${isSetup}&sort[${sortField}]=${sortOrder}`;

  if (consultant) {
    endpoint += `&consultant=${consultant}`;
  }

  const company: GetCompanyClientType = await get(endpoint);
  return company;
};

const postNewClient = async (body: AccountingFirmClient, params: Record<string, boolean>) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}?draft=${params['draft']}&sendEmails=${params['sendEmails']}`;
  const company = await post(endpoint, { ...body }, {});
  return company;
};

const updateClient = async (id: string | undefined, body: AccountingFirmClient) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/${id}`;
  const company = await put(endpoint, { ...body }, {});
  return company;
};

const deleteClient = async (id: string) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/${id}`;
  const company = await del(endpoint, {});
  return company;
};

const getMockDetail = async ({ id, type }) => {
  const endpoint = `${API_VERSION}${MOCK_COMPANY_NAME_ENDPOINT}/${id}?type=${type}`;

  const data = await get(endpoint);

  return data;
};

const getMockUserName = async () => {
  const endpoint = `${API_VERSION}${MOCK_USER_ENDPOINT}`;

  const { fullName } = await get(endpoint);

  return fullName;
};

const getClientDetailsByID = async (id) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/${id}`;
  const company: AccountingFirmClient = await get(endpoint);
  return company;
};

const getClientConsultants = async (firmID) => {
  /* return new Promise<Consultant[]>((resolve, reject) => {
    setTimeout(() => {
      // Simulating fetching data
      const data: Consultant[] = [
        {
          id: 'e017675375322332',
          fullName: 'Marita Stefanz',
          email: 'marita@mail.com',
          short: 'MS',
          position: 'Senior consultant',
          personalNumber: '19680215-0241',
        },
        {
          id: 'e01736555322332',
          fullName: 'Margareta Johansson',
          email: 'johansson@mail.com',
          short: 'MJ',
          position: 'Consultant',
          personalNumber: '19680215-0242',
        },
        {
          id: 'e017386246322332',
          fullName: 'Bruno Göransson',
          email: 'bruno@mail.com',
          short: 'BG',
          position: 'Supervisor',
          personalNumber: '19680215-0243',
        },
      ];

      // Resolving the promise with the fetched data
      resolve(data);
    }, 2000); // Simulating a 2-second delay
  }); */
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${firmID}/consultants?includeSupervisor=true`;
  const company: Consultant[] = await get(endpoint);
  const companyList = company.map((item) => {
    if (!item.fullName && item.firstName) {
      item.fullName = `${item.firstName} ${item.lastName}`;
    }

    return item;
  });

  return companyList;
};

const getLegalDocuments = async (id) => {
  let endpoint = `${API_VERSION}/verified/envelope/data/${id}`;
  const legalDocs = await get(endpoint);
  return legalDocs
};

const getProvisions = (): Promise<IAccountingFirmProvision[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          companyName: 'Company AB',
          orgNumber: '112233-2344',
          status: 'Active',
          employees: 10,
          totalMonths: 35,
          provisionPercent: 10,
          provisionTotal: 728,
        },
        {
          companyName: 'Numberology AB',
          orgNumber: '112233-2344',
          status: 'Active',
          employees: 33,
          totalMonths: 21,
          provisionPercent: 20,
          provisionTotal: 600,
        },
        {
          companyName: 'OneAndTwos AB',
          orgNumber: '112233-2344',
          status: 'Active',
          employees: 33,
          totalMonths: 3,
          provisionPercent: 30,
          provisionTotal: 850,
        },
        {
          companyName: 'Finance Really AB',
          orgNumber: '112233-2344',
          status: 'Active',
          employees: 10,
          totalMonths: 57,
          provisionPercent: 20,
          provisionTotal: 400,
        },
        {
          companyName: 'Also correct AB',
          orgNumber: '112233-2344',
          status: 'Active',
          employees: 33,
          totalMonths: 35,
          provisionPercent: 10,
          provisionTotal: 500,
        },
        {
          companyName: 'Mariposa AB',
          orgNumber: '112233-2344',
          status: 'Active',
          employees: 10,
          totalMonths: 22,
          provisionPercent: 20,
          provisionTotal: 650,
        },
        {
          companyName: 'In the Cloud AB',
          orgNumber: '112233-2344',
          status: 'Active',
          employees: 33,
          totalMonths: 11,
          provisionPercent: 30,
          provisionTotal: 400,
        },
      ]);
    }, 2000);
  });
};

const getClientContractNumber = async (): Promise<string> => {
  let endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/contract/number`;
  const categories = await get(endpoint);
  return categories;
};
const getAccountingfirmDashboardData = async () => {
  let endpoint = `${API_VERSION}/dashboard/supervisor`;
  const dashboard = await get(endpoint);
  return dashboard;
}

export {
  getClients,
  postNewClient,
  getMockDetail,
  getMockUserName,
  getClientDetailsByID,
  updateClient,
  deleteClient,
  getClientConsultants,
  getLegalDocuments,
  getProvisions,
  getClientContractNumber,
  getAccountingfirmDashboardData
};
