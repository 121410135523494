export const LOCALE_KEYS = {
  WhiteCollar: 'white_collar',
  BlueCollar: 'blue_collar',
  Consultant: 'consultant',
  Supervisor: 'supervisor',
  'Permanent employment': 'permanent_employment',
  'Reported pension': 'PENSION_OFFSET.reported_pension',
  'Reported salary changes': 'PENSION_OFFSET.reported_salary_changes',
  'From year of age': 'PENSION_POLICY.from_year_of_age',
  'From month of age': 'PENSION_POLICY.from_month_of_age',
  'Vacation days, setup 1': 'PENSION_POLICY.vacation_days_setup_1',
  'Vacation days, setup 2': 'PENSION_POLICY.vacation_days_setup_2',
  'Vacation days, setup 3': 'PENSION_POLICY.vacation_days_setup_3',
  'Monthly gross salary': 'PENSION_POLICY.monthly_gross_salary',
  Setup: 'setup',
  Active: 'active',
  Inactive: 'inactive',
  InActive: 'inactive',
  Draft: 'draft',
  'Salary data import': 'salary_data_import',
  'Data to pension companies': 'data_to_pension_companies',
  'Admin deadline': 'admin_deadline',
  'Pension data output': 'pension_data_output',
  POLICY_TYPE: {
    Company: 'company',
    CollectiveAgreement: 'collective_agreement',
    CorrectoProvided: 'correcto_provided',
    Personal: 'personal',
  },
  INSURANCE_TYPE: {
    healthInsurancePlan: 'INSURANCE_TYPES.health_insurance',
    occupationalPensionInsurancePlan: 'INSURANCE_TYPES.occupational_pension_insurance',
    otherInsurancesPlan: 'INSURANCE_TYPES.other_insurance',
    individualInsurancesPlan: 'INSURANCE_TYPES.individual_insurances',
    groupInsurancePlan: 'INSURANCE_TYPES.group_insurances',
  },
  'Health Insurance': 'INSURANCE_TYPES.health_insurance',
  'Occupational Pension Insurance': 'INSURANCE_TYPES.occupational_pension_insurance',
  'Other Insurance': 'INSURANCE_TYPES.other_insurance',
  'Individual Insurances': 'INSURANCE_TYPES.individual_insurances',
  'Group Insurances': 'INSURANCE_TYPES.group_insurances'

};

// const  = useLocale('WhiteCollar')
