import { useEffect, useRef, useState } from 'react';
import { Row, Spinner, Button } from 'react-bootstrap';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CardComponent from '../../../components/base/CardComponent';
import BSRemoteTable from '../../../components/table/BSRemoteTable';
import Pagination from '../../company/Pagination';
import { useColumn } from './useColumn';
import useAuth from '../../../hooks/useAuth';
import { getConsultants, deleteConsultant } from '../../../api/service/superAdmin/consultants/service';
import { GetConsultants } from '../../../api/service/superAdmin/consultants/types';
import { DialogWithTextField } from '../../../util/dialogs';

type Props = {
  isConsultantCreated: boolean;
};

export default function ConsultantsGrid({ isConsultantCreated }: Props) {
  const { user } = useAuth();
  const navigate = useNavigate();

  const onDelete = async (consultant) => {
    const title = `${t('i_want_to_detete')} ${consultant?.fullName} ${t('forever')}`;
    const textPara = `${t('to_delete_the')} ${t('consultant')}, ${t('type_in')}`;
    const result = await DialogWithTextField({
      title: t('are_you_sure'),
      // title: `<b><span>${t('are_you_sure')}?</span></b> <br/> <p class="user-select-none">${t('to_delete_the_consultant_type_in')}:</p><h4 class="user-select-none">"${title}"</h4>`,
      confirmButtonText: `${t('delete_consultant')}`,
      denyButtonText: t('cancel'),
      validationTitle: title,
      validationMessage: t('validation_message'),
      content: {
        caption: textPara,
        body: title,
      },
      confirmBtnClass: 'confirm-delete-btn',
    });
    if (result.isConfirmed) {
      await deleteConsultant(consultant.id);
      const filteredConsultants = consultantData?.filter((item) => item.id !== consultant.id);
      setConsultantData(filteredConsultants);
    }
  };
  const { companyConsultantColumn } = useColumn({ onDelete });
  const [isLoading, setIsLoading] = useState(true);
  const [tableFilter, setTableFilter] = useState({ searchString: '', status: '' });
  const [consultantData, setConsultantData] = useState<GetConsultants | any>(null);
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  const searchRef = useRef<any>();
  const totalPages = consultantData?.metaData
    ? Math.ceil(consultantData?.metaData.total / consultantData?.metaData?.page.size)
    : 0;

  const onPagination = async ({ page, sizePerPage }) => {
    await fetchConsultants({ page, sizePerPage });
  };
  const onSearch = async () => {
    const searchTerm = searchRef.current['value'];
    setTableFilter((prevValue) => ({ ...prevValue, searchString: searchTerm }));
  };
  const setActive = () => {
    setIsActive((isActive: any) => !isActive);
    if (isActive) {
      setTableFilter((prevValue) => ({ ...prevValue, status: '' }));
    } else {
      setTableFilter((prevValue) => ({ ...prevValue, status: 'Active' }));
    }
  };

  const fetchConsultants = async ({
    id = user?.role.company.id,
    searchString = '',
    page = consultantData?.metaData?.page?.current || 1,
    sizePerPage = consultantData?.metaData?.page?.size || 25,
    sortField = 'name',
    sortOrder = '1',
    status = '',
  }) => {
    const clients: any = await getConsultants({ id, searchString, page, sizePerPage, sortField, sortOrder, status });
    // const excludeLoginSupervisor = clients.filter((client) => client.id !== user?.id);
    setConsultantData(clients as any);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchConsultants(tableFilter);
  }, [tableFilter]);

  useEffect(() => {
    if (isConsultantCreated) {
      setIsLoading(true);
      fetchConsultants({});
    }
  }, [isConsultantCreated]);

  return (
    <>
      <CardComponent>
        <Row className="justify-content-between">
          <div className="cr-form-row w-auto">
            <div className="search-field">
              <div className="input-group">
                <input
                  ref={searchRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearch();
                    }
                  }}
                  placeholder={`${t('Search consultant')}...`}
                  className="form-control"
                  style={{ borderRight: 'none' }}
                />
                <span className="input-group-append bg-white border-left-0">
                  <span className="input-group-text ">
                    <Search className="cursor-pointer" onClick={onSearch} width={16} height={30} />
                  </span>
                </span>
              </div>
            </div>
            <Button
              className={`app-btn-white ${!isActive ? 'app-btn-secondary' : 'selected-button'}`}
              onClick={() => {
                setActive();
              }}
            >
              {t('active')}
            </Button>
            {/* <Button className="app-btn-secondary"> {`${t('Can')} ${t('add clients')} `}</Button> */}
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <BSRemoteTable
              data={(consultantData as any) ?? []}
              columns={companyConsultantColumn}
              noDataIndication={`${t('no_consultant_found')}`}
              sizePerPage={25}
              onSizePerPageChange={(sizePerPage, page) => onPagination({ sizePerPage, page })}
              keyField="id"
              onRowClick={(row) => {
                navigate(row.id);
              }}
              onTableChange={(type, { sortOrder, sortField }) => {
                if (type === 'sort') {
                  fetchConsultants({ sortField, sortOrder: sortOrder === 'asc' ? '1' : '-1' });
                }
              }}
            />
          )}
        </Row>
        {totalPages > 1 && (
          // <Pagination
          //   total={companyData?.metaData.total ?? 0}
          //   itemsPerPage={companyData?.metaData.page.size ?? 0}
          //   currentPage={companyData?.metaData.page.current ?? 0}
          //   onPageChange={(page) => onPagination({ page, sizePerPage: companyData?.metaData.page.size })}
          // />
          <Pagination total={(consultantData as any).length} itemsPerPage={25} currentPage={1} />
        )}
      </CardComponent>
    </>
  );
}
