import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PagesHeader from '../../../../components/base/PagesHeader';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';
import { FormProps } from '../types';
import classNames from 'classnames';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../util/form';

export default function ContractDetails({ context, isContractLoading }: FormProps & { isContractLoading?: boolean }) {
  const { t } = useTranslation();
  const { formGroup, updateContractEffectiveInput, updateInvoicingCostCenter, updateInvoiceEmailAddress, isEditMode } =
    context;

  return (
    <div>
      <PagesHeader pageTitle="Contract details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        <div className="cr-form-row">
          <div className="first-name-field">
            <Form.Label className="fs-name">
              {`${t('contracts')} ${t('number')}`} {isContractLoading && <Spinner size="sm" />}
            </Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="contractNumber"
              value={formGroup.values?.contractNumber || ''}
              disabled
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contractEffectiveFrom')} />
          </div>
          <div className="effect-from-field">
            <Form.Label className="fs-name mb-0 required">{`${t('contracts')} ${t('effective_from')}`}</Form.Label>
            <MonthAndYearDropdown
              value={formGroup.values?.contractEffectiveFrom}
              onOptionSelect={(val) => updateContractEffectiveInput(val)}
              disabled={isEditMode}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contractEffectiveFrom')))}
            />

            <ErrorFeedback field={formGroup.getFieldMeta('contractEffectiveFrom')} />
          </div>
          <div className="email-field">
            <Form.Label className="fs-name mb-0 required">{t('invoice_email_address')}</Form.Label>
            <Form.Control
              size="lg"
              className={classNames(errorHighlighter(formGroup.getFieldMeta(`invoiceEmailAddress`)))}
              onChange={(e) => updateInvoiceEmailAddress(e.target.value)}
              name="invoiceEmailAddress"
              value={formGroup.values.invoiceEmailAddress}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('invoiceEmailAddress')} />
          </div>
          <div className="cost-center-field">
            <Form.Label className="fs-name mb-0">{t('invoicing_cost_center')}</Form.Label>
            <Form.Control
              className={classNames(errorHighlighter(formGroup.getFieldMeta(`invoiceContractCenter`)))}
              size="lg"
              onChange={(e) => updateInvoicingCostCenter(e.target.value)}
              name="invoiceContractCenter"
              value={formGroup.values.invoiceContractCenter}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('invoiceContractCenter')} />
          </div>
        </div>
      </div>
    </div>
  );
}
