import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import BSModal from '../../../../components/Modal';
import ValidationAlert from '../../../../components/ValidationAlert';
import PersonalPolicy from './PersonalPolicy';
import { PersonalPolicyProvider } from '../context/PersonalPolicyContext';
import { IEmployeeInfoInput, PensionPolicyMode } from '../../../superAdmin/PensionPolicy/types';
import { PensionPolicyProvider } from '../../../superAdmin/PensionPolicy/PensionPolicyContext/PensionPolicyContext';
import { PersonalPolicyModalFooter } from './PersonalPolicyModalFooter';
import { useValidationError } from '../../../../hooks/useValidationError';

// eslint-disable-next-line react/prop-types
const ModalTitle = ({ personalPolicyName }) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="fs-h1 mb-0 theme-text">{`${t('PENSION_POLICY.personal_policy')}`}</p>
      <p className="fs-h1 mb-0 theme-text">{personalPolicyName}</p>
    </>
  );
};

type Props = {
  policy?: any | null;
  defaultSelectedPolicyOrigin?: any;
  showModal: boolean;
  employee: any;
  isEditAction?: boolean;
  toggleModal: (...args) => void;
  onSavePolicy: (args) => void;
  onUpdatePersonalPolicy: (updatedPolicyChanges: any) => void;
};

export default function CreatePersonalPolicyModal({
  policy,
  defaultSelectedPolicyOrigin = null,
  showModal,
  toggleModal,
  employee,
  onSavePolicy,
  onUpdatePersonalPolicy,
}: Props) {
  const { isInvalid, errors, setErrors } = useValidationError();
  const personalPolicyName = policy['title'] ?? `${employee?.fullName} ${employee?.personalNumber}`;
  const employeeInput: IEmployeeInfoInput | null = employee
    ? { paidHolidays: employee?.vacation?.paidHolidays ? +employee.vacation.paidHolidays : 0 }
    : null;

  const personalPolicyModalFooterRef = useRef<any>(null);

  const hideModal = () => {
    toggleModal();

    if (personalPolicyModalFooterRef?.current) {
      personalPolicyModalFooterRef.current.restoreToDefault();
    }
  };

  return (
    <PersonalPolicyProvider>
      <PensionPolicyProvider
        defaultSelectedPolicyMode={PensionPolicyMode.PERSONAL_POLICY}
        defaultEmployeeInfoInput={employeeInput}
      >
        <BSModal
          className="employee-personal-policy-modal crt-add-pension-policy-modal"
          show={showModal}
          onHide={hideModal}
          title={<ModalTitle personalPolicyName={personalPolicyName} />}
          ModalFooter={
            <PersonalPolicyModalFooter
              policy={policy}
              defaultSelectedPolicyOrigin={defaultSelectedPolicyOrigin}
              onSavePolicy={onSavePolicy}
              closeModal={hideModal}
              personalPolicyName={personalPolicyName}
              employee={employee}
              setErrors={setErrors}
              onUpdatePersonalPolicy={onUpdatePersonalPolicy}
              showModal={showModal}
              ref={personalPolicyModalFooterRef}
            />
          }
          backdrop={'static'}
        >
          <ValidationAlert show={isInvalid} errors={errors} />
          <PersonalPolicy
            policy={policy}
            employee={employee}
            defaultSelectedPolicyOrigin={defaultSelectedPolicyOrigin}
          />
        </BSModal>
      </PensionPolicyProvider>
    </PersonalPolicyProvider>
  );
}
