import { useTranslation } from 'react-i18next';
import PagesHeader from '../../../../../components/base/PagesHeader';
import { IntegrationFormProps, Mode } from '../../types';
import { Form, Row } from 'react-bootstrap';

const TypeOfIntegration = ({ context, mode }: IntegrationFormProps) => {
  const { formGroup, updateTypeOfIntegration } = context;
  const { t } = useTranslation();
  const handleTypeOfIntegration = (e) => {
    updateTypeOfIntegration(e.target.name, e.target.value);
  };
  const handleCompanyAPI = (e) => {
    updateTypeOfIntegration(e.target.name, e.target.checked);
  };

  return (
    <>
      <PagesHeader headingClass="fs-h3" pageTitle="Type of integration" translate={false} />
      <Row className="align-items-end mb-4">
        <div style={{ width: 130 }} className="align-self-center">
          <Form.Check
            disabled={mode === Mode.Detail}
            label={t('company_api')}
            checked={formGroup.values.typeOfIntegration.companyApi}
            onChange={(e) => handleCompanyAPI(e)}
            name="companyApi"
          />
        </div>
        <div style={{ width: 300 }}>
          <Form.Label className="fs-name ">{t('channel')}</Form.Label>
          <Form.Control
            name="channel"
            onChange={(e) => handleTypeOfIntegration(e)}
            value={formGroup.values.typeOfIntegration?.channel}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div style={{ width: 300 }}>
          <Form.Label className="fs-name">{t('api_key')}</Form.Label>
          <Form.Control
            type="text"
            name="apiKey"
            onChange={(e) => handleTypeOfIntegration(e)}
            value={formGroup.values.typeOfIntegration?.apiKey}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div style={{ width: 300 }}>
          <Form.Label className="fs-name ">{t('api_key')} 2</Form.Label>
          <Form.Control
            name="apiKey2"
            onChange={(e) => handleTypeOfIntegration(e)}
            value={formGroup.values.typeOfIntegration?.apiKey2}
            disabled={mode === Mode.Detail}
          />
        </div>
      </Row>
    </>
  );
};

export default TypeOfIntegration;
