export const INITIAL_ADMIN_DETAILS = {
    _id: '',
    personalNumber: '',
    fullName: '',
    firstName: '',
    lastName: '',
    email: '',
    isActive: false
};
export const FORM_INITIAL_VALUES = {
    admins: [],
};
