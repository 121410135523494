import {
  EmployeePensionPolicy,
  PensionPolicyVariant,
  PreExistEmployeePolicy,
} from '../../../superAdmin/PensionPolicy/types';
import { EmployeePolicyRevision } from '../context/EmployeePensionContext';
import useEmployeePensionContext from '../useContext';

const usePolicyRevision = () => {
  const { policyRevisionList, setPolicyRevisionList } = useEmployeePensionContext();

  const updateAdminPolicyRevisionSettings = (
    id: string,
    policy: EmployeePensionPolicy | any,
    category: 'admin' | 'personal' = 'admin',
    policyMetaDetails = null,
  ) => {
    if (category === 'personal') {
      if (!findPersonalPolicyFromRevisionList()) {
        return;
      }

      const _policyRevisionList = { ...policyRevisionList };
      _policyRevisionList['personal'] = Object.assign(
        {
          ..._policyRevisionList['personal'],
          ...policy,
        },
        policyMetaDetails ? { metaDetails: policyMetaDetails } : {},
      );

      setPolicyRevisionList(_policyRevisionList);

      return;
    }

    const _policyRevisionList = { ...policyRevisionList };
    const adminPolicies: EmployeePolicyRevision[] = [..._policyRevisionList[category]];

    const foundPolicyIdx = adminPolicies.findIndex((item) => {
      return item.currentPolicy.id === id;
    });

    if (foundPolicyIdx !== -1) {
      adminPolicies[foundPolicyIdx] = Object.assign(
        {
          ...adminPolicies[foundPolicyIdx],
          ...policy,
        },
        policyMetaDetails ? { metaDetails: policyMetaDetails } : {},
      ) as EmployeePolicyRevision;

      _policyRevisionList[category] = adminPolicies;

      setPolicyRevisionList(_policyRevisionList);
    }
  };

  const addToAdminPolicyRevisionList = (
    preExistPolicy: PreExistEmployeePolicy,
    policyDetails: any,
    additionalFormData,
  ) => {
    const _policyRevisionList = { ...policyRevisionList };
    const adminPolicies = [...policyRevisionList['admin']];

    if (findAdminPolicyRevisionList(preExistPolicy.id)) {
      return;
    }

    adminPolicies.push({
      ...additionalFormData,
      currentPolicy: preExistPolicy,
      policySettings: null,
      metaDetails: policyDetails,
    } as EmployeePolicyRevision);

    _policyRevisionList['admin'] = adminPolicies;

    setPolicyRevisionList(_policyRevisionList);
  };

  const addDefaultAdminPolicyRevision = (employeePension, policyDetails: any) => {
    let _defaultRevision = {
      currentPolicy: employeePension.currentPolicy,
      policySettings: employeePension.policySettings,
      metaDetails: policyDetails,
    };

    const _policyRevisionList = { ...policyRevisionList };
    const category: 'admin' | 'personal' =
      employeePension.currentPolicy.type === PensionPolicyVariant.PERSONAL ? 'personal' : 'admin';

    if (findAdminPolicyRevisionList(policyDetails._id, category)) {
      updateAdminPolicyRevisionSettings(policyDetails._id, employeePension, category);

      return;
    }

    if (policyDetails.type !== PensionPolicyVariant.PERSONAL) {
      const adminPolicies = [..._policyRevisionList[category]];

      adminPolicies.push(_defaultRevision as EmployeePolicyRevision);

      _policyRevisionList[category] = adminPolicies;
    } else {
      _policyRevisionList['personal'] = _defaultRevision;
    }

    setPolicyRevisionList(_policyRevisionList);
  };

  const findAdminPolicyRevisionList = (id: string, category: 'admin' | 'personal' = 'admin') => {
    if (category === 'personal') {
      return policyRevisionList['personal'];
    }

    const adminPolicies = [...policyRevisionList[category]];
    const foundPolicy = adminPolicies.find((item) => {
      return item.currentPolicy.id === id;
    });

    return foundPolicy;
  };

  const findPersonalPolicyFromRevisionList = () => {
    const _policyRevisionList = { ...policyRevisionList };

    if (_policyRevisionList.personal) {
      return _policyRevisionList.personal;
    }

    return null;
  };

  const addPersonalPolicyToRevisionList = (_policyDetails: any, additionalFormData) => {
    if (findPersonalPolicyFromRevisionList()) {
      return;
    }

    // const _policyRevisionList = { ...policyRevisionList };

    const _policy = {
      ...additionalFormData,
      currentPolicy: {
        id: _policyDetails._id,
        type: _policyDetails.type,
        attachedAt: new Date().toISOString(),
      },
      policySettings: null,
      metaDetails: _policyDetails,
    };

    //_policyRevisionList['personal'] = deepClone(_policy);

    setPolicyRevisionList((prev) => {
      return {
        ...prev,
        personal: _policy,
      };
    });
  };

  const replaceRevisionSettings = (pensionRevisionSettings, category: 'admin' | 'personal' = 'admin') => {
    const _policyRevisionList = { ...policyRevisionList };

    _policyRevisionList[category] = pensionRevisionSettings;

    setPolicyRevisionList(_policyRevisionList);
  };

  return {
    updateAdminPolicyRevisionSettings,
    addToAdminPolicyRevisionList,
    findAdminPolicyRevisionList,
    addDefaultAdminPolicyRevision,
    addPersonalPolicyToRevisionList,
    findPersonalPolicyFromRevisionList,
    replaceRevisionSettings,
  };
};

export default usePolicyRevision;
