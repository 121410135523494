import { Container } from 'react-bootstrap';
import Breadcrumb from '../../../components/base/Breadcrumb';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import BillingGrid from './BillingGrid';
import BillingPageHeader from './BillingPageHeader';
import { useState } from 'react';
import moment from 'moment';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Billing',
  },
];

const Billing = () => {
  const [period, setPeriod] = useState(moment().toString());
  const hideInvoiceBtn = period ? moment(period).month() === moment().month() : false;

  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <BillingPageHeader hideInvoiceBtn={hideInvoiceBtn} />
      <BillingGrid period={period} setPeriod={setPeriod} />
    </Container>
  );
};

export default Billing;
