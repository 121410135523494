import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import classNames from 'classnames';
import useContext from './context/useContext';
import useLocale from '../../../../hooks/useLocale';
import PagesHeader from '../../../../components/base/PagesHeader';
import CardComponent from '../../../../components/base/CardComponent';
import { IPensionManager } from '../types';
import { sortPensionManagers } from '../../../../api/service/superAdmin/settings/pension-companies';
import PensionCompaniesRowItem from './PensionCompaniesRowItem';

export default function PensionCompaniesList() {
  const [loadingContainer, setLoadingContainer] = useState({ primary: false, secondary: false });
  const { pensionCompanies, isBusy, replacePensionCompanies } = useContext();
  const { localize } = useLocale();

  const markedPensionManagers: IPensionManager[] = pensionCompanies['isCollectiveAgreement'] || [];
  const unMarkedPensionManagers: IPensionManager[] = pensionCompanies['isNotCollectiveAgreement'] || [];

  const toggleLoadingContainer = (containerKey) => {
    setLoadingContainer((prev) => {
      return {
        ...prev,
        [containerKey]: !prev[containerKey],
      };
    });
  };

  const listDiff = (prevList, newList) => {
    prevList = JSON.parse(JSON.stringify(prevList));
    newList = JSON.parse(JSON.stringify(newList));

    let changed = newList.filter((p, idx) => {
      if (p['id'] !== prevList[idx]['id']) {
        p['order'] = idx + 1;
        return p;
      }

      return false;
    });

    return changed;
  };

  const onDragEnd = async (event, type) => {
    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }

    const selectedLoadingType = type === 'isCollectiveAgreement' ? 'primary' : 'secondary';
    const selectedPensionManager = type === 'isCollectiveAgreement' ? markedPensionManagers : unMarkedPensionManagers;
    const oldIndex = selectedPensionManager.findIndex((item) => item.id === active.id);
    const newIndex = selectedPensionManager.findIndex((item) => item.id === over.id);
    const sortedList = arrayMove(selectedPensionManager, oldIndex, newIndex).map((item, index) => {
      item.order = index + 1;

      return item;
    });

    const _pensionManagerList = { ...pensionCompanies };
    if (type === 'isCollectiveAgreement') {
      _pensionManagerList['isCollectiveAgreement'] = sortedList;
    } else if (type === 'isNotCollectiveAgreement') {
      _pensionManagerList['isNotCollectiveAgreement'] = sortedList;
    }
    replacePensionCompanies(_pensionManagerList);

    const selectedReOrderedItems = listDiff(selectedPensionManager, sortedList);

    try {
      toggleLoadingContainer(selectedLoadingType);
      await sortPensionManagers({
        orders: selectedReOrderedItems.map((item) => {
          return {
            id: item.id,
            order: item.order,
          };
        }),
      });
    } catch (e) {
      console.error(e);
    } finally {
      toggleLoadingContainer(selectedLoadingType);
    }
  };

  return (
    <>
      <CardComponent>
        <PagesHeader headingClass="fs-h2 font-black" pageTitle={'Pension managers'} />
        <div className="d-flex align-items-start my-2 mb-4">
          <h3 className="fs-h3 font-black  theme-text">{localize('for_collective_agreements')}</h3>
          {loadingContainer.primary && <Spinner size="sm" className="ms-1" />}
        </div>
        {isBusy ? (
          <Spinner />
        ) : (
          <>
            <div
              className={classNames('table-responsive react-bootstrap-table chart-table', {
                'ui-blocker': loadingContainer.primary,
              })}
            >
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={(event) => onDragEnd(event, 'isCollectiveAgreement')}
              >
                <SortableContext
                  items={markedPensionManagers.map((item) => item.id!)}
                  strategy={verticalListSortingStrategy}
                >
                  <table className="table table-hover table-condensed pension-plans-table">
                    <thead className="header-wrapper">
                      <tr className="correcto-table-header">
                        <th style={{ width: 25 }}></th>
                        <th className="month-header-col" style={{ width: 150 }}>
                          {localize('company_short_name')}
                        </th>
                        <th className="month-header-col" style={{ width: 300 }}>
                          {localize('description')}
                        </th>
                        <th className="month-header-col" style={{ width: 80 }}>
                          {localize('status')}
                        </th>
                        <th style={{ width: 70 }}></th>
                      </tr>
                    </thead>
                    <tbody className="remote-table-body">
                      {markedPensionManagers?.map((item) => {
                        return <PensionCompaniesRowItem type="collectiveAgreement" key={item.id} item={item} />;
                      })}
                    </tbody>
                  </table>
                </SortableContext>
              </DndContext>
            </div>
            {/* <BSTable
              data={pensionCompanies['isCollectiveAgreement'] || []}
              columns={columns}
              onRowClick={(row) => {
                setPensionCompanyDetail(row.id);
              }}
            /> */}
          </>
        )}
        <div className="d-flex align-items-start my-2 mb-4">
          <h3 className="fs-h3 font-black theme-text">{localize('other')}</h3>
          {loadingContainer.secondary && <Spinner size="sm" className="ms-2" />}
        </div>
        {isBusy ? (
          <Spinner />
        ) : (
          <>
            <div
              className={classNames('table-responsive react-bootstrap-table chart-table', {
                'ui-blocker': loadingContainer.secondary,
              })}
            >
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={(event) => onDragEnd(event, 'isNotCollectiveAgreement')}
              >
                <SortableContext
                  items={unMarkedPensionManagers.map((item) => item.id!)}
                  strategy={verticalListSortingStrategy}
                >
                  <table className="table table-hover table-condensed pension-plans-table">
                    <thead className="header-wrapper">
                      <tr className="correcto-table-header">
                        <th style={{ width: 25 }}></th>
                        <th className="month-header-col" style={{ width: 150 }}>
                          {localize('company_short_name')}
                        </th>
                        <th className="month-header-col" style={{ width: 230 }}>
                          {localize('description')}
                        </th>
                        <th className="month-header-col" style={{ width: 70 }}>
                          {localize('correcto_partner')}
                        </th>
                        <th className="month-header-col" style={{ width: 80 }}>
                          {localize('status')}
                        </th>
                        <th style={{ width: 70 }}></th>
                      </tr>
                    </thead>
                    <tbody className="remote-table-body">
                      {unMarkedPensionManagers?.map((item) => {
                        return <PensionCompaniesRowItem type="isNotCollectiveAgreement" key={item.id} item={item} />;
                      })}
                    </tbody>
                  </table>
                </SortableContext>
              </DndContext>
            </div>
            {/* <BSTable
              data={pensionCompanies['isNotCollectiveAgreement'] || []}
              columns={columns}
              onRowClick={(row) => {
                setPensionCompanyDetail(row.id);
              }}
            /> */}
          </>
        )}
      </CardComponent>
    </>
  );
}
