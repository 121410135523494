import {
  ACCOUNTING_FIRM_ENDPOINT,
  API_VERSION,
  //   COMPANY_CLIENTS_ENDPOINT,
  //   MOCK_COMPANY_NAME_ENDPOINT,
  //   MOCK_USER_ENDPOINT,
} from '../../../../util/constants';

import { del, get, put, post } from '../../../../util/httpService';
import { Consultants, GetConsultants } from './types';

const getConsultants = async ({
  id = '',
  page = 1,
  sizePerPage = 25,
  searchString = '',
  sortField = 'name',
  sortOrder = '1',
  status = 'Inactive',
}) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}/consultants?page=${page}&pageSize=${sizePerPage}&filter[name][like]=${searchString}&sort[${sortField}]=${sortOrder}&status=${status}&includeSupervisor=${true}`;

  const company: GetConsultants = await get(endpoint);
  return company;
};
const getConsultantDetails = async ({ id = '', includeSupervisor = false }) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}/consultants?includeSupervisor=${includeSupervisor}`;

  const company: GetConsultants = await get(endpoint);
  return company;
};
const postNewConsultant = async (id, body: Consultants, params: Record<string, boolean>) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}/consultants?draft=${params['draft']}&sendEmails=${params['sendEmails']}`;
  const consultant = await post(endpoint, { ...body }, {});
  return consultant;
};

const updateConsultant = async (id: string | undefined, body: Consultants) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}/consultants`;
  const company = await put(endpoint, { ...body }, {});
  return company;
};

const deleteConsultant = async (id: string) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}/consultants`;
  const consultant = await del(endpoint, {});
  return consultant;
};


export { getConsultants, getConsultantDetails, postNewConsultant, updateConsultant, deleteConsultant };
