import { Button, Spinner } from 'react-bootstrap';
import useLocale from '../../../../hooks/useLocale';
import { Save } from 'react-feather';
import { Dispatch, SetStateAction, useState } from 'react';
import useContext from '../PensionPolicyContext/useContext';
import { AuthUser, UserRole } from '../../../../types/auth';
import { savePolicy } from '../../../../api/service/superAdmin/pension-policies/service';
import { LooseObject } from '../types';
import { PensionPolicyDTO } from '../entity';
import { isEmpty } from '../../../../util/util';

type Props = {
  toggleModal: () => void;
  setIsPolicyCreated: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
};

const PensionPolicyModalFooter = ({ toggleModal, setErrors, setIsPolicyCreated }: Props) => {
  const { localize } = useLocale();
  const { formGroup, policyForm, reloadToDefault } = useContext();
  const [isBusy, setIsBusy] = useState(false);
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const isSuperAdmin = user?.role?.role === UserRole.SUPER_ADMIN;

  const onSavePolicy = async () => {
    try {
      formGroup.handleSubmit();
      const errors = await formGroup.validateForm();

      if (!isEmpty(errors)) {
        setErrors(errors);
        return false;
      }

      const payload = PensionPolicyDTO.create({
        formGroup: formGroup.values,
        policyForm: policyForm,
        isSuperAdmin,
      });
      setIsPolicyCreated(false);
      setErrors({});
      setIsBusy(true);

      await savePolicy(payload);

      setIsPolicyCreated(true);
      reloadToDefault();
      toggleModal();
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
      setIsPolicyCreated(false);
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <Button onClick={toggleModal} className="me-1 app-btn-secondary cancel-btn">
          {localize('cancel')}
        </Button>
        <Button onClick={onSavePolicy} disabled={!formGroup.isValid} className="app-btn-primary">
          {isBusy ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}
          {`${localize('PENSION_POLICY.save_policy')}`}
        </Button>
      </div>
    </>
  );
};
export default PensionPolicyModalFooter;
