import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useContext from './context/useContext';
import { getPensionCompanyDetail } from '../../../../api/service/superAdmin/settings/pension-companies';
import { useSuperAdminSettingsContext } from '../useContext';
import { generateUniqueId } from '../../../../util/util';
import { Mode } from '../types';
import { Check, MoreVertical } from 'react-feather';
import { DeleteAction } from './actions';

const PensionCompaniesRowItem = ({ item, type }: any) => {
  const {
    pensionCompanies,
    replacePensionCompany,
    replaceContactInformation,
    replaceConnection,
    formGroup,
    setPensionManagerPlans,
    setSelectedPensionID,
    setSelectedPensionManagerDetails,
  } = useContext();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id!,
    attributes: {
      role: 'draggable',
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  const { setMode } = useSuperAdminSettingsContext();

  const setPensionCompanyDetail = async (id) => {
    const pensionCompanyDetail = (await getPensionCompanyDetail(id)) ?? [];
    setSelectedPensionManagerDetails(pensionCompanyDetail);
    replacePensionCompany({
      shortName: pensionCompanyDetail['shortName'],
      companyName: pensionCompanyDetail['companyName'],
      clientNumber: pensionCompanyDetail['clientNumber'],
      contractNumber: pensionCompanyDetail['contractNumber'],
      description: pensionCompanyDetail['description'],
      organizationNumber: pensionCompanyDetail['organizationNumber'],
      vatNumber: pensionCompanyDetail['vatNumber'],
      bankgiroNumber: pensionCompanyDetail['bankgiroNumber'],
      plusgiroNumber: pensionCompanyDetail['plusgiroNumber'],
      pensionType: pensionCompanyDetail['pensionType'],
      //integrationType: pensionCompanyDetail['integrationType'],
      //piKey: pensionCompanyDetail['apiKey'],
      //id: pensionCompanyDetail['id'],
      status: pensionCompanyDetail['status'],
      collectiveAgreement: pensionCompanyDetail['collectiveAgreement'],
    });
    replaceContactInformation(pensionCompanyDetail?.contactInformation);
    replaceConnection(pensionCompanyDetail?.connection);
    setPensionManagerPlans(pensionCompanyDetail?.pensionManagerPlans);
    const plans = (pensionCompanyDetail.pensionManagerPlans?.plans || []).map((item, index) => {
      item.id = generateUniqueId();
      item.order = item.order || index + 1;

      return item;
    });
    formGroup.setFieldValue('pensionManagerPlans', { ...pensionCompanyDetail.pensionManagerPlans, plans: plans });
    setSelectedPensionID(id);
    setMode(Mode.Detail);
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes} className="cursor-pointer">
      <td className="align-baseline">
        <div {...listeners} className="draggable-item">
          <MoreVertical />
        </div>
      </td>
      <td onClick={() => setPensionCompanyDetail(item.id)}>{item.shortName}</td>
      <td onClick={() => setPensionCompanyDetail(item.id)}>{item.description}</td>
      {type === 'isNotCollectiveAgreement' && (
        <td onClick={() => setPensionCompanyDetail(item.id)}>
          {item.pensionManagerPlans.correctoPartner && <Check color="#0D830B" className="icon-gap-right" />}
        </td>
      )}
      <td onClick={() => setPensionCompanyDetail(item.id)}>
        <span
          className={classNames('fs-text-400', {
            'theme-text': item.status === 'Draft',
            'active-green': item.status !== 'Draft',
          })}
        >
          {item.status ?? 'Active'}
        </span>
      </td>
      <td align="right">
        <DeleteAction id={item?.id} type="notHjertaPartner" data={pensionCompanies} />
      </td>
    </tr>
  );
};

export default PensionCompaniesRowItem;
