import { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Row, Spinner, Form } from 'react-bootstrap';
import { Menu } from 'react-feather';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import BSTable from '../../../../components/table/BSTable';
import PagesHeader from '../../../../components/base/PagesHeader';
import moment from 'moment';
import { numberFormat, toNumber } from '../../../../util/util';
import { Calendar } from '../../../../util/calendar';
import {
  ComparisonSeriesReportType,
  FileExportType,
  IEmployeeSalaryReportResponse,
  ReportNameMap,
} from '../../../../types/report';
import useAuth from '../../../../hooks/useAuth';
import {
  exportSalaryTypeReport,
  getCompanySalaryTypeReport,
  getSalaryTypeEmployeeDetail,
} from '../../../../api/service/company/reports/service';
import { REPORT_EXPORT_OPTIONS } from '../constants';
import { tableHeaderText } from '../../../../components/table/TableHeaderFormatter';

export const SalaryTypeReport = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [reportHeaders, setReportHeaders] = useState<any>();
  const [reportRecords, setReportRecords] = useState([]);
  const [reportResponse, setReportResponse] = useState<any>(null);
  const years = Calendar.getYearBoundary(5);
  const [selectedYear, setSelectedYear] = useState(Calendar.currentYear);

  const fetchAllEmployeeYearlyReport = async () => {
    try {
      const reportDetails: IEmployeeSalaryReportResponse = await getCompanySalaryTypeReport({
        duration: {
          from: Calendar.getSelectedYearBoundary(Number(selectedYear))[0],
          to: Calendar.getSelectedYearBoundary(Number(selectedYear))[1],
        },
        companyClientId: user?.role.company.id,
        type: ComparisonSeriesReportType.TABLE,
        year: selectedYear,
      });

      setReportResponse(reportDetails);
      processReportData({
        data: reportDetails.salariesByDate,
        employees: reportDetails.employees,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatEmployeeHeader = (column, colIndex) => {
    return (
      <div className="col-title">
        {column.text.fullName}
        <div className="col-subtext">{column.text.personalNumber}</div>
      </div>
    );
  };

  const processReportData = (reportResult) => {
    const formattedFieldData = reportResult?.data?.map((employee) => {
      return employee.employeeSalaries.map((data) => {
        return { [`field` + data.employee.id]: data['salary'].totalSalaryTransactions };
      });
    });
    const updatedFieldData = formattedFieldData.map((el, idx) => {
      return Object.assign([], el);
    });
    const records = reportResult.data.map((employee, index) => {
      return {
        date: moment(employee.date).format('MMM-YYYY'),
        timestamp: employee.date,
        total: employee.total,
        ...updatedFieldData[index].reduce((current, next) => {
          return { ...current, ...next };
        }),
      };
    });
    setReportRecords(records);
    initializeHeaders(reportResult.employees);
  };

  const exportCSV = async (fileType: FileExportType = FileExportType.XLSX) => {
    try {
      setIsExporting(true);
      const reportDetails = await exportSalaryTypeReport({
        duration: {
          from: Calendar.getSelectedYearBoundary(Number(selectedYear))[0],
          to: Calendar.getSelectedYearBoundary(Number(selectedYear))[1],
        },
        companyClientId: user?.role.company.id,
        type: ComparisonSeriesReportType.TABLE,
        year: selectedYear,
      });

      const url = window.URL.createObjectURL(new Blob([reportDetails]));
      const filePrefix = ReportNameMap['salary-type'];
      const fileSuffix = Calendar.instance().format('DDMMYYYYHHmmss');
      const fileName = `${filePrefix}-${fileSuffix}.${fileType}`;

      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  const setYearFilter = (value) => {
    setSelectedYear(value);
  };

  const getMockEmployeeSalaryCodeTransaction = (employee) => {
    return {
      employee: employee,
      transaction: {
        value: 0,
        unit: 'SEK',
      },
    };
  };

  const initializeHeaders = (employees: any) => {
    const columns = [
      {
        text: tableHeaderText('Month'),
        dataField: 'date',
        formatter: (cell) => <span className={classNames('font-weight-bold', 'theme-text')}>{t(cell || '')}</span>,
        footer: 'Total',
        footerClasses: 'font-bold theme-text title-header-col title-header-col--extended-2',
        headerClasses: 'fixed-col title-header-col title-header-col--extended-2',
        classes: 'fixed-col-content',
      },
      {
        text: 'Total / month',
        dataField: 'total',
        classes: 'fixed-col-content number-col',
        footer: (columnData) => (
          <span className="cell-content">{numberFormat(columnData.reduce((acc, item) => acc + item, 0))}</span>
        ),
        formatter: (cell) => {
          return <span className="cell-content">{numberFormat(cell) || ''}</span>;
        },
        footerClasses: 'font-bold theme-text number-col',
        headerClasses: 'fixed-col series-header-col',
      },
    ].concat(
      employees.map((employee) => {
        return {
          dataField: `field${employee.id}`,
          text: employee as any,
          headerFormatter: formatEmployeeHeader,
          headerClasses: 'col-head fluid-col series-header-col',
          formatter: (cell) => {
            return <span className="cell-content">{numberFormat(cell) || ''}</span>;
          },
          footer: (columnData) => (
            <span className="cell-content">
              {numberFormat(columnData.reduce((acc, item) => toNumber(acc) + toNumber(item), 0))}
            </span>
          ),
          classes: 'fluid-col-content number-col',
          footerClasses: 'font-bold theme-text number-col',
        };
      }),
    );
    setReportHeaders(columns);
  };

  const expandRow = {
    className: 'report-row--expanded',
    parentClassName: 'toggleable-group',
    renderer: (row) => {
      if (row.loading) {
        return (
          <table className="table-condensed w-100">
            <tbody>
              <tr>
                <td className="title-cell">
                  <span className="text-bold">
                    <Spinner size="sm" />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        );
      }

      const employeeTransactions = row.employeeTransactions || [];

      return (
        <table className="table-condensed w-100">
          <tbody>
            {employeeTransactions.map((item, idx) => {
              return (
                <tr key={item.name + idx}>
                  <td className="title-cell title-cell--extended-2">
                    <span className="text-bold">{item.name}</span>
                  </td>
                  <td className="fluid-cell number-col">
                    <span className="cell-content">{numberFormat(item.total)}</span>
                  </td>
                  {item.employeeTransactions.map((empItem) => {
                    return (
                      <td key={empItem.employee.id} className="fluid-cell number-col">
                        <span className="cell-content">{numberFormat(empItem.transaction.value)}</span>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    },
    onExpand: (row, isExpand, rowIndex, e) => {
      const fetchEmployeeSalaryDetails = async () => {
        const response = await getSalaryTypeEmployeeDetail({
          companyId: user?.role.company.id,
          date: row.timestamp,
        });

        const updatedRecords = [...reportRecords];
        const foundRow = updatedRecords.find((item) => item['date'] === row.date) as any;

        foundRow['employeeTransactions']! = (response.transactionsByCode || []).map((transactionMasterItem) => {
          transactionMasterItem.employeeTransactions = reportResponse.employees.map((employeeItem) => {
            const foundSalaryTransactionRow = transactionMasterItem.employeeTransactions.find(
              (item) => item.employee.id === employeeItem.id,
            );

            if (foundSalaryTransactionRow) {
              return foundSalaryTransactionRow;
            }

            return getMockEmployeeSalaryCodeTransaction(employeeItem);
          });
          return transactionMasterItem;
        });
        foundRow['loading']! = false;

        setReportRecords(updatedRecords);
      };

      if (isExpand) {
        row['loading']! = true;

        fetchEmployeeSalaryDetails();
      }
    },
  };

  const rowClasses = useCallback((row, rowIndex) => {
    if ((rowIndex + 1) % 2 === 0) {
      return 'grouped-expandable-row grouped-striped-row';
    }

    return 'grouped-expandable-row grouped-row';
  }, []);

  useEffect(() => {
    fetchAllEmployeeYearlyReport();
  }, [selectedYear]);

  return (
    <>
      <Row className="justify-content-between align-items-end">
        <div className="mt-5">
          <PagesHeader pageTitle="All Employees" headingClass="fs-page-title" />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Form.Group className="me-3">
            <Form.Label className="fs-name">{t('year_period')}</Form.Label>
            <Form.Select
              name="year"
              className="text-left"
              onChange={(e) => {
                setYearFilter(e.target.value);
              }}
            >
              {years.map((year) => (
                <option value={year} key={year}>
                  {year} {Calendar.isCurrentYear(year) ? 'YTD' : ''}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {isExporting ? (
            <Spinner />
          ) : (
            <Dropdown className="nav-item" align="end">
              <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
                <Button className="app-btn-secondary">
                  <Menu className="theme-text" />
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {REPORT_EXPORT_OPTIONS.map((report) => (
                  <Dropdown.Item onClick={() => exportCSV(report.value)} key={report.value}>
                    {report.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="crt-table-report crt-table-report--expandable">
            {reportHeaders && (
              <BSTable
                expandRow={expandRow}
                data={reportRecords}
                columns={reportHeaders}
                noDataIndication={`${t('no_employees_found')}`}
                keyField={'date'}
                rowClass={rowClasses}
              />
            )}
          </div>
        )}
      </Row>
    </>
  );
};
