import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Save } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { AccountProfile } from '../../types';
import useProfileContainer from '../../hooks/useProfileContainer';
import useProfileForm from '../../hooks/useProfileForm';
import { useProfileObserver } from '../../hooks/useProfileObserver';
import CancelButton from '../../../../../components/base/CancelButton';

type Props = {
  profileFormVal: AccountProfile;
};

const ProfilePaneAction = (props: Props) => {
  const { t } = useTranslation();
  const { updateProfile, cancelChanges, loading } = useProfileContainer();
  const { profileFormVal } = useProfileForm();
  const { isFormModified } = useProfileObserver();
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="mb-2 cr-form-row">
        {isFormModified && <CancelButton onCancel={cancelChanges} />}
        <Button
          className="app-btn-primary"
          onClick={() => {
            updateProfile(profileFormVal);
          }}
          disabled={!isFormModified}
        >
          {loading.save ? <Spinner size="sm" className="icon-gap-right" /> : <Save className="icon-gap-right" />}
          {t('save_changes')}
        </Button>
      </div>
    </div>
  );
};

export default ProfilePaneAction;
