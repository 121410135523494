import React, { createContext, useCallback, useEffect, useState } from 'react';
import { LocaleCode } from '../types/locale';
import { SESS_LANG_PREFIX } from '../config/config';
import { getPropByString, parseString } from '../util/util';

interface PropsType {
  children: React.ReactNode;
}

const DEFAULT_LANG = LocaleCode.EN;

const INITIAL_STATE = {
  lang: DEFAULT_LANG,
  setPreferredLang: (_lang: LocaleCode) => {},
  loadTranslation: (_lang: LocaleCode) => {},
  localize: (_key: string) => '',
  localeSession: () => '',
};

export let TRANSLATION_STORE = {};

const LocaleContext = createContext(INITIAL_STATE);

const LocaleProvider = ({ children }: PropsType) => {
  const langSession = localStorage.getItem(SESS_LANG_PREFIX) as LocaleCode | null;
  const [lang, setLang] = useState<LocaleCode>(langSession || DEFAULT_LANG); // Default language
  const [translation, setTranslation] = useState(null); // Default language

  const setPreferredLang = (language: LocaleCode) => {
    setLang(language);
  };

  const loadTranslation = async (language: LocaleCode) => {
    const res = await fetchTranslation(language);

    setPreferredLang(language);
    storeLangSession(language);
    setTranslation(res);
    TRANSLATION_STORE = res;
  };

  const storeLangSession = (language: LocaleCode) => {
    localStorage.setItem(SESS_LANG_PREFIX, language);
  };

  const localeSession = () => {
    return localStorage.getItem(SESS_LANG_PREFIX) || DEFAULT_LANG;
  };

  const fetchTranslation = useCallback(async (language: LocaleCode) => {
    try {
      const timestamp = Date.now();
      const response = await fetch(`/i18n/${language}.json?${timestamp}`);
      const translationRes = await response.json();

      return translationRes;
    } catch (error) {
      console.error('Error loading translation file', error);
    }
  }, []);

  const localize = (key: string) => {
    if (translation && key) {
      const val = getPropByString(translation, key);

      if (val && typeof val !== 'string') {
        return parseString(val);
      }

      return val || key;
    }
    return key;
  };

  useEffect(() => {
    if (lang) {
      loadTranslation(lang);
    }
  }, []);

  const value = {
    lang,
    setPreferredLang,
    loadTranslation,
    localize,
    localeSession,
  };

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

export { LocaleProvider, LocaleContext };
