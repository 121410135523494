import * as yup from 'yup';
import {
    personalNumberValidations,
} from '../../../../util/validation';

export const formSchema = yup.object().shape({
    admins: yup.array().of(
        yup.object().shape({
            personalNumber: personalNumberValidations(),
            fullName: yup.string().when('personalNumber', {
                is: (val) => val && val.trim().length > 0,
                then: yup.string().required('Required'),
                otherwise: yup.string(),
            }),
            email: yup
                .string()
                .email('Invalid email')
                .when('personalNumber', {
                    is: (val) => {
                        return val && val.trim().length > 0;
                    },
                    then: yup.string().required('Required'),
                    otherwise: yup.string(),
                }),
        }),
    ),
    advisors: yup.array().of(
        yup.object().shape({
            personalNumber: personalNumberValidations(),
            fullName: yup.string().when('personalNumber', {
                is: (val) => val && val.trim().length > 0,
                then: yup.string().required('Required'),
                otherwise: yup.string(),
            }),
            email: yup
                .string()
                .email('Invalid email')
                .when('personalNumber', {
                    is: (val) => {
                        return val && val.trim().length > 0;
                    },
                    then: yup.string().required('Required'),
                    otherwise: yup.string(),
                }),
        }),
    ),
});

