import { t } from 'i18next';
import moment from 'moment';

import tableHeaderFormatter from '../../../../components/table/TableHeaderFormatter';
import { STATUS } from './constants';
/* import {
  sendToTextract,
  getFromTextract,
  syncFromFile,
} from '../../../../api/service/superAdmin/data-management/service'; */
import { ReactComponent as PDFIcon } from '../../../../assets/img/file-PDF.svg';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { openExternalLink } from '../../../../util';

const useColumn = () => {
  const navigate = useNavigate();

  /* const step = {
    UPLOADED: sendToTextract,
    PROCESSING: getFromTextract,
    PROCESSED: syncFromFile,
  }; */

  const onNextStep = async (e, row) => {
    /*  e.preventDefault();
    e.stopPropagation();
    if (step[row['status']]) {
      await step[row['status']]();
      window.location.reload();
    } */
    navigate('/data-management/salary-slip/' + row.id);
  };

  return [
    {
      dataField: 'documentName',
      text: 'Salary document',
      headerFormatter: tableHeaderFormatter,
      formatter: (cell, row) => {
        return (
          <>
            <span className="pdf-icon">
              <PDFIcon />
            </span>
            <a
              target="_blank"
              rel="noreferrer"
              href={row.documentLink}
              className="fs-h3 ms-2 font-link data-management-filename"
              onClick={(e) => openExternalLink(row.documentLink, { event: e })}
            >
              {cell}
            </a>
          </>
        );
      },
      headerStyle: () => {
        return { width: 300 };
      },
      sort: false,
    },
    {
      dataField: 'user.personalNumber',
      text: 'Personal no',
      headerFormatter: tableHeaderFormatter,

      formatter: (_cell, row) => (
        <div className="cursor-pointer" onClick={() => onNextStep(null, row)}>
          {row.user.personalNumber}
        </div>
      ),
      headerStyle: () => {
        return { width: 120 };
      },
      sort: false,
    },
    {
      dataField: 'user.fullName',
      text: 'Employee name',
      headerFormatter: tableHeaderFormatter,
      headerStyle: () => {
        return { width: 280 };
      },
      formatter: (_cell, row) => (
        <div className="cursor-pointer" onClick={() => onNextStep(null, row)}>
          {row.user.fullName}
        </div>
      ),
      sort: false,
    },
    {
      dataField: 'processing_date',
      text: 'Incoming date',
      headerFormatter: tableHeaderFormatter,
      headerStyle: () => {
        return { width: 100 };
      },
      formatter: (cell, row) => (
        <div onClick={() => onNextStep(null, row)} className="cursor-pointer fs-name">
          {moment(cell).format('YYYY-MM-DD')}
        </div>
      ),
      sort: false,
    },
    {
      dataField: 'status',
      text: 'status',
      headerFormatter: tableHeaderFormatter,
      formatter: (cell, row) => {
        const isError = cell === 'PROCESSING_ERROR';
        return (
          <>
            <span
              onClick={() => onNextStep(null, row)}
              className={classNames('data-status-box', 'cursor-pointer', { 'data-status-box--error': isError })}
            >
              <span
                className={isError ? 'data-status-icon-error' : 'data-status-icon'}
                style={{
                  color: isError ? '#CC0E0E' : '#2B2B2B',
                  fontWeight: isError ? 700 : 400,
                }}
              >
                {STATUS[cell] ? STATUS[cell]['icon'] : ''}
                {t(STATUS[cell] ? t(STATUS[cell]['key']) : '')}
              </span>
            </span>
          </>
        );
      },
      headerStyle: () => {
        return { width: 200 };
      },
      sort: false,
    },
    // {
    //   dataField: '_id',
    //   text: '',
    //   formatter: (_, row) => {
    //     return (
    //       <div className="d-flex justify-content-end align-items-center">
    //         <div onClick={console.log} className="delete-icon">
    //           <Trash2 className="icon-gap-right" />
    //         </div>
    //       </div>
    //     );
    //   },
    //   sort: false,
    // },
  ];
};

export default useColumn;
