import React, { useState, useEffect, useCallback } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { Trash2, Plus, RefreshCw } from 'react-feather';
import { deepClone, deepMerge, generateNumberList, toNumber } from '../../../../util/util';
import { PercentageNumber } from '../../../../components/form/inputs/PercentangeNumber';
import { getSalaryTypes } from '../../../../api/service/superAdmin/pension-policies/service';
import { INITIAL_HOLIDAY_SALARY_SETUP, INITIAL_SALARY_TYPE } from '../constants';
import useContext from '../PensionPolicyContext/useContext';
import SalaryTypeItem from './SalaryTypeItem';
import usePolicyValidator from '../hooks/usePolicyValidator';
import usePolicyMode from '../hooks/usePolicyMode';
import { useTranslation } from 'react-i18next';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import useLocalizationResolver from '../../../../hooks/useLocalizationResolver';
import { TypeSalaryWithHolidays } from '../types';
import { getStandardHolidayPay } from '../../../../api/service/superAdmin/pension-policies/holiday-setup.service';
import PagesHeader from '../../../../components/base/PagesHeader';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';
import useSalaryTypeForm from '../hooks/useSalaryTypeForm';

enum SALARY_TYPE_KEY {
  DEFAULT = 'policySettings.salaryType.salaryTypes',
  HOLIDAYS = 'policySettings.salaryType.salaryTypesWithHolidays',
}

const HOLIDAYS_OPTIONS = generateNumberList(20, 40);

const SalaryTypesTable = () => {
  const { formGroup, preAssignedPolicy, hasEditAction, employeeInputInfo, isResetPolicy, isCancelEvent } = useContext();
  const { updateSalaryTypeInputByCode, pushToSalaryType, removeFromSalaryType } = useSalaryTypeForm();
  const { isCheckedTemplateCalculation, isCheckedHolidaySetup } = usePolicyValidator();
  const [isPatchedFormData, setIsPatchedFormData] = useState(false);
  const { isPersonalPolicyMode } = usePolicyMode();
  const { t } = useTranslation();
  const [salaryTypes, setSalaryTypes] = useState<any>([]);
  const [commonSalaryTypes, setCommonSalaryTypes] = useState<any>([]);
  const [otherSalaryTypes, setOtherSalaryTypes] = useState<any>([]);
  const [companySalaryTypes, setCompanySalaryTypes] = useState<any>([]);
  const [salaryTypeRecord, setSalaryTypeRecord] = useState<any>({
    company: [],
    common: [],
    other: [],
  });
  const [hasPatchedEmlpoyeeInput, setHasPatchedEmployeeInput] = useState(false);
  const { getLang } = useLocalizationResolver();
  const isPensionable = formGroup.values?.policySettings?.salaryType?.isTemplateCalculation;
  const salaryTypeHolidays = formGroup.values.policySettings?.salaryType?.salaryTypesWithHolidays || [];

  const fetchSalaryCodes = async () => {
    const salaryCodes = await getSalaryTypes();
    setSalaryTypes(salaryCodes?.map((i) => ({ ...i, selected: false, percentage: i.percentage ?? 100 })));

    const _commonSalaryTypes = salaryCodes.filter((salaryCode) => {
      return salaryCode.isCommon;
    });

    const _otherSalaryTypes = salaryCodes.filter((salaryCode) => {
      return !salaryCode.isCommon;
    });

    const _companySalaryTypes = salaryCodes.filter((salaryCode) => {
      return salaryCode.type === 'Company';
    });

    setCommonSalaryTypes([..._commonSalaryTypes]);
    setOtherSalaryTypes([..._otherSalaryTypes]);
    setCompanySalaryTypes([..._companySalaryTypes]);

    setSalaryTypeRecord({
      company: deepClone(_companySalaryTypes),
      common: deepClone(_commonSalaryTypes),
      other: deepClone(_otherSalaryTypes),
    });
  };

  const togglePensionableSalary = (e) => {
    const isChecked = e.target.checked;
    formGroup.setFieldValue('policySettings.salaryType.isTemplateCalculation', isChecked);

    if (isChecked) {
      const chosenSalaryTypes = (formGroup.getFieldMeta(SALARY_TYPE_KEY.HOLIDAYS).value as any[]) || [];

      if (chosenSalaryTypes.length === 0) {
        formGroup.setFieldValue(SALARY_TYPE_KEY.HOLIDAYS, deepClone(INITIAL_SALARY_TYPE.salaryTypesWithHolidays));
      }
    }
  };

  const updateSalaryTypeRecordBySelectedSalaryTypes = (selectedSalaryTypes: any[] = []) => {
    const _salaryTypeRecord = deepClone(salaryTypeRecord);

    ['common', 'company', 'other'].forEach((recordKey) => {
      selectedSalaryTypes.forEach((selectedSalaryItem) => {
        const foundSalaryType = (_salaryTypeRecord[recordKey] || []).find(
          (salaryItem) => salaryItem._id === selectedSalaryItem.id,
        );

        if (foundSalaryType) {
          foundSalaryType.selected = true;
          foundSalaryType.percentage = selectedSalaryItem.percentage;
        }
      });
    });

    setSalaryTypeRecord(_salaryTypeRecord);
  };

  const onAddSalaryTypeRowPerSetup = (setupIndex) => {
    formGroup.setFieldValue(`${SALARY_TYPE_KEY.HOLIDAYS}.[${setupIndex}].data`, [
      ...formGroup.values.policySettings.salaryType.salaryTypesWithHolidays[setupIndex].data,
      toHolidaySalaryDataItemEntity(setupIndex),
    ]);
  };

  const deleteSalarySetup = (index, rowIndex) => {
    const filteredSalaryTypes = formGroup.values.policySettings.salaryType?.salaryTypesWithHolidays[index]?.data.filter(
      (i, salaryIndex) => salaryIndex !== rowIndex,
    );

    formGroup.setFieldValue(`${SALARY_TYPE_KEY.HOLIDAYS}.[${index}].data`, filteredSalaryTypes);
  };

  const handleHolidayPercentageChange = (value, salaryIndex, index) => {
    const foundDetail = formGroup.values.policySettings.salaryType?.salaryTypesWithHolidays[salaryIndex].data.find(
      (i, idx) => idx === index,
    );
    foundDetail!['holidayPayMultiple'] = value;
    formGroup.setFieldValue(
      `${SALARY_TYPE_KEY.HOLIDAYS}.[${salaryIndex}].data[${index}].holidayPayMultiple`,
      foundDetail?.holidayPayMultiple,
    );
  };

  const handleHolidaySalaryCodeChange = (e, salaryIndex, index) => {
    const value = e.target.value;
    const foundDetail = formGroup.values.policySettings.salaryType?.salaryTypesWithHolidays[salaryIndex].data.find(
      (i, idx) => idx === index,
    )!;
    const foundSalaryType = salaryTypes.find((item) => item._id === value);

    let salaryTypeModel;

    if (foundSalaryType) {
      salaryTypeModel = {
        id: foundSalaryType._id,
        name: foundSalaryType.name,
        percentage: foundSalaryType.percentage,
        code: foundSalaryType.code,
        mapsTo: (foundSalaryType.mapsTo || []).map((item) => {
          return {
            code: item.code,
            name: item.name,
            platformName: item.platform,
          };
        }),
      };
    }

    foundDetail['salaryType'] = salaryTypeModel;

    formGroup.setFieldValue(`${SALARY_TYPE_KEY.HOLIDAYS}.[${salaryIndex}].data[${index}].salaryType`, salaryTypeModel);
  };

  const handleHolidayDaysPerYear = (e, index) => {
    const holidayYear = toNumber(e.target.value);
    const holidaySetups = [...salaryTypeHolidays];
    const standardValue = getStandardHolidayPay(holidayYear);

    holidaySetups[index].data = holidaySetups[index].data.map((item) => {
      if (standardValue != null) {
        item.holidayPayMultiple = standardValue;
      }

      return item;
    });
    holidaySetups[index].holidayDaysPerYear = holidayYear;

    formGroup.setFieldValue(`${SALARY_TYPE_KEY.HOLIDAYS}.[${index}]`, holidaySetups[index]);
  };

  const handleCheckedRow = useCallback(
    (e, salary, type) => {
      const isChecked = e.target.checked;

      if (type === 'common') {
        const _commonSalaryTypes: any = [...commonSalaryTypes];
        const foundDetail = _commonSalaryTypes.find((i) => i['code'] === salary.code);
        foundDetail!['selected'] = isChecked;
        setCommonSalaryTypes(_commonSalaryTypes);

        if (isChecked) {
          pushToSalaryType(foundDetail);
        } else {
          removeFromSalaryType(salary);
        }
      }

      if (type === 'other') {
        const _otherSalaryTypes: any = [...otherSalaryTypes];
        const foundDetail = _otherSalaryTypes.find((i) => i['code'] === salary.code);
        foundDetail!['selected'] = isChecked;
        setOtherSalaryTypes(_otherSalaryTypes);

        if (isChecked) {
          pushToSalaryType(foundDetail);
        } else {
          removeFromSalaryType(salary);
        }
      }

      if (type === 'company') {
        const _companySalaryTypes: any = [...companySalaryTypes];
        const foundDetail = _companySalaryTypes.find((i) => i['code'] === salary.code);
        foundDetail!['selected'] = isChecked;
        setCompanySalaryTypes(_companySalaryTypes);

        if (isChecked) {
          pushToSalaryType(foundDetail);
        } else {
          removeFromSalaryType(salary);
        }
      }
    },
    [otherSalaryTypes, commonSalaryTypes, companySalaryTypes],
  );

  const handleSalaryTypePercentageChange = useCallback(
    (value, salary, type) => {
      if (type === 'common') {
        const _commonSalaryTypes: any = [...commonSalaryTypes];
        const foundDetail = _commonSalaryTypes.find((i) => i['code'] === salary.code);
        foundDetail!['percentage'] = value;
        setCommonSalaryTypes(_commonSalaryTypes);

        updateSalaryTypeInputByCode(foundDetail['code'], 'percentage', value);
      }

      if (type === 'other') {
        const _otherSalaryTypes: any = [...otherSalaryTypes];
        const foundDetail = _otherSalaryTypes.find((i) => i['code'] === salary.code);
        foundDetail!['percentage'] = value;
        setOtherSalaryTypes(_otherSalaryTypes);

        updateSalaryTypeInputByCode(foundDetail['code'], 'percentage', value);
      }
      if (type === 'company') {
        const _companySalaryTypes: any = [...companySalaryTypes];
        const foundDetail = _companySalaryTypes.find((i) => i['code'] === salary.code);
        foundDetail!['percentage'] = value;
        setCompanySalaryTypes(_companySalaryTypes);

        updateSalaryTypeInputByCode(foundDetail['code'], 'percentage', value);
      }
    },
    [otherSalaryTypes, commonSalaryTypes, companySalaryTypes],
  );

  const toHolidaySalaryDataItemEntity = (setupIndex, dataItemRow: any = null) => {
    const salarySetup = formGroup.values.policySettings.salaryType.salaryTypesWithHolidays[setupIndex];

    return {
      salaryType: dataItemRow?.salaryType || null,
      holidayPayMultiple: dataItemRow?.holidayPayMultiple || getStandardHolidayPay(salarySetup.holidayDaysPerYear) || 0,
    };
  };

  const onAddHoldaySalaryItemSetup = () => {
    const holidaySetups = [...salaryTypeHolidays];
    const entity = toHolidaySetupEntity(null, holidaySetups.length + 1);

    holidaySetups.push(entity);
    formGroup.setFieldValue(`${SALARY_TYPE_KEY.HOLIDAYS}`, holidaySetups);
  };

  const onRemoveHoldaySalaryItemSetup = (index) => {
    const holidaySetups = [...salaryTypeHolidays];

    holidaySetups.splice(index, 1);
    formGroup.setFieldValue(`${SALARY_TYPE_KEY.HOLIDAYS}`, holidaySetups);
  };

  const toHolidaySetupEntity = (dataItemRow: any = null, index: number | null = null): TypeSalaryWithHolidays => {
    const entity: TypeSalaryWithHolidays = deepClone(INITIAL_HOLIDAY_SALARY_SETUP);

    entity.setupName = `${entity.setupName} ${index}`;

    return entity;
  };

  const toggleStandardValue = (setupIndex: number, rowIndex: number) => {
    const holidaySetups = [...salaryTypeHolidays];
    const holidayPerYear = holidaySetups[setupIndex].holidayDaysPerYear;
    const standardValue = getStandardHolidayPay(holidayPerYear);

    if (standardValue != null) {
      holidaySetups[setupIndex].data[rowIndex].holidayPayMultiple = standardValue;
    }

    formGroup.setFieldValue(`${SALARY_TYPE_KEY.HOLIDAYS}`, holidaySetups);
  };

  const hasShownHolidaySetupItem = (salaryItem, salaryIndex) => {
    if (salaryIndex === 0) {
      return true;
    }

    if (!isPersonalPolicyMode() && salaryIndex !== 0 && isCheckedHolidaySetup(salaryIndex)) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    fetchSalaryCodes();
  }, []);

  useEffect(() => {
    formGroup.setFieldValue(
      `policySettings.salaryType.salaryTypesWithHolidays`,
      deepClone(INITIAL_SALARY_TYPE.salaryTypesWithHolidays),
    );
  }, []);

  //? Reset the things related to the policy
  useEffect(() => {
    if (isResetPolicy) {
      setHasPatchedEmployeeInput(false);
      setIsPatchedFormData(false);
    }
  }, [isResetPolicy]);

  useEffect(() => {
    if (isCancelEvent) {
      const _salaryTypeRecord = deepClone(salaryTypeRecord);

      setIsPatchedFormData(false);
      setCommonSalaryTypes(_salaryTypeRecord.common);
      setOtherSalaryTypes(_salaryTypeRecord.other);
      setCompanySalaryTypes(_salaryTypeRecord.company);
    }
  }, [isCancelEvent]);

  //? Patched form data based on the preAssignedPolicy details
  useEffect(() => {
    if (preAssignedPolicy && !isPatchedFormData) {
      const selectedSalaryTypes = preAssignedPolicy?.policySettings?.salaryType?.salaryTypes;
      const isCheckedTemplateCalc = isCheckedTemplateCalculation(preAssignedPolicy);

      if (isCheckedTemplateCalc) {
        const selectedHolidaySalaryTypes = preAssignedPolicy?.policySettings?.salaryType?.salaryTypesWithHolidays;
        const defaultHolidaySalaryTypes = deepClone(INITIAL_SALARY_TYPE.salaryTypesWithHolidays);

        setIsPatchedFormData(true);

        formGroup.setFieldValue(
          `${SALARY_TYPE_KEY.HOLIDAYS}`,
          deepMerge(defaultHolidaySalaryTypes, selectedHolidaySalaryTypes),
        );

        return;
      }

      if (commonSalaryTypes.length || otherSalaryTypes.length || companySalaryTypes.length) {
        const _commonSalaryTypes = [...commonSalaryTypes];
        const _otherSalaryTypes = [...otherSalaryTypes];
        const _companySalaryTypes = [...companySalaryTypes];

        selectedSalaryTypes.forEach((selectedSalaryItem) => {
          const foundCommonSalaryType = commonSalaryTypes.find(
            (salaryItem) => salaryItem._id === selectedSalaryItem.id,
          );

          if (foundCommonSalaryType) {
            foundCommonSalaryType.selected = true;
            foundCommonSalaryType.percentage = selectedSalaryItem.percentage;
            return;
          }

          const foundOtherSalaryType = otherSalaryTypes.find((salaryItem) => salaryItem._id === selectedSalaryItem.id);

          if (foundOtherSalaryType) {
            foundOtherSalaryType.selected = true;
            foundOtherSalaryType.percentage = selectedSalaryItem.percentage;
          }

          const foundCompanySalaryType = companySalaryTypes.find(
            (salaryItem) => salaryItem._id === selectedSalaryItem.id,
          );

          if (foundCompanySalaryType) {
            foundCompanySalaryType.selected = true;
            foundCompanySalaryType.percentage = selectedSalaryItem.percentage;
          }
        });

        setIsPatchedFormData(true);
        updateSalaryTypeRecordBySelectedSalaryTypes(selectedSalaryTypes);
        setCommonSalaryTypes([..._commonSalaryTypes]);
        setOtherSalaryTypes([..._otherSalaryTypes]);
        setCompanySalaryTypes([..._companySalaryTypes]);
      }
    }
  }, [preAssignedPolicy, commonSalaryTypes, otherSalaryTypes, companySalaryTypes, isPatchedFormData]);

  //? Set the form based on the EMPLOYEE_INPUT
  useEffect(() => {
    if (
      !hasEditAction() &&
      employeeInputInfo &&
      employeeInputInfo.paidHolidays > 0 &&
      salaryTypeHolidays?.[0] &&
      !hasPatchedEmlpoyeeInput
    ) {
      formGroup.setFieldValue(`${SALARY_TYPE_KEY.HOLIDAYS}.[0].holidayDaysPerYear`, employeeInputInfo.paidHolidays);
      setHasPatchedEmployeeInput(true);
    }
  }, [employeeInputInfo, salaryTypeHolidays?.[0]]);

  return (
    <>
      <div className="mt-4 mb-3">
        <PagesHeader pageTitle="pensionable salary" biggerHeading={true} />
      </div>
      <div className="d-flex dark-content-section mb-4">
        <Row>
          <Col className="d-flex align-items-start">
            <Form>
              <Form.Group className=" pe-0 switch-btn-green">
                <Form.Label className="form-label">{t('template_calculation')}</Form.Label>
                <Form.Check
                  type="switch"
                  bsPrefix="chart-switch"
                  className="fs-name mb-1"
                  inline
                  label=""
                  id="pensionableSalary"
                  onChange={togglePensionableSalary}
                  checked={isPensionable}
                  name="isPensionable"
                />
              </Form.Group>
            </Form>
          </Col>
          <Col className="d-flex align-items-start">
            <div className="ms-4">
              <p className="m-0 text-label-note">{t('PENSION_POLICY.pensionable_salary_text')}</p>
            </div>
          </Col>
        </Row>
      </div>
      <ErrorFeedback behavior="alert" field={formGroup!.getFieldMeta('policySettings.salaryType')} />

      {!isPensionable && (
        <>
          <p className="text-black">{t('pension_policy_salary_types')}.</p>
          <div className="table-responsive react-bootstrap-table">
            <table className="table table-condensed salary-types-table">
              <thead className="header-wrapper">
                <tr className="correcto-table-header">
                  <th style={{ maxWidth: 80, width: 80 }}>{t('include')}</th>
                  <th style={{ maxWidth: 100, width: 100 }}>{t('salary_code')}</th>
                  <th style={{ maxWidth: 300, width: 300 }}>{t('name')}</th>
                  <th style={{ maxWidth: 108, width: 108 }}>{t('percentage')} (%)</th>
                </tr>
              </thead>
              <tbody>
                {companySalaryTypes.length > 0 && (
                  <>
                    <tr className="section-divider">
                      <td colSpan={4}>
                        <div>{t('PENSION_POLICY.company_salary_types')}</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0" colSpan={4}>
                        <table className="table table-hover table-condensed salary-types-table-child">
                          <tbody className="classic-table-body">
                            {companySalaryTypes?.map((salary, index) => (
                              <SalaryTypeItem
                                salary={salary}
                                index={index}
                                key={index}
                                handleCheckedRow={handleCheckedRow}
                                handleSalaryChange={handleSalaryTypePercentageChange}
                                type="company"
                              />
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                )}
                {commonSalaryTypes.length > 0 && (
                  <>
                    <tr className="section-divider">
                      <td colSpan={4}>
                        <div className="w-100">{t('PENSION_POLICY.common_salary_types')}</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0" colSpan={4}>
                        <table className="table table-hover table-condensed salary-types-table-child">
                          <tbody className="classic-table-body">
                            {commonSalaryTypes?.map((salary, index) => (
                              <SalaryTypeItem
                                salary={salary}
                                index={index}
                                key={index}
                                handleCheckedRow={handleCheckedRow}
                                handleSalaryChange={handleSalaryTypePercentageChange}
                                type="common"
                              />
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                )}
                {otherSalaryTypes.length > 0 && (
                  <>
                    <tr className="section-divider">
                      <td colSpan={4}>
                        <div>{t('PENSION_POLICY.other_salary_types')}</div>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0" colSpan={4}>
                        <table className="table table-hover table-condensed salary-types-table-child">
                          <tbody className="classic-table-body">
                            {otherSalaryTypes?.map((salary, index) => (
                              <SalaryTypeItem
                                salary={salary}
                                index={index}
                                key={index}
                                handleCheckedRow={handleCheckedRow}
                                handleSalaryChange={handleSalaryTypePercentageChange}
                                type="other"
                              />
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
      {isPensionable && (
        <>
          {formGroup.values.policySettings.salaryType?.salaryTypesWithHolidays
            .filter((salary, salaryIndex) => hasShownHolidaySetupItem(salary, salaryIndex))
            .map((salary, setupIndex) => (
              <div className="dark-content-section mb-3" key={setupIndex}>
                {/* {!isPersonalPolicyMode() && salaryIndex !== 0 && (
                  <Form className="d-flex mt-4 mb-4">
                    <Form.Group className=" pe-0 switch-btn-green">
                      <Form.Label className="form-label">{getLang(salary.setupName)}</Form.Label>
                      <Form.Check
                        type="switch"
                        bsPrefix="chart-switch"
                        className="fs-name mb-1"
                        inline
                        label=""
                        id="holidaySetup"
                        onChange={(e) => toggleHolidaySetup(e, salaryIndex)}
                        checked={isCheckedHolidaySetup(salaryIndex)}
                        name="holidaySetup"
                      />
                    </Form.Group>
                  </Form>
                )} */}
                <div className="holiday-setup__header d-flex justify-content-between align-items-center mb-4">
                  <h3 className="fs-h3 theme-text mb-4">
                    {t('PENSION_POLICY.vacation_days_setup', { number: setupIndex + 1 })}
                  </h3>
                  {setupIndex !== 0 && (
                    <div className="header-action">
                      <Button
                        onClick={() => onRemoveHoldaySalaryItemSetup(setupIndex)}
                        className="app-btn-secondary me-1"
                      >
                        <Trash2 /> Delete setup
                      </Button>
                    </div>
                  )}
                </div>

                {/* {!isPersonalPolicyMode() && salaryIndex === 0 && (
                  <p className="text-black">{getLang(salary.setupName)}</p>
                )} */}
                <>
                  <Form.Group className="holiday-days mb-2">
                    <Form.Label className="fs-name">{t('holiday_days_per_year')}</Form.Label>
                    <Form.Select
                      name="holidayDaysPerYear"
                      onChange={(e) => handleHolidayDaysPerYear(e, setupIndex)}
                      value={salary.holidayDaysPerYear}
                    >
                      {HOLIDAYS_OPTIONS.map((value) => (
                        <option value={value} key={value}>
                          {value}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <div className="table-responsive react-bootstrap-table">
                    <table className="table table-hover table-condensed table-transparent">
                      <thead className="header-wrapper">
                        <tr className="correcto-table-header">
                          <th style={{ width: 250 }}>{t('salary_type')}</th>
                          <th style={{ width: 320 }}>{t('holiday_pay_multiple')} (%)</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="classic-table-body">
                        {salary?.data.map((salaryTypeRow, salarytypeRowIndex) => (
                          <tr key={salarytypeRowIndex} className="highlight-inactive highlight-inactive--hover">
                            <td>
                              {salarytypeRowIndex !== 0 && (
                                <Form.Select
                                  name="salaryType"
                                  onChange={(e) => handleHolidaySalaryCodeChange(e, setupIndex, salarytypeRowIndex)}
                                  value={salaryTypeRow.salaryType?.id}
                                  defaultValue={''}
                                >
                                  <option disabled value="">
                                    {t('select')}...
                                  </option>
                                  {salaryTypes?.map((value, salaryIndex) => (
                                    <option value={value._id} key={`${salaryIndex}_${salarytypeRowIndex}`}>
                                      {value.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              )}
                              {salarytypeRowIndex === 0 && (
                                <Form.Control disabled={true} value={getLang(salaryTypeRow.salaryType?.name)} />
                              )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center ">
                                <PercentageNumber
                                  value={salaryTypeRow.holidayPayMultiple}
                                  onChangeHandler={(e) =>
                                    handleHolidayPercentageChange(e, setupIndex, salarytypeRowIndex)
                                  }
                                  name="holidayPayMultiple"
                                  className="text-right"
                                  decimalPlace={2}
                                  maxWidth={80}
                                />
                                <Button
                                  onClick={() => toggleStandardValue(setupIndex, salarytypeRowIndex)}
                                  className="app-btn-secondary ms-3"
                                  disabled={
                                    getStandardHolidayPay(salary.holidayDaysPerYear) ===
                                    salaryTypeRow.holidayPayMultiple
                                  }
                                >
                                  <RefreshCw className="icon-gap-right" /> Standard value
                                </Button>
                              </div>
                            </td>
                            <td align="right">
                              {salarytypeRowIndex !== 0 && (
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => deleteSalarySetup(setupIndex, salarytypeRowIndex)}
                                >
                                  <TooltipBox msg={`${t('delete')}`}>
                                    <Trash2 color="#930000" />
                                  </TooltipBox>
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Button onClick={() => onAddSalaryTypeRowPerSetup(setupIndex)} className="app-btn-secondary me-1">
                      <Plus />
                    </Button>
                  </div>
                </>
              </div>
            ))}
          {!isPersonalPolicyMode() && (
            <div className="mt-3">
              <Button onClick={() => onAddHoldaySalaryItemSetup()} className="app-btn-secondary me-1">
                <Plus /> Add holiday setup
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default React.memo(SalaryTypesTable);
