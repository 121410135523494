import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
    effective_from: Yup.string().required('Required'),
    status: Yup.string(),
    type: Yup.string().required('Required').oneOf(['CollectiveAgreement', 'Company'], 'Invalid type'),
    plan: Yup.object().shape({
        salaryInterval: Yup.string(),
        totalSalary: Yup.object().shape({
            percentageValue: Yup.string()
                .required('Required')
                .test('greaterThanZero', 'Value must be greater than zero', (value) => {
                    const input = Number(value);

                    if (!input) return false;

                    if (isNaN(input)) {
                        return false;
                    }

                    return input > 0;
                }),
            fixedValue: Yup.string(),
        }),
        partsUnder: Yup.object().shape({
            percentageValue: Yup.string(),
            fixedValue: Yup.string(),
        }),
        partsOver: Yup.object().shape({
            percentageValue: Yup.string(),
            fixedValue: Yup.string(),
        }),
    }),

    salaryCodes: Yup.object().shape({
        data: Yup.array()
            .of(
                Yup.object().shape({
                    _id: Yup.string(),
                    percentage: Yup.number(),
                    selected: Yup.boolean(),
                    name: Yup.string(),
                    code: Yup.number(),
                    includedInPension: Yup.boolean(),
                    unit: Yup.string(),
                    type: Yup.string(),
                }),
            )
            .min(1, 'At least one salary code is required')
            .test({
                name: 'salaryCodeGroupValidation',
                test: (arr) => (arr ? arr?.length > 0 : false),
                message: 'Salary Code is required',
            }),
    }),

    pensionCompanies: Yup.object().shape({
        data: Yup.array()
            .of(
                Yup.object().shape({
                    _id: Yup.string(),
                    selected: Yup.boolean(),
                    default: Yup.boolean(),
                    short_name: Yup.string(),
                    company_name: Yup.string(),
                    address: Yup.string(),
                    postal_code: Yup.string(),
                    city: Yup.string(),
                    organization_number: Yup.string(),
                    vat_number: Yup.string(),
                    phone: Yup.string(),
                    fax: Yup.string(),
                    pension_type: Yup.string(),
                }),
            )
            .min(1, 'At least one pension company is required')
            .test({
                name: 'pensionCompanyGroupValidation',
                test: (arr) => (arr ? arr?.length > 0 : false),
                message: 'Pension Company is required',
            }),
    }),
});
