/* eslint-disable react/prop-types */
import { Container, Tabs, Tab } from 'react-bootstrap';
import Breadcrumb from '../../../components/base/Breadcrumb';
import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import BusinessAnalyticsChart from '../../../components/charts/BusinessAnalyticsChart';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
// import MonthPicker from '../../../components/charts/MonthPicker';
import { FinanceSpecification } from './FinanceSpecification';
import { t } from 'i18next';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Business Analysis',
  },
];

function BusinessAnalysisPage() {
  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader pageTitle="Business Analysis" headingClass="fs-h1" />

      <CardComponent className="p-0 poistion-relative">
        {/* <div className="w-auto" style={{ float: 'right' }}>
          <MonthPicker rangeValue={rangeValue} handleRangeDismiss={handleRangeDismiss} />
        </div> */}

        <Tabs>
          <Tab
            style={{ borderTop: '2px solid #5F5F5F' }}
            tabClassName="active-invert me-2 fs-label font-color-primary border-radius-all"
            eventKey="base_salary"
            title={t('base_salary')}
          >
            <BusinessAnalyticsChart chartId="0" type="base-salaries" />
          </Tab>
          <Tab
            style={{ borderTop: '2px solid #5F5F5F' }}
            tabClassName="active-invert me-2 fs-label font-color-primary border-radius-all"
            eventKey="real_salary"
            title={t('real_salary')}
          >
            <BusinessAnalyticsChart chartId="3" type="real-salaries" />
          </Tab>
          <Tab
            style={{ borderTop: '2px solid #5F5F5F' }}
            tabClassName="active-invert me-2 fs-label font-color-primary border-radius-all"
            eventKey="total_costs"
            title={t('salary_change')}
          >
            <BusinessAnalyticsChart chartId="1" type="salary-changes" />
          </Tab>
          {/* <Tab
            style={{ borderTop: '2px solid #5F5F5F' }}
            tabClassName="active-invert me-2 fs-label font-color-primary border-radius-all"
            eventKey="employee_costs"
            title={`Special payroll tax`}
          >
            <BusinessAnalyticsChart chartId="2" />
          </Tab> */}
        </Tabs>
      </CardComponent>

      <FinanceSpecification />
      {/* <PensionCompaniesSlider /> */}
      {/* <CardComponent>
        <MonthlyStepper headerTitle="Pension cost" datasource={PENSION_COST} yearRange={[2022, 2023]} />
      </CardComponent> */}
    </Container>
  );
}

export default BusinessAnalysisPage;
