import { useState, useEffect } from 'react';
import { MultiSeriesChart } from '../../../components/charts/multi-series/MultiSeriesChart';
import { PieChart } from '../../../components/charts/piechart/PieChart';
import { Calendar } from '../../../util/calendar';
import { ISeries } from '../../../components/charts/piechart/types';
import moment from 'moment';
import { ISeriesOptions } from '../../../components/charts/multi-series/types';
import useAuth from '../../../hooks/useAuth';
import { UserRole } from '../../../types/auth';
import { useTranslation } from 'react-i18next';

type Props = {
  lineChartTitle?: string;
  pieChartTitle?: string;
  monochromePie?: boolean;
  chartData: any;
  isLoading?: boolean;
};

export const ChartsSection = ({
  lineChartTitle,
  pieChartTitle,
  monochromePie = false,
  chartData,
  isLoading,
}: Props) => {
  const [overviewLineChartData, setOverviewLineChartData] = useState<any[]>([]);
  const [overviewPieChartData, setOverviewPieChartData] = useState<any[]>();
  const { userRole } = useAuth();
  const { t } = useTranslation();
  const [dateRange /*setDateRange*/] = useState<string[]>(Calendar.getMonthsToYearBoundary(6));
  const [companySeries /* setCompanySeries*/] = useState<ISeries[]>([]);
  const [companyPieChartSeries /* setCompanyPieChartSeries*/] = useState<ISeries[]>([]);
  const seriesOptions: ISeriesOptions = {
    series: companySeries,
  };
  const companyClientSeriesOptions: ISeriesOptions = {
    series: companyPieChartSeries,
  };
  const formatLineChartData = () => {
    const formattedData: any = [];
    chartData?.pieChart?.dataset?.map((item, index) =>
      companyPieChartSeries.push({ label: item?.label, field: item?.count }),
    );
    chartData?.lineChart?.[0]?.dataset?.map((item) => companySeries.push({ label: item?.label, field: item?.label }));

    chartData?.lineChart
      ?.sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
      .forEach((value) => {
        formattedData.push([
          moment(value.date).startOf('month').format('YYYY-MM-DD'),
          ...value.dataset.map((item) => item.value),
        ]);
      });

    setOverviewLineChartData(formattedData);
    setOverviewPieChartData(chartData?.pieChart);
  };

  useEffect(() => {
    formatLineChartData();
  }, [chartData]);

  return (
    <div className="d-flex chart-section-wrapper mt-2">
      <div className="line-chart-section dark-content-section">
        <MultiSeriesChart
          initialDateRange={dateRange}
          data={overviewLineChartData}
          seriesOptions={seriesOptions}
          isLoading={isLoading}
          showDateSelector={false}
          showRangeSelector={false}
          chartTitle={lineChartTitle ?? `${t('total_costs')}`}
          containerStyle={userRole === UserRole.ACCOUNTING_FIRM ? { width: '600px', maxWidth: '100%' } : null}
        />
      </div>
      <div className="ms-2 pie-chart-section dark-content-section">
        <PieChart
          data={overviewPieChartData}
          seriesOptions={companyClientSeriesOptions}
          isLoading={isLoading}
          chartTitle={pieChartTitle ?? `${t('pension_companies')}`}
          containerStyle={userRole === UserRole.ACCOUNTING_FIRM ? { width: '400px', maxWidth: '100%' } : null}
        />
      </div>
    </div>
  );
};
