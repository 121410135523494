import { Menu } from 'react-feather';
import { useTranslation } from 'react-i18next';
import useEmployeeBenefitReport from './useEmployeeBenefitReport';
import { ReportInputProps } from './types';
import { EmployeeBenefitReportType, ReportResponseField } from '../../../../types/report';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import BSTable from '../../../../components/table/BSTable';
import { REPORT_EXPORT_OPTIONS } from '../constants';

const InsuranceReport = ({ selectedFilter }: ReportInputProps) => {
  const { t } = useTranslation();
  const { reportHeaders, reportRecords, isLoading, exportCSV, isExporting } = useEmployeeBenefitReport(
    EmployeeBenefitReportType.INSURANCE,
    selectedFilter,
    ReportResponseField.INSURANCE,
  );

  return (
    <div className="mt-4 mb-4">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-start my-2 mb-4">
          <h3 className="fs-h3 font-black theme-text">{t('insurance')}</h3>
          {isLoading && <Spinner size="sm" className="ms-2" />}
        </div>
        {isExporting ? (
          <Spinner />
        ) : (
          <Dropdown className="nav-item" align="end">
            <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
              <Button className="app-btn-secondary">
                <Menu className="theme-text" />
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {REPORT_EXPORT_OPTIONS.map((report) => (
                <Dropdown.Item onClick={() => exportCSV(report.value)} key={report.value}>
                  {report.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>

      {isLoading && reportHeaders.length === 0 ? (
        <Spinner />
      ) : (
        <div className="">
          <BSTable data={reportRecords} columns={reportHeaders} noDataIndication={`${t('no_data_found')}`} />
        </div>
      )}
    </div>
  );
};

export default InsuranceReport;
