type Props = {
  btn?: {
    text: string;
    disabled?: boolean;
    onClick?: () => void;
  };
  title: string;
};

function ReportHeader({ btn, title }: Props) {
  return (
    <div className="text-end d-flex justify-content-between align-items-center">
      <h3 className="me-2 fs-page-title theme-text">{title}</h3>
      {/* <Button onClick={btn.onClick} variant="primary" disabled={btn.disabled}>
        {btn.text.toLowerCase().includes('prev') ? (
          <>
            <ChevronLeft className="icon-gap-right" /> {btn.text}
          </>
        ) : (
          <>
            {btn.text} <ChevronRight className="icon-gap-left" />
          </>
        )}
      </Button> */}
    </div>
  );
}

export default ReportHeader;
