import { Dispatch, SetStateAction, useState, useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import BSModal from '../../../../components/Modal';
import CreateClientModalFooter from './CreateClientModalFooter';
import { useCreateClientContext } from '../context/useContext';
import {
  CompanyDetails,
  ContractDetails,
  AuthorizedSignatory,
  AdminDetails,
  Consultants,
  Invitation,
} from '../ClientForms';

import { INITIAL_COMPANY_DETAILS, INITIAL_ADDRESS_DETAILS, INITIAL_CONTACT_PEOPLE } from '../contants';
import { useTranslation } from 'react-i18next';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';
import { getClientContractNumber } from '../../../../api/service/accountingFirm/clients/service';
import { AccessLevel } from '../ClientForms/Access';
import { FileUpload } from '../ClientForms/FileUpload';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsClientCreated: Dispatch<SetStateAction<boolean>>;
};

const CreateClientModal = ({ showCreateModal, toggleModal, setIsClientCreated }: Props) => {
  const { errors, isInvalid, setErrors } = useValidationError();
  const { t } = useTranslation();
  const {
    setAddressDetails,
    setCompanyDetails,
    setSignatoryDetails,
    setAdminDetails,
    setSalaryContactPeople,
    setAccountingContactPeople,
    setContractEffectiveFrom,
    setInvitationEmail,
    updateContractNumber,
    formGroup,
  } = useCreateClientContext();
  const [isContractLoading, setIsContractLoading] = useState(false);
  const fetchContractNumber = useCallback(async () => {
    try {
      setIsContractLoading(true);

      const res = await getClientContractNumber();
      updateContractNumber(res);
    } finally {
      setIsContractLoading(false);
    }
  }, []);
  useEffect(() => {
    if (showCreateModal) {
      fetchContractNumber();
    }
  }, [showCreateModal]);
  return (
    <>
      <BSModal
        title={`${t('new_client')}`}
        show={showCreateModal}
        onHide={() => {
          setCompanyDetails(INITIAL_COMPANY_DETAILS);
          setAddressDetails(INITIAL_ADDRESS_DETAILS);
          setSignatoryDetails([]);
          setAdminDetails([]);
          setSalaryContactPeople(INITIAL_CONTACT_PEOPLE);
          setAccountingContactPeople(INITIAL_CONTACT_PEOPLE);
          setContractEffectiveFrom('');
          setInvitationEmail('');
          toggleModal();
        }}
        centered
        ModalFooter={
          <CreateClientModalFooter
            setIsClientCreated={setIsClientCreated}
            toggleModal={toggleModal}
            setErrors={setErrors}
            formGroup={formGroup!}
          />
        }
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />

          <CompanyDetails context={useCreateClientContext()} formGroup={formGroup!} />

          <ContractDetails
            isContractLoading={isContractLoading}
            context={useCreateClientContext()}
            formGroup={formGroup!}
          />

          <AuthorizedSignatory context={useCreateClientContext()} formGroup={formGroup!} />

          <AdminDetails context={useCreateClientContext()} formGroup={formGroup!} />

          <Consultants context={useCreateClientContext()} formGroup={formGroup!} />

          {/* <ContactPeople context={useCreateClientContext()} formGroup={formGroup!} /> */}
          <AccessLevel context={useCreateClientContext()} formGroup={formGroup!} />

          <FileUpload context={useCreateClientContext()} formGroup={formGroup!} />

          <Invitation context={useCreateClientContext()} formGroup={formGroup!} />
        </Form>
      </BSModal>
    </>
  );
};

export default CreateClientModal;
