import { ColumnType } from '../../../../components/table/BSTable';
import tableHeaderFormatter from '../../../../components/table/TableHeaderFormatter';
import { DeleteAction } from './actions';

export const useColumns = (): ColumnType => {
  return [
    {
      text: 'company (short name)',
      dataField: 'shortName',
      // headerFormatter: tableHeaderFormatter,
    },
    {
      text: 'Description',
      dataField: 'description',
      formatter: (cell) => cell ?? 'This is sample description',
      headerFormatter: tableHeaderFormatter,
    },
    {
      text: '',
      dataField: 'id',
      align: 'right',
      formatter: (_, row) => <DeleteAction id={row.id} />,
    },
  ];
};
