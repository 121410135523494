import React from 'react';

import { Button, Col, Dropdown, Row } from 'react-bootstrap';

import { Calendar, RefreshCw } from 'react-feather';
import useLocale from '../../hooks/useLocale';
import classNames from 'classnames';

type Props = {
  pageTitle: string;
  headingClass?: string;
  showCalender?: boolean;
  showRefreshBtn?: boolean;
  translate?: boolean;
  subText?: string | null;
  biggerHeading?: boolean;
  subTextPosition?: 'right' | 'bottom';
};

const PagesHeader = ({
  pageTitle,
  showCalender = false,
  showRefreshBtn = false,
  headingClass = 'fs-h3',
  translate = true,
  biggerHeading = false,
  subText,
  subTextPosition = 'right',
}: Props) => {
  const { localize } = useLocale();
  const _pageTitle = pageTitle?.toLowerCase().split(' ').join('_');
  const _subText = subText ? subText.toLowerCase().split(' ').join('_') : null;
  const headingSpacingWhenSubTextBottom = _subText && subTextPosition === 'bottom' ? 'mb-1' : '';

  return (
    <Row className="mb-2">
      <Col xs="auto" className="d-none d-sm-block">
        {biggerHeading ? (
          <h2
            className={classNames(
              `fs-h2 theme-text`,
              headingClass !== 'fs-h3' ? headingClass : '',
              headingSpacingWhenSubTextBottom,
            )}
          >
            {translate ? localize(_pageTitle) : pageTitle}
            {_subText && subTextPosition === 'right' ? (
              <span className="theme-subtext">({translate ? localize(_subText) : subText})</span>
            ) : null}
          </h2>
        ) : (
          <h3 className={`${headingClass} theme-text ${headingSpacingWhenSubTextBottom}`}>
            {translate ? localize(_pageTitle) : pageTitle}
            {_subText && subTextPosition === 'right' ? (
              <span className="theme-subtext">({translate ? localize(_subText) : subText})</span>
            ) : null}
          </h3>
        )}
        {_subText && subTextPosition === 'bottom' && (
          <span className="theme-text">{translate ? localize(_subText) : subText}</span>
        )}
      </Col>

      <Col xs="auto" className="ms-auto text-end mt-n1">
        {showCalender && (
          <Dropdown className="d-inline me-2">
            <Dropdown.Toggle variant="light" className="bg-white shadow-sm">
              <Calendar className="feather align-middle mt-n1 icon-gap-right" /> Today
            </Dropdown.Toggle>
          </Dropdown>
        )}

        {showRefreshBtn && (
          <Button variant="primary" className="shadow-sm">
            <RefreshCw className="feather" />
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default PagesHeader;
