import classNames from 'classnames';
import Swal from 'sweetalert2';
import { SweetAlertResult } from 'sweetalert2/src/types';

export type DialogOption = {
  cancelBtnClass: string;
};

export const ConfirmationDialog = ({
  title = 'title',
  confirmButtonText,
  denyButtonText,
  width = 700,
  option = null,
}: any): SweetAlertResult<any> => {
  return Swal.fire({
    width,
    // heightAuto: false,
    title: `<h2 class="${classNames('fs-h1 text-left', option?.headingClass || '')}">${title}</h2>`,
    showCancelButton: false,
    showCloseButton: false,
    showDenyButton: true,
    confirmButtonText: confirmButtonText,
    denyButtonText: denyButtonText,
    reverseButtons: true,
    customClass: {
      confirmButton: 'app-confirm-btn app-btn',
      denyButton: classNames('app-deny-btn app-btn', option?.cancelBtnClass),
    },
  }).then((result) => {
    return result;
  });
};

export const DialogWithTextField = ({
  title,
  text,
  content,
  confirmButtonText,
  denyButtonText,
  validationTitle = '',
  validationMessage,
  denyBtnClass,
  confirmBtnClass,
  showConfirmationInput = true,
}: any): SweetAlertResult<any> => {
  const captionElement = content?.caption
    ? ` <p class="confirm-modal-text theme-text fs-name mb-1">${content?.caption}:</p>`
    : '';
  const messageElement = content?.body ? `<p class="fs-14px text-bold theme-text mb-0">"${content?.body}"</p>` : '';
  const bodyElement = `<div class="confirm-model text-left">${captionElement}${messageElement}</div>`;
  const titleElement = `<div class="confirm-model text-left"><h1 class="fs-h1 mb-3 theme-text confirm-modal-title">${title}?</h1></div>`;

  return Swal.fire({
    width: 700,
    heightAuto: false,
    html: bodyElement,
    title:titleElement,
    input: showConfirmationInput ? 'text' : undefined,
    inputAttributes: {
      autocapitalize: 'off',
    },
    showCancelButton: false,
    showCloseButton: false,
    showDenyButton: true,
    confirmButtonText,
    denyButtonText,
    reverseButtons: true,
    customClass: {
      popup: 'textfield-popup',
      confirmButton: classNames('app-confirm-btn app-btn',confirmBtnClass || ''),
      denyButton: classNames('app-deny-btn app-btn',denyBtnClass || ''),
    },
    preConfirm: showConfirmationInput
      ? (inputValue) => {
          if (inputValue !== validationTitle) {
            return Swal.showValidationMessage(validationMessage);
          } else {
            return inputValue;
          }
        }
      : undefined,
  }).then((result) => result);
};
