import React, { Dispatch, SetStateAction, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CardComponent from '../../../components/base/CardComponent';
import BSRemoteTable from '../../../components/table/BSRemoteTable';
import MonthAndYearDropdown from '../../../section/MonthAndYearDropdown';
// import Pagination from '../../company/Pagination';
import BillingDetailsModal from './BillingDetailsModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { tableHeaderText } from '../../../components/table/TableHeaderFormatter';

const data = [
  {
    _id: '123',
    companyName: 'Company ABC AB',
    organizationNumber: '662233-2344',
    sumToInvoice: '3 028',
    approved: false,
    discount: 10,
  },
  {
    _id: '3123',
    companyName: 'Company ABC AB',
    organizationNumber: '662233-2344',
    sumToInvoice: '3 028',
    approved: true,
    discount: 10,
  },
  {
    _id: '12412',
    companyName: 'Company ABC AB',
    organizationNumber: '662233-2344',
    sumToInvoice: '3 028',
    approved: false,
    discount: 10,
  },
  {
    _id: '4124',
    companyName: 'Company ABC AB',
    organizationNumber: '662233-2344',
    sumToInvoice: '3 028',
    approved: true,
    discount: 10,
  },
];

export default function BillingGrid({
  period,
  setPeriod,
}: {
  period: string;
  setPeriod: Dispatch<SetStateAction<string>>;
}) {
  const [showBillingDetails, setShowBillingDetails] = useState(false);
  const [billingDetails, setBillingDetails] = useState<any>({});
  const { t } = useTranslation();
  const hideApprovedColumn = period ? moment(period).month() === moment().month() : false;

  const toggleModal = () => setShowBillingDetails(!showBillingDetails);

  // const onPagination = async ({ page, sizePerPage }) => {};

  const onBillSelect = (data) => {
    setBillingDetails(data);
    toggleModal();
  };

  return (
    <>
      <CardComponent>
        <Row className="justify-content-between">
          <Col md={2}>
            <Form>
              <Form.Label className="fs-name mb-0">{t('period')}</Form.Label>
              <div className="calender-field-current">
                <MonthAndYearDropdown
                  startFrom={moment().subtract(3, 'months').toString()}
                  value={period}
                  onOptionSelect={(val) => setPeriod(val)}
                  showLabelCurrent
                />
              </div>
            </Form>
          </Col>

          <BSRemoteTable
            onRowClick={onBillSelect}
            data={data}
            columns={[
              { dataField: 'companyName', text: tableHeaderText('Company name'), headerStyle: { width: 150 } },
              {
                dataField: 'organizationNumber',
                text: tableHeaderText('Org.number'),
                headerStyle: { width: 150 },
              },

              {
                dataField: 'discount',
                text: `${tableHeaderText('Discount')} (%)`,
                headerStyle: { width: 150 },
              },

              {
                dataField: 'sumToInvoice',
                text: tableHeaderText('Sum to invoice'),
                //   align: 'right',
                headerStyle: { width: 150 },
              },
              // { dataField: 'id', text: 'Approval', formatter: () => null, headerAlign: 'right' },
            ]}
            sizePerPage={25}
            selectRow={{
              hideSelectColumn: hideApprovedColumn,
              mode: 'checkbox',
              selectColumnPosition: 'right',

              selectionHeaderRenderer: ({ mode, ...rest }) => (
                <Form.Check className="fs-name" label="Approval" {...rest} />
              ),
              selectionRenderer: ({ mode, ...rest }) => {
                const isApproved = data.find((i) => i._id === rest.rowKey)?.approved;
                return isApproved ? (
                  <span style={{ color: '#0D830B', textDecoration: 'italic', fontWeight: 600, fontSize: 12 }}>
                    {t('approved_and_sent')}
                  </span>
                ) : (
                  <div className="form-check">
                    <Form.Check {...rest} />
                  </div>
                );
              },

              headerColumnStyle: {
                width: 200,
              },
            }}
          >
            <div
              style={{
                paddingRight: 'calc(var(--bs-gutter-x) * 0.5)',
                paddingLeft: 'calc(var(--bs-gutter-x) * 0.5)',
              }}
            >
              <Form.Check
                className="mb-4 mt-4 fs-name d-flex align-items-center"
                id="Automatic approval"
                label={t('automatic_approval')}
              />
            </div>
          </BSRemoteTable>
        </Row>
        {/* <Pagination
          total={10}
          itemsPerPage={10}
          currentPage={1}
          onPageChange={(page) => onPagination({ page, sizePerPage: 10 })}
        /> */}
      </CardComponent>

      {showBillingDetails && (
        <BillingDetailsModal billingDetails={billingDetails} show={showBillingDetails} toggleModal={toggleModal} />
      )}
    </>
  );
}
