import { API_VERSION, USERS_ENDPOINT } from "../../../util/constants";
import { get, post, put, del } from "../../../util/httpService";
import { UsersListResponse } from "./type";

const getAllUsers = async (id) => {
    const endpoint = `${API_VERSION}${USERS_ENDPOINT}/company?id=${id}`;
    const usersList = (await get(endpoint)) as UsersListResponse[];
    return usersList;
}
const fetchCompanyAdmins = async () => {
    const endpoint = `${API_VERSION}${USERS_ENDPOINT}/fetch-admins`;
    const usersList = (await get(endpoint))
    return usersList;
}
const addCompanyAdmin = async (body) => {
    const endpoint = `${API_VERSION}${USERS_ENDPOINT}/add-admins`;
    const company = await post(endpoint, { ...body }, {});
    return company
}
const updateCompanyAdmin = async (personalNumber: string | undefined, body) => {
    const endpoint = `${API_VERSION}${USERS_ENDPOINT}/update-admin?personalNumber=${personalNumber}`;
    const company = await put(endpoint, { ...body }, {});
    return company;
}
const deleteCompanyAdmin = async (personalNumber: string) => {
    const endpoint = `${API_VERSION}${USERS_ENDPOINT}/delete-admin/${personalNumber}`;
    const company = await del(endpoint, {});
    return company;
}

const fetchInsuranceAdviors = async () => {
    const endpoint = `${API_VERSION}${USERS_ENDPOINT}/fetch-advisors`;
    const usersList = (await get(endpoint))
    return usersList;
}

const deleteAdvisor = async (personalNumber: string) => {
    const endpoint = `${API_VERSION}${USERS_ENDPOINT}/delete-advisor/${personalNumber}`;
    const company = await del(endpoint, {});
    return company;
}
const addCompanyAdvisor = async (body) => {
    const endpoint = `${API_VERSION}${USERS_ENDPOINT}/add-advisors`;
    const company = await post(endpoint, { ...body }, {});
    return company
}
export {
    getAllUsers,
    fetchCompanyAdmins,
    addCompanyAdmin,
    updateCompanyAdmin,
    deleteCompanyAdmin,
    fetchInsuranceAdviors,
    deleteAdvisor,
    addCompanyAdvisor
}