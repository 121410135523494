import { deepClone } from '../../../../util/util';
import useContext from '../PensionPolicyContext/useContext';

import { PENSION_POLICY_MOD_KEY } from '../types';

const useSalaryTypeForm = () => {
  const { formGroup } = useContext();

  const updateSalaryTypeInputByCode = (code, key, value) => {
    const choosenSalaryTypes = deepClone(
      (formGroup.getFieldMeta(PENSION_POLICY_MOD_KEY.SALARY_TYPE.DEFAULT).value as any[]) || [],
    );

    const foundDetail = choosenSalaryTypes.find((i) => +i['code'] === +code);

    if (foundDetail) {
      foundDetail[key] = value;

      formGroup.setFieldValue(PENSION_POLICY_MOD_KEY.SALARY_TYPE.DEFAULT, choosenSalaryTypes);
    }
  };

  const pushToSalaryType = (salaryTypeItem) => {
    const chosenSalaryTypes = [
      ...((formGroup.getFieldMeta(PENSION_POLICY_MOD_KEY.SALARY_TYPE.DEFAULT).value as any[]) || []),
    ];

    const model = toRequestModelWhenOptionOff(salaryTypeItem);

    chosenSalaryTypes.push(model);

    formGroup.setFieldValue(PENSION_POLICY_MOD_KEY.SALARY_TYPE.DEFAULT, chosenSalaryTypes);
  };

  const removeFromSalaryType = (salaryTypeItem) => {
    const chosenSalaryTypes = [
      ...((formGroup.getFieldMeta(PENSION_POLICY_MOD_KEY.SALARY_TYPE.DEFAULT).value as any[]) || []),
    ];
    const filteredDataset = chosenSalaryTypes.filter((i) => +i['code'] !== +salaryTypeItem.code);

    formGroup.setFieldValue(PENSION_POLICY_MOD_KEY.SALARY_TYPE.DEFAULT, filteredDataset);
  };

  const isCheckedSalaryType = (code: any) => {
    const chosenSalaryTypes = [
      ...((formGroup.getFieldMeta(PENSION_POLICY_MOD_KEY.SALARY_TYPE.DEFAULT).value as any[]) || []),
    ];
    const foundDetail = chosenSalaryTypes.find((i) => +i['code'] === +code);

    if (foundDetail) {
      return true;
    }

    return false;
  };

  const toRequestModelWhenOptionOff = (salaryTypeItem) => {
    const model = {
      id: salaryTypeItem._id,
      selected: true,
      name: salaryTypeItem.name,
      code: salaryTypeItem.code,
      percentage: salaryTypeItem.percentage || 0,
      mapsTo: salaryTypeItem.mapsTo,
      isInclude: salaryTypeItem.includedInPension,
    };

    return model;
  };

  return {
    updateSalaryTypeInputByCode,
    pushToSalaryType,
    removeFromSalaryType,
    isCheckedSalaryType,
  };
};

export default useSalaryTypeForm;
