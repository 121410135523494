import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Form } from 'react-bootstrap';
import { ChevronRight, FilePlus, Search } from 'react-feather';

import Breadcrumb from '../../../components/base/Breadcrumb';
import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import BSRemoteTable from '../../../components/table/BSRemoteTable';
import Pagination from '../../company/Pagination';
import PensionPolicyModal from './CreatePensionPolicy/PensionPolicyModal';

import useContext from './PensionPolicyContext/useContext';
import useLocale from '../../../hooks/useLocale';
import { useColumn } from './PensionPolicyList/useColumn';
import { usePolicyList } from './PensionPolicyList/usePolicyList';

import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import { AuthUser, UserRole } from '../../../types/auth';
import RoleAccess from '../../../components/base/RoleAccess';
import { getCompanyWorkflow } from '../../../api/service/company/settings';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Pension policies',
  },
];

function PensionPoliciesPage() {
  const { localize } = useLocale();
  const navigate = useNavigate();
  const [workflowSetting, setWorkflowSetting] = useState<any>();

  const { isBusy, setIsBusy, setTabKey } = useContext();
  const { result, searchRef, fetchAllPensionPolicy, onDelete, onCopy, onSearch } = usePolicyList({
    setIsBusy,
    isBusy,
  });
  const { pensionPolicyListColumns } = useColumn({
    isBusy,
    onCopy,
    onDelete,
    workflowSetting,
  });

  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const isSuperAdmin = user?.role?.role === UserRole.SUPER_ADMIN;
  const isCompanyAdmin = user?.role?.role === UserRole.COMPANY_ADMIN;
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isPolicyCreated, setIsPolicyCreated] = useState<boolean>(false);

  /* const navigateToCreatePolicy = () => {
    setPolicyForm(INITAL_ADMIN_POLICY_FORM);
    setOccupationalPension(INITIAL_OCCUPATIONAL_PENSION);
    setSalaryTypes(INITIAL_SALARY_TYPE);
    setPensionCompanies({ data: [] });
    setTabKey('deal');
    navigate('/pension-policies/create');
  }; */

  const totalPages = result?.metaData ? Math.ceil(result?.metaData.total / result?.metaData?.page.size) : 0;

  const onPagination = async ({ page, sizePerPage }) => {
    await fetchAllPensionPolicy({ page, sizePerPage });
  };

  const handleTypeChange = (ev) => fetchAllPensionPolicy({ type: ev.target.value });

  const buttonTitle = `${
    !isSuperAdmin ? localize('PENSION_POLICY.add_new_company_policy') : localize('PENSION_POLICY.add_new_policy')
  }`;

  const toggleModal = () => {
    setTabKey('deal');
    setShowCreateModal((val) => !val);

    if (isPolicyCreated) {
      setIsPolicyCreated(false);
    }
  };

  const navigateToWorkflowSettings = () => {
    navigate('/settings?tab=workflow');
  };

  const fetchCompanyWorkflowSettings = async () => {
    if (user?.role?.role !== UserRole.COMPANY_ADMIN) {
      return;
    }

    const policyAssignmentRes = await getCompanyWorkflow();

    setWorkflowSetting(policyAssignmentRes);
  };

  useEffect(() => {
    fetchAllPensionPolicy();
    fetchCompanyWorkflowSettings();
  }, []);

  useEffect(() => {
    if (isPolicyCreated) {
      fetchAllPensionPolicy();
    }
  }, [isPolicyCreated]);

  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader pageTitle="Pension Policies" headingClass="fs-h1" />
        <div className="cr-form-row">
          {isCompanyAdmin && (
            <Button className="app-btn-secondary" onClick={navigateToWorkflowSettings}>
              {localize('PENSION_POLICY.set_auto_assigned')}
              <ChevronRight className="icon-gap-left" />
            </Button>
          )}
          <Button className="app-btn-primary" onClick={toggleModal}>
            <FilePlus className="icon-gap-right" />
            {buttonTitle}
          </Button>
        </div>
      </div>

      <CardComponent>
        <div className="cr-form-row justify-content-between">
          <div className="cr-form-row mb-3 d-flex">
            <div className="input-group d-flex align-items-end search-field-two">
              <input
                ref={searchRef}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearch();
                  }
                }}
                placeholder={`${localize('PENSION_POLICY.search_policy')}`}
                className="form-control"
              />
              <span className="input-group-append bg-white border-left-0">
                <span className="input-group-text ">
                  <Search width={16} height={30} onClick={onSearch} />
                </span>
              </span>
            </div>

            <RoleAccess allowedRoles={[UserRole.COMPANY_ADMIN]}>
              <div className="show-type-field">
                <Form.Label className="fs-name">{localize('show_type')}</Form.Label>
                <Form.Select onChange={handleTypeChange}>
                  <option value="All">{localize('all')}</option>
                  <option value="Company">{localize('company')}</option>
                  <option value="Personal">{localize('personal')}</option>
                  <option value="CorrectoProvided">{localize('correcto_provided')}</option>
                </Form.Select>
              </div>
            </RoleAccess>
          </div>
          <BSRemoteTable
            key={`ppList.${pensionPolicyListColumns.length}`}
            data={result?.data ?? []}
            columns={pensionPolicyListColumns}
            noDataIndication={localize('no_pension_policies_are_found')}
            sizePerPage={result?.metaData['size']}
            onSizePerPageChange={(sizePerPage, page) => onPagination({ sizePerPage, page })}
            keyField="_id"
            onRowClick={(row) => {
              navigate(`/pension-policies/${row._id}`);
            }}
            onTableChange={(type, { sortOrder, sortField }) => {
              if (type === 'sort') {
                fetchAllPensionPolicy({ sortField, sortOrder: sortOrder === 'asc' ? '1' : '-1' });
              }
            }}
          />
        </div>
        {totalPages > 1 && (
          <Pagination
            total={result?.metaData.length ?? 0}
            itemsPerPage={result?.metaData.page.size ?? 0}
            currentPage={result?.metaData.page.current ?? 0}
            onPageChange={(page) => onPagination({ page, sizePerPage: result?.metaData.page.size })}
          />
        )}
      </CardComponent>
      {showCreateModal && (
        <PensionPolicyModal
          setIsPolicyCreated={setIsPolicyCreated}
          toggleModal={toggleModal}
          showCreateModal={showCreateModal}
        />
      )}
    </Container>
  );
}

export default PensionPoliciesPage;
