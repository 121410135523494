import React from 'react';
import { useNavigate } from 'react-router-dom';
import HorizontalRule from '../base/HorizontalRule';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  link?: boolean;
};

function ReportTitle({ title, link }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleNavigate = () => {
    navigate(`/reports/${title}`);
  };

  return (
    <React.Fragment>
      <span className={`fw-bold  mb-1  report-title`} onClick={link ? handleNavigate : () => {}}>
        {t(title)}{' '}
      </span>
      <HorizontalRule />
    </React.Fragment>
  );
}

export default ReportTitle;
