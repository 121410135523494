import { useMemo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { useTranslation } from 'react-i18next';
import { Calendar } from '../../../../util/calendar';
import OccupationalPensionOffsetReport from './OccupationalPensionOffsetReport';
import CardComponent from '../../../../components/base/CardComponent';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import Breadcrumb from '../../../../components/base/Breadcrumb';
import SalaryChangeOffsetReport from './SalaryChangeOffsetReport';
import InsuranceReport from './InsuranceReport';
import { CalenderInput } from '../../../../components/base/CalenderInput';

const EmployeeBenefit = () => {
  const { t } = useTranslation();
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Reports',
      href: '/reports',
    },
    {
      name: t('REPORTS.employee_benefits'),
      translate: false,
    },
  ];

  const [selectedMonth, setSelectedMonth] = useState<string[]>(Calendar.currentMonthBoundary);
  const selectedFilter = useMemo(() => ({ months: selectedMonth }), [selectedMonth]);

  const setMonthFilter = (value) => {
    const month = Calendar.parseDate(value, 'YYYY-MM-DD');
    setSelectedMonth(Calendar.getMonthBoundary(month));
  };

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <Row className="d-flex">
        <Col md="6">
          <PagesHeader headingClass="fs-h1" translate={false} pageTitle={t('REPORTS.employee_benefits')} />
        </Col>
      </Row>
      <CardComponent>
        <Row className="">
          {/* <PagesHeader pageTitle="Employee benefits" headingClass="fs-page-title" /> */}
          <div className="d-flex justify-content-between align-items-center">
            <Form.Group className="me-3">
              <div>
                {' '}
                <Form.Label className="fs-name">{t('period_months')}</Form.Label>
              </div>
              <CalenderInput
                startDate={new Date(selectedMonth[0])}
                maxDate={new Date(Calendar.currentMonthBoundary[1])}
                onChangeHandler={(date) => setMonthFilter(date)}
                dateFormat="MMM yyyy"
                showMonthYearPicker={true}
                datepickerWidth={150}
              />
              {/* <Form.Select
                name="year"
                className="text-left"
                onChange={(e) => {
                  setYearFilter(e.target.value);
                }}
              >
                {years.map((year) => (
                  <option value={year} key={year}>
                    {year} {Calendar.isCurrentYear(year) ? 'YTD' : ''}
                  </option>
                ))}
              </Form.Select> */}
            </Form.Group>
          </div>
          <OccupationalPensionOffsetReport selectedFilter={selectedFilter} />
          <SalaryChangeOffsetReport selectedFilter={selectedFilter} />
          <InsuranceReport selectedFilter={selectedFilter} />
        </Row>
      </CardComponent>
    </>
  );
};

export default EmployeeBenefit;
