import { useCallback, useEffect, useState } from 'react';
import {
  CompanyPolicyWorkflow,
  CompanyPolicyWorkflowEditor,
  CompanyWokflowFormGroup,
  FormOptionInput,
  WorkflowTypeKey,
} from '../types';
import { useValidationError } from '../../../../hooks/useValidationError';
import { ConfirmationDialog } from '../../../../util/dialogs';
import { t } from 'i18next';
import { INITIAL_WORKFLOW_FORM } from '../constants';
import { getCompanyWorkflow, saveompanyWorkflow } from '../../../../api/service/company/settings';
import { getPolicies } from '../../../../api/service/superAdmin/pension-policies/service';
import { EmployeeType } from '../../../employee/types';
import { deepClone, isEqual } from '../../../../util/util';
import { toLowerCase } from '../../../../util';

const useWorkflowEditor = (): CompanyPolicyWorkflowEditor => {
  const { /* errors, isInvalid, */ setErrors } = useValidationError();
  const [inputOptionList, setInputOptionList] = useState<FormOptionInput>({
    whiteCollarPolicies: [],
    blueCollarPolicies: [],
  });
  const [workflowSettingRes, setWorkflowSettingRes] = useState<CompanyPolicyWorkflow | null>(null);
  const [workflowFormGroup, setWorkflowFormGroup] = useState<CompanyWokflowFormGroup>(deepClone(INITIAL_WORKFLOW_FORM));
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [disableSaveChangesBtn, setDisableSaveChangesBtn] = useState(false);

  const fetchPolicyAssignmentSettings = useCallback(async () => {
    try {
      setIsFetching(true);

      const policyAssignmentRes: CompanyPolicyWorkflow = await getCompanyWorkflow();
      const initialWorkflow = deepClone(INITIAL_WORKFLOW_FORM);

      if (policyAssignmentRes) {
        setWorkflowSettingRes(deepClone(policyAssignmentRes));
        setWorkflowFormGroup({
          blueCollarPolicy: policyAssignmentRes.blueCollarPolicy || initialWorkflow.blueCollarPolicy,
          whiteCollarPolicy: policyAssignmentRes.whiteCollarPolicy || initialWorkflow.whiteCollarPolicy,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  }, [workflowSettingRes, isFetching]);

  const fetchAllPensionPolicy = async () => {
    try {
      getPolicies({
        employeeType: EmployeeType.BLUE_COLLAR,
        selectAll: true,
        type: 'CompanyWithCorrectoProvided',
      })
        .then((res) => {
          setInputOptionList((prev) => {
            return {
              ...prev,
              blueCollarPolicies: res,
            };
          });
        })
        .catch((e: unknown) => {
          console.error(e);
        });

      getPolicies({
        employeeType: EmployeeType.WHITE_COLLAR,
        selectAll: true,
        type: 'CompanyWithCorrectoProvided',
      })
        .then((res) => {
          setInputOptionList((prev) => {
            return {
              ...prev,
              whiteCollarPolicies: res,
            };
          });
        })
        .catch((e: unknown) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleFormInput = (type: WorkflowTypeKey, name: string, value: unknown) => {
    const _workflowForm = { ...workflowFormGroup };

    _workflowForm[type][name] = value;

    setWorkflowFormGroup((prev) => {
      return {
        ...prev,
        ..._workflowForm,
      };
    });
  };

  const saveChanges = useCallback(async () => {
    const selectedBlueCollarPolicy = inputOptionList.blueCollarPolicies.find((item) => {
      const id = item._id || item.id;

      return id === workflowFormGroup.blueCollarPolicy.policyId;
    });
    const selectedWhileCollarPolicy = inputOptionList.whiteCollarPolicies.find((item) => {
      const id = item._id || item.id;

      return id === workflowFormGroup.whiteCollarPolicy.policyId;
    });

    const isBlueCollareAutoAssigned =
      workflowFormGroup.blueCollarPolicy.automatic && workflowFormGroup.blueCollarPolicy.policyId;
    const isWhiteCollareAutoAssigned =
      workflowFormGroup.whiteCollarPolicy.automatic && workflowFormGroup.whiteCollarPolicy.policyId;

    if (isBlueCollareAutoAssigned || isWhiteCollareAutoAssigned) {
      const blueCollarTitle =
        selectedBlueCollarPolicy && isBlueCollareAutoAssigned
          ? t('WORKFLOW.workflow_dialog_text', {
              employeeType: toLowerCase(t('blue_collar')),
              policyName: selectedBlueCollarPolicy?.policyName || '',
            })
          : '';

      const whiteCollarTitle =
        selectedWhileCollarPolicy && isWhiteCollareAutoAssigned
          ? t('WORKFLOW.workflow_dialog_text', {
              employeeType: toLowerCase(t('white_collar')),
              policyName: selectedWhileCollarPolicy?.policyName || '',
            })
          : '';

      const titleSegment = blueCollarTitle ? `<div class="mt-3">${blueCollarTitle}</div>` : '';
      const title = `${whiteCollarTitle}${titleSegment}`;

      const result = await ConfirmationDialog({
        title: title,
        confirmButtonText: t('ok'),
        denyButtonText: t('cancel'),
        width: 700,
        option: { headingClass: 'fs-22px theme-text font-weight-400' },
      });

      if (result.isConfirmed) {
        saveWorkflowSetting();
      }
    } else {
      saveWorkflowSetting();
    }
  }, [isLoading, workflowFormGroup, workflowSettingRes, inputOptionList]);

  const saveWorkflowSetting = async () => {
    try {
      setIsLoading(true);
      setErrors({});

      const payload: CompanyWokflowFormGroup = {
        blueCollarPolicy: {
          ...workflowFormGroup.blueCollarPolicy,
          policyId: workflowFormGroup.blueCollarPolicy.automatic ? workflowFormGroup.blueCollarPolicy.policyId : '',
        },
        whiteCollarPolicy: {
          ...workflowFormGroup.whiteCollarPolicy,
          policyId: workflowFormGroup.whiteCollarPolicy.automatic ? workflowFormGroup.whiteCollarPolicy.policyId : '',
        },
      };
      const _res = await saveompanyWorkflow(payload);
      setWorkflowSettingRes({ ..._res, ...deepClone(payload) });
      setWorkflowFormGroup(deepClone(payload));
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getInitialFormValue = () => {
    const initialWorkflow = deepClone(INITIAL_WORKFLOW_FORM);
    const initialFormGroup: CompanyWokflowFormGroup = workflowSettingRes
      ? {
          whiteCollarPolicy: workflowSettingRes?.whiteCollarPolicy || initialWorkflow.whiteCollarPolicy,
          blueCollarPolicy: workflowSettingRes?.blueCollarPolicy || initialWorkflow.blueCollarPolicy,
        }
      : initialWorkflow;

    return initialFormGroup;
  };

  const cancelChanges = useCallback(() => {
    const initialFormGroup: CompanyWokflowFormGroup = deepClone(getInitialFormValue());

    setWorkflowFormGroup(initialFormGroup);
  }, [workflowFormGroup, workflowSettingRes]);

  const validateWorkFlow = () => {
    let isValidatedWhiteCollar = false;
    let isValidatedBlueCollar = false;

    if (
      !workflowFormGroup.blueCollarPolicy.automatic ||
      (workflowFormGroup.blueCollarPolicy.automatic && workflowFormGroup.blueCollarPolicy.policyId)
    ) {
      isValidatedBlueCollar = true;
    }

    if (
      !workflowFormGroup.whiteCollarPolicy.automatic ||
      (workflowFormGroup.whiteCollarPolicy.automatic && workflowFormGroup.whiteCollarPolicy.policyId)
    ) {
      isValidatedWhiteCollar = true;
    }

    return isValidatedWhiteCollar && isValidatedBlueCollar;
  };

  useEffect(() => {
    const initialFormGroup: CompanyWokflowFormGroup = getInitialFormValue();
    let isValidated = validateWorkFlow();

    setDisableSaveChangesBtn(isValidated ? isEqual(initialFormGroup, workflowFormGroup) : true);
  }, [workflowFormGroup, workflowSettingRes]);

  return {
    workflowFormGroup,
    isLoading,
    disableSaveChangesBtn,
    inputOptionList,
    isFetching,
    setWorkflowFormGroup,
    setInputOptionList,
    saveChanges,
    cancelChanges,
    handleFormInput,
    fetchPolicyAssignmentSettings,
    fetchAllPensionPolicy,
  };
};

export default useWorkflowEditor;
