export const defaultChartOption = {
  title: {
    text: '',
  },
  subtitle: {
    text: null,
  },
  navigator: {
    enabled: false,
  },
  rangeSelector: {
    inputEnabled: true,
    verticalAlign: 'bottom',
    enabled: false,
    buttonTheme: {
      // visibility: 'hidden'
    },
    labelStyle: {
      // visibility: 'hidden'
    },
  },
  tooltip: {
    shared: true,
    false: true,
    enabled: true,
    stickOnContact: true,
    useHTML: true,
    valueDecimals: 2,
    xDateFormat: '%b-%y',
    headerFormat: '<table><tr><th colspan="2">{point.key}</th></tr>',
    pointFormat:
      '<tr style="font-size:13px"><td><span style="color: {series.color};margin-right:2px">●</span>{series.name}: </td>' +
      '<td style="text-align: right;font-weight: 700">{point.y}</td></tr>',
    footerFormat: '</table>',
  },
  exporting: {
    enabled: false,
    csv: {
      dateFormat: '%b-%Y',
      columnHeaderFormatter: function (item, key) {
        if (!key) {
          return 'Month'
        }
        return false
      }
    }
  },
  data: {
    rows: [],
    parsed: function (columns) {
      // Transform dates to UNIX epoch.
      let i = columns[0].length;
      while (i--) {
        columns[0][i] = new Date(columns[0][i]).getTime();
      }
    },
    complete: function (opts) {
      //delete opts.xAxis;
      // Assign each series to their own y-axes
      /*   opts.series.forEach(function (s, ix) {
          s.yAxis = ix;
        }); */
    },
  },
  xAxis: {
    crosshair: {
      width: 20,
    },
    minRange: 1000 * 60 * 60 * 24 * 10, // 10 days
    type: 'datetime',
    labels: {
      format: '{value:%b-%y}'
    }
  },
  // Each series gets its own y-axis since they measure on different scales
  yAxis: [
    {
      min: 0,
      visible: true,
      labels: {
        format: '{text}',
      },
      title: {
        text: null,
      },
    },
    {
      visible: true,
      gridLineWidth: 0,
      labels: {
        format: '{text}',
      },
      title: {
        text: '',
      },
      tickWidth: 1,
      lineWidth: 1,
      min: 0,
    },
  ],
  plotOptions: {
    series: {
      showInNavigator: true,
      showInLegend: true,
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  series: [],
};

export const soundscapeDarkModeOption = {
  chart: {
    backgroundColor: '#3c3c3c',
    borderWidth: 1,
    borderColor: '#ffffff33',
    style: {
      color: '#fff',
      fontFamily: "'Source Sans Pro', sans-serif",
    },
  },
  title: {
    style: {
      color: '#F00',
      font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  navigator: {
    maskFill: 'rgba(255,255,255,0.25)',
  },
  rangeSelector: {
    inputStyle: {
      color: '#ffffff',
      fontWeight: 'bold',
    },
    labelStyle: {
      color: '#ffffff',
      fontWeight: 'bold',
    },
  },
  xAxis: {
    crosshair: {
      color: '#3e464f',
      className: 'hc-crosshair',
    },
    labels: {
      style: {
        color: '#ffffff',
        fontFamily: "'Source Sans Pro', sans-serif",
      },
    },
    lineColor: '#ffffff',
    minorTickColor: '#ffffff',
    tickColor: '#ffffff',
  },
  yAxis: [
    {
      min: 0,
      visible: true,
      labels: {
        format: '{text}',
      },
      title: {
        text: null,
      },
    },
    {
      visible: true,
      opposite: false,
      gridLineWidth: 0,
      title: {
        text: null,
      },
      labels: {
        format: '{text}',
        style: {
          color: '#ffffff',
          fontFamily: "'Source Sans Pro', sans-serif",
        },
      },
      lineColor: '#ffffff',
      lineWidth: 1,
      minorTickColor: '#ffffff',
      tickColor: '#ffffff',
      tickWidth: 1,
    },
  ],
  legend: {
    itemStyle: {
      color: '#ffffff',
    },
    itemHiddenStyle: {
      color: '#b2adad',
    },
  },
  series: [
    {
      color: '#01b8aa',
    },
    {
      color: '#fd625e',
    },
    {
      color: '#ddb60a',
    },
    {
      color: '#26a4eb',
    },
    {
      color: '#f160e3',
    },
    {
      color: '#ff7f0e',
    },
    {
      color: '#2ca02c',
    },
    {
      color: '#d62728',
    },
    {
      color: '#9467bd',
    },
    {
      color: '#8c564b',
    },
    {
      color: '#FF8C00',
    },
  ],
};

export const soundscapeLightModeOption = {
  chart: {
    backgroundColor: null,
    style: {
      color: '#000000',
      fontFamily: "'Source Sans Pro', sans-serif",
    },
  },
  title: {
    style: {
      color: '#F00',
      font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
    },
  },
  navigator: {
    maskFill: 'rgba(255,255,255,0.25)',
  },
  rangeSelector: {
    inputStyle: {
      color: '#000000',
      fontWeight: 'bold',
    },
    labelStyle: {
      color: '#000000',
      fontWeight: 'bold',
    },
  },
  xAxis: {
    crosshair: {
      color: '#ebf0f5',
      className: 'hc-crosshair',
    },
    labels: {
      style: {
        color: '#000000',
        fontFamily: "'Source Sans Pro', sans-serif",
      },
    },
    lineColor: '#000000',
    minorTickColor: '#000000',
    tickColor: '#000000',
  },
  yAxis: [
    {
      min: 0,
      visible: true,
      labels: {
        format: '{text}',
      },
      title: {
        text: null,
      },
    },
    {
      visible: true,
      opposite: false,
      gridLineWidth: 0,
      title: {
        text: null,
      },
      labels: {
        format: '{text}',
        style: {
          color: '#000000',
          fontFamily: "'Source Sans Pro', sans-serif",
        },
      },
      lineColor: '#000000',
      lineWidth: 1,
      minorTickColor: '#000000',
      tickColor: '#000000',
      tickWidth: 1,
    },
  ],
  legend: {
    itemStyle: {
      color: '#000000',
    },
    itemHiddenStyle: {
      color: '#b2adad',
    },
  },
  series: [
    /* 
    {
      color: '#01b8aa',
    },
    {
      color: '#fd625e',
    },
    {
      color: '#ddb60a',
    },
    {
      color: '#26a4eb',
    },
    {
      color: '#f160e3',
    },
    {
      color: '#ff7f0e',
    },
    {
      color: '#2ca02c',
    },
    {
      color: '#d62728',
    },
    {
      color: '#9467bd',
    },
    {
      color: '#8c564b',
    },
    {
      color: '#800000',
    },
   */
  ],
};
