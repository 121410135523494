import { useState } from 'react';

const useCostSpecificationModal = () => {
  const [selectedCostPlanManager, setCostPlanManager] = useState<{ manager: any; planItem: any } | null>();
  const [showCostSpecModal, setShowCostSpecModal] = useState(false);

  const toggleModal = () => {
    setShowCostSpecModal(!showCostSpecModal);
  };

  const showParentModal = () => {
    document.querySelector('.crt-add-pension-policy-modal')!.setAttribute('style', 'display: block !important');
  };

  const hideParentModal = () => {
    document.querySelector('.crt-add-pension-policy-modal')!.setAttribute('style', 'display: none !important');
  };

  return {
    toggleModal,
    showCostSpecModal,
    selectedCostPlanManager,
    setCostPlanManager,
    showParentModal,
    hideParentModal,
  };
};

export default useCostSpecificationModal;
