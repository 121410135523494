import { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { UserX, Plus, UserPlus } from 'react-feather';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import PagesHeader from '../../../../components/base/PagesHeader';
import isEmpty from 'lodash/isEmpty';
import { AccountingFirm, Consultants, FormProps } from '../types';
import { getInitialName } from '../../../../util/util';
import { getAccountingFirms } from '../../../../api/service/superAdmin/accounting-firm/service';
import { UserSubRole } from '../../../../types/auth';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import classNames from 'classnames';
import { errorHighlighter, requiredError } from '../../../../util/form';
import useLocale from '../../../../hooks/useLocale';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

interface ConsultantDataSource extends Consultants {
  selected?: boolean;
}
interface AccountingDataSource extends AccountingFirm {
  supervisors: [];
  selected?: boolean;
}

export default function ExternalConsultant({ context }: FormProps) {
  const {
    consultants,
    accountingFirms,
    setAccountingFirms,
    isEditMode,
    formGroup,
    replaceConsultants,
    replaceConsultant,
    updateConsultant,
    appendDeletedConsultant,
  } = context;
  const { localize } = useLocale();
  const [consultantDataSource, setConsultantDataSource] = useState<ConsultantDataSource[]>([]);
  const [accountingFirmDataSource, setAccountingFirmDataSource] = useState<AccountingDataSource[]>([]);
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [chosenConsultant, setChosenSelector] = useState<Consultants>({
    id: '',
    personalNumber: '',
    email: '',
    fullName: '',
    role: '',
  });
  const [chosenAccountingFirm, setChosenAccountingFirmSelector] = useState<AccountingFirm>({
    _id: '',
    name: '',
    organizationNumber: '',
    seniorConsultants: [],
  });
  const filteredConsultantDataSource = consultantDataSource.filter((item) => {
    const foundSelected =
      isEditMode && consultants.find((consultantItem) => consultantItem.personalNumber === item.personalNumber);

    if (foundSelected) {
      return false;
    }

    if (!item.selected) {
      return true;
    }

    return false;
  });
  const filteredAccountingFirmDataSource = accountingFirmDataSource.filter((item) => {
    const foundSelected =
      isEditMode && accountingFirms.find((accountingFirmItem) => accountingFirmItem._id === item._id);
    if (foundSelected) {
      return false;
    }
    if (!item.selected) {
      return true;
    }

    return false;
  });
  const fetchAccountingFirms = async () => {
    const accountingFirms = await getAccountingFirms({});
    setAccountingFirmDataSource(accountingFirms as any);
  };

  const fetchConsultants = (consultants) => {
    setConsultantDataSource(consultants);
  };

  useEffect(() => {
    fetchAccountingFirms();
  }, [setAccountingFirmDataSource]);

  const onAddHandler = () => {
    replaceConsultant({ _id: '', personalNumber: '' });
    setShowAddBtn((showBtn) => !showBtn);
  };
  const handleChangeConsultant = (e) => {
    const personalNumber = e.target.value;
    const foundConsultant = consultantDataSource.find((i) => i.personalNumber === personalNumber);

    setChosenSelector({
      id: foundConsultant!._id,
      personalNumber: foundConsultant!.personalNumber,
      fullName: foundConsultant!.fullName,
      email: foundConsultant!.email,
      role: foundConsultant?.role ? foundConsultant.role : UserSubRole.SENIOR_CONSULTANT,
    });
    updateConsultant('personalNumber', foundConsultant!.personalNumber);
  };
  const handleChangeAccountingFirm = (e) => {
    const accountingFirmId = e.target.value;
    const foundAccountingFirm = accountingFirmDataSource.find((i) => i._id === accountingFirmId);
    const consultantsAndSupervisorsMerge = [
      ...(foundAccountingFirm?.seniorConsultants ?? []),
      ...foundAccountingFirm!.supervisors,
    ];
    setChosenAccountingFirmSelector({
      _id: foundAccountingFirm!._id,
      name: foundAccountingFirm!.name,
      organizationNumber: foundAccountingFirm!.organizationNumber,
      seniorConsultants: foundAccountingFirm!.seniorConsultants,
    });
    fetchConsultants(consultantsAndSupervisorsMerge);
    updateConsultant('_id', foundAccountingFirm!._id);
  };

  const addChosenConsultant = () => {
    setShowAddBtn(false);
    const _clientConsultants = [...consultants];
    const chosenConsultantID = chosenConsultant!.id;
    replaceConsultants([
      ..._clientConsultants,
      {
        id: chosenConsultantID,
        fullName: chosenConsultant!.fullName,
        email: chosenConsultant!.email,
        personalNumber: chosenConsultant!.personalNumber,
        role: chosenConsultant!.role,
        accountingFirm: {
          id: chosenAccountingFirm._id,
          name: chosenAccountingFirm.name,
        },
      },
    ]);

    clearChosenAccountingFirm();
    clearChosenConsultant();

    const _consultantDataSource = [...consultantDataSource];
    const foundConsultant = _consultantDataSource.find((i) => i._id === chosenConsultantID);

    foundConsultant!.selected = true;

    setConsultantDataSource(_consultantDataSource);
  };

  const removeConsultant = (item) => {
    const _consultantDataSource = [...consultantDataSource];
    const _clientConsultants = [...consultants];

    const _accountingtDataSource = [...accountingFirmDataSource];
    const _accountingFirms = [...accountingFirms];

    const _updatedConsultant = _clientConsultants.map((consultant) => {
      return {
        ...consultant,
        selected: false,
      };
    });
    const foundDetail = _updatedConsultant.find((i) => i.personalNumber === item.personalNumber);
    foundDetail!.selected = false;

    setAccountingFirmDataSource([..._accountingtDataSource]);
    setAccountingFirms([..._accountingFirms.filter((i) => i._id !== item?.role?.company?.id)]);

    setConsultantDataSource([..._consultantDataSource]);
    replaceConsultants([..._updatedConsultant.filter((i) => i.personalNumber !== item.personalNumber)]);

    formGroup.setValues((value) => ({
      ...value,
      consultants: [..._updatedConsultant.filter((i) => i.personalNumber !== item.personalNumber)],
    }));

    appendDeletedConsultant(item.id || item._id);
  };

  const clearChosenAccountingFirm = () => {
    setChosenAccountingFirmSelector({
      id: '',
      _id: '',
      name: '',
      organizationNumber: '',
      seniorConsultants: [],
    });
  };
  const clearChosenConsultant = () => {
    setChosenSelector({
      id: '',
      personalNumber: '',
      fullName: '',
      email: '',
      role: '',
    });
  };
  return (
    <div>
      <PagesHeader pageTitle={'External consultants'} headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section consultant-detail">
        {(consultants || []).map((item, idx) => (
          <div key={idx}>
            <Row className="align-items-center mb-3">
              <Col md="3">
                <div className="consultant-title">
                  <div className="consultant-title__avatar">
                    <span className="consultant-title-placeholder">{getInitialName(item.fullName) || ''}</span>
                  </div>
                  <div className="consultant-title__name theme-text fs-14px">{item.fullName}</div>
                </div>
              </Col>
              <Col style={{ maxWidth: 170 }}>
                <span className="theme-text fs-14px">{item.personalNumber}</span>
              </Col>
              {item?.accountingFirm && (
                <Col style={{ maxWidth: 250 }}>
                  <span className="theme-text text-primary fs-14px">{item?.accountingFirm?.name || ''}</span>
                </Col>
              )}
              <Col style={{ maxWidth: 170 }}>
                <span className="theme-text fs-14px">
                  {item.role === 'SeniorConsultant' ? 'Consultant' : item.role}
                </span>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <span className="me-2 mb-0">
                  <TooltipBox msg={`${localize('delete_user')}`}>
                    <UserX onClick={() => removeConsultant(item)} className="cursor-pointer delete-icon" />
                  </TooltipBox>
                </span>
              </Col>
            </Row>
            <HorizontalRule color="#C6C6C6" height="1px" />
          </div>
        ))}
        <div>
          {/* <HorizontalRule color="#C6C6C6" height="1px" /> */}
          {!showAddBtn && (
            <Button onClick={onAddHandler} className="app-btn-secondary">
              <UserPlus className="theme-text" />
            </Button>
          )}
        </div>
        {showAddBtn && (
          <div>
            <div className="cr-form-row">
              <div className="accounting-firm-field">
                <Form.Label
                  className={classNames('fs-name mb-0', requiredError(formGroup.getFieldMeta(`consultant._id`)))}
                >
                  {localize('accounting_firm')}
                </Form.Label>
                <Form.Select
                  value={chosenAccountingFirm?._id}
                  name="_id"
                  onChange={(e) => handleChangeAccountingFirm(e)}
                  className={classNames(errorHighlighter(formGroup.getFieldMeta(`consultant._id`)))}
                >
                  <option disabled value={''}>
                    {localize('please_select')}
                  </option>
                  {filteredAccountingFirmDataSource.map((item) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <ErrorFeedback field={formGroup.getFieldMeta(`consultant._id`)} />
              </div>
              <div className="personal-num-field">
                <Form.Label
                  className={classNames(
                    'fs-name mb-0',
                    requiredError(formGroup.getFieldMeta(`consultant.personalNumber`)),
                  )}
                >
                  {localize('consultant')}
                </Form.Label>
                <Form.Select
                  value={chosenConsultant?.personalNumber}
                  name="personalNumber"
                  onChange={(e) => handleChangeConsultant(e)}
                  className={classNames(errorHighlighter(formGroup.getFieldMeta(`consultant.personalNumber`)))}
                >
                  <option disabled value={''}>
                    {localize('please_select')}
                  </option>
                  {filteredConsultantDataSource.map((item) => {
                    return (
                      <option key={item.personalNumber} value={item.personalNumber}>
                        {item.fullName}
                      </option>
                    );
                  })}
                </Form.Select>
                <ErrorFeedback field={formGroup.getFieldMeta(`consultant.personalNumber`)} />
              </div>

              <div>
                <Button
                  disabled={isEmpty(chosenConsultant?.personalNumber)}
                  onClick={addChosenConsultant}
                  variant="primary"
                  className="app-btn-secondary"
                >
                  <Plus className="theme-text icon-gap-right" />
                  {<span className="theme-text">{localize('add')}</span>}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
