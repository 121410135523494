import { useEffect, useState } from 'react';
import useContext from '../context/useContext';
import { CostSpecification, CostSpecItem, TypePlans } from '../../types';
import { generateUniqueId, isEmptyVal } from '../../../../../util/util';

type UpdateSpecItemParams = {
  planIndex: number;
  name: string;
  value: any;
  specIndex?: number | null;
};

type CostSpecItemWithKey = CostSpecItem & { key: string };

const targetPlanKey = `pensionManagerPlans.plans`;
const targetCostSpecKey = `costSpecification`;

const useCostSpecification = (planIndex: number) => {
  const { formGroup, selectedPensionManagerDetails } = useContext();
  const [costSpecItems, setCostSpecItems] = useState<CostSpecItemWithKey[]>([]);

  const addSpecItem = (planIndex) => {
    addCostSpecItem();

    const targetPlan = `${targetPlanKey}.${planIndex}`;
    const targetCostSpec = `${targetPlan}.${targetCostSpecKey}`;

    const planCostSpecification = formGroup.getFieldMeta(`${targetCostSpec}`).value as CostSpecification;

    const costSpecItem: CostSpecItem = {
      title: '',
      fee: null,
      exemptionLetter: null,
    };

    const _specItems = [...planCostSpecification.specItems];
    _specItems.push(costSpecItem);

    formGroup.setFieldValue(`${targetCostSpec}.specItems`, _specItems);
  };

  const deleteSpecItem = (planIndex: number, specIndex: number) => {
    removeCostSpecItem(specIndex);

    const targetPlan = `${targetPlanKey}.${planIndex}`;
    const targetCostSpec = `${targetPlan}.${targetCostSpecKey}`;

    const planCostSpecification = formGroup.getFieldMeta(`${targetCostSpec}`).value as CostSpecification;
    const collection = [...planCostSpecification.specItems];

    collection.splice(specIndex, 1);

    formGroup.setFieldValue(`${targetCostSpec}.specItems`, collection);
  };

  const updateSpecItem = (params: UpdateSpecItemParams) => {
    if (!isEmptyVal(params.specIndex)) {
      updateCostSpecItem(params.name, params.value, params.specIndex!);
    }

    const targetPlan = `${targetPlanKey}.${params.planIndex}`;

    if (isEmptyVal(params.specIndex)) {
      formGroup.setFieldValue(`${targetPlan}.${targetCostSpecKey}.${params.name}`, params.value);

      return;
    }

    const targetCostSpec = `${targetPlan}.${targetCostSpecKey}.specItems.${params.specIndex}.${params.name}`;

    formGroup.setFieldValue(`${targetCostSpec}`, params.value);
  };

  const getCostSpecification = (planIndex) => {
    const targetPlan = `${targetPlanKey}.${planIndex}`;
    const targetCostSpec = `${targetPlan}.${targetCostSpecKey}`;

    const costSpec = formGroup.getFieldMeta(`${targetCostSpec}`).value as CostSpecification | null;

    return costSpec;
  };

  const updateCostSpecItem = (name: string, value: any, idx: number) => {
    const _rows = [...costSpecItems];
    const selected = _rows[idx];
    selected[name] = value;

    setCostSpecItems(_rows);
  };

  const addCostSpecItem = () => {
    const _rows = [...costSpecItems];
    const costSpecItem: CostSpecItemWithKey = {
      key: generateUniqueId(),
      title: '',
      fee: null,
      exemptionLetter: null,
    };
    _rows.push(costSpecItem);

    setCostSpecItems(_rows);
  };

  const removeCostSpecItem = (index) => {
    const _rows = [...costSpecItems];

    _rows.splice(index, 1);
    setCostSpecItems(_rows);
  };

  const patchDetails = () => {
    const plans = selectedPensionManagerDetails?.pensionManagerPlans?.plans as TypePlans[];
    const selectedPlan = plans[planIndex];
    const selectedSpecItems = selectedPlan?.costSpecification?.specItems || [];

    if (selectedSpecItems.length) {
      const _specItems: CostSpecItemWithKey[] = selectedSpecItems.map((item) => {
        return {
          key: generateUniqueId(),
          ...item,
        };
      });

      setCostSpecItems(_specItems);
    }
  };

  useEffect(() => {
    if (selectedPensionManagerDetails) {
      patchDetails();
    }
  }, [selectedPensionManagerDetails]);

  return {
    costSpecItems,
    addSpecItem,
    deleteSpecItem,
    updateSpecItem,
    getCostSpecification,
  };
};

export default useCostSpecification;
