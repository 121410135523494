import React from 'react';
import { useState, useEffect, useRef, useCallback } from 'react';
import * as Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportingData from 'highcharts/modules/export-data';
import HighChartAccessibility from 'highcharts/modules/accessibility';
import HighChartStock from 'highcharts/modules/stock';
import HighChartData from 'highcharts/modules/data';
import HighChartMore from 'highcharts/highcharts-more';
import { Menu, ArrowRight } from 'react-feather';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import moment from 'moment';

import { ThemeContext } from '../../../contexts/ThemeContext';
import { ChartTable } from '../ChartTable';
import { ISeries, ISeriesOptions } from '../multi-series/types';
import { CalenderInput } from '../../base/CalenderInput';
import ChartSwitchFilter from '../multi-series/ChartSwitchFilter';
import { BarStackAdapter } from './bar-stack-adapter';
import { enumerateMonthsBetweenDates } from './utils';
import { Calendar } from '../../../util/calendar';
import { activeStyleByMonthRange } from '../utils';

HighchartsExporting(Highcharts);
HighchartsExportingData(Highcharts);
HighChartAccessibility(Highcharts);
HighChartStock(Highcharts);
HighChartData(Highcharts);
HighChartMore(Highcharts);

interface ChartProps {
  data: any;
  seriesOptions: ISeriesOptions;
  addSeriesHandler?: () => void;
  removeSeriesHandler?: (series: ISeries) => void;
  changeDateHandler?: (dateRange: [string, string]) => void;
  initialDateRange?: string[];
  addSeriesBtnClass?: string;
  isLoading?: boolean;
  translate?: boolean;
}
const exportOptions = [
  { name: 'Print Chart', type: 'print' },
  { name: 'Download PNG image', type: 'image/png' },
  { name: 'Download JPEG image', type: 'image/jpeg' },
  { name: 'Download PDF document', type: 'application/pdf' },
  { name: 'Download SVG image', type: 'image/svg+xml' },
  { name: 'Download CSV', type: 'csv' },
  { name: 'Download XLS', type: 'xls' },
  { name: 'View Data Table', type: 'table' },
];
const rangeValue = [
  { type: 'month', maxRange: 4, text: '3m', range: Calendar.getRecentMonthBoundary(3) },
  { type: 'month', maxRange: 7, text: '6m', range: Calendar.getRecentMonthBoundary(6) },
  { type: 'year', maxRange: 12, text: '12m', range: Calendar.getRecentMonthBoundary(12) },
  { type: 'ytd', maxRange: 0, text: 'YTD', range: Calendar.getRecentMonthBoundary() },
];

export const BarStackChart = React.forwardRef((props: ChartProps, _ref: any) => {
  const ref: any = useRef(null);
  const { theme } = React.useContext(ThemeContext);
  const [seriesCollection, setSeriesCollection] = useState<ISeries[]>([]);
  const [startMonth, setStartMonth] = useState<Date>(
    new Date(props?.initialDateRange?.[0] ?? Calendar.currentYearBoundary[0]),
  );
  const [endMonth, setEndMonth] = useState<Date>(
    new Date(props?.initialDateRange?.[1] ?? Calendar.currentYearBoundary[1]),
  );
  const [isTable, setIsTable] = useState<boolean>(false);
  const [selectedDateRange, setSelectedDateRange] = useState<string[]>(Calendar.yearToDateBoundary);
  const [formattedTableData, setFormattedTableData] = useState<any[]>([]);
  const seriesAdapter = React.useMemo(() => {
    return BarStackAdapter.load(props.seriesOptions, props.data, [startMonth, endMonth]);
  }, [props.seriesOptions, props.data, startMonth, endMonth]);
  const [updatedChart, setUpdatedChart] = useState<any>(
    theme === 'dark' ? seriesAdapter.darktModeConfig : seriesAdapter.lightModeConfig,
  );
  const changeDateHandler = props.changeDateHandler;
  const isTranslate = props.translate ? true : false;
  const exportChart = (option) => {
    const chart = (ref!.current! as any).chart;

    if (option.type === 'print') {
      chart.print();
    } else if (option.type === 'csv') {
      chart.downloadCSV();
    } else if (option.type === 'xls') {
      chart.downloadXLS();
    } else if (option.type === 'table') {
      setIsTable(!isTable);
      !isTable ? (option.name = 'Hide Data Table') : (option.name = 'View Data Table');
    } else {
      chart.exportChart({
        type: option.type,
        filename: `${'my' + option.type}`,
      });
    }
  };
  const setRangeFromDuration = useCallback((startMonth, endMonth) => {
    const foundRangeItem = rangeValue.find((rangeItem) => {
      return (
        rangeItem.range[0] === moment(startMonth).format('YYYY-MM') &&
        rangeItem.range[1] === moment(endMonth).format('YYYY-MM')
      );
    });

    if (foundRangeItem) {
      setSelectedDateRange(foundRangeItem.range);
    } else {
      setSelectedDateRange([]);
    }

    return foundRangeItem;
  }, []);

  const setChartRange = (rangeItem) => {
    setSelectedDateRange(rangeItem.range);
    setStartMonth(new Date(moment(rangeItem.range[0]).format('YYYY-MM')));
    setEndMonth(new Date(moment(rangeItem.range[1]).format('YYYY-MM')));
  };
  const formatTableData = () => {
    setFormattedTableData(seriesAdapter.formattedTableData);
  };

  useEffect(() => {
    const chartRef = (ref!.current! as any).chart;

    chartRef.redraw();
    formatTableData();
  }, [seriesAdapter.data]);

  useEffect(() => {
    const chartRef = (ref!.current! as any).chart;
    if (changeDateHandler) {
      changeDateHandler!([
        moment(startMonth).startOf('month').format('YYYY-MM-DD'),
        moment(endMonth).startOf('month').format('YYYY-MM-DD'),
      ]);
    }
    setRangeFromDuration(startMonth, endMonth);
    const monthsRange = enumerateMonthsBetweenDates(
      moment(startMonth).format('MMM-YYYY'),
      moment(endMonth).format('MMM-YYYY'),
    );
    chartRef.xAxis[0].setCategories(monthsRange, true, true);
  }, [startMonth, endMonth]);
  // Set initial visibility of the chart series
  useEffect(() => {
    const chartRef = (ref!.current! as any).chart;
    seriesAdapter.series.forEach((seriesItem) => {
      if (!seriesItem.checked) {
        chartRef.series[seriesItem!.seriesIndex!].setVisible(false);
      }
    });
  }, []);
  useEffect(() => {
    const chartSeries = seriesAdapter.series.map((element, index) => ({
      ...element,
      color:
        theme === 'default'
          ? seriesAdapter.series[index].style!.defaultColor
          : seriesAdapter.series[index].style!.darkColor,
    }));

    theme === 'dark' ? setUpdatedChart(seriesAdapter.darktModeConfig) : setUpdatedChart(seriesAdapter.lightModeConfig);
    setSeriesCollection(chartSeries);
  }, [theme, seriesAdapter.series]);

  return (
    <>
      <div className="d-flex justify-content-between  mb-2">
        <div className="chart-series-filter">
          <ChartSwitchFilter
            ref={ref}
            seriesCollection={seriesCollection}
            series={seriesAdapter.series}
            initialChecked={seriesAdapter.initialChecked}
            shouldToggleTotal={seriesAdapter.shouldToggleTotal}
            allowDeleteSeries={seriesAdapter.allowDeleteSeries}
            removeSeriesHandler={props.removeSeriesHandler}
            totalSeriesItem={seriesAdapter.totalSeriesItem}
            translate={isTranslate}
          />
        </div>
        <div className="d-flex ">
          <Dropdown className="nav-item" align="end">
            <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
              <Button className="app-btn-secondary">
                <Menu className="theme-text" />
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {exportOptions.map((option, index) => (
                <Dropdown.Item key={index} onClick={() => exportChart(option)}>
                  {option.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="mb-2 d-flex ">
        <div className="d-flex  me-4">
          <CalenderInput
            startDate={startMonth}
            onChangeHandler={(date) => setStartMonth(date)}
            dateFormat="MMM-yyyy"
            showMonthYearPicker={true}
            datepickerWidth={150}
            maxDate={endMonth}
          />
          <ArrowRight className="mx-2 mt-2" />
          <CalenderInput
            startDate={endMonth}
            onChangeHandler={(date) => setEndMonth(date)}
            dateFormat="MMM-yyyy"
            showMonthYearPicker={true}
            datepickerWidth={150}
            minDate={startMonth}
            className="end-date"
          />
        </div>
        <div>
          {rangeValue.map((rangeItem, index) => (
            <Button
              key={index}
              onClick={() => setChartRange(rangeItem)}
              className={classNames(
                'app-btn-white',
                'me-2',
                activeStyleByMonthRange(selectedDateRange, rangeItem.range),
              )}
            >
              {rangeItem.text}
            </Button>
          ))}
        </div>
      </div>
      <div className="chart-parent">
        {props.isLoading && (
          <div className="spinner-wrapper spinner-wrapper--overlay">
            <div className="spinner-card">
              <Spinner className="icon-gap-right" />
              <span className="loading-text">Loading...</span>
            </div>
          </div>
        )}
        <HighchartsReact
          highcharts={Highcharts}
          options={{ ...updatedChart, series: seriesAdapter.data }}
          updateArgs={[true]}
          oneToOne={true}
          ref={ref}
        />
        {isTable && <ChartTable tableData={formattedTableData} selectedSeries={seriesCollection} />}
      </div>
    </>
  );
});
BarStackChart.displayName = 'BarStackChart';
