import { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Save, Edit2, Trash2, Database, Slash } from 'react-feather';
import { Briefcase } from 'react-feather';
import PagesHeader from '../../../components/base/PagesHeader';
import { InsuranceSaveMode, IntegrationSaveMode, Mode, PensionManagerSaveMode } from './types';
import { useSuperAdminSettingsContext } from './useContext';
import { useInternalCalculationContext } from './InternalCalculations/useContext';
import useLocale from '../../../hooks/useLocale';

type Props = {
  tabKey: string;
  toggleModal: () => void;
  accountingCompanyToggleModal: () => void;
  handleSaveCompany: (args) => void;
  handleSaveAccountingSalaryCompany: () => void;
  handleSaveInsurance: (args) => void;
  onDelete: () => void;
  onDeleteAccountingSalaryCompany: () => void;
  onDeleteInsurance: () => void;
  isDeleting: boolean;
  formData: any;
  toggleInsuranceModal: () => void;
  insuranceFormData: any;
  salaryCodeCmp: any;
  integrationCmp: any;
  generalSettingsRef: any;
};

export const AdminSettingsHeader = ({
  tabKey,
  toggleModal,
  handleSaveCompany,
  handleSaveAccountingSalaryCompany,
  onDeleteAccountingSalaryCompany,
  onDelete,
  accountingCompanyToggleModal,
  isDeleting,
  formData,
  toggleInsuranceModal,
  handleSaveInsurance,
  onDeleteInsurance,
  insuranceFormData,
  salaryCodeCmp,
  integrationCmp,
  generalSettingsRef,
}: Props) => {
  const { mode, setMode, setAccountingMode, accountingMode } = useSuperAdminSettingsContext();
  const { saveSettings, cancelChanges, hasInputChanges } = useInternalCalculationContext();
  const [isSaving, setIsSaving] = useState(false);
  const { localize } = useLocale();
  const saveMode = formData?.pensionCompany.status;
  const insuranceSaveMode = insuranceFormData?.insuranceCompany?.status;

  const saveInternalCalculationSettings = async () => {
    try {
      setIsSaving(true);
      await saveSettings();
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const cancelSettings = () => {
    cancelChanges();
  };

  return (
    <div className="d-flex justify-content-between">
      <PagesHeader headingClass="fs-h1 font-black" pageTitle="Settings" />
      {tabKey === 'general' && (
        <div className="cr-form-row">
          {/* {adminMode !== Mode.Edit && <Button className="app-btn-secondary me-3">{t('cancel')}</Button>} */}
          <Button
            className="app-btn-primary"
            onClick={generalSettingsRef?.saveChanges}
            disabled={!generalSettingsRef?.hasChanges()}
          >
            <Save className="icon-gap-right" /> {`${localize('save_changes')}`}
          </Button>
          {generalSettingsRef?.hasChanges() && (
            <Button className="app-btn-primary" onClick={generalSettingsRef?.cancelChanges}>
              <Slash className="icon-gap-right" /> {`${localize('cancel_changes')}`}
            </Button>
          )}
        </div>
      )}
      {tabKey === 'salary_code_conversion' && (
        <div>
          <Button
            className="app-btn-primary"
            onClick={salaryCodeCmp?.onSaveSalaryCodes}
            disabled={salaryCodeCmp?.chosenSalaryCodes?.length === 0}
          >
            {salaryCodeCmp?.isSaving ? (
              <Spinner className="icon-gap-right" size="sm" />
            ) : (
              <Save className="icon-gap-right" />
            )}{' '}
            {`${localize('save')} ${localize('settings')}`}
          </Button>
        </div>
      )}
      {tabKey === 'internal_calculations' && (
        <div>
          <Button disabled={hasInputChanges()} className="app-btn-secondary me-3" onClick={cancelSettings}>
            {localize('cancel')}
          </Button>
          <Button disabled={isSaving} className="app-btn-primary" onClick={saveInternalCalculationSettings}>
            {isSaving ? <Spinner className="icon-gap-right" size="sm" /> : <Save className="icon-gap-right" />}{' '}
            {`${localize('save')} ${localize('settings')}`}
          </Button>
        </div>
      )}
      <>
        {mode === Mode.Detail && tabKey === 'pension_managers' && (
          <div>
            <Button
              onClick={() => {
                setMode(Mode.List);
              }}
              className="app-btn-secondary me-3 cancel-btn"
            >
              {localize('cancel')}
            </Button>
            <Button onClick={() => setMode(Mode.Edit)} className="app-btn-primary me-3">
              <Edit2 className="icon-gap-right" /> {`${localize('edit')} ${localize('pension_manager')}`}
            </Button>
            <Button disabled={isDeleting} onClick={onDelete} className="app-btn-danger">
              {isDeleting ? <Spinner className="icon-gap-right" /> : <Trash2 className="icon-gap-right" />}{' '}
              {`${localize('delete')} ${localize('company')}`}
            </Button>
          </div>
        )}
        {(mode === Mode.Edit || mode === Mode.Add) && tabKey === 'pension_managers' && (
          <div>
            <div>
              <Button
                onClick={() => {
                  setMode(Mode.List);
                }}
                className="app-btn-secondary me-3 cancel-btn"
              >
                {localize('cancel')}
              </Button>
              {saveMode === PensionManagerSaveMode.DRAFT && (
                <Button
                  disabled={isSaving || isDeleting}
                  onClick={() => handleSaveCompany(PensionManagerSaveMode.DRAFT)}
                  className="app-btn-primary me-3"
                >
                  {isSaving ? <Spinner className="icon-gap-right" /> : <Save className="icon-gap-right" />}{' '}
                  {`${localize('save')} ${localize('as')} ${localize('draft')}`}
                </Button>
              )}
              <Button
                disabled={isSaving || isDeleting}
                onClick={() => handleSaveCompany(PensionManagerSaveMode.ACTIVE)}
                className="app-btn-primary "
              >
                {isSaving ? <Spinner className="icon-gap-right" /> : <Save className="icon-gap-right" />}{' '}
                {`${localize('save')} ${localize('changes')}`}
              </Button>
            </div>
          </div>
        )}

        {mode === Mode.List && tabKey === 'pension_managers' && (
          <div>
            <Button onClick={toggleModal} className="app-btn-primary d-block">
              <Briefcase className="icon-gap-right" /> {localize('add_manager')}
            </Button>
          </div>
        )}
      </>
      <>
        {mode === Mode.Detail && tabKey === 'insurances' && (
          <div>
            <Button
              onClick={() => {
                setMode(Mode.List);
              }}
              className="app-btn-secondary me-3 cancel-btn"
            >
              {localize('cancel')}
            </Button>
            <Button onClick={() => setMode(Mode.Edit)} className="app-btn-primary me-3">
              <Edit2 className="icon-gap-right" /> {`${localize('edit')} ${localize('insurance')}`}
            </Button>
            <Button disabled={isDeleting} onClick={onDeleteInsurance} className="app-btn-danger">
              {isDeleting ? <Spinner className="icon-gap-right" /> : <Trash2 className="icon-gap-right" />}{' '}
              {`${localize('delete')} ${localize('insurance')}`}
            </Button>
          </div>
        )}
        {(mode === Mode.Edit || mode === Mode.Add) && tabKey === 'insurances' && (
          <div>
            <div>
              <Button
                onClick={() => {
                  setMode(Mode.List);
                }}
                className="app-btn-secondary me-3 cancel-btn"
              >
                {localize('cancel')}
              </Button>
              {insuranceSaveMode === InsuranceSaveMode.DRAFT && (
                <Button
                  disabled={isSaving || isDeleting}
                  onClick={() => handleSaveInsurance(InsuranceSaveMode.DRAFT)}
                  className="app-btn-primary me-3"
                >
                  {isSaving ? <Spinner className="icon-gap-right" /> : <Save className="icon-gap-right" />}{' '}
                  {`${localize('save')} ${localize('as')} ${localize('draft')}`}
                </Button>
              )}
              <Button
                disabled={isSaving || isDeleting}
                onClick={() => handleSaveInsurance(InsuranceSaveMode.ACTIVE)}
                className="app-btn-primary "
              >
                {isSaving ? <Spinner className="icon-gap-right" /> : <Save className="icon-gap-right" />}{' '}
                {`${localize('save')} ${localize('changes')}`}
              </Button>
            </div>
          </div>
        )}

        {mode === Mode.List && tabKey === 'insurances' && (
          <div>
            <Button onClick={toggleInsuranceModal} className="app-btn-primary d-block">
              <Briefcase className="icon-gap-right" /> {localize('add_insurance_company')}{' '}
            </Button>
          </div>
        )}
      </>
      <>
        {accountingMode === Mode.Detail && tabKey === 'acounting_salary_company' && (
          <div>
            <Button
              onClick={() => {
                setAccountingMode(Mode.List);
              }}
              className="app-btn-secondary me-3 cancel-btn"
            >
              {localize('cancel')}
            </Button>
            <Button onClick={() => setAccountingMode(Mode.Edit)} className="app-btn-primary me-3">
              <Edit2 className="icon-gap-right" /> {`${localize('edit')} ${localize('company')}`}
            </Button>
            <Button disabled={isDeleting} onClick={onDeleteAccountingSalaryCompany} className="app-btn-danger">
              {isDeleting ? <Spinner className="icon-gap-right" /> : <Trash2 className="icon-gap-right" />}{' '}
              {`${localize('delete')} ${localize('company')}`}
            </Button>
          </div>
        )}
        {(accountingMode === Mode.Edit || accountingMode === Mode.Add) && tabKey === 'acounting_salary_company' && (
          <div>
            <div>
              <Button
                onClick={() => {
                  setAccountingMode(Mode.List);
                }}
                className="app-btn-secondary me-3 cancel-btn"
              >
                {localize('cancel')}
              </Button>
              <Button
                disabled={isSaving || isDeleting}
                onClick={handleSaveAccountingSalaryCompany}
                className="app-btn-primary"
              >
                {isSaving ? <Spinner className="icon-gap-right" /> : <Save className="icon-gap-right" />}{' '}
                {`${localize('save')} ${localize('changes')}`}
              </Button>
            </div>
          </div>
        )}

        {accountingMode === Mode.List && tabKey === 'acounting_salary_company' && (
          <div>
            <Button onClick={accountingCompanyToggleModal} className="app-btn-primary d-block">
              <Briefcase className="icon-gap-right" />
              {localize('add_accouting_and_salary_company')}
            </Button>
          </div>
        )}
      </>
      <>
        {mode === Mode.Detail && tabKey === 'integrations' && (
          <div>
            <Button
              onClick={() => {
                setMode(Mode.List);
              }}
              className="app-btn-secondary me-3 cancel-btn"
            >
              {localize('cancel')}
            </Button>
            <Button onClick={() => setMode(Mode.Edit)} className="app-btn-primary me-3">
              <Edit2 className="icon-gap-right" /> {`${localize('edit')} ${localize('integration')}`}
            </Button>
            <Button
              disabled={isDeleting}
              onClick={() => integrationCmp.removeIntegration(integrationCmp.selectedIntegrationID)}
              className="app-btn-danger"
            >
              {isDeleting ? <Spinner className="icon-gap-right" /> : <Trash2 className="icon-gap-right" />}{' '}
              {`${localize('delete')} ${localize('integration')}`}
            </Button>
          </div>
        )}
        {(mode === Mode.Edit || mode === Mode.Add) && tabKey === 'integrations' && (
          <div>
            <div>
              <Button
                onClick={() => {
                  setMode(Mode.List);
                }}
                className="app-btn-secondary me-3 cancel-btn"
              >
                {localize('cancel')}
              </Button>
              {integrationCmp.integrationSaveMode === IntegrationSaveMode.DRAFT && (
                <Button
                  disabled={isSaving || isDeleting}
                  onClick={() =>
                    integrationCmp.updateIntegrationDetail(
                      IntegrationSaveMode.DRAFT,
                      integrationCmp.selectedIntegrationID,
                    )
                  }
                  className="app-btn-primary me-3"
                >
                  {isSaving ? <Spinner className="icon-gap-right" /> : <Save className="icon-gap-right" />}{' '}
                  {`${localize('save')} ${localize('as')} ${localize('draft')}`}
                </Button>
              )}
              <Button
                disabled={isSaving || isDeleting}
                onClick={() =>
                  integrationCmp.updateIntegrationDetail(
                    IntegrationSaveMode.ACTIVE,
                    integrationCmp.selectedIntegrationID,
                  )
                }
                className="app-btn-primary "
              >
                {isSaving ? <Spinner className="icon-gap-right" /> : <Save className="icon-gap-right" />}{' '}
                {`${localize('save')} ${localize('changes')}`}
              </Button>
            </div>
          </div>
        )}

        {mode === Mode.List && tabKey === 'integrations' && (
          <div>
            <Button onClick={integrationCmp.toggleIntegrationModal} className="app-btn-primary d-block">
              <Database className="icon-gap-right" /> {localize('add_integration')}
            </Button>
          </div>
        )}
      </>
    </div>
  );
};
