import { useCallback } from 'react';

import { GrandIDAuthStatus, GrandIDInfo, GrandIDProcessing, GrandIDUser } from '../../types/auth';

const useGrandIDVerificationMode = () => {
  const isGrandIDObject = useCallback((grandidObject: unknown): grandidObject is GrandIDInfo => {
    if (grandidObject !== null && typeof grandidObject === 'object') {
      return true;
    }

    return false;
  }, []);

  const isVerificationCompleted = useCallback((response: unknown): response is GrandIDUser => {
    if (response !== null && typeof response === 'object') {
      return 'userAttributes' in response;
    }

    return false;
  }, []);

  const isVerificationPending = useCallback((response: unknown): response is GrandIDProcessing => {
    if (
      response !== null &&
      typeof response === 'object' &&
      'grandidObject' in response &&
      isGrandIDObject(response.grandidObject) &&
      response.grandidObject.message.status === GrandIDAuthStatus.PENDING
    ) {
      return true;
    }

    return false;
  }, []);

  const isVerificationFailed = useCallback((response: unknown): response is GrandIDProcessing => {
    if (
      response !== null &&
      typeof response === 'object' &&
      'grandidObject' in response &&
      isGrandIDObject(response.grandidObject) &&
      response.grandidObject.message.status === GrandIDAuthStatus.FAILED
    ) {
      return true;
    }

    return false;
  }, []);

  const getStatus = useCallback((response: unknown): GrandIDAuthStatus | null => {
    if (isVerificationCompleted(response)) {
      return GrandIDAuthStatus.COMPLETED;
    } else if (isVerificationFailed(response)) {
      return GrandIDAuthStatus.FAILED;
    } else if (isVerificationPending(response)) {
      return response.grandidObject.message.status;
    }

    return null;
  }, []);

  return {
    isVerificationCompleted,
    isVerificationFailed,
    isVerificationPending,
    isGrandIDObject,
    getStatus,
  };
};

export default useGrandIDVerificationMode;
