import { createContext, useState, SetStateAction, Dispatch } from 'react';
import { isEqual } from 'lodash';
import { deepClone, setPropByPath } from '../../../../util/util';
import { saveInternalCalculations } from '../../../../api/service/superAdmin/settings/service';

export interface IContext {
  defaultYearlyCalculationSettings: any;
  setDefaultYearlyCalculationSettings: Dispatch<SetStateAction<any>>;
  yearlyCalculationSettings: any;
  setYearlyCalculationSettings: Dispatch<SetStateAction<any>>;
  updateCalculationSettings: (key: string, value: any) => void;
  replaceCalculationSettings: (settings) => void;
  replaceDefaultCalculationSettings: (settings) => void;
  toParsedCalculationSettings: () => void;
  saveSettings: () => Promise<any>;
  cancelChanges: () => void;
  hasInputChanges: () => boolean;
}

type Props = {
  children: JSX.Element;
};

export const InternalCalculationContext = createContext<IContext>({
  defaultYearlyCalculationSettings: null,
  yearlyCalculationSettings: null,
  setYearlyCalculationSettings: () => {},
  setDefaultYearlyCalculationSettings: () => {},
  updateCalculationSettings: () => {},
  replaceCalculationSettings: () => {},
  replaceDefaultCalculationSettings: () => {},
  toParsedCalculationSettings: () => {},
  saveSettings: () => new Promise(() => {}),
  cancelChanges: () => {},
  hasInputChanges: () => false,
});

export const InternalCalculationProvider = ({ children }: Props) => {
  const [yearlyCalculationSettings, setYearlyCalculationSettings] = useState({});
  const [defaultYearlyCalculationSettings, setDefaultYearlyCalculationSettings] = useState({});

  const updateCalculationSettings = (key: string, value: any) => {
    const updatedSettings = { ...yearlyCalculationSettings };

    setPropByPath(updatedSettings, key, value);
    setYearlyCalculationSettings(updatedSettings);
  };

  const replaceCalculationSettings = (settings) => {
    setYearlyCalculationSettings(settings);
  };

  const replaceDefaultCalculationSettings = (settings) => {
    setDefaultYearlyCalculationSettings(deepClone(settings));
  };

  const toParsedCalculationSettings = () => {
    const parsedSettings = Object.values(yearlyCalculationSettings);

    return { settings: parsedSettings };
  };

  const saveSettings = async () => {
    const payload = toParsedCalculationSettings();

    return saveInternalCalculations(payload);
  };

  const cancelChanges = () => {
    setYearlyCalculationSettings(deepClone(defaultYearlyCalculationSettings));
  };

  const hasInputChanges = () => {
    return isEqual(yearlyCalculationSettings, defaultYearlyCalculationSettings);
  };

  const context: IContext = {
    yearlyCalculationSettings,
    defaultYearlyCalculationSettings,
    setYearlyCalculationSettings,
    setDefaultYearlyCalculationSettings,
    updateCalculationSettings,
    replaceCalculationSettings,
    replaceDefaultCalculationSettings,
    toParsedCalculationSettings,
    saveSettings,
    cancelChanges,
    hasInputChanges,
  };

  return <InternalCalculationContext.Provider value={context}>{children}</InternalCalculationContext.Provider>;
};
