import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classNames from 'classnames';
import { errorHighlighter } from '../../../util/form';
import ErrorFeedback from '../../ErrorFeedback';
import { useEffect, useState } from 'react';

type Props = {
  defaultValue?: string;
  onChangeHandler: (e) => void;
  field?: any;
  disabled?: boolean;
};

export const TextEditor = ({ defaultValue, field, onChangeHandler, disabled }: Props) => {
  const [inputVal, setInputVal] = useState('');
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
    // handlers: {
    //   undo: myUndo,
    //   redo: myRedo,
    // },
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  const handleChange = (value) => {
    if (value === '<p><br></p>' || value === '') {
      setInputVal('');
      onChangeHandler('');
      return;
    }
    setInputVal(value);
    onChangeHandler(value);
  };

  useEffect(() => {
    setInputVal(defaultValue || '');
  }, [defaultValue]);

  return (
    <>
      <ReactQuill
        readOnly={disabled}
        theme="snow"
        modules={modules}
        formats={formats}
        value={inputVal}
        onChange={handleChange}
        className={classNames(field ? errorHighlighter(field) : '')}
        preserveWhitespace={true}
      />
      {field && <ErrorFeedback field={field} />}
    </>
  );
};
