import useAuth from '../../../../hooks/useAuth';
import { UserSubRole } from '../../../../types/auth';
import { ClientSaveMode } from '../../Clients/types';

const useAccountingFirmAccess = () => {
  const { user } = useAuth();

  const canAccessByConsultant = (consultants: any[] = []) => {
    const isSupervisorWithConsultant =
      user?.role.subRole === UserSubRole.SUPERVISOR && consultants.find((item) => item._id === user.id);

    const isConsultant = user?.role.subRole === UserSubRole.SENIOR_CONSULTANT;

    return isConsultant || isSupervisorWithConsultant;
  };

  const canAccessByConsultantWithStatus = (status: ClientSaveMode | string, consultants: any[] = []) => {
    const isSupervisorWithConsultant =
      user?.role.subRole === UserSubRole.SUPERVISOR && consultants.find((item) => item._id === user.id);

    const isConsultant = user?.role.subRole === UserSubRole.SENIOR_CONSULTANT;

    const isActive = status === ClientSaveMode.ACTIVE;

    return isActive && (isConsultant || isSupervisorWithConsultant);
  };

  return {
    canAccessByConsultant,
    canAccessByConsultantWithStatus,
  };
};

export default useAccountingFirmAccess;
