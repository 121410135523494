import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
  employmentDate: Yup.string().required('Required'),
  employmentType: Yup.string().required('Required'),
  foraType: Yup.string().required('Required'),
  jobTitle: Yup.string(),
  personnelType: Yup.string().required('Required'),
  salary: Yup.string().required('Required'),
  salaryType: Yup.string().required('Required'),
  scheduleId: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Invalid email'),
  fullName: Yup.string().required('Required'),
  personalNumber: Yup.string().required('Required'),
  phoneNumberWork: Yup.string(),
  phoneNumberPrivate: Yup.string(),
  costCenter: Yup.string(),
  //paidHolidays: Yup.number(),
  vacation: Yup.object().shape({
    paidHolidays: Yup.number(),
    effectiveFrom: Yup.string(),
  }),
  policySettings: Yup.mixed(),
  scopeOfWork: Yup.number().nullable(),
  parentalInfo: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      birthDate: Yup.string(),
    }),
  ),
});
