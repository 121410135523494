import { Form, Row, Spinner, Col, Button } from 'react-bootstrap';
import CardComponent from '../../../../../components/base/CardComponent';
import { useTranslation } from 'react-i18next';
import { useColumn } from './useColumn';
import { useState, useEffect } from 'react';
import { getAllUsers } from '../../../../../api/service/general/user.service';
import { UsersListResponse } from '../../../../../api/service/general/type';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { File, FilePlus } from 'react-feather';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BSTable from '../../../../../components/table/BSTable';
import { GetPersonalDetailLogsType } from '../../../../../api/service/superAdmin/personal-clients/types';
import {
  getPersonalClientLogCategory,
  getPersonalClientLogDetails,
} from '../../../../../api/service/superAdmin/personal-clients/service';
// const { SearchBar } = Search;

type Props = {
  isBusy: boolean;
};

export const Log = ({ isBusy }: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { personalDetailLogColumn } = useColumn();
  const [personalDetailLogData, setPersonalDetailogData] = useState<GetPersonalDetailLogsType | null>(null);
  const [users, setUsers] = useState<UsersListResponse[]>([]);
  const [categories, setCategories] = useState<any[]>([]);

  const [logsFilter, setLogFilter] = useState({
    userId: '',
    action: '',
    level: '',
    from: '',
    to: '',
  });
  // const totalPages = companyClientLogData?.metaData ? Math.ceil(companyClientLogData?.metaData.total / 25) : 0;
  // const onPagination = async ({ page, pageSize }) => {
  //   await fetchAccountingFirmLog({ page, pageSize });
  // };

  const setFilter = (key, value) => {
    setLogFilter((prevValue) => ({
      ...prevValue,
      [key]: value,
    }));
  };
  const fetchUsersList = async () => {
    const usersList = await getAllUsers(id);
    setUsers(usersList);
  };
  const fetchLogCategory = async () => {
    const categories = await getPersonalClientLogCategory(id);
    setCategories(categories);
  };
  const fetchPersonalDetailLog = async ({
    page = personalDetailLogData?.metaData.page.current,
    pageSize = personalDetailLogData?.metaData.page.size,
    sortOrder = 1,
    sortField = 'dateTime',
  }) => {
    const personalDetailslogs = await getPersonalClientLogDetails(id, {
      filter: logsFilter,
      sort: { [sortField]: sortOrder },
      page,
      pageSize,
    });
    const formattedData = personalDetailslogs.data.map((item) => {
      return {
        id: item.id,
        dateTime: item.timestamp,
        fullName: item.message.actor.fullName,
        role: item.message.actor.role,
        action: item.message.action,
        event: item.message.event,
        level: item.level,
      };
    });
    setPersonalDetailogData({ data: formattedData, metaData: personalDetailslogs.metaData });
    setIsLoading(false);
  };
  const exportCSV = (props) => {
    if (props) {
      props.onExport();
    }
  };
  useEffect(() => {
    setIsLoading(true);
    fetchPersonalDetailLog({});
  }, [logsFilter]);

  useEffect(() => {
    fetchUsersList();
    fetchLogCategory();
  }, []);
  return (
    <>
      <CardComponent>
        {isBusy ? (
          <Spinner />
        ) : (
          <>
            <Row className="justify-content-between">
              <Col className="mb-5">
                <Form>
                  <ToolkitProvider
                    search
                    keyField="id"
                    data={personalDetailLogData?.data ?? []}
                    columns={personalDetailLogColumn}
                    csv
                  >
                    {(props: any) => (
                      <div>
                        <div className="cr-form-row mb-0">
                          <Form.Group className="from-to-field">
                            <Form.Label className="fs-name">{t('from')}</Form.Label>
                            <Form.Control
                              type="date"
                              name="startDate"
                              value={logsFilter.from}
                              onChange={(e) => {
                                setFilter('from', moment(e.target.value).format('YYYY-MM-DD'));
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="from-to-field">
                            <Form.Label className="fs-name">{t('to')}</Form.Label>
                            <Form.Control
                              type="date"
                              name="endDate"
                              value={logsFilter.to}
                              onChange={(e) => {
                                setFilter('to', moment(e.target.value).format('YYYY-MM-DD'));
                              }}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="fs-name">{t('user')}</Form.Label>
                            <Form.Select
                              name="user"
                              className="text-left"
                              onChange={(e) => {
                                setFilter('userId', e.target.value);
                              }}
                            >
                              <option value="">{t('all')}</option>
                              {users &&
                                users?.map((user) => (
                                  <option value={user.id} key={user.id}>
                                    {user.fullName}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="fs-name">{t('level')}</Form.Label>
                            <Form.Select
                              name="user"
                              className="text-left"
                              onChange={(e) => {
                                setFilter('level', e.target.value);
                              }}
                            >
                              <option value="">{t('all')}</option>
                              <option value="info">{t('info')}</option>
                              {/* <option value="debug">Debug</option> */}
                              <option value="error">{t('error')}</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="fs-name">{t('category')}</Form.Label>
                            <Form.Select
                              name="user"
                              className="text-left"
                              onChange={(e) => {
                                setFilter('action', e.target.value);
                              }}
                            >
                              <option value="">{t('all')}</option>
                              {categories &&
                                categories?.map((category, index) => (
                                  <option value={category} key={index}>
                                    {category}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>

                          <div className="last-button-box">
                            <Button className="app-btn-secondary" onClick={() => exportCSV({ ...props.csvProps })}>
                              <File className="icon-gap-right" /> {`${t('CSV')} ${t('export')}`}
                            </Button>
                            <Button className="app-btn-secondary" onClick={() => exportCSV({ ...props.csvProps })}>
                              <FilePlus className="icon-gap-right" />
                              {`${t('Excel')} ${t('export')}`}
                            </Button>
                          </div>
                        </div>
                        <div className="crt-table-report">
                          {isLoading ? <Spinner /> : <BSTable baseProps={props.baseProps} {...props.baseProps} />}
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </Form>
              </Col>
            </Row>
            {/* <Row className="justify-content-end">
              {isLoading ? (
                <Spinner />
              ) : (
                <BSRemoteTable
                  data={accountingFirmLogData?.data ?? []}
                  columns={accountingFirmLogColumn}
                  noDataIndication="No logs found"
                  sizePerPage={accountingFirmLogData?.metaData['size']}
                  onSizePerPageChange={(pageSize, page) => onPagination({ pageSize, page })}
                  keyField="id"
                  onTableChange={(type, { sortOrder, sortField }) => {
                    if (type === 'sort') {
                      fetchAccountingFirmLog({ sortField, sortOrder: sortOrder === 'asc' ? 1 : -1 });
                    }
                  }}
                />
              )}
            </Row> */}
            {/* {totalPages > 1 && ( */}
            {/* <Pagination
                total={companyClientLogData.metaData.total}
                itemsPerPage={companyClientLogData.metaData.page.size}
                currentPage={companyClientLogData.metaData.page.current}
                onPageChange={(page) => onPagination({ page, pageSize: companyClientLogData.metaData.page.size })}
              /> */}
            {/* )} */}
          </>
        )}
      </CardComponent>
    </>
  );
};
