import { useTranslation } from 'react-i18next';

/* eslint-disable react/prop-types */
const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
  const { t } = useTranslation();
  return (
    <>
      {previews}
      <div
        {...dropzoneProps}
        style={{ background: '#BFD3E6', overflow: 'auto', minHeight: 56, width: '100%', position: 'sticky', bottom: 0 }}
      >
        <div className="position-relative w-100">
          {files.length < maxFiles && input}
          <div className="text-center">
            <span className="fs-text-700">{t('drag_files_here')}</span>
            <br />
            <span className="fs-text-400">({t('files_allowed_text')})</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
