import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { Slash } from 'react-feather';
import { useTranslation } from 'react-i18next';

type Props = {
  btnText?: string;
  btnClassName?: string;
  onCancel: () => void;
};

const CancelButton = ({ btnText = 'cancel_changes', btnClassName, onCancel }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Button onClick={onCancel} className={classNames('app-btn-primary app-btn-secondary', btnClassName)}>
        <Slash className="icon-gap-right" /> {`${t(btnText)}`}
      </Button>
    </>
  );
};

export default CancelButton;
