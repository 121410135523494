import { Form, Spinner } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import { DateType } from '../../types';
import CompanyDetails from './GeneralForms/CompanyDetails';
import { useAccountingFirmEditGeneralSettingsContext } from './context/useContext';
import { AuthorizedSignatory } from './GeneralForms/AuthorizedSignatory';
import ContractDetails from './GeneralForms/ContractDetails';

type Props = {
  isBusy: boolean;
  date: DateType;
};
const General = ({ isBusy, date }: Props) => {
  const context = useAccountingFirmEditGeneralSettingsContext();

  return (
    <CardComponent>
      {isBusy ? (
        <Spinner />
      ) : (
        <Form>
          <CompanyDetails context={context} date={date} formGroup={context.formGroup} />
          <ContractDetails context={context} formGroup={context.formGroup} />
          <AuthorizedSignatory context={context} formGroup={context.formGroup} />
        </Form>
      )}
    </CardComponent>
  );
};

export default General;
