import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { CornerDownRight, RefreshCw } from 'react-feather';
import PagesHeader from '../../../../components/base/PagesHeader';
import { ClientSaveMode, FormProps } from '../types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../util/form';
import { OrganizationNumber } from '../../../../components/form/inputs/OrganizationNumber';
import { getRoaringCompanyInfo } from '../../../../api/service/superAdmin/company-clients/service';
import { splitFullName } from '../../../../util/util';
import { DEFAULT_COMPANY_INFO } from '../../../../types/general';
import { toTitleCase } from '../../../../util';

export default function CompanyDetails({ context, date }: FormProps) {
  const { t } = useTranslation();
  const {
    companyDetails,
    isEditMode,
    formGroup,
    contractNumber,
    replaceCompanyDetails,
    replaceCombinations,
    replaceAddress,
    updateAddress,
    updateCompanyDetails,
  } = context;
  const [isOrgNumberValid, setIsisOrgNumberValid] = useState<boolean>(true);

  const fetchCompanyDetails = async () => {
    try {
      updateCompanyDetails('name', DEFAULT_COMPANY_INFO.name);
      replaceAddress(DEFAULT_COMPANY_INFO.address);
      replaceCombinations(DEFAULT_COMPANY_INFO.authorizedSignatory);
      const { name, address, authorizedSignatory } = await getRoaringCompanyInfo(companyDetails?.organizationNumber);
      replaceCompanyDetails({ ...companyDetails, name });
      replaceAddress({ ...(address || null), city: toTitleCase(address.city) });
      replaceCombinations(
        authorizedSignatory.map((item, index) => {
          const updatedSignatory = item.map((value) => {
            const { name, positions, ...signatoryDetails } = value;
            return {
              ...signatoryDetails,
              ...splitFullName(name),
              position: positions[0].roleName,
              email: '',
              fullName: name,
            };
          });
          if (index === 0) {
            return {
              authorizedSignatory: updatedSignatory,
              signee: true,
            };
          } else {
            return {
              authorizedSignatory: updatedSignatory,
              signee: false,
            };
          }
        }),
      );
      setIsisOrgNumberValid(true);
    } catch (error) {
      setIsisOrgNumberValid(false);
    }
  };

  const handleSetCompany = (e) => {
    updateCompanyDetails(e.target.name, e.target.value);
    setIsisOrgNumberValid(true);
  };

  const handleAddressCompany = (e) => {
    updateAddress(e.target.name, e.target.value);
  };

  return (
    <div>
      <PagesHeader pageTitle="Company details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {isEditMode && companyDetails?.status !== ClientSaveMode.DRAFT && (
          <div className="cr-form-row">
            <div className="contract-num-field">
              <Form.Label className="fs-name mb-0">{t('contract_number')}</Form.Label>
              <Form.Control disabled defaultValue={contractNumber} />
            </div>
            <div className="creation-date-field">
              <Form.Label className="fs-name mb-0">{t('creation_date')}</Form.Label>
              <Form.Control disabled defaultValue={moment(date?.createdAt).format('YYYY-MM-DD')} />
            </div>
            <div className="last-edit-field">
              <Form.Label className="fs-name mb-0">{t('last_edit')}</Form.Label>
              <Form.Control disabled defaultValue={moment(date?.updatedAt).format('YYYY-MM-DD')} />
            </div>
          </div>
        )}
        <div className="cr-form-row">
          <OrganizationNumber
            disabled={isEditMode && companyDetails?.status !== ClientSaveMode.DRAFT}
            onChangeHandler={(e) => handleSetCompany(e)}
            field={formGroup.getFieldMeta(`companyDetails.organizationNumber`)}
            value={formGroup.values.companyDetails?.organizationNumber}
            isValid={isOrgNumberValid}
            lazy={true}
          />
          <div>
            <Button
              disabled={isEmpty(companyDetails?.organizationNumber)}
              onClick={
                isEmpty(companyDetails?.organizationNumber)
                  ? undefined
                  : () => {
                      fetchCompanyDetails();
                    }
              }
              className="app-btn-primary mt-2"
            >
              {isEditMode ? <RefreshCw className="icon-gap-right" /> : <CornerDownRight className="icon-gap-right" />}{' '}
              {isEditMode ? `${t('refresh')}` : `${t('fetch_data')}`}
            </Button>
          </div>
          <div className="company-name-field">
            <Form.Label className="fs-name mb-0 required ">{t('company_name')}</Form.Label>
            <Form.Control
              disabled
              onChange={(e) => handleSetCompany(e)}
              name="name"
              className={classNames(errorHighlighter(formGroup.getFieldMeta(`companyDetails.name`)))}
              value={formGroup.values.companyDetails?.name}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('companyDetails.name')} />
          </div>
          {isEditMode && companyDetails?.status !== ClientSaveMode.DRAFT && (
            <div className="status-field">
              <Form.Label className="fs-name mb-0 required">{t('status')}</Form.Label>
              {companyDetails?.status === ClientSaveMode.SETUP && (
                <Form.Control disabled name="status" defaultValue="Setup" />
              )}
              {companyDetails?.status !== ClientSaveMode.SETUP && (
                <Form.Select
                  name="status"
                  value={formGroup.values.companyDetails?.status}
                  onChange={handleSetCompany}
                  disabled={isEditMode}
                  className={classNames(errorHighlighter(formGroup.getFieldMeta(`companyDetails.status`)))}
                >
                  <option value="">{t('please_select')}</option>
                  {/* <option value="Draft">{t('draft')}</option> */}
                  <option value="Active">{t('active')}</option>
                  <option value="InActive">{t('inactive')}</option>
                </Form.Select>
              )}

              <ErrorFeedback field={formGroup.getFieldMeta('companyDetails.status')} />
            </div>
          )}
        </div>
        <div className="cr-form-row">
          <div className="address-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{t('address')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                name="street"
                value={formGroup.values.address?.street}
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.street`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.street')} />
            </Form.Group>
          </div>
          <div className="post-code-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{t('postcode')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={(e) => handleAddressCompany(e)}
                value={formGroup.values.address?.postcode}
                name="postcode"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.postcode`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.postcode')} />
            </Form.Group>
          </div>
          <div className="city-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{t('city')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={(e) => handleAddressCompany(e)}
                value={formGroup.values.address?.city}
                name="city"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.city`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.city')} />
            </Form.Group>
          </div>
          <div className="country-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{t('country')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.country`)))}
                onChange={(e) => handleAddressCompany(e)}
                value={formGroup.values.address?.country}
                name="country"
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.country')} />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
