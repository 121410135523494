import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Trash2 } from 'react-feather';
import { ConfirmationDialog } from '../../../../util/dialogs';
import useContext from './context/useContext';
import { deleteInsuranceCompany } from '../../../../api/service/superAdmin/settings/insurances';
import useLocale from '../../../../hooks/useLocale';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

type Props = {
  id: string;
  data: any;
};

export const DeleteAction = ({ id, data }: Props) => {
  const [isBusy, setIsBusy] = useState(false);
  const { formGroup, replaceInsurances } = useContext();
  const { localize } = useLocale();
  const onDelete = async () => {
    const title = localize('confirm_delete_insurance_company');

    const result = await ConfirmationDialog({
      title,
      confirmButtonText: localize('yes'),
      denyButtonText: localize('no'),
    });
    if (result.isConfirmed) {
      try {
        setIsBusy(true);
        await deleteInsuranceCompany(id);
        const filteredInsurances = formGroup.values.insurances.filter((company) => company.id !== id);

        replaceInsurances(filteredInsurances);
      } finally {
        setIsBusy(false);
      }
    }
  };
  return isBusy ? (
    <Spinner />
  ) : (
    <TooltipBox msg={`${localize('delete')}`}>
      <Trash2 className="delete-icon cursor-pointer" onClick={onDelete} />
    </TooltipBox>
  );
};
