import { reInitializeSessionWithCompany } from '../../../../api/service/auth/service';
import useAuth from '../../../../hooks/useAuth';
import { UserSubRole } from '../../../../types/auth';
import { AccountingFirmClient } from '../../../../api/service/accountingFirm/clients/types';
type Props = {
  setBusy: (isBusy) => void;
  details: AccountingFirmClient;
};
export const useAccountingFirmRoleSwitcher = () => {
  const { user } = useAuth();
  const switchToCompanyAdmin = async (props: Props) => {
    try {
      const isSupervisorWithConsultant =
        user?.role.subRole === UserSubRole.SUPERVISOR && props.details.consultants.find((item) => item._id === user.id);
      const isConsultant = user?.role.subRole === UserSubRole.SENIOR_CONSULTANT;

      if (isConsultant || isSupervisorWithConsultant) {
        const personalNumber = user!.personalNumber;
        const subRole = UserSubRole.SENIOR_CONSULTANT;
        props.setBusy(true);

        const session = await reInitializeSessionWithCompany({
          personalNumber: personalNumber,
          companyId: props.details.id,
          isSaveInitializedSession: true,
          subRole,
        });

        window.location.href = session.redirectTo;
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.setBusy(false);
    }
  };
  return {
    switchToCompanyAdmin,
  };
};
