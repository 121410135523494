import { ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';

type ModalProps = {
  children: JSX.Element | any;
  show: boolean;
  onHide: () => void;
  title: string | JSX.Element | ReactNode;
  ModalFooter?: ReactNode;
  centered?: boolean;
  modalWidthClass?: string;
  className?: string;
  hideFooter?: boolean;
  backdrop?: 'static' | true | false;
  size?: any;
};

function BSModal({
  children,
  show,
  onHide,
  title,
  ModalFooter,
  centered = false,
  modalWidthClass = 'modal-width-80',
  className,
  hideFooter = false,
  backdrop = true,
  size = 'lg',
}: ModalProps) {
  return (
    <Modal
      className={className}
      dialogClassName={modalWidthClass}
      centered={centered}
      scrollable
      size={size}
      show={show}
      onHide={onHide}
      backdrop={backdrop}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {typeof title === 'string' ? <p className="fs-h1 mb-0 theme-text">{title}</p> : title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {!hideFooter && <Modal.Footer>{ModalFooter}</Modal.Footer>}
    </Modal>
  );
}

export default BSModal;
