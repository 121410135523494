import { UserType } from '../api/service/auth/types';

export enum UserRole {
  SUPER_ADMIN = 'CorrectoAdmin',
  COMPANY_ADMIN = 'CompanyAdmin',
  EMPLOYEE = 'Employee',
  ACCOUNTING_FIRM = 'AccountingFirm',
}

export enum UserSubRole {
  SUPERVISOR = 'Supervisor',
  SENIOR_CONSULTANT = 'SeniorConsultant',
  AUTHORIZED_SIGNATORY = 'AuthorizedSignatory',
}

export type AuthUser =
  | null
  | (UserType & {
      accessToken: string;
    });

export type AuthState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  userRole?: UserRole | null;
  userSubRole?: UserSubRole | null;
};

export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type ContextType = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  method: 'jwt';
  signIn: (user: AuthUser) => Promise<void>;
  signOut: () => Promise<void>;
  userRole?: UserRole | null;
  userSubRole?: UserSubRole | null;
};

export type AuthSessionMethod = {
  id: string;
  method: string;
};

export enum GrandIDAuthStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

export enum GrandIDAuthHintCode {
  USER_CANCEL = 'userCancel',
  START_FAILED = 'startFailed',
  USER_SIGN = 'userSign',
  OUTSTANDING_TRANSACTION = 'outstandingTransaction',
}

export type GrandIDMessage = {
  status: GrandIDAuthStatus;
  hintCode: GrandIDAuthHintCode;
};

export type GrandIDInfo = {
  code: string;
  message: GrandIDMessage;
  sessionId: string;
  QRCode: string;
  autoStartToken: string;
};

export type GrandIDProcessing = {
  grandidObject: GrandIDInfo;
};

export type GrandIDUser = {
  sessionId: string;
  username: string;
  userAttributes: {
    personalNumber: string;
    name: string;
    givenName: string;
    surname: string;
    ipAddress: string;
    notBefore: string;
    notAfter: string;
    signature: string;
    ocspResponse: string;
    uhi: string;
    bankIdIssueDate: string;
  };
};

export type GrandIDVerificationResponse = GrandIDProcessing | GrandIDUser;

export type SignInMethod = 'mobile' | 'desktop';

export type SignInDevice = 'SAME_DEVICE' | 'ANOTHER_DEVICE';

export type SignInType = {
  method: SignInMethod;
  device: SignInDevice;
};

export const SignInAction: Record<SignInMethod, SignInType> = {
  mobile: {
    method: 'mobile',
    device: 'ANOTHER_DEVICE',
  },
  desktop: {
    method: 'desktop',
    device: 'SAME_DEVICE',
  },
} as const;

export enum AuthLayout {
  DEFAULT = 'default',
  STANDARD = 'standard',
}

export enum SignInRole {
  ADMIN = 'admin',
  NON_ADMIN = 'non_admin',
}
