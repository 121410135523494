import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { FakeInputBox } from '../../../../components/base/FakeInputBox';
import PagesHeader from '../../../../components/base/PagesHeader';
import { deepClone, numberFormatToSameDecimal, toNumber } from '../../../../util/util';
// import moment from 'moment';
import { PercentageNumber } from '../../../../components/form/inputs/PercentangeNumber';
import usePolicyMode from '../hooks/usePolicyMode';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';
import useContext from '../PensionPolicyContext/useContext';
import {
  DEFAULT_COMPENSATION_INCREASED_OFFSET,
  EDITABLE_EMPLOYEE_SALARY_CHANGE_INPUT,
  INITIAL_SALARY_CHNAGE,
  SalaryChangeInputType,
} from '../constants';
import PriceInput from '../../../../components/base/PriceInput';
import { Info } from 'react-feather';
import usePensionSchedule from '../../../company/Employees/hooks/usePensionSchedule';
import { useTranslation } from 'react-i18next';
import { Calendar } from '../../../../util/calendar';
import useEmployeeSalaryChange from '../hooks/useEmployeeSalaryChange';

type Props = {
  selectedPolicy: any;
  effectiveFrom?: string;
};

export const SalaryDetailsViewer = ({ selectedPolicy, effectiveFrom }: Props) => {
  const { formGroup, preAssignedPolicy, isResetPolicy, externalPolicyRevision, selectedPolicyDetails } = useContext();
  const {
    isCompanyEmployeePolicyMode,
    isCompanyEmployeeScheduledMode,
    isCompanyEmployeeReadOnlyMode,
    isCompanyEmployeeEditableMode,
  } = usePolicyMode();
  const [, setIsPreAssignedFixedOffset] = useState(true);
  const [, setIsPreAssignedPercentageOffset] = useState(true);
  const [, setIsPreAssignedManualOffset] = useState(true);
  const [, setCurrentSalaryChange] = useState<any>(null);
  const { hasShownScheduledEffectiveDateNotifier } = usePensionSchedule();
  const salaryChangeField = 'policySettings.salaryChange';
  const assignedSalaryChange = formGroup.values?.policySettings?.salaryChange;
  const { t } = useTranslation();
  const { disableEffectiveDateIfInvalid, applyEmployeeOffset } = useEmployeeSalaryChange();

  const handleSalaryChange = (value, key, field) => {
    selectedPolicy.policySettings.salaryChange[key][field] = value;
    formGroup.setFieldValue(`policySettings.salaryChange.${key}.${field}`, value);

    if (isCompanyEmployeeEditableMode() && field === 'offset') {
      applyEmployeeOffset(value, key);
    }
  };

  const toggleEditOffsetInput = (val = false) => {
    setIsPreAssignedPercentageOffset(val);
    setIsPreAssignedFixedOffset(val);
    setIsPreAssignedManualOffset(val);
  };

  const clearForm = () => {
    toggleEditOffsetInput(false);
    formGroup.setFieldValue('policySettings.salaryChange', deepClone(INITIAL_SALARY_CHNAGE));
  };

  /*   const hasShownFixedCompensationNotifier = () => {
    return isCompanyEmployeePolicyMode() && preAssignedPolicy?.salaryChange?.fixedOffset?.current;
  };

  const hasShownPercentageCompensationNotifier = () => {
    return isCompanyEmployeePolicyMode() && preAssignedPolicy?.salaryChange?.percentageOfSalaryOffset?.current;
  }; */

  const hasShownScheduledNotifier = (effectiveDate) => {
    return isCompanyEmployeeScheduledMode() && hasShownScheduledEffectiveDateNotifier(effectiveDate);
  };

  const isCheckedOffset = (prop) => {
    const salaryChangeSettings = selectedPolicy?.policySettings?.salaryChange;

    if (salaryChangeSettings?.isAllowed && salaryChangeSettings?.[prop]?.isChecked) {
      return true;
    }

    return false;
  };

  //* Clear formGroup
  useEffect(() => {
    if (isResetPolicy) {
      clearForm();
    }
  }, [isResetPolicy]);

  useEffect(() => {
    formGroup.setFieldValue(
      `${salaryChangeField}.${SalaryChangeInputType.FIXED_MONTHLY_OFFSET}.effectiveFrom`,
      effectiveFrom,
    );
    formGroup.setFieldValue(
      `${salaryChangeField}.${SalaryChangeInputType.PERCENTAGE_OF_SALARY_OFFSET}.effectiveFrom`,
      effectiveFrom,
    );
    formGroup.setFieldValue(`${salaryChangeField}.${SalaryChangeInputType.MANUAL_OFFSET}.effectiveFrom`, effectiveFrom);
  }, [effectiveFrom]);

  useEffect(() => {
    if (preAssignedPolicy) {
      formGroup.setFieldValue('policySettings.salaryChange', preAssignedPolicy.policySettings.salaryChange);

      if (
        preAssignedPolicy.salaryChange?.percentageOfSalaryOffset?.next?.offset &&
        preAssignedPolicy.salaryChange?.percentageOfSalaryOffset?.next?.effectiveFrom
      ) {
        setIsPreAssignedPercentageOffset(true);
      } else {
        setIsPreAssignedPercentageOffset(false);
      }
      if (
        preAssignedPolicy.salaryChange?.fixedOffset?.next?.offset &&
        preAssignedPolicy.salaryChange?.fixedOffset?.next?.effectiveFrom
      ) {
        setIsPreAssignedFixedOffset(true);
      } else {
        setIsPreAssignedFixedOffset(false);
      }

      setCurrentSalaryChange(preAssignedPolicy.salaryChange);
    } else {
      toggleEditOffsetInput(false);
    }
  }, [preAssignedPolicy, selectedPolicy]);

  useEffect(() => {
    if (selectedPolicy) {
      formGroup.setFieldValue('policySettings.salaryChange', selectedPolicy.policySettings.salaryChange);

      EDITABLE_EMPLOYEE_SALARY_CHANGE_INPUT.forEach((inputKey) => {
        formGroup.setFieldValue(
          `policySettings.salaryChange.${inputKey}.isChecked`,
          selectedPolicy.policySettings?.salaryChange[inputKey]?.isChecked,
        );
      });
    }
  }, [selectedPolicy]);

  return (
    <>
      {
        <div>
          <PagesHeader headingClass="pp-sec-heading" pageTitle="Salary change" biggerHeading />
          {isCheckedOffset(SalaryChangeInputType.FIXED_MONTHLY_OFFSET) && (
            <div className="mb-4">
              <PagesHeader pageTitle="Fixed Offset" />

              <div
                className={classNames(
                  `dark-content-section p-3  align-items-end`,
                  isCompanyEmployeePolicyMode() ? 'highlight-editable-option' : '',
                )}
              >
                {hasShownScheduledNotifier(assignedSalaryChange?.fixedMonthlyOffset?.effectiveFrom) && (
                  <div className="p-2 mb-3 d-inline-flex align-items-center alert alert-info theme-text">
                    <Info className="icon-gap-right" />
                    <span className="fs-name">{t('scheduled')}</span>
                  </div>
                )}

                <div className="d-flex align-items-end ">
                  {isCompanyEmployeePolicyMode() && (
                    <>
                      <Form.Group className="me-3 scope-of-work-field">
                        <Form.Label className="fs-name">
                          {t('compensation')}
                          <span className="font-weight-400"> (SEK)</span>
                        </Form.Label>
                        {isCompanyEmployeeEditableMode() && (
                          <PriceInput
                            defaultValue={assignedSalaryChange?.fixedMonthlyOffset?.offset}
                            name="compensation"
                            onChange={(value) => handleSalaryChange(value, 'fixedMonthlyOffset', 'offset')}
                            className="text-right"
                          />
                        )}
                        {isCompanyEmployeeReadOnlyMode() && (
                          <PriceInput
                            defaultValue={assignedSalaryChange?.fixedMonthlyOffset?.offset}
                            name="compensation"
                            disabled
                            className="text-right"
                          />
                        )}
                      </Form.Group>
                      <Form.Group className="me-3 effect-from-field">
                        <Form.Label className="fs-name">{t('effective_from')}</Form.Label>
                        {isCompanyEmployeeEditableMode() && (
                          <MonthAndYearDropdown
                            value={
                              formGroup.values.policySettings.salaryChange?.fixedMonthlyOffset?.effectiveFrom || ''
                            }
                            onOptionSelect={(val) => {
                              handleSalaryChange(val, 'fixedMonthlyOffset', 'effectiveFrom');
                            }}
                            disabledPreviousMonth
                            customOptions={Calendar.generateEffectiveDateOption(
                              formGroup.values.policySettings.salaryChange?.fixedMonthlyOffset?.effectiveFrom,
                            )}
                            disabled={disableEffectiveDateIfInvalid(
                              SalaryChangeInputType.FIXED_MONTHLY_OFFSET,
                              externalPolicyRevision?.assignedEmployeePension?.currentPolicy?.id,
                              selectedPolicyDetails?._id,
                            )}
                          />
                        )}
                        {isCompanyEmployeeReadOnlyMode() && (
                          <MonthAndYearDropdown
                            startFrom={externalPolicyRevision?.effectiveFrom}
                            value={
                              formGroup.values.policySettings.salaryChange?.fixedMonthlyOffset?.effectiveFrom || ''
                            }
                            disabled
                          />
                        )}
                      </Form.Group>
                    </>
                  )}
                  {!isCompanyEmployeePolicyMode() && (
                    <Form.Group className="mb-2">
                      <Form.Check
                        inline
                        type="checkbox"
                        label={`${t('employee_right_to_edit')}`}
                        id="isEditAllowed"
                        className="fs-name mb-1"
                        name="isEditAllowed"
                        defaultChecked={selectedPolicy.policySettings.salaryChange.fixedMonthlyOffset.isEditAllowed}
                        disabled={!isCompanyEmployeePolicyMode()}
                      />
                    </Form.Group>
                  )}
                </div>
              </div>
            </div>
          )}
          {isCheckedOffset(SalaryChangeInputType.PERCENTAGE_OF_SALARY_OFFSET) && (
            <div className="mb-4">
              <PagesHeader pageTitle={`${t('PENSION_POLICY.percentage_of_salary_offset')}`} translate={false} />
              <div
                className={classNames(
                  `dark-content-section p-3  align-items-end`,
                  isCompanyEmployeePolicyMode() ? 'highlight-editable-option' : '',
                )}
              >
                {hasShownScheduledNotifier(assignedSalaryChange?.percentageOfSalaryOffset?.effectiveFrom) && (
                  <div className="p-2 mb-3 d-inline-flex align-items-center alert alert-info theme-text">
                    <Info className="icon-gap-right" />
                    <span className="fs-name">{t('scheduled')}</span>
                  </div>
                )}
                {isCompanyEmployeePolicyMode() && (
                  <div className="d-flex align-items-end">
                    <Form.Group className="me-3 scope-of-work-field">
                      <Form.Label className="fs-name">
                        {t('percentage_compensation')}
                        <span className="font-weight-400"> (%)</span>
                      </Form.Label>
                      {isCompanyEmployeeEditableMode() && (
                        <PercentageNumber
                          maxWidth={60}
                          value={formGroup.values.policySettings.salaryChange?.percentageOfSalaryOffset?.offset}
                          name="compensation"
                          onChangeHandler={(value) => handleSalaryChange(value, 'percentageOfSalaryOffset', 'offset')}
                          field={formGroup!.getFieldMeta('policySettings.salaryChange.percentageOfSalaryOffset.offset')}
                          className="text-right"
                          decimalPlace={2}
                          disabled={!isCompanyEmployeePolicyMode()}
                        />
                      )}
                      {isCompanyEmployeeReadOnlyMode() && (
                        <PercentageNumber
                          value={formGroup.values.policySettings.salaryChange?.percentageOfSalaryOffset?.offset}
                          name="compensation"
                          field={formGroup!.getFieldMeta('policySettings.salaryChange.percentageOfSalaryOffset.offset')}
                          className="text-right"
                          decimalPlace={2}
                          disabled
                        />
                      )}
                    </Form.Group>
                    <Form.Group className="me-3 effect-from-field">
                      <Form.Label className="fs-name">{t('effective_from')}</Form.Label>
                      {isCompanyEmployeeEditableMode() && (
                        <MonthAndYearDropdown
                          startFrom={externalPolicyRevision?.effectiveFrom}
                          value={
                            formGroup.values.policySettings.salaryChange?.percentageOfSalaryOffset?.effectiveFrom || ''
                          }
                          onOptionSelect={(val) => {
                            handleSalaryChange(val, 'percentageOfSalaryOffset', 'effectiveFrom');
                          }}
                          customOptions={Calendar.generateEffectiveDateOption(
                            formGroup.values.policySettings.salaryChange?.percentageOfSalaryOffset?.effectiveFrom,
                          )}
                          disabled={disableEffectiveDateIfInvalid(
                            SalaryChangeInputType.PERCENTAGE_OF_SALARY_OFFSET,
                            externalPolicyRevision?.assignedEmployeePension?.currentPolicy?.id,
                            selectedPolicyDetails?._id,
                          )}
                        />
                      )}
                      {isCompanyEmployeeReadOnlyMode() && (
                        <MonthAndYearDropdown
                          startFrom={externalPolicyRevision?.effectiveFrom}
                          value={
                            formGroup.values.policySettings.salaryChange?.percentageOfSalaryOffset?.effectiveFrom || ''
                          }
                          disabled
                        />
                      )}
                    </Form.Group>
                  </div>
                )}

                {!isCompanyEmployeePolicyMode() && (
                  <Form.Group className="mb-2 ">
                    <Form.Check
                      inline
                      type="checkbox"
                      label={`${t('employee_right_to_edit')}`}
                      id="isEditAllowed"
                      className="fs-name mb-1"
                      name="isEditAllowed"
                      defaultChecked={selectedPolicy.policySettings.salaryChange.percentageOfSalaryOffset.isEditAllowed}
                      disabled
                    />
                  </Form.Group>
                )}
              </div>
            </div>
          )}
          {isCompanyEmployeePolicyMode() && isCheckedOffset(SalaryChangeInputType.MANUAL_OFFSET) && (
            <div className="mb-4">
              <PagesHeader pageTitle={`${t('PENSION_POLICY.one_time_offset')}`} translate={false} />
              <div className="dark-content-section p-3  align-items-end highlight-editable-option">
                {hasShownScheduledNotifier(assignedSalaryChange?.manualOffset?.effectiveFrom) && (
                  <div className="p-2 mb-3 d-inline-flex align-items-center alert alert-info theme-text">
                    <Info className="icon-gap-right" />
                    <span className="fs-name">{t('scheduled')}</span>
                  </div>
                )}
                <div className="d-flex align-items-end ">
                  <Form.Group className="me-3 scope-of-work-field">
                    <Form.Label className="fs-name">
                      {t('offset')}
                      <span className="font-weight-400"> (SEK)</span>
                    </Form.Label>
                    {isCompanyEmployeeEditableMode() && (
                      <PriceInput
                        defaultValue={toNumber(formGroup.values.policySettings?.salaryChange?.manualOffset?.offset)}
                        name="offset"
                        onChange={(value) => handleSalaryChange(value, 'manualOffset', 'offset')}
                        className="text-right"
                      />
                    )}
                    {isCompanyEmployeeReadOnlyMode() && (
                      <PriceInput
                        disabled
                        defaultValue={toNumber(formGroup.values.policySettings?.salaryChange?.manualOffset?.offset)}
                        name="offset"
                        className="text-right"
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="me-3 effect-from-field">
                    <Form.Label className="fs-name">{t('effective_month')}</Form.Label>
                    {isCompanyEmployeeEditableMode() && (
                      <MonthAndYearDropdown
                        startFrom={externalPolicyRevision?.effectiveFrom}
                        value={formGroup.values.policySettings.salaryChange?.manualOffset?.effectiveFrom || ''}
                        onOptionSelect={(val) => {
                          handleSalaryChange(val, 'manualOffset', 'effectiveFrom');
                        }}
                        customOptions={Calendar.generateEffectiveDateOption(
                          formGroup.values.policySettings.salaryChange?.manualOffset?.effectiveFrom,
                        )}
                        disabled={disableEffectiveDateIfInvalid(
                          SalaryChangeInputType.MANUAL_OFFSET,
                          externalPolicyRevision?.assignedEmployeePension?.currentPolicy?.id,
                          selectedPolicyDetails?._id,
                        )}
                      />
                    )}
                    {isCompanyEmployeeReadOnlyMode() && (
                      <MonthAndYearDropdown
                        startFrom={externalPolicyRevision?.effectiveFrom}
                        value={formGroup.values.policySettings.salaryChange?.manualOffset?.effectiveFrom || ''}
                        disabled
                      />
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
          )}
          {!isCompanyEmployeePolicyMode() && selectedPolicy.policySettings.salaryChange.manualOffset.isChecked && (
            <div className="mb-4">
              <PagesHeader pageTitle={t('PENSION_POLICY.manual_offset')} translate={false} />
              <div className="dark-content-section p-3 d-flex ">
                <Form.Group className="mb-2">
                  <Form.Check
                    inline
                    type="checkbox"
                    label={t('employee_right_to_edit')}
                    id="isChecked"
                    className="fs-name mb-1"
                    name="isChecked"
                    defaultChecked={selectedPolicy.policySettings.salaryChange.manualOffset.isChecked}
                    disabled
                  />
                </Form.Group>
              </div>
            </div>
          )}
          {
            <div className="mb-4">
              <PagesHeader pageTitle={`${t('PENSION_POLICY.compensation_with_increased_offset')}`} translate={false} />
              <div className="dark-content-section p-3 d-flex ">
                <Form.Group className="me-3">
                  <Form.Label className="theme-text fs-name">
                    {t('offset_addition')}{' '}
                    <span className="font-weight-400">
                      {t('PENSION_POLICY.offset_addition_default', {
                        percent: numberFormatToSameDecimal(DEFAULT_COMPENSATION_INCREASED_OFFSET),
                      })}
                    </span>
                  </Form.Label>
                  <FakeInputBox
                    width={60}
                    align="end"
                    value={toNumber(
                      selectedPolicy.policySettings.salaryChange.compensationWithIncreasedOffset.offsetAddition,
                    )}
                  />
                </Form.Group>
              </div>
            </div>
          }
          {
            <div className="mb-4">
              <Form.Group className="pe-0">
                <Form.Check
                  disabled
                  inline
                  type="checkbox"
                  label={t('pension_manager_salary_change_plans')}
                  id="isCheckedSepratePensionManagerPlans"
                  className="fs-name mb-1"
                  name="isCheckedSepratePensionManagerPlans"
                  defaultChecked={selectedPolicy.policySettings.salaryChange.isCheckedSepratePensionManagerPlans}
                />
                <p className="ms-4 theme-text" style={{ width: 300 }}>
                  {t('PENSION_POLICY.separate_pension_manager_plan_text')}
                </p>
              </Form.Group>
            </div>
          }
        </div>
      }
    </>
  );
};
