import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { FormProps } from '../types';
import classNames from 'classnames';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../util/form';
import PersonalNumber from '../../../../components/form/inputs/PersonalNumber';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import useLocale from '../../../../hooks/useLocale';
import { CompanyClientSaveMode } from '../../../superAdmin/CompanyClients/types';

export default function AuthorizedSignatory({ context }: FormProps) {
  const { formGroup, combinations, companyDetails, replaceCombinations } = context;
  const { localize } = useLocale();

  const handleCombination = (e, combinationIndex, signatoryIndex) => {
    const _combinations = [...combinations];
    const foundDetails = _combinations[combinationIndex].authorizedSignatory[signatoryIndex];
    if (foundDetails) {
      foundDetails![e.target.name] = e.target.value;
      replaceCombinations(_combinations);
    }
  };

  const handleSignee = (e, combinationIndex) => {
    const _combinations = [...combinations];
    const foundDetails = _combinations[combinationIndex];
    const notFoundDetails = _combinations.filter((i, index) => index !== combinationIndex);
    if (foundDetails) {
      foundDetails['signee'] = e.target.checked;
      replaceCombinations(_combinations);
    }
    if (notFoundDetails) {
      notFoundDetails.map((value) => {
        return (value.signee = false);
      });
      replaceCombinations(_combinations);
    }
  };

  return (
    <div>
      <PagesHeader pageTitle="Authorized signatory" headingClass="fs-h3" />
      <div className="form-section mb-3 dark-content-section">
        {combinations?.map((item, idx) => {
          return (
            <React.Fragment key={idx}>
              <Row className="align-items-end mb-3">
                <div className="d-flex justify-content-between my-2">
                  <div className="theme-text">
                    {localize('signatory_combination')} {idx + 1}
                  </div>
                  <div>
                    <Col className='radio-button-box'>
                      <div className="radio-button-label">
                        <Form.Check
                          inline
                          type="radio"
                          name="signee"
                          id="signee"
                          label={''}
                          style={{ marginRight: 0 }}
                          onChange={(e) => handleSignee(e, idx)}
                          value={item.signee as any}
                          //  checked={form.incharge}
                          disabled={companyDetails.status === CompanyClientSaveMode.SETUP}
                          defaultChecked={item.signee}
                        />
                        <Form.Label className="fs-name mb-0">{localize('signatory_combination')}</Form.Label>
                      </div>
                    </Col>
                  </div>
                </div>
                {item?.authorizedSignatory.map((form, index) => {
                  return (
                    <React.Fragment key={`signatory-${index}`}>
                      <div className='cr-form-row' key={index}>
                        <PersonalNumber
                          disabled={true}
                          value={form.personalNumber}
                          isRequiredError={true}
                          field={formGroup.getFieldMeta(
                            `combinations[${idx}].authorizedSignatory[${index}].personalNumber`,
                          )}
                        />
                        <div className='first-name-field'>
                          <Form.Label className="fs-name mb-0">{localize('first_name')}</Form.Label>
                          <Form.Control
                            size="lg"
                            type="text"
                            name="firstName"
                            value={form.firstName}
                            disabled
                            className={classNames('text-left')}
                          />
                        </div>
                        <div className='last-name-field'>
                          <Form.Label className="fs-name mb-0 ">{localize('last_name')}</Form.Label>
                          <Form.Control
                            size="lg"
                            type="text"
                            name="lastName"
                            value={form.lastName}
                            disabled
                            className={classNames('text-left')}
                          />
                        </div>
                        <div className='role-field'>
                          <Form.Label className="fs-name mb-0">{localize('role')}</Form.Label>
                          <Form.Control
                            size="lg"
                            type="text"
                            name="role"
                            value={form.position ? form?.position : ''}
                            disabled
                            className={classNames('text-left')}
                          />
                        </div>
                        <div className='email-field'>
                          <Form.Group>
                            <Form.Label
                              className={classNames('fs-name mb-0', {
                                required: formGroup.values.combinations[idx].signee,
                              })}
                            >{`${localize('email')} ${localize('address')}`}</Form.Label>
                            <Form.Control
                              size="lg"
                              type="text"
                              onChange={(e) => handleCombination(e, idx, index)}
                              name="email"
                              value={form.email}
                              className={classNames(
                                'text-left',
                                errorHighlighter(
                                  formGroup.getFieldMeta(`combinations[${idx}].authorizedSignatory[${index}].email`),
                                ),
                              )}
                              disabled={companyDetails.status === CompanyClientSaveMode.SETUP}
                            />
                            <ErrorFeedback
                              field={formGroup.getFieldMeta(`combinations[${idx}].authorizedSignatory[${index}].email`)}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Row>
              {combinations.length + 1 !== idx && <HorizontalRule color="#232D37" height="1px" />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
