import { useEffect, useState } from 'react';
import useContext from '../../PensionPolicyContext/useContext';
import { isEqual } from '../../../../../util/util';
import { TypeInsurances } from '../../types';

const useEmployeeInsuranceEffectiveDate = () => {
  const {
    preAssignedPolicy,
    formGroup,
    getPolicySettingsInput,
    updatePolicySettingsInput,
    externalPolicyRevision,
    selectedPolicyDetails,
  } = useContext();

  const [isModifyEffectiveDate, setIsModifyEffectiveDate] = useState(true);

  const updateEmployeePensionSettings = (keyName: string, val) => {
    updatePolicySettingsInput(`employeeMetaSettings.insurance.${keyName}`, val);
  };

  const getEmployeePensionSettings = (keyName: string) => {
    return getPolicySettingsInput(`employeeMetaSettings.insurance.${keyName}`);
  };

  const postToggleEffectiveDate = () => {
    if (
      externalPolicyRevision?.assignedEmployeePension &&
      selectedPolicyDetails &&
      externalPolicyRevision?.assignedEmployeePension?.currentPolicy?.id !== selectedPolicyDetails?._id
    ) {
      setIsModifyEffectiveDate(false);
    }
  };

  const disableEffectiveDateIfInvalid = () => {
    if (!preAssignedPolicy?.id) {
      setIsModifyEffectiveDate(true);
      updateEmployeePensionSettings('effectiveFrom', '');

      return;
    }

    const _currentPolicySettings = formGroup.values?.policySettings;
    const _initialPolicySettings = externalPolicyRevision?.assignedEmployeePension?.policySettings || null;

    const preAssignedInsurances: TypeInsurances[] = _initialPolicySettings?.insurances?.map((item) => {
      return {
        insuranceId: item.insuranceId || '',
        plans: (item.plans || []).map((planItem) => {
          return {
            categoryName: planItem?.categoryName || '',
            isChecked: planItem?.isChecked || false,
            planName: planItem?.planName || '',
          };
        }),
      };
    });

    const currentInsurances: TypeInsurances[] = (_currentPolicySettings?.insurances || [])?.map((item) => {
      return {
        insuranceId: item.insuranceId || '',
        plans: (item.plans || []).map((planItem) => {
          return {
            categoryName: planItem?.categoryName || '',
            isChecked: planItem?.isChecked || false,
            planName: planItem?.planName || '',
          };
        }),
      };
    });

    if (isEqual(preAssignedInsurances, currentInsurances)) {
      setIsModifyEffectiveDate(false);
      updateEmployeePensionSettings(
        'effectiveFrom',
        _initialPolicySettings?.employeeMetaSettings?.insurance?.effectiveFrom,
      );
    } else {
      setIsModifyEffectiveDate(true);
      updateEmployeePensionSettings('effectiveFrom', '');
    }
  };

  const defaultStartEffectiveDate = () => {
    if (!isModifyEffectiveDate) {
      return getEmployeePensionSettings(`effectiveFrom`);
    }

    return null;
  };

  useEffect(() => {
    if (preAssignedPolicy?.id) {
      disableEffectiveDateIfInvalid();
    }

    postToggleEffectiveDate();
  }, [formGroup.values?.policySettings?.insurances, preAssignedPolicy, externalPolicyRevision, selectedPolicyDetails]);

  return {
    isModifyEffectiveDate,
    defaultStartEffectiveDate,
    updateEmployeePensionSettings,
    getEmployeePensionSettings,
  };
};

export default useEmployeeInsuranceEffectiveDate;
