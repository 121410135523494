import { createContext, useState, SetStateAction, Dispatch, useEffect } from 'react';
import { getSalaryIntervalByYear } from '../../../../api/service/superAdmin/settings/service';
import { SuperAdminPensionPolicyCompaniesData, SuperAdminPensionPolicySalaryData } from '../../../../dummy/data';
import { ITableValues } from '../../../../types/base/TableTypes';
import { PensionPolicy, PensionPolicyFormGroup } from '../../../superAdmin/PensionPolicy/types';
import {
  INITIAL_SALARY_TYPE_PENSION_POLICY,
  INITIAL_VALUE_PENSION_POLICY,
} from '../../../superAdmin/PensionPolicy/constants';
import { formSchema } from '../../../../components/form/schema';
import { useFormik } from 'formik';
import { AuthUser, UserRole } from '../../../../types/auth';
import { getSalaryTypes } from '../../../../api/service/superAdmin/pension-policies/service';
import { deepClone } from '../../../../util/util';

interface LooseObject {
  [key: string]: any;
}
type PolicyForm = {
  employeeType: string;
  effective_from: string;
};

export const INITIAL_COMPENSATION_PLAN = {
  salaryInterval: '',
  totalSalary: {
    percentageValue: '',
    fixedValue: '',
  },
  partsUnder: {
    percentageValue: '0',
    fixedValue: '0',
  },
  partsOver: {
    percentageValue: '0',
    fixedValue: '0',
  },
};
interface ValuesObject {
  percentageValue: string | number | undefined;
  fixedValue: string | number | undefined;
}

type CompensationPlan = {
  salaryInterval: string | number | undefined;
  totalSalary: ValuesObject;
  partsUnder: ValuesObject;
  partsOver: ValuesObject;
};

export interface IPersonalPolicyContext {
  tabKey: string;
  setTabKey: any;
  isBusy: boolean;
  mounted: boolean;
  setIsBusy: Dispatch<SetStateAction<boolean>>;
  policyForm: PolicyForm;
  hasError: LooseObject;
  setHasError: Dispatch<SetStateAction<LooseObject>>;
  setPolicyForm: Dispatch<SetStateAction<PolicyForm>>;
  salaryTypes: ITableValues<typeof SuperAdminPensionPolicySalaryData>;
  setSalaryTypes: Dispatch<SetStateAction<ITableValues<typeof SuperAdminPensionPolicySalaryData>>>;
  pensionCompanies: ITableValues<typeof SuperAdminPensionPolicyCompaniesData>;
  setPensionCompanies: Dispatch<SetStateAction<ITableValues<typeof SuperAdminPensionPolicyCompaniesData>>>;
  plan: CompensationPlan;
  setPlan: Dispatch<SetStateAction<CompensationPlan>>;
  salaryInterval: number;
  formGroup: PensionPolicyFormGroup;
  replacePolicyInput: (inputValue: any) => void;
  updatePolicyFieldInput: (inputName: string, inputValue: any) => void;
  updateCompensationPlanFieldInput: (inputName: string, inputValue: any) => void;
  updateSalaryCodesInput: (inputValue: any) => void;
  updatePensionCompaniesInput: (inputValue: any) => void;
  replaceCompensationPlanInput: (inputValue: any) => void;
}

export const PersonalPolicyContext = createContext<IPersonalPolicyContext>({
  tabKey: '',
  setTabKey: () => {},
  isBusy: true,
  setIsBusy: () => {},
  mounted: false,
  policyForm: { employeeType: '', effective_from: '' },
  setPolicyForm: () => {},
  salaryTypes: INITIAL_SALARY_TYPE_PENSION_POLICY,
  setSalaryTypes: () => {},
  pensionCompanies: { data: [] },
  setPensionCompanies: () => {},
  plan: INITIAL_COMPENSATION_PLAN,
  setPlan: () => {},
  hasError: {},
  setHasError: () => {},
  salaryInterval: 0,
  formGroup: {} as PensionPolicyFormGroup,
  updatePolicyFieldInput: () => {},
  updateCompensationPlanFieldInput: () => {},
  updateSalaryCodesInput: () => {},
  updatePensionCompaniesInput: () => {},
  replaceCompensationPlanInput: () => {},
  replacePolicyInput: () => {},
});

type Props = {
  children: JSX.Element;
};

export const PersonalPolicyProvider = ({ children }: Props) => {
  const [policyForm, setPolicyForm] = useState({ employeeType: '', effective_from: '' });
  const [isBusy, setIsBusy] = useState(false);
  const [hasError, setHasError] = useState({});
  const [salaryTypes, setSalaryTypes] = useState<ITableValues<typeof SuperAdminPensionPolicySalaryData>>(
    INITIAL_SALARY_TYPE_PENSION_POLICY,
  );
  const [pensionCompanies, setPensionCompanies] = useState<ITableValues<typeof SuperAdminPensionPolicyCompaniesData>>({
    data: [],
  });
  const [plan, setPlan] = useState<CompensationPlan>(INITIAL_COMPENSATION_PLAN);
  const [salaryInterval, setSalryInterval] = useState(0);
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const selectedPensionCompanies = pensionCompanies.data.filter((i) => i.selected);
  const selectedsalaryTypes = salaryTypes.data.filter((i) => i.selected);
  const [tabKey, setTabKey] = useState('deal');
  const [mounted, setIsMounted] = useState<boolean>(false);

  const formGroup = useFormik<PensionPolicy>({
    initialValues: {
      ...deepClone(INITIAL_VALUE_PENSION_POLICY),
      isCollectiveAgreementLock: user?.role?.role === UserRole.SUPER_ADMIN ? true : false,
    },
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  useEffect(() => {
    (async () => {
      const year = new Date().getFullYear();
      const calc = await getSalaryIntervalByYear({ year });
      setSalryInterval(calc?.salaryInterval?.value);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (selectedsalaryTypes.length === 0) {
        setIsBusy(true);
        const _types = await getSalaryTypes();
        setSalaryTypes({ data: _types.map((i) => ({ ...i, selected: false, percentage: i.percentage ?? 100 })) });
        setIsBusy(false);
      }
      if (selectedPensionCompanies.length === 0) {
        setIsBusy(true);
        // const _pensionCompanies = await getPensionCompanies();

        // setPensionCompanies({
        //   data: _pensionCompanies?.map((i) => ({ ...i, selected: false, default: i.default ?? false })),
        // });
        setIsBusy(false);
      }

      setIsMounted(!mounted);
    })();
  }, [tabKey]);

  const updatePolicyFieldInput = (inputName, inputValue) => {
    setPolicyForm({ ...policyForm, [inputName]: inputValue });
    formGroup.setFieldValue(inputName, inputValue);
  };

  const updateSalaryCodesInput = (inputValue) => {
    setSalaryTypes(inputValue);
    formGroup.setFieldValue('salaryCodes', inputValue);
  };

  const updatePensionCompaniesInput = (inputValue) => {
    setPensionCompanies(inputValue);
    formGroup.setFieldValue('pensionCompanies', inputValue);
  };

  const updateCompensationPlanFieldInput = (inputName, inputValue) => {
    setPensionCompanies(inputValue);
    formGroup.setFieldValue(`plan.${inputName}`, inputValue);
  };

  const replaceCompensationPlanInput = (inputValue) => {
    setPlan(inputValue);
    formGroup.setFieldValue(`plan`, inputValue);
  };

  const replacePolicyInput = (inputValue) => {
    setPolicyForm(inputValue);
    formGroup.setFieldValue('effective_from', inputValue.effective_from);
  };

  const context: IPersonalPolicyContext = {
    policyForm,
    setPolicyForm,
    salaryTypes,
    setSalaryTypes,
    pensionCompanies,
    setPensionCompanies,
    plan,
    setPlan,
    isBusy,
    setIsBusy,
    tabKey,
    setTabKey,
    hasError,
    setHasError,
    salaryInterval,
    formGroup,
    mounted,
    updatePolicyFieldInput,
    updateSalaryCodesInput,
    updatePensionCompaniesInput,
    updateCompensationPlanFieldInput,
    replaceCompensationPlanInput,
    replacePolicyInput,
  };

  return <PersonalPolicyContext.Provider value={context}>{children}</PersonalPolicyContext.Provider>;
};
