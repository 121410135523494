import { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import classNames from 'classnames';
// import styles from '../../../../../assets/scss/6-pages/company/table.module.scss';
import { MoreVertical, Plus } from 'react-feather';
import { useSortable } from '@dnd-kit/sortable';
import { IInsurancesContext, InsurancePlan, Mode } from '../../types';
import { CSS } from '@dnd-kit/utilities';
import { Trash2 } from 'react-feather';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../../util/form';
import useLocale from '../../../../../hooks/useLocale';
import { generateNumberList, isObjectEmpty } from '../../../../../util/util';
import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/settings/insurance.module.scss';
import InsuranceCostSpecification from './InsuranceCostSpecification';
import InsuranceCostSpecificationViewDetails from './InsuranceCostSpecificationViewDetails';
import TooltipBox from '../../../../../components/base/Overlay/TooltipBox';

interface Props {
  context: IInsurancesContext;
  mode: Mode;
  index: number;
  plan: InsurancePlan;
  isEditable: boolean;
  planType: string;
}

export const PlanItem = ({ context, mode, index, plan, isEditable, planType }: Props) => {
  const { replaceInsurancePlan, replaceInsuranceDocuments, updateInsuranceAgeSpan, formGroup } = context;
  const { localize } = useLocale();
  const [documentValue, setDocumentValue] = useState({ text: '', documentLink: '' });
  const insurancePlans = formGroup.values?.insurancePlans[planType] || [];
  const managerDocuments = formGroup.values?.insurancePlans[planType][index]?.documents || [];
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: plan.id!,
    attributes: {
      role: 'draggable',
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const range = generateNumberList(15, 75);

  const deleteInsurancePlan = (index) => {
    const _insurancesPlans = [...insurancePlans];
    const filteredInsurancesPlan = _insurancesPlans
      .filter((i, idx) => idx !== index)
      .map((item, idx) => {
        item.order = idx + 1;

        return item;
      });

    replaceInsurancePlan(filteredInsurancesPlan, planType);
  };

  const handleInsurancePlan = (e, index) => {
    const plans = [...insurancePlans];
    const selectedInsurancePlan = plans[index];
    if (e.target.checked) {
      selectedInsurancePlan![e.target.name] = e.target.checked;
    } else {
      selectedInsurancePlan![e.target.name] = e.target.value;
    }
    replaceInsurancePlan(plans, planType);
  };

  /* const toggleInsuranceType = (e, index) => {
    const plans = [...insurancePlans];
    const selectedInsurancePlan = plans[index];
    if (e.target.checked) {
      selectedInsurancePlan![e.target.name] = e.target.checked;
    } else {
      selectedInsurancePlan![e.target.name] = false;
    }
    replaceInsurancePlan(plans, planType);
  }; */

  const addDocument = () => {
    const document = [...managerDocuments, documentValue];
    replaceInsuranceDocuments(document, planType, index);
    setDocumentValue({ text: '', documentLink: '' });
  };

  const deleteDocument = (documnetIndex) => {
    const _documents = [...managerDocuments];
    const filteredDocuments = _documents.filter((doc, docIndex) => {
      return docIndex !== documnetIndex;
    });
    replaceInsuranceDocuments(filteredDocuments, planType, index);
  };

  const handleInsurancePlanDocuments = (e, docIndex) => {
    const _documents = [...insurancePlans];
    const foundDocument = _documents.find((i, index) => index === docIndex);
    foundDocument![e.target.name] = e.target.value;
    replaceInsuranceDocuments(_documents, planType, index);
  };

  const handleDocuments = (e, index) => {
    setDocumentValue((preval) => ({ ...preval, [e.target.name]: e.target.value }));
  };

  const handleInsuranceAgePlan = (e) => {
    updateInsuranceAgeSpan(e.target.name, e.target.value, planType, index);
  };

  return (
    <>
      <tr className="highlight-inactive" ref={setNodeRef} style={style} {...attributes}>
        <td className="align-baseline p-0 border-bottom-0" colSpan={6}>
          <table
            className={classNames(
              'table table-hover table-condensed w-100 insurance-plan-table',
              themeStyle.insurance_plan,
            )}
          >
            <thead>
              <tr>
                {[Mode.Edit, Mode.Add].indexOf(mode) !== -1 && (
                  <td className={classNames(themeStyle.sort_icon_th)}></td>
                )}
                <td className={classNames('table-heading', themeStyle.name_th)}>{localize('insurance_name')}</td>
                <td className={classNames('table-heading', themeStyle.desc_th)}>{localize('insurance_description')}</td>
                <td className={classNames('table-heading', themeStyle.other_th)}></td>
                {/* <td className="table-heading">Listing order</td> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                {[Mode.Edit, Mode.Add].indexOf(mode) !== -1 && (
                  <td className="align-baseline">
                    {isEditable && (
                      <div {...listeners} className="draggable-item">
                        <MoreVertical />
                      </div>
                    )}
                  </td>
                )}
                <td className={classNames(themeStyle.title_col)}>
                  <Form.Control
                    type="text"
                    name="insuranceName"
                    onChange={(e) => handleInsurancePlan(e, index)}
                    value={formGroup.values.insurancePlans[planType][index].insuranceName}
                    className={classNames(
                      errorHighlighter(formGroup.getFieldMeta(`insurancePlans.${[planType]}.${[index]}.insuranceName`)),
                    )}
                    disabled={mode === Mode.Detail}
                  />
                  <ErrorFeedback
                    field={formGroup.getFieldMeta(`insurancePlans.${[planType]}.${[index]}.insuranceName`)}
                  />
                </td>
                <td colSpan={3} className={classNames(themeStyle.desc_col_td)}>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className={classNames('inner-table', themeStyle.desc_cell)}>
                          <Form.Control
                            style={{ height: 100 }}
                            as="textarea"
                            value={formGroup.values.insurancePlans[planType][index].insuranceDescription}
                            className={classNames(
                              'text-left',
                              errorHighlighter(
                                formGroup.getFieldMeta(`insurancePlans.${[planType]}.${[index]}.insuranceDescription`),
                              ),
                            )}
                            onChange={(e) => handleInsurancePlan(e, index)}
                            name="insuranceDescription"
                            disabled={mode === Mode.Detail}
                          />

                          <ErrorFeedback
                            field={formGroup.getFieldMeta(
                              `insurancePlans.${[planType]}.${[index]}.insuranceDescription`,
                            )}
                          />
                          <Row className="align-items-baseline">
                            {planType === 'groupInsurancePlan' && (
                              <Col>
                                <Form.Select
                                  name="pay"
                                  value={formGroup.values.insurancePlans[planType][index].pay}
                                  onChange={(e) => handleInsurancePlan(e, index)}
                                  className={classNames(
                                    errorHighlighter(
                                      formGroup.getFieldMeta(`insurancePlans.${[planType]}.${[index]}.pay`),
                                    ),
                                  )}
                                  disabled={mode === Mode.Detail}
                                >
                                  <option value={'private'}>{localize('private')}</option>
                                  <option value={'private-pay'}>{localize('private_pay')}</option>
                                </Form.Select>
                                <ErrorFeedback
                                  field={formGroup.getFieldMeta(`insurancePlans.${[planType]}.${[index]}.pay`)}
                                />
                              </Col>
                            )}
                            {/* <Col>
                              <Form.Check
                                defaultChecked={formGroup.values.insurancePlans[planType][index].hjertaInfinity}
                                onChange={(e) => handleInsurancePlan(e, index)}
                                inline
                                name="hjertaInfinity"
                                type="checkbox"
                                label={`${localize('hjerta_infinity')}`}
                                className="fs-name  mt-2"
                                id="hjertaInfinity"
                                disabled={mode === Mode.Detail}
                              />
                            </Col> */}
                          </Row>
                          <div className={classNames(themeStyle.age_condition)}>
                            <div className={classNames('d-flex age-dropdown', themeStyle.age_control)}>
                              <Form.Select
                                disabled={mode === Mode.Detail}
                                name="from"
                                value={formGroup.values.insurancePlans[planType][index].ageSpan.from}
                                onChange={(e) => handleInsuranceAgePlan(e)}
                                className={classNames(
                                  errorHighlighter(
                                    formGroup.getFieldMeta(`insurancePlans.${[planType]}.${[index]}.ageSpan.from`),
                                  ),
                                )}
                              >
                                <option value="" disabled>
                                  {localize('select')}
                                </option>
                                {range.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              </Form.Select>

                              <span>-</span>
                              <Form.Select
                                disabled={mode === Mode.Detail}
                                name="to"
                                value={formGroup.values.insurancePlans[planType][index].ageSpan.to}
                                onChange={(e) => handleInsuranceAgePlan(e)}
                                className={classNames(
                                  errorHighlighter(
                                    formGroup.getFieldMeta(`insurancePlans.${[planType]}.${[index]}.ageSpan.to`),
                                  ),
                                )}
                              >
                                <option value="" disabled>
                                  {localize('select')}
                                </option>
                                {range.map((item) => (
                                  <option value={item} key={item}>
                                    {item}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className={classNames('sp_condition_control', themeStyle.condition_control)}>
                              <Form.Select
                                name="specialCondition"
                                value={formGroup.values.insurancePlans[planType][index].specialCondition}
                                onChange={(e) => handleInsurancePlan(e, index)}
                                className={classNames(
                                  errorHighlighter(
                                    formGroup.getFieldMeta(`insurancePlans.${[planType]}.${[index]}.specialCondition`),
                                  ),
                                )}
                                disabled={mode === Mode.Detail}
                              >
                                <option value={'able-bodied'}>{localize('able-bodied')}</option>
                                <option value={'disabled'}>{localize('disabled')}</option>
                              </Form.Select>
                              <ErrorFeedback
                                field={formGroup.getFieldMeta(
                                  `insurancePlans.${[planType]}.${[index]}.specialCondition`,
                                )}
                              />
                            </div>
                          </div>
                        </td>
                        <td className={classNames('inner-table', themeStyle.condition_cell)}>
                          <table className="w-100">
                            <thead>
                              <tr>
                                <td className={classNames('table-heading', themeStyle.other_doc_cell)}>
                                  {localize('documents')} ({localize('links')})
                                </td>
                                <td className={classNames('table-heading', themeStyle.other_action_cell)}>
                                  {mode !== Mode.Detail && (
                                    <span style={{ cursor: 'pointer' }} onClick={() => deleteInsurancePlan(index)}>
                                      <TooltipBox msg={`${localize('delete')}`}>
                                        <Trash2 color="#930000" />
                                      </TooltipBox>
                                    </span>
                                  )}
                                  {mode === Mode.Detail && <></>}
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {formGroup.values.insurancePlans[planType][index].documents?.map((document, docIndex) => (
                                <tr key={docIndex}>
                                  <td className={classNames(themeStyle.other_doc_cell)}>
                                    <div className={classNames(themeStyle.doc_form_group)}>
                                      <div className={classNames(themeStyle.doc_form_control)}>
                                        <div className={classNames(themeStyle.doc_form_control_text)}>
                                          <Form.Control
                                            type="text"
                                            value={
                                              formGroup.values.insurancePlans[planType][index].documents[docIndex].text
                                            }
                                            className={classNames(
                                              'text-left',
                                              errorHighlighter(
                                                formGroup.getFieldMeta(
                                                  `insurancePlans.${[planType]}.${[index]}.documents${[docIndex]}.text`,
                                                ),
                                              ),
                                            )}
                                            onChange={(e) => handleInsurancePlanDocuments(e, docIndex)}
                                            name="text"
                                            disabled={mode === Mode.Detail}
                                            placeholder={`${localize('link_text')}`}
                                          />
                                          <ErrorFeedback
                                            field={formGroup.getFieldMeta(
                                              `insurancePlans.${[planType]}.${[index]}.documents${[docIndex]}.text`,
                                            )}
                                          />
                                        </div>
                                        <div className={classNames(themeStyle.doc_form_control_link)}>
                                          <Form.Control
                                            type="text"
                                            value={
                                              formGroup.values.insurancePlans[planType][index].documents[docIndex]
                                                .documentLink
                                            }
                                            className={classNames(
                                              'text-left',
                                              errorHighlighter(
                                                formGroup.getFieldMeta(
                                                  `insurancePlans.${[planType]}.${[index]}.documents${[docIndex]}.documentLink`,
                                                ),
                                              ),
                                            )}
                                            onChange={(e) => handleInsurancePlanDocuments(e, docIndex)}
                                            name="documentLink"
                                            disabled={mode === Mode.Detail}
                                            placeholder={`${localize('document_link')}`}
                                          />
                                          <ErrorFeedback
                                            field={formGroup.getFieldMeta(
                                              `insurancePlans.${[planType]}.${[index]}.documents${[docIndex]}.documentLink`,
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div className={classNames(themeStyle.doc_form_control_action)}>
                                        {!isObjectEmpty(document) && [Mode.Edit, Mode.Add].indexOf(mode) !== -1 && (
                                          <span style={{ cursor: 'pointer' }} onClick={() => deleteDocument(docIndex)}>
                                            <TooltipBox msg={`${localize('delete')}`}>
                                              <Trash2 color="#930000" />
                                            </TooltipBox>
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </td>

                                  <td className={classNames(themeStyle.other_action_cell)}></td>
                                </tr>
                              ))}

                              {[Mode.Edit, Mode.Add].indexOf(mode) !== -1 && (
                                <tr>
                                  <td className={classNames('table-heading', themeStyle.other_doc_cell)}>
                                    <div className={classNames(themeStyle.doc_form_group)}>
                                      <div className={classNames(themeStyle.doc_form_control)}>
                                        <div className={classNames(themeStyle.doc_form_control_text)}>
                                          <Form.Control
                                            type="text"
                                            value={documentValue.text}
                                            className={classNames('text-left')}
                                            onChange={(e) => handleDocuments(e, index)}
                                            name="text"
                                            placeholder={`${localize('link_text')}`}
                                          />
                                        </div>
                                        <div className={classNames(themeStyle.doc_form_control_link)}>
                                          <Form.Control
                                            type="text"
                                            value={documentValue.documentLink}
                                            className={classNames('text-left')}
                                            onChange={(e) => handleDocuments(e, index)}
                                            name="documentLink"
                                            placeholder={`${localize('document_link')}`}
                                          />
                                        </div>
                                      </div>
                                      <div className={classNames(themeStyle.doc_form_control_action)}>
                                        <Button className="app-btn-secondary btn btn-primary" onClick={addDocument}>
                                          <Plus />
                                        </Button>
                                      </div>
                                    </div>
                                  </td>
                                  <td className={classNames('table-heading', themeStyle.other_action_cell)}></td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          {mode !== Mode.Detail && (
                            <InsuranceCostSpecification mode={mode} planType={planType as any} planIndex={index} />
                          )}
                          {mode === Mode.Detail && (
                            <InsuranceCostSpecificationViewDetails
                              mode={mode}
                              planType={planType as any}
                              planIndex={index}
                            />
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
};

export default PlanItem;
