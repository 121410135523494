import { useEffect, useState } from 'react';
import moment from 'moment';

import useAuth from '../../../../../hooks/useAuth';
import { ISeries, ISeriesOptions } from '../../../../../components/charts/multi-series/types';
import { Calendar } from '../../../../../util/calendar';
import { IReportFilterParams } from '../../../../../types/report';
import { getCustomizableReport } from '../../../../../api/service/company/reports/service';

const useCustomEmployeeChart = (selectedReportTypeSeries: ISeries[]) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState<[string, string]>(Calendar.yearToDateBoundary);
  const [chartRecords, setChartRecords] = useState<any[]>([]);
  const [employeeSeries, setEmployeeSeries] = useState<ISeries[]>([{ label: 'Total', field: 'total' }]);

  const seriesOptions: ISeriesOptions = {
    series: employeeSeries,
    allowDeleteSeries: true,
    allowAddSeries: employeeSeries.length < 7 ? true : false,
    showToggleButtons: employeeSeries.length > 1 ? true : false,
  };

  const changeDateHandler = (dateRange: [string, string]) => {
    setDateRange(dateRange);
  };

  const addEmployeeToChart = (employee) => {
    setEmployeeSeries([
      ...employeeSeries,
      {
        label: employee.fullName,
        field: employee.id,
        metaData: employee,
      },
    ]);
  };

  const deleteHandler = (series) => {
    const employees = employeeSeries.filter((e) => e.field !== series.field);
    setEmployeeSeries([...employees]);
  };

  const fetchChartData = async () => {
    const startDateOfTheYear = dateRange[0];
    const endDateOfTheYear = dateRange[1];
    const formattedData: any = [];

    try {
      setIsLoading(true);

      let chartData = await getCustomizableReport({
        duration: { from: startDateOfTheYear, to: endDateOfTheYear },
        companyClientId: user?.role.company.id,
        employeeIds: employeeSeries.slice(1).map((item) => item.metaData.id),
        reportCategory: selectedReportTypeSeries.map((item) => item.field),
      } as IReportFilterParams & { reportCategory: string[] });

      chartData?.salariesByDate
        .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
        .forEach((value) => {
          formattedData.push(
            [moment(value.date).startOf('month').format('YYYY-MM-DD'), value.total || 0].concat(
              value.employeeSalaries
                ? employeeSeries.slice(1).map((item) => {
                    const foundEmployeeSalary = value.employeeSalaries.find(
                      (employeeItem) => employeeItem.employee.id === item.metaData.id,
                    );

                    if (foundEmployeeSalary) {
                      return foundEmployeeSalary['salary'].value || 0;
                    }

                    return 0;
                  })
                : [],
            ),
          );
        });

      setChartRecords([...formattedData]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [dateRange, employeeSeries, selectedReportTypeSeries]);

  return {
    employeeSeries,
    setEmployeeSeries,
    seriesOptions,
    dateRange,
    chartRecords,
    isLoading,
    setDateRange,
    setChartRecords,
    fetchChartData,
    changeDateHandler,
    addEmployeeToChart,
    deleteHandler,
  };
};

export default useCustomEmployeeChart;
