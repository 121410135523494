import moment from 'moment';
import {
  EmployeePensionRequestInput,
  PensionPolicy,
  PensionPolicyCategory,
} from '../../../superAdmin/PensionPolicy/types';
import { BaseAttachedEmployeePolicy } from '../types';

export class EmployeePensionRequest {
  static toModel({
    employeePolicySettings,
    currentPolicy,
    selectedPolicyType,
  }: {
    employeePolicySettings: PensionPolicy['policySettings'];
    currentPolicy: BaseAttachedEmployeePolicy | null;
    selectedPolicyType: PensionPolicyCategory | null;
  }) {
    const employeePensionPayload = {
      pensionManagers: {
        effectiveFrom: employeePolicySettings?.employeeMetaSettings!.pensionManager.effectiveFrom || '',
        plans: employeePolicySettings?.pensionManagers || [],
      },
      insurances: {
        effectiveFrom: employeePolicySettings?.employeeMetaSettings!.insurance.effectiveFrom || '',
        insurancesList: employeePolicySettings?.insurances || [],
      },
      salaryChange: employeePolicySettings?.salaryChange as unknown,
    } as Partial<EmployeePensionRequestInput>;

    const payload: any = Object.assign(
      {
        attachedAt: currentPolicy ? currentPolicy.attachedAt : moment().toISOString(),
      },

      selectedPolicyType !== PensionPolicyCategory.NO_POLICY
        ? {
            id: currentPolicy ? currentPolicy.id : undefined,
            type: currentPolicy ? currentPolicy.type : PensionPolicyCategory.NO_POLICY,
          }
        : null,

      selectedPolicyType !== PensionPolicyCategory.NO_POLICY ? employeePensionPayload : null,
    );

    return payload;
  }

  static fromModel(employeePension) {}
}
