import moment, { Moment } from 'moment';
import { MonthSelectOption } from '../../../../types';
import { Calendar } from '../../../../util/calendar';
import { VACATION_CUT_OF_NEXT_MONTH_DATE } from '../../../superAdmin/PensionPolicy/constants';

export class CalendarEffectiveDate {
  static selectMonthOffset(date: string) {
    const inputDate = moment(date);
    const currentYear = moment().year();
    const currentMonth = moment().month() + 1;

    if (inputDate.isBefore(moment(`${currentYear}-${currentMonth}`))) {
      return inputDate.subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
    }

    return date;
  }

  static selectNextMonthOffset(date: string) {
    const inputDate = moment(date);

    return inputDate.add(1, 'month').startOf('month').format('YYYY-MM-DD');
  }

  static selectVacationDateOptions = (vacationEffectiveDate: string | null | undefined) => {
    const upcomingMonthList: MonthSelectOption[] = Calendar.getMonthlyOptionAdapter({
      noOfMonths: 6,
      startFrom: Calendar.nextMonthStartDate,
    });
    const previousMonthList: MonthSelectOption[] = [];

    const currentMonthStartDateValue = Calendar.currentMonthStartDate;
    const currentMonthDate = Calendar.instance();
    const cutoffCurrentMonthDate = Calendar.parseDateStr(currentMonthStartDateValue)
      .date(VACATION_CUT_OF_NEXT_MONTH_DATE)
      .endOf('day');
    const isCutOffCurrentMonthDatePassed = currentMonthDate.isAfter(cutoffCurrentMonthDate);

    if (vacationEffectiveDate) {
      const effectiveMonthYear = Calendar.instance(vacationEffectiveDate).format('YYYY-MM');
      const currentMonthYear = Calendar.instance().format('YYYY-MM');
      let prevMonthInstance: Moment | null = null;

      if (
        effectiveMonthYear !== currentMonthYear &&
        !Calendar.instance(vacationEffectiveDate).isAfter(Calendar.instance())
      ) {
        prevMonthInstance = Calendar.instance(vacationEffectiveDate);
        const prevMonth = Calendar.getMonthDateSelectOption(prevMonthInstance, { isDisabled: true });

        previousMonthList.push(prevMonth);
      }

      if (!isCutOffCurrentMonthDatePassed) {
        const lastMonthInstance = Calendar.instance().startOf('month').subtract(1, 'month');

        if (
          (prevMonthInstance && prevMonthInstance.format('YYYY-MM') !== lastMonthInstance.format('YYYY-MM')) ||
          !prevMonthInstance
        ) {
          const lastMonth = Calendar.getMonthDateSelectOption(lastMonthInstance);

          previousMonthList.push(lastMonth);
        }
      }

      const currentMonth = Calendar.getMonthDateSelectOption(Calendar.instance());

      previousMonthList.push(currentMonth);
    } else {
      if (!isCutOffCurrentMonthDatePassed) {
        const lastMonthInstance = Calendar.instance().startOf('month').subtract(1, 'month');

        const lastMonth = Calendar.getMonthDateSelectOption(lastMonthInstance);

        previousMonthList.push(lastMonth);
      }

      const currentMonth = Calendar.getMonthDateSelectOption(Calendar.instance());

      previousMonthList.push(currentMonth);
    }

    return [...previousMonthList, ...upcomingMonthList];
  };

  static validateVacationEffectiveDate = (vacationEffectiveDate: string | null | undefined, selectedDate: string) => {
    const previousMonthList: MonthSelectOption[] = [];
    const selectedDateValue = Calendar.instance(selectedDate).startOf('month').format('YYYY-MM-DD');
    const currentMonthStartDateValue = Calendar.currentMonthStartDate;
    const currentMonthDate = Calendar.instance();
    const cutoffCurrentMonthDate = Calendar.parseDateStr(currentMonthStartDateValue)
      .date(VACATION_CUT_OF_NEXT_MONTH_DATE)
      .endOf('day');
    const isCutOffCurrentMonthDatePassed = currentMonthDate.isAfter(cutoffCurrentMonthDate);

    if (vacationEffectiveDate) {
      const effectiveMonthYear = Calendar.instance(vacationEffectiveDate).format('YYYY-MM');
      const currentMonthYear = Calendar.instance().format('YYYY-MM');
      let prevMonthInstance: Moment | null = null;

      if (
        effectiveMonthYear !== currentMonthYear &&
        !Calendar.instance(vacationEffectiveDate).isAfter(Calendar.instance())
      ) {
        prevMonthInstance = Calendar.instance(vacationEffectiveDate);
        const prevMonth = Calendar.getMonthDateSelectOption(prevMonthInstance, { isDisabled: true });

        previousMonthList.push(prevMonth);
      }

      if (!isCutOffCurrentMonthDatePassed) {
        const lastMonthInstance = Calendar.instance().startOf('month').subtract(1, 'month');

        if (
          (prevMonthInstance && prevMonthInstance.format('YYYY-MM') !== lastMonthInstance.format('YYYY-MM')) ||
          !prevMonthInstance
        ) {
          const lastMonth = Calendar.getMonthDateSelectOption(lastMonthInstance);

          previousMonthList.push(lastMonth);
        }
      }

      const currentMonth = Calendar.getMonthDateSelectOption(Calendar.instance());

      previousMonthList.push(currentMonth);
    }

    return previousMonthList.find((item) => item.key === selectedDateValue && item.readonly) ? false : true;
  };
}
