import { ACCOUNTING_FIRM_ENDPOINT, API_VERSION, LOGS_ENDPOINT } from '../../../../util/constants';

import { get, put } from '../../../../util/httpService';
import { AccountingFirmClient, IAccountingFirmLogsParams, ISettingInvoicesDoc, ISettingProxyDoc } from './types';
import { HttpQuery } from '../../../../util/http-query';

const getLogsFilterParams = (param: IAccountingFirmLogsParams): IAccountingFirmLogsParams => {
  const defaultParam = {
    page: 1,
    pageSize: 10,
    filter: {
      userId: '',
      action: '',
      level: '',
      from: '',
      to: '',
    },
    sort: { action: 1, fullName: 1, role: 1, dateTime: 1 },
  };
  return Object.assign({}, defaultParam, param);
};

const getCompanyDetailsByID = async (id) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}`;
  const company: AccountingFirmClient = await get(endpoint);
  return company;
};

const updateAccountingFirmSettings = async (id, data) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}`;
  const company: AccountingFirmClient = await put(endpoint, data, {});
  return company;
};

export const getServiceAgreementDocuments = async (id): Promise<any> => {
  const endpoint = `${API_VERSION}/verified/envelope/data/${id}`;
  const res = await get(endpoint);
  return res || [];
};

export const getProxyDocuments = (): Promise<ISettingProxyDoc[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          title: 'PROXY Konsultia AB (2021-03-01) signed 2021-02-15',
        },
        {
          title: 'PROXY Konsultia AB (2021-03-01) signed 2021-02-15',
        },
        {
          title: 'PROXY Konsultia AB (2021-03-01) signed 2021-02-15',
        },
      ]);
    }, 2000);
  });
};
export const getInvoicesDocuments = (): Promise<ISettingInvoicesDoc[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([
        {
          title: '2022-03 (invoice no: 2392)',
        },
        {
          title: '2022-04 (invoice no: 2606) ',
        },
        {
          title: '2022-05 (invoice no: 3214)',
        },
        {
          title: '2022-06 (invoice no: 3214)',
        },
        {
          title: '2022-07 (invoice no: 3214)',
        },
      ]);
    }, 2000);
  });
};

const getAccountingFirmLogDetails = async (companyClientId: any, filter: IAccountingFirmLogsParams) => {
  let endpoint = HttpQuery.serialize(getLogsFilterParams(filter), `${API_VERSION}${LOGS_ENDPOINT}/${companyClientId}`);
  const logs = await get(endpoint);
  return logs;
};

const getAccountingFirmLogCategory = async (companyClientId) => {
  let endpoint = `${API_VERSION}${LOGS_ENDPOINT}/actions/${companyClientId}`;
  const categories = await get(endpoint);
  return categories;
};

const saveAccountingFirmAccessLevel = async (payload, id) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/access/${id}`;
  const accessLevel = await put(endpoint, payload, {});
  return accessLevel;
};

export {
  getCompanyDetailsByID,
  getAccountingFirmLogDetails,
  getAccountingFirmLogCategory,
  updateAccountingFirmSettings,
  saveAccountingFirmAccessLevel,
};
