import { Alert } from 'react-bootstrap';
import { AlertTriangle } from 'react-feather';
import { formatString } from '../util/util';
interface LooseObject {
  [key: string]: any;
}

type Props = {
  show: boolean;
  errors: LooseObject;
};

export default function ValidationAlert({ show, errors }: Props) {
  const errorHandler: any = Object.keys(errors).map((item, index) => {
    if (item === 'error') {
      return (
        <li className="fs-label theme-text" key={index}>
          {errors[item]}
        </li>
      );
    }
    const formattedKey = formatString(item);

    return (
      <li className="fs-label theme-text" key={index}>
        {formattedKey} is required
      </li>
    );
  });

  return (
    <Alert className="d-flex flex-column p-3 validations" show={show} style={{ background: 'rgba(255, 0, 0, 0.2)' }}>
      <Alert.Heading>
        <span className="triangle-Icon icon-gap-right ">
          <AlertTriangle stroke="#930000" className="icon-gap-right" />
        </span>
        <span className="fs-label theme-text ms-2">Following fields are required</span>
      </Alert.Heading>
      <ul className="validation-list">{errorHandler}</ul>
    </Alert>
  );
}
