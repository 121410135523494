import {
  ACCOUNTING_FIRM_ENDPOINT,
  API_VERSION,
  COMPANY_CLIENTS_ENDPOINT,
  LOGS_ENDPOINT,
  //   COMPANY_CLIENTS_ENDPOINT,
  //   MOCK_COMPANY_NAME_ENDPOINT,
  //   MOCK_USER_ENDPOINT,
} from '../../../../util/constants';
import { HttpQuery } from '../../../../util/http-query';

import { del, get, post, put } from '../../../../util/httpService';
import { GetCompanyClientType } from '../company-clients/types';
// import { CompanyClient } from '../company-clients/types';
import { AccountingFirm, GetAccountingFirm, IAccountingFirmLogsParams } from './types';

const getLogsFilterParams = (param: IAccountingFirmLogsParams): IAccountingFirmLogsParams => {

  const defaultParam = {
    page: 1,
    pageSize: 10,
    filter: {
      userId: '',
      action: '',
      level: '',
      from: '',
      to: ''
    },
    sort: { action: 1, fullName: 1, role: 1, dateTime: 1 },

  }
  return Object.assign({}, defaultParam, param);
};
const getAccountingFirms = async ({
  page = 1,
  sizePerPage = 25,
  searchString = '',
  sortField = 'name',
  sortOrder = '1',
}) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}?page=${page}&pageSize=${sizePerPage}&filter[name][like]=${searchString}&sort[${sortField}]=${sortOrder}`;
  const company: GetAccountingFirm = await get(endpoint);
  return company;
};

const getAllAccountingFirms = async ({

  searchString = '',

}) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}?filter[name][like]=${searchString}`;
  const company: GetAccountingFirm = await get(endpoint);
  return company;
};

const postNewAccountingFirm = async (body: AccountingFirm, params: Record<string, boolean>) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}?draft=${params['draft']}&sendEmails=${params['sendEmails']}`;
  const company = await post(endpoint, { ...body }, {});
  return company;
};

const updateAccountingFirm = async (id: string | undefined, body: AccountingFirm) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}`;
  const company = await put(endpoint, { ...body }, {});
  return company;
};

const deleteAccountingFirm = async (id: string) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}`;
  const company = await del(endpoint, {});
  return company;
};


const getAccountingFirmDetails = async (id) => {
  const endpoint = `${API_VERSION}${ACCOUNTING_FIRM_ENDPOINT}/${id}`;
  const company: AccountingFirm = await get(endpoint);
  return company;
};
const getAccountingFirmLogDetails = async (companyClientId: any, filter: IAccountingFirmLogsParams
) => {
  let endpoint = HttpQuery.serialize(getLogsFilterParams(filter), `${API_VERSION}${LOGS_ENDPOINT}/${companyClientId}`);
  const logs = await get(endpoint);
  return logs;
}
const getAccountingFirmLogCategory = async (companyClientId
) => {
  let endpoint = `${API_VERSION}${LOGS_ENDPOINT}/actions/${companyClientId}`;
  const categories = await get(endpoint);
  return categories;
}
const getClients = async ({
  page = 1,
  sizePerPage = 25,
  searchString = '',
  sortField = 'name',
  sortOrder = '1',
  accountingFirmId
}) => {
  const endpoint = `${API_VERSION}${COMPANY_CLIENTS_ENDPOINT}/by/accounting-firm-id/${accountingFirmId}?page=${page}&pageSize=${sizePerPage}&filter[name][like]=${searchString}&sort[${sortField}]=${sortOrder}`;
  const company: GetCompanyClientType = await get(endpoint);
  return company;
};
export {
  getAccountingFirms,
  postNewAccountingFirm,
  getAllAccountingFirms,
  getAccountingFirmDetails,
  updateAccountingFirm,
  deleteAccountingFirm,
  getAccountingFirmLogDetails,
  getAccountingFirmLogCategory,
  getClients

};
