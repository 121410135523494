import { useParams } from 'react-router-dom';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { AlertTriangle, Lock } from 'react-feather';

import ErrorAlert from '../../../../components/ErrorAlert';
import CardComponent from '../../../../components/base/CardComponent';
import PagesHeader from '../../../../components/base/PagesHeader';
import { useSalarySlipContext } from '../context/useContext';
import SalaryArticleItem from './SalaryArticle';
import { useTranslation } from 'react-i18next';
import AlertInfo from '../../../../components/alerts/AlertInfo';

type Props = {
  isBusy: boolean;
};

export default function SalaryDocument({ isBusy }: Props) {
  const { t } = useTranslation();
  const { id } = useParams();
  const { selectedDetails, errorType, formatDate } = useSalarySlipContext();
  const errorMsg = errorType ? errorType.msg : '';

  return (
    <>
      {errorMsg && <ErrorAlert show={true} msg={errorMsg} />}
      <AlertInfo
        show={(selectedDetails?.comments || []).length > 0}
        msg="Articles without values are treated as comments"
      />
      <CardComponent className="salary-doc">
        {isBusy ? (
          <Spinner />
        ) : (
          <>
            <Row className="mb-3 general">
              <Col md="4">
                <PagesHeader pageTitle="General info" headingClass="fs-text-400" />
                <div className="dark-content-section d-flex p-2 align-items-end gap-2">
                  <Form.Group style={{ width: 180 }}>
                    <Form.Label className="fs-name">{t('name')}</Form.Label>
                    <Form.Control
                      className="text-left"
                      type="text"
                      name="personalNumber"
                      disabled
                      value={selectedDetails!.user?.fullName}
                    />
                  </Form.Group>
                  <Form.Group style={{ width: 150 }}>
                    <Form.Label className="fs-name">{t('personal_number')}</Form.Label>
                    <Form.Control
                      className="text-left"
                      type="text"
                      name="personalNumber"
                      disabled
                      value={selectedDetails!.user?.personalNumber}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col md="8">
                <PagesHeader pageTitle="Periods and payment" headingClass="fs-text-400" />
                <div className="dark-content-section d-flex p-2 align-items-end gap-2">
                  <Form.Group style={{ width: 120 }}>
                    <Form.Label className="fs-name">{t('payment_date')}</Form.Label>
                    <Form.Control
                      className="text-left"
                      type="date"
                      disabled
                      value={formatDate(selectedDetails!.paymentDate)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fs-name">{t('earning_period')}</Form.Label>
                    <div className="time-range">
                      <Form.Control
                        style={{ width: 100 }}
                        className="text-left"
                        size="lg"
                        type="text"
                        disabled
                        value={formatDate(selectedDetails!.salaryPeriod.from)}
                      />
                      <span className="separator">&ndash;</span>
                      <Form.Control
                        style={{ width: 100 }}
                        className="text-left"
                        size="lg"
                        type="text"
                        disabled
                        value={formatDate(selectedDetails!.salaryPeriod.to)}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="fs-name">{t('deviation_period')}</Form.Label>
                    <div className="time-range">
                      <Form.Control
                        style={{ width: 90 }}
                        className="text-left"
                        type="text"
                        name="personalNumber"
                        disabled
                        value={formatDate(selectedDetails!.deviationPeriod.from)}
                      />
                      <span className="separator">&ndash;</span>
                      <Form.Control
                        style={{ width: 90 }}
                        className="text-left"
                        type="text"
                        name="personalNumber"
                        disabled
                        value={formatDate(selectedDetails!.deviationPeriod.to)}
                      />
                    </div>
                  </Form.Group>
                </div>
              </Col>
            </Row>
            {id! === '1' && (
              <Row>
                <PagesHeader pageTitle="Specification" headingClass="fs-text-400" />
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table transaction-list">
                      <thead>
                        <tr>
                          <th style={{ minWidth: 230 }}>{t('article')}</th>
                          <th style={{ minWidth: 60 }}>{t('default')}</th>
                          <th style={{ minWidth: 130 }}>{t('from_date')}</th>
                          <th style={{ minWidth: 130 }}>{t('to_date')}</th>
                          <th style={{ minWidth: 80 }}>{t('pieces')}</th>
                          <th style={{ minWidth: 80 }}>{t('unit')}</th>
                          <th style={{ minWidth: 130 }}>{t('amount')}</th>
                          <th style={{ minWidth: 150 }}>{t('value')}</th>
                          <th style={{ minWidth: 150 }}>{t('value_new')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <Form.Select disabled value={'WhiteCollar'}>
                              <option value="">{t('select_option')}</option>
                              <option value="WhiteCollar">Manlon</option>
                              <option value="BlueCollar">Utlagg</option>
                            </Form.Select>
                            <div className="color-red">{t('unmatched_salary_type')}</div>
                          </td>
                          <td>
                            <button disabled className="btn app-btn-secondary">
                              <Lock width={15} />
                            </button>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="number-val amount">75 000,00</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            <Form.Select disabled value={'WhiteCollar'}>
                              <option value="">{t('select_option')}</option>
                              <option value="WhiteCollar">Föräldraledig dagsavdrag, ...</option>
                              <option value="BlueCollar">Utlagg</option>
                            </Form.Select>
                          </td>
                          <td></td>
                          <td>
                            <Form.Control
                              style={{ width: 120 }}
                              className="text-left"
                              type="date"
                              name="personalNumber"
                              disabled
                              value="2023-10-01"
                            />
                          </td>
                          <td>
                            <Form.Control
                              style={{ width: 120 }}
                              className="text-left"
                              type="date"
                              name="personalNumber"
                              disabled
                              value="2023-10-30"
                            />
                          </td>
                          <td>
                            <Form.Control
                              className="text-right"
                              type="text"
                              name="personalNumber"
                              disabled
                              value="4,00"
                            />
                          </td>
                          <td>
                            <Form.Control
                              className="text-left"
                              type="text"
                              name="personalNumber"
                              disabled
                              value="days"
                            />
                          </td>
                          <td>
                            <Form.Control
                              className="text-right"
                              type="text"
                              name="personalNumber"
                              disabled
                              value="-3 571,43"
                            />
                          </td>
                          <td className="number-val amount">-14 285,72</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>
                            <Form.Select disabled value={'WhiteCollar'}>
                              <option value="">{t('select_option')}</option>
                              <option value="WhiteCollar">Utlägg</option>
                              <option value="BlueCollar">Utlagg</option>
                            </Form.Select>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="number-val amount">1 019,00</td>
                          <td></td>
                        </tr>
                        <tr className="separator">
                          <td colSpan={9}></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td className="summary-items" colSpan={4} rowSpan={4}>
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Skattetabell</div>
                                      <div className="summary-item__value">31:1</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Jämkning</div>
                                      <div className="summary-item__value">0,00</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Prel. arbetsgiv. avg %</div>
                                      <div className="summary-item__value">31,42</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Prel. arbetsgiv. avg</div>
                                      <div className="summary-item__value">19 076,43</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Tabellskattegrund</div>
                                      <div className="summary-item__value">60 714,28</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Engångsskattegrund</div>
                                      <div className="summary-item__value">0</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Ack. bruttolön</div>
                                      <div className="summary-item__value">60 714,28</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Ack. preliminärskatt</div>
                                      <div className="summary-item__value">-17 606,00</div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Flexsaldo</div>
                                      <div className="summary-item__value">0,00</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Kompsaldo</div>
                                      <div className="summary-item__value">0,00</div>
                                    </div>
                                    <h5 className="summary-title">Semesterdagar</h5>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Betald</div>
                                      <div className="summary-item__value">0,00</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Sparad</div>
                                      <div className="summary-item__value">0,00</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Obetald</div>
                                      <div className="summary-item__value">21,00</div>
                                    </div>
                                    <div className="summary-item">
                                      <div className="summary-item__label">Förskott</div>
                                      <div className="summary-item__value">2,00</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td colSpan={3} className="total-label">
                            Bruttolön
                          </td>
                          <td className="total-value">
                            <span>60 714,28</span>
                            <AlertTriangle className="warning-indicator" />
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="total-label">
                            Preliminärskatt
                          </td>
                          <td className="total-value">
                            <span>-17 616,00</span>
                            <AlertTriangle className="warning-indicator" />
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="total-label">
                            Skattefria utlägg/avdrag
                          </td>
                          <td className="total-value">
                            <span>1 339,00</span>
                            <AlertTriangle className="warning-indicator" />
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td colSpan={3} className="total-label total-label--payout type-payout">
                            {t('to_payout')}
                          </td>
                          <td className="total-value type-payout amount">
                            <span>44 437,00</span>
                          </td>
                          <td className="type-payout"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </Col>
              </Row>
            )}
            {id! !== '1' && (
              <Row>
                <PagesHeader pageTitle="Specification" headingClass="fs-text-400" />
                <Col md={12}>
                  <SalaryArticleItem />
                </Col>
              </Row>
            )}
          </>
        )}
      </CardComponent>
    </>
  );
}
