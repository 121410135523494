import { ResellerAgreementMode } from "./types";

export const INITIAL_SIGNATORY_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
  incharge: false,
};

export const INITIAL_CONSULTANTS_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
  firstName: '',
  lastName: '',
};

export const INITIAL_SUPERVISORS_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
  firstName: '',
  lastName: '',
};

export const INITIAL_SENIOR_CONSULANTS_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
  firstName: '',
  lastName: '',
};

export const INITIAL_ADMIN_DETAILS = {
  personalNumber: '',
  fullName: '',
  email: '',
};

export const INITIAL_ADDRESS_DETAILS = {
  city: '',
  country: '',
  postcode: '',
  street: '',
};

export const INITIAL_COMPANY_DETAILS = {
  organizationNumber: '',
  name: '',
  status: 'Active',
};

export const INITIAL_FIRM_DETAILS = {
  organizationNumber: '',
  name: '',
  status: 'Active',
  contractNumber: '',
  resellerAgreement: ResellerAgreementMode.CERTIFIED_PROVIDER,
};
export const INITIAL_INIVITATION_EMAIL_SETTING = '3'

export const INITIAL_PROVISION_AGREEMENT = {
  uptoRange1: '',
  uptoRange2: '',
  uptoRange3: '',
  uptoRange4: '',
}