import { Form } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { FakeInputBox } from '../../../../components/base/FakeInputBox';
import { PercentageNumber } from '../../../../components/form/inputs/PercentangeNumber';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  selectedPolicy: any;
};

export const SalaryTypeDetailViewer = ({ selectedPolicy }: Props) => {
  const salaryType = selectedPolicy.policySettings.salaryType;
  const isAllowedCalculation = salaryType?.isTemplateCalculation;
  const salaryTypeList = salaryType?.salaryTypes || [];
  const salaryTypeHolidays = salaryType?.salaryTypesWithHolidays || [];
  const { t } = useTranslation();
  return (
    <>
      <PagesHeader headingClass="pp-sec-heading" pageTitle="Pensionable salary" biggerHeading />

      {!isAllowedCalculation && (
        <div className="table-responsive react-bootstrap-table chart-table">
          <table className="table table-hover table-condensed">
            <thead className="header-wrapper">
              <tr className="correcto-table-header">
                <th style={{ width: 84, minWidth: 84, maxWidth: 84 }}>{t('salary_code')}</th>
                <th style={{ width: 220, minWidth: 220, maxWidth: 220 }}>{t('name')}</th>
                <th>{t('percentage')} (%)</th>
              </tr>
            </thead>
            <tbody>
              {salaryTypeList.map((salary, index) => (
                <tr key={index} className="highlight-inactive highlight-inactive--hover">
                  <td>
                    <Form.Group className="pe-0">
                      <FakeInputBox width={60} align="end" value={salary.code} />
                    </Form.Group>
                  </td>
                  <td>
                    <FakeInputBox width={200} align="start" value={salary.name} />
                  </td>
                  <td>
                    <FakeInputBox width={60} align="end" value={salary.percentage} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {isAllowedCalculation && (
        <>
          {salaryTypeHolidays.map((salary, salaryIndex) => {
            return (
              <React.Fragment key={salaryIndex}>
                {(salaryIndex === 0 || salary.isEnable) && (
                  <>
                    <p className="text-black">
                      {' '}
                      {t('PENSION_POLICY.vacation_days_setup', { number: salaryIndex + 1 })}
                    </p>
                    <Form.Group className="mb-2">
                      <Form.Label className="fs-name">{t('holiday_days_per_year')}</Form.Label>
                      <Form.Control disabled={true} className="holiday-days" value={salary.holidayDaysPerYear} />
                    </Form.Group>

                    <div className="table-responsive react-bootstrap-table chart-table">
                      <table className="table table-hover table-condensed w-auto">
                        <thead className="header-wrapper">
                          <tr className="correcto-table-header">
                            <th style={{ width: 250 }}>{t('salary_type')}</th>
                            <th style={{ width: 160 }}>{t('holiday_pay_multiple')} (%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {salary.data.map((setup, index) => (
                            <tr key={index}>
                              <td>
                                <Form.Control disabled={true} value={setup.salaryType?.name} />
                              </td>
                              <td>
                                <PercentageNumber
                                  value={setup.holidayPayMultiple}
                                  className="text-right"
                                  decimalPlace={2}
                                  disabled={true}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};
