import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { sortCaret } from '../../../components/table/formatters';
import { UserX } from 'react-feather';
import { tableHeaderText } from '../../../components/table/TableHeaderFormatter';
import classNames from 'classnames';
import { getInitialName } from '../../../util/util';
import moment from 'moment';
import useAuth from '../../../hooks/useAuth';
import TooltipBox from '../../../components/base/Overlay/TooltipBox';

interface InputProps {
  onDelete: (id) => void;
}

export const useColumn = ({ onDelete }: InputProps) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const companyConsultantColumn: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Name'),
      dataField: 'fullName',
      sort: true,
      sortCaret,
      headerStyle: () => {
        return { width: '223px', textAlign: 'left' };
      },
      formatter: (cell, row) => (
        <div className="d-flex row-navigator" style={{ alignItems: 'center', width: 204 }}>
          <div className={classNames('avatar-group__item', 'avatar-group__item--first')}>
            <span className="avatar-name">{row.fullName ? getInitialName(row.fullName) : 'RS'}</span>
          </div>
          <div className="mx-3">{cell}</div>
        </div>
      ),
    },

    {
      text: tableHeaderText('Personal number'),
      dataField: 'personalNumber',
      headerStyle: () => {
        return { width: '138px', textAlign: 'left' };
      },
      sort: true,
      sortCaret,
    },
    {
      text: tableHeaderText('Role'),
      dataField: 'role',
      headerStyle: () => {
        return { width: '144px', textAlign: 'left' };
      },
      // classes: 'text-right',
      sort: true,
      sortCaret,
      formatter: (cell, row) => {
        return row?.role || '';
      },
    },
    // {
    //   /* TODO translation */
    //   text: 'Can add clients',
    //   dataField: 'canAddClients',
    //   headerStyle: () => {
    //     return { width: '113px', textAlign: 'right' };
    //   },
    //   classes: 'text-right',
    //   sort: true,
    //   sortCaret,
    //   formatter: (_, row) => {
    //     return (
    //       <div className="row-action d-flex justify-content-end align-items-center">
    //         <span className=" me-4 mb-0">{<Check className="check-icon active-green" />}</span>
    //       </div>
    //     );
    //   },

    //   // formatter: () => 10,
    //   headerFormatter: tableHeaderFormatter,
    // },
    {
      text: tableHeaderText('Clients'),
      dataField: 'clients',
      headerStyle: () => {
        return { width: '80px' };
      },
      sort: true,
      sortCaret,
      formatter: (cell, row) => {
        return row.clients || 0;
      },
    },

    {
      text: tableHeaderText('Status'),
      dataField: 'status',
      headerStyle: () => {
        return { width: '100px' };
      },
      sort: true,
      sortCaret,
      formatter: (cell) => <span className={classNames('fs-text-400', 'active-green')}>{t(cell || '')}</span>,
    },
    {
      text: tableHeaderText('Last interaction'),
      dataField: 'lastInteraction',
      headerStyle: () => {
        return { width: '240px' };
      },
      sort: true,
      sortCaret,
      formatter: (cell) => {
        return cell ? moment(cell).format('YYYY-MM-DD') : '-';
      },
    },

    {
      text: '',
      dataField: 'id',
      align: 'left',
      formatter: (_, row) => {
        return (
          <div className="row-action d-flex justify-content-end align-items-center">
            <TooltipBox msg={`${t('delete_consultant')}`} placement="bottom">
              <span className=" me-4 mb-0">
                {row.id !== user?.id && <UserX className="delete-icon" onClick={() => onDelete(row)} />}
              </span>
            </TooltipBox>
          </div>
        );
      },
      headerStyle: () => {
        return { width: '128px' };
      },
    },
  ];

  return { companyConsultantColumn };
};
