import { Dispatch, SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import BSModal from '../../../../components/Modal';
import { useCreatePersonalClientContext } from '../context/useContext';
import CreatePersonalClientFooter from './CreatePersonalClientFooter';
import { PersonalDetailsForm } from '../PersonalClientForm';
import ValidationAlert from '../../../../components/ValidationAlert';
import { useValidationError } from '../../../../hooks/useValidationError';
import useLocale from '../../../../hooks/useLocale';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsClientCreated: Dispatch<SetStateAction<boolean>>;
};

export default function CreatePersonalClientModal({ setIsClientCreated, showCreateModal, toggleModal }: Props) {
  const context = useCreatePersonalClientContext();
  const { formGroup } = context;
  const { errors, isInvalid, setErrors } = useValidationError();
  const { localize } = useLocale();

  const onClientCreated = () => {
    return true;
  };

  return (
    <>
      <BSModal
        title={`${localize('new_personal_client')}`}
        show={showCreateModal}
        onHide={toggleModal}
        centered
        ModalFooter={
          <CreatePersonalClientFooter
            toggleModal={toggleModal}
            setErrors={setErrors}
            onClientCreated={onClientCreated}
            setIsClientCreated={setIsClientCreated}
            formGroup={formGroup!}
          />
        }
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />
          <PersonalDetailsForm context={context} formGroup={formGroup!} />
        </Form>
      </BSModal>
    </>
  );
}
