import React, { useState } from 'react';
import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import FilesGrid from '../Settings/FilesGrid/FilesGrid';
import ErrorAlert from '../../../components/ErrorAlert';
import { useTranslation } from 'react-i18next';

type Props = {
  isUploaded: boolean;
};

export default function DataManagementGrid({ isUploaded }: Props) {
  const [showError, setShowError] = useState(false);
  const { t } = useTranslation();

  const [salaryErrorDocLength, setSalaryErrorDocLength] = useState(0);
  const errorMsg = `${t('salary_documents_with_errors', { count: salaryErrorDocLength })}`;

  const toggleShownError = (val: boolean) => {
    setShowError(val);
  };

  const updateSalaryDocList = (docs) => {
    setSalaryErrorDocLength(docs);
  };

  return (
    <>
      <ErrorAlert show={showError} msg={errorMsg} />
      <CardComponent>
        <div className="d-flex justify-content-between">
          <PagesHeader pageTitle="Salary documents" headingClass="fs-page-title" />
        </div>
        <FilesGrid
          isUploaded={isUploaded}
          toggleShownError={toggleShownError}
          updateSalaryDocList={updateSalaryDocList}
        />
      </CardComponent>
    </>
  );
}
