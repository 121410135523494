import { Container, Button } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import PagesHeader from '../../components/base/PagesHeader';
import { ChartsSection } from '../company/Overview/ChartsSection';
import { NumberCards } from '../company/Overview/NumberCards';
import { getOverviewChartData } from '../../api/service/company/overview/service';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function OverviewPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [overviewCardsData, setOverviewCardsData] = useState<any>();
  const [overviewChartData, setOverviewChartData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const currentDate = `${moment().format('MMMM')} ${moment().date()}`;

  const fetchOverviewData = async () => {
    const overviewData = await getOverviewChartData();
    setOverviewChartData({ pieChart: overviewData?.pie, lineChart: overviewData?.lineChart });

    setOverviewCardsData({
      totalCompanyClients: overviewData.totalCompanyClients,
      totalEmployees: overviewData.totalEmployees,
      pensionOffset: overviewData.pensionOffset,
      companyBilled: overviewData.companyBilled,
      activeEmployees: overviewData.activeEmployees,
    });
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchOverviewData();
  }, []);
  return (
    <Container fluid className="p-0">
      <PagesHeader headingClass="fs-h1" pageTitle={currentDate} translate={false} />

      <div className="d-flex gap-2">
        <div className="p-0 chartSection_boxOne">
          <NumberCards cardsData={overviewCardsData ?? {}} isLoading={isLoading} />
          <ChartsSection
            monochromePie
            lineChartTitle={`${t('total_pension_company_billing')}`}
            pieChartTitle={`${t('company_client_sizes')}`}
            chartData={overviewChartData}
            isLoading={isLoading}
          />
        </div>
        <div style={{ width: 226, minWidth: 226, maxWidth: 226 }} className="mt-3 ps-0">
          <Button onClick={() => navigate('/company-clients')} className="w-100 d-flex text-left mb-1 app-btn-primary">
            <ChevronRight className="icon-gap-right" />

            <span>{t('new_company_client')}</span>
          </Button>
          <Button onClick={() => navigate('/billing')} className="w-100 text-left d-flex my-1 app-btn-primary">
            <ChevronRight className="icon-gap-right" />

            <span>{t('billing')}</span>
          </Button>
        </div>
      </div>
    </Container>
  );
}

export default OverviewPage;
