import { Dispatch, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Save, Send } from 'react-feather';
import omit from 'lodash/omit';
import { useCreateAccountingFirmContext } from '../context/useContext';
import { SetStateAction } from 'react';
import { AccountingFirmSaveMode, LooseObject } from '../types';
import { AccountingFirm } from '../../../../api/service/superAdmin/accounting-firm/types';
import { postNewAccountingFirm } from '../../../../api/service/superAdmin/accounting-firm/service';
import { isObjectEmpty } from '../../../../util/util';
import useLocale from '../../../../hooks/useLocale';

type Props = {
  toggleModal: () => void;
  setIsAccountingFirmCreated: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
};

const STRING_TYPE = {
  DRAFT: 'DRAFT',
  SUBMIT: 'SUBMIT',
};

export default function CreateAccountingFirmtModalFooter({
  toggleModal,
  setIsAccountingFirmCreated,
  setErrors,
}: Props) {
  const { localize } = useLocale();
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const {
    firmDetails,
    addressDetails,
    contractEffectiveFrom,
    invoiceEmailAddress,
    invoicingCostCenter,
    seniorConsultants,
    supervisors,
    invitationEmail,
    formGroup,
    combinations,
  } = useCreateAccountingFirmContext();

  const modifiedCombinations = combinations.map((i) => {
    return {
      signee: i.signee,
      authorizedSignatory: i.authorizedSignatory.map((signatory) => {
        const { email, ...details } = signatory;

        return email ? signatory : details;
      }),
    };
  });

  const modifiedSeniorConsultants = seniorConsultants
    .filter((item) => {
      return isObjectEmpty(item);
    })
    .map((i: any) => {
      return omit(i, ['_id', 'valid']);
    });

  const modifiedSupervisors = supervisors
    .filter((item) => {
      return isObjectEmpty(item);
    })
    .map((i: any) => {
      return omit(i, ['_id', 'valid']);
    });

  const handleSaveCompany = async (type: string, saveMode: AccountingFirmSaveMode) => {
    const data = {
      ...firmDetails,
      address: addressDetails,
      contractEffectiveFrom,
      invoiceEmailAddress: invoiceEmailAddress,
      invoicingCostCenter: invoicingCostCenter,
      invitationEmail: invitationEmail,
      seniorConsultants: modifiedSeniorConsultants,
      supervisors: modifiedSupervisors,
      contactPeopleForSalary: {
        system: 'Fortnox',
        personalNumber: '19931013-1234',
        name: 'Gregory Marshall',
        email: 'gregory.marshall@sizzle.com',
      },
      contactPeopleForAccounting: {
        system: 'Fortnox',
        personalNumber: '19931013-1234',
        name: 'Gregory Marshall',
        email: 'gregory.marshall@sizzle.com',
      },
      combinations: modifiedCombinations,
      status: saveMode,
    } as AccountingFirm | any;

    let params = {};

    if (type === STRING_TYPE.DRAFT) {
      setIsBusyDrafting(true);
      params = {
        draft: true,
        sendEmails: false,
      };
    } else {
      setIsBusy(true);
      params = {
        draft: false,
        sendEmails: true,
      };
    }
    formGroup.handleSubmit();
    formGroup.validateForm().then((errors) => {
      setErrors(errors);
    });
    if (!formGroup.isValid) {
      setIsBusy(false);
      setIsBusyDrafting(false);
      return false;
    }

    try {
      await postNewAccountingFirm(data, params);

      toggleModal();
      setIsAccountingFirmCreated(true);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
      setIsAccountingFirmCreated(false);
    } finally {
      setIsBusy(false);
      setIsBusyDrafting(false);
    }
  };

  return (
    <div className="d-flex justify-content-between w-100  flex-wrap-row gap-3 ">
      <Button className="app-btn-secondary cancel-btn  cr-modal-draft-btn" onClick={toggleModal}>
        {localize('cancel')}
      </Button>

      <div className="d-flex gap-3 flex-wrap-row">
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary  cr-modal-draft-btn"
          onClick={() => {
            handleSaveCompany(STRING_TYPE.DRAFT, AccountingFirmSaveMode.DRAFT);
          }}
        >
          {isBusyDrafting ? <Spinner className="icon-gap-right" size="sm" /> : <Save className="icon-gap-right" />}
          {`${localize('save')} ${localize('as')} ${localize('draft')}`}
        </Button>
        <Button
          disabled={isBusy || isBusyDrafting}
          type="button"
          className="app-btn-primary  cr-modal-company-client-btn"
          onClick={() => {
            handleSaveCompany(STRING_TYPE.SUBMIT, AccountingFirmSaveMode.SETUP);
          }}
        >
          {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <Send className="icon-gap-right" />}
          {`${localize('save')} ${localize('accounting_firm')} ${localize('and_send_all_invites')}`}
        </Button>
      </div>
    </div>
  );
}
