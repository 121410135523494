export const PERSONAL_NUMBER_DIGITS = 12;
export const ORGANIZATION_NUMBER_DIGITS = 10;
export const SESS_LANG_PREFIX = 'crt_lang';

export const APP_CONFIG = {
  webURL: 'https://www.correcto.io',
  aboutWebRoute: 'omcorrecto',
  apiBaseURL: `${process.env['REACT_APP_BASE_URL']}`,
  mailDomain: `inboxcorrecto.info`,
} as const;
