import { useCallback } from 'react';
import { Menu } from 'react-feather';
import { Button, Dropdown, Row, Spinner, Form } from 'react-bootstrap';
import BSTable from '../../../../components/table/BSTable';
import PagesHeader from '../../../../components/base/PagesHeader';
import { numberFormat } from '../../../../util/util';
import { getCompanyCalculatedAndSalaryChangeReports } from '../../../../api/service/company/reports/service';
import useCalculatedSalaryReport from './hooks/useCalculatedSalaryReport';
import { Calendar } from '../../../../util/calendar';
import { REPORT_EXPORT_OPTIONS } from '../constants';
import { useTranslation } from 'react-i18next';

export const CalculatedSalaryChangeReport = () => {
  const { reportHeaders, reportRecords, isLoading, years, setYearFilter, exportCSV, isExporting } =
    useCalculatedSalaryReport(getCompanyCalculatedAndSalaryChangeReports);
  const { t } = useTranslation();

  const expandRow = {
    className: 'report-row--expanded',
    parentClassName: 'toggleable-group',
    renderer: (row) => {
      return (
        <table className="table-condensed w-100">
          <tbody>
            <tr>
              {reportHeaders.map((item: any, idx) => {
                if (item.dataField === 'date') {
                  return (
                    <td key={idx} className="title-cell">
                      <span className="text-bold">{t('REPORTS.calculated')}</span>
                    </td>
                  );
                }

                return (
                  <td key={idx} className="fluid-cell number-col">
                    <span className="cell-content">{numberFormat(row[item.dataField]?.['calculated'] || 0)}</span>
                  </td>
                );
              })}
            </tr>
            <tr>
              {reportHeaders.map((item: any, idx) => {
                if (item.dataField === 'date') {
                  return (
                    <td key={idx} className="title-cell">
                      <span className="text-bold">{t('salary_change')}</span>
                    </td>
                  );
                }
                return (
                  <td key={idx} className="fluid-cell number-col">
                    <span className="cell-content">{numberFormat(row[item.dataField]?.['salaryChange'] || 0)}</span>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      );
    },
    expanded: reportRecords.map((item: any) => item.date),
    // nonExpandable: reportRecords.map((item: any) => item.date),
  };

  const rowClasses = useCallback((row, rowIndex) => {
    if ((rowIndex + 1) % 2 === 0) {
      return 'grouped-expandable-row grouped-striped-row';
    }

    return 'grouped-expandable-row grouped-row';
  }, []);

  return (
    <>
      <Row className="justify-content-between align-items-end">
        <div className="mt-5">
          <PagesHeader pageTitle="All Employees" headingClass="fs-page-title" />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Form.Group className="me-3">
            <Form.Label className="fs-name">{t('year_period')}</Form.Label>
            <Form.Select
              name="year"
              className="text-left"
              onChange={(e) => {
                setYearFilter(e.target.value);
              }}
            >
              {years.map((year) => (
                <option value={year} key={year}>
                  {year} {Calendar.isCurrentYear(year) ? 'YTD' : ''}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {isExporting ? (
            <Spinner />
          ) : (
            <Dropdown className="nav-item" align="end">
              <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
                <Button className="app-btn-secondary">
                  <Menu className="theme-text" />
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {REPORT_EXPORT_OPTIONS.map((report) => (
                  <Dropdown.Item onClick={() => exportCSV(report.value)} key={report.value}>
                    {report.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="crt-table-report crt-table-report--expandable crt-table-report--expandable-off">
            {reportRecords && (
              <BSTable
                expandRow={expandRow}
                data={reportRecords}
                columns={reportHeaders}
                noDataIndication={`${t('no_reports_found')}`}
                keyField={'date'}
                rowClass={rowClasses}
              />
            )}
          </div>
        )}
      </Row>
    </>
  );
};
