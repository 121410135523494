import { PropsWithChildren, createContext, useState } from 'react';
import { useFormik } from 'formik';

import { IProfileContext, AccountProfile, ProfileFormGroup, ActionLoading } from '../types';
import { INITIAL_PROFILE } from '../constants';
import { ProfileFormSchema } from './schema';

export const ProfileContext = createContext<IProfileContext>({
  profileDetails: INITIAL_PROFILE,
  setProfileDetails: () => {},
  profileFormGroup: {} as ProfileFormGroup,
  setLoading: () => {},
  loading: {
    save: false,
    fetch: false,
  },
});

export const ProfileProvider = ({ children }: PropsWithChildren) => {
  const [profileDetails, setProfileDetails] = useState<AccountProfile>(INITIAL_PROFILE);
  const [loading, setLoading] = useState<ActionLoading>({
    save: false,
    fetch: false,
  });

  const profileFormGroup = useFormik({
    initialValues: INITIAL_PROFILE,
    validateOnMount: true,
    validationSchema: ProfileFormSchema,
    onSubmit: () => {},
  });

  const context: IProfileContext = {
    profileDetails,
    setProfileDetails,
    profileFormGroup,
    loading,
    setLoading,
  };

  return <ProfileContext.Provider value={context}>{children}</ProfileContext.Provider>;
};
