import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Slash } from 'react-feather';
import { Spinner } from 'react-bootstrap';

import { FormProps } from '../context/EmployeePensionContext';
import { Calendar } from '../../../../util/calendar';
import useLocale from '../../../../hooks/useLocale';
import { isEmptyVal, isNumeric } from '../../../../util/util';
import { CalendarEffectiveDate } from '../entity/CalendarEffectiveDate';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';

interface Props extends FormProps {
  onCancelSchdedule: (onSuccessCallback: Function) => void;
  cancellingSchedule: boolean;
}

function Vacation({ context, onCancelSchdedule, cancellingSchedule }: Props) {
  const { employee, updateVacationDetails, vacation, employeeRecord } = context;
  const [isScheduleable, setIsScheduleable] = useState(true);
  const [isEditable, setIsEditable] = useState(true);
  const { t } = useTranslation();
  const { localize } = useLocale();
  const vacationEffectiveStartDate =
    employee?.vacationAllowance?.next?.effectiveFrom || employee?.vacationAllowance?.current?.effectiveFrom;
  const handleChange = (name, value) => {
    updateVacationDetails(name, value);
  };

  const onCancelConfirmation = async () => {
    //setIsScheduleable(true);
    onCancelSchdedule(() => {
      setIsScheduleable(true);
    });
  };

  useEffect(() => {
    if (
      employee.vacationAllowance &&
      employee.vacationAllowance.current?.effectiveFrom &&
      employee.vacationAllowance.next?.effectiveFrom
    ) {
      setIsScheduleable(false);
    } else {
      setIsScheduleable(true);
    }
  }, [employee.vacationAllowance]);

  useEffect(() => {
    if (employeeRecord && employeeRecord?.vacation) {
      const initialPaidHolidays = employeeRecord?.vacation?.paidHolidays || 0;
      const currentPaidHolidays = vacation?.paidHolidays || 0;

      if (!isEmptyVal(initialPaidHolidays, { allowNum: true }) && +currentPaidHolidays === +initialPaidHolidays) {
        updateVacationDetails('effectiveFrom', employeeRecord.vacation?.effectiveFrom || '');
        setIsEditable(false);
      } else {
        const vacationEffectiveStartDate =
          employeeRecord?.vacationAllowance?.next?.effectiveFrom ||
          employeeRecord?.vacationAllowance?.current?.effectiveFrom;

        if (!CalendarEffectiveDate.validateVacationEffectiveDate(vacationEffectiveStartDate, vacation.effectiveFrom)) {
          updateVacationDetails('effectiveFrom', '');
          setIsEditable(true);
        }
      }
    }
  }, [vacation?.paidHolidays, employeeRecord, vacation?.effectiveFrom, employee.vacationAllowance]);

  return (
    <div>
      <h3 className="fs-22px theme-text mt-3 mb-2 font-weight-600"> {localize('vacation')}</h3>
      <div className="dark-content-section">
        <Form>
          {!isScheduleable &&
            employee?.vacationAllowance?.next?.effectiveFrom &&
            employee?.vacationAllowance?.current?.effectiveFrom && (
              <div className="p-2 mb-3 d-inline-flex align-items-center alert alert-info theme-text">
                <span className="fs-name">
                  {' '}
                  {t('current_vacation_days_are')} {employee?.vacationAllowance!.current!.paidHolidays},
                </span>
                <span>
                  &nbsp;{t('effective_since')}{' '}
                  {Calendar.parseDate(employee?.vacationAllowance!.current!.effectiveFrom, 'MMM YYYY')}
                </span>
              </div>
            )}
          <div className="cr-form-row">
            <div className="paid-vacation-field">
              <Form.Label className="fs-name required required--sub">{t('paid_vacation_days')}</Form.Label>
              <Form.Control
                type="number"
                name="paidHolidays"
                value={isNumeric(vacation?.paidHolidays) ? vacation?.paidHolidays : ''}
                onChange={(e) => handleChange('paidHolidays', e.target.value)}
                disabled={!isScheduleable}
                // className={classNames(errorHighlighter(formGroup.getFieldMeta('vacation.paidHolidays')))}
              />
              {/* <ErrorFeedback field={formGroup.getFieldMeta('vacation.paidHolidays')} /> */}
            </div>
            <div>
              <Form.Label className="fs-name">{`${t('effective_from')}`}</Form.Label>
              <MonthAndYearDropdown
                value={vacation?.effectiveFrom || ''}
                onOptionSelect={(val) => handleChange('effectiveFrom', val)}
                disabled={!isScheduleable || !isEditable}
                startFrom={vacation?.effectiveFrom}
                disabledPreviousMonth
                customOptions={CalendarEffectiveDate.selectVacationDateOptions(vacationEffectiveStartDate)}
              />
            </div>
            {!isScheduleable &&
              employee?.vacationAllowance?.next?.effectiveFrom &&
              employee?.vacationAllowance?.current?.effectiveFrom && (
                <div className="cr-form-row mb-0">
                  <Button className="app-btn-primary" onClick={() => onCancelConfirmation()}>
                    {cancellingSchedule ? (
                      <Spinner className="icon-gap-right" size="sm" />
                    ) : (
                      <Slash className="icon-gap-right" />
                    )}{' '}
                    {t('cancel_scheduled_update')}
                  </Button>
                  <div>
                    <div className="fs-name theme-text">{t('scheduled_update')}</div>
                    <p className="mb-0 theme-text">{t('cancel_planned_update')}</p>
                  </div>
                </div>
              )}
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Vacation;
