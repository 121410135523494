export interface WebflowCollection {
  _id: string;
  lastUpdated: string;
  createdOn: string;
  name: string;
  slug: string;
  singularName: string;
}

export interface CollectionItem {
  items: Item[];
  count?: number;
  limit?: number;
  offset?: number;
  total?: number;
}

export interface Item {
  _archived: boolean;
  _draft: boolean;
  'read-more-url'?: string;
  order: number;
  name: string;
  'read-more-text'?: string;
  'body-text': string;
  slug: string;
  'post-illustration': BackgroundImage;
  language: string;
  'post-type': string;
  'updated-on': string;
  'updated-by': string;
  'created-on': string;
  'created-by': string;
  'published-on': string;
  'published-by': string;
  _cid: string;
  _id: string;
  'background-image'?: BackgroundImage;
}

export interface BackgroundImage {
  fileId: string;
  url: string;
  alt: null;
}

export interface ChangelogPost {
  items: ChangelogPostItem[];
  count: number;
  limit: number;
  offset: number;
  total: number;
}

export interface ChangelogPostItem {
  _archived: boolean;
  _draft: boolean;
  heading: string;
  'body-content': string;
  name: string;
  'top-image': TopImage;
  slug: string;
  'updated-on': string;
  'updated-by': string;
  'created-on': string;
  'created-by': string;
  'published-on': string;
  'published-by': string;
  _cid: string;
  _id: string;
}

export interface TopImage {
  fileId: string;
  url: string;
  alt: null;
}

export enum PostType {
  OverviewPosts = 'overview-post',
  ChangelogPosts = 'changelog-posts',
}
