import { PensionTabKey } from '../types';
import useEmployeePensionContext from '../useContext';

const usePensionTabMode = () => {
  const { employeePensionSummary, setPensionTabKey } = useEmployeePensionContext();

  const canEditCurrentPolicy = () => {
    if (employeePensionSummary) {
      if (employeePensionSummary.current) {
        return true;
      }

      return false;
    }

    return true;
  };

  const canEditSchedulePolicy = () => {
    if (employeePensionSummary) {
      if (employeePensionSummary.schedule) {
        return true;
      }

      return false;
    }

    return false;
  };

  const activateTab = (_employeePensionSummary) => {
    if (!_employeePensionSummary) {
      setPensionTabKey(PensionTabKey.CURRENT);

      return;
    }

    if (!_employeePensionSummary.current && _employeePensionSummary.schedule) {
      setPensionTabKey(PensionTabKey.SCHEDULE);
    } else if (_employeePensionSummary.current && !_employeePensionSummary.schedule) {
      setPensionTabKey(PensionTabKey.CURRENT);
    } else if (_employeePensionSummary.current && _employeePensionSummary.schedule) {
      setPensionTabKey(PensionTabKey.SCHEDULE);
    } else if (!_employeePensionSummary.current && !_employeePensionSummary.schedule) {
      setPensionTabKey(PensionTabKey.CURRENT);
    }
  };

  const toggleTab = (key: PensionTabKey) => {
    setPensionTabKey(key);
  };

  return {
    canEditSchedulePolicy,
    canEditCurrentPolicy,
    activateTab,
    toggleTab,
  };
};

export default usePensionTabMode;
