import { useEffect, useState } from 'react';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import { Info, Lock } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { getPolicyDetailsByName } from '../../../../api/service/superAdmin/pension-policies/service';
import { TabDataType } from '../EmployeeDetails/EmployeeDetails';

import useContext from '../useContext';
import PensionPoliciesDetailsNonEditable from '../../../superAdmin/PensionPolicy/PensionPoliciesDetailsNonEditable';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';
import PagesHeader from '../../../../components/base/PagesHeader';
import classNames from 'classnames';
import { PensionPolicyProvider } from '../../../superAdmin/PensionPolicy/PensionPolicyContext/PensionPolicyContext';
import { ActionMode, PensionPolicyCategory, PensionPolicyMode } from '../../../superAdmin/PensionPolicy/types';
import usePensionSchedule from '../hooks/usePensionSchedule';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

function PensionSchedule({ employee, reset, setReset, pensionDataset, insuranceDataset }: TabDataType) {
  const { t } = useTranslation();
  const { findPolicyType, selectedScheduledEmployeePension, selectedScheduledPensionPolicy } = useContext();
  const pensionSchedule = usePensionSchedule();
  const [policyType, setPolicyType] = useState<PensionPolicyCategory | null>(null);
  const [shouldResetPolicy, setShouldResetPolicy] = useState(false);
  const [empolyeeAttachedPersonalPolicy, setEmpolyeeAttachedPersonalPolicy] = useState<any>();

  const getEmployeePersonalPolicy = async () => {
    try {
      const _policy = await getPolicyDetailsByName(`${employee?.fullName} ${employee?.personalNumber}`);

      if (_policy) {
        setEmpolyeeAttachedPersonalPolicy(_policy);
      }

      return _policy;
    } catch (e) {
      console.error(e);
    }
  };

  const selectPolicyType = (_selectedScheduledEmployeePension) => {
    if (_selectedScheduledEmployeePension.type) {
      setPolicyType(findPolicyType(_selectedScheduledEmployeePension.type));
    } else {
      setPolicyType(PensionPolicyCategory.NO_POLICY);
    }
  };

  const clearDetails = () => {
    setShouldResetPolicy(true);
    setPolicyType(null);
  };

  useEffect(() => {
    getEmployeePersonalPolicy();
  }, []);

  useEffect(() => {
    if (shouldResetPolicy) {
      setShouldResetPolicy(false);

      return;
    }
  }, [shouldResetPolicy]);

  useEffect(() => {
    if (selectedScheduledEmployeePension) {
      //const policyResponse = getPolicyDetails(selectedScheduledEmployeePension.id);
      //setSelectedScheduledPensionPolicy(policyResponse);

      selectPolicyType(selectedScheduledEmployeePension);
    } else {
      clearDetails();
    }
  }, [selectedScheduledEmployeePension]);

  return (
    <div className="employee-policy">
      {pensionSchedule.hasShownEffectiveDateNotification() && (
        <div className="p-2 mb-3 d-flex align-items-center alert alert-info theme-text">
          <Info className="icon-gap-right" />
          <span>{t('multiple_settings_have_separate_effective_dates')}</span>
        </div>
      )}
      <PagesHeader pageTitle="Pension policy" />
      <Form>
        <div className="d-flex mb-5 gap-2 pension-policy-selector flex-wrap-row">
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'companyPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'companyPolicy'}
              disabled
              inline
              value="companyPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.company_policy')}`}
              className="fs-name mb-1"
              id="companyPolicy"
            />
            <Form.Control
              disabled
              value={(policyType === 'companyPolicy' && selectedScheduledPensionPolicy?.policyName) || ''}
            />
          </div>
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'correctoProvidedPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'correctoProvidedPolicy'}
              disabled
              inline
              value="correctoProvidedPolicy"
              name="policyType"
              type="radio"
              label={t('correcto_provided')}
              className="fs-name mb-1"
              id="correctoProvidedPolicy"
            />
            <Form.Control
              disabled
              value={(policyType === 'correctoProvidedPolicy' && selectedScheduledPensionPolicy?.policyName) || ''}
            />
          </div>
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'personalPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'personalPolicy'}
              disabled
              inline
              value="personalPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.personal_policy')}`}
              className="fs-name radio-button-label"
              id="personalPolicy"
            />

            <div>{empolyeeAttachedPersonalPolicy && <p>{empolyeeAttachedPersonalPolicy?.policyName}</p>}</div>
          </div>
          <Col
            style={{ height: 86, width: 240, maxWidth: 240, minWidth: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'noPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'noPolicy'}
              disabled
              inline
              value="noPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.no_policy')}`}
              className="fs-name"
              id="noPolicy"
            />

            <Form.Check
              checked={selectedScheduledEmployeePension?.advisoryInvite ? true : false}
              disabled
              inline
              name="advisoryInvite"
              type="checkbox"
              label={t('invite_employee_to_correcto_advisory')}
              className="fs-name d-flex align-items-start mt-2"
              id="inviteEmployeeDisabled"
            />
          </Col>
        </div>
        <Row className="mb-4">
          <Form.Label className="mb-0 fs-name">{`${t('PENSION_POLICY.effective_from')}`}</Form.Label>
          <Col md={2} style={{ width: 119 }}>
            <MonthAndYearDropdown
              disabled
              startFrom={selectedScheduledEmployeePension?.effectiveFrom}
              value={selectedScheduledEmployeePension?.effectiveFrom}
            />
          </Col>
        </Row>
      </Form>
      <hr />

      {selectedScheduledEmployeePension && selectedScheduledPensionPolicy && (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <PagesHeader
              biggerHeading={true}
              pageTitle={`${selectedScheduledPensionPolicy['policyName']}`}
              translate={false}
            />
            {selectedScheduledPensionPolicy['isCollectiveAgreementLock'] && (
              <h6>
                <Badge bg="secondary" className="collective-policy-badge">
                  <TooltipBox msg={`${t('locked')}`}>
                    <Lock width={15} className="me-2" />
                  </TooltipBox>
                  {t('correcto_agreement')}
                </Badge>
              </h6>
            )}
          </div>
        </div>
      )}
      {selectedScheduledEmployeePension && selectedScheduledPensionPolicy && (
        <PensionPolicyProvider
          defaultSelectedPolicy={selectedScheduledPensionPolicy}
          shouldReset={shouldResetPolicy}
          defaultSelectedPolicyMode={PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY}
          defaultPensionFormPopulated={selectedScheduledEmployeePension}
          defaultActionMode={ActionMode.SCHEDULED}
          defaultExternalPolicyRevision={{ effectiveFrom: selectedScheduledEmployeePension?.effectiveFrom }}
        >
          <PensionPoliciesDetailsNonEditable
            policy={selectedScheduledPensionPolicy}
            containerBodyClassName="p-0"
            containerClassName="shadow-none"
            isSelectable
            effectiveFrom={selectedScheduledPensionPolicy?.effectiveFrom || ''}
            fetchedFromExternalSource={true}
            pensionDataset={pensionDataset}
            insuranceDataset={insuranceDataset}
          />
        </PensionPolicyProvider>
      )}

      {/* {
        <CreatePersonalPolicyModal
          showModal={showModal}
          toggleModal={toggleModal}
          employee={employee}
          onSavePolicy={onSavePolicy}
          onUpdatePersonalPolicy={onUpdatePersonalPolicy}
          policy={selectedScheduledPensionPolicy}
          isEditAction={editPersonalPolicyOption ? true : false}
          defaultSelectedPolicyOrigin={editPersonalPolicyOption?.chosenAssignedPersonalPolicy}
        />
      } */}
    </div>
  );
}

export default PensionSchedule;
