import { useEffect, useState } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import { Save } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom';
import { getPolicyDetails, updatePolicy } from '../../../api/service/superAdmin/pension-policies/service';
import Breadcrumb from '../../../components/base/Breadcrumb';
import PagesHeader from '../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import PensionPoliciesDetailsEditable from './PensionPoliciesDetailsEditable';
import useContext from './PensionPolicyContext/useContext';
import { useValidationError } from '../../../hooks/useValidationError';
import ValidationAlert from '../../../components/ValidationAlert';
import useLocale from '../../../hooks/useLocale';
import { AuthUser, UserRole } from '../../../types/auth';
import { ActionMode, PensionPolicyMode, PensionPolicyVariant } from './types';
import { PensionPolicyDTO } from './entity';
import { deepClone, isEmpty } from '../../../util/util';
import usePensionPolicyObserver from './hooks/usePensionPolicyObserver';
import CancelButton from '../../../components/base/CancelButton';

export default function EditPolicy() {
  const {
    policyForm,
    formGroup,
    setPreAssignedPolicy,
    setSelectedPolicyDetails,
    preAssignedPolicy,
    setActionMode,
    reloadToDefault,
    setIsResetPolicy,
    patchSelectedPensionPolicy,
    setPensionPolicyMode,
    triggerCancelEvent,
  } = useContext();
  const { errors, isInvalid, setErrors } = useValidationError();
  const navigate = useNavigate();
  const { localize } = useLocale();
  const { isFormModified } = usePensionPolicyObserver();

  const [isBusy, setIsBusy] = useState(false);

  const { id } = useParams();
  const [policy, setPolicy] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const isSuperAdmin = user?.role?.role === UserRole.SUPER_ADMIN;

  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Pension Policies',
      href: '/pension-policies',
    },
    {
      name: policyForm.name,
      translate: false,
    },
  ];

  const clearForm = () => {
    setPolicy({});
  };

  const navigateToPensionPolicies = (shouldBack = false) => {
    if (shouldBack) {
      navigate(-1);
    } else {
      navigate('/pension-policies');
    }
  };

  const fetchPolicyDetails = async () => {
    try {
      const _policy = await getPolicyDetails(id);
      const policyInstance = deepClone(_policy);

      patchSelectedPensionPolicy(policyInstance);
      setPolicy(policyInstance);
      setPreAssignedPolicy(policyInstance);
      setSelectedPolicyDetails(policyInstance);
      setupPolicyMode(policyInstance.type);
    } finally {
      setIsFetching(false);
    }
  };

  const setupPolicyMode = (_policyType: PensionPolicyVariant) => {
    if (_policyType === PensionPolicyVariant.PERSONAL) {
      setPensionPolicyMode(PensionPolicyMode.PERSONAL_POLICY);
    } else if (_policyType === PensionPolicyVariant.COMPANY) {
      setPensionPolicyMode(PensionPolicyMode.COMPANY_POLICY);
    } else {
      setPensionPolicyMode(PensionPolicyMode.ADMIN_POLICY);
    }
  };

  const onRevertChanges = () => {
    triggerCancelEvent();
    const policyInstance = deepClone(policy);

    patchSelectedPensionPolicy(policyInstance);
    setPolicy(policyInstance);
    setPreAssignedPolicy(deepClone(policy));
    setSelectedPolicyDetails(policyInstance);
    setupPolicyMode(policyInstance.type);
  };

  const onUpdatePolicy = async () => {
    try {
      formGroup.handleSubmit();
      const errors = await formGroup.validateForm();

      if (!isEmpty(errors)) {
        setErrors(errors);
        return false;
      }

      setIsBusy(true);
      setErrors({});

      const payload = PensionPolicyDTO.create({
        formGroup: formGroup.values,
        policyForm: policyForm,
        isSuperAdmin,
      });

      await updatePolicy(id, payload);
      navigateToPensionPolicies();
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsBusy(false);
    }
  };

  //? Mounted & UnMounted the edit policy page
  useEffect(() => {
    return () => {
      reloadToDefault({ isEditAction: true });
      clearForm();
    };
  }, []);

  useEffect(() => {
    setIsResetPolicy(true);
    setActionMode(ActionMode.EDIT);
    fetchPolicyDetails();

    return () => {
      setIsResetPolicy(false);
    };
  }, []);

  return (
    <Container fluid className="p-0">
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <Breadcrumb navItem={breadcrumb} />

          <div className="d-flex justify-content-between align-items-center">
            <PagesHeader headingClass="fs-h1" pageTitle={preAssignedPolicy?.policyName ?? ''} translate={false} />
            <div className="cr-form-row">
              {isFormModified && <CancelButton onCancel={onRevertChanges} />}
              <Button
                onClick={onUpdatePolicy}
                disabled={!formGroup.isValid || !isFormModified}
                className="app-btn-primary"
              >
                {isBusy ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}
                {localize('PENSION_POLICY.update_policy')}
              </Button>
            </div>
          </div>
          <ValidationAlert show={isInvalid} errors={errors} />
          <PensionPoliciesDetailsEditable policy={policy} />
        </>
      )}
    </Container>
  );
}
