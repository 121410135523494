import React from 'react';
import { Outlet } from 'react-router-dom';

import { ReactComponent as Logo } from '../assets/img/auth/Correcto-Logo.svg';

import Main from '../components/Main';
import { UserRole } from '../types/auth';
import CompanyBanner from '../components/auth/CompanyBanner';
import EmployeeBanner from '../components/auth/EmployeeBanner';

interface Props {
  pageRoleType?: UserRole;
}

const SignInLayout = ({ children, pageRoleType = UserRole.COMPANY_ADMIN }: React.PropsWithChildren<Props>) => (
  <React.Fragment>
    <Main>
      <div className="auth-screen">
        <div className="form-sidebar vh-100 d-flex flex-column align-content-center justify-content-between">
          <div className="ds">
            <Logo className="correcto-logo" />
            {children}
            <Outlet />
          </div>

          <footer className="auth-footer font-black theme-text fw-400">
            Never sign in at the behest of someone who contacts you. Do you have any questions? <br />
            <a href="http://correcto.io">Find our answers here</a>
          </footer>
        </div>
        {pageRoleType === UserRole.COMPANY_ADMIN && <CompanyBanner />}
        {pageRoleType === UserRole.EMPLOYEE && <EmployeeBanner />}
      </div>
    </Main>
  </React.Fragment>
);

export default SignInLayout;
