import CardComponent from '../../../../components/base/CardComponent';
import { AdminDetails } from './GeneralForms/AdminDetails';
import { AuthorizedSignatory } from './GeneralForms/AuthorizedSignatory';
import { CompanyDetails } from './GeneralForms/CompanyDetails';
import { ContractDetails } from './GeneralForms/ContractDetails';
import { Form, Spinner } from 'react-bootstrap';
import { DateType } from './GeneralForms/types';
import { useEditGeneralSettingsContext } from './context/useContext';
import { FileUpload } from './GeneralForms/FileUpload';

type Props = {
  isBusy: boolean;
  date: DateType;
};
export const General = ({ isBusy, date }: Props) => {
  const context = useEditGeneralSettingsContext();
  return (
    <>
      <CardComponent>
        {isBusy ? (
          <Spinner />
        ) : (
          <Form>
            <CompanyDetails context={context} date={date} formGroup={context.formGroup} />

            <ContractDetails context={context} formGroup={context.formGroup} />

            <AuthorizedSignatory context={context} formGroup={context.formGroup} />

            <AdminDetails context={context} formGroup={context.formGroup} />

            {/* <ExternalConsultant context={context} formGroup={context.formGroup} /> */}

            {/* <ContactPeople context={context} formGroup={context.formGroup} /> */}

            {/* <Invitation context={context} formGroup={context.formGroup} /> */}

            <FileUpload context={context} formGroup={context.formGroup} />
          </Form>
        )}
      </CardComponent>
    </>
  );
};
