import classNames from 'classnames';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { AlertTriangle } from 'react-feather';

interface LooseObject {
  [key: string]: any;
}

type Props = {
  show: boolean;
  errors?: LooseObject;
  msg?: string;
  inline?: boolean;
};

export default function ErrorAlert({ show, errors, msg, inline }: Props) {
  const defaultMsg = 'Following fields are required';

  return (
    <Alert
      className={classNames(inline ? 'd-inline-flex' : 'd-flex', 'flex-column validations')}
      show={show}
      style={{ background: 'rgba(255, 0, 0, 0.2)', padding: '8px 15px 8px 15px', borderRadius: '6px' }}
    >
      <Alert.Heading className={classNames({ 'm-0': msg && !errors })}>
        <span className="triangle-Icon">
          <AlertTriangle stroke="#930000" className="icon-gap-right" />
        </span>
        <span className="fs-label theme-text">{msg || defaultMsg}</span>
      </Alert.Heading>
      {errors && (
        <ul className="validation-list">
          {React.Children.toArray(
            Object.values(errors).map((value, idx) => {
              return <li className="fs-label theme-text">{value}</li>;
            }),
          )}
        </ul>
      )}
    </Alert>
  );
}
