import React from 'react';
import sortBy from 'lodash/sortBy';

import CardComponent from '../../../components/base/CardComponent';
import HorizontalRule from '../../../components/base/HorizontalRule';
import PagesHeader from '../../../components/base/PagesHeader';
import { Item } from '../../../api/service/webflow/types';

export const GettingStarted = ({ getStartedItems }: { getStartedItems: Item[] }) => {
  const sortedItems: Item[] = sortBy(getStartedItems, ['order']);

  return (
    <div className="mt-2 getting-started-box">
      <CardComponent className="get-started-bg mb-0">
        <PagesHeader pageTitle="Getting started" />
        <HorizontalRule height="1px" color="#D3D3D3" />
        <div className="d-flex justify-content-between flex-wrap">
          {React.Children.toArray(
            sortedItems.map((i) => {
              return (
                <div>
                  <img
                    className="mb-1 image-filter"
                    src={i['post-illustration'].url}
                    alt={i['post-illustration'].alt ?? 'logo'}
                    width={75}
                    height={65}
                  />
                  <div
                    style={{
                      backgroundColor: 'transparent',
                      padding: '5px 10px',
                    }}
                  >
                    <h3 className="fs-h3 theme-text mb-0 mt-2">{i.name}</h3>
                    <p className="fs-text-400 theme-text mt-1" style={{ width: 194 }}>
                      {i['body-text']}
                    </p>
                  </div>
                </div>
              );
            }),
          )}
        </div>
      </CardComponent>
    </div>
  );
};
