import { Alert, Form, Spinner } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import {
  AdminDetails,
  AuthorizedSignatory,
  CompanyDetails,
  ContractDetails,
  Consultants,
  Invitation,
} from '../ClientForms';
import { useEditClientContext } from '../context/useContext';
import { DateType } from '../types';
import { Info, ChevronRight } from 'react-feather';
import RoleAccess from '../../../../components/base/RoleAccess';
import { UserSubRole } from '../../../../types/auth';
import { useTranslation } from 'react-i18next';
import { AccessLevel } from '../ClientForms/Access';
import { FileUpload } from '../ClientForms/FileUpload';

type Props = {
  isBusy: boolean;
  date: DateType;
  switchCompanyLogin: any;
};

export default function General({ isBusy, date, switchCompanyLogin }: Props) {
  const context = useEditClientContext();
  const { t } = useTranslation();
  return (
    <>
      <CardComponent>
        {isBusy ? (
          <Spinner />
        ) : (
          <Form>
            <RoleAccess allowedRoles={[UserSubRole.SENIOR_CONSULTANT]} roleType="secondary">
              <Alert variant="primary">
                <div
                  className="alert-message d-flex justify-content-between  font-weight-bold"
                  style={{ fontSize: 14 }}
                >
                  <div>
                    <Info className="icon-gap-right" />
                    {t('more_company_settings_view')}
                  </div>
                  <div className="d-flex justify-content-center cursor-pointer" onClick={switchCompanyLogin}>
                    <ChevronRight />
                    <Alert>{t('company_view_settings')}</Alert>
                  </div>
                </div>
              </Alert>
            </RoleAccess>

            <CompanyDetails context={context} date={date} formGroup={context.formGroup} />

            <ContractDetails context={context} formGroup={context.formGroup} />

            <AuthorizedSignatory context={context} formGroup={context.formGroup} />

            <AdminDetails context={context} formGroup={context.formGroup} />

            <Consultants context={context} formGroup={context.formGroup} />

            {/* <ContactPeople context={context} formGroup={context.formGroup} /> */}
            <AccessLevel context={context} formGroup={context.formGroup} />

            <FileUpload context={context} formGroup={context.formGroup} />

            <Invitation context={context} formGroup={context.formGroup} />
          </Form>
        )}
      </CardComponent>
      {/* <CardComponent>
        <div className="d-flex justify-content-end">
          <Button onClick={onCancel} className="app-btn-primary me-2 cancel-btn">
            <Slash className="icon-gap-right" /> Cancel changes
          </Button>
          <Button className="app-btn-primary">
            <Save className="icon-gap-right" /> Save changes
          </Button>
        </div>
      </CardComponent> */}
    </>
  );
}
