import { useEffect, useState } from 'react';
import CardComponent from '../../../../components/base/CardComponent';
import { Spinner } from 'react-bootstrap';
import { ReactComponent as PDFIcon } from '../../../../assets/img/file-PDF.svg';
import { Link, useParams } from 'react-router-dom';
import { getCompanyClientLegalDocuments } from '../../../../api/service/superAdmin/company-clients/service';
import { ICompanyClientLegalDocuments } from '../../../../api/service/superAdmin/company-clients/types';
import { openExternalLink } from '../../../../util';

export const Legal = () => {
  const [legalDocs, setLegalDocs] = useState<ICompanyClientLegalDocuments>();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const fetchAllDocuments = async () => {
    try {
      setIsLoading(true);
      const docs = await getCompanyClientLegalDocuments(id);
      setLegalDocs(docs);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllDocuments();
  }, []);

  return (
    <>
      <CardComponent>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="table-responsive react-bootstrap-table">
            <table className="table table-hover table-condensed">
              <tbody className="remote-table-body">
                {legalDocs?.document?.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="pdf-icon">
                            <PDFIcon />
                          </span>
                          <Link
                            to={item.path}
                            onClick={(e) => openExternalLink(item.path, { event: e })}
                            target="_blank"
                            rel="noreferrer"
                            className="fs-h3 ms-2 font-link"
                          >
                            {item.name}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </CardComponent>
    </>
  );
};
