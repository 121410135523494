import { createContext, useState } from 'react';
import { ContactPeople } from '../../../../api/service/accountingFirm/clients/types';
import {
  INITIAL_COMPANY_DETAILS,
  INITIAL_ADDRESS_DETAILS,
  INITIAL_SIGNATORY_DETAILS,
  INITIAL_CONTACT_PEOPLE,
  FORM_INITIAL_VALUES,
  INITIAL_INIVITATION_EMAIL_SETTING,
} from '../contants';
import {
  CollectiveAgreementType,
  CompanyDetails,
  AddressDetails,
  AuthorizedSignatory,
  AdminDetails,
  ICompanyClientContext,
  ClientConsultant,
  ClientFormGroup,
} from '../types';
import { useFormik } from 'formik';
import { formSchema } from './schema';
import { AuthorizedSignatoryGroup } from '../../../superAdmin/CompanyClients/types';

export const CreateClientContext = createContext<ICompanyClientContext>({
  companyDetails: INITIAL_COMPANY_DETAILS,
  setCompanyDetails: () => {},

  addressDetails: INITIAL_ADDRESS_DETAILS,
  setAddressDetails: () => {},

  contractNumber: '',
  setContractNumber: () => {},

  adminDetails: [],
  setAdminDetails: () => {},

  clientConsultants: [],
  setClientConsultants: () => {},

  newClientConsultants: [],
  setNewClientConsultants: () => {},

  signatoryDetails: [],
  setSignatoryDetails: () => {},

  salaryContactPeople: INITIAL_CONTACT_PEOPLE,
  setSalaryContactPeople: () => {},

  accountingContactPeople: INITIAL_CONTACT_PEOPLE,
  setAccountingContactPeople: () => {},

  contractEffectiveFrom: '',
  setContractEffectiveFrom: () => {},

  invoiceCostCenter: '',
  setInvoiceCostCenter: () => {},

  invoiceEmailAddress: '',
  setInvoiceEmailAddress: () => {},

  collectiveAgreements: [],
  setCollectiveAgreements: () => {},

  invitationEmail: INITIAL_INIVITATION_EMAIL_SETTING,
  setInvitationEmail: () => {},

  vismaAPIKey: '',
  setVismaAPIKey: () => {},

  isEditMode: false,

  formGroup: {} as ClientFormGroup,

  signatoryAdmins: [],
  setSignatoryAdmins: () => {},

  deletedAdmins: [],
  setDeletedAdmins: () => {},

  deletedConsultants: [],
  setDeletedConsultants: () => {},

  combinations: [],
  setCombinations: () => {},

  replaceAdminDetails: () => {},
  replaceAddress: () => {},
  replaceAuthorizedSignatory: () => {},
  replaceCompanyDetails: () => {},
  replaceConsultants: () => {},
  replaceConsultant: () => {},
  replaceAccountingContactPeople: () => {},
  replaceSalaryContactPeople: () => {},
  updateAdminDetails: () => {},
  updateAddress: () => {},
  updateAuthorizedSignatory: () => {},
  updateContractNumber: () => {},
  updateCompanyDetails: () => {},
  updateConsultants: () => {},
  updateAccountingContactPeople: () => {},
  updateSalaryContactPeople: () => {},
  updateContractEffectiveInput: () => {},
  updateInvoicingCostCenter: () => {},
  updateInvoiceEmailAddress: () => {},
  updateInvitationEmail: () => {},
  replaceCollectiveAgreements: () => {},
  updateVismaAPIKey: () => {},
  replaceSignatoryAdmins: () => {},
  appendDeletedAdmin: () => {},
  appendDeletedConsultant: () => {},
  replaceCombinations: () => {},
  updateCombinations: () => {},
  replaceAccessLevel: () => {},
});

type Props = {
  children: JSX.Element;
};

export const CreateClientProvider = ({ children }: Props) => {
  const [companyDetails, setCompanyDetails] = useState<CompanyDetails>(INITIAL_COMPANY_DETAILS);
  const [contractNumber, setContractNumber] = useState<string>('');
  const [addressDetails, setAddressDetails] = useState<AddressDetails>(INITIAL_ADDRESS_DETAILS);
  const [clientConsultants, setClientConsultants] = useState<Array<ClientConsultant>>([]);
  const [newClientConsultants, setNewClientConsultants] = useState<Array<ClientConsultant>>([]);
  const [adminDetails, setAdminDetails] = useState<Array<AdminDetails>>([]);
  const [signatoryDetails, setSignatoryDetails] = useState<Array<AuthorizedSignatory>>([
    { _id: `${Math.random()}`, ...INITIAL_SIGNATORY_DETAILS, incharge: true },
    { _id: `${Math.random()}`, ...INITIAL_SIGNATORY_DETAILS },
  ]);
  const [salaryContactPeople, setSalaryContactPeople] = useState<ContactPeople>(INITIAL_CONTACT_PEOPLE);
  const [accountingContactPeople, setAccountingContactPeople] = useState<ContactPeople>(INITIAL_CONTACT_PEOPLE);
  const [contractEffectiveFrom, setContractEffectiveFrom] = useState<string>('');
  const [invoiceCostCenter, setInvoiceCostCenter] = useState<string>('');
  const [invoiceEmailAddress, setInvoiceEmailAddress] = useState<string>('');
  const [collectiveAgreements, setCollectiveAgreements] = useState<Array<CollectiveAgreementType>>([]);
  const [invitationEmail, setInvitationEmail] = useState<string>(INITIAL_INIVITATION_EMAIL_SETTING);
  const [vismaAPIKey, setVismaAPIKey] = useState<string>('');
  const [signatoryAdmins, setSignatoryAdmins] = useState<Array<AuthorizedSignatory>>([]);
  const [deletedAdmins, setDeletedAdmins] = useState<Array<string>>([]);
  const [deletedConsultants, setDeletedConsultants] = useState<Array<string>>([]);
  const [combinations, setCombinations] = useState<Array<AuthorizedSignatoryGroup>>([]);
  const isEditMode = false;

  const formGroup = useFormik({
    initialValues: FORM_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const replaceAdminDetails = (value) => {
    setAdminDetails(value);
    formGroup.setFieldValue('admins', value);
  };

  const replaceAddress = (value) => {
    setAddressDetails(value);
    formGroup.setFieldValue('address', value);
    formGroup.setFieldTouched('address');
  };

  const replaceAuthorizedSignatory = (value) => {
    setSignatoryDetails(value);
    formGroup.setFieldValue('authorizedSignatory', value);
  };

  const replaceCompanyDetails = (value) => {
    setCompanyDetails(value);
    formGroup.setFieldValue('companyDetails', value);
    formGroup.setFieldTouched('companyDetails');
  };

  const updateContractNumber = (inputValue) => {
    setContractNumber(inputValue);
    formGroup.setFieldValue(`contractNumber`, inputValue);
  };

  const replaceConsultants = (value) => {
    setClientConsultants(value);
    formGroup.setFieldValue('consultants', value);
  };
  const replaceConsultant = (value) => {
    formGroup.setFieldValue('consultant', value);
    // formGroup.setFieldTouched('consultant');
  };
  const replaceAccountingContactPeople = (value) => {
    setAccountingContactPeople(value);
    formGroup.setFieldValue('accountingContactPeople', value);
  };

  const replaceSalaryContactPeople = (value) => {
    setSalaryContactPeople(value);
    formGroup.setFieldValue('salaryContactPeople', value);
  };

  const updateAddress = (inputName, inputValue) => {
    setAddressDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`address.${inputName}`, inputValue);
  };

  const updateAdminDetails = (inputName, inputValue) => {
    setAdminDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`admins.${inputName}`, inputValue);
  };

  const updateAuthorizedSignatory = (inputName, inputValue) => {
    setSignatoryDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`authorizedSignatory.${inputName}`, inputValue);
  };

  const updateCompanyDetails = (inputName, inputValue) => {
    setCompanyDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`companyDetails.${inputName}`, inputValue);
  };

  const updateConsultants = (inputName, inputValue) => {
    setClientConsultants((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`consultants.${inputName}`, inputValue);
  };

  const updateAccountingContactPeople = (inputName, inputValue) => {
    setAccountingContactPeople((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`accountingContactPeople.${inputName}`, inputValue);
  };

  const updateSalaryContactPeople = (inputName, inputValue) => {
    setSalaryContactPeople((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`salaryContactPeople.${inputName}`, inputValue);
  };

  const updateContractEffectiveInput = (inputValue) => {
    setContractEffectiveFrom(inputValue);
    formGroup.setFieldValue(`contractEffectiveFrom`, inputValue);
  };

  const updateInvoicingCostCenter = (inputValue) => {
    setInvoiceCostCenter(inputValue);
    formGroup.setFieldValue(`invoiceContractCenter`, inputValue);
  };

  const updateInvoiceEmailAddress = (inputValue) => {
    setInvoiceEmailAddress(inputValue);
    formGroup.setFieldValue(`invoiceEmailAddress`, inputValue);
  };

  const updateInvitationEmail = (inputValue) => {
    setInvitationEmail(inputValue);
    formGroup.setFieldValue(`invitationEmail`, inputValue);
  };

  const updateVismaAPIKey = (inputValue) => {
    setVismaAPIKey(inputValue);
    formGroup.setFieldValue(`vismaAPIKey`, inputValue);
  };

  const replaceCollectiveAgreements = (inputValue) => {
    setCollectiveAgreements(inputValue);
    formGroup.setFieldValue('collectiveAgreements', inputValue);
  };

  const replaceSignatoryAdmins = (value) => {
    setSignatoryAdmins(value);
    formGroup.setFieldValue('signatoryAdmins', value);
  };

  const replaceCombinations = (value) => {
    setCombinations(value);
    formGroup.setFieldValue('combinations', value);
  };

  const updateCombinations = (inputName, inputValue) => {
    setCombinations((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`combinations.${inputName}`, inputValue);
  };

  const appendDeletedAdmin = (value) => {
    if (!isEditMode) {
      return;
    }

    const _deletedAdmins = [...deletedAdmins];
    _deletedAdmins.push(value);

    setDeletedAdmins(_deletedAdmins);
  };

  const appendDeletedConsultant = (value) => {
    if (!isEditMode) {
      return;
    }

    const _deletedConsultants = [...deletedConsultants];
    _deletedConsultants.push(value);

    setDeletedConsultants(_deletedConsultants);
  };

  const replaceAccessLevel = (allowCorrectoAccess, allowAdvisorAccess) => {
    formGroup.setFieldValue(`allowCorrectoAccess`, allowCorrectoAccess);
    formGroup.setFieldValue(`allowAdvisorAccess`, allowAdvisorAccess);
  };

  const context: ICompanyClientContext = {
    companyDetails,
    setCompanyDetails,
    contractNumber,
    setContractNumber,
    clientConsultants,
    setClientConsultants,
    newClientConsultants,
    setNewClientConsultants,
    adminDetails,
    setAdminDetails,
    signatoryDetails,
    setSignatoryDetails,
    addressDetails,
    setAddressDetails,
    salaryContactPeople,
    setSalaryContactPeople,
    accountingContactPeople,
    setAccountingContactPeople,
    contractEffectiveFrom,
    setContractEffectiveFrom,
    isEditMode: false,
    collectiveAgreements,
    setCollectiveAgreements,
    invoiceCostCenter,
    setInvoiceCostCenter,
    invoiceEmailAddress,
    setInvoiceEmailAddress,
    invitationEmail,
    setInvitationEmail,
    vismaAPIKey,
    setVismaAPIKey,
    signatoryAdmins,
    setSignatoryAdmins,
    deletedAdmins,
    setDeletedAdmins,
    deletedConsultants,
    setDeletedConsultants,
    combinations,
    setCombinations,

    formGroup: formGroup as any,
    replaceAdminDetails,
    replaceAddress,
    replaceAuthorizedSignatory,
    replaceCompanyDetails,
    updateContractNumber,
    replaceConsultants,
    replaceConsultant,
    replaceAccountingContactPeople,
    replaceSalaryContactPeople,
    updateAdminDetails,
    updateAddress,
    updateAuthorizedSignatory,
    updateCompanyDetails,
    updateConsultants,
    updateAccountingContactPeople,
    updateSalaryContactPeople,
    updateContractEffectiveInput,
    updateInvoiceEmailAddress,
    updateInvoicingCostCenter,
    updateInvitationEmail,
    replaceCollectiveAgreements,
    updateVismaAPIKey,

    replaceSignatoryAdmins,
    replaceCombinations,
    updateCombinations,
    appendDeletedAdmin,
    appendDeletedConsultant,
    replaceAccessLevel,
  };

  return <CreateClientContext.Provider value={context}>{children}</CreateClientContext.Provider>;
};
