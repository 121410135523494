export const defaultChartOption = {
  chart: {
    type: 'pie',
    plotShadow: false,
    height: '100%',
  },
  title: {
    y: 10,
    verticalAlign: 'middle',
    floating: true,
  },
  subtitle: {
    verticalAlign: 'middle',
    y: 30,
    floating: true,
  },
  navigator: {
    enabled: false,
  },

  tooltip: {
    shared: true,
    enabled: true,
    stickOnContact: true,
    useHTML: true,
    valueDecimals: 2,
    valuePrefix: '',
    // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  },

  exporting: {
    enabled: false,
    csv: {
      columnHeaderFormatter: (item, key, keyLength) => {
        if (item) {
          return false;
        } else {
          return 'Category';
        }
      },
      dateFormat: '',
    },
  },
  plotOptions: {
    pie: {
      cursor: 'pointer',
      allowPointSelect: true,
      size: 70,
      dataLabels: {
        enabled: true,
        distance: '1%',
      },
      // states: {
      //     hover: {
      //         halo: {
      //             attributes: {
      //                 'stroke-width': 0 //to keep default setting
      //             }
      //         }
      //     }
      // }
    },
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 400,
        },
        chartOptions: {
          chart: {
            spacing: [10, 0, 10, 0],
            margin: [0, 0, 0, 0],
            // width: 700
          },
          plotOptions: {
            pie: {
              size: 100,
              dataLabels: {
                connectorWidth: 0,
                distance: '0.6%',
              },
            },
          },
        },
      },
    ],
  },
  series: [],
};

export const pieChartDarkModeOption = {
  chart: {
    backgroundColor: '#3c3c3c',
    borderWidth: 1,
    borderColor: '#ffffff33',
    style: {
      color: '#fff',
      fontFamily: "'Source Sans Pro', sans-serif",
    },
  },
  title: {
    style: {
      color: '#fff',
      font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
      textAlign: 'center',
    },
  },
  subtitle: {
    style: {
      textAlign: 'center',
      color: '#fff',
    },
  },
  navigator: {
    maskFill: 'rgba(255,255,255,0.25)',
  },

  legend: {
    itemStyle: {
      color: '#ffffff',
    },
    itemHiddenStyle: {
      color: '#b2adad',
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        color: '#ffffff',
      },
      // states: {
      //     hover: {
      //         halo: {
      //             enabled: true,
      //             attributes: {
      //                 fill: '#ffffff',

      //             }
      //         }

      //     }
      // },

      series: [
        {
          color: '#01b8aa',
        },
        {
          color: '#fd625e',
        },
        {
          color: '#ddb60a',
        },
        {
          color: '#26a4eb',
        },
        {
          color: '#f160e3',
        },
        {
          color: '#ff7f0e',
        },
        {
          color: '#2ca02c',
        },
        {
          color: '#d62728',
        },
        {
          color: '#9467bd',
        },
        {
          color: '#8c564b',
        },
        {
          color: '#FF8C00',
        },
      ],
    },
  },
};

export const pieChartLightModeOption = {
  chart: {
    backgroundColor: null,
    style: {
      color: '#000000',
      fontFamily: "'Source Sans Pro', sans-serif",
    },
  },
  title: {
    style: {
      color: '#000000',
      font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
      textAlign: 'center',
    },
  },
  subtitle: {
    style: {
      textAlign: 'center',
      color: '#000000',
    },
  },
  navigator: {
    maskFill: 'rgba(255,255,255,0.25)',
  },
  legend: {
    itemStyle: {
      color: '#000000',
    },
    itemHiddenStyle: {
      color: '#b2adad',
    },
  },
  plotOptions: {
    pie: {
      dataLabels: {
        color: '#000000',
      },
      states: {
        hover: {
          halo: {
            enabled: true,
            // attributes: {
            //     fill: '#ffffff',

            // }
          },
        },
      },
    },
    series: [
      {
        states: {
          hover: {
            enabled: true,
          },
        },
        data: [
          {
            y: 10,
            borderWidth: 2,
            states: {
              hover: {
                borderColor: 'yellow',
                borderWidth: 4,
                halo: {
                  size: 20,
                  opacity: 1,
                  attributes: {
                    fill: 'green',
                  },
                },
              },
            },
          },
          {
            y: 18,
            borderWidth: 2,
            states: {
              hover: {
                borderColor: 'yellow',
                borderWidth: 4,
                halo: {
                  size: 20,
                  opacity: 1,
                  attributes: {
                    fill: 'red',
                  },
                },
              },
            },
          },
        ],
      },
    ],
  },
};
