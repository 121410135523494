import { useProfileContext } from '../context/useProfileContext';

const useProfileForm = () => {
  const { profileFormGroup } = useProfileContext();
  const profileFormVal = profileFormGroup.values;

  const handleProfileChange = (name: string, value: string) => {
    profileFormGroup.setFieldValue(name, value);
  };

  return {
    profileFormGroup,
    profileFormVal,
    handleProfileChange,
  };
};

export default useProfileForm;
