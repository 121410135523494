// Layouts
import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';

// Auth
import AuthGuard from './components/guards/AuthGuard';

import SignIn from './pages/auth/SignIn';

// Company Pages
import CompanyOverview from './pages/company/Overview/Overview';
import CompanyBusinessAnalysis from './pages/company/BusinessAnalysis/BusinessAnalysis';
import CompanyEmployees from './pages/company/Employees/Employees';
import CompanyEmployeeDetails from './pages/company/Employees/EmployeeDetails/EmployeeDetails';
import ReportOverview from './pages/company/Reports/ReportOverview';
import CompanySettings from './pages/company/Settings/Settings';

// Super Admin Pages
import SuperAdminOverview from './pages/superAdmin/Overview';
import SuperAdminCompanyClients from './pages/superAdmin/CompanyClients/CompanyClients';
import SuperAdminPersonalClients from './pages/superAdmin/PersonalClients/PersonalClients';
import SuperAdminPensionPolicies from './pages/superAdmin/PensionPolicy/PensionPolicies';
import SuperAdminCreatePensionPolicy from './pages/superAdmin/PensionPolicy/CreatePolicy';
import SuperAdminSettings from './pages/superAdmin/Settings';

// Accounting Firm Pages
import AccountingFirmClients from './pages/accountingFirm/Clients/Clients';
import { AccountingFirmOverview } from './pages/accountingFirm/Overview';

// Base Pages
import Page404 from './pages/base/Page404';
import PensionPolicyDetails from './pages/superAdmin/PensionPolicy/PensionPoliciesDetails';
import SuperAdminLogin from './pages/superAdmin/SuperAdminLogin';
import { PensionPolicyProvider } from './pages/superAdmin/PensionPolicy/PensionPolicyContext/PensionPolicyContext';
import EditPolicy from './pages/superAdmin/PensionPolicy/EditPolicy';
import AdminSettings from './pages/superAdmin/AdminSettings/AdminSettings';
import EmployeePensionProvider from './pages/company/Employees/context/EmployeePensionContext';
import AdminSignInPage from './pages/auth/AdminSignInPage';
import CompanyClientDetail from './pages/superAdmin/CompanyClients/CompanyClientDetails/CompanyClientDetail';
import PersonalClientDetails from './pages/superAdmin/PersonalClients/PersonalClientDetails/PersonalClientDetails';
import { EditPersonalClientProvider } from './pages/superAdmin/PersonalClients/context/EditPersonalClientContext';
import DataManagement from './pages/company/DataManagement/DataManagement';
import Billing from './pages/superAdmin/Billing/Billing';
import { EditClientProvider } from './pages/accountingFirm/Clients/context/EditClientContext';
import ClientDetail from './pages/accountingFirm/Clients/ClientDetails/ClientDetail';
import { Navigate } from 'react-router-dom';
import ConsultantList from './pages/accountingFirm/Consultant/ConsultantDetails/ConsultantDetail';
import { EditConsultantProvider } from './pages/accountingFirm/Consultant/context/EditConsultantContext';
import Consultants from './pages/accountingFirm/Consultant/Consultants';
import AccountingFirms from './pages/superAdmin/AccountingFirm/AccountingFirms';
import { EditAccountingFirmProvider } from './pages/superAdmin/AccountingFirm/context/EditAccountingFirmContext';
import AccountingFirmDetail from './pages/superAdmin/AccountingFirm/AccountingFirmDetails/AccountingFirmDetails';
import PublicLoginPage from './pages/auth/PublicLogin';
import SalarySlipDetail from './pages/company/DataManagement/SalarySlipDetail/SalarySlipDetail';
import { SalarySlipProvider } from './pages/company/DataManagement/context/SalarySlipContext';
import { BaseSalary } from './pages/company/Reports/BaseSalary/BaseSalary';
import { RealSalary } from './pages/company/Reports/RealSalary/RealSalary';
import { NetSalary } from './pages/company/Reports/NetSalary/NetSalary';
import { HolidayPayLiability } from './pages/company/Reports/HolidayPayLiabillity/HolidayPayLiability';
import { SpecialPayrollTax } from './pages/company/Reports/SpecialPayrollTax/SpecialPayrollTax';
import { SocialTax } from './pages/company/Reports/SocialTax/SocialTax';
import { CalculatedSalaryChange } from './pages/company/Reports/CalculatedSalaryChange/CalculatedSalaryChange';
import { SalaryType } from './pages/company/Reports/SalaryType/SalaryType';
import { EditCompanyClientProvider } from './pages/superAdmin/CompanyClients/context/EditCompanyClientContext';
import { EditGeneralSettingsProvider } from './pages/company/Settings/General/context/EditGeneralSettingsContext';
import { SuperAdminSettingProvider } from './pages/superAdmin/AdminSettings/context';
import { Customizable } from './pages/company/Reports/Customizable/Customizable';
import Adminlayout from './layouts/AdminLayout';
import SignInLayout from './layouts/SignInLayout';
import { UserRole } from './types/auth';
import AccountingFirmSettings from './pages/accountingFirm/Settings/Settings';
import { EditAccountingFirmGeneralSettingsProvider } from './pages/accountingFirm/Settings/General/context/EditGeneralSettingsContext';
import EmployeeBenefit from './pages/company/Reports/EmployeeBenefit/EmployeeBenefit';
import ProfileView from './pages/superAdmin/Profile/ProfileView';
import { OverlayLoaderProvider } from './contexts/OverlayLoaderContext';

const routesCompany = [
  {
    path: '',
    element: <Adminlayout />,
    children: [
      {
        path: '/',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <SignIn />,
          },
        ],
      },
      {
        path: '/admin',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <AdminSignInPage />,
          },
        ],
      },

      {
        path: '/invitation',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <SignIn />,
          },
        ],
      },
      {
        path: '/dashboard',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <CompanyOverview />,
          },
        ],
      },
      {
        path: '/business-analysis',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <CompanyBusinessAnalysis />,
          },
        ],
      },
      {
        path: '/employees',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <CompanyEmployees />,
          },
          {
            path: ':id/',
            element: (
              <EmployeePensionProvider>
                <CompanyEmployeeDetails />
              </EmployeePensionProvider>
            ),
            children: [
              {
                path: ':policyId/',
                element: (
                  <EmployeePensionProvider>
                    <CompanyEmployeeDetails />
                  </EmployeePensionProvider>
                ),
              },
            ],
          },
        ],
      },
      {
        path: '/data-management',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <DataManagement />,
          },
          {
            path: 'salary-slip/:id',
            element: (
              <SalarySlipProvider>
                <SalarySlipDetail />
              </SalarySlipProvider>
            ),
          },
        ],
      },
      // {
      //   path: '/pension-cost',
      //   element: (
      //     <AuthGuard>
      //       <DashboardLayout />
      //     </AuthGuard>
      //   ),
      //   children: [
      //     {
      //       path: '',
      //       element: <CompanyPensionCost />,
      //     },
      //   ],
      // },
      {
        path: '/pension-policies',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <PensionPolicyProvider>
                <SuperAdminPensionPolicies />
              </PensionPolicyProvider>
            ),
          },
          {
            path: 'create',
            element: (
              <PensionPolicyProvider>
                <SuperAdminCreatePensionPolicy />
              </PensionPolicyProvider>
            ),
          },
          {
            path: ':id/edit/',
            element: (
              <PensionPolicyProvider>
                <EditPolicy />
              </PensionPolicyProvider>
            ),
          },
          {
            path: ':id',
            element: (
              <PensionPolicyProvider>
                <PensionPolicyDetails />
              </PensionPolicyProvider>
            ),
          },
        ],
      },
      {
        path: '/reports',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <ReportOverview />,
          },
          {
            path: 'base-salary',
            element: <BaseSalary />,
          },
          {
            path: 'real-salary',
            element: <RealSalary />,
          },
          {
            path: 'net-salary',
            element: <NetSalary />,
          },
          {
            path: 'holiday-pay-liability',
            element: <HolidayPayLiability />,
          },
          {
            path: 'special-payroll-tax',
            element: <SpecialPayrollTax />,
          },
          {
            path: 'social-tax',
            element: <SocialTax />,
          },
          {
            path: 'calculated-salary-change',
            element: <CalculatedSalaryChange />,
          },
          {
            path: 'salary-type',
            element: <SalaryType />,
          },
          {
            path: 'customizable',
            element: <Customizable />,
          },
          {
            path: 'employee-benefit',
            element: <EmployeeBenefit />,
          },
        ],
      },
      {
        path: '/settings',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <EditGeneralSettingsProvider>
                <CompanySettings />
              </EditGeneralSettingsProvider>
            ),
          },
        ],
      },
      {
        path: '/profile',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <ProfileView />,
          },
        ],
      },
      {
        path: '*',
        element: <AuthLayout />,
        children: [
          {
            path: '*',
            element: <Page404 />,
          },
        ],
      },
      {
        path: '/superadmin',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <SuperAdminLogin />,
          },
        ],
      },
    ],
  },
];

const routesSuperAdmin = [
  // ...EMPLOYEE_ROUTE_REGISTRY,
  {
    path: '',
    element: <Adminlayout />,
    children: [
      {
        path: '/admin',
        element: <SignInLayout />,
        children: [
          {
            path: '',
            element: <AdminSignInPage />,
          },
        ],
      },
      {
        path: '/accounting-firms',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <AccountingFirms />,
          },
          {
            path: ':id',
            element: (
              <EditAccountingFirmProvider>
                <AccountingFirmDetail />
              </EditAccountingFirmProvider>
            ),
          },
          // {
          //   path: ':id',
          //   element: (
          //     <EditConsultantProvider>
          //       <ConsultantList />
          //     </EditConsultantProvider>
          //   ),
          // },
        ],
      },
      {
        path: '/',
        element: <SignInLayout />,
        children: [
          {
            path: '',
            element: <SignIn />,
          },
        ],
      },
      {
        path: '/signin/employee',
        element: <SignInLayout pageRoleType={UserRole.EMPLOYEE} />,
        children: [
          {
            path: '',
            element: <SignIn pageRoleType={UserRole.EMPLOYEE} />,
          },
        ],
      },
      {
        path: '/temp-login',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <PublicLoginPage />,
          },
        ],
      },
      {
        path: '/invitation',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <SignIn />,
          },
        ],
      },
      {
        path: '/superadmin',
        element: <AuthLayout />,
        children: [
          {
            path: '',
            element: <SuperAdminLogin />,
          },
        ],
      },
      {
        path: '/dashboard',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <SuperAdminOverview />,
          },
        ],
      },
      {
        path: '/company-clients',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <SuperAdminCompanyClients />,
          },
          {
            path: ':id',
            element: (
              <EditCompanyClientProvider>
                <OverlayLoaderProvider>
                  <CompanyClientDetail />
                </OverlayLoaderProvider>
              </EditCompanyClientProvider>
            ),
          },
        ],
      },

      {
        path: '/personal-clients',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <SuperAdminPersonalClients />,
          },
          {
            path: ':id',
            element: (
              <EditPersonalClientProvider>
                <PersonalClientDetails />
              </EditPersonalClientProvider>
            ),
          },
        ],
      },
      {
        path: '/admin-settings',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: (
              <SuperAdminSettingProvider>
                <AdminSettings />
              </SuperAdminSettingProvider>
            ),
          },
        ],
      },
      {
        path: '/pension-policies',
        element: <DashboardLayout />,
        children: [
          {
            path: '',
            element: (
              <PensionPolicyProvider>
                <SuperAdminPensionPolicies />
              </PensionPolicyProvider>
            ),
          },
          {
            path: 'create',
            element: (
              <PensionPolicyProvider>
                <SuperAdminCreatePensionPolicy />
              </PensionPolicyProvider>
            ),
          },
          {
            path: ':id/edit/',
            element: (
              <PensionPolicyProvider>
                <EditPolicy />
              </PensionPolicyProvider>
            ),
          },
          {
            path: ':id',
            element: (
              <PensionPolicyProvider>
                <PensionPolicyDetails />
              </PensionPolicyProvider>
            ),
          },
        ],
      },
      {
        path: '/correcto-admin',
        element: <DashboardLayout />,
        children: [
          {
            path: '',
            element: <SuperAdminSettings />,
          },
        ],
      },
      {
        path: '/billing',
        element: <DashboardLayout />,
        children: [
          {
            path: '',
            element: <Billing />,
          },
        ],
      },
      {
        path: '*',
        element: <AuthLayout />,
        children: [
          {
            path: '*',
            element: <Page404 />,
          },
        ],
      },
      {
        path: '/profile',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '',
            element: <ProfileView />,
          },
        ],
      },
    ],
  },
];

const routesAccountingFirm = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard" />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AccountingFirmOverview />,
      },
    ],
  },
  {
    path: '/accounting-firms',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <AccountingFirmClients />,
      },
      {
        path: ':id',
        element: (
          <EditClientProvider>
            <OverlayLoaderProvider>
              <ClientDetail />
            </OverlayLoaderProvider>
          </EditClientProvider>
        ),
      },
    ],
  },
  {
    path: '/consultant',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Consultants />,
      },
      {
        path: ':id',
        element: (
          <EditConsultantProvider>
            <ConsultantList />
          </EditConsultantProvider>
        ),
      },

      // {
      //   path: ':id',
      //   element: (
      //     <EditConsultantProvider>
      //       <ConsultantList />
      //     </EditConsultantProvider>
      //   ),
      // },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SuperAdminOverview />,
      },
    ],
  },
  {
    path: '/settings',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <EditAccountingFirmGeneralSettingsProvider>
            <AccountingFirmSettings />
          </EditAccountingFirmGeneralSettingsProvider>
        ),
      },
    ],
  },
  {
    path: '/profile',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <ProfileView />,
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
];

export { routesCompany, routesSuperAdmin, routesAccountingFirm };
