import { useEffect, useState } from 'react';
import { deepClone, isEmptyVal, isEqual } from '../../../../util/util';
import useEmployeePensionContext from '../useContext';

const useEmployeeOverviewObserver = () => {
  const [isFormModified, setIsFormModified] = useState(false);
  const {
    formGroup,
    employeeRecord,

    replaceEmployeeDetails,
    replaceVacationDetails,
    replaceParentalLeave,
  } = useEmployeePensionContext();

  const getInitialFormValue = () => {
    const initialPersonalInfo = {
      phoneNumberWork: employeeRecord?.phoneNumberWork || '',
      phoneNumberPrivate: employeeRecord?.phoneNumberPrivate || '',
      email: employeeRecord?.email || '',
    };

    const initialParentalInfo = (employeeRecord?.parentalInfo || []).map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        birthDate: item.birthDate,
      };
    });

    const initialCostCenter = employeeRecord?.costCenter || null;

    const initialPersonnelType = employeeRecord?.personnelType || null;

    const initialScopeOfWork = !isEmptyVal(employeeRecord?.scopeOfWork) ? employeeRecord?.scopeOfWork : null;

    const initialVacation = {
      paidHolidays: !isEmptyVal(employeeRecord?.vacation?.paidHolidays) ? employeeRecord?.vacation?.paidHolidays : null,
      effectiveFrom: employeeRecord?.vacation?.effectiveFrom || null,
    };

    return {
      personalInfo: initialPersonalInfo,
      parentalInfo: initialParentalInfo,
      costCenter: initialCostCenter,
      personnelType: initialPersonnelType,
      scopeOfWork: initialScopeOfWork,
      vacation: initialVacation,
    };
  };

  const getUpdatedFormValue = () => {
    const currentInfo = formGroup.values;

    const currentPersonalInfo = {
      phoneNumberWork: currentInfo.phoneNumberWork || '',
      phoneNumberPrivate: currentInfo.phoneNumberPrivate || '',
      email: currentInfo.email || '',
    };

    const currentParentalInfo = (currentInfo?.parentalInfo || []).map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        birthDate: item.birthDate,
      };
    });

    const currentCostCenter = currentInfo?.costCenter || null;

    const currentPersonelType = currentInfo?.personnelType || null;

    const currentScopeOfWork = !isEmptyVal(currentInfo?.scopeOfWork) ? currentInfo?.scopeOfWork : null;

    const currentVacation = {
      paidHolidays: !isEmptyVal(currentInfo?.vacation?.paidHolidays) ? currentInfo?.vacation?.paidHolidays : null,
      effectiveFrom: currentInfo?.vacation?.effectiveFrom || null,
    };

    return {
      personalInfo: currentPersonalInfo,
      parentalInfo: currentParentalInfo,
      costCenter: currentCostCenter,
      personnelType: currentPersonelType,
      scopeOfWork: currentScopeOfWork,
      vacation: currentVacation,
    };
  };

  const revertChanges = () => {
    const _employeeRecord = deepClone(employeeRecord);

    replaceEmployeeDetails({ ..._employeeRecord });
    replaceVacationDetails(_employeeRecord.vacation);
    replaceParentalLeave(_employeeRecord.parentalInfo);
  };

  useEffect(() => {
    const initialFormValue = getInitialFormValue();
    const updatedFormValue = getUpdatedFormValue();

    if (isEqual(initialFormValue, updatedFormValue)) {
      setIsFormModified(false);
    } else {
      setIsFormModified(true);
    }
  }, [employeeRecord, formGroup.values]);

  return {
    isFormModified,
    revertChanges,
  };
};

export default useEmployeeOverviewObserver;
