import { Button, Form, Spinner } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';

import BankIDIcon from '../../assets/img/bankID-logo.svg';
import { GrandIDAuthStatus } from '../../types/auth';

interface Props {
  verificationStatus: GrandIDAuthStatus;
  onRetrySignIn: () => void;
}

function DesktopVerification({ verificationStatus, onRetrySignIn }: Props) {
  return (
    <>
      <div className="bankid-desktop-verification">
        <Form.Label className="fs-h3 font-black d-flex">Sign in with your BankID on this device.</Form.Label>
        {verificationStatus === GrandIDAuthStatus.PENDING && (
          <div className="verification-content">
            <div className="img-holder">
              <img src={BankIDIcon} alt="" />
            </div>
            <div className="loading-status">
              <div className="loading-status--text theme-text">Your bankID is launching...</div>
              <Spinner className="loading-status--spinner" />
            </div>
            <Button onClick={() => onRetrySignIn()} className="app-btn-primary">
              Start the BankID app manually
            </Button>
          </div>
        )}

        {verificationStatus === GrandIDAuthStatus.FAILED && (
          <>
            <div className="theme-text mb-3 mt-3">
              The BankID app doesn&apos;t seem to be on your computer or phone. Install it and get a BankID from your
              internet bank. Install the app from your app store or https://install.bankid.com.
            </div>
            <div className="d-flex justify-content-center">
              <Button onClick={() => onRetrySignIn()} style={{ textAlign: 'left' }} className="app-btn-primary">
                <ChevronRight /> Try again
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DesktopVerification;
