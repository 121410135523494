// import axios from 'axios';
import { ChangelogPost, CollectionItem, PostType, WebflowCollection } from './types';
import { get } from '../../../util/httpService';

export const gettingStartedCategoryId = '642abcc92b66317435f7305d';
export const postsCategoryId = '642abd04ad4f8e6a42edae18';

export const getCollections = async () => {
  const response = await get(`/api/v1/webflow-api/collections`);
  const collections: WebflowCollection[] = response;

  const overviewCollectionId = collections.find((collection) => collection.slug === PostType.OverviewPosts);
  const changeLogCollectionId = collections.find((collection) => collection.slug === PostType.ChangelogPosts);

  return [
    { _id: overviewCollectionId?._id, type: PostType.OverviewPosts },
    { _id: changeLogCollectionId?._id, type: PostType.ChangelogPosts },
  ];
};

export const getCollectionItem = async (collectionId: string): Promise<CollectionItem | ChangelogPost> => {
  const data = await get(`/api/v1/webflow-api/collections/${collectionId}`);
  return data;
};
