export type EmployeeDetails = {
  _id: string;
  importInformation?: any;
  email: string;
  alternateEmail: string;
  fullName: string;
  firstName: string;
  lastName: string;
  personalNumber: string;
  phoneNumberWork: string;
  phoneNumberPrivate: string;
  age: string;
  address: string;
  postCode: string;
  city: string;
  country: string;
  employmentForm: string;
  salaryForm: string;
  jobTitle: string;
  startDate: string;
  endDate: string;
  employmentNumber: string;
  employementStatus: string;
  costCenter: string;
  employmentType: string;
  salary: string;
  scheduleId: string;
  employmentDate: string;
  vacation: {
    paidHolidays: number;
    effectiveFrom: string;
  };
};

export enum EmployeeType {
  WHITE_COLLAR = 'WhiteCollar',
  BLUE_COLLAR = 'BlueCollar',
}

export enum EmployeeStatus {
  ACTIVE = 'Active',
  IN_ACTIVE = 'InActive',
}
