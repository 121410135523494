import { useContext, useEffect, useRef, useState } from 'react';
import { Row, Spinner, Form, Button } from 'react-bootstrap';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getClientConsultants, getClients } from '../../../api/service/accountingFirm/clients/service';
import { GetCompanyClientType } from '../../../api/service/superAdmin/company-clients/types';
import CardComponent from '../../../components/base/CardComponent';
import BSRemoteTable from '../../../components/table/BSRemoteTable';
import { OverlayLoaderContext, OverlayLoaderProvider } from '../../../contexts/OverlayLoaderContext';
import useAuth from '../../../hooks/useAuth';
import Pagination from '../../company/Pagination';
import { ClientConsultant, ClientSaveMode } from './types';
import { useColumn } from './useColumn';
import RoleAccess from '../../../components/base/RoleAccess';
import { UserSubRole } from '../../../types/auth';
import { AccountingFirmClient } from '../../../api/service/accountingFirm/clients/types';
import { useAccountingFirmRoleSwitcher } from './hooks/useAccountingFirmRoleSwitcher';

type Props = {
  isClientCreated: boolean;
};

export default function ClientsGrid({ isClientCreated }: Props) {
  const { companyClientColumn } = useColumn();
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [companyData, setCompanyData] = useState<GetCompanyClientType | null>(null);
  const { t } = useTranslation();
  const searchRef = useRef<any>();
  const totalPages = companyData?.metaData
    ? Math.ceil(companyData?.metaData.total / companyData?.metaData?.page.size)
    : 0;
  const [consultantList, setConsultantList] = useState<ClientConsultant[]>([]);
  const [params] = useSearchParams();
  const consultantQueryParams = params.get('consultant') || '';
  const [consultantFilter, setConsultantFilter] = useState(consultantQueryParams);
  const [isError, setIsError] = useState(false);
  const [isSetup, setIsSetup] = useState(false);

  const [tableFilter, setTableFilter] = useState({
    searchString: '',
    consultant: consultantFilter,
    isError: false,
    isSetup: false,
  });
  // const subRole = user?.role?.subRole || '';
  //   const isSupervisor = !subRole || ['Supervisor'].indexOf(subRole) === -1;
  const { setBusy } = useContext(OverlayLoaderContext);
  const { switchToCompanyAdmin } = useAccountingFirmRoleSwitcher();
  const onPagination = async ({ page, sizePerPage }) => {
    await fetchClients({ page, sizePerPage });
  };

  const onSearch = async () => {
    const searchTerm = searchRef.current['value'];
    setTableFilter((prevValue) => ({ ...prevValue, searchString: searchTerm }));
  };

  const switchLoginToCompany = async (row: AccountingFirmClient) => {
    switchToCompanyAdmin({ setBusy, details: row });
  };

  const fetchClients = async ({
    searchString = '',
    consultant = '',
    page = companyData?.metaData.page.current,
    sizePerPage = companyData?.metaData.page.size,
    sortField = 'name',
    sortOrder = '1',
    isError = false,
    isSetup = false,
  }) => {
    const clients = await getClients({
      searchString,
      page,
      sizePerPage,
      sortField,
      sortOrder,
      consultant,
      isError,
      isSetup,
    });

    setCompanyData(clients);
    setIsLoading(false);
  };

  const fetchConsultants = async () => {
    const consultants = await getClientConsultants(user?.role.company.id);
    params.delete('consultant');
    setConsultantList(consultants);
  };

  const handleConsultantChange = async (e) => {
    const value = e.target.value;
    setTableFilter((prevValue) => ({ ...prevValue, consultant: value }));
    setConsultantFilter(value);
    //   fetchClients(tableFilter);
  };

  const isActiveClientRow = (row, _index) => {
    let rowStyleClass = '';

    const isSupervisorWithConsultant =
      user?.role.subRole === UserSubRole.SUPERVISOR && row.consultants.find((item) => item._id === user.id);
    const isConsultant = user?.role.subRole === UserSubRole.SENIOR_CONSULTANT;
    const isSetup = row.status === ClientSaveMode.SETUP;

    if (row.invalid) {
      rowStyleClass += 'invalid-row';
    }

    if (!(isConsultant || isSupervisorWithConsultant) || isSetup) {
      if (!row.invalid) {
        rowStyleClass += ' highlight-inactive';
      }
    } else {
      rowStyleClass += ' cursor-pointer';
    }

    return rowStyleClass;
  };

  const handleErrorBtn = () => {
    setIsError(!isError);
    setTableFilter((prevValue) => ({ ...prevValue, isError: !isError }));
  };

  const handleSetupBtn = () => {
    setIsSetup(!isSetup);
    setTableFilter((prevValue) => ({ ...prevValue, isSetup: !isSetup }));
  };

  useEffect(() => {
    setIsLoading(true);
    fetchClients(tableFilter);
  }, [tableFilter]);

  useEffect(() => {
    if (isClientCreated) {
      setIsLoading(true);
      fetchClients({});
    }
  }, [isClientCreated]);

  useEffect(() => {
    fetchConsultants();
  }, []);

  return (
    <>
      <CardComponent>
        <OverlayLoaderProvider>
          <Row className="justify-content-between">
            <div className="cr-form-row w-auto">
              <div className="search-field">
                <div className="input-group">
                  <input
                    ref={searchRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onSearch();
                      }
                    }}
                    placeholder={`${t('search')}...`}
                    className="form-control"
                    style={{ borderRight: 'none' }}
                  />
                  <span className="input-group-append bg-white border-left-0">
                    <span className="input-group-text ">
                      <Search className="cursor-pointer" onClick={onSearch} width={16} height={30} />
                    </span>
                  </span>
                </div>
              </div>
              <div className="search-field">
                <RoleAccess allowedRoles={[UserSubRole.SUPERVISOR]} roleType="secondary">
                  <Form.Select onChange={handleConsultantChange} value={consultantFilter}>
                    <option value={''}>{t('all')}</option>
                    {consultantList.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.fullName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </RoleAccess>
              </div>
              <RoleAccess allowedRoles={[UserSubRole.SUPERVISOR, UserSubRole.SENIOR_CONSULTANT]} roleType="secondary">
                <Button
                  className={`app-btn-white ${!isError ? 'app-btn-secondary' : 'selected-button'}`}
                  onClick={handleErrorBtn}
                >
                  {t('with_errors')}
                </Button>
              </RoleAccess>
              <RoleAccess allowedRoles={[UserSubRole.SUPERVISOR, UserSubRole.SENIOR_CONSULTANT]} roleType="secondary">
                <Button
                  className={`app-btn-white ${!isSetup ? 'app-btn-secondary' : 'selected-button'}`}
                  onClick={handleSetupBtn}
                >
                  {t('in_setup_stage')}
                </Button>
              </RoleAccess>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <BSRemoteTable
                data={companyData?.data ?? []}
                columns={companyClientColumn}
                noDataIndication={`${t('no_clients_found')}`}
                sizePerPage={companyData?.metaData['size']}
                onSizePerPageChange={(sizePerPage, page) => onPagination({ sizePerPage, page })}
                keyField="id"
                onRowClick={(row) => switchLoginToCompany(row)}
                onTableChange={(type, { sortOrder, sortField }) => {
                  if (type === 'sort') {
                    fetchClients({ sortField, sortOrder: sortOrder === 'asc' ? '1' : '-1' });
                  }
                }}
                rowClasses={isActiveClientRow}
                bodyClasses={'clickable-row--disabled'}
              />
            )}
          </Row>
          {totalPages > 1 && (
            <Pagination
              total={companyData?.metaData.total ?? 0}
              itemsPerPage={companyData?.metaData.page.size ?? 0}
              currentPage={companyData?.metaData.page.current ?? 0}
              onPageChange={(page) => onPagination({ page, sizePerPage: companyData?.metaData.page.size })}
            />
          )}
        </OverlayLoaderProvider>
      </CardComponent>
    </>
  );
}
