import { createContext, useState } from 'react';
import { INITIAL_EMPLOYEE_DETAILS, INITIAL_VACATION_DETAIL } from '../../../../section/constant';
import {
  CompanyEmployeeType,
  EmployeeVacationType,
  ParentalInfo,
} from '../../../../api/service/company/employees/types';
import { useFormik } from 'formik';
import { formSchema } from '../schema';
import { FormikProps } from 'formik';
import {
  EmployeeArchivedPension,
  EmployeePensionSummary,
  PensionPolicyCategory,
  PensionPolicyVariant,
  PolicySettings,
  PreExistEmployeePolicy,
} from '../../../superAdmin/PensionPolicy/types';
import { DEFAULT_POLICY_CATEGORY } from '../../../superAdmin/PensionPolicy/constants';
import { PensionTabKey, EmployeeActionLoading } from '../types';

type TypePolicies = {
  id: string;
  type: string;
  attachedAt: string;
};
export interface LooseObject {
  [key: string]: any;
}
export type TypeSalaryChange = {
  fixedOffset?: number;
  effectiveFrom: string;
  history?: Array<any>;
};

type TypePensionCompanies = {
  id: string;
  shortName: string;
  companyName: string;
  activatedAt: string;
};
export type FormProps = {
  context: IEmployeePensionContext;
  date?: DateType;
  formGroup: EmployeeFormGroup;
};
export type DateType = {
  createdAt: string;
  updatedAt: string;
};
export interface EmployeePolicyRevision {
  currentPolicy: PreExistEmployeePolicy;
  policySettings: PolicySettings | null;
  metaDetails: any;
}

export interface EmployeeRevisionCategory {
  personal: any;
  admin: EmployeePolicyRevision[];
}

export type EmployeeFormGroup = FormikProps<CompanyEmployeeType>;

export interface IEmployeePensionContext {
  effectiveFrom: string;
  pensionTabKey: string;
  policies: TypePolicies;
  salaryChange: TypeSalaryChange;
  actionLoading: EmployeeActionLoading;
  archivedPensionList: EmployeeArchivedPension[];
  selectedArchivedPension: EmployeeArchivedPension | null;
  pensionCompanies: TypePensionCompanies;
  employee: CompanyEmployeeType;
  employeeRecord: CompanyEmployeeType | null;
  formGroup: EmployeeFormGroup;
  selectedPolicyType: PensionPolicyCategory | null;
  employeePolicyRevision: any;
  selectedEmployeePension: any;
  selectedScheduledEmployeePension: any;
  selectedPensionPolicy: any;
  selectedScheduledPensionPolicy: any;
  policyRevisionList: any;
  employeePensionSummary: EmployeePensionSummary | null;
  advisoryInvite: boolean;
  vacation: EmployeeVacationType;
  isReverting: boolean;
  setIsReverting: React.Dispatch<React.SetStateAction<boolean>>;
  parentalInfo: ParentalInfo[];
  setEmployeeRecord: React.Dispatch<React.SetStateAction<CompanyEmployeeType | null>>;
  setParentalInfo: React.Dispatch<React.SetStateAction<ParentalInfo[]>>;
  setActionLoading: React.Dispatch<React.SetStateAction<EmployeeActionLoading>>;
  setArchivedPensionList: React.Dispatch<React.SetStateAction<EmployeeArchivedPension[]>>;
  setSelectedArchivedPension: React.Dispatch<React.SetStateAction<EmployeeArchivedPension | null>>;
  setEmployeePensionSummary: React.Dispatch<React.SetStateAction<EmployeePensionSummary | null>>;
  setSelectedEmployeePension: React.Dispatch<React.SetStateAction<any>>;
  setSelectedScheduledEmployeePension: React.Dispatch<React.SetStateAction<any>>;
  setEmployeePolicyRevision: React.Dispatch<React.SetStateAction<any>>;
  setPolicyRevisionList: React.Dispatch<React.SetStateAction<EmployeeRevisionCategory>>;
  setSelectedPensionPolicy: React.Dispatch<React.SetStateAction<any>>;
  setSelectedScheduledPensionPolicy: React.Dispatch<React.SetStateAction<any>>;
  setEffectiveFrom: React.Dispatch<React.SetStateAction<string>>;
  setPensionTabKey: React.Dispatch<React.SetStateAction<PensionTabKey>>;
  setpolicies: React.Dispatch<React.SetStateAction<TypePolicies>>;
  setsalaryChange: React.Dispatch<React.SetStateAction<TypeSalaryChange>>;
  setpensionCompanies: React.Dispatch<React.SetStateAction<TypePensionCompanies>>;
  setEmployee: React.Dispatch<React.SetStateAction<CompanyEmployeeType>>;
  setSelectedPolicyType: React.Dispatch<React.SetStateAction<PensionPolicyCategory | null>>;
  updateEmployeeDetails: (inputName: string, inputValue: any) => void;
  replaceEmployeeDetails: (value: any) => void;
  updatePolicyType: (value: any) => void;
  findPolicyType: (value: PensionPolicyVariant | null) => PensionPolicyCategory | null;
  setAdvisoryInvite: React.Dispatch<React.SetStateAction<boolean>>;
  updateVacationDetails: (inputName: string, inputValue: any) => void;
  replaceVacationDetails: (value: any) => void;
  updateParentalLeave: (inputName: string, inputValue: any) => void;
  replaceParentalLeave: (value: any) => void;
}

export const INITIAL_NO_POLICY_VALUE = { id: '', type: '', attachedAt: '' };
export const INITIAL_SALARY_CHANGE_VALUE = { effectiveFrom: '' };
export const INITIAL_PENSION_COMPANY_VALUE = { activatedAt: '', companyName: '', id: '', shortName: '' };

type Props = {
  children: JSX.Element;
};

export const EmployeePensionContext = createContext<IEmployeePensionContext>({
  effectiveFrom: '',
  pensionTabKey: PensionTabKey.CURRENT,
  policies: INITIAL_NO_POLICY_VALUE,
  salaryChange: INITIAL_SALARY_CHANGE_VALUE,
  pensionCompanies: INITIAL_PENSION_COMPANY_VALUE,
  employee: INITIAL_EMPLOYEE_DETAILS,
  formGroup: {} as EmployeeFormGroup,
  selectedPolicyType: DEFAULT_POLICY_CATEGORY,
  employeePolicyRevision: null,
  selectedEmployeePension: null,
  selectedScheduledEmployeePension: null,
  selectedPensionPolicy: null,
  actionLoading: {
    cancelSchedule: false,
    loadEmployeePension: false,
    loadAchivedPension: false,
  },
  archivedPensionList: [],
  selectedArchivedPension: null,

  selectedScheduledPensionPolicy: null,
  employeePensionSummary: null,
  policyRevisionList: {
    personal: null,
    admin: [],
  },
  advisoryInvite: false,
  vacation: INITIAL_VACATION_DETAIL,
  parentalInfo: [],
  isReverting: false,
  employeeRecord: null,
  setEmployeeRecord: () => {},
  setIsReverting: () => {},
  setArchivedPensionList: () => {},
  setSelectedArchivedPension: () => {},
  setActionLoading: () => {},
  setEmployeePensionSummary: () => {},
  setEmployeePolicyRevision: () => {},
  setPolicyRevisionList: () => {},
  setSelectedEmployeePension: () => {},
  setSelectedScheduledEmployeePension: () => {},
  setSelectedPensionPolicy: () => {},
  setSelectedScheduledPensionPolicy: () => {},
  setEffectiveFrom: () => {},
  setPensionTabKey: () => {},
  setpolicies: () => {},
  setsalaryChange: () => {},
  setpensionCompanies: () => {},
  setEmployee: () => {},
  setSelectedPolicyType: () => {},
  updateEmployeeDetails: () => {},
  replaceEmployeeDetails: () => {},
  updatePolicyType: (type) => {},
  findPolicyType: (type) => DEFAULT_POLICY_CATEGORY,
  setAdvisoryInvite: () => {},
  updateVacationDetails: () => {},
  replaceVacationDetails: () => {},
  setParentalInfo: () => {},
  updateParentalLeave: () => {},
  replaceParentalLeave: () => {},
});

export default function EmployeePensionProvider({ children }: Props) {
  const formGroup = useFormik({
    initialValues: INITIAL_EMPLOYEE_DETAILS,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });
  const [effectiveFrom, setEffectiveFrom] = useState('');
  const [pensionTabKey, setPensionTabKey] = useState<PensionTabKey>(PensionTabKey.CURRENT);
  const [policies, setpolicies] = useState(INITIAL_NO_POLICY_VALUE);
  const [salaryChange, setsalaryChange] = useState(INITIAL_SALARY_CHANGE_VALUE);
  const [pensionCompanies, setpensionCompanies] = useState(INITIAL_PENSION_COMPANY_VALUE);
  const [employee, setEmployee] = useState<CompanyEmployeeType>(INITIAL_EMPLOYEE_DETAILS);
  const [employeeRecord, setEmployeeRecord] = useState<CompanyEmployeeType | null>(null);
  const [selectedPolicyType, setSelectedPolicyType] = useState<PensionPolicyCategory | null>(null);
  const [employeePolicyRevision, setEmployeePolicyRevision] = useState<any>(null);
  const [policyRevisionList, setPolicyRevisionList] = useState<EmployeeRevisionCategory>({
    personal: null,
    admin: [],
  });
  const [selectedScheduledEmployeePension, setSelectedScheduledEmployeePension] = useState<any>(null);
  const [selectedEmployeePension, setSelectedEmployeePension] = useState<any>(null);
  const [selectedPensionPolicy, setSelectedPensionPolicy] = useState<any>(null);
  const [selectedScheduledPensionPolicy, setSelectedScheduledPensionPolicy] = useState<any>(null);
  const [employeePensionSummary, setEmployeePensionSummary] = useState<EmployeePensionSummary | null>(null);
  const [advisoryInvite, setAdvisoryInvite] = useState<boolean>(false);
  const [vacation, setVacation] = useState(INITIAL_VACATION_DETAIL);
  const [actionLoading, setActionLoading] = useState<EmployeeActionLoading>({
    cancelSchedule: false,
    loadEmployeePension: false,
    loadAchivedPension: false,
  });
  const [archivedPensionList, setArchivedPensionList] = useState<EmployeeArchivedPension[]>([]);
  const [selectedArchivedPension, setSelectedArchivedPension] = useState<EmployeeArchivedPension | null>(null);
  const [parentalInfo, setParentalInfo] = useState<ParentalInfo[]>([]);
  const [isReverting, setIsReverting] = useState(false);

  const updateEmployeeDetails = (inputName, inputValue) => {
    setEmployee((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`${inputName}`, inputValue);
  };

  const replaceEmployeeDetails = (inputValue) => {
    setEmployee(inputValue);
    formGroup.setValues(inputValue);
  };

  const updateVacationDetails = (inputName, inputValue) => {
    setVacation((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    setEmployee((value) => ({
      ...value,
      vacation: {
        ...(value.vacation || null),
        [inputName]: inputValue,
      },
    }));
    formGroup.setFieldValue(`vacation.${inputName}`, inputValue);
  };

  const replaceVacationDetails = (inputValue) => {
    setVacation(inputValue);
    formGroup.setFieldValue('vacation', inputValue);
  };

  const updatePolicyType = (policyType) => {
    setSelectedPolicyType(policyType);
  };

  const findPolicyType = (val: PensionPolicyVariant | null) => {
    if (!val) {
      return null;
    }

    if (val === PensionPolicyVariant.COMPANY) {
      return PensionPolicyCategory.COMPANY_POLICY;
    } else if (val === PensionPolicyVariant.CORRECTO_PROVIDED) {
      return PensionPolicyCategory.CORRECTO_PROVIDED_POLICY;
    } else {
      return PensionPolicyCategory.PERSONAL_POLICY;
    }
  };

  const updateParentalLeave = (inputName, inputValue) => {
    setParentalInfo((value) => ({
      ...value,
      [inputName]: inputValue,
    }));

    //formGroup.setFieldValue(`parentalInfo.${inputName}`, inputValue);
  };

  const replaceParentalLeave = (inputValue) => {
    if (!Array.isArray(inputValue)) {
      return;
    }

    setParentalInfo(inputValue);
    formGroup.setFieldValue(`parentalInfo`, inputValue);
  };

  const context: IEmployeePensionContext = {
    effectiveFrom,
    policies,
    salaryChange,
    pensionCompanies,
    employee,
    employeeRecord,
    formGroup,
    selectedPolicyType,
    employeePolicyRevision,
    selectedEmployeePension,
    selectedScheduledEmployeePension,
    selectedPensionPolicy,
    policyRevisionList,
    advisoryInvite,
    vacation,
    selectedScheduledPensionPolicy,
    pensionTabKey,
    employeePensionSummary,
    actionLoading,
    selectedArchivedPension,
    archivedPensionList,
    parentalInfo,
    isReverting,
    setIsReverting,
    setParentalInfo,
    setArchivedPensionList,
    setSelectedArchivedPension,
    setActionLoading,
    setPensionTabKey,
    setEffectiveFrom,
    setpolicies,
    setsalaryChange,
    setpensionCompanies,
    setEmployee,
    setEmployeeRecord,
    setSelectedPolicyType,
    updateEmployeeDetails,
    replaceEmployeeDetails,
    updatePolicyType,
    findPolicyType,
    setEmployeePolicyRevision,
    setPolicyRevisionList,
    setSelectedEmployeePension,
    setSelectedScheduledEmployeePension,
    setSelectedPensionPolicy,
    setSelectedScheduledPensionPolicy,
    setAdvisoryInvite,
    updateVacationDetails,
    replaceVacationDetails,
    setEmployeePensionSummary,
    updateParentalLeave,
    replaceParentalLeave,
  };
  return <EmployeePensionContext.Provider value={context}>{children}</EmployeePensionContext.Provider>;
}
