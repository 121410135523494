import { Dispatch, useState, SetStateAction } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Plus, Save } from 'react-feather';
import { LooseObject, PensionManagerSaveMode } from '../../types';
import { createPensionCompany } from '../../../../../api/service/superAdmin/settings/pension-companies';
import useContext from '../context/useContext';
import { Mode } from '../../types';
import { useSuperAdminSettingsContext } from '../../useContext';
import useLocale from '../../../../../hooks/useLocale';
import { isObjectEmpty } from '../../../../../util/util';

type Props = {
  toggleModal: () => void;
  setIsPensionCompanyCreated: Dispatch<SetStateAction<boolean>>;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
};

export const CreatePensionCompanyModalFooter = ({ toggleModal, setErrors }: Props) => {
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const [, /*isSaving*/ setIsSaving] = useState(false);
  const { localize } = useLocale();
  const { formGroup, validateDraftForm, validateForm, getRequestPayload } = useContext();
  const { setMode } = useSuperAdminSettingsContext();

  const handleSaveCompany = async (saveMode) => {
    const isDraftMode = saveMode === PensionManagerSaveMode.DRAFT;
    const payload = getRequestPayload({ status: saveMode });

    if (isDraftMode) {
      const formValidator = await validateDraftForm();

      if (!formValidator.valid) {
        setErrors({ error: `${localize('please_fill_in_the_required_fields')}` });

        return false;
      }
    } else {
      validateForm().then((errors) => {
        if (!isObjectEmpty(errors)) {
          setErrors({ error: `${localize('please_fill_in_the_required_fields')}` });
        }
      });

      if (!formGroup.isValid) {
        setIsBusy(false);
        setIsBusyDrafting(false);

        return false;
      }
    }

    try {
      if (isDraftMode) {
        setIsBusyDrafting(true);
      } else {
        setIsBusy(true);
      }

      await createPensionCompany(payload);
      toggleModal();
      setMode(Mode.List);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsSaving(false);
      setIsBusyDrafting(false);
      setIsBusy(false);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <Button className="app-btn-secondary cancel-btn" onClick={toggleModal}>
          {localize('cancel')}
        </Button>

        <div className="d-flex gap-3">
          <Button
            disabled={isBusy || isBusyDrafting}
            type="button"
            className="app-btn-primary"
            onClick={() => handleSaveCompany(PensionManagerSaveMode.DRAFT)}
          >
            {isBusyDrafting ? <Spinner className="icon-gap-right" size="sm" /> : <Save className="icon-gap-right" />}
            {`${localize('save')} ${localize('as')} ${localize('draft')}`}
          </Button>
          <Button
            disabled={isBusy || isBusyDrafting}
            type="button"
            className="app-btn-primary"
            onClick={() => handleSaveCompany(PensionManagerSaveMode.ACTIVE)}
          >
            {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <Plus className="icon-gap-right" />}
            {`${localize('add_pension_manager')}`}
          </Button>
        </div>
      </div>
    </>
  );
};
