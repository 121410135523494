import { useState } from 'react';

export const useChartTypeModal = () => {
  const [showReportTypeModal, setShowReportTypeModal] = useState(false);
  const addReportSeriesHandler = () => {
    setShowReportTypeModal(!showReportTypeModal);
  };
  const toggleReportModal = () => {
    setShowReportTypeModal(!showReportTypeModal);
  };
  return {
    showReportTypeModal,
    addReportSeriesHandler,
    toggleReportModal,
  };
};
