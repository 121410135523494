import { useEffect, useState } from 'react';

import useContext from '../PensionPolicyContext/useContext';
import { isEqual } from '../../../../util/util';
import { PensionFormBuilder } from '../entity/PensionFormBuilder';
import { AuthUser, UserRole } from '../../../../types/auth';
import { INITIAL_VALUE_PENSION_POLICY } from '../constants';

const usePensionPolicyObserver = () => {
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const isSuperAdminRole = user?.role?.role === UserRole.SUPER_ADMIN ? true : false;

  const [isFormModified, setIsFormModified] = useState(false);

  const { formGroup, selectedPolicyDetails } = useContext();

  const getInitialFormValue = () => {
    const _selectedPolicy = PensionFormBuilder.fromFormModel(selectedPolicyDetails);

    return _selectedPolicy;
  };

  const getUpdatedFormValue = () => {
    const _currentPolicy = PensionFormBuilder.fromFormModel(formGroup.values);

    return _currentPolicy;
  };

  const revertChanges = () => {
    formGroup.setValues({
      ...INITIAL_VALUE_PENSION_POLICY,
      isCollectiveAgreementLock: isSuperAdminRole,
    } as any);
  };

  useEffect(() => {
    const initialFormValue = getInitialFormValue();
    const updatedFormValue = getUpdatedFormValue();

    if (isEqual(initialFormValue, updatedFormValue)) {
      setIsFormModified(false);
    } else {
      setIsFormModified(true);
    }
  }, [formGroup.values, selectedPolicyDetails]);

  return {
    isFormModified,
    revertChanges,
  };
};

export default usePensionPolicyObserver;
