import BSTable from '../../../../components/table/BSTable';
import { Button, Dropdown, Row, Spinner, Form } from 'react-bootstrap';
import { Menu } from 'react-feather';
import { getCompanyBaseSalaryReport } from '../../../../api/service/company/reports/service';
import PagesHeader from '../../../../components/base/PagesHeader';
import useAllEmployeeYearlySalaryReport from '../hooks/useAllEmployeeYearlySalaryReport';
import { Calendar } from '../../../../util/calendar';
import { REPORT_EXPORT_OPTIONS } from '../constants';
import { useTranslation } from 'react-i18next';

export const BaseSalaryReport = () => {
  const { reportHeaders, reportRecords, isLoading, years, setYearFilter, exportCSV, isExporting } =
    useAllEmployeeYearlySalaryReport(getCompanyBaseSalaryReport, {
      fieldKey: 'salary',
      export: { requestName: 'base-salaries' },
    });
  const { t } = useTranslation();

  return (
    <>
      <Row className="justify-content-between align-items-end">
        <div className="mt-5">
          <PagesHeader pageTitle="All Employees" headingClass="fs-page-title" />
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Form.Group className="me-3">
            <Form.Label className="fs-name">{t('year_period')}</Form.Label>
            <Form.Select
              name="year"
              className="text-left"
              onChange={(e) => {
                setYearFilter(e.target.value);
              }}
            >
              {years.map((year) => (
                <option value={year} key={year}>
                  {year} {Calendar.isCurrentYear(year) ? 'YTD' : ''}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          {isExporting ? (
            <Spinner />
          ) : (
            <Dropdown className="nav-item" align="end">
              <Dropdown.Toggle as="a" className="nav-link nav-flag" style={{ padding: 0 }}>
                <Button className="app-btn-secondary">
                  <Menu className="theme-text" />
                </Button>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {REPORT_EXPORT_OPTIONS.map((report) => (
                  <Dropdown.Item onClick={() => exportCSV(report.value)} key={report.value}>
                    {report.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="crt-table-report">
            <BSTable data={reportRecords} columns={reportHeaders} noDataIndication={`${t('no_reports_found')}`} />
          </div>
        )}
      </Row>
    </>
  );
};
