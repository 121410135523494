import { Row, Form, Col } from 'react-bootstrap';
import PagesHeader from '../../../../../components/base/PagesHeader';
import HorizontalRule from '../../../../../components/base/HorizontalRule';
import { InsuranceFormProps, Mode, insurancePlanList } from '../../types';
import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/settings/insurance.module.scss';
import useLocale from '../../../../../hooks/useLocale';
import { Plans } from './Plans';
import classNames from 'classnames';

export const InsurancePlan = ({ context, mode }: InsuranceFormProps) => {
  const { updateInsurancePlan, formGroup } = context;
  const { localize } = useLocale();
  const insurancePlans = formGroup.values?.insurancePlans;
  const isEditable = [Mode.Edit, Mode.Add].indexOf(mode) !== -1;
  const handleInsurance = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    updateInsurancePlan(name, value);
  };

  return (
    <>
      <PagesHeader headingClass="fs-h3" pageTitle="Insurances" />
      <Row className="mb-4">
        <Col md="12">
          <Form.Label className="fs-name ">
            {localize('provider')} {localize('description')}
          </Form.Label>
          <Form.Control
            as="textarea"
            style={{ height: 80 }}
            value={formGroup.values?.insurancePlans.providerDescription}
            onChange={handleInsurance}
            name="providerDescription"
            disabled={mode === Mode.Detail}
          />
        </Col>
      </Row>
      <PagesHeader headingClass="fs-h5" pageTitle="Plans" />
      <HorizontalRule height="2px" />

      <div className={classNames(themeStyle.pm_setting_grid)}>
        {insurancePlanList.map((plan, index) => (
          <Plans
            key={index}
            context={context}
            mode={mode}
            insurancePlans={insurancePlans}
            isEditable={isEditable}
            insuranceType={plan}
          />
        ))}
      </div>
    </>
  );
};
