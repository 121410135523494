import { Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';
export enum Mode {
  List,
  Detail,
  Add,
  Edit,
}

export type ModeType = Mode;
export interface IAdminDetails {
  isNew: boolean;
  hasRecordExist?: boolean;
  id?: string;
  personalNumber: string;
  fullName: string;
  firstName?: string;
  lastName?: string;
  email: string;
  isActive: boolean;
}
export interface IAdmin {
  id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
}
export interface LooseObject {
  [key: string]: any;
}
export interface IAdminSettingsContext {
  isBusy: boolean;
  adminDetails: IAdminDetails[];
  setAdminDetails: Dispatch<SetStateAction<Array<IAdminDetails>>>;
  advisors: IAdvisors[];
  setAdvisors: Dispatch<SetStateAction<Array<IAdvisors>>>;
  formGroup: AdminSettingsFormGroup;
  replaceAdminDetails: (value: any) => void;
  replaceInsuranceAdvisors: (value: any) => void;
}

export type AdminSettingsForm = {
  admins: IAdminDetails[];
  advisors: IAdvisors[];
};

export type AdminSettingsFormGroup = FormikProps<AdminSettingsForm>;

export type FormProps = {
  context: IAdminSettingsContext;
  formGroup: AdminSettingsFormGroup;
};

export type AdminSettingsFormData = {
  data: any;
  form: AdminSettingsFormGroup;
};
export interface ISuperAdminSettingsContext {
  mode: ModeType;
  setMode: Dispatch<SetStateAction<ModeType>>;
  accountingMode: ModeType;
  setAccountingMode: Dispatch<SetStateAction<ModeType>>;
  adminMode: ModeType;
  setAdminMode: Dispatch<SetStateAction<ModeType>>;
}
export interface TypeContactInformation {
  address: string;
  city: string;
  phone: string;
  fax: string;
  postalCode: string;
  email: string;
}
export interface TypeIntegration {
  name: string;
  key: string;
}
export interface TypePensionCompany {
  shortName: string;
  companyName: string;
  clientNumber: string;
  contractNumber: string;
  description: string;
  organizationNumber: string;
  vatNumber: string;
  bankgiroNumber: string;
  plusgiroNumber: string;
  status: string;
  collectiveAgreement: boolean;
  // pensionType: Array<string>;
  // integrationType: Array<TypeIntegration>;
  // apiKey: string;
  id?: string;
}
export interface ConnectionAPIType {
  isChecked: boolean;
  apiKey: string;
  serviceKey: string;
}
export interface ConnectionFileType {
  isChecked: boolean;
  email: string;
  type: string;
}
export interface TypeConnection {
  connectionTypeAPI: ConnectionAPIType;
  connectionTypeFile: ConnectionFileType;
}

export interface TypeDocuments {
  text: string;
  documentLink: string;
}

export type TypePlans = {
  id?: string;
  planName: string;
  providerDescription: string;
  order: number;
  documents: TypeDocuments[];
  costSpecification: CostSpecification;
};

export type CostSpecification = {
  specItems: Array<CostSpecItem>;
  description: string;
};

export type CostSpecItem = {
  title: string;
  fee: number | null;
  exemptionLetter: number | null;
};

export interface TypePensionManagerPlan {
  providerDescription: string;
  correctoPartner: boolean;
  plans: TypePlans[];
}

export interface IPensionCompaniesContext {
  isBusy: boolean;
  pensionCompany: TypePensionCompany;
  setPensionCompany: Dispatch<SetStateAction<TypePensionCompany>>;
  contactInformation: TypeContactInformation;
  setContactInformation: Dispatch<SetStateAction<TypeContactInformation>>;
  pensionCompanies: Array<TypePensionCompany>;
  connection: TypeConnection;
  setConnection: Dispatch<SetStateAction<TypeConnection>>;
  pensionManagerPlans: TypePensionManagerPlan;
  setPensionManagerPlans: Dispatch<SetStateAction<TypePensionManagerPlan>>;
  formGroup: PensionCompaniesFormGroup;
  selectedPensionID: string | null;
  selectedPensionManagerDetails: any;
  setSelectedPensionManagerDetails: Dispatch<SetStateAction<any>>;
  setSelectedPensionID: Dispatch<SetStateAction<string | null>>;
  updateContactInformation: (inputName: string, inputValue: any) => void;
  replacePensionCompanies: (value: any) => void;
  replacePensionCompany: (value: any) => void;
  replaceContactInformation: (value: any) => void;
  replaceConnection: (value: any) => void;
  replacePensionManagerPlan: (value: any) => void;
  updatePensionCompany: (inputValue: any, inputName: any) => void;
  updateConnection: (inputValue: any, inputName: any) => void;
  updatePensionManagerPlan: (inputValue: any, inputName: any) => void;
  replacePensionManager: (value: any) => void;
  validateDraftForm: () => Promise<{ valid: boolean; errors: any }>;
  validateForm: () => Promise<any>;
  getRequestPayload: (additionalProps: any) => any;
  replacePensionManagerDocuments: (value: any, index: any) => void;
  addPlan: (defaultVal?: Partial<TypePlans> | null) => void;
  initializeDefaultPlans: () => void;
}
export interface IPensionCompanies {
  pensionCompanies: Array<TypePensionCompany>;
  pensionCompany: TypePensionCompany;
  contactInformation: TypeContactInformation;
  connection: TypeConnection;
  pensionManagerPlans: TypePensionManagerPlan;
}
export type PensionCompaniesFormGroup = FormikProps<IPensionCompanies>;

export type PensionCompanyFormProps = {
  context: IPensionCompaniesContext;
  // formGroup: IPensionCompaniesContext;
  OnupdatePensionCompany(args): void;
  mode: ModeType;
};
// Accounting Salary

export interface TypeAccountingSalaryCompany {
  shortName: string;
  companyName: string;
  clientNumber: string;
  contractNumber: string;
  description: string;
  organizationNumber: string;
  vatNumber: string;
  bankgiroNumber: string;
  plusgiroNumber: string;
  // pensionType: Array<string>;
  integrationType: Array<TypeIntegration>;
  apiKey: string;
  id: string;
}
export interface IAccountingSalaryCompanyContext {
  isBusy: boolean;
  accountingSalaryCompany: TypeAccountingSalaryCompany;
  setAccountingSalaryCompany: Dispatch<SetStateAction<TypeAccountingSalaryCompany>>;
  contactInformation: TypeContactInformation;
  setContactInformation: Dispatch<SetStateAction<TypeContactInformation>>;
  accountingSalaryCompanies: Array<TypeAccountingSalaryCompany>;
  formGroup: AccountingSalaryCompanyFormGroup;
  updateContactInformation: (inputName: string, inputValue: any) => void;
  replaceAccountingSalaryCompanies: (value: any) => void;
  replaceAccountingSalaryCompany: (value: any) => void;
  replaceContactInformation: (value: any) => void;
  updateAccountingSalaryCompany: (inputValue: any, inputName: any) => void;
}
export interface IAccountingSalaryCompany {
  accountingSalaryCompanies: Array<TypeAccountingSalaryCompany>;
  accountingSalaryCompany: TypeAccountingSalaryCompany;
  contactInformation: TypeContactInformation;
}
export type AccountingSalaryCompanyFormGroup = FormikProps<IAccountingSalaryCompany>;

export type AccountingSalaryCompanyFormProps = {
  context: IAccountingSalaryCompanyContext;
};

export enum PensionManagerSaveMode {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SETUP = 'Setup',
}

export interface IPensionManager {
  id?: string;
  shortName: string;
  companyName: string;
  clientNumber: string;
  contractNumber: string;
  description: string;
  organizationNumber: string;
  vatNumber: string;
  bankgiroNumber: string;
  plusgiroNumber: string;
  status: string;
  contactInformation: TypeContactInformation;
  connection: TypeConnection;
  pensionManagerPlans: TypePensionManagerPlan;
  order?: number;
}

export type IPensionManagerFormGroup = FormikProps<IPensionCompanies>;

/*Insurance*/

export interface TypeInsurance {
  shortName: string;
  companyName: string;
  clientNumber: string;
  contractNumber: string;
  description: string;
  organizationNumber: string;
  vatNumber: string;
  bankgiroNumber: string;
  plusgiroNumber: string;
  status: string;
  collectiveAgreement: boolean;
  // pensionType: Array<string>;
  // integrationType: Array<TypeIntegration>;
  // apiKey: string;
  id?: string;
}
export type AgePlan = {
  to: string;
  from: string;
};

export type InsurancePlan = {
  id?: string;
  insuranceName: string;
  insuranceDescription: string;
  specialCondition: string;
  hjertaInfinity: string;
  ageSpan: AgePlan;
  documents: TypeDocuments[];
  order: number;
  pay?: string;
  costSpecification: CostSpecification;
};

// TypeInsurancePlan
export type TypeInsurances = {
  providerDescription: string;
  healthInsurancePlan: InsurancePlan[];
  otherInsurancesPlan: InsurancePlan[];
  occupationalPensionInsurancePlan: InsurancePlan[];
  individualInsurancesPlan: InsurancePlan[];
  groupInsurancePlan: InsurancePlan[];
};

export interface IInsurancesContext {
  isBusy: boolean;
  insuranceCompany: TypeInsurance;
  setInsuranceCompany: Dispatch<SetStateAction<TypeInsurance>>;
  contactInformation: TypeContactInformation;
  setContactInformation: Dispatch<SetStateAction<TypeContactInformation>>;
  insurances: Array<IInsurances>;
  connection: TypeConnection;
  setConnection: Dispatch<SetStateAction<TypeConnection>>;
  insurancePlans: TypeInsurances;
  setInsurancePlans: Dispatch<SetStateAction<TypeInsurances>>;
  formGroup: InsurancesFormGroup;
  selectedInsuranceID: string | null;
  setSelectedInsuranceID: Dispatch<SetStateAction<string | null>>;
  selectedInsuranceDetails: any | null;
  setSelectedInsuranceDetails: Dispatch<SetStateAction<any | null>>;
  updateContactInformation: (inputName: string, inputValue: any) => void;
  replaceInsurances: (value: any) => void;
  replaceInsuranceCompany: (value: any) => void;
  replaceContactInformation: (value: any) => void;
  replaceConnection: (value: any) => void;
  replaceInsurancePlan: (value: any, planName: any) => void;
  updateInsuranceCompany: (inputValue: any, inputName: any) => void;
  updateConnection: (inputValue: any, inputName: any) => void;
  updateInsurancePlan: (inputValue: any, inputName: any) => void;
  //replacePensionManager: (value: any) => void;
  validateDraftForm: () => Promise<{ valid: boolean; errors: any }>;
  validateForm: () => Promise<any>;
  getRequestPayload: (additionalProps: any) => any;
  replaceInsuranceDocuments: (value: any, planName: any, index: any) => void;
  updateInsuranceAgeSpan: (inputName: any, inputValue: any, planName: any, index: any) => void;
}

export interface IInsurances {
  order?: number;
  id?: string;
  insurancePlans: TypeInsurances;
  insuranceCompany: TypePensionCompany;
  contactInformation: TypeContactInformation;
  connection: TypeConnection;
  insurances: Array<TypePensionCompany>;
}

export type InsurancesFormGroup = FormikProps<IInsurances>;

export type InsuranceFormProps = {
  context: IInsurancesContext;
  // formGroup: IPensionCompaniesContext;
  // OnupdateInsurance(args): void;
  mode: ModeType;
};

export enum InsuranceSaveMode {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SETUP = 'Setup',
}

export interface IInsurance {
  id?: string;
  shortName: string;
  companyName: string;
  clientNumber: string;
  contractNumber: string;
  description: string;
  organizationNumber: string;
  vatNumber: string;
  bankgiroNumber: string;
  plusgiroNumber: string;
  status: string;
  contactInformation: TypeContactInformation;
  connection: TypeConnection;
  insurances: TypeInsurances;
  order?: number;
}

export type IInsuranceFormGroup = FormikProps<IInsurances>;

export type TypePlanItem = {
  value: string;
  label: string;
};

export const insurancePlanList = [
  { value: 'healthInsurancePlan', label: 'health_insurance' },
  { value: 'occupationalPensionInsurancePlan', label: 'occupational_pension_insurance' },
  { value: 'otherInsurancesPlan', label: 'other_insurance' },
  { value: 'individualInsurancesPlan', label: 'individual_insurances' },
  { value: 'groupInsurancePlan', label: 'group_insurances' },
];

export type InsurancePlanKey =
  | 'healthInsurancePlan'
  | 'otherInsurancesPlan'
  | 'occupationalPensionInsurancePlan'
  | 'individualInsurancesPlan'
  | 'groupInsurancePlan';

export enum TabType {
  PENSION_MANAGER = 'pension_managers',
  INSURANCE = 'insurances',
}

/*Integrations*/
export interface TypeIntegrationInformation {
  shortName: string;
  companyName: string;
  status: string;
  instruction: string;
}
export interface ITypeOfIntegration {
  companyApi: boolean;
  channel: string;
  apiKey: string;
  apiKey2: string;
}
export interface IIntegrations {
  order: number;
  id?: string;
  integrationInformation: TypeIntegrationInformation;
  typeOfIntegration: ITypeOfIntegration;
}
export type IntegrationFormGroup = FormikProps<IIntegrations>;

export type IntegrationsFormProps = {
  context: IIntegrationContext;
  mode: ModeType;
};
export interface IIntegrationContext {
  isBusy: boolean;
  integrations: Array<IIntegrations>;
  setIntegrations: Dispatch<SetStateAction<Array<IIntegrations>>>;
  formGroup: IntegrationFormGroup;
  selectedIntegrationID: null | string;
  integrationSaveMode: string;
  setSelectedIntegrationID: Dispatch<SetStateAction<string | null>>;
  updateIntegrationInformation: (inputName: string, inputValue: any) => void;
  updateTypeOfIntegration: (inputName: string, inputValue: any) => void;
  replaceIntegrations: (value: any) => void;
  replaceIntegrationInformation: (value: any) => void;
  replaceTypeOfIntegration: (value) => void;
  validateDraftForm: () => Promise<{ valid: boolean; errors: any }>;
  validateForm: () => Promise<any>;
  getRequestPayload: (additionalProps: any) => any;
  setIntegrationSaveMode: Dispatch<SetStateAction<string>>;
}

export enum IntegrationSaveMode {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SETUP = 'Setup',
}

export interface IntegrationEditor {
  integrations: IIntegrations[];
  isLoading: boolean;
  showIntegrationCreateModal: boolean;
  isBusy: boolean;
  isBusyDrafting: boolean;
  selectedIntegrationID: string | null;
  formGroup: IntegrationFormGroup;
  integrationSaveMode: string;
  errors: any;
  removeIntegration: (id: string, index) => void;
  fetchAll: () => void;
  toggleIntegrationModal: () => void;
  setIntegrationDetail: (id) => void;
  updateIntegrationDetail: (saveMode, id) => void;
}

export type IntegrationFormProps = {
  context: IIntegrationContext;
  mode: ModeType;
};

export interface GeneralSettingsEditor {
  isLoading: boolean;
  cancelChanges: () => void;
  saveChanges: () => void;
  hasChanges: () => void;
  handleCorrectoAdmin: (e, id) => void;
  handleInsuranceAdvisor: (e, id) => void;
  deleteCorrectoAdmin: (id) => void;
  deleteInsuranceAdvisor: (id) => void;
  addCorrectoAdmin: () => void;
  addInsuranceAdvisor: () => void;
  loadAdminDetails: (form) => void;
  loadAdvisorDetails: (form) => void;
}

export interface GeneralSettingsRef {
  isLoading: GeneralSettingsEditor['isLoading'];
  saveChanges: GeneralSettingsEditor['saveChanges'];
  cancelChanges: GeneralSettingsEditor['cancelChanges'];
  hasChanges: GeneralSettingsEditor['hasChanges'];
  handleCorrectoAdmin: GeneralSettingsEditor['handleCorrectoAdmin'];
  handleInsuranceAdvisor: GeneralSettingsEditor['handleInsuranceAdvisor'];
  deleteCorrectoAdmin: GeneralSettingsEditor['deleteCorrectoAdmin'];
  deleteInsuranceAdvisor: GeneralSettingsEditor['deleteInsuranceAdvisor'];
  addCorrectoAdmin: GeneralSettingsEditor['addCorrectoAdmin'];
  addInsuranceAdvisor: GeneralSettingsEditor['addInsuranceAdvisor'];
  loadAdminDetails: GeneralSettingsEditor['loadAdminDetails'];
  loadAdvisorDetails: GeneralSettingsEditor['loadAdvisorDetails'];
}

export interface IAdvisors {
  isNew?: boolean;
  hasRecordExist?: boolean;
  id?: string;
  personalNumber: string;
  fullName: string;
  firstName?: string;
  lastName?: string;
  email: string;
  isActive: boolean;
}

export interface IAdvisor {
  id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
  isNew?: boolean;
}
