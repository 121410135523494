import { closestCenter, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import PlanItem from './PlanItem';
import { IPensionCompaniesContext, Mode, TypePlans } from '../../types';

type Props = {
  managerPlans: TypePlans[];
  context: IPensionCompaniesContext;
  mode: Mode;
  isEditable: boolean;
};

const SortableContainer = ({ context, children }: { context: IPensionCompaniesContext; children: JSX.Element }) => {
  const { replacePensionManagerPlan, formGroup } = context;
  const managerPlans = formGroup.values?.pensionManagerPlans.plans || [];

  const onDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }

    const oldIndex = managerPlans.findIndex((item) => item.id === active.id);
    const newIndex = managerPlans.findIndex((item) => item.id === over.id);
    const sortedList = arrayMove(managerPlans, oldIndex, newIndex).map((item, index) => {
      item.order = index + 1;

      return item;
    });

    replacePensionManagerPlan(sortedList);
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <SortableContext items={managerPlans.map((item) => item.id!)} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export const PlanList = ({ managerPlans, context, mode, isEditable }: Props) => {
  return (
    <>
      {isEditable ? (
        <SortableContainer context={context}>
          <>
            {managerPlans.map((plan, index) => (
              <PlanItem key={plan.id} plan={plan} index={index} context={context} mode={mode} isEditable={isEditable} />
            ))}
          </>
        </SortableContainer>
      ) : (
        <>
          {managerPlans.map((plan, index) => (
            <PlanItem key={plan.id} plan={plan} index={index} context={context} mode={mode} isEditable={isEditable} />
          ))}
        </>
      )}
    </>
  );
};

export default PlanList;
