import { useEffect, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

import styles from './MonthlyStepper.module.scss';

import { getYearsFromMonth, parseYearMonth } from './utils';
import { IYearMonthlyAggregate, StepperAction } from './types';
import { STEPPER_CONFIG } from './config';

interface MonthlyStepperHeaderProps {
  selectedHeaderMonths: any[];
  yearRange: any[];
  handlePrevious: () => void;
  handleNext: () => void;
  shouldRender: boolean;
  actionType?: StepperAction;
}

const MonthlyStepperTopHeader = ({
  selectedHeaderMonths,
  handlePrevious,
  handleNext,
  yearRange,
  shouldRender,
  actionType,
}: MonthlyStepperHeaderProps) => {
  const [isNexLinkDisabled, setNextLinkDisabled] = useState(false);
  const [isPrevLinkDisabled, setPrevLinkDisabled] = useState(false);
  const [headerGroupList, setHeaderGroupList] = useState<IYearMonthlyAggregate[]>([]);
  const [yearOffset, yearLimit] = useMemo(
    () => [parseYearMonth(yearRange[0]), parseYearMonth(yearRange[1])],
    [yearRange.join(',')],
  );

  useEffect(() => {
    const firstMonth = selectedHeaderMonths[0];
    const lastMonth = selectedHeaderMonths[selectedHeaderMonths.length - 1];

    if (firstMonth && firstMonth.year === yearOffset.year && firstMonth.monthIndex === yearOffset.month) {
      setPrevLinkDisabled(true);
    } else {
      setPrevLinkDisabled(false);
    }

    if (
      lastMonth &&
      lastMonth.year === yearLimit.year &&
      (lastMonth.monthIndex === yearLimit.month ||
        (selectedHeaderMonths.length < STEPPER_CONFIG.displayColumns && lastMonth.monthIndex < yearLimit.month!))
    ) {
      setNextLinkDisabled(true);
    } else {
      setNextLinkDisabled(false);
    }

    setHeaderGroupList(() => {
      const years = getYearsFromMonth(selectedHeaderMonths);

      return years;
    });
  }, [selectedHeaderMonths]);

  return (
    <table>
      <tr>
        <td>
          <table className="stepper-table-header">
            <span className="_left-button">
              <Button className="app-btn-secondary h-auto" onClick={handlePrevious} disabled={isPrevLinkDisabled}>
                <ChevronLeft />
              </Button>
            </span>
            <tr className={classNames('years')}>
              {headerGroupList.map((item, index) => {
                return (
                  <td key={item.year} colSpan={item.numberOfMonths} className="fs-name theme-text">
                    <span className={classNames(styles.fade_in, shouldRender ? styles.active : '')}>{item.year}</span>
                  </td>
                );
              })}
            </tr>
            <tr className={classNames('month-col')}>
              {selectedHeaderMonths.map((value) => {
                return (
                  <td className={classNames('fs-name theme-text')} key={value.monthName}>
                    <span className={classNames(styles.fade_in, shouldRender ? styles.active : '')}>
                      {value.monthName}
                    </span>
                  </td>
                );
              })}
            </tr>
            <span className="_right-button">
              <Button
                disabled={isNexLinkDisabled}
                // disabled
                onClick={handleNext}
                className="app-btn-secondary h-auto"
              >
                <ChevronRight />
              </Button>
            </span>
          </table>
        </td>
      </tr>
    </table>
  );
};

export default MonthlyStepperTopHeader;
