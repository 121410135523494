import { Form, Row } from 'react-bootstrap';
import PagesHeader from '../../../../../components/base/PagesHeader';
import classNames from 'classnames';
import { errorHighlighter } from '../../../../../util/form';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import { OrganizationNumber } from '../../../../../components/form/inputs/OrganizationNumber';
import { useEffect } from 'react';
import { InsuranceFormProps, Mode } from '../../types';
import {
  INITIAL_CONNECTION,
  INITIAL_CONTACT_INFORMATION,
  INITIAL_INSURANCE_COMPANY,
  INITIAL_INSURANCE_PLANS,
} from '../constants';
import { deepClone } from '../../../../../util/util';
import useLocale from '../../../../../hooks/useLocale';

export default function CompanyInformation({ context, mode }: InsuranceFormProps) {
  const {
    formGroup,
    insuranceCompany,
    contactInformation,
    updateInsuranceCompany,
    updateContactInformation,
    replaceInsuranceCompany,
    replaceContactInformation,
  } = context;

  const { localize, lang } = useLocale();

  const handleChange = (ev) => {
    updateInsuranceCompany(ev.target.name, ev.target.value);
  };

  const handleContactInfo = (e) => {
    updateContactInformation(e.target.name, e.target.value);
  };

  const handleCheck = (name, isChecked) => {
    updateInsuranceCompany(name, isChecked);
  };

  useEffect(() => {
    if (mode === Mode.Add) {
      replaceInsuranceCompany(deepClone(INITIAL_INSURANCE_COMPANY));
      replaceContactInformation(deepClone(INITIAL_CONTACT_INFORMATION));
      formGroup.setFieldValue('insurancePlans', deepClone(INITIAL_INSURANCE_PLANS));
      formGroup.setFieldValue('connection', INITIAL_CONNECTION);
    }
  }, []);

  return (
    <>
      <PagesHeader headingClass="fs-h3" pageTitle="Company Information" />
      <Row className="align-items-end mb-4">
        <div style={{ width: 219 }}>
          <Form.Label className="fs-name  required">{localize('short_name')}</Form.Label>
          <Form.Control
            name="shortName"
            onChange={(e) => handleChange(e)}
            value={insuranceCompany?.shortName}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('insuranceCompany.shortName')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('insuranceCompany.shortName')} />
        </div>
        <div style={{ width: 360 }}>
          <Form.Label className="fs-name  required">{localize('company_name')}</Form.Label>
          <Form.Control
            name="companyName"
            onChange={handleChange}
            value={insuranceCompany?.companyName}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('insuranceCompany.companyName')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('insuranceCompany.companyName')} />
        </div>
        <div style={{ width: 120 }}>
          <Form.Label className="fs-name  required">{`${localize('client_no')}.`}</Form.Label>
          <Form.Control
            type="text"
            name="clientNumber"
            onChange={handleChange}
            value={insuranceCompany?.clientNumber}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('insuranceCompany.clientNumber')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('insuranceCompany.clientNumber')} />
        </div>
        <div style={{ width: 120 }}>
          <Form.Label className="fs-name  required">{`${localize('contract_no')}`}</Form.Label>
          <Form.Control
            type="text"
            name="contractNumber"
            onChange={handleChange}
            value={insuranceCompany?.contractNumber}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('insuranceCompany.contractNumber')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('insuranceCompany.contractNumber')} />
        </div>
        <div style={{ width: 180 }} className="align-self-start">
          <Form.Label className="fs-name"> </Form.Label>
          <Form.Check
            checked={formGroup.values.insuranceCompany.collectiveAgreement}
            onChange={(e) => handleCheck('collectiveAgreement', e.target.checked)}
            inline
            name="collectiveAgreement"
            type="checkbox"
            label={`${localize('for_collective_agreement')}`}
            className="fs-name w-100"
            id="collectiveAgreement"
            disabled={mode === Mode.Detail}
          />
        </div>
      </Row>
      <Row className="align-items-end mb-4">
        <div style={{ width: 438 }}>
          <Form.Label className="fs-name">{localize('text_description')}</Form.Label>
          <Form.Control
            name="description"
            onChange={handleChange}
            value={insuranceCompany?.description}
            disabled={mode === Mode.Detail}
          />
        </div>
      </Row>
      <Row className="align-items-end mb-4">
        <OrganizationNumber
          onChangeHandler={handleChange}
          field={formGroup.getFieldMeta('insuranceCompany.organizationNumber')}
          value={insuranceCompany?.organizationNumber}
          customLabel={'Org.no'}
          disabled={mode === Mode.Detail}
          isRequiredError={true}
        />
        <div style={{ width: 120 }}>
          <Form.Label style={{ width: lang === 'se' ? 100 : 120 }} className="fs-name">
            {localize('vat_no')}
          </Form.Label>
          <Form.Control
            type="text"
            name="vatNumber"
            onChange={handleChange}
            value={insuranceCompany?.vatNumber}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div style={{ width: 116 }}>
          <Form.Label className="fs-name">{localize('bankgiro_no')}</Form.Label>
          <Form.Control
            type="text"
            name="bankgiroNumber"
            onChange={handleChange}
            value={insuranceCompany?.bankgiroNumber}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div style={{ width: 105 }}>
          <Form.Label className="fs-name">{localize('plusgiro_no')}</Form.Label>
          <Form.Control
            type="text"
            name="plusgiroNumber"
            onChange={handleChange}
            value={insuranceCompany?.plusgiroNumber}
            disabled={mode === Mode.Detail}
          />
        </div>
      </Row>
      <PagesHeader headingClass="fs-h3" pageTitle="Contact information" />
      <Row className="align-items-end mb-4">
        <div style={{ width: 116 }}>
          <Form.Label className="fs-name ">{localize('postal_address')}</Form.Label>
          <Form.Control
            name="address"
            onChange={handleContactInfo}
            value={contactInformation?.address}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div style={{ width: 120 }}>
          <Form.Label className="fs-name">{localize('zip_code')}</Form.Label>
          <Form.Control
            type="text"
            name="postalCode"
            onChange={handleContactInfo}
            value={contactInformation?.postalCode}
            className={classNames(errorHighlighter(formGroup.getFieldMeta('contactInformation.postalCode')))}
            disabled={mode === Mode.Detail}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('contactInformation.postalCode')} />
        </div>
        <div style={{ width: 116 }}>
          <Form.Label className="fs-name ">{localize('city')}</Form.Label>
          <Form.Control
            name="city"
            onChange={handleContactInfo}
            value={contactInformation?.city}
            disabled={mode === Mode.Detail}
          />
        </div>
      </Row>
      <Row className="align-items-end mb-4">
        <div style={{ width: 121 }}>
          <Form.Label className="fs-name ">{localize('phone_number')}</Form.Label>
          <Form.Control
            type="text"
            name="phone"
            onChange={handleContactInfo}
            value={contactInformation?.phone}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div style={{ width: 156 }}>
          <Form.Label className="fs-name ">{localize('fax')}</Form.Label>
          <Form.Control
            name="fax"
            onChange={handleContactInfo}
            value={contactInformation?.fax}
            disabled={mode === Mode.Detail}
          />
        </div>
        <div style={{ width: 220 }}>
          <Form.Label className="fs-name ">{localize('email')}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            onChange={handleContactInfo}
            value={contactInformation ? contactInformation!['email'] : ''}
            disabled={mode === Mode.Detail}
          />
        </div>
      </Row>
    </>
  );
}
