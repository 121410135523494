import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import PagesHeader from '../../../../../components/base/PagesHeader';
import styles from '../../../../../assets/scss/6-pages/superAdmin/pension-policy/SpecialCondition.module.scss';
import OtherAbsenceLeaveViewer from './OtherAbsenceLeaveViewer';
import ParentalLeaveViewer from './ParentalLeaveViewer';
import useSpecialConditionsViewer from './useSpecialConditionsViewer';
import { PolicyLeaveCategory } from '../../types';

const SpecialConditionsViewer = () => {
  const { t } = useTranslation();
  const { probationaryEmployement, otherLeave, parentalLeave, isPolicyPartIncluded } = useSpecialConditionsViewer();

  return (
    <>
      {(parentalLeave.isChecked || otherLeave.isChecked || probationaryEmployement.isChecked) && (
        <PagesHeader
          headingClass="pp-sec-heading"
          pageTitle={t('PENSION_POLICY.special_conditions')}
          biggerHeading
          translate={false}
        />
      )}
      {probationaryEmployement.isChecked && (
        <div className={classNames('mb-3 parental-check-group', styles.sp_cond_group)}>
          <PagesHeader pageTitle={t('PENSION_POLICY.probationary_employment')} translate={false} />
          <div className="d-flex align-items-end dark-content-section">
            <Container fluid>
              <Row className="w-100">
                <Col>
                  <Form.Group className="pe-0">
                    <Form.Check
                      inline
                      type="checkbox"
                      disabled
                      label={`${t('PENSION_POLICY.fixed_parental_leave')}`}
                      id="probationaryisChecked"
                      className="fs-name mb-1"
                      name="probationaryisChecked"
                      defaultChecked={probationaryEmployement.isChecked}
                    />
                  </Form.Group>
                </Col>

                <Col sm="7">
                  {isPolicyPartIncluded(PolicyLeaveCategory.PROBATIONARY) && (
                    <p className="fs-name">{t('PENSION_POLICY.policy_parts_included')}</p>
                  )}

                  {isPolicyPartIncluded(PolicyLeaveCategory.PROBATIONARY) && (
                    <div className={styles.sp_cond_parts}>
                      {probationaryEmployement.isPremiumCalculation && (
                        <div className={styles.sp_cond_parts__type}>
                          <Form.Group className="pe-0">
                            <Form.Check
                              inline
                              type="checkbox"
                              label={t('PENSION_POLICY.premium_calculation')}
                              id="probationaryisPremiumCalculation"
                              className={classNames('fs-name mb-1', styles.include_part)}
                              disabled
                              name="probationaryisPremiumCalculation"
                              defaultChecked={probationaryEmployement.isPremiumCalculation}
                            />
                          </Form.Group>
                        </div>
                      )}

                      {probationaryEmployement.isSalaryChange && (
                        <div className={styles.sp_cond_parts__type}>
                          <Form.Group className="pe-0">
                            <Form.Check
                              inline
                              type="checkbox"
                              label={t('PENSION_POLICY.applicable_salary_change')}
                              id="probationaryisSalaryChange"
                              className={classNames('fs-name mb-1', styles.include_part)}
                              disabled
                              name="probationaryisSalaryChange"
                              defaultChecked={probationaryEmployement.isSalaryChange}
                            />
                          </Form.Group>
                        </div>
                      )}

                      {probationaryEmployement.isInsurances && (
                        <div className={styles.sp_cond_parts__type}>
                          <Form.Group className="pe-0">
                            <Form.Check
                              inline
                              type="checkbox"
                              label={t('insurances')}
                              id="probationaryisInsurances"
                              className={classNames('fs-name mb-1', styles.include_part)}
                              disabled
                              name="probationaryisInsurances"
                              defaultChecked={probationaryEmployement.isInsurances}
                            />
                          </Form.Group>
                        </div>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
      {parentalLeave.isChecked && <ParentalLeaveViewer />}
      {otherLeave.isChecked && <OtherAbsenceLeaveViewer />}
    </>
  );
};

export default SpecialConditionsViewer;
