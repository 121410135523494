import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Button, Spinner } from 'react-bootstrap';
import { Slash, Save, Trash2 } from 'react-feather';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getConsultantDetails,
  deleteConsultant,
  updateConsultant,
} from '../../../../api/service/superAdmin/consultants/service';
import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { useEditConsultantContext } from '../context/useContext';
import General from './General';
import { DialogWithTextField } from '../../../../util/dialogs';
import { useTranslation } from 'react-i18next';
import { Consultants } from '../../../../api/service/superAdmin/consultants/types';
import useAuth from '../../../../hooks/useAuth';
import { splitFullName } from '../../../../util/util';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';
// import BaseButton from '../../../../components/base/BaseButton';

let _consultantDetail = {};
export default function ConsultantList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [date, ,] = useState({ createdAt: '', updatedAt: '' });
  const [isBusy, setIsBusy] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const { id } = useParams();
  const context = useEditConsultantContext();
  const { personalDetails, replaceConsultantInput, formGroup, setConsultantDetailRes } = context;
  const { errors, setErrors, isInvalid } = useValidationError();
  const { user } = useAuth();
  const hasChanges = !isEqual(_consultantDetail, personalDetails);
  const consultantName = personalDetails ? personalDetails['fullName'] : '';

  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Consultants',
      href: '/consultant',
    },
    {
      name: consultantName,
      translate: false,
    },
  ];

  const onCancel = () => {
    const { firstName, lastName } = splitFullName(_consultantDetail['fullName']);
    replaceConsultantInput({ ..._consultantDetail, firstName, lastName });
  };

  const onUpdateConsultant = async () => {
    try {
      const data = {
        email: personalDetails.email,
        fullName: personalDetails.fullName,
      } as Consultants | any;

      formGroup.handleSubmit();
      setErrors(formGroup.errors);

      if (!formGroup.isValid) {
        return false;
      }
      setisUpdating(true);
      await updateConsultant(id, data);
      await fetchConsultants();
    } finally {
      setisUpdating(false);
    }
  };

  const fetchConsultants = async () => {
    try {
      setIsBusy(true);
      let consultantDetails = await getConsultantDetails({ id: user?.role.company.id, includeSupervisor: true });
      consultantDetails = (consultantDetails as any).find((item) => {
        return item.id === id;
      });
      const { fullName } = consultantDetails as any;
      const { firstName, lastName } = splitFullName(fullName);
      const consultantResponse = { ...consultantDetails, firstName, lastName };
      _consultantDetail = structuredClone(consultantResponse);
      replaceConsultantInput(consultantResponse);
      setConsultantDetailRes(consultantResponse as any);
    } finally {
      setIsBusy(false);
    }
  };

  const onDelete = async () => {
    // const title = `${t('i_want_to_detete')} ${consultantName} ${t('forever')}`;
    const title = `${t('i_want_to_detete')} ${consultantName} ${t('forever')}`;
    const textPara = `${t('to_delete_the')} ${t('consultant')}, ${t('type_in')}`;
    const result = await DialogWithTextField({
      title: t('are_you_sure'),
      confirmButtonText: t('delete_consultant'),
      denyButtonText: t('cancel'),
      validationTitle: title,
      validationMessage: t('validation_message'),
      content: {
        caption: textPara,
        body: title,
      },
      confirmBtnClass: 'confirm-delete-btn',
    });

    if (result.isConfirmed) {
      try {
        setisDeleting(true);
        await deleteConsultant(id ?? '');
        navigate('/consultants');
      } finally {
        setisDeleting(false);
      }
    }
  };

  useEffect(() => {
    fetchConsultants();
  }, []);

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader
          headingClass="fs-h1"
          pageTitle={consultantName || 'Consultants'}
          // pageTitle={personalDetails ? personalDetails['firstName'] : ''}
          translate={false}
        />
        <div className="cr-form-row">
          {hasChanges && (
            <Button onClick={onCancel} className="app-btn-primary cancel-btn">
              <Slash className="icon-gap-right" /> {`${t('cancel_changes')}`}
            </Button>
          )}
          {/* {hasChanges && (
            <BaseButton onClickHandler={onCancel} className="me-2">
              <Slash className="icon-gap-right" />
              {`${t('cancel')} ${t('changes')}`}
            </BaseButton>
          )} */}
          <Button
            disabled={isDeleting || isUpdating || !hasChanges}
            onClick={onUpdateConsultant}
            className="app-btn-primary"
          >
            {isUpdating ? <Spinner size="sm" /> : <Save className="icon-gap-right" />} {`${t('save')} ${t('changes')}`}
          </Button>

          <Button disabled={isDeleting || isUpdating || id === user?.id} onClick={onDelete} className="app-btn-danger">
            {isDeleting ? <Spinner size="sm" /> : <Trash2 className="icon-gap-right" />}
            {`${t('delete')} ${t('consultant')}`}
          </Button>
        </div>
      </div>
      <ValidationAlert show={isInvalid} errors={errors} />

      <General isBusy={isBusy} date={date} />
    </>
  );
}
