import { FormikProps } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { AuthorizedSignatoryGroup } from '../CompanyClients/types';

export interface LooseObject {
  [key: string]: any;
}

export type DateType = {
  createdAt: string;
  updatedAt: string;
};

export type CollectiveAgreementType = {
  id: string;
  name: string;
};
export type ResellerAgreementType = {
  id: string;
  name: string;
};
export type FirmDetails = {
  organizationNumber: string;
  name: string;
  status: string;
  contractNumber: string;
  employees?: string;
  createDate?: string;
  resellerAgreement: string;
  lastEdit?: string;
  updatedAt?: string;
};

export type CompanyDetails = {
  organizationNumber: string;
  name: string;
  status: string;
};

export type AddressDetails = {
  city: string;
  country: string;
  postcode: string;
  street: string;
};

export type AuthorizedSignatory = {
  _id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
  incharge: boolean;
};

export type Consultants = {
  _id?: string;
  personalNumber: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type Superviors = {
  _id?: string;
  id?: string;
  personalNumber: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type SeniorConsultants = {
  _id?: string;
  id?: string;
  personalNumber: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
};

export type AdminDetails = {
  _id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
};

export type ProvisionAgreement = {
  uptoRange1?: string;
  uptoRange2?: string;
  uptoRange3?: string;
  uptoRange4?: string;
};

export enum ResellerAgreementMode {
  CERTIFIED_PROVIDER = 'Certified Provider',
}
export interface IAccountingFirmContext {
  firmDetails: FirmDetails;
  setFirmDetails: Dispatch<SetStateAction<FirmDetails>>;

  companyDetails: CompanyDetails | LooseObject;
  setCompanyDetails: Dispatch<SetStateAction<CompanyDetails | LooseObject>>;

  addressDetails: AddressDetails | LooseObject;
  setAddressDetails: Dispatch<SetStateAction<AddressDetails | LooseObject>>;

  adminDetails: Array<AdminDetails>;
  setAdminDetails: Dispatch<SetStateAction<Array<AdminDetails>>>;

  signatoryDetails: Array<AuthorizedSignatory>;
  setSignatoryDetails: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  signatoryAdmins: Array<AuthorizedSignatory>;
  setSignatoryAdmins: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  deletedSupervisors: Array<string>;
  setDeletedSupervisors: Dispatch<SetStateAction<Array<string>>>;

  deletedConsultants: Array<string>;
  setDeletedConsultants: Dispatch<SetStateAction<Array<string>>>;

  combinations: Array<AuthorizedSignatoryGroup>;
  setCombinations: Dispatch<SetStateAction<Array<AuthorizedSignatoryGroup>>>;

  consultants: Array<Consultants>;
  setConsultants: Dispatch<SetStateAction<Array<Consultants>>>;

  supervisors: Array<Superviors>;
  setSupervisors: Dispatch<SetStateAction<Array<Superviors>>>;

  seniorConsultants: Array<SeniorConsultants>;
  setSeniorConsultants: Dispatch<SetStateAction<Array<SeniorConsultants>>>;

  contractEffectiveFrom: string;
  setContractEffectiveFrom: Dispatch<SetStateAction<string>>;

  invoicingCostCenter: string;
  setInvoicingCostCenter: Dispatch<SetStateAction<string>>;

  invoiceEmailAddress: string;
  setInvoiceEmailAddress: Dispatch<SetStateAction<string>>;

  collectiveAgreements: Array<CollectiveAgreementType>;
  setCollectiveAgreements: Dispatch<SetStateAction<Array<CollectiveAgreementType>>>;

  resellerAgreements: Array<ResellerAgreementType>;
  setResellerAgreements: Dispatch<SetStateAction<Array<ResellerAgreementType>>>;

  invitationEmail: string;
  setInvitationEmail: Dispatch<SetStateAction<string>>;

  provisionAgreement: ProvisionAgreement;
  setProvisionAgreement: Dispatch<SetStateAction<ProvisionAgreement>>;

  isEditMode: boolean;

  formGroup: AccountingFirmFormGroup;

  updateFirmDetailsInput: (inputName: string, inputValue: any) => void;
  updateAddressInput: (inputName: string, inputValue: any) => void;
  updateContractEffectiveInput: (inputValue: any) => void;
  updateInvoicingCostCenter: (inputValue: any) => void;
  updateInvoiceEmailAddress: (inputValue: any) => void;
  replaceSupervisorsInput: (inputValue: any) => void;
  replaceSignatoriesInput: (inputValue: any) => void;
  replaceSeniorConsultantsInput: (inputValue: any) => void;
  updateInvitationEmailInput: (inputValue: any) => void;
  replaceFirmDetailsInput: (inputValue: any) => void;
  replaceAddressInput: (inputValue: any) => void;
  updateProvisionAgreementInput: (inputName: string, inputValue: any) => void;
  appendDeletedSupervisor: (value: any) => void;
  appendDeletedConsultant: (value: any) => void;
  replaceCombinations: (value: any) => void;
  updateCombinations: (inputName: any, inputValue: any) => void;
  replaceSignatoryAdmins: (value: any) => void;
}

export type AccountingFirmFormGroup = FormikProps<IAccountingFirm>;

export type FormProps = {
  context: IAccountingFirmContext;
  date?: DateType;
};

export interface IAccountingFirm {
  firmDetails: FirmDetails;
  addressDetails: AddressDetails;
  contractEffectiveFrom: string;
  invoicingCostCenter: string;
  invoiceEmailAddress: string;
  supervisors: Array<Superviors>;
  signatoryDetails: Array<AuthorizedSignatory>;
  seniorConsultants: Array<SeniorConsultants>;
  combinations: AuthorizedSignatoryGroup[];
  invitationEmail: string;
  provisionAgreement: ProvisionAgreement;
  //companyDetails: CompanyDetails;
  /* adminDetails: Array<AdminDetails>;
  consultants: Array<Consultants>;
  collectiveAgreements: Array<CollectiveAgreementType>;
  resellerAgreements: Array<ResellerAgreementType>;
   */
}

export interface IAccountingFirmLegalDoc {
  title: string;
  link?: string;
}

export interface IAccountingFirmProvision {
  companyName: string;
  orgNumber: string;
  employees: number;
  status: string;
  totalMonths: number;
  provisionPercent: number;
  provisionTotal: number;
}

export enum AccountingFirmSaveMode {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SETUP = 'Setup',
}
