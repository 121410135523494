import { Nav } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { SidebarItemsType } from '../../types/sidebar';
import useLocale from '../../hooks/useLocale';

interface TopMenuItemLinkProps {
  page: SidebarItemsType;
  items?: JSX.Element[];
  currentRoute?: string;
}

const TopMenuItemLink = (props: TopMenuItemLinkProps) => {
  const { localize } = useLocale();

  if ((props.page as any).preventLink) {
    return (
      <Link className="sidebar-link nav-link" to={''}>
        {localize(props.page.titleKey!)}
      </Link>
    );
  }
  return (
    <Nav.Link as={NavLink} className="sidebar-link" to={props.page.href}>
      {localize(props.page.titleKey!)}
    </Nav.Link>
  );
};

export default TopMenuItemLink;
