import React from 'react';
import { useRoutes } from 'react-router-dom';
import { Chart as ChartJS, registerables } from 'chart.js';
import 'chart.js/auto';
import './App.css';

import { routesCompany, routesSuperAdmin, routesAccountingFirm } from './routes';
import { EMPLOYEE_ROUTE_REGISTRY } from './routes/employee.route';

import { ThemeProvider } from './contexts/ThemeContext';
import { UserRole } from './types/auth';
// import { LocaleProvider } from './contexts/LocaleContext';
import useSession from './hooks/useSession';
import { LocaleProvider } from './contexts/LocaleContext';

function App() {
  const { authSession } = useSession();

  const getAppRoutes = () => {
    const roleType = authSession ? authSession.role.role : null;

    if (roleType === UserRole.EMPLOYEE) {
      return EMPLOYEE_ROUTE_REGISTRY;
    } else if (roleType === UserRole.COMPANY_ADMIN) {
      return routesCompany;
    } else if (roleType === UserRole.ACCOUNTING_FIRM) {
      return routesAccountingFirm;
    } else {
      return routesSuperAdmin;
    }
  };

  const content = useRoutes(getAppRoutes());

  ChartJS.register(...registerables);
  return (
    <ThemeProvider>
      <LocaleProvider>
        {/* <AuthProvider> */}
        {content}
        {/* </AuthProvider> */}
      </LocaleProvider>
    </ThemeProvider>
  );
}

export default App;
