import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getSalaryTypes } from '../../../api/service/superAdmin/pension-policies/service';
import CardComponent from '../../../components/base/CardComponent';
import HorizontalRule from '../../../components/base/HorizontalRule';
import PagesHeader from '../../../components/base/PagesHeader';
import { SuperAdminPensionPolicySalaryData } from '../../../dummy/data';
import { useValidationError } from '../../../hooks/useValidationError';
import ValidationAlert from '../../../components/ValidationAlert';
import SalaryCodeConversionTable from './SalaryCodeConversion/SalaryCodeConversionTable';
import { saveSalaryCodes } from '../../../api/service/superAdmin/settings/service';

interface InputProps {
  emitValues: (...args) => void;
}

const SalaryCodeConversion = React.forwardRef(({ emitValues }: InputProps, _ref: any) => {
  const { errors, isInvalid, setErrors } = useValidationError();

  const [salaryCodes, setSalaryCodes] = useState<typeof SuperAdminPensionPolicySalaryData>({ data: [] });
  const [isBusy, setisBusy] = useState(false);
  const [provider, setProvider] = useState('');
  const { t } = useTranslation();
  const [chosenSalaryCodes, setChosenSalaryCodes] = useState<any[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const fetchSalaryCodes = async () => {
    try {
      const _salaryCodes = await getSalaryTypes();
      const _chosenSalaryCodes = _salaryCodes.filter((item) => {
        return item.isCommon;
      });

      setSalaryCodes({ data: _salaryCodes });
      setChosenSalaryCodes(
        _chosenSalaryCodes.map((item) => {
          return {
            id: item._id,
            isCommon: true,
            hasRecordExist: true,
          };
        }),
      );
    } finally {
      setisBusy(false);
    }
  };

  const onProviderChange = (e) => {
    const { value } = e.target;
    setProvider(value);
    if (value === 'fortnox' && salaryCodes.data.length === 0) {
      fetchSalaryCodes();
    }
  };

  const onChangeSalaryCode = (salaryCode, isChecked) => {
    pushChosenSalaryCode(salaryCode, isChecked);
  };

  const pushChosenSalaryCode = (salaryCode, checked) => {
    const _chosenSalaryCodes = [...chosenSalaryCodes];
    const foundExistSalaryCodeIndex = _chosenSalaryCodes.findIndex((item) => item.id === salaryCode._id);

    if (foundExistSalaryCodeIndex !== -1) {
      if (_chosenSalaryCodes[foundExistSalaryCodeIndex].hasRecordExist) {
        _chosenSalaryCodes[foundExistSalaryCodeIndex] = {
          ..._chosenSalaryCodes[foundExistSalaryCodeIndex],
          isCommon: checked,
        };
      } else {
        _chosenSalaryCodes.splice(foundExistSalaryCodeIndex, 1);
      }
    } else {
      _chosenSalaryCodes.push({
        id: salaryCode._id,
        isCommon: checked,
      });
    }

    setChosenSalaryCodes(_chosenSalaryCodes);
  };

  const onSaveSalaryCodes = useCallback(async () => {
    try {
      setIsSaving(true);
      const payload = chosenSalaryCodes.map((item) => {
        const { hasRecordExist, ..._chosenSalaryCodeItem } = item;

        return _chosenSalaryCodeItem;
      });

      await saveSalaryCodes(payload);

      setChosenSalaryCodes([
        ...chosenSalaryCodes
          .filter((item) => (item.isCommon && item.hasRecordExist) || item.isCommon)
          .map((code) => {
            return {
              ...code,
              hasRecordExist: true,
            };
          }),
      ]);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsSaving(false);
    }
  }, [chosenSalaryCodes]);

  useImperativeHandle(
    _ref,
    () => {
      return {
        chosenSalaryCodes: chosenSalaryCodes,
        onSaveSalaryCodes: onSaveSalaryCodes,
        isSaving: isSaving,
      };
    },
    [chosenSalaryCodes, saveSalaryCodes],
  );

  useEffect(() => {
    emitValues({
      chosenSalaryCodes,
      isSaving,
      onSaveSalaryCodes,
    });
  }, [chosenSalaryCodes, saveSalaryCodes, isSaving]);

  return (
    <CardComponent>
      <PagesHeader headingClass="fs-h2" pageTitle="Salary code conversion" />
      <ValidationAlert show={isInvalid} errors={errors} />
      <div className="d-flex align-items-center justify-content-between mb-5">
        <Form.Group>
          <Form.Label className="fs-name">{t('provider')}</Form.Label>
          <Form.Select onChange={onProviderChange} value={provider}>
            <option disabled value="">
              {t('please_select')}
            </option>
            <option value="fortnox">{t('fortnox')}</option>
            <option value="visma">{t('visma')}</option>
            {/* {salaryCodes.data.map((i) => (
                <option key={i._id}>{i.name}</option>
              ))} */}
          </Form.Select>
        </Form.Group>

        {/* <Button disabled className="app-btn-primary">
          <Plus className="icon-gap-right" /> Add provider
        </Button> */}
      </div>
      {provider === 'fortnox' && (
        <>
          <div className="d-flex  justify-content-between mb-3">
            <div className="d-flex align-items-start">
              <PagesHeader headingClass="fs-page-title text-capitalize" pageTitle={provider} translate={false} />
              {isSaving && <Spinner style={{ position: 'relative', left: '-10px' }} />}
            </div>
            {/* <div>
              <Button disabled className="app-btn-primary me-2">
                <Edit2 className="icon-gap-right" /> Edit provider integration
              </Button>
              <Button disabled className="app-btn-danger">
                <Trash2 className="icon-gap-right" /> Delete provider
              </Button>
            </div> */}
          </div>
          <HorizontalRule />
          {/* <SalaryTypesGrid isBusy={isBusy} salaryData={salaryCodes} showSelection={false} /> */}
          <SalaryCodeConversionTable
            isBusy={isBusy}
            salaryTypesData={salaryCodes.data}
            onChangeSalaryCode={onChangeSalaryCode}
            chosenSalaryCodes={chosenSalaryCodes}
          />
        </>
      )}
    </CardComponent>
  );
});

export default SalaryCodeConversion;

SalaryCodeConversion.displayName = 'SalaryCodeConversion';
