import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CardComponent from '../../../../components/base/CardComponent';
import PagesHeader from '../../../../components/base/PagesHeader';
import { deepClone } from '../../../../util/util';
import { AccessLevelRef } from './types';
import useAccessLevelEditor from './useAccessLevelEditor';
import { useAccountingFirmEditGeneralSettingsContext } from '../General/context/useContext';
import ConsultantAccess from './ConsultantAccess';

interface InputProps {
  emitValues: (...args) => void;
}

export const AccessLevels = ({ emitValues }: InputProps) => {
  const { t } = useTranslation();
  const accessLevelEditor = useAccessLevelEditor();
  const {
    firmDetails,
    accessConsultants,
    allowAdvisorRemoteAccess,
    allowCorrectoRemoteAccess,
    setAllowAdvisorRemoteAccess,
    setAllowCorrectoRemoteAccess,
    appendDeletedConsultant,
    replaceAccessConsultants,
    replaceAccess,
  } = useAccountingFirmEditGeneralSettingsContext();

  const handleCorrectoRemoteAccess = (e) => {
    setAllowCorrectoRemoteAccess(e.target.checked);
  };
  const handleAdvisorRemoteAccess = (e) => {
    setAllowAdvisorRemoteAccess(e.target.checked);
  };
  const handleRemoveConsultant = (personalNumber) => {
    const filteredConsultants = accessConsultants.filter((item) => item.personalNumber !== personalNumber);
    const found = accessConsultants.find((item) => item.personalNumber === personalNumber);

    replaceAccessConsultants(filteredConsultants);
    appendDeletedConsultant(found!.id || found!._id);
  };

  const patchDetails = (_firmDetails) => {
    if (_firmDetails?.seniorConsultants?.length > 0) {
      replaceAccessConsultants(deepClone(_firmDetails.seniorConsultants));
    }
    replaceAccess(_firmDetails.allowCorrectoAccess, _firmDetails.allowAdvisorAccess);
  };

  useEffect(() => {
    if (firmDetails) {
      patchDetails(firmDetails);
    }
  }, [firmDetails]);

  useEffect(() => {
    emitValues({
      isSavingAccessLevel: accessLevelEditor.isLoading,
      hasAccessLevelChanges: accessLevelEditor.hasAccessLevelChanges,
      saveAccessLevel: accessLevelEditor.save,
      cancelAccessLevel: accessLevelEditor.cancelAccessLevelChanges,
    } as AccessLevelRef);
  }, [
    accessLevelEditor.isLoading,
    accessLevelEditor.save,
    accessLevelEditor.hasAccessLevelChanges,
    accessLevelEditor.cancelAccessLevelChanges,
  ]);

  return (
    <CardComponent>
      <PagesHeader pageTitle="Access" headingClass="fs-h2 font-black" />
      {accessConsultants.length > 0 && (
        <ConsultantAccess consultants={accessConsultants} handleRemoveConsultant={handleRemoveConsultant} />
      )}
      <div>
        <PagesHeader pageTitle="Correcto remote" headingClass="fs-h3 font-black" />
        <p className="theme-text">
          {t('ACCESS_LEVEL.allowing_correcto_remote_text')},<Link to={'#'}> {t('ACCESS_LEVEL.read_more_here')}</Link> .
        </p>
        <Form.Check
          label={t('ACCESS_LEVEL.allow_correcto_remote_access')}
          inline
          type="checkbox"
          id={`remoteAccess`}
          className="fs-name mb-3"
          onChange={(e) => handleCorrectoRemoteAccess(e)}
          checked={allowCorrectoRemoteAccess}
        />
      </div>
      <div className="mt-2">
        <PagesHeader pageTitle="Insurance advisor remote" headingClass="fs-h3 font-black" />
        <p className="theme-text">
          {t('ACCESS_LEVEL.allowing_insurance_advisor_remote_text')},
          <Link to={'#'}> {t('ACCESS_LEVEL.read_more_here')}</Link> .
        </p>
        <Form.Check
          label={t('ACCESS_LEVEL.allow_insurance_advisor_remote_access')}
          inline
          type="checkbox"
          id={`remoteAccess2`}
          className="fs-name mb-3"
          onChange={(e) => handleAdvisorRemoteAccess(e)}
          checked={allowAdvisorRemoteAccess}
        />
      </div>
    </CardComponent>
  );
};
