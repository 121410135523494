import { useEffect, useState, useRef } from 'react';
import { Button, Form, Row, Spinner } from 'react-bootstrap';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { GetSalaryDocumentBaseInfo } from './types';
import useColumn from './useColumn';
import DataManagementDetails from '../../DataManagement/components/DataManagementDetails';
import { getDataValidations } from '../../../../api/service/company/settings';
import BSRemoteTable from '../../../../components/table/BSRemoteTable';
import { ReactComponent as ClearFilter } from '../../../../assets/img/Clear_filter.svg';
import { hasValueChanges } from '../../../../util/general';
import MonthAndYearDropdown from '../../../../section/MonthAndYearDropdown';
import moment from 'moment';

type Props = {
  isUploaded: boolean;
  toggleShownError: (val: boolean) => void;
  updateSalaryDocList: (val: GetSalaryDocumentBaseInfo['data'][]) => void;
};

export default function FilesGrid({ isUploaded, toggleShownError, updateSalaryDocList }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [salaryDocResponse, setSalaryDocResponse] = useState<GetSalaryDocumentBaseInfo>({
    data: [],
  });
  const filesColumn = useColumn();
  const [showDataManagementModal, setDataManagementModal] = useState(false);
  const [selectedFile /* setSelectedFile */] = useState<any>();
  const toggleModal = () => setDataManagementModal(!showDataManagementModal);
  const [selectedFilter, setSelectedFilter] = useState({
    fullName: '',
    'nonValid.exist': false,
    period: '',
  });
  const [period, setPeriod] = useState(moment().toString());

  const showClearFilter = hasValueChanges(selectedFilter);
  const searchRef = useRef<any>();

  const fetchSalaryFiles = async ({
    filter = selectedFilter,
    page = salaryDocResponse?.metaData?.page.current,
    pageSize = salaryDocResponse?.metaData?.page.size,
    sortField = 'fullName',
    sortOrder = '1',
    sort = {
      [sortField]: sortOrder,
    },
  }) => {
    try {
      const res = await getDataValidations({ filter, page, pageSize, sort });
      // const errors = (res?.data || []).filter((item) => item.status === 'PROCESSING_ERROR');

      if (res.nonValidCount) {
        toggleShownError(true);
      } else {
        toggleShownError(false);
      }

      setSalaryDocResponse(res as any);
      updateSalaryDocList(res.nonValidCount);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onPagination = async ({ page, sizePerPage }) => {
    await fetchSalaryFiles({ page, pageSize: sizePerPage });
  };

  const onFilterChange = (key, value) => {
    setSelectedFilter((prevValue) => ({
      ...prevValue,
      [key]: value,
    }));
  };

  const onToggleValidation = () => {
    const value = !selectedFilter['nonValid.exist'];
    onFilterChange('nonValid.exist', value);
  };

  const onPeriodChange = (value) => {
    setPeriod(value);
    onFilterChange('period', value);
  };

  const clearFilter = async () => {
    searchRef.current.value = '';

    setSelectedFilter({
      fullName: '',
      'nonValid.exist': false,
      period: '',
    });
  };

  useEffect(() => {
    if (isUploaded) {
      fetchSalaryFiles({});
    }
  }, [isUploaded]);

  useEffect(() => {
    fetchSalaryFiles({});
  }, [selectedFilter]);

  return (
    <Row className="justify-content-between mb-3 data-management-table">
      <div className="mb-3">
        <Form>
          <Form.Label className="fs-name mb-0">{t('period')}</Form.Label>
          <div className="calender-field-current">
            <MonthAndYearDropdown
              startFrom={moment().subtract(3, 'months').toString()}
              value={period}
              onOptionSelect={(val) => onPeriodChange(val)}
              showLabelCurrent
            />
          </div>
        </Form>
      </div>

      <div className="cr-form-row w-auto">
        <div className="search-field">
          <div className="input-group">
            <input
              ref={searchRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFilterChange('fullName', (e.target as any).value);
                }
              }}
              placeholder={`${t('search_employee')}`}
              className="form-control"
              style={{ borderRight: 'none' }}
            />
            <span className="input-group-append bg-white border-left-0">
              <span className="input-group-text ">
                <Search
                  className="cursor-pointer"
                  onClick={() => onFilterChange('fullName', searchRef.current.value)}
                  width={16}
                  height={30}
                />
              </span>
            </span>
          </div>
        </div>
        <div>
          <Button
            onClick={() => onToggleValidation()}
            className={classNames(
              'app-btn-white mt-2',
              selectedFilter['nonValid.exist'] === true ? 'selected-button' : '',
            )}
            style={{ width: 140 }}
          >
            {t('non_validating')}
          </Button>
        </div>
        <div>
          {showClearFilter && (
            <Button onClick={clearFilter} className={classNames('app-btn-white')}>
              <ClearFilter />
            </Button>
          )}
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <BSRemoteTable
          data={salaryDocResponse?.data ?? []}
          columns={filesColumn}
          noDataIndication={`${t('no_clients_found')}`}
          sizePerPage={salaryDocResponse?.metaData?.page?.['size'] || 0}
          onSizePerPageChange={(sizePerPage, page) => onPagination({ sizePerPage, page })}
          keyField="id"
          onTableChange={(type, { sortOrder, sortField }) => {
            if (type === 'sort') {
              fetchSalaryFiles({ sortField, sortOrder: sortOrder === 'asc' ? '1' : '-1' });
            }
          }}
          rowClasses={(row) => {
            return row['status'] === 'PROCESSING_ERROR' ? 'data-flow-error' : 'data-flow-default';
          }}
        />
      )}
      {showDataManagementModal && (
        <DataManagementDetails
          toggleModal={toggleModal}
          showDataModal={showDataManagementModal}
          selectedFile={selectedFile}
        />
      )}
      {/* {showConlictModal && <DataConflictModal show={showConlictModal} toggleModal={toggleModal} />} */}
    </Row>
  );
}
