export const INITIAL_DATA = {
  specialPayrollTax: {
    value: 0,
  },
  priceBaseAmount: {
    value: 0,
  },
  incomeBaseAmount: {
    value: 0,
  },
  salaryInterval: {
    value: 0,
  },
  priceBaseAmountPcs: {
    value: 0,
  },
  rightOfDeduction: {
    value: 0,
  },
  mainRuleDeduction: {
    value: 0,
  },
  salaryInExcessOfAmount: {
    value: 0,
  },
  prelArbetsgivAvgPercent: {
    value: 0,
  },
};

export const internalCalculationsColumns = [
  {
    key: 'specialPayrollTax',
  },
  { key: 'priceBaseAmount' },
  { key: 'incomeBaseAmount' },
  { key: 'pcsIncomeBase' },
  { key: 'salaryInterval' },
  { key: 'feePremium' },
  { key: 'salaryAmountExcess' },
  { key: 'priceBaseAmountPcs' },
  { key: 'rightOfDeducation' },
  { key: 'mainRulededuction' },
  { key: 'prelArbetsgivAvgPercent' },
];

export const years = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031];
