import { Calendar } from '../../../../util/calendar';
import { assignWith, sortBy } from '../../../../util/util';
import {
  DEFAULT_CALCULATION_MATRIX_LENGTH,
  DEFAULT_COMPENSATION_INCREASED_OFFSET,
  DEFAULT_HOLIDAY_PER_YEAR,
  INITIAL_OCCUPATIONAL_PENSION,
  INITIAL_POLICY_SPECIAL_CONDITION,
  INITIAL_SALARY_TYPE,
} from '../constants';
import {
  MetaEmployeePolicySettings,
  PensionPolicy,
  PolicySettings,
  PolicySpecialCondition,
  SalaryType,
  TypeCalculation,
  TypeInsurances,
  TypeOccupationPension,
  TypePensionManager,
  TypeSalaryChange,
  TypeSalaryData,
  TypeSalaryWithHolidays,
} from '../types';

export class PensionFormBuilder {
  static fromFormModel(form: PensionPolicy | null = null) {
    const mainInfo = {
      policyName: form?.policyName || '',
      version: form?.version || '',
      policyDescription: form?.policyDescription || '',
      employeeType: form?.employeeType || '',
      effectiveFrom: form?.effectiveFrom || Calendar.currentMonthStartDate,
      isCollectiveAgreementLock: form?.isCollectiveAgreementLock || false,
      /* isAutoAssign: form?.isAutoAssign || false,
      status: form?.isAutoAssign || PensionPolicyStatus.IN_ACTIVE,
      type: form?.type || PensionPolicyVariant.COLLECTIVE_AGREEMENT, */
    };

    const policyConfig = form?.policySettings || null;

    const premiumCalculation: TypeOccupationPension = this.toPremiumCalculation(policyConfig);

    const pensionManagers: TypePensionManager[] = (policyConfig?.pensionManagers || [])?.map((item) => {
      return {
        pensionManagerId: item.pensionManagerId || '',
        planName: item.planName || '',
        fromOccupationalPension: {
          customerNumber: item?.fromOccupationalPension?.customerNumber || '',
          insuranceNumber: item?.fromOccupationalPension?.insuranceNumber || '',
          isDefault: item?.fromOccupationalPension?.isDefault || false,
          isInclude: item?.fromOccupationalPension?.isInclude || false,
        },
        fromSalaryChange: {
          customerNumber: item?.fromSalaryChange?.customerNumber || '',
          insuranceNumber: item?.fromSalaryChange?.insuranceNumber || '',
          isDefault: item?.fromSalaryChange?.isDefault || false,
          isInclude: item?.fromOccupationalPension?.isInclude || false,
        },
      };
    });

    const insurances: Partial<TypeInsurances>[] = (policyConfig?.insurances || [])?.map((item) => {
      return {
        insuranceId: item.insuranceId || '',
        plans: (item.plans || []).map((planItem) => {
          return {
            categoryName: planItem?.categoryName || '',
            isChecked: planItem?.isChecked || false,
            planName: planItem?.planName || '',
          };
        }),
      };
    });

    const employeeMetaSettings: MetaEmployeePolicySettings = {
      insurance: policyConfig?.employeeMetaSettings?.insurance || {
        effectiveFrom: '',
      },
      pensionManager: policyConfig?.employeeMetaSettings?.pensionManager || {
        effectiveFrom: '',
      },
    };

    const salaryChange: TypeSalaryChange = {
      isAllowed: false,
      fixedMonthlyOffset: {
        offset: policyConfig?.salaryChange?.fixedMonthlyOffset?.offset || 0,
        isChecked: policyConfig?.salaryChange?.fixedMonthlyOffset?.isChecked || false,
        isEditAllowed: policyConfig?.salaryChange?.fixedMonthlyOffset?.isEditAllowed || false,
        effectiveFrom: policyConfig?.salaryChange?.fixedMonthlyOffset?.effectiveFrom || '',
      },
      percentageOfSalaryOffset: {
        offset: policyConfig?.salaryChange?.percentageOfSalaryOffset?.offset || 0,
        isChecked: policyConfig?.salaryChange?.percentageOfSalaryOffset?.isChecked || false,
        isEditAllowed: policyConfig?.salaryChange?.percentageOfSalaryOffset?.isEditAllowed || false,
        effectiveFrom: policyConfig?.salaryChange?.percentageOfSalaryOffset?.effectiveFrom || '',
      },
      manualOffset: {
        offset: policyConfig?.salaryChange?.manualOffset?.offset || 0,
        isChecked: policyConfig?.salaryChange?.manualOffset?.isChecked || false,
        isManualOffsetAllowed: policyConfig?.salaryChange?.manualOffset?.isManualOffsetAllowed || false,
        effectiveFrom: policyConfig?.salaryChange?.manualOffset?.effectiveFrom || '',
      },
      compensationWithIncreasedOffset: {
        isChecked: policyConfig?.salaryChange?.compensationWithIncreasedOffset?.isChecked || false,
        offsetAddition:
          policyConfig?.salaryChange?.compensationWithIncreasedOffset?.offsetAddition ||
          DEFAULT_COMPENSATION_INCREASED_OFFSET,
      },
      isCheckedSepratePensionManagerPlans: policyConfig?.salaryChange?.isCheckedSepratePensionManagerPlans || false,
    };

    const salaryType: SalaryType = this.getSalaryType(policyConfig);

    const specialConditions: PolicySpecialCondition = this.toSpecialCondition(policyConfig);

    const policySettings = {
      occupationalPension: premiumCalculation,
      salaryChange,
      salaryType,
      pensionManagers: sortBy(pensionManagers, 'pensionManagerId'),
      insurances: sortBy(insurances, 'insuranceId'),
      employeeMetaSettings,
      specialConditions,
    };

    return {
      ...mainInfo,
      policySettings,
    };
  }

  private static getSalaryType(policyConfig: PolicySettings | null): SalaryType {
    const isTemplateCalculationChecked = policyConfig?.salaryType?.isTemplateCalculation || false;

    const salaryTypes: Partial<TypeSalaryData>[] = (policyConfig?.salaryType?.salaryTypes || [])?.map((item) => {
      return {
        code: item.code ?? 0,
        id: item.id || '',
        name: item.name || '',
        percentage: item.percentage ?? '',
      };
    });

    const holidaySalaryTypes = policyConfig?.salaryType?.salaryTypesWithHolidays?.length
      ? policyConfig?.salaryType?.salaryTypesWithHolidays
      : [
          /* {
            ...INITIAL_SALARY_TYPE.salaryTypesWithHolidays[0],
            data: isTemplateCalculationChecked ? INITIAL_SALARY_TYPE.salaryTypesWithHolidays[0].data : [],
          }, */
          INITIAL_SALARY_TYPE.salaryTypesWithHolidays[0],
        ];

    const salaryTypesWithHolidays = holidaySalaryTypes?.map((item) => {
      return {
        isEnable: item.isEnable ?? false,
        setupName: item.setupName ?? '',
        holidayDaysPerYear: item.holidayDaysPerYear ?? DEFAULT_HOLIDAY_PER_YEAR,
        data: (item.data || []).map((dataItem) => {
          return {
            salaryType: {
              name: dataItem.salaryType?.name ?? '',
            },
            holidayPayMultiple: dataItem.holidayPayMultiple ?? 0,
          };
        }),
      };
    });

    return {
      isTemplateCalculation: isTemplateCalculationChecked,

      salaryTypes: salaryTypes as TypeSalaryData[],

      salaryTypesWithHolidays: salaryTypesWithHolidays as TypeSalaryWithHolidays[],
    };
  }

  private static toPremiumCalculation(policyConfig: PolicySettings | null): TypeOccupationPension {
    return {
      percentageCompensation:
        policyConfig?.occupationalPension?.percentageCompensation ||
        INITIAL_OCCUPATIONAL_PENSION.isIntervalCompensation,

      fixedCompensation:
        policyConfig?.occupationalPension?.fixedCompensation || INITIAL_OCCUPATIONAL_PENSION.fixedCompensation,

      isPremium: policyConfig?.occupationalPension?.isPremium || INITIAL_OCCUPATIONAL_PENSION.isPremium,

      isIntervalCompensation:
        policyConfig?.occupationalPension?.isIntervalCompensation ||
        INITIAL_OCCUPATIONAL_PENSION.isIntervalCompensation,

      amountIndex: policyConfig?.occupationalPension?.amountIndex || INITIAL_OCCUPATIONAL_PENSION.amountIndex,

      offsetCalculation:
        policyConfig?.occupationalPension?.offsetCalculation || INITIAL_OCCUPATIONAL_PENSION.offsetCalculation,

      ageDefination: policyConfig?.occupationalPension?.ageDefination || INITIAL_OCCUPATIONAL_PENSION.ageDefination,

      calculation: policyConfig?.occupationalPension?.calculation?.length
        ? policyConfig?.occupationalPension?.calculation.map((item) => this.toIncomeLevelItem(item))
        : new Array(DEFAULT_CALCULATION_MATRIX_LENGTH[0])
            .fill(null)
            .map((_, rowIdx) =>
              new Array(DEFAULT_CALCULATION_MATRIX_LENGTH[1]).fill(null).map((item, colIdx) =>
                this.toIncomeLevelItem(item, {
                  x: `Age group ${rowIdx + 1}`,
                  y: `Income level ${colIdx + 1}`,
                } as Partial<TypeCalculation>),
              ),
            )
            .flat(),
    };
  }

  private static toIncomeLevelItem(item, defaultVal: Partial<TypeCalculation> | null = null): TypeCalculation {
    return {
      x: item?.x || defaultVal?.x || '',
      y: item?.y || defaultVal?.y || '',
      value: item?.value || null,
      age: {
        min: item?.age?.min || 0,
        max: item?.age?.max || 0,
      },
      indexAmount: {
        min: item?.indexAmount?.min || null,
        max: item?.indexAmount?.max || null,
      },
    };
  }

  private static toSpecialCondition(policyConfig: PolicySettings | null): PolicySpecialCondition {
    const specialCondition = policyConfig?.specialConditions || null;

    const probabtionaryEmployment = assignWith(
      INITIAL_POLICY_SPECIAL_CONDITION.probationaryEmployement,
      specialCondition?.probationaryEmployement,
      { ignoreUndefined: true },
    );

    const parentalLeave = assignWith(INITIAL_POLICY_SPECIAL_CONDITION.parentalLeave, specialCondition?.parentalLeave, {
      ignoreUndefined: true,
    });

    const otherLeave = assignWith(INITIAL_POLICY_SPECIAL_CONDITION.otherLeave, specialCondition?.otherLeave, {
      ignoreUndefined: true,
    });

    return {
      probationaryEmployement: {
        isChecked: probabtionaryEmployment?.isChecked,
        isPremiumCalculation: probabtionaryEmployment?.isPremiumCalculation,
        isSalaryChange: probabtionaryEmployment?.isSalaryChange,
        isInsurances: probabtionaryEmployment?.isInsurances,
      },
      parentalLeave: {
        isChecked: parentalLeave?.isChecked,
        isPremiumCalculation: parentalLeave?.isChecked,
        isSalaryChange: parentalLeave?.isChecked,
        isInsurances: parentalLeave?.isChecked,
        maximumLength: parentalLeave?.maximumLength,
      },
      otherLeave: {
        isChecked: otherLeave?.isChecked,
        isPremiumCalculation: otherLeave?.isPremiumCalculation,
        isSalaryChange: otherLeave?.isSalaryChange,
        isInsurances: otherLeave?.isInsurances,
        maximumLength: otherLeave?.maximumLength,
      },
    };
  }
}
