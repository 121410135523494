import { useState } from 'react';
import BSModal from '../../../../../components/Modal';
import { Button, Row, Spinner } from 'react-bootstrap';
import { Plus } from 'react-feather';
import BSTable from '../../../../../components/table/BSTable';
import { tableHeaderText } from '../../../../../components/table/TableHeaderFormatter';
import classNames from 'classnames';
import { CUSTOM_REPORT_TYPES } from '../../constants';
import { useTranslation } from 'react-i18next';
import { ISeries } from '../../../../../components/charts/multi-series/types';

type ChartTypeModalProps = {
  showReportTypeModal: boolean;
  reports?: any;
  toggleModal: () => void;
  addReportToChart: (employee) => void;
  preSelectedReportTypes?: ISeries[];
};

export const ChartTypeModal = ({
  showReportTypeModal,
  toggleModal,
  addReportToChart,
  preSelectedReportTypes = [],
}: ChartTypeModalProps) => {
  const { t } = useTranslation();
  const [isLoading, ,] = useState(false);
  const reportTypeDataSource = CUSTOM_REPORT_TYPES.map((item) => {
    const foundReportType = preSelectedReportTypes.find((preSelectedItem) => preSelectedItem.field === item.field);

    if (foundReportType) {
      item.isDisable = true;
    } else {
      item.isDisable = false;
    }

    return item;
  });

  const addToChartHandler = (report) => {
    addReportToChart(report);
    toggleModal();
  };

  return (
    <>
      <BSModal
        title={`${t('add_type_to_chart')}`}
        show={showReportTypeModal}
        onHide={() => {
          toggleModal();
        }}
        centered
        hideFooter={true}
        className="chart-search-employee-modal"
        // ModalFooter={<EmployeeModalFooter toggleModal={toggleModal} handleSaveChanges={handleSaveEmployeeChanges} />}
      >
        <Row className="justify-content-between align-items-end">
          {isLoading && <Spinner />}
          <div>
            <BSTable
              data={reportTypeDataSource}
              columns={[
                {
                  text: tableHeaderText('Type'),
                  dataField: 'labelKey',
                  headerStyle: { width: 200 },
                  formatter: (cell, row) => {
                    return t(cell);
                  },
                },
                {
                  dataField: 'isDisable',
                  text: '',
                  formatter: (cell, row) => {
                    return (
                      <div className={classNames('float-end')}>
                        <Button
                          className="app-btn-secondary"
                          onClick={() => addToChartHandler(row)}
                          disabled={row.isDisable}
                        >
                          <Plus className="icon-gap-right" />
                          {t('add_to_chart')}
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
              rowClass={(row) => {
                return row['isDisable'] === true ? 'selected-row' : '';
              }}
            />
          </div>
        </Row>
      </BSModal>
    </>
  );
};
