import { useEffect, useState } from 'react';
import useEmployeePensionContext from '../useContext';
import { includeKeys, isEqual } from '../../../../util/util';
import {
  EmployeePensionPolicy,
  EmployeeSalaryChange,
  MetaEmployeePolicySettings,
  TypeInsurances,
  TypePensionManager,
} from '../../../superAdmin/PensionPolicy/types';
import { EmployeePolicyRevision } from '../context/EmployeePensionContext';

const useEmployeePensionObserver = () => {
  const {
    formGroup,
    selectedEmployeePension,
    employeePolicyRevision,
    effectiveFrom,
    selectedPensionPolicy,
    employeePensionSummary,
    advisoryInvite,
    selectedPolicyType,
    policyRevisionList,

    setIsReverting,
    setAdvisoryInvite,
    updatePolicyType,
    setEmployeePolicyRevision,
    setSelectedEmployeePension,
    setSelectedPensionPolicy,
    setEffectiveFrom,
    setEmployeePensionSummary,
  } = useEmployeePensionContext();

  const [isFormModified, setIsFormModified] = useState(false);

  const hasEmployeeChosenPolicyCollectiveAgreement = (): boolean => {
    if (selectedPensionPolicy) {
      return selectedPensionPolicy.isCollectiveAgreementLock;
    }

    return false;
  };

  const getInitialFormValue = () => {
    const isCorrectoProvided = hasEmployeeChosenPolicyCollectiveAgreement() ? true : false;

    const _selectedEmployeePension: EmployeePensionPolicy | null = selectedEmployeePension || null;

    const _initialPolicySettings = _selectedEmployeePension?.policySettings || null;

    const initialChoosenPolicy = _selectedEmployeePension
      ? includeKeys(_selectedEmployeePension.currentPolicy, ['id', 'type'])
      : null;

    const initialEffectiveDate = _selectedEmployeePension ? _selectedEmployeePension.effectiveFrom : null;

    const initialAdvisoryInvite =
      employeePensionSummary === null ? false : employeePensionSummary?.current?.advisoryInvite || false;

    const salaryChange: Record<keyof EmployeeSalaryChange, { offset: number | null; effectiveFrom: string }> = {
      fixedOffset: {
        offset: _initialPolicySettings?.salaryChange?.fixedMonthlyOffset?.offset || null,
        effectiveFrom: _initialPolicySettings?.salaryChange?.fixedMonthlyOffset?.effectiveFrom || '',
      },
      oneTimeOffset: {
        offset: _initialPolicySettings?.salaryChange?.manualOffset?.offset || null,
        effectiveFrom: _initialPolicySettings?.salaryChange?.manualOffset?.effectiveFrom || '',
      },
      percentageOfSalaryOffset: {
        offset: _initialPolicySettings?.salaryChange?.percentageOfSalaryOffset?.offset || null,
        effectiveFrom: _initialPolicySettings?.salaryChange?.percentageOfSalaryOffset?.effectiveFrom || '',
      },
    };

    /*** Pension Manager Plans **/
    const originalPolicyPensionManagers = selectedPensionPolicy?.policySettings?.pensionManagers || [];
    const employeeAssignedPensionManagers = _initialPolicySettings?.pensionManagers || [];

    const allowCorrectoProvidedPlans =
      isCorrectoProvided && employeeAssignedPensionManagers.length === 0 && originalPolicyPensionManagers.length > 0;

    const pensionManagers: TypePensionManager[] = (
      allowCorrectoProvidedPlans ? originalPolicyPensionManagers : employeeAssignedPensionManagers
    )?.map((item) => {
      return {
        pensionManagerId: item.pensionManagerId || '',
        planName: item.planName || '',
        fromOccupationalPension: {
          customerNumber: item?.fromOccupationalPension?.customerNumber || '',
          insuranceNumber: item?.fromOccupationalPension?.insuranceNumber || '',
          isDefault: item?.fromOccupationalPension?.isDefault || false,
        },
        fromSalaryChange: {
          customerNumber: item?.fromSalaryChange?.customerNumber || '',
          insuranceNumber: item?.fromSalaryChange?.insuranceNumber || '',
          isDefault: item?.fromSalaryChange?.isDefault || false,
        },
      };
    });

    /*** Insurance Plans **/

    const originalPolicyInsurances = selectedPensionPolicy?.policySettings?.insurances || [];
    const employeeAssignedInsurances = _initialPolicySettings?.insurances || [];

    const allowCorrectoProvidedInsurances =
      isCorrectoProvided && employeeAssignedInsurances.length === 0 && originalPolicyInsurances.length > 0;

    const insurances: Partial<TypeInsurances>[] = (
      allowCorrectoProvidedInsurances ? originalPolicyInsurances : employeeAssignedInsurances
    )?.map((item) => {
      return {
        insuranceId: item.insuranceId || '',
        plans: (item.plans || []).map((planItem) => {
          return {
            categoryName: planItem?.categoryName || '',
            isChecked: planItem?.isChecked || false,
            planName: planItem?.planName || '',
          };
        }),
      };
    });

    const employeeMetaSettings: MetaEmployeePolicySettings = {
      insurance: _initialPolicySettings?.employeeMetaSettings?.insurance || {
        effectiveFrom: '',
      },
      pensionManager: _initialPolicySettings?.employeeMetaSettings?.pensionManager || {
        effectiveFrom: '',
      },
    };

    return {
      policy: initialChoosenPolicy,
      effectiveFrom: initialEffectiveDate,
      advisoryInvite: initialAdvisoryInvite,
      salaryChange,
      pensionManagers,
      insurances,
      employeeMetaSettings,
    };
  };

  const getUpdatedFormValue = () => {
    const _currentRevision: EmployeePolicyRevision | null = employeePolicyRevision || null;

    const _currentPolicySettings = formGroup.values?.policySettings;

    const currentChoosenPolicy = _currentRevision
      ? {
          id: _currentRevision?.currentPolicy?.id,
          type: _currentRevision?.currentPolicy?.type,
        }
      : null;

    const currentEffectiveDate = effectiveFrom || null;

    const currentAdvisoryInvite = advisoryInvite || false;

    const salaryChange: Record<keyof EmployeeSalaryChange, { offset: number | null; effectiveFrom: string }> = {
      fixedOffset: {
        offset: _currentPolicySettings?.salaryChange?.fixedOffset?.offset || null,
        effectiveFrom: _currentPolicySettings?.salaryChange?.fixedOffset?.effectiveFrom || '',
      },
      oneTimeOffset: {
        offset: _currentPolicySettings?.salaryChange?.oneTimeOffset?.offset || null,
        effectiveFrom: _currentPolicySettings?.salaryChange?.oneTimeOffset?.effectiveFrom || '',
      },
      percentageOfSalaryOffset: {
        offset: _currentPolicySettings?.salaryChange?.percentageOfSalaryOffset?.offset || null,
        effectiveFrom: _currentPolicySettings?.salaryChange?.percentageOfSalaryOffset?.effectiveFrom || '',
      },
    };

    const pensionManagers: TypePensionManager[] = (_currentPolicySettings?.pensionManagers || [])?.map((item) => {
      return {
        pensionManagerId: item.pensionManagerId || '',
        planName: item.planName || '',
        fromOccupationalPension: {
          customerNumber: item?.fromOccupationalPension?.customerNumber || '',
          insuranceNumber: item?.fromOccupationalPension?.insuranceNumber || '',
          isDefault: item?.fromOccupationalPension?.isDefault || false,
        },
        fromSalaryChange: {
          customerNumber: item?.fromSalaryChange?.customerNumber || '',
          insuranceNumber: item?.fromSalaryChange?.insuranceNumber || '',
          isDefault: item?.fromSalaryChange?.isDefault || false,
        },
      };
    });

    const insurances: Partial<TypeInsurances>[] = (_currentPolicySettings?.insurances || [])?.map((item) => {
      return {
        insuranceId: item.insuranceId || '',
        plans: (item.plans || []).map((planItem) => {
          return {
            categoryName: planItem?.categoryName || '',
            isChecked: planItem?.isChecked || false,
            planName: planItem?.planName || '',
          };
        }),
      };
    });

    const employeeMetaSettings: MetaEmployeePolicySettings = {
      insurance: _currentPolicySettings?.employeeMetaSettings?.insurance || {
        effectiveFrom: '',
      },
      pensionManager: _currentPolicySettings?.employeeMetaSettings?.pensionManager || {
        effectiveFrom: '',
      },
    };

    return {
      policy: currentChoosenPolicy,
      effectiveFrom: currentEffectiveDate,
      advisoryInvite: currentAdvisoryInvite,
      salaryChange,
      pensionManagers,
      insurances,
      employeeMetaSettings,
    };
  };

  const revertChanges = () => {
    if (employeePensionSummary && employeePensionSummary.current) {
      setEmployeePensionSummary({ ...employeePensionSummary });

      setIsReverting(true);
      /* setTimeout(() => {
        let choosenPolicyType = PensionPolicyCategory.NO_POLICY;
        const employeeAssignedPolicy = EmployeePensionRecord.toPolicySettings(
          employeePensionSummary!.current!,
          employeePensionSummary!.employeeId,
        );

        setEmployeePensionSummary({ ...employeePensionSummary });

        //   console.log('selectedEmployeePension______', deepClone(selectedEmployeePension));
        //   console.log('employeePensionSummary_________', deepClone(employeePensionSummary));
        if (employeeAssignedPolicy?.id && selectedPensionPolicy) {
          choosenPolicyType = findPolicyType(selectedPensionPolicy!.type)!;

          setSelectedPensionPolicy({ ...selectedPensionPolicy });
        }

        if (choosenPolicyType !== PensionPolicyCategory.NO_POLICY) {
          updateAdminPolicyRevisionSettings(
            selectedEmployeePension.currentPolicy.id,
            selectedEmployeePension,
            selectedEmployeePension.currentPolicy.type === PensionPolicyVariant.PERSONAL ? 'personal' : 'admin',
          );

          setSelectedEmployeePension({ ...selectedEmployeePension });

          if (employeeAssignedPolicy!.policySettings) {
            setEmployeePolicyRevision({
              ...employeeAssignedPolicy,
            });
          }

          setAdvisoryInvite(false);
        } else {
          console.log('selectedPensionPolicyselectedPensionPolicy', selectedPensionPolicy);
          setSelectedPensionPolicy(selectedPensionPolicy);
          // console.log('deeeperrrrr', deepClone(employeeAssignedPolicy));
          // console.log('deeeperrrrr', deepClone(selectedEmployeePension));
          updateAdminPolicyRevisionSettings(
            selectedEmployeePension?.currentPolicy?.id,
            selectedEmployeePension,
            'admin',
          );
          setSelectedEmployeePension(selectedEmployeePension);

          if (employeeAssignedPolicy!.policySettings) {
            setEmployeePolicyRevision({
              ...employeeAssignedPolicy,
            });
            formGroup.setFieldValue('policySettings', employeeAssignedPolicy!.policySettings);
          }
        }

        setEffectiveFrom(employeePensionSummary.current!.effectiveFrom!);
        setAdvisoryInvite(employeePensionSummary?.current?.advisoryInvite || false);
        updatePolicyType(choosenPolicyType);
      }); */
    } else {
      formGroup.setFieldValue('policySettings', {});
      setSelectedPensionPolicy(null);
      setSelectedEmployeePension(null);
      setEmployeePolicyRevision(null);
      updatePolicyType(null);
      setAdvisoryInvite(false);
      setEffectiveFrom('');
    }
  };

  useEffect(() => {
    const initialFormValue = getInitialFormValue();
    const updatedFormValue = getUpdatedFormValue();
    /* console.log('initialFormValue', initialFormValue);
    console.log('updatedFormValue', updatedFormValue);

    console.log('isEqual', isEqual(initialFormValue, updatedFormValue)); */

    if (isEqual(initialFormValue, updatedFormValue)) {
      setIsFormModified(false);
    } else {
      setIsFormModified(true);
    }
  }, [
    employeePensionSummary,
    selectedEmployeePension,
    employeePolicyRevision,
    selectedPensionPolicy,
    policyRevisionList,
    advisoryInvite,
    selectedPolicyType,
    formGroup.values,
    effectiveFrom,
  ]);

  return {
    isFormModified,
    revertChanges,
  };
};

export default useEmployeePensionObserver;
