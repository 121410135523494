import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PagesHeader from '../../../../components/base/PagesHeader';
import { Link } from 'react-router-dom';
import { FormProps } from '../types';

export const AccessLevel = ({ context }: FormProps) => {
  const { t } = useTranslation();
  const { replaceAccessLevel, formGroup } = context;

  const handleCorrectoRemoteAccess = (e) => {
    replaceAccessLevel(e.target.checked, formGroup.values.allowAdvisorAccess);
  };

  const handleAdvisorRemoteAccess = (e) => {
    replaceAccessLevel(formGroup.values.allowCorrectoAccess, e.target.checked);
  };

  return (
    <>
      <div>
        <PagesHeader pageTitle="Correcto remote" headingClass="fs-h3 font-black" />
        <div className="form-section dark-content-section mb-3">
          <p className="theme-text mb-2">
            {t('ACCESS_LEVEL.allowing_correcto_remote_text')},<Link to={'#'}> {t('ACCESS_LEVEL.read_more_here')}</Link>{' '}
            .
          </p>
          <Form.Check
            label={t('ACCESS_LEVEL.allow_correcto_remote_access')}
            inline
            type="checkbox"
            id={`remoteAccess`}
            className="fs-name"
            onChange={(e) => handleCorrectoRemoteAccess(e)}
            checked={formGroup.values.allowCorrectoAccess}
          />
        </div>
      </div>
      <div className="mb-3">
        <PagesHeader pageTitle="Insurance advisor remote" headingClass="fs-h3 font-black" />
        <div className="form-section dark-content-section">
          <p className="theme-text mb-2">
            {t('ACCESS_LEVEL.allowing_insurance_advisor_remote_text')},
            <Link to={'#'}> {t('ACCESS_LEVEL.read_more_here')}</Link> .
          </p>
          <Form.Check
            label={t('ACCESS_LEVEL.allow_insurance_advisor_remote_access')}
            inline
            type="checkbox"
            id={`remoteAccess2`}
            className="fs-name "
            onChange={(e) => handleAdvisorRemoteAccess(e)}
            checked={formGroup.values.allowAdvisorAccess}
          />
        </div>

        {/* <div className="form-section mb-3 dark-content-section consultant-detail">
          {accessLevelEditor?.advisorRemoteStaff?.map((item, idx) => (
            <div key={item.id}>
              {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

              <Row className="align-items-center mb-3">
                <Col md="3">
                  <div className="consultant-title">
                    <div className="consultant-title__avatar">
                      <span className="consultant-title-placeholder">{getInitialName(item.fullName) || ''}</span>
                    </div>
                    <div className="consultant-title__name theme-text fs-14px">{item.fullName}</div>
                  </div>
                </Col>
                <Col style={{ maxWidth: 170 }}>
                  <span className="theme-text fs-14px">{item.personalNumber}</span>
                </Col>

                <Col style={{ maxWidth: 250 }}>
                  <span className="theme-text text-primary fs-14px">{item.role}</span>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <span className="me-2 mb-0">
                    <UserX
                      onClick={() => {
                        accessLevelEditor.removeAdvisor(item.personalNumber);
                      }}
                      className="cursor-pointer delete-icon"
                    />
                  </span>
                </Col>
              </Row>
            </div>
          ))}
        </div> */}
      </div>
    </>
  );
};
