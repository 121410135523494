import { Form } from 'react-bootstrap';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CardComponent from '../../../../components/base/CardComponent';
import PagesHeader from '../../../../components/base/PagesHeader';
import { Link } from 'react-router-dom';
import { deepClone } from '../../../../util/util';
import useAccessLevelEditor from './useAccessLevelEditor';
import { AccessLevelRef } from './types';
import { useEditGeneralSettingsContext } from '../General/context/useContext';
import ConsultantAccess from './ConsultantAccess';

interface InputProps {
  emitValues: (...args) => void;
}
export const AccessLevel = ({ emitValues }: InputProps) => {
  const { t } = useTranslation();
  const accessLevelEditor = useAccessLevelEditor();
  const {
    consultants,
    replaceAccess,
    replaceConsultants,
    allowAdvisorRemoteAccess,
    allowCorrectoRemoteAccess,
    appendDeletedConsultant,
  } = useEditGeneralSettingsContext();

  const handleCorrectoRemoteAccess = (e) => {
    replaceAccess(e.target.checked, allowAdvisorRemoteAccess);
  };

  const handleAdvisorRemoteAccess = (e) => {
    replaceAccess(allowCorrectoRemoteAccess, e.target.checked);
  };

  const handleRemoveConsultant = (consultant) => {
    accessLevelEditor.setConsultantsAccess(
      accessLevelEditor.consultantsAccess.filter((item) => item.personalNumber !== consultant.personalNumber),
    );
    replaceConsultants(
      accessLevelEditor.consultantsAccess.filter((item) => item.personalNumber !== consultant.personalNumber),
    );
    appendDeletedConsultant(consultant.id || consultant._id);
  };

  useEffect(() => {
    if (consultants.length > 0) {
      accessLevelEditor.setConsultantsAccess(deepClone(consultants));
    }
  }, [consultants.length]);

  useEffect(() => {
    emitValues({
      isSavingAccessLevel: accessLevelEditor.isLoading,
      saveAccessLevel: accessLevelEditor.save,
      hasAccessLevelChanges: accessLevelEditor.hasAccessLevelChanges,
      cancelAccessLevelChanges: accessLevelEditor.cancelAccessLevelChanges,
    } as AccessLevelRef);
  }, [
    accessLevelEditor.isLoading,
    accessLevelEditor.save,
    accessLevelEditor.hasAccessLevelChanges,
    accessLevelEditor.cancelAccessLevelChanges,
  ]);

  return (
    <CardComponent>
      <PagesHeader pageTitle="Access" headingClass="fs-h2 font-black" />
      {accessLevelEditor.consultantsAccess.length > 0 && (
        <ConsultantAccess
          consultants={accessLevelEditor.consultantsAccess}
          handleRemoveConsultant={handleRemoveConsultant}
        />
      )}
      <div>
        <PagesHeader pageTitle="Correcto remote" headingClass="fs-h3 font-black" />
        <p className="theme-text">
          {t('ACCESS_LEVEL.allowing_correcto_remote_text')},<Link to={'#'}> {t('ACCESS_LEVEL.read_more_here')}</Link> .
        </p>
        <Form.Check
          label={t('ACCESS_LEVEL.allow_correcto_remote_access')}
          inline
          type="checkbox"
          id={`remoteAccess`}
          className="fs-name mb-3"
          onChange={(e) => handleCorrectoRemoteAccess(e)}
          checked={allowCorrectoRemoteAccess}
        />
        {/* <div className="form-section mb-3 dark-content-section consultant-detail">
          {accessLevelEditor?.correctoRemoteStaff?.map((item, idx) => (
            <div key={item.id}>
              {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

              <Row className="align-items-center mb-3">
                <Col md="3">
                  <div className="consultant-title">
                    <div className="consultant-title__avatar">
                      <span className="consultant-title-placeholder">{getInitialName(item.fullName) || ''}</span>
                    </div>
                    <div className="consultant-title__name theme-text fs-14px">{item.fullName}</div>
                  </div>
                </Col>
                <Col style={{ maxWidth: 170 }}>
                  <span className="theme-text fs-14px">{item.personalNumber}</span>
                </Col>

                <Col style={{ maxWidth: 250 }}>
                  <span className="theme-text text-primary fs-14px">{item.role}</span>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <span className="me-2 mb-0">
                    <UserX
                      onClick={() => {
                        accessLevelEditor.removeAdvisor(item.personalNumber);
                      }}
                      className="cursor-pointer delete-icon"
                    />
                  </span>
                </Col>
              </Row>
            </div>
          ))}
        </div> */}
      </div>
      <div className="mt-2">
        <PagesHeader pageTitle="Insurance advisor remote" headingClass="fs-h3 font-black" />
        <p className="theme-text">
          {t('ACCESS_LEVEL.allowing_insurance_advisor_remote_text')},
          <Link to={'#'}> {t('ACCESS_LEVEL.read_more_here')}</Link> .
        </p>
        <Form.Check
          label={t('ACCESS_LEVEL.allow_insurance_advisor_remote_access')}
          inline
          type="checkbox"
          id={`remoteAccess2`}
          className="fs-name mb-3"
          onChange={(e) => handleAdvisorRemoteAccess(e)}
          checked={allowAdvisorRemoteAccess}
        />
        {/* <div className="form-section mb-3 dark-content-section consultant-detail">
          {accessLevelEditor?.advisorRemoteStaff?.map((item, idx) => (
            <div key={item.id}>
              {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

              <Row className="align-items-center mb-3">
                <Col md="3">
                  <div className="consultant-title">
                    <div className="consultant-title__avatar">
                      <span className="consultant-title-placeholder">{getInitialName(item.fullName) || ''}</span>
                    </div>
                    <div className="consultant-title__name theme-text fs-14px">{item.fullName}</div>
                  </div>
                </Col>
                <Col style={{ maxWidth: 170 }}>
                  <span className="theme-text fs-14px">{item.personalNumber}</span>
                </Col>

                <Col style={{ maxWidth: 250 }}>
                  <span className="theme-text text-primary fs-14px">{item.role}</span>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <span className="me-2 mb-0">
                    <UserX
                      onClick={() => {
                        accessLevelEditor.removeAdvisor(item.personalNumber);
                      }}
                      className="cursor-pointer delete-icon"
                    />
                  </span>
                </Col>
              </Row>
            </div>
          ))}
        </div> */}
      </div>
    </CardComponent>
  );
};
