import { createContext, useState } from 'react';
import {
  INITIAL_ADDRESS_DETAILS,
  INITIAL_COMPANY_DETAILS,
  INITIAL_FIRM_DETAILS,
  INITIAL_PROVISION_AGREEMENT,
} from '../constants';
import {
  AccountingFirmFormGroup,
  AddressDetails,
  AdminDetails,
  AuthorizedSignatory,
  CollectiveAgreementType,
  CompanyDetails,
  Consultants,
  FirmDetails,
  IAccountingFirm,
  IAccountingFirmContext,
  LooseObject,
  ProvisionAgreement,
  SeniorConsultants,
  Superviors,
} from '../types';
import { AuthorizedSignatoryGroup, ResellerAgreementType } from '../../CompanyClients/types';
import { useFormik } from 'formik';
import { formSchema } from './schema';

export const EditAccountingFirmContext = createContext<IAccountingFirmContext>({
  firmDetails: INITIAL_FIRM_DETAILS,
  setFirmDetails: () => {},

  companyDetails: INITIAL_COMPANY_DETAILS,
  setCompanyDetails: () => {},

  adminDetails: [],
  setAdminDetails: () => {},

  signatoryDetails: [],
  setSignatoryDetails: () => {},

  addressDetails: INITIAL_ADDRESS_DETAILS,
  setAddressDetails: () => {},

  seniorConsultants: [],
  setSeniorConsultants: () => {},

  consultants: [],
  setConsultants: () => {},

  supervisors: [],
  setSupervisors: () => {},

  contractEffectiveFrom: '',
  setContractEffectiveFrom: () => {},

  collectiveAgreements: [],
  setCollectiveAgreements: () => {},

  resellerAgreements: [],
  setResellerAgreements: () => {},

  invoicingCostCenter: '',
  setInvoicingCostCenter: () => {},

  invitationEmail: '',
  setInvitationEmail: () => {},

  invoiceEmailAddress: '',
  setInvoiceEmailAddress: () => {},

  provisionAgreement: INITIAL_PROVISION_AGREEMENT,
  setProvisionAgreement: () => {},

  isEditMode: true,
  formGroup: {} as AccountingFirmFormGroup,

  signatoryAdmins: [],
  setSignatoryAdmins: () => {},

  combinations: [],
  setCombinations: () => {},

  deletedSupervisors: [],
  setDeletedSupervisors: () => {},

  deletedConsultants: [],
  setDeletedConsultants: () => {},

  updateFirmDetailsInput: () => {},
  updateAddressInput: () => {},
  updateContractEffectiveInput: () => {},
  updateInvoicingCostCenter: () => {},
  updateInvoiceEmailAddress: () => {},
  replaceSupervisorsInput: () => {},
  replaceSignatoriesInput: () => {},
  replaceSeniorConsultantsInput: () => {},
  updateInvitationEmailInput: () => {},
  replaceFirmDetailsInput: () => {},
  replaceAddressInput: () => {},
  updateProvisionAgreementInput: () => {},
  replaceSignatoryAdmins: () => {},
  replaceCombinations: () => {},
  updateCombinations: () => {},
  appendDeletedSupervisor: () => {},
  appendDeletedConsultant: () => {},
});

type Props = {
  children: JSX.Element;
};

export const INITIAL_ACCOUNTING_FIRM = {
  firmDetails: {
    ...INITIAL_FIRM_DETAILS,
  },
  addressDetails: INITIAL_ADDRESS_DETAILS,
  contractEffectiveFrom: '',
  invoicingCostCenter: '',
  invoiceEmailAddress: '',
  combinations: [],
  supervisors: [],
  seniorConsultants: [],
  signatoryDetails: [],
  invitationEmail: '',
  provisionAgreement: INITIAL_PROVISION_AGREEMENT,
} as IAccountingFirm;

export const EditAccountingFirmProvider = ({ children }: Props) => {
  const formGroup = useFormik({
    initialValues: INITIAL_ACCOUNTING_FIRM,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const [companyDetails, setCompanyDetails] = useState<CompanyDetails | LooseObject>(INITIAL_COMPANY_DETAILS);
  const [firmDetails, setFirmDetails] = useState<FirmDetails>(INITIAL_FIRM_DETAILS);
  const [addressDetails, setAddressDetails] = useState<AddressDetails | LooseObject>(INITIAL_ADDRESS_DETAILS);
  const [adminDetails, setAdminDetails] = useState<Array<AdminDetails>>([]);
  const [signatoryDetails, setSignatoryDetails] = useState<Array<AuthorizedSignatory>>([]);
  const [consultants, setConsultants] = useState<Array<Consultants>>([]);
  const [supervisors, setSupervisors] = useState<Array<Superviors>>([]);
  const [seniorConsultants, setSeniorConsultants] = useState<Array<SeniorConsultants>>([]);
  const [contractEffectiveFrom, setContractEffectiveFrom] = useState<string>('');
  const [invoicingCostCenter, setInvoicingCostCenter] = useState<string>('');
  const [invitationEmail, setInvitationEmail] = useState<string>('');
  const [invoiceEmailAddress, setInvoiceEmailAddress] = useState<string>('');
  const [collectiveAgreements, setCollectiveAgreements] = useState<Array<CollectiveAgreementType>>([]);
  const [resellerAgreements, setResellerAgreements] = useState<Array<ResellerAgreementType>>([]);
  const [provisionAgreement, setProvisionAgreement] = useState<ProvisionAgreement>(INITIAL_PROVISION_AGREEMENT);
  const [deletedSupervisors, setDeletedSupervisors] = useState<Array<string>>([]);
  const [deletedConsultants, setDeletedConsultants] = useState<Array<string>>([]);
  const [signatoryAdmins, setSignatoryAdmins] = useState<Array<AuthorizedSignatory>>([]);
  const [combinations, setCombinations] = useState<Array<AuthorizedSignatoryGroup>>([]);
  const isEditMode = true;

  const updateFirmDetailsInput = (inputName, inputValue) => {
    setFirmDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`firmDetails.${inputName}`, inputValue);
  };

  const updateAddressInput = (inputName, inputValue) => {
    setAddressDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`addressDetails.${inputName}`, inputValue);
  };

  const replaceFirmDetailsInput = (inputValue) => {
    setFirmDetails(inputValue);
    formGroup.setFieldValue('firmDetails', inputValue);
    formGroup.setFieldTouched('firmDetails');
  };

  const replaceAddressInput = (inputValue) => {
    setAddressDetails(inputValue);
    formGroup.setFieldValue('addressDetails', inputValue);
    formGroup.setFieldTouched('addressDetails');
  };

  const updateContractEffectiveInput = (inputValue) => {
    setContractEffectiveFrom(inputValue);
    formGroup.setFieldValue(`contractEffectiveFrom`, inputValue);
  };

  const updateInvoicingCostCenter = (inputValue) => {
    setInvoicingCostCenter(inputValue);
    formGroup.setFieldValue(`invoicingCostCenter`, inputValue);
  };

  const updateInvoiceEmailAddress = (inputValue) => {
    setInvoiceEmailAddress(inputValue);
    formGroup.setFieldValue(`invoiceEmailAddress`, inputValue);
  };

  const replaceSupervisorsInput = (inputValue) => {
    setSupervisors(inputValue);
    formGroup.setFieldValue('supervisors', inputValue);
  };

  const replaceSeniorConsultantsInput = (inputValue) => {
    setSeniorConsultants(inputValue);
    formGroup.setFieldValue('seniorConsultants', inputValue);
  };

  const replaceSignatoriesInput = (inputValue) => {
    setSignatoryDetails(inputValue);
    formGroup.setFieldValue('signatoryDetails', inputValue);
  };

  const updateInvitationEmailInput = (inputValue) => {
    setInvitationEmail(inputValue);
    formGroup.setFieldValue(`invitationEmail`, inputValue);
  };

  const updateProvisionAgreementInput = (inputValue) => {
    setProvisionAgreement(inputValue);
    formGroup.setFieldValue(`provisionAgreement`, inputValue);
  };

  const replaceSignatoryAdmins = (value) => {
    setSignatoryAdmins(value);
    formGroup.setFieldValue('signatoryAdmins', value);
  };

  const replaceCombinations = (value) => {
    setCombinations(value);
    formGroup.setFieldValue('combinations', value);
  };

  const updateCombinations = (inputName, inputValue) => {
    setCombinations((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
    formGroup.setFieldValue(`combinations.${inputName}`, inputValue);
  };

  const appendDeletedSupervisor = (value) => {
    if (!isEditMode) {
      return;
    }

    const _deletedSupervisors = [...deletedSupervisors];
    _deletedSupervisors.push(value);

    setDeletedSupervisors(_deletedSupervisors);
  };

  const appendDeletedConsultant = (value) => {
    if (!isEditMode) {
      return;
    }

    const _deletedConsultants = [...deletedConsultants];
    _deletedConsultants.push(value);

    setDeletedConsultants(_deletedConsultants);
  };

  const context: IAccountingFirmContext = {
    firmDetails,
    setFirmDetails,
    companyDetails,
    setCompanyDetails,
    adminDetails,
    setAdminDetails,
    signatoryDetails,
    setSignatoryDetails,
    addressDetails,
    setAddressDetails,
    contractEffectiveFrom,
    setContractEffectiveFrom,
    isEditMode: true,
    collectiveAgreements,
    setCollectiveAgreements,
    resellerAgreements,
    setResellerAgreements,
    invoicingCostCenter,
    setInvoicingCostCenter,
    invoiceEmailAddress,
    setInvoiceEmailAddress,
    consultants,
    setConsultants,
    supervisors,
    setSupervisors,
    seniorConsultants,
    setSeniorConsultants,
    invitationEmail,
    setInvitationEmail,
    provisionAgreement,
    setProvisionAgreement,
    formGroup,
    signatoryAdmins,
    setSignatoryAdmins,
    combinations,
    setCombinations,
    deletedSupervisors,
    setDeletedSupervisors,
    deletedConsultants,
    setDeletedConsultants,
    updateFirmDetailsInput,
    updateAddressInput,
    updateContractEffectiveInput,
    updateInvoicingCostCenter,
    updateInvoiceEmailAddress,
    replaceSupervisorsInput,
    replaceSignatoriesInput,
    replaceSeniorConsultantsInput,
    updateInvitationEmailInput,
    replaceFirmDetailsInput,
    replaceAddressInput,
    updateProvisionAgreementInput,
    replaceSignatoryAdmins,
    appendDeletedSupervisor,
    appendDeletedConsultant,
    replaceCombinations,
    updateCombinations,
  };

  return <EditAccountingFirmContext.Provider value={context}>{children}</EditAccountingFirmContext.Provider>;
};
