import { PERSONAL_NUMBER_DIGITS } from '../../../config/config';
import React from 'react';
import InputMask from 'react-input-mask';
import { Form } from 'react-bootstrap';
import ErrorFeedback from '../../ErrorFeedback';
import classNames from 'classnames';
import { errorHighlighter, requiredError } from '../../../util/form';
import useLocale from '../../../hooks/useLocale';
import { isValueEmpty } from '../../../util/util';
interface PersonalNumberProps {
  disabled?: boolean;
  field?: any;
  value?: string;
  maxWidth?: number;
  isRequiredError?: boolean;
  customLabel?: string;
  labelStyle?: Object;
  children?: React.ReactNode;
  showInputOnly?: boolean;
  isValid?: boolean;
  lazy?: boolean;
  onChangeHandler?: (e) => void;
}

export default function PersonalNumber({
  field,
  disabled,
  value,
  maxWidth,
  customLabel,
  isRequiredError = false,
  labelStyle,
  children,
  showInputOnly = false,
  isValid,
  lazy,
  onChangeHandler,
}: PersonalNumberProps) {
  const { localize } = useLocale();
  let mask = 'YdDDmMxy-DDDD';
  let formatChars = {
    Y: '[1|2]',
    d: '[0-9]',
    D: '[0-9]',
    m: '[0-1]',
    M: '[0-9]',
    x: '[0|1|2|3]',
    y: '[0-9]',
  };

  let beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;
    const monthValue = value.charAt(4);
    const dateValue = value.charAt(6);

    if (value.startsWith('1')) formatChars['d'] = '[9]';
    if (value.startsWith('2')) formatChars['d'] = '[0]';

    if (monthValue === '0') {
      formatChars['M'] = '[1-9]';
    }
    if (monthValue === '1') {
      formatChars['M'] = '[0-2]';
    }
    if (dateValue === '0' || dateValue === '1') {
      formatChars['y'] = '[1-9]';
    }
    if (dateValue === '2') {
      formatChars['y'] = '[0-9]';
    }
    if (dateValue === '3') {
      formatChars['y'] = '[0-1]';
    }
    return { value, selection: newState.selection };
  };

  return (
    <>
      <div className='personal-num-field'>
        <Form.Label
          className={classNames(
            'fs-name',
            'mb-0 ',
            `${isRequiredError ? requiredError(field) : field ? 'required' : ''}`,
          )} 
        >
          {customLabel ? customLabel : `${localize('personal_number')}`}{' '}
          <span className={'fs-name font-weight-400 theme-text'}>{`(${PERSONAL_NUMBER_DIGITS} ${localize(
            'digits',
          )})`}</span>
        </Form.Label>
        <InputMask
          value={value}
          onChange={onChangeHandler}
          disabled={disabled}
          name="personalNumber"
          mask={mask}
          maskChar=""
          formatChars={formatChars}
          beforeMaskedValueChange={beforeMaskedValueChange}
          className={classNames('form-control', field ? errorHighlighter(field) : '')}
        ></InputMask>
        {field && <ErrorFeedback field={field} />}

        {lazy && !isValueEmpty(value) && isValid === false && (
          <span className={classNames('color-error', field.error ? 'mt-3' : '')}>Personal number not found</span>
        )}
      </div>
      {/* <BaseInput
        onChangeHandler={onChangeHandler}
        value={value}
        disabled={disabled}
        name="personalNumber"
        label={customLabel ? customLabel : `${t('personal_number')}`}
        labelSuffix={`(${PERSONAL_NUMBER_DIGITS} ${t('digits')})`}
        field={field}
        maxWidth={maxWidth}
        isRequiredError={isRequiredError}
        labelStyle={labelStyle}
      />
      {children} */}
    </>
  );
}
