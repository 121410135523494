import { createContext, useState, SetStateAction, Dispatch, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  // getPensionCompanies,
  getPolicies,
} from '../../../../api/service/superAdmin/pension-policies/service';
import { getSalaryIntervalByYear } from '../../../../api/service/superAdmin/settings/service';
import { SuperAdminPensionPolicyCompaniesData, SuperAdminPensionPolicyData } from '../../../../dummy/data';
import { ITableValues } from '../../../../types/base/TableTypes';
import {
  INITAL_ADMIN_POLICY_FORM,
  INITIAL_OCCUPATIONAL_PENSION,
  INITIAL_SALARY_CHNAGE,
  INITIAL_VALUE_PENSION_POLICY,
  INITIAL_SALARY_TYPE,
  INITIAL_EMPLOYEE_POLICY_SETTINGS,
  INITIAL_POLICY_SPECIAL_CONDITION,
} from '../constants';
import {
  PolicyForm,
  PensionPolicyFormGroup,
  PensionPolicy,
  TypeOccupationPension,
  TypeSalaryChange,
  ActionMode,
  SalaryType,
  PensionPolicyMode,
  IReloadProps,
  IEmployeeInfoInput,
  CommonPolicy,
} from '../types';
import { useFormik } from 'formik';
import { buildSchema } from './schema';
import { AuthUser, UserRole } from '../../../../types/auth';
import { deepClone, deepMerge } from '../../../../util/util';

export interface IPensionPolicyContext {
  tabKey: string;
  setTabKey: any;
  isBusy: boolean;
  mounted: boolean;
  setIsBusy: Dispatch<SetStateAction<boolean>>;
  policyForm: PolicyForm | any;
  setPolicyForm: Dispatch<SetStateAction<PolicyForm>>;
  occupationalPension: TypeOccupationPension;
  setOccupationalPension: Dispatch<SetStateAction<TypeOccupationPension>>;
  salaryChange: TypeSalaryChange;
  setSalaryChange: Dispatch<SetStateAction<TypeSalaryChange>>;
  salaryTypeWithHolidays: SalaryType;
  setSalaryTypeWithHolidays: Dispatch<SetStateAction<SalaryType>>;
  pensionCompanies: ITableValues<typeof SuperAdminPensionPolicyCompaniesData>;
  setPensionCompanies: Dispatch<SetStateAction<ITableValues<typeof SuperAdminPensionPolicyCompaniesData>>>;
  fetchAndSetPolicies: Function;
  pensionPolicies: ITableValues<typeof SuperAdminPensionPolicyData>;
  salaryInterval: number;
  formGroup: PensionPolicyFormGroup;
  selectedPolicyDetails: any;
  setSelectedPolicyDetails: Dispatch<SetStateAction<any>>;
  preAssignedPolicy: any;
  setPreAssignedPolicy: Dispatch<SetStateAction<any>>;
  isResetPolicy: boolean;
  setIsResetPolicy: Dispatch<SetStateAction<boolean>>;
  isCancelEvent: boolean;
  setIsCancelEvent: Dispatch<SetStateAction<boolean>>;
  employeeInputInfo: IEmployeeInfoInput | null;
  setEmployeeInputInfo: Dispatch<SetStateAction<IEmployeeInfoInput | null>>;
  externalPolicyRevision: CommonPolicy | null;
  setExternalPolicyRevision: Dispatch<SetStateAction<CommonPolicy | null>>;
  actionMode: ActionMode;
  isPensionable: boolean;
  pensionPolicyMode: PensionPolicyMode;
  setIsPensionable: Dispatch<SetStateAction<boolean>>;
  setActionMode: Dispatch<SetStateAction<ActionMode>>;
  setPensionPolicyMode: Dispatch<SetStateAction<PensionPolicyMode>>;
  replacePolicyInput: (inputValue: any) => void;
  updatePolicyFieldInput: (inputName: string, inputValue: any) => void;
  updatePolicySettingsInput: (inputName: string, inputValue: any) => void;
  getPolicySettingsInput: (inputName: string, inputValue?: any) => any;
  updateOccupationalPensionFieldInput: (inputName: string, inputValue: any) => void;
  updateSalaryChangeFieldInput: (inputName: string, inputValue: any) => void;
  updatePensionCompaniesInput: (inputValue: any) => void;
  replaceOccupationalPensionInput: (inputValue: any) => void;
  replaceSalaryChangeInput: (inputValue: any) => void;
  updateSalarySetupInput: (inputName: string, inputValue: any) => void;
  replaceSalarySetupInput: (inputValue: any) => void;
  patchSelectedPensionPolicy: (inputValue: any) => void;
  reloadToDefault: (params?: Partial<IReloadProps> | null) => void;
  isEditorMode: () => boolean;
  hasEditAction: () => boolean;
  triggerCancelEvent: () => void;
}

export const PensionPolicyContext = createContext<IPensionPolicyContext>({
  tabKey: '',
  setTabKey: () => {},
  policyForm: INITAL_ADMIN_POLICY_FORM,
  setPolicyForm: () => {},
  occupationalPension: INITIAL_OCCUPATIONAL_PENSION,
  setOccupationalPension: () => {},
  isBusy: true,
  setIsBusy: () => {},
  salaryChange: INITIAL_SALARY_CHNAGE,
  setSalaryChange: () => {},
  salaryTypeWithHolidays: INITIAL_SALARY_TYPE,
  setSalaryTypeWithHolidays: () => {},
  pensionCompanies: { data: [] },
  setPensionCompanies: () => {},
  fetchAndSetPolicies: () => {},
  pensionPolicies: { data: [] },
  mounted: false,
  salaryInterval: 0,
  formGroup: {} as PensionPolicyFormGroup,
  selectedPolicyDetails: null,
  setSelectedPolicyDetails: () => {},
  preAssignedPolicy: null,
  setPreAssignedPolicy: () => {},
  isResetPolicy: false,
  setIsResetPolicy: () => {},
  isCancelEvent: false,
  setIsCancelEvent: () => {},
  employeeInputInfo: null,
  setEmployeeInputInfo: () => {},
  externalPolicyRevision: null,
  setExternalPolicyRevision: () => {},
  actionMode: ActionMode.ADD,
  isPensionable: false,
  pensionPolicyMode: PensionPolicyMode.ADMIN_POLICY,
  setIsPensionable: () => {},
  setActionMode: () => {},
  updatePolicyFieldInput: () => {},
  updatePolicySettingsInput: () => {},
  getPolicySettingsInput: () => {},
  updateOccupationalPensionFieldInput: () => {},
  updatePensionCompaniesInput: () => {},
  replaceOccupationalPensionInput: () => {},
  replacePolicyInput: () => {},
  updateSalaryChangeFieldInput: () => {},
  replaceSalaryChangeInput: () => {},
  replaceSalarySetupInput: () => {},
  updateSalarySetupInput: () => {},
  isEditorMode: () => true,
  hasEditAction: () => false,
  setPensionPolicyMode: () => {},
  patchSelectedPensionPolicy: () => {},
  reloadToDefault: (params?: Partial<IReloadProps> | null) => {},
  triggerCancelEvent: () => {},
});

const getInitialFormValue = (mode: PensionPolicyMode | undefined) => {
  if (mode === PensionPolicyMode.EMPLOYEE_WITHIN_COMPANY) {
    return deepMerge(deepClone(INITIAL_VALUE_PENSION_POLICY), {
      policySettings: {
        employeeMetaSettings: INITIAL_EMPLOYEE_POLICY_SETTINGS,
      } as keyof PensionPolicy['policySettings']['employeeMetaSettings'],
    });
  }

  return deepClone(INITIAL_VALUE_PENSION_POLICY);
};

type Props = {
  children: JSX.Element;
  defaultSelectedPolicy?: PensionPolicy;
  defaultEmployeeInfoInput?: IEmployeeInfoInput | null;
  defaultSelectedPolicyMode?: PensionPolicyMode;
  defaultActionMode?: ActionMode;
  syncPensionPolicyFormData?: (args: any) => void;
  defaultPensionFormPopulated?: PensionPolicy | null;
  shouldReset?: boolean;
  defaultExternalPolicyRevision?: CommonPolicy | null;
};

export const PensionPolicyProvider = ({
  children,
  defaultSelectedPolicy,
  defaultSelectedPolicyMode,
  defaultEmployeeInfoInput,
  syncPensionPolicyFormData,
  defaultPensionFormPopulated,
  shouldReset = false,
  defaultActionMode,
  defaultExternalPolicyRevision,
}: Props) => {
  const { pathname } = useLocation();
  const localUser = localStorage.getItem('user');
  const user = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const isSuperAdminRole = user?.role?.role === UserRole.SUPER_ADMIN ? true : false;
  const [policyForm, setPolicyForm] = useState<PolicyForm>({
    ...INITAL_ADMIN_POLICY_FORM,
    isCollectiveAgreementLock: isSuperAdminRole,
  });
  const [isBusy, setIsBusy] = useState(false);
  const [isCancelEvent, setIsCancelEvent] = useState(false);
  const [selectedPolicyDetails, setSelectedPolicyDetails] = useState<any>(defaultSelectedPolicy || null);
  const [preAssignedPolicy, setPreAssignedPolicy] = useState<PensionPolicy | null>();
  const [isResetPolicy, setIsResetPolicy] = useState(shouldReset);
  const [occupationalPension, setOccupationalPension] = useState<TypeOccupationPension>(INITIAL_OCCUPATIONAL_PENSION);
  const [salaryChange, setSalaryChange] = useState<TypeSalaryChange>(INITIAL_SALARY_CHNAGE);
  const [pensionCompanies, setPensionCompanies] = useState<ITableValues<typeof SuperAdminPensionPolicyCompaniesData>>({
    data: [],
  });
  const [pensionPolicies, setPensionPolicies] = useState<ITableValues<typeof SuperAdminPensionPolicyData>>({
    data: [],
  });
  const [salaryTypeWithHolidays, setSalaryTypeWithHolidays] = useState<SalaryType>(INITIAL_SALARY_TYPE);
  const [tabKey, setTabKey] = useState('deal');
  const [mounted /*setIsMounted*/] = useState<boolean>(false);
  const [salaryInterval, setSalryInterval] = useState(0);
  const [actionMode, setActionMode] = useState<ActionMode>(defaultActionMode || ActionMode.ADD);
  const [isPensionable, setIsPensionable] = useState<boolean>(false);
  const [pensionPolicyMode, setPensionPolicyMode] = useState<PensionPolicyMode>(
    defaultSelectedPolicyMode || PensionPolicyMode.ADMIN_POLICY,
  );
  const [employeeInputInfo, setEmployeeInputInfo] = useState<IEmployeeInfoInput | null>(null);
  const [externalPolicyRevision, setExternalPolicyRevision] = useState<CommonPolicy | null>(null);

  const formGroup = useFormik<PensionPolicy>({
    initialValues: {
      ...getInitialFormValue(defaultSelectedPolicyMode),
      isCollectiveAgreementLock: isSuperAdminRole,
    },
    validateOnMount: true,
    validationSchema: buildSchema(defaultSelectedPolicyMode),
    onSubmit: () => {},
  });

  useEffect(() => {
    (async () => {
      if (pathname.includes('create')) {
        const year = new Date().getFullYear();
        const calc = await getSalaryIntervalByYear({ year });
        setSalryInterval(calc?.salaryInterval?.value);
      }
    })();
  }, [pathname]);

  const fetchAndSetPolicies = async ({
    type = 'All',
    employeeType = '',
    page = 1,
    sizePerPage = 25,
    searchString = '',
    sortField = 'name',
    sortOrder = '1',
  } = {}) => {
    // setIsBusy(true);
    const _policies = await getPolicies({
      type,
      employeeType,
      page,
      sizePerPage,
      searchString,
      sortField,
      sortOrder,
    });
    setPensionPolicies({ data: _policies });
    setIsBusy(false);
  };

  const updatePolicyFieldInput = (inputName, inputValue) => {
    setPolicyForm({ ...policyForm, [inputName]: inputValue });
    formGroup.setFieldValue(inputName, inputValue);
  };

  const updatePolicySettingsInput = (keyName, value) => {
    formGroup.setFieldValue(`policySettings.${keyName}`, value);
  };

  const getPolicySettingsInput = (keyName, value = null) => {
    const field = formGroup.getFieldMeta(`policySettings.${keyName}`);

    if (field) {
      return field.value;
    }

    return null;
  };

  const updatePensionCompaniesInput = (inputValue) => {
    setPensionCompanies(inputValue);
    formGroup.setFieldValue('pensionCompanies', inputValue);
  };

  const updateOccupationalPensionFieldInput = (inputName, inputValue) => {
    setOccupationalPension(inputValue);
    formGroup.setFieldValue(`policySettings.occupationalPension.${inputName}`, inputValue);
  };

  const replaceSalaryChangeInput = (inputValue) => {
    setSalaryChange(inputValue);
    formGroup.setFieldValue(`policySettings.salaryChange`, inputValue);
  };

  const updateSalaryChangeFieldInput = (inputName, inputValue) => {
    setSalaryChange(inputValue);
    formGroup.setFieldValue(`policySettings.salaryChange.${inputName}`, inputValue);
  };

  const replaceOccupationalPensionInput = (inputValue) => {
    setOccupationalPension(inputValue);
    formGroup.setFieldValue(`policySettings.occupationalPension`, inputValue);
  };

  const updateSalarySetupInput = (inputName, inputValue) => {
    setSalaryTypeWithHolidays(inputValue);
    formGroup.setFieldValue(`policySettings.salaryType.data.${inputName}`, inputValue);
  };

  const replaceSalarySetupInput = (inputValue) => {
    setSalaryTypeWithHolidays(inputValue);
    formGroup.setFieldValue(`policySettings.salaryType`, inputValue);
  };

  const replacePolicyInput = (inputValue) => {
    setPolicyForm(inputValue);
    formGroup.setFieldValue('policyName', inputValue.policyName);
    formGroup.setFieldValue('version', inputValue.version);
    formGroup.setFieldValue('employeeType', inputValue.employeeType);
    formGroup.setFieldValue('effectiveFrom', inputValue.effectiveFrom);
    formGroup.setFieldValue('isCollectiveAgreementLock', inputValue.isCollectiveAgreementLock);
    formGroup.setFieldValue('status', inputValue.status);
    formGroup.setFieldValue('policyDescription', inputValue.policyDescription);
    formGroup.setFieldValue('isAutoAssign', inputValue.isAutoAssign);
  };

  const isEditorMode = () => {
    return [ActionMode.ADD, ActionMode.EDIT].indexOf(actionMode) !== -1;
  };

  const hasEditAction = () => {
    return ActionMode.EDIT === actionMode;
  };

  const setPolicyMode = (val: PensionPolicyMode | null = null) => {
    if (val) {
      setPensionPolicyMode(val);

      return;
    }

    const userRole = user?.role.role;

    if (userRole === UserRole.SUPER_ADMIN) {
      setPensionPolicyMode(PensionPolicyMode.ADMIN_POLICY);
    }

    if (userRole === UserRole.COMPANY_ADMIN) {
      setPensionPolicyMode(PensionPolicyMode.COMPANY_POLICY);
    }
  };

  const patchSelectedPensionPolicy = (policy) => {
    setPolicyForm(policy);
    replacePolicyInput(policy);

    const formGroupPolicy = deepClone(policy);
    
    formGroup.setFieldValue('policySettings.occupationalPension', formGroupPolicy.policySettings.occupationalPension);
    formGroup.setFieldValue('policySettings.insurances', formGroupPolicy.policySettings.insurances);
    formGroup.setFieldValue('policySettings.pensionManagers', formGroupPolicy.policySettings.pensionManagers);
    formGroup.setFieldValue('policySettings.salaryChange', formGroupPolicy.policySettings.salaryChange);
    formGroup.setFieldValue('policySettings.salaryType', formGroupPolicy.policySettings.salaryType);
    if (!formGroupPolicy.policySettings.specialConditions) {
      formGroup.setFieldValue('policySettings.specialConditions', INITIAL_POLICY_SPECIAL_CONDITION);
    } else {
      formGroup.setFieldValue('policySettings.specialConditions', formGroupPolicy.policySettings.specialConditions);
    }
  };

  const reloadToDefault = (params: Partial<IReloadProps> | null = null) => {
    if (params?.isEditAction) {
      setPreAssignedPolicy(null);
      setSelectedPolicyDetails(null);
    }

    setIsResetPolicy(true);
    formGroup.resetForm();
    replacePolicyInput(deepClone(INITAL_ADMIN_POLICY_FORM));
    setTabKey('deal');
    setActionMode(ActionMode.ADD);

    if (params?.restoreResetMode) {
      setIsResetPolicy(false);
    }
  };

  const triggerCancelEvent = () => {
    setIsCancelEvent(true);
    
    setTimeout(() => setIsCancelEvent(false), 0);
  };

  useEffect(() => {
    if (defaultSelectedPolicyMode) {
      setPensionPolicyMode(defaultSelectedPolicyMode);
    } else {
      setPolicyMode();
    }
  }, []);

  useEffect(() => {
    setIsResetPolicy(shouldReset);
  }, [shouldReset]);

  useEffect(() => {
    if (syncPensionPolicyFormData) {
      syncPensionPolicyFormData(formGroup.values.policySettings);
    }
  }, [formGroup.values]);

  useEffect(() => {
    setSelectedPolicyDetails(defaultSelectedPolicy);
  }, [defaultSelectedPolicy]);

  useEffect(() => {
    setPreAssignedPolicy(defaultPensionFormPopulated);
  }, [defaultPensionFormPopulated]);

  useEffect(() => {
    if (defaultEmployeeInfoInput) {
      setEmployeeInputInfo(defaultEmployeeInfoInput);
    }
  }, [defaultEmployeeInfoInput]);

  useEffect(() => {
    setExternalPolicyRevision(defaultExternalPolicyRevision || null);
  }, [defaultExternalPolicyRevision]);

  useEffect(() => {
    if (defaultActionMode) {
      setActionMode(defaultActionMode);
    }
  }, [defaultActionMode]);

  useEffect(() => {
    if (isResetPolicy) {
      setIsResetPolicy(false);

      return;
    }
  }, [isResetPolicy]);

  const context: IPensionPolicyContext = {
    policyForm,
    setPolicyForm,
    occupationalPension,
    setOccupationalPension,
    isBusy,
    setIsBusy,
    isCancelEvent,
    setIsCancelEvent,
    tabKey,
    setTabKey,
    salaryChange,
    setSalaryChange,
    pensionCompanies,
    setPensionCompanies,
    pensionPolicies,
    fetchAndSetPolicies,
    salaryTypeWithHolidays,
    setSalaryTypeWithHolidays,
    mounted,
    salaryInterval,
    formGroup,
    setSelectedPolicyDetails,
    selectedPolicyDetails,
    setPreAssignedPolicy,
    preAssignedPolicy,
    isResetPolicy,
    setIsResetPolicy,
    actionMode,
    isPensionable,
    pensionPolicyMode,
    setIsPensionable,
    employeeInputInfo,
    setEmployeeInputInfo,
    externalPolicyRevision,
    triggerCancelEvent,
    setExternalPolicyRevision,
    setActionMode,
    setPensionPolicyMode,
    isEditorMode,
    hasEditAction,
    updatePolicyFieldInput,
    updatePolicySettingsInput,
    getPolicySettingsInput,
    updatePensionCompaniesInput,
    updateOccupationalPensionFieldInput,
    updateSalaryChangeFieldInput,
    replaceOccupationalPensionInput,
    replacePolicyInput,
    replaceSalaryChangeInput,
    updateSalarySetupInput,
    replaceSalarySetupInput,
    patchSelectedPensionPolicy,
    reloadToDefault,
  };

  return <PensionPolicyContext.Provider value={context}>{children}</PensionPolicyContext.Provider>;
};
