import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import MonthlyStepperTopHeader from './MonthlyStepperTopHeader';
import MonthlyStepperFooter from './MonthlyStepperFooter';
import styles from './MonthlyStepper.module.scss';

import { getMonthNavigation, getSumByKey, getYearlyMonthListByCount, numberFormat, parseYearRange } from './utils';

import { IDatasource, StepperAction } from './types';
import { STEPPER_CONFIG } from './config';
import useLocalizationResolver from '../../hooks/useLocalizationResolver';

type Props = {
  yearRange: any[];
  datasource: IDatasource;
  headerTitle: string;
};

export const MonthlyStepper = ({ yearRange, datasource, headerTitle }: Props) => {
  const { getLang } = useLocalizationResolver();
  const [, /* timePeriodRecords */ setTimePeriodRecords] = useState<any>([]);
  const [selectedHeaderMonths, setSelectedHeaderMonths] = useState<Array<any>>([]);
  const [selectedMonths, setSelectedMonths] = useState<any>([]);
  const [selectedSummaryTotals, setSelectedSummaryTotals] = useState<any>([]);
  const [shouldRender, setShouldRender] = useState(false);
  const [actionType, setActionType] = useState<StepperAction | null>(null);

  const headerRows = datasource.headers || [];
  const datasourceCollection: any[] = datasource.reports || [];

  /* const yearList: number[] = useMemo(() => {
    if (yearRange.length === 1) {
      return yearRange;
    }

    const startYear = yearRange[0];
    const endYear = yearRange[1];

    return Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);
  }, [yearRange.join(',')]); */

  const yearBoundary = useMemo(() => parseYearRange(yearRange), [yearRange.join(',')]);
  const [yearOffset, yearLimit] = yearBoundary;

  const initializeSelectedPeriodData = () => {
    const initalMonths = getYearlyMonthListByCount(STEPPER_CONFIG.displayColumns, yearOffset, yearLimit);
    setSelectedHeaderMonths(initalMonths);
  };

  const updateTimePeriodRecords = () => {
    // const monthsInput: any = Array.from({ length: 12 }, () => ({ value: null }));

    /* setTimePeriodRecords(
      yearList.map((year, idx) => {
        return {
          year: year,
          dataset: headerRows.map((headerName) => {
            return {
              name: headerName.name,
              months: monthsInput,
            };
          }),
        };
      }),
    ); */

    setTimePeriodRecords(JSON.parse(JSON.stringify(datasourceCollection)));
  };

  useEffect(() => {
    initializeSelectedPeriodData();
  }, []);

  useEffect(() => {
    updateTimePeriodRecords();
  }, [datasourceCollection, yearRange]);

  useEffect(() => {
    const fadeInTimeout = setTimeout(() => {
      setShouldRender(true);
    }, 400);

    updateSelectedYearlyMonthDetails();

    return () => {
      setShouldRender(false);
      clearInterval(fadeInTimeout);
    };
  }, [selectedHeaderMonths]);

  const updateSelectedYearlyMonthDetails = () => {
    const selectedCalendarRecords = selectedHeaderMonths
      .map((value) => {
        const foundYearCollection = datasourceCollection.find((item) => {
          return item.year;
        });

        if (!foundYearCollection) {
          return {
            year: value.year,
            monthIndex: value.monthIndex,
            monthName: value.monthName,
            inputs: headerRows.map((_headerItem) => {
              return {
                monthIndex: value.monthIndex,
                value: null,
              };
            }),
          };
        }

        const foundRowPeriodRecord = foundYearCollection.dataset.map((item) => {
          const periodDataset = item.months.find((recordItem) => {
            return recordItem.monthIndex === value.monthIndex;
          });

          return periodDataset;
        });

        return {
          year: value.year,
          monthIndex: value.monthIndex,
          monthName: value.monthName,
          inputs: foundRowPeriodRecord,
        };
      })
      .filter((value) => value);

    const selectedCalendarSummaryRecords = selectedCalendarRecords.map((item) => {
      return {
        year: item!.year,
        monthIndex: item!.monthIndex,
        monthName: item!.monthName,
        value: getSumByKey(item!.inputs, 'value'),
      };
    });

    setSelectedMonths(selectedCalendarRecords);
    setSelectedSummaryTotals(selectedCalendarSummaryRecords);
  };

  const handleNext = () => {
    const lastMonth = selectedHeaderMonths[selectedHeaderMonths.length - 1];
    const nextMonth = getMonthNavigation({
      year: lastMonth.year,
      monthNumber: lastMonth.monthIndex,
      type: 'next',
    });

    setSelectedHeaderMonths((prev) => {
      const newDataList = [...prev];

      newDataList.shift();
      newDataList.push({
        year: nextMonth.year,
        monthIndex: nextMonth.monthIndex,
        monthName: nextMonth.monthName,
      });

      return newDataList;
    });
    setActionType(StepperAction.FORWARD);
  };

  const handlePrevious = () => {
    const firstMonth = selectedHeaderMonths[0];
    const nextMonth = getMonthNavigation({
      year: firstMonth.year,
      monthNumber: firstMonth.monthIndex,
      type: 'prev',
    });

    setSelectedHeaderMonths((prev) => {
      const newDataList = [...prev];

      newDataList.pop();
      newDataList.unshift({
        year: nextMonth.year,
        monthIndex: nextMonth.monthIndex,
        monthName: nextMonth.monthName,
      });

      return newDataList;
    });
    setActionType(StepperAction.BACKWARD);
  };

  return (
    <div className="total-cost-stepper">
      <table>
        <tr>
          <td className="table-left fs-name theme-text table-first-col" valign="bottom">
            {headerTitle}
          </td>
          <td className="table-right">
            <MonthlyStepperTopHeader
              selectedHeaderMonths={selectedHeaderMonths}
              yearRange={yearBoundary}
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              shouldRender={shouldRender}
            ></MonthlyStepperTopHeader>
          </td>
        </tr>

        {headerRows.map((header, headerIdx) => (
          <tr key={header.name}>
            <td className="table-left">{getLang(header.title)}</td>
            <td className="table-right table-body">
              <table className="border-spacing-0">
                <tr>
                  {selectedMonths.map((month, index) => (
                    <td
                      key={index}
                      className={classNames('text-right', styles.fade_in, shouldRender ? styles.active : '')}
                    >
                      {numberFormat(month.inputs[headerIdx]?.value || 0)}
                    </td>
                  ))}
                </tr>
              </table>
            </td>
          </tr>
        ))}

        <tr className="last-table-row">
          <td className="table-left">{STEPPER_CONFIG.footerTitle}</td>
          <td className="table-right table-body">
            <MonthlyStepperFooter
              selectedHeaderMonths={selectedHeaderMonths}
              selectedSummaryTotals={selectedSummaryTotals}
              shouldRender={shouldRender}
              actionType={actionType}
            ></MonthlyStepperFooter>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default React.memo(MonthlyStepper);
