import { useState } from 'react';
import { set, includes, isEmpty } from 'lodash';
import { formatString, hasEmptyValues } from '../util/util';

interface LooseObject {
  [key: string]: any;
}

export const useValidationError = () => {
  const [errors, setErrors] = useState<LooseObject>({});

  const isInvalid = Object.keys(errors).length > 0;

  const _errors = {};
  const runValidaton = ({ payload, exclude = [''], deepCheck = false, clearErrors = true, checkDeepArray = true }) => {
    Object.keys(payload).forEach((key) => {
      const formattedKey = formatString(key);

      const value = payload[key];
      if (Array.isArray(value) && checkDeepArray) {
        value.map((v) => runValidaton({ payload: { [key]: v }, deepCheck: true, clearErrors: false }));
      }

      if (deepCheck) {
        const isObject = value instanceof Object;
        const checkIsEmpty = isObject && !Array.isArray(value) ? hasEmptyValues(value) : isEmpty(value);

        if (checkIsEmpty && !includes(exclude, key)) {
          set(_errors, key, `${formattedKey} is required`);
        }
      } else if (isEmpty(value) && !includes(exclude, key)) {
        set(_errors, key, `${formattedKey} is required`);
      }
    });
    setErrors((pr) => {
      if (clearErrors) return { ..._errors };
      return { ...pr, ..._errors };
    });
    return Object.keys(_errors).length === 0;
  };

  return { errors, setErrors, runValidaton, isInvalid };
};
