import { useLayoutEffect } from 'react';

const useTabChangeListener = (tabKey) => {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' as any });
  }, [tabKey]);

  return;
};

export default useTabChangeListener;
