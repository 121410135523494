import { Calendar } from "../util/calendar";

export const INITIAL_DATA = {
  reportedSalaryChange: {
    changeValue: 0,
    effectiveOn: '',
  },
  salaryChangeManually: {
    changeValue: 0,
    effectiveOn: '',
  },
  additionalSalaryChange: {
    changeValue: 0,
    effectiveOn: '',
  },
  totalSalaryChange: {
    changeValue: 0,
    effectiveOn: '',
  },
};

export const salaryChangeColumns = [
  {
    key: 'reportedSalaryChange',
    valueTitle: 'Reported salary change',
  },
  { key: 'salaryChangeManually', valueTitle: 'Salary change (manually changed)' },
  // { key: 'additionalSalaryChange', valueTitle: 'Additional salary change' },
  // { key: 'totalSalaryChange', valueTitle: 'Total salary change' },
];

// export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const months: Array<string> = [];

export type TypeSalaryChangeCalculations = {
  month: number;
  effectiveOn: string;
  reportedSalaryChange: {
    changeValue: number;
    effectiveOn: string;
  };
  salaryChangeManually: {
    changeValue: number;
    effectiveOn: string;
  };
  additionalSalaryChange: {
    changeValue: number;
    effectiveOn: string;
  };
  totalSalaryChange: {
    changeValue: number;
    effectiveOn: string;
  };
};

export type CalcType = {
  [key: number]: TypeSalaryChangeCalculations;
};

export const INITIAL_VACATION_DETAIL = {
  paidHolidays: 0,
  effectiveFrom: Calendar.currentMonthStartDate
};


export const INITIAL_EMPLOYEE_DETAILS = {
  employmentDate: '',
  employmentType: '',
  foraType: '',
  jobTitle: '',
  personnelType: '',
  salary: 0,
  salaryType: '',
  scheduleId: '',
  email: '',
  fullName: '',
  personalNumber: '',
  phoneNumberWork: '',
  phoneNumberPrivate: '',
  costCenter: '',
  paidHolidays: 0,
  policySettings: {},
  vacation: INITIAL_VACATION_DETAIL,
  scopeOfWork: 100,
  parentalInfo: []
};
