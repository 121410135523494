import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Slash, Save, Trash2, Send } from 'react-feather';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { useEditAccountingFirmContext } from '../context/useContext';
import General from './General';
import { DialogWithTextField } from '../../../../util/dialogs';
import { AccountingFirm } from '../../../../api/service/superAdmin/accounting-firm/types';
import {
  getAccountingFirmDetails,
  updateAccountingFirm,
  deleteAccountingFirm,
} from '../../../../api/service/superAdmin/accounting-firm/service';
import { splitFullName } from '../../../../util/util';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';
import { Log } from './Log/Log';
import { Legal } from './Legal';
import { Provision } from './Provision/Provision';
import { AccountingFirmSaveMode } from '../types';
import useLocale from '../../../../hooks/useLocale';
import useTabChangeListener from '../../../../hooks/useTabChangeListener';
import { Clients } from './Clients/Clients';

let _companyDetail = {};
export default function AccountingFirmDetail() {
  const { localize } = useLocale();
  const navigate = useNavigate();
  const { isInvalid, errors, setErrors } = useValidationError();
  const [date, setDate] = useState({ createdAt: '', updatedAt: '' });
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  const { id } = useParams();
  const context = useEditAccountingFirmContext();
  const {
    replaceFirmDetailsInput,
    replaceAddressInput,
    replaceSupervisorsInput,
    replaceSeniorConsultantsInput,
    updateContractEffectiveInput,
    updateInvoiceEmailAddress,
    updateInvoicingCostCenter,
    updateInvitationEmailInput,
    // updateProvisionAgreementInput,
    invoiceEmailAddress,
    invoicingCostCenter,
    firmDetails,
    addressDetails,
    supervisors,
    seniorConsultants,
    contractEffectiveFrom,
    invitationEmail,
    provisionAgreement,
    formGroup,
    deletedSupervisors,
    deletedConsultants,
    combinations,
    replaceCombinations,
  } = context;

  const hasChanges =
    !isEqual(_companyDetail['name'], firmDetails.name) ||
    !isEqual(_companyDetail['organizationNumber'], firmDetails.organizationNumber) ||
    !isEqual(_companyDetail['status'], firmDetails.status) ||
    !isEqual(_companyDetail['address'], addressDetails) ||
    !isEqual(_companyDetail['contractEffectiveFrom'], contractEffectiveFrom) ||
    !isEqual(_companyDetail['invoiceEmailAddress'], invoiceEmailAddress) ||
    !isEqual(_companyDetail['invoicingCostCenter'], invoicingCostCenter) ||
    !isEqual(_companyDetail['seniorConsultants'], seniorConsultants) ||
    !isEqual(_companyDetail['supervisors'], supervisors) ||
    !isEqual(_companyDetail['invitationEmail'], invitationEmail) ||
    !isEqual(_companyDetail['provisionAgreement'], provisionAgreement) ||
    !isEqual(_companyDetail['combinations'], combinations);
  const firmName = firmDetails ? firmDetails['name'] : '';
  const hasDraftMode = firmDetails?.status === AccountingFirmSaveMode.DRAFT;

  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
      href: '/dashboard',
    },
    {
      name: 'Accounting firms',
      href: '/accounting-firms',
    },
    {
      name: firmName,
      translate: false,
    },
  ];

  const onCancel = () => {
    replaceFirmDetailsInput({
      organizationNumber: _companyDetail['organizationNumber'],
      name: _companyDetail['name'],
      status: _companyDetail['status'],
      contractNumber: _companyDetail['contractNumber'] || '',
      resellerAgreement: _companyDetail['resellerAgreement'],
      createDate: _companyDetail['createdAt'],
      lastEdit: _companyDetail['updatedAt'],
      // lastEdit?: string,
      // updatedAt?: string,
    });
    replaceAddressInput(_companyDetail['address']);
    updateContractEffectiveInput(_companyDetail['contractEffectiveFrom']);
    updateInvoiceEmailAddress(_companyDetail['invoiceEmailAddress']);
    updateInvoicingCostCenter(_companyDetail['invoicingCostCenter']);
    updateInvitationEmailInput(_companyDetail['invitationEmail']);
    replaceSeniorConsultantsInput(
      _companyDetail['seniorConsultants'].map((item) => {
        if (item.firstName) {
          return item;
        }
        const { firstName, lastName } = splitFullName(item.fullName);
        item.firstName = firstName;
        item.lastName = lastName;

        return item;
      }),
    );
    replaceSupervisorsInput(
      _companyDetail['supervisors'].map((item) => {
        if (item.firstName) {
          return item;
        }

        const { firstName, lastName } = splitFullName(item.fullName);

        item.firstName = firstName;
        item.lastName = lastName;

        return item;
      }),
    );
    replaceCombinations(_companyDetail['combinations']);
    // updateProvisionAgreementInput(_companyDetail['provisionAgreement']);
    setDate({ createdAt: _companyDetail['createdAt'], updatedAt: _companyDetail['updatedAt'] });
  };

  const onUpdateClient = async (saveMode: AccountingFirmSaveMode | null = null) => {
    try {
      // const modifiedAdminDetails = adminDetails.map((i) => omit(i, 'id'));
      const data = {
        ...firmDetails,
        address: addressDetails,
        contractEffectiveFrom: contractEffectiveFrom,
        invoiceEmailAddress: invoiceEmailAddress,
        invoicingCostCenter: invoicingCostCenter,
        invitationEmail: invitationEmail,
        seniorConsultants: seniorConsultants,
        supervisors: supervisors,
        provisionAgreement: provisionAgreement,
        combinations: combinations,
        deletedSupervisors,
        deletedConsultants,
      } as unknown as AccountingFirm;

      if (hasDraftMode && saveMode === AccountingFirmSaveMode.DRAFT) {
        data.status = AccountingFirmSaveMode.DRAFT;
      } else if (
        hasDraftMode &&
        [AccountingFirmSaveMode.ACTIVE, AccountingFirmSaveMode.INACTIVE].indexOf(data.status as any) === -1
      ) {
        data.status = AccountingFirmSaveMode.SETUP;
      }

      formGroup.handleSubmit();
      formGroup.validateForm().then((errors) => {
        setErrors(errors);
      });

      if (!formGroup.isValid) {
        return false;
      }

      if (saveMode === AccountingFirmSaveMode.SETUP) {
        setisUpdating(true);
      }
      if (saveMode === AccountingFirmSaveMode.DRAFT) {
        setIsBusyDrafting(true);
      }

      await updateAccountingFirm(id, data);
      await fetchAccountingFirmDetails();
    } finally {
      if (saveMode === AccountingFirmSaveMode.SETUP) {
        setisUpdating(false);
      }
      if (saveMode === AccountingFirmSaveMode.DRAFT) {
        setIsBusyDrafting(false);
      }
    }
  };

  const fetchAccountingFirmDetails = async () => {
    try {
      setIsBusy(true);
      const accountingFirmDetails = await getAccountingFirmDetails(id);
      const {
        contractEffectiveFrom,
        address,
        createdAt,
        updatedAt,
        invitationEmail,
        invoicingCostCenter,
        seniorConsultants,
        supervisors,
        invoiceEmailAddress,
        combinations,
        // provisionAgreement,
      } = accountingFirmDetails;
      const accountingFirmResponse = {
        ...accountingFirmDetails,
        supervisors: supervisors.map((item) => {
          if (item.firstName) {
            return item;
          }

          const { firstName, lastName } = splitFullName(item.fullName);

          item.firstName = firstName;
          item.lastName = lastName;

          return item;
        }),
        seniorConsultants: seniorConsultants.map((item) => {
          if (item.firstName) {
            return item;
          }
          const { firstName, lastName } = splitFullName(item.fullName);
          item.firstName = firstName;
          item.lastName = lastName;

          return item;
        }),
        invitationEmail: invitationEmail || '',
      };
      _companyDetail = structuredClone(accountingFirmResponse);

      replaceFirmDetailsInput({
        organizationNumber: _companyDetail['organizationNumber'],
        name: _companyDetail['name'],
        status: _companyDetail['status'],
        contractNumber: _companyDetail['contractNumber'] || '',
        resellerAgreement: _companyDetail['resellerAgreement'],
        createDate: _companyDetail['createdAt'],
        lastEdit: _companyDetail['updatedAt'],
      });
      replaceAddressInput(address);
      updateContractEffectiveInput(contractEffectiveFrom);
      updateInvoiceEmailAddress(invoiceEmailAddress);
      updateInvoicingCostCenter(invoicingCostCenter);
      replaceSeniorConsultantsInput(accountingFirmResponse.seniorConsultants);
      replaceSupervisorsInput(accountingFirmResponse.supervisors);
      setDate({ createdAt, updatedAt });
      updateInvitationEmailInput(accountingFirmResponse.invitationEmail);
      replaceCombinations(combinations);
      // updateProvisionAgreementInput(provisionAgreement);
    } finally {
      setIsBusy(false);
    }
  };

  const onDelete = async () => {
    const title = `${localize('i_want_to_detete')} ${firmName} ${localize('forever')}`;
    const textPara = `${localize('to_delete_the')} ${localize('accounting_firm')}, ${localize('type_in')}`;
    const result = await DialogWithTextField({
      title: localize('are_you_sure'),
      confirmButtonText: localize('delete_client'),
      denyButtonText: localize('cancel'),
      validationTitle: title,
      validationMessage: localize('validation_message'),
      content: {
        caption: textPara,
        body: title,
      },
      confirmBtnClass: 'confirm-delete-btn',
    });

    if (result.isConfirmed) {
      try {
        setisDeleting(true);
        await deleteAccountingFirm(id ?? '');
        navigate('/accounting-firms');
      } finally {
        setisDeleting(false);
      }
    }
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };
  useEffect(() => {
    fetchAccountingFirmDetails();
  }, []);

  useTabChangeListener(activeKey);

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader
          headingClass="fs-h1"
          pageTitle={
            firmDetails ? (hasDraftMode ? `${firmDetails['name']} ${localize('draft')}` : firmDetails['name']) : ''
          }
          translate={false}
        />
        <div>
          {activeKey === 'general' && (
            <div className="cr-form-row">
              {hasChanges && (
                <Button onClick={onCancel} className="app-btn-primary cancel-btn">
                  <Slash className="icon-gap-right" /> {`${localize('cancel_changes')}`}
                </Button>
              )}
              {hasDraftMode && (
                <Button
                  disabled={isBusyDrafting || isDeleting || isUpdating || !hasChanges}
                  onClick={() => onUpdateClient(AccountingFirmSaveMode.DRAFT)}
                  className="app-btn-primary"
                >
                  {isBusyDrafting ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}{' '}
                  {`${localize('save')} ${localize('as')} ${localize('draft')}`}
                </Button>
              )}
              <Button
                disabled={isBusyDrafting || isDeleting || isUpdating || !hasChanges}
                onClick={() => onUpdateClient(AccountingFirmSaveMode.SETUP)}
                className="app-btn-primary"
              >
                {isUpdating ? (
                  <Spinner size="sm" />
                ) : hasDraftMode ? (
                  <Send className="icon-gap-right" />
                ) : (
                  <Save className="icon-gap-right" />
                )}{' '}
                {hasDraftMode
                  ? `${localize('save')} ${localize('accounting_firms')} ${localize('and_send_all_invites')}`
                  : `${localize('save')} ${localize('changes')}`}
              </Button>
              <Button
                disabled={isBusyDrafting || isDeleting || isUpdating}
                onClick={onDelete}
                className="app-btn-danger"
              >
                {isDeleting ? <Spinner size="sm" /> : <Trash2 className="icon-gap-right" />}
                {`${localize('delete')} ${localize('client')}`}
              </Button>
            </div>
          )}
        </div>
      </div>
      <ValidationAlert show={isInvalid} errors={errors} />

      <Tabs defaultActiveKey="general" onSelect={handleTabChange}>
        <Tab eventKey="general" title={localize('general')}>
          <General isBusy={isBusy} date={date} />
        </Tab>
        {!hasDraftMode && (
          <Tab eventKey="provision" title={localize('provision')}>
            <Provision />
          </Tab>
        )}
        {!hasDraftMode && (
          <Tab eventKey="legal" title={localize('legal')}>
            <Legal />
          </Tab>
        )}
        {!hasDraftMode && (
          <Tab eventKey="log" title={localize('log')}>
            <Log isBusy={isBusy} />
          </Tab>
        )}
        {!hasDraftMode && (
          <Tab eventKey="clients" title={localize('clients')}>
            <Clients isBusy={isBusy} />
          </Tab>
        )}
      </Tabs>
    </>
  );
}
