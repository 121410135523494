import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button, Container, Spinner } from 'react-bootstrap';
import filter from 'lodash/filter';

import { ChangelogPostItem, Item, PostType } from '../../../api/service/webflow/types';
import PagesHeader from '../../../components/base/PagesHeader';
import { DashboardErrorAlert } from '../../../components/DashboardErrorAlert';
import { ChartsSection } from './ChartsSection';
import { DeadlineSidebar } from './DeadlineSidebar';
import { GettingStarted } from './GettingStarted';
import { LastTransactionSidebar } from './LastTransactionSidebar';
import { NumberCards } from './NumberCards';
// import { SmallAlerts } from './SmallAlerts';
import {
  gettingStartedCategoryId,
  postsCategoryId,
  getCollections,
  getCollectionItem,
} from '../../../api/service/webflow/service';
import { BlogsSection } from './BlogsSection';
import { InstructionBoxes } from './InstructionBoxes';
import BSModal from '../../../components/Modal';
import { useTranslation } from 'react-i18next';
import { getCompanyAdminDashboardData } from '../../../api/service/company/overview/service';
import { OverviewErrorAlert } from '../../../types';
// import { numberFormat } from '../../../util/util';

//const currentDate = `${moment().format('MMMM')} ${moment().date()}`;
type OverviewCollectionItemState = {
  getStartedItems?: Item[];
  blogs?: Item[];
  instructions?: Item[];
};

function OverviewPage() {
  const { t } = useTranslation();
  const [isBusy, setIsBusy] = useState(true);
  const [collectionItems, setCollectionItems] = useState<OverviewCollectionItemState>({
    getStartedItems: [],
    blogs: [],
    instructions: [],
  });
  const [changelog, setChangelog] = useState<ChangelogPostItem[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [overviewCardsData, setOverviewCardsData] = useState<any>();
  const [overviewChartData, setOverviewChartData] = useState<any>({});
  const [, /*overviewResponse*/ setOverviewResponse] = useState<any>({});
  const [errorAlerts, setErrorAlerts] = useState<OverviewErrorAlert[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const pushErrorAlerts = (_overviewResponse) => {
    const dashboardErrorAlerts: OverviewErrorAlert[] = [];

    if (_overviewResponse?.errors?.employee_policy_count > 0) {
      dashboardErrorAlerts.push({
        msg: `${t('dashboard_alert.company.employee_policy_count', {
          count: _overviewResponse?.errors?.employee_policy_count,
        })}`,
        linkText: `${t('employees')}`,
        link: '/employees',
      });
    }

    if (_overviewResponse?.errors?.cost_validation_count > 0) {
      dashboardErrorAlerts.push({
        msg: `${t('dashboard_alert.company.cost_validation_count', {
          count: _overviewResponse?.errors?.cost_validation_count,
        })}`,
        linkText: `${t('data_management')}`,
        link: '/data-management',
      });
    }
    setErrorAlerts(dashboardErrorAlerts);
  };

  const fetchOverviewData = async () => {
    const overviewData = await getCompanyAdminDashboardData();
    setOverviewResponse({ ...overviewData });
    setOverviewChartData({ pieChart: overviewData?.pie, lineChart: overviewData?.lineChart });
    setOverviewCardsData({
      totalSalary: overviewData.totalSalary,
      totalEmployee: overviewData.totalEmployee,
      socialFees: overviewData.socialFees,
      totalPension: overviewData.totalPension,
      specialSalaryTax: overviewData.specialSalaryTax,
    });
    pushErrorAlerts(overviewData);
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    fetchOverviewData();
  }, []);
  const changelogItem = changelog[0];

  const containerRef = useRef<any>();

  useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.firstElementChild.classList.add('fs-text-400');
      containerRef.current.firstElementChild.classList.add('theme-text');
    }
  });

  const fetchCollectionItems = async () => {
    try {
      const collections = await getCollections();
      collections.forEach(async (collection) => {
        const collectionItem = await getCollectionItem(collection._id!);
        const isChangelogPosts = collection.type === PostType.ChangelogPosts;
        const isOverviewPosts = collection.type === PostType.OverviewPosts;

        setIsBusy(false);
        if (collectionItem.items.length > 0) {
          if (isOverviewPosts) {
            setCollectionItems({
              blogs: filter(collectionItem.items, (i) => i['post-type'] !== gettingStartedCategoryId),
              getStartedItems: filter(collectionItem.items, { 'post-type': gettingStartedCategoryId }),
              instructions: filter(collectionItem.items, { 'post-type': postsCategoryId }),
            });
          } else if (isChangelogPosts) {
            setChangelog(collectionItem.items as ChangelogPostItem[]);
            setShowModal(true);
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = () => setShowModal(!showModal);

  useEffect(() => {
    fetchCollectionItems();
    fetchOverviewData();
  }, []);
  return (
    <Container fluid className="p-0">
      <PagesHeader headingClass="fs-h1" pageTitle="Overview" />
      <div className="d-flex gap-2">
        <div style={{ width: '100%' }} className="p-0">
          <NumberCards cardsData={overviewCardsData ?? {}} isLoading={isLoading} />
          {errorAlerts.length > 0 && <DashboardErrorAlert dashboardErrorAlerts={errorAlerts} />}
          <ChartsSection chartData={overviewChartData ?? {}} isLoading={isLoading} />
          {isBusy ? <Spinner size="sm" /> : <GettingStarted getStartedItems={collectionItems.getStartedItems!} />}
          {isBusy ? <Spinner size="sm" /> : <InstructionBoxes instructions={collectionItems.instructions!} />}
        </div>
        <div className="mt-3">
          {/* <SmallAlerts /> */}
          <DeadlineSidebar />
          <PagesHeader headingClass="fs-h3 mt-2 mb-0" pageTitle="Last transactions" />
          <LastTransactionSidebar />
          {isBusy ? <Spinner size="sm" /> : <BlogsSection blogs={collectionItems.blogs!} />}
        </div>
      </div>

      {
        //TODO: Use showModal state instead of false to enable the model
        false && (
          <BSModal
            className="overview-feature-modals"
            onHide={() => setShowModal(false)}
            title=""
            show={showModal}
            ModalFooter={
              <Button className="app-btn-primary ms-auto" onClick={toggleModal}>
                {t('continue')}
              </Button>
            }
          >
            <img
              style={{ width: '100%', objectFit: 'cover' }}
              height={354}
              src={changelogItem['top-image'].url}
              alt="logo"
            />
            <div style={{ padding: 'var(--bs-modal-padding)' }}>
              <h1 className="fs-h1 mt-3 theme-text">{changelogItem.heading}</h1>
              <div ref={containerRef} dangerouslySetInnerHTML={{ __html: changelogItem['body-content'] }} />
            </div>
          </BSModal>
        )
      }
    </Container>
  );
}

export default OverviewPage;
