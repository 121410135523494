import { Briefcase, Feather, Icon, Percent, User, Activity, Users } from 'react-feather';
import HorizontalRule from '../../../components/base/HorizontalRule';
import CoinsIcon from '../../../assets/img/icons/coins.svg';
import { UserRole } from '../../../types/auth';
import useAuth from '../../../hooks/useAuth';
import { ReactComponent as CompanyIcon } from '../../../assets/img/icons/company-2.svg';
import { ReactComponent as PiggyBank } from '../../../assets/img/icons/saving-coin.svg';
import { Calendar } from '../../../util/calendar';
import { numberFormat } from '../../../util/util';
import { useTranslation } from 'react-i18next';
type NumbersData = {
  title: string;
  subTitle: string;
  icon: Icon | string;
  custom?: boolean;
};

// const numbers: NumbersData[] = [
//   { title: '56', subTitle: 'Employees (Apr)', icon: User },
//   { title: '2 240 334', subTitle: 'SEK total gross salary (Apr)', icon: CoinsIcon, custom: true },
//   { title: '350 000', subTitle: 'SEK total social fees (Mar)', icon: Feather },
//   { title: '349 940', subTitle: 'SEK total pension (Mar)', icon: Briefcase },
//   { title: '203 992', subTitle: 'SEK special salary tax (Mar)', icon: Percent },
// ];

type Props = {
  cardsData?: any;
  isLoading?: boolean;
};

export const NumberCards = ({ cardsData, isLoading }: Props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const lastMonth = Calendar.lastMonthFullName;

  const numbersForCompanyAdmin: NumbersData[] = [
    {
      title: cardsData.totalEmployee,
      subTitle: `${t('employees')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: User,
    },
    {
      title: cardsData.totalSalary,
      subTitle: `${t('sek_total_gross_salary')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: CoinsIcon,
      custom: true,
    },
    {
      title: cardsData.socialFees,
      subTitle: `${t('sek_total_social_fees')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: Feather,
    },
    {
      title: cardsData.totalPension,
      subTitle: `${t('sek_total_pension')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: Briefcase,
    },
    {
      title: cardsData.specialSalaryTax,
      subTitle: `${t('sek_special_salary_tax')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: Percent,
    },
  ];

  const numbersFoSuperAdmin: NumbersData[] = [
    { title: cardsData.totalCompanyClients, subTitle: `${t('company_clients')} (${t('today')})`, icon: Briefcase },
    { title: cardsData.totalEmployees, subTitle: `${t('total_employees')} (${t('today')})`, icon: Users },
    {
      title: cardsData.pensionOffset,
      subTitle: `${t('pension_offset')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: CoinsIcon,
      custom: true,
    },
    {
      title: cardsData.companyBilled,
      subTitle: `${t('company_billed')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: Activity,
    },
    { title: cardsData.activeEmployees, subTitle: `% ${t('active_employees')} (${t('today')})`, icon: Percent },
  ];

  const numbersForAccountingFirm: NumbersData[] = [
    { title: cardsData.totalCompanyClients, subTitle: `${t('clients')}`, icon: CompanyIcon },
    { title: cardsData.totalEmployees, subTitle: `${t('employees')}`, icon: Users },
    {
      title: cardsData.totalSalaries,
      subTitle: `${t('salary')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: CoinsIcon,
      custom: true,
    },
    {
      title: cardsData.socialFees,
      subTitle: `${t('sek_total_social_fees')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: CoinsIcon,
      custom: true,
    },
    {
      title: cardsData.totalPension,
      subTitle: `${t('sek_total_pension')} (${t(`SHORT_MONTH_NAME.${lastMonth}`)})`,
      icon: PiggyBank,
    },
  ];

  const formattingStatsNumber = (val) => {
    if (isNaN(val)) {
      return val;
    }

    return numberFormat(val, { decimal: 0 });
  };

  return (
    <div className="number-cards-wrapper">
      {UserRole.COMPANY_ADMIN === user?.role.role &&
        numbersForCompanyAdmin.map((number, index) => (
          <div key={index} className="number-card dark-content-section">
            <div className="d-flex card-img-box">
              <div className="card-img">
                {number.custom ? <img src={number.icon as string} alt="logo" /> : <number.icon stroke="#0D830B" />}
              </div>

              <div className="card-num-box">
                <span className="card-title fs-h1-extended theme-text lh-1">
                  {!isLoading ? formattingStatsNumber(number.title) : 0}
                </span>
                <HorizontalRule color="#D3D3D3" height="1px" marginBottom="0" />
              </div>
            </div>
            <div className="card-desc">
              <p className="theme-text">{number.subTitle}</p>
            </div>
          </div>
        ))}
      {UserRole.SUPER_ADMIN === user?.role.role &&
        numbersFoSuperAdmin.map((number, index) => (
          <div key={index} className="number-card dark-content-section">
            <div className="d-flex card-img-box">
              <div className="card-img">
                {number.custom ? <img src={number.icon as string} alt="logo" /> : <number.icon stroke="#0D830B" />}
              </div>

              <div className="card-num-box">
                <span className="card-title fs-h1-extended theme-text lh-1">
                  {!isLoading ? formattingStatsNumber(number.title) : 0}
                </span>
                <HorizontalRule color="#D3D3D3" height="1px" marginBottom="0" />
              </div>
            </div>

            <div className="card-desc">
              <p className="theme-text">{number.subTitle}</p>
            </div>
          </div>
        ))}
      {UserRole.ACCOUNTING_FIRM === user?.role.role &&
        numbersForAccountingFirm.map((number, index) => (
          <div key={index} className="number-card dark-content-section">
            <div className="d-flex card-img-box">
              <div className="card-img">
                {number.custom ? <img src={number.icon as string} alt="logo" /> : <number.icon stroke="#0D830B" />}
              </div>

              <div className="card-num-box">
                <span className="card-title fs-h1-extended theme-text lh-1">
                  {!isLoading ? formattingStatsNumber(number.title) : 0}
                </span>
                <HorizontalRule color="#D3D3D3" height="1px" marginBottom="0" />
              </div>
            </div>
            <div className="card-desc">
              <p className="theme-text">{number.subTitle}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

NumberCards.displayName = 'NumberCards';
