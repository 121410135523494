import { Col, Form } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import classNames from 'classnames';
import { t } from 'i18next';
import usePensionMode from '../hooks/usePensionMode';
import { Info } from 'react-feather';

interface InputProps {
  empolyeeAttachedPersonalPolicy: any;
}

export const PensionPolicyDisabledView = ({ empolyeeAttachedPersonalPolicy }: InputProps) => {
  const policyType = null;
  const pensionMode = usePensionMode();

  return (
    <div className="employee-policy">
      {pensionMode.hasOnlyScheduled() && (
        <div className="p-2 mb-3 d-flex align-items-center alert alert-info theme-text">
          <Info className="icon-gap-right" />
          <span>{t('scheduled_update_prevents_settings_creation')}</span>
        </div>
      )}
      <PagesHeader pageTitle="Pension policy" />
      <Form>
        <div className="d-flex mb-5 gap-2 pension-policy-selector flex-wrap-row">
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'companyPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'companyPolicy'}
              disabled
              inline
              value="companyPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.company_policy')}`}
              className="fs-name mb-1"
              id="companyPolicy"
            />
            <Form.Control disabled />
          </div>
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'correctoProvidedPolicy',
            })}
          >
            <Form.Check
              disabled
              inline
              value="correctoProvidedPolicy"
              name="policyType"
              type="radio"
              label={t('correcto_provided')}
              className="fs-name mb-1"
              id="correctoProvidedPolicy"
            />
            <Form.Control disabled />
          </div>
          <div
            style={{ width: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'personalPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'personalPolicy'}
              disabled
              inline
              value="personalPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.personal_policy')}`}
              className="fs-name radio-button-label"
              id="personalPolicy"
            />

            <div>{empolyeeAttachedPersonalPolicy && <p>{empolyeeAttachedPersonalPolicy?.policyName}</p>}</div>
          </div>
          <Col
            style={{ height: 86, width: 240, maxWidth: 240, minWidth: 240 }}
            className={classNames('dark-content-section highlight-editable-option', {
              'highlight-editable-option--selected': policyType === 'noPolicy',
            })}
          >
            <Form.Check
              checked={policyType === 'noPolicy'}
              disabled
              inline
              value="noPolicy"
              name="policyType"
              type="radio"
              label={`${t('PENSION_POLICY.no_policy')} `}
              className="fs-name"
              id="noPolicy"
            />

            <Form.Check
              disabled
              inline
              name="advisoryInvite"
              type="checkbox"
              label={t('invite_employee_to_correcto_advisory')}
              className="fs-name d-flex align-items-start mt-2"
              id="inviteEmployeeDisabled"
            />
          </Col>
        </div>
      </Form>
    </div>
  );
};

export default PensionPolicyDisabledView;
