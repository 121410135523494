export enum ComparisonSeriesReportType {
  GRAPH = 'Graph',
  TABLE = 'Table',
}

export interface IReportFilterParams {
  duration?: {
    from: string;
    to: string;
  };
  employeeIds?: any[];
  companyClientId?: string;
  type?: ComparisonSeriesReportType;
  year?: string;
}

export interface IBusinessAnalysisFilterParams {
  duration?: {
    from: string;
    to: string;
  };
  companyClientId?: string;
  report_name?: 'base-salaries' | 'real-salaries' | 'salary-changes' | 'pensions-calculated_and_report';
}

export interface IEmployeeSalaryReportResponse {
  salariesByDate: Array<{
    date: string;
    total: number;
    employeeSalaries: Array<{
      totalPayable: {
        value: number;
      };
      employee: {
        id: string;
        fullName: string;
      };
    }>;
  }>;
  employees: Array<{
    id: string;
    fullName: string;
    personalNumber: string;
  }>;
}

export interface IEmployeeByReportTypeResponse {
  salariesByDate: Array<{
    date: string;
    total: number;
    employeeDataByReportType: Array<{
      totalPayable: {
        value: number;
      };
      reportType: {
        id: string;
        fullName: string;
      };
    }>;
  }>;
  employees: Array<{
    id: string;
    fullName: string;
    personalNumber: string;
  }>;
}

export interface ICompanyEmployeesFilterParams {
  page: number;
  pageSize?: number;
  sortField?: string;
  sortOrder?: string;
  sort?: any;
  filter?: {
    fullName: { like: string };
    personnelType: { like: string };
  };
  queryKey?: string;
}

export interface IReportGenreInfo {
  fieldKey: string;
  export?: {
    requestName: ReportSlugName;
  };
  reportURL?: string;
}
export interface IChartGenreInfo {
  fieldKey: string;
}

export enum ReportCategory {
  GROSS_SALARY = 'gross_salary',
  SOCIAL_TAX = 'social_tax',
  CALCULATED_PENSION = 'calculated_pension',
  SALARY_CHANGE = 'salary_change',
  SPECIAL_PAYROLL_TAX = 'special_payroll_tax',
}

export type ReportSlugName =
  | 'base-salaries'
  | 'real-salaries'
  | 'salary-changes'
  | 'pensions-calculated_and_report'
  | 'net-salaries'
  | 'special-payroll-tax'
  | 'social-tax'
  | 'customizable'
  | 'salary-type'
  | 'employee-benefit';

export const ReportNameMap: Partial<{ [key in ReportSlugName]: string }> = {
  'base-salaries': 'Base-Salary',
  'real-salaries': 'Real-Salary',
  'salary-changes': 'Salary-Changes',
  'net-salaries': 'Net-Salary',
  'pensions-calculated_and_report': 'Pensions-Calculated-And-Report',
  'special-payroll-tax': 'Special-Payroll-Tax',
  'social-tax': 'Social-Tax',
  customizable: 'Customizable',
  'salary-type': 'Salary-Type',
  'employee-benefit': 'Employee-Benefit',
};

export enum FileExportType {
  XLSX = 'xlsx',
  CSV = 'csv',
}

export enum MimeResponseType {
  xml = 'blob',
  zip = 'arraybuffer',
  excel = 'blob',
  csv = 'blob',
  pdf = 'blob',
}

export enum TaxesReportSlugName {
  SPECIAL_PAYROLL_TAX = 'special-payroll-tax',
  SOCIAL_TAX = 'social-tax',
}

export enum EmployeeBenefitReportType {
  PENSION_OFFSET = 'pension-offset',
  SALARY_CHANGE = 'salary-change',
  INSURANCE = 'insurance',
}

export interface IEmployeeeBenefitFilterParams extends IReportFilterParams {
  employee_benefit_report_name: EmployeeBenefitReportType;
}

export interface EmployeeBenefitSummary {
  employee: {
    id: string;
    fullName: string;
    personalNumber: string;
  };
  pensionManagerName: {
    shortName: string;
    companyName: string;
    pensionManagerId: string;
  };
  plan: string;
  insuranceNumber: string;
  period: string;
  ao: string;
  amount: number;
}

export enum ReportResponseField {
  PENSION_OFFSET = 'employeeBenefitsPensionOffsetReport',
  SALARY_CHANGE = 'employeeBenefitsSalaryChangeReport',
  INSURANCE = 'employeeBenefitsInsuranceReport',
}
