import { AuthUser, UserSubRole } from '../../../types/auth';
import {
  ADMIN_COMPANIES_DETAIL_ENDPOINT,
  API_VERSION,
  INITIALIZE_ADMIN_AUTH,
  INITIALIZE_AUTH,
  INVOKE_TOKEN,
  SESSION_REDIRECT_PATH,
  USER_SESSION,
  USERS,
  AUTH_INIT_COMPANY_SESS,
  AUTH_INIT_COMPANY_TOKEN,
  USERS_PERSONAL,
} from '../../../util/constants';
import { UserRole } from './../../../types/auth';
import { post, get } from '../../../util/httpService';
import { InitilizeSessionType, InvokeSessionType, UserDetailsResponse } from './types';

const initializeSession = async (method: string) => {
  const endpoint = `${API_VERSION}${INITIALIZE_AUTH}${SESSION_REDIRECT_PATH}`;
  const session: InitilizeSessionType = await post(
    endpoint,
    {
      signinMethod: method,
    },
    {},
  );

  return session;
};

const initializeAdminSession = async (method: string) => {
  const endpoint = `${API_VERSION}${INITIALIZE_ADMIN_AUTH}${SESSION_REDIRECT_PATH}`;
  const session: InitilizeSessionType = await post(
    endpoint,
    {
      signinMethod: method,
    },
    {},
  );

  return session;
};

const invokeToken = async ({ personalNumber, companyId, role }) => {
  const endpoint = `${API_VERSION}${INVOKE_TOKEN}`;
  const session: InvokeSessionType = await post(
    endpoint,
    {
      personalNumber,
      companyId,
      role: role,
    },
    {},
  );

  return session;
};

const getUserSession = async (sessionId: string | null, role) => {
  const endpoint = `${API_VERSION}${USER_SESSION}/${sessionId}/${role}`;
  const session = await get(endpoint);

  return session;
};

const getAdminCompaniesDetail = async (adminPersonalNumber: string) => {
  const endpoint = `${API_VERSION}${ADMIN_COMPANIES_DETAIL_ENDPOINT}/${adminPersonalNumber}`;
  const session = await get(endpoint);

  return session;
};

const getUserDetailsByPersonalNumber = async (personalNumber, role: string | null = null) => {
  const endpoint = `${API_VERSION}${USERS}?personalNumber=${personalNumber}${role ? '&role=' + role : ''}`;
  const session: UserDetailsResponse = await get(endpoint);

  return session;
};

const getConsultantDetailsByPersonalNumber = async (personalNumber, role: string | null = null) => {
  const endpoint = `${API_VERSION}${USERS}/consultant?personalNumber=${personalNumber}${role ? '&role=' + role : ''}`;
  const session: UserDetailsResponse = await get(endpoint);

  return session;
};

const getDetailsByPersonalNumber = async (personalNumber, role: string | null = null) => {
  const endpoint = `${API_VERSION}${USERS_PERSONAL}?personalNumber=${personalNumber}${role ? '&role=' + role : ''}`;
  const session: UserDetailsResponse = await get(endpoint);

  return session;
};

const reInitializeSessionWithCompany = async ({
  personalNumber,
  companyId,
  isSaveInitializedSession,
  subRole,
}: {
  personalNumber?: string;
  companyId?: string;
  isSaveInitializedSession?: boolean;
  subRole?: UserSubRole | string;
}) => {
  const userSession = await invokeToken({
    personalNumber: personalNumber,
    companyId: companyId,
    role: subRole || 'USER',
  });
  let navigateLink = '/dashboard';

  if (userSession?.user?.role?.role === UserRole.ACCOUNTING_FIRM && userSession?.user?.role?.subRole) {
    navigateLink = '/accounting-firms';
  }

  if (isSaveInitializedSession && !hasCompanyUserInitSession()) {
    copyInitialSession();
  }

  localStorage.setItem('user', JSON.stringify(userSession.user));
  localStorage.setItem('access_token', userSession.accessToken);

  return {
    userSession,
    redirectTo: navigateLink,
  };
};

const hasCompanyUserInitSession = () => {
  return localStorage.getItem(AUTH_INIT_COMPANY_SESS);
};

const switchUserSessionToInitial = () => {
  const userSession = localStorage.getItem(AUTH_INIT_COMPANY_SESS);
  const initialUserToken = localStorage.getItem(AUTH_INIT_COMPANY_TOKEN);
  const userSessionDetails = userSession ? (JSON.parse(userSession) as AuthUser) : null;
  let navigateLink = '/dashboard';

  if (userSessionDetails?.role?.role === UserRole.ACCOUNTING_FIRM && userSessionDetails?.role?.subRole) {
    navigateLink = '/accounting-firms';
  }

  localStorage.setItem('user', userSession!);
  localStorage.setItem('access_token', initialUserToken!);

  localStorage.removeItem(AUTH_INIT_COMPANY_SESS);
  localStorage.removeItem(AUTH_INIT_COMPANY_TOKEN);

  return {
    userSession: userSessionDetails,
    redirectTo: navigateLink,
  };
};

const copyInitialSession = () => {
  const userSession = localStorage.getItem('user');
  const userToken = localStorage.getItem('access_token');

  localStorage.setItem(AUTH_INIT_COMPANY_SESS, userSession!);
  localStorage.setItem(AUTH_INIT_COMPANY_TOKEN, userToken!);
};

const getInitializedUserSession = () => {
  const session = localStorage.getItem(AUTH_INIT_COMPANY_SESS);
  const userSession = session ? (JSON.parse(session) as AuthUser) : null;

  return userSession;
};

export {
  initializeSession,
  invokeToken,
  getAdminCompaniesDetail,
  getUserSession,
  initializeAdminSession,
  getUserDetailsByPersonalNumber,
  getConsultantDetailsByPersonalNumber,
  reInitializeSessionWithCompany,
  hasCompanyUserInitSession,
  switchUserSessionToInitial,
  copyInitialSession,
  getInitializedUserSession,
  getDetailsByPersonalNumber,
};
