import { Dispatch, SetStateAction } from 'react';
import { ContactPeople } from '../../../api/service/superAdmin/company-clients/types';
import { FormikProps } from 'formik';

export interface LooseObject {
  [key: string]: any;
}

export type DateType = {
  createdAt: string;
  updatedAt: string;
};

export type CollectiveAgreementType = {
  id: string;
  name: string;
};

export type ResellerAgreementType = {
  id: string;
  name: string;
};

export type CompanyDetails = {
  organizationNumber: string;
  name: string;
  status: string;
};

export type AddressDetails = {
  city: string;
  country: string;
  postcode: string;
  street: string;
};

export type AuthorizedSignatory = {
  _id?: string;
  id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  incharge?: boolean;
  positions?: Position[];
  position?: string;
};

export type AdminDetails = {
  _id?: string;
  id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
};

export type Consultants = {
  _id?: string;
  id?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  short?: string;
  position?: string;
  personalNumber?: string;
  email?: string;
  role?: any;
  accountingFirm?: any;
};

export type AccountingFirm = {
  _id?: string;
  id?: string;
  name?: string;
  organizationNumber?: string;
  seniorConsultants: [];
};

export type Position = {
  roleName: string;
  roleCode: number;
};

export type CompanyClientForm = {
  companyDetails: CompanyDetails;
  address: AddressDetails;
  contractEffectiveFrom: string;
  contractEmail: string;
  contractNumber: string;
  invoiceCostCenter: string;
  authorizedSignatory: AuthorizedSignatory[];
  combinations: AuthorizedSignatoryGroup[];
  admins: AdminDetails[];
  consultants: Consultants[];
  consultant: Consultants;
  salaryContactPeople: ContactPeople;
  accountingContactPeople: ContactPeople;
  collectiveAgreements: CollectiveAgreementType[];
  invitationEmail: string;
  allowCorrectoAccess: boolean,
  allowAdvisorAccess: boolean
};

export type CompanyClientFormGroup = FormikProps<CompanyClientForm>;

export interface ICompanyClientContext {
  companyDetails: CompanyDetails;
  setCompanyDetails: Dispatch<SetStateAction<CompanyDetails>>;

  addressDetails: AddressDetails;
  setAddressDetails: Dispatch<SetStateAction<AddressDetails>>;

  adminDetails: Array<AdminDetails>;
  setAdminDetails: Dispatch<SetStateAction<Array<AdminDetails>>>;

  signatoryDetails: Array<AuthorizedSignatory>;
  setSignatoryDetails: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  signatoryAdmins: Array<AuthorizedSignatory>;
  setSignatoryAdmins: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  deletedAdmins: Array<string>;
  setDeletedAdmins: Dispatch<SetStateAction<Array<string>>>;

  deletedConsultants: Array<string>;
  setDeletedConsultants: Dispatch<SetStateAction<Array<string>>>;

  combinations: Array<AuthorizedSignatoryGroup>;
  setCombinations: Dispatch<SetStateAction<Array<AuthorizedSignatoryGroup>>>;

  salaryContactPeople: ContactPeople;
  setSalaryContactPeople: Dispatch<SetStateAction<ContactPeople>>;

  accountingContactPeople: ContactPeople;
  setAccountingContactPeople: Dispatch<SetStateAction<ContactPeople>>;

  contractEffectiveFrom: string;
  setContractEffectiveFrom: Dispatch<SetStateAction<string>>;

  invoiceCostCenter: string;
  setInvoiceCostCenter: Dispatch<SetStateAction<string>>;

  contractEmail: string;
  setContractEmail: Dispatch<SetStateAction<string>>;

  contractNumber: string;
  setContractNumber: Dispatch<SetStateAction<string>>;

  collectiveAgreements: Array<CollectiveAgreementType>;
  setCollectiveAgreements: Dispatch<SetStateAction<Array<CollectiveAgreementType>>>;

  consultants: Array<Consultants>;
  setConsultants: Dispatch<SetStateAction<Array<Consultants>>>;

  accountingFirms: Array<AccountingFirm>;
  setAccountingFirms: Dispatch<SetStateAction<Array<AccountingFirm>>>;

  invitationEmail: string;
  setInvitationEmail: Dispatch<SetStateAction<string>>;

  selectedCompanyClient?: any;
  setCompanyClient?: (companyClient: any) => void;

  isEditMode: boolean;

  formGroup: CompanyClientFormGroup;

  replaceAdminDetails: (value: any) => void;
  replaceSignatoryAdmins: (value: any) => void;
  replaceAddress: (value: any) => void;
  appendDeletedAdmin: (value: any) => void;
  appendDeletedConsultant: (value: any) => void;
  replaceAuthorizedSignatory: (value: any) => void;
  replaceCombinations: (value: any) => void;
  replaceCompanyDetails: (value: any) => void;
  replaceConsultants: (value: any) => void;
  replaceConsultant: (value: any) => void;
  replaceAccountingContactPeople: (value: any) => void;
  replaceSalaryContactPeople: (value: any) => void;
  updateAdminDetails: (inputName: any, inputValue: any) => void;
  updateAddress: (inputName: any, inputValue: any) => void;
  updateAuthorizedSignatory: (inputName: any, inputValue: any) => void;
  updateCombinations: (inputName: any, inputValue: any) => void;
  updateCompanyDetails: (inputName: any, inputValue: any) => void;
  updateConsultants: (inputName: any, inputValue: any) => void;
  updateConsultant: (inputName: any, inputValue: any) => void;
  updateAccountingContactPeople: (inputName: any, inputValue: any) => void;
  updateSalaryContactPeople: (inputName: any, inputValue: any) => void;
  updateContractEffectiveInput: (inputValue: any) => void;
  updateInvoicingCostCenter: (inputValue: any) => void;
  updateInvoiceEmailAddress: (inputValue: any) => void;
  updateContractNumber: (inputValue: any) => void;
  replaceCollectiveAgreements: (inputValue: any) => void;
  updateInvitationEmail: (inputValue: any) => void;
  replaceAccessLevel: (allowCorrectoAccess, allowAdvisorAccess) => void
}

export type FormProps = {
  context: ICompanyClientContext;
  date?: DateType;
  formGroup: CompanyClientFormGroup;
};

export enum CompanyClientSaveMode {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SETUP = 'Setup',
}

// export interface IEditCompanyClientContext {
//   companyDetails: Record<string, string> | null;
//   setCompanyDetails: Dispatch<SetStateAction<Record<string, string> | null>>;

//   adminDetails: Array<any>;
//   setAdminDetails: Dispatch<SetStateAction<Array<any>>>;

//   signatoryDetails: Array<any>;
//   setSignatoryDetails: Dispatch<SetStateAction<Array<any>>>;

//   addressDetails: Record<string, string> | null;
//   setAddressDetails: Dispatch<SetStateAction<Record<string, string> | null>>;

//   salaryContactPeople: ContactPeople;
//   setSalaryContactPeople: Dispatch<SetStateAction<ContactPeople>>;

//   accountingContactPeople: ContactPeople;
//   setAccountingContactPeople: Dispatch<SetStateAction<ContactPeople>>;

//   contractEffectiveFrom: string;
//   setContractEffectiveFrom: Dispatch<SetStateAction<string>>;

//   collectiveAgreements: Array<CollectiveAgreementType>;
//   setCollectiveAgreements: Dispatch<SetStateAction<Array<CollectiveAgreementType>>>;

//   isEditMode: boolean;
// }

export interface AuthorizedSignatoryGroup {
  authorizedSignatory: AuthorizedSignatory[];
  signee: boolean;
}
