import { Button, Form } from 'react-bootstrap';
import { CornerDownRight, RefreshCw } from 'react-feather';
import set from 'lodash/set';
import { getRoaringPersonInfo } from '../../../../api/service/superAdmin/company-clients/service';
import PagesHeader from '../../../../components/base/PagesHeader';
import { isEmpty } from '../../../../util/util';
import { FormProps } from '../types';
import classNames from 'classnames';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../util/form';
import PersonalNumber from '../../../../components/form/inputs/PersonalNumber';
import useLocale from '../../../../hooks/useLocale';
import { useState } from 'react';
import { DEFAULT_PERSON_INFO } from '../../../../types/general';

export default function PersonalDetailsForm({ context, formGroup }: FormProps) {
  const { personalDetails, setPersonalDetails, isEditMode, patchPersonalDetailsForm } = context;
  const { localize } = useLocale();
  const [isPersonalNumberValid, setIsPersonalNumberValid] = useState<boolean>(true);

  const getMockData = async () => {
    try {
      patchPersonalDetailsForm(DEFAULT_PERSON_INFO);
      const { fullName, email, name, address } = await getRoaringPersonInfo(personalDetails.personalNumber);
      patchPersonalDetailsForm({ name, address, fullName, email });
      setIsPersonalNumberValid(true);
    } catch (e) {
      setIsPersonalNumberValid(false);
    }
  };

  const handleChange = (ev) => {
    const _personalDetails = { ...personalDetails };
    const inputName = ev.target.name;
    const inputValue = ev.target.value;

    formGroup.setFieldValue(inputName, inputValue);
    set(_personalDetails, inputName, inputValue);

    setPersonalDetails((value) => ({
      ...value,
      ..._personalDetails,
    }));

    setIsPersonalNumberValid(true);
  };

  return (
    <>
      <PagesHeader pageTitle="Personal details" />
      <div className="cr-form-row">
        <PersonalNumber
          disabled={isEditMode}
          value={personalDetails.personalNumber}
          onChangeHandler={(e) => handleChange(e)}
          field={formGroup.getFieldMeta('personalNumber')}
          isValid={isPersonalNumberValid}
          lazy={true}
        />

        <div>
          <Button
            disabled={isEditMode ? false : isEmpty(personalDetails.personalNumber)}
            onClick={isEmpty(personalDetails.personalNumber) ? undefined : getMockData}
            className="app-btn-primary"
          >
            {isEditMode ? <RefreshCw className="icon-gap-right" /> : <CornerDownRight className="icon-gap-right" />}
            {isEditMode ? localize('refresh') : `${localize('fetch_data')}`}
          </Button>
        </div>
        <div className="full-name-field">
          <Form.Label className="fs-name mb-0 required">{localize('name')}</Form.Label>
          <Form.Control
            disabled
            type="text"
            name="name"
            className={classNames(errorHighlighter(formGroup.getFieldMeta('fullName')))}
            onChange={(ev) => {
              handleChange(ev);
            }}
            value={formGroup.values.fullName}
          />
          <ErrorFeedback field={formGroup.getFieldMeta('fullName')} />
        </div>
        {isEditMode && (
          <div className="status-field">
            <Form.Label className="fs-name mb-0 ">{localize('status')}</Form.Label>
            <Form.Select name="status" value={personalDetails.status} onChange={handleChange}>
              <option value="">{localize('please_select')}</option>
              <option value="Draft">{localize('draft')}</option>
              <option value="Active">{localize('active')}</option>
              <option value="InActive">{localize('inactive')}</option>
            </Form.Select>
          </div>
        )}
      </div>
      <div className="cr-form-row">
        <div className="address-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0 required">{localize('address')}</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="address.street"
              onChange={(ev) => {
                handleChange(ev);
              }}
              value={formGroup.values.address?.street}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('address.street')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('address.street')} />
          </Form.Group>
        </div>
        <div className="post-code-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0 required">{localize('postcode')}</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              onChange={(ev) => {
                handleChange(ev);
              }}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('address.postcode')))}
              value={formGroup.values.address?.postcode}
              name="address.postcode"
            />
            <ErrorFeedback field={formGroup.getFieldMeta('address.postcode')} />
          </Form.Group>
        </div>
        <div className="city-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0 required">{localize('city')}</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              onChange={(ev) => {
                handleChange(ev);
              }}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('address.city')))}
              value={formGroup.values.address?.city}
              name="address.city"
            />
            <ErrorFeedback field={formGroup.getFieldMeta('address.city')} />
          </Form.Group>
        </div>
        <div className="country-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0 required">{localize('country')}</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              onChange={(ev) => {
                handleChange(ev);
              }}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('address.country')))}
              value={formGroup.values.address?.country}
              name="address.country"
            />
            <ErrorFeedback field={formGroup.getFieldMeta('address.country')} />
          </Form.Group>
        </div>
      </div>

      <div className="cr-form-row mb-5">
        <div className="phone-num-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0 required">{`${localize('phone_number')} (${localize(
              'work',
            )})`}</Form.Label>
            <Form.Control
              size="lg"
              type="number"
              onChange={(ev) => {
                handleChange(ev);
              }}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('phoneNumber.personal')))}
              name="phoneNumber.personal"
              value={formGroup.values.phoneNumber?.personal}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('phoneNumber.personal')} />
          </Form.Group>
        </div>
        <div className="phone-num-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0">{`${localize('phone_number')} (${localize('private')})`}</Form.Label>
            <Form.Control
              size="lg"
              type="number"
              onChange={(ev) => {
                handleChange(ev);
              }}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('phoneNumber.private')))}
              name="phoneNumber.private"
              value={formGroup.values.phoneNumber?.private}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('phoneNumber.private')} />
          </Form.Group>
        </div>
        <div className="email-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0 required">{`${localize('email_address')} (${localize(
              'work',
            )})`}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              onChange={(ev) => {
                handleChange(ev);
              }}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('email')))}
              name="email"
              value={formGroup.values.email}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('email')} />
          </Form.Group>
        </div>
        <div className="email-field">
          <Form.Group>
            <Form.Label className="fs-name mb-0">{`${localize('alternate_email_address')} (${localize(
              'private',
            )})`}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              onChange={(ev) => {
                handleChange(ev);
              }}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('alternateEmail')))}
              name="alternateEmail"
              value={formGroup.values.alternateEmail}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('alternateEmail')} />
          </Form.Group>
        </div>
      </div>
    </>
  );
}
