import { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import Breadcrumb from '../../../components/base/Breadcrumb';
import PagesHeader from '../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import CreateCompanyClientModal from './CreateCompanyClient/CreateCompanyClientModal';
import { CreateCompanyClientProvider } from './context/CreateCompanyClientContext';
import CompanyClientsGrid from './CompanyClientsGrid';
import { useTranslation } from 'react-i18next';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Company Clients',
  },
];

function CompanyClientsPage() {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isCompanyCreated, setIsCompanyCreated] = useState<boolean>(false);

  const toggleModal = () => setShowCreateModal(!showCreateModal);

  return (
    <Container fluid className="p-0">
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader pageTitle="Company Clients" headingClass="fs-h1" />
        <div className="cr-form-row">
          <Button onClick={toggleModal} className="app-btn-primary me-1">
            <Plus className="icon-gap-right" />
            {`${t('new_company_client')}`}
          </Button>
        </div>
      </div>
      <CompanyClientsGrid isCompanyCreated={isCompanyCreated} />

      {showCreateModal && (
        <CreateCompanyClientProvider>
          <CreateCompanyClientModal
            setIsCompanyCreated={setIsCompanyCreated}
            toggleModal={toggleModal}
            showCreateModal={showCreateModal}
          />
        </CreateCompanyClientProvider>
      )}
    </Container>
  );
}

export default CompanyClientsPage;
