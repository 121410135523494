import { useCallback, useEffect, useState } from 'react';
import { useValidationError } from '../../../../hooks/useValidationError';
// import { ConfirmationDialog } from '../../../../util/dialogs';
// import { t } from 'i18next';
import { AccessLevelEditor } from './types';
import { useAccountingFirmEditGeneralSettingsContext } from '../General/context/useContext';
import { isEqual } from '../../../../util/util';
import { saveAccountingFirmAccessLevel } from '../../../../api/service/accountingFirm/settings/service';
// import _ from 'lodash';

const useAccessLevelEditor = (): AccessLevelEditor => {
  const { errors, /*  isInvalid, */ setErrors } = useValidationError();
  const [hasAccessLevelChanges, setHasAccessLevelChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    allowAdvisorRemoteAccess,
    allowCorrectoRemoteAccess,
    deletedConsultants,
    firmDetails,
    accessConsultants,
    replaceAccessConsultants,
    replaceAccess,
    setFirmDetails,
  } = useAccountingFirmEditGeneralSettingsContext();

  const save = useCallback(async () => {
    try {
      setIsLoading(true);

      const payload = {
        allowCorrectoAccess: allowCorrectoRemoteAccess,
        allowAdvisorAccess: allowAdvisorRemoteAccess,
        deletedConsultants: deletedConsultants,
      };
      await saveAccountingFirmAccessLevel(payload, firmDetails._id);
      setFirmDetails({
        ...firmDetails,
        seniorConsultants: accessConsultants,
        allowAdvisorAccess: allowAdvisorRemoteAccess,
        allowCorrectoAccess: allowCorrectoRemoteAccess,
      });
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, allowAdvisorRemoteAccess, allowCorrectoRemoteAccess, deletedConsultants, errors, firmDetails]);

  const cancelAccessLevelChanges = useCallback(() => {
    replaceAccess(firmDetails['allowCorrectoAccess'], firmDetails['allowAdvisorAccess']);
    replaceAccessConsultants(firmDetails['seniorConsultants'] || []);
  }, [allowAdvisorRemoteAccess, allowCorrectoRemoteAccess, accessConsultants]);

  useEffect(() => {
    if (firmDetails) {
      setHasAccessLevelChanges(
        !isEqual(firmDetails['seniorConsultants'] || [], accessConsultants) ||
          !isEqual(firmDetails['allowAdvisorAccess'] || false, allowAdvisorRemoteAccess) ||
          !isEqual(firmDetails['allowCorrectoAccess'] || false, allowCorrectoRemoteAccess),
      );
    }
  }, [firmDetails, accessConsultants, allowAdvisorRemoteAccess, allowCorrectoRemoteAccess]);

  return {
    isLoading,
    hasAccessLevelChanges,
    save,
    cancelAccessLevelChanges,
  };
};

export default useAccessLevelEditor;
