import React from 'react';
import { Accordion } from 'react-bootstrap';
import classNames from 'classnames';
import CostSpecification from './CostSpecification';
import { Mode } from '../../types';
import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/settings/pension-manager.module.scss';
import useCostSpecification from '../hooks/useCostSpecification';

type Props = {
  planIndex: number;
  mode: Mode;
};

const CostSpecificationViewDetails = ({ planIndex, mode }: Props) => {
  const { getCostSpecification } = useCostSpecification(planIndex);
  const costSpecControl = getCostSpecification(planIndex);
  const isViewMode = mode === Mode.Detail;

  if (isViewMode && !costSpecControl?.description && !costSpecControl?.specItems.length) {
    return <></>;
  }

  return (
    <div className={classNames('insurance-table-accordion editor-accordion pp-accordion mb-2', themeStyle.accordion)}>
      <Accordion defaultActiveKey="costSpec" className="policy-desc-accordion">
        <Accordion.Item eventKey="costSpec">
          <Accordion.Header>
            <h4 className="fs-h4 theme-text-dark text-bold">Cost specification</h4>
          </Accordion.Header>
          <Accordion.Body className={classNames('policy-accordion-body', themeStyle.accordion_body)}>
            <CostSpecification planIndex={planIndex} mode={mode} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default CostSpecificationViewDetails;
