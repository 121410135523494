import { Button, Form } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import useAuth from '../../../../hooks/useAuth';
import { useState, useEffect } from 'react';
import { syncFortnoxEmployees, verifyFortnoxSession } from '../../../../api/service/company/settings';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getIntegrations } from '../../../../api/service/superAdmin/settings/integrations';
import { Integration } from '../types';

export const Integrations = () => {
  const [isActive, setIsActive] = useState(true);
  const { user } = useAuth();
  const [, /*isBusy*/ setIsBusy] = useState(false);
  const [, /* selectedInegrationType */ setSelectedIntegrationType] = useState('');

  const [integrations, setIntegrations] = useState<Integration[]>([]);
  const baseURL = window.location.origin;
  const { t } = useTranslation();
  const fortnoxURL = `https://apps.fortnox.se/oauth-v1/auth?client_id=w4tdYANjgD0f&redirect_uri=https%3A%2F%2Ftest.correcto.io%2Fapi%2Fv1%2Ffortnox%2Fauth%2Factivation&scope=companyinformation%20supplier%20salary%20supplierinvoice%20connectfile%20archive%20payment&state={"redirectTo":"${baseURL}/settings","userId":"${user?.id}","companyClientId":"${user?.role.company.id}"}&access_type=offline&response_type=code&account_type=service`;
  const connectToFortnox = async () => {
    try {
      setIsBusy(true);
      const response = await verifyFortnoxSession();

      if (response.accessToken) {
        setIsActive(false);

        return;
      }

      setIsActive(true);
    } finally {
      setIsBusy(false);
    }
  };

  const syncEmployees = async () => {
    await syncFortnoxEmployees();
  };

  const handleIntegrationType = (e) => {
    const { value } = e.target;
    setSelectedIntegrationType(value);
  };

  const fetchIntegrations = async () => {
    const _integrations = await getIntegrations();

    setIntegrations(
      _integrations.map((integration) => {
        return { ...integration, formattedShortName: integration.shortName.toLowerCase().trim() };
      }),
    );
  };
  useEffect(() => {
    fetchIntegrations();
    connectToFortnox();
  }, []);

  return (
    <CardComponent>
      {/* <a rel="noopener noreferrer" href={fortnoxURL}>
        <Button className="app-btn-primary" disabled={isActive}>
          {isBusy ? <Spinner size="sm" /> : <ExternalLink className="icon-gap-right" />}Activate integration with
          Fortnox
        </Button>
      </a>
      {isActive && (
        <Button className="app-btn-primary" style={{ marginLeft: 10 }} onClick={syncEmployees}>
          Sync Employees
        </Button>
      )} */}
      {integrations.map((integration, index) => (
        <div key={index}>
          <div
            className={classNames(
              'integration-item ',
              integration.formattedShortName === 'fortnox' ? 'integration-item--selected' : '',
            )}
          >
            <Form.Check
              checked={integration.formattedShortName === 'fortnox'}
              inline
              value={integration.shortName}
              name="integrationType"
              type="radio"
              label={integration.shortName}
              className={classNames('fs-name', 'mt-2', isActive ? 'mb-2' : 'mb-1')}
              id={integration.id}
              onChange={(e) => handleIntegrationType(e)}
              disabled={integration.formattedShortName !== 'fortnox'}
            />
            {
              <div>
                {isActive && integration.formattedShortName === 'fortnox' && (
                  <>
                    <p
                      className={classNames(
                        'theme-text fs-text-400  text-end ms-2 ',
                        integration.formattedShortName !== 'fortnox' ? 'integration-item--disabled ui-blocker' : '',
                      )}
                    >
                      <Link to={fortnoxURL} target="_blank" rel="noreferrer" className="fs-text-700  font-link">
                        Sign in to {integration.shortName}{' '}
                      </Link>
                      {t('and_under_integrations_select_correcto')}.<div>{t('click_to_activate')}.</div>
                    </p>
                    ,
                  </>
                )}
                {integration.formattedShortName === 'fortnox' && !isActive && (
                  <div className="d-flex justify-content-end mt-1 mb-2">
                    <Button className="app-btn-primary" onClick={syncEmployees}>
                      {t('sync_employees')}
                    </Button>
                  </div>
                )}
              </div>
            }
          </div>
          {/* <div
          className={classNames(
            'integration-item ',
            selectedInegrationType === 'visma' ? 'integration-item--selected' : '',
          )}
        >
          <Form.Check
            checked={selectedInegrationType === 'visma'}
            inline
            value="visma"
            name="integrationType"
            type="radio"
            label={t('visma')}
            className={classNames('fs-name', 'mt-2', isActive ? 'mb-2' : 'mb-1')}
            id="visma"
            onChange={(e) => handleIntegrationType(e)}
            disabled
          />

          <p className="theme-text fs-text-400  text-end integration-item--disabled ui-blocker">
            <Link to="" target="_blank" rel="noreferrer" className="fs-text-700  font-link">
              <span>{t('sign_in_to_visma')}</span>
            </Link>{' '}
            and lorum ipsum process
          </p>
        </div> */}
        </div>
      ))}
    </CardComponent>
  );
};
