import React from 'react';
import ProfileContainer from './ProfileContainer';
import { ProfileProvider } from './context/profile.context';

type Props = {};

const ProfileView = (props: Props) => {
  return (
    <>
      <ProfileProvider>
        <ProfileContainer />
      </ProfileProvider>
    </>
  );
};

export default ProfileView;
