import { Container, Nav, Navbar } from 'react-bootstrap';
import useAuth from '../../hooks/useAuth';

import { NavMenuItem } from '../../types/sidebar';
import NavbarLanguages from './NavbarLanguages';
import NavbarUser from './NavbarUser';
import TopMenuItem from './TopMenuItem';

interface TopMenuProps {
  items?: NavMenuItem[];
  logo: string;
}

const TopMenu = ({ items, logo }: TopMenuProps) => {
  const { user } = useAuth();
  const companyName = user?.role?.company?.name || '';

  return (
    <Container className="horizontal-nav-menu">
      <Navbar.Brand className="sidebar-brand" href="/dashboard">
        <img src={logo} alt="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {items?.map((item, idx) => {
            return <TopMenuItem key={idx} title={item.title} pages={item.pages}></TopMenuItem>;
          })}
          {/* <Nav.Link as={NavLink} className="sidebar-link" to="/accounting-firm">
            Clients
          </Nav.Link>*/}
        </Nav>
        <Nav className="navbar-align align-items-center">
          {/* <NavbarLanguages /> */}
          {companyName && <span className="nav-item nav-item--company-title">{companyName}</span>}
          <NavbarLanguages />
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Container>
  );
};

export default TopMenu;
