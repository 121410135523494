/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { NavLink } from 'react-router-dom';

import { Badge, Collapse } from 'react-bootstrap';
import useLocale from '../../../hooks/useLocale';

interface SidebarNavListItemProps {
  className?: string;
  depth: number;
  href: string;
  icon: any;
  badge?: string;
  open?: boolean;
  title: string;
  translate?: boolean;
  children?: React.ReactNode;
}

const SidebarNavListItem = (props: SidebarNavListItemProps) => {
  const { localize } = useLocale();
  const { title, translate, href, depth = 0, children, icon: Icon, badge, open: openProp = false } = props;
  const sidebarItemTitle =
    typeof translate === 'boolean' && translate === false ? title : localize(title.toLowerCase().split(' ').join('_'));

  const [open, setOpen] = React.useState(openProp);

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <li className={`sidebar-item ${open ? 'active' : ''}`}>
        <a
          className={`sidebar-link align-middle ${open ? '' : 'collapsed'}`}
          data-bs-toggle="collapse"
          aria-expanded={open ? 'true' : 'false'}
          data-depth={depth}
          onClick={handleToggle}
        >
          {Icon && <img src={Icon} alt="icon" className="feather align-middle icon-gap-right" />}

          <div>{sidebarItemTitle}</div>
          {badge && (
            <Badge className="badge-sidebar-primary" bg="">
              {badge}
            </Badge>
          )}
          <div />
        </a>
        <Collapse in={open}>
          <ul className="sidebar-dropdown list-unstyled">{children}</ul>
        </Collapse>
      </li>
    );
  }

  return (
    <li className="sidebar-item">
      <NavLink data-depth={depth} to={href} className="sidebar-link">
        <div className="sidebar-active-item">
          {Icon && <img src={Icon} alt="icon" className="feather align-middle icon-gap-right" />}
          <span className="sidenav-title">{sidebarItemTitle}</span>
        </div>
        {badge && (
          <Badge className="badge-sidebar-primary" bg="">
            {badge}
          </Badge>
        )}
      </NavLink>
    </li>
  );
};

export default SidebarNavListItem;
