import moment from 'moment';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { sortCaret } from '../../../../../components/table/formatters';
import { tableHeaderText } from '../../../../../components/table/TableHeaderFormatter';

export const useColumn = () => {
  const accountingFirmLogColumn: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Date and time'),
      // dataField: 'timestamp',
      dataField: 'dateTime',
      sort: true,
      sortCaret,
      formatter: (cell) => moment(cell).format('YYYY-MM-DD HH:MM'),
      headerStyle: { width: 104 },
    },
    {
      text: tableHeaderText('User'),
      // dataField: 'message.actor.fullName',
      dataField: 'fullName',
      sort: true,
      sortCaret,
      headerStyle: { width: 110 },
    },
    {
      text: tableHeaderText('Role'),
      dataField: 'role',
      // dataField: 'message.actor.role',
      sort: true,
      sortCaret,
      headerStyle: { width: 93 },
    },
    {
      text: tableHeaderText('Category'),
      dataField: 'action',
      // dataField: 'message.action',
      sort: true,
      sortCaret,
      headerStyle: { width: 100 },
    },

    {
      text: tableHeaderText('Event'),
      dataField: 'event',
      // dataField: 'message.event',
      headerStyle: { width: 160 },
    },
  ];

  return { accountingFirmLogColumn };
};
