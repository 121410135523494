import { useTranslation } from 'react-i18next';
import BSModal from '../../../../components/Modal';
import PensionPolicyModalFooter from './PensionPolicyModalFooter';
import CreatePolicy from '../CreatePolicy';
import { useValidationError } from '../../../../hooks/useValidationError';
import { Dispatch, SetStateAction } from 'react';
import useContext from '../PensionPolicyContext/useContext';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsPolicyCreated: Dispatch<SetStateAction<boolean>>;
};

const PensionPolicyModal = ({ showCreateModal, toggleModal, setIsPolicyCreated }: Props) => {
  const { reloadToDefault } = useContext();
  const { t } = useTranslation();
  const { setErrors, errors, isInvalid } = useValidationError();

  const onClose = () => {
    toggleModal();
    reloadToDefault();
  };

  return (
    <>
      <BSModal
        title={`${t('new')} ${t('pension_policy')}`}
        show={showCreateModal}
        onHide={onClose}
        centered
        ModalFooter={
          <PensionPolicyModalFooter
            toggleModal={toggleModal}
            setErrors={setErrors}
            setIsPolicyCreated={setIsPolicyCreated}
          />
        }
        className="crt-add-pension-policy-modal card-type-modal"
        backdrop={'static'}
      >
        <CreatePolicy errors={errors} isInvalid={isInvalid} />
      </BSModal>
    </>
  );
};
export default PensionPolicyModal;
