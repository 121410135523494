import { Spinner } from 'react-bootstrap';
import CardComponent from '../../../../../components/base/CardComponent';
import PagesHeader from '../../../../../components/base/PagesHeader';
import BSTable from '../../../../../components/table/BSTable';
import { useEffect, useState } from 'react';
import { useColumn } from './useColumn';
import { getAccessLevelList } from '../../../../../api/service/superAdmin/personal-clients/service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserRole } from '../../../../../types/auth';
import { uniqueBy } from '../../../../../util/util';
import { AccessLevelCompanyRole, AccessLevelGroup, AccessLevelUserRole } from './AccessLevel.type';

export const AccessLevels = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { accessLevelColumn } = useColumn();
  const { id } = useParams();
  const { t } = useTranslation();
  const [categorizedAccessLevels, setCategorizedAccessLevels] = useState<AccessLevelGroup>({
    [UserRole.COMPANY_ADMIN]: [],
    [UserRole.ACCOUNTING_FIRM]: [],
    [UserRole.EMPLOYEE]: [],
  });

  const fetchAccessLevels = async () => {
    const accessLevelList = await getAccessLevelList(id);
    console.log('accessLevelList', accessLevelList);

    const accessLevelRoles: AccessLevelCompanyRole[] = accessLevelList
      .map((item: any) => {
        return item.roles.map((roleItem: AccessLevelUserRole) => {
          return {
            company: item.company,
            email: item.email,
            role: roleItem.role,
            subRole: roleItem.subRole,
          } as AccessLevelCompanyRole;
        });
      })
      .flat();

    const accessLevelRolesByGroup: AccessLevelGroup = {
      [UserRole.COMPANY_ADMIN]: uniqueByRole(accessLevelRoles.filter((item) => item.role === UserRole.COMPANY_ADMIN)),
      [UserRole.ACCOUNTING_FIRM]: uniqueByRole(
        accessLevelRoles.filter((item) => item.role === UserRole.ACCOUNTING_FIRM),
      ),
      [UserRole.EMPLOYEE]: uniqueByRole(accessLevelRoles.filter((item) => item.role === UserRole.EMPLOYEE)),
    };
    setCategorizedAccessLevels(accessLevelRolesByGroup);
    // console.log(categorizedAccessLevelData);
    // setAccessLevelData(accessLevelList);

    // console.log('accessLevelRolesByGroupaccessLevelRolesByGroup', accessLevelRolesByGroup);
    setIsLoading(false);
  };

  const uniqueByRole = (companyRoles: AccessLevelCompanyRole[]) => {
    return uniqueBy(companyRoles, (item: AccessLevelCompanyRole) => {
      const orgNum = item.company.organizationNumber || '';
      const role = item.role || '';
      const subRole = item.subRole || '';

      return `${role}_${subRole}_${orgNum}`;
    }).filter((item: AccessLevelCompanyRole) => {
      if (item.role === UserRole.EMPLOYEE) {
        return true;
      }

      return item.subRole;
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAccessLevels();
  }, []);

  return (
    <CardComponent>
      <PagesHeader pageTitle="Access levels" headingClass="fs-h2 font-black" />
      <div>
        <PagesHeader pageTitle="Employment" />
        {isLoading ? (
          <Spinner />
        ) : (
          <BSTable
            data={categorizedAccessLevels.Employee ? categorizedAccessLevels.Employee : []}
            columns={accessLevelColumn}
            noDataIndication={`${t('no_access_levels_found')}`}
            key="id"
            // cellEdit={cellEditFactory({ mode: 'click' })}
            // onRowClick={(row) => {
            //   navigate(row._id);
            // }}
          />
        )}
      </div>
      <div>
        <PagesHeader pageTitle="Company level" />
        {isLoading ? (
          <Spinner />
        ) : (
          <BSTable
            data={categorizedAccessLevels.CompanyAdmin ? categorizedAccessLevels.CompanyAdmin : []}
            columns={accessLevelColumn}
            noDataIndication={`${t('no_access_levels_found')}`}
            key="id"
            // cellEdit={cellEditFactory({ mode: 'click' })}
            // onRowClick={(row) => {
            //   navigate(row._id);
            // }}
          />
        )}
      </div>
      <div>
        <PagesHeader pageTitle="Accounting firm level" />
        {isLoading ? (
          <Spinner />
        ) : (
          <BSTable
            data={categorizedAccessLevels.AccountingFirm ? categorizedAccessLevels.AccountingFirm : []}
            columns={accessLevelColumn}
            noDataIndication={`${t('no_access_levels_found')}`}
            key="id"
            // cellEdit={cellEditFactory({ mode: 'click' })}
            // onRowClick={(row) => {
            //   navigate(row._id);
            // }}
          />
        )}
      </div>
    </CardComponent>
  );
};
