import { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import MonthlyStepper from '../../../../components/monthly_stepper/MonthlyStepper';

import { getPensionOffset } from '../../../../api/service/company/employees/service';

import { Calendar } from '../../../../util/calendar';
import { EmployeePensionOffset } from '../types';
import { IDatasource } from '../../../../components/monthly_stepper/types';
import { useTranslation } from 'react-i18next';

const YEAR_BOUNDARY = Calendar.generateMonthBoundary(6);
const HEADER_COLUMNS = [
  { title: 'Reported pension', name: 'pensionOffset' },
  { title: 'Reported salary changes', name: 'salaryChange' },
];
/* const fillMissingMonthsAndYears = (data, fromDate, toDate) => {
  const result: EmployeePensionOffset[] = [];

  let currentDate = Calendar.parseDateStr(fromDate);
  const endDate = Calendar.parseDateStr(toDate);

  while (currentDate.isBefore(endDate)) {
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month() + 1;

    const existingData = data.find((yearData) => yearData.year === currentYear);

    if (existingData) {
      const existingMonthData = existingData.offsets.find((offset) => offset.month === currentMonth);

      if (existingMonthData) {
        // If data exists for the current month and year, add it to the result
        const yearIndex = result.findIndex((item) => item.year === currentYear);

        if (yearIndex !== -1) {
          result[yearIndex].offsets.push(existingMonthData);
        } else {
          result.push({
            year: currentYear,
            offsets: [existingMonthData],
          });
        }
      } else {
        // If data exists for the current year but not the current month, fill with null values
        const yearIndex = result.findIndex((item) => item.year === currentYear);

        if (yearIndex !== -1) {
          result[yearIndex].offsets.push({
            month: currentMonth,
            pensionOffset: null,
            salaryChange: null,
          });
        } else {
          result.push({
            year: currentYear,
            offsets: [
              {
                month: currentMonth,
                pensionOffset: null,
                salaryChange: null,
              },
            ],
          });
        }
      }
    } else {
      // If no data for the current year, fill with null values for all months
      const yearIndex = result.findIndex((item) => item.year === currentYear);

      if (yearIndex !== -1) {
        result[yearIndex].offsets.push(
          ...Array.from({ length: 12 }, (_, month) => ({
            month: month + 1,
            pensionOffset: 0,
            salaryChange: null,
          })),
        );
      } else {
        result.push({
          year: currentYear,
          offsets: Array.from({ length: 12 }, (_, month) => ({
            month: month + 1,
            pensionOffset: 0,
            salaryChange: null,
          })),
        });
      }
    }

    currentDate.add(1, 'month');
  }

  return result;
}; */

interface Props {
  employeeID: string;
}

const PensionOffset = ({ employeeID }: Props) => {
  const yearRange = useMemo(() => YEAR_BOUNDARY, []);
  const [pensionOffsetSource, setPensionOffsetSource] = useState<IDatasource>({
    headers: [...HEADER_COLUMNS],
    reports: [],
  });

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const fetchEmployeeYearlyPensionOffset = async () => {
    try {
      setLoading(true);

      let pensionOffsetResult: EmployeePensionOffset[] = await getPensionOffset(employeeID, {
        duration: { from: YEAR_BOUNDARY[0], to: YEAR_BOUNDARY[1] },
      });

      //const pensionOffsetDataset = fillMissingMonthsAndYears(pensionOffsetResult, YEAR_BOUNDARY[0], YEAR_BOUNDARY[1]);
      const _pensionOffsetSource = { ...pensionOffsetSource };

      _pensionOffsetSource.reports = toPensionOffsetModel(pensionOffsetResult);

      setPensionOffsetSource(_pensionOffsetSource);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const toPensionOffsetModel = (pensionOffsetDataset: EmployeePensionOffset[] = []) => {
    return pensionOffsetDataset.map((yearlyPensionOffset) => {
      const dataset = HEADER_COLUMNS.map((column) => {
        return {
          name: column.name,
          months: yearlyPensionOffset.offsets.map((monthlyOffset) => {
            return {
              monthIndex: monthlyOffset.month,
              value: monthlyOffset[column.name],
            };
          }),
        };
      });

      return {
        year: yearlyPensionOffset.year,
        dataset,
      };
    });
  };

  useEffect(() => {
    fetchEmployeeYearlyPensionOffset();
  }, []);

  return (
    <>
      <CardComponent>
        {loading && <Spinner />}
        {!loading && (
          <MonthlyStepper headerTitle={t('pension_company')} datasource={pensionOffsetSource} yearRange={yearRange} />
        )}
      </CardComponent>
    </>
  );
};

export default PensionOffset;
