export const AVAILABLE_LOCALES = [
  { label: 'EN', value: 'en' },
  { label: 'SE', value: 'se' },
];

export enum LocaleCode {
  EN = 'en',
  SE = 'se',
}

export interface IAppLocale {
  lang: string;
}
