import { Row, Spinner, Col } from 'react-bootstrap';
import CardComponent from '../../../../../components/base/CardComponent';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GetCompanyClientType } from '../../../../../api/service/superAdmin/company-clients/types';
import { useColumn } from './useColumn';
import BSRemoteTable from '../../../../../components/table/BSRemoteTable';
import Pagination from '../../../../company/Pagination';
import { getClients } from '../../../../../api/service/superAdmin/accounting-firm/service';

type Props = {
  isBusy: boolean;
};

export const Clients = ({ isBusy }: Props) => {
  const navigate = useNavigate();
  const { clientColumn } = useColumn();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [clientData, setClientData] = useState<GetCompanyClientType | null>(null);
  const { t } = useTranslation();
  const totalPages = clientData?.metaData ? Math.ceil(clientData?.metaData.total / clientData?.metaData?.page.size) : 0;

  const onPagination = async ({ page, sizePerPage }) => {
    await fetchClients({ page, sizePerPage });
  };

  const fetchClients = async ({
    searchString = '',
    page = clientData?.metaData.page.current,
    sizePerPage = clientData?.metaData.page.size,
    sortField = 'name',
    sortOrder = '1',
    accountingFirmId = id,
  }) => {
    const clients = await getClients({ searchString, page, sizePerPage, sortField, sortOrder, accountingFirmId });
    setClientData(clients);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchClients({});
  }, []);

  return (
    <>
      <CardComponent>
        <Row className="justify-content-between">
          <Col md={3}></Col>
          {isLoading ? (
            <Spinner />
          ) : (
            <BSRemoteTable
              data={clientData?.data ?? []}
              columns={clientColumn}
              noDataIndication={`${t('no_clients_found')}`}
              sizePerPage={clientData?.metaData['size']}
              onSizePerPageChange={(sizePerPage, page) => onPagination({ sizePerPage, page })}
              keyField="id"
              onRowClick={(row) => {
                navigate(`/company-clients/${row.id}`);
              }}
              onTableChange={(type, { sortOrder, sortField }) => {
                if (type === 'sort') {
                  fetchClients({ sortField, sortOrder: sortOrder === 'asc' ? '1' : '-1' });
                }
              }}
            />
          )}
        </Row>
        {totalPages > 1 && (
          <Pagination
            total={clientData?.metaData.total ?? 0}
            itemsPerPage={clientData?.metaData.page.size ?? 0}
            currentPage={clientData?.metaData.page.current ?? 0}
            onPageChange={(page) => onPagination({ page, sizePerPage: clientData?.metaData.page.size })}
          />
        )}
      </CardComponent>
    </>
  );
};
