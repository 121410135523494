export const API_VERSION = '/api/v1';
export const SESSION_REDIRECT_PATH = '&initsession=true';
export const AUTH_INIT_COMPANY_SESS = 'correcto_company_init_sess';
export const AUTH_INIT_COMPANY_TOKEN = 'correcto_company_init_token';

// auth
export const INITIALIZE_AUTH = `/auth/initialize-session?redirectTo=${process.env['REACT_APP_SESSION_REDIRECT_URL']}`;
export const INITIALIZE_ADMIN_AUTH = `/auth/initialize-session?redirectTo=${process.env['REACT_APP_SESSION_ADMIN_REDIRECT_URL']}`;
export const INVOKE_TOKEN = '/auth/invoke-token';
export const USER_SESSION = '/auth/user-session';
export const USERS = '/users';
export const USERS_PERSONAL = '/users/personalNumber';

// Company endpoints
export const COMPANY_EMPLOYEE_ENDPOINT = '/employees';
export const CANCEL_EMPLOYEE_VACCATION_SCHEDULE_ENDPOINT = '/employees/vacation/cancel';
export const COMPANY_EMPLOYEE_PENSION_ENDPOINT = '/employee-pension';
export const ADMIN_COMPANIES_DETAIL_ENDPOINT = '/company-clients/admins';

// Employee endpoints

// SuperAdmin endpoints
export const PERSONAL_CLIENTS_ENDPOINT = '/personal-clients';
export const COMPANY_CLIENTS_ENDPOINT = '/company-clients';
export const MOCK_COMPANY_NAME_ENDPOINT = '/company-clients/mock';
export const MOCK_USER_ENDPOINT = '/company-clients/mock/user';
export const ACCOUNTING_FIRM_ENDPOINT = '/accounting-firms';
// Company Client endpoints

export const COMPANY_CLIENT_ENDPOINT = '/client';

// Compensation Plans
export const COMPENSATION_PLANS_ENDPOINT = '/compensation-plans';
export const PENSION_COMPANIES_ENDPOINT = '/pension-managers';
export const SALARY_TYPES_ENDPOINT = '/salary-codes';
export const PENSION_POLICY_ENDPOINT = '/pension-policy';
export const SALARY_TRANSACTIONS_ENDPOINT = '/salary-transactions';
export const PENSION_OFFSET_ENDPOINT = '/pension-offset';
export const INSURANCE_ENDPOINT = '/insurance';

// Invitation Email Setting
export const INIVITATION_EMAIL_SETTING_OPTIONS = [
  {
    label: 'Please select',
    value: '',
    disabled: true,
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '7',
    value: '7',
  },
];
export const SALARY_EXPORT_REPORTS_ENDPOINT = '/reports/salaries/export';
export const REPORTS_CALCULATED_SALARY_CHANGE_EXPORT = '/reports/pensions/pension-calculated-and-salary-change/export';
export const REPORTS_CUSTOMIZABLE_EXPORT = '/reports/customizable/export';
export const SALARY_TYPE_EXPORT_ENDPOINT = '/reports/salary-types/export';
export const TAXES_REPORT_EXPORT_ENDPOINT = '/reports/taxes';

export const SALARY_REPORTS_ENDPOINT = '/reports/salaries';
export const BUSINESS_ANALYSIS_CHART_ENDPOINT = '/business-analysis/';
export const LOGS_ENDPOINT = '/logs';
export const REPORTS_TAXES = '/reports/taxes';
export const REPORTS_CALCULATED_SALARY_CHANGE = '/reports/pensions/pension-calculated-and-salary-change';
export const REPORTS_HOLIDAYS_CHANGE_ENDPOINT = '/reports/holidays';
export const ACCOUNTING_SALARY_COMPANY_ENDPOINT = '/accounting-and-salary-companies';
export const USERS_ENDPOINT = '/users';
export const COMPANY_SALARY_TYPES_ENDPOINT = '/salary-codes/company';
export const COMPANY_WORKFLOW_ENDPOINT = '/company-workflow';
export const INTEGRATIONS_ENDPOINT = '/integrations';
export const EMPLOYEE_BENEFIT_REPORT_ENDPOINT = '/reports/employee-benefits';
