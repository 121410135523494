import React from 'react';
import PagesHeader from '../../../../components/base/PagesHeader';
import { BarStackChart } from '../../../../components/charts/bar-stack/BarStackChart';
import { getCompanyCalculatedAndSalaryChangeReports } from '../../../../api/service/company/reports/service';
import useCalculatedSalaryChart from './hooks/useCalculatedSalaryChart';

export const CalculatedSalaryChangeChart = () => {
  const { seriesOptions, dateRange, chartRecords, isLoading, changeDateHandler } = useCalculatedSalaryChart(
    getCompanyCalculatedAndSalaryChangeReports,
  );
  return (
    <>
      <PagesHeader pageTitle="Total" headingClass="fs-page-title" />
      <div className="chart mt-4">
        <BarStackChart
          isLoading={isLoading}
          seriesOptions={seriesOptions}
          data={chartRecords}
          initialDateRange={dateRange}
          changeDateHandler={changeDateHandler}
          translate={true}
        />
      </div>
    </>
  );
};
