import { toTitleCase } from '../../../../../util';
import { deepClone, getPropValue, splitFullName } from '../../../../../util/util';
import { FORM_INITIAL_VALUES, INITIAL_ADDRESS_DETAILS } from '../contants';
import { useEditGeneralSettingsContext } from '../context/useContext';

const useGeneralForm = () => {
  const {
    replaceCompanyDetails,
    updateContractNumber,
    updateInvoiceEmailAddress,
    updateInvoicingCostCenter,
    replaceAdminDetails,
    updateContractEffectiveInput,
    replaceCombinations,
    updateInvitationEmail,
    replaceConsultants,
    replaceAccountingContactPeople,
    replaceSalaryContactPeople,
    replaceCollectiveAgreements,
    setCompanyRes,
    replaceAddress,
    deletedAdmins,
  } = useEditGeneralSettingsContext();

  const getDefaultValue = <T extends unknown = any>(data, value: any): T => {
    const initialCompanyDetails = deepClone({ ...FORM_INITIAL_VALUES, deletedAdmins });
    const lastUpdatedValue = getPropValue(data, value);
    const initialValue = getPropValue(initialCompanyDetails, value);

    console.log('initialCompanyDetails', initialCompanyDetails);
    console.log('initialValue', initialValue);

    if (lastUpdatedValue === undefined) {
      return initialValue;
    }

    return lastUpdatedValue;
  };

  const populateSettingsForm = (companyData) => {
    const {
      name,
      organizationNumber,
      status,
      combinations,
      consultants,
      invitationEmail,
      contactPeopleForAccounting,
      contactPeopleForSalary,
      collectiveAgreements,
      ...miscDetails
    } = companyData;
    const defaultAddress = companyData.address || INITIAL_ADDRESS_DETAILS;
    setCompanyRes(deepClone(companyData));
    replaceCompanyDetails({
      name,
      organizationNumber,
      status,
      employees: miscDetails.employees,
    });
    replaceAddress({ ...defaultAddress, city: toTitleCase(defaultAddress.city) });
    updateContractNumber(getDefaultValue(companyData, 'contractNumber'));
    updateInvoiceEmailAddress(getDefaultValue(companyData, 'invoiceEmailAddress'));
    updateInvoicingCostCenter(getDefaultValue(companyData, 'invoicingCostCenter'));
    replaceAdminDetails(getDefaultValue(companyData, 'admins'));
    updateContractEffectiveInput(getDefaultValue(companyData, 'contractEffectiveFrom'));
    replaceCombinations(
      combinations.map((combination, index) => {
        return {
          signee: combination.signee,
          authorizedSignatory: combination.authorizedSignatory.map((signatory) => {
            return { ...splitFullName(signatory.fullName), ...signatory };
          }),
        };
      }),
    );
    updateInvitationEmail(invitationEmail || 3);
    replaceAccountingContactPeople(contactPeopleForAccounting);
    replaceSalaryContactPeople(contactPeopleForSalary);
    replaceCollectiveAgreements(collectiveAgreements);
    replaceConsultants(consultants);
  };

  return {
    populateSettingsForm,
    getDefaultValue,
  };
};

export default useGeneralForm;
