import React, { useEffect, useState } from 'react';
import { Accordion, Col, Form, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Search } from 'react-feather';

import { ReactComponent as OpenLink } from '../../../../assets/img/open-link.svg';
import { ReactComponent as CurrencyIcon } from '../../../../assets/img/currency-union.svg';
// import { ReactComponent as SpecialLogo } from '../../../../assets/img/SpecialLogo.svg';

import PagesHeader from '../../../../components/base/PagesHeader';
import useInsuranceForm from '../hooks/useInsuranceForm';
import { deepClone } from '../../../../util/util';
import { INSURANCE_CONDITION } from '../constants';
import useLocale from '../../../../hooks/useLocale';
import { LOCALE_KEYS } from '../../../../config/localization-keys.config';
import { useTranslation } from 'react-i18next';
import useCostSpecificationModal from '../components/CostSpecificationModal/useCostSpecificationModal';
import CostSpecificationModal from '../components/CostSpecificationModal/CostSpecificationModal';

export default function InsuranceGrid() {
  const { insuranceList, isFetching, addOrRemoveInsuranceCollection, findInsurancePlan } = useInsuranceForm();
  const {
    toggleModal: toggleCostSpecModal,
    showCostSpecModal,
    setCostPlanManager,
    selectedCostPlanManager,
    hideParentModal,
    showParentModal,
  } = useCostSpecificationModal();

  const [selectedInsurances, setSelectedInsurances] = useState<any[]>(insuranceList);
  const [isCheckedInsurance, ,] = useState(false);

  const { localize } = useLocale();
  const { t } = useTranslation();

  const onSearchInsurance = (event) => {
    const searchTerm = event.target.value;

    const regex = new RegExp(searchTerm, 'ig');
    const searchedInsurance = insuranceList.filter((item) => {
      return regex.test(item.companyName);
    });
    const filteredList = isCheckedInsurance ? getFilterSelectedInsurance(searchedInsurance) : searchedInsurance;

    setSelectedInsurances(filteredList);
  };

  const getFilterSelectedInsurance = (_insurances: null | any[] = null) => {
    const filteredInsurance = _insurances || selectedInsurances;

    return deepClone(filteredInsurance).map((insurance) => {
      insurance.planTypes = insurance.planTypes
        .map((planType) => {
          planType.plans = planType.plans.filter((planItem) => {
            return planItem.hjertaInfinity;
          });

          return planType;
        })
        .filter((planType) => planType.plans.length);

      return insurance;
    });
  };

  const handleCheck = (insuranceID, insurancePlanID, categoryName) => {
    addOrRemoveInsuranceCollection({
      insuranceID: insuranceID,
      planID: insurancePlanID,
      categoryName,
    });
  };

  const getInsuranceLabel = (key: string) => {
    const selected = INSURANCE_CONDITION.find((item) => item.key === key)?.lang;

    return selected ? localize(selected) : '';
  };

  const isActiveStyle = (insuranceID, planID) => {
    return findInsurancePlan(insuranceID, planID) ? 'active-green-td' : '';
  };

  const hasIncludedInsurancePlan = (insuranceId, planID) => {
    return findInsurancePlan(insuranceId, planID) ? true : false;
  };

  const onShowPlanCostSpecification = (planType, insurancePlan) => {
    setCostPlanManager({ manager: planType, planItem: insurancePlan });
    toggleCostSpecModal();
    hideParentModal();
  };

  useEffect(() => {
    setSelectedInsurances(insuranceList);
  }, [insuranceList.length]);

  if (isFetching) {
    return (
      <>
        <div className="mt-4 mb-3">
          <PagesHeader pageTitle="Insurances" biggerHeading={true} />
        </div>
        <Spinner />
      </>
    );
  }

  return (
    <>
      <div className="mt-4 mb-3">
        <PagesHeader pageTitle="Insurances" biggerHeading={true} />
      </div>
      <div className=" mt-3 mb-4">
        <p className="fs-text-400">{localize('pension_policy_insurances_selection')}. </p>
      </div>
      <div className="d-flex mt-3 mb-4">
        <div className="d-flex align-items-center cr-form-row">
          <div className="search-field">
            <div className="input-group">
              <input
                placeholder={`${localize('search')}...`}
                className="form-control"
                style={{ borderRight: 'none' }}
                onKeyUp={(e) => onSearchInsurance(e)}
              />
              <span className="input-group-append bg-white border-left-0">
                <span className="input-group-text ">
                  <Search className="cursor-pointer" width={16} height={30} />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="insurance-table-accordion">
        <Accordion defaultActiveKey="0">
          {selectedInsurances.map((insuranceItem, idx) => {
            return (
              <Accordion.Item key={`insurance-row-${idx}`} eventKey={`${idx}`} className="insurance-accordion-item">
                <Accordion.Header>
                  <Row className="accordion-table">
                    <Col md={10} className="px-0">
                      <span className="fs-h3 theme-text">{insuranceItem.companyName}</span>
                      <p className="m-0">{insuranceItem.providerDescription}</p>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body>
                  {insuranceItem.planTypes.map((planType, planTypeIndex) => {
                    return (
                      <React.Fragment key={`insuranceSection-${planType.key}-${idx}`}>
                        <div className="pt-3">
                          <span className="fs-text-400 text-bold theme-text">
                            {t(LOCALE_KEYS.INSURANCE_TYPE[planType.key])}
                          </span>
                        </div>
                        <div className="table-responsive react-bootstrap-table">
                          <table className="table table-hover table-condensed policy-insurance-plan-table">
                            <thead className="header-wrapper">
                              <tr>
                                <th style={{ width: '280px' }}>{localize('plan')}</th>
                                <th style={{ width: '120px' }}>{localize('age_span')}</th>
                                <th style={{ width: '200px' }}>{localize('special_condition')}</th>
                                <th style={{ width: '140px' }}></th>
                                <th style={{ width: '120px' }}></th>
                              </tr>
                            </thead>
                            <tbody>
                              {planType.plans.map((insurancePlan, planIndex) => {
                                return (
                                  <tr key={`insurancePlan-${planType.key}-${idx}${planIndex}`}>
                                    <td
                                      className={isActiveStyle(insuranceItem.id, insurancePlan.insuranceName)}
                                      style={{ verticalAlign: 'top' }}
                                    >
                                      <Form.Group>
                                        <Form.Check
                                          inline
                                          type="checkbox"
                                          label={insurancePlan.insuranceName}
                                          id={`activeinsurance-${idx}${planIndex}`}
                                          className="fs-name mb-1 theme-text"
                                          onChange={(e) =>
                                            handleCheck(insuranceItem.id, insurancePlan.insuranceName, planType.key)
                                          }
                                          checked={hasIncludedInsurancePlan(
                                            insuranceItem.id,
                                            insurancePlan.insuranceName,
                                          )}
                                        />
                                      </Form.Group>

                                      <p className="ml-20 theme-text">{insurancePlan.insuranceDescription}</p>
                                    </td>
                                    <td
                                      className={classNames(
                                        isActiveStyle(insuranceItem.id, insurancePlan.insuranceName),
                                        'theme-text',
                                      )}
                                    >
                                      {insurancePlan.ageSpan.from}
                                      {insurancePlan.ageSpan.from && insurancePlan.ageSpan.to ? '-' : ''}{' '}
                                      {insurancePlan.ageSpan.to}
                                    </td>
                                    <td className={isActiveStyle(insuranceItem.id, insurancePlan.insuranceName)}>
                                      <Row>
                                        <Col className="theme-text">
                                          {getInsuranceLabel(insurancePlan.specialCondition)}
                                        </Col>
                                      </Row>
                                    </td>
                                    <td
                                      className={isActiveStyle(insuranceItem.id, insurancePlan.insuranceName)}
                                      style={{ verticalAlign: 'top' }}
                                    >
                                      {insurancePlan?.costSpecification && (
                                        <span
                                          className="link-text cursor-pointer"
                                          onClick={() => onShowPlanCostSpecification(planType, insurancePlan)}
                                        >
                                          <CurrencyIcon className="me-1" />
                                          Cost specification
                                        </span>
                                      )}
                                    </td>
                                    <td className={isActiveStyle(insuranceItem.id, insurancePlan.insuranceName)}>
                                      <ul className="table-links">
                                        <li>
                                          {insurancePlan.documents.map((doc, docIdx) => {
                                            return (
                                              <Link
                                                className="link-text"
                                                target="_blank"
                                                key={`doc-${idx}${docIdx}`}
                                                to={doc.documentLink}
                                              >
                                                <OpenLink /> {doc.text}
                                              </Link>
                                            );
                                          })}
                                        </li>
                                      </ul>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
          <CostSpecificationModal
            show={showCostSpecModal}
            toggleModal={toggleCostSpecModal}
            costSpecDetails={selectedCostPlanManager?.planItem?.costSpecification}
            planName={selectedCostPlanManager?.planItem?.insuranceName}
            managerName={selectedCostPlanManager?.manager?.label}
            onHide={showParentModal}
          />
          {/* <Accordion.Item eventKey="1">
            <Accordion.Header>
              <Row className="accordion-table">
                <Col md={10}>
                  <span className="fs-name">EuroAccident</span>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.
                  </p>
                </Col>
                <Col md={2} className="d-flex align-items-center">
                  <div className="accordion-right-section">
                    <span className="fs-name">Produkter från </span>
                    <Logo />
                  </div>
                </Col>
              </Row>
            </Accordion.Header>
            <Accordion.Body>
              <div className="pt-3  ms-3">
                <span className="fs-text-400">Gruppförsäkring</span>
              </div>
              <div className="table-responsive react-bootstrap-table">
                <table className="table table-hover table-condensed">
                  <thead className="header-wrapper">
                    <tr>
                      <th style={{ width: '280px' }}>Plan</th>
                      <th style={{ width: '120px' }}>Age span</th>
                      <th style={{ width: '350px' }}>Special condition</th>
                      <th>Documents</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        <Form.Group>
                          <Form.Check
                            inline
                            type="checkbox"
                            label="Employee right to edit"
                            id="employeeEdit"
                            className="fs-name mb-1 "
                          />
                        </Form.Group>

                        <p className="ml-20">
                          Lorum ipsum dolor sit amet asf aospdf asdof oasdf ioaasd asdfasdfpo podf osdf
                        </p>
                      </td>
                      <td>20-61</td>
                      <td>
                        <Row>
                          <Col>Able-bodied</Col>
                          <Col>
                            <SpecialLogo />
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <ul className="table-links">
                          <li>
                            <Link to={''}>
                              <OpenLink /> Pre-purchase information
                            </Link>
                          </li>
                          <li>
                            <Link to={''}>
                              <OpenLink /> Fact sheet
                            </Link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        <Form.Group>
                          <Form.Check
                            inline
                            type="checkbox"
                            label="Employee right to edit"
                            id="employeeEdit"
                            className="fs-name mb-1 "
                          />
                        </Form.Group>

                        <p className="ml-20">
                          Lorum ipsum dolor sit amet asf aospdf asdof oasdf ioaasd asdfasdfpo podf osdf
                        </p>
                      </td>
                      <td>20-61</td>
                      <td>
                        <Row>
                          <Col>Able-bodied</Col>
                          <Col>
                            <SpecialLogo />
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <ul className="table-links">
                          <li>
                            <Link to={''}>
                              <OpenLink /> Pre-purchase information
                            </Link>
                          </li>
                          <li>
                            <Link to={''}>
                              <OpenLink /> Fact sheet
                            </Link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        <Form.Group>
                          <Form.Check
                            inline
                            type="checkbox"
                            label="Employee right to edit"
                            id="employeeEdit"
                            className="fs-name mb-1 "
                          />
                        </Form.Group>

                        <p className="ml-20">
                          Lorum ipsum dolor sit amet asf aospdf asdof oasdf ioaasd asdfasdfpo podf osdf
                        </p>
                      </td>
                      <td>20-61</td>
                      <td>
                        <Row>
                          <Col>Able-bodied</Col>
                          <Col>
                            <SpecialLogo />
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <ul className="table-links">
                          <li>
                            <Link to={''}>
                              <OpenLink /> Pre-purchase information
                            </Link>
                          </li>
                          <li>
                            <Link to={''}>
                              <OpenLink /> Fact sheet
                            </Link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td className="active-green-td" style={{ verticalAlign: 'top' }}>
                        <Form.Group>
                          <Form.Check
                            inline
                            type="checkbox"
                            label="Employee right to edit"
                            id="employeeEdit"
                            className="fs-name mb-1 "
                          />
                        </Form.Group>

                        <p className="ml-20">
                          Lorum ipsum dolor sit amet asf aospdf asdof oasdf ioaasd asdfasdfpo podf osdf
                        </p>
                      </td>
                      <td className="active-green-td">20-61</td>
                      <td className="active-green-td">
                        <Row>
                          <Col>Able-bodied</Col>
                          <Col>
                            <SpecialLogo />
                          </Col>
                        </Row>
                      </td>
                      <td className="active-green-td">
                        <ul className="table-links">
                          <li>
                            <Link to={''}>
                              <OpenLink /> Pre-purchase information
                            </Link>
                          </li>
                          <li>
                            <Link to={''}>
                              <OpenLink /> Fact sheet
                            </Link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: 'top' }}>
                        <Form.Group>
                          <Form.Check
                            inline
                            type="checkbox"
                            label="Employee right to edit"
                            id="employeeEdit"
                            className="fs-name mb-1 "
                          />
                        </Form.Group>

                        <p className="ml-20">
                          Lorum ipsum dolor sit amet asf aospdf asdof oasdf ioaasd asdfasdfpo podf osdf
                        </p>
                      </td>
                      <td>20-61</td>
                      <td>
                        <Row>
                          <Col>Able-bodied</Col>
                          <Col>
                            <SpecialLogo />
                          </Col>
                        </Row>
                      </td>
                      <td>
                        <ul className="table-links">
                          <li>
                            <Link to={''}>
                              <OpenLink /> Pre-purchase information
                            </Link>
                          </li>
                          <li>
                            <Link to={''}>
                              <OpenLink /> Fact sheet
                            </Link>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Accordion.Body>
          </Accordion.Item> */}
        </Accordion>
      </div>
    </>
  );
}
