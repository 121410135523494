import { useEffect, useState } from 'react';
import moment from 'moment';

import useAuth from '../../../../hooks/useAuth';
import { ISeries, ISeriesOptions } from '../../../../components/charts/multi-series/types';
import { Calendar } from '../../../../util/calendar';
import { IChartGenreInfo, IEmployeeSalaryReportResponse, IReportFilterParams } from '../../../../types/report';

const DEFAULT_CHART_GENRE: IChartGenreInfo = {
  fieldKey: 'salary',
};

const useComparisonSeriesChart = (
  requestFn: (param: IReportFilterParams) => Promise<IEmployeeSalaryReportResponse>,
  chartGenre: IChartGenreInfo = DEFAULT_CHART_GENRE,
) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState<[string, string]>(Calendar.yearToDateBoundary);
  const [chartRecords, setChartRecords] = useState<any[]>([]);
  const [employeeSeries, setEmployeeSeries] = useState<ISeries[]>([{ label: 'Total', field: 'total' }]);

  // const [employeeSeries, setEmployeeSeries] = useState<ISeries[]>([
  //   {
  //     label: 'Total',
  //     field: 'total',
  //     style: {
  //       defaultColor: '#01b8aa',
  //       darkColor: '#01b8aa',
  //     },
  //     color: '#01b8aa',
  //     checked: true,
  //     value: '0',
  //     seriesIndex: 0,
  //   },
  //   {
  //     label: 'Daria Harrison',
  //     field: '642171eceab6b0fc47513c87',
  //     metaData: {
  //       email: 'oscar_hagman@hotmail.com',
  //       fullName: 'Daria Harrison',
  //       personalNumber: '19880308-3701',
  //       createdAt: '2023-03-26T13:27:46.835Z',
  //       updatedAt: '2023-03-26T13:27:46.835Z',
  //       id: '642171eceab6b0fc47513c87',
  //       phoneNumber: '0706749748',
  //       employmentDate: '2022-02-01T00:00:00.000Z',
  //       employmentType: 'Permanent employment',
  //       foraType: '-',
  //       jobTitle: '',
  //       personnelType: 'WhiteCollar',
  //       salary: '22000',
  //       salaryType: 'Monthly',
  //       scheduleId: 'HEL',
  //       vacation: {
  //         current: {
  //           paidHolidays: 0,
  //           effectiveFrom: '2024-01-01',
  //           updatedAt: '2024-01-24T11:56:00.009Z',
  //         },
  //         next: {
  //           paidHolidays: 67,
  //           effectiveFrom: '2024-02-01',
  //           updatedAt: '2024-01-24T14:57:52.731Z',
  //         },
  //         history: [],
  //       },
  //       isDisable: true,
  //     },
  //     style: {
  //       defaultColor: '#fd625e',
  //       darkColor: '#fd625e',
  //     },
  //     color: '#fd625e',
  //     checked: true,
  //     value: '1',
  //     seriesIndex: 1,
  //   },
  //   {
  //     label: 'Jenny Winbom',
  //     field: '642171eceab6b0fc47513c86',
  //     metaData: {
  //       email: 'oscar_hagman@hotmail.com',
  //       fullName: 'Jenny Winbom',
  //       personalNumber: '19891209-1223',
  //       createdAt: '2023-03-26T13:27:46.834Z',
  //       updatedAt: '2023-03-26T13:27:46.834Z',
  //       id: '642171eceab6b0fc47513c86',
  //       fixedOffsetSalaryChange: '2 000',
  //       phoneNumber: '0706749748',
  //       employmentDate: '2021-03-22T00:00:00.000Z',
  //       employmentType: 'Permanent employment',
  //       foraType: '-',
  //       jobTitle: '',
  //       personnelType: 'WhiteCollar',
  //       salary: '75000',
  //       salaryType: 'Monthly',
  //       scheduleId: 'HEL',
  //       isDisable: true,
  //     },
  //     style: {
  //       defaultColor: '#ddb60a',
  //       darkColor: '#ddb60a',
  //     },
  //     color: '#ddb60a',
  //     checked: true,
  //     value: '2',
  //     seriesIndex: 2,
  //   },
  // ]);

  //console.log('employeeSeries', employeeSeries);
  const seriesOptions: ISeriesOptions = {
    series: employeeSeries,
    allowDeleteSeries: true,
    allowAddSeries: employeeSeries.length < 7 ? true : false,
    showToggleButtons: employeeSeries.length > 1 ? true : false,
  };

  const changeDateHandler = (dateRange: [string, string]) => {
    setDateRange(dateRange);
  };

  const addEmployeeToChart = (employee) => {
    setEmployeeSeries([
      ...employeeSeries,
      {
        label: employee.fullName,
        field: employee.id,
        metaData: employee,
      },
    ]);
  };

  const deleteHandler = (series) => {
    const employees = employeeSeries.filter((e) => e.field !== series.field);
    setEmployeeSeries([...employees]);
  };

  const fetchChartData = async () => {
    const startDateOfTheYear = dateRange[0];
    const endDateOfTheYear = dateRange[1];
    const formattedData: any = [];

    try {
      setIsLoading(true);

      let chartData = await requestFn({
        duration: { from: startDateOfTheYear, to: endDateOfTheYear },
        companyClientId: user?.role.company.id,
        employeeIds: employeeSeries.slice(1).map((item) => item.metaData.id),
      });

      chartData?.salariesByDate
        .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
        .forEach((value) => {
          formattedData.push(
            [moment(value.date).startOf('month').format('YYYY-MM-DD'), value.total].concat(
              value.employeeSalaries
                ? employeeSeries.slice(1).map((item) => {
                    const foundEmployeeSalary = value.employeeSalaries.find(
                      (employeeItem) => employeeItem.employee.id === item.metaData.id,
                    );

                    if (foundEmployeeSalary) {
                      return foundEmployeeSalary[chartGenre.fieldKey].value;
                    }

                    return 0;
                  })
                : [],
            ),
          );
        });

      setChartRecords([...formattedData]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [dateRange, employeeSeries]);

  return {
    employeeSeries,
    setEmployeeSeries,
    seriesOptions,
    dateRange,
    chartRecords,
    isLoading,
    setChartRecords,
    fetchChartData,
    changeDateHandler,
    addEmployeeToChart,
    deleteHandler,
  };
};

export default useComparisonSeriesChart;
