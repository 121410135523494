import { Button, Form, Tab, Tabs } from 'react-bootstrap';
import { Edit2, Save } from 'react-feather';
import BSModal from '../../../components/Modal';
import MonthAndYearDropdown from '../../../section/MonthAndYearDropdown';
import { BillingDetailGrid } from './BillingDetailGrid';
import { useState } from 'react';
import moment from 'moment';
import PagesHeader from '../../../components/base/PagesHeader';
import { useTranslation } from 'react-i18next';

type Props = {
  show: boolean;
  toggleModal: () => void;
  billingDetails: any;
};

const ModalTitle = ({ companyName }: { companyName: string }) => {
  const { t } = useTranslation();
  return (
    <>
      <p className="fs-page-title mb-0">{t('billing_details')}</p>
      <PagesHeader headingClass="fs-h1" pageTitle={companyName} translate={false} />
    </>
  );
};

const ModalFooter = () => {
  const { t } = useTranslation();

  return (
    <>
      <Button className="app-btn-secondary cancel-btn cancel-btn">{t('cancel')}</Button>
      <Button className="app-btn-primary">
        <Save className="icon-gap-right" /> {t('save')}
      </Button>
    </>
  );
};

export default function BillingDetailsModal({ billingDetails, show, toggleModal }: Props) {
  const [effectiveFrom, setEffectiveFrom] = useState('');
  const [activeTab, setActiveTab] = useState<any | undefined>('current_month');
  const [editMode, setEditMode] = useState(false);
  const effectiveFromNotEmpty = effectiveFrom !== '';
  const { t } = useTranslation();

  return (
    <BSModal
      ModalFooter={<ModalFooter />}
      title={<ModalTitle companyName={billingDetails['companyName']} />}
      show={show}
      onHide={toggleModal}
      className="billing-detail-modal"
    >
      <Tabs
        activeKey={activeTab}
        onSelect={(tab) => setActiveTab(tab)}
        className="nav-border-bottom"
        defaultActiveKey="current_month"
      >
        <Tab
          disabled={editMode}
          tabClassName="mb-2 active-invert me-2 fs-label font-color-primary border-radius-all"
          eventKey="current_month"
          title={`${t('current_month')}`}
        >
          <BillingDetailGrid editMode={editMode} setEditMode={setEditMode} />
        </Tab>
        {effectiveFromNotEmpty && (
          <Tab
            disabled={editMode}
            tabClassName="mb-2 active-invert me-2 fs-label font-color-primary border-radius-all"
            eventKey="effective"
            title={`${t('effective')} ${moment(effectiveFrom).format('MMM YYYY')}`}
          >
            <BillingDetailGrid editMode={editMode} setEditMode={setEditMode} />
          </Tab>
        )}
      </Tabs>
      {!editMode && (
        <Button
          disabled={effectiveFromNotEmpty}
          onClick={() => setEditMode(true)}
          style={{ top: 20, right: 15}}
          className="position-absolute mb-2 app-btn-secondary ms-auto d-block"
        >
          <Edit2 className="icon-gap-right" /> {t('edit_global_product_template')}
        </Button>
      )}
      <div style={{ top: -3, left: effectiveFromNotEmpty ? '325px' : '160px' }} className="position-absolute">
        <Form.Label className="mb-0 fs-name">{t('new_billing_effective_from')}</Form.Label>
        <MonthAndYearDropdown
          disabled={editMode}
          value={effectiveFrom}
          onOptionSelect={(val) => {
            setEffectiveFrom(val);
            if (val === '') {
              setActiveTab('current_month');
            }
          }}
        />
      </div>
    </BSModal>
  );
}
