import { isNumeric } from '../../util/util';

type Align = 'center' | 'end' | 'start';
type Props = {
  value: any;
  align?: Align;
  width?: number | null;
  style?: any;
};

export const FakeInputBox = ({ value, align = 'start', width = null, style = null }: Props) => {
  const _width = width ? width : isNumeric(value) ? 80 : undefined;
  return (
    <div
      className={`d-flex justify-content-${align} align-items-center px-2 fake-input-box`}
      style={{
        width: _width,
        // background: value !== '' ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
        borderRadius: 2,
        height: 40,
        ...style,
      }}
    >
      {value}
    </div>
  );
};
