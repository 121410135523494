import useContext from '../../PensionPolicyContext/useContext';
import { PolicyLeaveCategory } from '../../types';
import { INITIAL_POLICY_SPECIAL_CONDITION } from '../../constants';

const useSpecialConditions = () => {
  const { formGroup, updatePolicySettingsInput } = useContext();
  const specialConditionSettings = formGroup?.values.policySettings.specialConditions;

  const probationaryEmployement =
    specialConditionSettings?.probationaryEmployement || INITIAL_POLICY_SPECIAL_CONDITION.probationaryEmployement;
  const parentalLeave = specialConditionSettings?.parentalLeave || INITIAL_POLICY_SPECIAL_CONDITION.parentalLeave;
  const otherLeave = specialConditionSettings?.otherLeave || INITIAL_POLICY_SPECIAL_CONDITION.otherLeave;

  const handleToggleProbationaryEmployeePolicy = (name: string, checked: boolean) => {
    updatePolicySettingsInput(`specialConditions.${PolicyLeaveCategory.PROBATIONARY}.${name}`, checked);
  };

  const handleToggleParentalLeavePolicy = (name: string, checked: boolean) => {
    updatePolicySettingsInput(`specialConditions.${PolicyLeaveCategory.PARENTAL}.${name}`, checked);
  };

  const handleToggleOtherLeavePolicy = (name: string, checked: boolean) => {
    updatePolicySettingsInput(`specialConditions.${PolicyLeaveCategory.OTHER}.${name}`, checked);
  };

  const handleChangeDaysLength = (category: PolicyLeaveCategory, name: string, value: string) => {
    let length: number | null = null;

    if (typeof value === 'string' && !Number.isNaN(Number(value))) {
      length = Number(value);
    }

    updatePolicySettingsInput(`specialConditions.${category}.${name}`, length);
  };

  const isPolicyPartIncluded = (category: PolicyLeaveCategory) => {
    if (!specialConditionSettings) {
      return false;
    }

    const policyLeaveCategory = specialConditionSettings[category];

    if (!policyLeaveCategory) {
      return false;
    }

    if (
      policyLeaveCategory.isInsurances &&
      policyLeaveCategory.isPremiumCalculation &&
      policyLeaveCategory.isSalaryChange
    ) {
      return true;
    }

    return false;
  };

  return {
    probationaryEmployement,
    parentalLeave,
    otherLeave,

    handleToggleProbationaryEmployeePolicy,
    handleToggleParentalLeavePolicy,
    handleToggleOtherLeavePolicy,
    handleChangeDaysLength,
    isPolicyPartIncluded,
  };
};

export default useSpecialConditions;
