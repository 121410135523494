import { useEffect, useState } from 'react';
import moment from 'moment';

import useAuth from '../../../../../hooks/useAuth';
import { ISeries, ISeriesOptions } from '../../../../../components/charts/multi-series/types';
import { Calendar } from '../../../../../util/calendar';
import { IReportFilterParams } from '../../../../../types/report';
import { getEmployeeStatsByReportType } from '../../../../../api/service/company/reports/service';

const useCustomEmployeeReportCategoryChart = (
  selectedEmployee: ISeries,
  selectedReportTypeSeries: ISeries[] = [],
  selectedDateRange: [string, string] | [] = [],
) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [chartRecords, setChartRecords] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[string, string]>(
    selectedDateRange?.length ? selectedDateRange : Calendar.yearToDateBoundary,
  );
  const [reportSeries, setReportSeries] = useState<ISeries[]>([{ label: 'Total', field: 'total' }]);
  const seriesOptions: ISeriesOptions = {
    series: reportSeries,
    allowDeleteSeries: true,
    allowAddSeries: reportSeries.length < 6 ? true : false,
    showToggleButtons: reportSeries.length > 1 ? true : false,
  };

  const fetchChartData = async () => {
    const startDateOfTheYear = dateRange[0];
    const endDateOfTheYear = dateRange[1];
    const formattedData: any = [];

    try {
      setIsLoading(true);

      let chartData = await getEmployeeStatsByReportType({
        duration: { from: startDateOfTheYear, to: endDateOfTheYear },
        companyClientId: user?.role.company.id,
        employeeId: selectedEmployee?.metaData?.id,
        reportCategory: reportSeries.slice(1).map((item) => item.field),
      } as IReportFilterParams & { reportCategory: string[] });

      chartData?.salariesByDate
        .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
        .forEach((value) => {
          formattedData.push(
            [moment(value.date).startOf('month').format('YYYY-MM-DD'), value.total || 0].concat(
              value.employeeDataByReportType
                ? reportSeries.slice(1).map((item) => {
                    const foundEmployeeSalary = value.employeeDataByReportType.find(
                      (employeeItem) => employeeItem.reportType.id === item.field,
                    );

                    if (foundEmployeeSalary) {
                      return foundEmployeeSalary['chart'].value || 0;
                    }

                    return 0;
                  })
                : [],
            ),
          );
        });

      setChartRecords([...formattedData]);
    } finally {
      setIsLoading(false);
    }
  };

  const changeDateHandler = (dateRange: [string, string]) => {
    setDateRange(dateRange);
  };

  const addReportToChart = (report) => {
    setReportSeries([
      ...reportSeries,
      {
        label: report.label,
        field: report.field,
        metaData: report,
      },
    ]);
  };

  const addManyReportTypesToChart = (reports) => {
    setReportSeries([
      { label: 'Total', field: 'total' },
      ...reports.map((report) => {
        return {
          label: report.label,
          field: report.field,
          labelKey: report.labelKey,
          metaData: report,
        };
      }),
    ]);
  };

  const deleteHandler = (series) => {
    const reports = reportSeries.filter((e) => e.field !== series.field);
    setReportSeries([...reports]);
  };

  useEffect(() => {
    addManyReportTypesToChart(selectedReportTypeSeries);
  }, [selectedReportTypeSeries.map((item) => item.label).join(',')]);

  useEffect(() => {
    fetchChartData();
  }, [dateRange, reportSeries]);

  return {
    reportSeries,
    setReportSeries,
    seriesOptions,
    dateRange,
    chartRecords,
    isLoading,
    setChartRecords,
    fetchChartData,
    changeDateHandler,
    addReportToChart,
    addManyReportTypesToChart,
    deleteHandler,
  };
};

export default useCustomEmployeeReportCategoryChart;
