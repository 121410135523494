import { useState, useEffect } from 'react';

interface ChartTableProps {
  tableData: any[];
  selectedSeries: any[];
}
export const PieChartTable = ({ tableData, selectedSeries }: ChartTableProps) => {
  const [header, setHeader] = useState<any>([]);
  const [rows, setRows] = useState<any>([]);

  const setTableData = () => {
    setHeader(tableData[0]);
    setRows(tableData.filter((value, index) => index !== 0));
  };

  useEffect(() => {
    setTableData();
  }, []);

  return (
    <>
      <div className="table-responsive react-bootstrap-table chart-table">
        <table className="table table-hover table-condensed">
          <thead className="header-wrapper">
            <tr className="correcto-table-header">
              <th className="month-header-col">Category</th>
              {<th className="series-header-col">{header[1]}</th>}
              {header.map((item, index) => {
                return (
                  <>
                    {index !== 0 && index !== 1 && (
                      <th key={index} className="theme-text series-header-col" scope="col">
                        {item}
                      </th>
                    )}
                  </>
                );
              })}
            </tr>
          </thead>
          <tbody className="remote-table-body">
            {rows.map((item, index) => {
              return (
                <tr key={index}>
                  {item.map((val, rowIndex) => {
                    return (
                      <>
                        {rowIndex === 0 && (
                          <>
                            <th className="theme-text" key={rowIndex}>
                              {val}
                            </th>
                          </>
                        )}
                        {rowIndex !== 0 && (
                          <td key={rowIndex} className="number-col">
                            <span className="theme-text ">{val}</span>
                          </td>
                        )}
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
