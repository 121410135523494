import { Button } from 'react-bootstrap';
import { IInsurancesContext, Mode, TypePlanItem } from '../../types';
import PlanList from './PlanList';
import { Plus } from 'react-feather';
import { generateUniqueId } from '../../../../../util/util';
import useContext from '../context/useContext';
import { InsuranceSpecialConditionMode } from '../../../PensionPolicy/types';
import { useTranslation } from 'react-i18next';
import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/settings/insurance.module.scss';
import classNames from 'classnames';

type Props = {
  insurancePlans: any;
  context: IInsurancesContext;
  mode: Mode;
  isEditable: boolean;
  insuranceType: TypePlanItem;
};

export const Plans = ({ mode, context, isEditable, insurancePlans, insuranceType }: Props) => {
  const { replaceInsurancePlan } = useContext();
  const selectedInsurancePlan = insurancePlans[insuranceType.value];
  const { t } = useTranslation();
  const addInsurancePlan = () => {
    replaceInsurancePlan(
      [
        ...selectedInsurancePlan,
        {
          id: generateUniqueId(),
          insuranceName: '',
          insuranceDescription: '',
          ageSpan: {
            to: '',
            from: '',
          },
          specialCondition: InsuranceSpecialConditionMode.ABLE_BODIED,
          hjertaInfinity: false,
          documents: [],
          order: 0,
        },
      ],
      insuranceType.value,
    );
  };
  return (
    <>
      {!isEditable && selectedInsurancePlan.length === 0 ? (
        <></>
      ) : (
        <div className="table-responsive react-bootstrap-table pension-plans-table mb-2 dark-content-section">
          <div className="fs-h3 theme-text">{t(`INSURANCE_TYPES.${insuranceType.label}`)}</div>
          <table className={classNames('table table-hover table-condensed mb-1', themeStyle.insurance_grid)}>
            <tbody>
              <PlanList
                context={context}
                mode={mode}
                insurancePlans={insurancePlans[insuranceType.value]}
                isEditable={isEditable}
                planType={insuranceType.value}
              />
            </tbody>
          </table>
          {mode !== Mode.Detail && (
            <div>
              <Button className="app-btn-secondary btn btn-primary" onClick={addInsurancePlan}>
                <Plus />
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};
