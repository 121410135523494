import { t } from 'i18next';
import { Locale } from '../locale/Locale';

export default function tableHeaderFormatter(column) {
  const headerText = column.text.toLowerCase().split(' ').join('_');

  return <Locale text={headerText} />;
}

export function tableHeaderText(text) {
  const headerText = text.toLowerCase().split(' ').join('_');

  return t(headerText);
}
