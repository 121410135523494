export interface SalaryType {
  _id: string;
  name: string;
  code: number;
  includeInPension: boolean;
  mapsTo: Array<{
    name: string;
    code: string;
    platformName: string;
  }>;
  includedInPension: boolean;
  updatedAt: string;
  englishName: string;
  percentage: number;
}

export const DEFAULT_PERSON_INFO = {
  name: '',
  address: { city: '', country: '', postcode: '', street: '' },
  fullName: '',
  email: '',
  firstName: '',
  lastName: '',
};

export const DEFAULT_COMPANY_INFO = {
  name: '',
  address: {
    postcode: '',
    city: '',
    country: '',
    street: '',
  },
  authorizedSignatory: [],
};

export type OverviewErrorAlert = {
  msg: string;
  link?: string;
  linkText?: string;
};

export type MonthSelectOption = {
  label: string;
  value: string;
  key: string;
  readonly?: boolean;
};

export enum BaseStatus {
  ACTIVE = 'Active',
  IN_ACTIVE = 'InActive',
}
