import { Save } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../components/base/Breadcrumb';
import PagesHeader from '../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import { useState, useEffect } from 'react';
import { Spinner, Button, Tabs, Tab } from 'react-bootstrap';
// import { useValidationError } from '../../../hooks/useValidationError';
// import ValidationAlert from '../../../components/ValidationAlert';
import useAuth from '../../../hooks/useAuth';
import useTabChangeListener from '../../../hooks/useTabChangeListener';
import General from './General/General';
import { useAccountingFirmEditGeneralSettingsContext } from './General/context/useContext';
import {
  getCompanyDetailsByID,
  updateAccountingFirmSettings,
} from '../../../api/service/accountingFirm/settings/service';
import { Legal } from './Legal/Legal';
import { Log } from './Log/Logs';
import { AccessLevels } from './AccessLevels/AccessLevel';
import { AccessLevelRef } from './AccessLevels/types';
import { deepClone, isObjectEmpty, splitFullName } from '../../../util/util';
import ValidationAlert from '../../../components/ValidationAlert';
import { useValidationError } from '../../../hooks/useValidationError';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Settings',
  },
];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
let _companyDetail = {};

const AccountingFirmSettings = () => {
  const { t } = useTranslation();
  const { errors, isInvalid, setErrors } = useValidationError();
  const [isBusy, setIsBusy] = useState(false);
  const [date, setDate] = useState({ createdAt: '', updatedAt: '' });
  const [activeKey, setActiveKey] = useState('general');
  const [isDeleting /*setisDeleting*/] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const { user } = useAuth();
  const id = user?.role.company.id;
  const [accessLevelRef, setAccessLevelRef] = useState<AccessLevelRef | null>();
  const [accountingFirmDetails, setAccountingFirmDetails] = useState<any>({
    supervisors: [],
    consultants: [],
    invitationEmail: '',
  });
  const context = useAccountingFirmEditGeneralSettingsContext();

  const {
    companyDetails,
    addressDetails,
    combinations,
    replaceCompanyDetails,
    replaceAddress,
    replaceAuthorizedSignatory,
    updateContractEffectiveInput,
    updateInvoiceEmailAddress,
    updateInvoicingCostCenter,
    patchFirmDetails,
    replaceCombinations,
    formGroup,
    contractEffectiveFrom,
    contractEmail,
    invoiceCostCenter,
  } = context;

  /* const hasChanges =
    !isEqual(_companyDetail['name'], companyDetails.name) ||
    !isEqual(_companyDetail['organizationNumber'], companyDetails.organizationNumber) ||
    !isEqual(_companyDetail['status'], companyDetails.status) ||
    !isEqual(_companyDetail['address'], addressDetails) ||
    !isEqual(_companyDetail['authorizedSignatory'], signatoryDetails); */

  // const hasAccessChanges = !isEqual(_accessDetail['accessLevels'], accessLevelRef?.accessLevels);

  /* const onCancel = () => {
    replaceCompanyDetails({
      name: _companyDetail['name'],
      organizationNumber: _companyDetail['organizationNumber'],
      status: _companyDetail['status'],
    });
    replaceAddress(_companyDetail['address']);
    replaceAuthorizedSignatory(_companyDetail['authorizedSignatory']);
    setDate({ createdAt: _companyDetail['createdAt'], updatedAt: _companyDetail['updatedAt'] });
  }; */

  const onUpdateAccountingFirm = async () => {
    try {
      // const modifiedSignatoryDetails = (signatoryDetails || []).map((i) => omit(i, ['id', 'valid']));

      const data = {
        ...companyDetails,
        address: addressDetails,
        // authorizedSignatory: modifiedSignatoryDetails,
        contractEffectiveFrom,
        invoiceEmailAddress: contractEmail,
        invoicingCostCenter: invoiceCostCenter,
        combinations,
        deletedAdmins: [],
        deletedConsultants: [],
        seniorConsultants: accountingFirmDetails.seniorConsultants,
        supervisors: accountingFirmDetails.supervisors,
        invitationEmail: accountingFirmDetails.invitationEmail,
      };

      formGroup.handleSubmit();

      const errors = await formGroup.validateForm();

      if (!isObjectEmpty(errors)) {
        console.log('errors', errors);
        setErrors(formGroup.errors);

        return;
      }

      setErrors({});
      setisUpdating(true);

      await updateAccountingFirmSettings(id, data);
      await fetchCompanyDetails();
    } finally {
      setisUpdating(false);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      setIsBusy(true);
      const clientDetails = await getCompanyDetailsByID(id);
      const {
        name,
        organizationNumber,
        contractEffectiveFrom,
        address,
        authorizedSignatory,
        createdAt,
        updatedAt,
        status,
        invoiceEmailAddress,
        invoicingCostCenter,
        contractNumber,
        resellerAgreement,
        combinations,
        invitationEmail,
        supervisors,
        seniorConsultants,
      } = clientDetails;
      _companyDetail = structuredClone(clientDetails);
      replaceCompanyDetails({
        name,
        organizationNumber,
        status,
        contractNumber,
        createdAt,
        updatedAt,
        resellerAgreement,
      });
      replaceAddress(address);
      updateContractEffectiveInput(contractEffectiveFrom);
      updateInvoiceEmailAddress(invoiceEmailAddress || '');
      updateInvoicingCostCenter(invoicingCostCenter);
      replaceAuthorizedSignatory(authorizedSignatory);
      patchFirmDetails(deepClone(clientDetails));
      replaceCombinations(
        combinations.map((combination, index) => {
          return {
            signee: combination.signee,
            authorizedSignatory: combination.authorizedSignatory.map((signatory) => {
              return { ...splitFullName(signatory.fullName), ...signatory };
            }),
          };
        }),
      );
      setAccountingFirmDetails({ seniorConsultants, invitationEmail, supervisors });
      setDate({ createdAt, updatedAt });
    } finally {
      setIsBusy(false);
    }
  };

  const broadcastAccessLevelValues = (ref) => {
    setAccessLevelRef(ref);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  useEffect(() => {
    fetchCompanyDetails();
  }, []);
  useTabChangeListener(activeKey);

  return (
    <>
      <div>
        <Breadcrumb navItem={breadcrumb} />
        <div className="d-flex justify-content-between">
          <PagesHeader pageTitle="Settings" headingClass="fs-h1" />
          {activeKey === 'general' && (
            <div className='cr-form-row'>
              {/* <Button className="app-btn-secondary me-2 cancel-btn" onClick={onCancel}>{`${t(
                'cancel_changes',
              )}`}</Button> */}
              <Button
                disabled={isDeleting || isUpdating}
                onClick={onUpdateAccountingFirm}
                className="app-btn-primary"
              >
                {isUpdating ? <Spinner size="sm" /> : <Save className="icon-gap-right" />} {`${t('save_changes')} `}
              </Button>
            </div>
          )}
          {accessLevelRef && activeKey === 'access_levels' && (
            <div className='cr-form-row'>
              {accessLevelRef!.hasAccessLevelChanges && (
                <Button
                  className="app-btn-secondary cancel-btn"
                  onClick={() => accessLevelRef!.cancelAccessLevel()}
                >{`${t('cancel_changes')}`}</Button>
              )}
              <Button
                onClick={() => accessLevelRef!.saveAccessLevel()}
                className="app-btn-primary "
                disabled={!accessLevelRef!.hasAccessLevelChanges}
              >
                {accessLevelRef!.isSavingAccessLevel ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}{' '}
                {`${t('save_changes')} `}
              </Button>
            </div>
          )}
        </div>
        <>
          <ValidationAlert show={isInvalid} errors={errors} />
          <Tabs defaultActiveKey="general" onSelect={handleTabChange}>
            <Tab eventKey="general" title={t('general')}>
              <General date={date} isBusy={isBusy} />
            </Tab>
            <Tab eventKey="legal" title={t('legal')}>
              <Legal />
            </Tab>
            <Tab eventKey="logs" title={t('logs')}>
              <Log isBusy={isBusy} />
            </Tab>
            <Tab eventKey="access_levels" title={t('access_levels')}>
              <AccessLevels emitValues={broadcastAccessLevelValues} />
            </Tab>
          </Tabs>
        </>
      </div>
    </>
  );
};

export default AccountingFirmSettings;
