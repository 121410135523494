import { SidebarItemsType } from '../../../types/sidebar';

import Activity from '../../../assets/img/icons/activity.svg';
import DollarSign from '../../../assets/img/icons/dollar-sign.svg';
import PiggyBank from '../../../assets/img/icons/saving-coin.svg';
import Users from '../../../assets/img/icons/users.svg';
import FileMinus from '../../../assets/img/icons/file-minus.svg';
import Settings from '../../../assets/img/icons/settings.svg';
import Briefcase from '../../../assets/img/icons/briefcase.svg';
import Grid from '../../../assets/img/icons/grid.svg';
import Sliders from '../../../assets/img/icons/sliders.svg';
import Database from '../../../assets/img/icons/database.svg';
import CompanyIcon from '../../../assets/img/icons/company-2.svg';

const companySidebarMenuItems = [
  {
    href: '/dashboard',
    icon: Grid,
    title: 'Overview',
  },
  // {
  //   href: '/business-analysis',
  //   icon: Activity,
  //   title: 'Business Analysis',
  // },
  {
    href: '/employees',
    icon: Users,
    title: 'Employees',
  },
  // {
  //   href: '/pension-cost',
  //   icon: DollarSign,
  //   title: 'Pension Cost',
  // },
  {
    href: '/pension-policies',
    icon: Briefcase,
    title: 'Pension Policies',
  },
  {
    href: '/reports',
    icon: FileMinus,
    title: 'Reports',
  },
  {
    href: '/data-management',
    icon: Database,
    title: 'Cost validation',
  },
  {
    href: '/settings',
    icon: Settings,
    title: 'Settings',
  },
] as SidebarItemsType[];

const superAdminSidebarMenuItems = [
  {
    href: '/dashboard',
    icon: Grid,
    title: 'Overview',
  },
  {
    href: '/accounting-firms',
    icon: CompanyIcon,
    title: 'Accounting firms',
  },
  {
    href: '/company-clients',
    icon: Briefcase,
    title: 'Company Clients',
  },
  {
    href: '/personal-clients',
    icon: Users,
    title: 'Personal Clients',
  },
  {
    href: '/pension-policies',
    icon: Briefcase,
    title: 'Pension Policies',
  },
  {
    href: '/billing',
    icon: PiggyBank,
    title: 'Billing',
  },
  {
    href: '/admin-settings',
    icon: Settings,
    title: 'Settings',
  },
] as SidebarItemsType[];

const employeeSidebarMenuItems = [
  {
    href: '/dashboard',
    icon: Grid,
    title: 'Overview',
  },
  {
    href: '/pension-statement',
    icon: Activity,
    title: 'Pension Statement',
  },
  {
    href: '/pension-funds',
    icon: Sliders,
    title: 'Change Pension Funds',
  },
  {
    href: '/salary-check',
    icon: DollarSign,
    title: 'Salary Check',
  },
  {
    href: '/pension-paid',
    icon: Briefcase,
    title: 'Pension Paid',
  },
  {
    href: '/settings',
    icon: Settings,
    title: 'Settings',
  },
] as SidebarItemsType[];

const accountingFirmMenuItems = [
  // {
  // href: '/dashboard',
  //icon: Grid,
  //   title: 'Overview',
  // },
  {
    href: '/accounting-firms',
    icon: CompanyIcon,
    title: 'Clients',
    subRoles: ['Supervisor'],
    titleKey: 'clients',
  },
  {
    href: '/accounting-firms',
    icon: CompanyIcon,
    title: 'My Clients',
    subRoles: ['Consultant', 'SeniorConsultant', 'JuniorConsultant'],
    titleKey: 'my_clients',
  },
  {
    href: '/consultant',
    icon: CompanyIcon,
    title: 'Consultants',
    subRoles: ['Supervisor'],
    titleKey: 'consultants',
  },
  {
    href: '',
    preventLink: true,
    icon: CompanyIcon,
    title: 'Billing',
    subRoles: ['Supervisor'],
    titleKey: 'NAVBAR.billing',
  },
  // {
  //   href: '',
  //   preventLink: true,
  //   icon: CompanyIcon,
  //   title: 'Contracts',
  //   subRoles: ['Supervisor'],
  // },
  {
    href: '/settings',
    icon: CompanyIcon,
    title: 'Settings',
    subRoles: ['Supervisor'],
    titleKey: 'settings',
  },
] as SidebarItemsType[];

const companyNavItems = [
  {
    title: '\n',
    pages: companySidebarMenuItems,
  },
];

const superAdminNavItems = [
  {
    title: '\n',
    pages: superAdminSidebarMenuItems,
  },
];

const employeeNavItems = [
  {
    title: '\n',
    pages: employeeSidebarMenuItems,
  },
];

const accountingFirmNavItems = [
  {
    title: '\n',
    pages: accountingFirmMenuItems,
  },
];

export { companyNavItems, superAdminNavItems, employeeNavItems, accountingFirmNavItems };
