import { Form } from 'react-bootstrap';
import { Article, SalaryErrorType } from '../types';
import { Lock } from 'react-feather';
import { useSalarySlipContext } from '../context/useContext';
import { isEmptyVal, numberFormat, toNumber } from '../../../../util/util';
import { ChangeEvent, useCallback, useEffect } from 'react';
import moment from 'moment';
import PriceInput from '../../../../components/base/PriceInput';
import { SalaryErrorLevel } from './error_level';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedItem: Article;
  index: number;
  errorCode: SalaryErrorType | null;
}

export default function SalaryArticleItem({ index, selectedItem, errorCode }: Props) {
  const { t } = useTranslation();
  const { setFormField, salaryTypes, formGroup } = useSalarySlipContext();
  const item = formGroup.values.articles[index];

  const hasErrorInArticle = () => {
    return selectedItem.article?.salaryCodeData?.id ? false : true;
  };

  const lockArticle = () => {
    setFormField(`articles[${index}]._internal.isLocked`, true);
    setFormField(`articles[${index}]._internal.disabledArticle`, true);
    setFormField(`articles[${index}].article.isDefault`, true);
  };

  const handleArticleChange = (value) => {
    const selectedSalaryType = salaryTypes.find((item) => item._id === value);

    setFormField(`articles[${index}].article.salaryCodeData`, {
      id: selectedSalaryType?._id,
      name: selectedSalaryType!.name,
      code: selectedSalaryType?.code,
    });
    setFormField(`articles[${index}]._internal.articleId`, value);
    setFormField(`articles[${index}]._internal.isLocked`, false);
  };

  const handleChangeDate = async (e: ChangeEvent<HTMLInputElement>) => {
    setFormField(`articles[${index}].${e.target.name}`, e.target.value);
  };

  const handleChangeAmount = (val) => {
    setFormField(`articles[${index}].amount`, val);
  };

  const hasDatePeriod = useCallback(() => {
    return item.fromDate && item.toDate;
  }, [item.fromDate, item.toDate]);

  const hasEditable = useCallback((errorCode: SalaryErrorType | null) => {
    if (!errorCode) {
      return false;
    }

    return errorCode !== SalaryErrorType.UNMATCHED_TYPE;
  }, []);

  // Calculate the days count between dates
  useEffect(() => {
    if (!hasDatePeriod()) {
      return;
    }

    const parsedStartDate = moment(item.fromDate);
    const parsedEndDate = moment(item.toDate);
    const daysDifference = parsedEndDate.diff(parsedStartDate, 'days') + 1;

    setFormField(`articles[${index}].pieces`, daysDifference);
  }, [item.fromDate, item.toDate]);

  // Calculate the updated value by using (days*amount)
  useEffect(() => {
    if (
      (SalaryErrorLevel.isLevelTwo(errorCode) && !hasDatePeriod()) ||
      (SalaryErrorLevel.isLevelOne(errorCode) && !hasDatePeriod())
    ) {
      return;
    }

    const pieces = toNumber(item.pieces);
    const amount = toNumber(item.amount);
    const total = pieces * amount;

    setFormField(`articles[${index}].updatedValue`, toNumber(total, true, 2));
  }, [item.pieces, item.amount, hasDatePeriod, errorCode]);

  return (
    <tr>
      <td>
        {!hasErrorInArticle() && (
          <Form.Control type="text" name="article" disabled value={selectedItem.article.salaryCodeData.name!} />
        )}
        {hasErrorInArticle() && (
          <Form.Select
            value={item._internal!.articleId}
            onChange={(e) => handleArticleChange(e.target.value)}
            disabled={item._internal!.disabledArticle}
          >
            <option value={selectedItem.article.extractedData.name}>{selectedItem.article.extractedData.name}</option>
            {salaryTypes.map((salaryItem) => {
              return (
                <option key={salaryItem._id} value={salaryItem._id}>
                  {salaryItem.name}
                </option>
              );
            })}
          </Form.Select>
        )}
        {hasErrorInArticle() && <div className="color-red">{t('unmatched_salary_type')}</div>}
      </td>
      <td>
        {hasErrorInArticle() && (
          <button className="btn app-btn-secondary" disabled={item._internal!.isLocked} onClick={() => lockArticle()}>
            <Lock width={15} />
          </button>
        )}
      </td>
      <td>
        {item.fromDate && (
          <Form.Control
            name="fromDate"
            style={{ width: 120 }}
            type="date"
            value={item.fromDate}
            max={item.toDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeDate(e)}
            disabled={!hasEditable(errorCode)}
          />
        )}
      </td>
      <td>
        {item.toDate && (
          <Form.Control
            name="toDate"
            style={{ width: 120 }}
            type="date"
            value={item.toDate}
            min={item.fromDate}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeDate(e)}
            disabled={!hasEditable(errorCode)}
          />
        )}
      </td>
      <td>
        {hasDatePeriod() && (
          <Form.Control className="text-right" name="pieces" disabled value={numberFormat(item.pieces)!} />
        )}
      </td>
      <td>{hasDatePeriod() && <Form.Control className="text-left" name="unit" disabled value={item.unit!} />}</td>
      <td>
        {hasDatePeriod() && (
          <PriceInput
            className="text-right"
            defaultValue={selectedItem.amount}
            onChange={handleChangeAmount}
            disabled={!hasEditable(errorCode)}
            maxValueDigit={7}
          />
        )}
      </td>
      <td className="number-val amount original-value">{numberFormat(item.orignalValue)!}</td>
      <td className="new-input-value">
        {SalaryErrorLevel.isLevelTwo(errorCode) &&
          (hasDatePeriod() ? (
            <Form.Control
              className="text-right new-input-value"
              type="text"
              name="personalNumber"
              value={numberFormat(item.updatedValue) || ''}
              disabled
            />
          ) : (
            <PriceInput
              className="text-right"
              onChange={(val) => setFormField(`articles[${index}].updatedValue`, val)}
              defaultValue={isEmptyVal(selectedItem.updatedValue) ? selectedItem.orignalValue : ''}
              maxValueDigit={7}
            />
          ))}
      </td>
    </tr>
  );
}
