import useContext from '../PensionPolicyContext/useContext';

const usePolicyValidator = () => {
  const { formGroup } = useContext();

  const isCheckedPensionManagerPlan = (preSelected: any = null) => {
    const formData = preSelected || formGroup.values;

    return formData?.policySettings?.salaryChange?.isCheckedSepratePensionManagerPlans || false;
  };

  const isCheckedTemplateCalculation = (preSelected: any = null) => {
    const formData = preSelected || formGroup.values;

    return formData?.policySettings?.salaryType?.isTemplateCalculation || false;
  };

  const isCheckedHolidaySetup = (index) => {
    const formData = formGroup.values;
    const salaryTypesWithHolidays = formData.policySettings.salaryType.salaryTypesWithHolidays;

    if (salaryTypesWithHolidays?.[index].isEnable) {
      return true;
    }

    return false;
  };

  const isCheckedThirdHolidaySetup = () => {
    const formData = formGroup.values;
    const salaryTypesWithHolidays = formData.policySettings.salaryType.salaryTypesWithHolidays;

    if (salaryTypesWithHolidays?.[2].isEnable) {
      return true;
    }

    return false;
  };

  return {
    isCheckedPensionManagerPlan,
    isCheckedTemplateCalculation,
    isCheckedHolidaySetup,
    isCheckedThirdHolidaySetup,
  };
};

export default usePolicyValidator;
