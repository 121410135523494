import React from 'react';

import AdminSignIn from '../../components/auth/AdminSignIn';

const AdminSignInPage = () => {
  return (
    <React.Fragment>
      <div className="middle-content pt-6">
        <div className="auth-box">
          <AdminSignIn />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AdminSignInPage;
