import { useEditGeneralSettingsContext } from '../context/useContext';
import { deepClone, excludeKeys, formatPersonalNumber, isEqual } from '../../../../../util/util';
import { EditableGeneralForm } from '../../types';
import { AdminDetails } from '../GeneralForms/types';
import useGeneralForm from './useGeneralForm';
import { useEffect, useState } from 'react';
import { FORM_INITIAL_VALUES } from '../contants';
import { toTitleCase } from '../../../../../util';

const useGeneralSettingsObserver = () => {
  const { formGroup, companyRes, addressDetails, invoiceCostCenter, contractEmail, adminDetails, deletedAdmins } =
    useEditGeneralSettingsContext();
  const { getDefaultValue, populateSettingsForm } = useGeneralForm();

  const [isFormModified, setIsFormModified] = useState(false);

  const getInitialFormValue = () => {
    const modifiedAdmins = getDefaultValue<any[]>(companyRes, 'admins').map((i) => {
      const excludedKeys = ['_id', 'valid', 'hasRecordExist'] as Array<keyof AdminDetails>;

      const entity = excludeKeys(i, excludedKeys);

      return {
        ...entity,
        personalNumber: formatPersonalNumber(i.personalNumber),
      };
    });

    const initialFormValue: EditableGeneralForm = {
      invoiceEmailAddress: getDefaultValue(companyRes, 'invoiceEmailAddress'),
      invoicingCostCenter: getDefaultValue(companyRes, 'invoicingCostCenter'),
      admins: modifiedAdmins,
      deletedAdmins: getDefaultValue(companyRes, 'deletedAdmins'),
      address: {
        street: getDefaultValue(companyRes, 'address.street'),
        postcode: getDefaultValue(companyRes, 'address.postcode'),
        city: toTitleCase(getDefaultValue(companyRes, 'address.city')),
        country: getDefaultValue(companyRes, 'address.country'),
      },
    };

    return initialFormValue;
  };

  const getUpdatedFormValue = (): EditableGeneralForm => {
    const modifiedAdminDetails = adminDetails.map((i) => {
      const excludedKeys = ['_id', 'valid', 'hasRecordExist'] as Array<keyof AdminDetails>;
      const entity: any = excludeKeys(i, excludedKeys);

      return {
        ...entity,
        personalNumber: formatPersonalNumber(i.personalNumber),
      };
    });

    return {
      invoiceEmailAddress: contractEmail,
      invoicingCostCenter: invoiceCostCenter,
      admins: modifiedAdminDetails,
      deletedAdmins: deletedAdmins,
      address: {
        street: addressDetails.street,
        postcode: addressDetails.postcode,
        city: toTitleCase(addressDetails.city),
        country: addressDetails.country,
      },
    };
  };

  const revertChanges = () => {
    const initialFormGroup = deepClone(companyRes || FORM_INITIAL_VALUES);

    populateSettingsForm(initialFormGroup);
  };

  useEffect(() => {
    const initialFormValue = getInitialFormValue();
    const updatedFormValue = getUpdatedFormValue();
    /* const pickedAdmins = initialFormGroup.admins.map((value) => omit(value, ['status']));
    const updatedPickedAdmins = adminDetails.map((value) => omit(value, ['status']));
    const updatedInitialFormGroup = { ...deepClone(initialFormGroup), admins: pickedAdmins };

    setDisableSaveChangesBtn(
      isEqual(updatedInitialFormGroup, {
        address: addressDetails,
        admins: updatedPickedAdmins,
        deletedAdmins,
        invoiceEmailAddress: contractEmail,
        invoicingCostCenter: invoiceCostCenter,
      }),
    ); */

    if (isEqual(initialFormValue, updatedFormValue)) {
      setIsFormModified(false);
    } else {
      setIsFormModified(true);
    }
    console.log('initialFormValue', initialFormValue);
    console.log('updatedFormValue', updatedFormValue);
    console.log('updatedFormValue', isEqual(initialFormValue, updatedFormValue));
  }, [formGroup.values]);

  return {
    revertChanges,
    isFormModified,
  };
};

export default useGeneralSettingsObserver;
