/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import BootstrapTable, {
  ColumnDescription,
  ExpandRowProps,
  RowEventHandler,
  SelectRowProps,
  SortOrder,
} from 'react-bootstrap-table-next';
// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
// const { SearchBar } = Search;

type ColumnProps<T extends object = any, E = any> = {
  editCellStyle?: Object | Function;
  editorRenderer?: Function;
  type?: string;
  editorStyle?: Object | Function;
} & ColumnDescription;

export type ColumnType = ColumnDescription<any, any>[] | ColumnProps<any, any>[];

type TableDataType = {
  columns: ColumnType;
  data: any[];
  selectRow?: SelectRowProps<any>;
  sort?:
    | {
        dataField?: any;
        order: SortOrder;
        sortFunc?: any;
        sortCaret?: any;
      }
    | undefined;
  noDataIndication?: string;
  cellEdit?: any;
  onRowClick?: (row: any) => void;
  rowStyle?: React.CSSProperties | ((row: any, rowIndex: number) => React.CSSProperties) | undefined;
  expandRow?: any | undefined;
  rowClass?: ((row: any, rowIndex: number) => string) | string | undefined;
  keyField?: string;
  baseProps?: any;
};

const BSTable = ({
  data,
  columns,
  selectRow,
  sort,
  noDataIndication = 'No Data found',
  cellEdit,
  onRowClick,
  rowStyle,
  expandRow,
  rowClass,
  keyField,
  baseProps,
}: TableDataType) => {
  const noDataFound = data.length === 0;

  const rowEvents = {
    onClick: (e, row) => {
      if (e.target['nodeName'] === 'TD') {
        onRowClick && onRowClick(row);
      }
    },
  };

  /* if (data.length > 1000) {
    return (
      <ToolkitProvider search keyField="id" data={data} columns={columns} csv bootstrap4>
        {(props: any) => (
          <div>
            <h2>UYsd sdbj</h2>
            <SearchBar className="d-none" {...props.searchProps} />
            <MyExportCSV {...props.csvProps} />
            <BootstrapTable
              {...props.baseProps}
              condensed
              headerClasses="correcto-table-header"
              headerWrapperClasses="header-wrapper"
              bodyClasses={`classic-table-body ${onRowClick ? 'clickable-row' : ''}`}
              bordered={false}
              keyField={keyField || '_id'}
              data={data}
              columns={columns}
              selectRow={selectRow}
              sort={sort}
              noDataIndication={noDataIndication}
              cellEdit={cellEdit}
              hover={!noDataFound}
              rowEvents={rowEvents}
              rowStyle={rowStyle}
              rowClasses={rowClass}
              expandRow={expandRow}
            />
          </div>
        )}
      </ToolkitProvider>
    );
  } */

  return (
    <>
      <BootstrapTable
        {...baseProps}
        condensed
        headerClasses="correcto-table-header"
        headerWrapperClasses="header-wrapper"
        bodyClasses={`classic-table-body ${onRowClick ? 'clickable-row' : ''}`}
        bordered={false}
        keyField={keyField || '_id'}
        data={data}
        columns={columns}
        selectRow={selectRow}
        sort={sort}
        noDataIndication={noDataIndication}
        cellEdit={cellEdit}
        hover={!noDataFound}
        rowEvents={rowEvents}
        rowStyle={rowStyle}
        rowClasses={rowClass}
        expandRow={expandRow}
      />
    </>
  );
};

export default BSTable;
