import React from 'react';
import { Form } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { FormProps } from '../types';
import classNames from 'classnames';
import useLocale from '../../../../hooks/useLocale';

export const ProvisionAgreement = ({ context }: FormProps) => {
  const { localize } = useLocale();

  return (
    <div>
      <PagesHeader pageTitle={'Provision Agreement'} headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        <p className="theme-text">{localize('provision_agreement_detail')}</p>
        <div>
          <div className="cr-form-row">
            <div className="month-field">
              <Form.Label className={classNames('fs-name mb-0')}>
                {localize('upto')} 12 {localize('months')} (%)
              </Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="fullName"
                value={30}
                className={classNames('text-left')}
                style={{ maxWidth: 70 }}
              />
            </div>
            <div className="month-field">
              <Form.Label className={classNames('fs-name mb-0')}>
                12 {localize('to')} 24 {localize('months')} (%)
              </Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="fullName"
                value={20}
                className={classNames('text-left')}
                style={{ maxWidth: 70 }}
              />
            </div>
            <div className="month-field">
              <Form.Label className={classNames('fs-name mb-0')}>
                24 {localize('to')} 36 {localize('months')} (%)
              </Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="fullName"
                value={10}
                className={classNames('text-left')}
                style={{ maxWidth: 70 }}
              />
            </div>
            <div className="month-field">
              <Form.Label className={classNames('fs-name mb-0')}>&gt; 36 {localize('months')} (%)</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="fullName"
                value={0}
                className={classNames('text-left')}
                style={{ maxWidth: 70 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
