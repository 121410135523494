import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { sortCaret } from '../../../components/table/formatters';
import { ExternalLink, DownloadCloud, AlertTriangle } from 'react-feather';
import { tableHeaderText } from '../../../components/table/TableHeaderFormatter';
import classNames from 'classnames';
import { getInitialName } from '../../../util/util';
import moment from 'moment';
import RoleSwitcherColumn from './RoleSwitcherColumn';
import { UserSubRole } from '../../../types/auth';
import { getColumnsAccessByRole } from '../../../util/role-permission';
import useAuth from '../../../hooks/useAuth';

export const useColumn = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const currentCompanyRole = user?.role?.subRole;

  const companyClientColumn: ColumnDescription<any, any>[] | any[] = [
    {
      text: tableHeaderText('Company name'),
      dataField: 'name',
      sort: true,
      sortCaret,
      headerStyle: { width: 200 },
    },
    {
      text: tableHeaderText('Org.number'),
      dataField: 'organizationNumber',
      headerStyle: { width: 130 },
      sort: true,
      sortCaret,
    },
    {
      text: tableHeaderText('Employees'),
      dataField: 'employees',
      headerStyle: { width: 94 },
      classes: 'text-right',
      sort: true,
      sortCaret,
      // formatter: (cell) => cell || '-',
    },
    // {
    //   text: tableHeaderText('Documents'),
    //   dataField: 'contractNumber',
    //   formatter: (cell) => (
    //     <div className="doc-list">
    //       <span className={classNames('doc-list__item', 'doc-list__item--active')}>Contr</span>
    //       <span className={classNames('doc-list__item', cell === 2 ? 'doc-list__item--active' : '')}>Proxy</span>
    //       <span className="doc-list__item">KYC</span>
    //     </div>
    //   ),
    //   headerStyle: { width: 200 },
    // },
    {
      text: tableHeaderText('Status'),
      dataField: 'status',
      headerStyle: { width: 110 },

      sort: true,
      sortCaret,
      formatter: (cell) => (
        <span className={classNames('fs-text-400', 'active-green')}>{t((cell || '').toLowerCase())}</span>
      ),
    },
    {
      text: tableHeaderText('Last transaction'),
      dataField: 'createdAt',
      headerStyle: { width: 180 },
      formatter: (cell, row) => (
        <div className="last-transaction">
          <div className={classNames('last-transaction__icon')}>
            {!row.warningAction ? <ExternalLink /> : null}
            {row.warningAction ? <DownloadCloud /> : null}
          </div>
          <div className={classNames('last-transaction__date')}>
            <span className="last-transaction-label">Data output</span>
            <span className="last-transaction-val">{row.createdAt ? moment(row.createdAt).format('DD MMM') : '-'}</span>
          </div>
        </div>
      ),
    },
    {
      text: tableHeaderText('Consultants'),
      dataField: 'consultants',
      formatter: (cell, row) => {
        return (
          <div className="avatar-group row-navigator">
            {cell?.length > 0
              ? (cell || []).map((item) => {
                  return (
                    <section key={item._id}>
                      <div className={classNames('avatar-group__item', 'avatar-group__item--first')}>
                        <span className="avatar-name">{getInitialName(item.fullName) || '-'}</span>
                      </div>
                    </section>
                  );
                })
              : '-'}
          </div>
        );
      },
      headerStyle: { width: 150 },
      allowedRoles: [UserSubRole.SUPERVISOR],
    },
    {
      text: '',
      dataField: '',
      headerStyle: { width: 10 },
      formatter: (_, row) => (
        <div>
          {row.invalid && <AlertTriangle className="delete-icon icon-gap-right" />}
          {/* <span className="color-danger">45 Mixed errors </span> */}
        </div>
      ),
      // allowedRoles: [UserSubRole.SENIOR_CONSULTANT],
    },
    {
      text: '',
      dataField: '_id',
      align: 'left',
      formatter: (_, row) => {
        return <RoleSwitcherColumn row={row} />;
      },
      headerStyle: { width: 100 },
      classes: 'disabled-action',
    },
  ];

  return {
    companyClientColumn: getColumnsAccessByRole({
      columns: companyClientColumn,
      roleType: 'secondary',
      allowedRoles: currentCompanyRole!,
    }),
  };
};
