import { SetStateAction, Dispatch, useEffect, useState, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import BSModal from '../../../../components/Modal';
import CreateAccountingFirmModalFooter from './CreateAccountingFirmModalFooter';
import { useCreateAccountingFirmContext } from '../context/useContext';
import {
  FirmDetails,
  ContractDetails,
  AuthorizedSignatory,
  InvitationEmailSettings,
  SeniorConsultant,
  Supervisors,
} from '../AccountingFirmForms';

import { INITIAL_ADDRESS_DETAILS, INITIAL_FIRM_DETAILS } from '../constants';
import { useTranslation } from 'react-i18next';
import ValidationAlert from '../../../../components/ValidationAlert';
import { useValidationError } from '../../../../hooks/useValidationError';
import { getCompanyContractNumber } from '../../../../api/service/superAdmin/company-clients/service';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsAccountingFirmCreated: Dispatch<SetStateAction<boolean>>;
};

const CreateAccountingFirmModal = ({ showCreateModal, toggleModal, setIsAccountingFirmCreated }: Props) => {
  const { isInvalid, errors, setErrors } = useValidationError();
  const { t } = useTranslation();
  const {
    setFirmDetails,
    setAddressDetails,
    setSignatoryDetails,
    setSeniorConsultants,
    setSupervisors,
    setContractEffectiveFrom,
    setInvitationEmail,
    updateFirmDetailsInput,
  } = useCreateAccountingFirmContext();
  const [isContractLoading, setIsContractLoading] = useState(false);

  const fetchContractNumber = useCallback(async () => {
    try {
      setIsContractLoading(true);

      const res = await getCompanyContractNumber();
      updateFirmDetailsInput('contractNumber', res);
    } finally {
      setIsContractLoading(false);
    }
  }, []);

  useEffect(() => {
    if (showCreateModal) {
      fetchContractNumber();
    }
  }, [showCreateModal]);

  return (
    <>
      <BSModal
        title={`${t('new')} ${t('accounting_firms')}`}
        show={showCreateModal}
        onHide={() => {
          setFirmDetails(INITIAL_FIRM_DETAILS);
          setAddressDetails(INITIAL_ADDRESS_DETAILS);
          setSignatoryDetails([]);
          setSeniorConsultants([]);
          setSupervisors([]);
          setSignatoryDetails([]);
          setContractEffectiveFrom('');
          setInvitationEmail('');
          toggleModal();
        }}
        centered
        ModalFooter={
          <CreateAccountingFirmModalFooter
            setIsAccountingFirmCreated={setIsAccountingFirmCreated}
            toggleModal={toggleModal}
            setErrors={setErrors}
          />
        }
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />
          <FirmDetails isContractLoading={isContractLoading} context={useCreateAccountingFirmContext()} />
          <ContractDetails context={useCreateAccountingFirmContext()} />
          <AuthorizedSignatory context={useCreateAccountingFirmContext()} />
          <Supervisors context={useCreateAccountingFirmContext()} />
          <SeniorConsultant context={useCreateAccountingFirmContext()} />
          <InvitationEmailSettings context={useCreateAccountingFirmContext()} />
        </Form>
      </BSModal>
    </>
  );
};

export default CreateAccountingFirmModal;
