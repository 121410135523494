import classNames from 'classnames';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { getMonthsAndYear } from '../util/util';
import { Calendar } from '../util/calendar';
import { MonthSelectOption } from '../types';
import { t } from 'i18next';

type Props = {
  value: string;
  onOptionSelect?: (args) => void;
  disabled?: boolean;
  startFrom?: string | null;
  showLabelCurrent?: boolean | null;
  className?: string;
  shouldStartFromNextMonth?: boolean;
  startMonth?: string;
  disabledPreviousMonth?: boolean;
  disabledPreviousMonthByDate?: string;
  customOptions?: MonthSelectOption[];
};

const MonthAndYearDropdown = ({
  value,
  onOptionSelect,
  disabled = false,
  startFrom = null,
  showLabelCurrent,
  className,
  shouldStartFromNextMonth,
  disabledPreviousMonth = false,
  customOptions = [],
}: Props) => {
  const getMonthOffset = () => {
    if (shouldStartFromNextMonth) {
      return Calendar.nextMonthStartDate;
    }

    return startFrom;
  };

  const isDisabledOption = (date) => {
    if (disabledPreviousMonth) {
      return Calendar.hasMonthPassed(date) ||
        Calendar.parseDate(Calendar.currentMonthStartDate, 'YYYY-MM') ===
          Calendar.parseDate(date, 'YYYY-MM', ['MMM YYYY'])
        ? true
        : false;
    }

    return false;
  };

  return (
    <>
      <Form.Select
        value={value ? moment(value).format('MMM YYYY') : ''}
        disabled={disabled}
        onChange={(e) => {
          const options = e.target.options;
          const value = options[options.selectedIndex].getAttribute('id');
          onOptionSelect!(value);
        }}
        className={(classNames(className), 'calender-field')}
      >
        {!customOptions?.length &&
          getMonthsAndYear({
            startFrom: getMonthOffset(),
            noOfMonths: shouldStartFromNextMonth || startFrom ? 12 : 6,
          }).map((i) => {
            return (
              <option id={i['key']} key={i['key']} value={i['value']} disabled={isDisabledOption(i['value'])}>
                {i['label']}
                {showLabelCurrent && moment().format('MMM YYYY') === i['value'] ? ' (Current)' : ''}
              </option>
            );
          })}

        {customOptions?.length && (
          <option value={''} selected={!value ? true : false} disabled>
            {t('select')}
          </option>
        )}
        {customOptions?.length &&
          customOptions.map((i) => {
            return (
              <option id={i['key']} key={i['key']} value={i['value']} disabled={i.readonly || false}>
                {i['label']}
                {showLabelCurrent && moment().format('MMM YYYY') === i['value'] ? ' (Current)' : ''}
              </option>
            );
          })}
      </Form.Select>
    </>
  );
};

export default MonthAndYearDropdown;
