import { closestCenter, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import PlanItem from './PlanItem';
import { IInsurancesContext, InsurancePlan, Mode } from '../../types';

type Props = {
  insurancePlans: InsurancePlan[];
  context: IInsurancesContext;
  mode: Mode;
  isEditable: boolean;
  planType: string;
};

const SortableContainer = ({
  context,
  children,
  planType,
}: {
  context: IInsurancesContext;
  children: JSX.Element;
  planType: string;
}) => {
  const { replaceInsurancePlan, formGroup } = context;
  const selectedInsurancePlans = formGroup.values?.insurancePlans[planType] || [];
  const onDragEnd = (event) => {
    const { active, over } = event;
    if (active.id === over.id) {
      return;
    }

    const oldIndex = selectedInsurancePlans.findIndex((item) => item.id === active.id);
    const newIndex = selectedInsurancePlans.findIndex((item) => item.id === over.id);
    const sortedList = arrayMove(selectedInsurancePlans, oldIndex, newIndex).map((item: any, index) => {
      item.order = index + 1;

      return item;
    });

    replaceInsurancePlan(sortedList, planType);
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd}>
      <SortableContext items={selectedInsurancePlans.map((item) => item.id!)} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export const PlanList = ({ insurancePlans, context, mode, isEditable, planType }: Props) => {
  return (
    <>
      {isEditable ? (
        <SortableContainer context={context} planType={planType}>
          <>
            {insurancePlans?.map((plan, index) => (
              <PlanItem
                key={plan.id}
                plan={plan}
                index={index}
                context={context}
                mode={mode}
                isEditable={isEditable}
                planType={planType}
              />
            ))}
          </>
        </SortableContainer>
      ) : (
        <>
          {insurancePlans.map((plan, index) => (
            <PlanItem
              key={plan.id}
              plan={plan}
              index={index}
              context={context}
              mode={mode}
              isEditable={isEditable}
              planType={planType}
            />
          ))}
        </>
      )}
    </>
  );
};

export default PlanList;
