import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import PagesHeader from '../../../../../../components/base/PagesHeader';
import useProfileForm from '../../../hooks/useProfileForm';
import { deepClone, uniqueBy } from '../../../../../../util/util';
import { INITIAL_ACCESS_CATEGORY } from '../../../constants';
import { AccountAccessLevel } from '../../../types';

import themeStyle from '../../../../../../assets/scss/6-pages/superAdmin/settings/profile.module.scss';

type Props = {};

const AccessLevels = (props: Props) => {
  const { t } = useTranslation();
  const { profileFormVal } = useProfileForm();
  const accessLevels = profileFormVal.accessLevels || deepClone(INITIAL_ACCESS_CATEGORY);

  const serializeRoles = (roles: AccountAccessLevel['role']) => {
    const roleText = uniqueBy(roles).join(', ');

    return roleText;
  };

  if (
    !accessLevels.companyLevel.length &&
    !accessLevels.accountingLevel.length &&
    !accessLevels.correcto_level.length
  ) {
    return <></>;
  }

  return (
    <div className="mt-4">
      <PagesHeader pageTitle="Access Levels" headingClass="fs-h2 font-black" />

      {accessLevels.companyLevel.length > 0 && (
        <div className="acl-sec">
          <h3 className="fs-h3 font-black">{t('PERSONAL_PROFILE.company_level')}</h3>
          <div className="table-responsive react-bootstrap-table">
            <table className="table pension-manager-table">
              <thead className="header-wrapper">
                <tr>
                  <th className={themeStyle.company_col}>{t('company')}</th>
                  <th className={themeStyle.org_col}>{t('PERSONAL_PROFILE.org_number')}</th>
                  <th className={themeStyle.work_email_col}>{t('PERSONAL_PROFILE.work_email')}</th>
                  <th className={themeStyle.work_phone_col}>{t('PERSONAL_PROFILE.work_phone')}</th>
                  <th>{t('PERSONAL_PROFILE.role(s)')}</th>
                </tr>
              </thead>
              <tbody>
                {accessLevels.companyLevel.map((item, index) => {
                  return (
                    <tr key={`companyLevel-${index}`} className="highlight-inactive highlight-inactive--hover">
                      <td>
                        <Form.Control value={item.company.name} disabled />
                      </td>
                      <td>
                        <Form.Control value={item.organizationNumber} disabled />
                      </td>
                      <td>
                        <div>
                          <Form.Control className="mb-2" value={''} disabled />
                          <Form.Check
                            label={t('PERSONAL_PROFILE.notifications_by_email')}
                            inline
                            type="checkbox"
                            className="fs-name"
                            onChange={(e) => {}}
                            checked={true}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <Form.Control className="mb-2" value={''} disabled />
                          <Form.Check
                            label={t('PERSONAL_PROFILE.notifications_by_sms')}
                            inline
                            type="checkbox"
                            className="fs-name"
                            onChange={(e) => {}}
                            checked={true}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="role-list">{serializeRoles(item.role)}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {accessLevels.accountingLevel.length > 0 && (
        <div className="acl-sec mt-4">
          <h3 className="fs-h3 font-black">{t('PERSONAL_PROFILE.accounting_firm_level')}</h3>
          <div className="table-responsive react-bootstrap-table">
            <table className="table pension-manager-table">
              <thead className="header-wrapper">
                <tr className="highlight-inactive highlight-inactive--hover">
                  <th className={themeStyle.company_col}>{t('company')}</th>
                  <th className={themeStyle.org_col}>{t('PERSONAL_PROFILE.org_number')}</th>
                  <th className={themeStyle.work_email_col}>{t('PERSONAL_PROFILE.work_email')}</th>
                  <th className={themeStyle.work_phone_col}>{t('PERSONAL_PROFILE.work_phone')}</th>
                  <th>{t('PERSONAL_PROFILE.role(s)')}</th>
                </tr>
              </thead>
              <tbody>
                {accessLevels.accountingLevel.map((item, index) => {
                  return (
                    <tr key={`accountingLevel-${index}`}>
                      <td>
                        <Form.Control value={item.company.name} disabled />
                      </td>
                      <td>
                        <Form.Control value={item.organizationNumber} disabled />
                      </td>
                      <td>
                        <div>
                          <Form.Control className="mb-2" value={''} disabled />
                          <Form.Check
                            label={t('PERSONAL_PROFILE.notifications_by_email')}
                            inline
                            type="checkbox"
                            className="fs-name"
                            onChange={(e) => {}}
                            checked={true}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <Form.Control className="mb-2" value={''} disabled />
                          <Form.Check
                            label={t('PERSONAL_PROFILE.notifications_by_sms')}
                            inline
                            type="checkbox"
                            className="fs-name"
                            onChange={(e) => {}}
                            checked={true}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="role-list">{serializeRoles(item.role)}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {accessLevels.correcto_level.length > 0 && (
        <div className="acl-sec mt-4">
          <h3 className="fs-h3 font-black">{t('PERSONAL_PROFILE.correcto_level')}</h3>
          <div className="table-responsive react-bootstrap-table">
            <table className="table table-hover table-condensed pension-manager-table">
              <thead className="header-wrapper">
                <tr>
                  <th className={themeStyle.company_col}>{t('company')}</th>
                  <th className={themeStyle.org_col}>{t('PERSONAL_PROFILE.org_number')}</th>
                  <th className={themeStyle.work_email_col}>{t('PERSONAL_PROFILE.work_email')}</th>
                  <th className={themeStyle.work_phone_col}>{t('PERSONAL_PROFILE.work_phone')}</th>
                  <th>{t('PERSONAL_PROFILE.role(s)')}</th>
                </tr>
              </thead>
              <tbody className="remote-table-body">
                {accessLevels.correcto_level.map((item, index) => {
                  return (
                    <tr key={`correctoLevel-${index}`} className="highlight-inactive highlight-inactive--hover">
                      <td>
                        <Form.Control value={item.company.name} disabled />
                      </td>
                      <td>
                        <Form.Control value={item.organizationNumber} disabled />
                      </td>
                      <td>
                        <div>
                          <Form.Control className="mb-2" value={''} disabled />
                          <Form.Check
                            label={t('PERSONAL_PROFILE.notifications_by_email')}
                            inline
                            type="checkbox"
                            className="fs-name"
                            onChange={(e) => {}}
                            checked={true}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <Form.Control className="mb-2" value={''} disabled />
                          <Form.Check
                            label={t('PERSONAL_PROFILE.notifications_by_sms')}
                            inline
                            type="checkbox"
                            className="fs-name"
                            onChange={(e) => {}}
                            checked={true}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="role-list">{serializeRoles(item.role)}</div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessLevels;
