import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccessKey } from '../../../../assets/img/access-key.svg';
import { useContext } from 'react';
import { OverlayLoaderContext } from '../../../../contexts/OverlayLoaderContext';
import useCompanyRoleSwitcher from '../hooks/useCompanyRoleSwitcher';

export type Props = {
  id: string | any;
};

const AccessButton = ({ id }: Props) => {
  const { t } = useTranslation();
  const { setBusy } = useContext(OverlayLoaderContext);
  const { switchToCompanyAdmin } = useCompanyRoleSwitcher({ setBusy, id });

  const switchCompanyLogin = async () => {
    setBusy(true);

    switchToCompanyAdmin();
  };
  return (
    <Button className="app-btn-primary" onClick={() => switchCompanyLogin()}>
      <AccessKey className="access-icon  access-icon--white icon-gap-right" />
      {t('GLOBAL.remote_access')}
    </Button>
  );
};

export default AccessButton;
