import { getStandardHolidayPay } from '../../../api/service/superAdmin/pension-policies/holiday-setup.service';
import { Calendar } from '../../../util/calendar';
import { deepClone, generateNumberList } from '../../../util/util';
import {
  EmployeeSalaryChangeInputType,
  InsuranceType,
  PensionPolicyCategory,
  PensionPolicyTabKey,
  PolicySpecialCondition,
  SalaryIntervalAgeType,
  SalaryIntervalAmountIndexType,
  SalaryIntervalOffserCalcType,
  TypeSalaryWithHolidays,
} from './types';

export const INITAL_ADMIN_POLICY_FORM = {
  policyName: '',
  policyDescription: '',
  version: '',
  employeeType: '',
  effectiveFrom: Calendar.currentMonthStartDate,
  isCollectiveAgreementLock: false,
  type: '',
  status: '',
  isAutoAssign: false,
};

export const INITIAL_COMPENSATION_PLAN = {
  salaryInterval: '',
  totalSalary: {
    percentageValue: '',
    fixedValue: '0',
  },
  partsUnder: {
    percentageValue: '0',
    fixedValue: '0',
  },
  partsOver: {
    percentageValue: '0',
    fixedValue: '0',
  },
};

export const INITIAL_POLICY_SPECIAL_CONDITION: PolicySpecialCondition = {
  probationaryEmployement: {
    isChecked: false,
    isPremiumCalculation: false,
    isSalaryChange: false,
    isInsurances: false,
  },
  parentalLeave: {
    isChecked: false,
    isPremiumCalculation: false,
    isSalaryChange: false,
    isInsurances: false,
    maximumLength: null,
  },
  otherLeave: {
    isChecked: false,
    isPremiumCalculation: false,
    isSalaryChange: false,
    isInsurances: false,
    maximumLength: null,
  },
};

export const DEFAULT_COMPENSATION_INCREASED_OFFSET = 5.8;

export const INITIAL_OCCUPATIONAL_PENSION = {
  isIntervalCompensation: false,
  percentageCompensation: null,
  fixedCompensation: null,
  isPremium: false,
  amountIndex: SalaryIntervalAmountIndexType.IBB,
  offsetCalculation: SalaryIntervalOffserCalcType.PERCENT,
  ageDefination: SalaryIntervalAgeType.FROM_MONTH,
  calculation: [],
};

export const INITIAL_SALARY_CHNAGE = {
  isAllowed: false,
  fixedMonthlyOffset: {
    offset: 0,
    effectiveFrom: '',
    isEditAllowed: false,
    isChecked: false,
  },
  percentageOfSalaryOffset: {
    offset: 0,
    effectiveFrom: '',
    isEditAllowed: false,
    isChecked: false,
  },
  manualOffset: {
    isManualOffsetAllowed: false,
    isChecked: false,
    offset: 0,
    effectiveFrom: '',
  },
  compensationWithIncreasedOffset: {
    offsetAddition: DEFAULT_COMPENSATION_INCREASED_OFFSET,
    isChecked: false,
  },
  isCheckedSepratePensionManagerPlans: false,
};

export const DEFAULT_HOLIDAY_SALARY_CODE_ID = 'Monthly gross salary';
export const DEFAULT_HOLIDAY_SETUP_NAME = 'Vacation days, setup';
export const DEFAULT_HOLIDAY_PER_YEAR = 25;
export const DEFAULT_HOLIDAY_PAY_MULTIPLE = getStandardHolidayPay(DEFAULT_HOLIDAY_PER_YEAR, 12)!;

export const INITIAL_SALARY_TYPE = deepClone({
  isTemplateCalculation: false,
  salaryTypes: [],
  salaryTypesWithHolidays: [
    {
      isEnable: true,
      setupName: `${DEFAULT_HOLIDAY_SETUP_NAME} 1`,
      holidayDaysPerYear: DEFAULT_HOLIDAY_PER_YEAR,
      data: [
        deepClone({
          salaryType: {
            name: DEFAULT_HOLIDAY_SALARY_CODE_ID,
          },
          holidayPayMultiple: DEFAULT_HOLIDAY_PAY_MULTIPLE,
        }),
      ],
    },
    /* {
      isEnable: false,
      setupName: 'Vacation days, setup 2',
      holidayDaysPerYear: 28,
      data: [
        {
          salaryType: {
            name: DEFAULT_HOLIDAY_SALARY_CODE_ID,
          },
          holidayPayMultiple: 13.44,
        },
      ],
    },
    {
      isEnable: false,
      setupName: 'Vacation days, setup 3',
      holidayDaysPerYear: 30,
      data: [
        {
          salaryType: {
            name: DEFAULT_HOLIDAY_SALARY_CODE_ID,
          },
          holidayPayMultiple: 14.4,
        },
      ],
    }, */
  ],
});

export const INITIAL_HOILDAY_SALARY_TYPE = {
  salaryType: {
    name: DEFAULT_HOLIDAY_SALARY_CODE_ID,
  },
  holidayPayMultiple: DEFAULT_HOLIDAY_PAY_MULTIPLE,
};

export const INITIAL_HOLIDAY_SALARY_SETUP: TypeSalaryWithHolidays = {
  isEnable: true,
  setupName: DEFAULT_HOLIDAY_SETUP_NAME,
  holidayDaysPerYear: DEFAULT_HOLIDAY_PER_YEAR,
  data: [INITIAL_HOILDAY_SALARY_TYPE],
};

export const INITIAL_SALARY_TYPE_PENSION_POLICY = {
  isPensionable: false,
  data: [],
};

export const INITIAL_SALARY_SETUP = {
  isPensionable: true,
  data: [],
};

export const INITIAL_PENSION_MANAGER_CUSTOM_DETAILS = {
  employeeRightToEditChecked: false,
};

export const INITIAL_INSURANCE_CUSTOM_DETAILS = {
  payInsuranceParental: false,
  payInsuranceOtherLeave: false,
};

export const INITIAL_EMPLOYEE_POLICY_SETTINGS = {
  pensionManager: {
    effectiveFrom: '',
  },
  insurance: {
    effectiveFrom: '',
  },
};

export const INITIAL_VALUE_PENSION_POLICY = {
  policyName: '',
  version: '',
  employeeType: '',
  policyDescription: '',
  effectiveFrom: Calendar.currentMonthStartDate,
  status: 'InActive',
  type: 'Company',
  isCollectiveAgreementLock: false,
  policySettings: {
    occupationalPension: INITIAL_OCCUPATIONAL_PENSION,
    salaryChange: INITIAL_SALARY_CHNAGE,
    salaryType: INITIAL_SALARY_TYPE,
    pensionManagers: [],
    insurances: [],
    pensionManagerConfig: INITIAL_PENSION_MANAGER_CUSTOM_DETAILS,
    specialConditions: INITIAL_POLICY_SPECIAL_CONDITION,
    employeeMetaSettings: null,
  },
};

export const INSURANCE_CATEGORIES = [
  { label: 'Health Insurance', key: InsuranceType.HEALTH },
  { label: 'Occupational Pension Insurance', key: InsuranceType.OCCUPATIONAL },
  { label: 'Other Insurances', key: InsuranceType.OTHER },
  { label: 'Individual Insurances', key: InsuranceType.INDIVIDUAL },
  { label: 'Group Insurance', key: InsuranceType.GROUP },
];

export const INSURANCE_CONDITION = [
  { label: 'Able-bodied', key: 'able-bodied', lang: 'able-bodied' },
  { label: 'Disabled', key: 'disabled', lang: 'disabled' },
];

export const SALARY_AMOUNT_INDEX_OPTIONS = [
  { label: 'IBB', key: SalaryIntervalAmountIndexType.IBB },
  { label: 'PBB', key: SalaryIntervalAmountIndexType.PBB },
];

export const SALARY_INTERVAL_AGE_DEFINITION_OPTIONS = [
  {
    label: 'From year of age',
    translationKey: 'PENSION_POLICY.from_year_of_age',
    key: SalaryIntervalAgeType.FROM_YEAR,
  },
  {
    label: 'From month of age',
    translationKey: 'PENSION_POLICY.from_month_of_age',
    key: SalaryIntervalAgeType.FROM_MONTH,
  },
];

export const SALARY_INTERVAL_OFFSET_OPTIONS = [{ label: '%', key: SalaryIntervalOffserCalcType.PERCENT }];

export enum SalaryChangeInputType {
  FIXED_MONTHLY_OFFSET = 'fixedMonthlyOffset',
  MANUAL_OFFSET = 'manualOffset',
  PERCENTAGE_OF_SALARY_OFFSET = 'percentageOfSalaryOffset',
  COMPENSATION_WITH_INCREASED_OFFSET = 'compensationWithIncreasedOffset',
  IS_CHECKED_SEPRATE_PENSION_MANAGER_PLANS = 'isCheckedSepratePensionManagerPlans',
}

export const EDITABLE_EMPLOYEE_SALARY_CHANGE_INPUT = [
  SalaryChangeInputType.FIXED_MONTHLY_OFFSET,
  SalaryChangeInputType.MANUAL_OFFSET,
  SalaryChangeInputType.PERCENTAGE_OF_SALARY_OFFSET,
];

export const EMPLOYEE_SALARY_CHANGE_MAPPED_INPUT = [
  {
    key: EmployeeSalaryChangeInputType.FIXED_MONTHLY_OFFSET,
    mapTo: SalaryChangeInputType.FIXED_MONTHLY_OFFSET,
  },
  {
    key: EmployeeSalaryChangeInputType.MANUAL_OFFSET,
    mapTo: SalaryChangeInputType.MANUAL_OFFSET,
  },
  {
    key: EmployeeSalaryChangeInputType.PERCENTAGE_OF_SALARY_OFFSET,
    mapTo: SalaryChangeInputType.PERCENTAGE_OF_SALARY_OFFSET,
  },
];

export const DEFAULT_POLICY_CATEGORY = PensionPolicyCategory.NO_POLICY;

export const INITIAL_EMPLOYEE_PENSION = {
  currentPolicy: {
    id: '',
    type: '',
    attachedAt: '',
  },
  employeeId: '',
  effectiveFrom: '',
  policySettings: {
    salaryChange: {
      fixedMonthlyOffset: {
        offset: 0,
        effectiveFrom: '',
      },
      percentageOfSalaryOffset: {
        offset: 0,
        effectiveFrom: '',
      },
      manualOffset: {
        offset: 0,
        effectiveFrom: '',
      },
    },
    pensionManagers: [],
    insurances: [],
    employeeMetaSettings: {
      pensionManager: {
        effectiveFrom: '',
      },
      insurance: {
        effectiveFrom: '',
      },
    },
  },
};

export const PENSION_POLICY_TABS = [
  PensionPolicyTabKey.OCCUPATIONAL,
  PensionPolicyTabKey.SALARY_CHANGE,
  PensionPolicyTabKey.SALARY_TYPE,
  PensionPolicyTabKey.PENSION_MANAGER,
  PensionPolicyTabKey.INSURANCE,
];

export const DEFAULT_LEAVE_DAY = 365;

export const CHILD_LEAVE_OPTIONS = generateNumberList(1, 365);

export const VACATION_CUT_OF_NEXT_MONTH_DATE = 3;

export const DEFAULT_CALCULATION_MATRIX_LENGTH = [4, 4];
