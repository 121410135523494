import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import PagesHeader from '../../../../../components/base/PagesHeader';
import useSpecialConditions from './useSpecialConditions';
import ParentalLeave from './ParentalLeave';
import OtherAbsenceLeave from './OtherAbsenceLeave';
import styles from '../../../../../assets/scss/6-pages/superAdmin/pension-policy/SpecialCondition.module.scss';

const SpecialConditions = () => {
  const { t } = useTranslation();
  const { probationaryEmployement, handleToggleProbationaryEmployeePolicy } = useSpecialConditions();

  return (
    <>
      <div className="mt-4 mb-3">
        <PagesHeader pageTitle={t('PENSION_POLICY.special_conditions')} translate={false} biggerHeading={true} />
      </div>
      <p className="text-black">{t('PENSION_POLICY.select_policy_conditions')}</p>
      <div className={classNames('mb-3 parental-check-group', styles.sp_cond_group)}>
        <PagesHeader pageTitle={t('PENSION_POLICY.probationary_employment')} translate={false} />
        <div className="d-flex align-items-end dark-content-section">
          <Container fluid>
            <Row className="w-100">
              <Col>
                <Form.Group className="pe-0">
                  <Form.Check
                    inline
                    type="checkbox"
                    label={`${t('PENSION_POLICY.fixed_probationary_employment')}`}
                    id="probationaryisChecked"
                    className="fs-name mb-1"
                    onChange={(e) => handleToggleProbationaryEmployeePolicy('isChecked', e.target.checked)}
                    name="probationaryisChecked"
                    defaultChecked={probationaryEmployement.isChecked}
                    checked={probationaryEmployement.isChecked}
                  />
                </Form.Group>
              </Col>

              <Col sm="7">
                {probationaryEmployement?.isChecked && (
                  <>
                    <p className="fs-name">{t('PENSION_POLICY.policy_parts_included')}</p>

                    <div className={styles.sp_cond_parts}>
                      <div className={styles.sp_cond_parts__type}>
                        <Form.Group className="pe-0">
                          <Form.Check
                            inline
                            type="checkbox"
                            label={t('PENSION_POLICY.premium_calculation')}
                            id="probationaryisPremiumCalculation"
                            className={classNames('fs-name mb-1', styles.include_part)}
                            onChange={(e) =>
                              handleToggleProbationaryEmployeePolicy('isPremiumCalculation', e.target.checked)
                            }
                            name="probationaryisPremiumCalculation"
                            defaultChecked={probationaryEmployement.isPremiumCalculation}
                            checked={probationaryEmployement.isPremiumCalculation}
                          />
                        </Form.Group>
                      </div>

                      <div className={styles.sp_cond_parts__type}>
                        <Form.Group className="pe-0">
                          <Form.Check
                            inline
                            type="checkbox"
                            label={t('PENSION_POLICY.applicable_salary_change')}
                            id="probationaryisSalaryChange"
                            className={classNames('fs-name mb-1', styles.include_part)}
                            onChange={(e) => handleToggleProbationaryEmployeePolicy('isSalaryChange', e.target.checked)}
                            name="probationaryisSalaryChange"
                            defaultChecked={probationaryEmployement.isSalaryChange}
                            checked={probationaryEmployement.isSalaryChange}
                          />
                        </Form.Group>
                      </div>

                      <div className={styles.sp_cond_parts__type}>
                        <Form.Group className="pe-0">
                          <Form.Check
                            inline
                            type="checkbox"
                            label={t('insurances')}
                            id="probationaryisInsurances"
                            className={classNames('fs-name mb-1', styles.include_part)}
                            onChange={(e) => handleToggleProbationaryEmployeePolicy('isInsurances', e.target.checked)}
                            name="probationaryisInsurances"
                            defaultChecked={probationaryEmployement.isInsurances}
                            checked={probationaryEmployement.isInsurances}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <ParentalLeave />
      <OtherAbsenceLeave />
    </>
  );
};

export default SpecialConditions;
