import { ColumnDescription } from 'react-bootstrap-table-next';
import { AlertTriangle } from 'react-feather';
import { sortCaret } from '../../components/table/formatters';
import { tableHeaderText } from '../../components/table/TableHeaderFormatter';
import { numberFormat } from '../../util/util';
import { ReactComponent as ScheduleIcon } from '../../assets/img/scheduled.svg';
import useLocalizationResolver from '../../hooks/useLocalizationResolver';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InactiveFilterIcon } from '../../assets/img/inactive-filter.svg';
import TooltipBox from '../../components/base/Overlay/TooltipBox';

const EmployeementTypeColumn = ({ row }: { row: any }) => {
  const { getLang } = useLocalizationResolver();
  return <>{getLang(row.personnelType)}</>;
};

const ScheduleIconIndicator = () => {
  const { t } = useTranslation();

  return (
    <TooltipBox msg={t('scheduled_changes')!}>
      <ScheduleIcon className="crt-icon" />
    </TooltipBox>
  );
};

const InactiveIconIndicator = () => {
  const { t } = useTranslation();

  return (
    <TooltipBox msg={t('inactive')!}>
      <InactiveFilterIcon />
    </TooltipBox>
  );
};

export const useColumn = () => {
  const employeeColumn: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Name'),
      dataField: 'fullName',
      sort: true,
      sortCaret,
      headerStyle: { width: 180 },
    },
    {
      text: tableHeaderText('Personal no'),
      dataField: 'personalNumber',
      // headerFormatter: tableHeaderFormatter,
      sort: true,
      sortCaret,
      headerStyle: { width: 120 },
    },
    /* {
      text: 'Contact info',
      dataField: 'email',
      formatter: (cell) => {
        const onMailClick = () => window.open(`mailto:${cell}`, '_blank');
        const onPhoneClick = () => window.open(`tel:1234123123`, '_blank');

        return (
          <>
            <Phone onClick={onPhoneClick} className="cursor-pointer me-3" width={17} />
            <Mail onClick={onMailClick} className="cursor-pointer" width={17} />
          </>
        );
      },
      headerStyle: { width: 115 },
    }, */
    {
      text: tableHeaderText('Employment type'),
      dataField: 'personnelType',
      headerStyle: { width: 180 },
      formatter: (_, row) => {
        return (
          <>
            <EmployeementTypeColumn row={row} />
          </>
        );
      },
    },
    // {
    //   text: 'Date of employment',
    //   dataField: 'employmentDate',
    //   headerFormatter: tableHeaderFormatter,

    //   formatter: (cell) => cell.split('T')[0] ?? '',
    //   sort: true,
    //   sortCaret,
    // headerStyle: () => {
    //   return { width: 80 };
    // },
    // },
    {
      text: tableHeaderText('Pension policy'),
      dataField: 'pensionPolicy',
      formatter: (cell, row) => {
        return row?.pensionPolicy?.policyName || '';
      },
      sort: false,
      sortCaret,
      headerStyle: { width: 180 },
    },
    {
      text: tableHeaderText('Salary'),
      dataField: 'salary',
      sort: true,
      sortCaret,
      formatter: (cell) => numberFormat(cell ?? 0, { decimal: 0 }),
      headerStyle: { width: 80 },
      classes: 'text-right',
    },
    {
      text: tableHeaderText('Fixed Offset Salary Change'),
      dataField: 'salaryChange',
      sort: true,
      sortCaret,
      formatter: (cell) => numberFormat(cell, { decimal: 0 }),
      headerStyle: { width: 122 },
      classes: 'text-right',
    },
    {
      text: tableHeaderText('Paid Vacation Days'),
      dataField: 'vacation',
      sort: false,
      headerStyle: { width: 105 },
      classes: 'text-right',
      formatter: (cell, row) => {
        return cell?.current?.paidHolidays || null;
      },
    },
    {
      text: tableHeaderText('Pension cost'),
      dataField: 'pensionCost',
      formatter: (cell) => numberFormat(cell, { decimal: 0 }),
      headerStyle: { width: 95 },
      classes: 'text-right',
    },
    {
      dataField: 'invalid',
      text: '',
      align: 'right',
      formatter: (_cell, row) => {
        if (row.importInformation.activeOnEMS === false) {
          return <InactiveIconIndicator />;
        }

        if (row.invalid) {
          return <AlertTriangle className="employee-invalid-icon" />;
        }

        if (row.isCurrent === false) {
          return <ScheduleIconIndicator />;
        }

        return <></>;
      },
    },
  ];

  return { employeeColumn };
};
