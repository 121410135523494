import { Form } from 'react-bootstrap';
import { Info } from 'react-feather';
import { t } from 'i18next';

import MonthAndYearDropdown from '../../../../../section/MonthAndYearDropdown';
import usePensionSchedule from '../../../../company/Employees/hooks/usePensionSchedule';
import usePolicyMode from '../../hooks/usePolicyMode';
import useContext from '../../PensionPolicyContext/useContext';
import useEmployeeInsuranceEffectiveDate from './useEmployeeInsuranceEffectiveDate';
import useLocale from '../../../../../hooks/useLocale';
import { Calendar } from '../../../../../util/calendar';

const InsuranceEffectiveDate = () => {
  const { localize } = useLocale();
  const { externalPolicyRevision } = useContext();

  const {
    isModifyEffectiveDate,
    defaultStartEffectiveDate,
    updateEmployeePensionSettings,
    getEmployeePensionSettings,
  } = useEmployeeInsuranceEffectiveDate();

  const { isCompanyEmployeeReadOnlyMode, isCompanyEmployeeEditableMode, isCompanyEmployeeScheduledMode } =
    usePolicyMode();

  const { hasShownScheduledEffectiveDateNotifier } = usePensionSchedule();

  const hasShownScheduledNotifier = () => {
    return (
      isCompanyEmployeeScheduledMode() &&
      hasShownScheduledEffectiveDateNotifier(getEmployeePensionSettings('effectiveFrom'))
    );
  };

  return (
    <>
      {isCompanyEmployeeEditableMode() && (
        <div className="employee-section mt-3">
          <Form.Group style={{ width: 220 }}>
            <Form.Label className="fs-name">{t('PENSION_POLICY.insurance_settings_effective_from')}</Form.Label>
            <MonthAndYearDropdown
              startFrom={externalPolicyRevision?.effectiveFrom}
              value={getEmployeePensionSettings('effectiveFrom') || ''}
              onOptionSelect={(val) => {
                updateEmployeePensionSettings('effectiveFrom', val);
              }}
              disabled={!isModifyEffectiveDate}
              customOptions={Calendar.generateEffectiveDateOption(defaultStartEffectiveDate())}
            />
          </Form.Group>
        </div>
      )}
      {isCompanyEmployeeReadOnlyMode() && (
        <div className="employee-section mt-3">
          <Form.Group style={{ width: 220 }}>
            <Form.Label className="fs-name">{localize('PENSION_POLICY.insurance_settings_effective_from')}</Form.Label>
            {hasShownScheduledNotifier() && (
              <div className="p-2 mb-2 d-inline-flex align-items-center alert alert-info theme-text">
                <Info className="icon-gap-right" />
                <span className="fs-name">{localize('scheduled')}</span>
              </div>
            )}
            <MonthAndYearDropdown
              startFrom={externalPolicyRevision?.effectiveFrom}
              value={getEmployeePensionSettings('effectiveFrom') || ''}
              disabled
            />
          </Form.Group>
        </div>
      )}
    </>
  );
};

export default InsuranceEffectiveDate;
