import moment from 'moment';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useTranslation } from 'react-i18next';
import { sortCaret } from '../../../components/table/formatters';
import { tableHeaderText } from '../../../components/table/TableHeaderFormatter';
import classNames from 'classnames';

export const useColumn = () => {
  const { t } = useTranslation();
  const personalClientColumn: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Name'),
      dataField: 'fullName',
      sort: true,
      sortCaret,
      headerStyle: { width: 180 },
    },
    {
      text: tableHeaderText('Personal number'),
      dataField: 'personalNumber',
      sort: true,
      sortCaret,
      headerStyle: { width: 150 },
    },
    {
      text: tableHeaderText('Contract number'),
      dataField: 'contractNumber',
      sort: true,
      sortCaret,
      formatter: () => 30292,
      headerStyle: { width: 150 },
    },
    {
      text: tableHeaderText('Status'),
      dataField: 'status',
      sort: true,
      sortCaret,
      formatter: (cell) => {
        return (
          <span
            className={classNames('fs-text-400', { 'theme-text': cell === 'Draft', 'active-green': cell !== 'Draft' })}
          >
            {t(cell.toLowerCase())}
          </span>
        );
      },
      headerStyle: { width: 90 },
    },
    {
      text: tableHeaderText('Creation date'),
      dataField: 'createdAt',
      sort: true,
      sortCaret,
      formatter: (cell) => moment(cell).format('YYYY-MM-DD'),
      headerStyle: { width: 110 },
    },
    {
      text: tableHeaderText('Last edit'),
      dataField: 'updatedAt',
      sort: true,
      sortCaret,
      formatter: (cell) => moment(cell).format('YYYY-MM-DD HH:mm'),
      headerStyle: { width: 130 },
    },
  ];

  return { personalClientColumn };
};
