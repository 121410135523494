import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { ReactComponent as BankIDIcon } from '../../assets/img/auth/bank-id.svg';
import { ReactComponent as MobileIDIcon } from '../../assets/img/auth/mobile-id.svg';
import useAuthSessionPolling from './useAuthSessionPolling';
import useAuthenticator from './useAuthenticator';
import { GrandIDAuthStatus, UserRole } from '../../types/auth';
import MobileVerification from './MobileVerification';
import DesktopVerification from './DesktopVerification';
import { ChevronRight } from 'react-feather';

const AdminSignIn = () => {
  const {
    isLoading,
    sessionID,
    signInAction,
    errorMsg,

    fetchVerifiedUserDetails,
    onInitSession,
    onRetrySignIn,
    isSingingByMobile,
    isSingingByDesktop,
    clear,
  } = useAuthenticator({ pageRoleType: UserRole.SUPER_ADMIN });

  const { verificationStatus, signInScanCode } = useAuthSessionPolling({
    session: sessionID,
    method: signInAction?.method,
    onCompleteVerification: fetchVerifiedUserDetails,
  });

  return (
    <div>
      {isLoading ? (
        <div className="mt-4 text-center">
          <Spinner className="signin-loader" animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="mt-3">
          {errorMsg && (
            <>
              <Alert variant="danger" className="mt-3 p-2">
                {errorMsg}
              </Alert>
              <div className="mt-1">
                <Button
                  onClick={() => clear()}
                  style={{ textAlign: 'left', display: 'flex', gap: '10px' }}
                  className="app-btn-primary"
                >
                  <ChevronRight /> Try again
                </Button>
              </div>
            </>
          )}
          {isSingingByMobile() && verificationStatus !== GrandIDAuthStatus.COMPLETED && (
            <MobileVerification
              signInScanCode={signInScanCode}
              verificationStatus={verificationStatus!}
              onRetrySignIn={onRetrySignIn}
            />
          )}
          {isSingingByDesktop() && verificationStatus !== GrandIDAuthStatus.COMPLETED && (
            <DesktopVerification verificationStatus={verificationStatus!} onRetrySignIn={onRetrySignIn} />
          )}
          {!signInAction && (
            <Form>
              <Form.Group className="mt-4 m-auto  text-left">
                <Form.Label className="fs-4 fw-bold font-black">Sign in to Correcto</Form.Label>
                <div className="mb-2">
                  <Button
                    onClick={() => onInitSession('mobile')}
                    style={{ textAlign: 'left', display: 'flex', gap: '10px' }}
                    className="app-btn-primary"
                    disabled={isLoading}
                  >
                    <MobileIDIcon /> Mobilt BankID
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => onInitSession('desktop')}
                    style={{ textAlign: 'left', display: 'flex', gap: '10px', color: '#000' }}
                    className="app-btn-secondary gap-10"
                    disabled={isLoading}
                  >
                    <BankIDIcon /> BankID (på denna enhet)
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminSignIn;
