import moment from 'moment';

export interface IYearMonth {
  year: number;
  month: number;
  instance?: moment.Moment;
}
export interface IDatasource {
  headers: Array<IHeaderRow>;
  reports: Array<IYearlySource>;
  aggregates?: IDatasourceSummary[];
}

export interface IHeaderRow {
  title: string;
  name: string;
}

export interface IYearlySource {
  year: number;
  dataset: Array<{
    name: string;
    months: Array<IMonthlySource>;
  }>;
}

export interface IMonthlySource {
  monthIndex: number;
  value: number | null;
}

export interface MonthlyStepperFooterFooterProps {
  selectedSummaryTotals: IDatasourceSummary[];
  selectedHeaderMonths: any[];
  shouldRender?: boolean;
  actionType: StepperAction | null;
}

export interface IDatasourceSummary {
  value: any;
  monthIndex: number;
  year?: number;
  monthName?: string;
}

export type TypeInternalCalculations = {
  year: number;
  effectiveDate: string;
  specialPayrollTax: {
    value: number;
    unit: string;
  };
  priceBaseAmount: {
    value: number;
    unit: string;
  };
  salaryInterval: {
    value: number;
    unit: string;
  };
  priceBaseAmountPcs: {
    value: number;
    unit: string;
  };
  rightOfDeducation: {
    value: number;
    unit: string;
  };
  mainRulededuction: {
    value: number;
    unit: string;
  };
};

export type CalcType = {
  [key: number]: TypeInternalCalculations;
};

export type SelectedHeaderMonths = {
  year: string;
  monthIndex: number;
  monthName: string;
};

export type InputList = {
  name: string;
  value: number;
};

export type SelectedHeader = {
  year: number;
  monthIndex: number;
  inputs: InputList[];
};

export interface IYearMonthlyAggregate {
  year: number;
  numberOfMonths: number;
}

export enum StepperAction {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}
