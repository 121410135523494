import { API_VERSION, INSURANCE_ENDPOINT } from '../../../../util/constants';
import { HttpQuery } from '../../../../util/http-query';
import { del, get, post, put } from '../../../../util/httpService';

export const getInsurances = async (params = {}) => {
  let endpoint = HttpQuery.serialize(params, `${API_VERSION}${INSURANCE_ENDPOINT}`);

  const insuranceCompany = await get(endpoint);
  return insuranceCompany;
};

export const getInsurancesByAgreement = async (params = {}) => {
  let endpoint = HttpQuery.serialize(params, `${API_VERSION}${INSURANCE_ENDPOINT}/by/collectiveAgreement`);

  const insuranceCompany = await get(endpoint);
  return insuranceCompany;
};

export const createInsuranceCompany = async (payload) => {
  const insuranceCompany = await post(`${API_VERSION}${INSURANCE_ENDPOINT}`, payload, {});
  return insuranceCompany;
};

export const updateInsuranceCompany = async (id, payload) => {
  const insuranceCompany = await put(`${API_VERSION}${INSURANCE_ENDPOINT}/${id}`, payload, {});
  return insuranceCompany;
};

export const deleteInsuranceCompany = async (id) => {
  const insuranceCompany = await del(`${API_VERSION}${INSURANCE_ENDPOINT}/${id}`, {});
  return insuranceCompany;
};
export const getInsuranceCompanyDetail = async (id) => {
  const insuranceCompany = await get(`${API_VERSION}${INSURANCE_ENDPOINT}/${id}`);
  if (insuranceCompany.contactInformation) {
    return insuranceCompany;
  } else {
    return { ...insuranceCompany, contactInformation: {} };
  }
};

export const sortInsurancePlans = async (payload) => {
  const pensionCompany = await post(`${API_VERSION}${INSURANCE_ENDPOINT}/orders`, payload, {});
  return pensionCompany;
};
