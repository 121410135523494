/* eslint-disable react/prop-types */
import { ProgressBar } from 'react-bootstrap';
import { X } from 'react-feather';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';
import { useTranslation } from 'react-i18next';

const FileItem = ({ name, onRemove }) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex align-items-center justify-content-between my-2 pb-2 border-bottom mx-3">
      <p className="fs-text-400 m-0 theme-text">{name}</p>

      <div className="d-flex align-items-center">
        <ProgressBar style={{ width: 133, height: 5 }} className="progress-bg" now={100} />
        <TooltipBox msg={`${t('delete')}`}>
          <X onClick={onRemove} className="cursor-pointer ms-2 upload-section-cross-icon" width={15} />
        </TooltipBox>
      </div>
    </div>
  );
};

export default FileItem;
