import { Dispatch, SetStateAction, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Plus, Save } from 'react-feather';
import { IntegrationSaveMode, LooseObject, Mode } from '../../types';
import useLocale from '../../../../../hooks/useLocale';
//import useIntegrationEditor from '../useIntegrationEditor';
import useContext from '../context/useContext';
import { isObjectEmpty } from '../../../../../util/util';
import { useSuperAdminSettingsContext } from '../../useContext';
import { createIntegration } from '../../../../../api/service/superAdmin/settings/integrations';

type Props = {
  toggleModal: () => void;
  setErrors: Dispatch<SetStateAction<LooseObject>>;
};

export const CreateIntegrationModalFooter = ({ toggleModal, setErrors }: Props) => {
  const { localize } = useLocale();
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const [, /*isSaving*/ setIsSaving] = useState(false);
  const [, /*integrationSaveMode*/ setIntegrationSaveMode] = useState(IntegrationSaveMode.ACTIVE);
  const { setMode } = useSuperAdminSettingsContext();
  const { getRequestPayload, validateDraftForm, validateForm, formGroup } = useContext();

  const save = async (saveMode) => {
    setIntegrationSaveMode(saveMode);
    const isDraftMode = saveMode === IntegrationSaveMode.DRAFT;
    const payload = getRequestPayload({ status: saveMode });
    if (isDraftMode) {
      const formValidator = await validateDraftForm();
      if (!formValidator.valid) {
        setErrors({ error: 'Please fill in the required fields' });
        return false;
      }
    } else {
      validateForm().then((errors) => {
        if (!isObjectEmpty(errors)) {
          setErrors({ error: 'Please fill in the required fields' });
        }
      });
      if (!formGroup.isValid) {
        setIsBusy(false);
        setIsBusyDrafting(false);
        return false;
      }
    }

    try {
      setErrors({});
      setIsSaving(true);
      await createIntegration(payload);
      toggleModal();
      setMode(Mode.List);
    } catch (error) {
      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <Button className="app-btn-secondary cancel-btn" onClick={toggleModal}>
          {localize('cancel')}
        </Button>

        <div className="d-flex gap-3">
          <Button
            disabled={isBusy || isBusyDrafting}
            type="button"
            className="app-btn-primary"
            onClick={() => save(IntegrationSaveMode.DRAFT)}
          >
            {isBusyDrafting ? <Spinner className="icon-gap-right" size="sm" /> : <Save className="icon-gap-right" />}
            {`${localize('save')} ${localize('as')} ${localize('draft')}`}
          </Button>
          <Button
            disabled={isBusy || isBusyDrafting}
            type="button"
            className="app-btn-primary"
            onClick={() => save(IntegrationSaveMode.ACTIVE)}
          >
            {isBusy ? <Spinner className="icon-gap-right" size="sm" /> : <Plus className="icon-gap-right" />}
            {`${localize('add_integration')}`}
          </Button>
        </div>
      </div>
    </>
  );
};
