import { PensionPolicyCategory, PensionPolicyVariant } from '../../../superAdmin/PensionPolicy/types';
import { EmployeePensionRecord } from '../entity/EmployeePensionRecord';
import useEmployeePensionContext from '../useContext';
import useAssignEmployeePension from './useAssignEmployeePension';

const useEmployeePolicyLoaded = () => {
  const {
    setSelectedPensionPolicy,
    setSelectedEmployeePension,
    updatePolicyType,
    setEmployeePolicyRevision,
    setAdvisoryInvite,
    findPolicyType,
    setEffectiveFrom,
    setEmployeePensionSummary,

    selectedPensionPolicy,
    employeePensionSummary,
    selectedEmployeePension,
    formGroup,
  } = useEmployeePensionContext();

  const { updateAdminPolicyRevisionSettings } = useAssignEmployeePension();

  const loadDefaultEmployeePension = () => {
    setSelectedPensionPolicy(null);
    setSelectedEmployeePension(null);
    setEmployeePolicyRevision(null);
    updatePolicyType(null);
    setAdvisoryInvite(false);
  };

  const loadEmployeePensionDetails = (_policyDetails, _employeePensionSummary) => {
    if (!_employeePensionSummary || !_employeePensionSummary.current) {
      loadDefaultEmployeePension();

      return;
    }

    const employeeAssignedPolicy = EmployeePensionRecord.toPolicySettings(
      _employeePensionSummary.current,
      _employeePensionSummary.employeeId,
    );

    let choosenPolicyType = PensionPolicyCategory.NO_POLICY;

    if (employeeAssignedPolicy?.id) {
      choosenPolicyType = findPolicyType(employeeAssignedPolicy!.type!)!;
    }

    setSelectedPensionPolicy(_policyDetails);
    setSelectedEmployeePension(employeeAssignedPolicy);
    updatePolicyType(choosenPolicyType);

    if (employeeAssignedPolicy!.policySettings) {
      setEmployeePolicyRevision({
        ...employeeAssignedPolicy,
      });
    }
  };

  const revertPensionDetails = () => {
    if (!employeePensionSummary?.current) {
      return;
    }

    let choosenPolicyType = PensionPolicyCategory.NO_POLICY;
    const employeeAssignedPolicy = EmployeePensionRecord.toPolicySettings(
      employeePensionSummary!.current!,
      employeePensionSummary!.employeeId,
    );

    setEmployeePensionSummary({ ...employeePensionSummary });

    if (employeeAssignedPolicy?.id && selectedPensionPolicy) {
      choosenPolicyType = findPolicyType(selectedPensionPolicy!.type)!;

      setSelectedPensionPolicy({ ...selectedPensionPolicy });
    }

    if (choosenPolicyType !== PensionPolicyCategory.NO_POLICY) {
      updateAdminPolicyRevisionSettings(
        selectedEmployeePension.currentPolicy.id,
        selectedEmployeePension,
        selectedEmployeePension.currentPolicy.type === PensionPolicyVariant.PERSONAL ? 'personal' : 'admin',
      );

      setSelectedEmployeePension({ ...selectedEmployeePension });

      if (employeeAssignedPolicy!.policySettings) {
        setEmployeePolicyRevision({
          ...employeeAssignedPolicy,
        });
      }

      setAdvisoryInvite(false);
    } else {
      setSelectedPensionPolicy(selectedPensionPolicy);
      updateAdminPolicyRevisionSettings(selectedEmployeePension?.currentPolicy?.id, selectedEmployeePension, 'admin');
      setSelectedEmployeePension(selectedEmployeePension);

      if (employeeAssignedPolicy!.policySettings) {
        setEmployeePolicyRevision({
          ...employeeAssignedPolicy,
        });
        formGroup.setFieldValue('policySettings', employeeAssignedPolicy!.policySettings);
      }
    }

    setEffectiveFrom(employeePensionSummary.current!.effectiveFrom!);
    setAdvisoryInvite(employeePensionSummary?.current?.advisoryInvite || false);
    updatePolicyType(choosenPolicyType);
  };

  return {
    loadEmployeePensionDetails,
    loadDefaultEmployeePension,
    revertPensionDetails,
  };
};

export default useEmployeePolicyLoaded;
