
export const INITIAL_INTEGRATION_INFORMATION = {
    shortName: '',
    companyName: '',
    status: 'Active',
    instruction: '',

};
export const INITIAL_TYPE_OF_INTEGRATION = {
    companyApi: false,
    channel: '',
    apiKey: '',
    apiKey2: '',
};


export const FORM_INITIAL_VALUES = {
    integrationInformation: INITIAL_INTEGRATION_INFORMATION,
    typeOfIntegration: INITIAL_TYPE_OF_INTEGRATION,
};

