import BSModal from '../../../../../components/Modal';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';

import themeStyle from '../../../../../assets/scss/6-pages/superAdmin/pension-policy/cost-specification.module.scss';
import { CostSpecification } from '../../../AdminSettings/types';

type Props = {
  show: boolean;
  onHide?: () => void;
  toggleModal: () => void;
  costSpecDetails: CostSpecification | null;
  planName: string;
  managerName: string;
};

type FooterProps = {
  toggleModal: () => void;
  onHide?: () => void;
};

const Footer = ({ toggleModal, onHide }: FooterProps) => {
  return (
    <div className="d-flex justify-content-end w-100">
      <Button
        className="app-btn-secondary cancel-btn"
        onClick={() => {
          if (onHide) {
            onHide();
          }
          toggleModal();
        }}
      >
        Close
      </Button>
    </div>
  );
};

const CostSpecificationModal = ({ show, toggleModal, onHide, planName, managerName, costSpecDetails }: Props) => {
  const title = `${managerName}${managerName ? ' / ' : ''}${planName}`;

  return (
    <BSModal
      title={title}
      show={show}
      onHide={() => {
        if (onHide) {
          onHide();
        }
        toggleModal();
      }}
      centered
      className="chart-search-employee-modal"
      ModalFooter={<Footer toggleModal={toggleModal} onHide={onHide} />}
      size={'md'}
      backdrop="static"
    >
      <>
        <h2 className="fs-h2 theme-text-dark mb-3">Cost specification</h2>
        <div className="form-section mb-3 dark-content-section">
          <table className={classNames('table pension-manager-table', themeStyle.cost_spec_grid)}>
            <thead>
              <tr>
                <th>Specification</th>
                <th>Fee</th>
                <th>Exemption letter</th>
              </tr>
            </thead>
            <tbody>
              {costSpecDetails?.specItems?.length === 0 && (
                <tr>
                  <td colSpan={4}>No specification Found</td>
                </tr>
              )}
              {costSpecDetails?.specItems?.map((item, index) => {
                return (
                  <tr key={`spec.${index}`} className="highlight-inactive highlight-inactive--hover">
                    <td className={classNames(themeStyle.spec_name, 'theme-text-dark')}>{item.title}</td>
                    <td className={classNames(themeStyle.spec_fee)}>
                      <div>{item.fee}</div>
                    </td>
                    <td className={classNames(themeStyle.spec_letter)}>
                      <div>{item.exemptionLetter}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {costSpecDetails?.description && (
              <tfoot>
                <tr>
                  <td className={classNames(themeStyle.desc)} colSpan={4}>
                    <div
                      className="pt-2 theme-text-dark"
                      dangerouslySetInnerHTML={{
                        __html: costSpecDetails?.description!,
                      }}
                    />
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </>
    </BSModal>
  );
};

export default CostSpecificationModal;
