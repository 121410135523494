import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import PagesHeader from '../../../../../components/base/PagesHeader';

import styles from '../../../../../assets/scss/6-pages/superAdmin/pension-policy/SpecialCondition.module.scss';
import useSpecialConditionsViewer from './useSpecialConditionsViewer';
import { PolicyLeaveCategory } from '../../types';

const ParentalLeaveViewer = () => {
  const { t } = useTranslation();
  const { parentalLeave, isPolicyPartIncluded } = useSpecialConditionsViewer();

  return (
    <div className={classNames('mb-3 parental-check-group', styles.sp_cond_group)}>
      <PagesHeader
        pageTitle={t('PENSION_POLICY.parental_leave')}
        translate={false}
        subText={t('PENSION_POLICY.fixed_compensation_only')}
        subTextPosition="bottom"
      />
      <div className="d-flex align-items-end dark-content-section">
        <Container fluid>
          <Row className="w-100">
            <Col>
              <Form.Group className="pe-0">
                <Form.Check
                  inline
                  type="checkbox"
                  label={`${t('PENSION_POLICY.policy_valid_parental_leave')}`}
                  id="parentalLeaveIsChecked"
                  className="fs-name mb-1"
                  disabled
                  name="parentalLeaveisChecked"
                  defaultChecked={parentalLeave.isChecked}
                />
              </Form.Group>
            </Col>

            <Col sm="7">
              {isPolicyPartIncluded(PolicyLeaveCategory.PARENTAL, true) && (
                <p className="fs-name">{t('PENSION_POLICY.policy_parts_included')}</p>
              )}

              {isPolicyPartIncluded(PolicyLeaveCategory.PARENTAL) && (
                <div className={styles.sp_cond_parts}>
                  {parentalLeave.isPremiumCalculation && (
                    <div className={styles.sp_cond_parts__type}>
                      <Form.Group className="pe-0">
                        <Form.Check
                          inline
                          type="checkbox"
                          label={t('PENSION_POLICY.premium_calculation')}
                          id="parentalLeaveisPremiumCalculation"
                          className={classNames('fs-name mb-1', styles.include_part)}
                          disabled
                          name="parentalLeaveisPremiumCalculation"
                          defaultChecked={parentalLeave.isPremiumCalculation}
                        />
                      </Form.Group>
                    </div>
                  )}

                  {parentalLeave.isSalaryChange && (
                    <div className={styles.sp_cond_parts__type}>
                      <Form.Group className="pe-0">
                        <Form.Check
                          inline
                          type="checkbox"
                          label={t('PENSION_POLICY.applicable_salary_change')}
                          id="iparentalLeavesSalaryChange"
                          className={classNames('fs-name mb-1', styles.include_part)}
                          disabled
                          name="parentalLeaveisSalaryChange"
                          defaultChecked={parentalLeave.isSalaryChange}
                        />
                      </Form.Group>
                    </div>
                  )}

                  {parentalLeave.isInsurances && (
                    <div className={styles.sp_cond_parts__type}>
                      <Form.Group className="pe-0">
                        <Form.Check
                          inline
                          type="checkbox"
                          label={t('insurances')}
                          id="parentalLeaveisInsurances"
                          className={classNames('fs-name mb-1', styles.include_part)}
                          disabled
                          name="parentalLeaveisInsurances"
                          defaultChecked={parentalLeave.isInsurances}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>
              )}

              {parentalLeave.maximumLength && (
                <div className={styles.sp_other_opt}>
                  <Form.Group className={classNames(styles.child_leave_opt)}>
                    <Form.Label className="fs-name">
                      {`${t('PENSION_POLICY.max_length')} / ${t('child')}`}{' '}
                      <span className="font-weight-400">({t('days')})</span>
                    </Form.Label>
                    <Form.Control disabled name="parentalLeaveMaximumOpt" value={parentalLeave.maximumLength} />
                  </Form.Group>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ParentalLeaveViewer;
