import { Spinner } from 'react-bootstrap';
import { useState } from 'react';
import { closestCenter, DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import classNames from 'classnames';
import CardComponent from '../../../../components/base/CardComponent';
import PagesHeader from '../../../../components/base/PagesHeader';
import useContext from './context/useContext';
import useIntegrationEditor from './useIntegrationEditor';
import useLocale from '../../../../hooks/useLocale';
import IntegrationRowItem from './IntegrationRowItem';
import { IIntegrations } from '../types';
import { sortIntegrations } from '../../../../api/service/superAdmin/settings/integrations';

const Integrations = () => {
  const { isBusy, integrations, replaceIntegrations } = useContext();
  const integrationEditor = useIntegrationEditor();
  const { localize } = useLocale();
  const [loadingContainer, setLoadingContainer] = useState({ primary: false, secondary: false });
  const integrationList: IIntegrations[] = integrations;

  const toggleLoadingContainer = (containerKey) => {
    setLoadingContainer((prev) => {
      return {
        ...prev,
        [containerKey]: !prev[containerKey],
      };
    });
  };

  const listDiff = (prevList, newList) => {
    prevList = JSON.parse(JSON.stringify(prevList));
    newList = JSON.parse(JSON.stringify(newList));

    let changed = newList.filter((p, idx) => {
      if (p['id'] !== prevList[idx]['id']) {
        p['newSortOrder'] = idx + 1;
        return p;
      }

      return false;
    });

    return changed;
  };

  const onDragEnd = async (event, type) => {
    const { active, over } = event;

    if (active.id === over.id) {
      return;
    }

    const selectedLoadingType = 'primary';
    const oldIndex = integrationList.findIndex((item) => item.id === active.id);
    const newIndex = integrationList.findIndex((item) => item.id === over.id);
    const sortedList = arrayMove(integrationList, oldIndex, newIndex).map((item, index) => {
      item.order = index + 1;

      return item;
    });

    let _integrationList = { ...integrations };
    _integrationList = sortedList;
    replaceIntegrations(_integrationList);

    const selectedReOrderedItems = listDiff(integrationList, sortedList);

    try {
      toggleLoadingContainer(selectedLoadingType);
      await sortIntegrations({
        orders: selectedReOrderedItems.map((item) => {
          return {
            id: item.id,
            order: item.order,
          };
        }),
      });
    } catch (e) {
      console.error(e);
    } finally {
      toggleLoadingContainer(selectedLoadingType);
    }
  };

  return (
    <CardComponent>
      <PagesHeader headingClass="fs-h2 font-black" pageTitle={'Integrations'} />
      {/* {isBusy ? (
        <Spinner />
      ) : (
        <BSTable
          data={integrations}
          columns={columns}
          onRowClick={(row) => {
            integrationEditor.setIntegrationDetail(row.id);
          }}
        />
      )} */}
      {isBusy ? (
        <Spinner />
      ) : (
        <>
          <div
            className={classNames('table-responsive react-bootstrap-table chart-table', {
              'ui-blocker': loadingContainer.primary,
            })}
          >
            <DndContext collisionDetection={closestCenter} onDragEnd={(event) => onDragEnd(event, 'hjertaPartner')}>
              <SortableContext items={integrations.map((item) => item.id!)} strategy={verticalListSortingStrategy}>
                <table className="table table-hover table-condensed pension-plans-table">
                  <thead className="header-wrapper">
                    <tr className="correcto-table-header">
                      <th style={{ width: 25 }}></th>
                      <th className="month-header-col" style={{ width: 200 }}>
                        {localize('company_short_name')}
                      </th>
                      {/* <th className="month-header-col" style={{ width: 300 }}>
                        {localize('description')}
                      </th> */}
                      <th className="month-header-col" style={{ width: 200 }}>
                        {localize('status')}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="remote-table-body">
                    {integrations?.map((item) => {
                      return (
                        <IntegrationRowItem
                          key={item.id}
                          item={item}
                          onRowClick={(item) => {
                            integrationEditor.setIntegrationDetail(item);
                          }}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </SortableContext>
            </DndContext>
          </div>
        </>
      )}
    </CardComponent>
  );
};

export default Integrations;
