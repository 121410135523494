import { reInitializeSessionWithCompany } from '../../../../api/service/auth/service';
import useAuth from '../../../../hooks/useAuth';

type Props = {
  // user: AuthUser;
  setBusy: (isBusy) => void;
  id: string | any;
};

const useCompanyRoleSwitcher = (props: Props) => {
  const { user } = useAuth();

  const switchToCompanyAdmin = async () => {
    try {
      const personalNumber = user!.personalNumber;
      props.setBusy(true);

      const session = await reInitializeSessionWithCompany({
        personalNumber: personalNumber,
        companyId: props.id,
        isSaveInitializedSession: true,
        subRole: 'CompanyAdmin',
      });

      window.location.href = session.redirectTo;
    } catch (error) {
      console.error(error);
    } finally {
      props.setBusy(false);
    }
  };

  return {
    switchToCompanyAdmin,
  };
};

export default useCompanyRoleSwitcher;
