import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  IEmployeeSalaryReportResponse,
  IReportFilterParams,
  ComparisonSeriesReportType,
  IReportGenreInfo,
  FileExportType,
  ReportNameMap,
} from '../../../../types/report';
import useAuth from '../../../../hooks/useAuth';
import { Calendar } from '../../../../util/calendar';
import { numberFormat } from '../../../../util/util';
import { exportChartReport } from '../../../../api/service/company/reports/service';

const DEFAULT_REPORT_GENRE: IReportGenreInfo = {
  fieldKey: 'salary',
};

const useAllEmployeeYearlySalaryReport = (
  requestFn: (param: IReportFilterParams) => Promise<IEmployeeSalaryReportResponse>,
  reportGenre: IReportGenreInfo = DEFAULT_REPORT_GENRE,
) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  // report table layout
  const [reportHeaders, setReportHeaders] = useState<any>();
  const [reportRecords, setReportRecords] = useState<any[]>([]);
  const [selectedYear, setSelectedYear] = useState(Calendar.currentYear);
  const years = Calendar.getYearBoundary(5);
  const fetchAllEmployeeYearlyReport = async () => {
    try {
      const reportDetails: IEmployeeSalaryReportResponse = await requestFn({
        duration: {
          from: Calendar.getSelectedYearBoundary(Number(selectedYear))[0],
          to: Calendar.getSelectedYearBoundary(Number(selectedYear))[1],
        },
        companyClientId: user?.role.company.id,
        type: ComparisonSeriesReportType.TABLE,
        year: selectedYear,
      });

      processReportData({
        data: reportDetails.salariesByDate,
        employees: reportDetails.employees,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const processReportData = (reportResult) => {
    const formattedFieldData = reportResult?.data?.map((employee) => {
      return employee.employeeSalaries.map((data) => {
        return { [`field` + data.employee.id]: data[reportGenre.fieldKey].value };
      });
    });
    const updatedFieldData = formattedFieldData.map((el, idx) => {
      return Object.assign([], el);
    });
    const records = reportResult.data.map((employee, index) => {
      return {
        date: moment(employee.date).format('MMM-YY'),
        total: employee.total,
        ...updatedFieldData[index].reduce((current, next) => {
          return { ...current, ...next };
        }),
      };
    });

    setReportRecords(records);
    initializeHeaders(reportResult.employees);
  };

  const initializeHeaders = (employees: IEmployeeSalaryReportResponse['employees']) => {
    const columns = [
      {
        text: 'Month',
        dataField: 'date',
        formatter: (cell) => <span className={classNames('font-weight-bold', 'theme-text')}>{t(cell || '')}</span>,
        footer: 'Total',
        footerClasses: 'font-bold theme-text title-header-col',
        headerClasses: 'fixed-col title-header-col',
        classes: 'fixed-col-content',
      },
      {
        text: 'Total / month',
        dataField: 'total',
        classes: 'fixed-col-content number-col',
        footer: (columnData) => (
          <span className="cell-content">{numberFormat(columnData.reduce((acc, item) => acc + item, 0))}</span>
        ),
        formatter: (cell) => {
          return <span className="cell-content">{numberFormat(cell) || ''}</span>;
        },
        footerClasses: 'font-bold theme-text number-col',
        headerClasses: 'fixed-col series-header-col',
      },
    ].concat(
      employees.map((employee) => {
        return {
          dataField: `field${employee.id}`,
          text: employee as any,
          headerFormatter: formatEmployeeHeader,
          csvText: `${employee.fullName} \n ${employee.personalNumber}`,
          headerClasses: 'col-head fluid-col series-header-col',
          formatter: (cell) => {
            return <span className="cell-content">{numberFormat(cell) || ''}</span>;
          },
          footer: (columnData) => (
            <span className="cell-content">{numberFormat(columnData.reduce((acc, item) => acc + item, 0))}</span>
          ),
          classes: 'fluid-col-content number-col',
          footerClasses: 'font-bold theme-text number-col',
        };
      }),
    );
    setReportHeaders(columns);
  };

  const exportCSV = async (fileType: FileExportType = FileExportType.XLSX) => {
    try {
      setIsExporting(true);
      const reportDetails = await exportChartReport(
        {
          duration: {
            from: Calendar.getSelectedYearBoundary(Number(selectedYear))[0],
            to: Calendar.getSelectedYearBoundary(Number(selectedYear))[1],
          },
          companyClientId: user?.role.company.id,
          type: ComparisonSeriesReportType.TABLE,
          year: selectedYear,
        },
        reportGenre.export?.requestName!,
        reportGenre?.reportURL,
      );

      const url = window.URL.createObjectURL(new Blob([reportDetails]));
      const filePrefix = ReportNameMap[reportGenre.export?.requestName!];
      const fileSuffix = Calendar.instance().format('DDMMYYYYHHmmss');
      const fileName = `${filePrefix}-${fileSuffix}.${fileType}`;

      const link: any = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsExporting(false);
    }
  };

  const formatEmployeeHeader = (column) => {
    return (
      <div className="col-title">
        {column.text.fullName}
        <div className="col-subtext">{column.text.personalNumber}</div>
      </div>
    );
  };

  const printReport = () => {
    window.print();
  };
  const setYearFilter = (value) => {
    setSelectedYear(value);
  };

  useEffect(() => {
    fetchAllEmployeeYearlyReport();
  }, [selectedYear]);

  return {
    reportHeaders,
    reportRecords,
    isLoading,
    printReport,
    exportCSV,
    years,
    setYearFilter,
    isExporting,
  };
};

export default useAllEmployeeYearlySalaryReport;
