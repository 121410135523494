import React from 'react';
import OverlayTrigger, { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';
import Tooltip from 'react-bootstrap/Tooltip';

interface TooltipBoxProps {
  children: React.ReactElement;
  trigger?: OverlayTriggerType | OverlayTriggerType[];
  show?: boolean;
  defaultShow?: boolean;
  onToggle?: (nextShow: boolean) => void;
  placement?: Placement;
  id?: string;
  msg?: string;
}

const TooltipBox = React.forwardRef<TooltipBoxProps, any>(
  ({ children, trigger = ['hover', 'focus'], placement = 'top', id, msg }, ref) => {
    const popoverElement = (
      <Tooltip id={id} className="crt-tooltip" style={{ position: 'fixed' }}>
        <React.Fragment>{msg}</React.Fragment>
      </Tooltip>
    );

    return (
      <OverlayTrigger delay={{ show: 300, hide: 300 }} trigger={trigger} placement={placement} overlay={popoverElement}>
        {children}
      </OverlayTrigger>
    );
  },
);

TooltipBox.displayName = 'TooltipBox';

export default TooltipBox;
