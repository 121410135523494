import { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { CornerDownRight, RefreshCw } from 'react-feather';

import { AccountingFirmSaveMode, FormProps } from '../types';
import moment from 'moment';
import classNames from 'classnames';
import useLocale from '../../../../../hooks/useLocale';
import { DEFAULT_COMPANY_INFO } from '../../../../../types';
import { getRoaringCompanyInfo } from '../../../../../api/service/superAdmin/company-clients/service';
import PagesHeader from '../../../../../components/base/PagesHeader';
import { OrganizationNumber } from '../../../../../components/form/inputs/OrganizationNumber';
import { errorHighlighter, isOrganizationNumberValid } from '../../../../../util/form';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import { splitFullName } from '../../../../../util/util';

export default function CompanyDetails({
  context,
  date,
  isContractLoading,
}: FormProps & { isContractLoading?: boolean }) {
  const { localize } = useLocale();
  const [isOrgNumberValid, setIsOrgNumberValid] = useState<boolean>(true);
  const {
    companyDetails,
    addressDetails,
    isEditMode,
    formGroup,
    updateCompanyDetails,
    updateAddress,
    replaceCompanyDetails,
    replaceAuthorizedSignatory,
    replaceAddress,
  } = context;
  const handleSetCompany = (e) => {
    updateCompanyDetails(e.target.name, e.target.value);
    setIsOrgNumberValid(true);
  };

  const handleAddressCompany = (e) => {
    updateAddress(e.target.name, e.target.value);
  };

  const fetchCompanyDetails = async () => {
    try {
      updateCompanyDetails('name', DEFAULT_COMPANY_INFO.name);
      replaceAddress(DEFAULT_COMPANY_INFO.address);
      replaceAuthorizedSignatory(DEFAULT_COMPANY_INFO.authorizedSignatory);
      const { name, address, authorizedSignatory } = await getRoaringCompanyInfo(companyDetails?.organizationNumber);
      replaceCompanyDetails({ ...companyDetails, name });
      replaceAddress(address);
      replaceAuthorizedSignatory(
        authorizedSignatory.map((item, index) => {
          const updatedSignatory = item.map((value) => {
            const { name, positions, ...signatoryDetails } = value;
            return {
              ...signatoryDetails,
              ...splitFullName(name),
              position: positions[0].roleName,
              email: '',
              fullName: name,
            };
          });
          if (index === 0) {
            return {
              authorizedSignatory: updatedSignatory,
              signee: true,
            };
          } else {
            return {
              authorizedSignatory: updatedSignatory,
              signee: false,
            };
          }
        }),
      );
      setIsOrgNumberValid(true);
    } catch (error) {
      setIsOrgNumberValid(false);
    }
  };

  return (
    <div>
      <PagesHeader pageTitle="Firm details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        <div className="cr-form-row">
          <div className="contract-num-field">
            <Form.Label className="fs-name mb-0">
              {localize('contract_number')} {isContractLoading && <Spinner size="sm" />}
            </Form.Label>
            <Form.Control
              disabled
              type="text"
              onChange={handleSetCompany}
              name="contractNumber"
              value={companyDetails.contractNumber}
            />
          </div>
          {isEditMode && companyDetails?.status !== AccountingFirmSaveMode.DRAFT && (
            <div className="creation-date-field">
              <Form.Label className="fs-name mb-0">{localize('creation_date')}</Form.Label>
              <Form.Control
                onChange={handleSetCompany}
                value={
                  companyDetails && companyDetails['createdAt']
                    ? moment(companyDetails['createdAt']).format('YYYY-MM-DD')
                    : ''
                }
                name="createdAt"
                disabled
              />
            </div>
          )}
          {isEditMode && companyDetails?.status !== AccountingFirmSaveMode.DRAFT && (
            <div className="last-edit-field">
              <Form.Label className="fs-name mb-0">{localize('last_edit')}</Form.Label>
              <Form.Control
                //  defaultValue={moment(date?.updatedAt).format('YYYY-MM-DD')}
                onChange={handleSetCompany}
                value={
                  companyDetails && companyDetails['updatedAt']
                    ? moment(companyDetails['updatedAt']).format('YYYY-MM-DD')
                    : ''
                }
                name="lastEdit"
                disabled
              />
            </div>
          )}
          <div className="reseller-agreement-field">
            <Form.Label className="fs-name mb-0">{localize('reseller_agreement')}</Form.Label>
            <Form.Control
              name="resellerAgreement"
              value={localize('certified_provider')}
              onChange={handleSetCompany}
              disabled={true}
              defaultValue="CertifiedProvider"
              type="text"
            />
          </div>
        </div>

        <div className="cr-form-row">
          <OrganizationNumber
            disabled={isEditMode && companyDetails?.status !== AccountingFirmSaveMode.DRAFT}
            onChangeHandler={(e) => handleSetCompany(e)}
            field={formGroup.getFieldMeta('companyDetails.organizationNumber')}
            value={companyDetails.organizationNumber}
            isValid={isOrgNumberValid}
            lazy={true}
            isRequiredError={false}
          />
          <div>
            <Button
              style={{ minWidth: '100%' }}
              disabled={isOrganizationNumberValid(companyDetails?.organizationNumber)}
              onClick={
                isEmpty(companyDetails?.organizationNumber)
                  ? undefined
                  : () => {
                      fetchCompanyDetails();
                    }
              }
              className="app-btn-secondary"
            >
              {isEditMode && companyDetails?.status !== AccountingFirmSaveMode.DRAFT ? (
                <RefreshCw className="icon-gap-right" />
              ) : (
                <CornerDownRight className="icon-gap-right" />
              )}{' '}
              {isEditMode && companyDetails?.status !== AccountingFirmSaveMode.DRAFT
                ? `${localize('refresh')}`
                : `${localize('fetch_data')}`}
            </Button>
          </div>
          <div className="company-name-field">
            <Form.Label className="fs-name mb-0">{localize('company_name')}</Form.Label>
            <Form.Control
              disabled
              type="text"
              onChange={handleSetCompany}
              name="name"
              value={companyDetails && companyDetails['name'] ? companyDetails['name'] : ''}
            />
          </div>
        </div>
        <div className="cr-form-row">
          <div className="address-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('address')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                name="street"
                className={classNames(errorHighlighter(formGroup.getFieldMeta('addressDetails.street')))}
                value={addressDetails ? addressDetails!['street'] : ''}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('addressDetails.street')} />
            </Form.Group>
          </div>
          <div className="post-code-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('postcode')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={addressDetails ? addressDetails!['postcode'] : ''}
                name="postcode"
                required
                className={classNames('text-left', errorHighlighter(formGroup.getFieldMeta('addressDetails.postcode')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('addressDetails.postcode')} />
            </Form.Group>
          </div>
          <div className="city-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('city')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={addressDetails ? addressDetails!['city'] : ''}
                name="city"
                required
                className={classNames(errorHighlighter(formGroup.getFieldMeta('addressDetails.city')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('addressDetails.city')} />
            </Form.Group>
          </div>
          <div className="country-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('country')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={addressDetails ? addressDetails!['country'] : ''}
                name="country"
                required
                className={classNames(errorHighlighter(formGroup.getFieldMeta('addressDetails.country')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('addressDetails.country')} />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
