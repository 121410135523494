import moment, { Moment } from 'moment';
import { MonthSelectOption } from '../types';

export class Calendar {
  static instance = moment;

  static nextMonthStartDate = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
  static lastMonthName = moment().subtract(1, 'month').startOf('month').format('MMM');

  static get currentMonthStartDate() {
    return moment().startOf('month').format('YYYY-MM-DD');
  }

  static get lastMonthStartDate() {
    return moment().startOf('month').format('YYYY-MM-DD');
  }

  static get lastMonthFullName() {
    return moment().startOf('month').format('MMMM');
  }

  static get currentMonthBoundary() {
    return [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
  }

  static padMonthWithZeros(dateRange, data, opt) {
    const defaultOpt = {
      includePrefix: true,
    };

    opt = Object.assign({}, defaultOpt, opt);

    const startDate = moment(dateRange[0]);
    const endDate = moment(dateRange[1]);
    const filledArray: any[] = [];

    for (const currentDate = startDate.clone(); currentDate.isSameOrBefore(endDate); currentDate.add(1, 'day')) {
      const existingValue = data[currentDate.diff(startDate, 'days')];

      if (typeof existingValue !== 'undefined') {
        filledArray.push(existingValue);
      } else {
        filledArray.push(0);
      }
    }

    return filledArray;
  }

  static get currentYearBoundary(): [string, string] {
    return [
      moment(new Date()).startOf('year').startOf('month').format('YYYY-MM-DD'),
      moment(new Date()).endOf('year').startOf('month').format('YYYY-MM-DD'),
    ];
  }

  static get currentYearDateBoundary(): [string, string] {
    return [
      moment(new Date()).startOf('year').startOf('month').format('YYYY-MM-DD'),
      moment(new Date()).endOf('year').endOf('month').format('YYYY-MM-DD'),
    ];
  }

  static get yearToDateBoundary(): [string, string] {
    return [
      moment(new Date()).startOf('year').startOf('month').format('YYYY-MM-DD'),
      moment(new Date()).startOf('month').format('YYYY-MM-DD'),
    ];
  }

  static getRecentMonths(numMonths: number) {
    const currentDate = moment();
    const monthsArray: string[] = [];

    for (let i = 0; i < numMonths; i++) {
      const currentMonth = currentDate.format('YYYY-MM');
      monthsArray.unshift(currentMonth);
      currentDate.subtract(1, 'month');
    }

    return monthsArray;
  }

  static getRecentMonthBoundary(numMonths?: number) {
    const currentDate = moment();

    if (!numMonths) {
      return [
        moment().startOf('year').startOf('months').format('YYYY-MM'),
        moment().startOf('months').format('YYYY-MM'),
      ];
    }

    const endMonth = currentDate.format('YYYY-MM');
    currentDate.subtract(numMonths - 1, 'months');
    const startMonth = currentDate.format('YYYY-MM');

    return [startMonth, endMonth];
  }
  static get getCurrentMonth() {
    return moment().format('MMMM');
  }
  static getYearBoundary(numYears?: number) {
    if (!numYears) {
      return [
        moment().startOf('year').startOf('months').format('YYYY-MM'),
        moment().startOf('months').format('YYYY-MM'),
      ];
    }
    let offset = 0;
    let yearsArray: any = [];
    while (offset < numYears) {
      yearsArray.push(moment().subtract(offset++, 'year').format('YYYY'));
    }
    return yearsArray;
  }

  static get currentYear() {
    return moment().format('YYYY');
  }

  static isCurrentYear(val) {
    return this.currentYear === val;
  }

  static isCurrentMonth(val) {
    if (!val) {
      return false;
    }

    const currentMonth = moment().format('YYYY-MM');
    const inputMonth = moment(val).format('YYYY-MM');

    return currentMonth === inputMonth;
  }

  static parseDate(str: string, format: string, acceptedFormat: string[] = []) {
    acceptedFormat = [...acceptedFormat, 'YYYY-MM-DD'];

    return moment(str, acceptedFormat).format(format);
  }

  static parseCurrentDate(format: string) {
    return moment().format(format);
  }

  static parseDateStr(str: string) {
    return moment(str);
  }

  static getSelectedYearBoundary(selectedYear: number) {
    return [
      moment().set({ year: selectedYear }).startOf('year').startOf('month').format('YYYY-MM-DD'),
      moment().set({ year: selectedYear }).endOf('year').startOf('month').format('YYYY-MM-DD'),
    ];
  }

  static getMonthsToYearBoundary(numMonths?: number) {
    const currentDate = moment();

    if (!numMonths) {
      return [
        moment().startOf('year').startOf('months').format('YYYY-MM-DD'),
        moment().startOf('months').format('YYYY-MM-DD'),
      ];
    }
    const endMonth = currentDate.startOf('month').format('YYYY-MM-DD');
    currentDate.subtract(numMonths - 1, 'months');
    const startMonth = currentDate.startOf('month').format('YYYY-MM-DD');
    return [startMonth, endMonth];
  }

  static hasDatePassed(dateString) {
    const inputDate = moment(dateString);
    const currentDate = moment();

    return inputDate.isBefore(currentDate);
  }

  static hasMonthPassed(dateString: string) {
    const inputDate = moment(dateString);
    const currentDate = moment().startOf('month');

    return inputDate.isBefore(currentDate);
  }

  static generateCurrentYearBoundary(yearsLength) {
    const currentDate = moment();

    const startDate = currentDate.clone().subtract(yearsLength, 'years').startOf('year').format('YYYY-MM');
    const endDate = currentDate.format('YYYY-MM');

    return [startDate, endDate];
  }

  static generateMonthBoundary(monthsLength) {
    const currentDate = moment();

    const startDate = currentDate.clone().subtract(monthsLength, 'months').startOf('month').format('YYYY-MM');
    const endDate = currentDate.clone().subtract(1, 'months').startOf('month').format('YYYY-MM');

    return [startDate, endDate];
  }

  static selectFutureOrNextMonthDate(date: string) {
    if (!date) {
      return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    }

    const inputDate = moment(date);
    const currentDate = moment();

    if (inputDate.isAfter(currentDate)) {
      return date;
    }

    return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
  }

  static selectEffectiveDate = (effectiveFromDate: string) => {
    if (!effectiveFromDate) {
      return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    }

    const inputDate = moment(effectiveFromDate);
    const currentDate = moment(`${moment().year()}-${moment().month() + 1}`);

    if (inputDate.format('YYYY-MM') === currentDate.format('YYYY-MM')) {
      return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    }

    if (inputDate.isBefore(currentDate)) {
      return moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
    }

    return effectiveFromDate;
  };

  static getMonthBoundary(month) {
    const startDate = moment(month).startOf('month').format('YYYY-MM-DD');

    const endDate = moment(month).endOf('months').format('YYYY-MM-DD');

    return [startDate, endDate];
  }

  static getInitialDateOfMonth(date: string) {
    if (!date) {
      return '';
    }

    return moment(date).startOf('month').format('YYYY-MM-DD');
  }

  static isCurrenMonthDatePassed(date: string) {
    if (!date) {
      return '';
    }

    const currentDate = this.currentMonthStartDate;

    return moment(date).isBefore(currentDate);
  }

  static getMonthlyOptionAdapter({ emptyMonths = false, startFrom, noOfMonths }): MonthSelectOption[] {
    const months: MonthSelectOption[] = [];
    const dateStart = startFrom ? moment(startFrom) : moment();
    const dateEnd = startFrom ? moment(startFrom).add(noOfMonths, 'month') : moment().add(noOfMonths, 'month');
    while (dateEnd.diff(dateStart, 'months') >= 0) {
      const year = dateStart.year();
      dateStart.set('date', 1);
      months.push({
        key: dateStart.format('YYYY-MM-DD'),
        label: `${dateStart.format('MMM')} ${year}`,
        value: `${dateStart.format('MMM')} ${year}`,
      });
      dateStart.add(1, 'month');
    }
    return months;
  }

  static getMonthDateSelectOption(instance: Moment, opt: { isDisabled: boolean } | null = null): MonthSelectOption {
    const labelMonth = instance.format('MMM YYYY');
    const labelMonthKey = instance.startOf('month').format('YYYY-MM-DD');

    return {
      key: labelMonthKey,
      label: labelMonth,
      value: labelMonth,
      readonly: opt?.isDisabled || false,
    };
  }

  static generateEffectiveDateOption(
    effectiveDate: string | null = null,
    opt: Partial<{ readonlyPrevMonth: boolean; includeCurrentMonth: boolean; noOfMonths: number }> | null = null,
  ) {
    const defaultOpt = { isDisabled: false, noOfMonths: 6, includePrevMonth: true };
    const upcomingMonthList: MonthSelectOption[] = Calendar.getMonthlyOptionAdapter({
      noOfMonths: opt?.noOfMonths || defaultOpt.noOfMonths,
      startFrom: Calendar.nextMonthStartDate,
    });
    const previousMonthList: MonthSelectOption[] = [];

    if (effectiveDate) {
      const prevMonthInstance = Calendar.instance(effectiveDate);
      const nextMonthInstance = Calendar.instance().add(1, 'month').startOf('month');

      const nextMonthStartDateValue = nextMonthInstance.format('YYYY-MM-DD');
      const lastMonthStartDateValue = prevMonthInstance.format('YYYY-MM-DD');

      const prevMonth = Calendar.getMonthDateSelectOption(
        prevMonthInstance,
        Object.assign(defaultOpt, opt?.readonlyPrevMonth ? { isDisabled: true } : {}),
      );

      if (nextMonthStartDateValue !== lastMonthStartDateValue && !prevMonthInstance.isAfter(nextMonthInstance)) {
        previousMonthList.push(prevMonth);
      }
    }

    if (opt?.includeCurrentMonth) {
      const currentMonth = Calendar.getMonthDateSelectOption(Calendar.instance());

      previousMonthList.push(currentMonth);
    }

    return [...previousMonthList, ...upcomingMonthList];
  }
}
