/* eslint-disable react/prop-types */
import FileItem from './FileItem';

const Preview = ({ meta, fileWithMeta }) => {
  const { name } = meta;
  const { remove } = fileWithMeta;
  return <FileItem onRemove={remove} name={name} />;
};

export default Preview;
