import Dropzone from 'react-dropzone-uploader';
import { useTranslation } from 'react-i18next';

import PagesHeader from '../../../../components/base/PagesHeader';
import Preview from './FilePreview';
import Layout from './FileUploadLayout';
import { copyToClipboard, generateEmailDomain } from '../../../../util';
import { Link } from 'react-router-dom';
import { Copy } from 'react-feather';
import useAuth from '../../../../hooks/useAuth';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

type Props = {
  onFilesUpload: (args) => void;
};

export default function FileUpload({ onFilesUpload }: Props) {
  const _files: Array<any> = [];
  const { t } = useTranslation();
  const authUser = useAuth();
  const emailTo = generateEmailDomain(authUser.user?.role.company?.organizationNumber || '5512345678');

  const handleChangeStatus = ({ meta, file, remove }, status) => {
    if (status === 'done') {
      _files.push({ id: meta.id, file, remove });
      onFilesUpload(_files);
    } else if (status === 'removed') _files.filter((i) => i.id !== meta.id);
  };

  return (
    <>
      <p className="fs-text-400 theme-text">{t('file_upload_modal_text')}</p>
      <div className="d-flex align-items-center fs-text-400">
        <p className="theme-text mb-0 me-4">
          {t('file_upload_modal_text_sub')}{' '}
          <Link onClickCapture={() => copyToClipboard(emailTo)} to={`mailto:${emailTo}`}>
            {emailTo}
          </Link>
        </p>
        <span className="mb-0" onClick={() => copyToClipboard(emailTo)} style={{ cursor: 'pointer' }}>
          <TooltipBox msg={`${t('copy')}`}>
            <Copy className="blue-icon-btn" />
          </TooltipBox>
        </span>
      </div>
      <>
        <PagesHeader pageTitle="File upload area" headingClass="fs-name mt-2" />
        <div
          className="p-2 theme-border"
          style={{
            width: 676,
            maxHeight: 274,
            borderRadius: 2,
            overflow: 'auto',
          }}
        >
          <Dropzone
            onChangeStatus={handleChangeStatus}
            accept="application/pdf"
            LayoutComponent={Layout}
            inputContent={null}
            PreviewComponent={Preview}
            styles={{
              inputLabelWithFiles: {
                background: 'transparent',
                width: '96%',
                minHeight: 46,
                position: 'absolute',
                bottom: 0,
              },
            }}
            inputWithFilesContent={null}
          />
        </div>
      </>
    </>
  );
}
