export const INITIAL_ACCOUNTING_SALARY_COMPANY = {
    shortName: '',
    companyName: '',
    clientNumber: '',
    contractNumber: '',
    description: '',
    organizationNumber: '',
    vatNumber: '',
    bankgiroNumber: '',
    plusgiroNumber: '',
    integrationType: [],
    apiKey: '',
    id: '',
};
export const INITIAL_CONTACT_INFORMATION = {
    address: '',
    city: '',
    phone: '',
    fax: '',
    postalCode: '',
    email: '',

}
export const FORM_INITIAL_VALUES = { contactInformation: INITIAL_CONTACT_INFORMATION, accountingSalaryCompany: INITIAL_ACCOUNTING_SALARY_COMPANY }
