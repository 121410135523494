import { Form, Spinner } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import {
  AuthorizedSignatory,
  FirmDetails,
  ContractDetails,
  SeniorConsultant,
  InvitationEmailSettings,
} from '../AccountingFirmForms';
import { useEditAccountingFirmContext } from '../context/useContext';
import { AccountingFirmSaveMode, DateType } from '../types';
import Supervisors from '../AccountingFirmForms/Supervisors';
import { ProvisionAgreement } from '../AccountingFirmForms/ProvisionAgreement';

type Props = {
  isBusy: boolean;
  date: DateType;
};

export default function General({ isBusy, date }: Props) {
  const context = useEditAccountingFirmContext();

  return (
    <>
      <CardComponent>
        {isBusy ? (
          <Spinner />
        ) : (
          <Form>
            <FirmDetails context={context} date={date} />
            <ContractDetails context={context} />
            {context.isEditMode && context.firmDetails?.status !== AccountingFirmSaveMode.DRAFT && (
              <ProvisionAgreement context={context} />
            )}
            <AuthorizedSignatory context={context} />
            <Supervisors context={context} />
            <SeniorConsultant context={context} />
            <InvitationEmailSettings context={context} />
          </Form>
        )}
      </CardComponent>
      {/* <CardComponent>
        <div className="d-flex justify-content-end">
          <Button onClick={onCancel} className="app-btn-primary me-2">
            <Slash className="icon-gap-right cancel-btn" /> Cancel changes
          </Button>
          <Button className="app-btn-primary">
            <Save className="icon-gap-right" /> Save changes
          </Button>
        </div>
      </CardComponent> */}
    </>
  );
}
