import { ColumnDescription } from 'react-bootstrap-table-next';
import { sortCaret } from '../../../components/table/formatters';
import { tableHeaderText } from '../../../components/table/TableHeaderFormatter';
import classNames from 'classnames';
import useLocalizationResolver from '../../../hooks/useLocalizationResolver';

export const useColumn = () => {
  const { getLang } = useLocalizationResolver();
  const accountingFirmColumn: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Company name'),
      dataField: 'name',
      sort: true,
      sortCaret,
      headerStyle: { width: 200 },
    },
    {
      text: tableHeaderText('Org.number'),
      dataField: 'organizationNumber',
      sort: true,
      sortCaret,
      headerStyle: { width: 150 },
    },
    {
      text: tableHeaderText('Users'),
      dataField: 'users',
      // formatter: () => '-',
      headerStyle: { width: 150 },
    },
    {
      text: tableHeaderText('Clients'),
      dataField: 'clients',
      // formatter: () => '-',
      headerStyle: { width: 130 },
    },
    /*{
      text: tableHeaderText('Documents'),
      dataField: 'contractNumber',
      formatter: (cell) => {
        return '-';
         return (
          <div className="doc-list">
            <span className={classNames('doc-list__item', 'doc-list__item--active')}>Contr</span>
            <span className={classNames('doc-list__item', cell === 2 ? 'doc-list__item--active' : '')}>KYC</span>
          </div>
        ) 
      },
      headerStyle: { width: 120 },
    },
    */
    {
      text: tableHeaderText('Status'),
      dataField: 'status',
      sort: true,
      sortCaret,
      formatter: (cell) => <span className={classNames('fs-text-400', 'active-green')}>{getLang(cell)}</span>,
      headerStyle: { width: 90 },
    },
  ];

  return { accountingFirmColumn };
};
