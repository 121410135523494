import { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { CornerDownRight, RefreshCw } from 'react-feather';
import { getRoaringCompanyInfo } from '../../../../api/service/superAdmin/company-clients/service';
import PagesHeader from '../../../../components/base/PagesHeader';
import { AccountingFirmSaveMode, FormProps } from '../types';
import moment from 'moment';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import classNames from 'classnames';
import { errorHighlighter, isOrganizationNumberValid } from '../../../../util/form';
import { OrganizationNumber } from '../../../../components/form/inputs/OrganizationNumber';
import useLocale from '../../../../hooks/useLocale';
import { splitFullName } from '../../../../util/util';
import { DEFAULT_COMPANY_INFO } from '../../../../types/general';
import { toTitleCase } from '../../../../util';

export default function FirmDetails({ context, date, isContractLoading }: FormProps & { isContractLoading?: boolean }) {
  const { localize } = useLocale();
  const [isOrgNumberValid, setIsOrgNumberValid] = useState<boolean>(true);
  const {
    firmDetails,
    addressDetails,
    isEditMode,
    formGroup,
    updateFirmDetailsInput,
    updateAddressInput,
    replaceFirmDetailsInput,
    replaceAddressInput,
    replaceCombinations,
  } = context;

  const handleSetCompany = (e) => {
    updateFirmDetailsInput(e.target.name, e.target.value);
    setIsOrgNumberValid(true);
  };

  const handleAddressCompany = (e) => {
    updateAddressInput(e.target.name, e.target.value);
  };

  const fetchCompanyDetails = async () => {
    try {
      updateFirmDetailsInput('name', DEFAULT_COMPANY_INFO.name);
      replaceAddressInput(DEFAULT_COMPANY_INFO.address);
      replaceCombinations(DEFAULT_COMPANY_INFO.authorizedSignatory);
      const { name, address, authorizedSignatory } = await getRoaringCompanyInfo(firmDetails?.organizationNumber);
      replaceFirmDetailsInput({ ...firmDetails, name });
      replaceAddressInput({ ...(address || null), city: toTitleCase(address.city) });
      replaceCombinations(
        authorizedSignatory.map((item, index) => {
          const updatedSignatory = item.map((value) => {
            const { name, positions, ...signatoryDetails } = value;
            return {
              ...signatoryDetails,
              ...splitFullName(name),
              position: positions[0].roleName,
              email: '',
              fullName: name,
            };
          });
          if (index === 0) {
            return {
              authorizedSignatory: updatedSignatory,
              signee: true,
            };
          } else {
            return {
              authorizedSignatory: updatedSignatory,
              signee: false,
            };
          }
        }),
      );
      setIsOrgNumberValid(true);
    } catch (error) {
      setIsOrgNumberValid(false);
    }
  };

  return (
    <div>
      <PagesHeader pageTitle="Firm details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        <div className="cr-form-row">
          <div className="contract-num-field">
            <Form.Label className="fs-name mb-0">
              {localize('contract_number')} {isContractLoading && <Spinner size="sm" />}
            </Form.Label>
            <Form.Control
              disabled
              type="text"
              onChange={handleSetCompany}
              name="contractNumber"
              value={firmDetails.contractNumber}
              className={errorHighlighter(formGroup.getFieldMeta('firmDetails.contractNumber'))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('firmDetails.contractNumber')} />
          </div>
          {isEditMode && firmDetails?.status !== AccountingFirmSaveMode.DRAFT && (
            <div className="creation-date-field">
              <Form.Label className="fs-name mb-0">{localize('creation_date')}</Form.Label>
              <Form.Control
                // disabled={isEditMode}
                // defaultValue={moment(date?.createdAt).format('YYYY-MM-DD')}
                onChange={handleSetCompany}
                value={
                  firmDetails && firmDetails['createDate'] ? moment(firmDetails['createDate']).format('YYYY-MM-DD') : ''
                }
                name="createDate"
                disabled
              />
            </div>
          )}
          {isEditMode && firmDetails?.status !== AccountingFirmSaveMode.DRAFT && (
            <div className="last-edit-field">
              <Form.Label className="fs-name mb-0">{localize('last_edit')}</Form.Label>
              <Form.Control
                //  defaultValue={moment(date?.updatedAt).format('YYYY-MM-DD')}
                onChange={handleSetCompany}
                value={
                  firmDetails && firmDetails['lastEdit'] ? moment(firmDetails['lastEdit']).format('YYYY-MM-DD') : ''
                }
                name="lastEdit"
                disabled
              />
            </div>
          )}
          <div className="reseller-agreement-field">
            <Form.Label className="fs-name mb-0 required">{localize('reseller_agreement')}</Form.Label>
            <Form.Select
              name="resellerAgreement"
              value={firmDetails?.resellerAgreement}
              onChange={handleSetCompany}
              // disabled={firmDetails?.status !== AccountingFirmSaveMode.DRAFT}
              // defaultValue="CertifiedProvider"
            >
              {/* <option disabled value="">
                {t('please_select')}
              </option> */}
              <option value="CertifiedProvider">{localize('certified_provider')}</option>
              {/* <option value="Provider">{t('provider')}</option> */}
            </Form.Select>
          </div>
        </div>

        <div className="cr-form-row">
          <OrganizationNumber
            disabled={isEditMode && firmDetails?.status !== AccountingFirmSaveMode.DRAFT}
            onChangeHandler={(e) => handleSetCompany(e)}
            field={formGroup.getFieldMeta('firmDetails.organizationNumber')}
            value={firmDetails.organizationNumber}
            isValid={isOrgNumberValid}
            lazy={true}
          />
          <div>
            <Button
              disabled={isOrganizationNumberValid(firmDetails?.organizationNumber)}
              onClick={
                isEmpty(firmDetails?.organizationNumber)
                  ? undefined
                  : () => {
                      fetchCompanyDetails();
                    }
              }
              className="app-btn-secondary"
            >
              {isEditMode && firmDetails?.status !== AccountingFirmSaveMode.DRAFT ? (
                <RefreshCw className="icon-gap-right" />
              ) : (
                <CornerDownRight className="icon-gap-right" />
              )}
              {isEditMode && firmDetails?.status !== AccountingFirmSaveMode.DRAFT
                ? `${localize('refresh')}`
                : `${localize('fetch_data')}`}
            </Button>
          </div>
          <div className="company-name-field">
            <Form.Label className="fs-name mb-0 required">{localize('company_name')}</Form.Label>
            <Form.Control
              disabled
              type="text"
              onChange={handleSetCompany}
              name="name"
              value={firmDetails && firmDetails['name'] ? firmDetails['name'] : ''}
              required
              className={classNames(errorHighlighter(formGroup.getFieldMeta('firmDetails.name')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('firmDetails.name')} />
          </div>
          {isEditMode && firmDetails?.status !== AccountingFirmSaveMode.DRAFT && (
            <div className="status-field">
              <Form.Label className="fs-name mb-0 required">{localize('status')}</Form.Label>
              {firmDetails?.status === AccountingFirmSaveMode.SETUP && (
                <Form.Control disabled name="status" defaultValue="Setup" />
              )}
              {firmDetails?.status !== AccountingFirmSaveMode.SETUP && (
                <Form.Select
                  name="status"
                  className={classNames(errorHighlighter(formGroup.getFieldMeta('firmDetails.status')))}
                  value={firmDetails?.status}
                  onChange={handleSetCompany}
                >
                  <option value="">{localize('please_select')}</option>
                  {/* <option value="Setup">{t('setup')}</option> */}
                  <option value="Active">{localize('active')}</option>
                  <option value="InActive">{localize('inactive')}</option>
                </Form.Select>
              )}
              <ErrorFeedback field={formGroup.getFieldMeta('firmDetails.status')} />
            </div>
          )}
        </div>
        <div className="cr-form-row">
          <div className="address-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('address')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                name="street"
                className={classNames(errorHighlighter(formGroup.getFieldMeta('addressDetails.street')))}
                value={addressDetails ? addressDetails!['street'] : ''}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('addressDetails.street')} />
            </Form.Group>
          </div>
          <div className="post-code-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('postcode')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={addressDetails ? addressDetails!['postcode'] : ''}
                name="postcode"
                required
                className={classNames('text-left', errorHighlighter(formGroup.getFieldMeta('addressDetails.postcode')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('addressDetails.postcode')} />
            </Form.Group>
          </div>
          <div className="city-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('city')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={addressDetails ? addressDetails!['city'] : ''}
                name="city"
                required
                className={classNames(errorHighlighter(formGroup.getFieldMeta('addressDetails.city')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('addressDetails.city')} />
            </Form.Group>
          </div>
          <div className="country-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{localize('country')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={addressDetails ? addressDetails!['country'] : ''}
                name="country"
                required
                className={classNames(errorHighlighter(formGroup.getFieldMeta('addressDetails.country')))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('addressDetails.country')} />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
