import { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Plus, Trash2, X } from 'react-feather';
import { getSalaryTypes } from '../../../../api/service/superAdmin/pension-policies/service';
import PagesHeader from '../../../../components/base/PagesHeader';
import CardComponent from '../../../../components/base/CardComponent';
import { useTranslation } from 'react-i18next';
import useCompanySalaryEditor from './useCompanySalaryEditor';
import { SalaryTypeRef } from '../types';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

interface InputProps {
  emitValues: (...args) => void;
}

export default function SalaryTypes({ emitValues }: InputProps) {
  const companySalaryTypeEditor = useCompanySalaryEditor();
  const [isBusy, setisBusy] = useState(false);
  const [provider, setProvider] = useState<'correcto' | 'company'>('correcto');
  const [commonSalaryTypes, setCommonSalaryTypes] = useState<any>([]);
  const [otherSalaryTypes, setOtherSalaryTypes] = useState<any>([]);
  const isCorrecto = provider === 'correcto';
  // const columns = useColumns(isCorrecto, companySalaryTypeEditor);
  const { t } = useTranslation();

  const fetchSalaryCodes = async () => {
    try {
      const _salaryCodes = await getSalaryTypes();
      const _commonSalaryTypes = _salaryCodes.filter((salaryCode) => {
        return salaryCode.isCommon;
      });

      const _otherSalaryTypes = _salaryCodes.filter((salaryCode) => {
        return !salaryCode.isCommon;
      });

      setCommonSalaryTypes([..._commonSalaryTypes]);
      setOtherSalaryTypes([..._otherSalaryTypes]);
    } finally {
      setisBusy(false);
    }
  };

  const onProviderChange = (e) => {
    const { value } = e.target;
    setProvider(value);
  };

  useEffect(() => {
    fetchSalaryCodes();
    companySalaryTypeEditor.fetchAll();
  }, []);

  useEffect(() => {
    emitValues({
      companySalaryTypes: companySalaryTypeEditor.companySalaryTypes,
      isSavingCompanySalaryType: companySalaryTypeEditor.isLoading,
      saveCompanySalaryTypes: companySalaryTypeEditor.save,
      providerType: provider,
    } as SalaryTypeRef);
  }, [
    provider,
    companySalaryTypeEditor.companySalaryTypes,
    companySalaryTypeEditor.isLoading,
    companySalaryTypeEditor.save,
  ]);

  return (
    <CardComponent>
      <PagesHeader pageTitle="Salary types" headingClass="fs-page-title" />
      <div className="d-flex justify-content-between align-items-center mb-5">
        <Form.Group>
          <Form.Label className="fs-name mb-0">{t('type')}</Form.Label>
          <Form.Select onChange={onProviderChange} value={provider}>
            <option value="company">{`${t('company_specific_salary_types')}`}</option>
            <option value="correcto">{t('correcto_salary_types')}</option>
          </Form.Select>
        </Form.Group>
        {!isCorrecto && (
          <div
            style={{ borderLeft: '1px solid #4E89C5', width: 324, height: 44 }}
            className="fs-text-400 font-black ps-3 theme-text"
          >
            {t('company_salary_types_note')}
          </div>
        )}
      </div>
      <>
        {!isCorrecto && (
          <div id="salaryRef" style={{ height: 400, overflowY: 'scroll', scrollBehavior: 'smooth' }}>
            {isBusy ? (
              <Spinner size="sm" />
            ) : (
              <div className="table-responsive react-bootstrap-table setting-salary-type-table">
                <table className="table table-condensed salary-types-table">
                  <thead className="header-wrapper">
                    <tr className="correcto-table-header">
                      <th style={{ maxWidth: 100, width: 100 }}>{t('salary_code')}</th>
                      <th style={{ maxWidth: 300, width: 300 }}>{t('name')}</th>
                      <th style={{ maxWidth: 300, width: 300 }}>{t('alternative_name')}s</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companySalaryTypeEditor.companySalaryTypes.length > 0 && (
                      <>
                        <tr className="highlight-inactive">
                          <td className="p-0" colSpan={4}>
                            <table className="table table-hover table-condensed salary-types-table-child setting-salary-type-table table--no-strip">
                              <tbody className="classic-table-body">
                                {companySalaryTypeEditor.companySalaryTypes?.map((salary, index) => (
                                  <tr className="highlight-inactive" key={salary.id}>
                                    <td style={{ maxWidth: 100, width: 100 }}>
                                      <div className="salary-code-control">
                                        <span>C</span>
                                        <Form.Control
                                          value={salary.code}
                                          onChange={(e) => {
                                            companySalaryTypeEditor.handleChange(index, 'code', e.target.value);
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td style={{ maxWidth: 300, width: 300 }}>
                                      <Form.Control
                                        value={salary.name}
                                        onChange={(e) => {
                                          companySalaryTypeEditor.handleChange(index, 'name', e.target.value);
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        {salary?.defaults?.map((defaultItem, index) => (
                                          <div
                                            className="d-flex align-items-baseline  justify-content-between alternative-section"
                                            key={`${defaultItem?.name}${index}`}
                                          >
                                            <span>{defaultItem?.name}</span>
                                            <TooltipBox msg={`${t('delete')}`}>
                                              <span>
                                                <X className="ms-2" width={20} style={{ color: '#930000' }} />
                                              </span>
                                            </TooltipBox>
                                          </div>
                                        ))}
                                      </div>
                                    </td>
                                    <td align="right">
                                      <TooltipBox msg={`${t('delete')}`}>
                                        <Trash2
                                          onClick={() => companySalaryTypeEditor.removeSalaryType(salary.id!, index)}
                                          className="cursor-pointer"
                                          color="#930000"
                                        />
                                      </TooltipBox>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {isCorrecto && (
          <div id="salaryRef" style={{ height: 400, overflowY: 'scroll', scrollBehavior: 'smooth' }}>
            {isBusy ? (
              <Spinner size="sm" />
            ) : (
              <div className="table-responsive react-bootstrap-table setting-salary-type-table">
                <table className="table table-condensed salary-types-table">
                  <thead className="header-wrapper">
                    <tr className="correcto-table-header">
                      <th style={{ maxWidth: 100, width: 100 }}>{t('salary_code')}</th>
                      <th style={{ maxWidth: 300, width: 300 }}>{t('name')}</th>
                      <th style={{ maxWidth: 300, width: 300 }}>{t('alternative_name')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {commonSalaryTypes.length > 0 && (
                      <>
                        <tr className="section-divider 'highlight-inactive'">
                          <td colSpan={4}>
                            <div className="w-100">{t('PENSION_POLICY.common_salary_types')}</div>
                          </td>
                        </tr>
                        <tr className="highlight-inactive">
                          <td className="p-0" colSpan={4}>
                            <table className="table table-hover table-condensed salary-types-table-child setting-salary-type-table">
                              <tbody className="classic-table-body">
                                {commonSalaryTypes?.map((salary, index) => (
                                  <tr key={index}>
                                    <td style={{ maxWidth: 100, width: 100 }}>
                                      <Form.Control value={salary.code} disabled />
                                    </td>
                                    <td style={{ maxWidth: 300, width: 300 }}>
                                      <Form.Control value={salary.name} disabled />
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        {salary?.defaults?.map((defaultItem, index) => (
                                          <div
                                            className="d-flex align-items-baseline  justify-content-between alternative-section"
                                            key={`${defaultItem?.name}${index}`}
                                          >
                                            <span>{defaultItem?.name}</span>
                                            <TooltipBox msg={`${t('delete')}`}>
                                              <span>
                                                <X className="ms-2" width={20} style={{ color: '#930000' }} />
                                              </span>
                                            </TooltipBox>
                                          </div>
                                        ))}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    )}

                    {otherSalaryTypes.length > 0 && (
                      <>
                        <tr className="section-divider highlight-inactive">
                          <td colSpan={4}>
                            <div>{t('PENSION_POLICY.other_salary_types')}</div>
                          </td>
                        </tr>
                        <tr className="highlight-inactive">
                          <td className="p-0" colSpan={4}>
                            <table className="table table-hover table-condensed salary-types-table-child">
                              <tbody className="classic-table-body">
                                {otherSalaryTypes?.map((salary, index) => (
                                  <tr key={index}>
                                    <td style={{ maxWidth: 100, width: 100 }}>
                                      <Form.Control value={salary.code} disabled />
                                    </td>
                                    <td style={{ maxWidth: 300, width: 300 }}>
                                      <Form.Control value={salary.name} disabled />
                                    </td>
                                    <td>
                                      <div className="d-flex">
                                        {salary?.defaults?.map((defaultItem, index) => (
                                          <div
                                            className="d-flex align-items-baseline  justify-content-between alternative-section"
                                            key={`${defaultItem?.name}${index}`}
                                          >
                                            <span>{defaultItem?.name}</span>
                                            <TooltipBox msg={`${t('delete')}`}>
                                              <span>
                                                <X className="ms-2" width={20} style={{ color: '#930000' }} />
                                              </span>
                                            </TooltipBox>
                                          </div>
                                        ))}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {!isCorrecto && (
          <Button onClick={() => companySalaryTypeEditor.addSalaryType()} className="app-btn-primary mt-4">
            <Plus className="icon-gap-right" /> {`${t('add_new_salary_type')}`}
          </Button>
        )}
      </>
    </CardComponent>
  );
}
