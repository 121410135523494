import { useContext } from 'react';
import { Settings } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { AccountingFirmClient } from '../../../api/service/accountingFirm/clients/types';
import { OverlayLoaderContext } from '../../../contexts/OverlayLoaderContext';
import classNames from 'classnames';
import TooltipBox from '../../../components/base/Overlay/TooltipBox';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AccessKey } from '../../../assets/img/access-key.svg';
import { useAccountingFirmRoleSwitcher } from './hooks/useAccountingFirmRoleSwitcher';
import useAccountingFirmAccess from '../common/hooks/useAccountingFirmAccess';

interface InputProps {
  row: AccountingFirmClient;
}

export default function RoleSwitcherColumn({ row }: InputProps) {
  const { setBusy } = useContext(OverlayLoaderContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { switchToCompanyAdmin } = useAccountingFirmRoleSwitcher();
  const { canAccessByConsultantWithStatus } = useAccountingFirmAccess();

  const canAccess = canAccessByConsultantWithStatus(row?.status, row.consultants);

  const navigateToCompanyDetails = () => {
    navigate(`/accounting-firms/${row.id}`);
  };

  return (
    <div className="row-action d-flex justify-content-end align-items-center">
      {/* {row.warningAction && (
        <span className="warning-right me-4 mb-0">
          <AlertTriangle className="delete-icon" />
        </span>
      )} */}

      <span className={classNames('mb-0', canAccess ? 'me-4' : 'me-6')} style={{ float: 'right' }}>
        <TooltipBox msg={`${t('settings')}`}>
          <Settings onClick={navigateToCompanyDetails} className="blue-icon-btn cursor-pointer" />
        </TooltipBox>
      </span>
      {canAccess && (
        <span className="me-4 mb-0">
          <TooltipBox msg={t('access')!} id={t('access')!}>
            <AccessKey
              onClick={() => switchToCompanyAdmin({ setBusy, details: row })}
              className="crt-icon-primary cursor-pointer"
            />
          </TooltipBox>
        </span>
      )}
    </div>
  );
}
