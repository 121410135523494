import { useCallback, useEffect, useMemo, useState } from 'react';
import { useValidationError } from '../../../../hooks/useValidationError';
import { AccessLevelEditor } from './types';
import { saveAccessLevel } from '../../../../api/service/company/settings';
import useAuth from '../../../../hooks/useAuth';
import { Consultants } from '../General/GeneralForms/types';
import { useEditGeneralSettingsContext } from '../General/context/useContext';
import { getCompanyClientDetails } from '../../../../api/service/superAdmin/company-clients/service';
import { isEqual } from 'lodash';

let _companyDetail = {};

const useAccessLevelEditor = (): AccessLevelEditor => {
  const { /* errors, isInvalid, */ setErrors } = useValidationError();
  const [isLoading, setIsLoading] = useState(false);
  const [consultantsAccess, setConsultantsAccess] = useState<Consultants[]>([]);
  const [hasAccessLevelChanges, setHasAccessLevelChanges] = useState(false);
  const {
    replaceAccess,
    replaceConsultants,
    allowAdvisorRemoteAccess,
    consultants,
    allowCorrectoRemoteAccess,
    deletedConsultants,
  } = useEditGeneralSettingsContext();

  const { user } = useAuth();
  const id = user?.role.company.id;
  const save = useCallback(async () => {
    try {
      setIsLoading(true);

      const paylaod = {
        allowCorrectoAccess: allowCorrectoRemoteAccess,
        allowAdvisorAccess: allowAdvisorRemoteAccess,
        deletedConsultants: deletedConsultants,
      };
      await saveAccessLevel(paylaod, id);

      // replaceConsultants(consultantsAccess);
      // replaceAccess(allowCorrectoRemoteAccess, allowAdvisorRemoteAccess);
      await fetchCompanyDetails();
    } catch (error) {
      console.error(error);

      if (error instanceof Error) {
        setErrors({ error: error.message });
      }
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, allowAdvisorRemoteAccess, allowCorrectoRemoteAccess, deletedConsultants]);

  const fetchCompanyDetails = useCallback(async () => {
    try {
      const clientDetails = await getCompanyClientDetails(id);
      const { consultants, allowAdvisorAccess, allowCorrectoAccess } = clientDetails;
      _companyDetail = structuredClone(clientDetails);
      replaceConsultants(consultants);
      replaceAccess(allowCorrectoAccess, allowAdvisorAccess);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const cancelAccessLevelChanges = useCallback(() => {
    replaceAccess(_companyDetail['allowCorrectoAccess'], _companyDetail['allowAdvisorAccess']);
    replaceConsultants(_companyDetail['consultants']);
  }, [allowAdvisorRemoteAccess, allowCorrectoRemoteAccess, consultants]);

  useMemo(
    () =>
      setHasAccessLevelChanges(
        !isEqual(_companyDetail['consultants'], consultants) ||
          !isEqual(_companyDetail['allowAdvisorAccess'], allowAdvisorRemoteAccess) ||
          !isEqual(_companyDetail['allowCorrectoAccess'], allowCorrectoRemoteAccess),
      ),
    [consultants, allowAdvisorRemoteAccess, allowCorrectoRemoteAccess],
  );

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  return {
    isLoading,
    consultantsAccess,
    save,
    setConsultantsAccess,
    cancelAccessLevelChanges,
    hasAccessLevelChanges,
  };
};

export default useAccessLevelEditor;
