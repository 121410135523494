import _isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import _merge from 'lodash/merge';
import _assignWith from 'lodash/assignWith';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import toString from 'lodash/toString';
import _set from 'lodash/set';
import _isEqual from 'lodash/isEqual';
import _compact from 'lodash/compact';
import _isObject from 'lodash/isObject';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import moment from 'moment';
import { t } from 'i18next';

export const isLightColor = (color: String) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substr(0, 2), 16);
  const c_g = parseInt(hex.substr(2, 2), 16);
  const c_b = parseInt(hex.substr(4, 2), 16);
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
  return brightness > 155;
};

export const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getNameInitials = (name: String) => {
  if (name !== null) {
    var names = name.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  } else return '';
};

export const isEmpty = (obj) => (obj ? Object.keys(obj).length === 0 : true);

export const serializeQuery = (params, prefix) => {
  const query = Object.keys(params).map((key) => {
    const value = params[key];

    if (params.constructor === Array) key = `${prefix}[]`;
    else if (params.constructor === Object) key = prefix ? `${prefix}[${key}` : key;

    if (typeof value === 'object') return serializeQuery(value, key);
    else return `${key}=${encodeURIComponent(value)}`;
  });

  return [].concat.apply([], query).join('&');
};

export const getMonthsAndYear = ({ emptyMonths = false, startFrom, noOfMonths }) => {
  const months = emptyMonths ? [] : [{ key: '', label: `${t('select')}...`, value: '' }];
  const dateStart = startFrom ? moment(startFrom) : moment();
  const dateEnd = startFrom ? moment(startFrom).add(noOfMonths, 'month') : moment().add(noOfMonths, 'month');
  while (dateEnd.diff(dateStart, 'months') >= 0) {
    const year = dateStart.year();
    dateStart.set('date', 1);
    months.push({
      key: dateStart.format('YYYY-MM-DD'),
      label: `${dateStart.format('MMM')} ${year}`,
      value: `${dateStart.format('MMM')} ${year}`,
    });
    dateStart.add(1, 'month');
  }
  return months;
};

export const getMonthName = (monthNumber) => {
  return moment(monthNumber.toString(), 'M').format('MMMM');
};

export const hasOwnProperty = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

export const findAndReplace = ({ data = [], val, replaceWith }) => {
  const dataCopy: Array<any> = [...data];
  const index = data.findIndex((el) => el['id'] === val);
  dataCopy[index] = replaceWith;
  return dataCopy;
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const hasEmptyValues = (object) => {
  return Object.values(object).some((x) => {
    if (x instanceof Object) {
      return hasEmptyValues(x);
    } else {
      return x === 0 || x === '' || x === undefined || x === null;
    }
  });
};

export const parseString = (str): string => {
  return toString(str) || '';
};

export const formatString = (input) => {
  let output = input.replace(/([A-Z])/g, ' $1').replace(/_/g, ' ');
  output = output.replace(/^[a-z]/, function (m) {
    return m.toUpperCase();
  });
  return output;
};

export const numberSpaceSeparator = (number) => {
  if (number) {
    const _sep = ' ';
    let _number = number.toString().replaceAll('.', ',');
    const minusRemovedNumber = _number.substring(1);
    _number = typeof _number != 'undefined' && number > 0 ? _number : number < 0 ? minusRemovedNumber : '';
    _number = _number
      .replace(new RegExp('^(\\d{' + (_number.length % 3 ? _number.length % 3 : 0) + '})(\\d{3})', 'g'), '$1 $2')
      .replace(/(\d{3})+?/gi, '$1 ')
      .trim();

    // eslint-disable-next-line eqeqeq
    if (typeof _sep != 'undefined' && _sep != ' ') {
      _number = _number.replace(/\s/g, _sep);
    }
    return number < 0
      ? `-${_number.replace(/\s*,/g, ',')}`
      : number === '0.00' || number === 0.0
        ? '0,00'
        : _number.replace(/\s*,/g, ',');
  } else {
    return '';
  }
};

export const clearObject = (obj) => {
  var props = Object.getOwnPropertyNames(obj);
  for (var i = 0; i < props.length; i++) {
    delete obj[props[i]];
  }
};

export const planOmittedValues = [
  'partsOver.fixedValue',
  'partsOver.percentageValue',
  'partsUnder.fixedValue',
  'partsUnder.percentageValue',
  // 'totalSalary.fixedValue',
  // 'totalSalary.percentageValue',
];

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// export const sumArrays = (...arrays) => {
//   const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
//   const result = Array.from({ length: n });
//   return result.map((_, i) => arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0));
// };

export const sumArrays = (array1, array2) => {
  var sum = array1.map(function (num, idx) {
    return {
      id: num['id'],
      effectiveOn: num['effectiveOn'],
      changeValue: Number(num['value']) + Number(array2[idx]['value']),
    };
  });

  return sum;
};

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const monthsArray = getMonthsAndYear({ emptyMonths: true, startFrom: moment().toString(), noOfMonths: 12 }).map(
  (i) => {
    if (moment(i.key).year() > moment().year()) {
      return { key: i.key, value: i.value };
    } else {
      return { key: i.key, value: i.value.split(' ')[0] };
    }
  },
);

export const fakeData = () => {
  return monthsArray.map((i) => {
    return {
      y: randomIntFromInterval(1, 100),
      x: i.value,
      key: i.key,
    };
  });
};
export const fakeChartData = () => {
  return monthsArray.map((i) => {
    return [i.value, randomIntFromInterval(1, 100)];
  });
};
export const modifyPlanValues = (plan) => {
  Object.entries(plan).forEach(([planKey, planValue]) => {
    if (typeof planValue === 'string') {
      if (planValue === '') {
        plan[planKey] = 0;
      } else {
        let formattedNumber = planValue.replaceAll(' ', '');
        formattedNumber = formattedNumber.replaceAll(',', '.');
        plan[planKey] = Number(formattedNumber);
      }
    } else if (typeof planValue === 'object') {
      modifyPlanValues(planValue);
    }
  });
};

export const modifyPlanValuesWithSeperator = (plan) => {
  Object.entries(plan).forEach(([planKey, planValue]) => {
    if (typeof planValue === 'number') {
      let formattedNumber = numberSpaceSeparator(planValue);
      plan[planKey] = formattedNumber;
    } else if (typeof planValue === 'object') {
      modifyPlanValuesWithSeperator(planValue);
    }
  });
};

export const isValueEmpty = (any) => {
  return _isEmpty(any) as boolean;
};

export const extractEmptyFromList = (arr: any[]) => {
  return arr.filter((object) => !_isEmpty(object));
};

export const cleanObject = (obj) => {
  return pickBy(obj, (value) => !isEmpty(value));
};

export const getInitialName = (fullName) => {
  if (!fullName) {
    return '';
  }
  const words = fullName.split(' ');
  let firstName = words[0].charAt(0);

  if (words.length > 1) {
    const lastName = words[words.length - 1].charAt(0);
    firstName += words.length > 2 ? lastName : '' + lastName;
  }

  return firstName;
};

export const splitFullName = (fullName) => {
  if (!fullName) {
    return {
      firstName: '',
      lastName: '',
    };
  }

  const words = fullName.trim().split(' ');
  const firstName = words.slice(0, -1).join(' ');
  const lastName = words[words.length - 1];

  return {
    firstName,
    lastName,
  };
};

export const generateUniqueId = () => {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36 string
  const randomString = Math.random().toString(36).substr(2, 5); // Generate a random string
  return timestamp + randomString;
};

export const isObjectEmpty = (obj) => {
  if (obj && obj.constructor.name === 'Object') {
    return Object.keys(obj).length === 0 ? true : false;
  }
  if (obj && obj.constructor.name === 'Array') {
    return obj.length === 0 ? true : false;
  }
  return Object.values(obj).every((value) => !!value);
};

export const isValid = (value) => {
  return value === undefined || value === '' || value.length === 0;
};

export function formatOrganizationNumber(value) {
  return value.replace(/(\d{6})-?(\d{4})/, '$1-$2');
}

export function formatPersonalNumber(value) {
  return value.replace(/(\d{8})-?(\d{4})/, '$1-$2');
}

export const deepMerge = (target: unknown, source: unknown): unknown => {
  return _merge(target, source);
};

export const isEmptyVal = (value, opt?: { allowNum: boolean }) => {
  if (value === '' || value === null || value === undefined || value === false) {
    return true;
  } else {
    if (opt?.allowNum && isNumeric(value) && value === 0) {
      return true;
    }

    return false;
  }
};

export const numberFormat = (val, opt: Partial<{ decimal: number; locale: string }> | null = null): string | null => {
  const defaultOpt = { decimal: 2, locale: 'se' };
  const locale = opt?.locale || defaultOpt.locale;
  const separator = locale === 'se' ? ',' : '.';
  const decimal = isEmptyVal(opt?.decimal) ? defaultOpt.decimal : opt?.decimal;
  let result: string;

  if (isEmptyVal(val)) {
    return null;
  }

  if (!isNumeric(val)) {
    return null;
  }

  if (locale === 'se') {
    const parts = Number(val)
      .toLocaleString('sv-SE', { minimumFractionDigits: decimal, maximumFractionDigits: decimal })
      .split(',');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const decimalPart = parts[1] ? ',' + parts[1] : '';
    result = integerPart + decimalPart;
  } else {
    result = Number(val)
      .toFixed(decimal)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      .replace(/\./g, separator);
  }

  return result;
};

export function toNumber(val, trimSeparator = true, decimal: number | null = null) {
  if (isEmptyVal(val)) {
    return null;
  }
  const trimExtraSeparator = (input) => {
    var n = 0;
    return input.replace(/\./g, () => (n++ > 0 ? '' : '.'));
  };
  val = trimExtraSeparator(val.toString().replace(/,/g, '.')).replace(/\s/g, '');
  if (trimSeparator === false) {
    if (val.length) {
      const strLength = val.length;
      if (val[0] === '.' || val[strLength - 1] === '.') {
        return val;
      }
    }
  }
  if (isNumeric(val)) {
    if (decimal) {
      val = parseFloat(val).toFixed(decimal);
    }
    return parseFloat(val);
  }
  return 0;
}

export const numberFormatToSameDecimal = (input) => {
  if (isEmptyVal(input)) {
    return null;
  }

  const inputString = typeof input === 'number' ? input.toString() : input;
  const decimalMatch = inputString.match(/\.\d+/);

  if (decimalMatch) {
    const decimalPart = decimalMatch[0];
    const decimalPlaces = decimalPart.length - 1;

    const result = numberFormat(inputString, { decimal: decimalPlaces });

    return result;
  } else {
    // If there is no decimal part, return the input as is
    return input;
  }
};

export const deepClone = (obj) => {
  try {
    if (!obj) {
      return null;
    }

    return JSON.parse(JSON.stringify(obj));
  } catch (error) {
    console.error('Unexpected JSON', obj);
    return null;
  }
};

export const excludeKeys = (data: any, excludeKeys: string[]) => {
  return omit(data, excludeKeys);
};

export const includeKeys = (data: any, includedKeys: string[]) => {
  return pick(data, includedKeys);
};

export const compareWithMaxValue = (inputValue, maxValueDigit: number = 7) => {
  const maxValueRange = Math.pow(10, maxValueDigit) - 1;

  if (!inputValue) {
    return false;
  }

  return inputValue > maxValueRange;
};

export const transformPathReducer = (obj) => {
  const result = {};

  for (const key in obj) {
    const keys = key.split('.');
    let current = result;

    for (let i = 0; i < keys.length; i++) {
      const subKey = keys[i];
      if (!current[subKey]) {
        if (i === keys.length - 1) {
          if (subKey === '0') {
            (current as any[]).push(obj[key]);
          } else {
            current[subKey] = obj[key];
          }
        } else {
          current[subKey] = isNaN(keys[i + 1] as any) ? {} : [];
        }
      } else if (Array.isArray(current[subKey])) {
        if (subKey === '0') {
          (current as any[]).push(obj[key]);
        } else {
          current[subKey].push(obj[key]);
        }
      }
      current = current[subKey];
    }
  }

  return result;
};

export const getPropByString = (obj, propString) => {
  if (!propString) return obj;

  let prop,
    props = propString.split('.');

  for (var i = 0, iLen = props.length - 1; i < iLen; i++) {
    prop = props[i];

    var candidate = obj[prop];
    if (candidate !== undefined) {
      obj = candidate;
    } else {
      break;
    }
  }
  return obj[props[i]];
};

export const setPropByPath = (obj, keyPath, value) => {
  return _set(obj, keyPath, value);
};

export const generateNumberList = (start, end) => {
  if (start > end) {
    [start, end] = [end, start];
  }

  const numberList: any = [];
  for (let i = start; i <= end; i++) {
    numberList.push(i);
  }

  return numberList;
};

export const isEqual = (val1, val2) => {
  return _isEqual(val1, val2);
};

export const sortBy = (arr: any, key: string) => {
  return _sortBy(arr, key);
};

export const normalizeCamelCase = (inputString: string) => {
  if (!inputString) {
    return '';
  }

  const stringWithSpaces = inputString.replace(/([A-Z])/g, ' $1').trim();

  return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
};

export const flattenObj = (obj) => {
  let result: any[] = [];

  function traverse(value) {
    if (Array.isArray(value)) {
      value.forEach((item) => traverse(item));
    } else if (_isObject(value)) {
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          traverse(value[key]);
        }
      }
    } else if (value !== null && value !== undefined && value !== '') {
      result.push(value.toString());
    }
  }

  traverse(obj);
  return _compact(result);
};

export const distinctListCollection = (arr: any[]) => {
  return Array.from(new Set(arr));
};

export const uniqueBy = (arr: any[], iteratee: Function | Object | string = '') => {
  return _uniqBy(arr, iteratee) as any[];
};

export const getPropValue = (obj, path) => {
  return _get(obj, path);
};

export const assignWith = (target, source, opt: any = null) => {
  const defautOpt = Object.assign(
    {
      ignoreUndefined: false,
    },
    opt,
  );

  const customizer = (objValue, srcValue) => {
    if (defautOpt?.ignoreUndefined && isEmptyVal(srcValue)) {
      return objValue;
    }

    return srcValue;
  };

  return _assignWith({}, target, source, customizer);
};
