import { Col } from 'react-bootstrap';
import { ChevronRight } from 'react-feather';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';

import { Item } from '../../../api/service/webflow/types';
import { useTranslation } from 'react-i18next';

export const InstructionBoxes = ({ instructions }: { instructions: Item[] }) => {
  const { t } = useTranslation();
  const sortedItems: Item[] = sortBy(instructions, ['order']);

  const onClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Col className="mt-2 instruction-box-wrapper">
      {sortedItems.map((item) => {
        return (
          <div
            key={item._id}
            className={classNames(' instruction-box')}
            style={{ background: `url(${item['background-image']?.url}) no-repeat`, backgroundSize: 'cover' }}
          >
            <div className="d-flex">
              <img
                height={102}
                width={116}
                src={item['post-illustration'].url}
                alt={item['post-illustration'].alt ?? 'logo'}
              />
              <div className="justify-self-center align-self-center mt-3">
                <span className="ms-2 fs-h3 theme-text">{item.name}</span>
                <br />
                <p className="ms-2 fs-text-400 theme-text">{item['body-text']}</p>
              </div>
            </div>
            <div
              onClick={(_) => onClick(item['read-more-url'])}
              className="fs-tertiery d-flex justify-content-end cursor-pointer theme-text"
              style={{ width: 'fit-content', float: 'right' }}
            >
              <ChevronRight />
              {t('read_more')}
            </div>
          </div>
        );
      })}
    </Col>
  );
};
