import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormProps } from './types';
import classNames from 'classnames';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import { errorHighlighter } from '../../../../../util/form';
export const ContractDetails = ({ context }: FormProps) => {
  const { t } = useTranslation();
  const { formGroup, updateInvoiceEmailAddress, updateInvoicingCostCenter } = context;

  return (
    <div>
      {/* <PagesHeader pageTitle="" headingClass="fs-h3 font-black" /> */}
      <div className="form-section mb-3 dark-content-section">
        <div className="cr-form-row">
          <div className="contract-effective-field">
            <Form.Label className="fs-name mb-0 required">{`${t('contracts')} ${t('effective_from')}`}</Form.Label>
            <Form.Control
              // startFrom={formGroup.values.contractEffectiveFrom}
              disabled={true}
              value={formGroup.values.contractEffectiveFrom}
              type="text"
              // onOptionSelect={(val) => updateContractEffectiveInput(val)}
              className={classNames(errorHighlighter(formGroup.getFieldMeta('contractEffectiveFrom')))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contractEffectiveFrom')} />
          </div>
          <div className="invoice-email-field">
            <Form.Label className="fs-name mb-0 required">{t('invoice_email_address')}</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              onChange={(e) => updateInvoiceEmailAddress(e.target.value)}
              name="contractEmail"
              className={classNames(errorHighlighter(formGroup.getFieldMeta(`contractEmail`)))}
              value={formGroup.values.contractEmail}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('contractEmail')} />
          </div>
          <div className="invoice-cost-field">
            <Form.Label className="fs-name mb-0 ">{t('invoicing_cost_center')}</Form.Label>
            <Form.Control
              size="lg"
              type="number"
              onChange={(e) => updateInvoicingCostCenter(e.target.value)}
              name="invoiceCostCenter"
              value={formGroup.values.invoiceCostCenter}
              className={classNames('text-left', errorHighlighter(formGroup.getFieldMeta(`invoiceCostCenter`)))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('invoiceCostCenter')} />
          </div>
        </div>
      </div>
    </div>
  );
};
