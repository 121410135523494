import isEmpty from 'lodash/isEmpty';
import { Alert, Button, Form, Spinner } from 'react-bootstrap';
import { ReactComponent as BankIDIcon } from '../../assets/img/auth/bank-id.svg';
import { ReactComponent as MobileIDIcon } from '../../assets/img/auth/mobile-id.svg';
import { GrandIDAuthStatus, UserRole } from '../../types/auth';
import useAuthenticator from './useAuthenticator';
import useAuthSessionPolling from './useAuthSessionPolling';
import MobileVerification from './MobileVerification';
import DesktopVerification from './DesktopVerification';
import { ChevronRight } from 'react-feather';

interface Props {
  pageRoleType?: UserRole;
}

function SignInPage({ pageRoleType = UserRole.COMPANY_ADMIN }: Props) {
  // const [params] = useSearchParams();
  // const sessionId = params.get('grandidsession');

  const {
    isLoading,
    isSigningIn,
    companyOptions,
    accountingFirmOptions,
    selectedRole,
    selectedAccountingFirmRole,
    sessionID,
    signInAction,
    errorMsg,
    user,

    fetchVerifiedUserDetails,
    onInitSession,
    onRetrySignIn,
    handleSelectCompanyOrAccountingFirm,
    signInBySelectedRole,
    isSingingByMobile,
    isSingingByDesktop,
    clear,
  } = useAuthenticator({ pageRoleType: pageRoleType });

  const { verificationStatus, signInScanCode } = useAuthSessionPolling({
    session: sessionID,
    method: signInAction?.method,
    onCompleteVerification: fetchVerifiedUserDetails,
  });

  return (
    <div className="auth-form-box">
      {isLoading ? (
        <div className="mt-4 text-center">
          <Spinner className="signin-loader" animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>
          <div>
            {errorMsg && (
              <>
                <Alert variant="danger" className="mt-3 p-2">
                  {errorMsg}
                </Alert>
                <div className="mt-1">
                  <Button
                    onClick={() => clear()}
                    style={{ textAlign: 'left', display: 'flex', gap: '10px' }}
                    className="app-btn-primary"
                  >
                    <ChevronRight /> Choose another login method
                  </Button>
                </div>
              </>
            )}
            {!isLoading && user['roles']?.length > 1 ? (
              <Form>
                <Form.Group className="mt-4 m-auto">
                  <h1 className="fs-h1 font-black theme-text">Welcome</h1>
                  <h2 className="fs-page-title font-black theme-text">{user['fullName']}</h2>
                  {accountingFirmOptions.length > 0 && companyOptions.length > 0 && (
                    <p className="fs-text-400 font-black theme-text">
                      You have access to two types of accounts, please select the appropriate account.
                    </p>
                  )}
                  {companyOptions.length > 0 && (
                    <Form.Group>
                      <Form.Label className="fs-text-400 font-black theme-text">Company sign in</Form.Label>
                      <Form.Select
                        defaultValue=""
                        bsPrefix="company-select"
                        className="w-100 app-btn-primary text-white px-2"
                        style={{
                          borderRight: '16px solid transparent',
                          marginBottom: isEmpty(selectedRole) ? '24px' : '0',
                        }}
                        name="signin-method"
                        value={selectedRole['name'] ?? ''}
                        onChange={(e) => handleSelectCompanyOrAccountingFirm(e, UserRole.COMPANY_ADMIN)}
                      >
                        <option value="" disabled>
                          Choose Your company
                        </option>

                        {companyOptions.map((item) => {
                          const company = item['company'];
                          return (
                            <option id={company['id']} key={company['id']} value={company['name']}>
                              {company['name'] + (item['subRole'] ? '   ( ' + item['subRole'] + ' )' : '')}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {!isEmpty(selectedRole) && (
                        <div className="text-center my-3">
                          {isSigningIn ? (
                            <Spinner />
                          ) : (
                            <Button
                              variant="primary"
                              className="app-btn-primary"
                              size="lg"
                              onClick={() => signInBySelectedRole({ type: UserRole.COMPANY_ADMIN })}
                            >
                              Sign in as {selectedRole['name']}
                            </Button>
                          )}
                        </div>
                      )}
                    </Form.Group>
                  )}
                  {accountingFirmOptions.length > 0 && (
                    <Form.Group>
                      <Form.Label className="fs-text-400 font-black theme-text">Accounting firm sign in</Form.Label>
                      <Form.Select
                        defaultValue=""
                        bsPrefix="company-select"
                        className="w-100 app-btn-primary text-white px-2"
                        style={{
                          borderRight: '16px solid transparent',
                        }}
                        name="signin-method"
                        value={selectedAccountingFirmRole?.['name'] ?? ''}
                        onChange={(e) => handleSelectCompanyOrAccountingFirm(e, UserRole.ACCOUNTING_FIRM)}
                      >
                        <option value="" disabled>
                          Choose Your company
                        </option>

                        {accountingFirmOptions.map((item) => {
                          const company = item['company'];
                          return (
                            <option id={company['id']} key={company['id']} value={company['name']}>
                              {company['name'] + (item['subRole'] ? '   ( ' + item['subRole'] + ' )' : '')}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {!isEmpty(selectedAccountingFirmRole) && (
                        <div className="text-center my-3">
                          {isSigningIn ? (
                            <Spinner />
                          ) : (
                            <Button
                              variant="primary"
                              size="lg"
                              className="app-btn-primary"
                              onClick={() => signInBySelectedRole({ type: UserRole.ACCOUNTING_FIRM })}
                            >
                              Sign in as {selectedAccountingFirmRole?.['name']}
                            </Button>
                          )}
                        </div>
                      )}
                    </Form.Group>
                  )}
                </Form.Group>
              </Form>
            ) : (
              <>
                {isSingingByMobile() && verificationStatus !== GrandIDAuthStatus.COMPLETED && (
                  <MobileVerification
                    signInScanCode={signInScanCode}
                    verificationStatus={verificationStatus!}
                    onRetrySignIn={onRetrySignIn}
                  />
                )}
                {isSingingByDesktop() && verificationStatus !== GrandIDAuthStatus.COMPLETED && (
                  <DesktopVerification verificationStatus={verificationStatus!} onRetrySignIn={onRetrySignIn} />
                )}
                {!signInAction && (
                  <Form>
                    <Form.Group className="m-auto text-left">
                      <Form.Label className="fs-4 fw-bold label-text">
                        {pageRoleType === UserRole.EMPLOYEE ? 'Sign in' : 'Sign in to Correcto'}
                      </Form.Label>
                      <div className="mb-2">
                        <Button
                          onClick={() => onInitSession('mobile')}
                          style={{ textAlign: 'left', display: 'flex', gap: '10px' }}
                          className="app-btn-primary"
                          disabled={isLoading}
                        >
                          <MobileIDIcon /> Mobilt BankID
                        </Button>
                      </div>
                      <div>
                        <Button
                          onClick={() => onInitSession('desktop')}
                          style={{
                            textAlign: 'left',
                            display: 'flex',
                            gap: '10px',
                            color: '#000',
                          }}
                          className="app-btn-secondary"
                          disabled={isLoading}
                        >
                          <BankIDIcon /> BankID (på denna enhet)
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default SignInPage;
