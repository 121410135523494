import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Breadcrumb from '../../../components/base/Breadcrumb';
import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import ReportLine from '../../../components/report/ReportLine';
import ReportTitle from '../../../components/report/ReportTitle';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './ReportOverview.module.scss';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Reports',
  },
];

function ReportOveriew() {
  const { t } = useTranslation();
  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <PagesHeader headingClass="fs-h1" pageTitle="Reports" />
      <CardComponent>
        <Row>
          <Col md="6" lg>
            <div className="mb-3">
              <ReportTitle title="salary" />
              <Link className={styles['report_link']} to="/reports/base-salary">
                <ReportLine text={t('basic_salary')} icon={true} type="ICON_VALUE" />
              </Link>
              <Link className={styles['report_link']} to="/reports/real-salary">
                <ReportLine text={t('gross_salary')} icon={true} type="ICON_VALUE" />
              </Link>
              <Link className={styles['report_link']} to="/reports/net-salary">
                <ReportLine text={t('net_salary')} icon={true} type="ICON_VALUE" />
              </Link>
              <Link className={styles['report_link']} to="/reports/salary-type">
                <ReportLine text={t('salary_types')} icon={true} type="ICON_VALUE" />
              </Link>
            </div>
            <div className="mb-3">
              <ReportTitle title="pension" />
              <Link className={styles['report_link']} to="/reports/calculated-salary-change">
                <ReportLine text={t('calculated_&_salary_change')} icon={true} type="ICON_VALUE" />
              </Link>
            </div>
          </Col>
          <Col md="6" lg>
            <div className="mb-3">
              <ReportTitle title="taxes" />
              <Link className={styles['report_link']} to="/reports/special-payroll-tax">
                <ReportLine text={t('special_payroll_tax')} icon={true} type="ICON_VALUE"></ReportLine>
              </Link>
              <Link className={styles['report_link']} to="/reports/social-tax">
                <ReportLine text={t('social_tax')} icon={true} type="ICON_VALUE"></ReportLine>
              </Link>
            </div>

            <div className="mb-3">
              <ReportTitle title="other" />
              <Link className={styles['report_link']} to="/reports/customizable">
                <ReportLine text={t('customizable')} icon={true} type="ICON_VALUE" />
              </Link>
              <Link className={styles['report_link']} to="/reports/employee-benefit">
                <ReportLine text={t('REPORTS.employee_benefits')} icon={true} type="ICON_VALUE" />
              </Link>
            </div>
          </Col>
        </Row>
      </CardComponent>
    </div>
  );
}

export default ReportOveriew;
