import { Save } from 'react-feather';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../../../components/base/Breadcrumb';
import PagesHeader from '../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import { Integrations } from './Integrations/Integrations';
import SalaryTypes from './SalaryTypes/SalaryTypes';
import { General } from './General/General';
import { getCompanyClientDetails, updateCompanyClient } from '../../../api/service/superAdmin/company-clients/service';
import { useState, useEffect } from 'react';
import { omit } from 'lodash';
import { Spinner, Button, Tabs, Tab } from 'react-bootstrap';
import { useValidationError } from '../../../hooks/useValidationError';
import { CompanyClient } from '../../../api/service/superAdmin/company-clients/types';
import ValidationAlert from '../../../components/ValidationAlert';
import useAuth from '../../../hooks/useAuth';
import { useEditGeneralSettingsContext } from './General/context/useContext';
import { Log } from './Log/Log';
import { Legal } from './Legal';
import { Invoices } from './Invoices';
import useTabChangeListener from '../../../hooks/useTabChangeListener';
import { SalaryTypeRef, WorkflowRef } from './types';
import { AccessLevel } from './AccessLevel/AccessLevel';
import Workflow from './Workflow/Workflow';
import { AccessLevelRef } from './AccessLevel/types';
import { useSearchParams } from 'react-router-dom';

import useGeneralSettingsObserver from './General/hook/useGeneralSettingsObserver';
import useGeneralForm from './General/hook/useGeneralForm';
import CancelButton from '../../../components/base/CancelButton';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Settings',
  },
];

function SettingsPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const { errors, isInvalid, setErrors } = useValidationError();
  const {
    contractEmail,
    invoiceCostCenter,
    companyDetails,
    adminDetails,
    addressDetails,
    salaryContactPeople,
    accountingContactPeople,
    contractEffectiveFrom,
    consultants,
    invitationEmail,
    contractNumber,
    combinations,
    deletedAdmins,
    formGroup,
  } = useEditGeneralSettingsContext();

  const { populateSettingsForm } = useGeneralForm();
  const { isFormModified, revertChanges } = useGeneralSettingsObserver();

  const [isBusy, setIsBusy] = useState(false);
  const [isDeleting /*setisDeleting*/] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  const [date, setDate] = useState({ createdAt: '', updatedAt: '' });

  //Ref State
  const [companySalaryTypeRef, setCompanySalaryTypeRef] = useState<SalaryTypeRef | null>();
  const [workflowRef, setWorkflowRef] = useState<WorkflowRef | null>();
  const [accessLevelRef, setAccessLevelRef] = useState<AccessLevelRef | null>();

  const { user } = useAuth();
  const id = user?.role.company.id;

  const onUpdateClient = async () => {
    try {
      const modifiedAdminDetails = adminDetails
        // .filter((item) => {
        //   return isObjectEmpty(item);
        // })
        .map((i) => omit(i, ['_id', 'valid', 'hasRecordExist']));
      const modifiedConsultants = consultants.map((i) => omit(i, ['id', 'valid']));
      const data = {
        ...companyDetails,
        address: addressDetails,
        admins: modifiedAdminDetails,
        contactPeopleForSalary: salaryContactPeople,
        contactPeopleForAccounting: accountingContactPeople,
        contractEffectiveFrom,
        invoiceEmailAddress: contractEmail,
        invoicingCostCenter: invoiceCostCenter,
        consultants: modifiedConsultants,
        invitationEmail: invitationEmail || '3',
        combinations: combinations,
        contractNumber,
        deletedAdmins,
        deletedConsultants: [],
      } as unknown as CompanyClient;

      formGroup.handleSubmit();
      formGroup.validateForm().then((errors) => {
        setErrors(errors);
      });

      if (!formGroup.isValid) {
        return false;
      }

      setisUpdating(true);
      /* console.log('datadata', data);
      return; */
      await updateCompanyClient(id, data);
      await fetchCompanyDetails();
    } finally {
      setisUpdating(false);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      setIsBusy(true);
      const clientDetails = await getCompanyClientDetails(id);
      const { createdAt, updatedAt } = clientDetails;
      populateSettingsForm(clientDetails);
      setDate({ createdAt, updatedAt });
    } finally {
      setIsBusy(false);
    }
  };

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const broadcastCompanySalaryTypeValues = (ref) => {
    setCompanySalaryTypeRef(ref);
  };

  const broadcastAccessLevelValues = (ref) => {
    setAccessLevelRef(ref);
  };

  const broadcastWorkflowChanges = (ref) => {
    setWorkflowRef(ref);
  };

  useEffect(() => {
    fetchCompanyDetails();

    const isActiveWorkflowTab = searchParams.get('tab') === 'workflow';

    if (isActiveWorkflowTab) {
      setActiveKey('workflow');
    }
  }, []);

  useTabChangeListener(activeKey);

  return (
    <div>
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader pageTitle="Settings" headingClass="fs-h1" />
        {activeKey === 'general' && (
          <div className="cr-form-row">
            {isFormModified && <CancelButton onCancel={revertChanges} />}
            <Button
              disabled={isDeleting || isUpdating || !isFormModified}
              onClick={onUpdateClient}
              className="app-btn-primary"
            >
              {isUpdating ? <Spinner size="sm" /> : <Save className="icon-gap-right" />} {`${t('save_changes')} `}
            </Button>
          </div>
        )}
        {companySalaryTypeRef && activeKey === 'salary_types' && companySalaryTypeRef?.providerType === 'company' && (
          <div className="cr-form-row">
            <Button onClick={() => companySalaryTypeRef!.saveCompanySalaryTypes()} className="app-btn-primary me-2">
              {companySalaryTypeRef!.isSavingCompanySalaryType ? (
                <Spinner size="sm" />
              ) : (
                <Save className="icon-gap-right" />
              )}{' '}
              {`${t('save')} ${t('changes')}`}
            </Button>
          </div>
        )}
        {activeKey === 'workflow' && (
          <div className="cr-form-row">
            {!workflowRef!.disableSaveChangesBtn && (
              <CancelButton onCancel={() => workflowRef!.cancelWorkFlowChanges()} btnText="cancel_settings" />
            )}
            <Button
              onClick={() => workflowRef!.saveWorkFlowChanges()}
              className="app-btn-primary"
              disabled={workflowRef!.disableSaveChangesBtn}
            >
              {workflowRef!.isSavingWorkflowChanges ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}{' '}
              {`${t('save_changes')} `}
            </Button>
          </div>
        )}
        {activeKey === 'access_level' && (
          <div className="cr-form-row">
            {accessLevelRef!.hasAccessLevelChanges && (
              <Button
                className="app-btn-secondary me-2 cancel-btn"
                onClick={() => accessLevelRef!.cancelAccessLevelChanges()}
              >{`${t('cancel_changes')}`}</Button>
            )}
            <Button
              onClick={() => accessLevelRef!.saveAccessLevel()}
              className="app-btn-primary me-2"
              disabled={!accessLevelRef!.hasAccessLevelChanges}
            >
              {accessLevelRef!.isSavingAccessLevel ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}{' '}
              {`${t('save_changes')} `}
            </Button>
          </div>
        )}
      </div>
      <>
        <ValidationAlert show={isInvalid} errors={errors} />
        <Tabs defaultActiveKey="general" onSelect={handleTabChange} activeKey={activeKey}>
          <Tab eventKey="general" title={t('general')}>
            <General date={date} isBusy={isBusy} />
          </Tab>
          <Tab eventKey="integrations" title={t('integrations')}>
            <Integrations />
          </Tab>
          <Tab eventKey="salary_types" title={t('salary_types')}>
            <SalaryTypes emitValues={broadcastCompanySalaryTypeValues} />
          </Tab>
          <Tab eventKey="workflow" title={t('work_flow')}>
            <Workflow emitValues={broadcastWorkflowChanges} />
          </Tab>
          <Tab eventKey="invoices" title={t('invoices')}>
            <Invoices />
          </Tab>
          <Tab eventKey="legal" title={t('legal')}>
            <Legal />
          </Tab>
          <Tab eventKey="access_level" title={t('access_levels')}>
            <AccessLevel emitValues={broadcastAccessLevelValues} />
          </Tab>
          <Tab eventKey="logs" title={t('logs')}>
            <Log isBusy={isBusy} />
          </Tab>
        </Tabs>
      </>
    </div>
  );
}

export default SettingsPage;
