import React from 'react';
import PagesHeader from '../../../../components/base/PagesHeader';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import { Col, Row } from 'react-bootstrap';
import Avatar from '../../../../components/avatar/Avatar';
import { UserX } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Consultants } from '../General/GeneralForms/types';
import { normalizeCamelCase } from '../../../../util/util';
import { Link } from 'react-router-dom';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

type Props = {
  consultants: Consultants[];
  handleRemoveConsultant: (item: any) => void;
};

const ConsultantAccess: React.FC<Props> = ({ consultants = [], handleRemoveConsultant }) => {
  const { t } = useTranslation();

  return (
    <div>
      <PagesHeader pageTitle="External consultants" headingClass="fs-h3 font-black" />
      <p className="theme-text">
        {' '}
        {t('ACCESS_LEVEL.correcto_certified_accounting_firms')} <Link to={'#'}>{t('ACCESS_LEVEL.read_more_here')}</Link>
        . {t('ACCESS_LEVEL.start_with_sending_request_below')}.
      </p>
      <div className="form-section mb-3 dark-content-section consultant-detail">
        {consultants.map((item, idx) => (
          <div key={item._id}>
            {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

            <Row className="align-items-center mb-3">
              <Col md="3">
                <Avatar name={item.fullName!} displayName />
              </Col>
              <Col style={{ maxWidth: 170 }}>
                <span className="theme-text fs-14px">{item.personalNumber}</span>
              </Col>

              <Col style={{ maxWidth: 250 }}>
                <span className="theme-text text-primary fs-14px">{item.accountingFirm?.name}</span>
              </Col>

              <Col style={{ maxWidth: 250 }}>
                <span className="theme-text fs-14px">{normalizeCamelCase(item.role)}</span>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <span className="me-2 mb-0">
                  <TooltipBox msg={`${t('delete_user')}`}>
                    <UserX
                      onClick={() => {
                        handleRemoveConsultant(item);
                      }}
                      className="cursor-pointer delete-icon"
                    />
                  </TooltipBox>
                </span>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConsultantAccess;
