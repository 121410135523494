import { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { UserX, Plus, UserPlus } from 'react-feather';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import PagesHeader from '../../../../components/base/PagesHeader';
import isEmpty from 'lodash/isEmpty';
import { ClientConsultant, FormProps } from '../types';
import { useTranslation } from 'react-i18next';
import { getClientConsultants } from '../../../../api/service/accountingFirm/clients/service';
import { getInitialName } from '../../../../util/util';
import useAuth from '../../../../hooks/useAuth';
import classNames from 'classnames';
import ErrorFeedback from '../../../../components/ErrorFeedback';
import { errorHighlighter, requiredError } from '../../../../util/form';
import { UserSubRole } from '../../../../types/auth';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';

interface ConsultantDataSource extends ClientConsultant {
  role: any;
  selected?: boolean;
}

export default function Consultants({ context }: FormProps) {
  const { user } = useAuth();
  const { isEditMode, clientConsultants, formGroup, replaceConsultants, replaceConsultant, appendDeletedConsultant } =
    context;
  const { t } = useTranslation();
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [consultantDataSource, setConsultantDataSource] = useState<ConsultantDataSource[]>([]);
  const [chosenConsultant, setChosenSelector] = useState<ClientConsultant>({
    personalNumber: '',
    email: '',
    fullName: '',
    accountingFirm: {
      id: '',
      name: '',
    },
    role: '',
  });
  const filteredConsultantDataSource = consultantDataSource.filter((item) => {
    const foundSelected =
      isEditMode && clientConsultants.find((consultantItem) => consultantItem.personalNumber === item.personalNumber);

    if (foundSelected) {
      return false;
    }

    if (!item.selected) {
      return true;
    }

    return false;
  });

  const fetchConsultants = async () => {
    const consultants = await getClientConsultants(user?.role.company.id);
    const consultantWithFirm = consultants.map((item) => ({
      ...item,
      accountingFirm: {
        id: user?.role.company.id,
        name: user?.role.company.name,
      },
    }));
    setConsultantDataSource(consultantWithFirm as any);
  };
  const onAddHandler = () => {
    replaceConsultant({ fullName: '', personalNumber: '' });
    setShowAddBtn((showBtn) => !showBtn);
  };
  useEffect(() => {
    fetchConsultants();
  }, [setConsultantDataSource]);
  const handleChangeConsultant = (e) => {
    const personalNumber = e.target.value;
    const foundConsultant = consultantDataSource.find((i) => i.personalNumber === personalNumber);
    replaceConsultant({ fullName: foundConsultant!.fullName, personalNumber: foundConsultant!.personalNumber });
    setChosenSelector({
      id: foundConsultant!.id,
      personalNumber: foundConsultant!.personalNumber,
      fullName: foundConsultant!.fullName,
      email: foundConsultant!.email,
      accountingFirm: {
        id: foundConsultant?.accountingFirm?.id,
        name: foundConsultant?.accountingFirm?.name,
      },
      role: foundConsultant?.role ? foundConsultant.role : UserSubRole.SENIOR_CONSULTANT,
    });
  };

  const addChosenConsultant = () => {
    setShowAddBtn(false);

    const _clientConsultants = [...clientConsultants];
    const chosenConsultantID = chosenConsultant!.id;
    replaceConsultants([
      ..._clientConsultants,
      {
        id: chosenConsultantID,
        fullName: chosenConsultant!.fullName,
        email: chosenConsultant!.email,
        personalNumber: chosenConsultant!.personalNumber,
        role: chosenConsultant!.role,
        accountingFirm: {
          id: chosenConsultant!.accountingFirm?.id,
          name: chosenConsultant!.accountingFirm?.name,
        },
      },
    ]);

    clearChosenConsultant();

    const _consultantDataSource = [...consultantDataSource];
    const foundDetail = _consultantDataSource.find((i) => i.id === chosenConsultantID);
    foundDetail!.selected = true;

    setConsultantDataSource(_consultantDataSource);
  };

  const removeConsultant = (personalNumber) => {
    const _consultantDataSource = [...consultantDataSource];
    const _clientConsultants = clientConsultants.map((consultant) => {
      return { ...consultant, selected: false };
    });

    const foundDetail = _clientConsultants.find((i) => i.personalNumber === personalNumber);
    foundDetail!.selected = false;

    setConsultantDataSource([..._consultantDataSource]);
    replaceConsultants([..._clientConsultants.filter((i) => i.personalNumber !== personalNumber)]);
  };

  const clearChosenConsultant = () => {
    setChosenSelector({
      id: '',
      personalNumber: '',
      fullName: '',
      email: '',
      accountingFirm: {},
      role: '',
    });
    formGroup.values.consultants = [];
  };

  return (
    <div>
      {/* TODO translation */}
      <PagesHeader pageTitle={'Consultants'} headingClass="fs-h3 font-black" />
      {
        <div className="form-section mb-3 dark-content-section consultant-detail">
          {(clientConsultants || []).map((item, idx) => (
            <div key={idx}>
              {idx > 0 && <HorizontalRule color="#C6C6C6" height="1px" />}

              <Row className="align-items-center mb-3">
                <Col md="3">
                  <div className="consultant-title">
                    <div className="consultant-title__avatar">
                      <span className="consultant-title-placeholder">{getInitialName(item.fullName) || ''}</span>
                    </div>
                    <div className="consultant-title__name theme-text fs-14px">{item.fullName}</div>
                  </div>
                </Col>
                <Col style={{ maxWidth: 170 }}>
                  <span className="theme-text fs-14px">{item.personalNumber}</span>
                </Col>
                <Col style={{ maxWidth: 250 }}>
                  <span className="theme-text fs-14px">{item.email}</span>
                </Col>
                <Col style={{ maxWidth: 250 }}>
                  <span className="theme-text fs-14px">
                    {item.role === 'SeniorConsultant' ? 'Consultant' : item.role}
                  </span>
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                  <span className="me-2 mb-0">
                    <TooltipBox msg={`${t('delete_user')}`}>
                      <UserX
                        onClick={() => {
                          removeConsultant(item.personalNumber);

                          appendDeletedConsultant(item.id || item._id);
                        }}
                        className="cursor-pointer delete-icon"
                      />
                    </TooltipBox>
                  </span>
                </Col>
              </Row>
            </div>
          ))}
          {showAddBtn && (
            <div>
              <div className='cr-form-row'>
                <div className='full-name-field'>
                  <Form.Label
                    className={classNames('fs-name mb-0', requiredError(formGroup.getFieldMeta(`consultant.fullName`)))}
                  >
                    {t('consultant')}
                  </Form.Label>
                  <Form.Select
                    className={classNames(errorHighlighter(formGroup.getFieldMeta(`consultant.fullName`)))}
                    value={chosenConsultant?.personalNumber}
                    name={`fullName`}
                    onChange={(e) => handleChangeConsultant(e)}
                  >
                    <option disabled value={''}>
                      {t('please_select')}
                    </option>
                    {filteredConsultantDataSource.map((item) => {
                      return (
                        <option key={item.personalNumber} value={item.personalNumber}>
                          {item.fullName}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <ErrorFeedback field={formGroup.getFieldMeta(`consultant.fullName`)} />
                </div>

                <div>
                  <Button
                    disabled={isEmpty(chosenConsultant?.personalNumber)}
                    onClick={addChosenConsultant}
                    variant="primary"
                    className="app-btn-secondary"
                  >
                    <Plus className="theme-text icon-gap-right" />
                    {<span className="theme-text">{t('add')}</span>}
                  </Button>
                </div>
              </div>
            </div>
          )}
          {!showAddBtn && (
            <Button className="app-btn-secondary" onClick={() => onAddHandler()}>
              <UserPlus className="theme-text" />
            </Button>
          )}
        </div>
      }
    </div>
  );
}
