import { ResellerAgreementMode } from "./types";

export const INITIAL_SIGNATORY_DETAILS = {
    personalNumber: '',
    fullName: '',
    email: '',
    incharge: false,
};


export const INITIAL_ADDRESS_DETAILS = {
    city: '',
    country: '',
    postcode: '',
    street: '',
};

export const INITIAL_COMPANY_DETAILS = {
    organizationNumber: '',
    name: '',
    status: 'Active',
    contractNumber: '',
    resellerAgreement: ResellerAgreementMode.CERTIFIED_PROVIDER,
    lastEdit: '',
    updatedAt: ''
};


export const FORM_INITIAL_VALUES = {
    companyDetails: INITIAL_COMPANY_DETAILS,
    address: INITIAL_ADDRESS_DETAILS,
    contractEffectiveFrom: '',
    contractEmail: '',
    invoiceCostCenter: '',
    authorizedSignatory: [{ _id: `${Math.random()}`, ...INITIAL_SIGNATORY_DETAILS, incharge: true },
    { _id: `${Math.random()}`, ...INITIAL_SIGNATORY_DETAILS },],
    combinations: [],
    // admins: [],
    // consultants: [],
    // consultant: {},
    // salaryContactPeople: { ...INITIAL_CONTACT_PEOPLE },
    // accountingContactPeople: { ...INITIAL_CONTACT_PEOPLE },
    // collectiveAgreements: [],
    // invitationEmail: INITIAL_INIVITATION_EMAIL_SETTING,
};
