import PensionPolicy from '../pages/company/Employees/EmployeePensionPolicy/PensionPolicy';
import { TabDataType } from '../pages/company/Employees/EmployeeDetails/EmployeeDetails';
import { Form, Spinner, Tab, Tabs } from 'react-bootstrap';
import useTabChangeListener from '../hooks/useTabChangeListener';
import classNames from 'classnames';
import CardComponent from '../components/base/CardComponent';
import useEmployeePensionContext from '../pages/company/Employees/useContext';
import { PensionTabKey } from '../pages/company/Employees/types';
import PensionSchedule from '../pages/company/Employees/EmployeeDetails/PensionSchedule';
import usePensionTabMode from '../pages/company/Employees/hooks/usePensionTabMode';
import useActionLoading from '../pages/company/Employees/hooks/useActionLoading';
import useArchivedPension from '../pages/company/Employees/hooks/useArchivedPension';
import ArchivedPension from '../pages/company/Employees/EmployeeDetails/ArchivedPension';
import { useTranslation } from 'react-i18next';

function PensionTab({ employee, tabKey, reset, setReset, policy, pensionDataset, insuranceDataset }: TabDataType) {
  const { setPensionTabKey, pensionTabKey } = useEmployeePensionContext();
  const { canEditSchedulePolicy } = usePensionTabMode();
  const { archivedPensionList, selectedArchivedPension, clearArchiveDetails, handleSelect } = useArchivedPension();
  const actionLoading = useActionLoading();
  const { t } = useTranslation();
  const onSelectTab = (tabKey: PensionTabKey) => {
    setPensionTabKey(tabKey);
    clearArchiveDetails();
  };

  useTabChangeListener(pensionTabKey);

  return (
    <CardComponent>
      {actionLoading.loadEmployeePension && <Spinner />}
      {!actionLoading.loadEmployeePension && (
        <>
          {archivedPensionList.length > 0 && (
            <div className="d-flex justify-content-end mb-1">
              <div className="archived-option-control" style={{ width: '250px' }}>
                <Form.Select onChange={(ev) => handleSelect(ev.target.value)}>
                  <option disabled value="" selected={selectedArchivedPension ? false : true}>
                    {`${t('archived_settings')}...`}
                  </option>
                  {archivedPensionList.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.current.policyName}
                      </option>
                    );
                  })}
                </Form.Select>
              </div>
            </div>
          )}
          <Tabs
            className="nav-border-bottom"
            activeKey={pensionTabKey}
            onSelect={(k) => onSelectTab(k as PensionTabKey)}
            defaultActiveKey="currentPolicy"
            id="crt-employee-policy-tab-container"
          >
            <Tab
              eventKey={PensionTabKey.CURRENT}
              title={`${t('current')}`}
              // disabled={canEditCurrentPolicy() ? false : true}
              tabClassName={classNames('mb-2 active-invert me-2 fs-label font-color-primary border-radius-all')}
            >
              <PensionPolicy
                policy={policy}
                employee={employee}
                tabKey={tabKey}
                reset={reset}
                setReset={setReset}
                pensionDataset={pensionDataset}
                insuranceDataset={insuranceDataset}
              />
            </Tab>
            <Tab
              eventKey={PensionTabKey.SCHEDULE}
              title={`${t('scheduled')}`}
              disabled={canEditSchedulePolicy() ? false : true}
              tabClassName={classNames('mb-2 active-invert me-2 fs-label font-color-primary border-radius-all')}
            >
              <PensionSchedule
                employee={employee}
                tabKey={tabKey}
                pensionDataset={pensionDataset}
                insuranceDataset={insuranceDataset}
              />
            </Tab>
            <Tab
              eventKey={PensionTabKey.ARCHIVE}
              title={``}
              tabClassName={classNames('mb-2 active-invert me-2 fs-label font-color-primary border-radius-all')}
            >
              <ArchivedPension employee={employee} tabKey={tabKey} />
            </Tab>
          </Tabs>
        </>
      )}
    </CardComponent>
  );
}

export default PensionTab;
