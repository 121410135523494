import * as yup from 'yup';
import { BaseStatus } from '../../../../types';

export const ProfileFormSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  personalNumber: yup.string(),
  email: yup.string(),
  status: yup.string().oneOf(Object.values(BaseStatus)),
  phoneNumber: yup.string(),
  accessLevels: yup.object().shape({
    accountingLevel: yup.array(
      yup.object().shape({
        company: yup.object({
          id: yup.string(),
          name: yup.string(),
        }),
        role: yup.array().of(yup.string()),
        organizationNumber: yup.string(),
      }),
    ),
    companyLevel: yup.array(
      yup.object().shape({
        company: yup.object({
          id: yup.string(),
          name: yup.string(),
        }),
        role: yup.array().of(yup.string()),
        organizationNumber: yup.string(),
      }),
    ),
    correcto_level: yup.array(
      yup.object().shape({
        company: yup.object({
          id: yup.string(),
          name: yup.string(),
        }),
        role: yup.array().of(yup.string()),
        organizationNumber: yup.string(),
      }),
    ),
  }),
});
