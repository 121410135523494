import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { ReactComponent as PDFIcon } from '../../../assets/img/file-PDF.svg';
import { Link } from 'react-router-dom';
import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import { ISettingInvoicesDoc } from './types';
import { getInvoicesDocuments } from '../../../api/service/company/settings';

export const Invoices = () => {
  const [invoicesDocs, setInvoicesDocs] = useState<ISettingInvoicesDoc[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAllDocuments = async () => {
    try {
      setIsLoading(true);
      const docs = await getInvoicesDocuments();
      setInvoicesDocs(docs);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllDocuments();
  }, []);

  return (
    <>
      <CardComponent>
        <PagesHeader pageTitle="Invoices" headingClass="fs-page-title" />
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="table-responsive react-bootstrap-table">
            <table className="table table-hover table-condensed">
              <tbody className="remote-table-body">
                {invoicesDocs.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="pdf-icon">
                            <PDFIcon />
                          </span>
                          <Link to="#" target="_blank" rel="noreferrer" className="fs-h3 ms-2 font-link">
                            {item.title}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </CardComponent>
    </>
  );
};
