import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';

const Page404 = () => {
  return (
    <React.Fragment>
      <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="h1">Page not found.</p>
        <p className="h2 fw-normal mt-3 mb-4">The page you are looking for might have been removed.</p>
        <Link to="/">
          <Button variant="primary" size="lg">
            Click Here to return
          </Button>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default Page404;
