import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Plus } from 'react-feather';
import moment from 'moment';
import classNames from 'classnames';
import { getInternalCalculations } from '../../../../api/service/superAdmin/settings/service';
import CardComponent from '../../../../components/base/CardComponent';
import HorizontalRule from '../../../../components/base/HorizontalRule';
import PagesHeader from '../../../../components/base/PagesHeader';
import { clearObject, deepClone } from '../../../../util/util';
import { CalcSettingsType, CalcType, TypeInternalCalculations } from './types';
import { years, INITIAL_DATA } from './constants';
import { useInternalCalculationContext } from './useContext';
import PriceInput from '../../../../components/base/PriceInput';
import useLocale from '../../../../hooks/useLocale';

function InternalCalculations() {
  const [isBusy, setIsBusy] = useState(true);
  const [activeIndex, setactiveIndex] = useState(0);
  const [showTotal, setshowTotal] = useState(4);
  const slicedYears = years.slice(activeIndex, showTotal);
  const renderedYears = slicedYears.length;
  const [formData, setFormData] = useState<CalcType>(INITIAL_DATA);
  const [settingsHeaders, setSettingsHeaders] = useState<CalcSettingsType[]>([]);
  const { localize } = useLocale();
  const {
    yearlyCalculationSettings,
    updateCalculationSettings,
    replaceCalculationSettings,
    replaceDefaultCalculationSettings,
  } = useInternalCalculationContext();

  const setTableHeaders = (res: TypeInternalCalculations[]) => {
    const initialRow = res[0];

    const sortedFormattedHeaders: CalcSettingsType[] = Object.keys(initialRow)
      .filter((key) => Object.prototype.hasOwnProperty.call(initialRow[key], 'order'))
      .sort((a, b) => initialRow[a].order - initialRow[b].order)
      .map((key) => ({
        key,
        order: initialRow[key].order as number,
        unit: initialRow[key].unit as string,
      }));

    setSettingsHeaders(sortedFormattedHeaders);

    return sortedFormattedHeaders;
  };

  const fetchIntCalc = async () => {
    try {
      const settings = await getInternalCalculations();
      const internalCalcSettings = {};
      const tableHeaders = setTableHeaders(settings);

      years.forEach((year) => {
        const settingItem = settings.find((i) => Number(i['year']) === year);

        if (settingItem) {
          internalCalcSettings[year] = settingItem;
        } else {
          const defaultDataset = Object.keys(deepClone(INITIAL_DATA)).reduce((prev, key) => {
            const item = INITIAL_DATA[key];
            const selectedHeader = tableHeaders.find((item) => item.key === key);

            return {
              ...prev,
              [key]: {
                ...item,
                ...selectedHeader,
              },
            };
          }, {}) as Partial<TypeInternalCalculations>;

          internalCalcSettings[year] = {
            year,
            effectiveDate: `${year}-01-01`,
            ...defaultDataset,
          };
        }
      });

      replaceCalculationSettings(internalCalcSettings);
      replaceDefaultCalculationSettings(internalCalcSettings);
    } finally {
      setIsBusy(false);
    }
  };

  const selectYearBoundary = () => {
    const _formData = {};

    clearObject(_formData);
    Array(renderedYears)
      .fill(1)
      .forEach((_, idx) => {
        const year = slicedYears[idx];
        const settingItem = yearlyCalculationSettings[year];

        _formData[year] = settingItem;
      });

    setFormData(_formData);
  };

  const handleChangeInput = (key, value) => {
    updateCalculationSettings(key, value);
  };

  useEffect(() => {
    fetchIntCalc();
  }, []);

  useEffect(() => {
    if (Object.keys(yearlyCalculationSettings).length) {
      selectYearBoundary();
    }
  }, [yearlyCalculationSettings, activeIndex, showTotal]);

  /* useEffect(() => {
    selectYearBoundary();
  }, [showTotal, activeIndex]); */

  const handleNext = () => {
    setactiveIndex(showTotal);
    setshowTotal(showTotal + 4);
  };

  const handlePrev = () => {
    setactiveIndex(activeIndex === 4 ? 0 : showTotal - activeIndex);
    setshowTotal(showTotal - 4);
  };

  return (
    <CardComponent>
      <PagesHeader pageTitle="Taxes, amounts and financial settings" />
      {isBusy ? (
        <Spinner />
      ) : (
        <>
          <div className="d-flex position-relative m-auto justify-content-center" style={{ paddingLeft: '17%' }}>
            <div className="d-flex align-items-baseline position-relative">
              <Button
                style={{ padding: '3px 10px 4px' }}
                onClick={handlePrev}
                disabled={activeIndex === 0}
                className="app-btn-secondary h-auto"
              >
                <ChevronLeft />
              </Button>
              <div className="fs-name font-black   theme-text">
                {years.slice(activeIndex, showTotal).map((i) => {
                  return (
                    <span key={i} className="fs-name font-black mx-5 theme-text">
                      {i}
                    </span>
                  );
                })}
              </div>

              <Button
                style={{ padding: '3px 10px 4px' }}
                disabled={showTotal > years.length}
                onClick={handleNext}
                className="app-btn-secondary h-auto"
              >
                <ChevronRight />
              </Button>
            </div>
            <div className="position-absolute" style={{ right: '0' }}>
              <Button className="app-btn-secondary h-auto" style={{ padding: '3px 10px 4px', position: 'relative' }}>
                <Plus />
              </Button>
            </div>
          </div>
          {settingsHeaders.map((int_cal, idx) => {
            return (
              <React.Fragment key={int_cal.key}>
                <hr className="bold-hr" />
                <div className="d-flex gap-3 flex-wrap-row justify-content-center align-items-center pt-2 mb-3">
                  <h3 className="text-right fs-h3 theme-text" style={{ width: 200 }}>
                    {localize(int_cal.key)}
                  </h3>

                  {Object.entries(formData).map((i) => {
                    const [key, value] = i;
                    const inputName = `${key}-${int_cal.key}`;

                    return (
                      <PriceInput
                        name={inputName}
                        disabled={moment().year() > Number(key)}
                        defaultValue={value[int_cal.key]?.value}
                        key={`${key}-${int_cal.key}-value`}
                        onChange={(value) => {
                          handleChangeInput(`${key}.${int_cal.key}.value`, value);
                        }}
                        style={{ width: 120 }}
                        maxValueDigit={7}
                        className={classNames('text-right')}
                      />
                    );
                  })}
                </div>
                <div className="d-flex gap-3  justify-content-center align-items-center pb-3">
                  <span className="text-right theme-text" style={{ width: 200 }}>
                    {localize('effective_date')}
                  </span>
                  {Object.entries(formData).map((i) => {
                    const [key, value] = i;
                    const inputName = `${key}-${int_cal.key}`;
                    return (
                      <Form.Control
                        disabled={moment().year() > Number(key)}
                        value={value.effectiveDate}
                        name={inputName}
                        key={`${key}-${int_cal.key}-effectiveDate`}
                        type="date"
                        style={{ width: 120 }}
                        onChange={(e) => {
                          handleChangeInput(`${key}.effectiveDate`, e.target.value);
                        }}
                      />
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
        </>
      )}
      <HorizontalRule />
    </CardComponent>
  );
}

export default InternalCalculations;
