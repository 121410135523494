import { useState, useEffect } from 'react';
import { omit, isEqual } from 'lodash';
import { Button, Spinner, Tab, Tabs } from 'react-bootstrap';
import { Slash, Save, Trash2, Send, ZapOff } from 'react-feather';
import { useParams, useNavigate } from 'react-router-dom';
import {
  deleteCompanyClient,
  getCompanyClientDetails,
  updateCompanyClient,
} from '../../../../api/service/superAdmin/company-clients/service';
import { CompanyClient } from '../../../../api/service/superAdmin/company-clients/types';
import Breadcrumb from '../../../../components/base/Breadcrumb';
import PagesHeader from '../../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../../types/base/BreadCrumb';
import { useEditCompanyClientContext } from '../context/useContext';
import General from './General';
import { DialogWithTextField } from '../../../../util/dialogs';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';
import { Log } from './Log/Log';
import { CompanyClientSaveMode } from '../types';
import useLocale from '../../../../hooks/useLocale';
import { splitFullName } from '../../../../util/util';
import { Legal } from './Legal';
import useTabChangeListener from '../../../../hooks/useTabChangeListener';
import AccessButton from './ActionButton';

let _companyDetail = {};
export default function CompanyClientDetail() {
  const { localize } = useLocale();
  const navigate = useNavigate();
  const { errors, isInvalid, setErrors } = useValidationError();
  const [date, setDate] = useState({ createdAt: '', updatedAt: '' });
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const [activeKey, setActiveKey] = useState('general');
  const { id } = useParams();
  const context = useEditCompanyClientContext();
  const {
    contractEmail,
    invoiceCostCenter,
    companyDetails,
    adminDetails,
    addressDetails,
    signatoryDetails,
    combinations,
    salaryContactPeople,
    accountingContactPeople,
    contractEffectiveFrom,
    collectiveAgreements,
    consultants,
    invitationEmail,
    signatoryAdmins,
    formGroup,
    deletedAdmins,
    deletedConsultants,
    replaceCompanyDetails,
    replaceConsultants,
    replaceAccountingContactPeople,
    replaceAddress,
    replaceAdminDetails,
    replaceAuthorizedSignatory,
    replaceCombinations,
    replaceSalaryContactPeople,
    updateContractEffectiveInput,
    updateInvoicingCostCenter,
    updateInvoiceEmailAddress,
    replaceCollectiveAgreements,
    replaceSignatoryAdmins,
    updateInvitationEmail,
    updateContractNumber,
    setCompanyClient,
    replaceAccessLevel,
    contractNumber,
  } = context;
  const hasChanges =
    !isEqual(_companyDetail['collectiveAgreements'], collectiveAgreements) ||
    !isEqual(_companyDetail['name'], companyDetails.name) ||
    !isEqual(_companyDetail['organizationNumber'], companyDetails.organizationNumber) ||
    !isEqual(_companyDetail['contractEffectiveFrom'], contractEffectiveFrom) ||
    !isEqual(_companyDetail['invoiceEmailAddress'], contractEmail) ||
    !isEqual(_companyDetail['invoicingCostCenter'], invoiceCostCenter) ||
    !isEqual(_companyDetail['status'], companyDetails.status) ||
    !isEqual(_companyDetail['admins'], adminDetails) ||
    !isEqual(_companyDetail['contactPeopleForSalary'], salaryContactPeople) ||
    !isEqual(_companyDetail['contactPeopleForAccounting'], accountingContactPeople) ||
    !isEqual(_companyDetail['address'], addressDetails) ||
    !isEqual(_companyDetail['consultants'], consultants) ||
    !isEqual(_companyDetail['authorizedSignatory'], signatoryDetails) ||
    !isEqual(_companyDetail['combinations'], combinations) ||
    !isEqual(_companyDetail['invitationEmail'], invitationEmail) ||
    !isEqual(_companyDetail['allowCorrectoAccess'], formGroup.values.allowCorrectoAccess) ||
    !isEqual(_companyDetail['allowAdvisorAccess'], formGroup.values.allowAdvisorAccess);

  const companyName = companyDetails ? companyDetails['name'] : '';
  const hasDraftMode = companyDetails?.status === CompanyClientSaveMode.DRAFT;
  const hasActiveMode = companyDetails?.status === CompanyClientSaveMode.ACTIVE;
  const allowCorrectoAccess = formGroup.values.allowCorrectoAccess;
  const breadcrumb: IBreadCrumb[] = [
    {
      name: 'Overview',
    },
    {
      name: 'Company Clients',
      href: '/company-clients',
    },
    {
      name: companyName,
      translate: false,
    },
  ];

  const onCancel = () => {
    replaceCompanyDetails({
      name: _companyDetail['name'],
      organizationNumber: _companyDetail['organizationNumber'],
      status: _companyDetail['status'],
    });
    replaceAddress(_companyDetail['address']);
    updateContractEffectiveInput(_companyDetail['contractEffectiveFrom']);
    updateInvoiceEmailAddress(_companyDetail['invoiceEmailAddress']);
    updateInvoicingCostCenter(_companyDetail['invoicingCostCenter']);
    updateInvitationEmail(_companyDetail['invitationEmail']);
    replaceAdminDetails(_companyDetail['admins']);
    replaceAuthorizedSignatory(_companyDetail['authorizedSignatory']);
    replaceCombinations(_companyDetail['combinations']);
    replaceAccountingContactPeople(_companyDetail['contactPeopleForAccounting']);
    replaceSalaryContactPeople(_companyDetail['contactPeopleForSalary']);
    replaceCollectiveAgreements(_companyDetail['collectiveAgreements']);
    replaceConsultants(_companyDetail['consultants']);
    setDate({ createdAt: _companyDetail['createdAt'], updatedAt: _companyDetail['updatedAt'] });
    replaceAccessLevel(_companyDetail['allowCorrectoAccess'], _companyDetail['allowAdvisorAccess']);
  };

  const onUpdateClient = async (saveMode: CompanyClientSaveMode | null = null) => {
    try {
      const modifiedAdminDetails = adminDetails.map((i) => omit(i, ['id', 'valid']));
      const modifiedConsultants = consultants.map((i) => omit(i, ['id', 'valid']));
      const data = {
        ...companyDetails,
        address: addressDetails,
        admins: [...signatoryAdmins, ...modifiedAdminDetails],
        combinations: combinations,
        contactPeopleForSalary: salaryContactPeople,
        contactPeopleForAccounting: accountingContactPeople,
        contractEffectiveFrom,
        invoiceEmailAddress: contractEmail,
        invoicingCostCenter: invoiceCostCenter,
        collectiveAgreements,
        consultants: modifiedConsultants,
        invitationEmail: invitationEmail,
        contractNumber: contractNumber,
        deletedAdmins,
        deletedConsultants,
        allowAdvisorAccess: formGroup.values.allowAdvisorAccess,
        allowCorrectoAccess: formGroup.values.allowCorrectoAccess,
      } as CompanyClient;

      if (hasDraftMode && saveMode === CompanyClientSaveMode.DRAFT) {
        data.status = CompanyClientSaveMode.DRAFT;
      } else if (
        hasDraftMode &&
        [CompanyClientSaveMode.ACTIVE, CompanyClientSaveMode.INACTIVE].indexOf(data.status as any) === -1
      ) {
        data.status = CompanyClientSaveMode.SETUP;
      }
      formGroup.handleSubmit();
      formGroup.validateForm().then((errors) => {
        setErrors(errors);
      });

      if (!formGroup.isValid) {
        return false;
      }
      if (saveMode === CompanyClientSaveMode.SETUP) {
        setisUpdating(true);
      }
      if (saveMode === CompanyClientSaveMode.DRAFT) {
        setIsBusyDrafting(true);
      }

      await updateCompanyClient(id, data);
      await fetchCompanyDetails();
      replaceSignatoryAdmins([]);
    } finally {
      if (saveMode === CompanyClientSaveMode.SETUP) {
        setisUpdating(false);
      }
      if (saveMode === CompanyClientSaveMode.DRAFT) {
        setIsBusyDrafting(false);
      }
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      setIsBusy(true);
      const clientDetails = await getCompanyClientDetails(id);
      const {
        name,
        organizationNumber,
        contractEffectiveFrom,
        address,
        admins,
        authorizedSignatory,
        combinations,
        contactPeopleForSalary,
        contactPeopleForAccounting,
        createdAt,
        updatedAt,
        collectiveAgreements,
        status,
        invoiceEmailAddress,
        invoicingCostCenter,
        consultants,
        invitationEmail,
        contractNumber,
        allowAdvisorAccess,
        allowCorrectoAccess,
      } = clientDetails;
      _companyDetail = structuredClone(clientDetails);
      setCompanyClient!(_companyDetail);
      replaceCompanyDetails({ name, organizationNumber, status });
      replaceAddress(address);
      updateContractEffectiveInput(contractEffectiveFrom);
      updateInvoiceEmailAddress(invoiceEmailAddress);
      updateInvoicingCostCenter(invoicingCostCenter);
      updateInvitationEmail(invitationEmail);
      updateContractNumber(contractNumber);
      replaceAdminDetails(admins);
      replaceAuthorizedSignatory(authorizedSignatory);
      replaceCombinations(
        combinations.map((combination, index) => {
          return {
            signee: combination.signee,
            authorizedSignatory: combination.authorizedSignatory.map((signatory) => {
              return { ...splitFullName(signatory.fullName), ...signatory };
            }),
          };
        }),
      );
      replaceAccountingContactPeople(contactPeopleForAccounting);
      replaceSalaryContactPeople(contactPeopleForSalary);
      replaceCollectiveAgreements(collectiveAgreements);
      replaceConsultants(consultants);
      setDate({ createdAt, updatedAt });
      replaceAccessLevel(allowCorrectoAccess, allowAdvisorAccess);
    } finally {
      setIsBusy(false);
    }
  };

  const onDelete = async () => {
    const title = `${localize('i_want_to_detete')} ${companyName} ${localize('forever')}`;
    const textPara = `${localize('to_delete_the')} ${localize('company')}, ${localize('type_in')}`;
    const result = await DialogWithTextField({
      title: localize('are_you_sure'),
      confirmButtonText: localize('delete_client'),
      denyButtonText: localize('cancel'),
      validationTitle: title,
      validationMessage: localize('validation_message'),
      content: {
        caption: textPara,
        body: title,
      },
      confirmBtnClass: 'confirm-delete-btn',
    });

    if (result.isConfirmed) {
      try {
        setisDeleting(true);
        await deleteCompanyClient(id ?? '');
        navigate('/company-clients');
      } finally {
        setisDeleting(false);
      }
    }
  };
  const handleTabChange = (key) => {
    setActiveKey(key);
  };
  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  useTabChangeListener(activeKey);

  return (
    <>
      <Breadcrumb navItem={breadcrumb} />
      <div className="d-flex justify-content-between">
        <PagesHeader
          headingClass="fs-h1"
          pageTitle={
            companyDetails ? (hasDraftMode ? `${companyDetails['name']} (draft)` : companyDetails['name']) : ''
          }
          translate={false}
        />
        {activeKey === 'general' && (
          <div className="cr-form-row">
            {hasChanges && (
              <Button onClick={onCancel} className="app-btn-primary cancel-btn">
                <Slash className="icon-gap-right" /> {`${localize('cancel_changes')}`}
              </Button>
            )}
            {hasDraftMode && (
              <Button
                disabled={isBusyDrafting || isDeleting || isUpdating || !hasChanges}
                onClick={() => onUpdateClient(CompanyClientSaveMode.DRAFT)}
                className="app-btn-primary"
              >
                {isBusyDrafting ? <Spinner size="sm" /> : <Save className="icon-gap-right" />}{' '}
                {`${localize('save')} ${localize('as')} ${localize('draft')}`}
              </Button>
            )}
            <Button
              disabled={isBusyDrafting || isDeleting || isUpdating}
              onClick={() => onUpdateClient(CompanyClientSaveMode.SETUP)}
              className="app-btn-primary "
            >
              {isUpdating ? (
                <Spinner size="sm" />
              ) : hasDraftMode ? (
                <Send className="icon-gap-right" />
              ) : (
                <Save className="icon-gap-right" />
              )}{' '}
              {hasDraftMode
                ? `${localize('save')} ${localize('company_clients')} ${localize('and_send_all_invites')}`
                : `${localize('save')} ${localize('changes')}`}
            </Button>
            {hasActiveMode && allowCorrectoAccess && <AccessButton id={id} />}
            {
              <Button
                disabled={isBusyDrafting || isDeleting || isUpdating}
                onClick={onDelete}
                className="app-btn-danger"
              >
                {isDeleting ? (
                  <Spinner size="sm" />
                ) : (
                  <>
                    {allowCorrectoAccess && hasActiveMode ? (
                      <ZapOff className="icon-gap-right" />
                    ) : (
                      <Trash2 className="icon-gap-right" />
                    )}
                  </>
                )}
                {allowCorrectoAccess && hasActiveMode
                  ? `${localize('inactivate_client')}`
                  : `${localize('delete')} ${localize('client')}`}
              </Button>
            }
          </div>
        )}
      </div>
      <ValidationAlert show={isInvalid} errors={errors} />
      <Tabs defaultActiveKey="general" onSelect={handleTabChange}>
        <Tab eventKey="general" title={localize('general')}>
          <General isBusy={isBusy} date={date} />
        </Tab>
        {!hasDraftMode && <Tab eventKey="billing" title={localize('billing')}></Tab>}
        {!hasDraftMode && (
          <Tab eventKey="legal" title={localize('legal')}>
            <Legal />
          </Tab>
        )}
        {!hasDraftMode && (
          <Tab eventKey="log" title={localize('log')}>
            <Log isBusy={isBusy} />
          </Tab>
        )}
        {!hasDraftMode && <Tab eventKey="uploaded_material" title={localize('uploaded_material')}></Tab>}
      </Tabs>
    </>
  );
}
