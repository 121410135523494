import CardComponent from '../../../../components/base/CardComponent';
import InsuranceAdvisor from './InsuranceAdvisor';
import CorrectoAdministrator from './CorrectorAdministrator';
import useGeneralSettingsEditor from './useGeneralSettingsEditor';

interface InputProps {
  emitValues: (...args) => void;
}

export default function General({ emitValues }: InputProps) {
  const generalSettingsEditor = useGeneralSettingsEditor();

  return (
    <>
      <CardComponent>
        <CorrectoAdministrator generalSettingsEditor={generalSettingsEditor} emitValues={emitValues} />
        <InsuranceAdvisor generalSettingsEditor={generalSettingsEditor} emitValues={emitValues} />
      </CardComponent>
    </>
  );
}
