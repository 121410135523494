import { useCallback, useState } from 'react';
import { IntegrationEditor, IIntegrations, Mode, IntegrationSaveMode } from '../types';
import { useValidationError } from '../../../../hooks/useValidationError';
import { ConfirmationDialog } from '../../../../util/dialogs';
import { t } from 'i18next';
import { deepClone, isObjectEmpty } from '../../../../util/util';
import useContext from './context/useContext';
import { useSuperAdminSettingsContext } from '../useContext';
import {
  deleteIntegration,
  getIntegrationDetail,
  getIntegrations,
  updateIntegration,
} from '../../../../api/service/superAdmin/settings/integrations';

const useIntegrationEditor = (): IntegrationEditor => {
  const { /* errors, isInvalid, */ errors, setErrors } = useValidationError();
  const { setMode } = useSuperAdminSettingsContext();

  const {
    formGroup,
    replaceIntegrations,
    validateDraftForm,
    validateForm,
    integrations,
    replaceIntegrationInformation,
    replaceTypeOfIntegration,
    selectedIntegrationID,
    setSelectedIntegrationID,
    integrationSaveMode,
    setIntegrationSaveMode,
  } = useContext();
  const [, /*initialIntegrations*/ setInitialIntegrations] = useState<IIntegrations[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isBusyDrafting, setIsBusyDrafting] = useState(false);
  const [, /*isSaving*/ setIsSaving] = useState(false);
  const [showIntegrationCreateModal, setShowIntegrationCreateModal] = useState<boolean>(false);

  const toggleIntegrationModal = useCallback(() => {
    setShowIntegrationCreateModal(!showIntegrationCreateModal);
  }, [showIntegrationCreateModal]);

  const fetchAll = useCallback(async () => {
    try {
      setIsLoading(true);
      const _integration = await getIntegrations();
      replaceIntegrations(_integration);
      setInitialIntegrations(deepClone(_integration));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, integrations]);

  const removeIntegration = useCallback(
    async (id) => {
      const title = 'Are you sure you want to remove that integration?';
      const result = await ConfirmationDialog({
        title: title,
        confirmButtonText: t('yes'),
        denyButtonText: t('no'),
      });

      if (result.isConfirmed) {
        await deleteIntegration(id);
        setMode(Mode.List);
      }
    },
    [integrations],
  );

  const setIntegrationDetail = useCallback(
    async (id) => {
      setSelectedIntegrationID(id);
      const integrationDetail = (await getIntegrationDetail(id)) ?? [];
      const _integrationInformation = {
        shortName: integrationDetail['shortName'],
        companyName: integrationDetail['companyName'],
        status: integrationDetail['status'],
        instruction: integrationDetail['instruction'],
      };
      const _typeOfIntegration = {
        companyApi: integrationDetail['companyApi'],
        channel: integrationDetail['channel'],
        apiKey: integrationDetail['apiKey'],
        apiKey2: integrationDetail['apiKey2'],
      };
      setIntegrationSaveMode(integrationDetail['status']);
      replaceIntegrationInformation(_integrationInformation);
      replaceTypeOfIntegration(_typeOfIntegration);

      setMode(Mode.Detail);
    },
    [formGroup.values, integrations, selectedIntegrationID, integrationSaveMode],
  );

  const updateIntegrationDetail = useCallback(
    async (saveMode, id) => {
      const { integrationInformation, typeOfIntegration } = formGroup.values;
      const data = { ...integrationInformation, ...typeOfIntegration, status: saveMode };
      const isDraftMode = saveMode === IntegrationSaveMode.DRAFT;

      if (isDraftMode) {
        const formValidator = await validateDraftForm();
        if (!formValidator.valid) {
          setErrors({ error: 'Please fill in the required fields' });
          return false;
        }
      } else {
        validateForm().then((errors) => {
          if (!isObjectEmpty(errors)) {
            setErrors({ error: 'Please fill in the required fields' });
          }
        });
        if (!formGroup.isValid) {
          setIsBusy(false);
          setIsBusyDrafting(false);
          return false;
        }
      }

      try {
        setIsSaving(true);
        await updateIntegration(id, data);
        setMode(Mode.List);
      } catch (error) {
        if (error instanceof Error) {
          setErrors({ error: error.message });
        }
      } finally {
        setIsSaving(false);
        setErrors({});
      }
    },
    [selectedIntegrationID, formGroup.values, integrations, errors, isBusy, isBusyDrafting],
  );

  return {
    integrations,
    isLoading,
    removeIntegration,
    fetchAll,
    updateIntegrationDetail,
    toggleIntegrationModal,
    showIntegrationCreateModal,
    isBusy,
    isBusyDrafting,
    setIntegrationDetail,
    selectedIntegrationID,
    formGroup,
    integrationSaveMode,
    errors,
  };
};

export default useIntegrationEditor;
