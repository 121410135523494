import { useCallback } from 'react';
import useEmployeePensionContext from '../useContext';
import { ActionMode } from '../../../superAdmin/PensionPolicy/types';

const usePensionMode = () => {
  const { employeePensionSummary, employeePolicyRevision } = useEmployeePensionContext();

  const hasOnlyScheduled = useCallback(() => {
    if (employeePensionSummary) {
      if (employeePensionSummary.schedule) {
        return true;
      }

      return false;
    }

    return false;
  }, [employeePensionSummary]);

  const hasCurrentPolicyExist = useCallback(() => {
    if (employeePensionSummary) {
      if (employeePensionSummary.current) {
        return true;
      }

      return false;
    }

    return false;
  }, [employeePensionSummary]);

  const hasReadOnlyCurrentPolicy = useCallback(() => {
    if (employeePensionSummary && employeePensionSummary.schedule) {
      return true;
    }

    return false;
  }, [employeePensionSummary]);

  const hasDisabledOnEditMode = useCallback(() => {
    if (employeePensionSummary && employeePensionSummary.schedule) {
      return true;
    }

    if (
      employeePensionSummary &&
      employeePensionSummary?.current &&
      employeePensionSummary?.current?.id === employeePolicyRevision?.currentPolicy?.id
    ) {
      return true;
    }

    return false;
  }, [employeePensionSummary, employeePolicyRevision]);

  const hasEditableCurrentPolicy = useCallback(() => {
    if (employeePensionSummary && employeePensionSummary.schedule) {
      return false;
    }

    return true;
  }, [employeePensionSummary]);

  const actionModeCurrentPolicy = useCallback(() => {
    if (employeePensionSummary && employeePensionSummary.schedule) {
      return ActionMode.VIEW;
    }

    return ActionMode.ADD;
  }, [employeePensionSummary]);

  const canVisibleEditPolicyLink = useCallback(() => {
    if (employeePensionSummary && employeePensionSummary.schedule) {
      return false;
    }

    return true;
  }, [employeePensionSummary]);

  return {
    hasOnlyScheduled,
    hasCurrentPolicyExist,
    hasReadOnlyCurrentPolicy,
    hasEditableCurrentPolicy,
    hasDisabledOnEditMode,
    actionModeCurrentPolicy,
    canVisibleEditPolicyLink,
  };
};

export default usePensionMode;
