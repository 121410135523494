import React, { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import classNames from 'classnames';

import Wrapper from '../components/Wrapper';
import Sidebar from '../components/sidebar/Sidebar';
import Main from '../components/Main';
import Navbar from '../components/navbar/Navbar';
import Content from '../components/Content';
import CorrectoLogo from '../assets/img/c-logo.svg';
import CompanyLogo from '../assets/img/company-logo-sample.png';

import {
  companyNavItems,
  employeeNavItems,
  superAdminNavItems,
  accountingFirmNavItems,
} from '../components/sidebar/sidebar_routes/sidebarRouteItems';
import { UserRole, UserSubRole } from '../types/auth';
import useAuth from '../hooks/useAuth';
import ErrorBoundary from '../components/error/error-boundary';

const Dashboard = ({ children }: React.PropsWithChildren) => {
  const { user } = useAuth();
  const [content, setContent] = useState(employeeNavItems);

  const getLogo = useCallback(() => {
    const userRole = user?.role?.role;

    if (!userRole || [UserRole.EMPLOYEE, UserRole.SUPER_ADMIN, UserRole.ACCOUNTING_FIRM].includes(userRole)) {
      return CorrectoLogo;
    } else {
      return CompanyLogo;
    }
  }, [user]);

  const logo = getLogo();
  const isHorizontalDashboard = user?.role.role === UserRole.ACCOUNTING_FIRM;

  useEffect(() => {
    const getContent = () => {
      if (user?.role.role === UserRole.EMPLOYEE) {
        setContent(employeeNavItems);
      } else if (user?.role.role === UserRole.COMPANY_ADMIN) {
        setContent(companyNavItems);
      } else if (user?.role.role === UserRole.ACCOUNTING_FIRM) {
        setContent(
          accountingFirmNavItems.map((item) => {
            item.pages = (item as any).pages.filter((menuItem) => {
              /* if (!menuItem.subRoles || !(user.role as any).subRole) {
                return true;
              } */
              return menuItem.subRoles.indexOf((user.role as any).subRole || UserSubRole.SUPERVISOR) !== -1;
            });

            return item;
          }),
        );
      } else {
        setContent(superAdminNavItems);
      }
    };

    getContent();
  }, [user]);

  return (
    <React.Fragment>
      <div className={classNames('main-dashboard', { 'main-dashboard--horizontal': isHorizontalDashboard })}>
        <Wrapper>
          {!isHorizontalDashboard && <Sidebar logo={logo} items={content} />}
          <Main>
            <Navbar user={user} roleType={user?.role.role} items={content} logo={logo} />
            <Content>
              {children}
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </Content>
            {/* <Footer /> */}
          </Main>
        </Wrapper>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
