import { Form, Spinner } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import { AdminDetails, AuthorizedSignatory, CompanyDetails, ContractDetails, Invitation } from '../CompanyClientForms';
import { useEditCompanyClientContext } from '../context/useContext';
import { DateType } from '../types';
import ExternalConsultant from '../CompanyClientForms/Consultants';
import { AccessLevel } from '../CompanyClientForms/Access';
import { FileUpload } from '../CompanyClientForms/FileUpload';

type Props = {
  isBusy: boolean;
  date: DateType;
};

export default function General({ isBusy, date }: Props) {
  const context = useEditCompanyClientContext();

  return (
    <>
      <CardComponent>
        {isBusy ? (
          <Spinner />
        ) : (
          <Form>
            <CompanyDetails context={context} date={date} formGroup={context.formGroup} />

            <ContractDetails context={context} formGroup={context.formGroup} />

            <AuthorizedSignatory context={context} formGroup={context.formGroup} />

            <AdminDetails context={context} formGroup={context.formGroup} />

            <ExternalConsultant context={context} formGroup={context.formGroup} />

            {/* <ContactPeople context={context} formGroup={context.formGroup} /> */}
            <AccessLevel context={context} formGroup={context.formGroup} />

            <FileUpload context={context} formGroup={context.formGroup} />

            <Invitation context={context} formGroup={context.formGroup} />
          </Form>
        )}
      </CardComponent>
      {/* <CardComponent>
        <div className="d-flex justify-content-end">
          <Button onClick={onCancel} className="app-btn-primary me-2">
            <Slash className="icon-gap-right cancel-btn" /> Cancel changes
          </Button>
          <Button className="app-btn-primary">
            <Save className="icon-gap-right" /> Save changes
          </Button>
        </div>
      </CardComponent> */}
    </>
  );
}
