import { ORGANIZATION_NUMBER_DIGITS } from '../../../config/config';
import InputMask from 'react-input-mask';
import { Form } from 'react-bootstrap';
import ErrorFeedback from '../../ErrorFeedback';
import classNames from 'classnames';
import { errorHighlighter, requiredError } from '../../../util/form';
import useLocale from '../../../hooks/useLocale';
import { isValueEmpty } from '../../../util/util';
interface OrganizationNumberProps {
  disabled?: boolean;
  field?: any;
  value?: string;
  customLabel?: string;
  isValid?: boolean;
  lazy?: boolean;
  isRequiredError?: boolean;
  onChangeHandler: (e) => void;
}
export const OrganizationNumber = ({
  field,
  disabled,
  value,
  customLabel,
  isValid,
  lazy,
  isRequiredError = false,
  onChangeHandler,
}: OrganizationNumberProps) => {
  const { localize } = useLocale();
  const formatOrgNumber = {
    P: '[2|5|7|8|9]',
    '0': '[0-9]',
  };
  return (
    <>
      <div className="organization-num-field">
        <Form.Label
          className={classNames(
            'fs-name',
            'mb-0 ',
            `${isRequiredError ? requiredError(field) : field ? 'required required--sub' : ''}`,
          )}
        >
          {customLabel ? customLabel : `${localize('organization_number')}`}{' '}
          <span className={'fs-name font-weight-400 theme-text'}>
            {`(${ORGANIZATION_NUMBER_DIGITS} ${localize('digits')})`}
          </span>
        </Form.Label>
        <InputMask
          mask="P00000-0000"
          value={value}
          onChange={onChangeHandler}
          disabled={disabled}
          name="organizationNumber"
          maskChar=""
          formatChars={formatOrgNumber}
          className={classNames('form-control', field ? errorHighlighter(field) : '')}
        ></InputMask>
        {field && <ErrorFeedback field={field} />}

        {lazy && !isValueEmpty(value) && !isValid && (
          <span className={'color-error'}>Organization number not found</span>
        )}
      </div>

      {/* <BaseInput
        onChangeHandler={onChangeHandler}
        value={value}
        disabled={disabled}
        name="organizationNumber"
        maxWidth={190}
        label={`${t('organization_number')}`}
        labelSuffix={`(${ORGANIZATION_NUMBER_DIGITS} ${t('digits')})`}
        field={field}
      /> */}
    </>
  );
};
