import { createContext, useState } from 'react';
import {
  INITIAL_CONSULTANTS_DETAILS,
  INITIAL_PERSONAL_DETAILS,
  INITIAL_STATISTICS,
  INITIAL_USER_SETTINGS,
} from '../contants';
import { Consultants, ConsultantsFormGroup, ICompanyConsultantContext, LooseObject } from '../types';
import { PersonalDetails, Statistics, UserSettings } from '../types';
import { useFormik } from 'formik';
import { formSchema } from './schema';

export const CreateConsultantContext = createContext<ICompanyConsultantContext>({
  consultantDetailRes: [],
  setConsultantDetailRes: () => {},

  consultants: [],
  setConsultants: () => {},

  personalDetails: INITIAL_PERSONAL_DETAILS,
  setPersonalDetails: () => {},

  userSettings: INITIAL_USER_SETTINGS,
  setUserSettings: () => {},

  statistics: INITIAL_STATISTICS,
  setStatistics: () => {},

  position: '',
  setPosition: () => {},

  formGroup: {} as ConsultantsFormGroup,

  isEditMode: false,

  updateConsultantInput: () => {},
  replaceConsultantInput: () => {},
  patchConsultantForm: () => {},
});

type Props = {
  children: JSX.Element;
};

export const CreateConsultantProvider = ({ children }: Props) => {
  const [consultantDetailRes, setConsultantDetailRes] = useState<any>(null);
  const [personalDetails, setPersonalDetails] = useState<PersonalDetails | LooseObject>(INITIAL_PERSONAL_DETAILS);
  const [statistics, setStatistics] = useState<Statistics | LooseObject>(INITIAL_STATISTICS);
  const [userSettings, setUserSettings] = useState<UserSettings | LooseObject>(INITIAL_USER_SETTINGS);
  const [consultants, setConsultants] = useState<Array<Consultants>>([
    { _id: `${Math.random()}`, ...INITIAL_CONSULTANTS_DETAILS },
  ]);
  const [position, setPosition] = useState<string>('');

  const formGroup = useFormik({
    initialValues: INITIAL_PERSONAL_DETAILS,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const updateConsultantInput = (inputName, inputValue) => {
    formGroup.setFieldValue(inputName, inputValue);
    setPersonalDetails((value) => ({
      ...value,
      [inputName]: inputValue,
    }));
  };

  const replaceConsultantInput = (value) => {
    setPersonalDetails({ value });
    formGroup.setValues(value);
  };
  const patchConsultantForm = (patchValue) => {
    setPersonalDetails((value) => ({
      ...value,
      fullName: patchValue.fullName,
      firstName: patchValue.firstName,
      lastName: patchValue.lastName,
      email: patchValue.email,
    }));
    formGroup.setValues((value) => ({
      ...value,
      fullName: patchValue.fullName,
      firstName: patchValue.firstName,
      lastName: patchValue.lastName,
      email: patchValue.email,
    }));
  };
  const context: ICompanyConsultantContext = {
    consultants,
    setConsultants,
    personalDetails,
    setPersonalDetails,
    userSettings,
    setUserSettings,
    statistics,
    setStatistics,
    position,
    setPosition,
    isEditMode: false,
    formGroup,
    updateConsultantInput,
    replaceConsultantInput,
    patchConsultantForm,
    consultantDetailRes,
    setConsultantDetailRes,
  };

  return <CreateConsultantContext.Provider value={context}>{children}</CreateConsultantContext.Provider>;
};
