import { Form, Spinner } from 'react-bootstrap';
import CardComponent from '../../../../components/base/CardComponent';
import { useEditConsultantContext } from '../context/useContext';
import { DateType } from '../types';
import PersonalDetails from '../ConsultantForms/PersonalDetails';
import Statistics from '../ConsultantForms/Statistics';

type Props = {
  isBusy: boolean;
  date: DateType;
};

export default function General({ isBusy, date }: Props) {
  const context = useEditConsultantContext();

  return (
    <>
      <CardComponent>
        {isBusy ? (
          <Spinner />
        ) : (
          <Form>
            <Statistics context={context} date={date} formGroup={context.formGroup} />
            <PersonalDetails context={context} formGroup={context.formGroup} />
            {/* <UserSettings context={context} /> */}
          </Form>
        )}
      </CardComponent>
      {/* <CardComponent>
        <div className="d-flex justify-content-end">
          <Button onClick={onCancel} className="app-btn-primary me-2 cancel-btn">
            <Slash className="icon-gap-right" /> Cancel changes
          </Button>
          <Button className="app-btn-primary">
            <Save className="icon-gap-right" /> Save changes
          </Button>
        </div>
      </CardComponent> */}
    </>
  );
}
