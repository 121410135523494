import * as yup from 'yup';
import { personalNumberValidations } from '../../../../util/validation';

export const formSchema = yup.object().shape({
  personalNumber: personalNumberValidations(),
  address: yup.object().shape({
    street: yup.string().required('Required'),
    city: yup.string().required('Required'),
    postcode: yup.string().required('Required'),
    country: yup.string().required('Required'),
  }),
  fullName: yup.string().required('Required'),
  phoneNumber: yup.object().shape({
    personal: yup.string().required('Required'),
    private: yup.string(),
  }),
  email: yup.string().email('Email must be a valid').required('Required'),
  alternateEmail: yup.string().email('Email must be a valid'),
});
