import { deepClone, deepMerge } from '../../../util/util';
import { defaultChartOption, soundscapeDarkModeOption, soundscapeLightModeOption } from './multi-series.config';
import { ISeries, ISeriesMetaOptions, ISeriesOptions } from './types';
import { padMonthWithZeros } from '../utils';

export class SeriesAdapter {
  static readonly TOTAL_FIELD_VALUE = 'total';

  static colorStyle = {
    seriesColors: [
      { defaultColor: '#01b8aa', darkColor: '#01b8aa' },
      { defaultColor: '#fd625e', darkColor: '#fd625e' },
      { defaultColor: '#ddb60a', darkColor: '#ddb60a' },
      { defaultColor: '#26a4eb', darkColor: '#26a4eb' },
      { defaultColor: '#f160e3', darkColor: '#f160e3' },
      { defaultColor: '#ff7f0e', darkColor: '#ff7f0e' },
      { defaultColor: '#2ca02c', darkColor: '#2ca02c' },
      { defaultColor: '#d62728', darkColor: '#d62728' },
      { defaultColor: '#9467bd', darkColor: '#9467bd' },
      { defaultColor: '#8c564b', darkColor: '#8c564b' },
      { defaultColor: '#800000', darkColor: '#FF8C00' },
    ],
    preset: {
      defaultColor: '#800000',
      darkColor: '#FF8C00',
    },
  };

  static load(opt: ISeriesOptions, data: any[], dateRange: [string | Date, string | Date]): SeriesOptionBuilder {
    const { series: selectedSeries, totalSeriesItem, sourceSeries } = this.getSeries(opt.series, opt);

    opt.series = selectedSeries;

    return new SeriesOptionBuilder({
      ...opt,
      data: data ? padMonthWithZeros(dateRange, data, { colLength: selectedSeries.length }) : [],
      totalSeriesItem,
      sourceSeries,
    });
  }

  private static getSeries(series: ISeries[], opt: ISeriesOptions) {
    const totalSeries: ISeries = this.getTotalSeries();

    const styledSeries = this.applyStyles(series, opt);
    const transformedSeries = styledSeries.map((item, index) => {
      if (item.value) {
        item.seriesIndex = index;

        return item;
      }

      if (typeof item.checked !== 'boolean') {
        item.checked = true;
      }
      item.value = index.toString();
      item.seriesIndex = index;

      return item;
    });

    const sourceSeries = deepClone(transformedSeries);

    return {
      sourceSeries: sourceSeries,
      series: transformedSeries,
      totalSeriesItem: totalSeries,
    };
  }

  private static applyStyles(series: ISeries[], opt: ISeriesOptions) {
    const usedColors = series.map((item) => item.style);
    const availableColors = this.colorStyle.seriesColors.filter((item) => {
      return !usedColors.find((usedItem) => usedItem?.defaultColor === item.defaultColor);
    });

    return series.map((item, index) => {
      if (item.value) {
        return item;
      }

      const colorStyle = availableColors[0] ?? this.colorStyle.preset;

      item.style = { ...(item.style || {}), ...colorStyle };
      item.color = item.style.defaultColor;

      availableColors.shift();

      return item;
    });
  }

  private static getTotalSeries(): ISeries {
    return { label: 'Total', field: this.TOTAL_FIELD_VALUE, checked: false };
  }

  private static insertSeriesTotal(totalSeries: ISeries, transformedSeries: ISeries[], opt: ISeriesOptions) {
    if (this.hasExistSeriesTotal(transformedSeries)) {
      return;
    }

    if (opt.showTotalAfterSeries) {
      transformedSeries.push(totalSeries);
    } else {
      transformedSeries.unshift(totalSeries);
    }
  }

  private static hasExistSeriesTotal(series: ISeries[]) {
    return series.find((item) => item.field === this.TOTAL_FIELD_VALUE);
  }
}

export class SeriesOptionBuilder {
  header: string[] = [];
  series: ISeries[] = [];
  sourceSeries: ISeries[] = [];
  totalSeriesItem: ISeries;
  showTotalAfterSeries = false;
  data: any[] = [];
  initialChecked: string[] = [];
  shouldToggleTotal = false;
  allowDeleteSeries = false;
  allowAddSeries = false;
  showToggleButtons = false;
  lightModeConfig;
  darktModeConfig;
  seriesLimit;
  onSelectEmployeeSeries

  constructor(opt: ISeriesMetaOptions) {
    Object.assign(this, opt);

    this.totalSeriesItem = opt.totalSeriesItem;

    this.setInitial();
    this.setHeader();
    this.setConfig();
  }

  setHeader() {
    this.header = ['Time', ...this.series.map((item) => item.label)];

    this.data.unshift(this.header);
  }

  setInitial() {
    this.initialChecked = this.series.filter((item) => item.checked).map((item) => item.field);
  }

  config(chartOptionSource) {
    return {
      ...chartOptionSource,
      series: this.series.map((item) => {
        return {
          type: 'line',
          visilbe: true,
          threshold: 32,
          opacity: 0.8,
          yAxis: 1,
          fillOpacity: 1,
          marker: {
            symbol: 'circle',
          },
          color: item.color,
        };
      }),
    };
  }

  setConfig() {
    this.lightModeConfig = deepMerge(
      {
        ...soundscapeLightModeOption,
        plotOptions: {
          series: {
            showInNavigator: true,
            showInLegend: true,
            events: {
              click: (event, ds) => {
                const seriesName = event.point.series.name;
                if (this.onSelectEmployeeSeries) {
                  this.onSelectEmployeeSeries(seriesName)
                }

              }
            }
          }
        },
        series: this.series.map((item) => {
          return {
            type: 'line',
            visilbe: true,
            threshold: 32,
            opacity: 0.8,
            yAxis: 1,
            fillOpacity: 1,
            marker: {
              symbol: 'circle',
            },
            color: item.color,
          };
        }),
      },
      defaultChartOption,
    );

    this.darktModeConfig = deepMerge(
      {
        ...soundscapeDarkModeOption,
        series: this.series.map((item) => {
          return {
            type: 'line',
            visilbe: true,
            threshold: 32,
            opacity: 0.8,
            yAxis: 1,
            fillOpacity: 1,
            marker: {
              symbol: 'circle',
            },
            color: item.color,
          };
        }),
      },
      defaultChartOption,
    );
  }
}
