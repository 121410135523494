// import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import PagesHeader from '../../../../../components/base/PagesHeader';
// import ErrorFeedback from '../../../../../components/ErrorFeedback';
// import { errorHighlighter } from '../../../../../util/form';
import { AccountingFirmSaveMode, FormProps } from '../types';
import useLocale from '../../../../../hooks/useLocale';
import moment from 'moment';

export default function ContractDetails({ context }: FormProps) {
  const { localize } = useLocale();
  const { contractEffectiveFrom, companyDetails, isEditMode } = context;
  return (
    <div>
      {!isEditMode ||
        (companyDetails?.status === AccountingFirmSaveMode.DRAFT && (
          <PagesHeader pageTitle="Contract details" headingClass="fs-h3 font-black" />
        ))}
      <div className="form-section mb-3 dark-content-section">
        <div className="d-flex align-items-sm-end mb-3 flex-wrap-row">
          <div className="contract-effective-field">
            <Form.Label className="fs-name mb-0 ">
              {`${localize('contracts')} ${localize('effective_from')}`}
            </Form.Label>
            <Form.Control
              // startFrom={contractEffectiveFrom}
              value={moment(contractEffectiveFrom).format('MMM YYYY')}
              disabled={true}
              type="text"
              // onOptionSelect={(val) => {
              //   updateContractEffectiveInput(val);
              // }}
              // disabled={true}
            />
          </div>
          {/* <Col style={{ maxWidth: 220 }}>
            <Form.Label className="fs-name mb-0 ">
              {'Correcto '}
              {localize('invoice_email_address')}
            </Form.Label>
            <Form.Control
              size="lg"
              type="email"
              onChange={(e) => updateInvoiceEmailAddress(e.target.value)}
              name="contractEmail"
              value={contractEmail}
              // className={classNames(errorHighlighter(formGroup.getFieldMeta('invoiceEmailAddress')))}
            />
          </Col> */}
          {/* <Col style={{ maxWidth: 220 }}>
            <Form.Label className="fs-name mb-0">
              {'Correcto '}
              {localize('invoicing_cost_center')}
            </Form.Label>
            <Form.Control
              size="lg"
              type="number"
              onChange={(e) => updateInvoicingCostCenter(e.target.value)}
              name="poNumber"
              required
              // className={classNames('text-left', errorHighlighter(formGroup.getFieldMeta('invoicingCostCenter')))}
              value={invoiceCostCenter}
            />
          </Col> */}
        </div>
      </div>
    </div>
  );
}
