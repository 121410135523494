import { useCallback, useEffect, useState } from 'react';
import { getInsurancesByAgreement } from '../../../../api/service/superAdmin/settings/insurances';
import useContext from '../PensionPolicyContext/useContext';
import { deepClone, generateUniqueId } from '../../../../util/util';
import { INSURANCE_CATEGORIES } from '../constants';
import { TypeInsurances } from '../types';
import { IInsurance } from '../../AdminSettings/types';
import usePolicyMode from './usePolicyMode';

interface InputProps {
  hasExternalSource?: boolean;
  remoteDataSource?: IInsurance[] | null;
}

interface IAssignedMetaInsurancePlan {
  insuranceId: string;
  metaDetails: any;
  assignedPlanTypes: Array<{
    label?: string;
    categoryName: string;
    plans: Array<{
      planName: string;
      planMetaDetails: any;
      isChecked: boolean;
    }>;
  }>;
}

interface IAssignedInsurancePlan {
  insuranceID: string;
  planID: string;
  categoryName: string;
}

const useInsuranceForm = ({ hasExternalSource = false, remoteDataSource = null }: Partial<InputProps> = {}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [insuranceList, setInsuranceList] = useState<any[]>([]);
  const {
    formGroup,
    selectedPolicyDetails,
    preAssignedPolicy,
    isResetPolicy,
    updatePolicySettingsInput,
    getPolicySettingsInput,
  } = useContext();
  const { isCompanyPolicyMode, isCompanyEmployeePolicyMode } = usePolicyMode();

  const [assignedInsurances, setAssignedInsurances] = useState<any[]>([]);

  const [selectedInputCollection, setSelectedInputCollection] = useState<any[]>([]);

  const isCheckedCollectiveAgreement = isCompanyPolicyMode() ? false : formGroup?.values?.isCollectiveAgreementLock;

  const hasEmployeeChosenPolicyCollectiveAgreement = (): boolean => {
    if (selectedPolicyDetails && isCompanyEmployeePolicyMode()) {
      return selectedPolicyDetails.isCollectiveAgreementLock;
    }

    return false;
  };

  const fetchInsurances = async () => {
    try {
      clearForm();

      setIsFetching(true);

      let isCollectiveAgreement = isCheckedCollectiveAgreement;

      if (isCompanyEmployeePolicyMode()) {
        isCollectiveAgreement = hasEmployeeChosenPolicyCollectiveAgreement();
      }

      const _insurances = await getInsurancesByAgreement({
        isDraft: false,
        isCollectiveAgreement: isCollectiveAgreement,
      });

      loadRemoteDataSource(_insurances);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  const loadRemoteDataSource = useCallback((_insurances) => {
    const insuranceTypes = INSURANCE_CATEGORIES;

    const insuranceResult = _insurances?.map((item) => {
      item.planTypes = insuranceTypes
        .map((planType) => {
          return {
            ...planType,
            plans: (item?.insurances?.[planType.key] || []).map((item) => {
              item.id = generateUniqueId();

              return item;
            }),
          };
        })
        .filter((planType) => planType.plans.length);
      item.providerDescription = item?.insurances?.providerDescription || '';

      return item;
    });

    setInsuranceList(insuranceResult);
  }, []);

  const findInsurancePlan = (insuranceId, planID) => {
    const foundPlan = selectedInputCollection.find(
      (item) => item.insuranceID === insuranceId && item.planID === planID,
    );

    return foundPlan;
  };

  const getIncludedInsurances = () => {
    return selectedInputCollection;
  };

  const updateInputCollection = (value: any[] = []) => {
    formGroup.setFieldValue('policySettings.insurances', value);
  };

  const toRequestedModel = (entity) => {
    const checkedPlans = entity.planTypes
      .map((planType) => {
        return planType.plans
          .map((planItem) => {
            const foundChecked = selectedInputCollection.findIndex(
              (input) => input.insuranceID === entity.id && input.planID === planItem.insuranceName,
            );

            return {
              categoryName: planType.key,
              planName: planItem.insuranceName,
              isChecked: foundChecked !== -1 ? true : false,
            };
          })
          .filter((planItem) => planItem.isChecked);
      })
      .flat();

    const model = {
      insuranceId: entity.id,
      plans: checkedPlans,
    };

    return model;
  };

  const fromRequestedModel = (entity: TypeInsurances) => {
    const collection: IAssignedInsurancePlan[] = [];

    entity.plans.forEach((planItem) => {
      collection.push({
        insuranceID: entity.insuranceId,
        planID: planItem.planName,
        categoryName: planItem.categoryName,
      } as IAssignedInsurancePlan);
    });

    return collection;
  };

  const addOrRemoveInsuranceCollection = (val: any) => {
    const collection = [...selectedInputCollection];
    const foundIndex = selectedInputCollection.findIndex(
      (item) => item.insuranceID === val.insuranceID && item.planID === val.planID,
    );

    if (foundIndex !== -1) {
      collection.splice(foundIndex, 1);

      setSelectedInputCollection(collection);
      return foundIndex;
    }

    collection.push(val);

    setSelectedInputCollection(collection);
  };

  const setInitialInsuranceOfCollectiveAgreement = () => {
    if (selectedPolicyDetails?.policySettings?.insurances) {
      const modelCollection = selectedPolicyDetails.policySettings.insurances
        .map((insuranceItem: TypeInsurances) => {
          return fromRequestedModel(insuranceItem);
        })
        .flat();

      setSelectedInputCollection(modelCollection);
    }
  };

  const toAssignedPlanMetaEntity = (assignedPlanItem, foundInsurance) => {
    const plans = foundInsurance.insurances[assignedPlanItem.categoryName];
    const foundPlan = plans.find((planItem) => planItem.insuranceName === assignedPlanItem.planName);

    return {
      ...assignedPlanItem,
      planMetaDetails: foundPlan,
    };
  };

  const patchInsurancePlans = (_assignedInsurancePlans: any[]) => {
    let assignedChoosenInsurances: IAssignedMetaInsurancePlan[] = [];

    _assignedInsurancePlans.forEach((assignedinsuranceType) => {
      const foundInsurance = insuranceList.find((insuranceItem) => {
        return assignedinsuranceType.insuranceId === insuranceItem.id;
      });

      if (!foundInsurance) {
        return;
      }

      const assignedInsuranceEntity: IAssignedMetaInsurancePlan = {
        insuranceId: assignedinsuranceType.insuranceId,
        metaDetails: foundInsurance,
        assignedPlanTypes: [],
      };

      assignedinsuranceType.plans.forEach((assignedinsuranceItem) => {
        const foundPlanType = assignedInsuranceEntity.assignedPlanTypes.find(
          (item: any) => item.categoryName === assignedinsuranceItem.categoryName,
        );

        if (!foundPlanType) {
          const selectedType = INSURANCE_CATEGORIES.find((item) => item.key === assignedinsuranceItem.categoryName);
          const assignedPlanType = {
            label: selectedType!.label,
            categoryName: selectedType!.key,
            plans: [toAssignedPlanMetaEntity(assignedinsuranceItem, foundInsurance)],
          };

          assignedInsuranceEntity.assignedPlanTypes.push(assignedPlanType);
        } else {
          foundPlanType.plans.push(toAssignedPlanMetaEntity(assignedinsuranceItem, foundInsurance));
        }
      });

      assignedChoosenInsurances.push(assignedInsuranceEntity);
    });

    setAssignedInsurances(assignedChoosenInsurances);
  };

  const updateEmployeePensionSettings = (keyName: string, val) => {
    updatePolicySettingsInput(`employeeMetaSettings.insurance.${keyName}`, val);
  };

  const replaceEmployeePensionSettings = (val) => {
    updatePolicySettingsInput(`employeeMetaSettings.insurance`, val);
  };

  const getEmployeePensionSettings = (keyName: string) => {
    return getPolicySettingsInput(`employeeMetaSettings.insurance.${keyName}`);
  };

  const clearForm = () => {
    setSelectedInputCollection([]);
    formGroup.setFieldValue('policySettings.insurances', []);
  };

  //* Mounted
  useEffect(() => {
    if (!hasExternalSource) {
      fetchInsurances();
    }
  }, [isCheckedCollectiveAgreement]);

  useEffect(() => {
    if (selectedPolicyDetails && isCompanyEmployeePolicyMode()) {
      fetchInsurances();
    }
  }, [selectedPolicyDetails]);

  //* set the fetch DataSource
  useEffect(() => {
    if (remoteDataSource) {
      loadRemoteDataSource(remoteDataSource);
    } else if (!remoteDataSource && insuranceList.length > 0) {
      loadRemoteDataSource([]);
    }
  }, [remoteDataSource]);

  //* Clear formGroup
  useEffect(() => {
    if (isResetPolicy) {
      clearForm();
    }
  }, [isResetPolicy]);

  //* Manage payload for the formGroup
  useEffect(() => {
    const collection = insuranceList
      .map((item) => {
        return toRequestedModel(item);
      })
      .filter((item) => {
        return item.plans.length;
      });

    updateInputCollection(deepClone(collection));
  }, [selectedInputCollection]);

  //* Assigned selectedPolicyDetails to the AssignedInsurances
  useEffect(() => {
    if (selectedPolicyDetails && insuranceList.length > 0) {
      const _assignedInsurances = selectedPolicyDetails.policySettings.insurances || [];

      patchInsurancePlans(_assignedInsurances);
    }
  }, [selectedPolicyDetails, insuranceList]);

  //* Patched Form Data based on the preAssignedPolicy input from PensionPolicyContext
  useEffect(() => {
    if (selectedPolicyDetails && preAssignedPolicy?.policySettings?.insurances) {
      const modelCollection = preAssignedPolicy.policySettings.insurances
        .map((insuranceItem: TypeInsurances) => {
          return fromRequestedModel(insuranceItem);
        })
        .flat();

      setSelectedInputCollection(modelCollection);

      if (preAssignedPolicy.policySettings?.employeeMetaSettings) {
        replaceEmployeePensionSettings(preAssignedPolicy.policySettings.employeeMetaSettings.insurance);
      }
    }

    if (
      hasEmployeeChosenPolicyCollectiveAgreement() &&
      selectedPolicyDetails.policySettings.insurances.length &&
      preAssignedPolicy?.policySettings?.insurances.length === 0
    ) {
      setInitialInsuranceOfCollectiveAgreement();
    }
  }, [preAssignedPolicy, selectedPolicyDetails, insuranceList]);

  return {
    insuranceList,
    assignedInsurances,
    selectedInputCollection,
    isFetching,
    hasEmployeeChosenPolicyCollectiveAgreement,
    updateInputCollection,
    addOrRemoveInsuranceCollection,
    findInsurancePlan,
    getIncludedInsurances,
    updateEmployeePensionSettings,
    getEmployeePensionSettings,
    preAssignedPolicy,
  };
};

export default useInsuranceForm;
