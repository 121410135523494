import { AuthUser } from '../types/auth';

const useSession = () => {
  const localUser = localStorage.getItem('user');
  const authSession = localUser ? (JSON.parse(localUser) as AuthUser) : null;

  return {
    authSession,
  };
};

export default useSession;
