import { PersonalDetails } from '../../../../pages/superAdmin/PersonalClients/types';
import { API_VERSION, LOGS_ENDPOINT, PERSONAL_CLIENTS_ENDPOINT } from '../../../../util/constants';
import { HttpQuery } from '../../../../util/http-query';
import { del, get, post, put } from '../../../../util/httpService';
import { GetPersonalClientType, IAccessLevel, IPersonalClientLogsParams } from './types';

const getLogsFilterParams = (param: IPersonalClientLogsParams): IPersonalClientLogsParams => {
  const defaultParam = {
    page: 1,
    pageSize: 10,
    filter: {
      userId: '',
      action: '',
      level: '',
      from: '',
      to: '',
    },
    sort: { action: 1, fullName: 1, role: 1, dateTime: 1 },
  };
  return Object.assign({}, defaultParam, param);
};

export const getPersonalClients = async ({
  page = 1,
  sizePerPage = 25,
  searchString = '',
  sortField = 'name',
  sortOrder = '1',
}) => {
  const endpoint = `${API_VERSION}${PERSONAL_CLIENTS_ENDPOINT}?page=${page}&pageSize=${sizePerPage}&filter[fullName][like]=${searchString}&sort[${sortField}]=${sortOrder}`;
  const company: GetPersonalClientType = await get(endpoint);
  return company;
};

export const createPersonalClient = async (body: Partial<PersonalDetails>, params: Record<string, boolean>) => {
  const endpoint = `${API_VERSION}${PERSONAL_CLIENTS_ENDPOINT}?draft=${params['draft']}&sendEmails=${params['sendEmails']}`;
  const company = await post(endpoint, { ...body }, {});
  return company;
};

export const getPersonalClientDetail = async (id) => {
  const endpoint = `${API_VERSION}${PERSONAL_CLIENTS_ENDPOINT}/${id}`;
  const client: PersonalDetails = await get(endpoint);
  return client;
};

export const updatePersonalClient = async (id: string | undefined, body: Partial<PersonalDetails>) => {
  const endpoint = `${API_VERSION}${PERSONAL_CLIENTS_ENDPOINT}/${id}`;
  const company = await put(endpoint, { ...body }, {});
  return company;
};

export const deletePersonalClient = async (id: string) => {
  const endpoint = `${API_VERSION}${PERSONAL_CLIENTS_ENDPOINT}/${id}`;
  const company = await del(endpoint, {});
  return company;
};

export const getLegalDocuments = async (id) => {
  let endpoint = `${API_VERSION}${PERSONAL_CLIENTS_ENDPOINT}/legal/${id}`;
  const legalDocs = await get(endpoint);
  return legalDocs;
};

export const getPersonalClientLogDetails = async (companyClientId: any, filter: IPersonalClientLogsParams) => {
  let endpoint = HttpQuery.serialize(getLogsFilterParams(filter), `${API_VERSION}${LOGS_ENDPOINT}/${companyClientId}`);
  const logs = await get(endpoint);
  return logs;
};

export const getPersonalClientLogCategory = async (companyClientId) => {
  let endpoint = `${API_VERSION}${LOGS_ENDPOINT}/actions/${companyClientId}`;
  const categories = await get(endpoint);
  return categories;
};

export const getAccessLevelList = async (id): Promise<IAccessLevel[]> => {
  let endpoint = `${API_VERSION}${PERSONAL_CLIENTS_ENDPOINT}/access-level/${id}`;
  const accessLevel = await get(endpoint);
  return accessLevel;
};
