import { API_VERSION } from "../../../../util/constants";
import { get } from "../../../../util/httpService";

export const getCompanyAdminDashboardData = async () => {
    let endpoint = `${API_VERSION}/dashboard/company-admin`;
    const dashboard = await get(endpoint);
    return dashboard
}

export const getOverviewChartData = async () => {
    let endpoint = `${API_VERSION}/dashboard`;
    const overviewData = await get(endpoint);
    return overviewData;
};
