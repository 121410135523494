import { Dispatch, SetStateAction } from 'react';
import { FormikProps } from 'formik';
import { CompanyClient, ContactPeople } from '../../../../../api/service/superAdmin/company-clients/types';

export interface LooseObject {
  [key: string]: any;
}

export type DateType = {
  createdAt: string;
  updatedAt: string;
};

export type CollectiveAgreementType = {
  id: string;
  name: string;
};

export type ResellerAgreementType = {
  id: string;
  name: string;
};

export type CompanyDetails = {
  organizationNumber: string;
  name: string;
  status: string;
  employees: number | null;
};

export type AddressDetails = {
  city: string;
  country: string;
  postcode: string;
  street: string;
};

export type Position = {
  roleName: string;
  roleCode: number;
};

export type AuthorizedSignatory = {
  _id?: string;
  id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  incharge?: boolean;
  positions?: Position[];
  position?: string;
};

export type AdminDetails = {
  id: string;
  _id?: string;
  personalNumber: string;
  fullName: string;
  email: string;
  hasNotExist?: boolean;
};

export type Consultants = {
  _id?: string;
  id?: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  short?: string;
  position?: string;
  personalNumber?: string;
  email?: string;
  role?: any;
  accountingFirm?: any;
};

export type AccountingFirm = {
  _id?: string;
  id?: string;
  name?: string;
  organizationNumber?: string;
  seniorConsultants: [];
};

export type CompanyClientForm = {
  companyDetails: CompanyDetails;
  address: AddressDetails;
  contractEffectiveFrom: string;
  contractEmail: string;
  invoiceCostCenter: string;
  authorizedSignatory: AuthorizedSignatory[];
  admins: AdminDetails[];
  consultants: Consultants[];
  consultant: Consultants;
  salaryContactPeople: ContactPeople;
  accountingContactPeople: ContactPeople;
  collectiveAgreements: CollectiveAgreementType[];
  invitationEmail: string;
  contractNumber: string;
  combinations: AuthorizedSignatoryGroup[];
  allowCorrectoAccess: boolean;
  allowAdvisorAccess: boolean;
};

export type CompanyClientFormGroup = FormikProps<CompanyClientForm>;

export interface ICompanyClientContext {
  companyRes: CompanyClient | null;
  setCompanyRes: Dispatch<SetStateAction<CompanyClient | null>>;

  companyDetails: CompanyDetails;
  setCompanyDetails: Dispatch<SetStateAction<CompanyDetails>>;

  addressDetails: AddressDetails;
  setAddressDetails: Dispatch<SetStateAction<AddressDetails>>;

  adminDetails: Array<AdminDetails>;
  setAdminDetails: Dispatch<SetStateAction<Array<AdminDetails>>>;

  signatoryDetails: Array<AuthorizedSignatory>;
  setSignatoryDetails: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  salaryContactPeople: ContactPeople;
  setSalaryContactPeople: Dispatch<SetStateAction<ContactPeople>>;

  accountingContactPeople: ContactPeople;
  setAccountingContactPeople: Dispatch<SetStateAction<ContactPeople>>;

  contractEffectiveFrom: string;
  setContractEffectiveFrom: Dispatch<SetStateAction<string>>;

  invoiceCostCenter: string;
  setInvoiceCostCenter: Dispatch<SetStateAction<string>>;

  contractEmail: string;
  setContractEmail: Dispatch<SetStateAction<string>>;

  collectiveAgreements: Array<CollectiveAgreementType>;
  setCollectiveAgreements: Dispatch<SetStateAction<Array<CollectiveAgreementType>>>;

  consultants: Array<Consultants>;
  setConsultants: Dispatch<SetStateAction<Array<Consultants>>>;

  accountingFirms: Array<AccountingFirm>;
  setAccountingFirms: Dispatch<SetStateAction<Array<AccountingFirm>>>;

  invitationEmail: string;
  setInvitationEmail: Dispatch<SetStateAction<string>>;

  contractNumber: string;
  setContractNumber: Dispatch<SetStateAction<string>>;

  combinations: Array<AuthorizedSignatoryGroup>;
  setCombinations: Dispatch<SetStateAction<Array<AuthorizedSignatoryGroup>>>;

  deletedAdmins: Array<string>;
  setDeletedAdmins: Dispatch<SetStateAction<Array<string>>>;

  deletedConsultants: Array<string>;
  setDeletedConsultants: Dispatch<SetStateAction<Array<string>>>;

  signatoryAdmins: Array<AuthorizedSignatory>;
  setSignatoryAdmins: Dispatch<SetStateAction<Array<AuthorizedSignatory>>>;

  allowCorrectoRemoteAccess: boolean;
  setAllowCorrectoRemoteAccess: Dispatch<SetStateAction<boolean>>;

  allowAdvisorRemoteAccess: boolean;
  setAllowAdvisorRemoteAccess: Dispatch<SetStateAction<boolean>>;

  isEditMode: boolean;

  formGroup: CompanyClientFormGroup;

  replaceAdminDetails: (value: any) => void;
  replaceAddress: (value: any) => void;
  replaceAuthorizedSignatory: (value: any) => void;
  replaceCompanyDetails: (value: any) => void;
  replaceConsultants: (value: any) => void;
  replaceConsultant: (value: any) => void;
  replaceAccountingContactPeople: (value: any) => void;
  replaceSalaryContactPeople: (value: any) => void;
  updateAdminDetails: (inputName: any, inputValue: any) => void;
  updateAddress: (inputName: any, inputValue: any) => void;
  updateAuthorizedSignatory: (inputName: any, inputValue: any) => void;
  updateCompanyDetails: (inputName: any, inputValue: any) => void;
  updateConsultants: (inputName: any, inputValue: any) => void;
  updateConsultant: (inputName: any, inputValue: any) => void;
  updateAccountingContactPeople: (inputName: any, inputValue: any) => void;
  updateSalaryContactPeople: (inputName: any, inputValue: any) => void;
  updateContractEffectiveInput: (inputValue: any) => void;
  updateInvoicingCostCenter: (inputValue: any) => void;
  updateInvoiceEmailAddress: (inputValue: any) => void;
  replaceCollectiveAgreements: (inputValue: any) => void;
  updateInvitationEmail: (inputValue: any) => void;
  updateContractNumber: (inputValue: any) => void;
  replaceCombinations: (value: any) => void;
  updateCombinations: (inputName: any, inputValue: any) => void;
  appendDeletedAdmin: (value: any) => void;
  appendDeletedConsultant: (value: any) => void;
  replaceSignatoryAdmins: (value: any) => void;
  replaceAccess: (correctoAccess: boolean, advisorAccess: boolean) => void;
  replaceDeletedAdmins: (value: any) => void;
}

export type FormProps = {
  context: ICompanyClientContext;
  date?: DateType;
  formGroup: CompanyClientFormGroup;
};

export interface AuthorizedSignatoryGroup {
  authorizedSignatory: AuthorizedSignatory[];
  signee: boolean;
}

export enum CompanyClientSaveMode {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  SETUP = 'Setup',
}
