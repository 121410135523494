import { useCallback, useEffect, useState } from 'react';
import useContext from '../PensionPolicyContext/useContext';
import { deepClone, generateUniqueId } from '../../../../util/util';
import { getPensionManagerByAgreement } from '../../../../api/service/superAdmin/settings/pension-companies';
import { TypePensionManager } from '../types';
import usePolicyMode from './usePolicyMode';
import { INITIAL_PENSION_MANAGER_CUSTOM_DETAILS } from '../constants';

/* const DEFAULT_DATASOURCE = {
  hjertaPartner: [],
  notHjertaPartner: [],
}; */

interface InputProps {
  hasExternalSource?: boolean;
  remoteDataSource?: {
    hjertaPartner: TypePensionManager[];
    notHjertaPartner: TypePensionManager[];
  } | null;
}

interface ICheckMode {
  combined: ICheckOption[];
  salary: ICheckOption[];
  occupational: ICheckOption[];
}

interface IAssignedMetaPensionManager {
  pensionManagerId: string;
  metaDetails: any;
  assignedPlans: Array<{
    pensionManagerId: string;
    planName: string;
    fromOccupationalPension: {
      isInclude: boolean;
      isDefault: boolean;
    };
    fromSalaryChange: {
      isInclude: boolean;
      isDefault: boolean;
    };
    planMetaDetails: any;
    isSalaryChecked: boolean;
    isOccupationalChecked: boolean;
  }>;
}

interface ICheckOption {
  pensionManagerId: string;
  planId: string;
  meta: {
    isInclude: boolean;
    isDefault: boolean;
    customerNumber: string;
    insuranceNumber: string;
  };
}

export enum ManagerMode {
  HJERTA = 'hjerta',
  NON_HJERTA = 'non_hjerta',
}

export enum ListMode {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum SelectionModeType {
  COMBINED = 'combined',
  SALARY = 'salary',
  OCCUPATIONAL = 'occupational',
}

const usePensionManagerForm = ({ hasExternalSource = false, remoteDataSource = null }: Partial<InputProps> = {}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [providerManagerList, setProviderManagerList] = useState<any[]>([]);
  const [hjertaManagerList, setHjertaManagerList] = useState<any[]>([]);
  const [otherManagerList, setOtherManagerList] = useState<any[]>([]);
  const [assignedManagers, setAssignedManagers] = useState<any[]>([]);
  const {
    formGroup,
    selectedPolicyDetails,
    preAssignedPolicy,
    isResetPolicy,
    updatePolicySettingsInput,
    getPolicySettingsInput,
  } = useContext();

  const [selectedMode, setSelectedMode] = useState<ListMode>(ListMode.PRIMARY);

  const { isCompanyEmployeePolicyMode, isCompanyEmployeeEditableMode, isCompanyPolicyMode } = usePolicyMode();

  //   const [selectedInputCollection, setSelectedInputCollection] = useState<any[]>([]);

  const [selectedPlanOption, setSelectedPlanOption] = useState<ICheckMode>({
    combined: [],
    salary: [],
    occupational: [],
  });

  const isCheckedCollectiveAgreement = isCompanyPolicyMode() ? false : formGroup?.values?.isCollectiveAgreementLock;

  const fallbackFormGroup = preAssignedPolicy && selectedPolicyDetails ? selectedPolicyDetails : formGroup?.values;
  const isAllowedSalaryChange = fallbackFormGroup?.policySettings?.salaryChange?.isAllowed;
  const isCheckedSepratePensionManagerPlansOption =
    isAllowedSalaryChange === false
      ? false
      : fallbackFormGroup?.policySettings?.salaryChange?.isCheckedSepratePensionManagerPlans || false;

  const isCheckedEmployeeEditOption =
    formGroup?.getFieldMeta<boolean>('policySettings.pensionManagerConfig.employeeRightToEditChecked').value || false;

  const updateCustomDetails = (keyName, value) => {
    formGroup.setFieldValue(`policySettings.pensionManagerConfig.${keyName}`, value);
  };

  const replaceCustomDetails = (updatedDetails) => {
    formGroup.setFieldValue(`policySettings.pensionManagerConfig`, updatedDetails);
  };

  const hasEmployeeChosenPolicyCollectiveAgreement = (): boolean => {
    if (selectedPolicyDetails && isCompanyEmployeePolicyMode()) {
      return selectedPolicyDetails.isCollectiveAgreementLock;
    }

    return false;
  };

  const loadRemoteDataSource = useCallback((_managerResposne) => {
    const _hjertaManagerList = ((_managerResposne as any[]) || []).map((item) => {
      item.pensionManagerPlans = {
        ...item.pensionManagerPlans,
        plans: item.pensionManagerPlans.plans.map((planItem) => {
          return {
            ...planItem,
            id: generateUniqueId(),
          };
        }),
      };

      return item;
    });
    /* const _notHjertaPartnerList = (_managerResposne.isNotCollectiveAgreement as any[]).map((item) => {
      item.pensionManagerPlans = {
        ...item.pensionManagerPlans,
        plans: item.pensionManagerPlans.plans.map((planItem) => {
          return {
            ...planItem,
            id: generateUniqueId(),
          };
        }),
      };

      return item;
    }); */

    setProviderManagerList([_hjertaManagerList /* _notHjertaPartnerList */]);

    const correctPartners = _hjertaManagerList.filter((item) => item.pensionManagerPlans.correctoPartner);
    const nonCorrectPartners = _hjertaManagerList.filter((item) => item.pensionManagerPlans.correctoPartner === false);

    setHjertaManagerList(isCompanyEmployeePolicyMode() ? _hjertaManagerList : correctPartners);
    setOtherManagerList(isCompanyEmployeePolicyMode() ? [] : nonCorrectPartners);
  }, []);

  const fetchPensionManagers = async () => {
    try {
      setIsFetching(true);

      let isCollectiveAgreement = isCheckedCollectiveAgreement;

      if (isCompanyEmployeePolicyMode()) {
        isCollectiveAgreement = hasEmployeeChosenPolicyCollectiveAgreement();
      }

      const _managerResposne = await getPensionManagerByAgreement({
        isDraft: false,
        isCollectiveAgreement: isCollectiveAgreement,
      });

      loadRemoteDataSource(_managerResposne);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  const updateInputCollection = (value: any[] = []) => {
    formGroup.setFieldValue('policySettings.pensionManagers', value);
  };

  const selectedModeType = (key) => {
    if (key === 'combined') {
      return 'fromOccupationalPension';
    } else if (key === 'salary') {
      return 'fromSalaryChange';
    } else if (key === 'occupational') {
      return 'fromOccupationalPension';
    }

    return 'fromOccupationalPension';
  };

  const toRequestedModel = (entity: ICheckOption, mode: SelectionModeType) => {
    const keyType = selectedModeType(mode);

    const model = {
      pensionManagerId: entity.pensionManagerId,
      planName: entity.planId,
      [keyType]: entity.meta,
    };

    return model;
  };

  const fromRequestedModel = (entity: TypePensionManager) => {
    if (!entity) {
      return;
    }

    const isCheckedSalaryChangeOption = isCompanyEmployeePolicyMode()
      ? true
      : selectedPolicyDetails.policySettings.salaryChange.isCheckedSepratePensionManagerPlans;

    const checkedPlanOption: ICheckMode = { combined: [], salary: [], occupational: [] };

    const newlyCollection: { [key in SelectionModeType]?: Partial<ICheckOption>[] } = isCheckedSalaryChangeOption
      ? {
          [SelectionModeType.OCCUPATIONAL]: [],
          [SelectionModeType.SALARY]: [],
        }
      : { [SelectionModeType.COMBINED]: [] };

    const selectedOptionType = isCheckedSalaryChangeOption
      ? [SelectionModeType.OCCUPATIONAL, SelectionModeType.SALARY]
      : [SelectionModeType.COMBINED];

    selectedOptionType.forEach((modeTypeKey) => {
      const keyType = selectedModeType(modeTypeKey);
      const metaEntity = entity[keyType] as any;

      if (!metaEntity) {
        return;
      }

      const model: Partial<ICheckOption> = {
        pensionManagerId: entity.pensionManagerId,
        planId: entity.planName,
        meta: {
          isInclude: metaEntity.isInclude || false,
          isDefault: metaEntity.isDefault || false,
          customerNumber: metaEntity.customerNumber,
          insuranceNumber: metaEntity.insuranceNumber,
        },
      };

      newlyCollection[modeTypeKey]!.push(model);

      checkedPlanOption[modeTypeKey].push(model as ICheckOption);
    });

    return checkedPlanOption;
  };

  const findManagerPlan = (key, pensionManagerId, planID) => {
    const _selectedOptions = selectedPlanOption[key];

    const manager = _selectedOptions.find(
      (item) => item.pensionManagerId === pensionManagerId && item.planId === planID,
    );

    return manager;
  };

  const markIncludePlanToPension = (key, pensionManagerId, planID) => {
    const checkedPlanOption = { ...selectedPlanOption };
    const _selectedOptions = [...checkedPlanOption[key]];

    const entity: ICheckOption = {
      pensionManagerId,
      planId: planID,
      meta: {
        isInclude: true,
        isDefault: false,
        customerNumber: '',
        insuranceNumber: '',
      },
    };

    const isDefaultExist = _selectedOptions.find((item) => {
      return item.meta.isDefault;
    });

    if (!isDefaultExist) {
      entity.meta.isDefault = true;
    }

    _selectedOptions.push(entity);

    checkedPlanOption[key] = _selectedOptions;

    setSelectedPlanOption(checkedPlanOption);
  };

  const unCheckPlanFromPension = (key, pensionManagerId, planID) => {
    const checkedPlanOption = { ...selectedPlanOption };
    const _selectedOptions = [...checkedPlanOption[key]];

    const matchedItemIndex = _selectedOptions.findIndex((item) => {
      return item.pensionManagerId === pensionManagerId && item.planId === planID;
    });
    const matchedItem = _selectedOptions[matchedItemIndex];

    if (matchedItem.meta.isDefault) {
      const firstAvailableIncluded = _selectedOptions.find((item) => {
        return item.planId !== planID && item.meta.isInclude;
      });

      if (firstAvailableIncluded) {
        firstAvailableIncluded.meta.isDefault = true;
      }
    }

    if (matchedItemIndex !== -1) {
      _selectedOptions.splice(matchedItemIndex, 1);
    }

    const isDefaultExist = _selectedOptions.find((item) => {
      return item.meta.isDefault;
    });

    if (!isDefaultExist && _selectedOptions.length > 0) {
      const firstAvailableIncluded = _selectedOptions.find((item) => {
        return item;
      });

      if (firstAvailableIncluded) {
        firstAvailableIncluded.meta.isDefault = true;
      }
    }

    checkedPlanOption[key] = _selectedOptions;
    setSelectedPlanOption(checkedPlanOption);
  };

  const changeNumberValue = (params: {
    key: SelectionModeType;
    propName: string;
    propValue: string;
    pensionManagerId: string;
    planID: string;
  }) => {
    const checkedPlanOption = { ...selectedPlanOption };
    const _selectedOptions = [...checkedPlanOption[params.key]];

    const matchedItemIndex = _selectedOptions.findIndex((item) => {
      return item.pensionManagerId === params.pensionManagerId && item.planId === params.planID;
    });
    const matchedItem = _selectedOptions[matchedItemIndex];

    matchedItem.meta[params.propName] = params.propValue;
    checkedPlanOption[params.key] = _selectedOptions;

    setSelectedPlanOption(checkedPlanOption);
  };

  const markAsDefault = (key, pensionManagerId, planID) => {
    const checkedPlanOption = { ...selectedPlanOption };
    const _selectedOptions: ICheckOption[] = [...checkedPlanOption[key]];

    _selectedOptions.forEach((item) => {
      item.meta.isDefault = false;
    });

    const foundManager = _selectedOptions.find((item) => {
      return item.pensionManagerId === pensionManagerId && item.planId === planID && item.meta.isInclude;
    });

    if (foundManager) {
      foundManager.meta.isDefault = true;

      checkedPlanOption[key] = _selectedOptions;

      setSelectedPlanOption(checkedPlanOption);

      return;
    }

    checkedPlanOption[key] = _selectedOptions;
    setSelectedPlanOption(checkedPlanOption);
  };

  const markAsIncludeWithDefault = (key, pensionManagerId, planID) => {
    const checkedPlanOption = { ...selectedPlanOption };
    const _selectedOptions: ICheckOption[] = [];

    const entity: ICheckOption = {
      pensionManagerId,
      planId: planID,
      meta: {
        isInclude: true,
        isDefault: true,
        customerNumber: '',
        insuranceNumber: '',
      },
    };

    _selectedOptions.push(entity);

    checkedPlanOption[key] = _selectedOptions;
    setSelectedPlanOption(checkedPlanOption);
  };

  const markAllInclude = (key, managerMode: ManagerMode) => {
    const originalCollection = managerMode === ManagerMode.HJERTA ? hjertaManagerList : otherManagerList;
    const checkedPlanOption = { ...selectedPlanOption };
    const _selectedOptions: ICheckOption[] = [...checkedPlanOption[key]];
    const isDefaultSelected = _selectedOptions.find((opt) => opt.meta.isDefault);
    const newlyCollection: any[] = [..._selectedOptions];

    originalCollection.forEach((planManager, idx) => {
      const plans = planManager?.pensionManagerPlans?.plans || [];

      plans.forEach((planItem, planIdx) => {
        const foundItem = _selectedOptions.find(
          (opt) => opt.pensionManagerId === planManager.id && opt.planId === planItem.planName,
        );
        const existedItem = newlyCollection.find(
          (opt) => opt.pensionManagerId === planManager.id && opt.planId === planItem.planName,
        );

        if (existedItem) {
          return;
        }

        if (!isDefaultSelected && idx === 0 && planIdx === 0) {
          if (foundItem) {
            foundItem.meta.isDefault = true;
            foundItem.meta.isInclude = true;
            newlyCollection.push(foundItem);

            return;
          }

          const entity: ICheckOption = {
            pensionManagerId: planManager.id,
            planId: planItem.planName,
            meta: {
              isInclude: true,
              isDefault: true,
              customerNumber: '',
              insuranceNumber: '',
            },
          };

          newlyCollection.push(entity);

          return;
        }

        if (foundItem) {
          newlyCollection.push(foundItem);
        } else {
          const entity: ICheckOption = {
            pensionManagerId: planManager.id,
            planId: planItem.planName,
            meta: {
              isInclude: true,
              isDefault: false,
              customerNumber: '',
              insuranceNumber: '',
            },
          };

          newlyCollection.push(entity);
        }
      });
    });

    checkedPlanOption[key] = newlyCollection;

    setSelectedPlanOption(checkedPlanOption);
  };

  const markAllExclude = (key, managerMode: ManagerMode) => {
    const originalCollection = managerMode === ManagerMode.HJERTA ? hjertaManagerList : otherManagerList;
    const checkedPlanOption = { ...selectedPlanOption };
    const _selectedOptions: ICheckOption[] = [...checkedPlanOption[key]];

    originalCollection.forEach((planManager, idx) => {
      const plans = planManager?.pensionManagerPlans?.plans || [];

      plans.forEach((planItem) => {
        const foundItemIndex = _selectedOptions.findIndex(
          (opt) => opt.pensionManagerId === planManager.id && opt.planId === planItem.planName,
        );

        if (foundItemIndex !== -1) {
          _selectedOptions.splice(foundItemIndex, 1);
        }
      });
    });

    const isDefaultExist = _selectedOptions.find((item) => {
      return item.meta.isDefault;
    });

    if (!isDefaultExist && _selectedOptions.length > 0) {
      const firstAvailableIncluded = _selectedOptions.find((item) => {
        return item;
      });

      if (firstAvailableIncluded) {
        firstAvailableIncluded.meta.isDefault = true;
      }
    }

    checkedPlanOption[key] = _selectedOptions;

    setSelectedPlanOption(checkedPlanOption);
  };

  const hasIncluded = (key, pensionManagerId, planID) => {
    const _selectedOptions: ICheckOption[] = selectedPlanOption[key] || [];

    return _selectedOptions.find((item) => item.pensionManagerId === pensionManagerId && item.planId === planID)
      ? true
      : false;
  };

  const canEditEffectiveDate = () => {
    const occupationalCheckedItems: ICheckOption[] = selectedPlanOption[SelectionModeType.OCCUPATIONAL] || [];
    const salaryCheckedItems: ICheckOption[] = selectedPlanOption[SelectionModeType.SALARY] || [];

    return occupationalCheckedItems.length || salaryCheckedItems.length;
  };

  const hasDefaultChecked = (key, pensionManagerId, planID) => {
    const _selectedOptions: ICheckOption[] = selectedPlanOption[key] || [];

    return _selectedOptions.find(
      (item) => item.pensionManagerId === pensionManagerId && item.planId === planID && item.meta.isDefault,
    )
      ? true
      : false;
  };

  const toAssignedPlanMetaEntity = (assignedManagerPlanItem, planMetaDetails) => {
    return {
      ...assignedManagerPlanItem,
      planMetaDetails: planMetaDetails,
      isSalaryChecked: assignedManagerPlanItem?.fromSalaryChange?.isInclude || false,
      isOccupationalChecked: assignedManagerPlanItem?.fromOccupationalPension?.isInclude || false,
    };
  };

  const patchPensionManagers = (_assignedPensionManagers: any[]) => {
    let assignedChoosenManagers: IAssignedMetaPensionManager[] = [];
    const managerList = [...hjertaManagerList, ...otherManagerList];

    _assignedPensionManagers.forEach((assignedManagerItem) => {
      const isExistedManager = assignedChoosenManagers.find(
        (managerItem) => managerItem.pensionManagerId === assignedManagerItem.pensionManagerId,
      );

      if (!isExistedManager) {
        const foundManager = managerList.find((managerItem) => {
          return assignedManagerItem.pensionManagerId === managerItem.id;
        });

        if (!foundManager) {
          return;
        }

        const { plans, ...managerDetails } = foundManager;

        const assignedManagerEntity: IAssignedMetaPensionManager = {
          pensionManagerId: assignedManagerItem.pensionManagerId,
          metaDetails: managerDetails,
          assignedPlans: [
            toAssignedPlanMetaEntity(
              assignedManagerItem,
              managerDetails.pensionManagerPlans.plans.find((item) => item.planName === assignedManagerItem.planName),
            ),
          ],
        };

        assignedChoosenManagers.push(assignedManagerEntity);
      } else {
        isExistedManager.assignedPlans.push(
          toAssignedPlanMetaEntity(
            assignedManagerItem,
            isExistedManager.metaDetails.pensionManagerPlans.plans.find(
              (item) => item.planName === assignedManagerItem.planName,
            ),
          ),
        );
      }
    });

    setAssignedManagers(assignedChoosenManagers);
  };

  const updateEmployeePensionSettings = (keyName: string, val) => {
    updatePolicySettingsInput(`employeeMetaSettings.pensionManager.${keyName}`, val);
  };

  const replaceEmployeePensionSettings = (val) => {
    updatePolicySettingsInput(`employeeMetaSettings.pensionManager`, val);
  };

  const getEmployeePensionSettings = (keyName: string) => {
    return getPolicySettingsInput(`employeeMetaSettings.pensionManager.${keyName}`);
  };

  const clearForm = () => {
    setSelectedPlanOption({
      combined: [],
      salary: [],
      occupational: [],
    });
    formGroup.setFieldValue('policySettings.pensionManagers', []);
    formGroup.setFieldValue('policySettings.pensionManagerConfig', deepClone(INITIAL_PENSION_MANAGER_CUSTOM_DETAILS));
  };

  const setEmployeeInitialDefaultPlanChecked = (managerPlans: TypePensionManager[] = []) => {
    if (!isCompanyEmployeeEditableMode()) {
      return;
    }

    const checkedPlanOption: ICheckMode = {
      combined: [],
      salary: [],
      occupational: [],
    };

    const isCheckedSalaryChangeOption = isCompanyEmployeePolicyMode()
      ? true
      : selectedPolicyDetails.policySettings.salaryChange.isCheckedSepratePensionManagerPlans;
    const selectedOptionType = isCheckedSalaryChangeOption
      ? [SelectionModeType.OCCUPATIONAL, SelectionModeType.SALARY]
      : [SelectionModeType.COMBINED];

    managerPlans.forEach((managerItem: TypePensionManager) => {
      if (!managerItem) {
        return;
      }

      selectedOptionType.forEach((modeTypeKey) => {
        const keyType = selectedModeType(modeTypeKey);
        const metaDetails = managerItem[keyType] as any;

        if (!metaDetails) {
          return;
        }

        if (checkedPlanOption[modeTypeKey].length === 0 && metaDetails.isDefault) {
          const entity: ICheckOption = {
            pensionManagerId: managerItem.pensionManagerId,
            planId: managerItem.planName,
            meta: {
              isInclude: true,
              isDefault: true,
              customerNumber: metaDetails.customerNumber || '',
              insuranceNumber: metaDetails.insuranceNumber || '',
            },
          } as ICheckOption;

          checkedPlanOption[modeTypeKey].push(entity);
        }
      });
    });

    setSelectedPlanOption(checkedPlanOption);
  };

  //* Mounted
  useEffect(() => {
    if (!hasExternalSource) {
      fetchPensionManagers();
    }
  }, [isCheckedCollectiveAgreement]);

  useEffect(() => {
    if (selectedPolicyDetails && isCompanyEmployeePolicyMode()) {
      fetchPensionManagers();
    }
  }, [selectedPolicyDetails]);

  //* set the fetch DataSource
  useEffect(() => {
    if (remoteDataSource) {
      clearForm();
      loadRemoteDataSource(remoteDataSource);
    } else if (!remoteDataSource && providerManagerList.length > 0) {
      clearForm();
      loadRemoteDataSource([]); /* DEFAULT_DATASOURCE */
    }
  }, [remoteDataSource]);

  //* Clear formGroup
  useEffect(() => {
    if (isResetPolicy) {
      clearForm();
    }
  }, [isResetPolicy]);

  //* Assigned selectedPolicyDetails to the AssignedManagers
  useEffect(() => {
    if (selectedPolicyDetails) {
      const _assignedManagers = selectedPolicyDetails.policySettings.pensionManagers || [];

      patchPensionManagers(_assignedManagers);

      if (selectedPolicyDetails.policySettings?.pensionManagerConfig) {
        replaceCustomDetails(selectedPolicyDetails.policySettings.pensionManagerConfig);
      }
    }
  }, [selectedPolicyDetails, providerManagerList]);

  //* Toggle Pension Policy Template
  useEffect(() => {
    if (isCheckedSepratePensionManagerPlansOption || isCompanyEmployeePolicyMode()) {
      clearForm();
      setSelectedMode(ListMode.SECONDARY);
    } else {
      clearForm();
      setSelectedMode(ListMode.PRIMARY);
    }
  }, [isCheckedSepratePensionManagerPlansOption]);

  //* Manage payload for the formGroup
  useEffect(() => {
    let selectedInputCollection: any[] = [];

    if (selectedMode === ListMode.PRIMARY) {
      selectedInputCollection = [...(selectedPlanOption[SelectionModeType.COMBINED] || [])].map((item) => {
        return toRequestedModel(item, SelectionModeType.COMBINED);
      });
    } else if (selectedMode === ListMode.SECONDARY) {
      const collection = [
        ...(selectedPlanOption[SelectionModeType.OCCUPATIONAL] || []).map((item) => {
          return toRequestedModel(item, SelectionModeType.OCCUPATIONAL);
        }),

        ...(selectedPlanOption[SelectionModeType.SALARY] || []).map((item) => {
          return toRequestedModel(item, SelectionModeType.SALARY);
        }),
      ];

      collection.forEach((item) => {
        const foundIndex = selectedInputCollection.findIndex(
          (input) => input.pensionManagerId === item.pensionManagerId && input.planName === item.planName,
        );

        if (foundIndex !== -1) {
          selectedInputCollection[foundIndex] = {
            ...selectedInputCollection[foundIndex],
            ...item,
          };

          return;
        }

        selectedInputCollection.push({
          ...item,
        });
      });
    }

    updateInputCollection(deepClone(selectedInputCollection));
  }, [selectedMode, selectedPlanOption]);

  //* Patched Form Data based on the preAssignedPolicy input from PensionPolicyContext
  useEffect(() => {
    if (selectedPolicyDetails && preAssignedPolicy) {
      if (isCompanyEmployeeEditableMode() && preAssignedPolicy.policySettings.pensionManagers?.length === 0) {
        setEmployeeInitialDefaultPlanChecked(selectedPolicyDetails.policySettings.pensionManagers || []);
      } else {
        const checkedPlanOption: ICheckMode = { combined: [], salary: [], occupational: [] };

        preAssignedPolicy.policySettings.pensionManagers.forEach((planManager: TypePensionManager) => {
          const result = fromRequestedModel(planManager);

          if (!result) {
            return;
          }

          result.combined && checkedPlanOption.combined.push(...result.combined);
          result.occupational && checkedPlanOption.occupational.push(...result.occupational);
          result.salary && checkedPlanOption.salary.push(...result.salary);
        });

        setSelectedPlanOption(deepClone(checkedPlanOption));
      }

      if (preAssignedPolicy.policySettings?.pensionManagerConfig) {
        replaceCustomDetails(preAssignedPolicy.policySettings.pensionManagerConfig);
      }

      if (preAssignedPolicy.policySettings?.employeeMetaSettings) {
        replaceEmployeePensionSettings(preAssignedPolicy.policySettings.employeeMetaSettings.pensionManager);
      }
    }
  }, [preAssignedPolicy, selectedPolicyDetails]);

  return {
    hjertaManagerList,
    otherManagerList,
    selectedMode,
    assignedManagers,
    isCheckedEmployeeEditOption,
    isFetching,
    isCheckedCollectiveAgreement,
    formGroup,
    isCheckedSepratePensionManagerPlansOption,

    hasEmployeeChosenPolicyCollectiveAgreement,
    updateCustomDetails,
    updateInputCollection,
    markIncludePlanToPension,
    unCheckPlanFromPension,
    markAsDefault,
    markAllInclude,
    markAllExclude,
    hasIncluded,
    canEditEffectiveDate,
    hasDefaultChecked,
    findManagerPlan,
    changeNumberValue,
    markAsIncludeWithDefault,
    setSelectedMode,
    selectedModeType,
    updateEmployeePensionSettings,
    getEmployeePensionSettings,
    clearForm,
  };
};

export default usePensionManagerForm;
