import { API_VERSION } from '../../../../util/constants';
import { get, put } from '../../../../util/httpService';
import { AccountProfile } from '../types';

const getProfileDetails = async () => {
  const endpoint = `${API_VERSION}/personal-clients/profile/me`;
  const userProfile = (await get(endpoint)) as AccountProfile;

  userProfile.accessLevels.accountingLevel = userProfile.accessLevels.accountingLevel.filter((item) => {
    return item.company;
  });

  userProfile.accessLevels.companyLevel = userProfile.accessLevels.companyLevel.filter((item) => {
    return item.company;
  });

  userProfile.accessLevels.correcto_level = userProfile.accessLevels.correcto_level.filter((item) => {
    return item.company;
  });

  return userProfile;
};


const updateProfileDetails = async (data) => {
  const endpoint = `${API_VERSION}/personal-clients/profile/me`;
  const userProfile = (await put(endpoint, { ...data }, {})) as AccountProfile;
  return userProfile;
};

export { getProfileDetails, updateProfileDetails };
