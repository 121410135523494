import { ColumnDescription } from 'react-bootstrap-table-next';

import { sortCaret } from '../../../../../components/table/formatters';
import classNames from 'classnames';
import { tableHeaderText } from '../../../../../components/table/TableHeaderFormatter';

export const useColumn = () => {
  const columns: ColumnDescription<any, any>[] = [
    {
      text: tableHeaderText('Company name'),
      dataField: 'companyName',
      sort: true,
      sortCaret,
      headerStyle: { width: 200 },
    },
    {
      text: tableHeaderText('Org. number'),
      dataField: 'orgNumber',
      headerStyle: { width: 80 },
    },
    {
      text: tableHeaderText('Employees'),
      dataField: 'employees',
      headerStyle: { width: 43 },
      classes: 'text-right',
    },
    {
      text: tableHeaderText('Status'),
      dataField: 'status',
      formatter: (cell) => <span className={classNames('fs-text-400', 'active-green')}>{cell}</span>,
      headerStyle: { width: 70 },
    },
    {
      text: tableHeaderText('Total months'),
      dataField: 'totalMonths',
      headerStyle: { width: 49 },
      classes: 'text-right',
    },
    {
      text: `${tableHeaderText('Provision')} (%)`,
      dataField: 'provisionPercent',
      headerStyle: { width: 49 },
      classes: 'text-right',
    },
    {
      text: tableHeaderText('Provision total'),
      dataField: 'provisionTotal',
      headerStyle: { width: 54 },
      classes: 'text-right',
    },
  ];

  return { columns };
};
