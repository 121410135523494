import PersonalInfo from '../../../pages/company/Employees/EmployeeDetails/PersonalInfo';
import SalaryInfo from '../../../pages/company/Employees/EmployeeDetails/SalaryInfo';
import { SalarySpecification } from './SalarySpecification';
import useContext from '../../../pages/company/Employees/useContext';
import Vacation from '../../../pages/company/Employees/EmployeeDetails/Vacation';
import { useState } from 'react';
import { ConfirmationDialog } from '../../../util/dialogs';
import { useTranslation } from 'react-i18next';
import ParentalInfo from '../../../pages/company/Employees/EmployeeDetails/ParentalInfo';
import CardComponent from '../../../components/base/CardComponent';

interface Props {
  cancelVaccationSchedule: (...args) => void;
}

function OverviewTab({ cancelVaccationSchedule }: Props) {
  const { t } = useTranslation();
  const { formGroup } = useContext();
  const [cancellingVaccationSchedule, setCancellingVaccationSchedule] = useState(false);

  const onCancelSchdedule = async (onSuccessCallback) => {
    try {
      const title = 'Are you sure you want to cancel that schedule?';

      const result = await ConfirmationDialog({
        title: title,
        confirmButtonText: t('yes'),
        denyButtonText: t('no'),
      });

      if (result.isConfirmed) {
        setCancellingVaccationSchedule(true);
        await cancelVaccationSchedule();
        onSuccessCallback();
      }
    } catch (e) {
      setCancellingVaccationSchedule(false);
    } finally {
      setCancellingVaccationSchedule(false);
    }
  };

  return (
    <div>
      <CardComponent>
        <PersonalInfo formGroup={formGroup!} context={useContext()} />
        <div className="mb-3">
          <SalaryInfo formGroup={formGroup!} context={useContext()} />
        </div>
        <Vacation
          formGroup={formGroup!}
          context={useContext()}
          onCancelSchdedule={onCancelSchdedule}
          cancellingSchedule={cancellingVaccationSchedule}
        />
        <SalarySpecification />
        <ParentalInfo />
      </CardComponent>
    </div>
  );
}

export default OverviewTab;
