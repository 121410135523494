import { useState, useEffect, useCallback } from 'react';
import { getUserSession } from '../../api/service/auth/service';
import { GrandIDVerificationResponse, SignInMethod } from '../../types/auth';
import useGrandIDVerificationMode from './useGrandIDVerificationMode';

interface Props {
  session: string | null;
  method: SignInMethod | undefined;
  onCompleteVerification: (personalNumber: string) => void;
}

const useAuthSessionPolling = ({ session, onCompleteVerification }: Props) => {
  const { isVerificationCompleted, isVerificationFailed, isVerificationPending, getStatus } =
    useGrandIDVerificationMode();

  const [inProgress, setInProgress] = useState(false);
  const [signingErrorMsg, setSigningErrorMsg] = useState('');
  const [resultTimeout, setResultTimeout] = useState<any>(null);
  const [result, setResult] = useState<GrandIDVerificationResponse | null>(null);

  const verificationStatus = getStatus(result);
  const verifiedAuthUser = isVerificationCompleted(result) ? result : null;

  const getQRCode = useCallback(() => {
    if (
      result &&
      !isVerificationCompleted(result)
      // result.grandidObject.message.status === GrandIDAuthStatus.PENDING
    ) {
      return result!.grandidObject!.QRCode;
    }

    return null;
  }, [result]);

  const pollSessionResult = async () => {
    try {
      setInProgress(true);
      setSigningErrorMsg('');

      const sessionResult = await getUserSession(session, 'user');

      setResult(sessionResult);

      if (isVerificationCompleted(sessionResult)) {
        onCompleteVerification(sessionResult.userAttributes.personalNumber);
      } else if (isVerificationFailed(sessionResult)) {
        setSigningErrorMsg('Verification failed....');
      } else if (isVerificationPending(sessionResult)) {
        const timeout = setTimeout(() => {
          abortSession(false);

          pollSessionResult();
        }, 2000);

        setResultTimeout(timeout);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setSigningErrorMsg(error.message);
      }
    } finally {
      setInProgress(false);
    }
  };

  const abortSession = (shouldUpdateProgress = true) => {
    if (shouldUpdateProgress) {
      setInProgress(false);
    }

    if (resultTimeout) {
      clearTimeout(resultTimeout);
    }
  };

  const clear = () => {
    setInProgress(false);
    setSigningErrorMsg('');
    setResult(null);
  };

  useEffect(() => {
    if (session) {
      pollSessionResult();
    }

    if (!session) {
      clear();
    }

    return () => {
      abortSession();
    };
  }, [session]);

  return {
    inProgress,
    signingErrorMsg,
    signInScanCode: getQRCode(),
    result,
    verificationStatus,
    verifiedAuthUser,
  };
};

export default useAuthSessionPolling;
