import * as yup from 'yup';
import {
  organizationNumberValidations,
  personalNumberValidations,
  validatePersonalNumber,
} from '../../../../util/validation';

export const formSchema = yup.object().shape({
  companyDetails: yup.object().shape({
    organizationNumber: organizationNumberValidations(),
    name: yup.string().required('Required'),
    status: yup.string().required('Required'),
  }),
  address: yup.object().shape({
    street: yup.string().required('Required'),
    city: yup.string().required('Required'),
    postcode: yup.string().required('Required'),
    country: yup.string().required('Required'),
  }),
  contractEffectiveFrom: yup.string().required('Required'),
  invoiceEmailAddress: yup.string().required('Required'),
  invoiceContractCenter: yup.string(),
  contractNumber: yup.string().nullable(),
  combinations: yup
    .array()
    .of(
      yup.object().shape({
        authorizedSignatory: yup.lazy((authorizedSignatory, context) => {
          const signee = context.parent.signee;

          if (signee) {
            return yup.array().of(
              yup.object().shape({
                personalNumber: personalNumberValidations(),
                fullName: yup.string(),
                email: yup.string().email('Invalid email').required('Required'),
              }),
            );
          } else {
            return yup.array().of(
              yup.object().shape({
                personalNumber: personalNumberValidations(),
                fullName: yup.string(),
                email: yup.string().email('Invalid email'),
              }),
            );
          }
        }),
        signee: yup.boolean(),
      }),
    )
    .min(1, 'At least one signatory is required'),
  admins: yup.array().of(
    yup.object().shape({
      personalNumber: yup
        .string()
        .trim('Personal Number cannot contain spaces')
        .test('personal_number_validator', '', validatePersonalNumber),
      fullName: yup.string().when('personalNumber', {
        is: (val) => val && val.trim().length > 0,
        then: yup.string().required('Required'),
        otherwise: yup.string(),
      }),
      email: yup
        .string()
        .email('Invalid email')
        .when('personalNumber', {
          is: (val) => val && val.trim().length > 0,
          then: yup.string().required('Required'),
          otherwise: yup.string(),
        }),
    }),
  ),
  consultants: yup
    .array()
    // .min(1, 'at least 1 Consultant')
    .of(
      yup.object().shape({
        name: yup.string().optional(),
        personalNumber: yup.string(),
        fullName: yup.string(),
      }),
    ),
  consultant: yup.object().shape({
    name: yup.string().optional(),
    personalNumber: yup.string(),
    fullName: yup.string().when('personalNumber', {
      is: (val) => val && val.trim().length > 0,
      then: yup.string().required('Required'),
      otherwise: yup.string(),
    }),
  }),
  salaryContactPeople: yup.object().shape({
    system: yup.string(),
    personalNumber: personalNumberValidations(false),
    name: yup.string(),
    email: yup.string().email('Invalid email'),
  }),
  accountingContactPeople: yup.object().shape({
    system: yup.string(),
    personalNumber: personalNumberValidations(false),
    name: yup.string(),
    email: yup.string().email('Invalid email'),
  }),
  invitationEmail: yup.string().required('Required'),
  collectiveAgreements: yup.array().of(
    yup.object().shape({
      name: yup.string(),
    }),
  ),
  vismaAPIKey: yup.string().optional(),
  allowCorrectoAccess: yup.boolean(),
  allowAdvisorAccess: yup.boolean()
});
