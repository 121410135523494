import { Dispatch } from 'react';
import { SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import BSModal from '../../../../components/Modal';
import CreateConsultanttModalFooter from './CreateConsultanttModalFooter';
import { useCreateConsultantContext } from '../context/useContext';
import { INITIAL_PERSONAL_DETAILS, INITIAL_STATISTICS, INITIAL_USER_SETTINGS } from '../contants';
import { useTranslation } from 'react-i18next';
import PersonalDetails from '../ConsultantForms/PersonalDetails';
import { useValidationError } from '../../../../hooks/useValidationError';
import ValidationAlert from '../../../../components/ValidationAlert';

type Props = {
  showCreateModal: boolean;
  toggleModal: () => void;
  setIsConsultantCreated: Dispatch<SetStateAction<boolean>>;
};

const CreateConsultantModal = ({ showCreateModal, toggleModal, setIsConsultantCreated }: Props) => {
  const { t } = useTranslation();
  const { setPersonalDetails, setStatistics, setUserSettings, formGroup } = useCreateConsultantContext();
  const { errors, isInvalid, setErrors } = useValidationError();

  const onConsultantCreated = () => {
    return true;
  };

  return (
    <>
      <BSModal
        title={`${t('new_consultant')}`}
        show={showCreateModal}
        onHide={() => {
          setPersonalDetails(INITIAL_PERSONAL_DETAILS);
          setStatistics(INITIAL_STATISTICS);
          setUserSettings(INITIAL_USER_SETTINGS);
          toggleModal();
        }}
        centered
        ModalFooter={
          <CreateConsultanttModalFooter
            setIsConsultantCreated={setIsConsultantCreated}
            toggleModal={toggleModal}
            setErrors={setErrors}
            onClientCreated={onConsultantCreated}
            formGroup={formGroup!}
          />
        }
      >
        <Form>
          <ValidationAlert show={isInvalid} errors={errors} />
          {/* <CompanyDetails context={useCreateConsultantContext()} /> */}
          <PersonalDetails context={useCreateConsultantContext()} formGroup={formGroup!} />
          {/* <UserSettings context={useCreateConsultantContext()} /> */}
        </Form>
      </BSModal>
    </>
  );
};

export default CreateConsultantModal;
