import moment from 'moment';
import { getRoaringCompanyInfo } from '../../../../../api/service/superAdmin/company-clients/service';
import ErrorFeedback from '../../../../../components/ErrorFeedback';
import PagesHeader from '../../../../../components/base/PagesHeader';
import { errorHighlighter } from '../../../../../util/form';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { OrganizationNumber } from '../../../../../components/form/inputs/OrganizationNumber';
import { useTranslation } from 'react-i18next';
import { FormProps } from './types';
import { CornerDownRight, RefreshCw } from 'react-feather';
import classNames from 'classnames';
import { DEFAULT_COMPANY_INFO } from '../../../../../types';

export const CompanyDetails = ({ context, date }: FormProps) => {
  const { t } = useTranslation();
  const [isOrgNumberValid, setIsisOrgNumberValid] = useState<boolean>(true);
  const {
    companyDetails,
    contractNumber,
    isEditMode,
    formGroup,
    replaceAddress,
    replaceCompanyDetails,
    replaceAuthorizedSignatory,
    updateAddress,
    updateCompanyDetails,
  } = context;

  const handleSetCompany = (e) => {
    updateCompanyDetails(e.target.name, e.target.value);
    setIsisOrgNumberValid(true);
  };

  const handleAddressCompany = (e) => {
    updateAddress(e.target.name, e.target.value);
  };

  return (
    <div>
      <PagesHeader pageTitle="Company details" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        {isEditMode && (
          <div className="cr-form-row">
            <div className="contract-num-field">
              <Form.Label className="fs-name mb-0">{t('contract_number')}</Form.Label>
              <Form.Control disabled defaultValue={contractNumber} />
            </div>
            <div className="employees-field">
              <Form.Label className="fs-name mb-0">{t('employees')}</Form.Label>
              <Form.Control disabled defaultValue={companyDetails.employees || ''} />
            </div>
            <div className="creation-date-field">
              <Form.Label className="fs-name mb-0">{t('creation_date')}</Form.Label>
              <Form.Control disabled defaultValue={moment(date?.createdAt).format('YYYY-MM-DD')} />
            </div>
            <div className="last-edit-field">
              <Form.Label className="fs-name mb-0">{t('last_edit')}</Form.Label>
              <Form.Control disabled defaultValue={moment(date?.updatedAt).format('YYYY-MM-DD')} />
            </div>
          </div>
        )}
        <div className="cr-form-row">
          <OrganizationNumber
            disabled={isEditMode}
            onChangeHandler={handleSetCompany}
            field={formGroup.getFieldMeta('companyDetails.organizationNumber')}
            value={companyDetails.organizationNumber}
            isValid={isOrgNumberValid}
            lazy={true}
          />
          <div>
            <Button
              disabled={true}
              onClick={
                isEmpty(companyDetails?.organizationNumber)
                  ? undefined
                  : async (e) => {
                      try {
                        updateCompanyDetails('name', DEFAULT_COMPANY_INFO.name);
                        replaceAddress(DEFAULT_COMPANY_INFO.address);
                        replaceAuthorizedSignatory(DEFAULT_COMPANY_INFO.authorizedSignatory);
                        const { name, address, authorizedSignatory } = await getRoaringCompanyInfo(
                          companyDetails?.organizationNumber,
                        );
                        replaceCompanyDetails({ ...companyDetails, name });
                        replaceAddress(address);
                        replaceAuthorizedSignatory(
                          authorizedSignatory.map((i, index) => {
                            if (index === 0) {
                              return {
                                _id: Math.random(),
                                incharge: true,
                                ...i,
                              };
                            } else {
                              return { _id: Math.random(), incharge: false, ...i };
                            }
                          }),
                        );
                        setIsisOrgNumberValid(true);
                      } catch (error) {
                        setIsisOrgNumberValid(false);
                      }
                    }
              }
              className="app-btn-primary"
            >
              {isEditMode ? <RefreshCw className="icon-gap-right" /> : <CornerDownRight className="icon-gap-right" />}{' '}
              {isEditMode ? `${t('refresh')}` : `${t('fetch_data')}`}
            </Button>
          </div>
          <div className="company-name-field">
            <Form.Label className="fs-name mb-0 required">{t('company_name')}</Form.Label>
            <Form.Control
              disabled
              onChange={handleSetCompany}
              name="name"
              value={formGroup.values.companyDetails.name}
              className={classNames(errorHighlighter(formGroup.getFieldMeta(`companyDetails.name`)))}
            />
            <ErrorFeedback field={formGroup.getFieldMeta('companyDetails.name')} />
          </div>
          {isEditMode && (
            <div className="status-field">
              <Form.Label className="fs-name mb-0 required">{t('status')}</Form.Label>
              <Form.Control
                disabled={true}
                name="status"
                value={formGroup.values.companyDetails?.status}
                onChange={handleSetCompany}
                type="text"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`companyDetails.status`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('companyDetails.status')} />
            </div>
          )}
        </div>
        <div className="cr-form-row">
          <div className="address-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{t('address')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                name="street"
                value={formGroup.values.address.street}
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.street`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.street')} />
            </Form.Group>
          </div>
          <div className="post-code-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{t('postcode')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={formGroup.values.address.postcode}
                name="postcode"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.postcode`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.postcode')} />
            </Form.Group>
          </div>
          <div className="city-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{t('city')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={formGroup.values.address.city}
                name="city"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.city`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.city')} />
            </Form.Group>
          </div>
          <div className="country-field">
            <Form.Group>
              <Form.Label className="fs-name mb-0 required">{t('country')}</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                onChange={handleAddressCompany}
                value={formGroup.values.address.country}
                name="country"
                className={classNames(errorHighlighter(formGroup.getFieldMeta(`address.country`)))}
              />
              <ErrorFeedback field={formGroup.getFieldMeta('address.country')} />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
};
