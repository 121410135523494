import React, { useEffect } from 'react';
import { Mode } from '../types';
import { useSuperAdminSettingsContext } from '../useContext';
import Integrations from './Integrations';
import { CreateIntegrationModal } from './CreateIntegration/CreateIntegrationModal';
import EditIntegration from './EditIntegration';
import useIntegrationEditor from './useIntegrationEditor';

interface InputProps {
  emitValues: (...args) => void;
}

const AdminIntegrations = React.forwardRef(({ emitValues }: InputProps, _ref: any) => {
  const integrationEditor = useIntegrationEditor();
  const { mode, setMode } = useSuperAdminSettingsContext();

  useEffect(() => {
    emitValues({
      toggleIntegrationModal: integrationEditor.toggleIntegrationModal,
      removeIntegration: integrationEditor.removeIntegration,
      selectedIntegrationID: integrationEditor.selectedIntegrationID,
      integrationSaveMode: integrationEditor.integrationSaveMode,
      updateIntegrationDetail: integrationEditor.updateIntegrationDetail,
      errors: integrationEditor.errors,
    });
  }, [
    integrationEditor.toggleIntegrationModal,
    integrationEditor.removeIntegration,
    integrationEditor.selectedIntegrationID,
    integrationEditor.integrationSaveMode,
    integrationEditor.updateIntegrationDetail,
    integrationEditor.errors,
  ]);

  useEffect(() => {
    if (integrationEditor.showIntegrationCreateModal) {
      setMode(Mode.Add);
    } else {
      setMode(Mode.List);
    }
  }, [integrationEditor.showIntegrationCreateModal]);

  useEffect(() => {
    if (mode === Mode.List) {
      integrationEditor.fetchAll();
      integrationEditor.formGroup.resetForm();
      integrationEditor.formGroup.setErrors({});
    }
  }, [mode]);
  return (
    <>
      {mode === Mode.List && <Integrations />}
      {mode === Mode.Add && (
        <CreateIntegrationModal
          toggleModal={integrationEditor.toggleIntegrationModal}
          showCreateModal={integrationEditor.showIntegrationCreateModal}
        />
      )}

      {mode === Mode.Edit && <EditIntegration />}
      {mode === Mode.Detail && <EditIntegration />}
    </>
  );
});

export default AdminIntegrations;
AdminIntegrations.displayName = 'AdminIntegrations';
