import { createContext, useState } from 'react';
import { useFormik } from 'formik';
import { IIntegrationContext, IIntegrations, IntegrationFormGroup, IntegrationSaveMode } from '../../types';
import { formSchema } from './schema';
import { FORM_INITIAL_VALUES } from '../constants';
import { generateUniqueId, includeKeys, isObjectEmpty } from '../../../../../util/util';

type Props = {
  children: JSX.Element;
};

export const IntegrationsContext = createContext<IIntegrationContext>({
  isBusy: false,
  integrations: [],
  setIntegrations: () => {},
  formGroup: {} as IntegrationFormGroup,
  selectedIntegrationID: null,
  setSelectedIntegrationID: () => {},
  replaceIntegrations: () => {},
  replaceIntegrationInformation: () => {},
  replaceTypeOfIntegration: () => {},
  updateIntegrationInformation: () => {},
  updateTypeOfIntegration: () => {},
  validateDraftForm: () => new Promise(() => ({ valid: false, errors: null })),
  validateForm: () => new Promise(() => {}),
  getRequestPayload: (_additionalProps: any) => {},
  integrationSaveMode: IntegrationSaveMode.ACTIVE,
  setIntegrationSaveMode: () => {},
});

export const IntegrationProvider = ({ children }: Props) => {
  const [isBusy /*setIsBusy*/] = useState(false);
  const [integrations, setIntegrations] = useState<Array<IIntegrations>>([]);
  const [selectedIntegrationID, setSelectedIntegrationID] = useState<string | null>(null);
  const [integrationSaveMode, setIntegrationSaveMode] = useState<string>(IntegrationSaveMode.ACTIVE);

  const formGroup = useFormik({
    initialValues: FORM_INITIAL_VALUES,
    validateOnMount: true,
    validationSchema: formSchema,
    onSubmit: () => {},
  });

  const updateIntegrationInformation = (inputName, inputValue) => {
    formGroup.setFieldValue(`integrationInformation.${inputName}`, inputValue);
  };

  const updateTypeOfIntegration = (inputName, inputValue) => {
    formGroup.setFieldValue(`typeOfIntegration.${inputName}`, inputValue);
  };

  const replaceIntegrations = (value) => {
    setIntegrations(value);
    formGroup.setFieldValue('integrations', value);
  };
  const replaceIntegrationInformation = (value) => {
    formGroup.setFieldValue('integrationInformation', value);
  };
  const replaceTypeOfIntegration = (value) => {
    formGroup.setFieldValue('typeOfIntegration', value);
  };

  const validateDraftForm = async () => {
    formGroup.setSubmitting(false);
    formGroup.setErrors({});
    // formGroup.handleReset(null);

    await formGroup.setFieldTouched('integrationInformation.shortName', true, true);

    const draftErrors = includeKeys(formGroup.errors, ['integrationInformation.shortName']);

    return isObjectEmpty(draftErrors) ? { valid: true, errors: null } : { valid: false, errors: draftErrors };
  };

  const validateForm = () => {
    formGroup.handleSubmit();

    return formGroup.validateForm();
  };

  const getRequestPayload = (additionalProps = {}) => {
    const { integrationInformation, typeOfIntegration } = formGroup.values;

    const data = {
      id: generateUniqueId(),
      ...integrationInformation,
      ...typeOfIntegration,
      ...additionalProps,
    };

    return data;
  };
  const context: IIntegrationContext = {
    isBusy,
    integrations,
    setIntegrations,
    replaceIntegrationInformation,
    replaceIntegrations,
    replaceTypeOfIntegration,
    updateIntegrationInformation,
    updateTypeOfIntegration,
    formGroup: formGroup as any,
    validateDraftForm,
    validateForm,
    getRequestPayload,
    selectedIntegrationID,
    setSelectedIntegrationID,
    integrationSaveMode,
    setIntegrationSaveMode,
  };
  return <IntegrationsContext.Provider value={context}>{children}</IntegrationsContext.Provider>;
};
