import { API_VERSION } from '../../../../util/constants';
import { get, put } from '../../../../util/httpService';

export const getInternalCalculations = async () => {
  const calc = await get(`${API_VERSION}/settings`);
  return calc;
};

export const saveInternalCalculations = async (payload) => {
  const result = await put(`${API_VERSION}/settings`, payload, {});

  return result;
};

export const saveSalaryCodes = async (payload) => {
  const result = await put(`${API_VERSION}/salary-codes/update/is-common`, payload, {});

  return result;
};

export const getAssignedSalaryCodes = async (payload) => {
  const result = await get(`${API_VERSION}/salary-codes`);

  return result;
};

export const getSalaryIntervalByYear = async ({ year }) => {
  const calc = await get(`${API_VERSION}/settings/${year}`);
  return calc;
};
