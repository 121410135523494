import { AlertTriangle } from 'react-feather';

import { numberFormat } from '../../../../util/util';
import { useSalarySlipContext } from '../context/useContext';
import { useSalaryCompensationTotal } from '../hooks/useSalaryCompensationTotal';
import SalaryArticleItem from './SalaryArticleItem';
import classNames from 'classnames';
import { SalaryErrorLevel } from './error_level';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

export default function SalaryArticle() {
  const { t } = useTranslation();
  const { selectedDetails, errorType, formGroup } = useSalarySlipContext();
  const { originalInput, modifiedInput } = useSalaryCompensationTotal(formGroup, selectedDetails);
  const errorCode = errorType ? errorType.type : null;

  return (
    <>
      <div className="table-responsive">
        <table
          className={classNames('table transaction-list', {
            'transaction-list--total-value': SalaryErrorLevel.isLevelTwo(errorCode!),
          })}
        >
          <thead>
            {errorCode && (
              <tr className="top-header">
                <th colSpan={7}></th>
                <th>{t('orignal_value')}</th>
                <th>{t('updated_values')}</th>
              </tr>
            )}
            <tr className="col-header">
              <th style={{ minWidth: 220 }}>{t('article')}</th>
              <th style={{ minWidth: 60 }}>{t('default')}</th>
              <th style={{ minWidth: 120, maxWidth: 120 }}>{t('from_date')}</th>
              <th style={{ minWidth: 120 }}>{t('to_date')}</th>
              <th style={{ minWidth: 80 }}>{t('pieces')}</th>
              <th style={{ minWidth: 80 }}>{t('unit')}</th>
              <th style={{ minWidth: 115 }}>{t('amount')}</th>
              <th className="original-value" style={{ minWidth: 145 }}>
                {t('value')}
              </th>
              <th
                className="new-input-value"
                style={Object.assign(
                  { minWidth: errorCode ? 140 : 20 },
                  SalaryErrorLevel.isLevelOne(errorCode) && { minWidth: 120 },
                )}
              >
                {errorCode && 'Value (new)'}
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedDetails!.articles.map((item, idx) => {
              return (
                <SalaryArticleItem
                  key={idx}
                  errorCode={errorCode}
                  index={idx}
                  selectedItem={selectedDetails?.articles[idx]!}
                />
              );
            })}
            {(selectedDetails?.comments || []).map((commentItem, idx) => {
              return (
                <tr key={`comment-${idx}`}>
                  <td colSpan={7}>
                    <Form.Control value={commentItem || ''} disabled={true} />
                  </td>
                  <td className="original-value"></td>
                  <td className="new-input-value text-right">
                    <AlertTriangle className="warning-indicator warning-indicator--markup" />
                  </td>
                </tr>
              );
            })}

            <tr className="separator">
              <td colSpan={7}></td>
              <td className="original-value"></td>
              <td className="new-input-value"></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td className="misc-article-item-summary" colSpan={4}>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td className="summary-items" colSpan={4} rowSpan={4}>
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                <div className="summary-item">
                                  <div className="summary-item__label">Skattetabell</div>
                                  <div className="summary-item__value">{selectedDetails!.taxTable}</div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Jämkning</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.equalization)}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Prel. arbetsgiv. avg %</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!['preliminaryEmployerSeverancePercentage'])}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Prel. arbetsgiv. avg</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.prelEmployerAvg)}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Tabellskattegrund</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.tableTaxBase)}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Engångsskattegrund</div>
                                  <div className="summary-item__value">{selectedDetails!.oneTimeTaxBase}</div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Ack. bruttolön</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.cumulativeGrossSalary)}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Ack. preliminärskatt</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.cumulativePreliminaryTax)}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="summary-item">
                                  <div className="summary-item__label">Flexsaldo</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.flexBalance)}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Kompsaldo</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.compBalance)}
                                  </div>
                                </div>
                                <h5 className="summary-title">Semesterdagar</h5>
                                <div className="summary-item">
                                  <div className="summary-item__label">Betald</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.holidays.paid)}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Sparad</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.holidays.saved)}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Obetald</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.holidays.obetald)}
                                  </div>
                                </div>
                                <div className="summary-item">
                                  <div className="summary-item__label">Förskott</div>
                                  <div className="summary-item__value">
                                    {numberFormat(selectedDetails!.holidays.forskott)}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td colSpan={3} className="total-label">
                <div className="box-label">
                  <div>Bruttolön</div>
                  <div>Preliminärskatt</div>
                  <div>Skattefria utlägg/avdrag</div>
                  <div className="last-row">To Payout</div>
                </div>
              </td>
              <td className="total-value original-value">
                <div className="box-value">
                  <div>
                    <div>
                      {numberFormat(originalInput.grossSalary)}
                      <AlertTriangle className="warning-indicator" />
                    </div>
                  </div>
                  <div>
                    <div>
                      {numberFormat(originalInput.tax)}
                      <AlertTriangle className="warning-indicator" />
                    </div>
                  </div>
                  <div>
                    <div>
                      <span>{numberFormat(originalInput.excludedDeductions)}</span>
                      <AlertTriangle className="warning-indicator" />
                    </div>
                  </div>
                  <div className="last-row">
                    <span>{numberFormat(originalInput.payout)}</span>
                    <AlertTriangle className="warning-indicator" />
                  </div>
                </div>
              </td>
              <td className="total-value new-input-value">
                <div className="right-Box">
                  <div>
                    {SalaryErrorLevel.isLevelTwo(errorCode) ? numberFormat(modifiedInput.grossSalary) : <>&nbsp;</>}
                  </div>
                  <div>{SalaryErrorLevel.isLevelTwo(errorCode) ? numberFormat(modifiedInput.tax) : <>&nbsp;</>}</div>
                  <div>
                    {SalaryErrorLevel.isLevelTwo(errorCode) ? (
                      numberFormat(modifiedInput.excludedDeductions)
                    ) : (
                      <>&nbsp;</>
                    )}
                  </div>
                  <div className="last-row">
                    {SalaryErrorLevel.isLevelTwo(errorCode) ? numberFormat(modifiedInput.payout) : <>&nbsp;</>}
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
        {/* <button type="button" onClick={test}>
          Test
        </button> */}
      </div>
    </>
  );
}
