import * as yup from 'yup';

export const formSchema = yup.object().shape({
  articles: yup.array().of(
    yup.object().shape({
      article: yup.mixed(),
      fromDate: yup.mixed(),
      toDate: yup.mixed(),
      pieces: yup.mixed(),
      unit: yup.mixed(),
      amount: yup.mixed(),
      orignalValue: yup.mixed(),
      updatedValue: yup.mixed(),
    }),
  ),
  // .min(1, 'At least one signatory is required'),
});
