import { useState } from 'react';

export const useEmployeeModal = () => {
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const addSeriesHandler = () => {
    setShowEmployeeModal(!showEmployeeModal);
  };
  const toggleModal = () => {
    setShowEmployeeModal(!showEmployeeModal);
  };
  return {
    showEmployeeModal,
    addSeriesHandler,
    toggleModal,
  };
};
