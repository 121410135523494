import React from 'react';
import { Row } from 'react-bootstrap';
import PagesHeader from '../../../../components/base/PagesHeader';
import { FormProps } from '../types';
import { INIVITATION_EMAIL_SETTING_OPTIONS } from '../../../../util/constants';
import BaseSelectBox from '../../../../components/base/BaseSelectBox';
import useLocale from '../../../../hooks/useLocale';

export default function Invitation({ context }: FormProps) {
  const { localize } = useLocale();
  const { invitationEmail, formGroup, updateInvitationEmail } = context;
  return (
    <div>
      <PagesHeader pageTitle="Invitation email settings" headingClass="fs-h3 font-black" />
      <div className="form-section mb-3 dark-content-section">
        <Row className="align-items-end mb-3 cr-days-field">
          <BaseSelectBox
            options={INIVITATION_EMAIL_SETTING_OPTIONS}
            maxWidth={250}
            onChangeHandler={(e) => updateInvitationEmail(e.target.value)}
            field={formGroup.getFieldMeta(`invitationEmail`)}
            value={invitationEmail}
            name="invitationEmail"
            label={localize('invitation_email_label')}
            labelSuffix={`(${localize('days')})`}
            labelDetail={localize('invitation_email_label_detail')}
            labelDetailClassname="fst-italic"
          />
        </Row>
      </div>
    </div>
  );
}
