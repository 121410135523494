import { Form, Spinner } from 'react-bootstrap';
import classNames from 'classnames';

import { PercentageNumber } from '../../../../components/form/inputs/PercentangeNumber';
import { useTranslation } from 'react-i18next';
interface Props {
  salaryTypesData: any[];
  chosenSalaryCodes: any[];
  isBusy: boolean;
  onChangeSalaryCode: (salaryCodeItem, isChecked) => void;
}

const SalaryCodeConversionTable = ({ salaryTypesData = [], isBusy, onChangeSalaryCode, chosenSalaryCodes }: Props) => {
  const { t } = useTranslation();
  const hasCheckedSalaryCode = (salaryCodeItem) => {
    return chosenSalaryCodes.find((item) => item.id === salaryCodeItem._id)?.isCommon || false;
  };

  return isBusy ? (
    <Spinner />
  ) : (
    <>
      <div
        className="table-responsive react-bootstrap-table"
        style={{ height: salaryTypesData?.length > 0 ? '400px' : 'auto' }}
      >
        <table className="table table-condensed salary-types-table">
          <thead className="header-wrapper">
            <tr className="correcto-table-header">
              <th style={{ maxWidth: 100, width: 100 }}>{t('salary_code')}</th>
              <th style={{ maxWidth: 300, width: 300 }}>{t('name')}</th>
              <th style={{ maxWidth: 110, width: 110 }}>{t('percentage')} (%)</th>
              <th>{t('common')}</th>
            </tr>
          </thead>
          <tbody>
            {salaryTypesData?.map((salary, index) => (
              <tr
                className={classNames('highlight-inactive', {
                  'active-highlighted-row': salary.selected,
                })}
                key={index}
              >
                <td style={{ maxWidth: 100, width: 100 }}>
                  <Form.Control value={salary.code} disabled />
                </td>
                <td style={{ maxWidth: 300, width: 300 }}>
                  <Form.Control value={salary.name} disabled />
                </td>
                <td style={{ maxWidth: 110, width: 110 }}>
                  <PercentageNumber
                    value={salary.percentage}
                    disabled
                    decimalPlace={2}
                    inputStyle={{ textAlign: 'right' }}
                    name="percentage"
                  />
                </td>
                <td>
                  <Form.Check
                    type="checkbox"
                    label={' '}
                    checked={hasCheckedSalaryCode(salary)}
                    onChange={(e) => onChangeSalaryCode(salary, e.target.checked)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SalaryCodeConversionTable;
