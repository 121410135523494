import { FieldMetaProps } from 'formik';

export const errorHighlighter = (field: FieldMetaProps<any>, styleClassName = '') => {
  return field.touched && field.error ? styleClassName || 'form-error' : '';
};
export const requiredError = (field: FieldMetaProps<any>) => {
  return field.touched && field.error ? 'required' : '';
};
export const getFieldError = (field: FieldMetaProps<any>) => {
  return field.touched && field.error ? field.error : '';
};
export const isOrganizationNumberValid = (value) => {
  const reg = new RegExp(/^\(?(((2|5|7|8|9))[0-9]{5})\)?[-. ]?([0-9]{4})$/);
  if (!reg.test(value)) {
    return true;
  } else {
    return false;
  }
};
export const isPersonalNumberMatch = (value) => {
  const reg = new RegExp(
    /^\(?((19|20)[0-9]{2})\)?[-. ]?(0[1-9]|1[0-2])[-. ]?(0[1-9]|1[0-9]|2[0-9]|3[01])[-. ]?([0-9]{4})$/,
  );
  if (!reg.test(value)) {
    return true;
  } else {
    return false;
  }
};
