import React, { useContext } from 'react';
import { CompanyClient } from '../../../../api/service/superAdmin/company-clients/types';
import { ReactComponent as AccessKey } from '../../../../assets/img/access-key.svg';
import { OverlayLoaderContext } from '../../../../contexts/OverlayLoaderContext';
import TooltipBox from '../../../../components/base/Overlay/TooltipBox';
import { t } from 'i18next';
import useCompanyRoleSwitcher from '../hooks/useCompanyRoleSwitcher';

type Props = {
  row: CompanyClient;
};

const CompanyRoleSwitcher = ({ row }: Props) => {
  const { setBusy } = useContext(OverlayLoaderContext);
  const { switchToCompanyAdmin } = useCompanyRoleSwitcher({ setBusy, id: row.id });

  return (
    <div className="row-action d-flex justify-content-end align-items-center">
      <span className="me-4 mb-0">
        <TooltipBox msg={t('GLOBAL.remote_access')!} id={t('GLOBAL.remote_access')!}>
          <AccessKey onClick={switchToCompanyAdmin} className="cursor-pointer crt-icon-primary" />
        </TooltipBox>
      </span>
    </div>
  );
};

export default CompanyRoleSwitcher;
