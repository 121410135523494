import useEmployeePensionContext from '../useContext';
import { DialogWithTextField } from '../../../../util/dialogs';
import { useTranslation } from 'react-i18next';
import { cancelEmployeeScheduledPension } from '../../../../api/service/company/employees/service';
import useActionLoading from './useActionLoading';
import moment from 'moment';

const usePensionSchedule = () => {
  const { t } = useTranslation();
  const actionLoading = useActionLoading();

  const { selectedScheduledEmployeePension, employeePensionSummary } = useEmployeePensionContext();

  const onCancelSchedule = async (onSuccessCallback) => {
    const title = `${t('i_want_to_cancel_that_schedule')}`;
    const textPara = `${t('to_cancel_that_schedule')}, ${t('type_in')}`;

    const result = await DialogWithTextField({
      title: t('are_you_sure'),
      confirmButtonText: t('yes'),
      denyButtonText: t('no'),
      showConfirmationInput: true,
      validationTitle: title,
      validationMessage: t('validation_message'),
      content: {
        caption: textPara,
        body: title,
      },
    });

    if (result.isConfirmed) {
      cancelSchedule(onSuccessCallback);
    }
  };

  const cancelSchedule = async (onSuccessCallback) => {
    try {
      actionLoading.toggle('cancelSchedule', true);
      await cancelEmployeeScheduledPension(employeePensionSummary!._id);
    } catch (e) {
      console.error(e);
    } finally {
      actionLoading.toggle('cancelSchedule', false);
    }

    if (onSuccessCallback) {
      onSuccessCallback();
    }
  };

  const hasShownEffectiveDateNotification = () => {
    if (selectedScheduledEmployeePension && !selectedScheduledEmployeePension.id) {
      return false;
    }

    if (
      selectedScheduledEmployeePension &&
      selectedScheduledEmployeePension.effectiveFrom &&
      selectedScheduledEmployeePension?.policySettings
    ) {
      const effectiveDate = selectedScheduledEmployeePension.effectiveFrom;

      const fixedMonthlyEffectiveDate =
        selectedScheduledEmployeePension.policySettings.salaryChange?.fixedMonthlyOffset?.effectiveFrom;
      const percentageOfSalaryEffectiveDate =
        selectedScheduledEmployeePension.policySettings.salaryChange?.percentageOfSalaryOffset?.effectiveFrom;
      const manualOffsetEffectiveDate =
        selectedScheduledEmployeePension.policySettings.salaryChange?.manualOffset?.effectiveFrom;
      const pensionManagerEffectiveDate =
        selectedScheduledEmployeePension.policySettings.employeeMetaSettings?.pensionManager?.effectiveFrom;
      const insuranceEffectiveDate =
        selectedScheduledEmployeePension.policySettings.employeeMetaSettings?.insurance?.effectiveFrom;

      return [
        fixedMonthlyEffectiveDate,
        percentageOfSalaryEffectiveDate,
        manualOffsetEffectiveDate,
        pensionManagerEffectiveDate,
        insuranceEffectiveDate,
      ].every((dateItem) => effectiveDate === dateItem)
        ? false
        : true;
    }

    return false;
  };

  const hasShownScheduledEffectiveDateNotifier = (date) => {
    if (!date || (selectedScheduledEmployeePension && !selectedScheduledEmployeePension.id)) {
      return false;
    }

    const effectiveDate = moment(selectedScheduledEmployeePension.effectiveFrom);
    const inputDate = moment(date);

    if (inputDate.format('YYYY-MM') === effectiveDate.format('YYYY-MM')) {
      return false;
    }

    if (inputDate.isAfter(effectiveDate)) {
      return true;
    }

    return false;
  };

  return {
    onCancelSchedule,
    hasShownEffectiveDateNotification,
    hasShownScheduledEffectiveDateNotifier,
  };
};

export default usePensionSchedule;
