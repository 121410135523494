import { useEffect, useState } from 'react';

import { getProvisions } from '../../../../../api/service/accountingFirm/clients/service';
import CardComponent from '../../../../../components/base/CardComponent';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { IAccountingFirmProvision } from '../../types';
import { useColumn } from './useColumn';
import BSTable from '../../../../../components/table/BSTable';
import moment from 'moment';
import MonthAndYearDropdown from '../../../../../section/MonthAndYearDropdown';
import { useTranslation } from 'react-i18next';

export const Provision = () => {
  const [provisionList, setProvisionList] = useState<IAccountingFirmProvision[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [provisionFilter, setProvisionFilter] = useState({
    period: '',
  });
  const { columns } = useColumn();
  const { t } = useTranslation();
  const fetchAllProvisions = async () => {
    try {
      setIsLoading(true);
      const provisions = await getProvisions();
      setProvisionList(provisions);
    } finally {
      setIsLoading(false);
    }
  };

  const changeFilter = (key, value) => {
    setProvisionFilter((prevValue) => ({
      ...prevValue,
      [key]: value,
    }));
  };

  useEffect(() => {
    fetchAllProvisions();
  }, []);

  return (
    <>
      <CardComponent>
        <Row className="justify-content-between">
          <Col className="mb-5" md={9}>
            <Form>
              <div className="d-flex mb-3 align-items-center">
                <Form.Group className="me-3">
                  <Form.Label className="fs-name">{t('period')}</Form.Label>
                  <MonthAndYearDropdown
                    startFrom={moment().subtract(3, 'months').toString()}
                    value={provisionFilter.period}
                    onOptionSelect={(val) => changeFilter('period', val)}
                    showLabelCurrent
                  />
                </Form.Group>
              </div>
            </Form>
          </Col>
          {isLoading ? (
            <Spinner />
          ) : (
            <BSTable
              data={provisionList}
              columns={columns}
              noDataIndication={`${t('no_provisions_found')}`}
              keyField="id"
            />
          )}
        </Row>
      </CardComponent>
    </>
  );
};
