import {
  API_VERSION,
  COMPENSATION_PLANS_ENDPOINT,
  PENSION_COMPANIES_ENDPOINT,
  PENSION_OFFSET_ENDPOINT,
  PENSION_POLICY_ENDPOINT,
  SALARY_TRANSACTIONS_ENDPOINT,
  SALARY_TYPES_ENDPOINT,
} from '../../../../util/constants';
import { post, get, del, put } from '../../../../util/httpService';
import { PensionOffset } from './types';
export const savePolicy = async (payload) => {
  const endpoint = `${API_VERSION}${PENSION_POLICY_ENDPOINT}`;
  const policy = await post(endpoint, payload, {});
  return policy;
};

export const updatePolicy = async (id, payload) => {
  const endpoint = `${API_VERSION}${PENSION_POLICY_ENDPOINT}/${id}`;
  const policy = await put(endpoint, payload, {});
  return policy;
};

export const getPolicies = async ({
  type = '',
  employeeType = '',
  page = 1,
  sizePerPage = 25,
  searchString = '',
  sortField = 'name',
  sortOrder = '1',
  paginated = false,
  selectAll = false,
  employeeId = null,
}) => {
  let requestURL = paginated
    ? `${API_VERSION}${PENSION_POLICY_ENDPOINT}/getAll`
    : `${API_VERSION}${PENSION_POLICY_ENDPOINT}`;

  let endpoint = requestURL;

  if (employeeType && !type) {
    endpoint = `${requestURL}?employeeType=${employeeType}&filter[name][like]=${searchString}`;
  } else if (type && !employeeType) {
    endpoint = `${requestURL}?type=${type}&filter[name][like]=${searchString}`;
  } else if (type && employeeType) {
    endpoint = `${requestURL}?type=${type}&employeeType=${employeeType}&filter[name][like]=${searchString}`;
  }

  if (!selectAll) {
    const pageQuery = `&page=${page}&pageSize=${sizePerPage}&sort[${sortField}]=${sortOrder}`;

    endpoint += pageQuery;
  }

  if (employeeId) {
    endpoint += `&employeeId=${employeeId}`;
  }

  const policies = await get(endpoint);

  return policies;
};

export const getPolicyDetails = async (id) => {
  const endpoint = `${API_VERSION}${PENSION_POLICY_ENDPOINT}/${id}`;
  const policy = await get(endpoint);
  return policy;
};

export const getPolicyDetailsByName = async (name) => {
  const endpoint = `${API_VERSION}${PENSION_POLICY_ENDPOINT}/name/${name}`;
  const policy = await get(endpoint);
  return policy;
};

export const deletePolicy = async (id) => {
  const endpoint = `${API_VERSION}${PENSION_POLICY_ENDPOINT}/${id}`;
  const policy = await del(endpoint, {});
  return policy;
};

export const duplicatePolicy = async (id) => {
  const endpoint = `${API_VERSION}${PENSION_POLICY_ENDPOINT}/duplicate/${id}`;
  const policy = await post(endpoint, {}, {});
  return policy;
};

export const getCompensationPlans = async () => {
  const endpoint = `${API_VERSION}${COMPENSATION_PLANS_ENDPOINT}`;
  const plans = await get(endpoint);
  return plans;
};

export const saveCompensationPlans = async (payload) => {
  const endpoint = `${API_VERSION}${COMPENSATION_PLANS_ENDPOINT}`;
  const plan = await post(endpoint, payload, {});
  return plan;
};

export const getSalaryTypes = async () => {
  const endpoint = `${API_VERSION}${SALARY_TYPES_ENDPOINT}`;
  const salaryTypes = await get(endpoint);
  return salaryTypes;
};

export const getPensionCompanies = async () => {
  const endpoint = `${API_VERSION}${PENSION_COMPANIES_ENDPOINT}`;
  const pensionCompanies = await get(endpoint);
  return pensionCompanies;
};

export const getSalaryTransactions = async ({ employeeId }) => {
  const endpoint = `${API_VERSION}${SALARY_TRANSACTIONS_ENDPOINT}?employeeId=${employeeId}`;
  const salaryTransactions = await get(endpoint);
  return salaryTransactions;
};

export const getPensionOffset = async ({ employeeId }) => {
  const endpoint = `${API_VERSION}${PENSION_OFFSET_ENDPOINT}/${employeeId}`;
  const pensionOffset: PensionOffset[] = await get(endpoint);
  return pensionOffset;
};

export const isAutoAssignEmployeePolicyType = async (employeeType) => {
  const endpoint = `${API_VERSION}${PENSION_POLICY_ENDPOINT}/check-auto-assign/${employeeType}`;
  const isAutoAssign = await get(endpoint);
  return isAutoAssign;
};
// export const getSalaryCodes = async () => {
//   const endpoint = `${API_VERSION}/salary-codes/add/common`;
//   const salaryCodes = await get(endpoint);
//   return salaryCodesData
// };
