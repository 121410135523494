import * as Yup from 'yup';
import {
  organizationNumberValidations,
  personalNumberValidations,
  validatePersonalNumber,
} from '../../../../util/validation';

export const formSchema = Yup.object().shape({
  firmDetails: Yup.object().shape({
    organizationNumber: organizationNumberValidations(),
    name: Yup.string().required('Required'),
    status: Yup.string().required('Required'),
    contractNumber: Yup.string().nullable(),
    resellerAgreement: Yup.string().required('Required'),
  }),
  addressDetails: Yup.object().shape({
    city: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
    street: Yup.string().required('Required'),
  }),
  contractEffectiveFrom: Yup.string().required('Required'),
  invoicingCostCenter: Yup.string(),
  invoiceEmailAddress: Yup.string().email('Invalid email'),
  /* signatoryDetails: Yup.array()
    .of(
      Yup.object().shape({
        personalNumber: personalNumberValidations(),
        fullName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        incharge: Yup.boolean(),
      }),
    )
    .min(1, 'At least one signatory is required'), */
  supervisors: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.string(),
        personalNumber: personalNumberValidations(),
        fullName: Yup.string(),
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
      }),
    )
    .min(1, 'At least one supervisor is required'),
  seniorConsultants: Yup.array().of(
    Yup.object().shape({
      personalNumber: Yup.string()
        .trim('Personal Number cannot contain spaces')
        .test('personal_number_validator', '', validatePersonalNumber),
      fullName: Yup.string(),
      firstName: Yup.string().when('personalNumber', {
        is: (val) => val && val.trim().length > 0,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      lastName: Yup.string().when('personalNumber', {
        is: (val) => val && val.trim().length > 0,
        then: Yup.string().required('Required'),
        otherwise: Yup.string(),
      }),
      email: Yup.string()
        .email('Invalid email')
        .when('personalNumber', {
          is: (val) => {
            return val && val.trim().length > 0;
          },
          then: Yup.string().required('Required'),
          otherwise: Yup.string(),
        }),
    }),
  ),
  invitationEmail: Yup.string(),
  combinations: Yup.array()
    .of(
      Yup.object().shape({
        authorizedSignatory: Yup.lazy((authorizedSignatory, context) => {
          const signee = context.parent.signee;

          if (signee) {
            return Yup.array().of(
              Yup.object().shape({
                personalNumber: personalNumberValidations(),
                fullName: Yup.string(),
                email: Yup.string().email('Invalid email').required('Required'),
              }),
            );
          } else {
            return Yup.array().of(
              Yup.object().shape({
                personalNumber: personalNumberValidations(),
                fullName: Yup.string(),
                email: Yup.string().email('Invalid email'),
              }),
            );
          }
        }),
        signee: Yup.boolean(),
      }),
    )
    .min(1, 'At least one signatory is required'),
});
