import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useContext from './context/useContext';
import { useSuperAdminSettingsContext } from '../useContext';
import { generateUniqueId } from '../../../../util/util';
import { Mode, insurancePlanList } from '../types';
import { MoreVertical } from 'react-feather';
import { DeleteAction } from './actions';
import { getInsuranceCompanyDetail } from '../../../../api/service/superAdmin/settings/insurances';

const InsurancesRowItem = ({ item }: any) => {
  const {
    insurances,
    replaceInsuranceCompany,
    replaceContactInformation,
    replaceConnection,
    formGroup,
    setInsurancePlans,
    setSelectedInsuranceID,
    setSelectedInsuranceDetails,
  } = useContext();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id!,
    attributes: {
      role: 'draggable',
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  const { setMode } = useSuperAdminSettingsContext();

  const setInsuranceCompanyDetail = async (id) => {
    const insuranceCompanyDetail = (await getInsuranceCompanyDetail(id)) ?? [];
    setSelectedInsuranceDetails(insuranceCompanyDetail);

    replaceInsuranceCompany({
      shortName: insuranceCompanyDetail['shortName'],
      companyName: insuranceCompanyDetail['companyName'],
      clientNumber: insuranceCompanyDetail['clientNumber'],
      contractNumber: insuranceCompanyDetail['contractNumber'],
      description: insuranceCompanyDetail['description'],
      organizationNumber: insuranceCompanyDetail['organizationNumber'],
      vatNumber: insuranceCompanyDetail['vatNumber'],
      bankgiroNumber: insuranceCompanyDetail['bankgiroNumber'],
      plusgiroNumber: insuranceCompanyDetail['plusgiroNumber'],
      pensionType: insuranceCompanyDetail['pensionType'],
      status: insuranceCompanyDetail['status'],
      collectiveAgreement: insuranceCompanyDetail['collectiveAgreement'],
    });
    replaceContactInformation(insuranceCompanyDetail?.contactInformation);
    replaceConnection(insuranceCompanyDetail?.connection);
    setInsurancePlans(insuranceCompanyDetail?.insurances);
    if (insuranceCompanyDetail?.insurances) {
      Object.keys(insuranceCompanyDetail?.insurances)
        .filter((insuranceTypeKey) => {
          return insurancePlanList.find((item) => item.value === insuranceTypeKey);
        })
        .map((insuranceTypeKey) => {
          return {
            [insuranceTypeKey]: insuranceCompanyDetail?.insurances[insuranceTypeKey].map((plan) => {
              plan.id = generateUniqueId();

              return plan;
            }),
          };
        })
        .forEach((value) => {
          formGroup.setFieldValue(`insurancePlans.${Object.keys(value)[0]}`, Object.values(value)[0]);
        });
      formGroup.setFieldValue(
        `insurancePlans.providerDescription`,
        insuranceCompanyDetail.insurances.providerDescription,
      );
    }

    setSelectedInsuranceID(id);
    setMode(Mode.Detail);
  };

  return (
    <tr ref={setNodeRef} style={style} {...attributes} className="cursor-pointer">
      <td className="align-baseline">
        <div {...listeners} className="draggable-item">
          <MoreVertical />
        </div>
      </td>
      <td onClick={() => setInsuranceCompanyDetail(item.id)}>{item.shortName}</td>
      <td onClick={() => setInsuranceCompanyDetail(item.id)}>{item.description}</td>
      <td onClick={() => setInsuranceCompanyDetail(item.id)}>
        <span
          className={classNames('fs-text-400', {
            'theme-text': item.status === 'Draft',
            'active-green': item.status !== 'Draft',
          })}
        >
          {item.status ?? 'Active'}
        </span>
      </td>
      <td align="right">
        <DeleteAction id={item?.id} data={insurances} />
      </td>
    </tr>
  );
};

export default InsurancesRowItem;
