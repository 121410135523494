export const defaultBarStackChartOption = {
    chart: {
        type: 'column',
        ignoreHiddenSeries: true,
        zoomType: null,
        zooming: {
            mouseWheel: {
                enabled: false
            }
        },
    },
    scrollbar: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    title: {
        text: '',
    },
    subtitle: {
        text: null,
    },
    navigator: {
        enabled: false,
    },
    rangeSelector: {
        inputEnabled: true,
        verticalAlign: 'bottom',
        enabled: false,
    },

    xAxis: {
        min: 0,
        type: "datetime",
        labels: {
            format: '{value:%b-%y}'
        }

    },
    yAxis: [
        {
            labels: {
                format: '{text}',
            },
            lineWidth: 1,
            min: 0,
            offset: 0,
            tickWidth: 1,
            gridLineWidth: 0,
            visible: true,

        },
        {
            labels: {
                format: '{text}',
            },
            lineWidth: 1,
            min: 0,
            offset: 0,
            tickWidth: 1,
            gridLineWidth: 0,
            visible: true,

        }

    ],
    legend: {
        enabled: false
    },
    exporting: {
        enabled: false,
        csv: {
            dateFormat: '%b-%Y',
            columnHeaderFormatter: function (item, key) {
                if (!key) {
                    return 'Month'
                }
                return false
            }
        }
    },
    plotOptions: {
        column: {
            enableMouseTracking: false,
            stacking: "normal",
            cursor: 'pointer',
            groupPadding: 0,
            pointWidth: 16,
            dataLabels: {
                enabled: false
            }
        }
    },
    series: []

};

export const barStackChartDarkModeOption = {
    chart: {
        backgroundColor: '#3c3c3c',
        borderWidth: 1,
        borderColor: '#ffffff33',
        style: {
            color: '#fff',
            fontFamily: "'Source Sans Pro', sans-serif",
        },
    },
    title: {
        style: {
            color: '#F00',
            font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
        },
    },
    navigator: {
        maskFill: 'rgba(255,255,255,0.25)',
    },
    rangeSelector: {
        inputStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
        },
        labelStyle: {
            color: '#ffffff',
            fontWeight: 'bold',
        },
    },
    xAxis: {
        labels: {
            style: {
                color: '#ffffff',
                fontFamily: "'Source Sans Pro', sans-serif",
            },
        },
        lineColor: '#ffffff',
        minorTickColor: '#ffffff',
        tickColor: '#ffffff',
    },
    yAxis: [
        {
            min: 0,
            visible: true,
            labels: {
                format: '{text}',
                style: {
                    color: '#ffffff',
                    fontFamily: "'Source Sans Pro', sans-serif",
                },
            },
            title: {
                text: null,
            },
        },
        {
            visible: true,
            opposite: false,
            gridLineWidth: 0,
            title: {
                text: null,
            },
            labels: {
                format: '{text}',
                style: {
                    color: '#ffffff',
                    fontFamily: "'Source Sans Pro', sans-serif",
                },
            },
            lineColor: '#ffffff',
            lineWidth: 1,
            minorTickColor: '#ffffff',
            tickColor: '#ffffff',
            tickWidth: 1,
            min: 0,
            offset: 0,
        },
    ],
    legend: {
        itemStyle: {
            color: '#ffffff',
        },
        itemHiddenStyle: {
            color: '#b2adad',
        },
    },
    series: [],
};

export const barStackChartLightModeOption = {
    chart: {
        backgroundColor: null,
        style: {
            color: '#000000',
            fontFamily: "'Source Sans Pro', sans-serif",
        },
    },
    title: {
        style: {
            color: '#F00',
            font: 'bold 16px "Trebuchet MS", Verdana, sans-serif',
        },
    },
    navigator: {
        maskFill: 'rgba(255,255,255,0.25)',
    },
    rangeSelector: {
        inputStyle: {
            color: '#000000',
            fontWeight: 'bold',
        },
        labelStyle: {
            color: '#000000',
            fontWeight: 'bold',
        },
    },
    xAxis: {
        labels: {
            style: {
                color: '#000000',
                fontFamily: "'Source Sans Pro', sans-serif",
            },
        },
        lineColor: '#000000',
        minorTickColor: '#000000',
        tickColor: '#000000',

    },
    yAxis: [
        {
            min: 0,
            visible: true,
            labels: {
                format: '{text}',
                style: {
                    color: '#000000',
                    fontFamily: "'Source Sans Pro', sans-serif",
                },
            },
            title: {
                text: null,
            },
        },
        {
            visible: true,
            opposite: false,
            gridLineWidth: 0,
            title: {
                text: null,
            },
            labels: {
                format: '{text}',
                style: {
                    color: '#000000',
                    fontFamily: "'Source Sans Pro', sans-serif",
                },
            },
            lineColor: '#000000',
            lineWidth: 1,
            minorTickColor: '#000000',
            tickColor: '#000000',
            tickWidth: 1,
        },
    ],
    legend: {
        itemStyle: {
            color: '#000000',
        },
        itemHiddenStyle: {
            color: '#b2adad',
        },
    },
    series: [],

};
