import { PensionPolicy, PensionPolicyVariant } from '../types';

const usePreferredPolicy = (selectedPolicyDetails: PensionPolicy) => {
  const type: PensionPolicyVariant | null = selectedPolicyDetails
    ? (selectedPolicyDetails.type as PensionPolicyVariant)
    : null;

  const hasCorrectoAdmin = () => {
    if (!type) {
      return false;
    }

    return [PensionPolicyVariant.CORRECTO_PROVIDED, PensionPolicyVariant.COLLECTIVE_AGREEMENT].indexOf(type) !== -1;
  };

  const hasCompanyAdmin = () => {
    if (!type) {
      return false;
    }

    return [PensionPolicyVariant.COMPANY, PensionPolicyVariant.PERSONAL].indexOf(type) !== -1;
  };

  const hasOnlyCompanyAdmin = () => {
    if (!type) {
      return false;
    }

    return [PensionPolicyVariant.COMPANY].indexOf(type) !== -1;
  };

  const hasOnlyPersonal = () => {
    if (!type) {
      return false;
    }

    return [PensionPolicyVariant.PERSONAL].indexOf(type) !== -1;
  };

  const isCollectiveContract = () => {
    if (!selectedPolicyDetails) {
      return false;
    }

    return selectedPolicyDetails.isCollectiveAgreementLock;
  };

  return {
    hasCorrectoAdmin,
    hasCompanyAdmin,
    hasOnlyCompanyAdmin,
    hasOnlyPersonal,
    isCollectiveContract,
  };
};

export default usePreferredPolicy;
