/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation, useNavigate } from 'react-router-dom';

import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { AuthUser, AuthState, ActionMap, ContextType, UserRole, UserSubRole } from '../types/auth';
import { pathRegistery } from '../routes/route-path';
import { AUTH_INIT_COMPANY_SESS, AUTH_INIT_COMPANY_TOKEN } from '../util/constants';

const INITIALIZE = 'INITIALIZE';
const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';

type AuthActionTypes = {
  [INITIALIZE]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [SIGN_IN]: {
    user: AuthUser;
    accessToken: string;
  };
  [SIGN_OUT]: null;
};

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userRole: null,
  userSubRole: null,
};

const AuthReducer = (state: AuthState, action: ActionMap<AuthActionTypes>[keyof ActionMap<AuthActionTypes>]) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isLoading: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload.accessToken,
        isLoading: false,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<ContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const localUser = localStorage.getItem('user');
  const userLocalStorage = localUser ? (JSON.parse(localUser) as AuthUser) : null;
  const accessToken = localStorage.getItem('access_token');
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [userRole, setUserRole] = useState<UserRole | null>(null);
  const [userSubRole, setUserSubRole] = useState<UserSubRole | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const initialize = async () => {
      try {
        if (!isEmpty(userLocalStorage) && !isEmpty(accessToken)) {
          signIn(userLocalStorage, pathname !== '/' ? pathname : '/dashboard');
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (user: AuthUser, navigateTo?: string) => {
    let redirectLink = user?.role?.role === UserRole.EMPLOYEE ? '/employee' : '/dashboard';

    dispatch({
      type: INITIALIZE,
      payload: {
        isAuthenticated: true,
        user: user,
      },
    });
    const currentCompanyRole = user?.role;

    if (currentCompanyRole) {
      setUserRole(currentCompanyRole!.role);
    }

    if (currentCompanyRole?.subRole) {
      redirectLink = '/dashboard';
      setUserSubRole(currentCompanyRole!.subRole);
    }
    navigate(navigateTo ?? redirectLink, { replace: true });
  };

  const redirectRoute = () => {
    const role = userLocalStorage?.role?.role;

    if (!role) {
      return pathRegistery.signIn;
    }

    if (role === UserRole.SUPER_ADMIN) {
      return pathRegistery.adminSignIn;
    } else if (role === UserRole.EMPLOYEE) {
      return pathRegistery.employeeSignIn;
    } else {
      return pathRegistery.signIn;
    }
  };

  const signOut = async () => {
    const redirectUrl = redirectRoute();

    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem(AUTH_INIT_COMPANY_SESS);
    localStorage.removeItem(AUTH_INIT_COMPANY_TOKEN);
    dispatch({
      type: SIGN_OUT,
      payload: null,
    });
    setUserRole(null);
    setUserSubRole(null);
    navigate(redirectUrl, { replace: true });

    if (redirectUrl === pathRegistery.employeeSignIn) {
      window.location.reload();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        signIn,
        signOut,
        userRole,
        userSubRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
