export class HttpQuery {
  static serialize(params: Record<string, any>, prefix: string | null = null) {
    let queryVal = Object.keys(params)
      .filter((key) => {
        const val = params[key];

        if (typeof val !== 'boolean' && !val) {
          return false;
        }

        if (val.constructor === Array && val.length === 0) {
          return false;
        } else if (val.constructor.name === 'Object' && Object.keys(val).length === 0) {
          return false;
        }

        return val != null && val !== '';
      })
      .map((key) => {
        const value = params[key];

        if (value.constructor === Array) {
          return this.serializeArray(value, key);
        } else if (value.constructor.name === 'Object') {
          return this.serializeObject(value, key);
        }

        return `${key}=${value}`;
      })
      .filter((value) => {
        if (value === '' || value === undefined || value === null) {
          return false;
        }

        return true;
      })
      .join('&');

    if (prefix) {
      return prefix + (queryVal ? `?${queryVal}` : '');
    }

    return queryVal;
  }

  static serializeObject(obj: Object, parentKey: number | string | null = null) {
    const params: string[] = [];

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        const currentKey = parentKey ? `${parentKey}[${key}]` : key;

        if (value === null || value === '') {
          continue;
        }

        if (typeof value === 'object' && value !== null) {
          params.push(this.serializeObject(value, currentKey));
        } else {
          params.push(`${encodeURIComponent(currentKey)}=${encodeURIComponent(value)}`);
        }
      }
    }

    return params.join('&');
  }

  static serializeArray(arr: any[], parentKey: number | string | null = null) {
    const params: string[] = [];

    arr.forEach((item, index) => {
      const currentKey = parentKey ? `${parentKey}[${index}]` : index;

      if (typeof item === 'object' && item !== null) {
        if (Array.isArray(item)) {
          params.push(this.serializeArray(item, currentKey));
        } else {
          params.push(this.serializeObject(item, currentKey));
        }
      } else {
        params.push(`${encodeURIComponent(currentKey)}=${encodeURIComponent(item)}`);
      }
    });

    return params.join('&');
  }
}
