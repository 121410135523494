import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getSalaryTransactions } from '../../../api/service/superAdmin/pension-policies/service';
import CardComponent from '../../../components/base/CardComponent';
import PagesHeader from '../../../components/base/PagesHeader';
import ReportHeader from '../../../components/report/ReportHeader';
import ReportLine from '../../../components/report/ReportLine';
import { SalaryTransaction } from './types';
import { numberSpaceSeparator } from '../../../util/util';

export const SalarySpecification = () => {
  const [salaryTransactions, setSalaryTransactions] = useState<SalaryTransaction[]>([]);
  const params = useParams();
  const { t } = useTranslation();
  const [activeIndex, setactiveIndex] = useState(0);
  const [showTotal, setshowTotal] = useState(2);
  // const salaryTransactionDetails = Object.entries(salaryTransactions);
  const slicedData = salaryTransactions.slice(activeIndex, showTotal);
  const totalDataLength = salaryTransactions.length;

  const fetchSalaryTransactions = async () => {
    const _transactions = await getSalaryTransactions({
      employeeId: params.id,
    });
    setSalaryTransactions(_transactions);
  };

  const handleNext = () => {
    setactiveIndex(showTotal);
    setshowTotal(showTotal + 2);
    // fetchIntCalc();
  };

  const handlePrev = () => {
    setactiveIndex(activeIndex - 2);
    setshowTotal((total) => total - 2);
  };

  useEffect(() => {
    fetchSalaryTransactions();
  }, []);

  return (
    <div className="px-2">
      {!!slicedData.length && (
        <Row>
          <div className="d-flex justify-content-between p-0 mb-0 ">
            <h3 className="fs-22px theme-text mt-3 font-weight-600"> {t('salary_specifications')}</h3>
            <div>
              <Button
                style={{ borderRadius: 6 }}
                className="app-btn-primary me-2 fs-label unique-disable"
                onClick={handlePrev}
                disabled={activeIndex === 0}
              >
                <ChevronLeft className="icon-gap-right" />
                {t('prior_month')}
              </Button>
              <Button
                style={{ borderRadius: 6 }}
                className="app-btn-primary fs-label unique-disable"
                onClick={handleNext}
                disabled={showTotal === totalDataLength}
              >
                {t('next_month')}
                <ChevronRight className="icon-gap-left" />
              </Button>
            </div>
          </div>
          <div className="d-flex salary-specs-wrapper py-3">
            {slicedData.map((item, idx) => {
              const month = moment(item.salaryPeriod.from).format('MMMM');
              const year = moment(item.salaryPeriod.from).year();
              return (
                <React.Fragment key={`salaryspec-${idx}`}>
                  <Col
                    className="mx-2 mt-5 position-relative"
                    key={idx}
                    md="4"
                    xl
                    // style={{ borderRight: idx % 2 === 0 ? '1px solid #5F5F5F' : 'none' }}
                  >
                    <ReportHeader title={`${year} ${month}`} />

                    <PagesHeader pageTitle="Salary" headingClass="font-black fs-h3" />
                    <CardComponent>
                      <div className="mt-3">
                        {item.transactions.map((i, index) => {
                          const salaryCodeName = i.salaryCodeData.name;
                          const salaryCode = i.salaryCodeData.code;
                          const label =
                            i.salaryCodeData.code === 0
                              ? 'Missing salary code'
                              : `${salaryCodeName} (Code ${salaryCode})`;

                          return (
                            <React.Fragment key={`reportline-${index}`}>
                              <ReportLine
                                key={i['salaryCode']}
                                label={label}
                                value={numberSpaceSeparator(i.amount.toFixed(2))}
                                tooltip={label}
                                type="LABEL_VALUE"
                                className="fs-text-400"
                              />
                            </React.Fragment>
                          );
                        })}
                        <div
                          style={{
                            borderTop: '1px solid #000000',
                            // borderBottom: '1px solid #000000',
                            // padding: '5px 0px',
                          }}
                        >
                          <ReportLine
                            label={t('total_gross_salary')}
                            value={numberSpaceSeparator(item.grossSalary.toFixed(2))}
                            tooltip={t('total_gross_salary') ?? ''}
                            type="LABEL_VALUE"
                            className="lh-1 fs-h3 font-black"
                          />
                          <ReportLine
                            label={t('preliminary_tax')}
                            value={numberSpaceSeparator(item.preliminaryTax.toFixed(2))}
                            tooltip={t('preliminary_tax')}
                            type="LABEL_VALUE"
                            className="lh-1 fs-h3 font-black"
                          />
                          <ReportLine
                            label={t('taxable_income')}
                            value={numberSpaceSeparator(item.taxableIcome.toFixed(2))}
                            tooltip={t('taxable_income')}
                            type="LABEL_VALUE"
                            className="lh-1 fs-h3 font-black"
                          />
                          <ReportLine
                            label={t('paid_out')}
                            value={numberSpaceSeparator(item.totalPayable.toFixed(2))}
                            tooltip={t('paid_out')}
                            type="LABEL_VALUE"
                            className="lh-1 fs-18px"
                          />
                        </div>
                        {/* <ReportLine
                          label={'Total Pension Provision'}
                          value={numberSpaceSeparator(item.totalPayable)}
                          tooltip={'Total Pension Provision'}
                          type="LABEL_VALUE"
                          className="fs-18px"
                        /> */}
                      </div>
                    </CardComponent>

                    <Button className="app-btn-primary d-block ms-auto">
                      <ChevronRight className="icon-gap-right" />
                      {`${t('salary')} ${t('slip')}`}
                    </Button>
                  </Col>

                  {idx % 2 === 0 && (
                    <Col
                      className="align-self-center mx-4"
                      md="4"
                      style={{ background: '#5F5F5F', width: 1, height: 500 }}
                    ></Col>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </Row>
      )}
    </div>
  );
};
