import { useState } from 'react';
import { Col, Row, Container, Button } from 'react-bootstrap';
import { Plus } from 'react-feather';
import Breadcrumb from '../../../components/base/Breadcrumb';
import PagesHeader from '../../../components/base/PagesHeader';
import { IBreadCrumb } from '../../../types/base/BreadCrumb';
import CreateClientModal from './CreateClient/CreateClientModal';
import { CreateClientProvider } from './context/CreateClientContext';
import ClientsGrid from './ClientsGrid';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import RoleAccess from '../../../components/base/RoleAccess';
import { UserSubRole } from '../../../types/auth';

const breadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'Clients',
  },
];

const myClientsBreadcrumb: IBreadCrumb[] = [
  {
    name: 'Overview',
    href: '/dashboard',
  },
  {
    name: 'My Clients',
  },
];

function ClientsPage() {
  const { t } = useTranslation();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isClientCreated, setIsClientCreated] = useState<boolean>(false);
  const { user } = useAuth();
  const subRole = user?.role?.subRole || '';
  const hasAccess = !subRole || ['Consultant', 'SeniorConsultant', 'JuniorConsultant'].indexOf(subRole) === -1;

  const toggleModal = () => {
    setShowCreateModal(!showCreateModal);
    setIsClientCreated(false);
  };

  return (
    <Container fluid className="p-0 client accounting-firm">
      <RoleAccess allowedRoles={[UserSubRole.SUPERVISOR]} roleType="secondary">
        <Breadcrumb navItem={breadcrumb} />
      </RoleAccess>
      <RoleAccess allowedRoles={[UserSubRole.SENIOR_CONSULTANT]} roleType="secondary">
        <Breadcrumb navItem={myClientsBreadcrumb} />
      </RoleAccess>
      <Row className="d-flex">
        <Col md="6">
          <RoleAccess allowedRoles={[UserSubRole.SUPERVISOR]} roleType="secondary">
            <PagesHeader headingClass="fs-h1" pageTitle="Clients" />
          </RoleAccess>
          <RoleAccess allowedRoles={[UserSubRole.SENIOR_CONSULTANT]} roleType="secondary">
            <PagesHeader headingClass="fs-h1" pageTitle="My Clients" />
          </RoleAccess>
        </Col>
        {hasAccess && (
          <Col md="6" className="d-flex justify-content-end mb-3">
            <span>
              <Button onClick={toggleModal} className="app-btn-primary me-1 d-flex align-items-center">
                <Plus className="icon-gap-right" />
                {`${t('new_client')}`}
              </Button>
            </span>
          </Col>
        )}
      </Row>
      <ClientsGrid isClientCreated={isClientCreated} />

      {showCreateModal && (
        <CreateClientProvider>
          <CreateClientModal
            setIsClientCreated={setIsClientCreated}
            toggleModal={toggleModal}
            showCreateModal={showCreateModal}
          />
        </CreateClientProvider>
      )}
    </Container>
  );
}

export default ClientsPage;
